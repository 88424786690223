/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/12/202
 * @modify 12/12/202
 * @desc Providing the assemble registration from /src/context which is used in /src/App.js
 */

import React from "react";
import { PatientRegContext } from "./";
const Practitionet = (props) => {
  let [patientData, setPatientData] = React.useState({
    identification: [{}],
    names: [{}],
    details: {},
    practitioner_description: {},
    communication: {},
    contact: [{
      "mobile": "",
      "mode": {
          "label": "EMAIL",
          "value": "CodingMaster/10469",
          "Type": "CONTACTSYSTEM",
          "_id": "CodeableConceptMaster/10468",
          "_key": "10468",
          "_rev": "_dsII2HC-BI",
          "activestatus": true,
          "coding": [
              {
                  "_key": "10469",
                  "_id": "CodingMaster/10469",
                  "_rev": "_dsII2sy-CL",
                  "Type": "CONTACTSYSTEM",
                  "activestatus": true,
                  "code": "email",
                  "createdby": "",
                  "createddate": 1635951049,
                  "display": "EMAIL",
                  "gmconfigvalues": {},
                  "id": 469,
                  "shortdesc": "EMAIL",
                  "status": true,
                  "updatedate": 1635951049,
                  "updatedby": ""
              }
          ],
          "createdby": "",
          "createddate": 1635951049,
          "id": 469,
          "status": true,
          "text": "email",
          "updatedate": 1635951049,
          "updatedby": ""
      },
      "use": {},
      "priority": {
          "label": "1",
          "value": "CodeableConceptMaster/10502",
          "Type": "PRIORITY",
          "_id": "CodeableConceptMaster/10502",
          "_key": "10502",
          "_rev": "_dsII2HC-Au",
          "activestatus": true,
          "coding": [
              {
                  "_key": "10503",
                  "_id": "CodingMaster/10503",
                  "_rev": "_dsII2s2-_M",
                  "Type": "PRIORITY",
                  "activestatus": true,
                  "code": "P001",
                  "createdby": "",
                  "createddate": 1635953893,
                  "display": "1",
                  "gmconfigvalues": {},
                  "id": 503,
                  "shortdesc": "1",
                  "status": true,
                  "updatedate": 1635953893,
                  "updatedby": ""
              }
          ],
          "createdby": "",
          "createddate": 1635953893,
          "id": 503,
          "status": true,
          "text": "P001",
          "updatedate": 1635953893,
          "updatedby": ""
      }
  }],
    Submitrole: {},
    address: [],
    publication: [{}],
    reporting: {},
    practitionerbyrole: [],
    qualification: [],
    training: [],
    job: [],
    awards: [],
  });
  return (
    <PatientRegContext.Provider value={{ ...patientData, setPatientData }}>
      {props.children}
    </PatientRegContext.Provider>
  );
};

export default Practitionet;
