export const styles = (theme) => ({
  root: {
    // height: "100%",
    flex: 1,
  },
  rootBackground:{
    backgroundColor: theme.frequency.paperColor,
    padding:"20px"
  },
  frequency: {
    color: theme.palette.text.primary
  },
  background: {
    backgroundColor: theme.palette.background.table
  },
  searchAndAddButtonContainer: {
    display: "flex",
    gap: "8px",
    marginRight: 20,
    "& .App1-MuiInputBase-root": {
      minWidth: "360px",
    },
  },
  searchAndButtonInput: {
    flex: 1,
  },
  add: {
    "&.App1-MuiButton-contained:hover": {
      backgroundColor: theme.palette.button.hover
    },
    backgroundColor: theme.palette.button.background,
    color:theme.palette.button.text,
  },
  tableContent: {
    height: "100%", // To make container fill full height
    borderRadius: "8px",
    border: "1px solid #DEE5EC",
    boxShadow: "0px 10px 25px #0000000A",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px 20px 10px 20px !important",
  },
  G28xA: {
    borderRadius: 7,
  },
  paginationContainer: {
    boxShadow: "unset",
    borderRadius: 0,
    // margin: "0px -20px",
  },
  divider: {
    margin: "0px -20px",
    backgroundColor: "#DEE5EC",
  },
  paginationComponent: {
    marginTop: 4,
  },
});
