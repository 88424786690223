import React from 'react';
import { Select, Div, Text } from 'qdm-component-library';
import { UIColor } from '../../../../../../utils';

const SelectCard = (props) => {

    const styles = {
        title: {
            fontSize: 12,
            color: UIColor.primaryText.color,
            margin: 0,
            marginBottom: 8
        },
        labelStyle: {
            color: UIColor.tertiaryText.color,
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        borderStyle: {
            borderRadius: '8px 8px 0px 0px',
            borderColor: UIColor.lineBorderFill.color,
            cursor: 'pointer'
        },
        content: {
            border: `1px solid ${UIColor.lineBorderFill.color}`,
            borderRadius: '0px 0px 8px 8px',
            padding: '7.5px 14px',
            minHeight: 100
        },
        contentText: {
            fontSize: 14,
            color: UIColor.secondaryText.color,
        },
        contentTextPlaceholder: {
            fontSize: 14,
            color: UIColor.tertiaryText.color,
        },
    };
    const { parent_id } = props;
    return (
        <Div
            // id={`${parent_id}_selectcard_parent_div`}
            style={{ width: '100%' }}>
            <Select
                // id={`${parent_id}_selectcard_select`}
                showArrow
                getOptionLabel="label"
                label={props?.label ?? ""}
                isRequired={props?.isRequired}
                placeholder={props?.placeholder ?? ""}
                labelStyle={styles.labelStyle}
                inLineStyles={styles.borderStyle}
                hoverColor={UIColor.lineBorderFill.color}
                value={props?.value ?? ""}
                onChangeValue={(e) => props?.onChangeValue(e)}
                options={props?.options ?? []}
            />
            <Div
                // id={`${parent_id}_selectcard_text_div`}
                className="pc_regular" style={props.content ? styles.content : null}>
                {
                    props?.content ?? <Text
                        // id={`${parent_id}_selectcard_${props?.content}_text`}
                        className="pc_regular" style={styles.contentText}>
                        {props?.content}
                    </Text>
                }
            </Div>
        </Div>
    )
}

SelectCard.defaultProps = {
    label: '',
    value: '',
    content: '',
    placeholder: '',
    contentPlaceHolder: '',
    options: [],
    onChangeValue: null,
    isRequired: false
}

export default SelectCard;