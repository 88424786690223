import React from "react";
import { Card, CircularProgress } from "@material-ui/core";
// import "../../components/font.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { 
    // GenerateForm, 
    CommonTable } from "../../../components";
import { AlertProps } from "../../../utils";
import { AlertContext } from "../../../contexts";
import { useTheme } from "@material-ui/styles";

export default function EncounterType(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { parent_id } = props;
  const alert = React.useContext(AlertContext);
  const listData = useSelector(
    (state) => state?.encounterTypeSlice?.encouterTypeRead?.data
  );
  const loading = useSelector(
    (state) => state?.encounterTypeSlice?.encouterTypeRead?.loading
  );
//   const saveloading = useSelector(
//     (state) => state?.encouterTypeSlice?.encouterTypeSlice?.loading
//   );

  const [state, setState] = React.useState({
    open: false,
    edit_data: null,
  });

//   const handleDrawerOpen = () => {
//     setState({
//       ...state,
//       open: true,
//     });
//   };
//   const handleDrawerClose = () => {
//     setState({
//       ...state,
//       open: false,
//       edit_data: null,
//     });
//   };

  React.useLayoutEffect(() => {
    dispatch(actions.ENCOUNTER_TYPE_READ());
  }, []);

//   const savePractitionerRole = async (values) => {
//     let data = {
//       code: values.type_code,
//       description: values.type_description,
//       type: props.type,
//       status: true,
//       id: 0,
//     };
//     if (state?.edit_data) {
//       data["_key"] = state?.edit_data._key;
//       data["id"] = state?.edit_data.id;
//       data["status"] = state?.edit_data.status;
//       data["coding_key"] = state?.edit_data?.coding_key??null;
//     }
    
//     let data_res = await dispatch(actions.PRACTITIONER_ROLE_UPSERT(data));
//     if(data_res?.payload?.data?.error){
//       alert.setSnack({
//         open: true,
//         severity: AlertProps.severity.error,
//         msg: data_res?.payload?.data?.validation_error?.[0]?.Errormsg?.replaceAll(/[.*+?^${}()"|[\]\\]/g,'')??"Something went wrong try again",
//         vertical: AlertProps.vertical.top,
//         horizontal: AlertProps.horizontal.right,
//       });
//     }else{
//       alert.setSnack({
//         open: true,
//         severity: AlertProps.severity.success,
//         msg: state?.edit_data ? "Updated Successfully" :"Added Successfully",
//         vertical: AlertProps.vertical.top,
//         horizontal: AlertProps.horizontal.right,
//       });
//     }
//     await dispatch(actions.PRACTITIONER_ROLE_READ({ type: props.type }));
//     await handleDrawerClose();
//   };

//   const handleEdit = (e, values, index) => {
//     let data = {
//       code: values.code_type,
//       description: values.description,
//       type: props.type,
//       status: values.status,
//       _key: values._key,
//       id: values.id,
//       coding_key: values?.coding_key??null
//     };
//     setState({
//       ...state,
//       edit_data: data,
//       open: true,
//     });
//   };

  const statusChange = async (e, values, index) => {
    let data = {
      status: e.target.checked,
      _key: values._key,
    };
    setState({
        ...state,
        edit_data: values
    })
    let data_res = await dispatch(actions.ENCOUNTER_TYPE_STATUS_CHANGE(data));
    if(data_res?.payload?.data?.error){
      alert.setSnack("error");
    }else{
      alert.setSnack("update");
    }
    await dispatch(actions.ENCOUNTER_TYPE_READ());
    setState({
        ...state,
        edit_data: null
    })
  };

  return (
    <Card
      id={`${parent_id}-parent-card`}
      style={{ borderRadius: "12px" }}>
      <div
        id={`${parent_id}-parent-div`}
        style={{ width: "100%" }}>
        <Card
          id={`${parent_id}-parent-sub-card`}
          style={{ borderRadius: "12px" }}>
          <div
            id={`${parent_id}-parent-sub-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              id={`${parent_id}-header-button-div`}
              style={{ display: "flex", justifyContent: "space-between",backgroundColor: theme.palette.background.table}}>
              <div
                id={`${parent_id}-${props.header?.replaceAll(" ", "-")}-div`}
                style={{ marginLeft: "18px" }}>
                <p
                  id={`${parent_id}-${props.header?.replaceAll(" ", "-")}-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  {props.header}
                </p>
              </div>
              {/* <div
                id={`${parent_id}-button-div`}
                style={{ marginTop: "16px", width: "10%", float: "right" }}>
                <button
                  id={`${parent_id}-Add-New-button`}
                  style={{
                    backgroundColor: "#0071F2",
                    color: "white",
                    border: "none",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderRadius: "5px",
                    fontFamily: "poppin",
                    fontSize: "12px",
                  }}
                  onClick={handleDrawerOpen}
                >
                  +Add New
                </button>
                <GenerateForm
                  parent_id={'practitionerrole'}
                  nocancel
                  loading={saveloading}
                  open={state.open}
                  save={savePractitionerRole}
                  handlecancel={handleDrawerClose}
                  header={
                    state.edit_data
                      ? `Edit ${props.header}`
                      : `Add ${props.header}`
                  }
                  json={[
                    {
                      componet_type: "text",
                      label: `${props.header} code`,
                      state_name: "type_code",
                      value: state?.edit_data?.code ?? "",
                      required: true,
                    },
                    {
                      componet_type: "text",
                      label: `${props.header} description`,
                      state_name: "type_description",
                      value: state?.edit_data?.description ?? "",
                      required: true,
                    },
                  ]}
                />
              </div> */}
            </div>

            {/* table */}
            <div
              id={`${parent_id}-circularprogress-div`}
              style={{ padding: 20, height: "71vh", overflow: "auto",backgroundColor: theme.palette.background.table}}>
              {loading && state.edit_data === null ? (
                <div
                  id={`${parent_id}-circularprogress-div`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "66vh",
                  }}
                >
                  <CircularProgress
                    id={`${parent_id}-circularprogress`}
                  />
                </div>
              ) : (
                <CommonTable
                  parent_id={'practitionerrole'}
                  Header={[
                    "S.No",
                    "Code",
                    "Description",
                    "Encounter Class",
                    "Status",
                    // "Action",
                  ]}
                  dataList={listData}
                  rightAlign={["Status", "Action"]}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "code" },
                    { type: ["TEXT"], name: "shortDesc" },
                    { type: ["TEXT"], name: "encounter_class" },
                    { type: ["CHECKBOX"], name: "status", align: "right" },
                    // { type: ["EDIT"], name: "", align: "right" },
                  ]}
                  handleCheckBox={statusChange}
                //   handleEdit={handleEdit}
                />
              )}
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
}
