/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Exporting all the functions from the /src/utils
 */

export * from "./helperFunctions";
export * from "./messages";
export * from "./validations";
export { default as Permissions } from "./permissions";
export * from "./permissionHandling";

export const languageOptions = [];

export const DispenseDurationOptions = [
  { label: "days", value: "1" },
  { label: "weeks", value: "7" },
  { label: "months", value: "30" },
  { label: "years", value: "365" },
];

export const DoseIssueUOMOptions = [{ label: "Strip", value: "strip" }];

export const UIColor = {
  primaryColor: {
    color: "#01205C",
  },
  secondaryColor: {
    color: "#EC6A49",
  },
  secondaryButtonColor: {
    color: "#2A60BC",
  },
  primaryText: {
    color: "#001C3C",
  },
  secondaryText: {
    color: "#2A3C50",
  },
  tertiaryText: {},
  greyBackground: {
    color: "#F4F5F8",
  },
  differentiationBackground: {
    color: "#F1F6FC",
  },
  lineBorderFill: {
    color: "#DEE5EC",
  },
  error: {
    color: "#FF4D4A",
  },
  success: {
    color: "#3BB213",
  },
  sidebarIconColor: {
    color: "#305396",
  },
};

export const WEEKDAYS = [
  {
    label: "Sunday",
    value: "Sunday",
  },
  {
    label: "Monday",
    value: "Monday",
  },
  {
    label: "Tuesday",
    value: "Tuesday",
  },
  {
    label: "Wednesday",
    value: "Wednesday",
  },
  {
    label: "Thursday",
    value: "Thursday",
  },
  {
    label: "Friday",
    value: "Friday",
  },
  {
    label: "Saturday",
    value: "Saturday",
  },
];

// Function that returns an array of label value pairs containing hours of the day in 24 hr format
export const getHourOptions = () => {
  const hours = [];
  for (let i = 1; i <= 168; i++) {
    hours.push({
      label: `${i < 10 ? `0${i}` : i}`,
      value: `${i < 10 ? `0${i}` : i}`,
    });
  }
  return hours;
};

export const DispenseDurationUOMOptions = {
  Days: "DrugUOM/11616",
  Months: "DrugUOM/11617",
  Weeks: "DrugUOM/11618",
  Years: "DrugUOM/11620",
  Doses: "DrugUOM/11629",
  Hours: "DrugUOM/11631",
};

export const getDurationWithGranularity = (duration, granularity) => {
  switch (granularity?.toLowerCase()) {
    case "days":
      return duration.asDays();
    case "months":
      return duration.asMonths();
    case "weeks":
      return duration.asWeeks();
    case "years":
      return duration.asYears();
    case "hours":
      return duration.asHours();
    default:
      return duration.asDays();
  }
};

export const WORKFLOW_VIEW = [
  {
    id: "compact",
    display: "Compact View",
  },
  {
    id: "default",
    display: "Default View",
  },
];
