import React from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import FilterListIcon from "@material-ui/icons/FilterList";
import { StyledPaper } from "../commonComponents";
import { withAllContexts } from "../../HOCs";
import FilterComponent from "./filter";
import { FIELD_TYPES } from "./utils";
import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";
import { getFormatForDateTimePicker } from "../../utils";
// import { headCells, rows } from "./utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// function normalize(headCells, rows) {
//   return rows?.map((row) => {
//     headCells.forEach((element) => {
//       row[element] = new Date(row[element]).format("dd/mm/yyyy");
//     });

//     return row;
//   });
// }

function EnhancedTableHead(props) {
  const { classes, headCells = [], order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.columnname}
            align={headCell.align}
            sortDirection={orderBy === headCell.columnname ? order : false}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.columnname}
                direction={orderBy === headCell.columnname ? order : "asc"}
                onClick={createSortHandler(headCell.columnname)}
              >
                {headCell.display}
                {orderBy === headCell.columnname ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <>{headCell.display}</>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { searchText, onSearchChange, onFilterIconClick } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      ></Typography>

      <TextField
        id="search"
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={onSearchChange}
      />

      <Tooltip title="Filter list">
        <IconButton aria-label="filter list" onClick={onFilterIconClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  searchText: PropTypes.string,
  handleSearchChange: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [searchText, setSearchText] = React.useState("");
  const [searchableCells, setSearchableCells] = React.useState([]);
  const [filterState, setFilterState] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { headCells = [], rows } = props;

  React.useEffect(() => {
    setSearchableCells(headCells.filter((headCell) => headCell.search));

    let statePayload = {};

    if (Array.isArray(headCells)) {
      headCells
        .filter((headCell) => headCell?.filter)
        .map((cell) => {
          statePayload[cell?.columnname] = getDefaultStateByFieldType(
            cell?.datatype
          );
        });
    }

    setFilterState(statePayload);
  }, [headCells]);

  const getDefaultStateByFieldType = (datatype) => {
    switch (datatype) {
      case FIELD_TYPES.text:
        return "";
      case FIELD_TYPES.switch:
        return false;
      case FIELD_TYPES.autocomplete:
      case FIELD_TYPES.datepicker:
      case FIELD_TYPES.timepicker:
        return null;
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  //   const getSearch = (row, column) => {
  //     if (column.numeric) {
  //       row[cell?.id]?.t;
  //     }

  //     return row[cell?.id]?.toLowerCase()?.includes(searchText?.toLowerCase());
  //   };

  const searchRows = (rows) => {
    
    if (searchText?.length === 0) return rows;
    // Filter Rows
    const result = rows.filter((row) => {
      let isTruthy = false;
      for (const cell of searchableCells) {
        isTruthy =
          isTruthy ||
          row[cell?.columnname]
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase());
      }
      return isTruthy;
    });
    return result;
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleDrawerClose = () => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: false,
      component: <></>,
    });
  };

  const handleFilterIconClick = () => {
    props.drawer.setDrawer({
      ...props.drawer,
      open: true,
      component: (
        <FilterComponent
          headCells={headCells?.filter((cell) => cell?.filter)}
          state={filterState}
          setState={(key, value) => {
            
            setFilterState((prev) => ({
              ...prev,
              [key]: value,
            }));
          }}
          onApplyFilterClick={() => {}}
          onCancelClick={handleDrawerClose}
        />
      ),
    });
  };

  const normalizeValue = (row, headCell) => {
    switch (headCell?.datatype) {
      case FIELD_TYPES.date:
        return format(
          fromUnixTime(row[headCell?.columnname]),
          getFormatForDateTimePicker("date")
        );
      case FIELD_TYPES.time:
        return format(
          fromUnixTime(row[headCell?.columnname]),
          getFormatForDateTimePicker("time")
        );
      case FIELD_TYPES.datetime:
        return format(
          fromUnixTime(row[headCell?.columnname]),
          getFormatForDateTimePicker("datetime")
        );
      default:
        return row[headCell?.columnname];
    }
  };

  return (
    <div className={classes.root}>
      <StyledPaper className={classes.paper}>
        <EnhancedTableToolbar
          searchText={searchText}
          onSearchChange={handleSearchChange}
          onFilterIconClick={handleFilterIconClick}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {searchRows(
                stableSort(
                  rows,
                  // normalize(dateColumns, rows),
                  getComparator(order, orderBy)
                )
              )
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  //   const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow tabIndex={-1} key={row.name}>
                      {headCells?.map((headCell) => (
                        <TableCell align={headCell.align}>
                          {normalizeValue(row, headCell)}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
      </StyledPaper>
    </div>
  );
}

export default withAllContexts(EnhancedTable);
