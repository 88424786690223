import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Tag from "../tag";
import Dot from "../../dot";
import TypographyWithIcon from "./typographyWithIcon";
import { HoldingHandIcon, StackOverflowIcon, TrashIcon } from "../../../assets";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 12,
    border: `1px solid #DEE5EC`,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  drugName: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
  },
  clickable: {
    cursor: "pointer",
  },
  tag: {
    borderRadius: 4,
  },
  defaultText: {
    lineHeight: 1,
    color: "white",
  },
  batchCount: {
    cursor: "pointer",
    "& > p": {
      color: "#2A60BC",
      fontWeight: "500",
    },
  },
}));

function DrugComponent(props) {
  // <------------------Hooks--------------->
  const classes = useStyles(props);
  const drugNamePopupState = usePopupState({
    variant: "popover",
    popupId: "drugName",
  });

  // <---------------Store States----------->

  // <-------------Component States--------->

  // <-----------------Handlers------------->

  // <-----------------Effects-------------->

  // <-----------------Others--------------->
  const {
    isBaseDrug,
    onBatchCountClick,
    drugName,
    issueQuantity,
    issueUom,
    Stock,
    showDelete,
    handleDelete,
    allowChangeDrug,
    options,
    handleChangebrandName,
  } = props;

  // <-----------------Render--------------->
  return (
    <div className={classes.root}>
      {isBaseDrug && (
        <Tag className={classes.tag}>
          <Typography variant="body2" className={classes.defaultText}>
            Base
          </Typography>
        </Tag>
      )}
      <div
        className={clsx(classes.drugName, classes.clickable)}
        {...(allowChangeDrug && { ...bindTrigger(drugNamePopupState) })}
      >
        <Typography variant="body2">{drugName}</Typography>
        {allowChangeDrug && <ExpandMoreIcon fontSize="small" />}
      </div>
      <Dot />
      <TypographyWithIcon startAdornment={<HoldingHandIcon />}>
        {issueQuantity}&nbsp;{issueUom}
      </TypographyWithIcon>
      <Box flexGrow={1} />
      {showDelete && (
        <TrashIcon
          onClick={() => handleDelete()}
          className={classes.clickable}
        />
      )}
      <TypographyWithIcon
        startAdornment={<StackOverflowIcon color={"#2A60BC"} />}
        className={classes.batchCount}
        onClick={onBatchCountClick}
      >
        {Stock}
      </TypographyWithIcon>
      {/* Menu for Drug Name Dropdown Icon */}
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...bindMenu(drugNamePopupState)}
      >
        {options?.map((item) => {
          return (
            <MenuItem onClick={() => handleChangebrandName(item.drugNameLong)}>
              {item.drugNameLong}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

export default DrugComponent;

DrugComponent.defaultProps = {
  isBaseDrug: false,
  onBatchCountClick: () => {},
};

DrugComponent.propTypes = {
  isBaseDrug: PropTypes.bool,
  onBatchCountClick: PropTypes.func,
};
