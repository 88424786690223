import React from "react";
import { CommonTable } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";

function DrugType() {
    const dispatch = useDispatch();

    const drugTypelist = useSelector((state) => state?.drugCategorySlice?.drug_type_list?.data);

    React.useLayoutEffect(() => {
        dispatch(actions.DRUG_TYPE_READ());
    }, [])
    // handleCloseForm = () => {
    //     const { changeState } = this.props
    //     changeState("open", false)
    //     this.setState({
    //         openEditFrom: false,
    //         // edit_data: null
    //     })
    // }
    // render() {
    //     const { open, openIndex } = this.props;
    return <React.Fragment>
        <CommonTable
            style={{ height: "calc(100vh - 245px)" }}
            parent_id={"drugType"}
            // handleEdit={this.handleEdit}
            Header={["S.No", "Drug Type", "Short Description", "Long Description"]}
            dataList={drugTypelist}
            tableData={[
                { type: ["INCRIMENT"], name: "" },
                { type: ["TEXT"], name: "drug_type" },
                { type: ["TEXT"], name: "short_description" },
                { type: ["TEXT"], name: "long_description" },
            ]}
        />
    </React.Fragment>
}

export default DrugType