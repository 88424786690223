import { Paper } from "@material-ui/core";

import React, { Component } from "react";
import { translate } from "react-i18next";
import { withRouter } from "react-router";
import Config from "../../config";
import { withAllContexts } from "../../HOCs";

class TaskAndDocument extends Component {
  render() {
    return (
      <>
        <Paper
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#F3F4F7",
            padding: "20px",
          }}
          elevation={3}
        >
          <Paper
            style={{
              width: "100%",
              height: "100%",
              padding: "20px",
            }}
            elevation={3}
          >
            <iframe
              title="Task And Document"
              src={Config.taskanddocument}
              style={{
                width: "100%",
                height: "100%",
                outline: "none",
                border: "none",
              }}
            ></iframe>
          </Paper>
        </Paper>
      </>
    );
  }
}

export default withRouter(withAllContexts(translate()(TaskAndDocument)));
