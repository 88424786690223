import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Avatar,
  IconButton,
  makeStyles,
} from "@material-ui/core";

// import { Autocomplete } from "@material-ui/lab";
import qdmstyles from "./styles.json";
import "./index.css";
import { InputBase, Autocomplete } from "../inputs";
import { TrashIcon } from "../../assets";
import Color from "../../themes/default.json"
import classNames from "classnames";
var aes256 = require("aes256");

const useStyles = makeStyles((theme) => ({
  mapped_title: {
    color: theme.palette.text.primary
  },
  description: {
    color: theme.palette.primary.light
  }
}));

function Mappeddespensablecomp(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();
  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  const {
    data,
    index,
    options,
    statevalue = [],
    stateName,
    onAddNewClick = () => { },
    onDeleteClick = () => { },
    setFun = () => { },
    shouldDisable,
    isOrderable,
  } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"hDIJk"}
            container={true}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {/* Header Starts */}
            <Grid
              key={"0"}
              id={"TgstR"}
              container={true}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              lg={"auto"}
              style={qdmstyles.rTexp}
            >
              <Grid
                key={"0"}
                id={"TgstR"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
                lg={"6"}
              >
                <Typography
                  key={"0"}
                  id={"mapped_dispensable_header"}
                  align={"left"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"MAPPED DISPENSABLES"}
                  aliasName={"mappeddispensableheader"}
                  style={qdmstyles.NAXiU}
                  className={classes.mapped_title}
                ></Typography>
              </Grid>
              <Grid
                key={"1"}
                id={"TgstR"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
              >
                <Button
                  key={"0"}
                  id={"mapped_dispensable_add_new_button"}
                  variant={"text"}
                  disabled={
                    location?.state?.isView === true || !shouldDisable
                      ? true
                      : false
                  }
                  color={"primary"}
                  children={"+Add New"}
                  aliasName={"mappeddispensableaddnewbutton"}
                  style={qdmstyles.NBvD}
                  onClick={() => onAddNewClick(stateName)}
                ></Button>
              </Grid>
            </Grid>
            {/* Header Ends */}

            {/* Body Starts */}

            <Grid
              key={"1"}
              id={"OeOjQ"}
              container={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              lg={"12"}
              md={"12"}
              sm={"12"}
              xl={"12"}
              xs={"12"}
            >
              <Grid
                key={"0"}
                id={"7uXWP"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"baseline"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                spacing={1}
              >
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6} md={5}>
                    <Typography
                      key={"0"}
                      id={"item_code_description_header"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Item Code & Description"}
                      className={classes.description}
                      aliasName={"itemcodedescriptionheader"}
                      style={qdmstyles.fWx}
                    ></Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography
                      key={"0"}
                      id={"preferred_sequence_header"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Preferred Sequence"}
                      className={classes.description}
                      aliasName={"preferredsequenceheader"}
                      style={qdmstyles.HRAX}
                    ></Typography>
                  </Grid>
                </Grid>

                {Array.isArray(statevalue) &&
                  statevalue?.map((item, index) => {
                    const rowState = statevalue?.[index];
                    return (
                      <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12} sm={6} md={5}>
                          <Autocomplete
                            id={"8VQ95"}
                            style={qdmstyles?.VQ}
                            disabled={
                              (isOrderable && index === 0) ||
                              location?.state?.isView === true ||
                              !shouldDisable
                                ? true
                                : false
                            }
                            getOptionLabel={(option) => {
                              const labelOption = [
                                option?.["DrugCode"] ?? "",
                                option?.["label"] ?? "",
                              ];
                              if (labelOption.some((x) => !!x)) {
                                return labelOption
                                  .filter((item) => item)
                                  .join(" - ");
                              } else {
                                return "";
                              }
                            }}
                            options={options?.item_code ?? []}
                            size={"small"}
                            onLoad={() =>
                              setFun(
                                stateName,
                                "dispensedrugcode",
                                rowState?.dispensedrugcode,
                                index
                              )
                            }
                            value={rowState?.dispensedrugcode ?? null}
                            onChange={(event, newValue) =>
                              setFun(
                                stateName,
                                "dispensedrugcode",
                                newValue,
                                index
                              )
                            }
                          />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={3} spacing={1}>
                          <Grid item xs>
                            <InputBase
                              key={"0"}
                              id={"preferred_sequence_value"}
                              label={""}
                              placeholder={""}
                              disabled={
                                location?.state?.isView === true ||
                                !shouldDisable
                                  ? true
                                  : false
                              }
                              type={"number"}
                              select={""}
                              size={"small"}
                              fullWidth={true}
                              variant={"outlined"}
                              margin={"none"}
                              aliasName={"preferredsequencevalue"}
                              inputProps={{
                                maxlength: 2,
                                min: 0,
                                max: 99,
                              }}
                              value={rowState?.seqno}
                              onChange={(event) => {
                                if (event?.target?.value?.length > 2) return;
                                setFun(
                                  stateName,
                                  "seqno",
                                  parseInt(event.target.value),
                                  index
                                );
                              }}
                            ></InputBase>
                          </Grid>
                          <Grid item>
                            <IconButton
                              onClick={() => onDeleteClick(stateName, index)}
                              disabled={
                                (isOrderable && index === 0) ||
                                location?.state?.isView === true ||
                                !shouldDisable
                                  ? true
                                  : false
                              }
                            >
                              <TrashIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Mappeddespensablecomp));
