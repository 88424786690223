import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    flexWrap: "nowrap",
    // minWidth: "fit-content",
  },
  iconContainer: {
    backgroundColor: "#F1F6FC",
    height: 40,
    width: 40,
    minWidth: 40,
    minHeight: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
  },
  label: {
    fontSize: 10,
    
    whiteSpace: "nowrap",
  },
  value: {
    fontSize: 14,
    color: "#2A3C50",
    fontWeight: 500,
  },
}));

function TimingDisplay({ icon, label, value, valueClassName, valueComponent }) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.iconContainer}>{icon}</div>
      <div className={classes.contentContainer}>
        <Typography className={classes.label}>{label}</Typography>
        {valueComponent ? (
          React.cloneElement(valueComponent, {className: classNames(classes.value, valueClassName)})
        ) : (
          <Typography className={classNames(classes.value, valueClassName)}>
            {value}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default TimingDisplay;
