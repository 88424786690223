import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
  TimePicker as MuiTimePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";

import PropTypes from "prop-types";
import { Typography, makeStyles } from "@material-ui/core";
import { getFormatForDateTimePicker } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "poppin",
    color: "#6F6F6F",
    fontSize: "12px",
    paddingBottom: "8px",
  },
  root: {
    "& .App1-MuiTextField-root": {
      display: "block",
    },
    "& .App1-MuiInputBase-input": {
      padding: "10.5px 14px",
    },
    "& .App1-MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
      width: "100%",
    },
    "& .App1-MuiOutlinedInput-root": {
      borderRadius: 7,
    },
    "& .App1-MuiSvgIcon-root": {
      fontSize: "1.25rem",
    },
    "& .App1-MuiFormHelperText-root": {
      color: "red",
    },
    "& .App1-MuiInputAdornment-root": {
      position: "absolute",
      right: 0,
    },
  },
}));

export const TimePicker = (props) => {
  const classes = useStyles(props);
  const { parent_id } = props;
  const handleChange = (date) => {
    props?.onChange && props.onChange(date);
  };
  let minDateVar = {};
  if (props.minDate) {
    minDateVar = { minDate: props.minDate };
  }
  let maxDateVar = {};
  if (props.maxDate) {
    maxDateVar = { maxDate: props.maxDate };
  }

  return (
    <div
      id={`${parent_id}-parent-div`}
      className={`${classes.root} ${props?.className}`}
    >
      {props?.label && (
        <Typography
          id={`${parent_id}-${props?.label.replaceAll(" ", "-")}-typography`}
          variant="caption"
          className={classes.title}
        >
          {props?.label}
          {props?.required && (
            <span
              style={{
                color: "red",
                marginLeft: 5,
              }}
            >
              *
            </span>
          )}
        </Typography>
      )}
      <MuiPickersUtilsProvider
        id={`${parent_id}-MuiPickersUtilsProvider`}
        utils={DateFnsUtils}
      >
        {props.views &&
        props.views.length > 0 &&
        ["hour", "minute", "second"].includes(props.views) ? (
          <MuiTimePicker
            id={`${parent_id}-MuiTimePicker`}
            placeholder={props.placeholder}
            inputVariant="outlined"
            disabled={props.disabled ? true : false}
            disableToolbar={props.disableToolbar}
            views={[props.views]}
            ampm={props.ampm}
            minutesStep={props.minutesStep}
            autoOk
            // label="Year only"
            value={props?.value ? props?.value : null}
            variant="inline"
            format={props?.dateFormat}
            onChange={(date) => handleChange(date)}
            error={props?.error}
            {...minDateVar}
            {...maxDateVar}
          />
        ) : (
          <KeyboardTimePicker
            id={`${parent_id}-KeyboardTimePicker`}
            placeholder={props.placeholder}
            disableToolbar={props.disableToolbar}
            variant="inline"
            style={{ width: "100%" }}
            autoOk
            format={props?.dateFormat}
            disabled={props.disabled ? true : false}
            ampm={props.ampm}
            minutesStep={props.minutesStep}
            // margin="normal"
            // id="date-picker-inline"
            // label="Date picker inline"
            value={props?.value ? props?.value : null}
            onChange={(date) => handleChange(date)}
            inputVariant="outlined"
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
            PopoverProps={{
              anchorOrigin: { horizontal: "center", vertical: "bottom" },
              // transformOrigin: { horizontal: "center", vertical: "center" }
            }}
            error={props?.error}
            {...minDateVar}
            {...maxDateVar}
          />
        )}
      </MuiPickersUtilsProvider>
    </div>
  );
};

TimePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  dateFormat: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  minDate: PropTypes.string,
  views: PropTypes.string,
  disableToolbar: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  minutesStep: PropTypes.number,
};

TimePicker.defaultProps = {
  dateFormat: getFormatForDateTimePicker("time"),
  error: false,
  required: false,
  minDate: null,
  maxDate: null,
  views: null,
  disableToolbar: false,
  className: "",
  placeholder: "",
  ampm: true,
  minutesStep: 1,
};
