import * as React from "react";
import PropTypes from "prop-types";

const PatientOxygenMask = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="21.035" viewBox="0 0 16.462 21.035">
    <path id="icons8-patient-oxygen-mask" d="M15.231,3C9.082,3,7.457,8.2,7.457,10.956v1.6s0,.007,0,.012A2.008,2.008,0,0,0,7,13.884a2.2,2.2,0,0,0,1.679,2.144,12.666,12.666,0,0,0,2.926,4.665,3.292,3.292,0,0,0,2.229,2.15l.025.011V21.67l-.457-.457V19.92a1.829,1.829,0,0,1,3.658,0v1.293l-.457.457v1.183l.023-.011a3.291,3.291,0,0,0,2.232-2.153,12.666,12.666,0,0,0,2.925-4.706,2.134,2.134,0,0,0,1.679-2.1A2,2,0,0,0,23,12.559c0-.016.01-1.6.01-1.6C23.005,5.974,20.1,3,15.231,3ZM12.765,7.657c1.073,1.308,2.686,1.295,4.04,1.284,1.793-.014,2.828.054,3.272,1.616a3.563,3.563,0,0,0,1.806,2.225l.281.14a.445.445,0,0,0,.107.187,1.061,1.061,0,0,1,.277.775A1.242,1.242,0,0,1,21.4,15.121a.455.455,0,0,0-.379.292,13.744,13.744,0,0,1-2.2,4,.314.314,0,0,1-.115.034c-.534-1.646-2.14-4.559-3.474-4.559s-2.943,2.916-3.475,4.562a.314.314,0,0,1-.115-.034,13.714,13.714,0,0,1-2.2-3.961.456.456,0,0,0-.378-.289,1.285,1.285,0,0,1-1.146-1.283,1.06,1.06,0,0,1,.277-.775.452.452,0,0,0,.095-.142A8.027,8.027,0,0,0,12.765,7.657Zm-.534,5.833c-.124.01-.2.067-.2.256a.686.686,0,1,0,1.372,0c0-.379-.307-.229-.686-.229C12.527,13.518,12.355,13.48,12.231,13.49Zm5.03,0c-.124.01-.2.067-.2.256a.686.686,0,1,0,1.372,0c0-.379-.307-.229-.686-.229C17.557,13.518,17.386,13.48,17.261,13.49Zm-2.03,5.515a.914.914,0,0,0-.915.915v.915l.457.457v2.744h.915V21.292l.457-.457V19.92A.914.914,0,0,0,15.231,19.005Z" transform="translate(-7 -3)" fill="#8895a5" />
  </svg>
);

export default PatientOxygenMask;

PatientOxygenMask.defaultProps = {
  color: "#2a3c50",
};

PatientOxygenMask.propTypes = {
  color: PropTypes.string,
};
