import { makeStyles } from "@material-ui/core";

export const useDividerStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#DEE5EC",
    width: "100%"
  },
  flexItem: {
    height: "1px",
    marginTop: "8px",
    alignSelf: "center",
  },
}));
