import React from "react";

import { NamespacesConsumer, translate } from "react-i18next";

import { withRouter } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Paper, makeStyles } from "@material-ui/core";
import OrderCartTable from "../../components/cartTable";
import { TopNavBar } from "../../components";
import AlertTagsForCart from "../../components/drugItem/components/drugCardTags/cartTags";
import Cartdetails from "../../components/carddetails";
import { actions } from "frequencyscreen_v_dbinder";

import { ReactComponent as ItemSvg } from "../../assets/GroupItem.svg";
import moment from "moment";
import { AlertProps } from "../../utils";
import { withAllContexts } from "../../HOCs";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  Maintitle: {
    color: theme.palette.text.primary,
  },
  MainBackground: {
    background: `${theme.palette.background.common} !important`,
  },
  MainBackgroundCard: {
    background: `${theme.palette.background.table} !important`,
  },
}));

function CartOrder(props) {

  let dispatch = useDispatch();
  const classes = useStyles();

  const history = useHistory();

  const stateList = useSelector((state) => state?.signInReducerApiSlice);

  const [ward, setWard] = React.useState(false);

  const [show, setShow] = React.useState(false);

  const [data, setData] = React.useState({
    cartName: [],
    subStore: [],
    subStoreValue: null,
    cartNameValue: null,
    startDate: null,
    endDtae: null,
    Orgname: '',
    cartid: "",
    carttrxid: "",
    Json: [],
    ward: [],
    wardValue: {},
    wardValueCode: '',
    carttrxid__key: ""
  });

  const handleState = async (val) => {


    if (!data.cartNameValue?.value || !data.wardValue?.value) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the mandatory fields!.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }


    const cartGenerate = await dispatch(
      actions.CART_GENERATE({
        cartid: data?.cartid,
        carttrxid: data?.carttrxid
      })
    );
    if (cartGenerate?.payload?.data?.error) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: cartGenerate?.payload?.data?.error,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      setShow(false);
      return;
    } else {
      const data_ = cartGenerate?.payload?.data?.map(v => {
        return {
          tabname: v?.drugname ?? '',
          hand: v?.totIssueQty + ' ' + v?.issueuom,
          stack: v?.stkQTY + ' ' + v?.stockuom,
          ban: v?.blockqty ?? '',
          order: "No.of orders" + ' ' + `(${v?.orderID?.length})`,
          // negative: true,
          numOrders: v?.orderID
        }
      })
      if (data_?.length > 0) {
        setShow(true);
      } else {
        setShow(false);
      }
      setData({ ...data, Json: data_ })
    }
  };

  React.useEffect(() => {


    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;

      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission

    getFetch()

  }, []);

  const getFetch = async () => {

    const cartName = await dispatch(
      actions.CART_NAME()
    );

    const result = cartName?.payload?.data?.map(v => {
      if (v?.cartName) {
        return {
          label: v?.cartName,
          value: v?.cartid,
          startDate: v?.startdate,
          endDate: v?.enddate,
          _id: v?._id,
          _key: v?._key,
          ward: v?.ward?.map(c => {
            return {
              label: c?.longdesc ?? '',
              value: c?._id ?? '',
              _loc: c?.locationID ?? '',
            }
          })
        }
      }
    }).filter((notUndefined) => notUndefined !== undefined);

    setData({ ...data, cartName: result })
  }

  const handleChange = async (n, v) => {

    data.wardValueCode = ''
    data.wardValue = {}

    if (n === 'cartNameValue') {
      const subStore = await dispatch(
        actions.SUB_STORE({ id: v?.value })
      );
      data.startDate = v?.startDate
      data.endDate = v?.endDate
      const result = subStore?.payload?.data?.map(v => {
        return {
          label: v?.locationDetails?.longdesc,
          Orgname: v?.cartname || v?.locationDetails?.longdesc,
          value: v?._id,
          locationid: v?.locationDetails?._id,
          locatId: v?.locationDetails?.locationID
        }
      });

      data.cartid = v?.value ?? ''
      data.carttrxid = v?._id ?? ''
      data.carttrxid__key = v?._key ?? ''

      data.ward = v?.ward ?? ''

      data.subStore = result

      data.subStoreValue = result?.[0] ?? {}
      data.Orgname = result?.[0]?.Orgname ?? ''
      setWard(false)
    }
    else if (n === 'wardValue') {
      data.wardValueCode = v?.label
    }

    setData({ ...data, [n]: v })
  }

  const handleOpenCart = async () => {
    

    const loginUser = stateList?.loggedUserInfo?.data;

    let arr = [];
    const updateOrderlineId = data?.Json?.map(v => {
      return v?.numOrders?.map(c => {
        return arr.push(c?.order)
      })
    });

    const uniqueId = arr?.filter((val, id, arr) => arr?.indexOf(val) == id);

    const payload = {
      "carttransid": data?.carttrxid ?? '',
      "cartid": data?.cartid ?? '',
      "enterpriseid": loginUser?.enterprise?._id ?? '',
      "enterprisealiasid": loginUser?.enterprise?.alias?.[0]?.aliasDesc ?? '',
      "orgid": loginUser?.org?._id ?? '',
      "orgaliasid": loginUser?.org?.alias?.[0].aliasDesc ?? '',
      "facilityid": loginUser?.facility?._id ?? '',
      // "facilityaliasid": loginUser?.facility?.alias?.[0]?.aliasDesc ?? '',
      // "facilityid": JSON.parse(loginUser?.facility?.alias?.[0]?.aliasDesc) ?? '',
      "facilityaliasid": JSON.parse(loginUser?.facility?.alias?.[0]?.aliasDesc) ?? '',
      // "invlocationid": data?.wardValue?.value ?? '',
      // "invlocationcode": data?.wardValue?.label ?? '',
      "invlocationid": data?.subStoreValue?.locationid ?? '',
      "invlocationcode": data?.subStoreValue?.locatId ?? '',
      "orderLineId": uniqueId ?? [],
    };

    const openCart = await dispatch(
      actions.OPEN_CART({ ...payload })
    );

    if (openCart?.payload?.data?.error) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: openCart?.payload?.data?.errorMessage || openCart?.payload?.data?.result?.[0]?.message ||
          openCart?.payload?.data?.result?.[0]?.Reason || openCart?.payload?.data?.error,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    } else {

      await dispatch(
        actions.UPDATE_STATUS_CART({
          key: data?.carttrxid__key ?? '',
          json: {
            time: moment().unix() ?? 0
          }
        })
      );

      history.push({
        pathname: "/cartOrderVerify",
        state: {
          isCardVerify: true,
          cartID: data?.cartid ?? '',
          locID: data?.subStoreValue?.locationid ?? '',
          wardID: data?.wardValue?.value ?? '',
        },
      })
    }
    // history.push({
    //   pathname: "/cartOrderVerify",
    //    state: {
    //   isCardVerify: true,
    //   cartID: data?.cartid ?? '',
    //   locID: data?.subStoreValue?.locationid ?? '',
    //   wardID: data?.wardValue?.value ?? '',
    // },
    // })
  };

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          {/* <TopNavBar
            ProjectTitle={"CLMM"}
            //   VersionNo={`Version: ${localStorage.getItem(
            //     LocalStorageKeys.version
            //   )}`}
            UserName={"Martha Richardsss"}
            Title={ props?.history?.location?.state?.id}
            SubTitle={"Pharmacist, Apollo Hospital"}
            HeaderBackgroundColor={"#01205C"}
            HeaderFontColor={"#FFFFFF"}
            Header={true}
          /> */}

          <div style={{ display: "flex" }}
            className={classes.MainBackground}>
            <Grid
              lg={9}
              md={9}
              sm={9}
              xl={9}
              xs={9}
              style={{
                margin: "10px",
                paddingRight: "15px",
                // backgroundColor: "#F4F5F8",
              }}
            >
              <div
              // style={{ marginTop: "20px" }}
              >
                {show ? (
                  <>
                    <div
                      style={{ marginLeft: "10px" }}>
                      <Typography style={{ fontWeight: 500 }} variant='inherit'>
                        Cart Orders ({data?.Json?.length})
                      </Typography>
                    </div>
                    {data?.Json.map((val) => (
                      <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                        <div style={{ marginLeft: "18px" }}>
                          <AlertTagsForCart val={val} />
                        </div>
                        <OrderCartTable val={val} />
                      </div>
                    ))}
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80vh",
                    }}
                  >
                    <div>
                      <ItemSvg />
                      <Typography style={{ textAlign: "center" }}>
                        No Items
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </Grid>

            <Grid item xs={3} style={{ borderLeft: "1px solid #cccccc4a", }}>
              <Cartdetails
                data={data}
                OrderData={data?.Json}
                Json={{
                  detail: data?.cartName ?? [],
                  issue: data?.subStore ?? [],
                  cart: data?.Orgname ?? '',
                  ward: data?.ward ?? [],
                  date: (data?.startDate || data?.endDate) ? `${moment.unix(data?.startDate).format('DD-MM-YYYY h:mm')} to ${moment.unix(data?.endDate).format('DD-MM-YYYY h:mm')}` : '',
                }}
                ward={ward}
                setWard={setWard}
                handleState={handleState}
                handleChange={handleChange}
                handleOpenCart={handleOpenCart}
              />
            </Grid>
          </div>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
};

export default withAllContexts(withRouter(translate()(CartOrder)));
