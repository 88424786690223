import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
  },
  defaultText: {
    whiteSpace: "nowrap",
    lineHeight: 1,
  },
}));

function TypographyWithIcon({
  children,
  startAdornment,
  endAdornment,
  className,
  ...props
}) {
  // <---------------Hooks------------------>
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...props}>
      {startAdornment}
      <Typography variant="body2" className={classes.defaultText}>
        {children}
      </Typography>
      {endAdornment}
    </div>
  );
}

export default TypographyWithIcon;

TypographyWithIcon.defaultProps = {
  startAdornment: null,
  endAdornment: null,
  className: {},
};

TypographyWithIcon.propTypes = {
  children: PropTypes.node.isRequired,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  className: PropTypes.object,
};
