import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { DispenseDurationOptions } from "../../../../../utils";
import { useStyles } from "./styles";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import { DownwardIcon } from "../../../../../assets";
import PropTypes from "prop-types";

const SimpleInput = withStyles((theme) => ({
  root: {
    flex: 0,
    "& .App1-MuiSelect-root": {
      fontSize: 14,
      
    },
    "& .App1-MuiSelect-icon": {
      top: "unset",
    },
    "& .App1-Mui-disabled": {
      color: "black",
    },
  },
  input: {
    padding: 0,
    "&:focus": {
      backgroundColor: "unset",
    },
  },
}))(InputBase);

function TextSelectField(props) {
  const {
    inputValue,
    onInputValueChange,
    selectValue,
    onSelectValueChange,
    options,
    disabled,
  } = props;

  // Styles
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SimpleInput
        disabled={disabled}
        id={"input"}
        placeholder="00"
        type={"text"}
        value={inputValue}
        onChange={(e) => onInputValueChange(e)}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        inputProps={{ "aria-label": "Input field" }}
        style={{ minWidth: 20 }}
      />
      <Select
        id="select"
        value={selectValue ? selectValue : options[0]?.value ?? null}
        onChange={(e) => onSelectValueChange(e)}
        input={<SimpleInput />}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        IconComponent={DownwardIcon}
      >
        {options.map((option) => (
          <MenuItem value={option?.value}>{option?.label}</MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default TextSelectField;

TextSelectField.propTypes = {
  inputValue: PropTypes.string,
  onInputValueChange: PropTypes.func,
  selectValue: PropTypes.string,
  onSelectValueChange: PropTypes.func,
  options: PropTypes.array,
};
TextSelectField.defaultProps = {
  inputValue: "",
  onInputValueChange: () => {},
  selectValue: null,
  onSelectValueChange: () => {},
  options: [],
};
