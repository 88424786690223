/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import DiagnosisTreeView from "./treeList";
import { GenerateForm } from "../../../../components/common";
import { useDispatch } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import moment from "moment";
import { AlertProps } from "../../../../utils";
import { withAllContexts } from "../../../../HOCs";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // margin: 20,
    borderRadius: 10,
    background: theme.palette.background.table
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    // fontSize: 18,
    fontSize: "16px",
    fontFamily: "poppinsemibold",
  },
  pos: {
    marginBottom: 12,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha("#00000009", 0.04),
    "&:hover": {
      backgroundColor: alpha("#00000008", 0.1),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#6F6F6F",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  headerStyles: {
    padding: "12px 16px",
    borderBottom: "1px solid #1010100D",
  },
  mainTreeArea: {
    padding: 22,
    paddingLeft: 40,
    height: "calc(100vh - 200px)",
    overflow: "auto",
  },
}));

// const data = [
//   {
//       label: "A00-B99 Certain infectious and parasitic diseases",
//       nodeId: "A00-B99",
//       startDate: "12-12-2021",
//       endDate: "12-12-2022",
//       children: [
//           {
//               label: "A00-A09 Intestinal infectious diseases",
//               nodeId: "A00-A09",
//               startDate: "12-12-2021",
//               endDate: "12-12-2022",
//               children: [
//                 {
//                     label: "A00 Cholera",
//                     nodeId: "A00",
//                     startDate: "12-12-2021",
//                     endDate: "12-12-2022",
//                     children: [
//                       {
//                           label: "A01.00 Typhoid fever, unspecified",
//                           nodeId: "A01.00",
//                           startDate: "12-12-2021",
//                           endDate: "12-12-2022",
//                       },
//                       {
//                         label: "A01.01 Typhoid meningitis",
//                         nodeId: "A01.01",
//                         startDate: "12-12-2021",
//                         endDate: "12-12-2022",
//                       },
//                       {
//                         label: "A01.02 Typhoid fever with heart involvement",
//                         nodeId: "A01.02",
//                         startDate: "12-12-2021",
//                         endDate: "12-12-2022",
//                       },
//                       {
//                         label: "A01.03 Typhoid pneumonia",
//                         nodeId: "A01.03",
//                         startDate: "12-12-2021",
//                         endDate: "12-12-2022",
//                       },
//                       {
//                         label: "A01.04 Typhoid arthritis",
//                         nodeId: "A01.04",
//                         startDate: "12-12-2021",
//                         endDate: "12-12-2022",
//                       }
//                     ]
//                 }
//               ]
//           },
//           {
//             label: "A01 | Typhoid and paratyphoid fevers",
//             nodeId: "A01",
//             startDate: "12-12-2021",
//             endDate: "12-12-2022",
//           },
//           {
//             label: "A02 Other salmonella infections",
//             nodeId: "A02",
//             startDate: "12-12-2021",
//             endDate: "12-12-2022",
//           }
//       ]
//   },
//   {
//       label: "A00-B100 Certain infectious and parasitic diseases",
//       nodeId: "A00-B100",
//       startDate: "12-12-2021",
//       endDate: "12-12-2022",
//   }
// ]

function DiagnosisMasterCard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    open: false,
    edit_data: null,
  });

  const [list, setlist] = React.useState({
    data: null,
    loading: true,
    activeNode: [],
  });

  const editList = (editData) => {
    let dataEdit = {
      _key: editData?._key,
      term_code: editData?.termcode,
      release_year: editData?.relYear,
      diagnosis_code: editData?.icdcode,
      short_description: editData?.icdshortname,
      long_description: editData?.icdname,
      parent_code: editData?.parenticdcode ?? "",
      level: editData?.levelnumber,
      effective_from: editData?.effFrom ? moment.unix(editData?.effFrom) : null,
      effective_to: editData?.effTo ? moment.unix(editData?.effTo) : null,
    };
    setState({
      ...state,
      open: true,
      edit_data: dataEdit,
    });
  };

  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      edit_data: null,
    });
  };

  // get initial data
  const getdata = async () => {
    let data = await dispatch(actions.GET_DIAGNOSIS_LIST({ level: -1 }));
    let dataUpdate = JSON.parse(JSON.stringify(data?.payload?.data));
    dataUpdate?.length > 0 &&
      dataUpdate?.map((val) => {
        val["child"] = [{}];
      });
    // setlist(dataUpdate);
    setlist({
      ...list,
      data: dataUpdate,
      loading: false,
    });
  };

  React.useEffect(() => {
    // GET_DIAGNOSIS_LIST
    getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let ids = null;
  const returnId = (dataList, key) => {
    dataList.map((val) => {
      if (val?._key === key) {
        ids = val;
      } else {
        if (val?.child?.length > 0) {
          returnId(val.child, key);
        }
      }
    });
  };

  const updateChild = async (data, index) => {
    if (!data || data?.split("-")[0] === "no_data") {
      setlist({
        ...list,
        activeNode: [],
      });
      return false;
    }
    if (data) {
      // let keys = list[index].diagnosis?.levelnumber+1
      let testRun = returnId(list?.data, data?.split("-")[0])?.levelnumber + 1;
      let keys = ids?.levelnumber;

      let dataCh = await dispatch(
        actions.GET_DIAGNOSIS_LIST_CHILD({ code: ids?.icdcode, level: keys })
      );
      let updateChildtree = JSON.parse(JSON.stringify(dataCh?.payload?.data));
      updateChildtree?.map((val) => {
        val["child"] = [{}];
      });

      const restFun = (dataList) => {
        dataList.map((val) => {
          if (val?._key === data?.split("-")[0]) {
            // val["child"] = updateChildtree;
            if (updateChildtree?.length > 0) {
              val["child"] = updateChildtree;
            } else {
              val["child"] = [
                {
                  _key: "no_data",
                  icdcode: "",
                  icdname: "No Data Found at this Level",
                  termcode: "",
                },
              ];
            }
          } else {
            if (val?.child?.length > 0) {
              restFun(val.child);
            }
          }
        });
      };

      restFun(list?.data);
      let update = JSON.parse(JSON.stringify(list?.data));
      // setlist(update)
      list?.activeNode?.push(data);
      setlist({
        ...list,
        data: update,
        loading: false,
      });
    }
  };

  const updateDiagnosisLis = async (data) => {
    let updateData = {
      key: state?.edit_data?._key,
      effTo: moment(data?.effective_to).unix(),
    };
    // UPDATE_DIAGNOSIS_LIST
    let resData = await dispatch(actions.UPDATE_DIAGNOSIS_LIST(updateData));

    if (resData?.payload?.data?.length > 0) {
      const restFun = (dataList) => {
        dataList.map((val) => {
          if (val?._key === state?.edit_data?._key) {
            val["effTo"] = moment(data?.effective_to).unix();
          } else {
            if (val?.child?.length > 0) {
              restFun(val.child);
            }
          }
        });
      };

      restFun(list?.data);
      let update = JSON.parse(JSON.stringify(list?.data));
      // setlist(update)
      setlist({
        ...list,
        data: update,
        loading: false,
      });

      // Alert message
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: AlertProps.message.update,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });

      setState({
        ...state,
        open: false,
        edit_data: null,
      });
    }
  };

  const searchList = async (e) => {
    let text = e.target.value;
    if (text?.length < 3) {
      if (text?.length === 0) {
        getdata();
        return false;
      }
      return false;
    }
    setlist({
      ...list,
      loading: true,
    });
    // SEARCH_LIST
    let searchData = await dispatch(actions.SEARCH_LIST({ text: text }));
    if (searchData?.payload?.data) {
      let searchUpdate = JSON.parse(JSON.stringify(searchData?.payload?.data));
      searchUpdate?.length > 0 &&
        searchUpdate?.map((val) => {
          val["child"] = [{}];
        });
      setlist({
        ...list,
        data: searchUpdate,
        loading: false,
        activeNode: [],
      });
      // setlist(searchUpdate);
    }
  };

  return (
    <Card className={classes.root}>
      {/* header */}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.headerStyles}
      >
        {/* titile */}
        <Typography
          variant={"h6"}
          className={classes.title}
          color={"textPrimary"}
        >
          Diagnosis master
        </Typography>
        {/* search  */}
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => searchList(e)}
          />
        </div>
      </Grid>

      <CardContent className={classes.mainTreeArea}>
        <DiagnosisTreeView
          data={list?.data}
          editList={editList}
          updateChild={updateChild}
          loading={list?.loading}
          activeNode={list?.activeNode}
        />
      </CardContent>

      {/* Edite List */}
      <GenerateForm
        parent_id={"practitionerrole"}
        nocancel
        // loading={saveloading}
        open={state.open}
        save={updateDiagnosisLis}
        handlecancel={handleDrawerClose}
        header={"Edit Diagnosis"}
        json={[
          {
            componet_type: "text",
            label: "Term Code",
            state_name: "term_code",
            value: state?.edit_data?.term_code ?? "",
            disabled: true,
            required: true,
            grid: { md: 6 },
          },
          {
            componet_type: "text",
            label: "Release Year",
            state_name: "release_year",
            value: state?.edit_data?.release_year ?? "",
            disabled: true,
            required: true,
            grid: { md: 6 },
          },
          {
            componet_type: "text",
            label: "Diagnosis Code",
            state_name: "diagnosis_code",
            value: state?.edit_data?.diagnosis_code ?? "",
            disabled: true,
            required: true,
          },
          {
            componet_type: "text",
            label: "Short Description",
            state_name: "short_description",
            value: state?.edit_data?.short_description ?? "",
            disabled: true,
            required: true,
          },
          {
            componet_type: "text",
            label: "Long Description",
            state_name: "long_description",
            value: state?.edit_data?.long_description ?? "",
            disabled: true,
            required: true,
          },
          {
            componet_type: "text",
            label: "Parent Code",
            state_name: "parent_code",
            value: state?.edit_data?.parent_code ?? "",
            disabled: true,
            grid: { md: 6 },
          },
          {
            componet_type: "text",
            label: "Level",
            state_name: "level",
            value: state?.edit_data?.level ?? "",
            disabled: true,
            grid: { md: 6 },
          },
          {
            componet_type: "date",
            label: "Effective From",
            state_name: "effective_from",
            value: state?.edit_data?.effective_from ?? "",
            disabled: true,
            dateFormat: "dd/MM/yyyy",
          },
          {
            componet_type: "date",
            label: "Effective To",
            state_name: "effective_to",
            value: state?.edit_data?.effective_to ?? "",
            dateFormat: "dd/MM/yyyy",
            minDateState: "effective_from",
          },
        ]}
      />
    </Card>
  );
}
export default withAllContexts(DiagnosisMasterCard);
