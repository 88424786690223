import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CareTypeTable from "./caretypetable";
import LevelofCareTable from "./levelofcaretable";
import HealthCareServiceTable from "./healthcareservicetable";
//import SpecialityTable from "./specialitytable";
import { CommonTabList } from "../../components/common";
import "../../components/font.css";
//import { checkWithCasbin } from "../../utils"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,

    "& .App1-MuiTab-textColorPrimary": {
      "&.Mui-selected": {
        color: "#0071F2",
      },
    },
    "&.App1-MuiPaper-elevation4": {
      shadow: "none",
    },
    "& .App1-MuiTab-root": {
      [theme.breakpoints.up("xs")]: {
        minWidth: "inherit !important",
      },
    },
    "& .App1-MuiAppBar-root": {
      width: "80%",
    },
  },
  MuiTab: {
    root: {
      minWidth: 0,
      "@media (min-width: 0px)": {
        minWidth: 0,
      },
    },
  },
  box: {
    padding: "24px",
    paddingBottom: "43px",
    backgroundColor: "#F6F6F6",
    // height: "100vh",
    height: "100%",
    paddingTop: "14px",
  },
}));
function OrganizationConfigTabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.box}>
          <Grid>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

OrganizationConfigTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();

  const [listData, setListData] = React.useState([]);

  const json = {
    careType: {
      name: "Care Type",
      component: (
        <CareTypeTable
          parent_id={"CareTypeTable"}
          type={"CARETYPE"}
          header={"Care Type"}
        />
      ),
    },
    levelofCare: {
      name: "Level of Care",
      component: (
        <LevelofCareTable
          parent_id={"LevelofCareTable"}
          type={"LEVELOFCARE"}
          header={"Level of Care"}
        />
      ),
    },
    // {
    //   name: "Speciality",
    //   component: <SpecialityTable type={"SPECIALITY"} header={"Speciality"} />
    // },
    healthCareService: {
      name: "Health Care Service",
      component: (
        <HealthCareServiceTable
          parent_id={"HealthCareServiceTable"}
          type={"HEALTHCARESERVICE"}
          header={"Health Care Service"}
        />
      ),
    },
  };
  React.useEffect(() => {
    const baseFun = async () => {
      // let permission = await checkWithCasbin(["OrganizationConfig"]);
      // function onlyUnique(value, index, self) {
      //   return self.indexOf(value) === index;
      // }
      let arr = [];
      // var unique = [...permission.read, ...permission.write].filter(onlyUnique);
      ["careType", "levelofCare", "healthCareService"].forEach((val) => {
        arr.push(json[val]);
      });
      setListData(arr);
    };
    baseFun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid id={"Organization-Config-parent-grid"} container spacing={0}>
      <Grid id={"Organization-Config-parent-sub-grid"} item md={12}>
        <div id={"Organization-Config-parent-div"} className={classes.root}>
          <CommonTabList
            parent_id={"CommonTabList"}
            title="Organization Config"
            list={listData}
          />
        </div>
      </Grid>
    </Grid>
  );
}
