import React from "react";
import PropTypes from "prop-types";
import {
  Div,
  Paper,
  H6,
  //TextInput,
  Divider,
  Row,
  Col,
  Image,
  //Select,
} from "qdm-component-library";
import "./navBar.css";
import { withRouter } from "react-router-dom";
import { Popover, makeStyles, useTheme } from "@material-ui/core";
import UploadFiles from "../addPractotioner/uploadFiles";
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import { InputAdornment, TextField } from "@material-ui/core";
import { useHistory } from "react-router";
import { Routes } from "../../../router/routes";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

const useStyles = makeStyles((theme) => ({
  muiPopovers: {
    boxShadow: "0px 25px 50px #00000029",
    borderRadius: 8,
    marginTop: 10,
    padding: 10,
  },
  labelStyle: {
    // color: '#6F6F6F',
    // fontSize: '12px',
    // marginBottom: '6px',
    // fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#fff",
    border: "none",
  },
  heigthinput: {
    border: "blue",
    "& input": {
      height: 0,
      color: "#101010",
    },
  },
  entity: {
    width: "45% ",
    marginTop: "10px",
    color: "#6F6F6F",
    marginLeft: "8px",
  },
}));

//const optiondata = [{ value: "Facility" }];
export const Component = (props) => {
  const theme = useTheme()
  const {
    navBarArr = [],
    title,
    //activeId = 1,
    //navClick = () => null,
    //putOnHold,
  } = props;
  const [isUpload, setIsUpload] = React.useState(null);
  const [
    //files, 
    setFiles] = React.useState([]);
  const { parent_id } = props;
  //const [val, setval] = React.useState([]);
  // const redirectLink = (data) => {
  //   navClick(data.id);
  // };
  const uploadFun = (e) => {
    setIsUpload(e?.currentTarget);
  };
  const goback = () => {
    if (props?.backHandle) {
      props.backHandle();
    } else {
      history.push(Routes.practitionerMaster);
    }
  };
  const uploadDocument = (files) => {
    setFiles(files);
  };
  const history = useHistory();
  const classes = useStyles();
  return (
    <div
      id={`${parent_id}-parent-div`}
      style={{ height: "56px" }}>
      <Paper
        id={`${parent_id}-parent-paper`}
        style={{
          margin: "0px",
          position: "fixed",
          zIndex: "1",
          // background: "white",
          background: theme.palette.background.table,
          boxShadow: "0px 3px 6px #0000000A",
        }}
      >
        <Row
          id={`${parent_id}-parent-row`}
          className={
            navBarArr.length > 0 ? "topNav-top-Parent" : "topNav-top-Parent1"
          }
        >
          <Col
            id={`${parent_id}-uploadIcon-col`}
          >
            <Div
              id={`${parent_id}-uploadIcon-div`}
              className={"topNav-parent"}>
              {/* <i onClick={() => goback()} className="fa fa-angle-left topNav-backIcon" aria-hidden="true"></i> */}
              <Div
                id={`${parent_id}-uploadIcon-left-div`}
                className="uploadIcon"
                style={{ backgroundColor: "#DCDCDC" }}
                onClick={() => goback()}
              >
                {/* <Image
                  id={`${parent_id}-uploadIcon-left-image`}
                  src="images/icons8-left.svg" alt="left" /> */}
                   <ArrowBackIosIcon
                        style={{ height: "20px", width: "20px",marginLeft:"8px" }}
                      />
              </Div>
              <H6
                id={`${parent_id}-${title}-h6`}
                className={"topNav-title"} style={{color: theme.palette.text.primary}}>{title}</H6>
              {navBarArr.length > 0 ? (
                <Divider
                  id={`${parent_id}-${title}-divider`}
                  className={"topNav-divider"} orientation="vertical" />
              ) : (
                <></>
              )}
            </Div>
          </Col>
          {/* <Col>
                {navBarArr.map((val, i) => {
                    return <p onClick={() => redirectLink(val)} className={`${activeId === val.id ? "topNav-active" : ""} topNav-tabs`}>{val.label}
                        {activeId === val.id && <Div className="bottomDivider"></Div>}
                    </p>
                })}
            </Col> */}
          {/* <Col>
            <Div
              style={{
                display: "flex",
                border: "1px solid#E0E0E0",
                height: "42px",
                borderRadius: "5px",
                width:""
              }}
            >
              <h5 className={classes.entity}>Entity Type :</h5>{" "}
              <Select
                options={optiondata}
                label={false}
                variant="none"
                onChangeValue={(e) => this.setDetails("entity_type", e)}
                showArrow
                placeholder="select..."
                labelStyle={classes.labelStyle}
                inLineStyles={classes.borderStyle}
                hoverColor="#0071F2"
              />
            </Div>
          </Col>
          <Col>
            <Div
              style={{
                display: "flex",
                border: "1px solid#E0E0E0",
                height: "42px",
                borderRadius: "5px",
              }}
            >
              <h5 className={classes.entity}>Entity Name :</h5>
              <Select
                options={optiondata}
                label={false}
                variant="none"
                onChangeValue={(e) => this.setDetails("entity_Name", e)}
                showArrow
                placeholder="select..."
                labelStyle={classes.labelStyle}
                inLineStyles={classes.borderStyle}
                hoverColor="#0071F2"
              />
            </Div>
          </Col> */}
        </Row>
        <Popover
          anchorEl={isUpload}
          open={Boolean(isUpload)}
          onClose={() => uploadFun(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          style={{ zIndex: 99999 }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: classes.muiPopovers }}
        >
          <UploadFiles
            id={`${parent_id}-${title}-Uploadfiles`}
            uploadDocument={uploadDocument} />
        </Popover>
      </Paper>
    </div>
  );
};

Component.propTypes = {
  title: PropTypes.string,
  withBackButton: PropTypes.bool,
  navBarArr: PropTypes.array,
  titleIcon: PropTypes.element,
  onTitleIconClicked: PropTypes.func,
};

Component.defaultProps = {
  withBackButton: false,
};

const TopNavbar = (props) => <Component {...props} />;

export default withRouter(TopNavbar);
