import React from "react";
import { withRouter } from "react-router-dom";
import {
  TimeFormatConversion,
  getFormatForDateTimePicker,
} from "../../utils/helperFunctions";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";

function TimeComponent({ dateTime, formatType = "date", separator,timeZone, ...props }) {
  return (
    <Typography {...props}>
      {dateTime && (
        <>
          {Array.isArray(dateTime)
            ? dateTime
                .map((date) =>
                  TimeFormatConversion(
                    date,
                    getFormatForDateTimePicker(formatType),
                    timeZone
                  )
                )
                .join(separator)
            : TimeFormatConversion(
                dateTime,
                getFormatForDateTimePicker(formatType),
                timeZone
              )}
        </>
      )}
    </Typography>
  );
}

export default withRouter(TimeComponent);

TimeComponent.propTypes = {
  dateTime: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  formatType: PropTypes.string,
};
