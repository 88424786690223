import React, { useRef, useLayoutEffect, useState } from "react";
import { InfoIcon, ReplyArrowIcon } from "../../../../assets";
import { ReactComponent as ScheduleIcon } from "../../../../assets/scheduleIcon.svg";
import { ReactComponent as ForwardIconSmall } from "../../../../assets/forwarIconSmall.svg";
import { ReactComponent as CollapseIcon } from "../../../../assets/collapse.svg";
import { ReactComponent as ExpandIcon } from "../../../../assets/expand.svg";
import { Popover as CustomPopover } from "../../../";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { useDividerStyles, useDividerStyles2 } from "./styles";
import {
  Dialog,
  DialogContent,
  IconButton,
  SvgIcon,
  Tooltip,
  Button,
  CardContent,
  CardHeader,
  Avatar,
  TextField,
  Card,
  InputBase,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Collapse from "@material-ui/core/Collapse";
import DrugBrandTableForReturnMedication from "./drugBrandTableForReturnMedication";
import TextSelectField from "./textSelectField";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  DispenseDurationOptions,
  makeName,
  TimeFormatConversion,
} from "../../../../utils";
import { Autocomplete as CustomAutocomplete, CustomButton } from "../../../";
import moment from "moment";
import OrderInfo from "./orderInfo";
import CloseIcon from "@material-ui/icons/Close";
import BasicTable from "./frequencySchedule";
import { Close, Tick } from "../../../../assets";
import { Autocomplete } from "@material-ui/lab";
import DrugAdditiveCard from "./drugAdditiveCard";
import OrderInfoIV from "./orderInfoIV";
import MedicationForm from "./medicationEdit";
import Config from "../../../../config";
import { actions } from "frequencyscreen_v_dbinder";
import { useDispatch } from "react-redux";
import Popover from "@material-ui/core/Popover";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import TimeComponent from "../../../timeComponent";
import { getUnixTime } from "date-fns";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ReasonMaster from "../../../reasonMaster";

const useDotStyles = makeStyles({
  root: {
    height: 4,
    width: 4,
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    margin: "0px 6px",
  },
});

const Dot = () => {
  const classes = useDotStyles();
  return <div className={classes.root}></div>;
};

const useStyles = makeStyles((theme) => ({
  selectOrder: {
    color: `${theme.palette.primary.main} !important`,
    marginBottom: "-5px",
  },
  dialogPaper: {
    minWidth: "871px",
    maxWidth: "871px",
    maxHeight: "100%",
  },
  container: {
    backgroundColor: "white",
    boxShadow: "0px 10px 25px #0000000A",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5, 1.5, 1.5, 2),
    overflow: "hidden",
  },
  root: {
    display: "flex",
    alignItems: "center",
    maxHeight: 44,
    gap: 24,
  },
  drugFlexContainer: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    // width: 600, // Make this div adjust to the width of the right side element using refs - Assigned inline style
    "&:last-child": {
      minWidth: 0,
    },
  },
  drugIvInfoContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 4,
  },
  name: {
    maxWidth: 200,
    minWidth: "20px",
    fontSize: 14,
    fontWeight: 500,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginRight: theme.spacing(1),
  },
  particularText: {
    fontSize: 12,
  },
  info: {
    fontSize: 12,
    color: "#001C3C",
    fontWeight: 500,
  },
  doctorName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  drugDosageContainer: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    flex: 0,
  },
  doseFieldContainer: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    "& p": {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  doseInput: {
    // width: 115,
    flex: 0,
  },
  iconContainer: {
    backgroundColor: "rgb(42, 96, 188)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    height: 20,
    width: 20,
    minWidth: 20,
    cursor: "pointer",
  },
  closeIconContainer: {
    backgroundColor: "#FF4D4A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    height: 20,
    width: 20,
    minWidth: 20,
    cursor: "pointer",
  },
  drugBrandGutter: {
    marginTop: theme.spacing(1.5),
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
  button: {
    color: "black",
    backgroundColor: "#DEE5EC",
    height: "30px",
    borderRadius: "8px",
    marginLeft: "6px",
    fontSize: "12px",
    "& .App1-MuiButton-root": {
      textTransform: "none",
    },
  },
  btngroup: {
    "& .App1-MuiButton-root": {
      textTransform: "none",
    },
  },
  paper: {
    borderRadius: "8px",
  },
  header: {
    padding: "10px",
    "& .App1-MuiCardHeader-content": {
      "& .App1-MuiCardHeader-subheader": {
        color: "black",
      },
    },
  },
  content: {
    padding: "10px",
    paddingBottom: "15px",
  },
  additiveHeaderContainer: {
    position: "relative",
    margin: "12px 0px 10px 0px",
    display: "flex",
  },
  additiveHeaderContent: {
    display: "flex",
    alignItems: "center",
    padding: "0px 4px 0px 2px",
    marginLeft: 4,
    background: "white",
    zIndex: 1,
  },
  additiveHeaderText: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#2A3C50",
  },
  expandCollapseButton: {
    zIndex: 1,
  },
  additiveHeaderLine: {
    position: "absolute",
    left: "0px",
    right: "0px",
    alignSelf: "center",
    margin: "0px -50px",
    border: "0px",
    borderBottom: "1px solid #DEE5EC",
  },
  stripQuantityContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    // padding: "6px 12px",
  },
  doseInputLabelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    border: "1px solid #DEE5EC",
    borderRadius: "8px",
    padding: "5px",
    "& > p": {
      lineHeight: 1,
      fontSize: 14,
      fontWeight: 500,
    },
  },
  reasonPaper: {
    padding: 12,
    display: "flex",
    flexDirection: "column",
    gap: 12,
    width: "360px",
  },
  reasonTitle: {
    color: "#001C3C",
    fontSize: 12,
    fontWeight: 500,
  },
  reasonFieldContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  reasonFieldLabel: {
    fontSize: 12,
  },
  reasonButton: {
    width: "fit-content",
    marginLeft: "auto",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: "12px",
    top: "12px",
    color: theme.palette.grey[500],
    backgroundColor: "#DEE5EC",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6">{children}</Typography>
      </div>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={props?.onClose}
        size={"small"}
        style={{ marginLeft: "20px" }}
      >
        <CloseIcon style={{ color: "#01205C", fontSize: "16px" }} />
      </IconButton>
    </MuiDialogTitle>
  );
});

function DrugCardForReturnMedication({
  drug,
  dispenseDuration,
  onDispenseDurationChange,
  dispenseFrequency,
  onDispenseFrequencyChange,
  isDispenseDurationApplied,
  onApplyDispenseDurationClick,
  isIntravenous = false,
  ...props
}) {
  // Hooks
  const theme = useTheme();
  const dispatch = useDispatch();
  const rootRef = useRef(null);

  // Styles
  const classes = useStyles();
  const dividerStyles = useDividerStyles();
  const dividerstyles2 = useDividerStyles2();

  // States
  const [needToBeWidth, setNeedToBeWidth] = React.useState(200);
  const [orderInfo, setOrderInfo] = React.useState(null);
  const [orderValue, setOrdervalue] = React.useState(null);
  const [totalOrder, setTotalOrder] = React.useState(null);
  const [doctorName, setDoctorName] = React.useState(null);
  const [Arrow, setArrow] = React.useState(null);
  const [schedule, setSchedule] = React.useState(null);
  const [isAdditiveExpanded, setIsAdditiveExpanded] = React.useState(false);
  const [reasonData, setReasonData] = React.useState([]);
  const [reIssuereasonData, setreIssuereasonData] = React.useState([]);

  const actionState = usePopupState({
    variant: "popover",
    popupId: "actionPopover",
  });

  const onAdditiveExpandCollapseChange = () => {
    setIsAdditiveExpanded((prev) => !prev);
  };

  const handleOrderinfo = (event, drug) => {
    setOrderInfo(true);
    setOrdervalue(drug?.orderinfo);
    setTotalOrder(drug);
  };
  const OrderInfoClose = () => {
    setOrderInfo(false);
  };
  // const open = Boolean(orderInfo);
  // const id = open ? 'simple-popover' : undefined;
  // Doctor name..........................
  const open = Boolean(doctorName);
  const handleDoctorName = (event) => {
    //
    setDoctorName(event.currentTarget);
  };
  const DoctorClose = () => {
    setDoctorName(null);
  };
  const id = open ? "simple-popover" : undefined;

  // arrow popover
  const openArrow = Boolean(Arrow);
  const idArrow = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setArrow(event.currentTarget);
  };

  const handleClose = () => {
    setArrow(null);
  };
  // Schedule popover

  const openSchedule = Boolean(schedule);
  const idFrq = open ? "simple-popover" : undefined;
  const handleClickSchedule = (event) => {
    setSchedule(event.currentTarget);
  };
  const handleCloseschedule = () => {
    setSchedule(null);
  };

  // Use Layout Effect to set the width of the left side container.
  useLayoutEffect(() => {
    if (!rootRef.current) return;

    const calculateRemainingContainerWidth = () => {
      const parent = rootRef.current;
      const needToRemoveWidthChild = parent.querySelector(
        "#drugDosageContainer"
      );

      const parentWidth = window
        .getComputedStyle(parent)
        .getPropertyValue("width");
      const childWidth = window
        .getComputedStyle(needToRemoveWidthChild)
        .getPropertyValue("width");

      const parentWidthNum = parseFloat(parentWidth.replace("px", ""));
      const childWidthNum = parseFloat(childWidth.replace("px", ""));

      const remainingWidth = parentWidthNum - childWidthNum;
      return remainingWidth;
    };

    setNeedToBeWidth(calculateRemainingContainerWidth());
  });

  React.useEffect(() => {
    isaddfuncy(drug);
    isaddfuncyOnclick(drug);
    // await this.props.MEDICATION_REASON_DROPDOWN({
    //   drugId: "DrugMaster/143241843",
    // });
  }, [drug]);

  const getReason = async () => {
    let ccMaster = await dispatch(
      actions.MEDICATION_REASON_DROPDOWN({
        drugId: drug?.drugCode,
      })
    );

    let reIssueMaster = await dispatch(actions.REISSUE_REASON_DROPDOWN());

    setReasonData(ccMaster?.payload?.data);
    setreIssuereasonData(reIssueMaster?.payload?.data);
  };

  React.useEffect(() => {
    getReason();
  }, []);

  const isaddfuncyOnclick = (drug) => {
    let Split = drug?.dosageUOM?.split("/");
    if (Split?.length > 1) {
      return true;
    } else {
      var num = 0;
      const a = (drug?.brands || [])?.forEach((v) => {
        if (v?.dosageValue) {
          num = num + v.dosageValue;
        }
      });
      return num === drug?.dosageValue ? true : false;
    }
  };

  const isaddfuncy = (drug) => {
    var num = 0;
    const a = (drug?.brands || [])?.forEach((v) => {
      if (v.dosageValue) {
        num = num + v.dosageValue;
      }
    });
    return num < drug?.dosageValue ? true : false;
  };

  const [expanded, setExpanded] = React.useState(false);
  const [action, setAction] = React.useState(null);

  const handleExpandClick = (val) => {
    setAction(val);
    setExpanded(false);
    if (val?.action === "DISCONTIUNE") {
      props.handleDiscontinue(val);
      setExpanded(false);
      return;
    }
    setExpanded(true);
  };

  const getDrugName = (drug) => {
    let name = [];
    drug.brands.forEach((v) => {
      name.push(v.name);
    });
    drug.additives.forEach((v) => {
      v.brands.forEach((b) => {
        name.push(b.name);
      });
    });
    return name;
  };

  const getDosage = (drug) => {
    let dosage = [];
    drug.brands.forEach((v) => {
      dosage.push(v.dosageValue);
    });
    drug.additives.forEach((v) => {
      v.brands.forEach((b) => {
        dosage.push(b.dosageValue);
      });
    });
    return dosage;
  };
  const getUOM = (drug) => {
    let uom = [];
    drug.brands.forEach((v) => {
      let UOM = v.dispuomtext ? v.dispuomtext : v.dosageUOM;
      uom.push(UOM);
    });
    drug.additives.forEach((v) => {
      v.brands.forEach((b) => {
        let UOM = b.dispuomtext ? b.dispuomtext : b.dosageUOM;
        uom.push(UOM);
      });
    });
    return uom;
  };

  const printLabel = async (drug, index, brand) => {
    
    let date = JSON.parse(localStorage.getItem("orgFormat"));
    let compoundDrugPayload = {
      reportid: process.env.REACT_APP_COMPOUND_PRINT_LABEL, //"d8e16b3d-76f0-4eea-bf81-a58732c62c4e",
      inputparams: {
        "@orderID": drug?._id,
        "@datetimeformat":
          "%" +
          date?.orgdatetimeformat?.display
            ?.replaceAll("/", "/%")
            ?.replaceAll(" ", " %")
            ?.replaceAll("mm", "%ii"),
      },
      result: [],
    };
    let type = drug?.additives?.length > 0 ? "IV" : "Brands";
    // Labelpayload........................

    let date_label = JSON.parse(localStorage.getItem("orgFormat"));
    const Labelpayload = {
      reportid: process.env.REACT_APP_OP_LABEL_PRINT_ID,

      inputparams: {},
      result: [
        {
          Mandatory: {
            drugType: drug?.drugType?.longdesc ?? drug?.drugType?.shortdesc ?? '',
            name: drug?.encounter_Id[0]?.patient_id[0].name[0]
              ? makeName(drug?.encounter_Id[0]?.patient_id[0].name[0])
              : "",
            mrn: drug?.encounter_Id[0]?.patient_id[0].alias
              ? drug?.encounter_Id[0]?.patient_id[0].alias
              : "",
            qty: brand?.batches?.[0]?.chargeqty || brand?.totadmqty || "",
            qtyuom: brand?.batches?.[0]?.chargeuom || brand?.totadmuom || "",
            batchno: brand?.batches?.[0]?.batchno ?? "",

            expiry: brand?.batches?.[0]?.expdate
              ? moment(brand?.batches?.[0]?.expdate).format(
                date_label?.orgdateformat?.display || "DD/MM/YYYY"
              )
              : brand?.batches?.[0]?.expirydate
                ? moment(brand?.batches?.[0]?.expirydate).format(
                  date_label?.orgdateformat?.display || "DD/MM/YYYY"
                )
                : "",
            date: moment(new Date()).format(
              date_label?.orgdatetimeformat?.display || "DD/MM/YYYY HH:mm"
            ),
            medicationname: brand?.name ? brand?.name : "",
            ControlledMedicine: true,
            hospname: drug?.encounter_Id?.[0]?.practitioner_details?.[0]?.clinic
              ? drug?.encounter_Id?.[0]?.practitioner_details?.[0]?.clinic
              : "",
            medicationorderdetails: drug?.medicationorderdetails ?? "",
            prescno: drug?.prescno ?? "",
            ward: drug?.ward ?? "",
            logo: drug?.logo ?? "",
            hospaddress: drug?.hospaddress ?? "",
            telecom: drug?.telecom ?? [],
            qrcode:
              (drug?.encounter_Id[0]?.patient_id[0].name[0]
                ? makeName(drug?.encounter_Id[0]?.patient_id[0].name[0])
                : "") +
              (drug?.encounter_Id[0]?.patient_id[0].alias
                ? drug?.encounter_Id[0]?.patient_id[0].alias
                : "") +
              (brand?.name ? brand?.name : "") +
              (brand?.totadmqty ? brand?.totadmqty : "") +
              (brand?.totadmuom ? brand?.totadmuom : "") +
              (drug?.ward ?? ""),
            listpres: brand?.pH_OrderLineDispenseSchedule
              ? brand?.pH_OrderLineDispenseSchedule?.dispensedetails[index]
                ?.direction
              : brand?.dispensedetails[index]?.direction ?? "",
            disptype:
              brand?.pH_OrderLineDispenseSchedule?.disptype ??
              drug?.disptype?.code ??
              "",
          },
          optional: {},
        },
      ],
    };
    // IVpayload...................

    const IVpayload = {
      reportid: process.env.REACT_APP_OP_IV_LABEL_PRINT_ID,

      inputparams: {},

      result: [
        {
          Mandatory: {
            drugType: drug?.drugType?.longdesc ?? drug?.drugType?.shortdesc ?? '',
            PatientName: drug?.encounter_Id[0]?.patient_id[0].name[0]
              ? makeName(drug?.encounter_Id[0]?.patient_id[0].name[0])
              : "",
            MRN: drug?.encounter_Id[0]?.patient_id[0].alias
              ? drug?.encounter_Id[0]?.patient_id[0].alias
              : "",
            ord: drug._id ? drug._id : "",
            "#C": "",
            location: drug?.location ?? "",
            rate:
              (drug?.brands?.[index]?.pH_OrderLineDispenseSchedule
                ?.ivInfusionRateValue ?? 0,
                drug?.brands?.[index].pH_OrderLineDispenseSchedule
                  ?.ivInfusionRateUOM ?? "",
                " ",
                drug?.brands?.[index]?.pH_OrderLineDispenseSchedule
                  ?.IVDosageDurationValue ?? 0,
                drug?.brands?.[index].pH_OrderLineDispenseSchedule
                  ?.IVDosageDurationUOM ?? ""),
            Exp: "",
            Due_time: drug?.brands?.[index]?.pH_OrderLinePerdoseSchedule
              ?.dosetime
              ? TimeFormatConversion(
                drug?.brands?.[index]?.pH_OrderLinePerdoseSchedule?.dosetime,
                date_label?.orgtimeformat || "hh:mm"
              )
              : TimeFormatConversion(
                drug?.brands?.[index]?.PH_OrderLinePerdoseSchedule?.dosetime,
                date_label?.orgtimeformat || "hh:mm"
              ),
            Due_date:
              TimeFormatConversion(
                drug?.brands?.[index]?.pH_OrderLinePerdoseSchedule?.dosedate,
                date_label?.orgdateformat || "dd/MM/yyyy"
              ) ||
              TimeFormatConversion(
                drug?.brands?.[index]?.PH_OrderLinePerdoseSchedule?.dosedate,
                date_label?.orgdateformat || "dd/MM/yyyy"
              ),
            Due_date_format:
              TimeFormatConversion(
                drug?.brands?.[index]?.pH_OrderLinePerdoseSchedule?.dosedate,
                date_label?.orgdatetimeformat || "dd/MM/yyyy hh:mm"
              ) ||
              TimeFormatConversion(
                drug?.brands?.[index]?.PH_OrderLinePerdoseSchedule?.dosedate,
                date_label?.orgdatetimeformat || "dd/MM/yyyy hh:mm"
              ),
            drugname: getDrugName(drug),
            dose: getDosage(drug),
            uom: getUOM(drug),
            direction: brand?.pH_OrderLineDispenseSchedule
              ? brand?.pH_OrderLineDispenseSchedule?.dispensedetails[index]
                ?.direction ?? ""
              : brand?.dispensedetails[index]?.direction ?? "",
            Route: drug?.routeCode ? drug?.routeCode : "",
            Freq: drug?.frequency.code ?? "",
          },
          optional: {},
        },
      ],
    };

    // const Labelpayload = {
    //   reportid: process.env.REACT_APP_OP_LABEL_PRINT_ID,

    //   inputparams: {},
    //   result: [
    //     {
    //       Mandatory: {
    //         currentDate: moment().unix(),
    //         currentDate_format: TimeFormatConversion(moment().unix()),

    //         hospitalname: drug?.encounter_Id?.[0]?.practitioner_details?.[0]
    //           ?.clinic
    //           ? drug?.encounter_Id?.[0]?.practitioner_details?.[0]?.clinic
    //           : "",

    //         logo: "10012",

    //         PatientName: drug?.encounter_Id[0]?.patient_id[0].name[0]
    //           ? makeName(drug?.encounter_Id[0]?.patient_id[0].name[0])
    //           : "",

    //         MRN: drug?.encounter_Id[0]?.patient_id[0].alias
    //           ? drug?.encounter_Id[0]?.patient_id[0].alias
    //           : " ",

    //         DrugName: brand?.name ? brand?.name : " ",

    //         Qty: brand?.strength ? brand?.strength : " ",

    //         Direction: brand?.pH_OrderLineDispenseSchedule
    //           ? brand?.pH_OrderLineDispenseSchedule?.dispensedetails[index]
    //             ?.direction
    //           : brand?.dispensedetails[index]?.direction ?? "",

    //         ArabicDirection: brand?.pH_OrderLineDispenseSchedule
    //           ? brand?.pH_OrderLineDispenseSchedule?.dispensedetails[index]
    //             ?.nldirection
    //           : brand?.dispensedetails[index]?.nldirection ?? "",

    //         PrescribedBy: drug?.doctorName ? drug?.doctorName : "",

    //         ReviewedBy: "",

    //         CheckedBy: "",

    //         ExpiryDate: brand?.batches?.[0]?.expdate
    //           ? moment(brand?.batches?.[0]?.expdate).unix()
    //           : brand?.batches?.[0]?.expirydate,
    //         ExpiryDate_format: brand?.batches?.[0]?.expdate
    //           ? TimeFormatConversion(
    //             moment(brand?.batches?.[0]?.expdate).unix()
    //           )
    //           : TimeFormatConversion(brand?.batches?.[0]?.expirydate),

    //         RefillDate: brand?.pH_OrderLineDispenseSchedule
    //           ? brand?.pH_OrderLineDispenseSchedule?.nextrefilldate
    //           : brand?.nextrefilldate,
    //         RefillDate_format:
    //           brand?.nextrefilldate > 0
    //             ? TimeFormatConversion(brand?.nextrefilldate)
    //             : "",

    //         RefillLocation: "",

    //         Barcode: drug?._id ? drug?._id : "",
    //       },
    //       optional: {},
    //     },
    //   ],
    // };
    // // IVpayload...................

    // const IVpayload = {
    //   reportid: process.env.REACT_APP_OP_IV_LABEL_PRINT_ID,

    //   inputparams: {},

    //   result: [
    //     {
    //       Mandatory: {
    //         PatientName: drug?.encounter_Id[0]?.patient_id[0].name[0]
    //           ? makeName(drug?.encounter_Id[0]?.patient_id[0].name[0])
    //           : "",

    //         MRN: drug?.encounter_Id[0]?.patient_id[0].alias
    //           ? drug?.encounter_Id[0]?.patient_id[0].alias
    //           : " ",

    //         ord: drug._id ? drug._id : " ",

    //         "#C": "",

    //         location: "",

    //         drugname: getDrugName(drug),

    //         dose: getDosage(drug),

    //         uom: getUOM(drug),

    //         rate: "",

    //         direction: brand?.pH_OrderLineDispenseSchedule
    //           ? brand?.pH_OrderLineDispenseSchedule?.dispensedetails[index]
    //             ?.direction
    //           : brand?.dispensedetails[index]?.direction ?? "",

    //         Route: drug?.routeCode ? drug?.routeCode : "",

    //         Freq: drug.frequency.code,

    //         Exp: "",
    //         Exp_format: brand?.batches?.[0]?.expdate
    //           ? moment(brand?.batches?.[0]?.expdate).unix()
    //           : brand?.batches?.[0]?.expirydate,
    //         ExpiryDate_format: brand?.batches?.[0]?.expdate
    //           ? TimeFormatConversion(
    //             moment(brand?.batches?.[0]?.expdate).unix()
    //           )
    //           : TimeFormatConversion(brand?.batches?.[0]?.expirydate),
    //         // brand?.batches?.[0]?.expdate
    //         //   ? moment(brand?.batches?.[0]?.expdate).unix()
    //         //   : brand?.batches?.[0]?.expirydate,

    //         Due_date: "",
    //         Due_date_format: "",
    //         Due_time: "",
    //       },

    //       optional: {},
    //     },
    //   ],
    // };

    await dispatch(
      actions.LABEL_PRINT({
        Body: type === "IV" ? IVpayload : Labelpayload,
      })
    );
  };

  const FreqHeader = (type) => {
    switch (type) {
      case "DBA":
        return ["Day", "Base schedule", "Dosage", "Remarks"];
      case "UDE":
        return ["Base schedule", "From Day", "To Day", "Dosage", "Remarks"];
      case "DET":
        return ["Time", "Dosage", "Remarks"];
      case "INT":
      case "SEQ":
        return ["Base schedule", "Dosage", "Remarks"];
      default:
        return [
          "Day",
          "Time",
          "Base schedule",
          "Start date",
          "End date",
          "Dosage",
          "Remarks",
        ];
    }
  };

  const FreqBody = (FreqDetls) => {
    let data = FreqDetls?.map((v) => {
      return {
        Day: v?.day ? v?.day : "-",
        Time: v?.time ? v?.time : "-",
        "Base schedule": v?.baseFreq ?? "-",
        "Start date":
          v?.fromDate === 0
            ? "-"
            : moment(v?.fromDate * 1000).format("DD-MM-YYYY"),

        "End date":
          v?.toDate === 0 ? "-" : moment(v?.toDate * 1000).format("DD-MM-YYYY"),
        Dosage: v?.dose ? v?.dose : "-",
        "From Day": v?.fromDay,
        "To Day": v?.toDay,
        Remarks: v?.adminDosageInstruction ? v?.adminDosageInstruction : (v.remarks ?? "-"),
      };
    });
    return data;
  };
  const [medicationEdit, setMedicationEdit] = useState(false);
  const [edirReason, setedirReason] = useState(false);
  const [valueReason, setvalueReason] = useState("");
  const handleMedicationEdit = () => {
    OrderInfoClose();
    setMedicationEdit(true);
  };
  const handleMedicationClose = () => {
    setMedicationEdit(false);
  };

  const handleChangeReason = (newValue) => {
    setedirReason(newValue?.isapproval);
    setvalueReason(newValue);
    props.handleVerifyReason(newValue?.value, newValue?.isapproval);
  };

  const isSelect = (d) => {
    var selected = false;

    const isFilter = props.isMultiOrderSelect;

    if (isFilter?.length > 0) {
      const data = isFilter?.filter((v) => v?._id === d?._id);
      selected = data?.length > 0;
    }
    return selected;
  };

  return (
    <>
      {!drug?.isEmpty && (
        <div className={classes.container}>
          <div className={classes.root} ref={rootRef}>
            <div
              className={classes.drugFlexContainer}
              style={{ width: needToBeWidth }}
            >
              <Tooltip title={drug?.name} arrow>
                <Typography noWrap className={classes.name}>
                  {drug?.name}
                </Typography>
              </Tooltip>
              <InfoIcon
                onClick={(e) => handleOrderinfo(e, drug)}
                style={{ minWidth: "14px", cursor: "pointer" }}
              />
              <Divider
                orientation="vertical"
                flexItem
                classes={dividerStyles}
                style={{ margin: theme.spacing(0, 1) }}
              />
              {!isIntravenous && (
                <>
                  <Typography className={classes.particularText}>
                    {drug?.doseStrength}
                  </Typography>
                  {drug?.route && (
                    <>
                      <Dot />
                      <Typography className={classes.particularText}>
                        {drug?.route}
                      </Typography>
                    </>
                  )}
                  <Dot />
                  <Typography className={classes.particularText}>
                    {drug?.frequency?.code}
                  </Typography>
                  <Dot />
                  <div
                    style={{ margin: "0px 4px", cursor: "pointer" }}
                    onClick={handleClickSchedule}
                  >
                    <ScheduleIcon />
                  </div>
                  <Dot />
                  <Typography className={classes.particularText}>
                    {drug?.duration}
                  </Typography>
                  <Dot />
                  {/* <Typography className={classes.particularText}>
                    {`${moment(drug?.startDate)?.format(
                      "DD-MM-YY HH:mm"
                    )} - ${moment(drug?.endDate).format("DD-MM-YY HH:mm")}`}
                  </Typography> */}
                  <TimeComponent
                    dateTime={[
                      getUnixTime(new Date(drug?.startDate)),
                      getUnixTime(new Date(drug?.endDate)),
                    ]}
                    separator={" - "}
                    formatType={"datetime"}
                    className={classes.particularText}
                  />
                  <Dot />
                </>
              )}
              <Typography
                onMouseOver={handleDoctorName}
                // onMouseLeave={DoctorClose}
                className={classNames(
                  classes.particularText,
                  classes.doctorName
                )}
              >
                {drug?.doctorName}
              </Typography>
              {isIntravenous && (
                <>
                  <Dot />
                  <Typography
                    className={classNames(
                      classes.particularText,
                      classes.doctorName
                    )}
                  >
                    {"Cardiology"}
                  </Typography>
                </>
              )}
            </div>
            {/* <div
              className={classes.drugFlexContainer}
              style={{ width: needToBeWidth }}
            >
              <Tooltip title={drug?.name} arrow>
                <Typography noWrap className={classes.name}>
                  {drug?.name}
                </Typography>
              </Tooltip>
              <InfoIcon
                onClick={(e) => handleOrderinfo(e, drug)}
                style={{ minWidth: "14px", cursor: "pointer" }}
              />
              <Divider
                orientation="vertical"
                flexItem
                classes={dividerStyles}
                style={{ margin: theme.spacing(0, 1) }}
              />
              <Typography
                onMouseOver={handleDoctorName}
                // onMouseLeave={DoctorClose}
                className={classNames(
                  classes.particularText,
                  classes.doctorName
                )}
              >
                {drug?.doctorName}
              </Typography>
            </div> */}

            <div style={{ flex: 1 }}></div>

            <div
              id="drugDosageContainer"
              className={classes.drugDosageContainer}
            >
              <div
                className={classes.iconContainer}
                {...bindTrigger(actionState)}
              >
                <SvgIcon
                  component={ForwardIconSmall}
                  viewBox="-5 -7 20 20"
                  style={{
                    width: "20px",
                    height: "20px",
                    transform: "rotate(-90deg)",
                  }}
                />
              </div>
              <CustomPopover
                {...bindPopover(actionState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {props.issueMedication ? (
                  <div className={classes.reasonPaper}>
                    <Typography className={classes.reasonTitle}>
                      Reason for ReIssue
                    </Typography>

                    <div className={classes.reasonFieldContainer}>
                      <Typography className={classes.reasonFieldLabel}>
                        Select Reason
                      </Typography>
                      {/* className={classes.reasonFieldLabel} */}

                      <ReasonMaster
                        type={"REISSUEREASON"}
                        onChange={handleChangeReason}
                        optionLabel={"label"}
                        value={valueReason}
                        reIssue={true}
                      />
                    </div>
                    {props.actions?.map((val) => {
                      return (
                        <Button
                          key={val.actionid}
                          className={classes.button}
                          onClick={() => {
                            // props.onActionClick(val.id);
                            handleExpandClick(val);
                            props.handleVerify(val, edirReason);
                          }}
                        >
                          {val.action}
                        </Button>
                      );
                    })}
                  </div>
                ) : (
                  <div className={classes.reasonPaper}>
                    {props?.hiddenReason && (
                      <Typography className={classes.reasonTitle}>
                        Reason for return
                      </Typography>
                    )}
                    {props?.hiddenReason && (
                      <div className={classes.reasonFieldContainer}>
                        <Typography className={classes.reasonFieldLabel}>
                          Select Reason
                        </Typography>
                        {/* className={classes.reasonFieldLabel} */}

                        <ReasonMaster
                          type={"REISSUEREASON"}
                          onChange={handleChangeReason}
                          optionLabel={"label"}
                          value={valueReason}
                          medication={true}
                        />
                      </div>
                    )}
                    {props.actions?.map((val) => {
                      if (!edirReason && val?.action === "RAISE FOR APPROVAL") {
                        return;
                      } else if (edirReason && val?.action === "RETURN") {
                        return;
                      }
                      return (
                        <Button
                          key={val.actionid}
                          className={classes.button}
                          onClick={() => {
                            // props.onActionClick(val.id);
                            handleExpandClick(val);
                            props.handleVerify(val, edirReason, true);
                          }}
                        >
                          {val.action}
                        </Button>
                      );
                    })}
                    {/* <CustomButton
                    variant="contained"
                    color="rgba(255, 255, 255, 1)"
                    backgroundColor="rgba(1, 32, 92, 1)"
                    onClick={() => {
                      props.handleVerify(action, false, true);
                    }}
                    className={classes.reasonButton}
                  >
                    Return
                  </CustomButton>
                  {edirReason && (
                    <CustomButton
                      variant="contained"
                      color="rgba(255, 255, 255, 1)"
                      backgroundColor="rgba(1, 32, 92, 1)"
                      onClick={() => {
                        props.handleVerify(action, edirReason, true);
                      }}
                      className={classes.reasonButton}
                    >
                      Raise For Approval
                    </CustomButton>
                  )} */}
                  </div>
                )}
              </CustomPopover>

              <Divider
                orientation="vertical"
                flexItem
                classes={dividerStyles}
              // style={{ margin: theme.spacing(0, 1.5) }}
              />

              {/* <div
                className={classes.closeIconContainer}
                // onClick={props.onApplyClick}
                onClick={() => window.location.reload()}
              >
                <Close color={"#ffffff"} />
              </div> */}

              <div onClick={() => props.isMultiOrderSelectFuntion(drug)}>
                {isSelect(drug) ? (
                  <CheckCircleIcon className={classes.selectOrder} />
                ) : (
                  <CheckCircleOutlineIcon className={classes.selectOrder} />
                )}
              </div>
            </div>
          </div>

          {/* <Collapse in={isDispenseDurationApplied}> */}
          <div className={classes.drugBrandGutter}>
            {drug?.brands?.map((brand, index) => (
              <DrugBrandTableForReturnMedication
                {...props}
                key={brand?.id}
                disptype={drug?.disptype?.code}
                drug={drug}
                brand={brand}
                imgId={brand?.drugPhotoId}
                isAnotherBrandAvailable={index !== drug?.brands?.length - 1}
                batchQuantity={props?.batchQuantity}
                onBatchQuantityChangeDrugBatchIssuMedication={(
                  value,
                  bacth_index
                ) =>
                  props?.onBatchQuantityChangeDrugBatchIssuMedication(
                    value,
                    index,
                    bacth_index
                  )
                }
                onBatchQuantityChangeDrug={(value, isMedication) =>
                  props?.onBatchQuantityChangeDrug(
                    value,
                    brand?.id,
                    index,
                    isMedication
                  )
                }
                onBatchQuantityChange={(value, batch_inx, isMedication) =>
                  props?.onBatchQuantityChange(
                    value,
                    brand?.id,
                    index,
                    batch_inx,
                    isMedication
                  )
                }
                handleChangebrandName={(value) => {
                  props.handleChangebrandName(value, index, brand?.id);
                }}
                handleDelete={() => props?.handleDelete(index)}
                handleAdd={() => props?.handleAdd(index)}
                isAdd={isaddfuncy(drug) && drug?.brands?.length - 1 === index}
                index={index}
                isIntravenous={isIntravenous}
                printLabel={() => printLabel(drug, index, brand)}
                handleDirectionSaveClick={(e) =>
                  props.handleDirectionSaveClick(e, index, brand)
                }
                printEnable
              />
            ))}
          </div>

          {isIntravenous && (
            <>
              <div className={classes.additiveHeaderContainer}>
                <div className={classes.additiveHeaderContent}>
                  {/* Add a expand/collapse button here */}
                  <IconButton
                    size="small"
                    disableRipple
                    onClick={onAdditiveExpandCollapseChange}
                    className={classes.expandCollapseButton}
                  >
                    {isAdditiveExpanded ? <CollapseIcon /> : <ExpandIcon />}
                  </IconButton>

                  <Typography className={classes.additiveHeaderText}>
                    Additives for Dextrose Saline 1000 ml
                  </Typography>
                </div>

                <hr className={classes.additiveHeaderLine} />
              </div>

              <Collapse in={isAdditiveExpanded}>
                {drug?.additives?.map((additive, index) => (
                  <DrugAdditiveCard
                    additive={additive}
                    drug={drug}
                    printLabel={(brand, ivIndex) =>
                      printLabel(drug, ivIndex, brand)
                    }
                  />
                ))}
              </Collapse>
            </>
          )}

          {/* </Collapse> */}
        </div>
      )}

      {/* Order Info Dialog..... */}
      <Dialog
        fullWidth
        // maxWidth="sm"
        classes={{ paper: classes.dialogPaper }}
        open={orderInfo}
        onClose={OrderInfoClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="customized-dialog-title"
          handleMedicationEdit={handleMedicationEdit}
          onClose={OrderInfoClose}
        >
          Order Info
        </DialogTitle>
        <DialogContent>
          <OrderInfo
            orderinfo={orderValue}
            handleMedicationEdit={handleMedicationEdit}
          />
        </DialogContent>
      </Dialog>

      {/* Edit Medication Info */}
      <Dialog
        fullWidth
        // maxWidth="sm"
        classes={{ paper: classes.dialogPaper }}
        open={medicationEdit}
        onClose={handleMedicationClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleMedicationClose}
        >
          Edit Medication Info
        </DialogTitle>
        <DialogContent>
          <MedicationForm
            handlepharmacyRefresh={props?.handlepharmacyRefresh}
            parent_id={"orderID"}
            orderinfo={orderValue}
            totalOrder={totalOrder}
            OrderInfoClose={handleMedicationClose}
            handleMedicationClose={handleMedicationClose}
            selectedState={props?.selectedState}
          />
        </DialogContent>
      </Dialog>

      {/* Add medication info */}
      <Dialog
        fullWidth
        // maxWidth="sm"
        classes={{ paper: classes.dialogPaper }}
        open={props.addnewmedication}
        onClose={handleMedicationClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleMedicationClose}
        >
          Add Medication Info
        </DialogTitle>
        <DialogContent>
          <MedicationForm
            handlepharmacyRefresh={props?.handlepharmacyRefresh}
            parent_id={"orderID"}
            orderinfo={orderValue}
            totalOrder={totalOrder}
            OrderInfoClose={props.createNewOrder}
            handleMedicationClose={props.createNewOrder}
            selectedState={props?.selectedState}
            isAdd={true}
            {...props}
          />
        </DialogContent>
      </Dialog>

      {/* Order Info IV Dialog..... */}
      <Dialog
        fullWidth
        // maxWidth="sm"
        classes={{ paper: classes.dialogPaper }}
        open={false}
        onClose={true}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={true}
          style={{ borderBottom: "1px solid #DEE5EC" }}
        >
          <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
            ORDER INFO
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: "16px" }}>
          <OrderInfoIV orderinfo={orderValue} />
        </DialogContent>
      </Dialog>

      {/* Doctor Name PopOver...... */}
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={doctorName}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={DoctorClose}
        disableRestoreFocus
      >
        <div style={{ marginTop: "5px", padding: "10px", width: "244px" }}>
          <Typography className={classes.info} style={{ paddingBottom: "8px" }}>
            Order Info
          </Typography>
          <Divider
            orientation="horizontal"
            flexItem
            classes={dividerstyles2}
            style={{ height: "1px !important" }}
          />
          <div style={{ marginTop: "5px" }}>
            <Typography
              className={classes.info}
              style={{ paddingTop: "8px", paddingBottom: "5px" }}
            >
              {drug?.speciality}
            </Typography>
            <div>
              <div style={{ display: "flex" }}>
                <div>
                  <Typography className={classes.particularText}>
                    {drug?.doctorName}
                  </Typography>
                </div>
                <div style={{ marginLeft: "5px" }}>
                  <Typography className={classes.particularText}>
                    {drug?.endDate}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popover>

      {/* Popover for Arrow svg */}

      {/* Frequency Schedule-------------------------------- */}
      <Popover
        id={idFrq}
        open={openSchedule}
        anchorEl={schedule}
        onClose={handleCloseschedule}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          style={{ display: "flex", padding: "12px" }}
          className={classes.btngroup}
        >
          <BasicTable
            header={FreqHeader(props.dosageFreqType)}
            dataTabs={FreqBody(props.FreqDetls)}
            FreqDetls={props.FreqDetls}
            dosageFreqCode={props.dosageFreqCode}
          />
        </div>
      </Popover>
    </>
  );
}

export default DrugCardForReturnMedication;

DrugCardForReturnMedication.propTypes = {
  drug: PropTypes.object.isRequired,
  dispenseDuration: PropTypes.string.isRequired,
  onDispenseDurationChange: PropTypes.func.isRequired,
  dispenseFrequency: PropTypes.string.isRequired,
  onDispenseFrequencyChange: PropTypes.func.isRequired,
  onApplyDispenseDurationClick: PropTypes.func.isRequired,
};

DrugCardForReturnMedication.defaultProps = {
  drug: {},
  onDispenseDurationChange: () => { },
  onDispenseFrequencyChange: () => { },
  onApplyDispenseDurationClick: () => { },
};
