import * as React from "react";
import PropTypes from "prop-types";
import { Routes } from "../router/routes";
import { useTheme } from "@material-ui/core";

const BuiildingIcon = (props) => {
  const theme = useTheme();
  const color =
    window.location.pathname === props.route
      ? theme.sideBar.text.active
      : theme.sideBar.text.nonActive;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.218"
      height="18.674"
      viewBox="0 0 18.218 18.674"
    >
      <path
        d="M7.139,5a.683.683,0,1,0,0,1.366h.228v8.67A1.6,1.6,0,0,0,6,16.614v5.01a.683.683,0,0,0,.683.683H12.6a.683.683,0,0,0,.683-.683v-2.96a5.946,5.946,0,0,1,.077-.911H12.6a.683.683,0,0,0-.683.683v2.505H7.366V16.614a.228.228,0,0,1,.228-.228H8.05a.683.683,0,0,0,.683-.683V6.366H19.664v6.4a5.89,5.89,0,0,1,1.366.266V6.366h.228a.683.683,0,1,0,0-1.366Zm3.416,3.188a.456.456,0,0,0-.455.455v.911a.456.456,0,0,0,.455.455h.911a.456.456,0,0,0,.455-.455V8.644a.456.456,0,0,0-.455-.455Zm3.188,0a.456.456,0,0,0-.455.455v.911a.456.456,0,0,0,.455.455h.911a.456.456,0,0,0,.455-.455V8.644a.456.456,0,0,0-.455-.455Zm3.188,0a.456.456,0,0,0-.455.455v.911a.456.456,0,0,0,.455.455h.911a.456.456,0,0,0,.455-.455V8.644a.456.456,0,0,0-.455-.455Zm-6.376,3.188a.456.456,0,0,0-.455.455v.911a.456.456,0,0,0,.455.455h.911a.456.456,0,0,0,.455-.455v-.911a.456.456,0,0,0-.455-.455Zm3.188,0a.456.456,0,0,0-.455.455v.911a.456.456,0,0,0,.455.455h.911a.456.456,0,0,0,.455-.455v-.911a.456.456,0,0,0-.455-.455Zm3.188,0a.456.456,0,0,0-.455.455v.911a.455.455,0,0,0,.455.455,5.893,5.893,0,0,1,1.351-.375.4.4,0,0,0,.016-.08v-.911a.456.456,0,0,0-.455-.455Zm2.277,2.277a5.01,5.01,0,1,0,5.01,5.01A5.01,5.01,0,0,0,19.208,13.654Zm-8.654.911a.456.456,0,0,0-.455.455v.911a.456.456,0,0,0,.455.455h.911a.456.456,0,0,0,.455-.455V15.02a.456.456,0,0,0-.455-.455Zm3.188,0a.456.456,0,0,0-.455.455v.911a.455.455,0,0,0,.455.455,5.913,5.913,0,0,1,1.137-1.753.439.439,0,0,0-.226-.069Zm5.466.911a1.366,1.366,0,1,1-1.366,1.366A1.366,1.366,0,0,1,19.208,15.476ZM9.188,17.753a.456.456,0,0,0-.455.455v.911a.456.456,0,0,0,.455.455H10.1a.456.456,0,0,0,.455-.455v-.911a.456.456,0,0,0-.455-.455Zm7.971,1.366h4.1a.683.683,0,0,1,.683.683v.228c0,1.006-1.223,1.822-2.733,1.822s-2.733-.816-2.733-1.822V19.8A.683.683,0,0,1,17.159,19.119Z"
        transform="translate(-6 -5)"
        fill={color}
      />
    </svg>
  );
};

export default BuiildingIcon;

BuiildingIcon.defaultProps = {
  color: "black",
};

BuiildingIcon.propTypes = {
  color: PropTypes.string,
};
