import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  uploadFileList,
  getImgUrl,
  isIterable,
} from "../../utils/helperFunctions";
import { LoggerContext } from "../../contexts";
import * as ALL from "../../components";
import { CustomButton } from "../../components";
import { TrashIcon } from "../../assets";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Paper,
  Grid,
  Avatar,
  Divider,
  Button,
  Typography,
  TextField,
  Checkbox,
  IconButton,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { ComponentRender } from "custom-material-ui-component";
import { Routes } from "../../router/routes";
import { Tabs, Tab, TabPanel } from "./components";
import qdmstyles from "./styles.json";
import "./index.css";
import {
  InputBase,
  Autocomplete,
  TextfieldWithSelect,
  FieldLabel,
} from "../../components";
import copy from "fast-copy";
import update from "immutability-helper";
import { withAllContexts } from "../../HOCs";
import classNames from "classnames";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import Color from "../../themes/default.json";

const statusOptions = {
  active: "CodingMaster/10272",
  inactive: "CodingMaster/10273",
  discontinued: "CodingMaster/10274",
};

const styles = (theme) => ({
  drug_title: {
    color: theme.palette.text.primary,
  },
  cancel: {
    color: theme.palette.primary.light,
  },
  phdrug: {
    color: theme.palette.text.primary,
  },
  ph_childtitle: {
    color: theme.palette.primary.light,
  },
  status: {
    color: theme.palette.text.primary,
  },
  entity: {
    color: theme.palette.primary.light,
  },
  commonBackground: {
    backgroundColor: theme.palette.background.common,
  },
  subheaderRoot: {
    display: "flex",
  },
  subheaderPaper: {
    width: "100%",
    boxShadow: "0px 1px 6px #00000029",
    padding: "8px 24px",
    backgroundColor: theme.palette.background.commonHeader,
  },
  subHeaderBack: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    "& > .App1-MuiTypography-root": {
      // color: "#001C3C",
      fontWeight: 600,
      fontSize: 16,
    },
  },
  backButton: {
    "&.App1-MuiIconButton-root:hover": {
      backgroundColor: theme.palette.common.white,
    },
    backgroundColor: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  divider: {
    height: "24px",
    alignSelf: "center",
    margin: "0px 24px",
    backgroundColor: "#B4BBC3",
  },
  stepper: {
    padding: "0px",
    width: "480px",
    "& .App1-MuiStepIcon-completed": {
      color: theme.palette.primary.main,
    },
    "& .App1-MuiStepLabel-completed": {
      color: Color.palette.text.tertiary,
    },
    "& .App1-MuiStepLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .App1-MuiStepConnector-line": {
      borderColor: Color.palette.text.light,
    },
    backgroundColor: theme.palette.background.commonHeader,
    "& .App1-MuiStepIcon-text": {
      fill: theme.palette.primary.contrastText,
    },
  },
  subheaderCTA: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  uploadImagePaper: {
    padding: "8px !important",
    display: "flex !important",
    flexDirection: "column",
    height: "100%",
    backgroundColor: theme.palette.background.table,
  },
  avatarContainer: {
    height: "100%",
    width: "100%",
    padding: "0px !important",
  },
  avatar: {
    borderRadius: "8px !important",
  },
  avatarDivider: {
    width: "calc(100% + 16px) !important",
    margin: "8px -8px 6px !important",
    padding: "0px !important",
    "& hr": {
      backgroundColor: "rgba(222, 229, 236, 1)",
    },
  },
  uploadButton: {
    backgroundColor: theme.palette.button.background,
    color: theme.palette.button.text,
  },

  // PH Drug Master
  phDrugMasterContainer: {
    padding: "16px 20px 16px 12px !important",
    backgroundColor: theme.palette.background.table,
  },
  phDrugMasterRow: {
    flexWrap: "nowrap !important",
    gap: "12px",
  },
  phDrugMasterLabelContainer: {
    margin: "0px 0px 8px 0px !important",
    whiteSpace: "nowrap",
  },
  phDrugMasterInputContainer: {
    margin: "0px !important",
  },
  tabRoot: {
    "& .custom-material-ui-component-MuiTabs-root": {
      padding: "0px 16px",
      borderBottom: "1px solid #DEE5EC",
    },
    "& .custom-material-ui-component-MuiBox-root": {
      padding: "16px",
    },
    "& .custom-material-ui-component-MuiTabs-flexContainer": {
      gap: 40,
      "& .custom-material-ui-component-MuiTab-root": {
        minWidth: "fit-content",
        textTransform: "unset",
      },
      "& button": {
        padding: "6px 0px",
      },
    },
  },
  drugTabSec: {
    backgroundColor: theme.palette.background.table,
  },
  // statusAutoComplete: {
  //   "& > .MuiInputBase-root": {
  //     backgroundColor: "unset",
  //   },
  // },

  // Status Autocomplete
  removeGutterRight: {
    "&.App1-MuiAutocomplete-hasPopupIcon&.MuiAutocomplete-hasClearIcon": {
      "& .App1-MuiAutocomplete-inputRoot": {
        paddingRight: 30,
      },
    },
  },
  multiAutoComplete: {
    "& .App1-MuiAutocomplete-inputRoot": {
      position: "relative",
      backgroundColor: "unset",
      gap: 2,
      borderRadius: "8px",
      "& .App1-MuiInputAdornment-root": {
        position: "absolute",
        right: 2,
      },
    },
  },

  radius: {
    borderRadius: "8px",
  },

  // Status Autocomplete
  removeGutterRight: {
    "&.App1-MuiAutocomplete-hasPopupIcon&.MuiAutocomplete-hasClearIcon": {
      "& .App1-MuiAutocomplete-inputRoot": {
        paddingRight: 30,
      },
    },
  },
  multiAutoComplete: {
    "& .App1-MuiAutocomplete-inputRoot": {
      position: "relative",
      backgroundColor: "unset",
      gap: 0,
      borderRadius: "8px",
      "& .App1-MuiInputAdornment-root": {
        position: "absolute",
        right: 2,
      },
    },
  },
});

const initialCompoundState = {
  compDrugCode: "",
  // compDrugDescription: "", // ShortDesc
  compStrengthValue: null, // StrengthValue
  compStrengthUOM: "", //StrengthUOM
  compStrengthVolume: null, //StrengthVolume
  compStrengthVolumeUoM: "", //StrengthVolumeUoM
  compQty: null,
  compQtyUom: "",
  compRemarks: "",
};

const initialSynonymState = {
  synonymType: "",
  langID: "",
  shortDesc: "",
  longDesc: "",
};

const initialAliasMappingState = {
  aliasType: "",
  aliasCode: "",
  aliasName: "",
};

const initialMappedDispensablesState = {
  dispensedrugcode: "",
  seqno: null,
};

const initialDrugOtherAttributesState = {
  Additive: false,
  ApplicableForCompounding: false,
  DispenseviaprescriptionOnly: false,
  CounsellingRequired: false,
  returnApplicable: false,
  stability: false,
  stabilityUOM: null,
};

const initialReturnApplicableState = {
  returnReason: "",
  stockReflection: false,
  approvalReq: false,
  billReflection: false,
};

// const initialFrequencyRulesState = {
//   dfltfreqcode: "",
//   dfltdurationcode: "",
//   dfltdurnUOM: "",
//   dfltdoseval: "",
//   dfltdoseUOM: "",
//   InfuseRateval: "",
//   InfuseRateUom: "",
//   DfltInfuseOverDurationval: "",
//   DfltInfuseOverDurationType: "",
//   DfltCriteria: [],
// };

// const initialFrequencyRulesState = {
//   id: 0,
//   freqcode: "",
//   durationcode: 0,
//   durnUOM: "",
//   doseval: 0,
//   doseUOM: "",
//   InfuseRateval: 0,
//   InfuseRateUom: "",
//   InfuseOverDurval: 0,
//   InfuseOverDurType: "",
//   Criteria: [],
// };

const initialOrderDefaultsState = {
  RoutesAllowed: [],
  DefaultRouteCode: "",
  FrequencyRules: [],
};

const initialDispenseDefaultState = {
  alloweddisptype: [],
  defaultdisptype: null,
  MaxDispDurnRulebyEnctype: [],
};

const initialStatusState = {
  entityType: "",
  entityCode: "",
  // entityName: "", // Not added by backend team
  status: "",
  effFr: 0,
  effTo: 0,
  reason: "",
  formulary: false,
};

const initialPackSizeApplicabilityState = {
  applicableInd: "",
  packSizeUoM: "",
  packSize: 0,
  packUnitUoM: "",

  applicablityUOM: "",
  roundtype: "",
  roundoff: 0,
  individualDoseRound: false,

  UOMCriteria: [],
  // roundtype: "",  // Added as per change request but not in UI - Holding for now
  // roundoff: "number"  // Added as per change request but not in UI - Holding for now
};

const initialDispChargeState = {
  dispUOM: "",
  dispRoundingType: "",
  DispRoundingVal: "",
  individualDoseRound: "",
};

// const initialInstructionsState = {
//   instrType: "",
//   instruction: "",
//   otrLangInstr: "",
//   directionType: "",
// };

class Drugintscreen extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);

    this.fileUploader = React.createRef();

    this.state = {
      permissionData: null,
      showStatuspage: false,
      loading: false,

      currentTabSection: 0,

      // Drug Active State
      drugActiveState: true,

      // All States
      photo: null,
      photoUrl: "",
      codeStandard: null,
      DrugCode: "",
      ShortDesc: "",
      LongDesc: "",
      DrugClass: "",
      DrugCategory: "",
      DrugType: "",
      FormCode: "",
      DrugGenericId: "",
      // codeStandard: this.props.dropdownOptions?.data?.code_standara?.[0],
      // DrugClass: this.props.dropdownOptions?.data?.drug_class?.[0],
      // DrugCategory: this.props.dropdownOptions?.data?.drug_category?.[0],
      // DrugType: this.props.dropdownOptions?.data?.drug_type?.[0],
      // FormCode: this.props.dropdownOptions?.data?.form?.[0],
      IsDispensible: false,

      IsOrderable: false,

      // Compound State
      compondCompDTls: [],

      // Tab State - Synonym
      drugSynonyms: [copy(initialSynonymState)],

      // Tab State - Alias Mapping
      aliasDtls: [copy(initialAliasMappingState)],

      // Tab State - Mapped Dispensables
      mappedItems: [copy(initialMappedDispensablesState)],

      // Tab State - Drug Other Attributes
      drugotherattributes: { ...initialDrugOtherAttributesState },

      // Tab State - Return Applicable
      newReturnParams: [copy(initialReturnApplicableState)],
      returnPeriodVal: "",
      returnPeriodUOM: null,
      partialQtyAllow: false,
      returnPolicy: "",

      // Tab State - Order Defaults
      orderDefaults: {
        ...initialOrderDefaultsState,
      },

      // Tab State - Dispense Defaults
      DrugDispRules: {
        ...initialDispenseDefaultState,
      },

      // DrugDispRules: [],

      // Tab State - Available Strength
      StrengthValuesPossible: [],

      // Status State
      selectedEntityType: "",
      selectedEntityName: [],
      entityLevelStatus: [],

      // Tab State - Unit of measure
      packSizeApplicability: [copy(initialPackSizeApplicabilityState)],

      // Tab State - Dispense & Charge
      dispenseVsChargeRoundingDefn: [copy(initialDispChargeState)],

      // Tab State - Instructions
      instrDtls: [],
      directionDtls: [],
      status: false,
    };
  }

  changeState = (key, value, callback) => {
    this.setState(
      {
        [key]: value,
      },
      () => {
        if (callback) callback(key);
      }
    );

    // Check for the drug type validations - show compound section only if the drug type is compound
    if (key === "DrugType") {
      if (value?.value === "DrugType/10005") {
        this.setState({
          compondCompDTls: [
            copy(initialCompoundState),
            copy(initialCompoundState),
          ],
        });
      } else if (this.state.compondCompDTls?.length > 0) {
        this.setState({
          compondCompDTls: [],
        });
      }
    }

    // Reload Entity Name options on Entity Type change
    if (key === "selectedEntityType") {
      this.setState(
        {
          entityLevelStatus: [],
          selectedEntityName: [],
        },
        async () => {
          await this.props.DRUG_CATALOG_MASTER_ENTITY_NAME_OPTIONS({
            entityTypeCode: value?.code,
          });
        }
      );
    }
  };

  verifyIfDrugShouldBeActive = () => {
    const { entityLevelStatus } = this.state;

    if (entityLevelStatus.length === 0) return;

    const drugActiveState = entityLevelStatus
      ?.map((x) => x?.status?.value)
      ?.some((id) => id === statusOptions.active);

    this.setState({
      drugActiveState: drugActiveState,
    });
  };

  changeStateForArrayElement = (stateKey, propertyKey, value, index) => {
    const newState = update(this.state, {
      [stateKey]: {
        [index]: {
          [propertyKey]: {
            $set: value,
          },
        },
      },
    });

    this.setState(
      {
        [stateKey]: newState?.[stateKey],
      },
      () => {
        if (stateKey === "entityLevelStatus" && propertyKey === "status") {
          this.verifyIfDrugShouldBeActive();
        }
      }
    );
  };

  onChangeSwitch = () => {
    this.setState({
      ...this.state,
      status: !this.state.status,
    });
  };

  changeStateForObjectElement = (stateKey, propertyKey, value) => {
    const newState = update(this.state, {
      [stateKey]: {
        [propertyKey]: {
          $set: value,
        },
      },
    });

    this.setState({
      [stateKey]: newState?.[stateKey],
    });
  };

  handleCompounDrugChange = (stateKey, propertyKey, value, index) => {
    const { dropdownOptions } = this.props;
    const { data: options } = dropdownOptions;

    const tem = options?.drug_uom?.filter(
      (x) => x?.value === value?.StrengthValuesPossible?.[0]?.StrengthUOM
    )?.[0]?.label;

    const newState = update(this.state, {
      [stateKey]: {
        [index]: {
          [propertyKey]: {
            $set: value,
          },
          // compDrugDescription: {
          //   $set: value?.ShortDesc,
          // }, // ShortDesc
          compStrengthValue: {
            $set: value?.StrengthValuesPossible?.[0]?.StrengthValue,
          }, // StrengthValue
          compStrengthUOM: {
            $set: options?.drug_uom?.filter(
              (x) =>
                x?.value === value?.StrengthValuesPossible?.[0]?.StrengthUOM
            )?.[0]?.label,
          }, //StrengthUOM,
          compStrengthVolume: {
            $set: value?.StrengthValuesPossible?.[0]?.StrengthVolume,
          }, //StrengthVolume
          compStrengthVolumeUoM: {
            $set: options?.drug_uom?.filter(
              (x) =>
                x?.value ===
                value?.StrengthValuesPossible?.[0]?.StrengthVolumeUoM
            )?.[0]?.label,
          }, //StrengthVolumeUoM
        },
      },
    });

    this.setState({
      [stateKey]: newState?.[stateKey],
    });
  };

  // Provide Left array with higher length to avoid invalid results
  getUniqueElementFromLeftArray = (
    leftArray = [],
    rightArray = [],
    propertyKey = "_id"
  ) => {
    return leftArray.filter(
      ({ [propertyKey]: id1 }) =>
        !rightArray.some(({ [propertyKey]: id2 }) => id2 === id1)
    );
  };

  handleEntityNameChange = (newValue, reason) => {
    if (reason === "select-option") {
      const { dropdownOptions } = this.props;
      const { data: options } = dropdownOptions;

      // Length < than newValue, since it is select-option
      const prevEntityNames = [...this.state?.selectedEntityName];

      const newlyAddedElement = this.getUniqueElementFromLeftArray(
        newValue,
        prevEntityNames,
        "_id"
      )?.[0];

      let newStatusState = copy(initialStatusState);

      //     entityType: "",
      // entityCode: "",
      // status: "",
      // effFr: "",
      // effTo: "",
      // reason: "",
      // formulary: "",

      newStatusState.entityType = this.state.selectedEntityType;
      newStatusState.entityCode = newlyAddedElement?.value;
      // newStatusState.entityName = newlyAddedElement?.name;
      newStatusState.status = options?.drug_status?.filter(
        (x) => x?.value === "CodingMaster/10272"
      )?.[0]; // Setting Default status as active
      newStatusState.formulary = true;
      const today = new Date();
      newStatusState.effFr = [
        today.getFullYear(),
        (today.getMonth() + 1).toString().padStart(2, "0"),
        today.getDate().toString().padStart(2, "0"),
      ].join("-");

      this.setState({
        entityLevelStatus: [...this.state.entityLevelStatus, newStatusState],
      });

      this.changeState("selectedEntityName", newValue);

      // this.setState({
      //   selectedEntityName: newValue,
      // });
    } else if (reason === "remove-option") {
      const prevEntityNames = [...this.state?.selectedEntityName];

      const newlyRemovedElement = this.getUniqueElementFromLeftArray(
        prevEntityNames,
        newValue,
        "_id"
      )?.[0];

      // Note: the entityCode spelling is different here because of data modelling decisions by backend engineers
      this.setState({
        entityLevelStatus: this.state.entityLevelStatus.filter((item) => {
          const ec = item?.entityCode?._id ?? item?.entityCode;
          return ec !== newlyRemovedElement?.value;
        }),
      });

      this.changeState("selectedEntityName", newValue);

      // this.setState({
      //   selectedEntityName: [],
      // });
    }
  };

  /**
   * To Add new row in compound card state
   */
  // handleCompoundAddNewClick = () => {
  //   let newCompoundState = copy(initialCompoundState);
  //   this.setState({
  //     compondCompDTls: [...this.state.compondCompDTls, newCompoundState],
  //   });
  // };

  handleAddNewClick = (key) => {
    switch (key) {
      case "compondCompDTls":
        let newCompoundState = copy(initialCompoundState);
        this.setState({
          compondCompDTls: [...this.state.compondCompDTls, newCompoundState],
        });
        break;

      case "drugSynonyms":
        let newSynonymState = copy(initialSynonymState);
        this.setState({
          drugSynonyms: [...this.state.drugSynonyms, newSynonymState],
        });
        break;

      case "aliasDtls":
        let newAliasMappingState = copy(initialAliasMappingState);
        this.setState({
          aliasDtls: [...this.state.aliasDtls, newAliasMappingState],
        });
        break;

      case "newReturnParams":
        let newReturnApplicableState = copy(initialReturnApplicableState);
        this.setState({
          newReturnParams: [
            ...this.state.newReturnParams,
            newReturnApplicableState,
          ],
        });
        break;

      case "mappedItems":
        let newMappedDispensablesState = copy(initialMappedDispensablesState);
        this.setState({
          mappedItems: [...this.state.mappedItems, newMappedDispensablesState],
        });
        break;

      case "packSizeApplicability":
        let newPackSizeApplicabilityState = copy(
          initialPackSizeApplicabilityState
        );
        this.setState({
          packSizeApplicability: [
            ...this.state.packSizeApplicability,
            newPackSizeApplicabilityState,
          ],
        });
        break;

      case "dispenseVsChargeRoundingDefn":
        let newDispChargeState = copy(initialDispChargeState);
        this.setState({
          dispenseVsChargeRoundingDefn: [
            ...this.state.dispenseVsChargeRoundingDefn,
            newDispChargeState,
          ],
        });
        break;

      default:
        break;
    }
  };

  handleDeleteClick = (key, index) => {
    switch (key) {
      case "compondCompDTls":
        this.setState({
          compondCompDTls: this.state.compondCompDTls.filter(
            (item, i) => i !== index
          ),
        });

      case "drugSynonyms":
        this.setState({
          drugSynonyms: this.state.drugSynonyms.filter(
            (item, i) => i !== index
          ),
        });
        break;

      case "aliasDtls":
        this.setState({
          aliasDtls: this.state.aliasDtls.filter((item, i) => i !== index),
        });
        break;

      case "newReturnParams":
        this.setState({
          newReturnParams: this.state.newReturnParams.filter(
            (item, i) => i !== index
          ),
        });
        break;

      case "mappedItems":
        this.setState({
          mappedItems: this.state.mappedItems.filter((item, i) => i !== index),
        });
        break;

      case "packSizeApplicability":
        this.setState({
          packSizeApplicability: this.state.packSizeApplicability.filter(
            (item, i) => i !== index
          ),
        });
        break;

      case "dispenseVsChargeRoundingDefn":
        this.setState({
          dispenseVsChargeRoundingDefn:
            this.state.dispenseVsChargeRoundingDefn.filter(
              (item, i) => i !== index
            ),
        });
        break;

      case "entityLevelStatus":
        this.setState({
          entityLevelStatus: this.state.entityLevelStatus.filter(
            (item, i) => i !== index
          ),
          selectedEntityName: this.state.selectedEntityName.filter(
            (item, i) => i !== index
          ),
        });
        break;

      default:
        break;
    }
  };

  readDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      // async event handlers
      reader.onload = (e) => resolve(reader.result);
      reader.onerror = (e) => reject(reader.error);
      reader.readAsDataURL(file);
    });
  };

  handleChangePhotoFileInput = (e) => {
    const target = e.currentTarget;
    const fileList = target.files;
    const actualFile = target.files.item(0);

    // validate file as image
    if (!actualFile.type.startsWith("image/")) {
      alert("File is not an image");
      return;
    }

    // {
    //   fileid: res.fileid,
    //   fileName: file.name,
    //   filetype: file.type,
    //   objectid: res.objectid,
    // }

    uploadFileList([actualFile]).then((result) => {
      this.changeState("photo", {
        ...result?.[0],
        url: "",
      });

      this.readDataUrl(actualFile).then((dataUrl) => {
        this.setState({
          // file,
          photoUrl: dataUrl,
          // objectUrl: URL.createObjectURL(file)
        });
      });
    });

    // store reference to the File object and a base64 representation of it
  };

  handleFormCancelClick = () => {
    this.props.history.push(Routes.drugmastertable);
  };

  isDrugTypeCompound = (value) => {
    return value === "DrugType/10005";
  };

  isDrugTypeVehicle = (value) => {
    return value === "DrugType/10012";
  };

  formatOptionsDataForArrayObjects = (
    arrayState = [],
    propertyKey = [],
    optionValueKey = "value"
  ) => {
    return arrayState?.map((item) => {
      let finalState = { ...item };

      propertyKey.forEach((property) => {
        finalState = {
          ...finalState,
          [property]: item?.[property]?.[optionValueKey],
        };
      });
      return finalState;
      // return {
      //   ...item,
      //   [propertyKey]: item?.[propertyKey]?.[optionValueKey],
      // };
    });
  };

  isInstructionTypeProcedural = (value) => {
    return value === "CodingMaster/11151";
  };

  // Instruction mandatory error
  isProcedureInstructionsError = (instruction) => {
    if (
      this.isDrugTypeCompound(this.state.DrugType?.value) &&
      this.isInstructionTypeProcedural(instruction?.[0]?.instrType?.value)
    ) {
      // If any one instruction present then return true else false
      if (
        Boolean(instruction?.[0]?.instruction) ||
        Boolean(instruction?.[0]?.otrLangInstr)
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  checkIsFormError = (data) => {
    const errors = [];
    const uniqueErrors = [];

    if (!data?.codeStandard) {
      errors.push("Code Standard");
    }
    if (!data?.DrugCode) {
      errors.push("Drug Code");
    } else {
      if (data?.DrugCode?.toUpperCase() !== data?.DrugCode) {
        uniqueErrors.push("Drug Code should be in uppercase");
      }
    }
    if (!data?.ShortDesc) {
      errors.push("Short Description");
    }
    if (!data?.LongDesc) {
      errors.push("Long Description");
    }
    if (!data?.DrugClass) {
      errors.push("Drug Class");
    }
    if (!this.state.DrugCategory) {
      errors.push("Drug Category");
    }
    if (!this.state.DrugType) {
      errors.push("Drug Type");
    }

    if (!this.state.FormCode) {
      errors.push("Form");
    }

    if (this.state.drugotherattributes?.returnApplicable) {
      if (!this.state.returnPeriodVal) {
        errors.push("Return Period");
      }
      if (!this.state.returnPeriodUOM) {
        errors.push("Return Period UOM");
      }
      if (this.state.newReturnParams?.length === 0) {
        errors.push("Reason For Return");
      }
      if (this.state.newReturnParams?.length > 0) {
        this.state.newReturnParams.forEach((item) => {
          if (!item.returnReason) {
            errors.push("Reason For Return");
          }
        });
      }
    }

    if (!this.state.IsDispensible && !this.state.IsOrderable) {
      uniqueErrors.push(
        "Please select either Orderable or Dispensable checkbox for saving the data"
      );
    }

    // Instruction mandatory error
    // if (
    //   this.isDrugTypeCompound(this.state.DrugType?.value) &&
    //   this.isInstructionTypeProcedural(
    //     this.state.instrDtls?.[0]?.instrType?.value
    //   )
    // ) {
    //   if (
    //     !this.state.instrDtls?.[0]?.instruction &&
    //     !this.state.instrDtls?.[0]?.otrLangInstr
    //   ) {
    //     uniqueErrors.push("Instruction Description");
    //   }
    // }

    if (data?.entityLevelStatus?.length > 0) {
      data.entityLevelStatus.map((entity) => {
        if (!entity.entityType) {
          errors.push("Entity Type");
        }
        // if (!entity.entityName) {
        //   errors.push("Entity Name");
        // }
        if (!entity.status) {
          errors.push("Entity Status");
        }
        if (entity.status === "CodingMaster/10272") {
          // If status option is active, then formulary should be active.
          if (!entity.formulary) {
            uniqueErrors.push(`Formulary should be true if status is active`);
          }
        }
        if (
          entity.status === "CodingMaster/10273" ||
          entity.status === "CodingMaster/10274"
        ) {
          if (entity.formulary) {
            uniqueErrors.push(
              `Cannot mark formulary as active if status is inactive or discontinued`
            );
          }
        }
        if (entity.effFr && entity.effTo) {
          if (entity.effFr > entity.effTo) {
            uniqueErrors.push(
              "Effective From should be less than Effective To"
            );
          }
        }
        if (entity.effTo) {
          if (!entity.reason) {
            uniqueErrors.push(
              "Reason is required if Effective To date is present"
            );
          }
        }
      });
    } else {
      uniqueErrors.push("Status cannot be empty");
    }

    let validationErrors = [...new Set(errors)];

    if (validationErrors.length > 0) {
      this.props.alert.setSnack({
        open: true,
        msg: `${validationErrors.join(", ")} are required!.`,
        severity: "error",
      });
    } else if (uniqueErrors.length > 0) {
      this.props.alert.setSnack({
        open: true,
        msg: `${uniqueErrors.join(", ")}`,
        severity: "error",
      });
    }

    return validationErrors?.length > 0 || uniqueErrors?.length > 0
      ? true
      : false;
  };

  getDrugStatus = () => {
    const { entityLevelStatus } = this.state;
    const status = entityLevelStatus?.map((item) => {
      return item?.status?.value;
    });

    const isActivePresent = status?.some((x) => x === statusOptions.active);

    return isActivePresent;
  };

  validateAndSetSelfDrug = (itemToBeMapped) => {
    try {
      const mappedItems = [...this.state.mappedItems];

      const selfDrugIndex = mappedItems.findIndex(
        (item) => item?.dispensedrugcode?.isSelfDrug
      );

      if (selfDrugIndex > -1) {
        mappedItems.splice(selfDrugIndex, 1);
      }

      mappedItems.unshift({
        dispensedrugcode: itemToBeMapped,
        seqno: null,
      });

      this.setState({
        mappedItems: mappedItems,
      });
    } catch (error) {
      console.log(error);
    }
  };

  validateAndRemoveSelfDrug = () => {
    const mappedItems = [...this.state.mappedItems];

    const selfDrugIndex = mappedItems.findIndex(
      (item) => item?.dispensedrugcode?.isSelfDrug
    );

    if (selfDrugIndex > -1) {
      mappedItems.splice(selfDrugIndex, 1);
    }

    this.setState({
      mappedItems: mappedItems,
    });
  };

  constructSelfDrug = (drugCode, shortDescription) => {
    return {
      label: `${drugCode} - ${shortDescription}`,
      value: drugCode,
      isSelfDrug: true,
    };
  };

  modifyMappedDispensableWithSelfDrug = (actionName) => {
    const { DrugCode, ShortDesc, IsDispensible } = this.state;
    const {
      constructSelfDrug,
      validateAndSetSelfDrug,
      validateAndRemoveSelfDrug,
    } = this;

    switch (actionName) {
      case "DrugCode": {
        if (IsDispensible) {
          const selfDrug = constructSelfDrug(DrugCode, ShortDesc);
          validateAndSetSelfDrug(selfDrug);
        }
        return;
      }
      case "ShortDesc": {
        if (IsDispensible) {
          const selfDrug = constructSelfDrug(DrugCode, ShortDesc);
          validateAndSetSelfDrug(selfDrug);
        }
        return;
      }
      case "IsDispensible": {
        if (IsDispensible) {
          const selfDrug = constructSelfDrug(DrugCode, ShortDesc);
          validateAndSetSelfDrug(selfDrug);
        } else {
          validateAndRemoveSelfDrug();
        }
        return;
      }
      case "IsOrderable": {
        if (IsDispensible) {
          const selfDrug = constructSelfDrug(DrugCode, ShortDesc);
          validateAndSetSelfDrug(selfDrug);
        } else {
          validateAndRemoveSelfDrug();
        }
        return;
      }
      default: {
        return;
      }
    }
  };

  handleIsOrderableChange = (value) => {
    this.setState({ IsOrderable: value });

    if (this.state.IsDispensible === true && value === true) {
      let newMappedDispensablesState = copy(initialMappedDispensablesState);

      newMappedDispensablesState = {
        ...newMappedDispensablesState,
        dispensedrugcode: {
          label: `${this.state.DrugCode} ${this.state.ShortDesc}`,
          value: this.state.DrugCode,
          selfDrug: true,
        },
        seqno: null,
      };

      const selfDrugIndex = this.state.mappedItems.indexOf((x) => x?.selfDrug);

      if (selfDrugIndex === 0) {
        const obj = [...this.state.mappedItems];
        this.setState({
          mappedItems: obj?.splice(
            selfDrugIndex,
            1,
            newMappedDispensablesState
          ),
        });
      } else {
        const obj = [...this.state.mappedItems];
        this.setState({
          mappedItems: [
            newMappedDispensablesState,
            ...obj?.splice(selfDrugIndex, 1),
          ],
        });
      }

      // this.setState({
      //   mappedItems: [newMappedDispensablesState, ...this.state.mappedItems],
      // });
    }
  };

  closeBackDrop = () => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  };

  onNextClickOnViewMode = () => {
    this.props.history.push(Routes.alertrestriction, {
      docId: this.props?.location?.state?.docId ?? "",
      docKey: this.props?.location?.state?.docKey ?? "",
      isEdit:
        Boolean(this.props?.location?.state?.docId ?? undefined) ||
        Boolean(this.props?.location?.state?.docKey ?? undefined),
      drugCode: this.state.DrugCode,
      drugDescription: this.state.ShortDesc,
      Activestatus:
        this.props.location?.state?.isActive || this.state.drugActiveState,
    });
  };

  handleFormSaveAndProceedClick = () => {
    this.setState({
      loading: true,
    });
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message:
        this.props?.location?.state?.docId &&
          this.props?.location?.state?.docKey
          ? "Updating Drug..."
          : "Saving Drug...",
    });

    const { dropdownOptions } = this.props;
    const { data: options } = dropdownOptions;

    let payload;

    // Payload to send to API

    if (
      this.props?.location?.state?.docId &&
      this.props?.location?.state?.docKey
    ) {
      payload = {
        isEdit: true,
        _key: this.props?.location?.state?.docKey,
        photo:
          this.state.IsDispensible && this.state.photo
            ? Array.isArray(this.state.photo)
              ? this.state.photo
              : [this.state.photo]
            : [],
        codeStandard: this.state.codeStandard?.value ?? "", //
        DrugCode: this.state.DrugCode, //
        ShortDesc: this.state.ShortDesc, //
        LongDesc: this.state.LongDesc, //
        DrugClass: this.state.DrugClass?.value ?? "", //
        DrugCategory: this.state.DrugCategory?.value ?? "", //
        DrugType: this.state.DrugType?.value ?? "", //
        FormCode: this.state.FormCode?.value ?? "", //
        DrugGenericId: this.state.DrugGenericId?.value ?? "", //
        IsDispensible: this.state.IsDispensible, //
        orderable: this.state.IsOrderable, //
        status: this.getDrugStatus(),

        // Compound State
        compondCompDTls: this.isDrugTypeCompound(this.state.DrugType?.value)
          ? this.formatOptionsDataForArrayObjects(
            this.state.compondCompDTls?.map((item) => {
              const {
                compDrugDescription,
                compStrengthValue,
                compStrengthVolume,
                compStrengthUOM,
                compStrengthVolumeUoM,
                ...remainingProperty
              } = item;
              return {
                ...remainingProperty,
                // compDrugDescription: item?.compDrugCode?.ShortDesc,
                // compStrengthUOM: options?.drug_uom?.filter(
                //   (x) =>
                //     x?.value ===
                //     item?.compDrugCode?.StrengthValuesPossible?.[0]
                //       ?.StrengthUOM
                // )?.[0]?.value,
                // compStrengthValue:
                //   item?.compDrugCode?.StrengthValuesPossible?.[0]
                //     ?.StrengthValue,
                // compStrengthVolume:
                //   item?.compDrugCode?.StrengthValuesPossible?.[0]
                //     ?.StrengthVolume,
                // compStrengthVolumeUoM: options?.drug_uom?.filter(
                //   (x) =>
                //     x?.value ===
                //     item?.compDrugCode?.StrengthValuesPossible?.[0]
                //       ?.StrengthVolumeUoM
                // )?.[0]?.value,

                // compStrengthValue:
                //   item?.compDrugCode?.StrengthValuesPossible?.[0]
                //     ?.StrengthValue,
                // compStrengthVolume:
                //   item?.compDrugCode?.StrengthValuesPossible?.[0]
                //     ?.StrengthVolume,
                // compStrengthVolumeUoM:
                //   item?.compDrugCode?.StrengthValuesPossible?.[0]
                //     ?.StrengthVolumeUoM,
              };
            }),
            ["compDrugCode", "compQtyUom"]
          )
          : [],

        drugSynonyms: this.formatOptionsDataForArrayObjects(
          this.state.drugSynonyms,
          ["synonymType"]
        ),
        aliasDtls: this.formatOptionsDataForArrayObjects(this.state.aliasDtls, [
          "aliasType",
        ]),
        StrengthValuesPossible: this.state.StrengthValuesPossible?.map(
          (strength) => {
            return {
              ...strength,
              StrengthUOM: strength?.StrengthUOM?.value,
              StrengthVolumeUoM: strength?.StrengthVolumeUoM?.value,
              OrderUOMAllowed: this.formatOptionsDataForArrayObjects(
                strength?.OrderUOMAllowed,
                ["AllowedOrderDoseUOM", "AllowedOrderDoseVolUoM"]
              ),
            };
          }
        ),

        // Drug Other Attribute State
        ...{
          Additive: this.state.drugotherattributes.Additive,
          ApplicableForCompounding:
            this.state.drugotherattributes.ApplicableForCompounding,
          DispenseviaprescriptionOnly:
            this.state.drugotherattributes.DispenseviaprescriptionOnly,
          CounsellingRequired:
            this.state.drugotherattributes.CounsellingRequired,
          returnApplicable: this.state.drugotherattributes.returnApplicable,
        },

        // Stability Period for vehicle - Only sent on Drug Type Vehicle
        ...(this.isDrugTypeVehicle(this.state.DrugType?.value) && {
          stability: this.state.drugotherattributes.stability,
          stabilityUOM:
            this.state.drugotherattributes?.stabilityUOM?.value ?? "",
        }),

        // Return Applicable
        ...(this.state.drugotherattributes.returnApplicable && {
          returnPeriodVal: this.state.returnPeriodVal,
          returnPeriodUOM: this.state.returnPeriodUOM?.value,
          partialQtyAllow: this.state.partialQtyAllow,
          returnPolicy: this.state.returnPolicy,
          newReturnParams: this.formatOptionsDataForArrayObjects(
            this.state.newReturnParams,
            ["returnReason"]
          ),
        }),

        // Unit Of Measure -- Dispense & Charge
        ...(this.state.IsDispensible && {
          packSizeApplicability: this.formatOptionsDataForArrayObjects(
            this.state.packSizeApplicability,
            [
              "applicableInd",
              "packSizeUoM",
              "packUnitUoM",
              "applicablityUOM",
              "roundtype",
            ]
          )?.map((x) => ({ ...x, packSize: parseFloat(x?.packSize) })),
        }),
        // Unit Of Measure -- Dispense & Charge End

        RoutesAllowed: this.state.orderDefaults.RoutesAllowed?.map(
          (x) => x?.value
        ),
        DefaultRouteCode: this.state.orderDefaults.DefaultRouteCode?.value,
        FrequencyRules: this.formatOptionsDataForArrayObjects(
          this.state.orderDefaults.FrequencyRules,
          [
            "freqcode",
            "durnUOM",
            "doseUOM",
            "InfuseRateUom",
            "InfuseOverDurType",
          ]
        ),

        // Dispense Default
        alloweddisptype: this.state.DrugDispRules?.alloweddisptype?.map(
          (option) => option?.value
        ),
        defaultdisptype: this.state.DrugDispRules?.defaultdisptype?.value,
        DrugDispRules: this.formatOptionsDataForArrayObjects(
          this.state.DrugDispRules?.MaxDispDurnRulebyEnctype,
          ["dfltDispDurationUOM", "maxDispDurationUOM", "Action"]
        ).map((x) => ({
          ...x,
          dfltDispDurationVal: Number(x?.dfltDispDurationVal),
          maxDispDurationVal: Number(x?.maxDispDurationVal),
        })),

        instrDtls: this.formatOptionsDataForArrayObjects(this.state.instrDtls, [
          "instrType",
        ]),

        directiontag: this.state.directionDtls?.length
          ? this.state.directionDtls?.[0]?.directiontag
          : "",
        directiontaglocal: this.state.directionDtls?.length
          ? this.state.directionDtls?.[0]?.directiontaglocal
          : "",

        // ...(this.state.IsDispensible === true &&
        // this.state.IsOrderable === false
        //   ? {}
        //   : {
        mappedItems: this.formatOptionsDataForArrayObjects(
          this.state.mappedItems,
          ["dispensedrugcode"]
        ),
        // }),

        entityLevelStatus: this.formatOptionsDataForArrayObjects(
          this.state.entityLevelStatus?.map((x) => ({
            ...x,
            effFr: moment(x?.effFr).valueOf(),
            effTo: Boolean(x?.effTo) ? moment(x?.effTo).valueOf() : 0,
            entityCode: x?.entityCode?._id ?? x?.entityCode,
          })),
          ["entityType", "status"]
        ),
      };
    } else {
      payload = {
        id: 0,
        photo:
          this.state.IsDispensible && this.state.photo
            ? [this.state.photo]
            : [],
        codeStandard: this.state.codeStandard?.value ?? "", //
        DrugCode: this.state.DrugCode, //
        ShortDesc: this.state.ShortDesc, //
        LongDesc: this.state.LongDesc, //
        DrugClass: this.state.DrugClass?.value ?? "", //
        DrugCategory: this.state.DrugCategory?.value ?? "", //
        DrugType: this.state.DrugType?.value ?? "", //
        FormCode: this.state.FormCode?.value ?? "", //
        DrugGenericId: this.state.DrugGenericId?.value ?? "", //
        IsDispensible: this.state.IsDispensible, //
        orderable: this.state.IsOrderable, //
        status: this.getDrugStatus(),

        // Compound State
        compondCompDTls: this.isDrugTypeCompound(this.state.DrugType?.value)
          ? this.formatOptionsDataForArrayObjects(
            this.state.compondCompDTls?.map((item) => {
              const {
                compDrugDescription,
                compStrengthValue,
                compStrengthVolume,
                compStrengthUOM,
                compStrengthVolumeUoM,
                ...remainingProperty
              } = item;
              return {
                ...remainingProperty,
                // compDrugDescription: item?.compDrugCode?.ShortDesc,
                // compDrugDescription: item?.compDrugCode?.ShortDesc,
                // compStrengthUOM: options?.drug_uom?.filter(
                //   (x) =>
                //     x?.value ===
                //     item?.compDrugCode?.StrengthValuesPossible?.[0]
                //       ?.StrengthUOM
                // )?.[0]?.value,
                // compStrengthValue:
                //   item?.compDrugCode?.StrengthValuesPossible?.[0]
                //     ?.StrengthValue,
                // compStrengthVolume:
                //   item?.compDrugCode?.StrengthValuesPossible?.[0]
                //     ?.StrengthVolume,
                // compStrengthVolumeUoM: options?.drug_uom?.filter(
                //   (x) =>
                //     x?.value ===
                //     item?.compDrugCode?.StrengthValuesPossible?.[0]
                //       ?.StrengthVolumeUoM
                // )?.[0]?.value,

                // compStrengthUOM:
                //   item?.compDrugCode?.StrengthValuesPossible?.[0]
                //     ?.StrengthUOM,
                // compStrengthValue:
                //   item?.compDrugCode?.StrengthValuesPossible?.[0]
                //     ?.StrengthValue,
                // compStrengthVolume:
                //   item?.compDrugCode?.StrengthValuesPossible?.[0]
                //     ?.StrengthVolume,
                // compStrengthVolumeUoM:
                //   item?.compDrugCode?.StrengthValuesPossible?.[0]
                //     ?.StrengthVolumeUoM,
              };
            }),
            ["compDrugCode", "compQtyUom"]
          )
          : [],

        drugSynonyms: this.formatOptionsDataForArrayObjects(
          this.state.drugSynonyms,
          ["synonymType"]
        ),
        aliasDtls: this.formatOptionsDataForArrayObjects(this.state.aliasDtls, [
          "aliasType",
        ]),
        StrengthValuesPossible: this.state.StrengthValuesPossible?.map(
          (strength) => {
            return {
              ...strength,
              StrengthUOM: strength?.StrengthUOM?.value,
              StrengthVolumeUoM: strength?.StrengthVolumeUoM?.value,
              OrderUOMAllowed: this.formatOptionsDataForArrayObjects(
                strength?.OrderUOMAllowed,
                ["AllowedOrderDoseUOM", "AllowedOrderDoseVolUoM"]
              ),
            };
          }
        ),

        // Drug Other Attribute State
        ...{
          Additive: this.state.drugotherattributes.Additive,
          ApplicableForCompounding:
            this.state.drugotherattributes.ApplicableForCompounding,
          DispenseviaprescriptionOnly:
            this.state.drugotherattributes.DispenseviaprescriptionOnly,
          CounsellingRequired:
            this.state.drugotherattributes.CounsellingRequired,
          returnApplicable: this.state.drugotherattributes.returnApplicable,
        },

        // Stability Period for vehicle - Only sent on Drug Type Vehicle
        ...(this.isDrugTypeVehicle(this.state.DrugType?.value) && {
          stability: this.state.drugotherattributes.stability,
          stabilityUOM:
            this.state.drugotherattributes?.stabilityUOM?.value ?? "",
        }),

        // Return Applicable
        ...(this.state.drugotherattributes.returnApplicable && {
          returnPeriodVal: this.state.returnPeriodVal,
          returnPeriodUOM: this.state.returnPeriodUOM?.value,
          partialQtyAllow: this.state.partialQtyAllow,
          returnPolicy: this.state.returnPolicy,
          newReturnParams: this.formatOptionsDataForArrayObjects(
            this.state.newReturnParams,
            ["returnReason"]
          ),
        }),

        // Dispense Default
        alloweddisptype: this.state.DrugDispRules?.alloweddisptype?.map(
          (option) => option?.value
        ),
        defaultdisptype: this.state.DrugDispRules?.defaultdisptype?.value,
        DrugDispRules: this.formatOptionsDataForArrayObjects(
          this.state.DrugDispRules?.MaxDispDurnRulebyEnctype,
          ["dfltDispDurationUOM", "maxDispDurationUOM", "Action"]
        ).map((x) => ({
          ...x,
          dfltDispDurationVal: Number(x?.dfltDispDurationVal),
          maxDispDurationVal: Number(x?.maxDispDurationVal),
        })),

        // Unit Of Measure -- Dispense & Charge
        ...(this.state.IsDispensible && {
          packSizeApplicability: this.formatOptionsDataForArrayObjects(
            this.state.packSizeApplicability,
            [
              "applicableInd",
              "packSizeUoM",
              "packUnitUoM",
              "applicablityUOM",
              "roundtype",
            ]
          )?.map((x) => ({ ...x, packSize: parseFloat(x?.packSize) })),
        }),
        // Unit Of Measure -- Dispense & Charge End

        RoutesAllowed: this.state.orderDefaults.RoutesAllowed?.map(
          (x) => x?.value
        ),
        DefaultRouteCode: this.state.orderDefaults.DefaultRouteCode?.value,
        FrequencyRules: this.formatOptionsDataForArrayObjects(
          this.state.orderDefaults.FrequencyRules,
          [
            "freqcode",
            "durnUOM",
            "doseUOM",
            "InfuseRateUom",
            "InfuseOverDurType",
          ]
        ),

        instrDtls: this.formatOptionsDataForArrayObjects(this.state.instrDtls, [
          "instrType",
        ]),

        directiontag: this.state.directionDtls?.length
          ? this.state.directionDtls?.[0]?.directiontag
          : "",
        directiontaglocal: this.state.directionDtls?.length
          ? this.state.directionDtls?.[0]?.directiontaglocal
          : "",

        // ...(this.state.IsDispensible === true &&
        //   this.state.IsOrderable === false
        //   ? {}
        //   : {
        mappedItems: this.formatOptionsDataForArrayObjects(
          this.state.mappedItems,
          ["dispensedrugcode"]
        ),
        // }),

        entityLevelStatus: this.formatOptionsDataForArrayObjects(
          this.state.entityLevelStatus?.map((x) => ({
            ...x,
            effFr: moment(x?.effFr).valueOf(),
            effTo: Boolean(x?.effTo) ? moment(x?.effTo).valueOf() : 0,
          })),
          ["entityType", "status"]
        ),
      };
    }
    // Payload Ends

    if (this.checkIsFormError(payload)) {
      this.setState({
        loading: false,
      });
      this.closeBackDrop();
      return;
    }

    const getErrorMessage = (errorMessage = "Something went wrong!") => {
      const fieldName = errorMessage?.match(/\["(.*?)\"]/)?.[1];
      if (fieldName === "DrugCode" && errorMessage?.includes("unique")) {
        return "Drug Code Already Exists";
      }

      return errorMessage;
    };

    Promise.resolve(
      this.props.DRUG_CATALOG_MASTER_SAVE({
        body: payload,
      })
    )
      .then(async (res) => {
        if (res?.payload?.data?.validation_error?.length > 0) {
          this.props.alert.setSnack({
            open: true,
            msg: getErrorMessage(
              res?.payload?.data?.validation_error?.[0]?.Errormsg
            ),
            severity: "error",
          });
          this.closeBackDrop();
          return;
        }

        if (this.state.IsDispensible) {
          // Read the saved drug
          // Construct mapped dispensable payload
          // Save the mapped dispensable in the same drug.

          const _id = res?.payload?.data?.Result?.[0]?.properties?.doc?._id;

          const mappedDispensableRead =
            await this.props.DRUG_MAPPED_DISPENSABLE_READ({ _id: _id });

          const selfDrug = mappedDispensableRead?.payload?.data?.[0];

          const mpItems = isIterable(selfDrug?.mappedItems)
            ? [...selfDrug?.mappedItems]
            : [];

          mpItems[0] = {
            ...(isIterable(mpItems?.[0]) ? mpItems?.[0] : {}),
            dispensedrugcode: selfDrug?._id,
            seqno: this.state.mappedItems?.[0]?.seqno,
          };

          const updatedPayload = {
            mappedItems: mpItems,
            _key: selfDrug?._key,
          };

          await this.props.DRUG_CATALOG_MAPPED_ITEM_SAVE({
            body: updatedPayload,
          });

          this.setState({
            loading: false,
          });
          // mappedDispensableSave?.payload?.data
        }

        this.setState({
          loading: false,
        });
        this.props.alert.setSnack({
          open: true,
          msg: this.props?.location?.state?.docId
            ? `Drug Updated Successfully`
            : `Drug Added Successfully`,
          severity: "success",
        });
        let ID = res?.payload?.data?.Result?.[0]?.properties?.doc?._id;
        let Key = res?.payload?.data?.Result?.[0]?.properties?.doc?._key;
        this.props.history.push(Routes.alertrestriction, {
          docId: this.props?.location?.state?.docId ?? ID,
          docKey: this.props?.location?.state?.docKey ?? Key,
          isEdit:
            Boolean(this.props?.location?.state?.docId ?? undefined) ||
            Boolean(this.props?.location?.state?.docKey ?? undefined),
          drugCode: this.state.DrugCode,
          drugDescription: this.state.ShortDesc,
          Activestatus:
            this.props.location?.state?.isActive || this.state.drugActiveState,
        });

        this.closeBackDrop();
      })
      .catch((error) => {
        alert(error);
        console.error(error);
        this.setState({
          loading: false,
        });
        console.error("ERROR WHILE SAVING");
      });

    // Push next route to route stack on Success validation
  };

  async componentDidMount() {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }

    if (this.props?.location?.state?.docId) {
      this.props.backDrop.setBackDrop({
        open: true,
        message: "Loading...",
      });
    }
    this.props.DIRECTION_TAGS()
    Promise.resolve(
      this.props.DRUG_CATALOG_MASTER_DROPDOWN_QUERY({
        itemCodeId: this.props?.location?.state?.docId,
      })
    )
      .then(() => {
        if (this.props?.location?.state?.docId) {
          this.preloadDrugData();
        }
      })
      .catch((e) => {
        console.error(e);
      });

    // await this.props.DRUG_CATALOG_MASTER_SINGLE_READ({
    //   id: this.props?.location?.state?.docId,
    // });

    // if (this.props?.location?.state?.docId) {
    //   this.preloadDrugData();
    // }

    /// setlogger && dispatch of all events

    /// fetch all permission
  }

  ///fetching all permission

  preloadDrugData = () => {
    const { dropdownOptions } = this.props;
    const { data: options } = dropdownOptions;

    const getOptionWithLabelValue = (option, type) => {
      switch (type) {
        case 1:
          return {
            ...option,
            label: option?.shortdesc ? option?.shortdesc : "",
            value: option?._id,
          };

        case 2:
          return {
            ...option,
            label: option?.display ? option?.display : "",
            value: option?._id ? option?._id : "",
          };

        case 3:
          return {
            ...option,
            label: option?.ShortDesc ? option?.ShortDesc : "",
            value: option?._id,
          };

        default: {
          return {
            ...option,
            label: option,
            value: option?._id,
          };
        }
      }
    };

    Promise.resolve(
      this.props.DRUG_CATALOG_MASTER_SINGLE_READ({
        id: this.props?.location?.state?.docId,
      })
    )
      .then(async (res) => {
        const data = res?.payload?.data?.[0] || {};

        const fetchedEntityType = data?.entityLevelStatus?.[0]?.entityType;

        await this.props.DRUG_CATALOG_MASTER_ENTITY_NAME_OPTIONS({
          entityTypeCode: fetchedEntityType?.code,
        });

        const { data: eNameOptions } = this.props.entityNameOptions;

        this.setState(
          {
            // status: data.status,
            photo: data?.photo,
            photoUrl: getImgUrl(data?.photo?.[0]?.fileid),
            codeStandard: getOptionWithLabelValue(data?.codeStandard, 1),
            DrugCode: data?.DrugCode,
            ShortDesc: data?.ShortDesc,
            LongDesc: data?.LongDesc,
            DrugClass: getOptionWithLabelValue(data?.DrugClass, 1),
            DrugCategory: getOptionWithLabelValue(data?.DrugCategory, 1),
            DrugType: getOptionWithLabelValue(data?.DrugType, 1),
            FormCode: getOptionWithLabelValue(data?.FormCode, 1),
            DrugGenericId: getOptionWithLabelValue(data?.DrugGenericId, 1),
            IsDispensible: data?.IsDispensible,
            IsOrderable: data?.orderable,
            compondCompDTls: data?.compondCompDTls?.map((x) => {
              const selectedDrugCode = x?.compDrugCode?.[0];
              return {
                ...x,
                compQtyUom: getOptionWithLabelValue(x?.compQtyUom, 1),
                compDrugCode: getOptionWithLabelValue(selectedDrugCode, 3),
                compStrengthValue:
                  selectedDrugCode?.StrengthValuesPossible?.[0]?.StrengthValue,
                compStrengthVolume:
                  selectedDrugCode?.StrengthValuesPossible?.[0]?.StrengthVolume,
                compStrengthUOM: options?.drug_uom?.filter(
                  (option) =>
                    option?.value ===
                    selectedDrugCode?.StrengthValuesPossible?.[0]?.StrengthUOM
                )?.[0]?.value,
                compStrengthVolumeUoM: options?.drug_uom?.filter(
                  (option) =>
                    option?.value ===
                    selectedDrugCode?.StrengthValuesPossible?.[0]
                      ?.StrengthVolumeUoM
                )?.[0]?.value,
              };
            }),
            drugSynonyms: data?.drugSynonyms?.map((x) => ({
              ...x,
              synonymType: getOptionWithLabelValue(x?.synonymType, 1),
            })),
            aliasDtls: data?.aliasDtls?.map((x) => ({
              ...x,
              aliasType: getOptionWithLabelValue(x?.aliasType, 1),
            })),
            mappedItems: data?.mappedItems?.map((x, index) => ({
              ...x,
              dispensedrugcode: {
                ...getOptionWithLabelValue(x?.dispensedrugcode, 3),
                ...(index === 0 &&
                  data?.IsDispensible && { isSelfDrug: data?.IsDispensible }),
              },
            })),
            drugotherattributes: {
              Additive: data?.Additive,
              ApplicableForCompounding: data?.ApplicableForCompounding,
              DispenseviaprescriptionOnly: data?.DispenseviaprescriptionOnly,
              CounsellingRequired: data?.CounsellingRequired,
              returnApplicable: data?.returnApplicable,
              stability: data?.stability?.toString() ?? "",
              stabilityUOM:
                getOptionWithLabelValue(data?.stabilityUOM, 1) ?? null,
            },

            ...(data?.returnApplicable && {
              returnPeriodVal: data?.returnPeriodVal,
              returnPeriodUOM: getOptionWithLabelValue(
                data?.returnPeriodUOM,
                2
              ),
              partialQtyAllow: data?.partialQtyAllow,
              returnPolicy: data?.returnPolicy,
              newReturnParams: data?.newReturnParams?.map((x) => ({
                ...x,
                returnReason: getOptionWithLabelValue(x?.returnReason, 2),
              })),
            }),

            orderDefaults: {
              RoutesAllowed: data?.RoutesAllowed?.map((x) => ({
                ...getOptionWithLabelValue(x, 1),
              })),
              DefaultRouteCode: getOptionWithLabelValue(
                data?.DefaultRouteCode,
                1
              ),
              FrequencyRules: data?.FrequencyRules?.map((x) => ({
                ...x,
                freqcode: getOptionWithLabelValue(x?.freqcode, 1),
                durnUOM: getOptionWithLabelValue(x?.durnUOM, 1),
                doseUOM: getOptionWithLabelValue(x?.doseUOM, 1),
                InfuseRateUom: getOptionWithLabelValue(x?.InfuseRateUom, 1),
                InfuseOverDurType: getOptionWithLabelValue(
                  x?.InfuseOverDurType,
                  1
                ),
              })),
            },
            StrengthValuesPossible: data?.StrengthValuesPossible?.map((x) => {
              return {
                ...x,
                StrengthUOM: getOptionWithLabelValue(x?.StrengthUOM, 1),
                StrengthVolumeUoM: getOptionWithLabelValue(
                  x?.StrengthVolumeUoM,
                  1
                ),
                OrderUOMAllowed: x?.OrderUOMAllowed?.map((y) => {
                  return {
                    ...y,
                    AllowedOrderDoseUOM: getOptionWithLabelValue(
                      y?.AllowedOrderDoseUOM,
                      1
                    ),
                    AllowedOrderDoseVolUoM: getOptionWithLabelValue(
                      y?.AllowedOrderDoseVolUoM,
                      1
                    ),
                  };
                }),
              };
            }),

            DrugDispRules: {
              ...data?.DrugDispRules,
              alloweddisptype: data?.alloweddisptype?.map((x) => ({
                ...getOptionWithLabelValue(x, 1),
              })),
              defaultdisptype: getOptionWithLabelValue(
                data?.defaultdisptype,
                1
              ),
              MaxDispDurnRulebyEnctype: data?.DrugDispRules?.map((y) => ({
                ...y,
                dfltDispDurationUOM: getOptionWithLabelValue(
                  y?.dfltDispDurationUOM,
                  1
                ),
                maxDispDurationUOM: getOptionWithLabelValue(
                  y?.maxDispDurationUOM,
                  1
                ),
                Action: getOptionWithLabelValue(y?.Action, 1),
              })),
            },

            instrDtls: data?.instrDtls?.map((x) => ({
              ...x,
              instrType: getOptionWithLabelValue(x?.instrType, 1),
            })),

            directionDtls:
              Boolean(data?.directiontag) || Boolean(data?.directiontaglocal)
                ? [
                  {
                    directiontag: data?.directiontag,
                    directiontaglocal: data?.directiontaglocal,
                  },
                ]
                : [],

            // directionDtls: data?.directionDtls?.map((x) => ({
            //   ...x,
            //   directionType: getOptionWithLabelValue(x?.directionType, 1),
            // })),

            // Entity Level Status - Supporting States
            ...(data?.entityLevelStatus?.length > 0 && {
              showStatuspage: true,
              selectedEntityType: getOptionWithLabelValue(
                data?.entityLevelStatus?.[0]?.entityType,
                1
              ),
              selectedEntityName: data?.entityLevelStatus?.map((x) => {
                return eNameOptions?.filter(
                  (y) => y?._id === x?.entityCode?._id
                )?.[0];
              }),
              // selectedEntityName: this.getUniqueElementFromLeftArray(
              //   options?.entity_Name,
              //   data?.entityLevelStatus?.map((x) => ({
              //     ...x,
              //     entityType: x?.entityType,
              //   }))
              // ),
            }),

            entityLevelStatus: data?.entityLevelStatus?.map((x) => ({
              ...x,
              effFr: moment(x?.effFr).format("YYYY-MM-DD"),
              effTo: Boolean(x?.effTo)
                ? moment(x?.effTo).format("YYYY-MM-DD")
                : "",
              entityType: getOptionWithLabelValue(x?.entityType, 1),
              status: getOptionWithLabelValue(x?.status, 1),
            })),
            packSizeApplicability: data?.packSizeApplicability?.map((x) => ({
              ...x,
              applicableInd: getOptionWithLabelValue(x?.applicableInd, 1),
              applicablityUOM: getOptionWithLabelValue(x?.applicablityUOM, 1),
              packSizeUoM: getOptionWithLabelValue(x?.packSizeUoM, 1),
              packUnitUoM: getOptionWithLabelValue(x?.packUnitUoM, 1),
              roundtype: getOptionWithLabelValue(x?.roundtype, 1),
            })),
          },
          () => {
            this.verifyIfDrugShouldBeActive();
          }
        );
      })
      .catch(() => { });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { loading: oldLoading } = this.props?.drugData || {};
    const { loading } = nextProps?.drugData || {};

    if (oldLoading !== loading) {
      if (!loading) {
        this.props.backDrop.setBackDrop({
          open: false,
          message: "",
        });
      }
    }

    const { loading: oldSaveLoading } = this.state.loading;
    const { loading: saveLoading } = nextState.loading;

    if (oldSaveLoading !== saveLoading) {
      if (!saveLoading) {
        this.props.backDrop.setBackDrop({
          open: false,
          message: "",
        });
      }
    }

    return true;
  }

  shouldShowMappedDispensable = () => {
    const { IsDispensible, IsOrderable } = this.state;

    if (IsDispensible === false && IsOrderable === true) {
      return true;
    }

    if (IsDispensible === true && IsOrderable === true) {
      return true;
    }

    if (IsDispensible === true && IsOrderable === false) {
      return false;
    }

    if (IsDispensible === false && IsOrderable === false) {
      return true;
    }
  };

  getTabs = () => {
    const { dropdownOptions } = this.props;
    const { data: options } = dropdownOptions;
    const isDrugActive =
      this.props.location?.state?.isActive || this.state.drugActiveState;

    const tabObject = {
      Synonym: {
        header: "Synonym",
        fullWidth: true,
        body: (
          <ALL.Synonymcomp
            shouldDisable={isDrugActive}
            propsState={this.state}
            setFun={this.changeStateForArrayElement}
            stateName={"drugSynonyms"}
            statevalue={this.state.drugSynonyms}
            onAddNewClick={this.handleAddNewClick}
            onDeleteClick={this.handleDeleteClick}
            options={{ synonym_type: options?.synonym_type }}
          />
        ),
      },
      AliasMapping: {
        header: "Alias Mapping",
        fullWidth: true,
        body: (
          <ALL.Aliasmappingcomp
            shouldDisable={isDrugActive}
            propsState={this.state}
            setFun={this.changeStateForArrayElement}
            stateName={"aliasDtls"}
            statevalue={this.state.aliasDtls}
            onAddNewClick={this.handleAddNewClick}
            onDeleteClick={this.handleDeleteClick}
            options={{ source: options?.source }}
          />
        ),
      },
      AvailableStrength: {
        header: "Available Strength",
        fullWidth: true,
        body: (
          <ALL.Availablestrengthtabcomp
            shouldDisable={isDrugActive}
            propsState={this.state}
            setFun={(value) => {
              this.changeState("StrengthValuesPossible", value);
            }}
            stateName={"StrengthValuesPossible"}
            statevalue={this.state.StrengthValuesPossible}
            options={{ strength_uom: options?.strength_uom }}
          />
        ),
      },
      DrugOtherAttributes: {
        header: "Drug Other Attributes",
        fullWidth: true,
        body: (
          <ALL.Drugotherattributes
            isDrugTypeVehicle={this.isDrugTypeVehicle(
              this.state.DrugType?.value
            )}
            shouldDisable={isDrugActive}
            propsState={this.state}
            setFun={this.changeStateForObjectElement}
            stateName={"drugotherattributes"}
            statevalue={this.state.drugotherattributes}
            options={{
              // duration_uom: options?.duration_uom,
              uom_time_type: options?.uom_time_type,
            }}
          />
        ),
      },
      ReturnApplicable: {
        header: "Return Applicable",
        fullWidth: true,
        body: (
          <ALL.ReturnApplicable
            shouldDisable={isDrugActive}
            propsState={this.state}
            setFun={this.changeStateForArrayElement}
            stateName={"newReturnParams"}
            statevalue={this.state.newReturnParams}
            onAddNewClick={this.handleAddNewClick}
            onDeleteClick={this.handleDeleteClick}
            options={{
              duration_uom: options?.duration_uom,
              return_reason: options?.return_reason,
            }}
            // Other States
            returnPeriodVal={this.state.returnPeriodVal}
            returnPeriodUOM={this.state.returnPeriodUOM}
            partialQtyAllow={this.state.partialQtyAllow}
            returnPolicy={this.state.returnPolicy}
            changeOtherState={this.changeState}
          />
        ),
      },
      UnitOfMeasure: {
        header: "UOM for Dispense & Charge",
        fullWidth: true,
        body: (
          <ALL.Unitofmeasurecomp
            shouldDisable={isDrugActive}
            propsState={this.state}
            setFun={this.changeStateForArrayElement}
            stateName={"packSizeApplicability"}
            statevalue={this.state.packSizeApplicability}
            onAddNewClick={this.handleAddNewClick}
            onDeleteClick={this.handleDeleteClick}
            options={{
              applicability: options?.applicability,
              // drug_uom: options?.drug_uom,
              pack_drug_uom: options?.pack_drug_uom,
              round_off_type: options?.round_off_type,
            }}
          />
        ),
      },
      // DispenseAndCharge: {
      //   header: "Dispense & Charge",
      //   fullWidth: true,
      //   body: (
      //     <ALL.DispenseAndChargeComp
      //       propsState={this.state}
      //       setFun={this.changeStateForArrayElement}
      //       stateName={"dispenseVsChargeRoundingDefn"}
      //       statevalue={this.state.dispenseVsChargeRoundingDefn}
      //       onAddNewClick={this.handleAddNewClick}
      //       onDeleteClick={this.handleDeleteClick}
      //       options={{
      //         drug_uom: options?.drug_uom,
      //         round_off_type: options?.round_off_type,
      //       }}
      //     />
      //   ),
      // },
      OrderDefaults: {
        header: "Order Defaults",
        fullWidth: true,
        body: (
          <ALL.Orderdetailscomp
            shouldDisable={isDrugActive}
            propsState={this.state}
            setFun={this.changeStateForObjectElement}
            stateName={"orderDefaults"}
            statevalue={this.state.orderDefaults}
            options={{
              routes_allowed: options?.routes_allowed,
              default_route: options?.default_route,
              frequency: options?.frequency,
              drug_uom: options?.drug_uom,
              order_default_duration_uom: options?.order_default_duration_uom,
              strength_uom: options?.strength_uom,
            }}
          />
        ),
      },
      DispenseDefaults: {
        header: "Dispense Defaults",
        fullWidth: true,
        body: (
          <ALL.Despensedefaultcomp
            shouldDisable={isDrugActive}
            propsState={this.state}
            setFun={this.changeStateForObjectElement}
            stateName={"DrugDispRules"}
            statevalue={this.state.DrugDispRules}
            options={{
              drug_uom: options?.drug_uom,
              privilege_type: options?.privilege_type,
              order_default_duration_uom: options?.duration_day_only,
              dispense_type: options?.dispense_type,
            }}
          />
        ),
      },
      Instructions: {
        header: "Instructions",
        fullWidth: true,
        body: (
          <ALL.Instructioncomp
            shouldDisable={isDrugActive}
            propsState={this.state}
            setFun={(value) => {
              this.changeState("instrDtls", value);
            }}
            stateName={"instrDtls"}
            statevalue={this.state.instrDtls}
            options={{
              instruction_type: options?.instruction_type,
            }}
            isProcedureInstructionsError={(value) =>
              this.isProcedureInstructionsError(value)
            }
          />
        ),
      },
      Directions: {
        header: "Directions",
        fullWidth: true,
        body: (
          <ALL.Directioncomp
            shouldDisable={isDrugActive}
            propsState={this.state}
            setFun={(value) => {
              this.changeState("directionDtls", value);
            }}
            stateName={"directionDtls"}
            statevalue={this.state.directionDtls}
          // options={{
          //   instruction_type: options?.instruction_type,
          // }}
          />
        ),
      },
      MappedDispensables: {
        header: "Mapped Dispensables",
        fullWidth: true,
        body: (
          <ALL.Mappeddespensablecomp
            isOrderable={this.state.IsDispensible}
            shouldDisable={isDrugActive}
            propsState={this.state}
            setFun={this.changeStateForArrayElement}
            stateName={"mappedItems"}
            statevalue={this.state.mappedItems}
            onAddNewClick={this.handleAddNewClick}
            onDeleteClick={this.handleDeleteClick}
            options={{
              item_code: options?.item_code,
            }}
          />
        ),
      },
    };

    return [
      tabObject.Synonym,
      tabObject.AliasMapping,
      tabObject.AvailableStrength,
      tabObject.DrugOtherAttributes,
      this.state.drugotherattributes.returnApplicable &&
      tabObject.ReturnApplicable,
      this.state.IsDispensible && tabObject.UnitOfMeasure, // Show Only if IsDispensable is true
      // tabObject.DispenseAndCharge,
      tabObject.OrderDefaults,
      tabObject.DispenseDefaults,
      tabObject.Instructions,
      tabObject.Directions,
      tabObject.MappedDispensables,
      // this.shouldShowMappedDispensable() && tabObject.MappedDispensables,
    ].filter((x) => x);

    if (this.state.IsDispensible) {
      return [
        tabObject.Synonym,
        tabObject.AliasMapping,
        tabObject.AvailableStrength,
        tabObject.DrugOtherAttributes,
        this.state.drugotherattributes.returnApplicable &&
        tabObject.ReturnApplicable,
        tabObject.UnitOfMeasure,
        // tabObject.DispenseAndCharge,
        tabObject.OrderDefaults,
        tabObject.DispenseDefaults,
        tabObject.Instructions,
        tabObject.Directions,
      ].filter((x) => x);
    } else {
      return [
        tabObject.Synonym,
        tabObject.AliasMapping,
        tabObject.AvailableStrength,
        tabObject.DrugOtherAttributes,
        this.state.drugotherattributes.returnApplicable &&
        tabObject.ReturnApplicable,
        tabObject.OrderDefaults,
        tabObject.DispenseDefaults,
        tabObject.Instructions,
        tabObject.Directions,
        tabObject.MappedDispensables,
      ].filter((x) => x);
    }
  };

  // ------------------------------------------ Render function ---------------------------------------------------------

  render() {
    const {
      classes,
      dropdownOptions = {},
      entityNameOptions = {},
    } = this.props;

    const { data: options } = dropdownOptions;

    const { data: eNameOptions } = entityNameOptions;

    const isDrugActive =
      this.props.location?.state?.isActive || this.state.drugActiveState;

    const isEdit =
      this.props?.location?.state?.docId && this.props?.location?.state?.docKey;

    const isView = this.props.location?.state?.isView === true ? true : false;

    const compoundRemarksComponent = (rowState, index) => (
      <InputBase
        key={"0"}
        id={"p4SrD"}
        label={""}
        placeholder={""}
        type={"text"}
        variant={"outlined"}
        select={""}
        fullWidth={true}
        disabled={
          this.props.location?.state?.isView === true || !isDrugActive
            ? true
            : false
        }
        size={"small"}
        style={qdmstyles.vHsV}
        inputProps={{
          maxlength: 100,
        }}
        value={rowState?.compRemarks}
        onChange={(event) =>
          this.changeStateForArrayElement(
            "compondCompDTls",
            "compRemarks",
            event.target.value,
            index
          )
        }
      ></InputBase>
    );
    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>

            {/* <DrugAddEditComponent
              data={{ ...this.props.location?.state }}
              isView={this.props.location?.state?.isView}
              onBackClick={() => {
                this.props.history.push(Routes.drugmastertable);
              }}
              onCancelClick={() => {
                this.props.history.push(Routes.drugmastertable);
              }}
              onSaveSuccess={(data) => {
                debugger;
                this.props.history.push({
                  pathname: Routes.alertRestriction,
                  state: { ...data },
                });
                successMessage(this.props.location?.state?._key);
              }}
            />
             */}
            <Grid container>
              <Paper className={classes.subheaderPaper}>
                <div className={classes.subheaderRoot}>
                  <div className={classes.subHeaderBack}>
                    <IconButton className={classes.backButton} size="small">
                      <ArrowBackIosOutlinedIcon
                        fontSize="small"
                        style={{ placeSelf: "center" }}
                        htmlColor={"#000000"}
                        onClick={() =>
                          this.props.history.push(Routes.drugmastertable)
                        }
                      />
                    </IconButton>
                    <Typography
                      key={"0"}
                      id={"yf8Th"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      className={classes.drug_title}
                      children={
                        isView
                          ? "Drug catalog master"
                          : isEdit
                            ? "Edit Drug catalog master"
                            : "Add New Drug catalog master"
                      }
                      style={{
                        ...qdmstyles.LP,
                      }}
                    ></Typography>
                  </div>
                  <Divider
                    className={classes.divider}
                    orientation="vertical"
                    flexItem
                  />

                  <Stepper activeStep={0} className={classes.stepper}>
                    {["Drug master setup", "Alerts and Restrictions"].map(
                      (label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      }
                    )}
                  </Stepper>

                  <div style={{ flex: 1 }}></div>

                  <div className={classes.subheaderCTA}>
                    <CustomButton
                      variant="text"
                      className={classes.cancel}
                      // backgroundColor="#DEE5EC"
                      onClick={this.handleFormCancelClick}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      // color={'primary'}
                      disabled={
                        this.props.location?.state?.isView === true
                          ? true
                          : false
                      }
                      backgroundColor={
                        this.props.theme.palette.button.background
                      }
                      color={this.props.theme.palette.button.text}
                      onClick={
                        isView
                          ? this.onNextClickOnViewMode
                          : this.handleFormSaveAndProceedClick
                      }
                    >
                      {isView
                        ? "Next"
                        : isEdit
                          ? "Update And Proceed"
                          : "Save And Proceed"}
                    </CustomButton>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Paper
              key={"1"}
              id={"GuNrF"}
              elevation={"{3}"}
              style={qdmstyles.GuNrF}
              className={classes.commonBackground}
            >
              <Grid
                key={"0"}
                id={"m1DLn"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"stretch"}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                spacing={"1"}
                style={qdmstyles.mDLn}
              >
                {this.state.IsDispensible && (
                  <Grid
                    key={"0"}
                    id={"khsxZ"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"stretch"}
                    xl={"2"}
                    lg={"2"}
                    md={"2"}
                    sm={"3"}
                    xs={"3"}
                    item={true}
                  >
                    <Paper
                      key={"0"}
                      id={"rTKoj"}
                      variant={"outlined"}
                      style={qdmstyles.XVrA}
                      className={classes.uploadImagePaper}
                    >
                      <Grid
                        key={"0"}
                        id={"XLmtk"}
                        container={true}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"12"}
                      >
                        <Grid
                          key={"0"}
                          id={"5VWEj"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          style={qdmstyles.Letch}
                          className={classes.avatarContainer}
                        >
                          <Avatar
                            key={"0"}
                            id={"upload_image_view"}
                            alt={"Avatar"}
                            variant={"rounded"}
                            aliasName={"uploadimageview"}
                            style={qdmstyles.DnFrG}
                            className={classes.avatar}
                            src={this.state.photoUrl}
                          ></Avatar>
                          <input
                            type="file"
                            id="uploadImage"
                            ref={this.fileUploader}
                            onChange={(e) => this.handleChangePhotoFileInput(e)}
                            style={{ display: "none" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"tV9WH"}
                        container={true}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        style={qdmstyles.bhxM}
                        className={classes.avatarDivider}
                      >
                        <Divider
                          key={"0"}
                          id={"AH7fO"}
                          variant={"fullWidth"}
                          flexItem={""}
                          orientation={"horizontal"}
                          style={qdmstyles.UMQM}
                        ></Divider>
                      </Grid>
                      <Grid
                        key={"2"}
                        id={"5VWEj"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        style={qdmstyles.RzsB}
                      >
                        <Button
                          key={"0"}
                          id={"upload_image_button"}
                          variant={"text"}
                          children={"Upload Image"}
                          fullWidth={true}
                          aliasName={"uploadimagebutton"}
                          style={qdmstyles.uqe}
                          className={classes.uploadButton}
                          disabled={
                            this.props.location?.state?.isView === true ||
                            !isDrugActive
                          }
                          onClick={() => {
                            this.fileUploader.current.click();
                          }}
                        ></Button>
                      </Grid>
                    </Paper>
                  </Grid>
                )}
                <Grid
                  key={"1"}
                  id={"vGiAm"}
                  container={""}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={this.state.IsDispensible ? "10" : "12"}
                  md={this.state.IsDispensible ? "10" : "12"}
                  item={true}
                  sm={this.state.IsDispensible ? "9" : "12"}
                  xs={this.state.IsDispensible ? "9" : "12"}
                  xl={this.state.IsDispensible ? "10" : "12"}
                >
                  <Paper
                    key={"0"}
                    id={"RN9SC"}
                    elevation={""}
                    style={qdmstyles.cdG}
                    className={classes.phDrugMasterContainer}
                  >
                    <Grid
                      key={"0"}
                      id={"WbID5"}
                      container={true}
                      direction={"column"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      item={true}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      spacing={3}
                      wrap={"wrap"}
                      style={qdmstyles.kjth}
                    >
                      <Grid
                        key={"0"}
                        id={"rtmlg"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        xs={"12"}
                        xl={"12"}
                        sm={"12"}
                        md={"12"}
                        lg={"12"}
                        item={true}
                      >
                        <Typography
                          key={"0"}
                          id={"ph_drug_master_header"}
                          align={"inherit"}
                          // color={"primary"}
                          display={"initial"}
                          variant={"body1"}
                          children={"PH DRUG MASTER"}
                          aliasName={"phdrugmasterheader"}
                          className={classes.phdrug}
                          style={{
                            ...qdmstyles.bvrxm,
                          }}
                        ></Typography>
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"CWXd4"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        item={true}
                        spacing={""}
                        wrap={"wrap"}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                        style={qdmstyles.eqsr}
                        className={classes.phDrugMasterRow}
                      >
                        <Grid
                          key={"0"}
                          id={"6ecQm"}
                          container={true}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          lg={2}
                          md={"3"}
                          sm={"6"}
                          xl={2}
                          xs={"6"}
                        >
                          <Grid
                            key={"0"}
                            id={"kwZd6"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.xIYz}
                            className={classes.phDrugMasterLabelContainer}
                          >
                            <FieldLabel
                              key={"0"}
                              id={"codeStandard_Typography"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Code Standard"}
                              required
                              aliasName={"codeStandardHeading"}
                              className={classes.ph_childtitle}
                              style={{
                                ...qdmstyles.dBCay,
                              }}
                            ></FieldLabel>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"kwZd6"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.gfX}
                            className={classes.phDrugMasterInputContainer}
                          >
                            <Autocomplete
                              id={"Hk0O4"}
                              style={qdmstyles?.HkO}
                              disabled={
                                this.props.location?.state?.isView === true ||
                                  !isDrugActive
                                  ? true
                                  : false
                              }
                              onLoad={() =>
                                this.changeState(
                                  "codeStandard",
                                  this.state?.codeStandard ?? ""
                                )
                              }
                              getOptionLabel={(option) => option?.["label"]}
                              options={options?.code_standara ?? []}
                              size={"small"}
                              placeholder=""
                              value={this.state?.codeStandard ?? null}
                              onChange={(event, newValue) =>
                                this.changeState("codeStandard", newValue)
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"6ecQm"}
                          container={true}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          lg={"2"}
                          md={"3"}
                          sm={"6"}
                          xl={"2"}
                          xs={"6"}
                        >
                          <Grid
                            key={"0"}
                            id={"kwZd6"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.QIbHD}
                            className={classes.phDrugMasterLabelContainer}
                          >
                            <FieldLabel
                              key={"0"}
                              id={"drugCode_Typography"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Drug Code"}
                              required
                              aliasName={"drugCodeHeading"}
                              className={classes.ph_childtitle}
                              style={{
                                ...qdmstyles.uVXd,
                              }}
                            ></FieldLabel>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"kwZd6"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.ModB}
                            className={classes.phDrugMasterInputContainer}
                          >
                            <InputBase
                              key={"0"}
                              id={"drug_code_value"}
                              label={""}
                              placeholder={""}
                              type={"text"}
                              variant={"outlined"}
                              size={"small"}
                              fullWidth={true}
                              disabled={
                                this.props.location?.state?.isView === true ||
                                  !isDrugActive
                                  ? true
                                  : false
                              }
                              aliasName={"drugcodevalue"}
                              style={qdmstyles.Yfc}
                              inputProps={{
                                maxlength: 20,
                              }}
                              value={this.state.DrugCode}
                              onChange={(event) =>
                                this.changeState(
                                  "DrugCode",
                                  event.target.value?.toUpperCase(),
                                  this.modifyMappedDispensableWithSelfDrug
                                )
                              }
                            ></InputBase>
                          </Grid>
                        </Grid>
                        <Grid
                          key={"2"}
                          id={"6ecQm"}
                          container={true}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          lg={"2"}
                          md={"6"}
                          sm={"6"}
                          xl={"3"}
                          xs={"6"}
                        >
                          <Grid
                            key={"0"}
                            id={"kwZd6"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.TJYq}
                            className={classes.phDrugMasterLabelContainer}
                          >
                            <FieldLabel
                              key={"0"}
                              id={"shortDescription_Typography"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Short Description"}
                              required
                              aliasName={"shortDescriptionHeading"}
                              className={classes.ph_childtitle}
                              style={{
                                ...qdmstyles.oJx,
                              }}
                            ></FieldLabel>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"kwZd6"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.fbfVp}
                            className={classes.phDrugMasterInputContainer}
                          >
                            <InputBase
                              key={"0"}
                              id={"InputofShortDescription_Textfield"}
                              label={""}
                              disabled={
                                this.props.location?.state?.isView === true ||
                                  !isDrugActive
                                  ? true
                                  : false
                              }
                              placeholder={""}
                              type={"text"}
                              variant={"outlined"}
                              size={"small"}
                              aliasName={"InputofShortDescription"}
                              fullWidth={true}
                              style={qdmstyles.gbQzl}
                              inputProps={{
                                maxlength: 30,
                              }}
                              value={this.state.ShortDesc}
                              onChange={(event) =>
                                this.changeState(
                                  "ShortDesc",
                                  event.target.value,
                                  this.modifyMappedDispensableWithSelfDrug
                                )
                              }
                            ></InputBase>
                          </Grid>
                        </Grid>
                        <Grid
                          key={"3"}
                          id={"6ecQm"}
                          container={true}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          lg={"3"}
                          md={"3"}
                          sm={"3"}
                          xl={"3"}
                          xs={"3"}
                        >
                          <Grid
                            key={"0"}
                            id={"kwZd6"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.qog}
                            className={classes.phDrugMasterLabelContainer}
                          >
                            <FieldLabel
                              key={"0"}
                              id={"longDescription_Typography"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Long Description"}
                              required
                              aliasName={"longDescriptionHeading"}
                              className={classes.ph_childtitle}
                              style={{
                                ...qdmstyles.PqLw,
                              }}
                            ></FieldLabel>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"kwZd6"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.LXcKc}
                            className={classes.phDrugMasterInputContainer}
                          >
                            <InputBase
                              key={"0"}
                              id={"InputofLongDescription_Textfield"}
                              label={""}
                              placeholder={""}
                              type={"text"}
                              variant={"outlined"}
                              disabled={
                                this.props.location?.state?.isView === true ||
                                  !isDrugActive
                                  ? true
                                  : false
                              }
                              size={"small"}
                              aliasName={"InputofLongDescription"}
                              fullWidth={true}
                              style={qdmstyles.rpidP}
                              inputProps={{
                                maxlength: 100,
                              }}
                              value={this.state.LongDesc}
                              onChange={(event) =>
                                this.changeState("LongDesc", event.target.value)
                              }
                            ></InputBase>
                          </Grid>
                        </Grid>
                        <Grid
                          key={"0"}
                          id={"aq1c2"}
                          container={""}
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          spacing={""}
                          lg={"3"}
                          alignContent={"center"}
                          md={"3"}
                          sm={"3"}
                          xl={"3"}
                          xs={"3"}
                          style={qdmstyles.ewP}
                        >
                          <Grid
                            key={"0"}
                            id={"dIQ02"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.mMIQ}
                            className={classes.phDrugMasterLabelContainer}
                          >
                            <FieldLabel
                              key={"0"}
                              id={"drugClass_Typography"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Drug Class"}
                              required
                              aliasName={"drugClassHeading"}
                              className={classes.ph_childtitle}
                              style={{
                                ...qdmstyles.Yhjxg,
                              }}
                            ></FieldLabel>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"dIQ02"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.Rarp}
                            className={classes.phDrugMasterInputContainer}
                          >
                            <Autocomplete
                              id={"4df89"}
                              style={qdmstyles?.df}
                              onLoad={() =>
                                this.changeState(
                                  "DrugClass",
                                  this.state?.DrugClass
                                )
                              }
                              getOptionLabel={(option) => option?.["label"]}
                              disabled={
                                this.props.location?.state?.isView === true ||
                                  !isDrugActive
                                  ? true
                                  : false
                              }
                              options={options?.drug_class ?? []}
                              size={"small"}
                              placeholder={""}
                              value={this.state?.DrugClass ?? null}
                              onChange={(event, newValue) =>
                                this.changeState("DrugClass", newValue)
                              }
                            />
                          </Grid>
                        </Grid>
                        {/* <Grid
                          key={"3"}
                          id={"6ecQm"}
                          container={true}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          lg={"1"}
                          md={"6"}
                          sm={"6"}
                          xl={"5"}
                          xs={"6"}
                        >
                          <Grid
                            key={"0"}
                            id={"kwZd6"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.qog}
                            className={classes.phDrugMasterLabelContainer}
                          >
                            <FieldLabel
                              key={"0"}
                              id={"longDescription_Typography"}
                              align={"inherit"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Status"}
                              aliasName={"longDescriptionHeading"}
                              style={{
                                ...qdmstyles.PqLw,
                                color: Color.palette.text.tertiary,
                              }}
                            ></FieldLabel>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"kwZd6"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.LXcKc}
                            className={classes.phDrugMasterInputContainer}
                          >
                            <Switch
                              key={"0"}
                              id={"switch_input"}
                              disabled={
                                this.props.location?.state?.isView === true
                                  ? true
                                  : false
                              }
                              checked={this.state.status}
                              color={"primary"}
                              onChange={() => {
                                this.onChangeSwitch(this.state.status);
                              }}
                            ></Switch>
                          </Grid>
                        </Grid> */}
                      </Grid>
                      <Grid
                        key={"2"}
                        id={"CWXd4"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                        item={true}
                        spacing={1}
                        wrap={"wrap"}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                        style={qdmstyles.Wpl}
                        className={classes.phDrugMasterRow}
                      >

                        <Grid
                          key={"1"}
                          id={"aq1c2"}
                          container={""}
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          spacing={""}
                          lg={"2"}
                          alignContent={"center"}
                          md={"2"}
                          sm={"6"}
                          xl={"2"}
                          xs={"6"}
                          style={qdmstyles.MMifZ}
                        >
                          <Grid
                            key={"0"}
                            id={"dIQ02"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.qUBw}
                            className={classes.phDrugMasterLabelContainer}
                          >
                            <FieldLabel
                              key={"0"}
                              id={"drugCategory_Textfield"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Drug Category"}
                              required
                              aliasName={"drugCategoryHeading"}
                              className={classes.ph_childtitle}
                              style={{
                                ...qdmstyles.NlzO,
                              }}
                            ></FieldLabel>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"dIQ02"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            style={qdmstyles.Ssdx}
                            className={classes.phDrugMasterInputContainer}
                          >
                            <Autocomplete
                              id={"3l5se"}
                              style={qdmstyles?.lse}
                              onLoad={() =>
                                this.changeState(
                                  "DrugCategory",
                                  this.state?.DrugCategory
                                )
                              }
                              getOptionLabel={(option) => option?.["label"]}
                              disabled={
                                this.props.location?.state?.isView === true ||
                                  !isDrugActive
                                  ? true
                                  : false
                              }
                              options={options?.drug_category ?? []}
                              size={"small"}
                              placeholder=""
                              value={this.state?.DrugCategory ?? null}
                              onChange={(event, newValue) =>
                                this.changeState("DrugCategory", newValue)
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          key={"2"}
                          id={"aq1c2"}
                          container={""}
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          spacing={""}
                          lg={"2"}
                          alignContent={"center"}
                          md={"4"}
                          sm={"4"}
                          xl={"2"}
                          xs={"4"}
                          style={qdmstyles.qGUQy}
                        >
                          <Grid
                            key={"0"}
                            id={"dIQ02"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            style={qdmstyles.kLUQ}
                            className={classes.phDrugMasterLabelContainer}
                          >
                            <FieldLabel
                              key={"0"}
                              id={"drugType_Typography"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Drug Type"}
                              required
                              aliasName={"drugTypeHeading"}
                              className={classes.ph_childtitle}
                              style={{
                                ...qdmstyles.eHPb,
                              }}
                            ></FieldLabel>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"dIQ02"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            style={qdmstyles.VEbTM}
                            className={classes.phDrugMasterInputContainer}
                          >
                            <Autocomplete
                              id={"qCjmh"}
                              style={qdmstyles?.qCjmh}
                              onLoad={() =>
                                this.changeState(
                                  "DrugType",
                                  this.state?.DrugType
                                )
                              }
                              getOptionLabel={(option) => option?.["label"]}
                              disabled={
                                this.props.location?.state?.isView === true ||
                                  !isDrugActive
                                  ? true
                                  : false
                              }
                              options={options?.drug_type ?? []}
                              size={"small"}
                              placeholder=""
                              value={this.state?.DrugType ?? null}
                              onChange={(event, newValue) =>
                                this.changeState("DrugType", newValue)
                              }
                            />
                          </Grid>
                        </Grid>
                        {/*  */}
                        <Grid
                          key={"3"}
                          id={"aq1c2"}
                          container={""}
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          spacing={""}
                          lg={"2"}
                          alignContent={"center"}
                          md={"4"}
                          sm={"4"}
                          xl={"2"}
                          xs={"4"}
                          style={qdmstyles.qGUQy}
                        >
                          <Grid
                            key={"0"}
                            id={"dIQ02"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            style={qdmstyles.kLUQ}
                            className={classes.phDrugMasterLabelContainer}
                          >
                            <FieldLabel
                              key={"0"}
                              id={"form_Typography"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Form"}
                              aliasName={"formHeading"}
                              className={classes.ph_childtitle}
                              required
                              style={{
                                ...qdmstyles.eHPb,
                              }}
                            ></FieldLabel>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"dIQ02"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            style={qdmstyles.VEbTM}
                            className={classes.phDrugMasterInputContainer}
                          >
                            <Autocomplete
                              id={"qCjmh"}
                              style={qdmstyles?.qCjmh}
                              onLoad={() =>
                                this.changeState(
                                  "FormCode",
                                  this.state?.FormCode
                                )
                              }
                              getOptionLabel={(option) => option?.["label"]}
                              disabled={
                                this.props.location?.state?.isView === true ||
                                  !isDrugActive
                                  ? true
                                  : false
                              }
                              options={options?.form ?? []}
                              size={"small"}
                              placeholder=""
                              value={this.state?.FormCode ?? null}
                              onChange={(event, newValue) =>
                                this.changeState("FormCode", newValue)
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          key={"4"}
                          id={"aq1c2"}
                          container={""}
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          spacing={""}
                          lg={"3"}
                          alignContent={"center"}
                          md={"3"}
                          sm={"6"}
                          xl={"3"}
                          xs={"6"}
                          style={qdmstyles.RsaET}
                        >
                          <Grid
                            key={"0"}
                            id={"dIQ02"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.yzEo}
                            className={classes.phDrugMasterLabelContainer}
                          >
                            <FieldLabel
                              key={"0"}
                              id={"drugGeneric_Typography"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Drug Generic"}
                              aliasName={"drugGenericHeading"}
                              className={classes.ph_childtitle}
                              style={{
                                ...qdmstyles.mrwSp,
                              }}
                            ></FieldLabel>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"dIQ02"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.iagcZ}
                            className={classes.phDrugMasterInputContainer}
                          >
                            <Autocomplete
                              id={"605Py"}
                              disabled={
                                this.props.location?.state?.isDisabled ===
                                  false || !isDrugActive
                                  ? true
                                  : false
                              }
                              style={qdmstyles?.Py}
                              onLoad={() =>
                                this.changeState(
                                  "DrugGenericId",
                                  this.state?.DrugGenericId
                                )
                              }
                              getOptionLabel={(option) => option?.["label"]}
                              options={options?.drug_generic ?? []}
                              size={"small"}
                              placeholder=""
                              value={this.state?.DrugGenericId ?? null}
                              onChange={(event, newValue) =>
                                this.changeState("DrugGenericId", newValue)
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          key={"5"}
                          id={"aq1c2"}
                          container={""}
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          spacing={""}
                          lg={1}
                          alignContent={"center"}
                          md={1}
                          sm={"2"}
                          xl={1}
                          xs={"2"}
                          style={qdmstyles.Kosko}
                        >
                          <Grid
                            key={"0"}
                            id={"dIQ02"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.AfiO}
                            className={classes.phDrugMasterLabelContainer}
                          >
                            <FieldLabel
                              key={"0"}
                              id={"Dispensable_CheckboxHeading"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Dispensable ?"}
                              aliasName={"DispensableCheckboxHeading"}
                              className={classes.ph_childtitle}
                              style={{
                                ...qdmstyles.BBmu,
                              }}
                            ></FieldLabel>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"dIQ02"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.GRNA}
                            className={classes.phDrugMasterInputContainer}
                          >
                            <Checkbox
                              key={"0"}
                              id={"Dispensable_Checkbox"}
                              color={"primary"}
                              size={"small"}
                              aliasName={"DispensableCheckbox"}
                              disabled={
                                this.props.location?.state?.isView === true ||
                                  !isDrugActive
                                  ? true
                                  : false
                              }
                              checked={this.state.IsDispensible}
                              onChange={(event) =>
                                this.changeState(
                                  "IsDispensible",
                                  event.target.checked,
                                  this.modifyMappedDispensableWithSelfDrug
                                )
                              }
                              inputProps={{ "aria-label": "dispensable?" }}
                            ></Checkbox>
                          </Grid>
                        </Grid>
                        <Grid
                          key={"5"}
                          id={"aq1c2"}
                          container={""}
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          spacing={""}
                          lg={1}
                          alignContent={"center"}
                          md={1}
                          sm={"2"}
                          xl={1}
                          xs={"2"}
                          style={qdmstyles.Kosko}
                        >
                          <Grid
                            key={"0"}
                            id={"dIQ021"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.AfiO}
                            className={classes.phDrugMasterLabelContainer}
                          >
                            <FieldLabel
                              key={"0"}
                              id={"Orderable_CheckboxHeading"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Orderable ?"}
                              aliasName={"OrderableCheckboxHeading"}
                              className={classes.ph_childtitle}
                              style={qdmstyles.BBmu}
                            ></FieldLabel>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"dIQ02"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.GRNA}
                            className={classes.phDrugMasterInputContainer}
                          >
                            <Checkbox
                              key={"0"}
                              id={"Orderable_Checkbox"}
                              color={"primary"}
                              size={"small"}
                              aliasName={"OrderableCheckbox"}
                              disabled={
                                this.props.location?.state?.isView === true ||
                                  !isDrugActive
                                  ? true
                                  : false
                              }
                              checked={this.state.IsOrderable}
                              onChange={(event) =>
                                // this.handleIsOrderableChange(
                                //   event.target.checked
                                // )
                                this.changeState(
                                  "IsOrderable",
                                  event.target.checked,
                                  this.modifyMappedDispensableWithSelfDrug
                                )
                              }
                              inputProps={{ "aria-label": "orderable?" }}
                            ></Checkbox>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>

              {/* Compound Section Starts */}
              {this.state.compondCompDTls?.length > 0 && (
                <Grid
                  key={"1"}
                  id={"oSbbs"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid
                    key={"0"}
                    id={"WI79n"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                  >
                    <Paper
                      key={"0"}
                      id={"TOgaz"}
                      elevation={"{3}"}
                      style={qdmstyles.vQHg}
                      className={classes.drugTabSec}
                    >
                      <Grid
                        key={"0"}
                        id={"TgstR"}
                        container={true}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                        style={qdmstyles.TwGt}
                      >
                        <Grid
                          key={"0"}
                          id={"TgstR"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          lg={"6"}
                        >
                          <Typography
                            key={"0"}
                            id={"compoundHeading_Typography"}
                            align={"left"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"COMPOUND"}
                            aliasName={"compoundHeading"}
                            style={qdmstyles.yJwKG}
                          ></Typography>
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"TgstR"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                        >
                          <Button
                            key={"0"}
                            id={"CompondAddnewButton_button"}
                            variant={"text"}
                            color={"primary"}
                            disabled={
                              this.props.location?.state?.isView === true ||
                                !isDrugActive
                                ? true
                                : false
                            }
                            children={"+ Add New"}
                            aliasName={"CompondAddnewButton"}
                            style={qdmstyles.wsy}
                            onClick={() =>
                              this.handleAddNewClick("compondCompDTls")
                            }
                          ></Button>
                        </Grid>
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"oZ25m"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        spacing={"1"}
                      >
                        {/* Label Row */}
                        <Grid
                          key={"0"}
                          id={"R3123"}
                          spacing={"1"}
                          container={true}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"12"}
                          md={"12"}
                          item={true}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                        >
                          <Grid
                            key={"0"}
                            id={"CTVXT"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"2"}
                            md={"2"}
                            sm={"2"}
                            xl={"2"}
                            xs={"2"}
                          >
                            <Typography
                              key={"0"}
                              id={"DrugcodeHeading_Typography"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Drug Code"}
                              aliasName={"DrugcodeHeading"}
                              style={qdmstyles.Usbr}
                            ></Typography>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"CTVXT"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"3"}
                            md={"3"}
                            sm={"3"}
                            xl={"3"}
                            xs={"3"}
                            style={qdmstyles.PUW}
                          >
                            <Typography
                              key={"0"}
                              id={"descriptionHeading_Typography"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Description"}
                              aliasName={"descriptionHeading"}
                              style={qdmstyles.UAZJs}
                            ></Typography>
                          </Grid>
                          <Grid
                            key={"2"}
                            id={"CTVXT"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"2"}
                            md={"2"}
                            sm={"2"}
                            xl={"2"}
                            xs={"2"}
                          >
                            <Typography
                              key={"0"}
                              id={"strengthandconcentrationHeading_Typography"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Strength/Concentration"}
                              aliasName={"strengthandconcentrationHeading"}
                              style={qdmstyles.Rhky}
                            ></Typography>
                          </Grid>
                          <Grid
                            key={"3"}
                            id={"CTVXT"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"2"}
                            md={"2"}
                            sm={"2"}
                            xl={"2"}
                            xs={"2"}
                          >
                            <Typography
                              key={"0"}
                              id={"RequiredQuantityHeading_Typography"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Required Quantity & UOM"}
                              aliasName={"RequiredQuantityHeading"}
                              style={qdmstyles.eBPpS}
                            ></Typography>
                          </Grid>
                          <Grid
                            key={"4"}
                            id={"CTVXT"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"3"}
                            md={"3"}
                            sm={"3"}
                            xl={"3"}
                            xs={"3"}
                          >
                            <Typography
                              key={"0"}
                              id={"remarksHeading_Typography"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Remarks"}
                              aliasName={"RemarksHeading"}
                              style={qdmstyles.joa}
                            ></Typography>
                          </Grid>
                        </Grid>

                        {/* Field Row */}
                        {this.state.compondCompDTls.map((item, index) => {
                          const rowState = this.state.compondCompDTls[index];
                          return (
                            <Grid
                              key={index}
                              spacing={"1"}
                              container={true}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              lg={"12"}
                              md={"12"}
                              item={true}
                              sm={"12"}
                              xl={"12"}
                              xs={"12"}
                            >
                              <Grid
                                key={"0"}
                                id={"CTVXT"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={"2"}
                                md={"2"}
                                sm={"2"}
                                xl={"2"}
                                xs={"2"}
                              >
                                <Autocomplete
                                  id={"GW7ar"}
                                  style={qdmstyles?.GWar}
                                  onLoad={() =>
                                    this.handleCompounDrugChange(
                                      "compondCompDTls",
                                      "compDrugCode",
                                      rowState?.compDrugCode,
                                      index
                                    )
                                  }
                                  getOptionLabel={(option) => option?.["label"]}
                                  disabled={
                                    this.props.location?.state?.isView ===
                                      true || !isDrugActive
                                      ? true
                                      : false
                                  }
                                  options={options?.drug_code ?? []}
                                  size={"small"}
                                  placeholder=""
                                  value={rowState?.compDrugCode ?? null}
                                  onChange={(event, newValue) =>
                                    this.handleCompounDrugChange(
                                      "compondCompDTls",
                                      "compDrugCode",
                                      newValue,
                                      index
                                    )
                                  }
                                />
                              </Grid>
                              <Grid
                                key={"1"}
                                id={"CTVXT"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={"3"}
                                md={"3"}
                                sm={"3"}
                                xl={"3"}
                                xs={"3"}
                              >
                                <InputBase
                                  key={"0"}
                                  id={"p4SrD"}
                                  label={""}
                                  placeholder={""}
                                  type={"text"}
                                  variant={"outlined"}
                                  disabled={
                                    this.props.location?.state?.isView ===
                                      true || !isDrugActive
                                      ? true
                                      : false
                                  }
                                  size={"small"}
                                  fullWidth={true}
                                  style={qdmstyles.VeII}
                                  readOnly
                                  // value={rowState?.compDrugDescription}
                                  value={
                                    rowState?.compDrugCode?.ShortDesc
                                      ? rowState?.compDrugCode?.ShortDesc
                                      : ""
                                  }
                                // onChange={(event) =>
                                //   this.changeStateForArrayElement(
                                //     "compondCompDTls",
                                //     "compDrugDescription",
                                //     event.target.value,
                                //     index
                                //   )
                                // }
                                ></InputBase>
                              </Grid>
                              <Grid
                                key={"2"}
                                id={"CTVXT"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={"2"}
                                md={"2"}
                                sm={"2"}
                                xl={"2"}
                                xs={"2"}
                              >
                                <InputBase
                                  key={"0"}
                                  id={"p4SrD"}
                                  label={""}
                                  placeholder={""}
                                  type={"text"}
                                  variant={"outlined"}
                                  size={"small"}
                                  fullWidth={true}
                                  style={qdmstyles.iRSf}
                                  disabled={
                                    this.props.location?.state?.isView ===
                                      true || !isDrugActive
                                      ? true
                                      : false
                                  }
                                  readOnly
                                  value={
                                    rowState?.compStrengthValue ||
                                      rowState?.compStrengthUOM ||
                                      rowState?.compStrengthVolume ||
                                      rowState?.compStrengthVolumeUoM
                                      ? [
                                        [
                                          rowState?.compStrengthValue?.toString() ??
                                          "",
                                          rowState?.compStrengthUOM?.toString() ??
                                          "",
                                        ].join(""),
                                        "/",
                                        [
                                          rowState?.compStrengthVolume?.toString() ??
                                          "",
                                          rowState?.compStrengthVolumeUoM?.toString() ??
                                          "",
                                        ].join(""),
                                      ].join(" ")
                                      : ""
                                  }
                                  onChange={(event) =>
                                    this.changeStateForArrayElement(
                                      "compondCompDTls",
                                      "compStrengthValue",
                                      event.target.value,
                                      index
                                    )
                                  }
                                ></InputBase>
                              </Grid>
                              <Grid
                                key={"3"}
                                id={"CTVXT"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={"2"}
                                md={"2"}
                                sm={"2"}
                                xl={"2"}
                                xs={"2"}
                              >
                                {/* Required Quantity UOM Component */}
                                <TextfieldWithSelect
                                  InputProps={{
                                    id: `requiredQuantity-${index}`,
                                    type: "number",
                                    inputProps: {
                                      maxlength: 10,
                                      min: 0,
                                      max: 9999999999,
                                    },
                                    fullWidth: true,
                                    disabled:
                                      this.props.location?.state?.isView ===
                                        true || !isDrugActive
                                        ? true
                                        : false,
                                    value: rowState?.compQty,
                                    onChange: (event) => {
                                      if (event?.target?.value?.length > 10)
                                        return;
                                      this.changeStateForArrayElement(
                                        "compondCompDTls",
                                        "compQty",
                                        parseInt(event.target.value),
                                        index
                                      );
                                    },
                                  }}
                                  AutocompleteProps={{
                                    id: `requiredQuantityUOM-${index}`,
                                    onLoad: () =>
                                      this.changeStateForArrayElement(
                                        "compondCompDTls",
                                        "compQtyUom",
                                        rowState?.compQtyUom,
                                        index
                                      ),
                                    getOptionLabel: (option) =>
                                      option?.["label"],
                                    options:
                                      options?.compound_quantity_uom ?? [],
                                    value: rowState?.compQtyUom ?? null,
                                    onChange: (event, newValue) =>
                                      this.changeStateForArrayElement(
                                        "compondCompDTls",
                                        "compQtyUom",
                                        newValue,
                                        index
                                      ),
                                  }}
                                />
                              </Grid>
                              <Grid
                                key={"4"}
                                id={"CTVXT"}
                                container={true}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={"3"}
                                md={"3"}
                                sm={"3"}
                                xl={"3"}
                                xs={"3"}
                              >
                                {index >= 2 ? (
                                  <>
                                    <Grid item xs={10}>
                                      {compoundRemarksComponent(
                                        rowState,
                                        index
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          this.handleDeleteClick(
                                            "compondCompDTls",
                                            index
                                          )
                                        }
                                      >
                                        <TrashIcon />
                                      </IconButton>
                                    </Grid>
                                  </>
                                ) : (
                                  <Grid item xs={12}>
                                    {compoundRemarksComponent(rowState, index)}
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          );
                        })}

                        {/* Field Row Ends */}
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              )}
              {/* Compound Section Ends */}

              {/* Drug Tabs Section Starts */}
              <Grid
                key={"2"}
                id={"5vNt9"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                style={qdmstyles.vNt}
              >
                <Grid
                  key={"0"}
                  id={"x6qUr"}
                  container={""}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"12"}
                  md={"12"}
                  sm={"12"}
                  xl={"12"}
                  xs={"12"}
                >
                  <Paper
                    key={"0"}
                    id={"VI5ak"}
                    elevation={"{3}"}
                    style={qdmstyles.VIak}
                    className={classes.drugTabSec}
                  >
                    <Tabs
                      value={this.state.currentTabSection}
                      onChange={(e, newValue) => {
                        this.changeState("currentTabSection", newValue);
                      }}
                      variant="scrollable"
                      aria-label="simple tabs example"
                      scrollButtons="on"
                    // classes={tabsStyles}
                    >
                      {this.getTabs().map((tabItem, index) => (
                        <Tab
                          key={tabItem?.id ?? index}
                          label={tabItem?.header}
                          // classes={tabStyles}
                          disableRipple
                        />
                      ))}
                    </Tabs>
                    {this.getTabs().map((tabItem, index) => (
                      <TabPanel
                        value={this.state.currentTabSection}
                        index={index}
                      >
                        {tabItem?.body}
                      </TabPanel>
                    ))}
                  </Paper>
                </Grid>
              </Grid>
              {/* Drug Tabs Section Ends */}

              {/* Drug Status Section Starts */}
              <Grid
                key={"3"}
                id={"QmXa7"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                style={qdmstyles.QmXa}
              >
                <Grid
                  key={"0"}
                  id={"Ai9jq"}
                  container={""}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"12"}
                  md={"12"}
                  sm={"12"}
                  xl={"12"}
                  xs={"12"}
                >
                  <Paper
                    key={"0"}
                    id={"If6NF"}
                    elevation={"{3}"}
                    style={qdmstyles.IfNF}
                    className={classes.drugTabSec}
                  >
                    {!this.state.showStatuspage && (
                      <Grid
                        key={"0"}
                        id={"Tm1em"}
                        container={true}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <>
                          <Grid
                            key={"0"}
                            id={"Ja5NS"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"2"}
                          >
                            <Typography
                              key={"0"}
                              id={"status_header"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"STATUS"}
                              aliasName={"statusheader"}
                              style={qdmstyles.FPew}
                              className={classes.status}
                            ></Typography>
                          </Grid>

                          <Grid
                            key={"1"}
                            id={"YhT40"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"2"}
                            style={qdmstyles.YhT}
                          >
                            <Button
                              key={"0"}
                              id={"status_add_new_button"}
                              disabled={
                                this.props.location?.state?.isView === true
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                this.setState({ showStatuspage: true })
                              }
                              // disabled={this.props.location?.state?.isView === true ? true : false}
                              variant={"text"}
                              color={"primary"}
                              children={"+ Add New status"}
                              aliasName={"statusaddnewbutton"}
                              style={qdmstyles.KIyu}
                            ></Button>
                          </Grid>
                        </>
                      </Grid>
                    )}
                    {this.state.showStatuspage && (
                      <>
                        <Grid
                          key={"0"}
                          id={"RPVil"}
                          container={true}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          style={qdmstyles.RPVil}
                        >
                          <Grid
                            key={"0"}
                            id={"status_header"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            lg={"4"}
                            md={"4"}
                            sm={"4"}
                            aliasName={"statusheader"}
                          >
                            <Typography
                              key={"0"}
                              id={"KnNVp"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"STATUS"}
                              className={classes.status}
                              style={qdmstyles.KnNVp}
                            ></Typography>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"CYeHC"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                            lg={"8"}
                            md={"8"}
                            sm={"8"}
                            spacing={""}
                            item={true}
                          >
                            <Grid
                              key={"0"}
                              id={"ajgKb"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              lg={"3"}
                              md={"3"}
                              sm={"3"}
                              style={qdmstyles.ajgKb}
                            >
                              <Autocomplete
                                id={"uYkPJ"}
                                style={qdmstyles?.uYkPJ}
                                disabled={
                                  this.props.location?.state?.isView === true
                                    ? true
                                    : false
                                }
                                getOptionLabel={(option) => option?.["label"]}
                                options={options?.entity_type ?? []}
                                placeholder={"Select Entity Type *"}
                                required
                                className={classes.statusAutoComplete}
                                value={this.state?.selectedEntityType ?? null}
                                onLoad={() =>
                                  this.changeState(
                                    "selectedEntityType",
                                    this.state?.selectedEntityType
                                  )
                                }
                                onChange={(event, newValue) =>
                                  this.changeState(
                                    "selectedEntityType",
                                    newValue
                                  )
                                }
                              />
                            </Grid>
                            <Grid
                              key={"1"}
                              id={"jLeH1"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              lg={"6"}
                              md={"6"}
                              sm={"6"}
                            >
                              <Autocomplete
                                id={"9RTTk"}
                                placeholder={
                                  this.state?.selectedEntityName.length > 0
                                    ? "Select Entity Name"
                                    : " "
                                }
                                disabled={
                                  this.props.location?.state?.isView === true
                                    ? true
                                    : false
                                }
                                style={qdmstyles?.uYkPJ}
                                getOptionLabel={(option) => option?.["name"]}
                                options={eNameOptions ?? []}
                                classes={{
                                  root: classes.radius,
                                }}
                                multiple
                                limitTags={2}
                                size="small"
                                required
                                className={classNames(
                                  classes.removeGutterRight,
                                  classes.multiAutoComplete
                                )}
                                // className={classes.statusAutoComplete}
                                // renderTags={(value, getTagProps) =>
                                //   value.map((option, index) => (
                                //     <Chip
                                //       label={option?.label?.name}
                                //       deleteIcon={
                                //         <CloseIcon htmlColor="#6A7888" />
                                //       }
                                //       {...getTagProps({ index })}
                                //     />
                                //   ))
                                // }
                                value={this.state?.selectedEntityName ?? null}
                                onLoad={() =>
                                  this.changeState(
                                    "selectedEntityName",
                                    this.state?.selectedEntityName
                                  )
                                }
                                onChange={(event, newValue, reason) => {
                                  // this.changeState(
                                  //   "selectedEntityName",
                                  //   newValue
                                  // );
                                  this.handleEntityNameChange(newValue, reason);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Entity Name"
                                    placeholder="Select Entity Name"
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"uVXKs"}
                          container={true}
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          spacing={"1"}
                          style={qdmstyles.uVXKs}
                        >
                          <Grid
                            key={"0"}
                            id={"CrWu1"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            lg={1}
                            md={1}
                            sm={1}
                            item={true}
                          >
                            <Typography
                              key={"0"}
                              id={"r7FV0"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Entity Type"}
                              style={qdmstyles.rFV}
                              className={classes.entity}
                            ></Typography>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"7Hr8S"}
                            container={""}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            lg={"2"}
                            md={"2"}
                            sm={"2"}
                            item={true}
                          >
                            <Typography
                              key={"0"}
                              id={"qp7gH"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Entity Name"}
                              className={classes.entity}
                              style={qdmstyles.qpgH}
                            ></Typography>
                          </Grid>
                          <Grid
                            key={"2"}
                            id={"2DPvI"}
                            container={""}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            lg={2}
                            md={2}
                            sm={2}
                            item={true}
                          >
                            <FieldLabel
                              key={"0"}
                              id={"nfRjR"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"status"}
                              required
                              style={qdmstyles.nfRjR}
                            ></FieldLabel>
                          </Grid>
                          <Grid
                            key={"3"}
                            id={"ITURC"}
                            container={""}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            lg={1}
                            md={1}
                            sm={1}
                          >
                            <Typography
                              key={"0"}
                              id={"3SVbe"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              className={classes.entity}
                              children={"Formulary"}
                              style={qdmstyles.SVbe}
                            ></Typography>
                          </Grid>
                          <Grid
                            key={"4"}
                            id={"CajG1"}
                            container={""}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            lg={"2"}
                            md={"2"}
                            sm={"2"}
                            item={true}
                          >
                            <Typography
                              key={"0"}
                              id={"844XH"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              className={classes.entity}
                              children={"Effective From"}
                              style={qdmstyles.XH}
                            ></Typography>
                          </Grid>
                          <Grid
                            key={"5"}
                            id={"CajG1"}
                            container={""}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            lg={"2"}
                            md={"2"}
                            sm={"2"}
                            item={true}
                          >
                            <Typography
                              key={"0"}
                              id={"mO3PH"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              className={classes.entity}
                              children={"Effective To"}
                              style={qdmstyles.mOPH}
                            ></Typography>
                          </Grid>
                          <Grid
                            key={"6"}
                            id={"CajG1"}
                            container={""}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            lg={"2"}
                            md={"2"}
                            sm={"2"}
                            item={true}
                          >
                            <Typography
                              key={"0"}
                              id={"uc4F1"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              className={classes.entity}
                              children={"Reason"}
                              style={qdmstyles.ucF}
                            ></Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          key={"2"}
                          id={"dkeHT"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Divider
                            key={"0"}
                            id={"30G6A"}
                            variant={"fullWidth"}
                          ></Divider>
                        </Grid>
                        {this.state.entityLevelStatus?.map((item, index) => {
                          return (
                            <Grid
                              key={"3"}
                              id={"dqYyZ"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <ComponentRender
                                key={"0"}
                                id={"TRmUl"}
                                component={
                                  <ALL.Statuslist
                                    isEdit={isEdit}
                                    propsState={this.state}
                                    setFun={(stateName, propertyKey, value) => {
                                      this.changeStateForArrayElement(
                                        stateName,
                                        propertyKey,
                                        value,
                                        index
                                      );
                                      // if (propertyKey === "status") {
                                      //   if (
                                      //     value?.value === "CodingMaster/10273"
                                      //   ) {
                                      //     this.changeStateForArrayElement(
                                      //       stateName,
                                      //       "formulary",
                                      //       false,
                                      //       index
                                      //     );
                                      //   }
                                      // }
                                    }}
                                    stateName={"entityLevelStatus"}
                                    statevalue={
                                      this.state?.entityLevelStatus?.[index]
                                    }
                                    drugStatusOptions={
                                      options?.drug_status ?? []
                                    }
                                    entityNameOptions={eNameOptions ?? []}
                                    data={item}
                                    onDeleteClick={() =>
                                      this.handleDeleteClick(
                                        "entityLevelStatus",
                                        index
                                      )
                                    }
                                  />
                                }
                              ></ComponentRender>
                            </Grid>
                          );
                        })}
                      </>
                    )}
                  </Paper>
                </Grid>
              </Grid>
              {/* Drug Status Section Starts */}
            </Paper>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}
const mapStateToProps = (state) => ({
  dropdownOptions:
    state?.drugCatalogMasterSlice?.drugCatalogMasterDropdownQuery,
  entityNameOptions:
    state?.drugCatalogMasterSlice?.drugCatalogMasterEntityNameOptions,
  drugData: state?.drugCatalogMasterSlice?.drugCatalogMasterSingleread,
});

export default connect(
  mapStateToProps,
  actions
)(
  withRouter(
    withAllContexts(
      translate()(withStyles(styles, { withTheme: true })(Drugintscreen))
    )
  )
);
