import * as React from "react";
import PropTypes from "prop-types";

const Ambulance = (props) => (

  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="25.362" viewBox="0 0 22.054 25.362" {...props}>
    <path id="icons8-ambulance_1_" data-name="icons8-ambulance (1)" d="M14.2,3.757a.827.827,0,0,1-.827-.827v-1.1a.827.827,0,0,1,1.654,0v1.1A.827.827,0,0,1,14.2,3.757Zm4.962,2.757h-1.1a.827.827,0,1,1,0-1.654h1.1a.827.827,0,1,1,0,1.654ZM16.957,4.308A.827.827,0,0,1,16.372,2.9l.551-.551a.827.827,0,1,1,1.169,1.169l-.551.551A.822.822,0,0,1,16.957,4.308Zm-5.513,0a.824.824,0,0,1-.585-.242l-.551-.551a.827.827,0,0,1,1.169-1.169l.551.551a.827.827,0,0,1-.584,1.411Zm-1.1,2.205h-1.1a.827.827,0,1,1,0-1.654h1.1a.827.827,0,1,1,0,1.654Zm6.341.827a2.481,2.481,0,0,0-4.962,0v.827h4.962Zm8.67,7.738a.8.8,0,0,0-.053-.07L21.58,10.572a3.028,3.028,0,0,0-2.49-1.3H7.032A3.036,3.036,0,0,0,4,12.3v8.27a3.035,3.035,0,0,0,2.8,3.021,3.3,3.3,0,0,0,6.519.012h3.408a3.3,3.3,0,0,0,6.519-.012,3.035,3.035,0,0,0,2.8-3.021V17.386A4.123,4.123,0,0,0,25.351,15.079ZM10.065,24.708a1.654,1.654,0,1,1,1.654-1.654A1.656,1.656,0,0,1,10.065,24.708Zm2.481-9.373H11.168v1.378a.827.827,0,0,1-1.654,0V15.335H8.135a.827.827,0,0,1,0-1.654H9.513V12.3a.827.827,0,1,1,1.654,0v1.378h1.378a.827.827,0,1,1,0,1.654Zm7.443,9.373a1.654,1.654,0,1,1,1.654-1.654A1.656,1.656,0,0,1,19.989,24.708Zm-1.93-8.27a1.38,1.38,0,0,1-1.378-1.378V10.924H19.09a1.375,1.375,0,0,1,1.143.609.8.8,0,0,0,.053.07L24,16.035a2.492,2.492,0,0,1,.206.4H18.059Z" transform="translate(-4 -1)" fill="#2a3c50" />
  </svg>
);

export default Ambulance;

Ambulance.defaultProps = {
  color: "#2a3c50",
};

Ambulance.propTypes = {
  color: PropTypes.string,
};
