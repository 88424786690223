/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Providing the AuthContext from /src/context which is used in the /src/App.js.
 */

import React from "react";
import { connect } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import jsonwebtoken from "jsonwebtoken";
import { LoggerContext } from "./contexts";
import Logger from "qdm-log4js";

class AppLogger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    if (localStorage.getItem("userInfo")) {
      let log = new Logger();
      let userId = jsonwebtoken.decode(
        localStorage.getItem("userInfo")
      )?.practitionerid;
      let projectName = "CLMM";
      log.init(userId, projectName);
      this.setState({
        data: log,
      });
    }
  };

  setLog = async (logType, message) => {
    let log = this.state.data;

    // Application Logs
    switch (logType) {
      case "trace":
        return await log.trace(message);
      case "info":
        return await log.info(message);
      case "warn":
        return await log.warn(message);
      case "debug":
        return await log.debug(message);
      case "error":
        return await log.error(message);
      case "fatal":
        return await log.fatal(message);

      default:
        return;
    }
  };

  render() {
    return (
      <LoggerContext.Provider
        value={{
          ...this.state,
          getData: this.getData,
          setLogData: this.setLog,
        }}
      >
        {this.props.children}
      </LoggerContext.Provider>
    );
  }
}
const mapStateToProps = (state) => ({
  loggerUserInfo: state?.signInReducerApiSlice?.loggedUserInfo?.data,
});

export default connect(mapStateToProps, actions)(AppLogger);
