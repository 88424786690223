const Routes = {
  orderRoutingScreen: "/orderroutingscreen",
  addnewpharmacy: "/addnewpharmacy",
  pharmacyTest: "/pharmacy_test",
  login: "/login",
  pharmacyTable: "/pharmacy_table",
  editNewPharmacy: "/editnewpharmacy",
  pharmacy: "/pharmacy",
  // Drug Catalog Master Starts
  drugmastertable: "/drugmastertable",
  drugintscreen: "/drugintscreen",
  alertrestriction: "/alertrestriction",

  // Drug Catalog Master Ends
  frequency: "/frequency",
  addNewFrequency: "/addnewfrequency",
  addnewalertandrestriction: "/addnewalertandrestriction",
  addFrequency: "/addnewfrequency_org",
  editFrequency: "/editnewfrequency_org",
  // addnewfrequencyOrg: "/addnewfrequency_org",
  // editNewFrequencyOrg: "/editnewfrequency_org",
  pharmacyVerify: "/pharmacyverify",
  cartOrder: "/cartorder",
  cartVerify: "/cartOrderVerify",
  
  // Reports
  report: "/report",

  //Task and Document
  taskAndDocument: "/taskanddocument",

  // Cart Definition
  cartDefinition: "/cartdefinition",
  cartDefinitionUpsert: "/cartdefinitionupsert",

  //Primary Care
  primaryCare: "/primarycare",

  // Masters - PC
  organizationConfig: "/organizationConfigPC",
  organization: "/organizationPC",
  administration: "/administrationPC",
  permissionManagement: "/permissionManagementPC",
  userManagement: "/userManagementPC",
  practitionerMaster: "/practitionerMasterPC",
  consultMaster: "/consultMasterPC",
  addperson: "/addpersonPC",
  addpractitioner: "/addpractitioner",

  //Printer Queue
  printerQueue: "/printerQueue",


  DrugMasters: "/drug",
  addNewDrug: "/addnewdrug_org",
  editNewDrug: "/editnewdrug_org",
};

export { Routes };

export const basicRouters = ["/frequency", "/pharmacy", "/pharmacyverify"];
