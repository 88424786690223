import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import InputBase from "@material-ui/core/InputBase";

// const StripInput = withStyles((theme) => ({}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: ({ minWidth }) => ({
    flex: 0,
    gap: 10,
    padding: "4px 8px",
    background: "white",
    borderRadius: "4px",
    border: "1px solid #DEE5EC",
    "& .App1-MuiInputBase-input": {
      minWidth: minWidth,
    },
  }),
  input: {
    padding: 0,
    "&:focus": {
      backgroundColor: "unset",
    },
  },
}));

function SwitchableInputText(props) {
  // <------------------Hooks--------------->
  const theme = useTheme();
  const classes = useStyles(props);

  // <-----------------Others--------------->
  const { isInput } = props;

  // <-----------------Render--------------->
  return (
    <>
      {isInput ? (
        <>
          <InputBase classes={classes} {...props} />
        </>
      ) : (
        <Typography
          variant="body2"
          style={{ color: theme.palette.text.grey, ...props.typographyStyles }}
        >
          {props.value}
        </Typography>
      )}
    </>
  );
}

export default SwitchableInputText;

SwitchableInputText.defaultProps = {
  isInput: false,
  value: "",
  onChange: () => {},
  minWidth: 20,
  typographyStyles: {},
};

SwitchableInputText.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isInput: PropTypes.bool,
  minWidth: PropTypes.number,
  typographyStyles: PropTypes.object,
};
