import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Box, Grid, Typography, Button, IconButton } from "@material-ui/core";
import { TrashIcon } from "../../assets";

import { Autocomplete } from "@material-ui/lab";
import qdmstyles from "./styles.json";
import "./index.css";
import copy from "fast-copy";
import makeStyles from "@material-ui/core/styles/makeStyles";

var aes256 = require("aes256");

const useStyles = makeStyles((theme) => ({
  orderchildtitle: {
    color: theme.palette.primary.light
  },
  orderdetails: {
    color: theme.palette.text.primary,
  },
  autocomplete: {
    "& .App1-MuiOutlinedInput-notchedOutline":{
      borderColor:theme.palette.border.textField
    },
   },
   paper: {
     color:"#6a7888"
   },
   notchedOutline:{
    borderColor:theme.palette.border.textField
  },
  add: {
    "&.App1-MuiButton-contained:hover": {
        backgroundColor: theme.palette.button.hover
      },
      color:theme.palette.button.text,
      backgroundColor:theme.palette.button.background,
  },
}));

function Orderingdetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pharmacy_orderrouting_orderingfacility_dropdownData = useSelector(
    (state) =>
      state
        ?.orderingdetailspharmacy_orderrouting_orderingfacility_dropdown_BtPu1Reducer
        ?.pharmacy_orderrouting_orderingfacility_dropdown_BtPu1?.data
  );
  const pharmacy_orderrouting_sourcelocationtype_dropdownData = useSelector(
    (state) =>
      state
        ?.orderingdetailspharmacy_orderrouting_sourcelocationtype_dropdown_BtPu1Reducer
        ?.pharmacy_orderrouting_sourcelocationtype_dropdown_BtPu1?.data
  );
  const pharmacy_orderrouting_sourcelocation_dropdownData = useSelector(
    (state) =>
      state
        ?.orderingdetailspharmacy_orderrouting_sourcelocation_dropdown_pGqoMReducer
        ?.pharmacy_orderrouting_sourcelocation_dropdown_pGqoM?.data
  );

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
    orderingdetails_btpu1: null,
    orderingdetails_vxwxs: null,
    orderingdetails_60h3x: null,
    orderingdetails_fqnvb: null,
    orderingdetails_pgqom: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const onload_btpu1 = async (event, data) => {
    let index = data;
    const payload0 = {
      history: props.history.location.state,
      data: data,
    };
    let data0 = await dispatch(
      actions.orderingdetailspharmacy_orderrouting_orderingfacility_dropdown_BtPu1(
        payload0
      )
    );
    const payload1 = {
      history: props.history.location.state,
      data: data,
    };
    let data1 = await dispatch(
      actions.orderingdetailspharmacy_orderrouting_sourcelocationtype_dropdown_BtPu1(
        payload1
      )
    );

    let updatestate = {};
    setState({
      ...state,
      ...updatestate,
      orderingdetails_btpu1: event?.target?.value,
    });
  };
  const onchange_vxwxs = (e, index) => {
    let updatestate = {};

    setState({ ...state, ...updatestate, orderingdetails_vxwxs: e });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = { ...stateValue[index], orderingdetails_vxwxs: e };
    props.setFun(stateValue);
  };
  const onclick_60h3x = (e, index) => {
    let updatestate = {};

    setState({
      ...state,
      ...updatestate,
      orderingdetails_60h3x: e?.target?.value,
    });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];
    props.setFun([...stateValue, {}]);
  };
  const onchange_fqnvb = (e, index) => {
    let updatestate = {};

    setState({ ...state, ...updatestate, orderingdetails_fqnvb: e });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = { ...stateValue[index], orderingdetails_fqnvb: e };
    props.setFun(stateValue);
  };
  const onchange_pgqom = async (event, data) => {
    let index = data;
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = { ...stateValue[index], orderingdetails_pgqom: event };
    props.setFun(stateValue);

    const payload0 = {
      orderingdetails_loccode: event?.code?._id ?? event?.code,
      orderingdetails_orgcode:
        state.orderingdetails_fqnvb?.entitycode?._id ??
        state.orderingdetails_fqnvb?.entitycode,
      history: props.history.location.state,
      data: data,
    };
    let data0 = await dispatch(
      actions.orderingdetailspharmacy_orderrouting_sourcelocation_dropdown_pGqoM(
        payload0
      )
    );

    let updatestate = {};
    setState({ ...state, ...updatestate, orderingdetails_pgqom: event });
  };
  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events
    onload_btpu1();
    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {
    if (
      pharmacy_orderrouting_sourcelocationtype_dropdownData?.loading !==
      pharmacy_orderrouting_sourcelocationtype_dropdownData?.loading
    ) {
      if (
        !pharmacy_orderrouting_sourcelocationtype_dropdownData?.loading &&
        pharmacy_orderrouting_sourcelocationtype_dropdownData.error
      ) {
      } else if (
        !pharmacy_orderrouting_sourcelocationtype_dropdownData?.loading &&
        !pharmacy_orderrouting_sourcelocationtype_dropdownData.error
      ) {
      }
    }

    if (
      pharmacy_orderrouting_sourcelocation_dropdownData?.loading !==
      pharmacy_orderrouting_sourcelocation_dropdownData?.loading
    ) {
      if (
        !pharmacy_orderrouting_sourcelocation_dropdownData?.loading &&
        pharmacy_orderrouting_sourcelocation_dropdownData.error
      ) {
      } else if (
        !pharmacy_orderrouting_sourcelocation_dropdownData?.loading &&
        !pharmacy_orderrouting_sourcelocation_dropdownData.error
      ) {
      }
    }
  }, [state]);

  const onDeleteOrderingDetailsClick = (index) => {
    
    const { statevalue } = props || {};
    const copiedstatevalue = copy(statevalue);
    copiedstatevalue.splice(index, 1);
    props.setFun(copiedstatevalue);
  };

  const { data, index } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Box key={"0"} id={"0jBuw"} m={1} component={"div"}>
            <Box key={"0"} id={"8OHOP"} m={1} component={"div"}>
              <Grid
                key={"0"}
                id={"7a5rV"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <Grid
                  key={"0"}
                  id={"4If2S"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  xs={"6"}
                  xl={"6"}
                  sm={"6"}
                  md={"6"}
                  lg={"6"}
                >
                  <Typography
                    key={"0"}
                    id={"VYVmg"}
                    align={"inherit"}
                    display={"initial"}
                    className={classes.orderdetails}
                    variant={"body1"}
                    children={t(
                      "pharmacy.addNewDrugCatalogMaster.orderingDetails"
                    )}
                    style={qdmstyles.Hyze}
                  ></Typography>
                </Grid>
                <Grid
                  key={"1"}
                  id={"RVSUy"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  xs={"6"}
                  xl={"6"}
                  sm={"6"}
                  md={"6"}
                  lg={"6"}
                >
                  <Button
                    key={"0"}
                    onClick={(e) => onclick_60h3x(e, index)}
                    id={"h8YUT"}
                    variant={"text"}
                    color={"primary"}
                    children={"+ Add New"}
                    style={qdmstyles.hX}
                  ></Button>
                </Grid>
              </Grid>
            </Box>
            <Box
              key={"1"}
              id={"7ncTc"}
              m={1}
              component={"div"}
              onLoad={(e) => props.setFun(props?.statevalue ?? [{}])}
            >
              {(props?.statevalue ?? [{}])?.map((data, index) => {
                return (
                  <React.Fragment>
                    <Grid
                      key={"0"}
                      id={"kjwa3"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      item={true}
                      spacing={"2"}
                      // style={qdmstyles.BtPu}
                      onLoad={(e) => props.setFun(props?.statevalue ?? [{}])}
                    >
                      {/* {(props?.statevalue ?? [{}])?.map((data, index) => {
                        return ( */}
                      <React.Fragment>
                        <Grid
                          key={"0"}
                          id={"kxf7u"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"4"}
                          item={true}
                          md={"4"}
                          sm={"4"}
                          xl={"4"}
                          xs={"4"}
                        >
                          <Typography
                            key={"0"}
                            id={"ordering_facility_heading"}
                            align={"inherit"}
                            color={"initial"}
                           className={classes.orderchildtitle}
                            display={"initial"}
                            variant={"body1"}
                            children={
                              <span>
                                Ordering Facility
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            }
                            aliasName={"orderingfacilityheading"}
                            style={qdmstyles.FEwx}
                          ></Typography>
                          <Autocomplete
                            id={"fQNvb"}
                            style={qdmstyles?.fQNvb}
                            onLoad={() =>
                              setState({
                                orderingdetails_fqnvb:
                                  state?.orderingdetails_fqnvb ?? null,
                              })
                            }
                            classes={{
                              paper: classes.paper
                            }}
                            className={classes.autocomplete}
                            getOptionLabel={(option) => option?.["name"]}
                            options={
                              pharmacy_orderrouting_orderingfacility_dropdownData.result ??
                              []
                            }
                            size={"small"}
                            onChange={(e, newvalue) =>
                              onchange_fqnvb(newvalue, index)
                            }
                            renderInput={(params) => (
                              <ALLMCORE.TextField
                                {...params}
                                label={""}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"HkE9H"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"4"}
                          item={true}
                          md={"4"}
                          sm={"4"}
                          xl={"4"}
                          xs={"4"}
                        >
                          <Typography
                            key={"0"}
                            id={"location_type_heading"}
                            align={"inherit"}
                            className={classes.orderchildtitle}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={
                              <span>
                                Location Type
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            }
                            aliasName={"locationtypeheading"}
                            style={qdmstyles.KTQcq}
                          ></Typography>
                          <Autocomplete
                            id={"pGqoM"}
                            style={qdmstyles?.pGqoM}
                            onLoad={() =>
                              setState({
                                orderingdetails_pgqom:
                                  state?.orderingdetails_pgqom ?? null,
                              })
                            }
                            classes={{
                              paper: classes.paper
                            }}
                            className={classes.autocomplete}
                            getOptionLabel={(option) => option?.["display"]}
                            options={
                              pharmacy_orderrouting_sourcelocationtype_dropdownData.result ??
                              []
                            }
                            size={"small"}
                            onChange={(e, newvalue) =>
                              onchange_pgqom(newvalue, index)
                            }
                            renderInput={(params) => (
                              <ALLMCORE.TextField
                                {...params}
                                label={""}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          key={"2"}
                          id={"ZheNQ"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"4"}
                          item={true}
                          md={"4"}
                          sm={"4"}
                          xs={"4"}
                          xl={"4"}
                        >
                          <Typography
                            key={"0"}
                            id={"ordering_location_heading"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            className={classes.orderchildtitle}
                            variant={"body1"}
                            children={"Ordering Location"}
                            aliasName={"orderinglocationheading"}
                            style={qdmstyles.KWgic}
                          ></Typography>
                          <Grid item container xs={12}>
                            <Grid item xs>
                              <Autocomplete
                                id={"vXWXS"}
                                style={qdmstyles?.vXWXS}
                                onLoad={() =>
                                  setState({
                                    orderingdetails_vxwxs:
                                      state?.orderingdetails_vxwxs ?? null,
                                  })
                                }
                                classes={{
                                  paper: classes.paper
                                }}
                                className={classes.autocomplete}
                                getOptionLabel={(option) =>
                                  option?.["locationID"]
                                }
                                options={
                                  pharmacy_orderrouting_sourcelocation_dropdownData.result ??
                                  []
                                }
                                size={"small"}
                                onChange={(e, newvalue) =>
                                  onchange_vxwxs(newvalue, index)
                                }
                                renderInput={(params) => (
                                  <ALLMCORE.TextField
                                    {...params}
                                    label={""}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item>
                              <IconButton
                                onClick={() =>
                                  onDeleteOrderingDetailsClick(index)
                                }
                              >
                                <TrashIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                      {/* // ); // })} */}
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Box>
          </Box>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Orderingdetails));
