import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
//import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";

// import IDMPANEL from "idmpanel-pkg";
// import Config from "../../config";
// import cookie from "react-cookies";

import * as ALL from "../../components";
import * as ALLMCORE from "@material-ui/core";

// import { Modal } from "qdm-component-library";

import { Paper, Grid, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CustRowTable } from "custom-material-ui-component";

import qdmstyles from "./styles.json";
import "./index.css";
import { styles } from "./styles";
import { SearchInputBox } from "./searchInputBox";
import SearchIcon from "@material-ui/icons/Search";
import { Routes } from "../../router/routes";
import { withAllContexts } from "../../HOCs";
import copy from "fast-copy";
import Color from "../../themes/default.json"

// var aes256 = require("aes256");

const minSearchCount = 3;
class Drugmastertable extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      permissionData: null,
      page: 0,
      perPage: 10,
      searchQuery: "",
      fullData: [],
      filteredList: [],
      visibleList: [],
      shouldFilterLocally: false,
    };
  }

  callFullRead = (page, perPage, searchQuery, fetchWithoutLimit) => {
    const withLimitPayload = {
      page: page,
      perPage: perPage,
      searchQuery: searchQuery,
    };

    const withOutLimitPayload = {
      searchQuery: searchQuery,
      isWithoutLimit: true,
    };

    Promise.resolve(
      this.props
        .DRUG_CATALOG_MASTER_FULL_READ(
          fetchWithoutLimit ? withOutLimitPayload : withLimitPayload
        )
        .then((res) => {
          let filteredData = this.filterData(res?.payload?.data, searchQuery);
          let copiedData = copy(filteredData);

          let listToShow = fetchWithoutLimit
            ? this.limitData(copiedData, page, perPage)
            : copiedData;

          let s = {
            fullData: res?.payload?.data,
            filteredList: filteredData,
            visibleList: listToShow,
          };
          this.setState(s);
        })
        .catch((err) => console.error(err))
    );
  };

  limitData = (data, page, perPage) => {
    let start = page;
    let end = start + perPage;
    return data?.slice(start, end);
  };

  filterData = (data, searchQuery) => {
    if (searchQuery?.length) {
      let res = data?.filter(
        (item) =>
          item?.DrugCode?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
          item?.DrugType?.longdesc
            ?.toLowerCase()
            ?.includes(searchQuery?.toLowerCase()) ||
          item?.ShortDesc?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
      return res;
    }

    return data;
  };

  filterLocalList = (page, perPage) => {
    const { searchQuery } = this.state;
    let list = this.props.fullReadData?.data;
    list = this.filterData(list, searchQuery);

    let filteredList = copy(list);
    let copiedList = copy(list);

    let final = this.limitData(copiedList, page, perPage);

    this.setState({
      fullData: this.props.fullReadData?.data,
      filteredList: filteredList,
      visibleList: final,
    });
  };

  changeState = (key, value) => {
    let prevSearchQuery = "";
    this.setState(
      (prev) => {
        if (key === "searchQuery") {
          prevSearchQuery = prev.searchQuery;
        }
        return {
          [key]: value,
        };
      },
      () => {
        if (key === "searchQuery") {
          if (
            value?.length === 0 ||
            (value?.length === minSearchCount &&
              !this.state.shouldFilterLocally)
          ) {
            this.callFullRead(
              this.state.page,
              this.state.perPage,
              value,
              value?.length !== 0
            );
            this.setState({
              shouldFilterLocally: value?.length >= 3,
            });
            // this.props.DRUG_CATALOG_MASTER_FULL_READ({
            //   page: this.state.page,
            //   perPage: 0,
            //   searchQuery: value,
            // });
          } else if (value.length < minSearchCount) {
            if (prevSearchQuery.length > value.length) {
              this.setState({
                shouldFilterLocally: false,
              });
              // this.callFullRead(this.state.page, this.state.perPage, value);
            }
          } else if (this.state.shouldFilterLocally) {
            this.filterLocalList(0, 10);
          }
        }
      }
    );
  };

  async componentDidMount() {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }

    this.callFullRead(
      this.state.page,
      this.state.perPage,
      this.state.searchQuery
    );
    // await this.props.DRUG_CATALOG_MASTER_FULL_READ({
    //   page: this.state.page,
    //   perPage: this.state.perPage,
    //   searchQuery: this.state.searchQuery,
    // });
    /// setlogger && dispatch of all events

    /// fetch all permission
  }

  ///fetching all permission

  // shouldComponentUpdate(nextProps, nextState) {
  //   const { searchQuery: oldSearchQuery } = this.state;
  //   const { searchQuery: searchQuery } = nextState;

  //   if (oldSearchQuery !== searchQuery) {
  //     this.props.DRUG_CATALOG_MASTER_FULL_READ({
  //       page: this.state.page,
  //       perPage: this.state.perPage,
  //       searchQuery: searchQuery,
  //     });
  //   }

  //   return true;
  // }

  changeStatus = async (status, id) => {
    await this.props.DRUG_CATALOG_STATUS_UPDATE({
      key: id,
      status: status,
    });
    this.props.alert.setSnack({
      open: true,
      msg: `Status Updated Successfully`,
      severity: "success",
    });

    this.callFullRead(
      this.state.page,
      this.state.perPage,
      this.state.searchQuery
    );

    // await this.props.DRUG_CATALOG_MASTER_FULL_READ({
    //   page: this.state.page,
    //   perPage: this.state.perPage,
    //   searchQuery: this.state.searchQuery,
    // });
  };

  handlepageChange = async (currentPage, PerPage) => {
    this.setState(
      {
        ...this.state,
        page: (currentPage - 1) * PerPage,
        perPage: PerPage,
      },
      () => {
        if (this.state.shouldFilterLocally) {
          this.filterLocalList((currentPage - 1) * PerPage, PerPage);
        } else {
          this.callFullRead(
            (currentPage - 1) * PerPage,
            PerPage,
            this.state.searchQuery
          );
        }
      }
    );

    // await this.props.DRUG_CATALOG_MASTER_FULL_READ({
    //   page: (currentPage - 1) * PerPage,
    //   perPage: PerPage,
    //   searchQuery: this.state.searchQuery,
    // });
  };

  render() {
    const { classes } = this.props;
    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>

            {/* <DrugListingComponent
              onAddClick={() => {
                this.props.history.push(Routes.drugintscreen);
              }}
              onEditClick={(data) => {
                debugger;
                this.props.history.push({
                  pathname: Routes.drugintscreen,
                  state: { ...data },
                });
              }}
              onViewClick={(data) => {
                this.props.history.push({
                  pathname: Routes.drugintscreen,
                  state: { ...data, isView: true },
                });
              }}
            /> */}

            <Paper
              key={"0"}
              id={"S6KKa"}
              elevation={"{3}"}
              style={qdmstyles.SKKa}
              className={classes.root}
            >
              <Paper
                key={"0"}
                id={"zTaTr"}
                elevation={"{3}"}
                style={qdmstyles.zTaTr}
                className={classes.tableContent}
              >
                <Grid
                  key={"0"}
                  id={"q6Nyp"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid
                    key={"0"}
                    id={"P48UY"}
                    container={true}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                  >
                    <Grid
                      key={"0"}
                      id={"ajikb"}
                      container={""}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"2"}
                      item={true}
                      md={"4"}
                      sm={"4"}
                      xl={"2"}
                      xs={"4"}
                    >
                      <Typography
                        key={"0"}
                        id={"Kfob3"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        className={classes.drugtitle}
                        children={"Drug Catalog Master"}
                        style={{ ...qdmstyles.Kfob }}
                      ></Typography>
                    </Grid>
                    <Grid
                      key={"1"}
                      id={"SD4cP"}
                      container={""}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg
                      item={true}
                      md
                      sm
                      xs
                      xl
                      style={qdmstyles.SDcP}
                    >
                      <div className={classes.searchAndAddButtonContainer}>
                        <SearchInputBox
                          id="searchDrug"
                          placeholder="Search Code & Description"
                          onChange={(e) =>
                            this.changeState("searchQuery", e.target.value)
                          }
                          className={classes.searchAndButtonInput}
                          style={{ color: Color.palette.text.primary }}
                          startAdornment={
                            <ALLMCORE.InputAdornment position="start">
                              <SearchIcon htmlColor="#6A7888" />
                            </ALLMCORE.InputAdornment>
                          }
                        />

                        <Button
                          onClick={() => {
                            this.props.history.push(Routes.drugintscreen);
                          }}
                          key={"0"}
                          id={"HlSGX"}
                          variant={"contained"}
                          children={"+ add new"}
                          comment={""}
                          className={classes.addnew}
                          color={'secondary'}
                          disableElevation={true}
                          style={{
                            ...qdmstyles.HlSGX,
                          }}
                        ></Button>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"TMgBy"}
                    container={true}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    item={true}
                    style={qdmstyles.TMgBy}
                  >
                    <Grid
                      key={"0"}
                      id={"QlgdO"}
                      container={""}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      item={true}
                    >
                      <Paper
                        key={"0"}
                        id={"G28xA"}
                        elevation={"{3}"}
                        style={qdmstyles.GxA}
                        className={classes.G28xA}
                      >
                        <CustRowTable
                          key={"0"}
                          id={"ruP7y"}
                          HeaderComp={
                            <ALL.Drugmastertablerow
                              // propsState={this.props.fullReadData.data}
                              setFun={(value) => {
                                this.changeState("Drugmastertablerow", value);
                              }}
                              stateName={"Drugmastertablerow"}
                              statevalue={this.state?.Drugmastertablerow}
                            />
                          }
                          BodyComp={
                            <ALL.Drugmastertablevalue
                              propsState={this.state.visibleList}
                              // propsState={this.props.fullReadData.data}
                              handleStatusChange={this.changeStatus}
                              stateName={"Drugmastertablevalue"}
                              statevalue={this.state?.Drugmastertablevalue}
                              loading={this.props.fullReadData.loading}
                            />
                          }
                        ></CustRowTable>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Paper className={classes.paginationContainer}>
                  <ALLMCORE.Divider light className={classes.divider} />
                  {/* {this.props.fullReadData.data.length > 0 && ( */}
                  {this.state.fullData?.length > 0 && (
                    <ALL.CustomTablePagination
                      handlepageChange={(currentPage, PerPage) =>
                        this.handlepageChange(currentPage, PerPage)
                      }
                      count={
                        this.state.shouldFilterLocally
                          ? this.state.filteredList?.length
                          : this.props.fullReadData.data?.[0]?.Total?.[0]
                      }
                      incrementCount={this.state.page}
                      showPagination={true}
                      rowsPerPageOptions={[
                        { label: "10 Rows", value: 10 },
                        { label: "50 Rows", value: 50 },
                        { label: "100 Rows", value: 100 },
                      ]}
                    />
                  )}
                </Paper>
              </Paper>
            </Paper>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}
const mapStateToProps = (state) => ({
  fullReadData: state?.drugCatalogMasterSlice?.drugCatalogMasterFullread,
});

export default connect(
  mapStateToProps,
  actions
)(
  withRouter(withAllContexts(translate()(withStyles(styles)(Drugmastertable))))
);
