/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Providing the AlertContext from (/src/context/) which is used by /src/App.js.
 */

 import React from "react";
 import {  DateTimeContext } from "./contexts";
 
 class AppDateTime extends React.Component {
   constructor(props) {
     super(props);
     this.state = {
      format: "DD/MM/YYYY"
     };
   }
 
   set = (props) => {
     this.setState({ ...props });
   };
 
   render() {
     return (
       <DateTimeContext.Provider
         value={{
           ...this.state,
           setFormat: this.set,
         }}
       >
         {this.props.children}
       </DateTimeContext.Provider>
     );
   }
 }
 
 export default AppDateTime;