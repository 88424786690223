import { makeStyles } from "@material-ui/core/styles";

export const useDividerStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#DEE5EC",
  },
  flexItem: {
    height: "1px",
    minWidth: "77%",
    alignSelf: "center",
  },
}));