import React from "react";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";

import { Grid, Avatar, Typography } from "@material-ui/core";
import HeightIcon from "@material-ui/icons/Height";

import qdmstyles from "./styles.json";
import "./index.css";

function VitalsInfo(props) {
  const { propsState, activeIndex } = props;

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          {propsState?.map((item, index) => {
            if (activeIndex === index) {
              let value = item.component;
              return (
                <Grid
                  id={"vitals_container"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  aliasName={"vitalsContainer"}
                  spacing={"0"}
                  style={qdmstyles.hdOXC}
                >
                  {value?.map((val, index) => {
                    let HigBp = value?.find((x) => x.obscode === "HIGHBP");
                    return (
                      <>
                        {val?.obscode === "TEMP" && (
                          <Grid
                            id={"temp_div"}
                            container={false}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            aliasName={"tempDiv"}
                            item={true}
                            lg={"3"}
                            md={"3"}
                            sm={"3"}
                            xl={"3"}
                            xs={"3"}
                            spacing={"1"}
                            style={{ ...qdmstyles.pdfl, marginRight: "12px" }}
                          >
                            <Avatar
                              id={"temp_logo"}
                              alt={"Avatar"}
                              variant={"rounded"}
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316444133"
                              }
                              aliasName={"tempLogo"}
                              style={qdmstyles.infM}
                            ></Avatar>
                            <Typography
                              id={"temp"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={`${val?.obsvalue}.0 F`}
                              aliasName={"temp"}
                              style={qdmstyles.Crgy}
                            ></Typography>
                          </Grid>
                        )}

                        {val?.obscode === "HEIGHT" && (
                          <Grid
                            id={"temp_div"}
                            container={false}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            aliasName={"tempDiv"}
                            item={true}
                            lg={"3"}
                            md={"3"}
                            sm={"3"}
                            xl={"3"}
                            xs={"3"}
                            spacing={"1"}
                            style={{ ...qdmstyles.pdfl, marginRight: "12px" }}
                          >
                            <Avatar
                              id={"temp_logo"}
                              alt={"Avatar"}
                              variant={"rounded"}
                              // src={
                              //   "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316444133"
                              // }
                              aliasName={"tempLogo"}
                              style={qdmstyles.infM}
                            >
                              <HeightIcon fontSize="12px" />
                            </Avatar>
                            <Typography
                              id={"temp"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={`${val?.obsvalue} Cm`}
                              aliasName={"temp"}
                              style={qdmstyles.Crgy}
                            ></Typography>
                          </Grid>
                        )}
                        {val?.obscode === "RESP" && (
                          <Grid
                            id={"lungs_div"}
                            container={false}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            aliasName={"lungsDiv"}
                            item={true}
                            lg={"4"}
                            md={"4"}
                            sm={"4"}
                            xl={"4"}
                            xs={"4"}
                            spacing={"1"}
                            style={{ ...qdmstyles.pdfl, marginRight: "12px" }}
                          >
                            <Avatar
                              id={"lungs_logo"}
                              alt={"Avatar"}
                              variant={"rounded"}
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316452764"
                              }
                              aliasName={"lungsLogo"}
                              style={qdmstyles.egQH}
                            ></Avatar>
                            <Typography
                              id={"lungs_capacity"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={`${val?.obsvalue} bpm`}
                              aliasName={"lungsCapacity"}
                              style={qdmstyles.NWrRz}
                            ></Typography>
                          </Grid>
                        )}
                        {val?.obscode === "BSA" && (
                          <Grid
                            id={"lungs_div"}
                            container={false}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            aliasName={"lungsDiv"}
                            item={true}
                            lg={"4"}
                            md={"4"}
                            sm={"4"}
                            xl={"4"}
                            xs={"4"}
                            spacing={"1"}
                            style={{ ...qdmstyles.pdfl, marginRight: "12px" }}
                          >
                            <Avatar
                              id={"lungs_logo"}
                              alt={"Avatar"}
                              variant={"rounded"}
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316452764"
                              }
                              aliasName={"lungsLogo"}
                              style={qdmstyles.egQH}
                            ></Avatar>
                            <Typography
                              id={"lungs_capacity"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={`${val?.obsvalue} BSA  `}
                              aliasName={"lungsCapacity"}
                              style={qdmstyles.NWrRz}
                            ></Typography>
                          </Grid>
                        )}
                        {val?.obscode === "O2S" && (
                          <Grid
                            id={"oxygen_div"}
                            container={false}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            aliasName={"oxygenDiv"}
                            item={true}
                            lg={"3"}
                            md={"3"}
                            sm={"3"}
                            xl={"3"}
                            xs={"3"}
                            spacing={"1"}
                            style={{ ...qdmstyles.pdfl, marginRight: "12px" }}
                          >
                            <Avatar
                              id={"oxygen_logo"}
                              alt={"Avatar"}
                              variant={"rounded"}
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316452926"
                              }
                              aliasName={"oxygenLogo"}
                              style={qdmstyles.etMY}
                            ></Avatar>
                            <Typography
                              id={"oxygen"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={`${val?.obsvalue} %`}
                              aliasName={"oxygen"}
                              style={qdmstyles.acB}
                            ></Typography>
                          </Grid>
                        )}
                        {val?.obscode === "WEIGHT" && (
                          <Grid
                            id={"weight_div"}
                            container={false}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            aliasName={"weightDiv"}
                            item={true}
                            lg={"3"}
                            md={"3"}
                            sm={"3"}
                            xl={"3"}
                            xs={"3"}
                            spacing={"1"}
                            style={{ ...qdmstyles.pdfl, marginRight: "12px" }}
                          >
                            <Avatar
                              id={"weight_logo"}
                              alt={"Avatar"}
                              variant={"rounded"}
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316453047"
                              }
                              aliasName={"weightLogo"}
                              style={qdmstyles.OuSZo}
                            ></Avatar>
                            <Typography
                              id={"weight"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={`${val?.obsvalue} kg`}
                              aliasName={"weight"}
                              style={qdmstyles.Jtnaj}
                            ></Typography>
                          </Grid>
                        )}
                        {val?.obscode === "LOWBP" && (
                          <Grid
                            id={"blood_sample_div"}
                            container={false}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            aliasName={"bloodSampleDiv"}
                            item={true}
                            lg={"5"}
                            md={"5"}
                            sm={"5"}
                            xl={"5"}
                            xs={"5"}
                            spacing={"1"}
                            style={{ ...qdmstyles.pdfl, marginRight: "12px" }}
                          >
                            <Avatar
                              id={"blood_sample_logo"}
                              alt={"Avatar"}
                              variant={"rounded"}
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316453349"
                              }
                              aliasName={"bloodSampleLogo"}
                              style={qdmstyles.UAuWR}
                            ></Avatar>
                            <Typography
                              id={"blood_sample"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={`${HigBp?.obsvalue}/${val.obsvalue} mm/Hg`}
                              aliasName={"bloodSample"}
                              style={qdmstyles.mDAb}
                            ></Typography>
                          </Grid>
                        )}
                        {val?.obscode === "PULSE" && (
                          <Grid
                            id={"pulse_div"}
                            container={false}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            aliasName={"pulseDiv"}
                            item={true}
                            lg={"3"}
                            md={"3"}
                            sm={"3"}
                            xl={"3"}
                            xs={"3"}
                            spacing={"1"}
                            style={{ ...qdmstyles.pdfl, marginRight: "12px" }}
                          >
                            <Avatar
                              id={"pulse_logo"}
                              alt={"Avatar"}
                              variant={"rounded"}
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316453594"
                              }
                              aliasName={"pulseLogo"}
                              style={qdmstyles.HaTmp}
                            ></Avatar>
                            <Typography
                              id={"pulse"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={`${val?.obsvalue} bpm`}
                              aliasName={"pulse"}
                              style={qdmstyles.Gcgp}
                            ></Typography>
                          </Grid>
                        )}
                      </>
                    );
                  })}
                </Grid>
              );
            } else {
              return null;
            }
          })}
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(VitalsInfo));
