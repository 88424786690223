/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Providing the AuthContext from /src/context which is used in the /src/App.js.
 */

import React from "react";
import { BorderLinearProgress } from "qdm-component-library";
import { NetworkCall } from "./networkcall";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
import jsonwebtoken from "jsonwebtoken";
import {
  LocalStorageKeys,
  NetWorkCallMethods,
  semverGreaterThan,
  refreshCacheAndReload,
} from "./utils";

class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    NetworkCall(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ":" + window.location.port : ""
      }/meta.json?time=${Date.now()}`,
      NetWorkCallMethods.get,
      null,
      null,
      false,
      true
    )
      .then((_) => {
        const isVersion = semverGreaterThan(
          _.data.version,
          localStorage.getItem(LocalStorageKeys.version)
        );
        localStorage.setItem(LocalStorageKeys.version, _.data.version);
        if (isVersion) {
          refreshCacheAndReload();
        }
      })
      .catch((err) => {
        console.log("err:", err);
      });

    if (
      localStorage.getItem("token") &&
      localStorage.getItem("userInfo") &&
      localStorage.getItem("userInfoKeys")
    ) {
      try {
        let userInfo = jsonwebtoken.decode(localStorage.getItem("userInfo"));
        let userInfoKeys = JSON.parse(
          atob(localStorage.getItem("userInfoKeys"))
        );

        await this.props.LOGGED_USER_INFO({
          params: userInfo,
          keys: userInfoKeys,
        });
      } catch (err) {
        localStorage.clear();
      }
    } else {
      //localStorage.clear();
      // window.location.href = "/";
    }
  }

  render() {
    let { loading } = this.props;

    return (
      <>
        {loading && <BorderLinearProgress />}
        {this.props.children}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(AppAuth);
