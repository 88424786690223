import React from "react";
import MuiPopover from "@material-ui/core/Popover";
import { usePopoverStyles } from "./style";

function Popover(props) {
  const popoverStyles = usePopoverStyles();
  return <MuiPopover classes={popoverStyles} {...props} />;
}

export default Popover;
