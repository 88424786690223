import React from "react";
import { CommonTable, GenerateForm } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { AlertProps } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";

function DrugForm(props) {
  const dispatch = useDispatch();

  const drugDosagelist = useSelector((state) => state?.drugDosageSlice?.drug_dosage_list?.data);
  const loading = useSelector((state) => state?.drugDosageSlice?.drug_dosage_list?.loading);
  const saveloading = useSelector((state) => state?.drugDosageSlice?.drug_dosage_upsert?.loading);

  React.useLayoutEffect(() => {
    dispatch(actions.DRUG_DOSAGE_READ());
  }, [])

  const [state, setState] = React.useState({
    openEditFrom: false,
    saveloading: false,
    edit_data: null,
    status: true
  })

  const handleCloseForm = () => {
    const { changeState } = props
    changeState("open", false)
    setState({
      openEditFrom: false,
      edit_data: null
    })
  }

  const handleEdit = (e, data) => {
    setState({
      openEditFrom: true,
      edit_data: data,
      status: data?.status
    })
  }

  const saveForm = async (values) => {
    let type = "success";
    let data = {
      drug_dosage: values?.drug_dosage?.toUpperCase?.(),
      short_description: values.short_description,
      long_description: values.long_description,
      status: true,
    }
    if (state?.edit_data) {
      data["_key"] = state?.edit_data._key
      //   data["id"] = state?.edit_data.id
      data["status"] = state?.edit_data.status
      type = "update";
    }
  let save = await dispatch(actions.DRUG_DOSAGE_UPSERT(data));
   if (save.payload?.data?.Code === 1111) {
     let type =save.payload?.data?.validation_error?.[0]?.Errormsg === "[\"dosageform\"] fields must be unique" ?  "unique" :"error"
     props.alert.setSnack(type);
   } else {
    await dispatch(actions.DRUG_DOSAGE_READ());
    await handleCloseForm();
    props.alert.setSnack(type);
  }
  }

  const statusChange = async (e, values, index) => {
    let data = {
      _key: values._key,
      status: e.target.checked
    }
    await dispatch(actions.DRUG_DOSAGE_STATUS_CHANGE(data));
    await dispatch(actions.DRUG_DOSAGE_READ());
    props.alert.setSnack("update")
  }

  // render() {
  const { open, openIndex, parent_id } = props;
  return <React.Fragment>
    <CommonTable
      parent_id={"drugform"}
      style={{ height: "calc(100vh - 257px)" }}
      rightAlign={["Status", "Action"]}
      handleEdit={handleEdit}
      handleCheckBox={statusChange}
      Header={["S.No", "Dosage Form", "Short Description", "Long Description", "Status", "Action"]}
      dataList={drugDosagelist}
      tableData={[
        { type: ["INCRIMENT"], name: "" },
        { type: ["TEXT"], name: "drug_dosage" },
        { type: ["TEXT"], name: "short_description" },
        { type: ["TEXT"], name: "long_description" },
        { type: ["CHECKBOX"], name: "status", align: "right" },
        { type: ["EDIT"], name: "", align: "right" },
      ]}
    />
    <GenerateForm
      parent_id={"drugform"}
      loading={saveloading}
      nocancel
      open={((open && openIndex === 4) || state?.openEditFrom) ? true : false}
      handlecancel={() => handleCloseForm()}
      save={saveForm}
      header={state?.edit_data ? "Edit Dosage Form" : 'Add New Dosage Form'}
      json={[{
        componet_type: "text",
        label: "Dosage Form",
        state_name: "drug_dosage",
        value: state?.edit_data?.drug_dosage ?? "",
        required: true,
        isUpperCase: true,
        disabled: state?.edit_data?.drug_dosage ? true : false
      },
      {
        componet_type: "text",
        label: "Short Description",
        state_name: "short_description",
        value: state?.edit_data?.short_description ?? "",
        required: true
      },
      {
        componet_type: "text",
        label: "Long Description",
        state_name: "long_description",
        value: state?.edit_data?.long_description ?? "",
        required: true
      },
      ]}
    />
  </React.Fragment>
}

export default withAllContexts(DrugForm);