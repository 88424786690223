import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    fontSize: 14,
    fontWeight: 500,
    textTransform: "unset",
    borderRadius: "8px !important",
    border: "0px !important",
    // color: props.selected ? "#FFFFFF" : "#2A3C50",
    // backgroundColor: props.selected ? "#2A60BC" : "#DEE5EC",

    background: "#DEE5EC",
    color: "#2A3C50",
    "&:hover": {
      background: "#DEE5EC",
    },

    "&.Mui-selected": {
      background: "#2A60BC",
      color: "#FFFFFF",
      "&:hover": {
        background: "#2A60BC",
      },
    },

    "&.Mui-disabled": {
      color: "#B4BBC3",
    },
  }),
}));

function CustomToggleButton(props) {
  const buttonStyles = useStyles(props);
  return <ToggleButton classes={buttonStyles} disableElevation {...props} />;
}

export default CustomToggleButton;
