import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import BatchParticular from "./batchParticular";
import DrugParticular from "./drugParticular";
import { StyledDivider } from "../../commonComponents";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // padding: theme.spacing(1.5),
  },
  divider: {
    background: "#DEE5EC",
  },
}));

const DrugItem = withStyles((theme) => ({
  root: {
    borderRadius: 10,
    // overflow: "hidden",
    border: `1px solid #DEE5EC`,
  },
}))(Box);

function Drug(props) {
  // <---------------Hooks------------------>
  const classes = useStyles();

  // <---------------Store States----------->

  // <-------------Component States--------->
  const [isCollapsed, setIsCollapsed] = useState(true);

  // <-----------------Handlers------------->
  const handleCollapseToggle = () => {
    setIsCollapsed((prev) => !prev);
  };

  const getRemainig = (total, issue) => {
    return total - issue;
  };
  const [isShow, setisShow] = React.useState(false);

  // <-----------------Effects-------------->

  const { batches, brand } = props;

  return (
    <div className={classes.wrapper}>
      <DrugItem>
        <DrugParticular
          {...props}
          isCollapsed={isCollapsed}
          handleCollapseToggle={handleCollapseToggle}
          isShow={isShow}
          setisShow={setisShow}
          isEdit={props.issueMedication && brand?.batches?.length === 1}
        />
        {isCollapsed && <StyledDivider className={classes.divider} />}
        <Collapse in={isCollapsed}>
          {batches.map((batch, index) => (
            <div key={batch?.uniqueId}>
              <BatchParticular
                {...props}
                BatchName={batch?.id}
                expiryDate={batch?.expiryDate}
                bin={batch?.bin?.number}
                aisle={batch?.bin?.aisle}
                availableQuantity={
                  <>
                    {batch?.availableQuantity}&nbsp;
                    {batch?.availableQuantityUOM}
                  </>
                }
                blockedQuantity={batch?.heldQuantity}
                amount={batch?.totalAmount}
                chargeqty={batch?.chargeqty}
                chargeuom={batch?.chargeuom}
                BalanceQuantity={getRemainig(batch?.stockqty, batch?.blockqty)}
                onBatchQuantityChange={(e, v_, b) => {
                  props.onBatchQuantityChange(e, index, v_, b)
                }
                }
                batch={batch}
                isShow={isShow}
                setisShow={setisShow}
              />
              {index !== batches.length - 1 && (
                <StyledDivider className={classes.divider} />
              )}
            </div>
          ))}
        </Collapse>
      </DrugItem>
    </div>
  );
}

export default Drug;

Drug.propTypes = {
  batchList: PropTypes.array,
};

Drug.defaultProps = {
  batchList: [1, 2, 3],
};
