import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { isEmptyObject } from "../../utils";
import { LoggerContext } from "../../contexts";

// import IDMPANEL from "idmpanel-pkg";
// import Config from "../../config";
// import cookie from "react-cookies";

// import * as ALL from "../../components";
// import * as ALLMCORE from "@material-ui/core";

// import { Modal } from "qdm-component-library";
// import { MuiStepper } from "custom-material-ui-component";
import {
  Paper,
  Grid,
  Typography,
  Button,
  Box,
  Avatar,
  TextField,
  //ButtonGroup,
  withStyles,
  Divider,
  Stepper,
  Step,
  StepLabel,
  IconButton,
} from "@material-ui/core";
import { CustRowTable } from "custom-material-ui-component";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";

import qdmstyles from "./styles.json";
import "./index.css";
import {
  Autocomplete,
  InputBase,
  CustomButton,
  CustomToggleButton,
} from "../../components";
import { Routes } from "../../router/routes";
import { CreateRule } from "sequence-rule-builder";
import copy from "fast-copy";
import { withAllContexts } from "../../HOCs";
//var aes256 = require("aes256");

// const STATUS_APPLICABILITY = {
//   serviceOrder: "serviceOrder",
//   medication: "medication",
// };

const RULE_TYPE = {
  Restriction: "CodingMaster/10294",
  Alert: "CodingMaster/10293",
};

const PRIVILEGE_TYPE = {
  ApprovalRequired: "CodingMaster/10299",
  OverriddenAccess: "CodingMaster/10300",
  AlertAndStop: "CodingMaster/10295",
  ContinueWithReason: "CodingMaster/10296",
  InformativeAlert: "CodingMaster/10297",
  NoAccess: "CodingMaster/10298",
};

// const PRIVILEGE_TYPE_OPTIONS = {
//   "CodingMaster/10294": [
//     PRIVILEGE_TYPE.ApprovalRequired,
//     PRIVILEGE_TYPE.OverriddenAccess,
//   ],
//   "CodingMaster/10293": [
//     PRIVILEGE_TYPE.AlertAndStop,
//     PRIVILEGE_TYPE.ContinueWithReason,
//     PRIVILEGE_TYPE.InformativeAlert,
//   ],
// };

const allowedRestrictionPrivilegeTypes = [
  PRIVILEGE_TYPE.ApprovalRequired,
  PRIVILEGE_TYPE.OverriddenAccess,
];

const allowedAlertPrivilegeTypes = [
  PRIVILEGE_TYPE.AlertAndStop,
  PRIVILEGE_TYPE.ContinueWithReason,
  PRIVILEGE_TYPE.InformativeAlert,
];

// const activeButtonProps = {
//   backgroundColor: "#2A60BC",
//   color: "#FFFFFF",
// };
// const buttonProps = {
//   backgroundColor: "#DEE5EC",
//   color: "#2A3C50",
// };

const styles = (theme) => ({
  subheaderRoot: {
    display: "flex",
  },
  subheaderPaper: {
    width: "100%",
    boxShadow: "0px 1px 6px #00000029",
    padding: "8px 24px",
  },
  subHeaderBack: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    "& > .App1-MuiTypography-root": {
      color: "#001C3C",
      fontWeight: 600,
      fontSize: 16,
    },
  },
  backButton: {
    backgroundColor: "#DEE5EC",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  divider: {
    height: "24px",
    alignSelf: "center",
    margin: "0px 24px",
    backgroundColor: "#B4BBC3",
  },
  stepper: {
    padding: "0px",
    width: "480px",
    "& .App1-MuiStepIcon-completed": {},
    "& .App1-MuiStepLabel-completed": {},
    "& .App1-MuiStepLabel-active": {
      color: "#2A60BC",
    },
    "& .App1-MuiStepConnector-line": {
      borderColor: "#2A60BC",
    },
  },
  subheaderCTA: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    "& > .App1-MuiTypography-root": {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  buttonFieldContainer: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  bottomCta: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
});

const initialRuleState = {
  editId: [],
  isRule: false,
  isExist: false,
  isOpen: false,
  group: {},
  rule: {},
  ruleData: [],
};

class Alertrestriction extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      permissionData: null,
      // rule_type: RULE_TYPE.Restriction,
      // privilege_type: PRIVILEGE_TYPE_OPTIONS[RULE_TYPE.Restriction][0],

      // Main States here
      orgn_code: "",
      service_applicablity: "",
      rule_type: "",
      privilege_type: "",
      alert_test_message: "",
      workflow: "",

      patient_criteria: {
        ...initialRuleState,
      },

      pract_criteria: {
        ...initialRuleState,
      },

      groupData: [],
      ruleData: [],
      _key: null,
    };
  }

  changeState = (key, value) => {
    this.setState((prev) => ({
      ...prev,
      [key]: value,
      // ...(key === "rule_type" && {
      //   privilege_type: PRIVILEGE_TYPE_OPTIONS[value][0],
      // }),
    }));
  };

  componentDidMount() {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }

    if (this.props?.location?.state?.isEdit) {
      this.props.backDrop.setBackDrop({
        open: true,
        message: "Loading...",
      });
    }

    /// setlogger && dispatch of all events

    /// fetch all permission

    this.loadEssentials();

    // Promise.resolve(this.props.DRUG_CATALOG_MASTER_DROPDOWN_QUERY({}))
    //   .then(() => {
    //     if (this.props?.location?.state?.isEdit) {
    //       this.preLoadData();
    //     }
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   });
  }

  loadEssentials = () => {
    Promise.all([
      this.props.DRUG_RULE__TYPE(),
      this.props.DRUG_PRIVILEGE__TYPE(),
      this.props.DRUG_SERVICE_APPLICABILITY_TYPE(),
      this.props.DRUG_FACILITY_OPTIONS(),
      this.props.DRUG_APPROVAL_WORKFLOW_OPTIONS(),
    ])
      .then(() => {
        const {
          drugRuleType,
          drugPrivilegeType,
          drugServiceApplicabilityType,
        } = this.props;
        let preSelectedServiceApplicabilityType =
          drugServiceApplicabilityType?.data?.[1];
        let preSelectedRuleType = drugRuleType?.data?.[0];

        let preSelectedPrivilegeType;

        // Selecting Privilege type based on allowed values
        if (preSelectedRuleType?.value === RULE_TYPE.Alert) {
          preSelectedPrivilegeType = drugPrivilegeType?.data?.filter(
            (x) => x?.value === allowedAlertPrivilegeTypes[0]
          )?.[0];
        } else if (preSelectedRuleType?.value === RULE_TYPE.Restriction) {
          preSelectedPrivilegeType = drugPrivilegeType?.data?.filter(
            (x) => x?.value === allowedRestrictionPrivilegeTypes[0]
          )?.[0];
        }

        this.setState({
          rule_type: preSelectedRuleType,
          privilege_type: preSelectedPrivilegeType,
          service_applicablity: preSelectedServiceApplicabilityType,
        });

        // Preloading State Part
        if (this.props?.location?.state?.isEdit) {
          this.preLoadData();
        }
      })
      .catch((e) => console.error(e));

    // Setting Group Data Options
    Promise.resolve(this.props.group_dropdown())
      .then((res) => {
        const groupOptions = res?.payload?.result;
        this.changeState("groupData", groupOptions);
      })
      .catch((e) => console.error(e));
  };

  preLoadData = () => {
    Promise.resolve(
      this.props.DRUG_ALERTS_RESTRICTIONS_SINGLE_READ({
        id: this.props?.location?.state?.docId,
      })
    )
      .then(async (res) => {
        const data =
          res?.payload?.data?.slice(res?.payload?.data?.length - 1)?.[0] || {};

        const {
          drugRuleType,
          drugPrivilegeType,
          drugServiceApplicabilityType,
          drugFacilityOptions,
          drugApprovalWorkflowOptions,
        } = this.props;

        this.setState((prev) => ({
          orgn_code: drugFacilityOptions?.data?.filter(
            (x) => x?.value === data?.orgn_code
          )?.[0],
          ...(drugServiceApplicabilityType?.data?.filter(
            (x) => x?.value === data?.service_applicablity
          )?.length > 0 && {
            service_applicablity: drugServiceApplicabilityType?.data?.filter(
              (x) => x?.value === data?.service_applicablity
            )?.[0],
          }),
          ...(drugRuleType?.data?.filter((x) => x?.value === data?.rule_type)
            ?.length > 0 && {
            rule_type: drugRuleType?.data?.filter(
              (x) => x?.value === data?.rule_type
            )?.[0],
          }),
          ...(drugPrivilegeType?.data?.filter(
            (x) => x?.value === data?.privilege_type
          )?.length && {
            privilege_type: drugPrivilegeType?.data?.filter(
              (x) => x?.value === data?.privilege_type
            )?.[0],
          }),
          alert_test_message: data?.alert_test_message,
          workflow: drugApprovalWorkflowOptions?.data?.filter(
            (x) => x?.value === data?.workflow
          )?.[0],
          patient_criteria: {
            ...prev.patient_criteria,
            isOpen:
              Array.isArray(data?.patient_criteria) &&
              data?.patient_criteria?.length > 0,
            editId: data?.patient_criteria,
          },
          pract_criteria: {
            ...prev.pract_criteria,
            isOpen:
              Array.isArray(data?.pract_criteria) &&
              data?.pract_criteria?.length > 0,
            editId: data?.pract_criteria,
          },
          _key: data?._key,
        }));
      })
      .catch(() => {});
  };

  ///fetching all permission

  shouldComponentUpdate(nextProps, nextState) {
    const { loading: oldLoading } =
      this.props?.drugAlertsRestrictionSingleRead || {};
    const { loading } = nextProps?.drugAlertsRestrictionSingleRead || {};

    if (oldLoading !== loading) {
      if (!loading) {
        this.props.backDrop.setBackDrop({
          open: false,
          message: "",
        });
      }
    }
    return true;
  }

  // Existing Rule Set
  // Patient Criteria Rule Set
  handlePatientCriteriaGroupStateChange = (value) => {
    Promise.resolve(this.props.rule_dropdown({ groupId: value?.value }))
      .then((res) => {
        this.changePatientCriteriaState("ruleData", res?.payload?.result);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        this.changePatientCriteriaState("group", value);
      });
  };

  changePatientCriteriaState = (key, value) => {
    this.setState((prev) => ({
      ...prev,
      patient_criteria: {
        ...prev.patient_criteria,
        [key]: value,
      },
    }));
  };

  setPatientCriteriaRuleBuilderAction = (data) => {
    if (!data) {
      this.changePatientCriteriaState("isRule", false);
      return;
    }

    this.setState((prev) => ({
      ...prev,
      patient_criteria: {
        ...prev.patient_criteria,
        editId: [data?.Result?.[0]?.properties?.doc],
        isRule: !prev.patient_criteria.isRule,
        isOpen: true,
      },
    }));
  };

  handlePatientRuleRemove = () => {
    this.setState((prev) => ({
      ...prev,
      patient_criteria: {
        ...prev.patient_criteria,
        ...copy(initialRuleState),
        isOpen: !prev.patient_criteria.isOpen,
      },
    }));
  };

  handlePatientCriteriaExistingRuleSave = () => {
    const { group, rule } = this.state.patient_criteria;
    if (isEmptyObject(group)) {
      return this.props.alert.setSnack({
        open: true,
        msg: `Please choose the group`,
        severity: "error",
      });
    }
    if (isEmptyObject(rule)) {
      return this.props.alert.setSnack({
        open: true,
        msg: `Please choose the rule`,
        severity: "error",
      });
    }

    this.setState((prev) => ({
      ...prev,
      patient_criteria: {
        editId: [
          {
            group: group,
            rule: rule,
          },
        ],
        isExist: false,
        isOpen: true,
        group: {},
        rule: {},
      },
    }));
  };

  // PRACTITIONER Criteria Rule Set
  handlePractitionerCriteriaGroupStateChange = (value) => {
    Promise.resolve(this.props.rule_dropdown({ groupId: value?.value }))
      .then((res) => {
        this.changePractitionerCriteriaState("ruleData", res?.payload?.result);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        this.changePractitionerCriteriaState("group", value);
      });
  };

  changePractitionerCriteriaState = (key, value) => {
    this.setState((prev) => ({
      ...prev,
      pract_criteria: {
        ...prev.pract_criteria,
        [key]: value,
      },
    }));
  };

  setPractitionerCriteriaRuleBuilderAction = (data) => {
    if (!data) {
      this.changePractitionerCriteriaState("isRule", false);
      return;
    }

    this.setState((prev) => ({
      ...prev,
      pract_criteria: {
        ...prev.pract_criteria,
        editId: [data?.Result?.[0]?.properties?.doc],
        isRule: !prev.pract_criteria.isRule,
        isOpen: true,
      },
    }));
  };

  handlePractitionerRuleRemove = () => {
    this.setState((prev) => ({
      ...prev,
      pract_criteria: {
        ...prev.pract_criteria,
        ...copy(initialRuleState),
        isOpen: !prev.pract_criteria.isOpen,
      },
    }));
  };

  handlePractitionerCriteriaExistingRuleSave = () => {
    const { group, rule } = this.state.pract_criteria;
    if (isEmptyObject(group)) {
      return this.props.alert.setSnack({
        open: true,
        msg: `Please choose the group`,
        severity: "error",
      });
    }
    if (isEmptyObject(rule)) {
      return this.props.alert.setSnack({
        open: true,
        msg: `Please choose the rule`,
        severity: "error",
      });
    }

    this.setState((prev) => ({
      ...prev,
      pract_criteria: {
        editId: [
          {
            group: group,
            rule: rule,
          },
        ],
        isExist: false,
        isOpen: true,
        group: {},
        rule: {},
      },
    }));
  };

  handleAlertsAndRestrictionSave = () => {
    let payload;

    payload = {
      id: 0,
      orgn_code: this.state?.orgn_code?.value,
      service_applicablity: this.state?.service_applicablity?.value,
      order_catalog_criteria: [],
      patient_criteria: this.state?.patient_criteria?.editId,
      pract_criteria: this.state?.pract_criteria?.editId,
      // appr_leveles: [
      //   {
      //     appr_level: "string",
      //     appr_level_description: "string",
      //     approval_authority_criteria: "array",
      //   },
      // ],
      rule_type: this.state?.rule_type?.value,
      privilege_type: this.state?.privilege_type?.value,
      status: true,

      // Send alert value if rule type is Alert.
      alert_test_message:
        this.state.rule_type?.value === RULE_TYPE.Alert
          ? this.state?.alert_test_message
          : "",

      // Send workflow value if rule type is restriction & privilege type is approval required.
      workflow:
        this.state.rule_type?.value === RULE_TYPE.Restriction &&
        this.state.privilege_type?.value === PRIVILEGE_TYPE.ApprovalRequired
          ? this.state?.workflow?.value
          : "",
      relationID: this.props?.location?.state?.docId, // This is mandatory for further reading of documents on Update mode. Do not remove this field.

      // Below fields are needed for edit purpose.
      ...(this.props?.location?.state?.docId &&
        this.props?.location?.state?.docKey &&
        this.props?.location?.state?.isEdit && {
          isEdit: true,
          _key: this.state._key,
        }),
    };

    Promise.resolve(
      this.props.DRUG_ALERTS_RESTRICTIONS_SAVE({
        body: payload,
      })
    )
      .then((res) => {
        if (res?.payload?.data?.validation_error?.length > 0) {
          this.props.alert.setSnack({
            open: true,
            msg: `${
              res?.payload?.data?.validation_error?.[0]?.Errormsg ??
              "Something went wrong!"
            }`,
            severity: "error",
          });
          this.setState({
            loading: false,
          });
          return;
        }
        this.setState({
          loading: false,
        });
        this.props.alert.setSnack({
          open: true,
          msg:
            this.props?.location?.state?.isEdit === true
              ? `Drug Updated Successfully`
              : `Drug Added Successfully`,
          severity: "success",
        });
        this.props.history.push(Routes.drugmastertable, {
          docId: this.props?.location?.state?.docId,
          docType: this.props?.location?.state?.docKey,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
        console.error("ERROR WHILE SAVING");
      });
  };

  render() {
    const { rule_type, privilege_type, patient_criteria, pract_criteria } =
      this.state;
    const {
      classes,
      drugRuleType,
      drugPrivilegeType,
      drugServiceApplicabilityType,
      drugFacilityOptions,
      drugApprovalWorkflowOptions,
    } = this.props;
    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <Paper
              key={"0"}
              id={"upUMw"}
              elevation={"{3}"}
              style={qdmstyles.OXtf}
            >
              <Grid
                key={"0"}
                id={"B6hKS"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                sm={"12"}
                md={"12"}
                lg={"12"}
                xl={"12"}
                xs={"12"}
                style={qdmstyles.kyoS}
              >
                <Grid container>
                  <Paper className={classes.subheaderPaper}>
                    <div className={classes.subheaderRoot}>
                      <div className={classes.subHeaderBack}>
                        <IconButton className={classes.backButton} size="small">
                          <ArrowBackIosOutlinedIcon
                            fontSize="small"
                            style={{ placeSelf: "center" }}
                            htmlColor={"#000000"}
                            // onClick={history.push(Routes.drugmastertable)}
                            // onClick={() => this.props.history.goBack()}
                            onClick={() =>
                              this.props.history.push(Routes.drugintscreen, {
                                docId: this.props?.location?.state?.docId,
                                docKey: this.props?.location?.state?.docKey,
                              })
                            }
                          />
                        </IconButton>
                        <Typography
                          key={"0"}
                          id={"yf8Th"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={
                            this.props?.location?.state?.isEdit
                              ? "Edit Drug catalog master"
                              : "Add New Drug catalog master"
                          }
                          style={qdmstyles.LP}
                        ></Typography>
                      </div>
                      <Divider
                        className={classes.divider}
                        orientation="vertical"
                        flexItem
                      />

                      <Stepper activeStep={1} className={classes.stepper}>
                        {["Drug master setup", "Alerts and Restrictions"].map(
                          (label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                              <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                              </Step>
                            );
                          }
                        )}
                      </Stepper>

                      <div style={{ flex: 1 }}></div>

                      <div className={classes.subheaderCTA}>
                        <CustomButton
                          variant="text"
                          // color="#2A3C50"
                          // backgroundColor="#DEE5EC"
                          onClick={() => this.props.history.go(-2)}
                        >
                          Cancel
                        </CustomButton>
                        <CustomButton
                          variant="contained"
                          color="#2A3C50"
                          backgroundColor="#DEE5EC"
                          // onClick={() => {
                          //   this.props.history.goBack();
                          // }}
                          onClick={() =>
                            this.props.history.push(Routes.drugintscreen, {
                              docId: this.props?.location?.state?.docId,
                              docKey: this.props?.location?.state?.docKey,
                            })
                          }
                        >
                          Previous
                        </CustomButton>
                        <CustomButton
                          variant="contained"
                          color="#FFFFFF"
                          backgroundColor="#EC6A49"
                          onClick={this.handleAlertsAndRestrictionSave}
                        >
                          {this.props?.location?.state?.isEdit
                            ? "Update"
                            : "Save"}
                        </CustomButton>
                      </div>
                    </div>
                  </Paper>
                </Grid>

                {/* Heading */}
                <Grid
                  key={"1"}
                  id={"34pKH"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  sm={"12"}
                  md={"12"}
                  lg={"12"}
                  style={qdmstyles.YN}
                >
                  <Typography
                    key={"0"}
                    id={"add_new_alerts_and_restriction_header"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"ADD NEW ALERTS AND RESTRICTIONS"}
                    aliasName={"addnewalertsandrestrictionheader"}
                    style={qdmstyles.MlMJO}
                  ></Typography>
                </Grid>

                {/* Body */}
                <Grid
                  key={"2"}
                  id={"dpeX9"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  style={qdmstyles.DRG}
                >
                  <Grid
                    key={"0"}
                    id={"qo7rp"}
                    container={true}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    style={qdmstyles.vSyzJ}
                  >
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={4}>
                        <div className={classes.fieldContainer}>
                          <Typography
                            id={"Facility_Typography"}
                            children={"Facility"}
                          ></Typography>
                          <Autocomplete
                            id={"facility"}
                            onLoad={() =>
                              this.changeState(
                                "orgn_code",
                                this.state?.orgn_code ?? ""
                              )
                            }
                            getOptionLabel={(option) => option?.["label"]}
                            options={drugFacilityOptions?.data ?? []}
                            size={"small"}
                            placeholder=""
                            value={this.state?.orgn_code ?? null}
                            disabled={
                              !this.props?.location?.state?.Activestatus
                            }
                            onChange={(event, newValue) =>
                              this.changeState("orgn_code", newValue)
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={classes.fieldContainer}>
                          <Typography
                            id={"Service_Applicability_Typography"}
                            children={"Service Applicability"}
                          ></Typography>
                          <div className={classes.buttonFieldContainer}>
                            {drugServiceApplicabilityType?.data?.map((item) => (
                              <CustomToggleButton
                                value={item?.value}
                                disabled={item?.value === "CodingMaster/10292"} //Disabled For Service Order
                                selected={item?.value === "CodingMaster/10291"} // Selected for Medication
                              >
                                {item?.label}
                              </CustomToggleButton>
                            ))}
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    {/* MEDICATION CRITERIA STARTS */}
                    <Grid
                      key={"0"}
                      id={"OKjtg"}
                      container={true}
                      direction={"column"}
                      justifyContent={"flex-start"}
                      alignItems={"flex-start"}
                      style={qdmstyles.hKrnI}
                    >
                      <Typography
                        key={"0"}
                        id={"order_criteria_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"MEDICATION CRITERIA"}
                        aliasName={"ordercriteriaheader"}
                        style={qdmstyles.RhDpc}
                      ></Typography>
                      <Grid
                        key={"1"}
                        id={"xUF0v"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <Box key={"0"} id={"ZFQt6"} m={1} component={"div"}>
                          <Typography
                            key={"0"}
                            id={"nnJMl"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Code"}
                            style={qdmstyles.kyer}
                          ></Typography>
                          <InputBase
                            key={"0"}
                            id={"p4SrD"}
                            label={""}
                            placeholder={""}
                            type={"text"}
                            variant={"outlined"}
                            size={"small"}
                            fullWidth={true}
                            style={qdmstyles.iRSf}
                            disabled={
                              !this.props?.location?.state?.Activestatus
                            }
                            readOnly
                            value={this.props?.location?.state?.drugCode ?? ""}
                          ></InputBase>
                        </Box>
                        <Box key={"1"} id={"ZFQt6"} m={1} component={"div"}>
                          <Typography
                            key={"0"}
                            id={"nnJMl"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Description"}
                            style={qdmstyles.QnycR}
                          ></Typography>
                          <InputBase
                            key={"0"}
                            id={"p4SrD"}
                            label={""}
                            placeholder={""}
                            disabled={
                              !this.props?.location?.state?.Activestatus
                            }
                            type={"text"}
                            variant={"outlined"}
                            size={"small"}
                            fullWidth={true}
                            style={qdmstyles.iRSf}
                            readOnly
                            value={
                              this.props?.location?.state?.drugDescription ?? ""
                            }
                          ></InputBase>
                        </Box>
                      </Grid>
                    </Grid>
                    {/* MEDICATION CRITERIA ENDS */}

                    {/* PATIENT CRITERIA STARTS */}
                    <Grid
                      key={"0"}
                      id={"VSjRk"}
                      container={true}
                      direction={"column"}
                      justifyContent={"flex-start"}
                      alignItems={"flex-start"}
                      style={qdmstyles.DiHL}
                    >
                      <Typography
                        key={"0"}
                        id={"patient_criteria_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"PATIENT CRITERIA"}
                        aliasName={"patientcriteriaheader"}
                        style={qdmstyles.gIsZ}
                      ></Typography>

                      {patient_criteria?.isOpen ? (
                        <Grid
                          container
                          spacing={2}
                          style={{ margin: "10px 0px" }}
                        >
                          <Grid item xs>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <Avatar
                                  key={"0"}
                                  id={"group_icon"}
                                  alt={"Avatar"}
                                  variant={"square"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317031738"
                                  }
                                  aliasName={"groupicon"}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    background: "red",
                                    padding: "7px",
                                    borderRadius: "50%",
                                    marginRight: "4px",
                                    backgroundColor: "#f1f6fc",
                                  }}
                                ></Avatar>
                              </div>
                              <div>
                                PATIENT CRITERIA
                                <br />
                                PATIENT AND PHARMACY
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <Avatar
                                  key={"0"}
                                  id={"group_icon"}
                                  alt={"Avatar"}
                                  variant={"square"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317006791"
                                  }
                                  aliasName={"groupicon"}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    background: "red",
                                    padding: "7px",
                                    borderRadius: "50%",
                                    marginRight: "4px",
                                    backgroundColor: "#f1f6fc",
                                  }}
                                ></Avatar>
                              </div>
                              <div>
                                {patient_criteria.editId?.[0]?.ruleName ||
                                  patient_criteria.editId?.[0]?.group?.label ||
                                  ""}
                                <br />
                                {patient_criteria.editId?.[0]?.priority ||
                                  patient_criteria.editId?.[0]?.rule?.label ||
                                  ""}
                              </div>
                            </div>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={this.handlePatientRuleRemove}
                              style={qdmstyles.dLEbQ2}
                              disabled={
                                !this.props?.location?.state?.Activestatus
                              }
                            >
                              Remove
                            </Button>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          key={"1"}
                          id={"tp21ZSa"}
                          container={true}
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          {patient_criteria?.isExist && (
                            <Grid
                              id={"i1kt4"}
                              container={true}
                              direction={"row"}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              // style={qdmstyles.ikt}
                              spacing={2}
                              style={{ margin: "10px 2px 0px" }}
                            >
                              <Grid
                                key={"1"}
                                id={"V7v9F"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"4"}
                                md={"4"}
                                sm={"4"}
                                xl={"4"}
                                xs={"4"}
                                item={true}
                              >
                                <div className={classes.fieldContainer}>
                                  <Typography
                                    id={"Group_Typography"}
                                    children={"Group"}
                                  ></Typography>
                                  <Autocomplete
                                    id={"r9IiL"}
                                    style={qdmstyles?.rIiL}
                                    disabled={
                                      !this.props?.location?.state?.Activestatus
                                    }
                                    getOptionLabel={(option) =>
                                      option?.["label"]
                                    }
                                    options={this.state.groupData ?? []}
                                    value={patient_criteria?.group}
                                    onLoad={() =>
                                      this.changePatientCriteriaState(
                                        "group",
                                        patient_criteria?.group
                                      )
                                    }
                                    onChange={(event, newValue) =>
                                      this.handlePatientCriteriaGroupStateChange(
                                        newValue
                                      )
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid
                                key={"1"}
                                id={"V7v9F"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"4"}
                                md={"4"}
                                sm={"4"}
                                xl={"4"}
                                xs={"4"}
                                item={true}
                              >
                                <div className={classes.fieldContainer}>
                                  <Typography
                                    id={"Rule_Typography"}
                                    children={"Rule"}
                                  ></Typography>
                                  <Autocomplete
                                    id={"lksad93"}
                                    style={qdmstyles?.rIiL}
                                    disabled={
                                      !this.props?.location?.state?.Activestatus
                                    }
                                    getOptionLabel={(option) =>
                                      option?.["label"]
                                    }
                                    options={patient_criteria?.ruleData ?? []}
                                    value={patient_criteria?.rule}
                                    onLoad={() =>
                                      this.changePatientCriteriaState(
                                        "rule",
                                        patient_criteria?.rule
                                      )
                                    }
                                    onChange={(event, newValue) =>
                                      this.changePatientCriteriaState(
                                        "rule",
                                        newValue
                                      )
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid
                                key={"1"}
                                id={"V7v339F"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"4"}
                                md={"4"}
                                sm={"4"}
                                xl={"4"}
                                xs={"4"}
                                item={true}
                              >
                                <div className={classes.fieldContainer}>
                                  <Typography
                                    style={{ opacity: 0, userSelect: "none" }}
                                  >
                                    cta
                                  </Typography>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 8,
                                    }}
                                  >
                                    <CustomButton
                                      variant={"contained"}
                                      color="#FFFFFF"
                                      backgroundColor="#3f51b5"
                                      disabled={
                                        !this.props?.location?.state
                                          ?.Activestatus
                                      }
                                      onClick={() =>
                                        this.handlePatientCriteriaExistingRuleSave()
                                      }
                                    >
                                      Save
                                    </CustomButton>
                                    <CustomButton
                                      variant="text"
                                      color="#2A3C50"
                                      backgroundColor="#DEE5EC"
                                      disabled={
                                        !this.props?.location?.state
                                          ?.Activestatus
                                      }
                                      onClick={(e) => {
                                        this.changePatientCriteriaState(
                                          "isExist",
                                          false
                                        );
                                      }}
                                    >
                                      Cancel
                                    </CustomButton>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          )}

                          {!patient_criteria?.isExist && (
                            <Grid
                              key={"1"}
                              id={"tpZSa"}
                              container={true}
                              direction={"row"}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                            >
                              <Box
                                key={"0"}
                                id={"jUtwI"}
                                m={1}
                                component={"div"}
                                style={qdmstyles.lqU}
                              >
                                <Avatar
                                  key={"0"}
                                  id={"patient_criteria_add_new_rule_set_icon"}
                                  alt={"Avatar"}
                                  variant={"rounded"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/325667675"
                                  }
                                  aliasName={"patientcriteriaaddnewruleseticon"}
                                  style={qdmstyles.BJdP}
                                ></Avatar>
                                <Button
                                  key={"1"}
                                  id={
                                    "patient_criteria_add_new_rule_set_button"
                                  }
                                  disabled={
                                    !this.props?.location?.state?.Activestatus
                                  }
                                  variant={"text"}
                                  color={"primary"}
                                  children={"Add New Rule Set"}
                                  aliasName={
                                    "patientcriteriaaddnewrulesetbutton"
                                  }
                                  style={qdmstyles.Wfr}
                                  size={"small"}
                                  onClick={() => {
                                    this.changePatientCriteriaState(
                                      "isRule",
                                      true
                                    );
                                  }}
                                ></Button>
                              </Box>
                              <Typography
                                key={"1"}
                                id={"FzxSu"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={"(or)"}
                                style={qdmstyles.qWkT}
                              ></Typography>
                              <Box
                                key={"2"}
                                id={"jUtwI"}
                                m={1}
                                component={"div"}
                                style={qdmstyles.evHRC}
                              >
                                <Avatar
                                  key={"0"}
                                  id={
                                    "patient_criteria_add_existing_rule_set_icon"
                                  }
                                  alt={"Avatar"}
                                  variant={"rounded"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/325667675"
                                  }
                                  aliasName={
                                    "patientcriteriaaddexistingruleseticon"
                                  }
                                  style={qdmstyles.HRISB}
                                ></Avatar>
                                <Button
                                  key={"1"}
                                  id={
                                    "patient_criteria_add_existing_rule_set_button"
                                  }
                                  disabled={
                                    !this.props?.location?.state?.Activestatus
                                  }
                                  variant={"text"}
                                  color={"primary"}
                                  children={"Add Existing Rule Set"}
                                  aliasName={
                                    "patientcriteriaaddexistingrulesetbutton"
                                  }
                                  style={qdmstyles.Sp}
                                  size={"small"}
                                  onClick={(e) => {
                                    this.changePatientCriteriaState(
                                      "isExist",
                                      true
                                    );
                                  }}
                                ></Button>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      )}

                      <CreateRule
                        // editId="Rules/10719"
                        editId={patient_criteria?.editId?.[0]?._id}
                        editSchema={patient_criteria?.editId?.[0]}
                        dbName={"primarycare"}
                        open={patient_criteria.isRule}
                        handleClose={(params, e) =>
                          this.setPatientCriteriaRuleBuilderAction(
                            params ? params : false
                          )
                        }
                      />
                    </Grid>
                    {/* PATIENT CRITERIA ENDS */}

                    {/* PRACTITIONER CRITERIA STARTS */}
                    <Grid
                      key={"0"}
                      id={"VSjRk"}
                      container={true}
                      direction={"column"}
                      justifyContent={"flex-start"}
                      alignItems={"flex-start"}
                      style={qdmstyles.DiHL}
                    >
                      <Typography
                        key={"0"}
                        id={"PRACTITIONER_criteria_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"PRACTITIONER CRITERIA"}
                        aliasName={"PRACTITIONERcriteriaheader"}
                        style={qdmstyles.gIsZ}
                      ></Typography>

                      {pract_criteria?.isOpen ? (
                        <Grid
                          container
                          spacing={2}
                          style={{ margin: "10px 0px" }}
                        >
                          <Grid item xs>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <Avatar
                                  key={"0"}
                                  id={"group_icon"}
                                  alt={"Avatar"}
                                  variant={"square"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317031738"
                                  }
                                  aliasName={"groupicon"}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    background: "red",
                                    padding: "7px",
                                    borderRadius: "50%",
                                    marginRight: "4px",
                                    backgroundColor: "#f1f6fc",
                                  }}
                                ></Avatar>
                              </div>
                              <div>
                                PRACTITIONER CRITERIA
                                <br />
                                PRACTITIONER AND PHARMACY
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <Avatar
                                  key={"0"}
                                  id={"group_icon"}
                                  alt={"Avatar"}
                                  variant={"square"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317006791"
                                  }
                                  aliasName={"groupicon"}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    background: "red",
                                    padding: "7px",
                                    borderRadius: "50%",
                                    marginRight: "4px",
                                    backgroundColor: "#f1f6fc",
                                  }}
                                ></Avatar>
                              </div>
                              <div>
                                {pract_criteria.editId?.[0]?.ruleName ||
                                  pract_criteria.editId?.[0]?.group?.label ||
                                  ""}
                                <br />
                                {pract_criteria.editId?.[0]?.priority ||
                                  pract_criteria.editId?.[0]?.rule?.label ||
                                  ""}
                              </div>
                            </div>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={this.handlePractitionerRuleRemove}
                              disabled={
                                !this.props?.location?.state?.Activestatus
                              }
                              style={qdmstyles.dLEbQ2}
                            >
                              Remove
                            </Button>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          key={"1"}
                          id={"tp21ZSa"}
                          container={true}
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          {pract_criteria?.isExist && (
                            <Grid
                              id={"i1kt4"}
                              container={true}
                              direction={"row"}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              // style={qdmstyles.ikt}
                              spacing={2}
                              style={{ margin: "10px 2px 0px" }}
                            >
                              <Grid
                                key={"1"}
                                id={"V7v9F"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"4"}
                                md={"4"}
                                sm={"4"}
                                xl={"4"}
                                xs={"4"}
                                item={true}
                              >
                                <div className={classes.fieldContainer}>
                                  <Typography
                                    id={"Group_Typography"}
                                    children={"Group"}
                                  ></Typography>
                                  <Autocomplete
                                    id={"r9IiL"}
                                    style={qdmstyles?.rIiL}
                                    disabled={
                                      !this.props?.location?.state?.Activestatus
                                    }
                                    getOptionLabel={(option) =>
                                      option?.["label"]
                                    }
                                    options={this.state.groupData ?? []}
                                    value={pract_criteria?.group}
                                    onLoad={() =>
                                      this.changePractitionerCriteriaState(
                                        "group",
                                        pract_criteria?.group
                                      )
                                    }
                                    onChange={(event, newValue) =>
                                      this.handlePractitionerCriteriaGroupStateChange(
                                        newValue
                                      )
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid
                                key={"1"}
                                id={"V7v9F"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"4"}
                                md={"4"}
                                sm={"4"}
                                xl={"4"}
                                xs={"4"}
                                item={true}
                              >
                                <div className={classes.fieldContainer}>
                                  <Typography
                                    id={"Rule_Typography"}
                                    children={"Rule"}
                                  ></Typography>
                                  <Autocomplete
                                    id={"lksad93"}
                                    style={qdmstyles?.rIiL}
                                    disabled={
                                      !this.props?.location?.state?.Activestatus
                                    }
                                    getOptionLabel={(option) =>
                                      option?.["label"]
                                    }
                                    options={pract_criteria.ruleData ?? []}
                                    value={pract_criteria?.rule}
                                    onLoad={() =>
                                      this.changePractitionerCriteriaState(
                                        "rule",
                                        pract_criteria?.rule
                                      )
                                    }
                                    onChange={(event, newValue) =>
                                      this.changePractitionerCriteriaState(
                                        "rule",
                                        newValue
                                      )
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid
                                key={"1"}
                                id={"V7v339F"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"4"}
                                md={"4"}
                                sm={"4"}
                                xl={"4"}
                                xs={"4"}
                                item={true}
                              >
                                <div className={classes.fieldContainer}>
                                  <Typography
                                    style={{ opacity: 0, userSelect: "none" }}
                                  >
                                    cta
                                  </Typography>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 8,
                                    }}
                                  >
                                    <CustomButton
                                      variant={"contained"}
                                      color="#FFFFFF"
                                      backgroundColor="#3f51b5"
                                      disabled={
                                        !this.props?.location?.state
                                          ?.Activestatus
                                      }
                                      onClick={() =>
                                        this.handlePractitionerCriteriaExistingRuleSave()
                                      }
                                    >
                                      Save
                                    </CustomButton>
                                    <CustomButton
                                      variant="text"
                                      color="#2A3C50"
                                      backgroundColor="#DEE5EC"
                                      disabled={
                                        !this.props?.location?.state
                                          ?.Activestatus
                                      }
                                      onClick={(e) => {
                                        this.changePractitionerCriteriaState(
                                          "isExist",
                                          false
                                        );
                                      }}
                                    >
                                      Cancel
                                    </CustomButton>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          )}

                          {!pract_criteria?.isExist && (
                            <Grid
                              key={"1"}
                              id={"tpZSa"}
                              container={true}
                              direction={"row"}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                            >
                              <Box
                                key={"0"}
                                id={"jUtwI"}
                                m={1}
                                component={"div"}
                                style={qdmstyles.lqU}
                              >
                                <Avatar
                                  key={"0"}
                                  id={"patient_criteria_add_new_rule_set_icon"}
                                  alt={"Avatar"}
                                  variant={"rounded"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/325667675"
                                  }
                                  aliasName={"patientcriteriaaddnewruleseticon"}
                                  style={qdmstyles.BJdP}
                                ></Avatar>
                                <Button
                                  key={"1"}
                                  id={
                                    "patient_criteria_add_new_rule_set_button"
                                  }
                                  variant={"text"}
                                  disabled={
                                    !this.props?.location?.state?.Activestatus
                                  }
                                  color={"primary"}
                                  children={"Add New Rule Set"}
                                  aliasName={
                                    "patientcriteriaaddnewrulesetbutton"
                                  }
                                  style={qdmstyles.Wfr}
                                  size={"small"}
                                  onClick={() => {
                                    this.changePractitionerCriteriaState(
                                      "isRule",
                                      true
                                    );
                                  }}
                                ></Button>
                              </Box>
                              <Typography
                                key={"1"}
                                id={"FzxSu"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={"(or)"}
                                style={qdmstyles.qWkT}
                              ></Typography>
                              <Box
                                key={"2"}
                                id={"jUtwI"}
                                m={1}
                                component={"div"}
                                style={qdmstyles.evHRC}
                              >
                                <Avatar
                                  key={"0"}
                                  id={
                                    "patient_criteria_add_existing_rule_set_icon"
                                  }
                                  alt={"Avatar"}
                                  variant={"rounded"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/325667675"
                                  }
                                  aliasName={
                                    "patientcriteriaaddexistingruleseticon"
                                  }
                                  style={qdmstyles.HRISB}
                                ></Avatar>
                                <Button
                                  key={"1"}
                                  id={
                                    "patient_criteria_add_existing_rule_set_button"
                                  }
                                  variant={"text"}
                                  disabled={
                                    !this.props?.location?.state?.Activestatus
                                  }
                                  color={"primary"}
                                  children={"Add Existing Rule Set"}
                                  aliasName={
                                    "patientcriteriaaddexistingrulesetbutton"
                                  }
                                  style={qdmstyles.Sp}
                                  size={"small"}
                                  onClick={(e) => {
                                    this.changePractitionerCriteriaState(
                                      "isExist",
                                      true
                                    );
                                  }}
                                ></Button>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      )}

                      <CreateRule
                        // editId="Rules/10719"
                        editId={pract_criteria?.editId?.[0]?._id}
                        editSchema={pract_criteria?.editId?.[0]}
                        dbName={"primarycare"}
                        open={pract_criteria.isRule}
                        handleClose={(params, e) =>
                          this.setPractitionerCriteriaRuleBuilderAction(
                            params ? params : false
                          )
                        }
                      />
                    </Grid>
                    {/* PRACTITIONER CRITERIA ENDS */}

                    {/* RULE TYPE + PRIVILEGE TYPE STARTS */}
                    <Grid
                      key={"2"}
                      id={"eowNT"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      style={qdmstyles.sL}
                    >
                      <Box key={"0"} id={"ZFQt6"} m={1} component={"div"}>
                        <Typography
                          key={"0"}
                          id={"rule_type_header"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Rule Type"}
                          aliasName={"ruletypeheader"}
                          style={qdmstyles.mhgSY}
                        ></Typography>
                        <Box
                          key={"1"}
                          id={"A6EcI"}
                          m={1}
                          component={"div"}
                          style={qdmstyles.aWjHF}
                        >
                          {drugRuleType?.data?.map((item) => (
                            <CustomToggleButton
                              value={item?.value}
                              selected={rule_type?.value === item?.value}
                              onChange={() =>
                                this.changeState("rule_type", item)
                              }
                              disabled={
                                !this.props?.location?.state?.Activestatus
                              }
                            >
                              {item?.label}
                            </CustomToggleButton>
                          ))}
                        </Box>
                      </Box>
                      <Box key={"1"} id={"ZFQt6"} m={1} component={"div"}>
                        <Typography
                          key={"0"}
                          id={"privilege_type_header"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Privilege Type"}
                          aliasName={"privilegetypeheader"}
                          style={qdmstyles.qPu}
                        ></Typography>
                        <Box
                          key={"1"}
                          id={"A6EcI"}
                          m={1}
                          component={"div"}
                          style={qdmstyles.SKvK}
                        >
                          {/* IF rule_type === RESTRICTION */}
                          {rule_type?.value === RULE_TYPE.Restriction && (
                            <>
                              {drugPrivilegeType?.data?.map((item) => {
                                if (
                                  allowedRestrictionPrivilegeTypes.indexOf(
                                    item?.value
                                  ) > -1
                                ) {
                                  return (
                                    <CustomToggleButton
                                      value={item?.value}
                                      disabled={
                                        !this.props?.location?.state
                                          ?.Activestatus
                                      }
                                      selected={
                                        privilege_type?.value === item?.value
                                      }
                                      onChange={() =>
                                        this.changeState("privilege_type", item)
                                      }
                                    >
                                      {item?.label}
                                    </CustomToggleButton>
                                  );
                                }
                                return null;
                              })}
                            </>
                          )}

                          {/* IF rule_type === ALERT */}
                          {rule_type?.value === RULE_TYPE.Alert && (
                            <>
                              {drugPrivilegeType?.data?.map((item) => {
                                if (
                                  allowedAlertPrivilegeTypes.indexOf(
                                    item?.value
                                  ) > -1
                                ) {
                                  return (
                                    <CustomToggleButton
                                      value={item?.value}
                                      disabled={
                                        !this.props?.location?.state
                                          ?.Activestatus
                                      }
                                      selected={
                                        privilege_type?.value === item?.value
                                      }
                                      onChange={() =>
                                        this.changeState("privilege_type", item)
                                      }
                                    >
                                      {item?.label}
                                    </CustomToggleButton>
                                  );
                                }
                                return null;
                              })}
                            </>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    {/* RULE TYPE + PRIVILEGE TYPE ENDS */}

                    {/* RULE/PRIVILEGE DEPENDENCY STARTS */}
                    {rule_type?.value === RULE_TYPE.Alert && (
                      <Grid
                        key={"3"}
                        id={"0HojJ"}
                        container={true}
                        direction={"column"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                        style={qdmstyles.tCZ}
                      >
                        <Typography
                          key={"0"}
                          id={"nnJMl"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Alert Text Message"}
                          style={qdmstyles.TLURa}
                        ></Typography>
                        <TextField
                          key={"1"}
                          id={"7rYVT"}
                          label={""}
                          placeholder={"Type here"}
                          type={"text"}
                          multiline={true}
                          variant={"outlined"}
                          fullWidth={true}
                          maxRows={"4"}
                          size={"medium"}
                          minRows={"4"}
                          inputProps={{
                            maxlength: 100,
                          }}
                          value={this.state?.alert_test_message}
                          onChange={(e) =>
                            this.changeState(
                              "alert_test_message",
                              e.target.value
                            )
                          }
                          style={qdmstyles.UcwNt}
                        ></TextField>
                      </Grid>
                    )}
                    {rule_type?.value === RULE_TYPE.Restriction &&
                      privilege_type?.value ===
                        PRIVILEGE_TYPE.ApprovalRequired && (
                        <Grid
                          key={"3"}
                          id={"0HojJ"}
                          container={true}
                          direction={"column"}
                          justifyContent={"flex-start"}
                          alignItems={"flex-start"}
                          style={qdmstyles.tCZ}
                        >
                          <div className={classes.fieldContainer}>
                            <Typography
                              id={"Select_Approval_Workflow_Typography"}
                              children={"Select Approval Workflow"}
                            ></Typography>
                            <Autocomplete
                              id={"Hk0O4"}
                              style={qdmstyles?.HkO}
                              disabled={
                                !this.props?.location?.state?.Activestatus
                              }
                              onLoad={() =>
                                this.this.changeState(
                                  "workflow",
                                  this.state?.workflow ?? ""
                                )
                              }
                              getOptionLabel={(option) => option?.["label"]}
                              options={drugApprovalWorkflowOptions?.data ?? []}
                              size={"small"}
                              placeholder=""
                              value={this.state?.workflow ?? null}
                              onChange={(event, newValue) =>
                                this.changeState("workflow", newValue)
                              }
                            />
                          </div>
                        </Grid>
                      )}
                    {/* RULE/PRIVILEGE DEPENDENCY ENDS */}

                    {/* Save Button */}
                    {/* <Grid
                      key={"2"}
                      id={"eowNT"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      style={qdmstyles.sL}
                    >
                      <div className={classes.subheaderCTA}>
                        <CustomButton
                          variant="text"
                          // color="#2A3C50"
                          // backgroundColor="#DEE5EC"
                        >
                          Cancel
                        </CustomButton>
                        <CustomButton
                          variant="contained"
                          color="#FFFFFF"
                          backgroundColor="#2A60BC"
                        >
                          Save & Close
                        </CustomButton>
                      </div>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}
const mapStateToProps = (state) => ({
  drugRuleType: state?.drugCatalogMasterSlice?.drugRuleType,
  drugPrivilegeType: state?.drugCatalogMasterSlice?.drugPrivilegeType,
  drugServiceApplicabilityType:
    state?.drugCatalogMasterSlice?.drugServiceApplicabilityType,
  drugFacilityOptions: state?.drugCatalogMasterSlice?.drugFacilityOptions,
  drugApprovalWorkflowOptions:
    state?.drugCatalogMasterSlice?.drugApprovalWorkflowOptions,
  drugAlertsRestrictionSave:
    state?.drugCatalogMasterSlice?.drugAlertsRestrictionSave,
  drugAlertsRestrictionSingleRead:
    state?.drugCatalogMasterSlice?.drugAlertsRestrictionSingleRead,
});

export default connect(
  mapStateToProps,
  actions
)(
  withRouter(translate()(withStyles(styles)(withAllContexts(Alertrestriction))))
);
