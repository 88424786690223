import React from "react";
import { CardContent } from "../../commonComponents";
import Typography from "@material-ui/core/Typography";
import { ScheduleIcon } from "../../../assets";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    padding: 12,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: 12,
    backgroundColor: "#F1F6FC",
    paddingBlock: 8,
    paddingInline: 12,
  },
}));

function Directions({
  directions,
  isVerficationState,
  handleEditClick,
  ...props
}) {
  const classes = useStyles();
  return (
    <CardContent
      title="Directions"
      titleIcon={<ScheduleIcon />}
      actionIcon={isVerficationState && <CreateOutlinedIcon fontSize="small" />}
      handleActionClick={handleEditClick}
    >
      <div className={classes.body}>
        {directions?.map((item, index) => (
          <div key={index} className={classes.row}>
            <Typography variant="body2">
              {
                // <div
                //   dangerouslySetInnerHTML={{
                //     __html: item,
                //   }}
                // />
                item
              }
            </Typography>
          </div>
        ))}
      </div>
    </CardContent>
  );
}

export default Directions;

Directions.defaultProps = {
  directions: [],
};

Directions.propTypes = {
  directions: PropTypes.array,
};
