import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CusTimePicker } from "../common";
import {
  // StylesProvider,
  // createGenerateClassName,
  TextField,
  Divider,
  Tooltip,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import AddIcon from "@material-ui/icons/Add";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { validateWeeklyHours } from "./validation";
import moment from "moment";

const useStyles = (props) =>
  makeStyles((theme) => ({
    label: {
      fontSize: "14px",
    },
    title: {
      fontSize: "14px",
      color: theme.palette.text.primary,
    },

    day: {
      fontSize: "12px !important",
      fontWeight: "600 !important",
      display: "inline-block",
      color: theme.palette.text.primary,
      fontFamily: '"pc_semibold" !important',
    },

    content: {
      display: "flex",
      alignItems: "baseline",
      paddingBottom: "15px",
    },

    btnEdit: {
      backgroundColor: "#0071F2",
      color: "#fff",
      padding: "6px 16px",
      fontFamily: "poppin",
      fontSize: "12px",
      textTransform: "none",
      borderRadius: "10px",
    },
    iconBorder: {
      border: "1px solid #e0e0e0",
      cursor: "pointer",
      borderRadius: "8px",
      margin: "auto",
      color: theme.palette.primary.main,
      fontSize: "20px",
      width: "25px",
      height: "25px",
    },
    add: {
      "&.App1-MuiButton-contained:hover": {
        backgroundColor: theme.palette.button.hover,
      },
      color: theme.palette.button.text,
      backgroundColor: theme.palette.button.background,
    },
    textField: {
      width: "100%",
      "& .App1-MuiOutlinedInput-input": {
        padding: "12.5px 14px",
      },
    },
    Grid: {
      padding: "10px",
      backgroundColor: theme.palette.background.table,
    },
    paper: {
      width: "100%",
      maxHeight: 500,
      overflow: "hidden",
      maxWidth: 775,
      position: "relative",
    },
    iconGrid: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "5px",
    },
    gridpadding: {
      padding: "5px",
    },
    notchedOutline: {
      borderColor: `${theme.palette.border.textField} !important`,
    },
    dialogDividers: {
      borderTop: "1px solid #0000001f",
      //`1px solid ${theme.palette.text.primary}`,
      backgroundColor: theme.palette.background.table,
      padding: "8px 10px",
    },
    buttonSide: {
      padding: "10px 0",
      textAlign: "end",
      position: "absolute",
      width: "100%",
      bottom: 0,
      background: theme.palette.background.table,
    },
  }));

const initalStateWorkingDays = {
  effFrmDate: "",
  effToDate: "",
  day: false,
  format: false,
  workDaysOfWeek: [
    {
      day: "Mon",
      checked: false,
      checkedHr: false,
      operate_time: [
        {
          openingtime: "",
          closingtime: "",
        },
      ],
    },
    {
      day: "Tue",
      checked: false,
      checkedHr: false,
      operate_time: [
        {
          openingtime: "",
          closingtime: "",
        },
      ],
    },
    {
      day: "Wed",
      checked: false,
      checkedHr: false,
      operate_time: [
        {
          openingtime: "",
          closingtime: "",
        },
      ],
    },
    {
      day: "Thu",
      checked: false,
      checkedHr: false,
      operate_time: [
        {
          openingtime: "",
          closingtime: "",
        },
      ],
    },
    {
      day: "Fri",
      checked: false,
      checkedHr: false,
      operate_time: [
        {
          openingtime: "",
          closingtime: "",
        },
      ],
    },
    {
      day: "Sat",
      checked: false,
      checkedHr: false,
      operate_time: [
        {
          openingtime: "",
          closingtime: "",
        },
      ],
    },
    {
      day: "Sun",
      checked: false,
      checkedHr: false,
      operate_time: [
        {
          openingtime: "",
          closingtime: "",
        },
      ],
    },
  ],
};

const initalOverRide = {
  overridetype: "Date Range",
  ovrDay: false,
  ovrfromdate: "",
  ovrtodate: "",
  daysOfWeek: [
    {
      ovrDay: "Mon",
      checked: false,
      checkedHr: false,
      operate_time: [
        {
          ovrOpeningtime: "",
          ovrClosingtime: "",
        },
      ],
    },
    {
      ovrDay: "Tue",
      checked: false,
      checkedHr: false,
      operate_time: [
        {
          ovrOpeningtime: "",
          ovrClosingtime: "",
        },
      ],
    },
    {
      ovrDay: "Wed",
      checked: false,
      checkedHr: false,
      operate_time: [
        {
          ovrOpeningtime: "",
          ovrClosingtime: "",
        },
      ],
    },
    {
      ovrDay: "Thu",
      checked: false,
      checkedHr: false,
      operate_time: [
        {
          ovrOpeningtime: "",
          ovrClosingtime: "",
        },
      ],
    },
    {
      ovrDay: "Fri",
      checked: false,
      checkedHr: false,
      operate_time: [
        {
          ovrOpeningtime: "",
          ovrClosingtime: "",
        },
      ],
    },
    {
      ovrDay: "Sat",
      checked: false,
      checkedHr: false,
      operate_time: [
        {
          ovrOpeningtime: "",
          ovrClosingtime: "",
        },
      ],
    },
    {
      ovrDay: "Sun",
      checked: false,
      checkedHr: false,
      operate_time: [
        {
          ovrOpeningtime: "",
          ovrClosingtime: "",
        },
      ],
    },
  ],
};

function WorkingDaysModal(props) {
  const classe = useStyles(props)();
  const {
    stateName = "workingDay",
    handleClose = () => null,
    handleSave = () => null,
    editData,
    open,
    isRadio = false,
    isWorkingDay,
    WorkingDays,
    isOverRise,
    OverRide,
  } = props;

  var thesorted = null;
  var thesorted_start = null;

  if (WorkingDays && isOverRise) {
    thesorted = props.WorkingDays.flatMap((it) => [it.effToDate, it.effToDate])[
      props.WorkingDays.flatMap((it) => [it.effToDate, it.effToDate]).length - 1
    ];

    // thesorted = WorkingDays?.sort(function (x, y) {
    //   return y?.effToDate - x?.effToDate;
    // });

    // thesorted_start = WorkingDays?.sort(function (x, y) {
    //   return x?.effFrmDate - y?.effFrmDate;
    // });

    thesorted_start = props.WorkingDays.flatMap((it) => [
      it.effFrmDate,
      it.effFrmDate,
    ])[0];
  }

  const [state, setState] = React.useState(
    isWorkingDay ? initalStateWorkingDays : initalOverRide
  );

  const [error, seterror] = React.useState({
    from: false,
    to: false,
  });

  const [dateerror, setsetdateerrorerror] = React.useState(false);
  const [single, setsingle] = React.useState(false);
  const [one, setone] = React.useState(false);
  const [duplicate_date, setduplicate_date] = React.useState(false);

  const handleDuplicateFuncy = (isOverRise) => {
    var error = false;
    setduplicate_date(false);

    if (isOverRise) {
      const isDuplicate = OverRide?.map((v) => {
        const start = moment(state[props.effectiveData]).unix();
        const end = moment(state[props.effectiveDataTo]).unix();

        if (
          v[props.effectiveData] === start &&
          v[props.effectiveDataTo] === end
        ) {
          setduplicate_date(true);
          return (error = true);
        }
      });
    } else {
      const isDuplicate = WorkingDays?.map((v) => {
        const start = moment(state[props.effectiveData]).unix();
        const end = moment(state[props.effectiveDataTo]).unix();

        if (
          v[props.effectiveData] === start &&
          v[props.effectiveDataTo] === end
        ) {
          setduplicate_date(true);
          return (error = true);
        }
      });
    }
    return error;
  };

  const handleSubmit = (fun) => {
    seterror({ from: false, to: false });
    setsetdateerrorerror(false);
    setsingle(false);
    setone("");

    if (fun === "cancel") {
      handleModalClose();
      setState(isWorkingDay ? initalStateWorkingDays : initalOverRide);
    } else {
      if ((WorkingDays && !isOverRise) || isOverRise) {
        if (handleDuplicateFuncy(isOverRise)) {
          return;
        }
      }

      if (!state.overridetype && isRadio) {
        setsingle(true);
        return;
      }

      if (!state[props.effectiveData]) {
        seterror({ ...error, from: true });
        return;
      }
      // if (
      //   !state[props.effectiveDataTo] &&
      //   state.overridetype !== "Single Date"
      // ) {
      //   seterror({ ...error, to: true });
      //   return;
      // }
      let err = false;

      const isEmpty = state[props.workDaysOfWeek]?.filter((v) => v?.checked);
      if (!isEmpty?.length > 0 && state.overridetype !== "Single Date") {
        setone("Please choose atleast one day and time!.");
        err = true;
      }
      if (state[props.workDaysOfWeek]) {
        state[props.workDaysOfWeek].map((v, i) => {
          if (
            v.checked &&
            (!v?.operate_time[0]?.[props.closingtime] ||
              !v?.operate_time[0]?.[props.openingtime])
          ) {
            setone("Please choose correct time format!.");
            err = true;
          } else if (
            state.overridetype === "Single Date" &&
            (!v?.operate_time[0]?.[props.closingtime] ||
              !v?.operate_time[0]?.[props.openingtime])
          ) {
            setone("Please choose correct time format!.");
            err = true;
          }
        });
      }

      if (!one && !err) {
        const valid = validateWeeklyHours(state[props.workDaysOfWeek]);
        if (!valid) {
          setsetdateerrorerror(true);
          return;
        }

        handleSave(stateName, state);
        setState(isWorkingDay ? initalStateWorkingDays : initalOverRide);
      } else {
        return false;
      }
    }
  };

  const handleModalClose = () => {
    seterror({ from: false, to: false });
    handleClose();
  };

  const handleworkdaytimeChange = (date, pi, ci, type, item, name) => {
    setone("");
    const State = JSON.parse(JSON.stringify(state));

    State[props.workDaysOfWeek][pi][type] = date;

    if (
      name === "parent" &&
      State[props.workDaysOfWeek][pi]?.[props.operate_time]
    ) {
      State[props.workDaysOfWeek][pi][props.operate_time].map((val) => {
        const currentDate = new Date();
        val[props.openingtime] = date ? currentDate.setHours(0, 0, 0, 0) : "";
        val[props.closingtime] = date
          ? currentDate.setHours(23, 59, 59, 999)
          : "";
      });
    } else {
      // const Isdate = moment(date).format();
      State[props.workDaysOfWeek][pi][props.operate_time][ci] = {
        ...state[props.workDaysOfWeek][pi][props.operate_time][ci],
        [type]: date * 1000,
        //  Isdate
        // moment(Isdate).unix(),
      };
    }

    setState({ ...State });
  };

  const addworkdayfield = (e, i, item, time_i) => {
    const State = JSON.parse(JSON.stringify(state));

    if (State[props.workDaysOfWeek][i][props.operate_time]) {
      State[props.workDaysOfWeek][i][props.operate_time].push({
        [props.openingtime]: "",
        [props.closingtime]: "",
      });
      setState({ ...State });
    }
  };

  const deleteworkdayfield = (e, pi, ci) => {
    const State = JSON.parse(JSON.stringify(state));
    if (State[props.workDaysOfWeek][pi]?.[props.operate_time]) {
      State[props.workDaysOfWeek][pi][props.operate_time].splice(ci, 1);
      setState({ ...State });
    }
  };

  const handleworkdayChange = async (v, type, FunName) => {
    const State = JSON.parse(JSON.stringify(state));
    if (FunName === "DayClick") {
      await State[props.workDaysOfWeek].forEach((val) => {
        val.checked = v;
      });
    }
    if (FunName === "formatClick") {
      await State[props.workDaysOfWeek].forEach((val) => {
        if (val.checked) {
          val["checkedHr"] = v;
          val[props.operate_time].forEach((val1) => {
            const currentDate = new Date();
            val1[props.openingtime] = v ? currentDate.setHours(0, 0, 0, 0) : "";
            val1[props.closingtime] = v
              ? currentDate.setHours(23, 59, 59, 999)
              : "";
          });
        }
      });
    }
    setone("");
    seterror({ from: false, to: false });
    State[type] = v;
    await setState({ ...State });
  };

  const handleRadioChange = (v, type) => {
    // if (props.editData) {
    //   return;
    // }
    setsingle(false);
    if (v === "Single Date") {
      state[props.workDaysOfWeek] = [
        {
          ovrDay: "Mon",
          checked: false,
          checkedHr: false,
          operate_time: [
            {
              ovrOpeningtime: "",
              ovrClosingtime: "",
            },
          ],
        },
      ];
    } else if (v === "Date Range") {
      state[props.workDaysOfWeek] = [
        {
          ovrDay: "Mon",
          checked: false,
          checkedHr: false,
          operate_time: [
            {
              ovrOpeningtime: "",
              ovrClosingtime: "",
            },
          ],
        },
        {
          ovrDay: "Tue",
          checked: false,
          checkedHr: false,
          operate_time: [
            {
              ovrOpeningtime: "",
              ovrClosingtime: "",
            },
          ],
        },
        {
          ovrDay: "Wed",
          checked: false,
          checkedHr: false,
          operate_time: [
            {
              ovrOpeningtime: "",
              ovrClosingtime: "",
            },
          ],
        },
        {
          ovrDay: "Thu",
          checked: false,
          checkedHr: false,
          operate_time: [
            {
              ovrOpeningtime: "",
              ovrClosingtime: "",
            },
          ],
        },
        {
          ovrDay: "Fri",
          checked: false,
          checkedHr: false,
          operate_time: [
            {
              ovrOpeningtime: "",
              ovrClosingtime: "",
            },
          ],
        },
        {
          ovrDay: "Sat",
          checked: false,
          checkedHr: false,
          operate_time: [
            {
              ovrOpeningtime: "",
              ovrClosingtime: "",
            },
          ],
        },
        {
          ovrDay: "Sun",
          checked: false,
          checkedHr: false,
          operate_time: [
            {
              ovrOpeningtime: "",
              ovrClosingtime: "",
            },
          ],
        },
      ];
    }
    state[type] = v;
    setState({ ...state });
  };

  React.useEffect(() => {
    const isState = isWorkingDay ? initalStateWorkingDays : initalOverRide;
    setState(isState);
    if (
      editData?.[props.workDaysOfWeek] &&
      editData.overridetype !== "Single Date"
    ) {
      var parentArr = [];
      var arr = [];

      if (editData?.[props.workDaysOfWeek]) {
        editData[props.workDaysOfWeek].map((child) => {
          return arr.push(child?.ovrDay || child?.day);
        });
      }
      if (isState?.[props.workDaysOfWeek]) {
        isState[props.workDaysOfWeek].map((parent) => {
          if (!arr.includes(parent?.ovrDay || parent?.day)) {
            parentArr.push(parent);
          }
        });
      }

      editData[props.workDaysOfWeek] = [
        ...editData[props.workDaysOfWeek],
        ...parentArr,
      ];

      // editData?.[props.workDaysOfWeek].sort(function sortByDay(a, b) {
      //   let day1 = a.day.toLowerCase();
      //   let day2 = b.day.toLowerCase();
      //   return sorter[day1] - sorter[day2];
      // });
    }

    setState(
      editData
        ? editData
        : isWorkingDay
        ? initalStateWorkingDays
        : initalOverRide
    );
  }, [stateName, editData, open]);

  React.useEffect(() => {
    const isState = isWorkingDay ? initalStateWorkingDays : initalOverRide;
    setState(isState);
  }, []);
  // const isEdit = props.isEdit || props.editData;

  return (
    <div {...props}>
      <Dialog
        classes={{
          paper: classe.paper,
        }}
        maxWidth="md"
        open={open}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid className={classe.Grid} container>
          <Grid className={classe.gridpadding} item xs={6}>
            <Typography variant="h6">Operation Timings</Typography>
          </Grid>
          <Grid className={classe.iconGrid} item xs={6}>
            <div
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                backgroundColor: "rgb(224, 224, 224)",
                minWidth: "30px",
                minHeight: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handleClose()}
            >
              <ClearOutlinedIcon style={{ padding: "3px" }} />
            </div>
          </Grid>
          {isRadio && (
            <div>
              {single && (
                <div
                  style={{
                    color: "#f44336",
                    fontSize: "0.75rem",
                    textAlign: "center",
                  }}
                >
                  Please choose Single or Date.
                </div>
              )}
              <RadioGroup
                value={state.overridetype}
                row
                onChange={(e) =>
                  handleRadioChange(e.target.value, "overridetype")
                }
              >
                <FormControlLabel
                  style={{ height: "40px" }}
                  value="Single Date"
                  control={<Radio color="primary" />}
                  label="Single Date"
                />
                <FormControlLabel
                  style={{ height: "40px" }}
                  value="Date Range"
                  control={<Radio color="primary" />}
                  label="Date Range"
                />
              </RadioGroup>
            </div>
          )}
          <Grid className={classe.gridpadding} item xs={12}>
            <Divider style={{ margin: "0" }}></Divider>
          </Grid>

          <Grid className={classe.gridpadding} item sm={3} xs={6}>
            <Typography className={classe.title} variant="body2" gutterBottom>
              Effective From <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              // disabled={isEdit}
              id="date"
              type="date"
              error={error?.from}
              InputProps={{
                inputProps: {
                  // min: moment(new Date()).format("YYYY-MM-DD"),
                  min: thesorted_start
                    ? moment.unix(thesorted_start).format("YYYY-MM-DD")
                    : moment(new Date()).format("YYYY-MM-DD"),
                  max: thesorted && moment.unix(thesorted).format("YYYY-MM-DD"),
                },
                classes: { notchedOutline: classe.notchedOutline },
              }}
              helperText={error?.from && "Please choose the date"}
              className={classe.textField}
              value={state[props.effectiveData]}
              onChange={(e) =>
                handleworkdayChange(e.target.value, props.effectiveData)
              }
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid className={classe.gridpadding} item sm={3} xs={5}>
            {((isRadio && state.overridetype !== "Single Date") ||
              !isRadio) && (
              <>
                <Typography
                  className={classe.title}
                  variant="body2"
                  gutterBottom
                >
                  Effective To
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Typography>

                <TextField
                  id="date"
                  type="date"
                  value={state[props.effectiveDataTo]}
                  // minDate={
                  //   thesorted && moment.unix(thesorted?.[0]?.effToDate).format('DD/MM/YYYY')
                  // }
                  InputProps={{
                    inputProps: {
                      min: state[props.effectiveData],
                      max:
                        thesorted &&
                        moment.unix(thesorted).format("YYYY-MM-DD"),
                    },
                    classes: { notchedOutline: classe.notchedOutline },
                  }}
                  className={classe.textField}
                  disabled={!state[props.effectiveData]}
                  // error={error?.to}
                  // helperText={error?.to && "Please choose the date"}
                  onChange={(e) =>
                    handleworkdayChange(e.target.value, props.effectiveDataTo)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </>
            )}
          </Grid>
          <Grid className={classe.gridpadding} item sm={6} xs={1}></Grid>

          {state.overridetype !== "Single Date" && (
            <Grid className={classe.gridpadding} item xs={2}>
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={state[props.day]}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  style={{
                    padding: "0px 10px 0px 0px",
                    // color: "#0071F2",
                  }}
                  color="primary"
                  size="small"
                  onChange={(e) =>
                    handleworkdayChange(e.target.checked, props.day, "DayClick")
                  }
                  // disabled={isEdit}
                />
                <Typography className={classe.day} style={{ margin: 0 }}>
                  Day
                </Typography>
              </div>
            </Grid>
          )}

          {state.overridetype !== "Single Date" && (
            <Grid className={classe.gridpadding} item xs={2}>
              <div style={{ display: "flex" }}>
                <Checkbox
                  inputProps={{ "aria-label": "primary checkbox" }}
                  checked={state?.format}
                  style={{
                    padding: "0px 10px 0px 0px",
                    // color: "#0071F2",
                  }}
                  color="primary"
                  size="small"
                  onChange={(e) =>
                    handleworkdayChange(
                      e.target.checked,
                      "format",
                      "formatClick"
                    )
                  }
                  // disabled={isEdit}
                />
                <Typography className={classe.day} style={{ margin: 0 }}>
                  24 Hr
                </Typography>
              </div>
            </Grid>
          )}

          {state.overridetype !== "Single Date" && (
            <Grid className={classe.gridpadding} item xs={4}>
              <Typography variant="body2" gutterBottom>
                Start Time
              </Typography>
            </Grid>
          )}

          {state.overridetype !== "Single Date" && (
            <Grid className={classe.gridpadding} item xs={4}>
              <Typography variant="body2" gutterBottom>
                End Time
              </Typography>
            </Grid>
          )}
        </Grid>
        {duplicate_date && (
          <div
            style={{
              color: "#f44336",
              fontSize: "0.75rem",
              textAlign: "center",
            }}
          >
            You have aleardy added this Effective From and Effective To date.
          </div>
        )}
        {dateerror && (
          <div
            style={{
              color: "#f44336",
              fontSize: "0.75rem",
              textAlign: "center",
            }}
          >
            Please choose the correct time format!, you choose only 24Hrs peer
            day, not more.
          </div>
        )}
        {one && (
          <div
            style={{
              color: "#f44336",
              fontSize: "0.75rem",
              textAlign: "center",
            }}
          >
            {one}
          </div>
        )}
        <DialogContent
          // dividers
          className={classe.dialogDividers}
          style={{ minHeight: "170px", marginBottom: "50px" }}
        >
          {state?.[props.workDaysOfWeek]?.map((item, i) => {
            return (
              <Grid container alignItems="center" className={classe.content}>
                {((isRadio && state.overridetype !== "Single Date") ||
                  !isRadio) && (
                  <Grid className={classe.gridpadding} item xs={2}>
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        checked={item.checked}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        style={{
                          padding: "0px 10px 0px 0px",
                          // color: "#0071F2",
                        }}
                        color="primary"
                        // disabled={isEdit}
                        onChange={(date) =>
                          handleworkdaytimeChange(
                            date.target.checked,
                            i,
                            undefined,
                            "checked",
                            item
                          )
                        }
                        size="small"
                      />
                      <Typography className={classe.day} style={{ margin: 0 }}>
                        {item[props.day]}
                      </Typography>
                    </div>
                  </Grid>
                )}
                <Grid className={classe.gridpadding} item xs={1}>
                  <Checkbox
                    checked={item?.checkedHr}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    disabled={
                      state.overridetype !== "Single Date" && !item.checked
                    }
                    style={{
                      padding: "0px 10px 0px 0px",
                      // color: "#0071F2",
                    }}
                    color="primary"
                    onChange={(date) =>
                      handleworkdaytimeChange(
                        date.target.checked,
                        i,
                        undefined,
                        "checkedHr",
                        item,
                        "parent"
                      )
                    }
                    // disabled={isEdit}
                    size="small"
                  />
                </Grid>

                <Grid item xs={9}>
                  {item[props.operate_time]?.map((itm, time_i) => (
                    <Grid container spacing={2}>
                      <Grid className={classe.gridpadding} item xs={5}>
                        <CusTimePicker
                          value={itm[props.openingtime]}
                          disabled={
                            // isEdit ||
                            item?.checkedHr ||
                            (state.overridetype !== "Single Date" &&
                              !item.checked)
                          }
                          onChange={(date) =>
                            handleworkdaytimeChange(
                              date,
                              i,
                              time_i,
                              props.openingtime,
                              item
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <div
                          style={{
                            border: "0.5px solid #E0E0E0",
                            width: "8px",
                            margin: "0 15px",
                            background: "#E0E0E0",
                          }}
                        ></div>
                      </Grid>

                      <Grid className={classe.gridpadding} item xs={5}>
                        <div style={{ display: "flex" }}>
                          <CusTimePicker
                            value={itm[props.closingtime]}
                            disabled={
                              // isEdit ||
                              item?.checkedHr ||
                              (state.overridetype !== "Single Date" &&
                                !item.checked)
                            }
                            onChange={(e) =>
                              handleworkdaytimeChange(
                                e,
                                i,
                                time_i,
                                props.closingtime,
                                item
                              )
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={1}>
                        {item[props.operate_time].length === 0 ||
                        item[props.operate_time].length - 1 === time_i ? (
                          <div
                            className={classe.iconBorder}
                            onClick={(e) =>
                              // !isEdit &&
                              addworkdayfield(e, i, itm, time_i)
                            }
                          >
                            <AddIcon />
                          </div>
                        ) : (
                          <div
                            onClick={(e) =>
                              // !isEdit &&
                              deleteworkdayfield(e, i, time_i)
                            }
                            className={classe.iconBorder}
                          >
                            <Tooltip title="Delete" arrow>
                              <DeleteOutlineOutlinedIcon
                                style={{ color: "red" }}
                              />
                            </Tooltip>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            );
          })}
        </DialogContent>
        <div className={classe.buttonSide}>
          <Button
            variant="outlined"
            onClick={() => handleSubmit("cancel", "workingdays")}
          >
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            className={classe.add}
            onClick={() => handleSubmit("save", "workingdays")}
          >
            Save
          </Button>
          &nbsp;&nbsp;
        </div>
      </Dialog>
    </div>
  );
}

WorkingDaysModal.defaultProps = {
  isWorkingDay: true,
  effectiveData: "effFrmDate",
  effectiveDataTo: "effToDate",
  workDaysOfWeek: "workDaysOfWeek",
  operate_time: "operate_time",
  day: "day",
  openingtime: "openingtime",
  closingtime: "closingtime",
  isRadio: false,
};

export default WorkingDaysModal;
