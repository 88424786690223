import React from "react";
import { Div, Card, Image } from "qdm-component-library";
import { withStyles, Popover } from "@material-ui/core";
import SelectCard from "./selectCard";
import down from "./down.svg";

const materialStyles = (theme) => ({
  muiPopovers: {
    boxShadow: "0px 25px 50px #00000029",
    borderRadius: 8,
    width: "32%",
    width: "48%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  Maintitle: {
    color: theme.palette.text.primary,
  },
  MainBackground: {
    background: `${theme.palette.background.common} !important`,
  },
  MainBackgroundCard: {
    background: `${theme.palette.background.table} !important`,
  },
});
class InsuranceInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
    };
  }
  styles = {
    rowFlex: {
      display: "flex",
      alignItems: "center",
    },
    cardStyle: {
      // boxShadow: "0px 10px 25px #0000000A",
      boxShadow: "none",
      borderRadius: 8,
      //marginLeft: "5px",
      // padding: 10,
    },
  };
  openPopover = (e) => {
    this.setState({
      open: e?.currentTarget ?? null,
    });
  };
  closePopover = (e) => {
    this.setState({
      open: null,
    });
  };
  handleChange = (val) => {
    this.props?.onChange && this.props.onChange(val);
    this.closePopover();
  };
  render() {
    let { open } = this.state;
    const {
      classes,
      selected = {},
      careList = [],
      parent_id,
      padding,
    } = this.props;
    return (
      <React.Fragment>
        <Card
          className={classes.MainBackgroundCard}
          id={`${parent_id}_patienttype_parent_card`}
          style={{
            ...this.styles.cardStyle,
            cursor: careList?.length > 1 ? "pointer" : "auto",
            height: "100%",
            padding: padding ? 10 : 0,
          }}
          onClick={(e) => (careList?.length > 1 ? this.openPopover(e) : null)}
        >
          <Div
            id={`${parent_id}_patienttype_parent_div`}
            style={this.styles.rowFlex}
          >
            {selected && (
              <SelectCard
                classesTheme={classes}
                parent_id={"patient_care"}
                moreopen={true}
                pic={selected?.pic}
                title_name={selected?.title_name}
                care_type={selected?.care_type}
                noOfIteams={careList?.length}
              />
            )}
            {careList?.length > 1 && (
              <Div
                id={`${parent_id}_patienttype_div`}
                style={{ ...this.styles.rowFlex, width: 24, height: 24 }}
              >
                <Image
                  id={`${parent_id}_patienttype_group_downarrow_image`}
                  src={down}
                  alt="downArrow"
                />
              </Div>
            )}
          </Div>
        </Card>
        <Popover
          anchorEl={open}
          open={Boolean(open)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={this.closePopover}
          classes={{
            paper: `${classes.muiPopovers} ${classes.MainBackgroundCard}`,
          }}
        >
          <Div id={`${parent_id}_patienttype_title_div`} style={{ padding: 5 }}>
            {careList.map((val, i) => {
              return (
                <Div
                  id={`${parent_id}_${i}_patienttype_title_name_div`}
                  style={{
                    ...this.styles.rowFlex,
                    padding: "5px 10px",
                    paddingBottom: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => this.handleChange(val)}
                >
                  <SelectCard
                    parent_id={"patient_care_" + i}
                    pic={val.pic}
                    title_name={val?.title_name}
                    care_type={val?.care_type}
                    showUpArrow={i === 0 ? true : false}
                    showDivider={i < careList.length - 1 ? true : false}
                    classesTheme={classes}
                  />
                </Div>
              );
            })}
          </Div>
        </Popover>
      </React.Fragment>
    );
  }
}
InsuranceInfo.defaultProps = {
  careList: [],
};
export default withStyles(materialStyles)(InsuranceInfo);
