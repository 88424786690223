import React from "react";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// const data = {
//   value: "grid",
//   children: "",
// };

const useToggleButtonStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.text.primary} !important`,
    padding: 4,
    // border: "0px",
    // backgroundColor: "#F1F6FC",
    "&:hover": {
      backgroundColor: "#F1F6FC",
    },
    "&$selected": {
      backgroundColor: "#D6E5FF",
      "&:hover": {
        backgroundColor: "#F1F6FC",
      },
    },
  },
  selected: {},
}));

export default function ToggleButtons({
  value = "",
  onChange = () => { },
  options = [],
  exclusive = true,
  classes
}) {
  const toggleButtonStyles = useToggleButtonStyles();
  const handleAlignment = (event, newValue) => {
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive={exclusive}
      onChange={handleAlignment}
      classes={`${toggleButtonStyles} ${classes.MainBackground}`}
    >
      {options?.map((item) => (
        <ToggleButton
          classes={toggleButtonStyles}
          value={item?.value}
          aria-label={item?.value}
          disableRipple
        >
          {item?.children ?? ""}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

ToggleButtons.propTypes = {
  options: PropTypes.array,
};
