import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import {
  Paper,
  Box,
  Grid,
  Avatar,
  Typography,
  Button,
} from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
var aes256 = require("aes256");

function PharmacyHeader(props) {
  const dispatch = useDispatch();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {}, [state]);

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Paper
            key={"0"}
            id={"nRYYu"}
            elevation={"{3}"}
            style={qdmstyles.nRYYu}
          >
            <Box key={"0"} id={"wnj3L"} m={"1"} component={"div"}>
              <Grid
                key={"0"}
                id={"hxrHR"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                lg={"12"}
              >
                <Grid
                  key={"0"}
                  id={"OQOlz"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  lg={"9"}
                >
                  <Grid
                    key={"0"}
                    id={"K5oCW"}
                    container={true}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"1"}
                    style={qdmstyles.KoCW}
                  >
                    <Avatar
                      key={"0"}
                      id={"zUsjU"}
                      alt={"Avatar"}
                      variant={"rounded"}
                    ></Avatar>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"UGTVI"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    lg={"2"}
                  >
                    <Typography
                      key={"0"}
                      id={"WkTHr"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Add New Pharmacy"}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"8Athi"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    lg={"9"}
                  >
                    <Box
                      key={"0"}
                      id={"bks0M"}
                      m={"1"}
                      component={"div"}
                      style={qdmstyles.bksM}
                    >
                      <Typography
                        key={"0"}
                        id={"GIFQ8"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"1"}
                        style={qdmstyles.GIFQ}
                      ></Typography>
                    </Box>
                    <Typography
                      key={"1"}
                      id={"oIG4g"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Pharmacy Definition"}
                      style={qdmstyles.oIGg}
                    ></Typography>
                    <Grid
                      key={"2"}
                      id={"9jyfq"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"2"}
                      style={qdmstyles.jyfq}
                    ></Grid>
                    <Box
                      key={"3"}
                      id={"RA8o3"}
                      m={"1"}
                      component={"div"}
                      style={qdmstyles.RAo}
                    >
                      <Typography
                        key={"0"}
                        id={"wd3y8"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"2"}
                        style={qdmstyles.wdy}
                      ></Typography>
                    </Box>
                    <Typography
                      key={"4"}
                      id={"4DQER"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Order Routing"}
                      style={qdmstyles.DQER}
                    ></Typography>
                  </Grid>
                </Grid>
                <Grid
                  key={"1"}
                  id={"QL5bC"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  style={{paddingRight: "1%"}}
                >
                  <Button
                    key={"0"}
                    id={"wEkaC"}
                    variant={"text"}
                    color={"primary"}
                    children={"Cancel"}
                    style={qdmstyles.wEkaC}
                  ></Button>
                  <Button
                    key={"1"}
                    id={"fez21"}
                    variant={"contained"}
                    color={"primary"}
                    children={"Save and Proceed"}
                    style={qdmstyles.fez}
                  ></Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(PharmacyHeader));
