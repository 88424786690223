import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import {
  Grid,
  Typography,
  Checkbox,
  TextField,
  Avatar,
  IconButton,
  makeStyles,
} from "@material-ui/core";

// import { Autocomplete } from "@material-ui/lab";
import qdmstyles from "./styles.json";
import "./index.css";
import { InputBase, Autocomplete } from "../inputs";
import { TrashIcon } from "../../assets";
var aes256 = require("aes256");

const useStyles = makeStyles((theme) => ({
  entitytype: {
    color: theme.palette.text.primary
  }
}));
function Statuslist(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();
  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  const {
    data,
    index,
    drugStatusOptions,
    entityNameOptions,
    setFun = () => { },
    stateName,
    statevalue,
    onDeleteClick = () => { },
  } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"yYTdx"}
            container={true}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            lg={"12"}
            md={"12"}
            sm={"12"}
            xl={"12"}
            xs={"12"}
            spacing={"1"}
            style={qdmstyles.NVcG}
          >
            <Grid
              key={"0"}
              id={"yYTdx"}
              container={""}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              lg={1}
              md={1}
              sm={1}
              xl={1}
              xs={1}
              item={true}
            >
              <Typography
                key={"0"}
                id={"FG5WL"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={data?.entityType?.label}
                style={qdmstyles.KBgPH}
                className={classes.entitytype}
              ></Typography>
            </Grid>
            <Grid
              key={"1"}
              id={"yYTdx"}
              container={""}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              lg={"2"}
              md={"2"}
              sm={"2"}
              xl={"2"}
              xs={"2"}
              item={true}
            >
              <Typography
                key={"0"}
                id={"hgfNC"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                className={classes.entitytype}
                variant={"body1"}
                children={
                  entityNameOptions?.filter(
                    (x) =>
                      x?.value === statevalue?.entityCode ||
                      x?.value === statevalue?.entityCode?._id
                  )?.[0]?.name
                }
                style={qdmstyles.fZKC}
              ></Typography>
            </Grid>
            <Grid
              key={"2"}
              id={"yYTdx"}
              container={""}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              lg={"2"}
              md={"2"}
              sm={"2"}
              xl={"2"}
              xs={"2"}
              item={true}
            >
              <Autocomplete
                id={"s66D7"}
                style={qdmstyles?.sD}
                disabled={props.location?.state?.isView === true ? true : false}
                getOptionLabel={(option) => option?.["label"]}
                options={drugStatusOptions ?? []}
                placeholder="Status"
                value={statevalue?.status}
                onLoad={() => setFun(stateName, "status", statevalue?.status)}
                onChange={(event, newValue) =>
                  setFun(stateName, "status", newValue)
                }
              />
            </Grid>
            <Grid
              key={"3"}
              id={"yYTdx"}
              container={""}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              lg={1}
              md={1}
              sm={1}
              xl={1}
              xs={1}
              item={true}
            >
              <Checkbox
                key={"0"}
                id={"KvhLv"}
                color={"primary"}
                disabled={props.location?.state?.isView === true ? true : false}
                size={"small"}
                style={qdmstyles.uEXR}
                checked={statevalue?.formulary}
                onChange={(event) =>
                  setFun(stateName, "formulary", event.target.checked)
                }
              ></Checkbox>
            </Grid>
            <Grid
              key={"4"}
              id={"yYTdx"}
              container={""}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              lg={"2"}
              md={"2"}
              sm={"2"}
              xl={1}
              xs={1}
              item={true}
            >
              <InputBase
                key={"0"}
                id={"ysf9u"}
                label={""}
                placeholder={"select"}
                disabled={props.location?.state?.isView === true ? true : false}
                type={"date"}
                inputProps={{
                  min: props?.isEdit
                    ? new Date().toISOString().split("T")[0]
                    : null,
                }}
                value={statevalue?.effFr}
                onChange={(event) => {
                  setFun(stateName, "effFr", event.target.value);
                }}
              ></InputBase>
            </Grid>
            <Grid
              key={"5"}
              id={"yYTdx"}
              container={""}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              lg={"2"}
              md={"2"}
              sm={"2"}
              xl={"2"}
              xs={"2"}
              item={true}
            >
              <InputBase
                key={"0"}
                id={"EpEab"}
                label={""}
                disabled={props.location?.state?.isView === true ? true : false}
                placeholder={"select"}
                type={"date"}
                value={statevalue?.effTo}
                onChange={(event) => {
                  setFun(stateName, "effTo", event.target.value);
                }}
              ></InputBase>
            </Grid>
            <Grid
              key={"6"}
              id={"yYTdx"}
              container={""}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              lg={"2"}
              md={"2"}
              sm={"2"}
              xl={"2"}
              xs={"2"}
              item={true}
              style={qdmstyles.nZQc}
            >
              <InputBase
                key={"0"}
                id={"SX3Jx"}
                label={"Type here"}
                placeholder={"Type here"}
                disabled={props.location?.state?.isView === true ? true : false}
                type={"text"}
                style={{ flex: 1 }}
                inputProps={{
                  maxlength: 100,
                }}
                value={statevalue?.reason}
                onChange={(event) => {
                  setFun(stateName, "reason", event.target.value);
                }}
              ></InputBase>
              <IconButton
                onClick={onDeleteClick}
                disabled={props.location?.state?.isView === true ? true : false}
              >
                <TrashIcon />
              </IconButton>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Statuslist));
