import React from "react";
import { Text, Row, Col, Select, TextInput } from "qdm-component-library";
import { UIColor } from "../../../../../../utils";

const styles = {
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: "8px 0px 0px 8px",
    borderColor: UIColor.lineBorderFill.color,
  },
  errorBorderStyle: {
    borderRadius: "8px 0px 0px 8px",
    borderColor: UIColor.error.color,
  },
  label: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px !important",
    fontFamily: "pc_regular!important",
    marginBottom: "6px",
    fontWeight: 400,
  },
  required: {
    color: UIColor.error.color,
    marginLeft: 5,
  },
  dividerVertical: {
    display: "flex",
    alignItems: "center",
    fontSize: 27,
    color: UIColor.tertiaryText.color,
    padding: "0px 5px",
    marginTop: 2,
  },
  inputStyle: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  selectStyle: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
};

const QuantityRangePicker = (props) => {
  const { title, isRequired, optionsvolumuom, optionsuom, parent_id } = props;

  return (
    <Row
    // id={`${parent_id}_quantity_range_row`}
    >
      <Col
        // id={`${parent_id}_quantity_range_col`}
        xs={12}
      >
        <Text
          // id={`${parent_id}_quantity_range_title_text`}
          inLineStyles={styles.label}
        >
          {title}
          {isRequired ? (
            <span
              id={`${parent_id}_quantity_range_title_star_span`}
              style={styles.required}
            >
              *
            </span>
          ) : null}
        </Text>
      </Col>
      <Col
        // id={`${parent_id}_quantity_range_col`}
        xs={12}
        style={{ display: "flex" }}
      >
        <InputGroup
          parent_id={"quantity_range"}
          options={optionsuom}
          {...props}
          type="min"
        />
        {props.data?.max?.quantity && (
          <React.Fragment>
            <div
              // id={`${parent_id}_quantity_range_div`}
              style={styles.dividerVertical}
            >
              /
            </div>
            <InputGroup
              parent_id={"quantity_range"}
              options={optionsvolumuom}
              {...props}
              type="max"
            />
          </React.Fragment>
        )}
      </Col>
    </Row>
  );
};

export const InputGroup = (props) => {
  const { options, data, id, handleChange, disabled, type, parent_id } = props;
  return (
    <div
      // id={`${parent_id}_quantity_range_number_div`}
      style={{ display: "flex" }}
    >
      <div
        // id={`${parent_id}_quantity_range_number_sub_div`}
        style={{ width: 90 }}
      >
        <TextInput
          // id={`${id}-${type}-input`}
          className={"textInput"}
          label={""}
          placeholder=""
          isRequired
          type="number"
          labelStyle={styles.labelStyle}
          style={{
            borderRadius: "1px 0px 0px 1px",
            borderColor: props?.error
              ? UIColor.error.color
              : UIColor.lineBorderFill.color,
          }}
          value={type ? data?.[type]?.quantity : data?.quantity}
          hoverColor={UIColor.lineBorderFill.color}
          onChange={(event) => {
            // if (event.target.value > 999) {
            //   return false;
            // }
            var data1 = data;
            if (type) data1[type].quantity = event.target.value;
            else data1.quantity = event.target.value;
            handleChange(id, data1);
          }}
          disabled={disabled}
        />
      </div>
      <Select
        className={"select"}
        // id={`${id}-min-select`}
        showArrow
        label={""}
        options={options}
        getOptionLabel="label"
        placeholder=""
        labelStyle={styles.labelStyle}
        inLineStyles={{
          ...{ borderRadius: "0px 8px 8px 0px" },
          ...styles.selectStyle,
          ...{
            backgroundColor: disabled
              ? `${UIColor.lineBorderFill.color} !important`
              : "",
          },
          ...{
            borderColor: props?.error
              ? UIColor.error.color
              : UIColor.lineBorderFill.color,
          },
        }}
        hoverColor={UIColor.lineBorderFill.color}
        value={
          type
            ? data?.[type]?.unit?.label ?? data?.[type]?.unit
            : data?.unit?.label ?? data?.unit
        }
        onChangeValue={(value) => {
          var data1 = data;
          if (type) data1[type].unit = value;
          else data1.unit = value;
          handleChange(id, data1);
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default QuantityRangePicker;
