import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
// import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../../contexts";

// import IDMPANEL from "idmpanel-pkg";
// import Config from "../../config";
// import cookie from "react-cookies";

import * as ALL from "../../../components";
import * as ALLMCORE from "@material-ui/core";

// import { Modal } from "qdm-component-library";

import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { CustRowTable } from "custom-material-ui-component";
import SearchIcon from "@material-ui/icons/Search";
import { SearchInputBox } from "./searchInputBox";

import qdmstyles from "./styles.json";
import "./index.css";
import { Routes } from "../../../router/routes";
import { styles } from "./styles";
import Color from "../../../themes/default.json";
import copy from "fast-copy";
import { withStyles } from "@material-ui/core/styles";
// var aes256 = require("aes256");
import { FrequencyListingWrapper } from "clmm-masters"

const minSearchCount = 3;
class FrequencyListing extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      permissionData: null,
      page: 0,
      perPage: 10,
      searchQuery: "",
      fullData: [],
      filteredList: [],
      visibleList: [],
      shouldFilterLocally: false,
    };
  }

  // Limit Data Helper
  limitData = (data = [], page, perPage) => {
    let start = page;
    let end = start + perPage;
    return data?.slice(start, end);
  };

  // Filter Data Helper
  filterData = (data, searchQuery) => {
    if (searchQuery?.length) {
      let res = data?.filter(
        (item) =>
          item?.freqcode?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
          item?.frequencytype?.shortdesc
            ?.toLowerCase()
            ?.includes(searchQuery?.toLowerCase()) ||
          item?.shortdesc?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
      return res;
    }

    return data;
  };

  // Full Read
  frequencyFullRead = (page, perPage, searchQuery, fetchWithoutLimit) => {
    const withLimitPayload = {
      page: page,
      perPage: perPage,
      searchQuery: searchQuery,
    };

    const withOutLimitPayload = {
      searchQuery: searchQuery,
      isWithoutLimit: true,
    };

    Promise.resolve(
      this.props
        .FREQUENCY_MASTER_FULL_READ(
          fetchWithoutLimit ? withOutLimitPayload : withLimitPayload
        )
        .then((res) => {
          let filteredData = this.filterData(res?.payload?.data, searchQuery);
          let copiedData = copy(filteredData);
          let listToShow = fetchWithoutLimit
            ? this.limitData(copiedData, page, perPage)
            : copiedData;
          let s = {
            fullData: res?.payload?.data,
            filteredList: filteredData,
            visibleList: listToShow,
          };
          this.setState(s);
        })
        .catch((err) => console.error(err))
    );
  };

  // Filter List Locally Helper
  filterLocalList = (page, perPage) => {
    const { searchQuery } = this.state;
    let list = this.props.FrequencyMasterFullRead?.data;
    list = this.filterData(list, searchQuery);

    let filteredList = copy(list);
    let copiedList = copy(list);

    let final = this.limitData(copiedList, page, perPage);

    this.setState({
      fullData: this.props.FrequencyMasterFullRead?.data,
      filteredList: filteredList,
      visibleList: final,
    });
  };

  changeState = (key, value) => {
    let prevSearchQuery = "";
    this.setState(
      (prev) => {
        if (key === "searchQuery") {
          prevSearchQuery = prev.searchQuery;
        }
        return {
          [key]: value,
        };
      },
      () => {
        if (key === "searchQuery") {
          if (
            value?.length === 0 ||
            (value?.length === minSearchCount &&
              !this.state.shouldFilterLocally)
          ) {
            this.frequencyFullRead(
              this.state.page,
              this.state.perPage,
              value,
              value?.length !== 0
            );
            this.setState({
              shouldFilterLocally: value?.length >= 3,
            });
            // this.props.DRUG_CATALOG_MASTER_FULL_READ({
            //   page: this.state.page,
            //   perPage: 0,
            //   searchQuery: value,
            // });
          } else if (value.length < minSearchCount) {
            if (prevSearchQuery.length > value.length) {
              this.setState({
                shouldFilterLocally: false,
              });
              // this.callFullRead(this.state.page, this.state.perPage, value);
            }
          } else if (this.state.shouldFilterLocally) {
            this.filterLocalList(0, 10);
          }
        }
      }
    );
  };

  handlepageChange = async (currentPage, PerPage) => {
    this.setState(
      {
        ...this.state,
        page: (currentPage - 1) * PerPage,
        perPage: PerPage,
      },
      () => {
        if (this.state.shouldFilterLocally) {
          this.filterLocalList((currentPage - 1) * PerPage, PerPage);
        } else {
          this.frequencyFullRead(
            (currentPage - 1) * PerPage,
            PerPage,
            this.state.searchQuery
          );
        }
      }
    );
    // await this.props.FREQUENCY_MASTER_FULL_READ({
    //   page: (currentPage - 1) * PerPage,
    //   perPage: PerPage,
    //   searchQuery: this.state.searchQuery,
    // });
  };

  statusChange = async (status, data, index) => {
    let payload = {
      editnewfrequency_org__key: data?._key,
      editnewfrequency_org_status: status,
    };
    await this.props.frequency_schedule_status_updata(payload);
    this.frequencyFullRead(
      this.state.page,
      this.state.PerPage,
      this.state.searchQuery
    );
    // const res = {
    //   page: this.state.page,
    //   perPage: this.state.perPage,
    //   searchQuery: this.state.searchQuery,
    // };
    // this.props.FREQUENCY_MASTER_FULL_READ(res);
  };

  componentDidMount() {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(localStorage.defaultLng);
    }

    /// setlogger && dispatch of all events
    this.frequencyFullRead(
      this.state.page,
      this.state.perPage,
      this.state.searchQuery
    );
    /// fetch all permission
  }

  ///fetching all permission

  // shouldComponentUpdate(nextProps, nextState) {
  //   const { searchQuery: oldSearchQuery } = this.state;
  //   const { searchQuery: searchQuery } = nextState;

  //   if (oldSearchQuery !== searchQuery) {
  //     this.props.FREQUENCY_MASTER_FULL_READ({
  //       // page: this.state.page,
  //       // perPage: this.state.perPage,
  //       page: 0,
  //       perPage: 10,
  //       searchQuery: searchQuery,
  //     });
  //   }

  //   return true;
  // }

  render() {
    const { classes } = this.props;
    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            {/* <Grid
              key={"0"}
              id={"ryrv1"}
              container={true}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              lg={"12"}
              md={"12"}
              sm={"12"}
              className={classes.rootBackground}
            >
              <Grid
                key={"0"}
                id={"w6EVY"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xs={"12"}
                style={qdmstyles.wEVY}
              >
                <Grid
                  key={"0"}
                  id={"Wmqyr"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"12"}
                  md={"12"}
                  sm={"12"}
                  item={true}
                >
                  <Paper
                    key={"0"}
                    id={"7ohqz"}
                    elevation={"1"}
                    variant={"elevation"}
                    className={classes.background}
                  >
                    <Grid
                      key={"0"}
                      id={"Y8ErD"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      spacing={"0"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      item={true}
                      xs={"12"}
                      style={qdmstyles.YErD}
                    >
                      <Grid
                        key={"0"}
                        id={"uzVuR"}
                        container={false}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"3"}
                        xs={"3"}
                        style={qdmstyles.uzVuR}
                      >
                        <Typography
                          key={"0"}
                          id={"KOWr4"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={t("frequency.title")}
                          style={qdmstyles.KOWr}
                          className={classes.frequency}
                        ></Typography>
                      </Grid>

                      <div className={classes.searchAndAddButtonContainer}>
                        <SearchInputBox
                          id="searchDrug"
                          placeholder="Search Code"
                          onChange={(e) =>
                            this.changeState("searchQuery", e.target.value)
                          }
                          className={classes.searchAndButtonInput}
                          style={{ color: Color.palette.text.primary }}
                          startAdornment={
                            <ALLMCORE.InputAdornment position="start">
                              <SearchIcon htmlColor="#6A7888" />
                            </ALLMCORE.InputAdornment>
                          }
                        />

                        <Button
                          onClick={() => {
                            this.props.history.push(Routes.addFrequency, {
                              prevRoute: Routes.frequency,
                            });
                          }}
                          id={"V1isO"}
                          variant={"contained"}
                          children={"+ Add New"}
                          size={"small"}
                          fullWidth={true}
                          className={classes.add}
                          // color="primary"
                          style={
                            qdmstyles.VisO
                            // backgroundColor: Color.palette.secondary.main,
                            // color: Color.palette.text.second,
                          }
                        ></Button>
                      </div>
                    </Grid>
                    <CustRowTable
                      key={"1"}
                      id={"0WNzy"}
                      HeaderComp={
                        <ALL.Tableheader
                          propsState={this.state}
                          setFun={(value) => {
                            this.changeState("Tableheader", value);
                          }}
                          stateName={"Tableheader"}
                          statevalue={this.state?.Tableheader}
                        />
                      }
                      BodyComp={
                        <ALL.Tablerow
                          propsState={this.state.visibleList}
                          loading={this.props?.FrequencyMasterFullRead?.loading}
                          statusChange={this.statusChange}
                        />
                      }
                      style={qdmstyles.WNzy}
                    ></CustRowTable>

                    {this.state.fullData?.length > 0 && (
                      <div style={{ padding: "0px 20px 10px" }}>
                        <ALL.CustomTablePagination
                          handlepageChange={(currentPage, PerPage) =>
                            this.handlepageChange(currentPage, PerPage)
                          }
                          // count={
                          //   this.props.FrequencyMasterFullRead.data[0]?.count
                          //   // 5
                          // }
                          count={
                            this.state.shouldFilterLocally
                              ? this.state.filteredList?.length
                              : this.props?.FrequencyMasterFullRead?.data?.[0]
                                  ?.count?.[0]
                          }
                          incrementCount={this.state.page}
                          showPagination={true}
                          rowsPerPageOptions={[
                            { label: "10 Rows", value: 10 },
                            { label: "50 Rows", value: 50 },
                            { label: "100 Rows", value: 100 },
                          ]}
                        />
                      </div>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Grid> */}
            <FrequencyListingWrapper
              onAddClick={() => {
                this.props.history.push(Routes.addFrequency, {
                  prevRoute: Routes.frequency,
                });
              }}
              onEditClick={(_key) => {
                this.props.history.push({
                  pathname: Routes.editFrequency,
                  state: {
                    _key: _key,
                  },
                });
              }}
              onViewClick={(_key) => {
                this.props.history.push({
                  pathname: Routes.editFrequency,
                  state: {
                    _key: _key,
                    isView: true,
                  },
                });
              }}
            />
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}
const mapStateToProps = (state) => ({
  FrequencyMasterFullRead:
    state?.tablerowfrequencymaster_fullread_aFir7Reducer
      ?.frequencymaster_fullread_aFir7,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(translate()(withStyles(styles)(FrequencyListing))));
