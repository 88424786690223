import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import MainView from "./mainView";
import TreeView from "./treeStuct";
import Grid from "@material-ui/core/Grid";
import { CommonTabList } from "../../components/common";

const styles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary,
		height: "90vh",
		backgroundColor: "white",
		// marginBottom: "20px",
	},
	backgroundColor: {
		backgroundColor: "transparent",
		padding: 0,
	},
});

class Organization extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openTree: true,
			form: false,
			key: null,
			treeView: 0
		};
	}
	render() {
		const hospitalsTree = () => {
			this.setState({
				openTree: !this.state.openTree,
			});
		};
		const formClick = (getkey, fromType) => {
			this.setState({
				...this.state,
				form: !this.state.form,
				key: getkey,
				isexternal: fromType
			});
		};
		const setKeyfuntion = (getkey, fromType) => {
			this.setState({
				key: getkey,
				isexternal: fromType
			});
		};

		// const handleChange = (event, newValue) => {
		// 	this.setState({
		// 		...this.state,
		// 		treeView: newValue
		// 	})
		// };

		const { classes, theme } = this.props;
		return (
			<div
				id={'organization-parent-div'}
				className={classes.root}>
				<Grid id={'organization-parent-grid'} container>
					{this.state.openTree ? (
						<Grid id={'organization-parent-sub-grid'} item xs={3} style={{ zIndex: 1 }}>
							<CommonTabList
								parent_id={'admintabpanel'}
								title={""}
								styles={{backgroundColor: theme.palette.background.table, borderTop: "2px solid #f6f6f6"}}
								list={[{
									name: "Enterprise Details",
									component: (
										<TreeView
											parent_id={'treestuck'}
											onClickTreebtn={() => hospitalsTree}
											onClickformClick={formClick}
											treeis={this.state.openTree}
											isform={this.state.form}
											getKeyfuntion={setKeyfuntion}
											isexternal={false}
										/>
									)
								  },
								  {
									name: "External",
									component: (
										<TreeView
											parent_id={'treestuck'}
											onClickTreebtn={() => hospitalsTree}
											onClickformClick={formClick}
											treeis={this.state.openTree}
											isform={this.state.form}
											getKeyfuntion={setKeyfuntion}
											isexternal={true}
										/>
									)
								  }]}
							/>
						</Grid>
					) : null}

					<Grid
						id={'organization-grid'}
						item xs={this.state.openTree ? 9 : 12}>
						<div
							id={'organization-div'}
							className={clsx(classes.paper, classes.backgroundColor)}>
							<MainView
								parent_id={'mainview'}
								onClickTreebtn={() => hospitalsTree}
								onClickformClick={formClick}
								treeis={this.state.openTree}
								isform={this.state.form}
								handelkey={this.state.key}
								isexternal={this.state?.isexternal??false}
							/>
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(
	(Organization)
);
