import React from "react";
import { VerticalList, CommonTable } from "../../../../components"
import { DrugClass } from "./drugClass";
import { DrugType } from "./drugType";
import { DrugCategory } from "./drugCategory";
import { DrugForm } from "./drugForm";
import { DrugRoutes } from "./drugRoutes";
import { DrugUOM } from "./drugUOM";
import { DrugRouteDirection } from "./drugRoutesDirection"
import  FrequencyMaster  from './frequencyMaster';
// import DrugMaster from './drugMaster';

class Drugs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openIndex: null
        }
        this.handleAddBtn = this.handleAddBtn.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.changeState = this.changeState.bind(this);
    }
    handleAddBtn = (index) => {
        let { open } = this.state;
        this.setState({
            open: !open,
            openIndex: index
        })
    }
    changeState = (name, value) => {
        this.setState({ [name]: value })
    }
    changeTab = () => {
        this.setState({ open: false })
    }
    render() {
        const { open, openIndex } = this.state;
        return <React.Fragment>
            <VerticalList
                parent_id={"drugs"}
                isLeftList={true}
                addFunc={this.handleAddBtn}
                changeTab={this.changeTab}
                hiddenButton={[1, 2]}
                TabList={[
                    {
                        name: "Drug Class",
                        component: <DrugClass
                            parent_id={"drugs"}
                            changeState={this.changeState} open={open} openIndex={openIndex} />
                    },
                    {
                        name: "Drug Type",
                        component: <DrugType
                            parent_id={"drugs"}
                            open={open} changeState={this.changeState} openIndex={openIndex} />,
                        hiddenButton: true
                    },
                    {
                        name: "Drug Category",
                        component: <DrugCategory
                            parent_id={"drugs"}
                            open={open} changeState={this.changeState} openIndex={openIndex} />,
                        hiddenButton: true
                    },
                    {
                        name: "Drug UOM",
                        component: <DrugUOM
                            parent_id={"drugs"}
                            open={open} closeFrom={this.handleAddBtn} changeState={this.changeState} openIndex={openIndex} />
                    },
                    {
                        name: "Dosage Form",
                        component: <DrugForm
                            parent_id={"drugs"}
                            open={open} changeState={this.changeState} openIndex={openIndex} />
                    },
                    {
                        name: "Drug Routes",
                        component: <DrugRoutes
                            parent_id={"drugs"}
                            open={open} changeState={this.changeState} openIndex={openIndex} />
                    },
                    {
                        name: "Drug Route Direction",
                        component: <DrugRouteDirection
                            parent_id={"drugs"}
                            open={open} closeFrom={this.handleAddBtn} changeState={this.changeState} openIndex={openIndex} />
                    },
                    // {
                    //     name: "Drug Master",
                    //     component: <DrugMaster
                    //         parent_id={"drugs"}
                    //         open={open} closeFrom={this.handleAddBtn} changeState={this.changeState} openIndex={openIndex} />
                    // },
                    // {
                    //     name: "Frequency Master",
                    //     component: <FrequencyMaster
                    //         parent_id={"FrequencyMaster"}
                    //         open={open} changeState={this.changeState} openIndex={openIndex} />
                    // },
                ]}
            />
        </React.Fragment>
    }
}

export default Drugs