import React from "react";
import { Row, Col } from "qdm-component-library";
import {
  FormLabel,
  makeStyles,
  Grid,
  IconButton,
  Button,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: "white",
    width: "100%",
    borderRadius: 8,
    padding: 14,
  },
  paddingLeft0: {
    paddingLeft: 0,
  },
  req_red: {
    color: "red",
  },
}));
const styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
  },
  errorBorderStyle: {
    borderRadius: 8,
    border: "1px solid red",
  },
};
const SystemConfiguration = (props) => {
  const classes = useStyles();
  //const details = {};
  const { labelStyle } = styles;
  const {
    parent_id,
    orgdateformat,
    orgtimeformat,
    orgdatetimeformat,
    handleSystemConfigurationChange,
  } = props;

  const dispatch = useDispatch();
  const organizationSlice = useSelector((state) => state.organizationSlice);

//   React.useEffect(() => {
//     const getdata = async () => {
//       await dispatch(actions.ORGANIZATION_GET_DATE_FORMAT());
//       await dispatch(actions.ORGANIZATION_GET_TIME_FORMAT());
//       await dispatch(actions.ORGANIZATION_GET_DATE_TIME_FORMAT());
//     };
//     getdata();

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

  return (
    <Grid
      id={`${parent_id}-parent-row`}
      className={classes.main}
      container
      direction="row"
    >
      <Grid id={`${parent_id}-parent-sub-grid`} container item xs={12}>
        <Grid id={`${parent_id}-parent-container`} container direction="column">
          <Row
            id={`${parent_id}-parent-row`}
            inLineStyles={{ paddingBottom: "2%" }}
          >
            {/* Date Format */}
            <Col
              id={`${parent_id}-Date-Format-col`}
              md={3}
              lg={3}
              sm={3}
              xs={12}
              className={classes.paddingLeft0}
            >
              <FormLabel
                id={`${parent_id}-Date-Format-title-FormLabel`}
                component="legend"
                style={{ ...labelStyle, marginBottom: 10 }}
              >
                Date Format
                {/* <span className={classes.req_red}>*</span> */}
              </FormLabel>
              <Autocomplete
                id={`${parent_id}-Date-Format-autocomplete`}
                getOptionLabel={(option) => option.title}
                style={{ width: "auto", marginTop: "1%" }}
                size={"small"}
                renderInput={(params) => (
                  <TextField
                    id={`${parent_id}-Date-Format-autocomplete`}
                    {...params}
                    variant="outlined"
                    placeholder={"Date Format"}
                    error={false}
                  />
                )}
                options={
                  organizationSlice?.orgDateFormat?.data
                    ? organizationSlice?.orgDateFormat?.data
                    : []
                }
                onChange={(e, val) =>
                  handleSystemConfigurationChange("orgdateformat", val)
                }
                value={orgdateformat}
              />
            </Col>

            {/* Time Format */}
            <Col
              id={`${parent_id}-Time-Format-col`}
              md={3}
              lg={3}
              sm={3}
              xs={12}
              className={classes.paddingLeft0}
            >
              <FormLabel
                id={`${parent_id}-Time-Format-title-FormLabel`}
                component="legend"
                style={{ ...labelStyle, marginBottom: 10 }}
              >
                Time Format
                {/* <span className={classes.req_red}>*</span> */}
              </FormLabel>
              <Autocomplete
                id={`${parent_id}-Time-Format-autocomplete`}
                getOptionLabel={(option) => option.title}
                style={{ width: "auto", marginTop: "1%" }}
                size={"small"}
                renderInput={(params) => (
                  <TextField
                    id={`${parent_id}-Time-Format-autocomplete`}
                    {...params}
                    variant="outlined"
                    placeholder={"Time Format"}
                    error={false}
                  />
                )}
                options={
                  organizationSlice?.orgTimeFormat?.data
                    ? organizationSlice?.orgTimeFormat?.data
                    : []
                }
                onChange={(e, val) =>
                  handleSystemConfigurationChange("orgtimeformat", val)
                }
                value={orgtimeformat}
              />
            </Col>

            {/* Date Time Format */}
            <Col
              id={`${parent_id}-Date-Time-Format-col`}
              md={3}
              lg={3}
              sm={3}
              xs={12}
              className={classes.paddingLeft0}
            >
              <FormLabel
                id={`${parent_id}-Date-Time-Format-title-FormLabel`}
                component="legend"
                style={{ ...labelStyle, marginBottom: 10 }}
              >
                Date Time Format
                {/* <span className={classes.req_red}>*</span> */}
              </FormLabel>
              <Autocomplete
                id={`${parent_id}-Date-Time-Format-autocomplete`}
                getOptionLabel={(option) => option.title}
                style={{ width: "auto", marginTop: "1%" }}
                size={"small"}
                renderInput={(params) => (
                  <TextField
                    id={`${parent_id}-Date-Time-Format-autocomplete`}
                    {...params}
                    variant="outlined"
                    placeholder={"Date Time Format"}
                    error={false}
                  />
                )}
                options={
                  organizationSlice?.orgDateTimeFormat?.data
                    ? organizationSlice?.orgDateTimeFormat?.data
                    : []
                }
                onChange={(e, val) =>
                  handleSystemConfigurationChange("orgdatetimeformat", val)
                }
                value={orgdatetimeformat}
              />
            </Col>
          </Row>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SystemConfiguration;
