import React from "react";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";

import { Grid, Box, Typography, Avatar } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";

function ChiefComplaintsInfo(props) {
  const { propsState, openBody, activeChiefcomplaint } = props;

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  const Chiefcomplaintdata = () => {
    if (activeChiefcomplaint?.value === "All") {
      return propsState;
    } else {
      return propsState?.filter(
        (val) => val?.clinicalStatus === activeChiefcomplaint?.value
      );
    }
  };

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <>
            {Chiefcomplaintdata().length > 0 ? (
              <>
                {Chiefcomplaintdata()?.map((item, index) => {
                  return (
                    <Grid
                      key={item?._key}
                      id={"chief_complaints_info"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      aliasName={"chiefComplaintsInfo"}
                      style={qdmstyles.Cmxlm}
                    >
                      <Grid
                        id={"chiefComplaints_contents"}
                        container={false}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"11"}
                        md={"11"}
                        sm={"11"}
                        xs={"11"}
                        aliasName={"chiefComplaintsContents"}
                      >
                        <Box
                          id={"chiefComplaints_header_div"}
                          m={"1"}
                          component={"div"}
                          aliasName={"chiefComplaintsHeaderDiv"}
                          style={qdmstyles.SREJh}
                        >
                          <Box
                            id={"chiefComplaints_status_div"}
                            m={"1"}
                            component={"div"}
                            aliasName={"chiefComplaintsStatusDiv"}
                            style={{
                              ...qdmstyles.vmfj,
                              // backgroundColor: openBody ? "#CDF0C1" : "#fff",
                            }}
                          >
                            <Box
                              id={"dot"}
                              m={"1"}
                              component={"div"}
                              aliasName={"dot"}
                              style={{
                                ...qdmstyles.vXsF,
                                // backgroundColor: activeChiefcomplaint?.color,
                              }}
                            ></Box>
                          </Box>
                          <Typography
                            id={"chiefComplaints"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={item?.shortdesc}
                            aliasName={"chiefComplaints"}
                            style={qdmstyles.jRhb}
                          ></Typography>
                        </Box>
                        {openBody && (
                          <Box
                            id={"chiefComplaints_lower_div"}
                            m={"1"}
                            component={"div"}
                            aliasName={"chiefComplaintsLowerDiv"}
                            style={qdmstyles.fEzs}
                          >
                            <Box
                              id={"severity_cheif"}
                              m={"1"}
                              component={"div"}
                              aliasName={"severityCheif"}
                              style={qdmstyles.xzyO}
                            >
                              <Box id={"YVB2C"} m={"1"} component={"div"}>
                                <Avatar
                                  id={"Hkahh"}
                                  alt={"Avatar"}
                                  variant={"rounded"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317076983"
                                  }
                                  style={qdmstyles.Hkahh}
                                ></Avatar>
                              </Box>
                              <Typography
                                id={"severityChief"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={item?.severity}
                                aliasName={"severityChief"}
                                style={qdmstyles.GEGRp}
                              ></Typography>
                            </Box>
                            <Box
                              id={"dot"}
                              m={"1"}
                              component={"div"}
                              aliasName={"dot"}
                              style={qdmstyles.fgInB}
                            ></Box>
                            <Typography
                              id={"NDt59"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={item?.bodysite}
                              style={qdmstyles.NDt}
                            ></Typography>
                            <Box
                              id={"dot"}
                              m={"1"}
                              component={"div"}
                              aliasName={"dot"}
                              style={qdmstyles.oqPb}
                            ></Box>
                            <Typography
                              key={"3"}
                              id={"allergy_condition"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={item?.verificationstatus}
                              aliasName={"allergyCondition"}
                              style={qdmstyles.zGK}
                            ></Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid
                        id={"chiefComplaints_edit"}
                        container={false}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"1"}
                        md={"1"}
                        sm={"1"}
                        xs={"1"}
                        aliasName={"chiefComplaintsEdit"}
                      >
                        {/* <Box
                   
                    id={"pen_box"}
                    m={"1"}
                    component={"div"}
                    aliasName={"penBox"}
                  >
                    <Avatar
                     
                      id={"edit_chiefComplaints"}
                      alt={"Avatar"}
                      variant={"rounded"}
                      aliasName={"editChiefComplaints"}
                      src={
                        "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316716564"
                      }
                      style={qdmstyles.WkKtZ}
                    ></Avatar>
                  </Box> */} 
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  paddingBottom: "10px",
                }}
              >
                No Data Found
              </div>
            )}
          </>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(ChiefComplaintsInfo));
