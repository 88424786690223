import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Button,
  Drawer,
  Grid,
  Avatar,
} from "@material-ui/core";
import { CommonTable } from "../../../../../../components";
import { useSelector } from "react-redux";
import { validationFormEnbty } from "../../../../../../utils";
import { withAllContexts } from "../../../../../../HOCs";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
import styles from "../../../../../../components/common/smartForm/styles";
import { generateComponent } from "../../../../../../components/common/smartForm/formsList";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    background:theme.palette.background.table,
    padding: 10,
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "poppin",
    textTransform: "none",
  },
  title: {
    fontFamily: "poppinsemibold",
    display: "inline",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
}));

function FacilityTab(props) {
  const classes = useStyles();
  const classes_ = styles();
  const storeData1 = useSelector((state) => state?.orderCatalogSlice);
  const { FacilityData, handleFormState, parent_id } = props;
  // console.log("FacilityData", FacilityData);

  let entityTypeData =
    storeData1?.order_catalog_masters?.data?.entityType ?? [];
  let entityNameData = storeData1?.entityTypeName?.data ?? [];
  const [state, setState] = React.useState({
    open: false,
    saveloading: false,
    edit_data: false,
    editIndex: null,
    list: [
      {
        componet_type: "select",
        options: entityTypeData,
        label: "Entity Type",
        state_name: "entity_type",
        value: "",
        // required: true,
      },
      {
        componet_type: "select",
        options: entityNameData,
        label: "Entity Name",
        state_name: "entity_name",
        value: "",
        // required: true,
      },
      {
        componet_type: "checkbox",
        label: "Appointment required",
        state_name: "appointment",
        value: "",
        // required: true,
      },
      {
        componet_type: "text",
        label: "Appointment Duration in mins",
        state_name: "duration",
        value: "",
        type: "number",
        // required: true,
      },
      {
        componet_type: "date",
        label: "Effective From",
        state_name: "effective_from",
        value: "",
        dateFormat: "dd/MM/yyyy"
        // required: true,
      },
      {
        componet_type: "date",
        label: "Effective To",
        state_name: "effective_to",
        value: "",
        dateFormat: "dd/MM/yyyy"
        // required: true,
      },
    ],
    states: {
      entity_type: "",
      entity_name: "",
      duration: "",
      appointment: "",
      effective_from: "",
      effective_to: "",
      _id: "",
    },
    error: {},
  });
  const handleDrawerClose = () => {
    setState({ ...state, open: false, editIndex: null });
  };

  const handleDelete = (e, data, index) => {
    let list = JSON.parse(JSON.stringify(FacilityData));
    list.splice(index, 1);
    handleFormState("FacilityData", list);
  };

  const handleEdit = async (e, data, index) => {
    await props.ENTITY_NAME_DATA({ type: data?.entity_type?.value });
    var fmt = 'DD/MM/YYYY';
    let from = data?.effective_from ? new Date(moment.utc(data?.effective_from, fmt).local()) : "";
    let end = data?.effective_to ? new Date(moment.utc(data?.effective_to, fmt).local()) : "";
    setState({
      ...state,
      editIndex: index,
      open: true,
      states: {
        entity_type: data?.entity_type??"",
        entity_name: data?.entity_name??"",
        duration: data?.duration??"",
        appointment: data?.appointment??"",
        effective_from: data?.effective_from? from :"",
        effective_to: data?.effective_to?end: "",
        _id: data?._id??"",
      },
    });
  };

  const saveFacility = async () => {
    let { error, errorState } = await validationFormEnbty(state.list, state.states);
    if (!error && state.states?.effective_to >= state.states?.effective_from) {
      let data = state.states;
      let obj = {};
      obj["entity_type"] = data?.entity_type??"";
      obj["entity_name"] = data?.entity_name??"";
      obj["duration"] = data?.duration??"";
      obj["appointment"] = data?.appointment??"";
      obj["effective_from"] = data?.effective_from? data?.effective_from?.toLocaleDateString(): "";
      obj["effective_to"] = data?.effective_to? data?.effective_to?.toLocaleDateString():"";
      obj["_id"] = data?._id??"";
      let list = JSON.parse(JSON.stringify(FacilityData));
      // let list = JSON.parse(JSON.stringify(applicableFacilities));
      if (state.editIndex || state.editIndex === 0) {
        list[state.editIndex] = {
          ...list[state.editIndex],
          ...obj,
        };
      } else {
        list.push(obj);
      }
      handleFormState("FacilityData", list);
      handleDrawerClose();
    } else {
      
      let customMsg =
        state.states?.effective_to >= state.states?.effective_from
          ? "Require at least one field"
          : "Effective To Should be less than Effective from";
      props.alert.setSnack("mandatory", customMsg);
      setState({
        ...state,
        error: errorState,
      });
    }
  };

  const handleChange = (type, val) => {
    const state_ = state;
    if (type === "entity_type" && val) {
      props.ENTITY_NAME_DATA({ type: val.value });
    }
    if (type === "duration" && val.length <= 3) {
      state_.states[type] = val;
    } else if (type !== "duration") {
      state_.states[type] = val;
    }

    setState({ ...state_ });
  };

  return (
    <React.Fragment>
      <Paper id={`${parent_id}-parent-paper`} className={classes.root}>
        {/* <Typography className={classes.title} variant="h6">PERFORMING ORGANIZATION</Typography> */}
        <Button
          id={`${parent_id}-Add-button`}
          className={classes.addBtn}
          onClick={() =>
            setState({
              ...state,
              open: true,
              states: {
                entity_type: "",
                entity_name: "",
                duration: "",
                appointment: "",
                effective_from: "",
                effective_to: "",
                _id: "",
              },
            })
          }
          color="primary"
        >
          + Add New
        </Button>
        <Grid
          id={`${parent_id}-CommonTable-grid`}
          container
          className={classes.form}
        >
          <CommonTable
            parent_id={"facility"}
            handleEdit={(e, data, index) =>
              // setState({ ...state, editIndex: index, open: true })
              handleEdit(e, data, index)
            }
            handleDelete={handleDelete}
            rightAlign={["Action"]}
            Header={[
              "S.No",
              "Entity Type",
              "Entity Name",
              "Appointment Required",
              "Appointment Duration in mins",
              "Effective From",
              "Effective To",
              "Action",
            ]}
            dataList={FacilityData}
            tableData={[
              { type: ["INCRIMENT"], name: "" },
              { type: ["TEXT"], name: "entity_type", optionLabel: "label" },
              { type: ["TEXT"], name: "entity_name", optionLabel: "label" },
              { type: ["CHECKBOX"], name: "appointment" },
              { type: ["TEXT"], name: "duration" },
              { type: ["TEXT"], name: "effective_from" },
              { type: ["TEXT"], name: "effective_to" },
              { type: ["EDIT", "DELETE"], name: "", align: "right" },
            ]}
            // condition={condition}
          />
        </Grid>
        <Drawer
          className={classes_.drawer}
          anchor="right"
          open={state.open}
          classes={{
            paper: classes_.drawerPaper,
          }}
        >
          {
            <div>
              <Avatar
                src="/images/close.svg"
                className={classes_.large}
                onClick={() => handleDrawerClose()}
              />
              <div>
                <div className={classes_.drawerHead}>
                  <Typography className={classes_.headerMain}>
                    {state.editIndex !== null
                      ? "Edit Performing Organization"
                      : "Add New Performing Organization"}
                  </Typography>
                </div>

                <Grid
                  container
                  direction="row"
                  style={{ padding: "10px 20px" }}
                >
                  <Grid item xs={12}>
                    {state?.list?.length > 0 &&
                      state?.list?.map((val) => {
                        let opt =
                          val.state_name === "entity_type"
                            ? storeData1?.order_catalog_masters?.data
                                ?.entityType
                            : val.state_name === "entity_name"
                            ? storeData1?.entityTypeName?.data
                            : [];
                        return generateComponent({
                          data: { ...val, options: opt },

                          handleChange: handleChange,
                          state: state,
                          classes: classes_,
                          parent_id: parent_id,
                        });
                      })}
                    <div className={classes_.btnPosition}>
                      <Button
                        // disabled={state?.loading ?? false}
                        variant={"contained"}
                        color={"primary"}
                        style={{ marginTop: 20 }}
                        size={"small"}
                        contained
                        onClick={saveFacility}
                      >
                        {"Save"}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          }
        </Drawer>
      </Paper>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(withAllContexts(FacilityTab));
