import React from "react";
import Main from "./main.js";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { checkWithCasbin, languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import cookie from "react-cookies";
import { LocalStorageKeys } from "../../utils";
import {
  AdminIcon,
  BuiildingIcon,
  CartFilledIcon,
  DrugIcon,
  FrequencyIcon,
  MasterIcon,
  PharmacyIcon,
  PractitionerIcon,
  PrinterIcon,
  RuleBuilderIcon,
  TaskAndDocumentIcon,
  UserIcon,
} from "../../assets";
import { Routes } from "../../router/routes.js";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import Color from "../../themes/default.json";

// PC-Icons
import { ReactComponent as OrgLogo } from "../../assets/icons8-business-building.svg";
import EngineeringLogo from "../../assets/engineering.png";
import { ReactComponent as BusinessLogo } from "../../assets/icons8-database-administrator (1).svg";
import { ReactComponent as PractitionerMasterLogo } from "../../assets/Practitioner_Master.svg";
import { ReactComponent as UserLogo } from "../../assets/icons8-user-folder (1).svg";
import copy from "fast-copy";

const pages = [
  {
    Ispage: true,
    repoName: "CLMM - Frequency",
    name: "Frequency",
    pageId: "pnBI9",
    page: "/frequency",
    pages: ["/frequency", "/editnewfrequency_org", "/addnewfrequency_org"],
    masterLayoutId: "i20Hg",
    isShow: true,
    size: "13",
    icon: (
      <FrequencyIcon
        color={
          window.location.pathname === "/frequency" ||
          window.location.pathname === "/addnewfrequency_org" ||
          window.location.pathname === "/editnewfrequency_org"
            ? "#2A60BC"
            : "#001C3C"
        }
      />
    ),
  },
  // ? "#2A60BC" : window.location.pathname === "/editnewfrequency_org"
  {
    repoName: "CLMM - Pharmacy",
    Ispage: true,
    name: "Pharmacy",
    pageId: "tLskT",
    page: "/pharmacy",
    masterLayoutId: "i20Hg",
    size: "13",
    pages: ["/pharmacy", "/editnewpharmacy", "/addnewpharmacy"],
    isShow: true,
    icon: (
      <PharmacyIcon
        color={
          window.location.pathname === "/pharmacy" ||
          window.location.pathname === "/editnewpharmacy" ||
          window.location.pathname === "/addnewpharmacy"
            ? "#2A60BC"
            : "#001C3C"
        }
      />
    ),
  },
  {
    repoName: "CLMM - Drug Catalogue Master",
    Ispage: true,
    name: "Drug Catalog Master",
    pageId: "yuERa",
    page: Routes.drugmastertable,
    masterLayoutId: "i20Hg",
    size: "13",
    isShow: true,
    pages: [
      Routes.drugmastertable,
      Routes.drugintscreen,
      Routes.alertrestriction,
    ],
    icon: (
      <DrugIcon
        color={
          window.location.pathname === Routes.drugmastertable ||
          window.location.pathname === Routes.drugintscreen ||
          window.location.pathname === Routes.alertrestriction
            ? "#2A60BC"
            : "#001C3C"
        }
      />
    ),
  },
  {
    repoName: "CLMM - Task and Document",
    Ispage: true,
    name: "Task and Document",
    page: Routes.taskAndDocument,
    size: "13",
    pageId: "yuERa",
    masterLayoutId: "i20Hg",
    isShow: true,
    pages: [Routes.taskAndDocument],
    icon: (
      <TaskAndDocumentIcon
        color={
          window.location.pathname === Routes.taskAndDocument
            ? "#2A60BC"
            : "#001C3C"
        }
      />
    ),
  },
  {
    repoName: "CLMM - Cart",
    Ispage: true,
    name: "Cart Definition",
    page: Routes.cartDefinition,
    size: "13",
    pageId: "yuERacart",
    masterLayoutId: "i20Hg",
    pages: [Routes.cartDefinition],
    isShow: true,
    icon: (
      <CartFilledIcon
        color={
          window.location.pathname === Routes.cartDefinition ||
          window.location.pathname === Routes.cartDefinitionUpsert
            ? "#2A60BC"
            : "#001C3C"
        }
      />
    ),
  },

  {
    repoName: "CLMM - Organization",
    Ispage: true,
    name: "Organization Config",
    page: Routes.organizationConfig,
    isShow: true,
    size: "13",
    pageId: "yuERa241",
    masterLayoutId: "i20Hg",
    pages: [Routes.organizationConfig],
    icon: <BuiildingIcon route={Routes.organizationConfig} />,
  },
  {
    repoName: "CLMM - Organization",
    Ispage: true,
    name: "Organizations",
    page: Routes.organization,
    isShow: true,
    size: "13",
    pageId: "yuERa242",
    masterLayoutId: "i20Hg",
    pages: [Routes.organization],
    icon: <BuiildingIcon route={Routes.organization} />,
  },
  {
    repoName: "CLMM - Administration",
    Ispage: true,
    name: "Administration",
    page: Routes.administration,
    isShow: true,
    size: "13",
    pageId: "yuERa243",
    masterLayoutId: "i20Hg",
    pages: [Routes.administration],
    icon: <AdminIcon />,
  },
  {
    repoName: "CLMM - User Management",
    Ispage: true,
    name: "User Management",
    page: Routes.userManagement,
    isShow: true,
    size: "13",
    pageId: "yuERa244",
    masterLayoutId: "i20Hg",
    pages: [Routes.userManagement],
    icon: <UserIcon route={Routes.userManagement} />,
  },
  {
    repoName: "CLMM - Practitioner Master",
    Ispage: true,
    name: "Practitioner Master",
    page: Routes.practitionerMaster,
    isShow: true,
    size: "13",
    pageId: "yuERa245",
    masterLayoutId: "i20Hg",
    pages: [Routes.practitionerMaster],
    icon: <PractitionerIcon />,
  },
  {
    repoName: "CLMM - Consult Masters",
    Ispage: true,
    name: "Consult Masters",
    isShow: true,
    page: Routes.consultMaster,
    size: "13",
    pageId: "yuERa246",
    masterLayoutId: "i20Hg",
    pages: [Routes.consultMaster],
    icon: <UserIcon route={Routes.consultMaster} />,
  },
  {
    repoName: "CLMM - Rule Builder",
    Ispage: true,
    name: "Rule Builder",
    page: Routes.ruleBuilder,
    isShow: true,
    size: "13",
    pageId: "yuERa247",
    masterLayoutId: "i20Hg",
    pages: [Routes.ruleBuilder],
    icon: <RuleBuilderIcon />,
    redirectUrl: `${Config.ruleBuilderUiUrl}/rules?token=68b4e72f-d774-42d1-927c-4ac81d2adedc`,
  },
  {
    repoName: "CLMM - Printer Queue",
    Ispage: true,
    name: "Printer Queue",
    page: Routes.printerQueue,
    isShow: true,
    size: "13",
    pageId: "yuERa24",
    masterLayoutId: "i20Hg",
    pages: [Routes.printerQueue],
    icon: (
      <PrinterIcon
        color={
          window.location.pathname === Routes.printerQueue
            ? "#2A60BC"
            : "#001C3C"
        }
      />
    ),
  },
  {
    repoName: "Rx Management",
    Ispage: true,
    name: "Rx management",
    isShow: true,
    page: Routes.pharmacyVerify,
    size: "13",
    pageId: "yuERa246",
    masterLayoutId: "i20Hg",
    pages: [Routes.pharmacyVerify],
    icon: <UserIcon route={Routes.pharmacyVerify} />,
  },
  {
    repoName: "CLMM - Add New Order",
    Ispage: true,
    name: "Order Cart",
    isShow: true,
    page: Routes.cartOrder,
    size: "13",
    pageId: "yuERa246",
    masterLayoutId: "i20Hg",
    pages: ["/cartorder"],
    icon: <UserIcon route={"/cartorder"} />,
  },
  {
    repoName: "Cart Verify",
    Ispage: true,
    name: "Cart Order Verify",
    isShow: true,
    page: Routes.cartVerify,
    size: "13",
    pageId: "yuERa246",
    masterLayoutId: "i20Hg",
    pages: ["/cartOrderVerify"],
    icon: <UserIcon route={"/cartOrderVerify"} />,
  },
  {
    repoName: "CLMM - Report Viewer",
    Ispage: true,
    name: "Report",
    isShow: true,
    page: Routes.report,
    size: "13",
    pageId: "reportUniqueId",
    masterLayoutId: "i20Hg",
    pages: [Routes.report],
    icon: <UserIcon route={Routes.report} />,
  },
];

export function MainLayout(props) {
  const [renderPages, setRenderPages] = React.useState([]);
  const roleAccess = async (Role) => {
    let AccessData = await checkWithCasbin(Role);
    return AccessData;
  };

  React.useEffect(() => {
    (async () => {
      let Role = localStorage.getItem("role_name");
      let accessRights = await roleAccess(Role);

      let AccessedPages = copy(pages);

      AccessedPages = AccessedPages.filter(
        (page) => accessRights.write.indexOf(page.repoName) > -1
      );
      setRenderPages(AccessedPages);
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Main
            key={"0"}
            id={"i20Hg"}
            Component={props.children}
            {...props}
            Header={true}
            Menu={props.ismenu === undefined ? true : props.ismenu}
            pages={renderPages}
            ProjectTitle={"CLMM"}
            VersionNo={`Version: ${localStorage.getItem(
              LocalStorageKeys.version
            )}`}
            UserName={localStorage.loginUserName}
            SubTitle={localStorage.getItem("role_name")}
            HeaderBackgroundColor={Color.palette.primary.main}
            HeaderFontColor={"#FFFFFF"}
            // style={qdmstyles.iHg}
          />
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}
