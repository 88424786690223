import React from "react";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { LoggerContext } from "../../contexts";
import { useDispatch } from "react-redux";
import { Grid, Box, Typography, Avatar } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";

function DiagnosisInfo(props) {
  const dispatch = useDispatch();
  const { propsState, openBody, activeDiagnosis } = props;

  const [state, setState] = React.useState({
    permissionData: null,
  });

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {

      const { i18n } = props;

      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  const Diagnosisdata = () => {
    if (activeDiagnosis?.value === "All") {
      return propsState;
    } else {
      return propsState?.filter(
        (val) => val?.clinicalstatus === activeDiagnosis?.value
      );
    }
  };

  const { classes } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <>
            {Diagnosisdata().length > 0 ? (
              <>
                {Diagnosisdata()?.map((item, index) => {
                  return (
                    <Grid
                      key={item?._key}
                      id={"diagnosis_component"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      aliasName={"diagnosisComponent"}
                      style={qdmstyles.SNdhm}
                      className={classes.root}
                    >
                      <Grid
                        id={"diagnosis_contents"}
                        container={false}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"11"}
                        md={"11"}
                        sm={"11"}
                        xs={"11"}
                        aliasName={"diagnosisContents"}
                      >
                        <Box
                          id={"diagnosis_header_div"}
                          m={"1"}
                          component={"div"}
                          aliasName={"diagnosisHeaderDiv"}
                          style={qdmstyles.YbGF}
                        >
                          <Box
                            id={"diagnosis_status_div"}
                            m={"1"}
                            component={"div"}
                            aliasName={"diagnosisStatusDiv"}
                            style={{
                              ...qdmstyles.KOH,
                              // backgroundColor: openBody ? "#CDF0C1" : "#fff",
                            }}
                          >
                            <Box
                              id={"dot"}
                              m={"1"}
                              component={"div"}
                              aliasName={"dot"}
                              style={{
                                ...qdmstyles.lpUWV,
                                // backgroundColor: activeDiagnosis?.color,
                              }}
                            ></Box>
                          </Box>
                          <Typography
                            key={"1"}
                            id={"diagnosis"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={item?.icdshortname}
                            aliasName={"diagnosis"}
                            style={qdmstyles.HdRO}
                          ></Typography>
                        </Box>
                        {openBody && (
                          <Box
                            key={"1"}
                            id={"allergy_composition_div"}
                            m={"1"}
                            component={"div"}
                            aliasName={"allergyCompositionDiv"}
                            style={qdmstyles.kbNJf}
                          >
                            {/* <Box
                       
                        id={"allergy_severity_div"}
                        m={"1"}
                        component={"div"}
                        aliasName={"allergySeverityDiv"}
                        style={qdmstyles.BMElU}
                      >
                        <Box
                         
                          id={"eyqL2"}
                          m={"1"}
                          component={"div"}
                          style={qdmstyles.eyqL}
                        >
                          <Avatar
                           
                            id={"severity_logo"}
                            alt={"Avatar"}
                            variant={"rounded"}
                            aliasName={"severityLogo"}
                            src={
                              "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317076983"
                            }
                            style={qdmstyles.vRJHy}
                          ></Avatar>
                        </Box>
                        <Typography
                          key={"1"}
                          id={"severity"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={item?.use}
                          aliasName={"severity"}
                          style={qdmstyles.dbBqu}
                        ></Typography>
                      </Box> */}
                            <Box
                              key={"1"}
                              id={"allergy_severity_div"}
                              m={"1"}
                              component={"div"}
                              aliasName={"allergySeverityDiv"}
                              style={qdmstyles.bvxb}
                            >
                              <Box id={"JdMbe"} m={"1"} component={"div"}>
                                <Avatar
                                  id={"food_logo"}
                                  alt={"Avatar"}
                                  variant={"rounded"}
                                  aliasName={"foodLogo"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317077803"
                                  }
                                  style={qdmstyles.PNodB}
                                ></Avatar>
                              </Box>
                              <Typography
                                key={"1"}
                                id={"severity"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={item?.use}
                                aliasName={"severity"}
                                style={qdmstyles.rfk}
                              ></Typography>
                            </Box>
                            <Box
                              key={"2"}
                              id={"dot"}
                              m={"1"}
                              component={"div"}
                              aliasName={"dot"}
                              style={qdmstyles.oqPb}
                            ></Box>
                            <Typography
                              key={"3"}
                              id={"allergy_condition"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={item?.VerificationSTatus}
                              aliasName={"allergyCondition"}
                              style={qdmstyles.zGK}
                            ></Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"diagnosis_edit"}
                        container={false}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"1"}
                        md={"1"}
                        sm={"1"}
                        xs={"1"}
                        aliasName={"diagnosisEdit"}
                      >
                        {/* <Box
                   
                    id={"pen_box"}
                    m={"1"}
                    component={"div"}
                    aliasName={"penBox"}
                  >
                    <Avatar
                     
                      id={"edit_diagnosis"}
                      alt={"Avatar"}
                      variant={"rounded"}
                      aliasName={"editDiagnosis"}
                      src={
                        "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316716564"
                      }
                      style={qdmstyles.uIln}
                    ></Avatar>
                  </Box> */}
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  paddingBottom: "10px",
                }}
              >
                No Data Found
              </div>
            )}
          </>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(DiagnosisInfo));
