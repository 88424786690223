import React from "react";
import AgeRangeForm from "./form";
import {
  VerticalList,
  CommonTable,
  OverlayCompt,
} from "../../../../components";
import { withAllContexts } from "../../../../HOCs";
import { connect } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
//import { AlertProps } from "../../../../utils";
class AgeRangesMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      editData: null,
    };
    this.handleForm = this.handleForm.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }
  async componentDidMount() {
    await this.props.GET_AGE_RANGE_MASTER();
    await this.props.UNIT_UOM();
  }
  handleForm = () => {
    let { openForm, editData } = this.state;
    this.setState({
      openForm: !openForm,
      editData: openForm ? null : editData,
    });
  };
  handleEdit = (e, data, index) => {
    this.setState({
      editData: data,
      openForm: true,
    });
  };
  handleCheckBox = async (e, data, index) => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Updating...",
    });
    await this.props.UPDATE_AGE_RANGE({
      editId: data._id,
      status: e.target.checked,
    });
    await this.props.GET_AGE_RANGE_MASTER();
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
    this.props.alert.setSnack("update");
  };
  render() {
    const { openForm, editData } = this.state;
    return (
      <React.Fragment>
        <VerticalList
          parent_id={"consult_master"}
          addFunc={this.handleForm}
          TabList={[
            {
              name: "Age Ranges",
              component: (
                <CommonTable
                  parent_id={"consult_master"}
                  rightAlign={["Status", "Action"]}
                  handleEdit={this.handleEdit}
                  handleCheckBox={this.handleCheckBox}
                  Header={[
                    "S.No",
                    "Code",
                    "Short Description",
                    "Long Description",
                    "Status",
                    "Action",
                  ]}
                  dataList={this.props?.ageRangeList?.data ?? []}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "agerangecode" },
                    { type: ["TEXT"], name: "shortdesc" },
                    { type: ["TEXT"], name: "longdesc" },
                    { type: ["CHECKBOX"], name: "status", align: "right" },
                    { type: ["EDIT"], name: "", align: "right" },
                  ]}
                />
              ),
            },
          ]}
        />
        <OverlayCompt
          parent_id={"consult-master"}
          open={openForm}
          children={
            <React.Fragment>
              {openForm && (
                <AgeRangeForm
                  parent_id={"consult-master"}
                  editData={editData}
                  closeForm={this.handleForm}
                />
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ageRangeList: state?.ageRangeMasterSlice?.age_range_list,
  ageRangeUom: state?.ageRangeMasterSlice?.unit_uom_list,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(AgeRangesMaster));
