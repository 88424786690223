import React from "react";
import { Card } from "@material-ui/core";
import "../../font.css";
import { Typography } from "@material-ui/core";
import { TextInput, Upload, Col, Button, Text } from "qdm-component-library";
import "./style.css";
import PatientCotactPoint from "./contactPoint";
import PatientAddress from "./address";
import { withAllContexts } from "../../../HOCs";
import { Filenameui } from "./commonUI";
import { AlertProps, uploadImage, checkMaxDateValidation, getFormatForDateTimePicker } from "../../../utils";
import moment from "moment";
import { DateTimePicker } from "../../common/smartForm/component";
class AddNewTraining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: [{}],
      address: [],
    };
  }

  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "10px",
      marginBottom: "6px",
      fontFamily: `poppin !important`,
    },
    borderStyle: {
      borderRadius: "8px",
      borderColor: "#E0E0E0",
      width: "514px",
      height: "40px",
    },
    borderStyle2: {
      borderRadius: "8px",
      height: "40px",
      borderColor: "#E0E0E0",
      width: "180px",
    },
    root: {
      borderRadius: "8px !important",
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
    errorBorderStyle: {
      borderRadius: 8,
      border: "1px solid red",
      width: "514px",
      height: "40px",
    },
  };

  onChangeState = (key, value, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    if (key === "course_start_date") {
      this.state["course_end_date"] = "";
      this.state["certificate_issue_date"] = "";
      this.state["certificate_expiry_date"] = "";
    }
    if (key === "course_end_date") {
      this.state["certificate_issue_date"] = "";
      this.state["certificate_expiry_date"] = "";
    }
    if (key === "certificate_issue_date") {
      this.state["certificate_expiry_date"] = "";
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  componentDidMount() {
    const { editData } = this.props;
    if (editData) {
      this.setState({ ...editData, erorr: {} });
    } else {
      this.Clear();
    }
  }
  validateCheck = (data) => {
    // const contactVal = () => {
    //   if(data.contact?.length === 0){
    //     return true;
    //   }else{
    //      let error = data.contact.map(val => val.mobile?.length > 0 ? true : false );
    //      return !error.includes(false);
    //   }
    // }
    if (this.state?.course_start_date?.length > 0 && this.state?.course_end_date?.length > 0) {
      if (checkMaxDateValidation(this.state?.course_start_date, this.state?.course_end_date)) {
        return true
      }
    }
    if (this.state?.course_end_date?.length > 0 && this.state?.certificate_issue_date?.length > 0) {
      if (checkMaxDateValidation(this.state?.course_end_date, this.state?.certificate_issue_date)) {
        return true
      }
    }
    if (this.state?.certificate_issue_date?.length > 0 && this.state?.certificate_expiry_date?.length > 0) {
      if (checkMaxDateValidation(this.state?.certificate_issue_date, this.state?.certificate_expiry_date)) {
        return true
      }
    }
    if (
      data?.organization?.length > 0 &&
      data?.responsible_person?.length > 0 &&
      data?.course_name?.length > 0
    ) {
      return false;
    } else {
      let errors = {
        organization: data?.organization?.length > 0 ? false : true,
        responsible_person: data?.responsible_person?.length > 0 ? false : true,
        course_name: data?.course_name?.length > 0 ? false : true,
      };
      this.setState({ ...this.state, erorr: errors });
      return true;
    }
  };

  saveAddress = () => {
    if (this.validateCheck(this.state)) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill Training course details mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }
    if (this.props?.saveAddress) {
      this.props.saveAddress(this.state);
    }

    // const { onclose, index, handleClear } = this.props;
    // let patientData = this.props[this.props.saveto];
    // if (index !== null) {
    //   patientData.training[index] = this.state;
    // } else {
    //   patientData.training.push(this.state);
    // }
    // patientData[this.props.setTo]({
    //   ...patientData,
    // });
    // onclose();
    // handleClear();
    // this.Clear();
  };

  Clear = () => {
    this.setState({
      organization: null,
      course_name: null,
      responsible_person: null,
      course_start_date: null,
      course_end_date: null,
      certificate_issue_date: null,
      certificate_expiry_date: null,
    });
  };

  setCustomContact = (name, value, index) => {
     if (name === "mode") {
       this.state.contact[index]["mobile"] = "";
     }
    this.state.contact[index][name] = value;
    this.setState({
      ...this.state,
    });
  };

  addNewContact = () => {
    this.state.contact.push({});
    this.setState({
      ...this.state,
    });
  };

  deleteContect = (index) => {
    // let contact = this.state.contact.splice(0, index)
    this.state.contact.splice(index, 1);
    this.setState({
      ...this.state,
      // contact: contact
    });
  };

  handleClear = () => {
    this.Clear();
    if (this.props?.handleClear) {
      this.props.handleClear();
    }
  };

  setAddress = (data) => {
    this.state.address = data;
    this.setState({
      ...this.state,
    });
  };

  setDataUpload = async (name, value, next, limit) => {
    var UploadedFiles = [];
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    UploadedFiles = await uploadImage([value]);
    this.onChangeState(name, UploadedFiles);
  };

  render() {
    const {
      labelStyle,
      borderStyle,
      errorBorderStyle,
      borderStyle2,
      containedButton,
    } = this.styles;
    const { onclose, parent_id } = this.props;

    return (
      <div
        id={`${parent_id}-parent-div`}
      >
        <Card
          id={`${parent_id}-parent-card`}
          style={{
            paddingBottom: "20px",
            paddingLeft: "20px",
            paddingTop: "15px",
            margin: 8,
          }}
        >
          <div
            id={`${parent_id}-Course-Details-title-div`}
            style={{ paddingBottom: 6 }}>Course Details</div>
          <div
            id={`${parent_id}-Course-Details-div`}
            style={{ display: "flex" }}>
            <div
              id={`${parent_id}-Organization-div`}
            >
              <Typography
                id={`${parent_id}-Organization-title-div`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Organization <span
                  id={`${parent_id}-Organization-star-span`}
                  style={{ color: "red" }}>*</span>
              </Typography>
              <TextInput
                id={`${parent_id}-Organization-textinput`}
                className="root"
                onChange={(e) =>
                  this.onChangeState("organization", e.target.value)
                }
                value={this.state.organization}
                variant="outlined"
                fullWidth
                size="small"
                name="pageName"
                //style={{ width: "514px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                placeholder={"Enter Organization"}
                // style={borderStyle}
                style={
                  this.state?.erorr?.organization
                    ? errorBorderStyle
                    : borderStyle
                }
                isRequired
              />
            </div>
            <div
              id={`${parent_id}-Course-name-div`}
              style={{ marginLeft: "15px" }}>
              <Typography
                id={`${parent_id}-Course-name-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Course Name <span
                  id={`${parent_id}-Course-name-star-span`}
                  style={{ color: "red" }}>*</span>
              </Typography>
              <TextInput
                id={`${parent_id}-Course-name-textinput`}
                className="root"
                onChange={(e) =>
                  this.onChangeState("course_name", e.target.value)
                }
                value={this.state.course_name}
                variant="outlined"
                fullWidth
                size="small"
                name="pageName"
                //style={{ width: "514px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                placeholder={"Enter Course Name"}
                // style={borderStyle}
                style={
                  this.state?.erorr?.course_name
                    ? errorBorderStyle
                    : borderStyle
                }
              />
            </div>
          </div>
          <div
            id={`${parent_id}-Responsible-Person-div`}
            style={{ display: "flex", marginTop: "16px" }}>
            <div
              id={`${parent_id}-Responsible-Person-sub-div`}
            >
              <Typography
                id={`${parent_id}-Responsible-Person-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Responsible Person <span
                  id={`${parent_id}-Responsible-Person-star-span`}
                  style={{ color: "red" }}>*</span>
              </Typography>
              <TextInput
                id={`${parent_id}-Responsible-Person-textinput`}
                className="root"
                onChange={(e) =>
                  this.onChangeState("responsible_person", e.target.value)
                }
                value={this.state.responsible_person}
                variant="outlined"
                fullWidth
                size="small"
                name="pageName"
               // style={{ width: "514px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                placeholder={"Enter Responsible Person"}
                // style={borderStyle}
                style={
                  this.state?.erorr?.responsible_person
                    ? errorBorderStyle
                    : borderStyle
                }
              />
            </div>
            <div
              id={`${parent_id}-Course-start-date-div`}
              style={{ marginLeft: "15px" }}>
              {/* <Typography
                id={`${parent_id}-Course-start-date-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Course Start Date
              </Typography> */}
              {/* <TextInput
                id={`${parent_id}-Course-start-date-textinput`}
                className="root"
                onChange={(e) =>
                  this.onChangeState("course_start_date", e.target.value)
                }
                value={this.state.course_start_date}
                variant="outlined"
                fullWidth
                size="small"
                type="date"
                name="pageName"
                // style={{ width: "180px", borderRadius: "18px !important" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={borderStyle2}
                max={"9999-12-31"}
              /> */}
              <DateTimePicker
              parent_id={parent_id}
              label={"Course Start Date"}
              placeholder={getFormatForDateTimePicker("date")}
              // dateFormat={"dd-MM-yyyy"}
              onChange={(value) => this.onChangeState("course_start_date", value)}
              value={this.state.course_start_date}
              maxDate={moment(new Date("12-31-9999"))}
              />
            </div>
            <div
              id={`${parent_id}-Course-end-date-div`}
              style={{ marginLeft: "15px" }}>
              {/* <Typography
                id={`${parent_id}-Course-end-date-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Course End Date
              </Typography>
              <TextInput
                id={`${parent_id}-Course-end-date-textinput`}
                className="root"
                onChange={(e) =>
                  this.onChangeState("course_end_date", e.target.value)
                }
                value={this.state.course_end_date}
                variant="outlined"
                fullWidth
                size="small"
                type="date"
                name="pageName"
                //style={{ width: "180px" }}
                min={moment(this.state.course_start_date).format("YYYY-MM-DD")}
                max={"9999-12-31"}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={borderStyle2}
                helperText={(this.state?.course_start_date?.length > 0 && this.state?.course_end_date?.length > 0) ? checkMaxDateValidation(this.state?.course_start_date, this.state?.course_end_date) ?? " " : " "}
                error={true}
              /> */}
              <DateTimePicker
              parent_id={parent_id}
              label={"Course End Date"}
              placeholder={getFormatForDateTimePicker("date")}
              // dateFormat={"dd-MM-yyyy"}
              value={this.state.course_end_date}
              onChange={(value) => this.onChangeState("course_end_date", value)}
              min={moment(this.state.course_start_date).format("YYYY-MM-DD")}
              maxDate={moment(new Date("12-31-9999"))}
              />
            </div>
          </div>
          <div
            id={`${parent_id}-parent-div`}
            style={{ display: "flex", marginTop: "16px" }}>
            <div
              id={`${parent_id}-certificate-issue-div`}
            >
              {/* <Typography
                id={`${parent_id}-certificate-issue-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Certificate Issue Date
              </Typography>
              <TextInput
                id={`${parent_id}-certificate-issue-textinput`}
                className="root"
                onChange={(e) =>
                  this.onChangeState("certificate_issue_date", e.target.value)
                }
                min={moment(this.state.course_end_date).format("YYYY-MM-DD")}
                max={"9999-12-31"}
                value={this.state.certificate_issue_date}
                variant="outlined"
                fullWidth
                size="small"
                type="date"
                name="pageName"
                //style={{ width: "180px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={borderStyle2}
              // helperText={(this.state?.course_end_date?.length > 0 && this.state?.certificate_issue_date?.length > 0) ? checkMaxDateValidation(this.state?.course_end_date, this.state?.certificate_issue_date)??" ":" "}
              // error={true}
              /> */}
              <DateTimePicker
              parent_id={parent_id}
              label={"Certificate Issue Date"}
              placeholder={getFormatForDateTimePicker("date")}
              // dateFormat={"dd-MM-yyyy"}
              value={this.state.certificate_issue_date}
              onChange={(value) => this.onChangeState("certificate_issue_date", value)}
              min={moment(this.state.course_end_date).format("YYYY-MM-DD")}
              maxDate={moment(new Date("12-31-9999"))}
              />
              {(this.state?.course_end_date?.length > 0 && this.state?.certificate_issue_date?.length > 0) &&
                <Text style={{ padding: "0px 4px", fontSize: "12px", color: "red" }}>
                  {checkMaxDateValidation(this.state?.course_end_date, this.state?.certificate_issue_date) ?? " "}
                </Text>}
            </div>
            <div
              id={`${parent_id}-certificate-expiry-div`}
              style={{ marginLeft: "15px" }}>
              {/* <Typography
                id={`${parent_id}-certificate-expiry-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Certificate Expiry Date
              </Typography>
              <TextInput
                id={`${parent_id}-certificate-expiry-textinput`}
                className="root"
                onChange={(e) =>
                  this.onChangeState("certificate_expiry_date", e.target.value)
                }
                value={this.state.certificate_expiry_date}
                variant="outlined"
                max={"9999-12-31"}
                min={moment(this.state.certificate_issue_date).format(
                  "YYYY-MM-DD"
                )}
                fullWidth
                size="small"
                type="date"
                name="pageName"
                //style={{ width: "180px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={borderStyle2}
                helperText={(this.state?.certificate_issue_date?.length > 0 && this.state?.certificate_expiry_date?.length > 0) ? checkMaxDateValidation(this.state?.certificate_issue_date, this.state?.certificate_expiry_date) ?? " " : " "}
                error={true}
              /> */}
              <DateTimePicker
              parent_id={parent_id}
              label={"Certificate Expiry Date"}
              placeholder={getFormatForDateTimePicker("date")}
              // dateFormat={"dd-MM-yyyy"}
              value={this.state.certificate_expiry_date}
              onChange={(value) => this.onChangeState("certificate_expiry_date", value)}
              min={moment(this.state.certificate_issue_date).format("YYYY-MM-DD")}
              maxDate={moment(new Date("12-31-9999"))}
              />
            </div>

            {this.state?.doc?.[0]?.filename ? (
              <div
                id={`${parent_id}-Filenameui-div`}
                style={{ padding: 10 }}>
                <Filenameui
                  parent_id={'addnewtraining'}
                  fileName={this.state?.doc?.[0]?.filename ?? " "}
                  delete={() => this.onChangeState("doc", null)}
                />
              </div>
            ) : (
              <Col
                id={`${parent_id}-Upload-col`}
                md={3} lg={3} sm={6} xs={12} inLineStyles={{ padding: 10 }}>
                <div
                  id={`${parent_id}-Upload-div`}
                  style={{ marginTop: "23px", marginLeft: "15px" }}>
                  {/* <UploadFiles /> */}
                  <Upload
                    id={`${parent_id}-Document-Upload`}
                    label="Upload Document"
                    className="pi-upload-btn"
                    handleChange={(e, value) =>
                      this.setDataUpload("doc", value)
                    }
                    labelColor="#0071F2"
                    iconColor="#0071F2"
                    iconSize={14}
                    imgUrl="images/uploadBlue.svg"
                    labelStyle={{
                      fontSize: 14,
                      fontFamily: "pc_medium !important",
                    }}
                  />
                </div>
              </Col>
            )}
          </div>
        </Card>
        {/* <div>
          <PatientCotactPoint saveto={"patientData"} setTo={"setPatientData"} />
        </div>
        <div>
          <PatientAddress saveto={"patientData"} setTo={"setPatientData"} />
        </div> */}
        <div>
          <PatientCotactPoint
            parent_id={'PatientCotactPoint'}
            allMasters={this.props?.allMasters}
            saveto={"patientData"}
            setTo={"setPatientData"}
            setCustom={this.setCustomContact}
            addNewContact={this.addNewContact}
            ondelete={this.deleteContect}
            contactList={this.state?.contact ?? []}
          />

          <PatientAddress
            parent_id={'PatientAddress'}
            addressList={this.state?.address ?? []}
            setAddress={this.setAddress}
            allMasters={this?.props?.allMasters}
            saveto={"patientData"}
            setTo={"setPatientData"}
          />
        </div>
        <div
          id={`${parent_id}-buttons-div`}
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <Button
            id={`${parent_id}-Cancel-button`}
            variant="text"
            className={"topNav-actions cancelBtn"}
            onClick={() => this.handleClear()}
          >
            Cancel
          </Button>
          <Button
            id={`${parent_id}-Save-button`}
            onClick={() => this.saveAddress()}
            inLineStyles={containedButton}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default withAllContexts(AddNewTraining);
