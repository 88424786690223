import React, { useState } from 'react';
import { Div, H6, Icons, Text, Select, Row, Col, Button, Image, TextInput } from 'qdm-component-library';
import { makeStyles, Popover } from '@material-ui/core'
import MaterialAutoComplete from './materialAutoComplete';
import { UIColor,AlertProps } from '../../../../../../utils';
import { withAllContexts } from '../../../../../../HOCs';
import { InputGroup } from './index';
import { useSelector } from 'react-redux';
const useStyles = makeStyles({
    autocomplete: {
        '& .App1-MuiInputBase-formControl': {
            height: 40,
            backgroundColor: UIColor.lineBorderFill.color,
            borderRadius: 8,
        },
        '& input': {
            fontFamily: 'pc_regular',
            fontSize: 14,
            color: UIColor.secondaryText.color,
            '&::placeholder': {
                fontSize: 14,
                fontFamily: 'pc_regular !important',
            }
        },
        '& .App1-MuiOutlinedInput-notchedOutline': {
            border: 0
        }
    },
    muiPopovers: {
        boxShadow: '0px 4px 24px #00000029',
        borderRadius: 8,
        padding: 10
    },
});

const AdditiveDetails = (props) => {

    const classes = useStyles(props);
    const stateList = useSelector(state => state?.MedicationMastersSlice);
    const styles = {
        flexCenterJustify: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        title: {
            color: UIColor.primaryText.color,
            fontSize: 12,
            margin: 0,
            //fontWeight: 600
        },
        wrapper: {
            border: `1px solid ${UIColor.lineBorderFill.color}`,
            borderRadius: 8,
            padding: 10,
            backgroundColor: UIColor.greyBackground.color
        },
        actionText: {
            fontSize: 12,
            color: UIColor.primaryColor.color,
            margin: 0,
            cursor: 'pointer'
        },
        span: {
            color: UIColor.tertiaryText.color,
            fontSize: 10
        },
        h6: {
            fontSize: 12,
            margin: 0,
            ccolor: UIColor.tertiaryText.color
        },
        flexRow: {
            display: 'flex',
            alignItems: 'center'
        },
        icon: {
            cursor: 'pointer',
            marginRight: 15
        },
        labelStyle: {
            color: UIColor.tertiaryText.color,
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        bulletStyles: {
            width: 4,
            height: 4,
            borderRadius: '50%',
            backgroundColor: UIColor.secondaryText.color,
            marginLeft: 8,
            marginTop: 4
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: UIColor.lineBorderFill.color
        },
        colPadding: {
            padding: "7px"
        },
        flexEnd: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
        },
        // cancelBtn: {
        //     padding: "4px 10px",
        //     fontSize: 14,
        //     borderColor: "#DEDEDE",
        //     borderRadius: 8
        // },
        // filledBtn: {
        //     backgroundColor: "#0271F2",
        //     borderColor: "#0271F2",
        //     padding: "4px 10px",
        //     fontSize: 14,
        //     borderRadius: 8,
        //     marginLeft: 10
        // },
        cancelBtn: {
            padding: "8px",
            fontSize: 14,
            marginRight: 10,
            borderColor: UIColor.lineBorderFill.color,
            color: UIColor.primaryColor.color,
            borderRadius: 8,
        },
        filledBtn: {
            backgroundColor: UIColor.primaryColor.color,
            borderColor: UIColor.primaryColor.color,
            padding: "8px",
            fontSize: 14,
            borderRadius: 8,
        },
        showDetails: {
            border: `1px solid ${UIColor.lineBorderFill.color}`,
            borderRadius: 8,
            padding: '10px 0px',
            backgroundColor: "#fff",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 10
        },
        indexStyle: {
            backgroundColor: UIColor.lineBorderFill.color,
            padding: 10,
            borderRadius: '0px 8px 8px 0px',
            fontSize: 12,
            position: 'relative',
            left: '-1px',
            minHeight: 30
        },
        star: {
            color: UIColor.error.color,
            marginLeft: "5px"
        },
        required: {
            color: UIColor.error.color,
            marginLeft: "5px"
        },
        ...props.styles
    };

    const initialState = {
        description: null,
        dosage: {
            quantity: null,
            unit: null
        },
        strength: null,
        dosageForm: null
    }

    const [state, setState] = useState({
        data: initialState,
        active: null
    })

    const onChangeState = (key, value) => {
        state.data[key] = value;
        if (key === 'description') {
            let dosageUnit = {
                label: value?.StrengthUOM,
                value: value?.StrengthUOM
            }
            state.data = {
                description: value,
                dosage: {
                    quantity: value.StrengthValues,
                    unit: value?.StrengthUOM ? dosageUnit : undefined
                },
                strength: value?.StrengthValues + " " + value?.StrengthUOM,
                dosageForm: value?.formValue
            }
        }
        setState({
            ...state
        })
    }

    const [isErr,setIsErr] = useState(false)
    const save = (type) => {
        let data = props?.additiveDetails ?? [];
        if (!state.data.dosage.quantity || !state.data.dosage.unit) {
            setIsErr(true)
            props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please fill Patient Complaint",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
            })
            return false
        }
        if (state.active || state.active === 0) {
            data[state.active] = state.data;
            state.active = null;
            state.data = initialState;
            setState({
                ...state
            })
            props.actionButton(data);
        } else {
            data.push(state.data)
            state.data = initialState;
            setState({
                ...state
            })
            props.actionButton(data);
        }
        if (!type) setAnchorEl(null); ///only for save and close
    }

    const cancel = () => {
        handleClose();
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        if (index >= 0) {
            state.active = index;
            state.data = props?.additiveDetails[index];
            setState({
                ...state
            })
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        state.active = null;
        setState({
            ...state
        })
    };

    const ondelete = () => {

    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Div style={styles.wrapper}>
            <Div style={{ width: '100%' }}>
                <Div style={styles.flexCenterJustify}>
                    <H6 className="pc_regular" style={styles.title}>{props?.title}</H6>
                    {<H6 className="pc_regular" style={styles.actionText} onClick={(e) => handleClick(e)}>{props?.action}</H6>}
                </Div>
                {
                    props?.additiveDetails && props?.additiveDetails.map((item, index) => {
                        return (
                            <Div style={styles.showDetails}>
                                <Div style={styles.indexStyle}>
                                    {index + 1}
                                </Div>
                                <Div style={{ flex: 1, marginLeft: 15, display: 'flex', alignItems: 'center' }}>
                                    <H6 style={styles.h6} className="pc_medium">
                                        {item?.description?.label}
                                    </H6>
                                    <Text inLineStyles={styles.labelStyle} style={{ marginBottom: 0, marginTop: 2, marginLeft: 8 }}>
                                        {item?.strength}
                                    </Text>
                                    <div style={styles.bulletStyles}>.</div>
                                    <Text inLineStyles={styles.labelStyle} style={{ marginBottom: 0, marginTop: 2, marginLeft: 8 }} >
                                        {item?.dosage?.quantity}{item?.dosage?.unit?.label}
                                    </Text>
                                </Div>
                                <Div style={styles.flexRow}>
                                    <Image onClick={(e) => handleClick(e, index)} style={styles.icon} src="images/icons8-edit1.svg" alt="edit" />
                                    <Image onClick={() => ondelete()} style={styles.icon} src="images/icons8-trash1.svg" alt="delete" />
                                </Div>
                            </Div>
                        )
                    })
                }
            </Div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                classes={{ paper: classes.muiPopovers }}
            >
                <Row style={{ display: 'flex', width: '800px' }}>
                    {/* drug code and description START */}
                    <Col
                        xs={12}
                        sm={12}
                        md={6}
                        lg={8}
                        xl={8}
                        inLineStyles={styles.colPadding}
                    >
                        <MaterialAutoComplete
                            optionsList={stateList?.drugMaster_masters?.data}
                            handleInputChange={onChangeState}
                            value={state?.data?.description}
                            placeholder={"search.."}
                            title={"Drug Code and Description"}
                            keyCode={"description"}
                            isRequired={false}
                        />
                    </Col>
                    {/* drug code and description END */}
                    {/* dosage Range Picker START */}
                    <Col
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        xl={4}
                        inLineStyles={styles.colPadding}
                    >
                        <Text inLineStyles={styles.label}>
                            Dosage{<span style={styles.required}>*</span>}
                        </Text>
                        <InputGroup
                            title="Dosage"
                            isRequired={true}
                            options={stateList?.strengthUom_masters?.data}
                            id="dosage"
                            handleChange={onChangeState}
                            data={state?.data?.dosage}
                            disabled={false}
                            type=""
                            error={isErr && !state.data.dosage.quantity && !state.data.dosage.unit ? true :false}
                        />
                    </Col>
                    {/* dosage Range Picker END */}
                    {/* strength END */}
                    <Col
                        xs={12}
                        sm={12}
                        md={6}
                        lg={3}
                        xl={3}
                        inLineStyles={styles.colPadding}
                    >
                        <TextInput
                            className={"textInput"}
                            id={`mf-stregth`}
                            label={"Strength"}
                            placeholder=""
                            // isRequired
                            labelStyle={styles.labelStyle}
                            style={{ ...styles.borderStyle, ...styles.inputStyle }}
                            value={state?.data?.strength}
                            hoverColor={UIColor.lineBorderFill.color}
                            onChange={(event) => onChangeState("strength", event.target.value)}
                            disabled={true}
                        />
                    </Col>
                    {/* strength END */}
                    {/* dosage form START */}
                    <Col
                        xs={12}
                        sm={12}
                        md={6}
                        lg={3}
                        xl={3}
                        inLineStyles={styles.colPadding}
                    >
                        <TextInput
                            className={"textInput"}
                            id={"mf-dosageFrom"}
                            label={"Dosage Form"}
                            placeholder=""
                            // isRequired
                            labelStyle={styles.labelStyle}
                            style={{ ...styles.borderStyle, ...styles.inputStyle }}
                            value={state?.data?.dosageForm}
                            onChangeValue={(value) => onChangeState("dosageForm", value?.value)}
                            hoverColor={UIColor.lineBorderFill.color}
                            disabled={true}
                        />
                    </Col>
                    {/* dosage form END */}
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: "10px 0px" }}>
                        <Div inLineStyles={styles.flexEnd}>
                            <Button onClick={cancel} variant={"text"} inLineStyles={styles.cancelBtn} >
                                Cancel
                            </Button>
                            {
                                (state?.active || state.active === 0) ?
                                    <Button
                                        variant={"outlined"}
                                        inLineStyles={styles.filledBtn}
                                        onClick={() => save()}
                                    >
                                        Update
                                    </Button>
                                    :
                                    <>
                                        <Button
                                            variant={"outlined"}
                                            inLineStyles={{ ...styles.filledBtn, marginRight: "10px" }}
                                            onClick={() => save(true)}
                                        >
                                            Save & Add Another
                                        </Button>
                                        <Button variant={"outlined"}
                                            inLineStyles={styles.filledBtn}
                                            onClick={() => save()}
                                        >
                                            Save
                                        </Button>
                                    </>
                            }
                        </Div>
                    </Col>
                </Row>
            </Popover>
        </Div>
    )
}
export default withAllContexts(AdditiveDetails);

AdditiveDetails.defaultProps = {
    title: '',
    action: '',
    actionButton: null,
    additiveDetails: {},
    onChangeState: null,
    optionsList: []
}