import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Grid, Paper, Typography, Button, TextField } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
var aes256 = require("aes256");

function Backupdata(props) {
  const dispatch = useDispatch();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {}, [state]);

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"TtegL"}
            container={true}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Paper
              key={"0"}
              id={"Ysfg2"}
              elevation={"1"}
              variant={"elevation"}
              style={qdmstyles.pkbO}
            >
              <Grid
                key={"0"}
                id={"Sqq4d"}
                container={true}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                item={true}
                lg={"12"}
                style={qdmstyles.oKf}
              >
                <Grid
                  key={"0"}
                  id={"kWbw8"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  style={qdmstyles.RTqmn}
                >
                  <Typography
                    key={"0"}
                    id={"WSZqV"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"SCHEDULE LINES"}
                    style={qdmstyles.jaW}
                  ></Typography>
                </Grid>
                <Grid
                  key={"1"}
                  id={"hBm1i"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  style={qdmstyles.ppjR}
                >
                  <Button
                    key={"0"}
                    id={"EhOH3"}
                    variant={"text"}
                    color={"primary"}
                    children={"+ Add New"}
                  ></Button>
                </Grid>
              </Grid>
              <Grid
                key={"1"}
                id={"dHOGr"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                item={true}
                lg={"12"}
                spacing={"1"}
                md={"12"}
                sm={"12"}
                xs={"12"}
                style={qdmstyles.fVYNr}
              >
                <Grid
                  key={"0"}
                  id={"kstWr"}
                  container={true}
                  direction={"column"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  item={true}
                  lg={"1"}
                  md={"1"}
                  sm={"1"}
                  xs={"1"}
                >
                  <Grid
                    key={"0"}
                    id={"nk8iA"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                  >
                    <Typography
                      key={"0"}
                      id={"Y8M1c"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"From Day"}
                      style={qdmstyles.sTyKd}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"nk8iA"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                  >
                    <TextField
                      key={"0"}
                      id={"saQ6c"}
                      label={"name"}
                      placeholder={"name"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      size={"small"}
                    ></TextField>
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"nk8iA"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                  >
                    <TextField
                      key={"0"}
                      id={"saQ6c"}
                      label={"name"}
                      placeholder={"name"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      size={"small"}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid
                  key={"1"}
                  id={"kstWr"}
                  container={true}
                  direction={"column"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  item={true}
                  lg={"1"}
                  md={"1"}
                  sm={"1"}
                  xs={"1"}
                >
                  <Grid
                    key={"0"}
                    id={"nk8iA"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                  >
                    <Typography
                      key={"0"}
                      id={"Y8M1c"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"To Day"}
                      style={qdmstyles.yPS}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"nk8iA"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                  >
                    <TextField
                      key={"0"}
                      id={"saQ6c"}
                      label={"name"}
                      placeholder={"name"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      size={"small"}
                    ></TextField>
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"nk8iA"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                  >
                    <TextField
                      key={"0"}
                      id={"saQ6c"}
                      label={"name"}
                      placeholder={"name"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      size={"small"}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid
                  key={"2"}
                  id={"kstWr"}
                  container={true}
                  direction={"column"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  item={true}
                  lg={"3"}
                  md={"3"}
                  sm={"3"}
                  xs={"3"}
                  style={qdmstyles.frbE}
                >
                  <Grid
                    key={"0"}
                    id={"nk8iA"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                  >
                    <Typography
                      key={"0"}
                      id={"Y8M1c"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Base Frequency"}
                      style={qdmstyles.QIwe}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"nk8iA"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                  >
                    <TextField
                      key={"0"}
                      id={"saQ6c"}
                      label={"name"}
                      placeholder={"name"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      size={"small"}
                      select={true}
                      fullWidth={true}
                    ></TextField>
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"nk8iA"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                  >
                    <TextField
                      key={"0"}
                      id={"saQ6c"}
                      label={"name"}
                      placeholder={"name"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      size={"small"}
                      select={true}
                      fullWidth={true}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid
                  key={"3"}
                  id={"kstWr"}
                  container={true}
                  direction={"column"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  item={true}
                  lg={"5"}
                  md={"5"}
                  sm={"5"}
                  xs={"5"}
                  style={qdmstyles.buSbI}
                >
                  <Grid
                    key={"0"}
                    id={"nk8iA"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                  >
                    <Typography
                      key={"0"}
                      id={"Y8M1c"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Remarks"}
                      style={qdmstyles.ylxlS}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"nk8iA"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                  >
                    <TextField
                      key={"0"}
                      id={"saQ6c"}
                      label={"name"}
                      placeholder={"name"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      size={"small"}
                      fullWidth={true}
                    ></TextField>
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"nk8iA"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                  >
                    <TextField
                      key={"0"}
                      id={"saQ6c"}
                      label={"name"}
                      placeholder={"name"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      size={"small"}
                      fullWidth={true}
                    ></TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Backupdata));
