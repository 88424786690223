import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import { bindHover, bindPopover } from "material-ui-popup-state";
import { StyledPopover } from "../commonComponents";
import { usePopupState } from "material-ui-popup-state/hooks";
import HeightIcon from "@material-ui/icons/Height";

const useStyles = makeStyles((theme) => ({
  rootPapover: {
    background: `${theme.palette.background.table} !important`,
  },
  root: {
    display: "flex",
    alignItems: "center",
    background: `${theme.palette.background.table} !important`,
    borderRadius: 6,
    padding: 10,
    width: "100%",
    "& .item": {
      marginRight: 8,
    },
    "& img": {
      marginBottom: "-2px",
    },
    "& svg": {
      marginBottom: "-7px",
      fontSize: "20px",
    },
    position: "relative",
  },
  len: {
    color: "#EC6A49",
    right: 7,
    position: "absolute",
  },
}));

function Vitals(props) {
  const classes = useStyles(props);
  const more = usePopupState({
    variant: "popover",
    popupId: "directions",
  });

  const { icon, list, activeIndex } = props;

  return (
    <>
      {list?.length > 0 ? (
        <div className={classes.root}>
          <img src={icon} alt="icon" />
          <Divider orientation="vertical" variant="middle" flexItem />
          {list?.map((item, index) => {
            if (activeIndex === index) {
              let HigBp = item?.component?.find((x) => x?.obscode === "HIGHBP");
              let value = item?.component;
              return value?.map((val, i) => {
                return (
                  <Typography noWrap>
                    {val?.obscode === "TEMP" && (
                      <span className="item">
                        <img
                          src={
                            "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316444133"
                          }
                          alt="icon"
                        />
                        &nbsp;
                        {val?.obsvalue} &deg;
                        {val?.abnormalmsg.replaceAll('"', "")}
                      </span>
                    )}
                    {val?.obscode === "RESP" && (
                      <span className="item">
                        <img
                          src={
                            "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316452764"
                          }
                          alt="icon"
                        />
                        &nbsp;{val?.obsvalue + " bpm"}
                      </span>
                    )}
                    {val?.obscode === "SPO2" && (
                      <span className="item">
                        <img
                          src={
                            "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316452926"
                          }
                          alt="icon"
                        />
                        &nbsp;{val?.obsvalue + " %"}
                      </span>
                    )}
                    {val?.obscode === "WEIGHT" && (
                      <span className="item">
                        <img
                          src={
                            "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316453047"
                          }
                          alt="icon"
                        />
                        &nbsp;{val?.obsvalue + " kg"}
                      </span>
                    )}
                  </Typography>
                );
              });
            }
          })}
          &nbsp;
          {
            <span className={classes.len} {...bindHover(more)}>
              {"More"}
            </span>
          }
        </div>
      ) : (
        "No Vitals"
      )}

      <StyledPopover
        renderHoverPopover
        maxWidth={"550px"}
        {...bindPopover(more)}
      >
        <div className={classes.rootPapover}>
          {list?.map((item, index) => {
            let HigBp = item?.component?.find((x) => x?.obscode === "HIGHBP");
            if (activeIndex === index) {
              return (
                <div className={classes.root}>
                  {item?.component?.map((val) => {
                    return (
                      <Typography noWrap>
                        {val?.obscode === "HEIGHT" && (
                          <span className="item">
                            <HeightIcon />
                            &nbsp;{val?.obsvalue + " Cm"}
                          </span>
                        )}
                        {val?.obscode === "BSA" && (
                          <span className="item">
                            <img
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316452764"
                              }
                              alt="icon"
                            />
                            &nbsp;{val?.obsvalue + " BSA"}
                          </span>
                        )}
                        {val?.obscode === "O2S" && (
                          <span className="item">
                            <img
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316452926"
                              }
                              alt="icon"
                            />
                            &nbsp;{val?.obsvalue + " %"}
                          </span>
                        )}
                        {val?.obscode === "LOWBP" && (
                          <span className="item">
                            <img
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316453349"
                              }
                              alt="icon"
                            />
                            &nbsp;
                            {(HigBp?.obsvalue ?? "") +
                              " / " +
                              val?.obsvalue +
                              " mm/Hg"}
                          </span>
                        )}
                        {val?.obscode === "PULSE" && (
                          <span className="item">
                            <img
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316453594"
                              }
                              alt="icon"
                            />
                            &nbsp;{val?.obsvalue + " bpm"}
                          </span>
                        )}
                      </Typography>
                    );
                  })}
                </div>
              );
            }
          })}
        </div>
      </StyledPopover>
    </>
  );
}

export default Vitals;
