import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { useCustomDotStyles2 } from "../drugItem/components/drugCard/drugBrandTable/styles";
import { actions } from "frequencyscreen_v_dbinder";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { makeNameForPract } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    "& .App1-MuiListItem-gutters": {
      paddingTop: "0px",
      paddingBottom: "0px",
      "& .App1-MuiListItemText-multiline": {
        marginTop: "0px",
        marginBottom: "0px",
      },
    },
  },
  inline: {
    display: "inline",
  },
  blue: {
    backgroundColor: "#D5E4FD",
    color: "#2A60BC",
    padding: "4px 8px 4px 8px",
    fontSize: "12px",
    marginLeft: "8px",
  },
  black: {
    color: "#2A3C50",
    fontSize: "12px",
    marginLeft: "8px",
  },
  gray: {
    
    fontSize: "12px",
    marginLeft: "8px",
  },
  highlight: {
    backgroundColor: "#F1F6FC",
    color: "#2A3C50",
    padding: "4px 8px 4px 8px",
    marginTop: "8px",
    fontSize: "12px",
    marginLeft: "8px",
  },
}));

const details = [
  {
    name: "Dr. John",
    job: "verifier",
    details: "consultant On 24-02-2021 10:00",
    content: "Instructions given by doctor in Rx",
  },
  {
    name: "Richards",
    job: "Filler",
    details: "pharmacist On 24-02-2021 10:00",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Raechal",
    job: "checker",
    details: "pharmacist on 24-03-2021 10:30",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
];
function StatusLog({ _key, data }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { READ_STATUS_HISTORY } = actions;

  const historyList = useSelector(
    (state) => state?.phramcyFrameworkApiSlice?.readStatusHistory
  )?.data;

  useEffect(() => {
    if (!_key) return;
    dispatch(READ_STATUS_HISTORY({ key: _key }));
  }, [_key]);

  return (
    <>
      <List className={classes.root}>
        {data?.map((history, i) => {
          return (
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderLeft: i !== data?.length - 1 && "1px dashed grey",
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <Dot style={{ marginTop: "8px" }} />
                      <Typography className={classes.black}>
                        {makeNameForPract(history?.practitioner?.[0]?.name?.[0])}
                      </Typography>
                    </div>
                    <Typography className={classes.blue}>
                      {history?.practitionerRole?.[0]?.display}
                    </Typography>
                  </div>
                }
                secondary={
                  <React.Fragment>
                    <div
                      style={{
                        borderLeft: i !== data?.length - 1 && "1px dashed grey",
                        paddingBottom: "16px",
                      }}
                    >
                      <Typography className={classes.gray}>
                        {`${history?.status?.shortdesc} on ${moment
                          .unix(history?.statusupdatetime)
                          .format("DD-MM-YYYY HH:mm")}`}
                      </Typography>
                      {history?.remarks && (
                        <div>
                          <Typography className={classes.highlight}>
                            {history?.remarks}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
}

export default StatusLog;

const Dot = () => {
  const classes = useCustomDotStyles2();
  return <div className={classes.root}></div>;
};
