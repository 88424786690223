const casbinjs = require("casbin.js");

export const permissionJson = (permission) => {
  let returnJson = {
    read: [],
    write: [],
  };

  permission.forEach((perms) => {
    if (perms?.permsnread) {
      returnJson.read.push(perms.repoName);
    }
    if (perms?.permsnwrite || perms?.permsnupdate || perms?.permsndelete) {
      returnJson.write.push(perms.repoName);
    }
  });
  return returnJson;
};

export const checkWithCasbin = async (role) => {
  let roleData = localStorage.getItem("role_data");

  const accessObj = JSON.parse(atob(roleData))?.[role] || [];
  let permission = permissionJson(accessObj?.permission);
  const authorizer = new casbinjs.Authorizer("manual", permission);
  authorizer.setPermission(permission);

  let data = await new Promise(async (resolve, reject) => {
    let list = casbinList;
    let readList = await Promise.all(
      list.read.map(async (val) => {
        return await new Promise(async (resolve, reject) => {
          let isread = await authorizer.can("read", val);
          resolve({ [val]: isread });
        });
      })
    );
    let readData = [];
    readList.map((val) => {
      let key = Object.keys(val)[0];
      if (val[key]) {
        readData.push(key);
      }
    });

    let writeList = await Promise.all(
      list.write.map(async (val) => {
        return await new Promise(async (resolve, reject) => {
          let isread = await authorizer.can("write", val);
          resolve({ [val]: isread });
        });
      })
    );
    let writeData = [];
    writeList.map((val) => {
      let key = Object.keys(val)[0];
      if (val[key]) {
        writeData.push(key);
      }
    });
    resolve({
      read: readData,
      write: writeData,
    });
  });
  let read = data?.read;
  let write = data?.write;

  return {
    read: read,
    write: write,
  };
};

const casbinList = {
  read: [
    "Medication Order",
    "OP New",
    "IP",
    "Verification",
    "Filling",
    "Checking",
    "Verification",
    "Filling",
    "Checking",
    "VERIFY",
    "DISCONTIUNE",
    "HOLD",
    "FILLED",
    "REVERIFY",
    "HOLD",
    "CHECKED",
    "REVERIFY",
    "HOLD",
    "Dispensing",
    "ISSUE",
    "REVERIFY",
    "HOLD",
    "Dispensing",
    "VERIFY",
    "DISCONTIUNE",
    "HOLD",
    "FILLED",
    "REVERIFY",
    "HOLD",
    "CHECKED",
    "REVERIFY",
    "HOLD",
    "ISSUE",
    "REVERIFY",
    "OP Refill",
    "Verification",
    "Filling",
    "Checking",
    "Dispensing",
    "Completed",
    "DISCONTIUNE",
    "VERIFY",
    "HOLD",
    "FILLED",
    "REVERIFY",
    "HOLD",
    "CHECKED",
    "REVERIFY",
    "HOLD",
    "ISSUE",
    "REVERIFY",
    "HOLD",
    "Cart",
    "Verification",
    "Filling",
    "Checking",
    "Dispensing",
    "Completed",
    "HOLD",
    "VERIFY",
    "DISCONTIUNE",
    "FILLED",
    "HOLD",
    "REVERIFY",
    "HOLD",
    "CHECKED",
    "REVERIFY",
    "HOLD",
    "ISSUE",
    "REVERIFY",
    "DISCONTINUE",
    "REVERIFY",
    "IP Discharge",
    "Verification",
    "Filling",
    "Checking",
    "Dispensing",
    "Completed",
    "VERIFY",
    "DISCONTIUNE",
    "HOLD",
    "REVERIFY",
    "FILLED",
    "HOLD",
    "REVERIFY",
    "CHECKED",
    "HOLD",
    "REVERIFY",
    "ISSUE",
    "HOLD",
    "Day Care",
    "Verification",
    "Filling",
    "Checking",
    "Dispensing",
    "Completed",
    "VERIFY",
    "DISCONTIUNE",
    "HOLD",
    "FILLED",
    "REVERIFY",
    "HOLD",
    "CHECKED",
    "HOLD",
    "REVERIFY",
    "ISSUE",
    "REVERIFY",
    "HOLD",
    "Emergency",
    "Verification",
    "Filling",
    "Checking",
    "Dispensing",
    "Completed",
    "VERIFY",
    "DISCONTIUNE",
    "HOLD",
    "FILLED",
    "HOLD",
    "REVERIFY",
    "CHECKED",
    "HOLD",
    "REVERIFY",
    "ISSUE",
    "HOLD",
    "REVERIFY",
    "Return Medication",
    "Medication to be returned",
    "Rejected",
    "Returned",
    "Pending Approval",
    "RETURN",
    "RAISE FOR APPROVAL",
    "APPROVE",
    "REJECT",
    "CLMM - Frequency",
    "Frequency",
    "Add Frequency",
    "Edit Frequency",
    "CLMM - Pharmacy",
    "Pharmacy",
    "Add Pharmacy",
    "Edit Pharmacy",
    "CLMM - Drug Catalogue Master",
    "Drug Catalogue Master",
    "Add Drug Catalogue Master",
    "Drug Master Alert and Restriction",
    "CLMM - Task and Document",
    "CLMM - Cart",
    "Cart Definition",
    "Add New Cart",
    "Cart Order",
    "Cart Verify",
    "CLMM - Organization",
    "Organization Configuration",
    "Add New Organization",
    "CLMM - Administration",
    "CLMM - User Management",
    "CLMM - Practitioner Master",
    "CLMM - Consult Masters",
    "CLMM - Rule Builder",
    "CLMM - Printer Queue",
    "Rx Management",
    "CLMM - Add New Order",
    "CLMM - Print Prescription",
    "CLMM - Report Viewer",
  ],
  write: [
    "Medication Order",
    "OP New",
    "IP",
    "Verification",
    "Filling",
    "Checking",
    "Verification",
    "Filling",
    "Checking",
    "VERIFY",
    "DISCONTIUNE",
    "HOLD",
    "FILLED",
    "REVERIFY",
    "HOLD",
    "CHECKED",
    "REVERIFY",
    "HOLD",
    "Dispensing",
    "ISSUE",
    "REVERIFY",
    "HOLD",
    "Dispensing",
    "VERIFY",
    "DISCONTIUNE",
    "HOLD",
    "FILLED",
    "REVERIFY",
    "HOLD",
    "CHECKED",
    "REVERIFY",
    "HOLD",
    "ISSUE",
    "REVERIFY",
    "OP Refill",
    "Verification",
    "Filling",
    "Checking",
    "Dispensing",
    "Completed",
    "DISCONTIUNE",
    "VERIFY",
    "HOLD",
    "FILLED",
    "REVERIFY",
    "HOLD",
    "CHECKED",
    "REVERIFY",
    "HOLD",
    "ISSUE",
    "REVERIFY",
    "HOLD",
    "Cart",
    "Verification",
    "Filling",
    "Checking",
    "Dispensing",
    "Completed",
    "HOLD",
    "VERIFY",
    "DISCONTIUNE",
    "FILLED",
    "HOLD",
    "REVERIFY",
    "HOLD",
    "CHECKED",
    "REVERIFY",
    "HOLD",
    "ISSUE",
    "REVERIFY",
    "DISCONTINUE",
    "REVERIFY",
    "IP Discharge",
    "Verification",
    "Filling",
    "Checking",
    "Dispensing",
    "Completed",
    "VERIFY",
    "DISCONTIUNE",
    "HOLD",
    "REVERIFY",
    "FILLED",
    "HOLD",
    "REVERIFY",
    "CHECKED",
    "HOLD",
    "REVERIFY",
    "ISSUE",
    "HOLD",
    "Day Care",
    "Verification",
    "Filling",
    "Checking",
    "Dispensing",
    "Completed",
    "VERIFY",
    "DISCONTIUNE",
    "HOLD",
    "FILLED",
    "REVERIFY",
    "HOLD",
    "CHECKED",
    "HOLD",
    "REVERIFY",
    "ISSUE",
    "REVERIFY",
    "HOLD",
    "Emergency",
    "Verification",
    "Filling",
    "Checking",
    "Dispensing",
    "Completed",
    "VERIFY",
    "DISCONTIUNE",
    "HOLD",
    "FILLED",
    "HOLD",
    "REVERIFY",
    "CHECKED",
    "HOLD",
    "REVERIFY",
    "ISSUE",
    "HOLD",
    "REVERIFY",
    "Return Medication",
    "Medication to be returned",
    "Rejected",
    "Returned",
    "Pending Approval",
    "RETURN",
    "RAISE FOR APPROVAL",
    "APPROVE",
    "REJECT",
    "CLMM - Frequency",
    "Frequency",
    "Add Frequency",
    "Edit Frequency",
    "CLMM - Pharmacy",
    "Pharmacy",
    "Add Pharmacy",
    "Edit Pharmacy",
    "CLMM - Drug Catalogue Master",
    "Drug Catalogue Master",
    "Add Drug Catalogue Master",
    "Drug Master Alert and Restriction",
    "CLMM - Task and Document",
    "CLMM - Cart",
    "Cart Definition",
    "Add New Cart",
    "Cart Order",
    "Cart Verify",
    "CLMM - Organization",
    "Organization Configuration",
    "Add New Organization",
    "CLMM - Administration",
    "CLMM - User Management",
    "CLMM - Practitioner Master",
    "CLMM - Consult Masters",
    "CLMM - Rule Builder",
    "CLMM - Printer Queue",
    "Rx Management",
    "CLMM - Add New Order",
    "CLMM - Print Prescription",
    "CLMM - Report Viewer",
  ],
};
