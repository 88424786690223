import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { IconButton, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: "#DEE5EB !important",
    color: theme.palette.text.primary,
    // color: theme.palette.common.white,
    // border: "none !important",
    background: `${theme.palette.background.common} !important`,
  },
}))(TableCell);

const fontStyleRegular = {
  // color: theme.palette.text.primary,
  // color: "#787E88",
  fontSize: "14px",
  fontWeight: 400,
};
const StyledTableRow = withStyles((theme) => ({
  root: {
    background: `${theme.palette.background.common} !important`,
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    marginTop: 15,
    "& .App1-MuiTableCell-root": {
      ...fontStyleRegular,
      paddingLeft: 20,
      background: `${theme.palette.background.common} !important`,
    },
  },
  autocomplete: {
    border: "none",
    "& .App1-MuiInputBase-input": {
      ...fontStyleRegular,
    },
    "& input": {
      border: "none"
    },
    background: `${theme.palette.background.table} !important`,
    "&:input": {
      color: theme.palette.text.primary,
    }
  },
  main: {
    background: `${theme.palette.background.common} !important`,
  }
}));

export default function ViewTable(props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} style={{ boxShadow: "none" }} className={classes.main}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" style={{ borderTopLeftRadius: 15 }}>
              Ward ID
            </StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <StyledTableCell align="left">Closing Time</StyledTableCell>
            <StyledTableCell align="left" style={{ borderTopRightRadius: 15 }}>
              Updated By
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.main}>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              <Autocomplete
                id="combo-box-demo"
                options={[{ title: "DSU", id: "DSU" }]}
                value={props.selectData?.wardValue ?? {}}
                size="small"
                disabled
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label="" style={{ border: "none" }} variant="standard"
                  />
                )}
                // renderInput={(params) => (
                //   <TextField
                //     {...params}
                //     label=""
                //     variant="standard"
                //     InputProps={{
                //       endAdornment: (
                //         <IconButton style={{ padding: 4 }}>
                //           <KeyboardArrowDownIcon />
                //         </IconButton>
                //       ),
                //       disableUnderline: true,
                //     }}
                //   />
                // )}
                PaperComponent={({ children }) => (
                  <Paper className={classes.dropdown}>{children}</Paper>
                )}
                className={classes.autocomplete}
              />
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
              <Autocomplete
                id="combo-box-demo"
                options={props?.cartReason ?? []}
                value={props?.cartReason?.[0] ?? {}}
                size="small"
                style={{ width: 160 }}
                disabled
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label="" style={{ border: "none" }} variant="standard"
                  />
                )}
                PaperComponent={({ children }) => (
                  <Paper className={classes.dropdown}>{children}</Paper>
                )}
                className={classes.autocomplete}
              />
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
              {moment().format('h:mm')}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
              <Autocomplete
                id="combo-box-demo"
                options={[{ title: "Beatrice Fuller", id: "Beatrice Fuller" }]}
                value={props.selectData?.practitoner ?? {}}
                size="small"
                style={{ width: 160 }}
                getOptionLabel={(option) => option.label}
                disabled
                renderInput={(params) => (
                  <TextField {...params} label="" style={{ border: "none" }} variant="standard"
                  />
                )}
                PaperComponent={({ children }) => (
                  <Paper className={classes.dropdown}>{children}</Paper>
                )}
                className={classes.autocomplete}
              />
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
