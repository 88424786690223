

import * as React from "react";
import PropTypes from "prop-types";

const Hospital = (props) => (

  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg" width="17" height="21.838" viewBox="0 0 20.845 21.838">
    <path
      id="icons8-hospital-3" d="M11.685,2a2.733,2.733,0,0,0-2.73,2.73V5.97H5.73A2.729,2.729,0,0,0,3,8.7V21.108a2.729,2.729,0,0,0,2.73,2.73h5.956a.745.745,0,0,0,.744-.744V19.867a.5.5,0,0,1,.5-.5h.993a.5.5,0,0,1,.5.5v3.226a.745.745,0,0,0,.744.744h5.956a2.729,2.729,0,0,0,2.73-2.73V8.7a2.729,2.729,0,0,0-2.73-2.73H17.889V4.73A2.733,2.733,0,0,0,15.16,2Zm-.248,1.489h3.97a.992.992,0,0,1,.993.993v3.97a.992.992,0,0,1-.993.993h-3.97a.992.992,0,0,1-.993-.993V4.482A.992.992,0,0,1,11.437,3.489Zm1.985.993a.745.745,0,0,0-.744.744v.5h-.5a.744.744,0,0,0,0,1.489h.5v.5a.744.744,0,0,0,1.489,0v-.5h.5a.744.744,0,0,0,0-1.489h-.5v-.5A.745.745,0,0,0,13.423,4.482ZM6.97,11.926h.993a.5.5,0,0,1,.5.5v.993a.5.5,0,0,1-.5.5H6.97a.5.5,0,0,1-.5-.5v-.993A.5.5,0,0,1,6.97,11.926Zm3.97,0h.993a.5.5,0,0,1,.5.5v.993a.5.5,0,0,1-.5.5h-.993a.5.5,0,0,1-.5-.5v-.993A.5.5,0,0,1,10.941,11.926Zm3.97,0H15.9a.5.5,0,0,1,.5.5v.993a.5.5,0,0,1-.5.5h-.993a.5.5,0,0,1-.5-.5v-.993A.5.5,0,0,1,14.911,11.926Zm3.97,0h.993a.5.5,0,0,1,.5.5v.993a.5.5,0,0,1-.5.5h-.993a.5.5,0,0,1-.5-.5v-.993A.5.5,0,0,1,18.882,11.926ZM6.97,14.9h.993a.5.5,0,0,1,.5.5v.993a.5.5,0,0,1-.5.5H6.97a.5.5,0,0,1-.5-.5V15.4A.5.5,0,0,1,6.97,14.9Zm3.97,0h.993a.5.5,0,0,1,.5.5v.993a.5.5,0,0,1-.5.5h-.993a.5.5,0,0,1-.5-.5V15.4A.5.5,0,0,1,10.941,14.9Zm3.97,0H15.9a.5.5,0,0,1,.5.5v.993a.5.5,0,0,1-.5.5h-.993a.5.5,0,0,1-.5-.5V15.4A.5.5,0,0,1,14.911,14.9Zm3.97,0h.993a.5.5,0,0,1,.5.5v.993a.5.5,0,0,1-.5.5h-.993a.5.5,0,0,1-.5-.5V15.4A.5.5,0,0,1,18.882,14.9ZM6.97,17.882h.993a.5.5,0,0,1,.5.5v.993a.5.5,0,0,1-.5.5H6.97a.5.5,0,0,1-.5-.5v-.993A.5.5,0,0,1,6.97,17.882Zm11.911,0h.993a.5.5,0,0,1,.5.5v.993a.5.5,0,0,1-.5.5h-.993a.5.5,0,0,1-.5-.5v-.993A.5.5,0,0,1,18.882,17.882Z" transform="translate(-3 -2)" fill="#8895a5" />
  </svg>
);

export default Hospital;

Hospital.defaultProps = {
  color: "#2a3c50",
};

Hospital.propTypes = {
  color: PropTypes.string,
};
