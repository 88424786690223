import React from "react";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Routes } from "../../router/routes";
import { Grid, Typography, Switch, Box, Divider } from "@material-ui/core";
import { Edit } from "../../assets/edit";
import * as ALLMCORE from "@material-ui/core";
import qdmstyles from "./styles.json";
import Color from "../../themes/default.json";
import "./index.css";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  tabletitle: {
    color: theme.palette.text.primary,
  },
}));

function Tablerow(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    permissionData: null,
    tablerow_afir7: null,
  });

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events
    /// fetch all permission
  }, []);

  const { loading, propsState } = props;
  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            container={true}
            direction={"row"}
            justifyContent={"space-evenly"}
            alignItems={"stretch"}
            alignContent={"stretch"}
            spacing={"1"}
            lg={"12"}
            md={"12"}
            sm={"12"}
            style={qdmstyles.aFir}
          >
            {loading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ALLMCORE.CircularProgress />
              </div>
            ) : (
              <>
                {propsState?.length > 0 ? (
                  propsState?.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid
                          key={"0"}
                          container={false}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"1"}
                          md={"1"}
                          sm={"1"}
                          item={true}
                        >
                          <Typography
                            key={"0"}
                            align={"inherit"}
                            display={"initial"}
                            variant={"body1"}
                            children={index + 1}
                            className={classes.tabletitle}
                            style={qdmstyles.cqrJL}
                          ></Typography>
                        </Grid>
                        <Grid
                          key={"1"}
                          container={false}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"2"}
                          md={"2"}
                          sm={"2"}
                          item={true}
                        >
                          <Typography
                            key={"0"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            className={classes.tabletitle}
                            children={data?.freqcode}
                            style={qdmstyles.cPPi}
                          ></Typography>
                        </Grid>
                        <Grid
                          key={"2"}
                          container={false}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"3"}
                          md={"3"}
                          sm={"3"}
                          item={true}
                        >
                          <Typography
                            key={"0"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            className={classes.tabletitle}
                            children={data?.shortdesc}
                            style={qdmstyles.pkeXM}
                          ></Typography>
                        </Grid>
                        <Grid
                          key={"3"}
                          container={false}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"4"}
                          md={"4"}
                          sm={"4"}
                          item={true}
                          style={qdmstyles.bHMLi}
                        >
                          <Typography
                            key={"0"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            className={classes.tabletitle}
                            variant={"body1"}
                            children={data?.frequencytype?.display}
                            style={qdmstyles.WMcI}
                          ></Typography>
                        </Grid>
                        <Grid
                          key={"4"}
                          container={false}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"1"}
                          md={"1"}
                          sm={"1"}
                          item={true}
                          style={qdmstyles.ymdfy}
                        >
                          {data?.status ? (
                            <div style={qdmstyles.active}>active</div>
                          ) : (
                            <div style={qdmstyles.inactive}>in active </div>
                          )}
                          {/* <Switch
                            key={"0"}
                            // onChange={(event) =>
                            //   props.statusChange(event.target.checked, data)
                            // }
                            disabled
                            checked={data?.status}
                            color={"primary"}
                            name={"switch"}
                            style={qdmstyles.QCHK}
                          ></Switch> */}
                        </Grid>
                        <Grid
                          key={"5"}
                          container={false}
                          direction={"row"}
                          style={{ display: "flex" }}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"1"}
                          md={"1"}
                          sm={"1"}
                          item={true}
                        >
                          <Box
                            key={"0"}
                            onClick={() => {
                              props.history.push({
                                pathname: Routes.editFrequency,
                                state: {
                                  // tablerow_row_key: data._key,
                                  _key: data._key,
                                  prevRoute: Routes.frequency,
                                  isDisabled: data.status,
                                },
                              });
                            }}
                            m={"1"}
                            component={"div"}
                            children={data?.status}
                            style={qdmstyles.lvrRM}
                          >
                            <Typography
                              key={"0"}
                              id={"gfC9T"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={<Edit />}
                              style={qdmstyles.gfCT}
                            ></Typography>
                          </Box>
                          <VisibilityOutlinedIcon
                            style={{
                              marginLeft: "10px",
                              marginTop: "8px",
                              fontSize: "19px",
                            }}
                            onClick={() => {
                              props.history.push({
                                pathname: Routes.editFrequency,
                                state: {
                                  // tablerow_row_key: data._key,
                                  _key: data._key,
                                  prevRoute: Routes.frequency,
                                  isDisabled: false,
                                  isView: true,
                                },
                              });
                            }}
                          />
                        </Grid>

                        {/* <Grid key={"6"}
                          container={false}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"1"}
                          md={"1"}
                          sm={"1"}>
                            <VisibilityIcon/>
                            </Grid> */}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <Typography
                    variant="body1"
                    style={{ textAlign: "center", margin: "10px 0px" }}
                  >
                    No data found
                  </Typography>
                )}
              </>
            )}
          </Grid>
          <Divider key={"1"} id={"U2Xtq"} variant={"fullWidth"}></Divider>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Tablerow));
