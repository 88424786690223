import React from "react";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { InputBase } from "./inputfield";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { StyledChip } from "../commonComponents";
import CloseIcon from "@material-ui/icons/Close";
import { Paper, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .App1-MuiFormControl-root": {
      "& .App1-MuiOutlinedInput-root": {
        borderRadius: "8px",
      },
    },
  },
  removeGutterRight: {
    "&.App1-MuiAutocomplete-hasPopupIcon&.MuiAutocomplete-hasClearIcon": {
      "& .App1-MuiAutocomplete-inputRoot": {
        paddingRight: 30,
      },
    },
  },
  multiAutoComplete: {
    "& .App1-MuiAutocomplete-inputRoot": {
      position: "relative",
      gap: 0,
      "& .App1-MuiInputAdornment-root": {
        position: "absolute",
        right: 2,
      },
    },
  },
  dropdown: {
    background: `${theme.palette.background.table} !important`,
    "&:input": {
      color: theme.palette.text.primary,
    },
  },
}));

export function Autocomplete(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  if (props.multiple) {
    // Multiple Autocomplete Component
    return (
      <MuiAutocomplete
        multiple={props.multiple}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        classes={{
          root: classes.root,
          paper: classes.paper,
        }}
        className={classNames({
          [classes.removeGutterRight]: props?.multiple,
          [classes.multiAutoComplete]: props?.multiple,
        })}
        PaperComponent={({ children }) => (
          <Paper className={classes.dropdown}>{children}</Paper>
        )}
        disabled={props.disabled}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => (
            <StyledChip
              label={
                typeof option?.label === "object" &&
                !Array.isArray(option?.label) &&
                option?.label !== null
                  ? option?.label?.name
                  : option?.label
              }
              deleteIcon={<CloseIcon htmlColor="#6A7888" />}
              {...getTagProps({ index })}
            />
          ));
        }}
        renderInput={(params) => (
          <TextField size="small" {...params} variant="outlined" />
        )}
        size={props?.size ?? "small"}
        {...props}
      />
    );
  } else {
    // Single Autocomplete Component
    return (
      <MuiAutocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        classes={{ root: classes.root, paper: classes.paper }}
        className={classNames({
          [classes.removeGutterRight]: props?.multiple,
          [classes.multiAutoComplete]: props?.multiple,
        })}
        disabled={props.disabled}
        PaperComponent={({ children }) => (
          <Paper className={classes.dropdown}>{children}</Paper>
        )}
        renderInput={(params) => (
          <TextField size={"small"} {...params} variant="outlined" />
        )}
        // renderInput={(params) => {
        //   return (
        //     <InputBase
        //       {...(props?.multiple && params.InputProps)}
        //       ref={params.InputProps.ref}
        //       inputProps={params.inputProps}
        //       disabled={props.disabled}
        //       placeholder={props.placeholder}
        //       required={props.required}
        //       className={classes.dropdown}
        //       endAdornment={
        //         <InputAdornment position="end">
        //           {!props?.disableClearable && (
        //             <IconButton
        //               size="small"
        //               onClick={() => props.onChange({}, null)}
        //               disabled={props.disabled}
        //               style={{
        //                 visibility:
        //                   props?.value && isHovering ? "visible" : "hidden",
        //               }}
        //             >
        //               <CloseIcon
        //                 htmlColor="#6A7888"
        //                 style={{ fontSize: "1rem" }}
        //               />
        //             </IconButton>
        //           )}
        //           <IconButton
        //             size="small"
        //             onClick={handleOpen}
        //             style={{ ...(open && { transform: "rotate(180deg)" }) }}
        //             disabled={props.disabled}
        //           >
        //             <ExpandMoreIcon
        //               htmlColor="#6A7888"
        //               style={{ fontSize: "1.425rem" }}
        //             />
        //           </IconButton>
        //         </InputAdornment>
        //       }
        //     />
        //   );
        // }}
        // {...(!props?.disableClearable && {
        //   onMouseOver: () => setIsHovering(true),
        //   onMouseOut: () => setIsHovering(false),
        // })}
        size={props?.size ?? "small"}
        {...props}
      />
    );
  }
}

export default Autocomplete;

// Todo: Add Clear Functionality for multiple autocomplete
