import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Box, Paper, Typography, Grid, TextField } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
import Color from "../../themes/default.json"
import makeStyles from "@material-ui/core/styles/makeStyles";

var aes256 = require("aes256");


const useStyles = makeStyles((theme) => ({
  editchildtitle: {
    color: theme.palette.primary.light
  },
  editaddress: {
    color: theme.palette.text.primary,
  },
  addresspharmacy: {
    backgroundColor: theme.palette.background.table,
  },
  notchedOutline:{
    borderColor:theme.palette.border.textField
  },
}))

function EditAddressPharmacy(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
    editaddresspharmacy_wdhjk: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const onchange_wdhjk = (e, index) => {
    let updatestate = {};

    setState({
      ...state,
      ...updatestate,
      editaddresspharmacy_wdhjk: e?.target?.value,
    });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = {
      ...stateValue[index],
      editaddresspharmacy_wdhjk: e?.target?.value,
    };
    props.setFun(stateValue);
  };
  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  const { data, index } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Paper
            key={"0"}
            id={"yl1ZB"}
            elevation={"{3}"}
            style={qdmstyles.gvDn}
            onLoad={(e) => props.setFun(props?.statevalue ?? [{}])}
            className={classes.addresspharmacy}
          >
            {(props?.statevalue ?? [{}])?.map((data, index) => {
              return (
                <React.Fragment>
                  <Typography
                    key={"0"}
                    id={"address_header"}
                    align={"inherit"}
                    color={"initial"}
                    className={classes.editaddress}
                    display={"initial"}
                    variant={"body1"}
                    children={t('pharmacy.editNewDrugCatalogMaster.address')}
                    aliasName={"addressheader"}
                    style={qdmstyles.mcW}
                  ></Typography>
                  <Grid
                    key={"1"}
                    id={"Dd1WL"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                  >
                    <Typography
                      key={"0"}
                      id={"location_address_header"}
                      align={"inherit"}
                      color={"initial"}
                      className={classes.editchildtitle}
                      display={"initial"}
                      variant={"body1"}
                      children={
                        <span>
                          Location address
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      aliasName={"locationaddressheader"}
                      style={qdmstyles.QimNE}
                    ></Typography>
                    <TextField
                      key={"1"}
                      onChange={(e) => onchange_wdhjk(e, index)}
                      value={data.editaddresspharmacy_wdhjk || data?.text || ''}
                      id={"location_address_textfield"}
                      label={""}
                      placeholder={"Location address"}
                      inputProps={{
                        maxLength: 100
                      }}
                      InputProps={{ classes: {notchedOutline: classes.notchedOutline}}}
                      disabled={location.state?.isdisabled === "inactive" ? true : false}
                      type={"text"}
                      variant={"outlined"}
                      fullWidth={true}
                      size={"small"}
                      aliasName={"locationaddresstextfield"}
                      margin={"dense"}
                    ></TextField>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Paper>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(EditAddressPharmacy));
