import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import DrugBrandTable from "../drugBrandTable";
import { WarningIcon } from "../../../../../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #DEE5EC",
    borderRadius: 8,
    padding: 12,
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  drugNameContainer: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  drugName: {
    fontSize: 12,
    fontWeight: 400,
    color: "#2A3C50",
  },
  drugUOM: {
    fontSize: 12,
    fontWeight: 400,
  },
  warning: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    padding: "4px 8px",
    borderRadius: "6px",
    backgroundColor: "#F1F6FC",
  },
  warningText: {
    fontSize: 12,
    color: "#2A3C50",
  },
}));

export const useDividerStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#B4BBC3",
  },
  flexItem: {
    height: "18px",
    alignSelf: "center",
  },
}));

function DrugAdditiveCard({ printLabel, additive, ...props }) {
  const classes = useStyles();
  const dividerStyles = useDividerStyles();

  return (
    <div className={classes.root}>
      <div className={classes.rowContainer}>
        <div className={classes.drugNameContainer}>
          <Typography className={classes.drugName}>{additive.name}</Typography>
          <Divider orientation="vertical" flexItem classes={dividerStyles} />
          <Typography className={classes.drugUOM}>
            {additive?.addose}
            {additive?.addoseuomtext}
          </Typography>
        </div>
        <div className={classes.warning}>
          <WarningIcon />
          <Typography className={classes.warningText}>
            {additive.name} for {props?.drug?.name}
          </Typography>
        </div>
      </div>
      <div>
        {additive.brands?.map((brand, index) => (
          <DrugBrandTable
            {...props}
            key={brand?.uniqueId}
            brand={brand}
            //imgId={drug?.drugPhotoId}
            //isAnotherBrandAvailable={index !== drug?.brands?.length - 1}
            batchQuantity={props?.batchQuantity}
            onBatchQuantityChange={(value) =>
              props?.onBatchQuantityChange(value, brand?.id)
            }
            handleChangebrandName={(value) => {
              props.handleChangebrandName(value, index, brand?.id);
            }}
            handleDelete={() => props?.handleDelete(index)}
            handleAdd={() => props?.handleAdd(index)}
            // handlePrint={() => props?.handlePrint(index,"IV")}
            // isAdd={isaddfuncy(drug) && drug?.brands?.length - 1 === index}
            index={index}
            // isIntravenous={isIntravenous}
            printLabel={() => printLabel(brand, index)}
            printEnable={false}
            errorMessage={brand?.StrengthErrmessage}
          />
        ))}
      </div>
    </div>
  );
}

export default DrugAdditiveCard;
