import React from "react";
import { makeStyles } from "@material-ui/core";
import { CommonTabList } from "../../components";
import {
  VitalsMaster,
  AgeRangesMaster,
  Orders,
  Drugs,
  OrderSetMaster,
  AllergenMaster,
  DiagnosisMasterCard,
} from "./component";
import { checkWithCasbin } from "../../utils";
import OrderSetApplicabilityTabel from "./odersetapplicability";
import FlexAttribute from "./component/flexAttr/flexAttribute";
import FlexAttributesApplicableCriteria from "./component/flexAttr/flexAttributesApplicableCriteria";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
}));

const Consultmaster = (props) => {
  const classes = useStyles();
  const [listData, setListData] = React.useState([]);

  const json = {
    ageRangeMaster: {
      name: "Age Ranges",
      component: <AgeRangesMaster />,
    },
    vitalsMaster: {
      name: "Vitals",
      component: <VitalsMaster />,
    },
    OrderMaster: {
      name: "Order",
      component: <Orders />,
    },
    DrugMaster: {
      name: "Drug",
      component: <Drugs />,
    },
    orderSet: {
      name: "Order Set",
      component: <OrderSetMaster />,
    },
    flexAttribute: {
      name: "Flex Attribute",
      component: <FlexAttribute />,
    },
    flexAttributesApplicableCriteria: {
      name: "Flex Attributes Applicable Criteria",
      component: <FlexAttributesApplicableCriteria />,
    },
    allergenMaster: {
      name: "Allergen Master",
      component: <AllergenMaster />,
    },
    diagnosisMaster: {
      name: "Diagnosis Master",
      component: <DiagnosisMasterCard />,
    },
  };

  React.useEffect(() => {
    const baseFun = async () => {
      // let permission = await checkWithCasbin(["consultMaster"]);
      // function onlyUnique(value, index, self) {
      //   return self.indexOf(value) === index;
      // }
      let arr = [];
      // var unique = [...permission.read, ...permission.write].filter(onlyUnique);
      [
        "ageRangeMaster",
        "vitalsMaster",
        "OrderMaster",
        "DrugMaster",
        "orderSet",
        "allergenMaster",
        "diagnosisMaster",
        "flexAttribute",
        "flexAttributesApplicableCriteria",
      ].map((val) => {
        arr.push(json[val]);
      });
      setListData(arr);
    };
    baseFun();
  }, []);

  return (
    <div id={"consult-master-parent-div"} className={classes.root}>
      <CommonTabList
        parent_id={"consult-master"}
        title="Consult Masters"
        list={listData}
      />
    </div>
  );
};

export default Consultmaster;
