import React, { useState } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {
  // AddIcon,
  DollarIcon,
  // DrugPrint,
  EstimateIcon,
  GroceryShelfIcon,
  HoldingHandIcon,
  HoldingHandWithPillIcon,
  InfoIcon,
  NoAccessIcon,
  PillIcon,
  ReturnPolicyIcon,
  // ReplyArrowIcon,
  ScheduleIcon,
  SchoolIcon,
  StackIcon,
  StackOverflowIcon,
  SupplementDoseIcon,
  SupplementTotalDoseIcon,
  TimesheetIcon,
  // TrashIcon,
} from "../../../../../assets";
import { ReactComponent as CollapseIcon } from "../../../../../assets/collapse.svg";
import { ReactComponent as ExpandIcon } from "../../../../../assets/expand.svg";
import {
  useDividerStyles,
  useDrugBatchStyles,
  useDrugParticularsStyles,
  useCustomDotStyles,
  useCustomTooltip,
  useReturnPolicyDialogStyles,
} from "./styles";
import { useDividerStyles2 } from "../styles";
import InputBase from "@material-ui/core/InputBase";
//import TextSelectField from "../textSelectField";
import { getImgUrl } from "../../../../../utils";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  //DialogContentText,
  FormControl,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Tooltip,
  Collapse,
  //Modal,
} from "@material-ui/core";
import PatientEducation from "../patientEducation";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { GlassMagnifier } from "react-image-magnifiers";
//import { Directions } from "@material-ui/icons";
import { Edit } from "../../../../../assets/edit";
import EditInstructions from "../../../../editDirections";
//import { actions } from "frequencyscreen_v_dbinder";
import { useDispatch, useSelector } from "react-redux";
// import { Document, Page } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import PDFViewer from "pdf-viewer-reactjs";

const StripInput = withStyles((theme) => ({
  root: {
    flex: 0,
    gap: 10,
    padding: "4px 8px",
    background: "white",
    borderRadius: "4px",
    border: "1px solid #DEE5EC",
    "& .App1-MuiInputBase-input": {
      minWidth: 20,
    },
  },
  input: {
    padding: 0,
    "&:focus": {
      backgroundColor: "unset",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #DEE5EC",
    borderRadius: 8,
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    "& .App1-MuiInputBase-root": {
      fontSize: "12px",
    },
  },
  selectcomp: {
    "& .App1-MuiInputBase-root": {
      fontSize: "12px",
    },
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function DrugBrandTableForReturnMedication(props) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  // Props
  const { brand = {}, handleChangebrandName } = props;

  const handleExpandCollapseChange = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className={classes.root}>
      <DrugParticulars
        {...props}
        handleChangebrandName={handleChangebrandName}
        isExpanded={isExpanded}
        onExpandCollapseChange={handleExpandCollapseChange}
        isEdit={brand?.batches?.length === 1}
      />
      <Collapse in={isExpanded}>
        {brand.batches &&
          brand.batches.length > 0 &&
          brand.batches.map((batch, _in) => {
            return (
              <DrugBatch
                key={batch?.id}
                batch={batch}
                isEdit={brand?.batches?.length === 1}
                _in={_in}
                {...props}
                onBatchQuantityChange={(e, isMedication) =>
                  props.onBatchQuantityChange(e, _in, isMedication)
                }
              />
            );
          })}
      </Collapse>

      {/* <DrugBatch />
      <DrugBatch /> */}
    </div>
  );
}

export default DrugBrandTableForReturnMedication;

// ----------------------------------------Custom dialog--------------------------------------------

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

// -------------------------------- Table Head - Drug Particulars --------------------------------------------

const DrugParticulars = ({
  drug,
  brand,
  batchQuantity,
  onBatchQuantityChange,
  isAnotherBrandAvailable,
  handleChangebrandName,
  handleAdd,
  isAdd,
  handleDelete = () => null,
  isChecker,
  index,
  selectedState,
  printLabel,
  // Is Intravenous fluid type
  isIntravenous,
  printEnable,
  // Component level props
  isExpanded,
  onExpandCollapseChange = () => null,
  ...props
}) => {
  // Styles
  const classes = useDrugParticularsStyles();
  //const dispatch = useDispatch();
  const binderData = useSelector(
    (state) => state?.phramcyFrameworkApiSlice?.label_print?.data
  );
  const dividerStyles = useDividerStyles();
  const tooltipStyles = useCustomTooltip();
  const returnPolicyDialogStyles = useReturnPolicyDialogStyles();

  // State
  const [returnPolicyOpen, setReturnPolicyOpen] = React.useState(false);
  const [isStripTextfield, setIsStripTextfield] = React.useState(false);
  //const [Brand, setBrand] = React.useState(10);
  const [patientEdu, setPatientEdu] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [directionDialog, setDirectionDialog] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  const [printopen, setPrintOpen] = React.useState(false);
  //const [numPages, setNumPages] = React.useState();
  const [pageNumber, setPageNumber] = React.useState(1);
  const handleChange = (event) => {
    handleChangebrandName(event.target.value);
  };
  // const dropdownMenuProps = {
  //   menuStyle: {
  //     border: "1px solid black",
  //     borderRadius: "5%",
  //     backgroundColor: "lightgrey",
  //     maxWidth: "50px",
  //   },
  // };
  // Dialog
  const handleDialog = (event, drug) => {
    setPatientEdu(true);
    // setOrdervalue(drug?.patientEdu);
  };
  const DialogClose = () => {
    setPatientEdu(false);
  };

  // Directions PopOver
  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Edit Directions Dialog
  const handleEditDirections = () => {
    setAnchorEl(null);
    setDirectionDialog(true);
  };
  const handleEditDirectionsClose = () => {
    setDirectionDialog(false);
  };

  //print popover

  // const handlePrint = () => {
  //   setPrintOpen(true);
  //   printLabel();
  // };

  const handleClose = () => {
    setPrintOpen(false);
  };

  //  const onDocumentLoadSuccess=({numPages})=>{
  //    setNumPages(numPages)
  //  }

  const handleReturnPolicyOpen = () => {
    setReturnPolicyOpen(true);
  };

  const handleReturnPolicyClose = () => {
    setReturnPolicyOpen(false);
  };

  //const data = JSON.stringify(binderData.downloadUrl);
  return (
    <>
      <div className={classes.root}>
        {/* Add a expand/collapse button here */}
        <IconButton
          size="small"
          disableRipple
          onClick={onExpandCollapseChange}
          className={classes.expandCollapseButton}
        >
          {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
        </IconButton>

        {selectedState === "verification" ? (
          <FormControl className={classes.formControl}>
            <Select
              disableUnderline
              className={classes.selectcomp}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={brand?.name}
              // MenuProps={{
              //   anchorOrigin: {
              //     vertical: "bottom",
              //     horizontal: "left",
              //   },
              //   transformOrigin: {
              //     vertical: "top",
              //     horizontal: "left",
              //   },
              //   getContentAnchorEl: null,
              // }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                classes: { paper: classes.select },
              }}
              onChange={handleChange}
              InputLabelProps={{ style: { fontSize: 12 } }}
              inputProps={{ style: { fontSize: 12 } }}
              // MenuProps={{ classes: { paper: classes.select } }}
            >
              {brand?.option?.map((val) => {
                return <MenuItem value={val.drugName}>{val.drugName}</MenuItem>;
              })}
            </Select>
          </FormControl>
        ) : (
          <Tooltip title={brand?.name}>
            <Typography noWrap className={classes.headerText}>
              {brand?.name}
            </Typography>
          </Tooltip>
        )}
        <Dot />
        <Typography className={classes.defaultText}>
          {(brand?.allStrength || [])?.map((v, i) => {
            return (
              <>
                {v}
                {i !== brand?.allStrength?.length - 1 ? `/ ` : ``}
              </>
            );
          })}
          {brand?.strength}
        </Typography>
        <Dot />
        {brand?.totadmqty > 0 && (
          <>
            <Tooltip title="Rx Dose" arrow classes={tooltipStyles}>
              <div className={classes.doseDetailItem}>
                <SupplementDoseIcon />
                <Typography className={classes.defaultText}>
                  {brand?.totadmqty} doses
                </Typography>
              </div>
            </Tooltip>

            <Dot />
          </>
        )}
        {brand?.tabPerDose > 0 && (
          <>
            <Tooltip
              title="Administrative Dose Quantity"
              arrow
              classes={tooltipStyles}
            >
              <div className={classes.doseDetailItem}>
                <PillIcon />
                <Typography className={classes.defaultText}>
                  {props?.disptype === "IV-LV"
                    ? brand?.strength + " "
                    : brand?.perdoseadminqty + " " + brand?.totadmuom + " "}
                  per dose
                </Typography>
              </div>
            </Tooltip>

            <Dot />
          </>
        )}
        {brand?.actualdoseCount > 0 && (
          <>
            <Tooltip
              title="Required Dose Quantity"
              arrow
              classes={tooltipStyles}
            >
              <div className={classes.doseDetailItem}>
                <SupplementTotalDoseIcon />
                <Typography className={classes.defaultText}>
                  {brand?.totadmqty} {brand?.totadmuom}
                </Typography>
              </div>
            </Tooltip>

            <Dot />
          </>
        )}
        {isIntravenous && brand?.rateqty && (
          <>
            <div className={classes.doseDetailItem}>
              <Typography className={classes.defaultText}>
                {/* {"250CC/Hr for 2 hrs"} */}
                {brand?.rateqty} {brand?.rateuom}
              </Typography>
            </div>
            <Dot />
          </>
        )}
        {isIntravenous && brand?.totstkqty && (
          <>
            <Tooltip title="Total Quantity" arrow classes={tooltipStyles}>
              <div className={classes.doseDetailItem}>
                <HoldingHandWithPillIcon />
                <Typography className={classes.defaultText}>
                  {brand?.totstkqty}
                  {brand?.totstkuom}
                </Typography>
              </div>
            </Tooltip>
            <Dot />
          </>
        )}

        <Tooltip title="Issue Quantity" arrow classes={tooltipStyles}>
          <div
            className={classNames(
              classes.doseDetailItem,
              classes.stripQuantity
            )}
            onClick={(e) => {
              setIsStripTextfield((prev) => !prev);
            }}
          >
            <HoldingHandIcon style={{ minWidth: "17.705px" }} />
            {isStripTextfield ? (
              <>
                <StripInput
                  autoFocus
                  id={"stripInput"}
                  placeholder="00"
                  type={"text"}
                  //value={batchQuantity}
                  value={brand?.totstkqty}
                  disabled
                  onChange={(e) => {
                    onBatchQuantityChange(e.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  endAdornment={
                    <Typography className={classes.stripFielAdornText}>
                      {brand?.totstkuom}
                    </Typography>
                  }
                  inputProps={{ "aria-label": "Input field" }}
                />
                {/* <Typography
                  style={{ display: "inline" }}
                  className={classes.defaultText}
                >
                  ( {brand?.totadmqty} {brand?.totadmuom})
                </Typography> */}
              </>
            ) : (
              <Typography className={classes.defaultText}>
                {brand?.totstkqty}
                {brand?.totstkuom}
                {/* ( {brand?.totadmqty} {brand?.totadmuom}) */}
              </Typography>
            )}
          </div>
        </Tooltip>

        <Dot />

        <Tooltip title="Bill Quantity" arrow classes={tooltipStyles}>
          <div className={classes.doseDetailItem}>
            <EstimateIcon />
            <Typography className={classes.defaultText}>
              {brand?.totbillqty} {brand?.totbilluom}
              {/* ( {brand?.totadmqty}
              {brand?.totadmuom}) */}
            </Typography>
          </div>
        </Tooltip>
        <Dot />
        <div
          style={{ cursor: "pointer", display: "flex" }}
          onMouseOver={handleClickPopover}
        >
          <TimesheetIcon />
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "12px 12px 4px",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <TimesheetIcon />
                <Typography
                  style={{
                    color: "#001C3C",
                    fontSize: "12px",
                    marginLeft: "6px",
                  }}
                >
                  Directions
                </Typography>
              </div>
              {selectedState === "verification" && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handleEditDirections}
                >
                  <Edit width={"14"} height={"14"} />
                </div>
              )}
            </div>
            <div style={{ padding: "12px" }}>
              {drug?.PH_OrderLineDispenseSchedule?.length > 0
                ? drug?.PH_OrderLineDispenseSchedule?.[0]?.dispensedetails?.[
                    index
                  ]?.direction
                : brand?.pH_OrderLineDispenseSchedule?.dispensedetails?.[index]
                    ?.direction && (
                    <div
                      style={{
                        backgroundColor: "#F1F6FC",
                        borderRadius: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#2A3C50",
                          padding: "8px 12px",
                        }}
                      >
                        {drug?.PH_OrderLineDispenseSchedule?.length > 0 ? (
                          // <div
                          //   dangerouslySetInnerHTML={{
                          //     __html:
                          //       drug?.PH_OrderLineDispenseSchedule?.[0]
                          //         ?.dispensedetails?.[index]?.direction,
                          //   }}
                          // />
                          drug?.PH_OrderLineDispenseSchedule?.[0]
                          ?.dispensedetails?.[index]?.direction
                        ) : (
                          // <div
                          //   dangerouslySetInnerHTML={{
                          //     __html:
                          //       brand?.pH_OrderLineDispenseSchedule
                          //         ?.dispensedetails?.[index]?.direction,
                          //   }}
                          // />
                          brand?.pH_OrderLineDispenseSchedule
                          ?.dispensedetails?.[index]?.direction
                        )}
                      </Typography>
                    </div>
                  )}
              {drug?.PH_OrderLineDispenseSchedule?.length > 0
                ? drug?.PH_OrderLineDispenseSchedule?.[0]?.dispensedetails?.[
                    index
                  ]?.nldirection
                : brand?.pH_OrderLineDispenseSchedule?.dispensedetails?.[index]
                    ?.nldirection && (
                    <div
                      style={{
                        backgroundColor: "#F1F6FC",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#2A3C50",
                          padding: "8px 12px",
                        }}
                      >
                        {drug?.PH_OrderLineDispenseSchedule?.length > 0 ? (
                          // <div
                          //   dangerouslySetInnerHTML={{
                          //     __html:
                          //       drug?.PH_OrderLineDispenseSchedule?.[0]
                          //         ?.dispensedetails?.[index]?.nldirection,
                          //   }}
                          // />
                          drug?.PH_OrderLineDispenseSchedule?.[0]
                                  ?.dispensedetails?.[index]?.nldirection
                        ) : (
                          // <div
                          //   dangerouslySetInnerHTML={{
                          //     __html:
                          //       brand?.pH_OrderLineDispenseSchedule
                          //         ?.dispensedetails?.[index]?.nldirection,
                          //   }}
                          // />
                          brand?.pH_OrderLineDispenseSchedule
                          ?.dispensedetails?.[index]?.nldirection
                        )}
                      </Typography>
                    </div>
                  )}
            </div>
          </>
        </Popover>
        <EditInstructions
          directionDialog={directionDialog}
          handleEditDirectionsClose={handleEditDirectionsClose}
          directionDescripion={
            drug?.PH_OrderLineDispenseSchedule?.length > 0
              ? drug?.PH_OrderLineDispenseSchedule?.[0]?.dispensedetails?.[
                  index
                ]?.direction
              : brand?.pH_OrderLineDispenseSchedule?.dispensedetails?.[index]
                  ?.direction
          }
          directionLocalLanguage={
            drug?.PH_OrderLineDispenseSchedule?.length > 0
              ? drug?.PH_OrderLineDispenseSchedule?.[0]?.dispensedetails?.[
                  index
                ]?.nldirection
              : brand?.pH_OrderLineDispenseSchedule?.dispensedetails?.[index]
                  ?.nldirection
          }
          handleDirectionSaveClick={(e) => props.handleDirectionSaveClick(e)}
          // changeDrugDirection={props.changeDrugDirection}
        />

        {isChecker && (
          <>
            <Dot />
            <div onClick={handleDialog} style={{ marginTop: "4px" }}>
              <SchoolIcon />
            </div>
            <div style={{ flex: 1 }} />
            <div className={classes.doseDetailItem}>
              <Typography className={classes.defaultText}>sub-Total</Typography>
              <Typography className={classes.amount}>
                <span style={{ marginRight: "5px" }}>$</span>475.00
              </Typography>
            </div>
          </>
        )}
        <div
          className={classes.returnPolicyIconContainer}
          onClick={handleReturnPolicyOpen}
        >
          <ReturnPolicyIcon />
        </div>

        <Dialog
          onClose={handleReturnPolicyClose}
          aria-labelledby="return-policy-dialog"
          open={returnPolicyOpen}
          classes={returnPolicyDialogStyles}
        >
          <MuiDialogTitle
            disableTypography
            id="customized-dialog-title"
            onClose={handleReturnPolicyClose}
            className={classes.returnPolicyDialogTitle}
          >
            <div className={classes.returnPolicyDialogTitleText}>
              <ReturnPolicyIcon color="#2A3C50" />
              <Typography className={classes.returnPolicyTitleText}>
                RETURN GUIDELINES/ POLICY
              </Typography>
            </div>
            <IconButton
              aria-label="close"
              className={classes.returnPolicyDialogCloseButton}
              size="small"
              onClick={handleReturnPolicyClose}
            >
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent dividers>
            {/* <div dangerouslySetInnerHTML={}></div> */}
            {/* <div dangerouslySetInnerHTML={{ __html: "<div>Hello</div>" }} /> */}
            <div>{props?.returnPolicy ?? "NO RETURN GUIDELINES/ POLICY"}</div>
          </DialogContent>
        </Dialog>
        <Divider orientation="vertical" flexItem classes={dividerStyles} />
        <div className={classes.actionContainer}>
          <div className={classes.returnFieldContainer}>
            <Typography className={classes.returnFieldLabel}>
              {props.issueMedication ? "ReIssue" : "Return"}
            </Typography>
            <div
              className={classes.returnField}
              style={{
                padding: 6,
                borderRadius: 4,
              }}
            >
              {props.isEdit && props.isReturnMedicationEnable ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <StripInput
                    style={{ width: "40px" }}
                    value={brand?.totstkqty}
                    onChange={(e) =>
                      props.onBatchQuantityChangeDrug(e.target.value, true)
                    }
                  />
                  &nbsp;
                  {brand?.totstkuom}
                </div>
              ) : (
                <Typography className={classes.defaultText}>
                  {brand?.totstkqty}&nbsp;
                  {brand?.totstkuom}
                </Typography>
              )}

              {/* <StripInput
                style={{ width: "40px" }}
                value={batch?.chargeqty}
                onChange={(e) => onBatchQuantityChange(e.target.value)}
              />
              {drug?.returnuom} */}
              {/* {/* <TextSelectField
                disabled={false}
                inputValue={""}
                onInputValueChange={(e) => {}}
                selectValue={null}
                onSelectValueChange={(e) => {}}
                options={[]} */}
              {/* /> */}
            </div>
          </div>

          {/* <div className={classes.replyIconContainer} onClick={() => {}}>
            <ReplyArrowIcon />
          </div> */}
        </div>
      </div>
      <Dialog
        fullWidth
        // maxWidth="sm"
        classes={{ paper: classes.dialogPaper }}
        open={patientEdu}
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="customized-dialog-title" onClose={DialogClose}>
          <div style={{ fontSize: "14px", fontWeight: 500 }}>
            PATIENT EDUCATION
          </div>
        </DialogTitle>
        <DialogContent>
          <PatientEducation />
          {/* <patientEdu patientEdu={orderValue} /> */}
        </DialogContent>
      </Dialog>

      {/* //LabelPrint........................... */}
      <Dialog
        open={printopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.pdfDialogPaper }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Label Print Preview"}
        </DialogTitle>
        <DialogContent>
          {/* <div>{binderData.downloadUrl}</div> */}
          <Document file={binderData.downloadUrl}>
            <Page pageNumber={pageNumber} />
          </Document>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DrugParticulars.propTypes = {
  batchQuantity: PropTypes.string,
  onBatchQuantityChange: PropTypes.func,
};

DrugParticulars.defaultProps = {
  batchQuantity: "",
  onBatchQuantityChange: () => {},
};

// End of Drug Particulars

// -------------------------------- Table Body - Drug Batch --------------------------------------------

const DrugBatch = ({
  batch,
  batchQuantity,
  onBatchQuantityChange,
  isChecker,
  imgId,
  selectedState,
  ...props
}) => {
  //const theme = useTheme();
  // Styles
  const classes = useDrugBatchStyles();
  const dividerStyles = useDividerStyles();
  const dividerstyles2 = useDividerStyles2();
  const [breakdown, setBreakdown] = React.useState(null);
  const [state, setState] = useState({
    allowOverflow: true,
    magnifierBorderSize: 5,
    magnifierBorderColor: "rgba(255, 255, 255, .5)",
    magnifierSize: "25%",
    square: false,
  });

  const [anchorElImage, setAnchorElImage] = React.useState(null);

  const handleZoomImage = (event) => {
    setAnchorElImage(event.currentTarget);
  };

  const handleZoomImageClose = () => {
    setAnchorElImage(null);
  };

  const openImager = Boolean(anchorElImage);

  const open = Boolean(breakdown);
  const handleBreakdown = (event) => {
    //
    setBreakdown(event.currentTarget);
  };
  const BreakdownClose = () => {
    setBreakdown(null);
  };

  const getRemainig = (total, issue) => {
    return total - issue;
  };
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <div className={classes.root}>
        <div className={classes.batchIdExpiry}>
          <Tooltip title={batch?.id}>
            <Typography noWrap className={classes.batchId}>
              {batch?.id}
            </Typography>
          </Tooltip>
          <Dot />
          <div className={classes.expiry}>
            <ScheduleIcon color="#2A3C50" />
            <Typography className={classes.defaultText}>
              {moment(batch?.expiryDate).format("DD MMM, YYYY")}
            </Typography>
          </div>
        </div>
        <Divider orientation="vertical" flexItem classes={dividerStyles} />
        <div className={classes.BinLocation}>
          <div className={classes.binNumber}>
            <Tooltip
              title={
                <>
                  BIN&nbsp;<span>{batch?.bin?.number}</span>
                </>
              }
            >
              <Typography
                // style={{ width: "30px" }}
                noWrap
              >
                &nbsp;<span>{batch?.bin?.number}</span>
              </Typography>
            </Tooltip>
          </div>

          <GroceryShelfIcon />

          <Typography className={classes.aisleShelfLevel}>
            {`${batch?.bin?.aisle} `}
            {/* - ${batch?.bin?.shelf} - ${batch?.bin?.level} */}
          </Typography>
        </div>
        <Divider orientation="vertical" flexItem classes={dividerStyles} />
        <div className={classes.stockBlockedAmount}>
          <div className={classes.stockDetailItem}>
            <StackOverflowIcon />
            <Typography className={classes.defaultText}>
              {batch?.availableQuantity}&nbsp;{batch?.availableQuantityUOM}
            </Typography>
          </div>
          <Dot />
          <div className={classes.stockDetailItem}>
            <NoAccessIcon />
            <Typography className={classes.defaultText}>
              {batch?.heldQuantity}
            </Typography>
          </div>
          <Dot />
          <div className={classes.stockDetailItem}>
            <DollarIcon />
            <Typography className={classes.defaultText}>
              {batch?.totalAmount}
            </Typography>
          </div>
          <Dot />
          {selectedState !== "verification" && (
            <div
              aria-owns={openImager ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              className={classes.stockDetailItem}
              onMouseOver={handleZoomImage}
            >
              <div
                style={{
                  border: "1px solid #DEE5EC",
                  borderRadius: "4px",
                  padding: "2px",
                }}
              >
                <img src={getImgUrl(imgId)} width="20px" height={"18px"} />
              </div>
            </div>
          )}

          {/* Popover For Zoom Image */}
          <Popover
            id="mouse-over-popover-image"
            className={classes.popover}
            classes={{
              paper: classes.paperImage,
            }}
            open={openImager}
            anchorEl={anchorElImage}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handleZoomImageClose}
            disableRestoreFocus
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <GlassMagnifier
                className="input-position"
                imageSrc={getImgUrl(imgId)}
                largeImageSrc={getImgUrl(imgId)}
                style={{ width: "330px", height: "100%" }}
                allowOverflow={state.allowOverflow}
                magnifierSize={state.magnifierSize}
                magnifierBorderSize={state.magnifierBorderSize}
                magnifierBorderColor={state.magnifierBorderColor}
                square={state.square}
              />
              <div></div>
            </div>
          </Popover>
        </div>
        <div style={{ flex: 1 }} />

        <Divider
          orientation="vertical"
          flexItem
          classes={dividerStyles}
          style={{ marginRight: 4 }}
        />
        <div className={classes.stripQuantityContainer}>
          <div className={classes.doseInputLabelContainer}>
            <Typography>Qty :&nbsp;</Typography>
            {props.issueMedication ||
            (props.isReturnMedicationEnable && !props.isEdit) ? (
              <StripInput
                style={{ width: "40px" }}
                value={
                  props.issueMedication ? batch?.chargeqty : batch?.returnqty
                }
                onChange={(e) =>
                  props.issueMedication
                    ? props.onBatchQuantityChangeDrugBatchIssuMedication(
                        e.target.value,
                        props._in
                      )
                    : (e) => onBatchQuantityChange(e.target.value, true)
                }
              />
            ) : (
              batch?.returnqty
            )}
            &nbsp;
            {props.issueMedication ? batch?.chargeuom : batch?.returnuom}
          </div>
          <div className={classes.addedDrugContainer}>
            <StackIcon />
            <Typography>
              {getRemainig(batch?.stockqty, batch?.blockqty)}
            </Typography>
          </div>
        </div>

        {/* ------------------Disp Breakdown--------------------------- */}
        {isChecker && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              classes={dividerStyles}
              style={{ marginRight: 4 }}
            />
            <div className={classes.stripQuantityContainer}>
              <div className={classes.aliign}>
                {/* <Typography>Qty :&nbsp;</Typography> */}
                <EstimateIcon />
                <Typography className={classes.defaultText}>15</Typography>
              </div>
              <Dot />
              <div className={classes.doseDetailItem}>
                <div style={{ display: "flex" }} onMouseOver={handleBreakdown}>
                  <InfoIcon style={{ marginBottom: "3px" }} />
                  <Typography
                    className={classes.defaultText2}
                    style={{ marginLeft: "5px" }}
                  >
                    Breakdown{" "}
                  </Typography>
                </div>
              </div>
            </div>
            <Divider
              orientation="vertical"
              flexItem
              classes={dividerStyles}
              style={{ marginRight: 4 }}
            />
            <Typography
              className={classes.defaultText2}
              style={{ marginLeft: "8px" }}
            >
              <span style={{ fontSize: "14px", fontWeight: 500 }}>$</span>{" "}
              475.00
            </Typography>
          </>
        )}
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={breakdown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={BreakdownClose}
        disableRestoreFocus
      >
        <div style={{ marginTop: "5px", padding: "10px", width: "157px" }}>
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: "3px" }}>
              <InfoIcon />
            </div>
            <Typography className={classes.bold}>Price brakdown</Typography>
          </div>

          <Divider
            orientation="horizontal"
            flexItem
            classes={dividerstyles2}
            style={{ height: "1px !important" }}
          />
          <div style={{ marginTop: "5px" }}>
            {/* <Typography
              className={classes.info}
              style={{ paddingTop: "8px", paddingBottom: "5px" }}
            >
              self pay 
            </Typography> */}

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Typography className={classes.boldtext}>self pay</Typography>
              </div>
              <div style={{ marginLeft: "5px" }}>
                <Typography className={classes.greytext}>$125.00</Typography>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Typography className={classes.boldtext}>
                  corporate pay
                </Typography>
              </div>
              <div style={{ marginLeft: "5px" }}>
                <Typography className={classes.greytext}>$350.00</Typography>
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};

// End of Drug Batch

// -------------------------------- Dot ----------------------------------------------------------------

const Dot = () => {
  const classes = useCustomDotStyles();
  return <div className={classes.root}></div>;
};

// End of Dot
