/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc withAllContexts HOC will send all the necessary Context such as AlertContext,
 * DialogContext, BackdropContext and DrawerContext as a prop.
 */

import React from "react";
import {
  AlertContext,
  //DialogContext,
  BackdropContext,
  DrawerContext,
  MedicationContext,
  ThemeContext,
  PatientRegContext,
  DateTimeContext,
  LoggerContext,
} from "../contexts";

const withAllContexts = (Component) => (props) => {
  const alert = React.useContext(AlertContext);
  //const dialog = React.useContext(DialogContext);
  const backDrop = React.useContext(BackdropContext);
  const drawer = React.useContext(DrawerContext);
  const medicationsData = React.useContext(MedicationContext);
  const themes = React.useContext(ThemeContext);
  const patientData = React.useContext(PatientRegContext);
  const DateTime = React.useContext(DateTimeContext);
  const logger = React.useContext(LoggerContext);

  return (
    <Component
      {...props}
      alert={alert}
      themes={themes}
      patientData={patientData}
      medicationsData={medicationsData}
      // dialog={dialog}
      backDrop={backDrop}
      DateTime={DateTime}
      drawer={drawer}
      // queryParams={queryParams}
      logger={logger}
    >
      {props.children}
    </Component>
  );
};

export default withAllContexts;
