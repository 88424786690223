import React from "react";
import { Div, Paper, H6, Text } from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
import { AlertProps } from "../../../utils";
import PersonAddAddress from "./addAdress";
import ShowList from "./showList";
import { CheckCircleOutline, ExpandLess, ExpandMore } from "@material-ui/icons";

class PersonAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Showfilter: this.props?.[this.props.saveto]?.names,
      limited: false,
      Collapse: false,
      addCollapseForm: false,
      contact: [{}],
    };
  }

  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
  };

  validateCheck = (data) => {
    if (
      data?.addressLine1?.length > 0 &&
      data?.use?.label?.length > 0 &&
      data?.type?.label?.length > 0 &&
      data?.city?.title?.length > 0 &&
      data?.country?.title?.length > 0 &&
      data?.district?.title?.length > 0 &&
      data?.pincode?.title?.length > 0 &&
      data?.state?.title?.length > 0
    ) {
      return false;
    } else {
      let errors = {
        addressLine1: data?.addressLine1?.length > 0 ? false : true,
        use: data?.use?.label?.length > 0 ? false : true,
        type: data?.type?.label?.length > 0 ? false : true,
        city: data?.city?.title?.length > 0 ? false : true,
        country: data?.country?.title?.length > 0 ? false : true,
        district: data?.district?.title?.length > 0 ? false : true,
        pincode: data?.pincode?.title?.length > 0 ? false : true,
        state: data?.state?.title?.length > 0 ? false : true,
      };
      this.setState({ ...this.state, erorr: errors });
      return true;
    }
  };

  saveAddress = (data) => {
    if (this.validateCheck(data)) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill address mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }

    // if(this.props?.setAddress){
    let setdata = this.props?.addressList;
    if (this.state?.editindex !== null) {
      setdata[this.state?.editindex] = data;
    } else {
      setdata.push(data);
    }
    this.props.setAddress(setdata);
    this.Clear();
    return false;
    // }

    // this.Clear();
  };

  Clear = () => {
    this.setState({
      select_role: null,
      speciality: null,
      select_entity: null,
      entity_name: null,
      select_location: null,
      start_date: null,
      end_date: null,
      addCollapseForm: false,
      contact: [{}],
    });
  };

  OpenAddress = async (e) => {
    this.setState({
      addAddress: e?.currentTarget ?? null,
    });
  };
  handleClear = () => {
    this.setState({
      editadd: {},
      editindex: null,
      addCollapseForm: false,
    });
  };

  Collapse(e) {
    this.setState({
      Collapse: !this.state.Collapse,
    });
  }

  addCollapse(e) {
    this.setState({
      addCollapseForm: !this.state.addCollapseForm,
      Collapse: true,
    });
  }

  onedit = async (v, i, e) => {
    this.setState({
      ...this.state,
      ...v,
      editadd: { ...v },
      editindex: i,
    });
    this.addCollapse(e);
  };

  ondelete = (v, i) => {
    // if(this.props?.setAddress){
    let setdata = this.props?.addressList;
    setdata.splice(i, 1);
    this.props.setAddress(setdata);
    this.Clear();
    // }else{
    //   let patientData = this.props[this.props.saveto];
    //   patientData.address.splice(i, 1);
    //   patientData[this.props.setTo]({
    //     ...patientData,
    //   });
    //   this.Clear();
    // }
  };

  render() {
    let { addCollapseForm, Collapse } = this.state;
    // const { address } = this.props[this.props.saveto];
    let address = [];
    if (this.props?.addressList) {
      address = this.props?.addressList;
    } else {
      address = { address: "" };
    }

    return (
      <Div className="pi-root">
        <Paper className="pi-paper">
          <Div style={{ display: "flex", alignItems: "center" }}>
            <H6
              className="pi-title"
              style={{
                display: "flex",
                alignItems: "center",
                margin: 0,
                flex: 1,
              }}
            >
              Address
              {address?.length > 0 && (
                <>
                  {" (" + JSON.stringify(address?.length) + ")"}
                  {/* <Image
                  style={{ width: 16, height: 16, marginLeft: 5 }}
                  src="images/icons8-ok.svg"
                  alt="tick"
                /> */}
                  <CheckCircleOutline
                    style={{
                      width: 16,
                      height: 16,
                      marginLeft: 5,
                      borderRadius: "16px",
                      color: "white",
                      backgroundColor: "#38C20A",
                    }}
                  />
                </>
              )}
              {/* )} */}
            </H6>
            <Text
              inLineStyles={{
                color: "#0071F2",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
              }}
              className="pi-addbtn"
            >
              <Text
                className="pi-addbtn"
                style={{ marginRight: 10 }}
                onClick={(e) => {
                  this.handleClear();
                  this.addCollapse(e);
                }}
              >
                + Add New
              </Text>
              {address?.length > 0 && (
                <React.Fragment>
                  {Collapse ? (
                    //  <i onClick={(e) => this.addCollapse(e)} className="fa fa-angle-down ad-arrow" aria-hidden="true" /> : <i className="fa fa-angle-up ad-arrow" aria-hidden="true" onClick={(e) => this.addCollapse(e)} />}
                    // <Image
                    //   src="images/Group 3873.svg"
                    //   style={{ width: 20, height: 20 }}
                    //   alt="downArrow"
                    //   onClick={(e) => this.Collapse(e)}
                    // />
                    <ExpandMore
                      style={{ width: 20, height: 20 }}
                      alt="downArrow"
                      onClick={(e) => this.Collapse(e)}
                    />
                  ) : (
                    <>
                      {/* <Image
                      style={{ width: 20, height: 20 }}
                      src="images/Group -1.svg"
                      alt="upArrow"
                      onClick={(e) => this.Collapse(e)}
                    /> */}
                      <ExpandLess
                        alt="upArrow"
                        onClick={(e) => this.Collapse(e)}
                        style={{ width: 20, height: 20 }}
                      />
                    </>
                  )}
                </React.Fragment>
              )}
            </Text>
          </Div>
          {Collapse && address?.length > 0 && (
            <Div style={{ paddingTop: "10px" }}>
              {address?.map((val, i) => {
                return (
                  <div style={{ marginBottom: 12 }}>
                    <ShowList
                      index={i + 1}
                      title={val?.type?.label ?? ""}
                      description={
                        (val?.use?.label ?? "") +
                        (val.addressLine1?.length > 0
                          ? ", " + val.addressLine1
                          : "") +
                        (val.addressLine2?.length > 0
                          ? ", " + val.addressLine2
                          : "") +
                        (val.city?.title ? ", " + val.city?.title : "") +
                        (val.district?.title
                          ? ", " + val.district?.title
                          : "") +
                        (val.state?.title ? ", " + val.state?.title : "") +
                        (val.country?.title ? ", " + val.country?.title : "") +
                        (val.pincode?.title?.length > 0
                          ? ", " + val.pincode?.title
                          : "")
                      }
                      ondelete={() => this.ondelete(val, i)}
                      onedit={(e) => this.onedit(val, i, e)}
                      onswitch={false}
                    />
                  </div>
                );
              })}
            </Div>
          )}

          {addCollapseForm && (
            <Div>
              <Paper style={{ margin: 0, marginTop: 10 }}>
                <PersonAddAddress
                  saveAddress={this.saveAddress}
                  allMasters={this.props?.allMasterList}
                  handleClear={this.handleClear}
                  index={this.state.editindex}
                  editData={this.state.editadd}
                  setTo={this.props.setTo}
                  saveto={this.props.saveto}
                  onclose={() => this.OpenAddress(null)}
                />
              </Paper>
            </Div>
          )}
        </Paper>
      </Div>
    );
  }
}

export default withAllContexts(PersonAddress);
