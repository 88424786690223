import React, { useEffect } from "react";
import {
  CheckIcon,
  DocumentIcon,
  HomeDeliveryIcon,
  InfoIcon,
  DocumentBlack,
} from "../../../../assets";
import { ReactComponent as ClockIcon } from "../../../../assets/clockIcon.svg";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tag from "./tag";
import classNames from "classnames";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import {
  IconButton,
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import VerticalLinearStepper from "../drugCard/remark";
import AlertDialog from "../drugCard/alertDialog";
import { actions } from "frequencyscreen_v_dbinder";
import { useDispatch, useSelector } from "react-redux";
import { AlertProps } from "../../../../utils";
import { withAllContexts } from "../../../../HOCs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: 6,
    flexWrap: "nowrap",
  },
  dialogPaper: {
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
    minWidth: "464px",
    maxWidth: "465px",
    "& .App1-MuiDialogContent-dividers": {
      paddingRight: "0px",
    },
  },
  dialogPaperContent: {
    "& ul": {
      background: `${theme.palette.background.table} !important`,
      color: theme.palette.text.primary,
    },
  },
  alertdialogPaper: {
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
    minWidth: "893px",
    maxWidth: "893px",
    "& .App1-MuiDialogContent-dividers": {
      paddingRight: "0px",
    },
  },
  defaultText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    color: "white",
  },
  interactive: {
    cursor: "pointer",
  },
  durationContainer: {
    display: "flex",
    gap: 6,
    flexWrap: "nowrap",
  },
  delayText: {
    color: "#EC6A49",
  },
  alertCountText: {
    fontWeight: 600,
  },
  dot: {
    height: 10,
    width: 10,
    backgroundColor: "#FFC00F",
    borderRadius: "50%",
  },
  tickSize: {
    transform: "scale(0.8)",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const { ALERTLOGS_SINGLE_READ, ALERT_UPDATE } = actions;

function AlertTags({
  _key,
  elapsedTime,
  delay,
  dispenseStatus,
  clinicalValidation,
  alerts,
  drugColor,
  orderLineID,
  orderType,
  SlidingScaleDose,
  prnComment,
  selectedState,
  isReverify,
  ...props
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();

  // State
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickAlert = async () => {
    
    // if (selectedState === "verification") {
    await dispatch(ALERTLOGS_SINGLE_READ({ key: orderLineID }));
    setAlert(true);
    // }
  };
  const handleCloseAlert = (value, save) => {
    setAlert(false);
    if (save === "save") {
      handleUpsertAlert(value);
    }
  };

  const handleUpsertAlert = (data) => {
    // if (orderLineID) {
    //   data['orderLineId'] = orderLineID
    // }
    Promise.resolve(dispatch(ALERT_UPDATE({ alertPayload: data })))
      .then((res) => {
        if (res?.payload?.data?.Code === 201) {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Alert updated successfully!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        } else {
          throw new Error();
        }
      })
      .catch((e) => {
        console.error(e);
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong. Failed to update alert.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      });
  };

  return (
    <>
      <div className={classes.root}>
        {/* Duration Tag */}
        {elapsedTime && (
          <Tag>
            <div className={classes.durationContainer}>
              <ClockIcon />
              <Typography className={classes.defaultText}>
                {elapsedTime}
              </Typography>
            </div>
          </Tag>
        )}
        {/* Delay Tag */}
        {delay && (
          <Tag backgroundColor={"#F9DDD6"}>
            <Typography
              className={classNames(classes.defaultText, classes.delayText)}
            >
              {delay} delay
            </Typography>
          </Tag>
        )}
        {/* New Tag */}
        {dispenseStatus && (
          <Tag>
            <Typography className={classes.defaultText}>
              {dispenseStatus}
            </Typography>
          </Tag>
        )}

        {isReverify && (
          <Tag>
            <Tooltip title={props?.isReverifyStatusreason ?? ''} arrow>
              <Typography className={classes.defaultText}>
                {'Re-Verify'}
              </Typography>
            </Tooltip>
          </Tag>
        )}
        {/* Green Status */}
        <Tag backgroundColor={
          clinicalValidation ? '#D4F7D4' : '#FDF3D8'
        }>
          {(
            clinicalValidation) ? (
            <CheckIcon color={"#38C20A"} />
          ) : (
            <InfoIcon color={"#FFC00F"} />
          )}
        </Tag>
        {/* Alerts count tag */}
        {alerts?.length > 0 && (
          <Tag backgroundColor={'#EC6A49'}>
            <Typography
              className={classNames(classes.defaultText, classes.interactive)}
              onClick={handleClickAlert}
            >
              Alerts&nbsp;
              <span className={classes.alertCountText}>
                {alerts?.length?.toString()?.padStart(2, "0")}
              </span>
            </Typography>
          </Tag>
        )}
        {/* Dot Tag */}
        {drugColor && (
          <Tag backgroundColor={theme.palette.primary.main}>
            <div className={classes.dot}></div>
          </Tag>
        )}
        {/* Home Delivery Tag */}
        {orderType?.toString()?.toLowerCase() === "regular" && (
          <Tag>
            <HomeDeliveryIcon />
          </Tag>
        )}
        {/* PNR COMMENT */}
        {prnComment && (
          <Tooltip title="prnComment" arrow>
            <Tag>
              <Tooltip title={prnComment} arrow>
                <Typography noWrap className={classes.defaultText}>
                  {prnComment}
                </Typography>
              </Tooltip>
            </Tag>
          </Tooltip>
        )}
        {/* Sliding Scale Tag */}
        {
          // SlidingScaleDose?.length > 0 &&
          SlidingScaleDose?.filter(e => e)?.length > 0 && (
            <Tag>
              <Tooltip title="Sliding Scale Dose" arrow>
                <Typography className={classes.defaultText}>
                  {SlidingScaleDose}
                </Typography>
              </Tooltip>
            </Tag>
          )}
        {/* Prescription Tag */}
        <Tag className={classes.interactive} onClick={handleClickOpen}>
          <DocumentIcon color={"#fff"} />
        </Tag>
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div style={{ display: "flex", justifyContent: "left" }}>
            <div>
              <DocumentBlack
                style={{ marginTop: "5px" }}
                color={theme.palette.text.primary}
              />
            </div>
            <div>
              <Typography style={{ marginTop: "5px", marginLeft: "5px" }}>
                {" "}
                STATUS LOGS
              </Typography>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogPaperContent}>
          <VerticalLinearStepper _key={_key} />
          {/* <AlertDialog/> */}
        </DialogContent>
      </Dialog>

      {/* Alert Dialog_______________ */}
      <AlertDialog
        alertDialogOpen={alert}
        handleCloseAlertDialog={handleCloseAlert}
        isHide={true}
        selectedState={selectedState === "verification"}
      />
    </>
  );
}

export default withAllContexts(AlertTags);
