import React from "react";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Grid, Typography, Divider, Avatar, Box } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
var aes256 = require("aes256");

function InfoModal(props) {
  const dispatch = useDispatch();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {}, [state]);

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"info_modal_grid"}
            container={false}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            aliasName={"infoModalGrid"}
          >
            <Grid
              key={"0"}
              id={"info_header"}
              container={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              aliasName={"infoHeader"}
              style={qdmstyles.vtXR}
            >
              <Grid
                key={"0"}
                id={"header_grid"}
                container={false}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                aliasName={"headerGrid"}
                item={true}
                lg={"11"}
                md={"11"}
                sm={"11"}
                xs={"11"}
                xl={"11"}
              >
                <Typography
                  key={"0"}
                  id={"gZHLh"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"ORDER INFO"}
                  style={qdmstyles.gZHLh}
                ></Typography>
              </Grid>
            </Grid>
            <Divider key={"1"} id={"6Bbx3"} variant={"fullWidth"}></Divider>
            <Grid
              key={"2"}
              id={"drug_info"}
              container={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              aliasName={"drugInfo"}
              style={qdmstyles.ENqF}
            >
              <Grid
                key={"0"}
                id={"drug_info_header_grid"}
                container={false}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
                lg={"1"}
                md={"1"}
                xl={"1"}
                xs={"1"}
                sm={"1"}
                aliasName={"drugInfoHeaderGrid"}
              >
                <Typography
                  key={"0"}
                  id={"durg_info_typo"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"DRUG INFO"}
                  aliasName={"durgInfoTypo"}
                  style={qdmstyles.brtC}
                ></Typography>
              </Grid>
              <Grid
                key={"1"}
                id={"drug_info_divider_grid"}
                container={false}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
                lg={"11"}
                md={"11"}
                sm={"11"}
                xl={"11"}
                xs={"11"}
                aliasName={"drugInfoDividerGrid"}
              >
                <Divider
                  key={"0"}
                  id={"Wzit5"}
                  variant={"fullWidth"}
                  style={qdmstyles.Wzit}
                ></Divider>
              </Grid>
            </Grid>
            <Grid
              key={"3"}
              id={"drug_components"}
              container={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              aliasName={"drugComponents"}
              style={qdmstyles.XpxI}
            >
              <Grid
                key={"0"}
                id={"drug_img_grid"}
                container={false}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                item={true}
                lg={"1"}
                md={"1"}
                sm={"1"}
                xl={"1"}
                xs={"1"}
                aliasName={"drugImgGrid"}
              >
                <Avatar
                  key={"0"}
                  id={"drug_image"}
                  alt={"Avatar"}
                  variant={"rounded"}
                  aliasName={"drugImage"}
                  style={qdmstyles.oaCf}
                ></Avatar>
              </Grid>
              <Grid
                key={"1"}
                id={"drug_information_grid"}
                container={false}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
                lg={"11"}
                md={"11"}
                sm={"11"}
                xl={"11"}
                xs={"11"}
                aliasName={"drugInformationGrid"}
              >
                <Grid
                  key={"0"}
                  id={"grid_info_section1"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  aliasName={"gridInfoSection1"}
                  style={qdmstyles.Pcos}
                >
                  <Grid
                    key={"0"}
                    id={"drug_type"}
                    container={false}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    item={true}
                    aliasName={"drugType"}
                    lg={"3"}
                    style={qdmstyles.jGw}
                  >
                    <Box
                      key={"0"}
                      id={"drug_type_icon_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugTypeIconBox"}
                    >
                      <Avatar
                        key={"0"}
                        id={"pharmacy_icon"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        aliasName={"pharmacyIcon"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                        }
                        style={qdmstyles.nIu}
                      ></Avatar>
                    </Box>
                    <Box
                      key={"1"}
                      id={"drug_type_contents"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugTypeContents"}
                      style={qdmstyles.NoPZr}
                    >
                      <Typography
                        key={"0"}
                        id={"drug_type_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Drug Type"}
                        aliasName={"drugTypeHeader"}
                        style={qdmstyles.qmgG}
                      ></Typography>
                      <Typography
                        key={"1"}
                        id={"drug_type_typo"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Unit Dose"}
                        aliasName={"drugTypeTypo"}
                        style={qdmstyles.wmvH}
                      ></Typography>
                    </Box>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"drug_code"}
                    container={false}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    item={true}
                    aliasName={"drugCode"}
                    lg={"4"}
                    style={qdmstyles.Qxjaw}
                  >
                    <Box
                      key={"0"}
                      id={"drug_code_icon_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugCodeIconBox"}
                    >
                      <Avatar
                        key={"0"}
                        id={"pharmacy_icon"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        aliasName={"pharmacyIcon"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                        }
                        style={qdmstyles.LaX}
                      ></Avatar>
                    </Box>
                    <Box
                      key={"1"}
                      id={"drug_code_contents"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugCodeContents"}
                      style={qdmstyles.VOvK}
                    >
                      <Typography
                        key={"0"}
                        id={"drug_code_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Drug Code & Description"}
                        aliasName={"drugCodeHeader"}
                        style={qdmstyles.Szj}
                      ></Typography>
                      <Typography
                        key={"1"}
                        id={"drug_code_typo"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"MD00342 - Aspirin Chewable Tab 500 mg"}
                        aliasName={"drugCodeTypo"}
                        style={qdmstyles.kRu}
                      ></Typography>
                    </Box>
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"drug_dosage"}
                    container={false}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    item={true}
                    aliasName={"drugDosage"}
                    lg={"2"}
                    style={qdmstyles.RWkvj}
                  >
                    <Box
                      key={"0"}
                      id={"drug_dosage_icon_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugDosageIconBox"}
                    >
                      <Avatar
                        key={"0"}
                        id={"pharmacy_icon"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        aliasName={"pharmacyIcon"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                        }
                        style={qdmstyles.eCAt}
                      ></Avatar>
                    </Box>
                    <Box
                      key={"1"}
                      id={"drug_dosage_contents"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugDosageContents"}
                      style={qdmstyles.bZk}
                    >
                      <Typography
                        key={"0"}
                        id={"drug_dosage_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Dosage"}
                        aliasName={"drugDosageHeader"}
                        style={qdmstyles.wSStc}
                      ></Typography>
                      <Typography
                        key={"1"}
                        id={"drug_dosage_typo"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"250 mg / 2ml"}
                        aliasName={"drugDosageTypo"}
                        style={qdmstyles.Blob}
                      ></Typography>
                    </Box>
                  </Grid>
                  <Grid
                    key={"3"}
                    id={"drug_category"}
                    container={false}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    item={true}
                    aliasName={"drugCategory"}
                    lg={"3"}
                    style={qdmstyles.bfLH}
                  >
                    <Box
                      key={"0"}
                      id={"drug_category_icon_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugCategoryIconBox"}
                    >
                      <Avatar
                        key={"0"}
                        id={"pharmacy_icon"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        aliasName={"pharmacyIcon"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                        }
                        style={qdmstyles.BbrN}
                      ></Avatar>
                    </Box>
                    <Box
                      key={"1"}
                      id={"drug_category_contents"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugCategoryContents"}
                      style={qdmstyles.bUQl}
                    >
                      <Typography
                        key={"0"}
                        id={"drug_category_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Drug Category"}
                        aliasName={"drugCategoryHeader"}
                        style={qdmstyles.wDG}
                      ></Typography>
                      <Typography
                        key={"1"}
                        id={"drug_category_typo"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Narcotic"}
                        aliasName={"drugCategoryTypo"}
                        style={qdmstyles.IHirG}
                      ></Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  key={"1"}
                  id={"drug_info_section2"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  aliasName={"drugInfoSection2"}
                  style={qdmstyles.JyrZ}
                >
                  <Grid
                    key={"0"}
                    id={"drug_route"}
                    container={false}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    item={true}
                    aliasName={"drugRoute"}
                    lg={"1"}
                    md={"1"}
                    sm={"1"}
                    xl={"1"}
                    xs={"1"}
                    style={qdmstyles.bDtH}
                  >
                    <Box
                      key={"0"}
                      id={"drug_route_icon_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugRouteIconBox"}
                    >
                      <Avatar
                        key={"0"}
                        id={"pharmacy_icon"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        aliasName={"pharmacyIcon"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                        }
                        style={qdmstyles.EbxMH}
                      ></Avatar>
                    </Box>
                    <Box
                      key={"1"}
                      id={"drug_route_contents"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugRouteContents"}
                      style={qdmstyles.tcl}
                    >
                      <Typography
                        key={"0"}
                        id={"drug_route_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Route"}
                        aliasName={"drugRouteHeader"}
                        style={qdmstyles.Eoqd}
                      ></Typography>
                      <Typography
                        key={"1"}
                        id={"drug_route_typo"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Oral"}
                        aliasName={"drugRouteTypo"}
                        style={qdmstyles.jz}
                      ></Typography>
                    </Box>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"drug_Form"}
                    container={false}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    item={true}
                    aliasName={"drugForm"}
                    lg={"1"}
                    md={"1"}
                    sm={"1"}
                    xl={"1"}
                    xs={"1"}
                    style={qdmstyles.iuINo}
                  >
                    <Box
                      key={"0"}
                      id={"drug_Form_icon_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugFormIconBox"}
                    >
                      <Avatar
                        key={"0"}
                        id={"pharmacy_icon"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        aliasName={"pharmacyIcon"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                        }
                        style={qdmstyles.eAWI}
                      ></Avatar>
                    </Box>
                    <Box
                      key={"1"}
                      id={"drug_Form_contents"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugFormContents"}
                      style={qdmstyles.GvqBU}
                    >
                      <Typography
                        key={"0"}
                        id={"drug_Form_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Form"}
                        aliasName={"drugFormHeader"}
                        style={qdmstyles.dEpDu}
                      ></Typography>
                      <Typography
                        key={"1"}
                        id={"drug_Form_typo"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Tablet"}
                        aliasName={"drugFormTypo"}
                        style={qdmstyles.crFT}
                      ></Typography>
                    </Box>
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"drug_Generic"}
                    container={false}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    item={true}
                    aliasName={"drugGeneric"}
                    lg={"3"}
                    style={qdmstyles.hSDz}
                  >
                    <Box
                      key={"0"}
                      id={"drug_Generic_icon_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugGenericIconBox"}
                    >
                      <Avatar
                        key={"0"}
                        id={"pharmacy_icon"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        aliasName={"pharmacyIcon"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                        }
                        style={qdmstyles.Ppwc}
                      ></Avatar>
                    </Box>
                    <Box
                      key={"1"}
                      id={"drug_Generic_contents"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugGenericContents"}
                      style={qdmstyles.iAmIh}
                    >
                      <Typography
                        key={"0"}
                        id={"drug_Generic_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Generic"}
                        aliasName={"drugGenericHeader"}
                        style={qdmstyles.zhfP}
                      ></Typography>
                      <Typography
                        key={"1"}
                        id={"drug_Generic_typo"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Aspirin Chewable Tab"}
                        aliasName={"drugGenericTypo"}
                        style={qdmstyles.zR}
                      ></Typography>
                    </Box>
                  </Grid>
                  <Grid
                    key={"3"}
                    id={"drug_Mode"}
                    container={false}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    item={true}
                    aliasName={"drugMode"}
                    lg={"2"}
                    md={"2"}
                    sm={"2"}
                    xl={"2"}
                    xs={"2"}
                    style={qdmstyles.OxTt}
                  >
                    <Box
                      key={"0"}
                      id={"drug_Mode_icon_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugModeIconBox"}
                    >
                      <Avatar
                        key={"0"}
                        id={"pharmacy_icon"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        aliasName={"pharmacyIcon"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                        }
                        style={qdmstyles.sgjMk}
                      ></Avatar>
                    </Box>
                    <Box
                      key={"1"}
                      id={"drug_Mode_contents"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugModeContents"}
                      style={qdmstyles.aTxg}
                    >
                      <Typography
                        key={"0"}
                        id={"drug_Mode_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Mode"}
                        aliasName={"drugModeHeader"}
                        style={qdmstyles.tpfA}
                      ></Typography>
                      <Typography
                        key={"1"}
                        id={"drug_Generic_typo"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Regular"}
                        aliasName={"drugGenericTypo"}
                        style={qdmstyles.QBel}
                      ></Typography>
                    </Box>
                  </Grid>
                  <Grid
                    key={"4"}
                    id={"drug_Nature"}
                    container={false}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    item={true}
                    aliasName={"drugNature"}
                    lg={"2"}
                    md={"2"}
                    sm={"2"}
                    xl={"2"}
                    xs={"2"}
                    style={qdmstyles.wlAz}
                  >
                    <Box
                      key={"0"}
                      id={"drug_Nature_icon_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugNatureIconBox"}
                    >
                      <Avatar
                        key={"0"}
                        id={"pharmacy_icon"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        aliasName={"pharmacyIcon"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                        }
                        style={qdmstyles.YwvH}
                      ></Avatar>
                    </Box>
                    <Box
                      key={"1"}
                      id={"drug_Nature_contents"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugNatureContents"}
                      style={qdmstyles.oJmOk}
                    >
                      <Typography
                        key={"0"}
                        id={"drug_Nature_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Nature"}
                        aliasName={"drugNatureHeader"}
                        style={qdmstyles.qoDV}
                      ></Typography>
                      <Typography
                        key={"1"}
                        id={"drug_Nature_typo"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Pre-Admission"}
                        aliasName={"drugNatureTypo"}
                        style={qdmstyles.BMAF}
                      ></Typography>
                    </Box>
                  </Grid>
                  <Grid
                    key={"5"}
                    id={"drug_Duration"}
                    container={false}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    item={true}
                    aliasName={"drugDuration"}
                    lg={"2"}
                    md={"2"}
                    sm={"2"}
                    xl={"2"}
                    xs={"2"}
                    style={qdmstyles.wGd}
                  >
                    <Box
                      key={"0"}
                      id={"drug_Duration_icon_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugDurationIconBox"}
                    >
                      <Avatar
                        key={"0"}
                        id={"pharmacy_icon"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        aliasName={"pharmacyIcon"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                        }
                        style={qdmstyles.fdEwi}
                      ></Avatar>
                    </Box>
                    <Box
                      key={"1"}
                      id={"drug_Duration_contents"}
                      m={"1"}
                      component={"div"}
                      aliasName={"drugDurationContents"}
                      style={qdmstyles.zznek}
                    >
                      <Typography
                        key={"0"}
                        id={"drug_Duration_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Duration"}
                        aliasName={"drugDurationHeader"}
                        style={qdmstyles.aNnjY}
                      ></Typography>
                      <Typography
                        key={"1"}
                        id={"drug_Duration_typo"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Pre-Admission"}
                        aliasName={"drugDurationTypo"}
                        style={qdmstyles.zAI}
                      ></Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                key={"2"}
                id={"Nm28F"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <Grid
                  key={"0"}
                  id={"drug_start_date"}
                  container={false}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  item={true}
                  aliasName={"drutStartDate"}
                  lg={"3"}
                  md={"3"}
                  sm={"3"}
                  xl={"3"}
                  xs={"3"}
                  style={qdmstyles.eZwi}
                >
                  <Box
                    key={"0"}
                    id={"drug_startDate_box"}
                    m={"1"}
                    component={"div"}
                    aliasName={"drugStartDateconBox"}
                  >
                    <Avatar
                      key={"0"}
                      id={"pharmacy_icon"}
                      alt={"Avatar"}
                      variant={"rounded"}
                      aliasName={"pharmacyIcon"}
                      src={
                        "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                      }
                      style={qdmstyles.hoky}
                    ></Avatar>
                  </Box>
                  <Box
                    key={"1"}
                    id={"drug_startDate_contents"}
                    m={"1"}
                    component={"div"}
                    aliasName={"drugStartDateContents"}
                    style={qdmstyles.Fiy}
                  >
                    <Typography
                      key={"0"}
                      id={"drug_startDate_header"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Start Date"}
                      aliasName={"drugStartDateHeader"}
                      style={qdmstyles.Qrx}
                    ></Typography>
                    <Typography
                      key={"1"}
                      id={"drug_startDate_typo"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"24-03-2022 10:30"}
                      aliasName={"drugStartDateTypo"}
                      style={qdmstyles.ZRae}
                    ></Typography>
                  </Box>
                </Grid>
                <Grid
                  key={"1"}
                  id={"drug_end_date"}
                  container={false}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  item={true}
                  aliasName={"drugEndDate"}
                  lg={"3"}
                  md={"3"}
                  sm={"3"}
                  xl={"3"}
                  xs={"3"}
                  style={qdmstyles.Nswu}
                >
                  <Box
                    key={"0"}
                    id={"drug_EndDate_box"}
                    m={"1"}
                    component={"div"}
                    aliasName={"drugEndDateconBox"}
                  >
                    <Avatar
                      key={"0"}
                      id={"pharmacy_icon"}
                      alt={"Avatar"}
                      variant={"rounded"}
                      aliasName={"pharmacyIcon"}
                      src={
                        "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                      }
                      style={qdmstyles.woLV}
                    ></Avatar>
                  </Box>
                  <Box
                    key={"1"}
                    id={"drug_EndDate_contents"}
                    m={"1"}
                    component={"div"}
                    aliasName={"drugEndDateContents"}
                    style={qdmstyles.cFso}
                  >
                    <Typography
                      key={"0"}
                      id={"drug_EndDate_header"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"End Date"}
                      aliasName={"drugEndDateHeader"}
                      style={qdmstyles.KxgF}
                    ></Typography>
                    <Typography
                      key={"1"}
                      id={"drug_EndDate_typo"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"30-03-2022 10:30"}
                      aliasName={"drugEndDateTypo"}
                      style={qdmstyles.hCsa}
                    ></Typography>
                  </Box>
                </Grid>
                <Grid
                  key={"2"}
                  id={"drug_end_date"}
                  container={false}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  item={true}
                  aliasName={"drugEndDate"}
                  lg={"6"}
                  md={"6"}
                  sm={"6"}
                  xl={"6"}
                  xs={"6"}
                  style={qdmstyles.dHETu}
                >
                  <Box
                    key={"0"}
                    id={"drug_Diagnosis_box"}
                    m={"1"}
                    component={"div"}
                    aliasName={"drugDiagnosisBox"}
                  >
                    <Avatar
                      key={"0"}
                      id={"pharmacy_icon"}
                      alt={"Avatar"}
                      variant={"rounded"}
                      aliasName={"pharmacyIcon"}
                      src={
                        "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                      }
                      style={qdmstyles.LuRW}
                    ></Avatar>
                  </Box>
                  <Box
                    key={"1"}
                    id={"drug_Diagnosis_contents"}
                    m={"1"}
                    component={"div"}
                    aliasName={"drugDiagnosisContents"}
                    style={qdmstyles.EkDbD}
                  >
                    <Typography
                      key={"0"}
                      id={"drug_Diagnosis_header"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Diagnosis"}
                      aliasName={"drugDiagnosisHeader"}
                      style={qdmstyles.igzD}
                    ></Typography>
                    <Box
                      key={"1"}
                      id={"diagnosis_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"diagnosisBox"}
                      style={qdmstyles.qXt}
                    >
                      <Typography
                        key={"0"}
                        id={"9Yuix"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Benign essential hypertension"}
                        style={qdmstyles.Yuix}
                      ></Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              key={"4"}
              id={"practioner_info"}
              container={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              aliasName={"practionerInfo"}
              style={qdmstyles.grtm}
            >
              <Grid
                key={"0"}
                id={"practioner_header_grid"}
                container={false}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
                lg={"2"}
                md={"2"}
                xl={"2"}
                xs={"2"}
                sm={"2"}
                aliasName={"practionerHeaderGrid"}
              >
                <Typography
                  key={"0"}
                  id={"practioner_typo"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"PRACTITIONER INFO"}
                  aliasName={"practionerTypo"}
                  style={qdmstyles.iugN}
                ></Typography>
              </Grid>
              <Grid
                key={"1"}
                id={"practioner_divider_grid"}
                container={false}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
                lg={"10"}
                md={"10"}
                sm={"10"}
                xl={"10"}
                xs={"10"}
                aliasName={"practionerDividerGrid"}
              >
                <Divider
                  key={"0"}
                  id={"Wzit5"}
                  variant={"fullWidth"}
                  style={qdmstyles.TxMbk}
                ></Divider>
              </Grid>
            </Grid>
            <Grid
              key={"5"}
              id={"practitioner_grid"}
              container={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              aliasName={"practitionerCardGrid"}
              style={qdmstyles.NofPT}
            >
              <Grid
                key={"0"}
                id={"practioner_card"}
                container={false}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                aliasName={"practionerCard"}
                item={true}
                lg={"4"}
                md={"4"}
                sm={"4"}
                xl={"4"}
                xs={"4"}
                style={qdmstyles.oDLdR}
              >
                <Grid
                  key={"0"}
                  id={"practitioner_information"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  aliasName={"practitionerInformation"}
                  style={qdmstyles.auYz}
                >
                  <Avatar
                    key={"0"}
                    id={"practioner_avatar"}
                    alt={"Avatar"}
                    variant={"rounded"}
                    aliasName={"practionerAvatar"}
                    style={qdmstyles.jvc}
                  ></Avatar>
                  <Box
                    key={"1"}
                    id={"practioner_info_box"}
                    m={"1"}
                    component={"div"}
                    aliasName={"practionerInfoBox"}
                    style={qdmstyles.IcGs}
                  >
                    <Typography
                      key={"0"}
                      id={"practitioner_name"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Dr. Roger Fox"}
                      aliasName={"practitionerName"}
                      style={qdmstyles.dlO}
                    ></Typography>
                    <Box
                      key={"1"}
                      id={"practitioner_cardInfo"}
                      m={"1"}
                      component={"div"}
                      aliasName={"practitionerCardInfo"}
                      style={qdmstyles.JxHV}
                    >
                      <Typography
                        key={"0"}
                        id={"practioner_id"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"ID 34562"}
                        aliasName={"practionerId"}
                        style={qdmstyles.GGbM}
                      ></Typography>
                      <Box
                        key={"1"}
                        id={"dot"}
                        m={"1"}
                        component={"div"}
                        aliasName={"dot"}
                        style={qdmstyles.WMKG}
                      ></Box>
                      <Typography
                        key={"2"}
                        id={"practioner_mbl"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"+91 95854 25622"}
                        aliasName={"practionerMbl"}
                        style={qdmstyles.fsnn}
                      ></Typography>
                    </Box>
                  </Box>
                </Grid>
                <Divider key={"1"} id={"WvhmD"} variant={"fullWidth"}></Divider>
                <Grid
                  key={"2"}
                  id={"lower_practioner"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  aliasName={"lowerPractioner"}
                  style={qdmstyles.byox}
                >
                  <Grid
                    key={"0"}
                    id={"specilisation"}
                    container={false}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    aliasName={"specilisation"}
                    item={true}
                    lg={"6"}
                    md={"6"}
                    sm={"6"}
                    xs={"6"}
                    xl={"6"}
                    style={qdmstyles.TxV}
                  >
                    <Box
                      key={"0"}
                      id={"specilization_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"specilizationBox"}
                    >
                      <Avatar
                        key={"0"}
                        id={"pharmacy_icon"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        aliasName={"pharmacyIcon"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                        }
                        style={qdmstyles.IwuP}
                      ></Avatar>
                    </Box>
                    <Box
                      key={"1"}
                      id={"specilization_contents"}
                      m={"1"}
                      component={"div"}
                      aliasName={"specilizationContents"}
                      style={qdmstyles.AlVe}
                    >
                      <Typography
                        key={"0"}
                        id={"specilizationheader"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Speciality Name"}
                        aliasName={"specilizationHeader"}
                        style={qdmstyles.ErejY}
                      ></Typography>
                      <Typography
                        key={"1"}
                        id={"speciality_typo"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Cardiology"}
                        aliasName={"specialityTypo"}
                        style={qdmstyles.gKeq}
                      ></Typography>
                    </Box>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"clinic"}
                    container={false}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    aliasName={"clinic"}
                    item={true}
                    lg={"6"}
                    md={"6"}
                    sm={"6"}
                    xs={"6"}
                    xl={"6"}
                    style={qdmstyles.Hax}
                  >
                    <Box
                      key={"0"}
                      id={"speciality_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"specialityBox"}
                    >
                      <Avatar
                        key={"0"}
                        id={"pharmacy_icon"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        aliasName={"pharmacyIcon"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/322570079"
                        }
                        style={qdmstyles.pbKDl}
                      ></Avatar>
                    </Box>
                    <Box
                      key={"1"}
                      id={"clinic_contents"}
                      m={"1"}
                      component={"div"}
                      aliasName={"clinicContents"}
                      style={qdmstyles.ZYNNb}
                    >
                      <Typography
                        key={"0"}
                        id={"clinic_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Clinic"}
                        aliasName={"clinicHeader"}
                        style={qdmstyles.xXZd}
                      ></Typography>
                      <Typography
                        key={"1"}
                        id={"clinic_typo"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Neoro Clinic Room"}
                        aliasName={"clinicTypo"}
                        style={qdmstyles.WMpNk}
                      ></Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(InfoModal));
