import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 500,
    },
    tableHeader: {
        fontSize: "14px",
    },
    tableBody: {
        padding: "12px",
        fontFamily: "poppinsemibold",
        fontSize: "12px",
        padding: 10,
    },
    outerMargin: {
        marginTop: "0px",
        margin: "15px 20px",
        border: "1px solid #DEE5EC",
        borderRadius: "8px",
    },
    innerHeading: {
        backgroundColor: "#DEE5EC",
        padding: "12px 15px",
        fontSize: "14px",
    },
    bluetext: {
        color: "#2A60BC",
        fontSize: "12px",
    },
    tableContainer: {
        border: "1px solid #e4e4e4",
        boxShadow: "none",
        //   margin: "15px 0",
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop: "0px !important",
        marginLeft: "0px !important",
        minWidth: "285px",
    },
    text: {
        fontSize: "14px",
        fontWeight: 500,
    },
    contenttext: {
        
        fontSize: "12px",
    },
    input: {
        "& .App1-MuiTextField-root": {
            margin: theme.spacing(1),
            marginTop: "0px !important",
            marginLeft: "0px !important",
            width: "447px",
        },
    },
    label: {
        
        height: "17px",
        fontSize: "12px",
    },
    tickSize: {
        transform: "scale(0.8)",
    },
}));

export const IssueMedicationTable = (props) => {
    const classes = useStyles();

    const header = [
        "S.NO",
        "Dose date time",
        "Dose & UOM",
        "Administrative & UOM",
        "Issue quantity & UOM",
        "Action"
    ];

    const { dataTabs } = props;
    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table
                className={classes.table}
                aria-label="simple table"
                size="small"
            >
                <TableHead style={{ background: "#DEE5EC" }}>
                    <TableRow>
                        {header.map((val, i) => {
                            return (
                                <TableCell className={classes.tableHeader}>
                                    {val}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataTabs.map((val, i) => {
                        return (
                            <TableRow>
                                <TableCell>{val.sNo}</TableCell>
                                <TableCell>{moment.unix(val.doseDateTime).format('DD-MM-YY h:mm')}</TableCell>
                                <TableCell>{val.doeUOM}</TableCell>
                                <TableCell>{val.AadminUOM}</TableCell>
                                <TableCell>{val.IssueQNY}</TableCell>
                                <TableCell><Checkbox checked={val?.action} onChange={() => props.handleRessue(i, !val?.action)} /></TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>

    )
}