import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import Grid from "@material-ui/core/Grid";
import { StyledDialog, StyledPaper } from "../commonComponents";
import Description from "./description";
import { ReactComponent as PrintIcon } from "../../assets/printIcon.svg";
import { ReactComponent as ForwardIconSmall } from "../../assets/forwarIconSmall.svg";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AdditionalDescription from "./additionalDescription";
import { DrugComponent, DrugSelectorByType } from "./drug";
import PropTypes from "prop-types";
import StyledInput from "./styledInput";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { AlertContext } from "../../contexts";
import {
  AlertProps,
  checkDosageFulfilled,
  makeName,
  TimeFormatConversion,
} from "../../utils";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { actions } from "frequencyscreen_v_dbinder";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { PrinterIcon, StackOverflowIcon } from "../../assets";
import { useDialogStylesWorksheet } from "../drugItem/components/drugCard/styles";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import TypographyWithIcon from "./drug/typographyWithIcon";

const useStyles = makeStyles((theme) => ({
  selectOrder: {
    color: `${theme.palette.primary.main} !important`,
    marginBottom: "-5px",
  },
  wrapper: {
    padding: theme.spacing(1.5, 1, 1.5, 2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  descriptionRow: {
    display: "flex",
  },
  flex: {
    flex: 1,
  },
  duration: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    justifyContent: "flex-end",
  },
  batchText: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  sizeSmall: {
    padding: theme.spacing(0.5),
  },
  dispenseDurationLabelContainer: {
    flex: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 8,
    borderRadius: "8px",
    "& > p": {
      lineHeight: 1,
      fontSize: 14,
      fontWeight: 500,
    },
  },
  dispenseDurationInput: {
    flex: 0,
  },
  iconContainer: {
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    height: 20,
    width: 20,
    minWidth: 20,
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  inputField: {
    fontWeight: 500,
    paddingBlock: 2,
    "& > p": {
      color: theme.palette.text.grey,
    },
  },
  iconButton: {
    padding: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: theme.palette.background.common,
    },
  },
  batchCount: {
    cursor: "pointer",
    "& > p": {
      color: "#2A60BC",
      fontWeight: "500",
    },
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 980,
    borderRadius: 8,
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
  },
}));

const useDialogContentStyles = makeStyles((theme) => ({
  dividers: {
    padding: 0,
    borderColor: "rgba(222, 229, 236, 1Batch)",
  },
}));

const useIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: theme.palette.background.common,
    },
    "& svg": {
      color: theme.palette.text.primary,
      "& path": {
        fill: `${theme.palette.text.primary}`,
        color: `${theme.palette.text.primary}`,
      },
    },
  },
}));

function OrderDescription(props) {
  // <---------------Hooks------------------>
  const classes = useStyles();
  const dispatch = useDispatch();
  //const theme = useTheme();
  const dialogStyles = useDialogStyles();
  const dialogContentStyles = useDialogContentStyles();
  const iconButtonStyles = useIconButtonStyles();
  const dialogStyleWorksheet = useDialogStylesWorksheet();
  const binderData = useSelector(
    (state) => state?.phramcyFrameworkApiSlice?.label_print?.data
  );
  const addPopupState = usePopupState({
    variant: "popover",
    popupId: "add-popup",
  });

  const printPopupState = usePopupState({
    variant: "popover",
    popupId: "print",
  });

  // <---------------Context Provider States----------->
  const { setSnack } = useContext(AlertContext);

  // <---------------Store States----------->

  // <-------------Component States--------->
  const [isBatchDialogOpen, setIsBatchDialogOpen] = useState(false);

  // <-----------------Handlers------------->
  const handleBatchDialogToggle = () => {
    setIsBatchDialogOpen((prev) => !prev);
  };

  // <-----------------Effects-------------->

  // <-----------------Others-------------->
  // const handleBatchCountClick = () => {};

  // <-----------------Render--------------->
  const {
    isIntravenous,
    isCompound,
    isSingleLine,
    route,
    name,
    dosage,
    frequency,
    duration,
    doctorName,
    speciality,
    infusionRate,
    infusionTime,
    typeOfInfusion,
    occurStartDateTime,
    occurEndDateTime,
    dosageFrequencyType,
    dosageFrequencyCode,
    orderFrequencyDetails,
    CompoundQuantity,
    CompoundQuantityUnit,
    CompoundStatbility,
    batchDrugStability,
    selectedState,
    dispenseDuration,
    dispenseDurationUOM,
    onDispenseDurationChange = () => {},
    onApplyDispenseDurationClick,
    orderValue,
    batchNo,
    onChangeBatch,
    orderedDrugs,
    brands,
    compounds,
    addictive,
    disptype,
    onBatchQuantityChange,
    addDrugOption,
    handleAdd,
    directions,
    handleDirectionSaveClick,
    handleDelete,
    handleChangebrandName,
    orderid,
    handleMedicationEdit,
    drug,
  } = props;
  //Get Drug Type
  const [openWorkSheet, setOpenWorkSheet] = useState(false);

  const onCloseWorkSheet = () => {
    setOpenWorkSheet(false);
  };

  const getType = (isIv, isCompound) => {
    if (isIv) {
      return "IV";
    } else if (isCompound) {
      return "compound";
    } else {
      return "UD";
    }
  };

  const isSelect = (d) => {
    var selected = false;

    const isFilter = props.isMultiOrderSelect;

    if (isFilter?.length > 0) {
      const data = isFilter?.filter((v) => v?._id === d?._id);
      selected = data?.length > 0;
    }
    return selected;
  };

  const getDrugName = (drug) => {
    let name = [];
    drug.brands.forEach((v) => {
      name.push(v.name);
    });
    drug.additives.forEach((v) => {
      v.brands.forEach((b) => {
        name.push(b.name);
      });
    });
    return name;
  };

  const getDosage = (drug) => {
    let dosage = [];
    drug.brands.forEach((v) => {
      dosage.push(v.dosageValue);
    });
    drug.additives.forEach((v) => {
      v.brands.forEach((b) => {
        dosage.push(b.dosageValue);
      });
    });
    return dosage;
  };
  const getUOM = (drug) => {
    let uom = [];
    drug.brands.forEach((v) => {
      let UOM = v.dispuomtext ? v.dispuomtext : v.dosageUOM;
      uom.push(UOM);
    });
    drug.additives.forEach((v) => {
      v.brands.forEach((b) => {
        let UOM = b.dispuomtext ? b.dispuomtext : b.dosageUOM;
        uom.push(UOM);
      });
    });
    return uom;
  };

  const handlePrintWorkSheet = async (drug) => {
    setOpenWorkSheet(true);
    let date = JSON.parse(localStorage.getItem("orgFormat"));
    let payload = {
      reportid: process.env.REACT_APP_COMPOUND_PRINT_LABEL,
      inputparams: {
        "@orderID": drug?._id,
        "@datetimeformat":
          "%" +
          date?.orgdatetimeformat?.display
            ?.replaceAll("/", "/%")
            ?.replaceAll(" ", " %")
            ?.replaceAll("mm", "%ii"),
        // "%dd/%mm/%yyyy %hh:%ii",
      },
      result: [],
    };

    await dispatch(
      actions.LABEL_PRINT({
        Body: payload,
      })
    );
  };

  const printLabel = async (drug, index, brand) => {
    
    if (
      brand?.pH_OrderLineDispenseSchedule ||
      brand?.pH_OrderLineDispenseSchedule?.dispensedetails ||
      brand?.dispensedetails
    ) {
      // alert("Never");
    } else {
      setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Generate Schedule to Print label!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }

    if (drug?.disptype?.code === "COMPOUND") {
      setOpenWorkSheet(true);
    }
    let type = drug?.additives?.length > 0 ? "IV" : "Brands";
    // Labelpayload........................

    let DrugName = drug?.mappedItems?.find(
      (v) => v?.dispensedrugcode === brand?.dispensedrugcode
    )?.drugNameLong;

    let date_label = JSON.parse(localStorage.getItem("orgFormat"));

    const Labelpayload = {
      reportid: process.env.REACT_APP_OP_LABEL_PRINT_ID,

      inputparams: {},
      result: [
        {
          Mandatory: {
            drugType: drug?.drugType?.longdesc ?? drug?.drugType?.shortdesc ?? '',
            name: drug?.encounter_Id[0]?.patient_id[0].name[0]
              ? makeName(drug?.encounter_Id[0]?.patient_id[0].name[0])
              : "",
            mrn: drug?.encounter_Id[0]?.patient_id[0].alias
              ? drug?.encounter_Id[0]?.patient_id[0].alias
              : "",
            qty: brand?.batches?.[0]?.chargeqty || brand?.totadmqty || "",
            qtyuom: brand?.batches?.[0]?.chargeuom || brand?.totadmuom || "",
            batchno: brand?.batches?.[0]?.batchno ?? "",

            expiry: brand?.batches?.[0]?.expdate
              ? moment(brand?.batches?.[0]?.expdate).format(
                  date_label?.orgdateformat?.display || "DD/MM/YYYY"
                )
              : brand?.batches?.[0]?.expirydate
              ? moment(brand?.batches?.[0]?.expirydate).format(
                  date_label?.orgdateformat?.display || "DD/MM/YYYY"
                )
              : "",
            date: moment(new Date()).format(
              date_label?.orgdatetimeformat?.display || "DD/MM/YYYY HH:mm"
            ),
            medicationname: DrugName ? DrugName : brand?.name,
            ControlledMedicine: true,
            hospname: drug?.encounter_Id?.[0]?.practitioner_details?.[0]?.clinic
              ? drug?.encounter_Id?.[0]?.practitioner_details?.[0]?.clinic
              : "",
            medicationorderdetails: drug?.medicationorderdetails ?? "",
            prescno: drug?.prescno ?? "",
            ward: drug?.ward ?? "",
            logo: drug?.logo ?? "",
            hospaddress: drug?.hospaddress ?? "",
            telecom: drug?.telecom ?? [],
            qrcode:
              (drug?.encounter_Id[0]?.patient_id[0].name[0]
                ? makeName(drug?.encounter_Id[0]?.patient_id[0].name[0])
                : "") +
              (drug?.encounter_Id[0]?.patient_id[0].alias
                ? drug?.encounter_Id[0]?.patient_id[0].alias
                : "") +
              (DrugName ? DrugName : brand?.name) +
              (brand?.totadmqty ? brand?.totadmqty : "") +
              (brand?.totadmuom ? brand?.totadmuom : "") +
              (drug?.ward ?? ""),
            listpres: brand?.pH_OrderLineDispenseSchedule
              ? brand?.pH_OrderLineDispenseSchedule?.dispensedetails[index]
                  ?.direction
              : brand?.dispensedetails[index]?.direction ?? "",
            disptype:
              brand?.pH_OrderLineDispenseSchedule?.disptype ??
              drug?.disptype?.code ??
              "",
          },
          optional: {},
        },
      ],
    };

    // IVpayload...................

    const IVpayload = {
      reportid: process.env.REACT_APP_OP_IV_LABEL_PRINT_ID,
      inputparams: {},
      result: [
        {
          Mandatory: {
            drugType: drug?.drugType?.longdesc ?? drug?.drugType?.shortdesc ?? '',
            PatientName: drug?.encounter_Id?.[0]?.patient_id[0].name[0]
              ? makeName(drug?.encounter_Id?.[0]?.patient_id[0].name[0])
              : "",
            MRN: drug?.encounter_Id?.[0]?.patient_id[0].alias
              ? drug?.encounter_Id?.[0]?.patient_id[0].alias
              : "",
            ord: drug._id ? drug._id : "",
            "#C": "",
            location: drug?.location ?? "",
            rate:
              (drug?.brands?.[index]?.pH_OrderLineDispenseSchedule
                ?.ivInfusionRateValue ?? 0,
              drug?.brands?.[index].pH_OrderLineDispenseSchedule
                ?.ivInfusionRateUOM ?? "",
              " ",
              drug?.brands?.[index]?.pH_OrderLineDispenseSchedule
                ?.IVDosageDurationValue ?? 0,
              drug?.brands?.[index].pH_OrderLineDispenseSchedule
                ?.IVDosageDurationUOM ?? ""),
            Exp: "",
            Due_time: drug?.brands?.[index]?.pH_OrderLinePerdoseSchedule
              ?.dosetime
              ? TimeFormatConversion(
                  drug?.brands?.[index]?.pH_OrderLinePerdoseSchedule?.dosetime,
                  date_label?.orgtimeformat || "hh:mm"
                )
              : TimeFormatConversion(
                  drug?.brands?.[index]?.PH_OrderLinePerdoseSchedule?.dosetime,
                  date_label?.orgtimeformat || "hh:mm"
                ),
            Due_date:
              TimeFormatConversion(
                drug?.brands?.[index]?.pH_OrderLinePerdoseSchedule?.dosedate,
                date_label?.orgdateformat || "dd/MM/yyyy"
              ) ||
              TimeFormatConversion(
                drug?.brands?.[index]?.PH_OrderLinePerdoseSchedule?.dosedate,
                date_label?.orgdateformat || "dd/MM/yyyy"
              ),
            Due_date_format:
              TimeFormatConversion(
                drug?.brands?.[index]?.pH_OrderLinePerdoseSchedule?.dosedate,
                date_label?.orgdatetimeformat || "dd/MM/yyyy hh:mm"
              ) ||
              TimeFormatConversion(
                drug?.brands?.[index]?.PH_OrderLinePerdoseSchedule?.dosedate,
                date_label?.orgdatetimeformat || "dd/MM/yyyy hh:mm"
              ),
            drugname: getDrugName(drug),
            dose: getDosage(drug),
            uom: getUOM(drug),
            direction: brand?.pH_OrderLineDispenseSchedule
              ? brand?.pH_OrderLineDispenseSchedule?.dispensedetails[index]
                  ?.direction ?? ""
              : brand?.dispensedetails[index]?.direction ?? "",
            Route: drug?.routeCode ? drug?.routeCode : "",
            Freq: drug?.frequency.code ?? "",
          },
          optional: {},
        },
      ],
    };

    await dispatch(
      actions.LABEL_PRINT({
        Body: type === "IV" ? IVpayload : Labelpayload,
      })
    );
  };
  return (
    <StyledPaper className={classes.wrapper}>
      <div className={classes.descriptionRow}>
        <Description
          isIntravenous={isIntravenous}
          route={route}
          name={!isSingleLine ? name : brands?.[0]?.drugNameLong}
          dosage={dosage}
          frequency={frequency}
          duration={duration}
          doctorName={doctorName}
          speciality={speciality}
          occurStartDateTime={occurStartDateTime}
          occurEndDateTime={occurEndDateTime}
          dosageFrequencyType={dosageFrequencyType}
          dosageFrequencyCode={dosageFrequencyCode}
          orderFrequencyDetails={orderFrequencyDetails}
          dispenseDuration={dispenseDuration}
          orderid={orderid}
          handleMedicationEdit={handleMedicationEdit}
          //UNIT DOSE SINGLE ROW UI PROPS
          options={brands?.[0]?.option ?? []}
          handleChangebrandName={(value) => {
            handleChangebrandName(value, 0, brands?.[0]?.id);
          }}
          allowChangeDrug={isSingleLine}
          //Direction
          isVerficationState={selectedState === "verification" ? true : false}
          direction={
            directions
              ? directions?.[0]?.direction
              : brands?.[0]?.pH_OrderLineDispenseSchedule?.dispensedetails?.[0]
                  ?.direction
          }
          nlDirection={
            directions
              ? directions?.[0]?.direction
              : brands?.[0]?.pH_OrderLineDispenseSchedule?.dispensedetails?.[0]
                  ?.nldirection
          }
          handleDirectionSaveClick={(e) =>
            props.handleDirectionSaveClick(e, 0, brands?.[0])
          }
        />
        <div className={classes.flex}></div>
        <div className={classes.duration}>
          {/* Stocks */}
          {isSingleLine && (
            <>
              <TypographyWithIcon
                startAdornment={<StackOverflowIcon color={"#2A60BC"} />}
                className={classes.batchCount}
                onClick={handleBatchDialogToggle}
              >
                {brands?.[0]?.batches?.[0]?.availableQuantity}
              </TypographyWithIcon>
            </>
          )}
          {/* Add more Drug */}
          {selectedState === "verification" && (
            <IconButton
              className={classes.iconButton}
              disableRipple
              {...bindTrigger(addPopupState)}
            >
              <AddCircleIcon color="primary" />
            </IconButton>
          )}

          {isCompound && (
            <Tooltip title="Work Sheet" arrow>
              <div
                style={{ marginTop: "4px" }}
                onClick={() => handlePrintWorkSheet(drug)}
              >
                <PrinterIcon />
              </div>
            </Tooltip>
          )}
          <div className={classes.dispenseDurationInput}>
            <StyledInput
              id={"Dispense_Quantity"}
              type={"text"}
              variant={"outlined"}
              size={"small"}
              fullWidth={true}
              value={dispenseDuration}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                onDispenseDurationChange(e.target.value);
              }}
              disabled={selectedState === "verification" ? false : true}
              endAdornment={<Typography>{dispenseDurationUOM}</Typography>}
            ></StyledInput>
          </div>
          {!props?.isOnHold && (
            <Tooltip title="Apply" arrow>
              <IconButton
                className={classes.iconContainer}
                onClick={
                  checkDosageFulfilled(orderValue)
                    ? onApplyDispenseDurationClick
                    : () => {
                        setSnack({
                          open: true,
                          severity: AlertProps.severity.error,
                          msg: "Dispense Dosage is not fullfilled!",
                          vertical: AlertProps.vertical.top,
                          horizontal: AlertProps.horizontal.right,
                          tone: true,
                        });
                      }
                }
              >
                <SvgIcon
                  component={ForwardIconSmall}
                  color="#fff"
                  viewBox="-5 -7 20 20"
                  style={{
                    width: "20px",
                    height: "20px",
                    transform: "rotate(-90deg)",
                    color: "#fff",
                  }}
                />
              </IconButton>
            </Tooltip>
          )}

          {props?.isOnHold && (
            <Tooltip title="Can you please release the on-hold order" arrow>
              <div
                style={{
                  padding: "3px 6px",
                  background: "#3f51b5",
                  color: "#fff",
                  borderRadius: 8,
                  cursor: "pointer",
                }}
              >
                Hold
              </div>
            </Tooltip>
          )}

          {!props?.isOnHold && (
            <div onClick={() => props.isMultiOrderSelectFuntion(props.drug)}>
              {isSelect(props.drug) ? (
                <CheckCircleIcon className={classes.selectOrder} />
              ) : (
                <CheckCircleOutlineIcon className={classes.selectOrder} />
              )}
            </div>
          )}
        </div>
      </div>

      <StyledDialog
        id="compound_work_sheet"
        open={openWorkSheet}
        handleClose={onCloseWorkSheet}
        title={"Compound Work Sheet"}
        dialogProps={{
          classes: dialogStyleWorksheet,
        }}
        contentProps={{
          classes: dialogContentStyles,
        }}
      >
        <Document file={binderData.downloadUrl}>
          <Page pageNumber={1} />
        </Document>
      </StyledDialog>

      <div className={classes.descriptionRow}>
        <AdditionalDescription
          type={getType(isIntravenous, isCompound)}
          infusionRate={infusionRate}
          infusionTime={infusionTime}
          dosage={dosage}
          typeOfInfusion={typeOfInfusion}
          duration={duration}
          occurStartDateTime={occurStartDateTime}
          occurEndDateTime={occurEndDateTime}
          CompoundQuantity={CompoundQuantity}
          CompoundQuantityUnit={CompoundQuantityUnit}
          CompoundStatbility={CompoundStatbility}
          batchDrugStability={batchDrugStability}
          selectedState={selectedState}
          batchNo={batchNo}
          onChangeBatch={onChangeBatch}
        />
      </div>
      {!isSingleLine && (
        <div className={classes.drugComponentRow}>
          <Grid container spacing={2}>
            {orderedDrugs?.map((item, index) => (
              <Grid item xs={6}>
                <DrugComponent
                  key={item?.uniqueId}
                  isBaseDrug={index === 0 && isIntravenous}
                  onBatchCountClick={handleBatchDialogToggle}
                  drugName={item?.drugNameLong}
                  issueQuantity={item?.totadmqty}
                  issueUom={item?.totadmuom}
                  Stock={item?.batches?.[0]?.availableQuantity}
                  showDelete={
                    (getType(isIntravenous, isCompound) === "UD" ||
                      getType(isIntravenous, isCompound) === "IV") &&
                    index > 0 &&
                    selectedState === "verification"
                  }
                  allowChangeDrug={
                    getType(isIntravenous, isCompound) === "UD" &&
                    selectedState === "verification"
                  }
                  handleDelete={() => handleDelete(index)}
                  options={item?.option}
                  handleChangebrandName={(value) => {
                    handleChangebrandName(value, index, item?.id);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      {/* -----------------------Batch Dialog Starts---------------------- */}
      <StyledDialog
        id="batch_dialog"
        open={isBatchDialogOpen}
        handleClose={handleBatchDialogToggle}
        title={"DISPENSE DETAILS"}
        dialogProps={{
          classes: dialogStyles,
        }}
        contentProps={{
          classes: dialogContentStyles,
        }}
      >
        <DrugSelectorByType
          {...props}
          brands={brands}
          compounds={compounds}
          addictives={addictive}
          type={getType(isIntravenous, isCompound)}
          disptype={disptype}
          onBatchQuantityChange={onBatchQuantityChange}
          orderedDrugName={name}
          orderedDosage={dosage}
          directions={directions}
          handleDirectionSaveClick={handleDirectionSaveClick}
          isVerficationState={selectedState === "verification" ? true : false}
          drug={drug}
          printLabel={printLabel}
        />
      </StyledDialog>
      {/* -----------------------Batch Dialog Ends---------------------- */}

      {/* Menu for Add Icon */}
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...bindMenu(addPopupState)}
      >
        {addDrugOption?.map((option) => {
          return (
            <MenuItem
              onClick={() => {
                handleAdd(option);
                addPopupState.close();
              }}
            >
              {option?.longdesc}
            </MenuItem>
          );
        })}
      </Menu>

      {/* Menu for Print Icon */}
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...bindMenu(printPopupState)}
      >
        <MenuItem onClick={() => {}}>Print</MenuItem>
        <MenuItem onClick={() => {}}>Print & Copy</MenuItem>
      </Menu>
    </StyledPaper>
  );
}

export default OrderDescription;

OrderDescription.propTypes = {
  isIntravenous: PropTypes.bool,
  route: PropTypes.string,
  name: PropTypes.string,
  dosage: PropTypes.string,
  frequency: PropTypes.string,
  duration: PropTypes.string,
  doctorName: PropTypes.string,
  speciality: PropTypes.string,
};
