import React from "react";
import { Div, Paper, H6, Text, Image } from "qdm-component-library";
import "../../practitionermaster/addPractotioner/style.css";
import { withAllContexts } from "../../../HOCs";
import { AlertProps, getFormatForDateTimePicker } from "../../../utils";
import AddNewTraining from "./addNewTraining";
import ShowList from "./showList";
import moment from "moment";
import { CheckIcon } from "../../../assets";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { withStyles } from "@material-ui/core";
import format from "date-fns/format"

const style = (theme) => ({
  piPaperBackground: {
    background: theme.palette.background.table,
  },
});

class Training extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Showfilter: this.props?.[this.props.saveto]?.names,
      limited: false,
      Collapse: false,
      addCollapseForm: false,
      contact: [{}],
    };
  }

  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
  };
  onChangeState = (key, value, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  validateCheck = (data) => {
    // const contactVal = () => {
    //   if(data.contact?.length === 0){
    //     return true;
    //   }else{
    //      let error = data.contact.map(val => val.mobile?.length > 0 ? true : false );
    //      return !error.includes(false);
    //   }
    // }
    if (
      data?.organization?.length > 0 &&
      data?.responsible_person?.length > 0 &&
      data?.course_name?.length > 0
    ) {
      return false;
    } else {
      let errors = {
        organization: data?.organization?.length > 0 ? false : true,
        responsible_person: data?.responsible_person?.length > 0 ? false : true,
        course_name: data?.course_name?.length > 0 ? false : true,
      };
      this.setState({ ...this.state, erorr: errors });
      return true;
    }
  };

  saveAddress = (data) => {
    if (this.validateCheck(data)) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }
    let patientData = this.props[this.props.saveto];
    if (this.state?.editindex !== null) {
      patientData.training[this.state?.editindex] = data;
    } else {
      patientData.training.push(data);
    }
    patientData[this.props.setTo]({
      ...patientData,
    });
    this.Clear();
  };

  Clear = () => {
    this.setState({
      select_role: null,
      speciality: null,
      select_entity: null,
      entity_name: null,
      select_location: null,
      start_date: null,
      end_date: null,
      addCollapseForm: false,
      contact: [{}],
    });
  };

  FlterOptions = (type) => {
    const { allMasters } = this.props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      var datatemp = {
        value: data[index]?.coding?.display,
      };
      lista.push(datatemp);
    }
    return lista;
  };
  OpenAddress = async (e) => {
    this.setState({
      addAddress: e?.currentTarget ?? null,
    });
  };
  handleClear = () => {
    this.setState({
      editadd: {},
      editindex: null,
      addCollapseForm: false,
    });
  };

  Collapse(e) {
    this.setState({
      Collapse: !this.state.Collapse,
    });
  }

  addCollapse(e) {
    this.setState({
      addCollapseForm: !this.state.addCollapseForm,
      Collapse: true,
    });
  }

  onedit = async (v, i, e) => {
    this.setState({
      ...this.state,
      ...v,
      editadd: { ...v },
      editindex: i,
    });
    this.addCollapse(e);
  };

  ondelete = (v, i) => {
    let patientData = this.props[this.props.saveto];
    patientData.training.splice(i, 1);
    patientData[this.props.setTo]({
      ...patientData,
    });
    this.Clear();
  };

  render() {
    const { classes } = this.props;
    let { addCollapseForm, Collapse } = this.state;
    const { training, parent_id } = this.props[this.props.saveto];

    return (
      <Div id={`${parent_id}-parent-div`} className="pi-root">
        <Paper
          id={`${parent_id}-parent-paper`}
          className={`pi-paper ${classes.piPaperBackground}`}
        >
          <Div
            id={`${parent_id}-title-div`}
            style={{ display: "flex", alignItems: "center" }}
          >
            <H6
              id={`${parent_id}-title-h6`}
              className="pi-title"
              style={{
                display: "flex",
                alignItems: "center",
                margin: 0,
                flex: 1,
              }}
            >
              Training
              {training?.length > 0 && (
                <>
                  {" (" + JSON.stringify(training?.length) + ")"}
                  {/* <Image
                  id={`${parent_id}-tick-image`}
                  style={{ width: 16, height: 16, marginLeft: 5 }}
                  src="images/icons8-ok.svg"
                  alt="tick"
                /> */}
                  <CheckIcon style={{ width: 16, height: 16, marginLeft: 5 }} />
                </>
              )}
              {/* )} */}
            </H6>
            <Text
              id={`${parent_id}-Add-New-text`}
              inLineStyles={{
                color: "#0071F2",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
              }}
              className="pi-addbtn"
            >
              {addCollapseForm ? (
                <Text
                  className="pi-addbtn"
                  style={{ marginRight: 10, color: "gray" }}
                >
                  + Add New
                </Text>
              ) : (
                <Text
                  id={`${parent_id}-addCollapseForm-Add-New-text`}
                  className="pi-addbtn"
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    this.handleClear();
                    this.addCollapse(e);
                  }}
                >
                  + Add New
                </Text>
              )}
              {training?.length > 0 && (
                <React.Fragment>
                  {Collapse ? (
                    //  <i onClick={(e) => this.addCollapse(e)} className="fa fa-angle-down ad-arrow" aria-hidden="true" /> : <i className="fa fa-angle-up ad-arrow" aria-hidden="true" onClick={(e) => this.addCollapse(e)} />}
                    // <Image
                    //   id={`${parent_id}-downArrow-image`}
                    //   src="images/Group 3873.svg"
                    //   style={{ width: 20, height: 20 }}
                    //   alt="downArrow"
                    //   onClick={(e) => this.Collapse(e)}
                    // />
                    <ExpandLess onClick={(e) => this.Collapse(e)} />
                  ) : (
                    <>
                      {/* <Image
                        id={`${parent_id}-upArrow-image`}
                        style={{ width: 20, height: 20 }}
                        src="images/Group -1.svg"
                        alt="upArrow"
                        onClick={(e) => this.Collapse(e)}
                      /> */}
                      <ExpandMore onClick={(e) => this.Collapse(e)} />
                    </>
                  )}
                </React.Fragment>
              )}
            </Text>
          </Div>
          {Collapse && training?.length > 0 && (
            <Div
              id={`${parent_id}-Collapse-div`}
              style={{ paddingTop: "10px" }}
            >
              {training?.map((val, i) => {
                return (
                  <div
                    id={`${parent_id}-${i}-ShowList-div`}
                    style={{ marginBottom: 12 }}
                  >
                    <ShowList
                      parent_id={"training" + i}
                      index={i + 1}
                      title={val?.course_name ?? ""}
                      description={
                        (val?.organization?.length > 0
                          ? val?.organization
                          : "") +
                        (val?.responsible_person?.length > 0
                          ? ", " + val?.responsible_person
                          : "") +
                        (val.course_start_date
                          ? ", " +
                            format(
                              new Date(val.course_start_date),
                              getFormatForDateTimePicker("date")
                            )
                          : "") +
                        (val.course_end_date
                          ? ", " +
                            format(
                              new Date(val.course_end_date),
                              getFormatForDateTimePicker("date")
                            )
                          : "") +
                        (val.certificate_issue_date
                          ? ", " +
                            format(
                              new Date(val.certificate_issue_date),
                              getFormatForDateTimePicker("date")
                            )
                          : "") +
                        (val.certificate_expiry_date
                          ? ", " +
                            format(
                              new Date(val.certificate_issue_date),
                              getFormatForDateTimePicker("date")
                            )
                          : "")
                      }
                      ondelete={() => this.ondelete(val, i)}
                      onedit={(e) => this.onedit(val, i, e)}
                      onswitch={false}
                    />
                  </div>
                );
              })}
            </Div>
          )}

          {addCollapseForm && (
            <Div id={`${parent_id}-AddNew-div`}>
              <AddNewTraining
                parent_id={"AddNewTraining"}
                saveAddress={this.saveAddress}
                allMasters={this.props?.allMasters}
                handleClear={this.handleClear}
                index={this.state.editindex}
                editData={this.state.editadd}
                setTo={this.props.setTo}
                saveto={this.props.saveto}
                onclose={() => this.OpenAddress(null)}
                erorr={this.state.erorr}
              />
            </Div>
          )}
        </Paper>
      </Div>
    );
  }
}

export default withAllContexts(withStyles(style)(Training));
