import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";

export const SearchInputBox = withStyles((theme) => ({
  root: {
    flex: 0,
    gap: 10,
    padding: "4px 8px",
    background: "#F1F6FC",
    borderRadius: "4px",
    border: "1px solid #DEE5EC",
    "& .App1-MuiInputBase-input": {
      minWidth: 20,
    },
  },
  input: {
    padding: 0,
    "&:focus": {
      backgroundColor: "unset",
    },
  },
}))(InputBase);
