import { AlertProps } from "../../../utils";

export const getDynamicContent = (isFormModeEdit) => {
  let dynamicContent = {};
  if (isFormModeEdit) {
    dynamicContent = {
      title: "Edit Existing Frequency Schedule",
      buttonText: "Update",
    };
  } else {
    dynamicContent = {
      title: "Add New Frequency Schedule",
      buttonText: "Save",
    };
  }

  return dynamicContent;
};

export const frequencyTypes = {
  dayBased: "CodingMaster/10925",
  detail: "CodingMaster/10923",
  interval: "CodingMaster/10924",
  sequential: "CodingMaster/10927",
  userDefined: "CodingMaster/10926",
};

export const durationUOM = {
  Days: "CodingMaster/10930",
  Hours: "CodingMaster/10928",
  Months: "CodingMaster/10932",
  Times: "CodingMaster/10929",
  Weeks: "CodingMaster/10931",
  Years: "CodingMaster/10933",
};

export const errorMessages = {
  duplicateFrequencyCode: "Frequency Code Already Exists.",
  scheduleLineLacksSufficiency:
    "Number of Schedule Lines is not sufficient for the selected Frequency Type.",
  scheduleLineExceedsSufficiency:
    "Number of Schedule Lines exceeds the maximum allowed.",
  scheduleLineMaxReached: "Maximum Schedule line limit reached.",
  scheduleLineDataInvalid: "Please fill all the fields in Schedule Line.",
  userDefinedFromToDayInvalid:
    "From Day should not exceed To Day in Schedule line.",
  userDefinedOverlap: "From day and To day should not overlap.",
  requiredFields: (fields) =>
    fields?.length === 1
      ? `${fields?.join(", ")} is required field`
      : `${fields?.join(", ")} are required fields`,
  invalidTimeFormat: "Please enter a valid time format",
  default: "Something went wrong!",
};

export const successMessages = {
  frequencyUpdateSuccess: "Frequency Schedule Updated Successfully.",
  frequencySaveSuccess: "Frequency Schedule Added Successfully.",
  default: "Success!",
};

export const getSnackObject = {
  open: (
    errorMessage = errorMessages.default,
    successMessage = successMessages.default
  ) => {
    return {
      open: true,
      severity: errorMessage
        ? AlertProps.severity.error
        : AlertProps.severity.success,
      msg: errorMessage || successMessage,
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
      tone: true,
    };
  },
  close: () => {
    return {
      open: false,
      msg: "",
    };
  },
};

// export const testNumericField = (value, maxlength = "") => {
//   if (value === "") {
//     return true;
//   }
//   const expression = "/^[1-9][0-9]{0," + maxlength + "}$/";
//   const regex = new RegExp(expression);
//   if (regex.test(value)) {
//     return true;
//   }

//   return false;
// };

export const isValidUserDefinedSchedule = (array) => {
  let isValid = true;
  array.forEach((item) => {
    if (item.fromDay > item.toDay) {
      isValid = false;
    }
  });
  return isValid;
};

export const findOverlapForUserDefinedType = (array) => {
  let isOverlap = false;
  for (let i = 0; i < array.length; i++) {
    for (let j = i + 1; j < array.length; j++) {
      if (
        (array[i].fromDay >= array[j].fromDay &&
          array[i].fromDay <= array[j].toDay) ||
        (array[i].toDay >= array[j].fromDay &&
          array[i].toDay <= array[j].toDay) ||
        (array[i].fromDay <= array[j].fromDay &&
          array[i].toDay >= array[j].toDay)
      ) {
        isOverlap = true;
        break;
      }
    }
  }
  return isOverlap;
};

export const shouldShowScheduleLine = (frequencyType, onceEveryUOM) => {
  const toBeHiddenUOM = [durationUOM.Hours, durationUOM.Times];

  if (frequencyType?._id === frequencyTypes.interval) {
    if (toBeHiddenUOM.includes(onceEveryUOM?._id)) {
      return false;
    }
  }

  return true;
};
