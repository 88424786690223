import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";
import makeStyles from "@material-ui/core/styles/makeStyles";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  Avatar,
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import qdmstyles from "./styles.json";
import "./index.css";
var aes256 = require("aes256");


const useStyles = makeStyles((theme) => ({
  editchildtitle: {
    color: theme.palette.primary.light
  },
  editcontact: {
    color: theme.palette.text.primary,
  },
  autocomplete: {
    "& .App1-MuiOutlinedInput-notchedOutline":{
      borderColor:theme.palette.border.textField
    },
   },
   paper: {
     color:"#6a7888"
   },
   notchedOutline:{
    borderColor:theme.palette.border.textField
  },
  mobileValue:{
    border:"1px solid",
    borderColor:theme.palette.border.textField,
    borderRadius:"4px"
  },
  dividerColor:{
    backgroundColor:theme.palette.border.textField,
  },
}));

function Editcontactpoint(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();

  const pharmacy_contact_contactmode_dropdownData = useSelector(
    (state) =>
      state?.editcontactpointpharmacy_contact_contactmode_dropdown_zorJVReducer
        ?.pharmacy_contact_contactmode_dropdown_zorJV?.data
  );
  const pharmacy_contact_use_dropdownData = useSelector(
    (state) =>
      state?.editcontactpointpharmacy_contact_use_dropdown_zorJVReducer
        ?.pharmacy_contact_use_dropdown_zorJV?.data
  );
  const pharmacy_contact_priority_dropdownData = useSelector(
    (state) =>
      state?.editcontactpointpharmacy_contact_priority_dropdown_zorJVReducer
        ?.pharmacy_contact_priority_dropdown_zorJV?.data
  );

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
    editcontactpoint_zorjv: null,
    editcontactpoint_8nvk4: null,
    editcontactpoint_slpbw: null,
    editcontactpoint_tqcwn: null,
    editcontactpoint_e5hnz: null,
    editcontactpoint_1gu0d: null,
    editcontactpoint_tbhrq: null,
    editcontactpoint_7gob3: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const onload_zorjv = async (event, data) => {
    let index = data;
    const payload0 = {
      history: props.history.location.state,
      data: data,
    };
    let data0 = await dispatch(
      actions.editcontactpointpharmacy_contact_contactmode_dropdown_zorJV(
        payload0
      )
    );
    const payload1 = {
      history: props.history.location.state,
      data: data,
    };
    let data1 = await dispatch(
      actions.editcontactpointpharmacy_contact_use_dropdown_zorJV(payload1)
    );
    const payload2 = {
      history: props.history.location.state,
      data: data,
    };
    let data2 = await dispatch(
      actions.editcontactpointpharmacy_contact_priority_dropdown_zorJV(payload2)
    );

    let updatestate = {};
    setState({
      ...state,
      ...updatestate,
      editcontactpoint_zorjv: event?.target?.value,
    });
  };
  const onclick_8nvk4 = (e, index) => {
    let updatestate = {};

    setState({
      ...state,
      ...updatestate,
      editcontactpoint_8nvk4: e?.target?.value,
    });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];
    props.setFun([...stateValue, {}]);
  };
  const onclick_slpbw = (e, index) => {
    if (location.state?.isdisabled === "inactive") {
      return
    } else {
      let updatestate = {};

      setState({
        ...state,
        ...updatestate,
        editcontactpoint_slpbw: e?.target?.value,
      });
      let stateValue = props.statevalue;
      stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];
      stateValue.splice(index, 1);
      props.setFun([...stateValue]);
    }
  };
  const onchange_tqcwn = (e, index) => {
    let updatestate = {};

    setState({ ...state, ...updatestate, editcontactpoint_tqcwn: e });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = {
      ...stateValue[index],
      editcontactpoint_tqcwn: e,
      editcontactpoint_1gu0d: "",
    };

    props.setFun(stateValue);
  };
  const onchange_e5hnz = (e, index) => {
    let updatestate = {};

    setState({ ...state, ...updatestate, editcontactpoint_e5hnz: e });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = { ...stateValue[index], editcontactpoint_e5hnz: e };
    props.setFun(stateValue);
  };
  const onchange_1gu0d = (e, index, data) => {

    if (
      data?.editcontactpoint_tqcwn?.display === "FAX" ||
      data?.editcontactpoint_tqcwn?.display === "EMAIL" ||
      data?.editcontactpoint_tqcwn?.display === "URL" ||
      data?.editcontactpoint_tqcwn?.display === "OTHER" ||
      data?.editcontactpoint_tqcwn?.display === "SMS"
    ) {
      if (e?.target?.value?.length <= 50) {

        let updatestate = {};

        setState({
          ...state,
          ...updatestate,
          editcontactpoint_1gu0d: e?.target?.value,
        });
        let stateValue = props.statevalue;
        stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

        stateValue[index] = {
          ...stateValue[index],
          editcontactpoint_1gu0d: e?.target?.value,
        };
        props.setFun(stateValue);
      }
    } else {

      let updatestate = {};

      setState({
        ...state,
        ...updatestate,
        editcontactpoint_1gu0d: e?.target?.value,
      });
      let stateValue = props.statevalue;
      stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

      stateValue[index] = {
        ...stateValue[index],
        editcontactpoint_1gu0d: e?.target?.value,
      };
      props.setFun(stateValue);
    }

  };
  const onchange_tbhrq = (e, index) => {
    let updatestate = {};

    setState({ ...state, ...updatestate, editcontactpoint_tbhrq: e });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = { ...stateValue[index], editcontactpoint_tbhrq: e };
    props.setFun(stateValue);
  };
  const onchange_7gob3 = (e, index) => {
    let updatestate = {};

    setState({ ...state, ...updatestate, editcontactpoint_7gob3: e });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = { ...stateValue[index], editcontactpoint_7gob3: e };
    props.setFun(stateValue);
  };
  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events
    onload_zorjv();
    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {
    if (
      pharmacy_contact_priority_dropdownData?.loading !==
      pharmacy_contact_priority_dropdownData?.loading
    ) {
      if (
        !pharmacy_contact_priority_dropdownData?.loading &&
        pharmacy_contact_priority_dropdownData.error
      ) {
      } else if (
        !pharmacy_contact_priority_dropdownData?.loading &&
        !pharmacy_contact_priority_dropdownData.error
      ) {
      }
    }
  }, [state]);

  const { data, index } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"YrJKf"}
            container={true}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Grid
              key={"0"}
              id={"6EVn4"}
              container={""}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"flex-end"}
              xs={"6"}
              xl={"6"}
              sm={"6"}
              md={"6"}
              lg={"6"}
            >
              <Typography
                key={"0"}
                id={"BuMXV"}
                align={"inherit"}
                color={"initial"}
                className={classes.editcontact}
                display={"initial"}
                variant={"body1"}
                children={t("pharmacy.editNewDrugCatalogMaster.contactPoints")}
                style={qdmstyles.Ciybd}
              ></Typography>
            </Grid>
            <Grid
              key={"1"}
              id={"GOaqU"}
              container={true}
              direction={"row"}
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
              xs={"6"}
              xl={"6"}
              sm={"6"}
              md={"6"}
              lg={"6"}
            >
              <Button
                key={"0"}
                onClick={(e) => onclick_8nvk4(e, index)}
                id={"yYkqp"}
                variant={"text"}
                color={"primary"}
                children={"+ Add New Contact"}
                style={qdmstyles.NvK}
                disabled={location.state?.isdisabled === "inactive" ? true : false}
              ></Button>
            </Grid>
          </Grid>
          <Grid
            key={"0"}
            id={"VqEMp"}
            container={true}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            spacing={"2"}
            style={qdmstyles.zorJV}
            onLoad={(e) => props.setFun(props?.statevalue ?? [{}])}
          >
            {(props?.statevalue ?? [{}])?.map((data, index) => {
              return (
                <React.Fragment>
                  <Grid
                    key={"0"}
                    id={"7Nzhv"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"3"}
                    item={true}
                  >
                    <Typography
                      key={"0"}
                      id={"contact_mode_header"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      className={classes.editchildtitle}
                      variant={"body1"}
                      children={
                        <span>
                          Contact Mode
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      aliasName={"contactmodeheader"}
                      style={qdmstyles.guDMf}
                    ></Typography>
                    <Autocomplete
                      id={"TQCWN"}
                      style={qdmstyles?.TQCWN}
                      value={
                        data?.editcontactpoint_tqcwn
                          ? data?.editcontactpoint_tqcwn
                          : data?.system
                            ? data?.system
                            : {}
                      }
                      classes={{
                        paper: classes.paper
                      }}
                      className={classes.autocomplete}
                      onLoad={() =>
                        setState({
                          editcontactpoint_tqcwn:
                            state?.editcontactpoint_tqcwn ?? null,
                        })
                      }
                      disabled={location.state?.isdisabled === "inactive" ? true : false}
                      getOptionLabel={(option) => option?.["display"]}
                      options={
                        pharmacy_contact_contactmode_dropdownData.result ?? []
                      }
                      size={"small"}
                      onChange={(e, newvalue) =>
                        onchange_tqcwn(newvalue, index)
                      }
                      renderInput={(params) => (
                        <ALLMCORE.TextField
                          disabled={location.state?.isdisabled === "inactive" ? true : false}
                          {...params}
                          label={""}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"iayzf"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"4"}
                    item={true}
                  >
                    <Typography
                      key={"0"}
                      id={"mobile_header"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      className={classes.editchildtitle}
                      variant={"body1"}
                      children={
                        <span>
                          {data?.editcontactpoint_tqcwn?.display || "Phone"}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      aliasName={"mobileheader"}
                      style={qdmstyles.XTN}
                    ></Typography>
                    <Grid
                      key={"1"}
                      id={"mobile_value"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      aliasName={"mobilevalue"}
                      style={qdmstyles.FRfF}
                    >
                      {((data?.editcontactpoint_tqcwn?.display?.toLowerCase() ||
                        data?.system?.display?.toLowerCase()) === "phone" ||
                        (data?.editcontactpoint_tqcwn?.display?.toLowerCase() ||
                          data?.system?.display?.toLowerCase()) === "mobile" ||
                        (data?.editcontactpoint_tqcwn?.display?.toLowerCase() ||
                          data?.system?.display?.toLowerCase()) === "fax" ||
                        (data?.editcontactpoint_tqcwn?.display?.toLowerCase() ||
                          data?.system?.display?.toLowerCase()) === "sms" ||
                        !data?.editcontactpoint_tqcwn?.display) && (
                          <Grid
                            key={"0"}
                            id={"etDh7"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"3"}
                            md={"2"}
                            sm={"2"}
                            item={true}
                            xl={"2"}
                            xs={"2"}
                          >
                            <Autocomplete
                              id={"e5Hnz"}
                              style={qdmstyles?.eHnz}
                              disabled={location.state?.isdisabled === "inactive" ? true : false}
                              onLoad={() =>
                                setState({
                                  editcontactpoint_e5hnz:
                                    state?.editcontactpoint_e5hnz ?? null,
                                })
                              }
                              getOptionLabel={(option) => option?.["label"]}
                              options={[] ?? []}
                              value={{ label: "+ 91" }}
                              size={"small"}
                              onChange={(e, newvalue) =>
                                onchange_e5hnz(newvalue, index)
                              }
                              classes={{
                                paper: classes.paper
                              }}
                              className={classes.autocomplete}
                              class={"outlined"}
                              renderInput={(params) => (
                                <ALLMCORE.TextField
                                  // disabled={location.state?.isdisabled === "inactive" ? true : false}
                                  {...params}
                                  variant="outlined"
                                  class={"outlined"}
                                />
                              )}
                            />
                          </Grid>
                        )}
                      {((data?.editcontactpoint_tqcwn?.display?.toLowerCase() ||
                        data?.system?.display?.toLowerCase()) === "phone" ||
                        (data?.editcontactpoint_tqcwn?.display?.toLowerCase() ||
                          data?.system?.display?.toLowerCase()) === "mobile" ||
                        (data?.editcontactpoint_tqcwn?.display?.toLowerCase() ||
                          data?.system?.display?.toLowerCase()) === "fax" ||
                        (data?.editcontactpoint_tqcwn?.display?.toLowerCase() ||
                          data?.system?.display?.toLowerCase()) === "sms" ||
                        (!data?.editcontactpoint_tqcwn?.display &&
                          data?.system?.display?.toLowerCase() === "phone") ||
                        data?.system?.display?.toLowerCase() === "mobile" ||
                        (!data?.editcontactpoint_tqcwn?.display &&
                          data?.system?.display?.toLowerCase() === "fax") ||
                        (!data?.editcontactpoint_tqcwn?.display &&
                          data?.system?.display?.toLowerCase() === "sms"))
                        && (
                          <Divider
                            key={"1"}
                            id={"PR2E5"}
                            variant={"middle"}
                            flexItem={true}
                            orientation={"vertical"}
                            className={classes.dividerColor}
                            style={{ height: 26, margin: 0, marginTop: "2%" }}
                          ></Divider>
                        )}
                      <Grid
                        key={"2"}
                        id={"GHkwH"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={
                          data.editcontactpoint_tqcwn?.display?.toLowerCase() ===
                            "phone" || data.editcontactpoint_tqcwn?.display?.toLowerCase() ===
                            "fax" || data.editcontactpoint_tqcwn?.display?.toLowerCase() ===
                            "sms" || !data.editcontactpoint_tqcwn?.display
                            ? "7"
                            : "12"
                        }
                      >
                        <TextField
                          disabled={location.state?.isdisabled === "inactive" ? true : false}
                          key={"0"}
                          onChange={(e) => onchange_1gu0d(e, index, data)}
                          value={data?.editcontactpoint_1gu0d}
                          id={"OVtAT"}
                          label={""}
                          fullWidth
                          placeholder={
                            data?.editcontactpoint_tqcwn?.display || "Phone"
                            // state.editcontactpoint_tqcwn?.display ||
                            // // data.system ||
                            // "Phone"
                          }
                          onKeyPress={
                            data.editcontactpoint_tqcwn?.display?.toLowerCase() ===
                              "phone" || data.editcontactpoint_tqcwn?.display?.toLowerCase() ===
                              "fax" || data.editcontactpoint_tqcwn?.display?.toLowerCase() ===
                              "sms" || !data.editcontactpoint_tqcwn?.display
                              ? (e) => {
                                var k = e.keyCode || e.which;
                                if (k >= 48 && k <= 57) {
                                  return;
                                } else {
                                  e.preventDefault();
                                  return;
                                }
                              }
                              : (e) => null
                          }
                          inputProps={{
                            maxLength:
                              (data.editcontactpoint_tqcwn?.display?.toLowerCase() ===
                                "phone" || data.editcontactpoint_tqcwn?.display?.toLowerCase() ===
                                "fax" || data.editcontactpoint_tqcwn?.display?.toLowerCase() ===
                                "sms" || !data.editcontactpoint_tqcwn?.display) &&
                              10,
                          }}
                          // type={
                          //   (data?.editcontactpoint_tqcwn?.display?.toLowerCase() ||
                          //     data?.system?.display?.toLowerCase()) ===
                          //     "phone" ||
                          //   (data?.editcontactpoint_tqcwn?.display?.toLowerCase() ||
                          //     data?.system?.display?.toLowerCase()) ===
                          //     "mobile" ||
                          //   !data?.editcontactpoint_tqcwn?.display
                          //     ? (data?.editcontactpoint_tqcwn?.display?.toLowerCase() ||
                          //         data?.system?.display?.toLowerCase()) ===
                          //         "phone" ||
                          //       (data?.editcontactpoint_tqcwn?.display?.toLowerCase() ||
                          //         data?.system?.display?.toLowerCase()) ===
                          //         "mobile"
                          //       ? "number"
                          //       : "text"
                          //     : "text"
                          // }
                          variant={"outlined"}
                          size={"small"}
                          aliasName={"mobile_value"}
                          class={"outlined"}
                        ></TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"EcYcz"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"2"}
                    item={true}
                  >
                    <Typography
                      key={"0"}
                      id={"use_header"}
                      align={"inherit"}
                      color={"initial"}
                      className={classes.editchildtitle}
                      display={"initial"}
                      variant={"body1"}
                      children={
                        <span>
                          Use
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      aliasName={"useheader"}
                      style={qdmstyles.wUyU}
                    ></Typography>
                    <Autocomplete
                      id={"TbhRq"}
                      style={qdmstyles?.TbhRq}
                      value={
                        data?.editcontactpoint_tbhrq
                          ? data?.editcontactpoint_tbhrq
                          : data?.use
                      }
                      onLoad={() =>
                        setState({
                          editcontactpoint_tbhrq:
                            state?.editcontactpoint_tbhrq ?? null,
                        })
                      }
                      classes={{
                        paper: classes.paper
                      }}
                      className={classes.autocomplete}
                      disabled={location.state?.isdisabled === "inactive" ? true : false}
                      getOptionLabel={(option) => option?.["display"]}
                      options={pharmacy_contact_use_dropdownData.result ?? []}
                      size={"small"}
                      onChange={(e, newvalue) =>
                        onchange_tbhrq(newvalue, index)
                      }
                      renderInput={(params) => (
                        <ALLMCORE.TextField
                          {...params}
                          label={""}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    key={"3"}
                    id={"dW8aj"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"3"}
                    item={true}
                  >
                    <Typography
                      key={"0"}
                      id={"priority_header"}
                      align={"inherit"}
                      color={"initial"}
                      className={classes.editchildtitle}
                      display={"initial"}
                      variant={"body1"}
                      children={
                        <span>
                          Priority
                          <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      aliasName={"priorityheader"}
                      style={qdmstyles.ZDXBP}
                    ></Typography>
                    <Grid
                      key={"1"}
                      id={"xzvVQ"}
                      container={""}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      style={qdmstyles.PuGd}
                    >
                      <Autocomplete
                        id={"7GOb3"}
                        style={qdmstyles?.GOb}
                        value={data?.editcontactpoint_7gob3 ?? data?.rank}
                        onLoad={() =>
                          setState({
                            editcontactpoint_7gob3:
                              state?.editcontactpoint_7gob3 ?? null,
                          })
                        }
                        classes={{
                          paper: classes.paper
                        }}
                        className={classes.autocomplete}
                        disabled={location.state?.isdisabled === "inactive" ? true : false}
                        getOptionLabel={(option) => option?.["display"]}
                        options={
                          pharmacy_contact_priority_dropdownData.result ?? []
                        }
                        size={"small"}
                        onChange={(e, newvalue) =>
                          onchange_7gob3(newvalue, index)
                        }
                        fullWidth
                        renderInput={(params) => (
                          <ALLMCORE.TextField {...params} variant="outlined" />
                        )}
                      />{" "}
                      <Avatar
                        key={"1"}
                        onClick={(e) => onclick_slpbw(e, index)}
                        id={"delete_icon"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317130944"
                        }
                        aliasName={"deleteicon"}
                        style={qdmstyles.SlPBw}
                      ></Avatar>
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </React.Fragment >
      )
      }
    </NamespacesConsumer >
  );
}

export default withRouter(translate()(Editcontactpoint));
