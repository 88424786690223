import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DynamicTable } from "../../components";
// import EnhancedTable from "./component";
import { headCells, rows } from "./utils";
import { actions } from "frequencyscreen_v_dbinder";

const { ACTIVEMEDIACATION_AGAINST_PATIENT } = actions;

function Sandbox() {
  const dispatch = useDispatch();
  const reportColumns = useSelector(
    (state) => state?.phramcyFrameworkApiSlice?.activeMedication?.reportColumns
  );
  const reportRows = useSelector(
    (state) => state?.phramcyFrameworkApiSlice?.activeMedication?.reportRows
  );

  useEffect(() => {
    dispatch(
      ACTIVEMEDIACATION_AGAINST_PATIENT({
        id: "Patient/10733",
        encounter_Id: "Encounter/11143",
        reportGrpCode: "",
        reportid: "35d4f6cc-660c-4049-bcb7-f6d85b7e635c", // "77667fa9-81eb-4427-a6f7-511dbb9d321f",
        facility_id: "Organization/10002",
        location_id: "LocationMaster/10240",
      })
    );
  }, []);

  // {
  //   "reportGrpCode": "",
  //   "reportid": "77667fa9-81eb-4427-a6f7-511dbb9d321f",
  //   "queryinputs": {
  //     "activestatus": true,
  //     "patient_Id": "Patient/10733",
  //     "encounter_Id": "Encounter/11143",
  //     "facility_id": "Organization/10002",
  //     "location_id": "LocationMaster/10240"
  //   }
  // }

  // this.props.ACTIVEMEDIACATION_AGAINST_PATIENT({
  //   // id: this.props.patientData[0]._id,
  //   id: this.state?.patientId,
  //   encounter_Id:
  //     this.props.encounterData?.[this.state.activeIndex]?._id ?? "",
  //   reportGrpCode: full?.reportGrpCode,
  //   reportid: v?.reportid,
  // });
  return (
    <div style={{ padding: 20 }}>
      <DynamicTable headCells={reportColumns} rows={reportRows} />
    </div>
  );
}

export default Sandbox;
