import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drug from "./drug";
import Typography from "@material-ui/core/Typography";
import { StyledDivider } from "../../commonComponents";
import { WarningIcon } from "../../../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.25),
    border: `1px solid #DEE5EC`,
    borderRadius: theme.spacing(1),
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1.25),
  },
  leftSection: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  rightSection: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.75),
    borderRadius: theme.spacing(0.75),
    background: "#F1F6FC",
    padding: theme.spacing(0.5, 1),
  },
  greyText: {
    color: theme.palette.text.grey,
  },
}));

function DrugWrapper({ children, ...props }) {
  // <---------------Hooks------------------>
  const classes = useStyles();

  // <---------------Store States----------->

  // <-------------Component States--------->

  // <-----------------Handlers------------->

  // <-----------------Effects-------------->

  const { drugName, dosageValue, dosageUOM, type, orderedDrugName } = props;

  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <div className={classes.leftSection}>
          <Typography variant="body2">{drugName}</Typography>
          <StyledDivider orientation="vertical" flexItem />
          <Typography variant="body2" className={classes.greyText}>
            {dosageValue}
            {dosageUOM}
          </Typography>
        </div>
        {type === "IV" && (
          <div className={classes.rightSection}>
            <WarningIcon />
            <Typography variant="body2">
              {drugName} for {orderedDrugName}
            </Typography>
          </div>
        )}
      </div>
      {children}
    </div>
  );
}

export default DrugWrapper;
