import React from "react";
import { CommonTable, GenerateForm } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { AlertProps } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";

function DrugRoutes(props) {
  const dispatch = useDispatch();

  const drugRouteslist = useSelector(
    (state) => state?.drugRoutesSlice?.drug_routes_list?.data
  );
  const loading = useSelector(
    (state) => state?.drugRoutesSlice?.drug_routes_list?.loading
  );
  const saveloading = useSelector(
    (state) => state?.drugRoutesSlice?.drug_routes_upsert?.loading
  );

  React.useLayoutEffect(() => {
    dispatch(actions.DRUG_ROUTES_READ());
    dispatch(actions.DIRECTION_TAGS());
  }, []);

  const [state, setState] = React.useState({
    openEditFrom: false,
    saveloading: false,
    edit_data: null,
    status: true,
  });

  const handleCloseForm = () => {
    const { changeState } = props;
    changeState("open", false);
    setState({
      openEditFrom: false,
      edit_data: null,
    });
  };

  const handleEdit = (e, data) => {
    setState({
      openEditFrom: true,
      edit_data: data,
      status: data?.status,
    });
  };

  const saveForm = async (values) => {
    let type = "success";
    let data = {
      drug_routes: values?.drug_routes?.toUpperCase?.(),
      short_description: values.short_description,
      long_description: values.long_description,
      route_adverb: values.route_adverb,
      direction: values.direction,
      directiontaglocal: values.directiontaglocal,
      status: true,
    };
    if (state?.edit_data) {
      data["_key"] = state?.edit_data._key;
      //   data["id"] = state?.edit_data.id
      data["status"] = state?.edit_data.status;
      type = "update";
    }
    let save = await dispatch(actions.DRUG_ROUTES_UPSERT(data));
    if (save.payload?.data?.Code === 1111) {
      type = save.payload?.data?.validation_error?.[0]?.Errormsg.includes(
        "unique"
      )
        ? "unique"
        : "error";
      props.alert.setSnack(type);
    } else {
      await dispatch(actions.DRUG_ROUTES_READ());
      await handleCloseForm();
      props.alert.setSnack(type);
    }
  };

  const statusChange = async (e, values, index) => {
    let data = {
      _key: values._key,
      status: e.target.checked,
    };
    await dispatch(actions.DRUG_ROUTES_STATUS_CHANGE(data));
    await dispatch(actions.DRUG_ROUTES_READ());
    props.alert.setSnack("update");
  };

  // render() {
  const { open, openIndex, parent_id } = props;
  return (
    <React.Fragment>
      <CommonTable
        style={{ height: "calc(100vh - 245px)" }}
        parent_id={"drugroutes"}
        rightAlign={["Status", "Action"]}
        handleEdit={handleEdit}
        handleCheckBox={statusChange}
        Header={[
          "S.No",
          "Drug Route",
          "Short Description",
          "Long Description",
          "Status",
          "Action",
        ]}
        dataList={drugRouteslist}
        tableData={[
          { type: ["INCRIMENT"], name: "" },
          { type: ["TEXT"], name: "drug_routes" },
          { type: ["TEXT"], name: "short_description" },
          { type: ["TEXT"], name: "long_description" },
          { type: ["CHECKBOX"], name: "status", align: "right" },
          { type: ["EDIT"], name: "", align: "right" },
        ]}
      />

      <GenerateForm
        parent_id={"drugroutes"}
        nocancel
        // open={(open && openIndex === 5) }
        open={(open && openIndex === 5) || state?.openEditFrom ? true : false}
        handlecancel={() => handleCloseForm()}
        save={saveForm}
        header={state?.edit_data ? "Edit Drug Routes" : "Add New Drug Routes"}
        json={[
          {
            componet_type: "text",
            label: "Drug Routes",
            state_name: "drug_routes",
            value: state?.edit_data?.drug_routes ?? "",
            required: true,
            isUpperCase: true,
            disabled: state?.edit_data?.drug_routes ? true : false,
          },
          {
            componet_type: "text",
            label: "Short Description",
            state_name: "short_description",
            value: state?.edit_data?.short_description ?? "",
            required: true,
          },
          {
            componet_type: "text",
            label: "Long Description",
            state_name: "long_description",
            value: state?.edit_data?.long_description ?? "",
            required: true,
          },
          {
            componet_type: "text",
            label: "Route Adverb",
            state_name: "route_adverb",
            value: state?.edit_data?.route_adverb ?? "",
            required: true,
          },
          {
            componet_type: "direction_field",
            label: "Direction (English)",
            state_name: "direction",
            value: state?.edit_data?.direction,
          },
          {
            componet_type: "direction_field",
            label: "Direction Local Language",
            state_name: "directiontaglocal",
            value: state?.edit_data?.directiontaglocal,
          },
        ]}
      />
    </React.Fragment>
  );
}

export default withAllContexts(DrugRoutes);
