/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Providing the AlertContext from (/src/context/) which is used by /src/App.js.
 */

import React from "react";
import { Alerts as Alert } from "./components";
import { AlertProps } from "./utils";
import { AlertContext } from "./contexts";

class AppAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      severity: AlertProps.severity.success,
      msg: "",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    };
  }

  close = () => {
    this.setState({
      open: false,
      severity: AlertProps.severity.success,
      msg: "",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
  };

  set = (type, CustomMsg, props) => {
    if (typeof type === "string") {
      let message = "Something Went Wrong";
      let severity = AlertProps.severity.error;
      if (type === "success") {
        message = AlertProps.message.success;
        severity = AlertProps.severity.success;
      } else if (type === "update") {
        message = AlertProps.message.update;
        severity = AlertProps.severity.success;
      } else if (type === "delete") {
        message = AlertProps.message.delete;
        severity = AlertProps.severity.success;
      } else if (type === "mandatory") {
        message = AlertProps.message.mandatory;
        severity = AlertProps.severity.error;
      } else if (type === "processing") {
        message = AlertProps.message.processing;
        severity = AlertProps.severity.info;
      } else if (type === "unique") {
        message = AlertProps.message.unique;
        severity = AlertProps.severity.error;
      } else if (type === "error") {
        message = AlertProps.message.error;
        severity = AlertProps.severity.error;
      }
      if (CustomMsg) {
        message = CustomMsg;
      }
      this.setState({
        open: true,
        severity: severity,
        msg: message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        ...props,
      });
    } else {
      this.setState({ ...type });
    }
  };

  render() {
    return (
      <AlertContext.Provider
        value={{
          ...this.state,
          onclose: this.close,
          setSnack: this.set,
        }}
      >
        {this.state.open ? <Alert {...this.state} onclose={this.close} /> : ""}
        {this.props.children}
      </AlertContext.Provider>
    );
  }
}

export default AppAlert;