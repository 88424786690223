import { makeStyles } from "@material-ui/core/styles";

export const useDividerStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#B4BBC3",
  },
  flexItem: {
    height: "18px",
    alignSelf: "center",
  },
}));

export const useDividerStyles2 = makeStyles((theme) => ({
  root: {
    backgroundColor: "#B4BBC3",
  },
  flexItem: {
    height: "1px",
    alignSelf: "center",
    background: "#DEE5EC !important",
  },
}));

export const useDialogStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 1096,
    borderRadius: 8,
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
  },
}));

export const useDialogStylesWorksheet = makeStyles((theme) => ({
  paper: {
    minWidth: 800,
    borderRadius: 8,
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
  },
}));

export const useDialogContentStyles = makeStyles((theme) => ({
  dividers: {
    padding: 0,
    borderColor: "rgba(222, 229, 236, 1)",
  },
}));
