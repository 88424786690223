import React from "react";
import OrderSetForm from "./form";
import {
  VerticalList,
  CommonTable,
  OverlayCompt, 
} from "../../../../components";
import { withAllContexts } from "../../../../HOCs";
import { connect } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { AlertProps } from "../../../../utils";
import VisibilityIcon from "@material-ui/icons/Visibility";
import OrderSetApplicabilityTabel from "../../odersetapplicability";

const dummyTabelData = [
  {
    id: 1,
    orderSetCode: "ADM-CARDIAC",
    shortDescription: "Medical Assistant",
    longDescription: "Medical Assistant",
    orderSetApplicability: "icon",
    status: false,
  },
  {
    id: 2,
    orderSetCode: "PN",
    shortDescription: "Advanced Practice Nurse",
    longDescription: "Advanced Practice Nurse",
    orderSetApplicability: "icon",
    status: true,
  },
  {
    id: 3,
    orderSetCode: "RN",
    shortDescription: "Registered Nurse",
    longDescription: "Registered Nurse",
    orderSetApplicability: "icon",
    status: true,
  },
  {
    id: 4,
    orderSetCode: "PHARMD",
    shortDescription: "Doctor Of Pharmacy",
    longDescription: "Doctor Of Pharmacy",
    orderSetApplicability: "icon",
    status: true,
  },
];

class OrderSetMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      openForm_: false,
      editData: null,
      editShortDes: "",
      edit_id: "",
    };
    this.handleForm = this.handleForm.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleView = this.handleView.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }
  async componentDidMount() {
    await this.props.GET_ORDER_SET_LIST();
  }
  handleForm = async () => {
    let { openForm } = this.state;
    this.setState({
      openForm: !openForm,
      editData: null,
    });
  };
  handleForm_ = async () => {
    let { openForm_ } = this.state;
    this.setState({
      openForm_: !openForm_,
      editData: null,
    });
  };
  saveForm = async () => {
    await this.props.GET_ORDER_SET_LIST();
    let { openForm } = this.state;
    this.setState({
      openForm: !openForm,
      editData: null,
    });
  };
  handleEdit = async (e, data, index) => {
    let { openForm, editData } = this.state;
    this.setState({
      openForm: !openForm,
      editData: data?._key,
    });
  };
  handleView = (e, data, index) => {
    let { openForm_ } = this.state;
    this.setState({
      openForm_: !openForm_,
      editData: data?._id,
      editShortDes: data?.shortDesc,
      edit_id: data?._id,
    });
  };
  handleCheckBox = async (e, data, index) => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Status Updating...",
    });
    await this.props.ORDER_SET_MASTER_STATUS_CHANGE({
      _key: data._key,
      status: e.target.checked,
    });
    await this.props.GET_ORDER_SET_LIST();
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
    this.props.alert.setSnack("update");
  };
  render() {
    const { openForm, openForm_, editData } = this.state;
    return (
      <React.Fragment>
        <VerticalList
          parent_id={"consult_master"}
          addFunc={this.handleForm}
          TabList={[
            {
              name: "Order Set",
              component: (
                <CommonTable
                  parent_id={"consult_master"}
                  rightAlign={["Status", "Action"]}
                  handleEdit={this.handleEdit}
                  handleCheckBox={this.handleCheckBox}
                  handleView={this.handleView}
                  Header={[
                    "S.No",
                    "Order Set Code",
                    "Short Description",
                    "Long Description",
                    "Order Set Applicability",
                    "Status",
                    "Action",
                  ]}
                  dataList={this.props?.orderSetMasterList?.data ?? []}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "orderSetCode" },
                    { type: ["TEXT"], name: "shortDesc" },
                    { type: ["TEXT"], name: "longDesc" },
                    {
                      type: ["VIEWICON"],
                      name: "",
                      icon: (
                        <VisibilityIcon
                          style={{
                            color: "#0071F2",
                            fontSize: 16,
                            marginRight: "5px",
                          }}
                        />
                      ),
                      label: "View",
                      // toolTip: {
                      //   title: "Draft",
                      //   placement: "top",
                      //   arrow: true,
                      // },
                    },
                    { type: ["CHECKBOX"], name: "status", align: "right" },
                    { type: ["EDIT"], name: "", align: "right" },
                  ]}
                />
              ),
            },
          ]}
        />
        <OverlayCompt
          parent_id={"consult-master"}
          open={openForm}
          children={
            <React.Fragment>
              {openForm && (
                <OrderSetForm
                  parent_id={"consult-master"}
                  editData={editData}
                  closeForm={this.handleForm}
                  saveForm={this.saveForm}
                />
              )}
            </React.Fragment>
          }
        />
        <OverlayCompt
          parent_id={"consult-master"}
          open={openForm_}
          children={
            <React.Fragment>
              {openForm_ && (
                <OrderSetApplicabilityTabel
                  parent_id={"consult-master"}
                  editData={editData}
                  closeForm_={this.handleForm_}
                  saveForm_={this.saveForm}
                  editShortDes={this.state?.editShortDes}
                  edit_id={this.state?.edit_id}
                />
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  orderSetMasterList: state?.orderSetMasterSlice?.order_set_master_list,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(OrderSetMaster));
