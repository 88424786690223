import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
// import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";

// import IDMPANEL from "idmpanel-pkg";
// import Config from "../../config";
// import cookie from "react-cookies";

import * as ALL from "../../components";

// import { Modal } from "qdm-component-library";

import {
  Grid,
  Paper,
  Typography,
  TextField,
  Box,
  Divider,
  Checkbox,
} from "@material-ui/core";
import { ComponentRender } from "custom-material-ui-component";

import { Autocomplete } from "@material-ui/lab";

import qdmstyles from "./styles.json";
import "./index.css";
// var aes256 = require("aes256");

class AddNewFrequency extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      permissionData: null,
      addnewfrequency_krpij: null,
    };
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };
  onchange_krpij = (e, index) => {
    this.setState({ addnewfrequency_krpij: e?.target?.value });
  };
  componentDidMount() {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(localStorage.defaultLng);
    }

    /// setlogger && dispatch of all events

    /// fetch all permission
  }

  ///fetching all permission

  shouldComponentUpdate(props, state) {
    return true;
  }

  render() {
    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <Grid
              key={"0"}
              id={"5o45v"}
              container={true}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              style={qdmstyles.ov}
            >
              <Grid
                key={"0"}
                id={"bHcZj"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                lg={"12"}
                aliasName={"user defined"}
                style={qdmstyles.lEBpM}
              >
                <Grid
                  key={"0"}
                  id={"Lgyys"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"12"}
                  item={true}
                  md={"12"}
                  sm={"12"}
                >
                  <Paper
                    key={"0"}
                    id={"FImGi"}
                    elevation={"1"}
                    variant={"elevation"}
                    style={qdmstyles.kvz}
                  >
                    <Grid
                      key={"0"}
                      id={"kQnnR"}
                      container={false}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      style={qdmstyles.kQnnR}
                    >
                      <Typography
                        key={"0"}
                        id={"3zKDA"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"FREQUENCY DEFINITION"}
                        style={qdmstyles.PEvLF}
                      ></Typography>
                    </Grid>
                    <Grid
                      key={"1"}
                      id={"2WGYO"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      lg={"12"}
                      item={true}
                      md={"12"}
                      sm={"12"}
                      spacing={"2"}
                      style={qdmstyles.Pom}
                    >
                      <Grid
                        key={"0"}
                        id={"FTOds"}
                        container={false}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"2"}
                        item={true}
                      >
                        <Typography
                          key={"0"}
                          id={"h9zkG"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Frequency Code"}
                        ></Typography>
                        <TextField
                          key={"1"}
                          id={"VWbB8"}
                          label={""}
                          placeholder={"TAPER"}
                          type={"text"}
                          variant={"outlined"}
                          margin={"dense"}
                          fullWidth={true}
                          aliasName={"freqCode"}
                          style={qdmstyles.GqS}
                        ></TextField>
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"FTOds"}
                        container={false}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"4"}
                        item={true}
                      >
                        <Typography
                          key={"0"}
                          id={"h9zkG"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Frequency Name"}
                        ></Typography>
                        <TextField
                          key={"1"}
                          id={"2TiFD"}
                          label={""}
                          placeholder={"Tapering"}
                          type={"text"}
                          fullWidth={true}
                          variant={"outlined"}
                          margin={"dense"}
                          aliasName={"freqName"}
                          style={qdmstyles.RYFsU}
                        ></TextField>
                      </Grid>
                      <Grid
                        key={"2"}
                        id={"FTOds"}
                        container={false}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"3"}
                        item={true}
                      >
                        <Typography
                          key={"0"}
                          id={"h9zkG"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Frequency Type"}
                        ></Typography>
                        <Autocomplete
                          id={"NQ3iF"}
                          style={qdmstyles?.NQiF}
                          value={this.state?.addnewfrequency_nq3if ?? null}
                          onLoad={() =>
                            this.setState({
                              addnewfrequency_nq3if:
                                this.state?.addnewfrequency_nq3if ?? null,
                            })
                          }
                          getOptionLabel={(option) => option?.["label"]}
                          options={[]}
                          onChange={(e, newvalue) =>
                            this.setState({ addnewfrequency_nq3if: newvalue })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"label"}
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      key={"2"}
                      id={"2WGYO"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"flex-start"}
                      lg={"12"}
                      item={true}
                      md={"12"}
                      sm={"12"}
                      spacing={"2"}
                      style={qdmstyles.uePu}
                    >
                      <Grid
                        key={"0"}
                        id={"FTOds"}
                        container={false}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"2"}
                        item={true}
                        md={"2"}
                        sm={"2"}
                        style={qdmstyles.SrZdi}
                      >
                        <Typography
                          key={"0"}
                          id={"h9zkG"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"From Day"}
                          aliasName={""}
                        ></Typography>
                        <Box
                          key={"1"}
                          id={"5KwQJ"}
                          m={"1"}
                          component={"div"}
                          style={qdmstyles.xmz}
                        >
                          <TextField
                            key={"0"}
                            id={"VWbB8"}
                            label={""}
                            placeholder={"TAPER"}
                            type={"text"}
                            variant={"standard"}
                            margin={"dense"}
                            aliasName={"from_day"}
                            style={qdmstyles.fLq}
                          ></TextField>
                          <Divider
                            key={"1"}
                            id={"78BJ8"}
                            variant={"fullWidth"}
                            flexItem={true}
                            light={false}
                            orientation={"vertical"}
                          ></Divider>
                          <Typography
                            key={"2"}
                            id={"h9zkG"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Times"}
                            style={qdmstyles.XJrNI}
                          ></Typography>
                        </Box>
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"FTOds"}
                        container={false}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"2"}
                        item={true}
                        md={"2"}
                        sm={"2"}
                        style={qdmstyles.pyv}
                      >
                        <Typography
                          key={"0"}
                          id={"h9zkG"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"To Day"}
                          aliasName={""}
                        ></Typography>
                        <Box
                          key={"1"}
                          id={"5KwQJ"}
                          m={"1"}
                          component={"div"}
                          style={qdmstyles.WGBi}
                        >
                          <TextField
                            key={"0"}
                            id={"VWbB8"}
                            label={""}
                            placeholder={"TAPER"}
                            type={"text"}
                            variant={"standard"}
                            margin={"dense"}
                            aliasName={"to_day"}
                            style={qdmstyles.rZuLA}
                          ></TextField>
                          <Divider
                            key={"1"}
                            id={"78BJ8"}
                            variant={"fullWidth"}
                            flexItem={true}
                            light={false}
                            orientation={"vertical"}
                          ></Divider>
                          <Typography
                            key={"2"}
                            id={"h9zkG"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Hrs"}
                            style={qdmstyles.qUdix}
                          ></Typography>
                        </Box>
                      </Grid>
                      <Grid
                        key={"2"}
                        id={"FTOds"}
                        container={false}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"2"}
                        item={true}
                        md={"2"}
                        sm={"2"}
                        style={qdmstyles.RyLKR}
                      >
                        <Typography
                          key={"0"}
                          id={"h9zkG"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Base Frequency"}
                          aliasName={""}
                        ></Typography>
                        <Box
                          key={"1"}
                          id={"5KwQJ"}
                          m={"1"}
                          component={"div"}
                          style={qdmstyles.WFJWM}
                        >
                          <TextField
                            key={"0"}
                            id={"VWbB8"}
                            label={""}
                            placeholder={"TAPER"}
                            type={"text"}
                            variant={"standard"}
                            margin={"dense"}
                            fullWidth={true}
                            aliasName={"base_frequency"}
                            style={qdmstyles.uqzr}
                          ></TextField>
                          <Divider
                            key={"1"}
                            id={"78BJ8"}
                            variant={"fullWidth"}
                            flexItem={true}
                            light={false}
                            orientation={"vertical"}
                          ></Divider>
                          <Autocomplete
                            id={"kRpIj"}
                            style={qdmstyles?.kRpIj}
                            value={this.state?.addnewfrequency_krpij ?? null}
                            onLoad={() =>
                              this.setState({
                                addnewfrequency_krpij:
                                  this.state?.addnewfrequency_krpij ?? null,
                              })
                            }
                            getOptionLabel={(option) => option?.["label"]}
                            options={[]}
                            onChange={(e, newvalue) =>
                              this.setState({ addnewfrequency_krpij: newvalue })
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"label"}
                                variant="outlined"
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        key={"3"}
                        id={"FTOds"}
                        container={true}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"stretch"}
                        lg={"2"}
                        item={true}
                        alignContent={"stretch"}
                        md={"2"}
                        sm={"2"}
                        style={qdmstyles.Rxwf}
                      >
                        <Typography
                          key={"0"}
                          id={"h9zkG"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Is Base Frequency"}
                        ></Typography>
                        <Checkbox
                          key={"1"}
                          id={"Wr5U5"}
                          checked={true}
                          color={"default"}
                          size={"small"}
                          aliasName={"is_base_freq"}
                        ></Checkbox>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid
                key={"1"}
                id={"DGwzy"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                aliasName={"user defined"}
                lg={"12"}
                md={false}
                sm={false}
                style={qdmstyles.ltESX}
              >
                <Grid
                  key={"0"}
                  id={"Ngyez"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item={true}
                  lg={"12"}
                  md={"12"}
                  sm={"12"}
                  xs={"auto"}
                >
                  <ComponentRender
                    key={"0"}
                    id={"AANOv"}
                    component={
                      <ALL.Schedulelines
                        propsState={this.state}
                        setFun={(value) => {
                          this.changeState("Schedulelines", value);
                        }}
                        stateName={"Schedulelines"}
                        statevalue={this.state?.Schedulelines}
                      />
                    }
                  ></ComponentRender>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  actions
)(withRouter(translate()(AddNewFrequency)));
