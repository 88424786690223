export const FIELD_TYPES = {
  text: "text",
  switch: "switch",
  autocomplete: "autocomplete",
  datepicker: "datepicker",
  timepicker: "timepicker",

  // Temp
  string: "string",
  number: "number",
  date: "date",
};
