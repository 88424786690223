import {
  Typography,
  makeStyles,
  TextField,
  Paper,
  Grid,
  Button,
  Switch,
  IconButton,
  useTheme
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { SelectBox } from "../../../components/common/smartForm/component";
import React, {useState } from "react";
//import { AlertProps } from "../../../utils";
import { withAllContexts } from "../../../HOCs";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    // padding: 20,
    borderRadius: 8,
    "& .App1-MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  title: {
    fontSize: "14px",
    family: "poppinsemibold",
    padding: "20px 20px 10px",
  },
  form: {},
  formStandCol:{
    // padding: "10px 14px 1px",
    textAlign: "center",
    background: "#F9F9F9",
    borderRadius: "10px",
    border: "1px solid #F0F0F0"
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: theme.palette.primary.light,
  },
  formtitlecol: {
    fontSize: "12px",
    Fontfamily: "pc_regular",
    color: theme.palette.primary.light,
  },
  formParent: {
    paddingRight: 10,
  },
  formParentCol: {
    padding: 10,
    borderRightColor: "#F0F0F0",
    borderRightStyle: "solid",
    borderRightWidth: "1px",
    borderRadius:10,
    backgroundColor : theme.palette.background.table
  },
  closeIcon: {
    color: "#6F6F6F",
    cursor: "pointer",
  },
  saveBtn: {
    color: theme.palette.button.text,
    padding: "6px 16px",
    fontSize: "12px",
    fontFamily: "poppin",
    textTransform: "none",
    backgroundColor: theme.palette.button.background,
  },
  helperText: {
    color: "red",
  },
  notchedOutline:{
    borderColor:theme.palette.border.textField
  },
  add: {
    "&.App1-MuiButton-contained:hover": {
        backgroundColor: theme.palette.button.hover
      },
      color:theme.palette.button.text,
      backgroundColor:theme.palette.button.background,
  },
}));

const standCol = [ 
  {
    name: "Code (20 Chars) ",
    size: 2,
    isReq: true
  },
  {
    name: "Short Description (60 Chars) ",
    size: 4,
    isReq: true
  },
  {
    name: "Long Description (100 Chars) ",
    size: 4,
    isReq: true
  },
  {
    name: "Status (Y/N)",
    size: 2
  }
]

function GeneralMasterForm(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [state, setState] = useState({});
  const [errorState, setErrorstate] = useState({});
	const columnTypeMaster = useSelector((state) => state?.generalMasterSlice?.column_type_master?.data??[]);
	const masterNamelist = useSelector((state) => state?.generalMasterSlice?.generalMastersRead?.data??[]);
  const [addColumn, setAddColumn] = useState([
    // {
    //   columnName: "Code",
    //   columnType: { value: "", label: "" },
    //   columnSize: "",
    // },
    // {
    //   columnName: "Short Description",
    //   columnType: { value: "", label: "" },
    //   columnSize: "",
    // },
    // {
    //   columnName: "Long Description",
    //   columnType: { value: "", label: "" },
    //   columnSize: "",
    // },
    // {
    //   columnName: "Status",
    //   columnType: { value: "", label: "" },
    //   columnSize: "",
    // }
  ]);

  React.useLayoutEffect(() => {
    const intialCall = async () => {
      await dispatch(actions?.COLUMN_TYPE_MASTERS())
      // console.log("masterNamelist",masterNamelist)
    }
    intialCall()
    if(props?.editeList){
      setState({
        ...state,
        masterType: props?.editeList?.gentype??"",
        masterName: props?.editeList?.genname??""
      })
      if(props?.editeList.additionalcolumns?.length > 0){
        let dataList = [];
        props?.editeList.additionalcolumns?.map(vl => { // eslint-disable-line no-unused-expressions
          let list = {
            columnName: vl?.columnname??"",
            columnType: vl?.columntype ? { value: vl?.columntype?._id??"", label: vl?.columntype?.display??"", ...vl?.columntype } : null,
            columnMaster: vl?.columnmaster ? { value: vl?.columnmaster?._id??"", label: vl?.columnmaster?.name??"", ...vl?.columnmaster } : null,
            columnSize: vl?.columnsize??"",
            mandatory: vl?.ismandatory??false
          }
          dataList.push(list);
        })
        setAddColumn(dataList);
      }
    }
},[])

  const handleFormState = (name, value) => {
    if(errorState[name]){
      errorState[name] = false;
      setErrorstate({
       ...errorState
      })
    }
    setState({ ...state, [name]: value });
  };

  const handleAdditionalColumns = (name, value, i) => {
    // console.log("name",name);
    // console.log("value",value);
    // console.log("i",i);
    addColumn[i][name] = value;
    setAddColumn([...addColumn])
  };

  const handleAddNewColumn = () => {
    let obj = {
      columnName: "",
      columnType: { value: "", label: "" },
      columnSize: "",
      new: true
    };
    addColumn.push(obj);
    setAddColumn([...addColumn])
  };

  const validation = () => {
    let error = false
    let errorState={}
    if(!state?.masterType ){
      error = true
      errorState["masterType"]=true
    } 
    if(!state?.masterName){
       error = true;
       errorState["masterName"] = true;
    }
    setErrorstate(errorState);
    const checkUnicName = (string) => {
      let all_names = string.map(val => val.columnName?.toLocaleUpperCase()?.trim());
      all_names = all_names.concat(['CODE', 'SHORT DESCRIPTION', 'LONG DESCRIPTION', 'STATUS']);
      return all_names.filter((item, index) => all_names.indexOf(item) !== index)?.length > 0 ? true : false;
    }
    if(addColumn?.length > 0){
      let errorAdditional = [];
      addColumn?.map(val => { // eslint-disable-line no-unused-expressions
        if(val?.columnName?.length > 0 && val?.columnType?.value && (val?.columnType?.label?.includes("select") ? val?.columnMaster?.name?.length > 0 : true)){
          errorAdditional.push(false)
        }else{
          errorAdditional.push(true)
        }
      })
      if(checkUnicName(addColumn)){
        errorAdditional.push(true)
        props.alert.setSnack("unique","Please fill unique Column names fields");
        return
      }
      error = errorAdditional.includes(true);
    }
    return error;
  }
  const handleSave = async () => {
    if(validation()){
      props.alert.setSnack("mandatory");
    }else{
      let data_list = {
        "additional_colum" : addColumn,
        "general_type": state?.masterType??"",
        "general_name": state?.masterName??"",
        "_key": props?.editeList?._key??null
      }
      let return_data = await dispatch(actions.UPSERT_GENERAL_MASTER_LIST(data_list));
      if(return_data?.payload?.data?.validation_error?.[0]?.Errormsg?.includes("unique") || return_data?.payload?.data?.validation_error?.[0]?.Errormsg){
        let type = return_data?.payload?.data?.validation_error?.[0]?.Errormsg?.includes("unique") ? "unique" : "error"
        props.alert.setSnack(type);
      }else{
        props.alert.setSnack("success");
        await props.handleFormClose("reload");
    }
   }
  };

  const deleteFun = (index) => {
    addColumn.splice(index, 1);
    setAddColumn([...addColumn])
  }

  return (
    <div>
      <Paper className={classes.root}>
        <div style={{ display: "flex", backgroundColor: theme.palette.background.tableHeader, }}>
          <Typography className={classes.title} variant="h6">
            {props?.editeList ? "Edit General Master" : "New General Master"}
          </Typography>
          <div style={{ flexGrow: 1 }}></div>
          <div
            onClick={() => props.handleFormClose()}
            style={{ padding: "20px 20px 0"}}
          >
            <CloseIcon className={classes.closeIcon} />
          </div>
        </div>
        <Grid
          container
          className={classes.form}
          style={{ padding: "0px 20px",backgroundColor: theme.palette.background.table  }}
        >
          <Grid md="3" lg="3" className={classes.formParent}>
            <Typography className={classes.formtitle} variant="body1">
              Master Type <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              // onChange={(e) => handleFormState("masterType", e.target.value.replaceAll(/[.*+?^${}()"|[\]\\]/g,'').replaceAll(" ", "_").toLocaleUpperCase())}
              onChange={(e) => handleFormState("masterType", e.target.value?.replaceAll(" ", ""))}
              value={state?.masterType ?? ""}
              fullWidth
              InputProps={{ classes: {notchedOutline: classes.notchedOutline}}}
              type="text"
              variant="outlined"
              size="small"
              required
              error={ errorState?.masterType }
              FormHelperTextProps={{ classes: { root: classes.helperText } }}
              disabled={props?.editeList?true:false}
            />
          </Grid>
          <Grid md="4" lg="4" className={classes.formParent}>
            <Typography className={classes.formtitle} variant="body1">
              Master Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              onChange={(e) => handleFormState("masterName", e.target.value?.replaceAll(" ", ""))}
              value={state?.masterName ?? ""}
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              required
              InputProps={{ classes: {notchedOutline: classes.notchedOutline}}}
              error={
                errorState?.masterName }
              FormHelperTextProps={{ classes: { root: classes.helperText } }}
              disabled={props?.editeList?true:false}
            />
          </Grid>
        </Grid>

        {/* Standard Columns */}
        <div style={{ display: "flex", borderBottom: "1px solid #f0f0f0",backgroundColor: theme.palette.background.table }}>
          <Typography className={classes.title} variant="h6">
            Standard Columns
          </Typography>
        </div>
        <div style={{padding: 10,backgroundColor: theme.palette.background.table}}>
          <Grid
            container
            className={classes.formStandCol}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
          {standCol.map(val => {
            return  <Grid md={`${val?.size}`} lg={`${val?.size}`} className={classes.formParentCol}>
            <Typography className={classes.formtitlecol} variant="body1">
              {val?.name??""} {val?.isReq && <span style={{ color: "red" }}>*</span>}
            </Typography>
          </Grid> 
          })}
          </Grid>
        </div>
        {/* Standard Columns end */}


        <div style={{ display: "flex", borderBottom: "1px solid #f0f0f0",backgroundColor: theme.palette.background.table }}>
          <Typography className={classes.title} variant="h6">
            Additional Columns
          </Typography>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            className={classes.saveBtn}
            // style={{ backgroundColor: "#fff", color: "#0071F2" }}
            onClick={handleAddNewColumn}
          >
            + Add New
          </Button>
        </div>
        {addColumn.map((val,i)=>(
          <Grid
          container
          className={classes.form}
          style={{ padding: "10px 14px 14px" }}
        >
          <Grid md="4" lg="4" className={classes.formParent}>
            <Typography className={classes.formtitle} variant="body1">
              Column Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              onChange={(e) => handleAdditionalColumns("columnName", e.target.value,i)}
              value={val?.columnName ?? ""}
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              required
              disabled={(props?.editeList && !val?.new)??false}
            />
          </Grid>
          <Grid md="3" lg="3" className={classes.formParent}>
            <Typography className={classes.formtitle} variant="body1">
              Column Type<span style={{ color: "red" }}> *</span>
            </Typography>
            <SelectBox
              list={columnTypeMaster}
              placeholder={" "}
              value={val?.columnType ?? ""}
              onchange={(e, value) =>
                handleAdditionalColumns("columnType", value,i)
              }
              disabled={(props?.editeList && !val?.new)??false}
            />
          </Grid>

          {val?.columnType?.label?.includes("select") ?
          // {/* Mater Name list */}
          <Grid md="3" lg="3" className={classes.formParent}>
            <Typography className={classes.formtitle} variant="body1">
                Master Name<span style={{ color: "red" }}> *</span>
            </Typography>
            <SelectBox
              Optionlabel={"name"}
              list={masterNamelist}
              placeholder={" "}
              value={val?.columnMaster ?? ""}
              onchange={(e, value) =>
                handleAdditionalColumns("columnMaster", value,i)
              }
              disabled={(props?.editeList && !val?.new)??false}
            />
          </Grid>
          // {/* end Mater Name */}
          :
          <Grid md="3" lg="3" className={classes.formParent}>
            <Typography className={classes.formtitle} variant="body1">
              Column Size
              {/* <span style={{ color: "red" }}> *</span> */}
            </Typography>
            <TextField
              onChange={(e) => handleAdditionalColumns("columnSize", e.target.value,i)}
              value={val?.columnSize ?? ""}
              fullWidth
              type={"number"}
              variant="outlined"
              size="small"
              disabled={(props?.editeList && !val?.new)??false}
            />
          </Grid>
          }

          <Grid md="1" lg="1" className={classes.formParent}>
            <Typography className={classes.formtitle} variant="body1">
              Mandatory
            </Typography>
            <Switch
              id = {`__switch`}
              checked={val?.mandatory ?? ""}
              onChange={(e) => handleAdditionalColumns("mandatory", e.target.checked, i)}
              name={"Mandatory"}
              inputProps={{ "aria-label": "secondary checkbox" }}
              disabled={(props?.editeList && !val?.new)??false}
            />
          </Grid>
          <Grid md="1" lg="1" className={classes.formParent} style={{paddingLeft: "10px", textAlign: "center"}}>
            <Typography className={classes.formtitle} variant="body1">
              Delete
            </Typography>
            <IconButton
              aria-label="DeleteIcon"
              className={classes.margin}
              size={"medium"}
              onClick={() => deleteFun(i)}
              disabled={(props?.editeList && !val?.new)??false}
            >
              <DeleteIcon style={{ color: "#FF4D4A", fontSize: 20 }} />
            </IconButton>
          </Grid>
        </Grid>
        ))}
        

        <div
          style={{
            padding: "20px 20px 20px",
            display: "flex",
            justifyContent: "end",
            backgroundColor: theme.palette.background.table,
          }}
        >
          <Button 
            disabled={props?.editeList ? (addColumn?.filter(val => val?.new === true)?.length > 0? false : true) : false}
            variant={"contained"}
            color={"primary"}
            size={"small"}
            className={classes.add} 
            onClick={handleSave}>
            {props?.editeList?"Update":"Save"}
          </Button>
        </div>
      </Paper>
    </div>
  );
}

export default withAllContexts(GeneralMasterForm);
