import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Chip,
  Button,
  Avatar,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDividerStyles } from "./styles";
import React, { useRef, useLayoutEffect } from "react";
import {
  PillDark,
  TaskIcon,
  Pills,
  DocumentBlack,
  Clock,
  Calender,
  Diagnosis,
  Speciality,
  LocationIcon,
} from "../../../../../assets";
import { getImgUrl } from "../../../../../utils";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles({
  align: {
    marginLeft: "30px",
    marginTop: "20px",
  },
  align2: {
    marginTop: "20px",
  },
  heading: {
    fontSize: "12px !important",
    
    marginLeft: "5px",
  },
  value3: {
    fontSize: "14px !important",
    color: "#2A3C50",
    marginLeft: "18px",
    fontWeight: 500,
  },
  secondline: {
    marginTop: "24px",
  },
  card: {
    minWidth: 175,
  },
  root: {
    borderRadius: "13px",
    "& .App1-MuiCardContent-root": {
      padding: "0px !important",
    },
  },
  chip: {
    height: "15px",
    borderRadius: "0px",
    marginLeft: "5px",
  },
});

function OrderInfo(props) {
  const classes = useStyles();
  const theme = useTheme();
  const dividerStyles = useDividerStyles();
  const { orderinfo } = props;
  return (
    <>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "19%", height: "25vh" }}>
          {orderinfo?.drugPhoto ? (
            <img
              style={{ borderRadius: "10px" }}
              width={"100%"}
              height="auto"
              alt="Drug"
              src={getImgUrl(orderinfo?.drugPhoto)}
            />
          ) : (
            <div
              style={{
                background: "#d3d3d3cc",
                height: "100%",
                width: "100%",
                display: "flex",
                borderRadius: "15px",
              }}
            >
              <p
                style={{
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                No Image
              </p>{" "}
            </div>
          )}
        </div>
        <div>
          <div style={{ display: "flex" }}>
            <div className={classes.align}>
              <div style={{ display: "flex" }}>
                <PillDark />
                <Typography className={classes.heading}>Drug type</Typography>
              </div>
              <div>
                <Typography className={classes.value3}>
                  {orderinfo?.drugtype}
                </Typography>
              </div>
            </div>
            <div className={classes.align}>
              <div style={{ display: "flex" }}>
                <TaskIcon />
                <Typography className={classes.heading}>
                  Drug code description
                </Typography>
              </div>
              <div>
                <Typography className={classes.value3}>
                  {/* {orderinfo?.drugcode} */}
                  {props.drug?.name}
                </Typography>
              </div>
            </div>
            <div className={classes.align}>
              <div style={{ display: "flex" }}>
                <Pills />
                <Typography className={classes.heading}>Dosage</Typography>
              </div>
              <div>
                <Typography className={classes.value3}>
                  {orderinfo?.dosage}
                </Typography>
              </div>
            </div>
            <div className={classes.align}>
              <div style={{ display: "flex" }}>
                <PillDark />
                <Typography className={classes.heading}>
                  Drug category
                </Typography>
              </div>
              <div>
                <Typography className={classes.value3}>
                  {orderinfo?.catagory}
                </Typography>
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }} className={classes.secondline}>
            <div className={classes.align}>
              <div style={{ display: "flex" }}>
                <Pills />
                <Typography className={classes.heading}>Route</Typography>
              </div>
              <div>
                <Typography className={classes.value3}>
                  {orderinfo?.route}
                </Typography>
              </div>
            </div>
            <div className={classes.align}>
              <div style={{ display: "flex" }}>
                <DocumentBlack />
                <Typography className={classes.heading}>Form</Typography>
              </div>
              <div>
                <Typography className={classes.value3}>
                  {orderinfo?.form}
                </Typography>
              </div>
            </div>
            <div className={classes.align}>
              <div style={{ display: "flex" }}>
                <DocumentBlack />
                <Typography className={classes.heading}>Generic</Typography>
              </div>
              <div>
                <Typography className={classes.value3}>
                  {orderinfo?.generic}
                </Typography>
              </div>
            </div>
            <div className={classes.align}>
              <div style={{ display: "flex" }}>
                <DocumentBlack />
                <Typography className={classes.heading}>Mode</Typography>
              </div>
              <div>
                <Typography className={classes.value3}>
                  {orderinfo?.mode}
                </Typography>
              </div>
            </div>
            <div className={classes.align}>
              <div style={{ display: "flex" }}>
                <DocumentBlack />
                <Typography className={classes.heading}>Nature</Typography>
              </div>
              <div>
                <Typography className={classes.value3}>
                  {orderinfo?.nature}
                </Typography>
              </div>
            </div>
            <div className={classes.align}>
              <div style={{ display: "flex" }}>
                <Clock />
                <Typography className={classes.heading}>Duration</Typography>
              </div>
              <div>
                <Typography className={classes.value3}>
                  {orderinfo?.duration}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex" }} className={classes.secondline}>
        <div className={classes.align2}>
          <div style={{ display: "flex" }}>
            <Calender />
            <Typography className={classes.heading}>Start date</Typography>
          </div>
          <div>
            <Typography className={classes.value3}>
              {orderinfo?.startdate}
            </Typography>
          </div>
        </div>
        <div className={classes.align}>
          <div style={{ display: "flex" }}>
            <Calender />
            <Typography className={classes.heading}>End date</Typography>
          </div>
          <div>
            <Typography className={classes.value3}>
              {orderinfo?.enddate}
            </Typography>
          </div>
        </div>
        <div className={classes.align}>
          <div style={{ display: "flex" }}>
            <Diagnosis />
            <Typography className={classes.heading}>Diagnosis</Typography>
          </div>
          {orderinfo?.length > 0 && (
            <div style={{ marginLeft: "10px" }}>
              {orderinfo?.diagnosis.map((val) => {
                return <Chip className={classes.chip} label={val} />;
              })}
            </div>
          )}
        </div>
        {orderinfo?.PRNYN && (
          <div className={classes.align}>
            <div style={{ display: "flex" }}>
              <Calender />
              <Typography className={classes.heading}>PRN</Typography>
            </div>
            <div>
              <Typography className={classes.value3}>
                {orderinfo?.prnComment}
              </Typography>
            </div>
          </div>
        )}
      </div>

      {/* <!---------------Practioner info-------------> */}

      <div style={{ marginTop: "16px" }}>
        <div style={{ display: "flex" }}>
          <Typography className={classes.value3} style={{ marginLeft: "0px" }}>
            PRACTIONER INFO
          </Typography>
          <Divider
            orientation="horizontal"
            flexItem
            classes={dividerStyles}
            style={{ margin: theme.spacing(0, 1) }}
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <Card variant="outlined" className={classes.root}>
            <CardContent>
              <Grid container>
                <Grid sx={5}>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "12px",
                      paddingTop: "22px",
                      paddingLeft: "12px",
                    }}
                  >
                    <Avatar src={orderinfo?.profile} variant="square" />

                    {/* {orderinfo?.image} */}

                    <div>
                      <div>
                        <Typography className={classes.value3}>
                          {orderinfo?.doctorName}
                          {!orderinfo?.doctorName && <>&nbsp;</>}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          className={classes.heading}
                          style={{
                            listStyle: "disc outline none",
                            display: "listItem",
                            marginLeft: "18px",
                          }}
                        >
                          {orderinfo?.id}
                          <span
                            style={{
                              fontSize: "14px",
                              
                              listStyle: "disc outline none",
                              display: "listItem",
                            }}
                          >
                            ,
                          </span>{" "}
                          {orderinfo?.contactnumber}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid sx={1}>
                  {" "}
                  <Divider
                    orientation="horizontal"
                    flexItem
                    classes={dividerStyles}
                    style={{ margin: theme.spacing(0, 1) }}
                  />
                </Grid>
                <Grid sx={5}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      marginTop: "12px",
                      paddingTop: "12px",
                      paddingLeft: "12px",
                      paddingBottom: "16px",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex" }}>
                        <Speciality />
                        <Typography className={classes.heading}>
                          Speciality Name
                        </Typography>
                      </div>
                      <div>
                        <Typography className={classes.value3}>
                          {orderinfo?.specialityname}
                        </Typography>
                      </div>
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                      <div style={{ display: "flex" }}>
                        <LocationIcon />
                        <Typography className={classes.heading}>
                          Clinic
                        </Typography>
                      </div>
                      <div>
                        <Typography className={classes.value3}>
                          {orderinfo?.clinicname}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <Button
            style={{ color: "#9D2E42", marginTop: "-84px" }}
            startIcon={<EditIcon style={{ fontSize: "14px" }} />}
            color="primary"
            variant="default"
            onClick={props?.handleMedicationEdit}
          >
            Edit
          </Button>
        </div>
      </div>
    </>
  );
}

export default OrderInfo;
