import React from "react";
import {
  CommonTable,
  GenerateForm,
  VerticalList,
} from "../../../../components";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { AlertProps, checkError } from "../../../../utils";
import { withAllContexts } from "../../../../HOCs";

class AllergenMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      edit_data: null,
      perPage: 10,
      page: 0,
    };
  }

  initFetch = async () => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Fetching...",
    });
    await this.props.GET_ALLERGEN_MASTER({
      page: this.state?.page,
      perpage: this.state?.perPage,
    });
    let d = await this.props.ALLERGEN_MASTER_MASTER();
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  };

  handlepageChange = async (currentPage, PerPage) => {
    this.setState({
      ...this.state,
      perPage: PerPage,
      page: (currentPage - 1) * PerPage,
    });
    await this.props.GET_ALLERGEN_MASTER({
      page: (currentPage - 1) * PerPage,
      perpage: PerPage,
    });
  };

  componentDidMount() {
    this.initFetch();
  }

  handleDrawerOpen = () => {
    this.setState({
      ...this.state,
      open: true,
    });
  };
  handleDrawerClose = () => {
    this.setState({
      ...this.state,
      open: false,
      edit_data: null,
    });
  };

  handleCheckBox = async (e, data, index) => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Status Updating...",
    });
    await this.props.UPDATE_ALLERGEN_MASTER_STATUS({
      _key: data._key,
      status: e.target.checked,
    });
    await this.props.GET_ALLERGEN_MASTER({
      page: this.state?.page,
      perpage: this.state?.perPage,
    });
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
    this.props.alert.setSnack({
      open: true,
      severity: AlertProps.severity.success,
      msg: "Status Changed Successfully",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
  };

  saveAllergenMaster = async (values) => {
    let severity = AlertProps.severity.success;
    let data_ = {};
    if (this.state?.edit_data) {
      data_ = {
        _key: this.state?.edit_data?._key,
        _id: this.state?.edit_data?._id,
        id: this.state?.edit_data?.id,
        extsystemsourceid: values?.extsystemsourceid?.value,
        allergytypeind: values?.allergytypeind?.value,
        code: values?.code,
        shortdesc: values?.shortdesc,
        longdesc: values?.longdesc,
        conceptidtype: values?.conceptidtype?.value,
        status: this.state?.edit_data?.status,
      };
    } else {
      data_ = {
        extsystemsourceid: values?.extsystemsourceid?.value,
        allergytypeind: values?.allergytypeind?.value,
        code: values?.code,
        shortdesc: values?.shortdesc,
        longdesc: values?.longdesc,
        conceptidtype: values?.conceptidtype?.value,
        status: true,
      };
    }
    const upsertdata = await this.props.UPSERT_ALLERGEN_MASTER({
      dataList: data_,
    });

    if (upsertdata?.payload?.data?.error) {
      severity = AlertProps.severity.error;
      //ERROR ALERT
      this.props.alert.setSnack({
        open: true,
        severity: severity,
        msg: upsertdata?.payload?.data?.validation_error[0].Errormsg === '["code"] fields must be unique' ?  AlertProps.message.unique : upsertdata?.payload?.data?.validation_error[0].Errormsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else {
      await await this.props.GET_ALLERGEN_MASTER({
        page: this.state?.page,
        perpage: this.state?.perPage,
      });

      //SUCCESS ALERT
      this.props.alert.setSnack({
        open: true,
        severity: severity,
        msg: this.state?.edit_data?._key
          ? "Allergen master updated successfully"
          : "Allergen master added successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });

      await this.handleDrawerClose();
    }
  };

  handleEdit = async (e, values, index) => {
    if (values?._key) {
      let data = {
        _key: values?._key ?? "",
        id: values?.id ?? "",
        _id: values?._id ?? "",
        allergytypeind: values?.allergytypeind ?? "",
        code: values?.code ?? "",
        conceptidtype: values?.conceptidtype ?? "",
        extsystemsourceid: values?.extsystemsourceid ?? "",
        longdesc: values?.longdesc ?? "",
        shortdesc: values?.shortdesc ?? "",
        status: values?.status ?? "",
      };
      this.setState({
        ...this.state,
        edit_data: data,
        open: true,
      });
    }
  };

  render() {
    const { open, openIndex, parent_id } = this.props;
    return (
      <React.Fragment>
        {this.props?.getAllOrderCategory?.loading ? (
          <div
            id={`${parent_id}-parent-div`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "66vh",
            }}
          >
            <CircularProgress id={`${parent_id}-CircularProgress`} />
          </div>
        ) : (
          <>
            <VerticalList
              parent_id={"consult_master"}
              addFunc={this.handleDrawerOpen}
              TabList={[
                {
                  name: "Allergen master",
                  component: (
                    <CommonTable
                      parent_id={"consult_master"}
                      rightAlign={["Status", "Action"]}
                      handleEdit={this.handleEdit}
                      handleCheckBox={this.handleCheckBox}
                      Header={[
                        "S.No",
                        "Allergen Type Indicator",
                        "Code",
                        "Short Description",
                        "Long Description",
                        "Concept Id Type",
                        "Ext System Source Id",
                        "Status",
                        "Action",
                      ]}
                      dataList={this.props?.allergenMasterList?.data ?? []}
                      tableData={[
                        { type: ["INCRIMENT"], name: "" },
                        {
                          type: ["TEXT"],
                          name: "allergytypeind",
                          optionLabel: "label",
                        },
                        { type: ["TEXT"], name: "code" },
                        { type: ["TEXT"], name: "shortdesc" },
                        { type: ["TEXT"], name: "longdesc" },
                        {
                          type: ["TEXT"],
                          name: "conceptidtype",
                          optionLabel: "label",
                        },
                        {
                          type: ["TEXT"],
                          name: "extsystemsourceid",
                          optionLabel: "label",
                        },
                        { type: ["CHECKBOX"], name: "status", align: "right" },
                        { type: ["EDIT"], name: "", align: "right" },
                      ]}
                      handlepageChange={(currentPage, PerPage) =>
                        this.handlepageChange(currentPage, PerPage)
                      }
                      TableCount={this.props?.allergenMasterList?.data?.[0]?.TotalCount ?? 0}
                      incrementCount={this.state?.page}
                      showPagination={true}
                      rowsPerPageOptions={[
                        { label: "10 Rows", value: 10 },
                        { label: "50 Rows", value: 50 },
                        { label: "100 Rows", value: 100 },
                      ]}
                    />
                  ),
                },
              ]}
            />

            <GenerateForm
              parent_id={"ordercategory"}
              nocancel
              open={this.state?.open}
              handlecancel={() => this.handleDrawerClose()}
              header={
                this.state?.edit_data?.id
                  ? "Edit New Allergen Master"
                  : "Add New Allergen Master"
              }
              json={[
                {
                  componet_type: "select",
                  options:
                    this.props?.masterData_?.data?.allergyTypeIndicator ?? [],
                  label: "Allergen Type Indicator",
                  state_name: "allergytypeind",
                  value: this.state?.edit_data?.allergytypeind ?? {},
                  required: true,
                },
                {
                  componet_type: "text",
                  label: "Code",
                  state_name: "code",
                  value: this.state?.edit_data?.code ?? "",
                  required: true,
                  isUpperCase: true,
                  textLength: 6,
                  disabled: this.state?.edit_data?.allergytypeind ? true : false
                },
                {
                  componet_type: "text",
                  label: "Short Description",
                  state_name: "shortdesc",
                  value: this.state?.edit_data?.shortdesc ?? "",
                  required: true,
                  textLength: 60
                },
                {
                  componet_type: "text",
                  label: "Long Description",
                  state_name: "longdesc",
                  value: this.state?.edit_data?.longdesc ?? "",
                  required: true,
                  textLength: 200
                },
                {
                  componet_type: "select",
                  options: this.props?.masterData_?.data?.conceptIdType ?? {},
                  label: "Concept Id Type",
                  state_name: "conceptidtype",
                  value: this.state?.edit_data?.conceptidtype ?? [],
                  required: true,
                },
                {
                  componet_type: "select",
                  options:
                    this.props?.masterData_?.data?.extSystemSourceId ?? [],
                  label: "Ext System Source Id",
                  state_name: "extsystemsourceid",
                  value: this.state?.edit_data?.extsystemsourceid ?? {},
                  required: true,
                },
              ]}
              loading={this.props.upsertOrderCategory?.loading}
              save={(val) => this.saveAllergenMaster(val)}
            />
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  allergenMasterList: state?.allergenMasterSlice?.allergen_master_list,
  masterData_: state?.allergenMasterSlice?.allergen_master_master,
  //upsertOrderCategory: state?.orderMasterSlice?.upsertOrderCategory,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(AllergenMaster));
