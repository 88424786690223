import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InfoDisplay from "../infoDisplay";
import { PractitionerInfoCard } from "../practitionerInfoCard";
import StatusLog from "../statusLog";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { actions } from "frequencyscreen_v_dbinder";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {
  useDialogStyles,
  useDialogContentStyles,
  useDividerStyles,
} from "./styles";
import { StyledDialog } from "../commonComponents";
import {
  Calender,
  Clock,
  DocumentBlack,
  HoldingHandIcon,
  PillDark,
  Pills,
  StethoscopeIcon,
  TaskIcon,
} from "../../assets";
import OrderDetailTable from "../orderDetailTable";
import TimeComponent from "../timeComponent";
import { BasicTable } from "../drugItem/components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "24px 24px 16px 24px",
    display: "grid",
    gridTemplateAreas: `
        "infoRow1"
        "infoRow2"
        "infoRow3"
        `,
    gridTemplateColumns: "1fr",
    gridGap: 40,
  },
  infoRow1: {
    gridArea: "infoRow1",
    display: "grid",
    gridTemplateColumns: "1fr 3fr 1fr 1fr",
  },
  infoRow2: {
    gridArea: "infoRow2",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 2fr",
  },
  infoRow3: {
    gridArea: "infoRow3",
    display: "grid",
    gridTemplateColumns: "1fr 2fr 1fr 2fr",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  practitionerInfoRoot: {
    padding: "30px 24px 16px 24px",
  },
  practInfoCompRoot: {
    paddingTop: 20,
  },
  dispenseRoot: {
    padding: "30px 24px 16px 24px",
  },
  dispenseHeader: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
    // color: "#101010",
  },
  dispenseInfoRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dispenseInfoRowLeft: {
    display: "flex",
    gap: 30,
    alignItems: "center",
  },
  statusLog: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  statusLogHeading: {
    fontSize: 12,
  },
  dispenseItem: {
    padding: "20px 0px",
  },
}));

const getText = (value = "", filler = "-") =>
  value?.toString() ? value : filler;

// const getFormattedDate = (date) => moment.unix(date).format("DD-MM-YYYY HH:mm");

const { DISPENSE_HISTORY_READ } = actions;

function DrugOrderDetails({
  id,
  showPractitionerEditButton,
  handleEditClick = () => { },
  ...props
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dialogStyles = useDialogStyles();
  const dialogContentStyles = useDialogContentStyles();
  const dividerStyles = useDividerStyles();
  const theme = useTheme();

  // Action States
  const dispenseHistory = useSelector(
    (state) => state?.phramcyFrameworkApiSlice?.dispenseHistoryRead
  );
  const data = dispenseHistory?.data?.[0];

  const [open, setOpen] = React.useState(false);
  const [currentLog, setCurrentLog] = React.useState([]);

  const handleClickOpen = (statusLog) => {
    setCurrentLog(statusLog);
    setOpen(true);
  };
  const handleClose = () => {
    setCurrentLog([]);
    setOpen(false);
  };

  useEffect(() => {
    dispatch(DISPENSE_HISTORY_READ({ id: id }));
  }, [id]);

  const FreqHeader = (type) => {
    
    switch (type) {
      case "DBA":
        return ["Day", "Base schedule", "Dosage", "Remarks"];
      case "UDE":
        return ["Base schedule", "From Day", "To Day", "Dosage", "Remarks"];
      case "DET":
        return ["Time", "Dosage", "Remarks"];
      case "INT":
      case "SEQ":
        return ["Base schedule", "Dosage", "Remarks"];
      default:
        return [
          "Day",
          "Time",
          "Base schedule",
          "Start date",
          "End date",
          "Dosage",
          "Remarks",
        ];
    }
  }; 

  const FreqBody = (FreqDetls) => {
    
    let data = FreqDetls?.map((v) => {
      return {
        Remarks: v?.adminDosageInstruction ? v?.adminDosageInstruction : (v?.remarks ?? "-"),
        Day: v?.day ? v?.day : "-",
        Time: v?.time ? v?.time : "-",
        "Base schedule": v?.baseFreq ?? "-",
        "Start date":
          v?.fromDate === 0
            ? "-"
            : moment(v?.fromDate * 1000).format("DD-MM-YYYY"),

        "End date":
          v?.toDate === 0 ? "-" : moment(v?.toDate * 1000).format("DD-MM-YYYY"),
        Dosage: v?.dose ? v?.dose : "-",
        "From Day": v?.fromDay,
        "To Day": v?.toDay,
      };
    });
    return data;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <div className={classes.infoRow1}>
          <InfoDisplay
            icon={<PillDark color={theme.palette.primary.main} />}
            label="Drug Type"
            value={getText(data?.drugType?.longdesc)}
          />
          <InfoDisplay
            icon={<TaskIcon color={theme.palette.primary.main} />}
            label="Order Description"
            value={getText(data?.orderdescription)}
          />
          <InfoDisplay
            icon={<Pills color={theme.palette.primary.main} />}
            label="Dosage"
            value={`${getText(data?.dosageValue)}${getText(
              data?.dosageUOM,
              ""
            )}`}
          />
          <InfoDisplay
            icon={<PillDark color={theme.palette.primary.main} />}
            label="Drug Category"
            value={getText(data?.drug_category?.longdesc)}
          />
        </div>
        <div className={classes.infoRow2}>
          <InfoDisplay
            icon={<DocumentBlack color={theme.palette.primary.main} />}
            label="Mode"
            value={getText(data?.presMode?.display)}
          />
          <InfoDisplay
            icon={<Pills color={theme.palette.primary.main} />}
            label="Route"
            value={getText(data?.routeCode)}
          />
          <InfoDisplay
            icon={<DocumentBlack color={theme.palette.primary.main} />}
            label="Form"
            value={getText(data?.formCode?.longdesc)}
          />
          <InfoDisplay
            icon={<DocumentBlack color={theme.palette.primary.main} />}
            label="Nature"
            value={getText(data?.presNature?.display)}
          />
          <InfoDisplay
            icon={<DocumentBlack color={theme.palette.primary.main} />}
            label="Generic"
            value={getText(data?.GenericDrug?.[0]?.longdesc)}
          />
        </div>
        <div className={classes.infoRow3}>
          <InfoDisplay
            icon={<Clock color={theme.palette.primary.main} />}
            label="Duration"
            value={`${getText(data?.dosageDurationValue)} ${getText(
              data?.dosageDurationUOM,
              ""
            )}`}
          />
          <InfoDisplay
            icon={<Calender color={theme.palette.primary.main} />}
            label="Start Date"
            // value={getFormattedDate(data?.occurStartDateTime)}
            valueComponent={
              <TimeComponent
                dateTime={data?.occurStartDateTime}
                timeZone={"UTC"}
                formatType={"datetime"}
              />
            }
          />
          <InfoDisplay
            icon={<Calender color={theme.palette.primary.main} />}
            label="End Date"
            // value={getFormattedDate(data?.occurEndDateTime)}
            valueComponent={
              <TimeComponent
                dateTime={data?.occurEndDateTime}
                timeZone={"UTC"}
                formatType={"datetime"}
              />
            }
          />
          <InfoDisplay
            icon={<StethoscopeIcon color={theme.palette.primary.main} />}
            label="Diagnosis"
            value={getText(data?.drugType?.longdesc)}
          />
        </div>
        {/* ------------------ Frequency Details Section Starts ------------------------- */}
        <BasicTable
          header={FreqHeader(data?.dosageFreqCodetype)}
          dataTabs={FreqBody(data?.FreqDetls)}
          dosageFreqCode={data?.dosageFreqCode}
        />
        {/* /////////////////////// Frequency Details Section Ends ////////////////////////////// */}
      </div>

      {/* ------------------ Practitioner Info UI Starts ----------------------------- */}

      <div className={classes.practitionerInfoRoot}>
        <Typography className={classes.dispenseHeader}>
          Practitioner Info
        </Typography>
        <Divider
          orientation="horizontal"
          flexItem
          classes={dividerStyles}
        // style={{ margin: theme.spacing(0, 1) }}
        />
        <div className={classes.practInfoCompRoot}>
          <PractitionerInfoCard
            data={data?.requestor?.[0]?.practId?.[0]}
            clinicName={
              data?.encounter_Id?.[0]?.practitioner_details?.[0]?.clinic
            }
            specialityName={
              data?.encounter_Id?.[0]?.practitioner_details?.[0]
                ?.speciality?.[0]?.coding?.[0]?.display
            }
            showEditButton={showPractitionerEditButton}
            handleEditClick={handleEditClick}
          />
        </div>
      </div>

      {/* ------------------ Practitioner Info UI Ends ----------------------------- */}

      {/* ------------------ Dispense Details Section Starts ------------------------- */}
      {data?.PH_OrderLineDispenseSchedule?.length > 0 && (
        <div className={classes.dispenseRoot}>
          <div>
            <Typography className={classes.dispenseHeader}>
              Dispense Details
            </Typography>
            <Divider
              orientation="horizontal"
              flexItem
              classes={dividerStyles}
            // style={{ margin: theme.spacing(0, 1) }}
            />
          </div>
          {Array.isArray(data?.PH_OrderLineDispenseSchedule) &&
            data?.PH_OrderLineDispenseSchedule?.map((dispenseData) => (
              <div className={classes.dispenseItem}>
                <div className={classes.dispenseInfoRow}>
                  <div className={classes.dispenseInfoRowLeft}>
                    <InfoDisplay
                      icon={<Calender color={theme.palette.primary.main} />}
                      label="Dispense Date"
                      value={moment
                        .unix(dispenseData?.dispdate)
                        .format("DD-MM-YYYY HH:mm")}
                    />
                    <InfoDisplay
                      icon={<Calender color={theme.palette.primary.main} />}
                      label="From Date"
                      value={moment
                        .unix(dispenseData?.dispfromdate)
                        .format("DD-MM-YYYY HH:mm")}
                    />
                    <InfoDisplay
                      icon={<Calender color={theme.palette.primary.main} />}
                      label="To Date"
                      value={moment
                        .unix(dispenseData?.disptodate)
                        .format("DD-MM-YYYY HH:mm")}
                    />
                    <InfoDisplay
                      icon={
                        <HoldingHandIcon color={theme.palette.primary.main} />
                      }
                      label="Dispense Duration"
                      value={`${getText(dispenseData?.dispdurval)}${getText(
                        dispenseData?.dispduruomText,
                        ""
                      )}`}
                    />
                    <InfoDisplay
                      icon={<Clock color={theme.palette.primary.main} />}
                      label="Balance Duration"
                      value={getText(dispenseData?.balanceduration)}
                    />
                  </div>
                  <div className={classes.statusLog}>
                    <Typography className={classes.statusLogHeading}>
                      Status Log
                    </Typography>
                    <div
                      onClick={() =>
                        handleClickOpen(dispenseData?.statushistory)
                      }
                      style={{
                        marginLeft: "10px",
                        padding: "5px 7px 3px 7px",
                        backgroundColor: "#F1F6FC",
                      }}
                    >
                      <DocumentBlack color={theme.palette.primary.main} />
                    </div>
                  </div>
                </div>

                {Array.isArray(dispenseData?.dispensedetails) &&
                  dispenseData?.dispensedetails?.map((dispData) => (
                    <div style={{ marginTop: "20px" }}>
                      <OrderDetailTable
                        // {...props}
                        // key={brand?.id}
                        // disptype={drug?.disptype?.code}
                        dispenseData={dispData}
                      // brand={brand}
                      // imgId={drug?.drugPhotoId}
                      // isAnotherBrandAvailable={index !== drug?.brands?.length - 1}
                      // batchQuantity={props?.batchQuantity}
                      // onBatchQuantityChange={(value, batch_inx) =>
                      //   props?.onBatchQuantityChange(
                      //     value,
                      //     brand?.id,
                      //     index,
                      //     batch_inx
                      //   )
                      // }
                      // handleChangebrandName={(value) => {
                      //   props.handleChangebrandName(value, index, brand?.id);
                      // }}
                      // handleDelete={() => props?.handleDelete(index)}
                      // handleAdd={() => props?.handleAdd(index)}
                      // isAdd={isaddfuncy(drug) && drug?.brands?.length - 1 === index}
                      // index={index}
                      // isIntravenous={isIntravenous}
                      // printLabel={() => printLabel(drug, index, brand)}
                      // handleDirectionSaveClick={(e) =>
                      //   props.handleDirectionSaveClick(e, index, brand)
                      // }
                      // printEnable
                      />
                    </div>
                  ))}
              </div>
            ))}
        </div>
      )}

      {/* /////////////////////// Dispense Details Section Ends ////////////////////////////// */}

      {/* <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div style={{ display: "flex" }}>
            <DocumentBlack color={{theme.palette.primary.main}} />
            <Typography> Status Log</Typography>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <StatusLog data={currentLog} />
        </DialogContent>
      </Dialog> */}

      <StyledDialog
        id="status_log"
        open={open}
        handleClose={handleClose}
        title={"Status Log"}
        dialogProps={{
          classes: dialogStyles,
        }}
        contentProps={{
          classes: dialogContentStyles,
        }}
      >
        <StatusLog data={currentLog} />
      </StyledDialog>
    </div>
  );
}

export default DrugOrderDetails;
