import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Grid, Paper, Avatar, Typography, Switch } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import qdmstyles from "./styles.json";
import makeStyles from "@material-ui/core/styles/makeStyles";
import "./index.css";
var aes256 = require("aes256");

const useStyles = makeStyles((theme) => ({
  address: {
    color: theme.palette.primary.light
  },
  childname: {
    color: theme.palette.text.primary,
  },
  childback:{
    backgroundColor:theme.pharmacy.table.subHeader,
    borderRadius:"0px 0px 4px 4px"
  },
  infoHead:{
    backgroundColor:theme.pharmacy.table.header,
  }
}));

function Pharmacytable(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pharmacy_fullreadData = useSelector(
    (state) =>
      state?.pharmacytablepharmacy_fullread_pnrrOReducer
        ?.pharmacy_fullread_pnrrO?.data
  );

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
    pharmacytable_pnrro: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const onload_pnrro = async (event, data) => {
    let index = data;
    const payload0 = {
      history: props.history.location.state,
      data: data,
    };
    let data0 = await dispatch(actions.PHARMACY_MASTER_FULL_READ(payload0));

    let updatestate = {};
    setState({
      ...state,
      ...updatestate,
      pharmacytable_pnrro: event?.target?.value,
    });
  };
  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events
    // onload_pnrro();
    /// fetch all permission
  }, []);

  ///fetching all permission

  // console.log(pharmacy_fullreadData?.result, "pharmacy_fullreadData?.result");

  const onChangeSwitch = (data) => {
    let status;

    if (data?.status?.toLowerCase() === "active") {
      status = "inActive";
    }
    if (data?.status?.toLowerCase() === "inactive") {
      status = "Active";
    }

    // console.log(data?.status, "ds");
    // console.log(status, "s");

    let payload = {
      // ...data,
      editnewpharmacy_status: status,
      editnewpharmacy__key: data?._key,
    };
    Promise.resolve(
      dispatch(actions.EditNewPharmacypharmacy_update_a0GKU(payload))
    ).then((res) => {
      let resStatus = res.payload.error;
      if (!resStatus) {
        onload_pnrro();
      }
    });
  };

  const { data, index, loading, propsState } = props;

  const getTelecomNumber = (data) => {
    let isrANK1 = data?.filter(
      (filterdata) => filterdata?.rank?.display == "Rank1"
    );
    let isrANK2 = data?.filter(
      (filterdata) => filterdata?.rank?.display == "Rank2"
    );

    let Number = [];
    if (isrANK1.length > 0 ? isrANK1 : isrANK2) {
      // Number = (isrANK1.length > 0 ? isrANK1 : isrANK2)?.map((item, index) => {
      Number = isrANK1?.map((item, index) => {
        if (item?.system?.code === "phone") {
          return (
            <>
              <Avatar
                key={index}
                id={"EDfRn"}
                alt={"Avatar"}
                variant={"rounded"}
                src={
                  "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317068344"
                }
                style={qdmstyles.jhbCF}
              ></Avatar>
              <Typography
                key={"1"}
                id={"vJpWK"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                className={classes.address}
                children={item?.value}
                style={qdmstyles.EmK}
              ></Typography>
            </>
          );
        }
      });
    } else {
      Number = data?.map((item, index) => {
        if (item?.system?.code === "phone") {
          return (
            <>
              <Avatar
                key={index}
                id={"EDfRn"}
                alt={"Avatar"}
                variant={"rounded"}
                src={
                  "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317068344"
                }
                style={qdmstyles.jhbCF}
              ></Avatar>
              <Typography
                key={"1"}
                id={"vJpWK"}
                align={"inherit"}
                className={classes.address}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={item?.value}
                style={qdmstyles.EmK}
              ></Typography>
            </>
          );
        }
      });
    }
    return Number;
  };

  const getEmail = (data) => {
    let isrANK1 = data?.filter(
      (filterdata) => filterdata?.rank?.display == "Rank1"
    );
    let isrANK2 = data?.filter(
      (filterdata) => filterdata?.rank?.display !== "Rank2"
    );
    let Email = (isrANK1.length > 0 ? isrANK1 : isrANK2)?.map((item, index) => {
      // let Email = data?.map((item, index) => {
      if (item?.system?.code === "email") {
        return (
          <>
            <Avatar
              key={index}
              id={"EDfRn"}
              alt={"Avatar"}
              variant={"square"}
              src={
                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317076736"
              }
              style={qdmstyles.bV}
            ></Avatar>
            <Typography
              key={"1"}
              id={"vJpWK"}
              align={"inherit"}
              color={"initial"}
              display={"initial"}
              variant={"body1"}
              children={item?.value}
              style={{
                ...qdmstyles.LEPan,
                // ...{
                //   textOverflow: "ellipsis",
                //   overflow: "hidden",
                //   width: "140px",
                //   whiteSpace: "nowrap",
                // },
              }}
            ></Typography>
          </>
        );
      }
    });
    return Email;
  };

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"9jTLk"}
            container={""}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            lg={"12"}
            md={"12"}
            sm={"12"}
            xl={"12"}
            xs={"12"}
            item={true}
            aliasName={"parentgridofpharmacy"}
            children={data?.result?.[0]}
            style={qdmstyles.pnrrO}
          >
            {loading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ALLMCORE.CircularProgress />
              </div>
            ) : (
              <>
                {propsState?.length > 0 ? (
                  propsState?.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid
                          key={"0"}
                          id={"9jTLk"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                          item={true}
                          aliasName={"parentgridofpharmacy"}
                          style={
                            (qdmstyles.LmrO,
                              { borderRadius: 10, marginBottom: 16 })
                          }
                        >
                          <Paper
                            key={"0"}
                            id={"e8kBJ"}
                            elevation={"{3}"}
                            variant={"elevation"}
                            style={qdmstyles.BLjf}
                            className={classes.infoHead}
                          >
                            <Grid
                              key={"0"}
                              id={"VaZCa"}
                              container={true}
                              direction={"row"}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              item={true}
                              lg={"12"}
                              md={"12"}
                              sm={"12"}
                              xl={"12"}
                              xs={"12"}
                              style={(qdmstyles.LZiNt, { margin: "0px -7px" })}
                            >
                              <Grid
                                key={"0"}
                                id={"GIage"}
                                container={true}
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                xs={"10"}
                                item={true}
                              >
                                <Grid
                                  key={"0"}
                                  id={"VdvlY"}
                                  container={true}
                                  direction={"row"}
                                  justifyContent={"flex-start"}
                                  alignItems={"center"}
                                  lg={"3"}
                                  md={"3"}
                                  sm={"3"}
                                  xl={"3"}
                                  xs={"3"}
                                  item={true}
                                  spacing={"2"}
                                  style={qdmstyles.krB}
                                >
                                  <Avatar
                                    key={"0"}
                                    id={"group_icon"}
                                    alt={"Avatar"}
                                    variant={"square"}
                                    src={
                                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317006791"
                                    }
                                    aliasName={"groupicon"}
                                    style={qdmstyles.suyn}
                                  ></Avatar>
                                  <Typography
                                    key={"1"}
                                    id={"OPPharmacyHeading_Typography"}
                                    align={"inherit"}
                                    color={"initial"}
                                    display={"initial"}
                                    variant={"inherit"}
                                    className={classes.childname}
                                    children={data?.longdesc}
                                    aliasName={"OPPharmacyHeading"}
                                    style={{
                                      ...qdmstyles.lPmi,
                                      ...{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        width: "140px",
                                        whiteSpace: "nowrap",
                                      },
                                    }}
                                  ></Typography>
                                </Grid>
                                <Grid
                                  key={"1"}
                                  id={"dLQ2F"}
                                  container={true}
                                  direction={"row"}
                                  justifyContent={"flex-start"}
                                  alignItems={"center"}
                                  lg={"2"}
                                  item={true}
                                  md={"2"}
                                  sm={"3"}
                                  xl={"2"}
                                  xs={"2"}
                                  spacing={1}
                                  style={qdmstyles.bSGg}
                                >
                                  <Avatar
                                    key={"0"}
                                    id={"identificationdocuments_Icon"}
                                    alt={"Avatar"}
                                    variant={"square"}
                                    src={
                                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317031738"
                                    }
                                    aliasName={"identificationdocuments"}
                                    style={qdmstyles.MwHrJ}
                                  ></Avatar>
                                  <Typography
                                    key={"1"}
                                    id={
                                      "identificationdocumentsofpharmacy_Typography"
                                    }
                                    align={"inherit"}
                                    color={"initial"}
                                    display={"initial"}
                                    className={classes.childname}
                                    variant={"inherit"}
                                    children={
                                      data?.managingOrgEntityType?.code ||
                                      data?.locationID
                                    }
                                    aliasName={
                                      "identificationdocumentsofpharmacyHeading"
                                    }
                                    style={{
                                      ...qdmstyles.FMlbC,
                                      ...{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        width: "100px",
                                        whiteSpace: "nowrap",
                                      },
                                    }}
                                  ></Typography>
                                </Grid>
                                <Grid
                                  key={"2"}
                                  id={"HpgZ4"}
                                  container={true}
                                  direction={"row"}
                                  justifyContent={"flex-start"}
                                  alignItems={"center"}
                                  item={true}
                                  lg={"6"}
                                  md={"6"}
                                  sm={"6"}
                                  xl={"7"}
                                  xs={"6"}
                                  wrap={"wrap"}
                                  style={qdmstyles.sTAY}
                                >
                                  <Avatar
                                    key={"0"}
                                    id={"hospital_icon"}
                                    alt={"Avatar"}
                                    variant={"square"}
                                    src={
                                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317042167"
                                    }
                                    aliasName={"hospitalavatar"}
                                    style={qdmstyles.oKFkn}
                                  ></Avatar>
                                  <Typography
                                    key={"1"}
                                    id={"hospitalAddress_Typography"}
                                    align={"inherit"}
                                    color={"initial"}
                                    className={classes.childname}
                                    display={"initial"}
                                    variant={"inherit"}
                                    children={
                                      data?.managingOrgID?.name ||
                                      data?.managingOrgID?.[0]?.name
                                    }
                                    aliasName={"hospitaladdres"}
                                    style={{
                                      ...qdmstyles.wkMLO,
                                      ...{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        width: "100px",
                                        whiteSpace: "nowrap",
                                      },
                                    }}
                                  ></Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                key={"1"}
                                id={"YT8mI"}
                                container={true}
                                direction={"row"}
                                justifyContent={"flex-end"}
                                alignItems={"center"}
                                item={true}
                                lg={"2"}
                                md={"2"}
                                sm={"2"}
                                xl={"2"}
                                xs={"2"}
                              >
                                <Grid
                                  key={"0"}
                                  id={"edit_icon"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  item={true}
                                  aliasName={"editicon"}
                                >
                                  <Avatar
                                    key={"0"}
                                    onClick={() => {
                                      props.history.push({
                                        pathname: "/editnewpharmacy",
                                        state: {
                                          pharmacytable_pharmacy_row_key:
                                            data._key,
                                          isdisabled: data.status,
                                        },
                                      });
                                      // await window.location.reload();
                                    }}
                                    id={"editIocnInphramacy_Icon"}
                                    alt={"Avatar"}
                                    variant={"rounded"}
                                    src={
                                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317048880"
                                    }
                                    aliasName={"editIocnInphramacy"}
                                    style={qdmstyles.HnN}
                                  ></Avatar>
                                </Grid>
                                <Grid
                                  key={"1"}
                                  id={"kB6T8"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  item={true}
                                >
                                  {/* <Switch
                                key={"0"}
                                id={"switch_input"}
                                checked={
                                  data?.status === "active" ? true : false
                                }
                                color={"primary"}
                                name={"switch"}
                                aliasName={"switchIcon"}
                                disabled
                                onChange={() => {
                                  onChangeSwitch(data);
                                }}
                              ></Switch> */}
                                  <div
                                    style={{
                                      color:
                                        data?.status === "active"
                                          ? "#229a16"
                                          : "#b72136",
                                      background:
                                        data?.status === "active"
                                          ? "#54d62c29"
                                          : "#ff484229",
                                      padding: "4px 10px",
                                      margin: 6,
                                      fontSize: 13,
                                      borderRadius: 4,
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {data?.status}
                                  </div>
                                </Grid>

                                {/* //view................. */}

                                <Grid
                                  key={"0"}
                                  id={"edit_icon"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  item={true}
                                  aliasName={"editicon"}
                                >
                                  {/* <Avatar
                                    key={"0"}
                                    onClick={() => {
                                      props.history.push({
                                        pathname: "/editnewpharmacy",
                                        state: {
                                          pharmacytable_pharmacy_row_key:
                                            data._key,
                                          isdisabled: data.status,
                                        },
                                      });
                                      // await window.location.reload();
                                    }}
                                    id={"editIocnInphramacy_Icon"}
                                    alt={"Avatar"}
                                    variant={"rounded"}
                                    src={VisibilityIcon}
                                    aliasName={"editIocnInphramacy"}
                                    style={qdmstyles.HnN}
                                  ></Avatar> */}
                                  <VisibilityOutlinedIcon
                                    style={{
                                      fontSize: "19px",
                                      marginTop: "5px",
                                    }}
                                    onClick={() => {
                                      props.history.push({
                                        pathname: "/editnewpharmacy",
                                        state: {
                                          pharmacytable_pharmacy_row_key:
                                            data._key,
                                          isdisabled: "inactive",
                                          isView: "inactive",
                                        },
                                      });
                                      // await window.location.reload();
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              key={"1"}
                              id={"ZqbwK"}
                              container={true}
                              direction={"row"}
                              justifyContent={"flex-end"}
                              alignItems={"center"}
                              item={true}
                              lg={"12"}
                              md={"12"}
                              sm={"12"}
                              className={classes.childback}
                              xl={"12"}
                              xs={"12"}
                              style={qdmstyles.uUaBM}
                            >
                              <Grid
                                key={"0"}
                                id={"Aua8y"}
                                container={true}
                                direction={"row"}
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                                item={true}
                                lg={"4"}
                                md={"4"}
                                sm={"4"}
                                xl={"4"}
                                xs={"4"}
                              >
                                <Grid
                                  key={"0"}
                                  id={"l1r1c"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  item={true}
                                  lg={1}
                                >
                                  <Avatar
                                    key={"0"}
                                    id={"QOdcA"}
                                    alt={"Avatar"}
                                    variant={"rounded"}
                                    src={
                                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317066708"
                                    }
                                    style={qdmstyles.zJBLO}
                                  ></Avatar>
                                </Grid>
                                <Grid
                                  key={"1"}
                                  id={"AMTND"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  item={true}
                                >
                                  <Typography
                                    key={"0"}
                                    id={"zuMdg"}
                                    align={"inherit"}
                                    color={"initial"}
                                    display={"initial"}
                                    className={classes.address}
                                    variant={"body1"}
                                    children={
                                      (data?.address?.[0]?.text
                                        ? data?.address?.[0]?.text
                                        : "") +
                                      (data?.address?.[0]?.line
                                        ? "," + data?.address?.[0]?.line
                                        : "")
                                    }
                                    style={{
                                      ...qdmstyles.KxVE,
                                      ...{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        width: "100px",
                                        whiteSpace: "nowrap",
                                      },
                                      // color: Color.palette.text.black,
                                    }}
                                  ></Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                key={"1"}
                                id={"rmx2t"}
                                container={true}
                                direction={"row"}
                                justifyContent={"flex-end"}
                                alignItems={"center"}
                                item={true}
                                lg={"8"}
                                md={"8"}
                                sm={"8"}
                                xl={"8"}
                                xs={"8"}
                              >
                                <Grid
                                  key={"0"}
                                  id={"cvAe4"}
                                  container={true}
                                  direction={"row"}
                                  justifyContent={"flex-end"}
                                  alignItems={"center"}
                                  item={true}
                                  lg={"4"}
                                  md={"4"}
                                  sm={"5"}
                                  xl={"4"}
                                  xs={"5"}
                                >
                                  {/* <Avatar
                                key={"0"}
                                id={"EDfRn"}
                                alt={"Avatar"}
                                variant={"rounded"}
                                src={
                                  "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317068344"
                                }
                                style={qdmstyles.DsTX}
                              ></Avatar>
                              <Typography
                                key={"1"}
                                id={"vJpWK"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={"+(769)773-4125"}
                                style={qdmstyles.RWSF}
                              ></Typography> */}
                                </Grid>
                                <Grid
                                  key={"1"}
                                  id={"cvAe4"}
                                  container={true}
                                  className={classes.address}
                                  direction={"row"}
                                  justifyContent={"flex-end"}
                                  alignItems={"center"}
                                  item={true}
                                  lg={"4"}
                                  md={"4"}
                                  sm={"5"}
                                  xl={"4"}
                                  xs={"5"}
                                >
                                  {getTelecomNumber(data?.telecom)}
                                </Grid>
                                <Grid
                                  key={"2"}
                                  id={"cvAe4"}
                                  container={true}
                                  className={classes.address}
                                  direction={"row"}
                                  justifyContent={"flex-end"}
                                  alignItems={"center"}
                                  item={true}
                                  lg={"4"}
                                  md={"4"}
                                  sm={"5"}
                                  xl={"4"}
                                  xs={"5"}
                                >
                                  {getEmail(data?.telecom)}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <Typography
                    variant="body1"
                    style={{ textAlign: "center", margin: "10px 0px" }}
                  >
                    No data found
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Pharmacytable));
