/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2020-11-27
 * @modify date 2021-02-03
 * @desc withNavBars HOC will give you the Navbars (Top/Bottom Navbars)
 */

 import React from "react";
 import { makeStyles } from "@material-ui/core";
//  import { HoverNavBar } from "../components/navbars";
//  import { TopNavBar } from "../components/navbars";
 
 const drawerWidth = 80;
 
 const useStyles = makeStyles((theme) => ({
   root: {
     display: "flex",
      position:"relative",
      top:"63px",
   },
   topNavBar:{
     position:"fixed",
     zIndex:100
   },
   content: {
     width: `calc(100% - ${drawerWidth}px)`,
     position:"relative",
     left:`${drawerWidth}px`
   },
 }));
 
 const withHoverDrawer = (Component) => (props) => {
   const classes = useStyles({ props });
 
   return (
     <div>
       <div className={classes.topNavBar}>
         {/* <TopNavBar></TopNavBar> */}
       </div>
       <div className={classes.root}>
         {/* <HoverNavBar drawerWidth={drawerWidth} /> */}
         {/* Content */}
         <div className={classes.content}>
           <Component {...props}>{props.children}</Component>
         </div>
       </div>
     </div>
   );
 };
 
 export default withHoverDrawer;
 