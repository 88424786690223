import React from "react";
import { Button, makeStyles, Grid, Avatar, useTheme } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Switch from "@material-ui/core/Switch";
import { OverlayCompt } from "../../components";
import MainForm from "./orgForm";
import { withAllContexts } from "../../HOCs";
import { connect } from "react-redux";
import orgEntityName from "../../assets/org_entity.svg";
import orgCode from "../../assets/org_code.svg";
import orgParent from "../../assets/org_parent.svg";
import orgLevel from "../../assets/org_level.svg";
import org1Level from "../../assets/orgs_level.svg";
import orgAddress from "../../assets/org_address.svg";
import orgPersonName from "../../assets/org_person_name.svg";
import orgDesignation from "../../assets/org_designation.svg";
import orgMobile from "../../assets/org_mobile.svg";
import orgPhone from "../../assets/org_phone.svg";
import orgFax from "../../assets/org_fax.svg";
import orgEmail from "../../assets/org_email.svg";
import { actions } from "frequencyscreen_v_dbinder";
import { useDispatch, useSelector } from "react-redux";
//import { AlertProps } from "../../utils";
import BusinessIcon from "@material-ui/icons/Business";
import { getImgUrl } from "../../utils";
import { CustomTabs } from "../../components";
import { CommonTable } from "../../components";

const useStyles = makeStyles((theme) => ({
  titleDev: {
    height: "7vh",
    // backgroundColor: "#fff",
    background: theme.palette.background.table,
    display: "flex",
    alignItems: "center",
    paddingLeft: "4%",
    position: "relative",
    borderLeft: "2px solid #f6f6f6",
  },
  left_arrow: {
    padding: 6,
    backgroundColor: "white",
    position: "absolute",
    left: "-1%",
    bottom: "-26%",
    "& svg": {
      fontSize: 16,
      paddingLeft: 4,
    },
    boxShadow: "0 0px 1px 0 rgb(0 0 0 / 20%)",
    border: "1px solid #dddddd;",
    zIndex: 999,
  },
  rightSide: {
    right: 16,
    textAlign: "right",
    position: "relative",
  },
  leftSide: {
    textAlign: "left",
    left: 24,
    position: "relative",
  },
  Div: {
    paddingTop: "1%",
    display: "flex",
    alignItems: "center",
  },
  hosp_name: {
    fontSize: 16,
    // color: "#000",
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  hosp_pic: {
    borderRadius: 20,
  },
  detailTitle: {
    // color: "#000",
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontSize: 14,
    textAlign: "left",
  },
  detailBox: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "1%",
  },
  detailDiv: {
    marginTop: "2%",
  },
  boxFirstDiv: {
    marginRight: "2%",
    marginBottom: "2%",
  },
  detailData: {
    marginLeft: "2.6vh",
    textAlign: "left",
  },
  iconStyle: {
    fontSize: 18,
    color: "#0071F2",
    marginRight: 4,
  },
  addressbox: {
    padding: 12,
  },
  root: {
    "& .App1-MuiTabs-root": {
      "& .App1-MuiTabs-scroller": {
        "& .App1-MuiTabs-flexContainer": {
          alignItems: "center",
          justifyContent: "flex-start !important",
          margin: "0px 17px",
          "& .App1-MuiButtonBase-root": {
            marginRight: "2% !important",
          },
        },
      },
    },
  },
  detailDiv: {
    padding: 20,
    paddingBottom: "0px",
  },
  detailDiv1: {
    // padding: 20,
    padding: "16px 20px 10px 20px",
    // borderBottom: "1px solid #e0e0e0"
  },
  wrapper: {
    padding: "0px 20px",
  },
  buttonStyle: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },
}));

const MainView = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { parent_id } = props;
  const organizationSlice = useSelector((state) => state.organizationSlice);
  const handleChange = async (event) => {
    // props.backDrop.setBackDrop({
    // 	...props.backDrop,
    // 	open: true,
    // 	message: "Status Updating...",
    // });
    //let data =
    await dispatch(
      actions.ORGANIZATION_READ_STATUS_UPSERT({
        _key: props.handelkey,
        status: event.target.checked,
      })
    );
    // let status_res = data.payload.data.Code;
    let status_res = [];
    if (status_res === 201 || 200) {
      getdata(props.handelkey);
      props.alert.setSnack("update");
    } else {
      props.alert.setSnack("error");
    }
    // props.backDrop.setBackDrop({
    // 	...props.backDrop,
    // 	open: false,
    // 	message: "",
    // });
  };

  const handleEdit = (key, isexterna) => {
    props.onClickformClick(key, props?.isexternal ?? isexterna);
  };
  const dispatch = useDispatch();
  React.useEffect(() => {
    getdata(props.handelkey);
  }, [props.handelkey]);

  const getdata = async (key) => {
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Loading...",
    });
    await dispatch(actions.ORGANIZATION_READ_DETAILS({ _key: key }));
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
  };

  const getIconType = (type) => {
    switch (type) {
      case "Mobile":
        return orgMobile;
      case "Phone":
        return orgPhone;
      case "Fax":
        return orgFax;
      case "Email":
        return orgEmail;
      default:
        return orgMobile;
    }
  };
  const tabs = [
    {
      header: "CONTACT PERSON DETAILS",
      component: (
        <div
          className={classes.wrapper}
          id={`${parent_id}-CONTACT-PERSON-DETAILS-div`}
        >
          {props?.treeData?.data?.contact_person_name?.length > 0 &&
            props?.treeData?.data?.contact_person_name?.map((valus, i) => {
              return (
                <Grid
                  id={`${parent_id}-CONTACT-PERSON-DETAILS-grid`}
                  container
                  // direction="row"
                  // justifyContent="flex-start"
                  // alignItems="center"
                  className={classes.detailDiv1}
                  style={
                    i + 1 === props?.treeData?.data?.contact_person_name?.length
                      ? {}
                      : { borderBottom: "1px solid #e0e0e0" }
                  }
                >
                  <Grid item xs={12} md={4}>
                    <div
                      className={classes.boxFirstDiv}
                      id={`${parent_id}-CONTACT-PERSON-DETAILS-parent-div` + i}
                    >
                      <div
                        className={classes.detailBox}
                        id={`${parent_id}-CONTACT-PERSON-Name-title-div` + i}
                      >
                        <img
                          id={`${parent_id}-CONTACT-PERSON-Name-img` + i}
                          src={orgPersonName}
                          className={classes.iconStyle}
                          alt="pic"
                        />
                        Contact Person Name
                      </div>
                      <div
                        className={classes.detailData}
                        id={`${parent_id}-contactPersonName-treeData-div` + i}
                      >
                        {valus?.name === "" ? "-" : valus?.name}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div
                      className={classes.boxFirstDiv}
                      id={`${parent_id}-Designation-div` + i}
                    >
                      <div
                        className={classes.detailBox}
                        id={`${parent_id}-Designation-title-div` + i}
                      >
                        <img
                          id={`${parent_id}-Designation-img` + i}
                          src={orgDesignation}
                          className={classes.iconStyle}
                          alt="pic"
                        />
                        Designation
                      </div>
                      <div
                        className={classes.detailData}
                        id={`${parent_id}-Designation-treeData-div` + i}
                      >
                        {valus?.designation === "" ? "-" : valus?.designation}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div
                      className={classes.boxFirstDiv}
                      id={`${parent_id}-Mobile-div` + i}
                    >
                      <div
                        className={classes.detailBox}
                        id={`${parent_id}-Mobile-title-div` + i}
                      >
                        <img
                          id={`${parent_id}-Mobile-title-img` + i}
                          src={getIconType(valus?.contact_type ?? "Mobile")}
                          // src={orgMobile}
                          className={classes.iconStyle}
                          alt="pic"
                        />
                        {/* Mobile */}
                        {valus?.contact_type ?? "Mobile"}
                      </div>
                      <div
                        className={classes.detailData}
                        id={`${parent_id}-Mobile-treeData-div` + i}
                        title={valus?.contact ?? "-"}
                      >
                        {valus?.contact === ""
                          ? "-"
                          : `${valus?.contact}`?.length > 20
                          ? `${valus?.contact}`.slice(0, 20) + "..."
                          : valus?.contact}
                      </div>
                    </div>
                  </Grid>
                  {/* <div className={classes.boxFirstDiv}>
							<div className={classes.detailBox}>
								<img
									id={`${parent_id}-Phone-title-img`}
									src={orgPhone}
									className={classes.iconStyle}
								/>
								Phone
							</div>
							<div
								id={`${parent_id}-Phone-treeData-div`}
								className={classes.detailData}>
								{props?.treeData?.data?.contactData?.length > 0
									? "-"
									: props?.treeData?.data?.contactData?.[0]
										?.Phone
										? props?.treeData?.data?.contactData?.[0]
											?.Phone
										: "-"}
							</div>
						</div>
						<div
							id={`${parent_id}-Fax-div`}
							className={classes.boxFirstDiv}>
							<div
								id={`${parent_id}-Fax-title-div`}
								className={classes.detailBox}>
								<img
									id={`${parent_id}-Fax-title-img`}
									src={orgFax}
									className={classes.iconStyle}
								/>
								Fax
							</div>
							<div
								id={`${parent_id}-Fax-treeData-div`}
								className={classes.detailData}>
								{props?.treeData?.data?.contactData?.length > 0
									? "-"
									: props?.treeData?.data?.contactData?.[0]?.Fax
										? props?.treeData?.data?.contactData?.[0]?.Fax
										: "-"}
							</div>
						</div>
						<div
							id={`${parent_id}-Email-id-div`}
							className={classes.boxFirstDiv}>
							<div
								id={`${parent_id}-Email-id-title-div`}
								className={classes.detailBox}>
								<img
									id={`${parent_id}-Email-id-title-img`}
									src={orgEmail}
									className={classes.iconStyle}
								/>
								Email id
							</div>
							<div
								id={`${parent_id}-Email-id-treeData-div`}
								className={classes.detailData}>
								{props?.treeData?.data?.contactData?.length > 0
									? "-"
									: props?.treeData?.data.contactData?.[0]
										?.Email
										? props?.treeData?.data.contactData?.[0]
											?.Email
										: "-"}
							</div>
						</div> */}
                </Grid>
              );
            })}
          {/* {JSON.stringify(props?.treeData?.data?.contact_person_name)} */}
        </div>
      ),
    },
  ];
  if (props?.treeData?.data?.orgEntityCode === "OT003") {
    tabs.push({
      header: "Applicable Specialty",
      component: (
        <CommonTable
          parent_id={"main-CommonTable"}
          rightAlign={["Action"]}
          handleEdit={handleEdit}
          Header={["Specialty Name", "Effective From", "Effective To"]}
          dataList={
            organizationSlice?.organizationReadDetails?.data?.specialtyDetails
          }
          tableData={[
            { type: ["TEXT"], name: "specialty" },
            { type: ["TEXT"], name: "effFrom" },
            { type: ["TEXT"], name: "effTo" },
          ]}
        />
      ),
    });
  }
  return (
    <div className={classes.root}>
      {props.handelkey > 0 ? (
        <div id={`${parent_id}-parent-sub-div`}>
          <Grid id={`${parent_id}-parent-grid`} className={classes.titleDev}>
            {props.treeis ? null : (
              <IconButton
                id={`${parent_id}-iconbutton`}
                aria-label="left arrow"
                className={classes.left_arrow}
                onClick={props.onClickTreebtn()}
              >
                <ArrowBackIosIcon
                  id={`${parent_id}-ArrowBackIosIcon`}
                  style={{ transform: "rotate(180deg)" }}
                />
              </IconButton>
            )}

            <span id={`${parent_id}-ArrowBackIosIcon-span`}>
              {props?.treeData?.data?.parentEntity !== ""
                ? props?.treeData?.data?.entityName !== ""
                  ? props?.treeData?.data?.entityName
                  : props?.treeData?.data.parentEntity
                : props?.treeData?.data?.entityName}
            </span>
          </Grid>
          <Grid
            id={`${parent_id}-entity-grid`}
            container
            className={classes.Div}
          >
            <Grid
              id={`${parent_id}-entity-sub-grid`}
              item
              xs={6}
              className={classes.leftSide}
            >
              <p
                id={`${parent_id}-entity-text-p`}
                className={classes.hosp_name}
              >
                {props?.treeData?.data?.entityName === ""
                  ? "-"
                  : props?.treeData?.data?.entityName}
              </p>
            </Grid>
            <Grid
              id={`${parent_id}-buttons-grid`}
              item
              xs={6}
              className={classes.rightSide}
            >
              <Switch
                id={`${parent_id}-checked-switch`}
                onChange={handleChange}
                checked={
                  organizationSlice?.organizationReadDetails?.data?.status ??
                  false
                }
                name="checkedC"
                color={"primary"}
              />
              <Button
                id={`${parent_id}-Edit-button`}
                variant="contained"
                color="primary"
                size="small"
                onClick={() =>
                  handleEdit(props?.treeData?.data?._key, props?.isexternal)
                }
                className={classes.buttonStyle}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
          <Grid
            id={`${parent_id}-parent-grid`}
            container
            className={classes.Div}
            style={{ paddingTop: 0 }}
          >
            <Grid
              id={`${parent_id}-parent-sub-grid`}
              item
              xs={12}
              style={{
                backgroundColor: theme.palette.background.table,
                borderRadius: 14,
                padding: 22,
                margin: 12,
              }}
            >
              <Grid
                id={`${parent_id}-parent-sublevel-grid`}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={2} style={{ textAlign: "left" }}>
                  <div id={`${parent_id}-treedata-div`}>
                    {props?.treeData?.data?.log ? (
                      <Avatar
                        id={`${parent_id}-Avatar`}
                        alt={props?.treeData?.data?.log}
                        variant={"square"}
                        style={{
                          width: "125px",
                          height: "145px",
                          borderRadius: 20,
                        }}
                        src={getImgUrl(props?.treeData?.data?.log)}
                      />
                    ) : (
                      <div
                        id={`${parent_id}-hosp-pic-div`}
                        className={classes.hosp_pic}
                        style={{
                          backgroundColor: theme.palette.background.tableHeader,
                          height: "145px",
                          width: "125px",
                        }}
                      />
                    )}
                  </div>
                </Grid>

                <Grid id={`${parent_id}-title-grid`} item xs={10}>
                  <Grid id={`${parent_id}-title-sub-grid`} xs={12}>
                    <Grid
                      id={`${parent_id}-ENTITY-DETAILS-grid`}
                      style={{ paddingLeft: "15px" }}
                      container
                    >
                      <Grid
                        id={`${parent_id}-ENTITY-DETAILS-title-grid`}
                        item
                        xs={2}
                        className={classes.detailTitle}
                      >
                        <span>ENTITY DETAILS</span>{" "}
                      </Grid>
                      <Grid
                        id={`${parent_id}-ENTITY-DETAILS-divider-grid`}
                        item
                        xs={10}
                      >
                        <hr
                          id={`${parent_id}-ENTITY-DETAILS-divider`}
                          style={{ border: "1px solid #f1f1f1" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      id={`${parent_id}-titles-grid`}
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.detailDiv}
                    >
                      <Grid item md={4} xs={12}>
                        <div
                          id={`${parent_id}-titles-div`}
                          className={classes.boxFirstDiv}
                        >
                          <div
                            id={`${parent_id}-Entity-Name-title-div`}
                            className={classes.detailBox}
                          >
                            <img
                              id={`${parent_id}-Entity-Name-title-img`}
                              src={orgEntityName}
                              className={classes.iconStyle}
                              alt="pic"
                            />
                            Entity Name
                          </div>
                          <div
                            id={`${parent_id}-Entity-Name-treedata-div`}
                            className={classes.detailData}
                          >
                            {props?.treeData?.data?.entityName === ""
                              ? "-"
                              : props?.treeData?.data?.entityName}
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <div
                          id={`${parent_id}-Entity-Code-title-div`}
                          className={classes.boxFirstDiv}
                        >
                          <div
                            id={`${parent_id}-Entity-Code-title-sub-div`}
                            className={classes.detailBox}
                          >
                            <img
                              id={`${parent_id}-Entity-Code-orgCode-img`}
                              src={orgCode}
                              className={classes.iconStyle}
                              alt="pic"
                            />
                            Entity Code
                          </div>
                          <div
                            id={`${parent_id}-Entity-Code-treedata-div`}
                            className={classes.detailData}
                          >
                            {props?.treeData?.data?.entityCode === ""
                              ? "-"
                              : props?.treeData?.data?.entityCode}
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <div
                          id={`${parent_id}-Parent-Entity-div`}
                          className={classes.boxFirstDiv}
                        >
                          <div
                            id={`${parent_id}-Parent-Entity-title-div`}
                            className={classes.detailBox}
                          >
                            <img
                              id={`${parent_id}-Parent-Entity-img`}
                              src={orgParent}
                              className={classes.iconStyle}
                              alt="pic"
                            />
                            Parent Entity
                          </div>
                          <div
                            id={`${parent_id}-Parent-Entity-treedata-div`}
                            className={classes.detailData}
                          >
                            {props?.treeData?.data?.parentEntity === ""
                              ? "-"
                              : props?.treeData?.data.parentEntity}
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      id={`${parent_id}-titles-grid`}
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.detailDiv}
                    >
                      <Grid item md={4} xs={12}>
                        <div
                          id={`${parent_id}-Level-of-care-title-div`}
                          className={classes.boxFirstDiv}
                        >
                          <div
                            id={`${parent_id}-Level-of-care-title-sub-div`}
                            className={classes.detailBox}
                          >
                            <img
                              id={`${parent_id}-Level-of-care-orgLevel-img`}
                              src={orgLevel}
                              className={classes.iconStyle}
                              alt="pic"
                            />
                            Level of care
                          </div>
                          <div
                            id={`${parent_id}-Level-of-care-treedata-div`}
                            className={classes.detailData}
                          >
                            {props?.treeData?.data?.levelOfCare === ""
                              ? "-"
                              : props?.treeData?.data.levelOfCare}
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <div
                          id={`${parent_id}-Level-title-div`}
                          className={classes.boxFirstDiv}
                        >
                          <div
                            id={`${parent_id}-Level-orgLevel-div`}
                            className={classes.detailBox}
                          >
                            <img
                              id={`${parent_id}-Level-orgLevel-img`}
                              src={org1Level}
                              className={classes.iconStyle}
                              alt="pic"
                            />
                            Level
                          </div>
                          <div
                            id={`${parent_id}-Level-treedata-div`}
                            className={classes.detailData}
                          >
                            {props?.treeData?.data?.level === ""
                              ? "-"
                              : props?.treeData?.data.level}
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <div
                          id={`${parent_id}-Level-title-div`}
                          className={classes.boxFirstDiv}
                        >
                          <div
                            id={`${parent_id}-Level-orgLevel-div`}
                            className={classes.detailBox}
                          >
                            <img
                              id={`${parent_id}-Level-orgLevel-img`}
                              src={org1Level}
                              className={classes.iconStyle}
                              alt="pic"
                            />
                            External
                          </div>
                          <div
                            id={`${parent_id}-Level-treedata-div`}
                            className={classes.detailData}
                          >
                            {props?.treeData?.data?.external === ""
                              ? "-"
                              : props?.treeData?.data?.external
                              ? "Yes"
                              : "No"}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div
                    id={`${parent_id}-Address-parent-div`}
                    className={classes.addressbox}
                  >
                    <div
                      id={`${parent_id}-Address-div`}
                      className={classes.detailBox}
                    >
                      <img
                        id={`${parent_id}-Address-orgAddress-img`}
                        src={orgAddress}
                        className={classes.iconStyle}
                        alt="pic"
                      />
                      Address
                    </div>
                    <div
                      id={`${parent_id}-address-title-div`}
                      className={classes.detailData}
                    >
                      {props?.treeData?.data?.address === ""
                        ? "-"
                        : props?.treeData?.data.address}
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div
                    id={`${parent_id}-Date-Format-parent-div`}
                    className={classes.addressbox}
                  >
                    <div
                      id={`${parent_id}-Date-Format-div`}
                      className={classes.detailBox}
                    >
                      <img
                        id={`${parent_id}-Date-Format-img`}
                        src={orgAddress}
                        className={classes.iconStyle}
                        alt="pic"
                      />
                      Date Format
                    </div>
                    <div
                      id={`${parent_id}-Date-Format-title-div`}
                      className={classes.detailData}
                    >
                      {props?.treeData?.data?.orgdateformat?.display ?? "-"}
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div
                    id={`${parent_id}-Time-Format-parent-div`}
                    className={classes.addressbox}
                  >
                    <div
                      id={`${parent_id}-Time-Format-div`}
                      className={classes.detailBox}
                    >
                      <img
                        id={`${parent_id}-Time-Format-img`}
                        src={orgAddress}
                        className={classes.iconStyle}
                        alt="pic"
                      />
                      Time Format
                    </div>
                    <div
                      id={`${parent_id}-Time-Format-title-div`}
                      className={classes.detailData}
                    >
                      {props?.treeData?.data?.orgtimeformat?.display ?? "-"}
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div
                    id={`${parent_id}-Date-Time-Format-parent-div`}
                    className={classes.addressbox}
                  >
                    <div
                      id={`${parent_id}-Date-Time-Format-div`}
                      className={classes.detailBox}
                    >
                      <img
                        id={`${parent_id}-Date-Time-Format-img`}
                        src={orgAddress}
                        className={classes.iconStyle}
                        alt="pic"
                      />
                      Date Time Format
                    </div>
                    <div
                      id={`${parent_id}-Date-Time-Format-title-div`}
                      className={classes.detailData}
                    >
                      {props?.treeData?.data?.orgdatetimeformat?.display ?? "-"}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                id={`${parent_id}-customeTab-parent-div`}
                xs={12}
                style={{ marginTop: "2%" }}
              >
                <CustomTabs
                  parent_id={"customeTab"}
                  className={"customeTab"}
                  tabs={tabs}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div
          id={`${parent_id}-BusinessIcon-div`}
          style={{ display: "grid", height: "80vh" }}
        >
          <div
            id={`${parent_id}-BusinessIcon-sub-div`}
            style={{
              margin: "auto",
            }}
          >
            <BusinessIcon
              id={`${parent_id}-BusinessIcon`}
              style={{ fontSize: 120, color: "#e0e0e0" }}
            />
            <p
              id={`${parent_id}-Organization-p`}
              style={{ color: "#d0d0d0", fontSize: 18 }}
            >
              Select Organization
            </p>
          </div>
        </div>
      )}
      <OverlayCompt
        parent_id={"main-OverlayCompt"}
        open={props.isform}
        children={
          <React.Fragment>
            <MainForm
              isexternal={props?.isexternal}
              parent_id={"MainForm"}
              handleEdit={props.onClickformClick}
              getDat={getdata}
              handelkey={props?.handelkey}
            />
          </React.Fragment>
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  treeData: state?.organizationSlice?.organizationReadDetails,
});
export default connect(mapStateToProps, actions)(withAllContexts(MainView));
//export default MainView;
