import React, { Component } from "react";
import { EditorState, convertToRaw, convertFromRaw, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "./style.css";
import { Button } from "@material-ui/core";
import Color from "../../../themes/default.json";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";

const customToolbarOptions = {
  options: ["inline", "list"],
  inline: {
    options: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "superscript",
      "subscript",
    ],
    className: "rtf-toolbar-inline",
  },
  list: {
    options: ["unordered", "ordered"],
    className: "rtf-toolbar-list",
  },
};

const insertCharacter = (characterToInsert, editorState) => {
  const currentContent = editorState.getCurrentContent(),
    currentSelection = editorState.getSelection();

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    characterToInsert
  );

  const newEditorState = EditorState.push(
    editorState,
    newContent,
    "insert-characters"
  );

  return newEditorState;

  // return EditorState.forceSelection(
  //   newEditorState,
  //   newContent.getSelectionAfter()
  // );
};

class RichTextfield extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    // 
    this.setState({
      // editorState: convertFromRaw(this.props.initialValue),
      editorState: EditorState.moveSelectionToEnd(
        EditorState.createWithContent(stateFromHTML(this.props.initialValue))
      ),
    });
  }

  getButtonAction = (text, createFieldMentions = false) => {
    const contentState = this.state.editorState.getCurrentContent();
    const selection = this.state.editorState.getSelection();

    let newContentState = Modifier.insertText(contentState, selection, text);
    // console.log(convertToRaw(this.state.editorState.getCurrentContent()));

    const newEditorState = EditorState.push(
      this.state.editorState,
      newContentState,
      "insert-characters"
    );

    this.setState({
      editorState: EditorState.forceSelection(
        newEditorState,
        newContentState.getSelectionAfter()
      ),
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const prevEditorState = this.props.initialValue;
    const nextEditorState = nextProps.initialValue;
    if (prevEditorState !== nextEditorState) {
      // If Empty initial value provided then update the editor state with empty content.
      if (!Boolean(nextEditorState)) {
        this.setState({
          editorState: EditorState.createWithContent(
            stateFromHTML(nextEditorState)
          ),
        });
      }
    }

    const prevAppendValue = this.props.appendValue;
    const nextAppendValue = nextProps.appendValue;

    if (prevAppendValue !== nextAppendValue) {
      if (Boolean(nextAppendValue)) {
        const newEditorState = insertCharacter(
          nextAppendValue,
          this.state.editorState
        );
        this.setState(
          {
            editorState: newEditorState,
          },
          () => {
            this.props.onChange(
              stateToHTML(this.state.editorState.getCurrentContent())
            );
            this.props.resetAppendValue();
          }
        );
      }
    }

    return true;
  }

  onEditorStateChange = (editorState) => {
    this.setState(
      {
        editorState,
      },
      () => {
        // const currentContent = editorState.getCurrentContent()

        // const raw = convertToRaw(editorState.getCurrentContent());
        const html = stateToHTML(editorState.getCurrentContent());
        this.props.onChange(html);
      }
    );
  };

  render() {
    const { withDirectionTags, theme, dropdownOptions = {} } = this.props;
    const { editorState } = this.state;
    const { data: tags } = dropdownOptions;

    return (
      <>
        <Editor
          editorState={editorState}
          toolbar={customToolbarOptions}
          toolbarClassName={
            withDirectionTags
              ? "rtf-toolbar-with-direction-control"
              : "rtf-toolbar"
          }
          wrapperClassName={
            withDirectionTags ? "rtf-wrapper-with-direction" : "rtf-wrapper"
          }
          editorClassName="rtf-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
        {withDirectionTags && (
          <div
            style={{
              display: "flex",
              border: "1px solid #DEE5EC",
              borderTop: "0px",
              padding: "8px",
              borderRadius: "8px",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              flexWrap:"wrap"
            }}
          >
            {tags?.map((val) => (
              <Button
                style={{
                  margin: "10px",
                  color: theme.palette.button.background,
                  backgroundColor: "#aeb4ba",
                  fontSize: "12px",
                  border: "none",
                }}
                classes={{
                  label: "button-label",
                }}
                title="Frequency"
                onClick={() => this.getButtonAction(val?.display)}
              >
                {val?.code}
              </Button>
            ))}
            {/* <Button
              style={{
                marginLeft: "10px",
                color: theme.palette.button.background,
                backgroundColor: "#aeb4ba",
                fontSize: "12px",
                border: "none",
              }}
              classes={{
                label: "button-label",
              }}
              title="Frequency"
              onClick={() => this.getButtonAction("<Frequency>")}
            >
              Frequency
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                color: theme.palette.button.background,
                backgroundColor: "#aeb4ba",
                fontSize: "12px",
              }}
              classes={{
                label: "button-label",
              }}
              title="Route"
              onClick={() => this.getButtonAction("<Route>")}
            >
              Route
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                color: theme.palette.button.background,
                backgroundColor: "#aeb4ba",
                fontSize: "12px",
              }}
              classes={{
                label: "button-label",
              }}
              title="Order Dose"
              onClick={() => this.getButtonAction("<Order Dose>")}
            >
              Order Dose
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                color: theme.palette.button.background,
                backgroundColor: "#aeb4ba",
                fontSize: "12px",
              }}
              classes={{
                label: "button-label",
              }}
              title="Admin Qty"
              onClick={() => this.getButtonAction("<Admin Qty>")}
            >
              Admin Qty
            </Button> */}
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  dropdownOptions:
    state?.drugCatalogMasterSlice?.directionTags,
});
export default connect(
  mapStateToProps,
  actions
)(withStyles({}, { withTheme: true })(RichTextfield));
