import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";

export const SearchInputBox = withStyles((theme) => ({
  root: {
    height: 36,
    width: 270,
    flex: 0,
    gap: 10,
    padding: "4px 8px",
    background: "#F1F6FC",
    borderRadius: "8px",
    "& .App1-MuiInputBase-input": {
      minWidth: 20,
    },
    "& .App1-MuiInputAdornment-root": {
      "& .App1-MuiSvgIcon-root": {
        fontSize: "1.3rem",
      },
    },
  },
  input: {
    padding: 0,
    "&:focus": {
      backgroundColor: "unset",
    },
  },
}))(InputBase);
