import { makeStyles } from "@material-ui/core/styles";

export const useDrugParticularsStyles = makeStyles((theme) => ({
  Maintitle: {
    color: theme.palette.text.primary,
  },
  MainBackground: {
    background: `${theme.palette.background.common} !important`,
  },
  MainBackgroundCard: {
    background: `${theme.palette.background.table} !important`,
  },
  root: {
    color: theme.palette.text.primary,
    position: "relative",
    padding: theme.spacing(0.5, 2, 0.5, 1.5),
    borderRadius: 8,
    background: `${theme.palette.background.table} !important`,
    display: "flex",
    alignItems: "center",
    gap: 8,
    minHeight: 37,
    "& .App1-MuiFormControl-root": {
      "& .App1-MuiInputBase-root": {
        fontSize: "12px",
      },
    },
  },
  nroot: {
    position: "relative",
    padding: theme.spacing(0.5, 2, 0.5, 1.5),
    borderRadius: 8,
    background: `${theme.palette.background.table} !important`,
    display: "flex",
    alignItems: "center",
    gap: 8,
    minHeight: 37,
    "& .App1-MuiFormControl-root": {
      "& .App1-MuiInputBase-root": {
        fontSize: "12px",
      },
    },
  },
  expandCollapseButton: {
    zIndex: 1,
    position: "absolute",
    bottom: "-10px",
    left: "5px",
  },
  defaultText: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.primary,
    // color: "#2A3C50",
    whiteSpace: "nowrap",
    lineHeight: 1,
  },
  headerText: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.primary,
    // color: "#2A3C50",
    whiteSpace: "nowrap",
    lineHeight: 1,
    // width: "8%",
  },
  defaultText2: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.primary,
    // color: "#2A3C50",
    whiteSpace: "nowrap",
    lineHeight: 1,
    marginTop: "2px",
    marginLeft: "3px",
  },
  doseDetailItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
  },
  stripQuantity: {
    cursor: "pointer",
    flex: 0,
  },
  stripFielAdornText: {
    color: "#B4BBC3",
    fontSize: 12,
    fontWeight: 400,
    whiteSpace: "nowrap",
    lineHeight: 1,
  },
  actionContainer: {
    display: "flex",
    gap: 8,
  },
  select: {
    "& ul": {
      // backgroundColor: "#cccccc",
      // width:"90px"
    },
    "& li": {
      fontSize: 12,
      // width:"90px"
    },
  },
  total: {
    display: "flex",
  },
  bold: {
    fontWeight: 500,
    fontSize: "14px",
    paddingBottom: "8px",
  },
  paper: {
    borderRadius: "8px",
  },
  boldtext: {
    fontWeight: 500,
    fontSize: "12px",
  },
  greytext: {
    fontSize: "12px",
    
  },
  amount: {
    fontWeight: 500,
    fontSize: "14px",
  },
  dialogPaper: {
    borderRadius: "8px",
    minWidth: "742px",
  },
  pdfDialogPaper: {
    minWidth: "750px",
  },
  iconContainer: {
    backgroundColor: "rgb(42, 96, 188)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    height: 20,
    width: 20,
    minWidth: 20,
    cursor: "pointer",
  },
}));

export const useDrugBatchStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid #DEE5EC",
    maxHeight: 45,
  },
  paperImage: {
    width: "500px",
  },
  defaultText: {
    color: "#2A3C50",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1,
  },
  defaultText2: {
    fontSize: 12,
    fontWeight: 400,
    color: "#2A3C50",
    whiteSpace: "nowrap",
    lineHeight: 1,
    marginTop: "2px",
    marginLeft: "3px",
  },
  batchIdExpiry: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
    flexWrap: "nowrap",
    padding: 12,
  },
  batchId: {
    color: "#001C3C",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1,
    minWidth: "20px",
    maxWidth: "150px",
  },
  expiry: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
  },
  stockBlockedAmount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
    flexWrap: "nowrap",
    padding: 12,
  },
  stockDetailItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
  },
  BinLocation: {
    borderRadius: 4,
    background: "#DDE7F9",
    display: "flex",
    alignItems: "center",
    gap: 6,
    minHeight: 23,
    paddingRight: 6,
    margin: "10px 12px",
  },
  binNumber: {
    background: "#385181",
    borderRadius: 4,
    alignSelf: "stretch",
    display: "flex",
    padding: "3px 6px",
    "& p": {
      color: "white",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1,
      margin: "auto",
      "& span": {
        fontWeight: 500,
      },
    },

    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  },
  aisleShelfLevel: {
    fontSize: 12,
    fontWeight: 600,
    color: "#2A3C50",
    lineHeight: 1,
  },
  stripQuantityContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    padding: "6px 12px",
  },
  doseInputLabelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    border: "1px solid #DEE5EC",
    borderRadius: "8px",
    padding: "5px",
    "& > p": {
      
      lineHeight: 1,
      fontSize: 14,
      fontWeight: 500,
    },
  },
  aliign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
  },
  doseInput: { maxWidth: 100, borderRadius: 8 },
  addedDrugContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 6,
    background: "#6A7888",
    borderRadius: 4,
    padding: "4px 6px",
    minWidth: 50,
    height: 24,
    "& > p": {
      color: "white",
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1,
    },
  },
  total: {
    display: "flex",
  },
  bold: {
    fontWeight: 500,
    fontSize: "14px",
    paddingBottom: "4px",
    marginLeft: "5px",
  },
  paper: {
    borderRadius: "8px",
  },
  boldtext: {
    fontWeight: 500,
    fontSize: "12px",
  },
  greytext: {
    fontSize: "12px",
    
    marginLeft: "8px",
  },
  amount: {
    fontWeight: 500,
    fontSize: "14px",
  },
  dialogPaper: {
    borderRadius: "8px",
  },
  doseDetailItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
  },
});

export const useDividerStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#DEE5EC !important",
    borderRadius: "0px !important",
  },
}));

export const useCustomDotStyles = makeStyles({
  root: {
    height: 4,
    width: 4,
    backgroundColor: "#B4BBC3 !important",
    borderRadius: "50% !important",
    display: "inline-block",
  },
});

export const useCustomDotStyles2 = makeStyles({
  root: {
    height: 8,
    width: 8,
    position: "absolute",
    marginTop: "2px",
    left: 12,
    backgroundColor: "#B4BBC3 !important",
    borderRadius: "50% !important",
    display: "inline-block",
  },
});

export const useCustomTooltip = makeStyles({
  tooltip: {
    backgroundColor: "#485C74",
    fontSize: 12,
    fontWeight: 500,
  },
});
