import React from "react";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { LoggerContext } from "../../contexts";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, Avatar, Typography, Divider } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
import { getAge, getImgUrl, makeName } from "../../utils";
import classNames from "classnames";
import { StyledDialog } from "../commonComponents";
import { useDialogContentStyles, useDialogStyles } from "./styles";
import PatientProfile from "../patientProfile";
import { differenceInYears } from "date-fns";
var aes256 = require("aes256");

function PatientInfo(props) {
  const dispatch = useDispatch();
  const dialogStyles = useDialogStyles();
  const dialogContentStyles = useDialogContentStyles();

  const { propsState, classes } = props;

  const [state, setState] = React.useState({
    permissionData: null,
  });

  const [patientInfoDialogOpen, setPatientInfoDialogOpen] =
    React.useState(false);

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {}, [state]);

  const getGender = (value) => {
    switch (value) {
      case "male":
        return "M";
      case "female":
        return "F";
      default:
        return "";
    }
  };

  const handleOpenPatientInfoDialog = () => {
    setPatientInfoDialogOpen(true);
  };

  const handleClosePatientInfoDialog = () => {
    setPatientInfoDialogOpen(false);
  };

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            id={"patient_info_card"}
            container={true}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            aliasName={"patientInfoCard"}
            style={qdmstyles.VIG}
            className={classNames(
              classes.MainBackgroundCard,
              classes.patientInfoCard
            )}
            onClick={handleOpenPatientInfoDialog}
          >
            <Grid
              id={"time_component"}
              container={false}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              aliasName={"timeComponent"}
              item={true}
              lg={"2"}
              md={"2"}
              sm={"2"}
              xl={"2"}
              xs={"2"}
              style={qdmstyles.soeN}
            >
              <Box
                id={"ticket_side_box"}
                m={"1"}
                component={"div"}
                aliasName={"ticketSideBox"}
                style={qdmstyles.NWCMl}
              >
                <Box
                  id={"ticket_box"}
                  m={"1"}
                  component={"div"}
                  aliasName={"ticketBox"}
                >
                  <Avatar
                    id={"ticketLogo"}
                    alt={"Avatar"}
                    variant={"rounded"}
                    aliasName={"ticketLogo"}
                    src={
                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/320008908"
                    }
                    style={qdmstyles.oqQrP}
                  ></Avatar>
                </Box>
                <Typography
                  id={"ticket_name"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"101"}
                  aliasName={"ticketName"}
                  style={qdmstyles.jBADj}
                  className={classes.Maintitle}
                ></Typography>
              </Box>
              <Box
                id={"clock_side_box"}
                m={"1"}
                component={"div"}
                aliasName={"clockSideBox"}
                style={qdmstyles.iMKcz}
              >
                <Box
                  id={"clock_box"}
                  m={"1"}
                  component={"div"}
                  aliasName={"clockBox"}
                >
                  <Avatar
                    id={"ticketLogo"}
                    alt={"Avatar"}
                    variant={"rounded"}
                    aliasName={"ticketLogo"}
                    src={
                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316382595"
                    }
                    style={qdmstyles.lLxl}
                  ></Avatar>
                </Box>
                <Typography
                  id={"ticket_name"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"00:12"}
                  aliasName={"ticketName"}
                  style={qdmstyles.lGXjn}
                  className={classes.Maintitle}
                ></Typography>
              </Box>
            </Grid>
            <Grid
              id={"patient_info_avatar"}
              container={false}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              aliasName={"patientInfoAvatar"}
              lg={"2"}
              md={"2"}
              sm={"2"}
              xs={"2"}
              item={true}
              alignContent={"center"}
              xl={"2"}
              style={qdmstyles.NkuI}
            >
              <Avatar
                id={"patient_avatar"}
                alt={"Avatar"}
                variant={"rounded"}
                aliasName={"patientAvatar"}
                src={getImgUrl(propsState?.photo?.[0]?.fileid) ?? ""}
                style={qdmstyles.LibJ}
              ></Avatar>
            </Grid>
            <Grid
              key={"2"}
              id={"patient_info_contents"}
              container={false}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              aliasName={"patientInfoContents"}
              lg={"8"}
              md={"8"}
              xs={"8"}
              item={true}
              sm={"8"}
              xl={"8"}
              style={qdmstyles.JIWs}
            >
              <Grid
                id={"contents_div"}
                container={false}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                aliasName={"contentsDiv"}
              >
                <Grid
                  id={"header_patient_card"}
                  container={false}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  aliasName={"headerPatientCard"}
                  style={qdmstyles.aBc}
                >
                  <Box
                    id={"patient_name"}
                    m={"1"}
                    component={"div"}
                    aliasName={"patientName"}
                  >
                    <Typography
                      noWrap
                      title={makeName(propsState?.name?.[0] || {})}
                      id={"patient_name_typo"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={makeName(propsState?.name?.[0] || {})}
                      aliasName={"patientNameTypo"}
                      style={qdmstyles.zGwA}
                      className={classes.Maintitle}
                    ></Typography>
                  </Box>
                  <Box
                    id={"patient_info_age_div"}
                    m={"1"}
                    component={"div"}
                    aliasName={"patientInfoAgeDiv"}
                    style={qdmstyles.vsru}
                  >
                    {propsState?.age && (
                      <Box
                        id={"Dot"}
                        m={"1"}
                        component={"div"}
                        aliasName={"Dot"}
                        style={qdmstyles.Cjdr}
                      ></Box>
                    )}
                    <Typography
                      id={"patient_age"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={`${
                        getAge(propsState?.birthDate) ?? ""
                      }${getGender(
                        propsState?.gender?.display?.toLowerCase()
                      )}`}
                      aliasName={"patientAge"}
                      style={qdmstyles.Qpe}
                      className={classes.Maintitle}
                    ></Typography>
                    {propsState?.bloodGroup?.display && (
                      <Box
                        key={"2"}
                        id={"Dot"}
                        m={"1"}
                        component={"div"}
                        aliasName={"Dot"}
                        style={qdmstyles.owfK}
                      ></Box>
                    )}
                  </Box>
                  <Typography
                    key={"2"}
                    id={"patient_blood"}
                    align={"inherit"}
                    noWrap
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    title={propsState?.bloodGroup?.display}
                    children={propsState?.bloodGroup?.display}
                    aliasName={"patientBlood"}
                    style={qdmstyles.FNSVv}
                  ></Typography>
                </Grid>
                <Grid
                  id={"patient_info_contact"}
                  container={false}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  aliasName={"patientInfoContact"}
                  style={qdmstyles.ocJLF}
                >
                  <Box
                    id={"mrn_div"}
                    m={"1"}
                    component={"div"}
                    aliasName={"mrnDiv"}
                    style={qdmstyles.NVscD}
                  >
                    <Box
                      id={"mrn_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"mrnBox"}
                    >
                      {propsState?.alias && (
                        <Avatar
                          id={"mrn_logo"}
                          alt={"Avatar"}
                          variant={"rounded"}
                          aliasName={"mrnLogo"}
                          src={
                            "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316316455"
                          }
                          style={qdmstyles.GgZKW}
                        ></Avatar>
                      )}
                    </Box>
                    <Typography
                      id={"mrn_no"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={propsState?.alias}
                      aliasName={"mrnNo"}
                      style={qdmstyles.TqSDd}
                    ></Typography>
                  </Box>
                  <Box
                    id={"phoneDiv"}
                    m={"1"}
                    component={"div"}
                    aliasName={"phoneDiv"}
                    style={qdmstyles.pIyKn}
                  >
                    <Box
                      id={"phone_box"}
                      m={"1"}
                      component={"div"}
                      aliasName={"phoneBox"}
                    >
                      {propsState?.telecom?.[0]?.value && (
                        <Avatar
                          id={"phone_logo"}
                          alt={"Avatar"}
                          variant={"rounded"}
                          aliasName={"phoneLogo"}
                          src={
                            "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316338442"
                          }
                          style={qdmstyles.oy}
                        ></Avatar>
                      )}
                    </Box>
                    <Typography
                      id={"phone_no"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={propsState?.telecom?.[0]?.value}
                      aliasName={"phoneNo"}
                      style={qdmstyles.uThG}
                    ></Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider
                id={"patient_info_divider"}
                variant={"fullWidth"}
                orientation={"vertical"}
                aliasName={"patientInfoDivider"}
                style={qdmstyles.vhPU}
              ></Divider>
            </Grid>
          </Grid>
          <StyledDialog
            id="Patient_Info"
            open={patientInfoDialogOpen}
            handleClose={handleClosePatientInfoDialog}
            title={"PATIENT PROFILE"}
            dialogProps={{
              classes: dialogStyles,
            }}
            contentProps={{
              classes: dialogContentStyles,
            }}
          >
            <PatientProfile propsState={propsState} />
          </StyledDialog>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(PatientInfo));
