import React from "react";
import { Button, Grid } from "@material-ui/core";
import { CommonTabList } from "../../../../../../components";
import OrderDescription from "./orderDescription";
//import Characteristic from "./characteristic";
//import ConsentRequired from "./consentRequired";
//import CheckDuplicate from "./checkDuplicate";
import DefaultQuantity from "./defaultQuantity";
import GeneralReport from "./generalReport";
//import AttributesComp from "./attributes";
import MultiTabForms from "./multiTabforms";
//import { AlertProps } from "../../../../../../utils";
// import { withAllContexts } from "../../../../../HOCs";
// import RangeDetails from "./rangeDetails";
// import CategoryDetails from "./categoryDetails";

import { withAllContexts } from "../../../../../../HOCs";
import { connect } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { valitationFunc } from "../../../../../../utils";

const errorList = [
  "code",
  "longdes",
  "shortdes",
  "orderCategory",
  "orderType",
  "Qtyno",
  "DefaultQuantity",
  // "attribute",
  // "SpecimenType",
  // "VolumeQuantity",
  // "Volume",
  // "SpecimenContainer",
];

// const QuantitySection = ["Qtyno", "DefaultQuantity"];
// const specimenSection = ["SpecimenType", "VolumeQuantity", "Volume"];
class OrderCatalogForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      longdes: "",
      shortdes: "",
      catalogSynonym: "",
      orderCategory: null,
      orderType: null,
      healthcareService: null,
      Qtyno: "",
      DefaultQuantity: null,
      AllowQtyCheck: false,
      maxno: "",
      Maxorder: null,
      explantorymsg: "",

      characteristicJson: [
        {
          label: "Complete On Order",
          checked: false,
        },
        {
          label: "Hold Applicable",
          checked: false,
        },
        {
          label: "Discontinue Applicable",
          checked: false,
        },
        {
          label: "Chargeable",
          checked: false,
        },
        {
          label: "Result Applicable",
          checked: false,
        },
        {
          label: "Result Authentication Required",
          checked: false,
        },
      ],
      checkSwitch: false,
      no: "",
      DuplicatePeriod: null,
      CheckAction: null,
      consentSwitch: false,
      ConsentForm: null,
      attribute: "",
      AttributeList: [],
      FacilityData: [],
      SpecimenType: null,
      VolumeQuantity: "",
      Volume: null,
      BodyType: null,
      FastingPeriodQuantity: "",
      FastingPeriod: null,
      SpecimenContainer: null,
      CollectionMethod: null,
      instructions: [],
      Frequency_applicability: false,
      frequency: [],
      editData: null,
      id: 0,
      draftstatus: true,
      status: true,
      error:{}
    };
    this.handleFormState = this.handleFormState.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    // this.addAttribute = this.addAttribute.bind(this);
  }

  async componentDidMount() {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "fetching...",
    });
    await this.props.ORDER_CATALOG_MASTERS();

    if (this.props.editData) {
      const editData = this.props.editData;
      await this.props.EDIT_ORDER_CATALOG({ id: editData });
      let data = JSON.parse(JSON.stringify(this.props.editOrderCatalog.data));

      if (!this.props.editOrderCatalog.error) {
        await this.props.ORDER_TYPE_DATA({ type: data.orderCategory.value });
        this.state = data;
        this.setState({
          ...this.state,
        });
      } else {
        this.props.alert.setSnack("error");
      }
    }
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  }
  handleFormState = async (name, value, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
     let errorObj = JSON.parse(JSON.stringify(this.state.error));
     if (errorObj[name]){
      errorObj[name] = false;
     } 
    if(name === "code"){
       errorObj[name] = false;
      this.state[name] = value?.replaceAll(/[.*+?^${}()"|[\]\\]/g,'')?.replaceAll(" ", "")?.toLocaleUpperCase();
      this.setState({ ...this.state, error: errorObj });
      return
    }
    if (name === "orderCategory" && value) {
      await this.props.ORDER_TYPE_DATA({ type: value.value });
    }
    this.setState({ [name]: value, error: errorObj });
  };
  handleCheckbox = (value, i) => {
    const state = this.state;
    
    state.characteristicJson[i]["checked"] = value;
    this.setState({ ...state });
  };
  // addAttribute = (data) => {
  //   this.setState((prevState) => ({
  //     AttributeList: [...prevState.AttributeList, data],
  //   }));
  // };

  draftValidation = () => {
    if (
      this.state?.code?.length > 0 &&
      this.state?.longdes?.length > 0 &&
      this.state?.shortdes?.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  saveForm = async (fun) => {
    // let error = false;
    // let errorMessage = "";

    // if (fun === "draft") {
    //   // error = this.draftValidation();
    //   // errorMessage = "Please fill at least Order Catalog Code, Short Description, Long Description fields"
    //   errorMessage = "Please fill mandatory fields";
    //   errorList.map((val) => {
    //     if (!this.state[val]) {
    //       arr.push(val);
    //       error = true;
    //     }
    //   });
    // } else {

    // errorList.map((val) => {
    //   errorMessage =
    //     "Please fill all mandatory fields in Order Description Section";
    //   if (!this.state[val]) {
    //     error = true;
    //   }
    // });
    // // }

    // if (!error) {
    //   QuantitySection.map((val) => {
    //     errorMessage = "Please fill all mandatory fields in Quantity Section";
    //     if (!this.state[val]) {
    //       error = true;
    //     }
    //   });
    // }
    // if (!error && fun !== "draft") {
    //   specimenSection.map((val) => {
    //     errorMessage = "Please fill all mandatory fields in Specimen Details Section";
    //     if (!this.state[val]) {
    //       error = true;
    //     }
    //   });
    // }

    // new attribute changed valiodation function - pc-5639
    // if(this.state.orderCategory?.label?.toLocaleLowerCase() === "lab"){
    //   errorList.splice(errorList.indexOf('SpecimenContainer'), 1);
    // }

    // attribute list is greter then 0 it's need mandatory for attribute Label Name filed
    if(this.state.AttributeList?.length > 0){
      errorList.push("attribute");
    }else{
      errorList.splice(errorList.indexOf('attribute'), 1);
    }

    

    let { error, errorState } = await valitationFunc(this.state, errorList);
    
    if (error) {
      this.props.alert.setSnack("mandatory");
       this.setState({
         ...this.state,
         error: errorState,
       });
    } else {
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: fun === "draft" ? "Saving as draft..." : "saving...",
      });
      if (fun === "draft") {
        this.state.draftstatus = true;
      } else {
        this.state.draftstatus = false;
      }

      await this.props.SAVE_ORDER_CATALOG({ list: this.state });
      let type = this.props.editData ? "update":"success"
       this.props.alert.setSnack(type);

      if (
        this.props.saveOrderCatalog?.data?.Code === 201 &&
        !this.props.saveOrderCatalog?.error
      ) {
        await this.props.READ_ORDER_CATALOG({ page: this.props.parentState?.page, perPage:this.props.parentState?.perPage });
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false,
            message: "",
          });
         this.props.closeForm();
      } else {
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
          message: "",
        });
        if (this.props.saveOrderCatalog?.data?.error === "Validation Not Passed") {
          const isUnique = (this.props.saveOrderCatalog?.data?.validation_error || [])?.some(val => {
            if ((val?.Errormsg || "")?.includes("unique")) {
              return true;
            }
            return false;
          });
          if (isUnique) {
            this.props.alert.setSnack("unique");
            return;
          }
        }
        this.props.alert.setSnack("error");
      }
    }
  };

  render() {
    const { closeForm = () => null, parent_id } = this.props;
    // console.log("state", this.state);
    return (
      <React.Fragment>
        <CommonTabList
          parent_id={"CommonTabList"}
          backbtn
          backFun={closeForm}
          title={
            this.props.editData
              ? "Update Order Catalog"
              : "Add New Order Catalog"
          }
          list={[]}
        />
        <Grid id={`${parent_id}-form-div`} style={{ padding: "20px" }}>
          <OrderDescription
            parent_id={"order_catalog_order_discription"}
            handleFormState={this.handleFormState}
            isEdit={this.props.editData??false}
            {...this.state}
          />
        </Grid>
        <Grid id={`${parent_id}-form-div`} container>
          <Grid
            id={`${parent_id}-form-grid`}
            item
            xs={6}
            style={{ padding: "20px" }}
          >
            <DefaultQuantity
              parent_id={"order_catalog_quantity"}
              handleFormState={this.handleFormState}
              {...this.state}
            />
          </Grid>
          <Grid
            id={`${parent_id}-GeneralReport-form-grid`}
            item
            xs={6}
            style={{ padding: "20px" }}
          >
            <GeneralReport
              parent_id={"order_catalog_remarks"}
              handleFormState={this.handleFormState}
              {...this.state}
            />
          </Grid>
        </Grid>
        <Grid
          id={`${parent_id}-form-MultiTab-grid`}
          style={{ padding: "20px" }}
        >
          <MultiTabForms
            parent_id={"order_catalog_multiTabForms"}
            handleCheckState={this.handleCheckbox}
            handleFormState={this.handleFormState}
            addAttribute={this.addAttribute}
            {...this.state}
          />
        </Grid>

        <Grid style={{ padding: "20px" }}>
          <Button
            onClick={() => this.saveForm("save")}
            style={{ float: "right" }}
            variant="contained"
            color="primary"
          >
            {this.props.editData ? "Update" : "Save"}
          </Button>
          {(!this.props.editData || this.state.draftstatus) && (
            <Button
              onClick={() => this.saveForm("draft")}
              style={{ float: "right", marginRight: "20px" }}
              variant="outlined"
              color="primary"
            >
              Save As Draft
            </Button>
          )}
          <Button
            onClick={() => closeForm()}
            style={{ float: "right", marginRight: "20px" }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  orderCatalogMaster: state.orderCatalogSlice.order_catalog_masters,
  editOrderCatalog: state.orderCatalogSlice.edit_order_catalog,
  saveOrderCatalog: state.orderCatalogSlice.save_order_catalog,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(OrderCatalogForm));
