import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter,useLocation } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Grid, Typography, Avatar } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
import { CreateIcon, TrashIcon } from "../../assets";
import Color from "../../themes/default.json"
var aes256 = require("aes256");

function Availablestrengthtablevalue(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {}, [state]);

  const { data, index, onDeleteClick, onEditClick, shouldDisable } = props;

  const getStringWithSpace = (values) => {
    
    return values.join(" ");
  };

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"UYSha"}
            container={true}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            lg={"12"}
            md={"12"}
            sm={"12"}
            xl={"12"}
            xs={"12"}
            style={qdmstyles.uurn}
          >
            <Grid
              key={"0"}
              id={"8k4Lv"}
              container={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              lg={"3"}
              md={"3"}
              sm={"3"}
              xl={"3"}
              xs={"3"}
            >
              <Typography
                key={"0"}
                id={"c3m1q"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                style={{...qdmstyles.XXlZ,color:Color.palette.text.black}}
              >
                {`${getStringWithSpace([
                  data?.StrengthValue,
                  data?.StrengthUOM?.label ?? "",
                ])} / ${getStringWithSpace([
                  data?.StrengthVolume,
                  data?.StrengthVolumeUoM?.label ?? "",
                ])}`}
              </Typography>
            </Grid>
            <Grid
              key={"1"}
              id={"XTsif"}
              container={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              lg={"7"}
              md={"7"}
              sm={"7"}
              xl={"7"}
              xs={"7"}
            >
              <Typography
                key={"0"}
                id={"availableStrengthTablerow_allowedvaluesforders"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                aliasName={"availableStrengthTablerowallowedvaluesforders"}
                style={{...qdmstyles.vPLF,color:Color.palette.text.black}}
              >
                {data?.OrderUOMAllowed?.map(
                  (item) =>
                    `${getStringWithSpace([
                      item?.AllowedOrderDoseVal,
                      item?.AllowedOrderDoseUOM?.label ?? "",
                    ])} / ${getStringWithSpace([
                      item?.AllowedOrderDoseVolVal,
                      item?.AllowedOrderDoseVolUoM?.label ?? "",
                    ])}`
                )?.join(", ")}
              </Typography>
            </Grid>
            <Grid
              key={"2"}
              id={"S0IIq"}
              container={true}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              lg={"2"}
              md={"2"}
              sm={"2"}
              xl={"2"}
              xs={"2"}
              style={qdmstyles.nTjXV}
            >
              <ALLMCORE.IconButton
                size="small"
                style={{ minWidth: "30px", height: "30px" }}
                disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                onClick={onDeleteClick}
              >
                <TrashIcon />
              </ALLMCORE.IconButton>
              <ALLMCORE.IconButton
                size="small"
                disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                style={{ minWidth: "30px", height: "30px" }}
                onClick={onEditClick}
              >
                <CreateIcon />
              </ALLMCORE.IconButton>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Availablestrengthtablevalue));
