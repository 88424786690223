import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: 8,
    border: "1px solid #DEE5EC",
    borderRadius: 4,
    padding: "4px 8px",
  },
}));
