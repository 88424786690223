import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Box, Paper, Typography, Grid, TextField } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

import qdmstyles from "./styles.json";
import "./index.css";
var aes256 = require("aes256");

const useStyles = makeStyles((theme) => ({
  childtitle: {
    color: theme.palette.primary.light
  },
  location: {
    color: theme.palette.text.primary,
  },
  geolocation: {
    backgroundColor: theme.palette.background.table,
  },
  notchedOutline:{
    borderColor:theme.palette.border.textField
  },
}));

function GeoLocationPharmacy(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
    geo_location_pharmacy_nxmup: null,
    geo_location_pharmacy_g98qf: null,
    geo_location_pharmacy_chvlg: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const onchange_nxmup = (e, index) => {
    if (e?.target?.value?.length <= 30) {
      let updatestate = {};

      setState({
        ...state,
        ...updatestate,
        geo_location_pharmacy_nxmup: e?.target?.value,
      });
      let stateValue = props.statevalue;
      stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

      stateValue[index] = {
        ...stateValue[index],
        geo_location_pharmacy_nxmup: e?.target?.value,
      };
      props.setFun(stateValue);
    }
  };
  const onchange_g98qf = (e, index) => {
    if (e?.target?.value?.length <= 30) {
      let updatestate = {};

      setState({
        ...state,
        ...updatestate,
        geo_location_pharmacy_g98qf: e?.target?.value,
      });
      let stateValue = props.statevalue;
      stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

      stateValue[index] = {
        ...stateValue[index],
        geo_location_pharmacy_g98qf: e?.target?.value,
      };
      props.setFun(stateValue);
    }
  };
  const onchange_chvlg = (e, index) => {
    if (e?.target?.value?.length <= 30) {
      let updatestate = {};

      setState({
        ...state,
        ...updatestate,
        geo_location_pharmacy_chvlg: e?.target?.value,
      });
      let stateValue = props.statevalue;
      stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

      stateValue[index] = {
        ...stateValue[index],
        geo_location_pharmacy_chvlg: e?.target?.value,
      };
      props.setFun(stateValue);
    }
  };
  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  const { data, index } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Paper
            key={"0"}
            id={"AyIIU"}
            elevation={"{3}"}
            style={qdmstyles.wxC}
            onLoad={(e) => props.setFun(props?.statevalue ?? [{}])}
            className={classes.geolocation}
          >
            {(props?.statevalue ?? [{}])?.map((data, index) => {
              return (
                <React.Fragment>
                  <Typography
                    key={"0"}
                    id={"geo_location_header"}
                    align={"inherit"}
                    display={"initial"}
                    className={classes.location}
                    variant={"body1"}
                    children={t("pharmacy.addNewDrugCatalogMaster.geoLocation")}
                    aliasName={"geolocationheader"}
                    style={qdmstyles.zeVRh}
                  ></Typography>
                  <Grid
                    key={"1"}
                    id={"xNqnW"}
                    container={true}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    spacing={"3"}
                  >
                    <Grid
                      key={"0"}
                      id={"Z7g9Q"}
                      container={""}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      item={true}
                      lg={"4"}
                      md={"4"}
                      sm={"4"}
                      xl={"4"}
                      xs={"4"}
                    >
                      <Typography
                        key={"0"}
                        id={"longitude_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        className={classes.childtitle}
                        children={
                          <span>
                            Longitude
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </span>
                        }
                        aliasName={"longitudeheader"}
                        style={qdmstyles.rRFJw}
                      ></Typography>
                      <TextField
                        key={"1"}
                        value={
                          state?.geo_location_pharmacy_nxmup ||
                          data.geo_location_pharmacy_nxmup ||
                          null
                        }
                        InputProps={{ classes: {notchedOutline: classes.notchedOutline}}}
                        onChange={(e) => onchange_nxmup(e, index)}
                        id={"longitude_textfield"}
                        label={""}
                        inputProps={{
                          maxLength: 30
                        }}
                        placeholder={"Longitude"}
                        type={"text"}
                        variant={"outlined"}
                        size={"small"}
                        aliasName={"longitudetextfield"}
                        fullWidth={true}
                        margin={"dense"}
                      ></TextField>
                    </Grid>
                    <Grid
                      key={"1"}
                      id={"Z7g9Q"}
                      container={""}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      item={true}
                      lg={"4"}
                      md={"4"}
                      sm={"4"}
                      xl={"4"}
                      xs={"4"}
                    >
                      <Typography
                        key={"0"}
                        id={"latitude_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        className={classes.childtitle}
                        variant={"body1"}
                        children={"Latitude"}
                        aliasName={"latitudeheader"}
                        style={qdmstyles.bEbyB}
                      ></Typography>
                      <TextField
                        key={"1"}
                        value={
                          state?.geo_location_pharmacy_g98qf ||
                          data.geo_location_pharmacy_g98qf ||
                          null
                        }
                        InputProps={{ classes: {notchedOutline: classes.notchedOutline}}}
                        onChange={(e) => onchange_g98qf(e, index)}
                        id={"latitude_textfield"}
                        label={""}
                        placeholder={"Latitude"}
                        type={"text"}
                        inputProps={{
                          maxLength: 30
                        }}
                        variant={"outlined"}
                        size={"small"}
                        aliasName={"latitudetextfield"}
                        fullWidth={true}
                        margin={"dense"}
                      ></TextField>
                    </Grid>
                    <Grid
                      key={"2"}
                      id={"Z7g9Q"}
                      container={""}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      item={true}
                      lg={"4"}
                      sm={"4"}
                      md={"4"}
                      xl={"4"}
                      xs={"4"}
                    >
                      <Typography
                        key={"0"}
                        id={"altitude_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        className={classes.childtitle}
                        children={"Altitude"}
                        aliasName={"altitudeheader"}
                        style={qdmstyles.foE}
                      ></Typography>
                      <TextField
                        key={"1"}
                        value={
                          state?.geo_location_pharmacy_chvlg ||
                          data.geo_location_pharmacy_chvlg ||
                          null
                        }
                        InputProps={{ classes: {notchedOutline: classes.notchedOutline}}}
                        onChange={(e) => onchange_chvlg(e, index)}
                        id={"altitude_textfield"}
                        label={""}
                        placeholder={"Altitude"}
                        inputProps={{
                          maxLength: 30
                        }}
                        type={"text"}
                        variant={"outlined"}
                        size={"small"}
                        aliasName={"altitudetextfield"}
                        fullWidth={true}
                        margin={"dense"}
                      ></TextField>
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Paper>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(GeoLocationPharmacy));
