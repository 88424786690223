import React, { useEffect, useState } from "react";
//import { makeStyles } from "@material-ui/core/styles";
import { Card, Button } from "@material-ui/core";
import { CircularProgress, 
  //Drawer
 } from "@material-ui/core";
//import expandarrow from "../../../assets/icons8-expand-arrow.svg";
import { CommonTable } from "../../../components";
//import { Row, Col } from "qdm-component-library";
//import { AlertProps } from "../../../utils";
import { useDispatch, 
  //useSelector
 } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
//import moment from "moment";
//import Avatar from "@material-ui/core/Avatar";
import { Routes } from "../../../router/routes";
import { useHistory } from "react-router";


//const drawerWidth = "94%";
// const useStyles = makeStyles((theme) => ({
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//   },
//   drawerPaper: {
//     width: drawerWidth,
//   },
// }));

//  const persons =[{
//      name:"kathir",

//  }]

function Person(props) {
  //const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({
    open: false,
    edit_data: null,
  });
  const statusChange = () => { };

  const handleEdit = (data, row, index) => {

    history.push({
      pathname: Routes.addperson,
      state: { id: row._key }
    });
  };

  //integration by manikandan

  const dispatch = useDispatch();

  //const personList = useSelector((state) => state?.personSlice?.person_read?.data);
  // React.useLayoutEffect(() => {
  //   // dispatch(actions.ROLE_READ());
  //   //  dispatch(actions.PERSON_READ())
  // }, []);
  useEffect(() => {
    constructTableJson();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [tableState, setTableState] = useState({
    person_list: []
  });
  const [tableLoading, setTableLoading] = useState(true)
  const addNewPersonHandler = () => {
    
    setState({
      ...state,
      open: true,
    });
    history.push({
      pathname: Routes.addperson,
    });
  };
  const constructTableJson = async () => {
    let personList = await dispatch(actions.PERSON_READ())

    let personData = personList?.payload?.data?.map(_ => {
      let pht = null;
      if (_.photo && _.photo.length > 0) {
        pht = _.photo?.[0]?.fileid
      }
      return {
        Name: _?.name[0]?.text === "" ? _?.telecom[0]?.value : _?.name[0]?.text,
        DOB: _?.birthDay ?? "",
        Gender: _?.gender?.display ?? "",
        Email: _?.telecom[0]?.system === 'Email' ? _?.telecom[0]?.value : "",
        Mobile: _?.telecom[0]?.system === 'Phone' ? _?.telecom[0]?.value : "",
        _key: _?._key,
        pht: pht,
        active: _?.active ? 'Active' : 'InActive'
      }
    });
    setTableState({
      person_list: personData
    });
    setTableLoading(false)
  }
  return (
    <div style={{ borderRadius: "12px" }}>
      <div style={{ width: "100%" }}>
        <Card style={{ borderRadius: "12px" }}>
          <div
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 20px",
              }}
            >
              <div>
                <p
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  Person
                </p>
              </div>
              <div style={{ marginTop: "16px", float: "right" }}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => addNewPersonHandler()}
                >
                  +Add New
                </Button>
              </div>
            </div>
            <div style={{ padding: 20, height: "71vh", overflow: "auto" }}>
              {
                tableLoading ? <div>
                  <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", minHeight: "66vh" }}>
                    <CircularProgress />
                  </div>
                </div>
                  :
                  <CommonTable
                    Header={[
                      "S.No",
                      "Name",
                      "DOB",
                      "Gender",
                      "Email",
                      "Mobile",
                      "Status",
                      "Action",
                    ]}
                    dataList={

                      tableState?.person_list ?? []
                    }
                    tableData={[
                      { type: ["INCRIMENT"], name: "" },
                      { type: ["PROFILE"], name: "Name" },
                      { type: ["TEXT"], name: "DOB" },
                      { type: ["TEXT"], name: "Gender" },
                      { type: ["TEXT"], name: "Email" },
                      { type: ["TEXT"], name: "Mobile" },
                      { type: ["TEXT"], name: "active" },
                      { type: ["EDIT"], name: "action" },
                    ]}
                    handleCheckBox={statusChange}
                    handleEdit={handleEdit}
                  />
              }
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
export default Person;
