import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  Typography,
  IconButton,
  Grid,
  TextField,
  Paper,
} from "@material-ui/core";
import { Divider } from "qdm-component-library";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateRangeIcon from "@material-ui/icons/DateRange";
import moment from "moment";
import ViewTable from "./table";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 900,
    borderRadius: 14,
    "& .App1-MuiDialogTitle-root": {
      paddingTop: 16,
      paddingBottom: 16,
    },
    "& .App1-MuiSvgIcon-root": {
      fontSize: 20,
    },
    "& .card-title": {
      fontSize: "14px !important",
      fontWeight: 800,
      textTransform: "uppercase",
    },
    // height: 500
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  timeBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 22,
    marginLeft: 25,
    "& .body": {
      display: "flex",
    },
    "& .timeContent": {
      marginLeft: 12,
    },
    "& .App1-MuiSvgIcon-root": {
      fill: theme.palette.primary.light,
      fontSize: 24,
    },
  },
  timeLabel: {
    fontSize: 14,
    fontWeight: 600,
  },
  timeText: {
    fontSize: 14,
    color: theme.palette.text.primary,
    // color: "#848B91",
  },
  formLabel: {
    fontSize: 14,
    color: theme.palette.text.primary,
    // color: "#848B91",
  },
  dropdown: {
    background: `${theme.palette.background.table} !important`,
    "&:input": {
      color: theme.palette.text.primary,
    }
  },
  btns: {
    color: theme.palette.text.primary,
    background: `${theme.palette.background.table} !important`,
    fontSize: 12,
    borderRadius: "8px",
    marginRight: 6,
  },
}));

const CartClose = (props) => {

  const classes = useStyles();

  const { selectData } = props;

  return (
    <Dialog
      open={props?.open}
      onClose={props?.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      PaperProps={{ classes: { root: classes.root } }}
    >
      <DialogTitle id="alert-dialog-title">
        <div className={classes.dialogTitle}>
          <div>
            <Typography variant="body1" className="card-title">
              Cart Close
            </Typography>
          </div>
          <IconButton
            style={{ backgroundColor: "#E1E9EF", padding: 3 }}
            onClick={props?.onClose}
          >
            <CloseIcon size="small" />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider style={{ margin: 0 }} />
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <div>
                  <Typography variant="caption" className={classes.formLabel}>
                    Cart Name
                  </Typography>
                </div>
                <Autocomplete
                  id="combo-box-demo"
                  options={[]}
                  value={props.selectData?.cartName ?? {}}
                  size="small"
                  disabled
                  style={{ width: 200 }}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label="" variant="outlined" />
                  )}
                  PaperComponent={({ children }) => (
                    <Paper className={classes.dropdown}>{children}</Paper>
                  )}
                  className={classes.dropdown}
                />
              </Grid>
              <Grid item className={classes.timeBlock}>
                <div className="body">
                  <div>
                    <IconButton style={{ backgroundColor: "#EFFBF4" }}>
                      <DateRangeIcon />
                    </IconButton>
                  </div>
                  <div className="timeContent">
                    <div>
                      <Typography
                        variant="caption"
                        className={classes.timeText}
                      >
                        Close Cart date & time
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        variant="caption"
                        className={classes.timeLabel}
                      >
                        {moment().format('DD-MM-YYYY h:mm')}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item className={classes.timeBlock}>
                <div className="body">
                  <div>
                    <IconButton style={{ backgroundColor: "#EFFBF4" }}>
                      <DateRangeIcon />
                    </IconButton>
                  </div>
                  <div className="timeContent">
                    <div>
                      <Typography
                        variant="caption"
                        className={classes.timeText}
                      >
                        Close Cart Start date & End date
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        variant="caption"
                        className={classes.timeLabel}
                      >
                        {props.selectData?.date ?? ''}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <div>
              <Typography variant="h6" className="card-title">
                Cart Details
              </Typography>
            </div>
            <ViewTable selectData={selectData} cartReason={props.cartReason ?? []} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          onClick={props.onClose}
          color="primary"
          variant="contained"
          style={{
            padding: "7px 21px",
            borderRadius: "11px",
          }}
          className={classes.btns}
        >
          Close Cart
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CartClose;
