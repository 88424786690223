import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: ({ backgroundColor }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 6px",
    maxHeight: 21,
    borderTopLeftRadius: "4px !important",
    borderTopRightRadius: "4px !important",
    // background: theme.palette.primary.main,
    backgroundColor: backgroundColor
      ? `${backgroundColor} !important`
      : "#6A7888 !important",
  }),
}));

function Tag({ children, className, ...others }, ref) {
  const classes = useStyles(others);

  return (
    <div className={classNames(classes.root, className)} {...others} ref={ref}>
      {children}
    </div>
  );
}

export default React.forwardRef(Tag);
