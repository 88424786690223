import React from "react";
import {
  Div,
  Paper,
  //Select,
  TextInput,
  Upload,
  //H6,
  Col,
  Row,
  Text,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
import { getUtcTime, uploadImage, utcTOLocal } from "../../../utils";
import Delete from "../../../assets/icons8-trash.svg";
import { Filenameui } from "./commonUI";
import { DateTimePicker } from "../../common/smartForm/component";
import moment from "moment";
//import { SelectBox } from "../../common/smartForm/component";
import { SelectWithLabel } from "../../../components";
import { Typography } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

class PersonIdentification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Showfilter: this.props?.[this.props.saveto]?.names,
      limited: false,
      showdoc: true,
      rows: [{}],
      fileUpload: true,
    };
  }
  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorBorderStyle: {
      borderRadius: 8,
      border: "1px solid red",
    },
  };

  setData = (name, value, index, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    this.setState({ fileUpload: true });
  };
  setDataUpload = async (name, value, next, limit) => {
    let UploadedFiles = [];
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    UploadedFiles = await uploadImage([value]);
    this.props.handlePersonIdentifier("upload_document", UploadedFiles, next);
  };
  newRowAdd = () => {
    
    this.props.addPersonIdentifier()
  };

  getSize = (size) => {
    let fileSize = size / 1024 / 1024;
    if (fileSize < 1) {
      return `${parseInt(size / 1024) + 1} KB`;
    } else {
      return `${parseInt(fileSize)} MB`;
    }
  };
  handleDelete = (i) => {
    this.state.rows.splice(i, 1);
    this.setState({ ...this.state });
  };
  FlterOptions = (type) => {
    const { allMasters } = this.props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      var datatemp = {
        value: data[index]?.coding?.display,
      };
      lista.push(datatemp);
    }
    return lista;
  };
  handleLimit = () => {
    this.setState({
      limited: !this.state.limited,
    });
  };
  removedoc = () => {
    this.setState({
      showdoc: !this.state.showdoc,
    });
  };
  showfilter = (list, comp_value) => {
    if (list?.length > 0) {
      return list.filter(_ => _._id === comp_value)[0]
    }
    return {};
  }
  render() {
    // const { identification } = this.props[this.props.saveto];
    const { labelStyle, borderStyle, errorBorderStyle } = this.styles;
    // const { showdoc } = this.state;
    // const mapped = [];

    // const mapped = identification?.map((v, i) => {
    //
    //   if (i === 0) {
    //     v?.["doc"] = {
    //       fileName: "Employee Id.pdf 12MB",
    //     };
    //   }
    //   return v;
    // });
    return (
      <Div className="pi-root">
        <Paper className="pi-paper">
          {/* {JSON.stringify(identification)} */}
          <Div>
            <Typography
              variant="body2"
              style={{ fontWeight: 700, fontSize: "12px" }}
            >
              {this.props.saveto === "nextOfKin"
                ? "NEXT OF KIN IDENTIFICATION"
                : "PERSON IDENTIFICATION"}
            </Typography>
            <Text onClick={() => this.props.addPersonIdentifier()} className="pi-addbtn">
              + Add New
            </Text>
          </Div>
          {this.props?.personIdentification?.length > 0 && this.props?.personIdentification?.slice(0, this.state.limited ? this?.props?.personIdentification?.length : 1)
            .map((val, i) => (
              <Row>
                <Col
                  md={2}
                  lg={2}
                  sm={6}
                  xs={12}
                  className="no-padding-left"
                  inLineStyles={{ padding: 10, color: "#101010" }}
                >
                  {/* <Select
                    value={val?.id_type?.label ?? null}
                    onChangeValue={(e) => this.setData("id_type", e, i)}
                    label="ID Type"
                    placeholder="select..."
                    showArrow
                    labelStyle={labelStyle}
                    inLineStyles={
                      val?.error?.id_type ? errorBorderStyle : borderStyle
                    }
                    hoverColor="#0071F2"
                    getOptionLabel="label"
                    options={this?.props?.allMasters?.["id_type"]}
                    isRequired={true}
                  /> */}
                  {/* <Typography
                    style={{
                      fontSize: "12px",

                      Fontfamily: "pc_regular",
                      color: "#6F6F6F",
                    }}
                    variant="body1"
                  >
                    ID Type<span style={{ color: "red" }}>*</span>
                  </Typography> */}
                  {/* <SelectBox
                    list={this?.props?.allMasters?.["id_type"] ?? []}
                    placeholder={"select..."}
                    disableClearable
                    value={val?.id_type}
                    onchange={(e, value) => this.setData("id_type", value, i)}
                  /> */}
                  {/* {JSON.stringify(val?.id_type)} */}

                  <SelectWithLabel
                    options={this?.props?.idTypeMaster ?? []}
                    label={"ID Type"}
                    required={true}
                    disableClearable
                    value={this.showfilter(this?.props?.idTypeMaster, val?.id_type)}
                    onChange={(value) => this.props.handlePersonIdentifier("id_type", value?._id ?? "", i)}
                    placeholder={"select..."}
                    // onChange={(data)=>props?.onChangeState('class',data)}
                    error={val?.error?.id_type ?? false}
                  />
                </Col>
                <Col
                  md={2}
                  lg={2}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <TextInput
                    // helperText={
                    //   val?.error && val?.error["id_number"] ? "Is required" : ""
                    // }
                    error={val?.error && val?.error["id_number"] ? true : false}
                    //helperText={val.error.id_number ? val.errorMsg.id_number : ''}
                    value={val.id_number ? val.id_number : ""}
                    onChange={(e) =>
                      this.props.handlePersonIdentifier("id_number", e.target.value, i)
                    }
                    label="ID Number"
                    // type="number"
                    isRequired={true} 
                    // isRequired={val?.id_type ? true : false}
                    placeholder="Id number"
                    labelStyle={labelStyle}
                    // style={borderStyle}
                    style={
                      val?.error?.id_number ? errorBorderStyle : borderStyle
                    }
                    hoverColor="#0071F2"
                  />
                </Col>
                <Col
                  md={2}
                  lg={2}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <DateTimePicker
                    required={true}
                    label={"Expiry Date"}
                    dateFormat={"dd/MM/yyyy"}
                    value={val?.expiry_date ? new Date(utcTOLocal(val?.expiry_date,'MM/DD/YYYY')) : null}
                    helperText={val.error.expiry_date ? val.errorMsg.expiry_date : ''}

                    onChange={(value,v) => {
                        
                      this.props.handlePersonIdentifier(
                        "expiry_date",
                        getUtcTime(value,v),
                        i
                      )
                    }
                    }
                    error={val?.error?.expiry_date ?? false}
                    // minDate={
                    //   val?.expiry_date ? moment(val?.expiry_date) : new Date()
                    // }
                    minDate={
                      val?.expiry_date
                        ? moment(val?.expiry_date).diff(
                          moment(new Date()),
                          "days"
                        ) >= 0
                          ? new Date()
                          : moment(val?.expiry_date)
                        : new Date()
                    }
                  />
                </Col>

                {/* {this.props.saveto !== "nextOfKin" && <Col md={2} lg={2} sm={6} xs={12} inLineStyles={{ padding: 10 }}>
                        <Select value={val?.id_source?.value ?? null}
                            helperText={(val?.error && val?.error['id_source']) ? 'Is required' : ''}
                            error={(val?.error && val?.error['id_source']) ? true : false}
                            onChangeValue={(e) => this.setData("id_source", e, i)}
                            options={this.FlterOptions('Relationship')}
                            showArrow
                            options={optiondata}
                            isRequired={true}
                            //  isRequired={val?.id_type ? true : false}
                            label="ID Source" placeholder="select..." labelStyle={labelStyle}
                            inLineStyles={borderStyle} hoverColor="#0071F2" />
                    </Col>} */}
                <Col
                  md={4}
                  lg={4}
                  sm={12}
                  xs={12}
                  className="pi-upload-root"
                  inLineStyles={{ padding: 10 }}
                >
                  {val?.upload_document?.length <= 0 ? (
                    <Div style={{ display: "flex", marginTop: "18px" }}>
                      <Upload
                        label="Upload Document"
                        className="mt"
                        handleChange={(e, value) =>
                          this.setDataUpload("doc", value, i)
                        }
                        labelColor="#0071F2"
                        iconColor="#0071F2"
                        iconSize={14}
                        imgUrl="images/uploadBlue.svg"
                        labelStyle={{
                          fontSize: 14,
                          fontFamily: "pc_medium !important",
                        }}
                      />
                      {/* <Div
                        style={{
                          width: 15,
                          cursor: "pointer",
                        }}
                      >
                        <Image
                          src="images/icons8-trash.svg"
                          alt="delete"
                          onClick={() => this.handleDelete(i)}
                        />
                      </Div> */}
                    </Div>
                  ) : (
                    <div>
                      {
                        <>
                          <div
                            style={{
                              padding: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              gap: "12px",
                            }}
                          >
                            <Filenameui
                              fileName={val?.upload_document[0]?.filename ?? " "}
                              delete={() => this.props.handlePersonIdentifier("upload_document", null, i)}
                            />

                            <VisibilityIcon htmlColor="#B6B6B6" />
                          </div>

                        </>
                      }
                      {/* <div
                        style={{
                          width: 15,
                          cursor: "pointer",
                        }}
                      >
                        <Image
                          src="images/icons8-trash.svg"
                          alt="delete"
                          onClick={() => this.handleDelete(i)}
                        />
                      </div> */}
                    </div>
                  )}
                  {this.props?.personIdentification?.length > 0 && i !== 0 && (
                    <>
                      <div style={{ marginTop: "30px", marginLeft: "20px" }}>
                        <img
                          src={Delete}
                          alt="delete"
                          onClick={() => this.props.handleIdentifierDelete(i)}
                        />
                      </div>
                    </>
                  )}

                  {this.props?.personIdentification?.length > 1 && i === 0 && (
                    <Text
                      className="pc_medium"
                      style={{
                        color: "#F58B00",
                        marginLeft: 15,
                        cursor: "pointer",
                        fontSize: "14px",
                        marginTop: 20,
                      }}
                      onClick={() => this.handleLimit()}
                    >
                      {this.state.limited
                        ? "Show Less"
                        : `Show +${this.props?.personIdentification?.length}`}{" "}
                    </Text>
                  )}
                </Col>
              </Row>
            ))}
        </Paper>
      </Div>
    );
  }
}

export default withAllContexts(PersonIdentification);
