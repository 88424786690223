import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Box,
  Divider,
  Checkbox,
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import qdmstyles from "./styles.json";
import "./index.css";
var aes256 = require("aes256");

function Frequencydefinitionnew(props) {
  const dispatch = useDispatch();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {}, [state]);

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"bHcZj"}
            container={true}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            lg={"12"}
            aliasName={"user defined"}
            style={qdmstyles.MxN}
          >
            <Paper
              key={"0"}
              id={"FImGi"}
              elevation={"1"}
              variant={"elevation"}
              style={qdmstyles.ISzME}
            >
              <Typography
                key={"0"}
                id={"3zKDA"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={"FREQUENCY DEFINITION"}
                style={qdmstyles.Kfujq}
              ></Typography>
              <Grid
                key={"1"}
                id={"2WGYO"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                spacing={"2"}
                style={qdmstyles.nNebp}
              >
                <Grid
                  key={"0"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Code"}
                  ></Typography>
                  <TextField
                    key={"1"}
                    id={"VWbB8"}
                    label={""}
                    placeholder={"TAPER"}
                    type={"text"}
                    variant={"outlined"}
                    margin={"dense"}
                    fullWidth={true}
                    style={qdmstyles.hBjE}
                  ></TextField>
                </Grid>
                <Grid
                  key={"1"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"4"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Name"}
                  ></Typography>
                  <TextField
                    key={"1"}
                    id={"2TiFD"}
                    label={""}
                    placeholder={"Tapering"}
                    type={"text"}
                    fullWidth={true}
                    variant={"outlined"}
                    margin={"dense"}
                    style={qdmstyles.qHSgO}
                  ></TextField>
                </Grid>
                <Grid
                  key={"2"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Type"}
                  ></Typography>
                  <Autocomplete
                    id={"hIHKa"}
                    style={qdmstyles?.hIHKa}
                    value={state?.frequencydefinitionnew_hihka ?? null}
                    onLoad={() =>
                      setState({
                        frequencydefinitionnew_hihka:
                          state?.frequencydefinitionnew_hihka ?? null,
                      })
                    }
                    getOptionLabel={(option) => option?.["label"]}
                    options={[]}
                    onChange={(e, newvalue) =>
                      setState({ frequencydefinitionnew_hihka: newvalue })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"label"}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                key={"2"}
                id={"2WGYO"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                spacing={"2"}
                style={qdmstyles.ZcA}
              >
                <Grid
                  key={"0"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                  md={"2"}
                  sm={"3"}
                  style={qdmstyles.lSVl}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Total No Of Days"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.Rvgmy}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.tZqZ}
                    ></TextField>
                  </Box>
                </Grid>
                <Grid
                  key={"1"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                  md={"2"}
                  sm={"3"}
                  style={qdmstyles.VMHZI}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Dose Per Day"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.WXXK}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.PkaY}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Typography
                      key={"2"}
                      id={"h9zkG"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Times"}
                      style={qdmstyles.UKQ}
                    ></Typography>
                  </Box>
                </Grid>
                <Grid
                  key={"2"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                  md={"2"}
                  sm={"3"}
                  style={qdmstyles.JQYy}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Interval Days"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.zIJwX}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.NlQl}
                    ></TextField>
                  </Box>
                </Grid>
                <Grid
                  key={"3"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                  md={"2"}
                  sm={"3"}
                  style={qdmstyles.HuOT}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Dose Per Day"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.opfL}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.LPJP}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Typography
                      key={"2"}
                      id={"h9zkG"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Times"}
                      style={qdmstyles.SoDF}
                    ></Typography>
                  </Box>
                </Grid>
                <Grid
                  key={"4"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                  md={"2"}
                  sm={"3"}
                  style={qdmstyles.SyAu}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Interval Days"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.rfgKa}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.moh}
                    ></TextField>
                  </Box>
                </Grid>
                <Grid
                  key={"5"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                  md={"2"}
                  sm={"3"}
                  style={qdmstyles.BkkV}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Dispense Grace Period"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.EXgX}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.hp}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Typography
                      key={"2"}
                      id={"h9zkG"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Hrs"}
                      style={qdmstyles.AiFi}
                    ></Typography>
                  </Box>
                </Grid>
                <Grid
                  key={"6"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                  md={"3"}
                  sm={"4"}
                  style={qdmstyles.XLmBX}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Fixed Duration"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.rBec}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.anPZ}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Autocomplete
                      id={"6djM0"}
                      style={qdmstyles?.djM}
                      value={state?.frequencydefinitionnew_6djm0 ?? null}
                      onLoad={() =>
                        setState({
                          frequencydefinitionnew_6djm0:
                            state?.frequencydefinitionnew_6djm0 ?? null,
                        })
                      }
                      getOptionLabel={(option) => option?.["label"]}
                      options={[]}
                      onChange={(e, newvalue) =>
                        setState({ frequencydefinitionnew_6djm0: newvalue })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"label"}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid
                  key={"7"}
                  id={"FTOds"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"stretch"}
                  lg={"2"}
                  item={true}
                  alignContent={"stretch"}
                  md={"2"}
                  sm={"2"}
                  style={qdmstyles.PANnv}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Is Base Frequency"}
                  ></Typography>
                  <Checkbox
                    key={"1"}
                    id={"Wr5U5"}
                    checked={true}
                    color={"default"}
                    size={"small"}
                  ></Checkbox>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Frequencydefinitionnew));
