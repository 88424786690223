/* eslint-disable no-unused-expressions */
import React from "react";
import {
  Paper,
  Grid,
  Typography,
  makeStyles,
  FormControlLabel,
  Checkbox,
  //TextField,
} from "@material-ui/core";
import {
  TextFieldLabel,
  SelectWithLabel,
  TextFieldWithSelect,
  TimePicker,
} from "../../../../../components";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";

const useStyles = makeStyles(() => ({
  root: {
    "& .App1-MuiPaper-root": {
      padding: 17,
      boxShadow: "0px 10px 25px #0000000A",
      borderRadius: 8,
    },
    "& .App1-MuiInputBase-root input": {
      padding: 10,
    },
  },
  title: {
    color: "#101010",
    fontSize: 12,
    fontFamily: "pc_semibold",
    paddingBottom: 12,
  },
  label: {
    color: "#6F6F6F",
    fontSize: 12,
    fontFamily: "pc_regular",
    marginBottom: 8,
  },
  checkBox: {
    padding: 0,
    margin: "0px 9px",
  },
}));

export const FrequencyDescription = (props) => {
  const classes = useStyles(props);
  const { parent_id,error } = props;
  const dispatch = useDispatch();
  const resourceFREQUENCY = useSelector((state) => state.drugFrequencySlice);

  React.useLayoutEffect(() => {
    const getdata = async () => {
      await dispatch(actions.DRUG_SCHEDULEBY_READ());
      await dispatch(actions.DRUG_FREQUENCY_UOM_READ());
      await dispatch(actions.DRUG_WEEKDAYS_READ());
      await dispatch(actions.DRUG_FIXEDDURATIONTYPE_READ());
      await dispatch(actions.DRUG_FREQUENCYTYPE_READ());
    };
    getdata();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id={`${parent_id}-parent-div`}
      className={classes.root}>
      <Paper
        id={`${parent_id}-parent-paper`}
      >
        <Typography
          id={`${parent_id}-title-typography`}
          variant="body1" className={classes.title}>
          FREQUENCY DESCRIPTION
        </Typography>
        <Grid
          id={`${parent_id}-parent-grid`}
          container spacing={2}>
          <Grid
            id={`${parent_id}-scheduleBy-grid`}
            item xs={12} sm={12} md={3}>
            <SelectWithLabel
              parent_id={"scheduleBy"}
              options={
                resourceFREQUENCY?.drug_schedule_list?.data
                  ? resourceFREQUENCY?.drug_schedule_list?.data
                  : null
              }
              label="Schedule By"
              value={props?.data?.scheduleBy}
              // required={true}
              onChange={(data) => props?.onChangeState("scheduleBy", data)}
              error={error?.scheduleBy}
            />
          </Grid>
          <Grid
            id={`${parent_id}-doseFrequencyValue-grid`}
            item xs={12} sm={12} md={3}>
            <TextFieldWithSelect
              parent_id={"doseFrequencyValue"}
              options={
                resourceFREQUENCY?.drug_frequncy_uom_list?.data
                  ? resourceFREQUENCY?.drug_frequncy_uom_list?.data 
                  : null
              }
              label="Dose Frequency Value"
              value={props?.data?.doseFrequencyValue}
              onChange={(data) =>
                props?.onChangeState("doseFrequencyValue", data)
              }
              equalWidth
              type={"number"}
              // required={true}
              error={error?.doseFrequencyValue}
            // error={true}
            />
          </Grid>
          <Grid
            id={`${parent_id}-doseFrequencyRateValue-grid`}
            item xs={12} sm={12} md={3}>
            <TextFieldWithSelect
              parent_id={"doseFrequencyRateValue"}
              options={
                resourceFREQUENCY?.drug_frequncy_uom_list?.data
                  ? resourceFREQUENCY?.drug_frequncy_uom_list?.data
                  : null
              }
              label="Dose Frequency Rate Value"
              value={props?.data?.doseFrequencyRateValue}
              onChange={(data) =>
                props?.onChangeState("doseFrequencyRateValue", data)
              }
              type={"number"}
              equalWidth
              // required={true}
              error={error?.doseFrequencyRateValue}
            // error={true}
            />
          </Grid>
          <Grid
            id={`${parent_id}-startDay-grid`}
            item xs={12} sm={12} md={3}>
            <SelectWithLabel
              parent_id={"startDay"}
              options={
                resourceFREQUENCY?.drug_weekdays_list?.data
                  ? resourceFREQUENCY?.drug_weekdays_list?.data
                  : null
              }
              label="Start Day"
              value={props?.data?.startDay}
              onChange={(data) => props?.onChangeState("startDay", data)}
              error={error?.startDay}
              // required={true}
            />
          </Grid>
          <Grid
            id={`${parent_id}-startTime-grid`}
            item xs={12} sm={12} md={3}>
            {/* <TextField
              label="Start Time"
              type="time"
              value="18:00"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              timeformat="24h"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              dateFormat="HH:mm"
              onChange={(data) => props?.onChangeState("startTime", data)}
            /> */}
            <TimePicker
              parent_id={"startTime"}
              label="Start Time"
              value={props?.data?.startTime}
              onChange={(data) => {
                
                props?.onChangeState("startTime", data);
              }}
              hrsLabel
              timeFormat={"h:mm aa"}
              error={error?.startTime}
              // required={true}
            // error={}
            />
          </Grid>
          <Grid
            id={`${parent_id}-gracePeriod-grid`}
            item xs={12} sm={12} md={3}>
            <TextFieldLabel
              parent_id={"gracePeriod"}
              label="Grace Period"
              value={props?.data?.gracePeriod}
              onChange={(data) => props?.onChangeState("gracePeriod", data)}
              type={"number"}
              // required={true}
               error={error?.gracePeriod}
            // error={}
            />
          </Grid>
          <Grid
            id={`${parent_id}-fixedDuration-grid`}
            item xs={12} sm={12} md={3}>
            <TextFieldLabel
              parent_id={"fixedDuration"}
              label="Fixed Duration"
              value={props?.data?.fixedDuration}
              onChange={(data) => props?.onChangeState("fixedDuration", data)}
              type={"number"}
              // required={true}
               error={error?.fixedDuration}
            // error={}
            />
          </Grid>
          <Grid
            id={`${parent_id}-fixedDurationType-grid`}
            item xs={12} sm={12} md={3}>
            <SelectWithLabel
              parent_id={"fixedDurationType"}
              options={
                resourceFREQUENCY?.drug_fixeddurationType_list?.data
                  ? resourceFREQUENCY?.drug_fixeddurationType_list?.data
                  : null
              }
              label="Fixed Duration Type"
              value={props?.data?.fixedDurationType}
              onChange={(data) =>
                props?.onChangeState("fixedDurationType", data)
              }
               error={error?.fixedDurationType}
              // required={true}
            />
          </Grid>
          <Grid
            id={`${parent_id}-frequencyPattern-grid`}
            item xs={12} sm={12} md={3}>
            <TextFieldLabel
              parent_id={"frequencyPattern"}
              label="Frequency Pattern"
              value={props?.data?.frequencyPattern}
              onChange={(data) =>
                props?.onChangeState("frequencyPattern", data)
              }
              // required={true}
               error={error?.frequencyPattern}
            // error={}
            />
          </Grid>
          <Grid
            id={`${parent_id}-frequencyType-grid`}
            item xs={12} sm={12} md={3}>
            <SelectWithLabel
              parent_id={"frequencyType"}
              options={
                resourceFREQUENCY?.drug_frequnecyType_list?.data
                  ? resourceFREQUENCY?.drug_frequnecyType_list?.data
                  : null
              }
              label="Frequency Type"
              value={props?.data?.frequencyType}
              onChange={(data) => props?.onChangeState("frequencyType", data)}
              // required={true}
               error={error?.frequencyType}
            />
          </Grid>
          <Grid
            id={`${parent_id}-frequencyType-grid`}
            item xs={12} sm={12} md={3}>
            <TextFieldLabel
              parent_id={"dosageDirection"}
              label="Dosage Direction"
              value={props?.data?.dosageDirection}
              onChange={(data) => props?.onChangeState("dosageDirection", data)}
              // required={true}
               error={error?.dosageDirection}
            // error={}
            />
          </Grid>
          <Grid
            id={`${parent_id}-firstDone-grid`}
            item xs={12} sm={12} md={1}>
            <Typography
              id={`${parent_id}-firstDone-typography`}
              className={classes.label}>{"First Dose"}</Typography>
            <FormControlLabel
              id={`${parent_id}-firstDone-FormControlLabel`}
              control={
                <Checkbox
                  id={`${parent_id}-firstDone-Checkbox`}
                  checked={props?.data?.firstDone ?? false}
                  onChange={(e) =>
                    props?.onChangeState("firstDone", e.target.checked)
                  }
                  className={classes.checkBox}
                />
              }
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

FrequencyDescription.propTypes = {
  data: PropTypes.object,
  onChangeState: PropTypes.func,
};

FrequencyDescription.defaultProps = {
  data: {},
  onChangeState: null,
};
