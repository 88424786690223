import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Box, Paper, Typography, Grid, TextField } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
import makeStyles from "@material-ui/core/styles/makeStyles";

var aes256 = require("aes256");


const useStyles = makeStyles((theme) => ({
  editchildtitle: {
    color: theme.palette.primary.light
  },
  editlocation: {
    color: theme.palette.text.primary,
  },
  geolocation: {
    backgroundColor: theme.palette.background.table,
  },
  notchedOutline:{
    borderColor:theme.palette.border.textField
  },
}));


function EditGeoLocationPharmacy(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
    edit_geo_location_pharmacy_ufkfi: null,
    edit_geo_location_pharmacy_bdq8z: null,
    edit_geo_location_pharmacy_yoyzf: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const onchange_ufkfi = (e, index) => {
    let updatestate = {};

    setState({
      ...state,
      ...updatestate,
      edit_geo_location_pharmacy_ufkfi: e?.target?.value,
    });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = {
      ...stateValue[index],
      edit_geo_location_pharmacy_ufkfi: e?.target?.value,
    };
    props.setFun(stateValue);
  };
  const onchange_bdq8z = (e, index) => {
    let updatestate = {};

    setState({
      ...state,
      ...updatestate,
      edit_geo_location_pharmacy_bdq8z: e?.target?.value,
    });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = {
      ...stateValue[index],
      edit_geo_location_pharmacy_bdq8z: e?.target?.value,
    };
    props.setFun(stateValue);
  };
  const onchange_yoyzf = (e, index) => {
    let updatestate = {};

    setState({
      ...state,
      ...updatestate,
      edit_geo_location_pharmacy_yoyzf: e?.target?.value,
    });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = {
      ...stateValue[index],
      edit_geo_location_pharmacy_yoyzf: e?.target?.value,
    };
    props.setFun(stateValue);
  };
  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  const { data, index } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Paper
            key={"0"}
            id={"AyIIU"}
            elevation={"{3}"}
            style={qdmstyles.ELCe}
            onLoad={(e) => props.setFun(props?.statevalue ?? [{}])}
            className={classes.geolocation}
          >
            {(props?.statevalue?.length > 0 ? props?.statevalue : [{}])?.map(
              (data, index) => {
                return (
                  <React.Fragment>
                    <Typography
                      key={"0"}
                      id={"geo_location_header"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      className={classes.editlocation}
                      variant={"body1"}
                      children={t(
                        "pharmacy.editNewDrugCatalogMaster.geoLocation"
                      )}
                      aliasName={"geolocationheader"}
                      style={qdmstyles.iljVP}
                    ></Typography>
                    <Grid
                      key={"1"}
                      id={"xNqnW"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      spacing={"3"}
                    >
                      <Grid
                        key={"0"}
                        id={"Z7g9Q"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"4"}
                        md={"4"}
                        sm={"4"}
                        xl={"4"}
                        xs={"4"}
                      >
                        <Typography
                          key={"0"}
                          id={"longitude_header"}
                          align={"inherit"}
                          className={classes.editchildtitle}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={
                            <span>
                              Longitude
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </span>
                          }
                          aliasName={"longitudeheader"}
                          style={qdmstyles.IOtD}
                        ></Typography>
                        <TextField
                          key={"1"}
                          onChange={(e) => onchange_ufkfi(e, index)}
                          value={
                            data.edit_geo_location_pharmacy_ufkfi ??
                            data?.longitude
                          }
                          InputProps={{ classes: {notchedOutline: classes.notchedOutline}}}
                          id={"longitude_textfield"}
                          label={""}
                          placeholder={"Longitude"}
                          type={"text"}
                          inputProps={{
                            maxLength: 30
                          }}
                          disabled={location.state?.isdisabled === "inactive" ? true : false}
                          variant={"outlined"}
                          size={"small"}
                          aliasName={"longitudetextfield"}
                          fullWidth={true}
                          margin={"dense"}
                        ></TextField>
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"Z7g9Q"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"4"}
                        md={"4"}
                        sm={"4"}
                        xl={"4"}
                        xs={"4"}
                      >
                        <Typography
                          key={"0"}
                          id={"latitude_header"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          className={classes.editchildtitle}
                          variant={"body1"}
                          children={"Latitude"}
                          aliasName={"latitudeheader"}
                          style={qdmstyles.fFUeC}
                        ></Typography>
                        <TextField
                          key={"1"}
                          onChange={(e) => onchange_bdq8z(e, index)}
                          value={
                            data.edit_geo_location_pharmacy_bdq8z ??
                            data?.latitude
                          }
                          id={"latitude_textfield"}
                          label={""}
                          inputProps={{
                            maxLength: 30
                          }}
                          InputProps={{ classes: {notchedOutline: classes.notchedOutline}}}
                          disabled={location.state?.isdisabled === "inactive" ? true : false}
                          placeholder={"Latitude"}
                          type={"text"}
                          variant={"outlined"}
                          size={"small"}
                          aliasName={"latitudetextfield"}
                          fullWidth={true}
                          margin={"dense"}
                        ></TextField>
                      </Grid>
                      <Grid
                        key={"2"}
                        id={"Z7g9Q"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"4"}
                        sm={"4"}
                        md={"4"}
                        xl={"4"}
                        xs={"4"}
                      >
                        <Typography
                          key={"0"}
                          id={"altitude_header"}
                          align={"inherit"}
                          color={"initial"}
                          className={classes.editchildtitle}
                          display={"initial"}
                          variant={"body1"}
                          children={"Altitude"}
                          aliasName={"altitudeheader"}
                          style={qdmstyles.hTaM}
                        ></Typography>
                        <TextField
                          key={"1"}
                          onChange={(e) => onchange_yoyzf(e, index)}
                          value={
                            data.edit_geo_location_pharmacy_yoyzf ??
                            data?.altitude
                          }
                          id={"altitude_textfield"}
                          label={""}
                          placeholder={"Altitude"}
                          inputProps={{
                            maxLength: 30
                          }}
                          InputProps={{ classes: {notchedOutline: classes.notchedOutline}}}
                          disabled={location.state?.isdisabled === "inactive" ? true : false}
                          type={"text"}
                          variant={"outlined"}
                          size={"small"}
                          aliasName={"altitudetextfield"}
                          fullWidth={true}
                          margin={"dense"}
                        ></TextField>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                );
              }
            )}
          </Paper>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(EditGeoLocationPharmacy));
