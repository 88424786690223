import React from "react";
import { VerticalList, CommonTable } from "../../../../components";
import { OrderCategory } from "./orderCategory";
import { OrderNature } from "./orderNature";
import { OrderMode } from "./orderMode";
import { OrderCatalog } from "./orderCatalog";
import { OrderType } from "./orderType";
import { PerformingLocation } from "./performingLocation";
import { AlertRestrictions } from "./alertsRestrictions";

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openIndex: null,
      isedit: false
    };
    this.handleAddBtn = this.handleAddBtn.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.changeState = this.changeState.bind(this);
  }
  handleAddBtn = (index) => {
    let { open } = this.state;
    this.setState({
      open: !open,
      openIndex: index,
      isedit: false
    });
  };
  changeState = (name, value) => {
    this.setState({ [name]: value });
  };
  changeTab = () => {
    this.setState({ open: false });
  };
  iseditfuncy = () => {

    this.setState({ isedit: true });
  };
  render() {
    const { open, openIndex, isedit } = this.state;
    return (
      <React.Fragment>
        <VerticalList
          parent_id={"orders"}
          isLeftList={true}
          addFunc={this.handleAddBtn}
          changeTab={this.changeTab}
          TabList={[
            {
              name: "Order Category",
              component: (
                <OrderCategory
                  parent_id={"OrderCategory"}
                  changeState={this.changeState}
                  open={open}
                  openIndex={openIndex}
                />
              ),
            },
            {
              name: "Order Nature",
              component: (
                <OrderNature
                  parent_id={"order"}
                  changeState={this.changeState}
                  open={open}
                  openIndex={openIndex}
                />
              ),
            },
            {
              name: "Order Mode",
              component: (
                <OrderMode
                  parent_id={"order"}
                  changeState={this.changeState}
                  open={open}
                  openIndex={openIndex}
                />
              ),
              //  <DrugCategory open={open} changeState={this.changeState} openIndex={openIndex} />
            },
            {
              name: "Order Type",
              component: <OrderType
                parent_id={"order"}
                changeState={this.changeState} open={open} openIndex={openIndex} />
            },
            {
              name: "Order Catalog",
              component: (
                <OrderCatalog
                  parent_id={"order"}
                  changeState={this.changeState}
                  closeFrom={this.handleAddBtn}
                  open={open}
                  openIndex={openIndex}
                />
              ),
            },
            {
              name: "Performing Location",
              component: (
                <PerformingLocation
                  parent_id={"order"}
                  changeState={this.changeState}
                  closeFrom={this.handleAddBtn}
                  open={open}
                  openIndex={openIndex}
                />
              ),
            },
            {
              name: "Alert, Restrictions, Approvals",
              component: (
                <AlertRestrictions
                  parent_id={"order"}
                  changeState={this.changeState}
                  closeFrom={this.handleAddBtn}
                  open={open}
                  openIndex={openIndex}
                  isedit={isedit}
                  iseditfuncy={this.iseditfuncy}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

export default Orders;
