import { Typography, InputAdornment } from "@material-ui/core";
import React, { useEffect } from "react";
import { StyledPaper, CustomButton, StyledTable } from "../../../components";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { SearchInputBox } from "./searchInputBox";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../router/routes";
import { AlertProps } from "../../../utils";
import { withAllContexts } from "../../../HOCs";
import Color from "../../../themes/default.json";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.common,
    padding: 24,
    height: "100%",
  },
  titleRow: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    marginBottom: 20,
  },
  title: {
    color: theme.palette.primary.light,
    fontSize: 16,
    fontWeight: "600",
  },
  container: {
    padding: "24px 20px",
  },
  flexAuto: {
    flex: 1,
  },
  paper:{
    backgroundColor: theme.palette.background.table,
  }
}));

const columns = [
  {
    label: "S.No",
    id: "sno",
    type: "serial",
  },
  {
    label: "Cart name",
    id: "cartName",
  },
  {
    label: "Sub store",
    id: "subStore",
  },
  {
    label: "Week days",
    id: "Timings",
  },
  {
    label: "Ward",
    id: "Ward",
  },
  {
    label: "Status",
    id: "status",
    type: "switch",
  },
  {
    label: "Action",
    id: "action",
    type: "action",
  },
];

function createData(cartName, subStore, Timings, Ward, status, action) {
  return { cartName, subStore, Timings, Ward, status, action };
}

const rows = [
  createData(
    "Cart 1 must",
    "Sub Store 1",
    "Monday, Tuesday, Wednesday",
    "Ward 1",
    "Active",
    "Edit"
  ),
  createData(
    "Cart 2",
    "Sub Store 2",
    "Monday, Tuesday, Wednesday",
    "Ward 2",
    "Active",
    "Edit"
  ),
  createData(
    "Cart 2",
    "Sub Store 2",
    "Monday, Tuesday, Wednesday",
    "Ward 2",
    "Active",
    "Edit"
  ),
  createData(
    "Cart 2",
    "Sub Store 2",
    "Monday, Tuesday, Wednesday",
    "Ward 2",
    "Active",
    "Edit"
  ),
  createData(
    "Cart 2",
    "Sub Store 2",
    "Monday, Tuesday, Wednesday",
    "Ward 2",
    "Active",
    "Edit"
  ),
];

const { CART_DEFINITION_MASTER_FULL_READ, CART_DEFINITION_STATUS_UPDATE, CART_DEFINITION_MASTER_FILTER } =
  actions;

function CartDefinitionListing(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  // Store States
  const data = useSelector(
    (state) => state?.cartDefinitionMasterSlice?.cartDefinitionMasterFullread
  )?.data;

  const dataFilter = useSelector(
    (state) => state?.cartDefinitionMasterSlice?.cartDefinitionMasterFilter
  )?.data;

  // Component States

  // Component Handlers
  const loadData = () => {
    Promise.resolve(dispatch(CART_DEFINITION_MASTER_FULL_READ()))
      .then((res) => { })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleEditClick = (_id, _key) => {
    history.push(Routes.cartDefinitionUpsert, { _id, _key });
  };

  const handleStatusChange = (_key, cartstatus) => {
    Promise.resolve(
      dispatch(
        CART_DEFINITION_STATUS_UPDATE({
          body: { _key, cartstatus: !cartstatus },
        })
      )
    )
      .then(() => {
        loadData();
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "Cart Definition status updated successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      })
      .catch((err) => {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: err ?? "Something went wrong while updating status",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      });
  };

  // Component Lifecycle
  useEffect(() => {
    loadData();
  }, []);


  const [search, setSearch] = React.useState('')

  const handleFilter = (v) => {
    setSearch(v)
    Promise.resolve(dispatch(CART_DEFINITION_MASTER_FILTER({ filter: v })))
      .then((res) => { })
      .catch((err) => {
        console.error(err);
      });

  };


  return (
    <div className={classes.wrapper}>
      <StyledPaper className={classes.paper}>
        <div className={classes.container}>
          <div className={classes.titleRow}>
            <Typography className={classes.title}>Cart Definition</Typography>
            <div className={classes.flexAuto}></div>
            <div>
              <SearchInputBox
                id="searchDrug"
                placeholder="Search by Name, Store & Ward"
                onChange={(e) => { handleFilter(e.target.value) }}
                style={{ color: Color.palette.text.primary }}
                className={classes.searchAndButtonInput}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon htmlColor="#6A7888" />
                  </InputAdornment>
                }
              />
            </div>
            <div>
              <CustomButton
                variant={"contained"}
                color={"primary"}
                backgroundColor="#EC6A49"
                onClick={() => {
                  history.push(Routes.cartDefinitionUpsert);
                }}
              >
                + Add New
              </CustomButton>
            </div>
          </div>
          <StyledTable
            columns={columns}
            rows={search ? dataFilter : data}
            onEditClick={handleEditClick}
            onStatusChange={handleStatusChange}
          />
        </div>
      </StyledPaper>
    </div>
  );
}

export default withAllContexts(CartDefinitionListing);
