import React from "react";
import { Button, Grid } from "@material-ui/core";
import { CommonTabList } from "../../../../../../components";
import VitalCodeDescription from "./codeDescroption";
import ReferencesRanges from "./reference";

import { valitationFunc } from "../../../../../../utils";
import { withAllContexts } from "../../../../../../HOCs";
import { connect } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";

const errorList = ["code", "longdes", "shortdes", "unitAllowed", "defaultUnit"];
class VitalMastersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: null,
      code: "",
      longdes: "",
      shortdes: "",
      status: true,
      unitAllowed: [],
      defaultUnit: null,
      reference: [],
      error:{}
    };
    this.handleFormState = this.handleFormState.bind(this);
    this.saveForm = this.saveForm.bind(this);
  }

  //fetch masterdata and read QUERY
  async componentDidMount() {
    //masters data
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: this.props.editData ? "Loading..." : "Fetching...",
    });
    await this.props.UNIT_ALLOWED();
    await this.props.MASTER_GENDER();
    let a = await this.props.REFERENCE_UNIT_ALLOWED();
    
    await this.props.REFERENCE_AGERANGES();
    if (this.props.editData) {
      const editData = this.props.editData;
      await this.props.EDIT_VITAL_MASTER({ id: editData._id });
      let data = this.props.vitalEditData.data;

      if (!this.props.vitalEditData.error) {
        // for getting default unit
        let arr = data[0].unitAllowed.map((v) => {
          return v.value;
        });
        await this.props.DEFAULT_UNIT({ AllowedUnit: arr });

        // //for filtering AgeRangeCode
        // let datalist = data[0].reference.map((val) => val.ageRange.value);
        // let ageRangeMasterData = this.props.ageRange.data;
        // let ageRangeData = ageRangeMasterData.filter(
        //   (val) => datalist.indexOf(val.value) === -1
        // );

        this.state = data[0];
        this.setState({
          // ageRange: ageRangeData,
          ...this.state,
        });
      } else {
        this.props.alert.setSnack("error");
      }
    }
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  }

  // handle state
  handleFormState = async (name, value) => {
    if(name === "code"){
      if (value?.length > 20) {
        return false;
      }
    }
    let errorObj = JSON.parse(JSON.stringify(this.state.error)); 
     errorObj[name]=false
    if (name === "unitAllowed") {
      let arr = value.map((v) => {
        return v.value;
      });
      await this.props.DEFAULT_UNIT({ AllowedUnit: arr });
      this.state["defaultUnit"] = null;
    }
    this.setState({ [name]: value, error: errorObj });
  };

  //save form
  saveForm = async () => {
    // let error = false;
    // errorList.map((val) => {
    //   if (!this.state[val]) {
    //     error = true;
    //   }
    // });
  let {error,errorState}=  await valitationFunc(this.state, errorList);
        this.setState({
          ...this.state,
          error: errorState,
        });
    if (error) {
      //alertCall("mandatory", this.props.alert.setSnack);
      this.props.alert.setSnack("mandatory");
    } else {
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: this.props.editData ? "Updating..." : "Adding...",
      });
      await this.props.SAVE_VITAL_MASTER({ list: this.state });

      
      if (
        this.props.saveVitalMaster?.data?.Code === 201 &&
        !this.props.saveVitalMaster?.error
      ) {
        await this.props.READ_VITAL_MASTER();
         let type = this.props.editData ? "update" : "success";
         this.props.alert.setSnack(type);
         this.props.closeForm();
        } else if (this.props.saveVitalMaster?.error) {
        this.props.alert.setSnack("error");
      } else {
        this.props.alert.setSnack("unique");
      }
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });
     
      
    }
  };

  render() {
    const { closeForm = () => null, parent_id } = this.props;
    return (
      <React.Fragment>
        <CommonTabList
          parent_id={"vitlas_master_form"}
          backbtn
          backFun={closeForm}
          title={
            this.props.editData
              ? "Update Vital Master"
              : "Add New Vitals Master"
          }
          list={[]}
        />
        <Grid
          id={`${parent_id}_Vital_Code_Des_grid`}
          style={{ padding: "20px" }}>
          <VitalCodeDescription
            parent_id={"Vital_Code_Description"}
            handleFormState={this.handleFormState}
            editData={this.props.editData}
            {...this.state}
          />
        </Grid>
        <Grid
          id={`${parent_id}_References_grid`}
          style={{ padding: "20px" }}>
          <ReferencesRanges
            parent_id={"References_Ranges"}
            editData={this.props.editData}
            ageRangeOption={this.state.ageRange}
            handleFormState={this.handleFormState}
            unitData={this.props.refUnit?.data ?? []}
            {...this.state}
          />
        </Grid>

        <Grid
          id={`${parent_id}_button_grid`}
          style={{ padding: "20px" }}>
          <Button
            id={`${parent_id}_Save_button`}
            onClick={() => this.saveForm()}
            style={{ float: "right" }}
            variant="contained"
            color="primary"
          >
            {this.props.editData ? "Update" : "Save"}
          </Button>
          <Button
            id={`${parent_id}_Cancel_button`}
            onClick={() => closeForm()}
            style={{ float: "right", marginRight: "20px" }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  defaultUnit: state.vitalsMasterSectionSlice.default_unit,
  vitalEditData: state.vitalsMasterSectionSlice.edit_vital_master,
  ageRange: state.vitalsMasterSectionSlice.reference_ageranges,
  saveVitalMaster: state.vitalsMasterSectionSlice.save_vital_master,
  // gender: state.vitalsMasterSectionSlice.master_gender,
  // agerange: state.vitalsMasterSectionSlice.reference_ageranges,
  refUnit: state.vitalsMasterSectionSlice.reference_unit_allowed,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(VitalMastersForm));
