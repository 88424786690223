import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const StyledSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  switchBase: {
    padding: 4,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.primary.main,
      "& + $track": {
        backgroundColor: theme.palette.background.table,
        // backgroundColor: "#2A60BC",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#2A60BC",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    // backgroundColor: theme.palette.grey[50],
    backgroundColor: theme.palette.background.table,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
      checked={props.checked}
    />
  );
});

export default StyledSwitch;
