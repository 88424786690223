import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tag from "./tag";
import classNames from "classnames";
import { Tooltip, Typography } from "@material-ui/core";
import moment from "moment";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: 6,
    flexWrap: "nowrap",
  },
  defaultText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    color: "white",
  },
  expiredText: {
    fontWeight: 600,
    color: "#EC6A49",
  },
  issuedText: {
    fontWeight: 600,
  },
  dateText: {
    fontWeight: 400,
  },
}));

function AlertTagsForReturnMedication(props) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        {/* Return Tag */}
        {true && (
          <Tag>
            <Typography className={classes.defaultText}>Return</Typography>
          </Tag>
        )}

        {/* Expired On tag */}
        {/* <Tag backgroundColor={"#F9DDD6"}>
          <Typography
            className={classNames(classes.defaultText, classes.expiredText)}
          >
            Expired On&nbsp;
            <span className={classes.dateText}>
              {moment.unix(props.expiryOnDate).format("DD-MM-YYYY")}
            </span>
          </Typography>
        </Tag> */}

        {/* Issued on tag */}
        <Tag>
          <Typography
            className={classNames(classes.defaultText, classes.issuedText)}
          >
            Issued On&nbsp;
            <span className={classes.dateText}>
              {moment.unix(props.issuedOnDate).format("DD-MM-YYYY")}
            </span>
          </Typography>
        </Tag>

        {props?.drug?.returndate !== 0 && <Tag>
          <Typography
            className={classNames(classes.defaultText, classes.issuedText)}
          >
            Return On&nbsp;
            <span className={classes.dateText}>
              {moment.unix(props?.drug?.returndate).format("DD-MM-YYYY")}
            </span>
          </Typography>
        </Tag>}

        {props?.drug?.statusReason && <Tag backgroundColor={"#fff"}>
          <Tooltip title={'Reason : ' + props?.drug?.statusReason}>
            <DescriptionOutlinedIcon style={{ width: 18 }} />
          </Tooltip>
        </Tag>}

      </div>
    </>
  );
}

export default AlertTagsForReturnMedication;
