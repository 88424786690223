import React, { useState } from "react";
import { ReactComponent as ForwardIcon } from "../../assets/forwardIcon.svg";
import { ReactComponent as PrintIcon } from "../../assets/printIcon.svg";
import RefreshIcon from "@material-ui/icons/Refresh";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import {
  useTabsStyles,
  useTabStyles,
  useDividerStyles,
  useIconButtonStyles,
} from "./styles";
import AddIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Settings";
import { Close, Tick } from "../../assets";
import PropTypes from "prop-types";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Popover,
  TextField,
  Tooltip,
} from "@material-ui/core";
import ReasonMaster from "../../components/reasonMaster";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { WORKFLOW_VIEW } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
    // background: "white",
    background: `${theme.palette.background.table} !important`,
    // borderBottomLeftRadius: theme.spacing(1),
    // borderBottomRightRadius: theme.spacing(1),
    maxHeight: theme.spacing(6),
    display: "flex",
    alignItems: "center",
  },
  multiSave: {
    color: "#fff",
    background: `${theme.palette.primary.main} !important`,
    fontSize: 10,
    minWidth: "max-content",
    textTransform: "capitalize",
  },
  forwardIcon: {
    background: "#DEE5EC",
    borderRadius: "50%",
    height: theme.spacing(3.5),
    width: theme.spacing(3.5),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(1),
    "& svg": {
      transform: "rotate(180deg)",
      fontSize: "1.3rem",
    },
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  tabLabelCount: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    borderRadius: "50%",
    "& p": {
      margin: 0,
      color: "white",
    },
  },
  Maintitle: {
    color: theme.palette.text.primary,
  },
  MainBackground: {
    background: `${theme.palette.background.common} !important`,
  },
  // MainBackgroundCard: {
  //   background: `${theme.palette.background.table} !important`,
  // },
}));

function MedicationTopBar(props) {
  // Hook Calls
  const theme = useTheme();

  const viewChangePopupState = usePopupState({
    variant: "popover",
    popupId: "view-change",
  });

  // Styles
  const classes = useStyles();
  const tabsStyles = useTabsStyles();
  const tabStyles = useTabStyles();
  const dividerStyles = useDividerStyles();
  const iconButtonStyles = useIconButtonStyles();
  const [Arrow, setArrow] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [action, setAction] = React.useState(null);

  const [edirReason, setedirReason] = useState(false);
  const [valueReason, setvalueReason] = useState("");

  const handleChangeReason = (newValue) => {
    const value = typeof newValue === "string";

    if (!value) {
      setedirReason(newValue?.isapproval);
    }
    setvalueReason(newValue);
    props.handleVerifyReason(
      value ? newValue : (newValue?.value ?? newValue),
      newValue?.isapproval
    );
  };

  // Props
  const {
    onBackButtonClick,
    //title,
    tabs,
    tabIndex,
    onTabChange,
    onRefreshButtonClick,
    onPrintButtonClick,
    createNewOrder,
    isCardVerify,
    NewOrder,
    //tabActiveData,
    printDiscription,
    orderCount,
    onFullScreenWorkFlow,
    isMultiOrderSelect,
    workflowView,
    handleWorkflowChange,
  } = props;

  // Component States
  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const openArrow = Boolean(Arrow);
  const idArrow = openArrow ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setArrow(event.currentTarget);
  };

  const handleClose = () => {
    setArrow(null);
    setExpanded(false);
  };

  const handleExpandClick = (val) => {
    setAction(val);
    setExpanded(true);
  };

  return (
    <div className={classes.root}>
      <IconButton
        classes={iconButtonStyles}
        disableRipple
        className={classes.forwardIcon}
        onClick={onBackButtonClick}
      >
        <SvgIcon component={ForwardIcon} viewBox="-2 -2 8.35 14" />
      </IconButton>
      {/* <Typography className={classes.title}>{title}</Typography> */}
      {/* {isCardVerify &&
        tabActiveData.map((tab, index) => (
          <Tab
            key={index + 1}
            label={
              <React.Fragment>
                <div className={classes.tabLabelCount}>
                  <p>{index + 1}</p>
                </div>
                <Typography>{tab?.label}</Typography>
                {tabActiveData?.length - 1 !== index && '---------------- '}
              </React.Fragment>
            }
            classes={tabStyles}
            disableRipple
          />
        ))
      } */}
      <Divider
        orientation="vertical"
        flexItem
        classes={dividerStyles}
        style={{ margin: theme.spacing(0, 2) }}
      />
      <Tabs
        value={tabIndex}
        onChange={onTabChange}
        classes={tabsStyles}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        className={classes.MainBackgroundCard}
      >
        {tabs?.map((tab, index) => (
          <Tab
            key={tab?.id ?? index}
            label={
              <React.Fragment>
                <Typography>{tab?.label}</Typography>
                <div className={classes.tabLabelCount}>
                  <p>
                    {isCardVerify ? tab?.count : orderCount?.[tab?.label] ?? 0}
                  </p>
                </div>
              </React.Fragment>
            }
            classes={tabStyles}
            disableRipple
          />
        ))}
      </Tabs>
      <div style={{ flex: 1 }}></div>
      {!isCardVerify && props.selectedState === "verification" && NewOrder && (
        <Divider
          orientation="vertical"
          flexItem
          classes={dividerStyles}
          style={{ margin: theme.spacing(0, 2) }}
        />
      )}
      {!isCardVerify && props.selectedState === "verification" && (
        <IconButton classes={iconButtonStyles} onClick={() => createNewOrder()}>
          <AddIcon />
        </IconButton>
      )}
      {!isCardVerify && (
        <Divider
          orientation="vertical"
          flexItem
          classes={dividerStyles}
          style={{ margin: theme.spacing(0, 2) }}
        />
      )}
      <IconButton
        classes={iconButtonStyles}
        {...bindTrigger(viewChangePopupState)}
      >
        <SettingsIcon />
      </IconButton>
      <Divider
        orientation="vertical"
        flexItem
        classes={dividerStyles}
        style={{ margin: theme.spacing(0, 2) }}
      />
      {!isCardVerify && (
        <IconButton classes={iconButtonStyles} onClick={onRefreshButtonClick}>
          <RefreshIcon />
        </IconButton>
      )}
      {
        <Tooltip arrow title="Show Full Screen">
          <IconButton classes={iconButtonStyles} onClick={onFullScreenWorkFlow}>
            <FullscreenIcon />
          </IconButton>
        </Tooltip>
      }
      <Divider
        orientation="vertical"
        flexItem
        classes={dividerStyles}
        style={{ margin: theme.spacing(0, 1.5) }}
      />
      {printDiscription && (
        <IconButton
          classes={iconButtonStyles}
          disableRipple
          onClick={onPrintButtonClick}
        >
          <PrintIcon />
        </IconButton>
      )}
      <Divider
        orientation="vertical"
        flexItem
        classes={dividerStyles}
        style={{ margin: theme.spacing(0, 1.5) }}
      />
      {isMultiOrderSelect?.length > 0 && (
        <div onMouseOver={(e) => handleClick(e)}>
          <Button className={classes.multiSave}>
            {isMultiOrderSelect?.length} Selected
          </Button>
        </div>
      )}
      &nbsp;
      {isMultiOrderSelect?.length > 0 && (
        <Button
          className={classes.multiSave}
        // onClick={() => { props.saveMultiOrder() }}
        >
          Save & Proceed
        </Button>
      )}
      <Popover
        id={idArrow}
        open={openArrow}
        anchorEl={Arrow}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {props.issueMedication ? (
          <div className={classes.reasonPaper}>
            <Typography className={classes.reasonTitle}>
              Reason for ReIssue
            </Typography>

            <div className={classes.reasonFieldContainer}>
              <Typography className={classes.reasonFieldLabel}>
                Select Reason
              </Typography>

              <ReasonMaster
                type={"REISSUEREASON"}
                onChange={handleChangeReason}
                optionLabel={"label"}
                value={valueReason}
                reIssue={true}
              />
            </div>

            {props.actions?.map((val) => {
              return (
                <Button
                  key={val.actionid}
                  className={classes.button}
                  onClick={() => {
                    // handleExpandClick(val);
                    // props.handleVerify(val, edirReason);

                    props.saveMultiOrder(val, edirReason);
                    handleClose();
                  }}
                >
                  {val.action}
                </Button>
              );
            })}
          </div>
        ) : (
          props.returnMedication && (
            <div className={classes.reasonPaper}>
              {props?.hiddenReason && (
                <Typography className={classes.reasonTitle}>
                  Reason for return
                </Typography>
              )}

              {props?.hiddenReason && (
                <div className={classes.reasonFieldContainer}>
                  <Typography className={classes.reasonFieldLabel}>
                    Select Reason
                  </Typography>

                  <ReasonMaster
                    // type={'DrugMaster/143241961'}
                    type={
                      props.drugIds?.length === 1
                        ? props.drugIds?.[0]
                        : props.drugIds || []
                    }
                    onChange={handleChangeReason}
                    optionLabel={"label"}
                    value={valueReason}
                    medication={true}
                  />
                </div>
              )}

              {props.actions?.map((val) => {
                if (!edirReason && val?.action === "RAISE FOR APPROVAL") {
                  return;
                } else if (edirReason && val?.action === "RETURN") {
                  return;
                }
                return (
                  <Button
                    key={val.actionid}
                    className={classes.button}
                    onClick={() => {
                      // handleExpandClick(val);
                      // props.handleVerify(val, edirReason, true);

                      props.saveMultiOrder(val, edirReason);
                      handleClose();
                    }}
                  >
                    {val.action}
                  </Button>
                );
              })}
            </div>
          )
        )}

        {!props.issueMedication && !props.returnMedication && (
          <>
            <div
              style={{ display: "flex", padding: "12px" }}
              className={classes.btngroup}
            >
              {props?.actions?.map((val) => {
                return (
                  <Button
                    key={val.actionid}
                    className={classes.button}
                    onClick={() => {
                      handleExpandClick(val);
                    }}
                  >
                    {val.action}
                  </Button>
                );
              })}
            </div>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Divider />
              <Card className={classes.card}>
                <CardHeader
                  className={classes?.header}
                  action={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        onClick={handleClose}
                        style={{ width: "24px", height: "24px", margin: "5px" }}
                      >
                        <Close />
                      </div>
                      <div
                        onClick={() => {
                          handleClose();
                          props.saveMultiOrder(action);
                        }}
                        style={{ width: "24px", height: "24px", margin: "5px" }}
                      >
                        <Tick />
                      </div>
                    </div>
                  }
                  subheader={<span>
                    Reason for {action?.action?.toLowerCase()}
                    {action?.action?.toLowerCase()?.includes('reverify') && <span style={{ color: "red" }}>&nbsp;*</span>}

                  </span>}
                />
                <CardContent className={classes?.content}>
                  <ReasonMaster
                    action={action?.action}
                    // drug={drug}
                    // value={valueReason ?? {}}
                    // optionLabel={"title"}
                    type={"DRUGFILLINGREASON"}
                    onChange={handleChangeReason}
                  />
                </CardContent>
              </Card>
            </Collapse>
          </>
        )}
      </Popover>
      {/* Menu for View Change Icon */}
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...bindMenu(viewChangePopupState)}
      >
        {WORKFLOW_VIEW.map((view, index) => (
          <MenuItem
            onClick={() => {
              handleWorkflowChange(view.id, index);
              viewChangePopupState.close();
            }}
            selected={view.id === workflowView}
          >
            {view.display}
          </MenuItem>
        ))}

        {/* <MenuItem
          onClick={() => {
            handleWorkflowChange(WORKFLOW_VIEW.default);
            viewChangePopupState.close();
          }}
        >
          Default View
        </MenuItem> */}
      </Menu>
    </div>
  );
}

export default MedicationTopBar;

MedicationTopBar.defaultProps = {
  onBackButtonClick: () => { },
  title: "",
  tabs: [],
  tabIndex: 0,
  onTabChange: () => { },
  onPrintButtonClick: () => { },
};

MedicationTopBar.propTypes = {
  onBackButtonClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  tabIndex: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
  onPrintButtonClick: PropTypes.func,
};
