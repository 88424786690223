import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Grid, Typography } from "@material-ui/core";
import Color from "../../themes/default.json"
import makeStyles from "@material-ui/core/styles/makeStyles";

import qdmstyles from "./styles.json";
import "./index.css";
var aes256 = require("aes256");


const useStyles = makeStyles((theme) => ({
  tableheader:{
    backgroundColor:theme.palette.background.tableHeader
  },
  tabletitle:{
    color:theme.palette.text.primary
  }
}));

function Tableheader(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            container={true}
            direction={"row"}
            justifyContent={"space-evenly"}
            alignItems={"stretch"}
            alignContent={"stretch"}
            spacing={"1"}
            lg={"12"}
            md={"12"}
            sm={"12"}
            className={classes.tableheader}
            style={qdmstyles.rztO}
          >
            <Grid
              key={"0"}
              container={false}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              lg={"1"}
              md={"1"}
              sm={"1"}
              item={true}
            >
              <Typography
                key={"0"}
                align={"inherit"}
                display={"initial"}
                variant={"body1"}
                children={t('frequency.table.sNo')}
                style={qdmstyles.AKIzs}
                className={classes.tabletitle}
              ></Typography>
            </Grid>
            <Grid
              key={"1"}
              container={false}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              lg={"2"}
              md={"2"}
              sm={"2"}
              item={true}
            >
              <Typography
                key={"0"}
                align={"inherit"}
                display={"initial"}
                variant={"body1"}
                className={classes.tabletitle}
                children={t('frequency.table.freqcode')}
                style={qdmstyles.GqpL}
              ></Typography>
            </Grid>
            <Grid
              key={"2"}
              container={false}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              lg={"3"}
              md={"3"}
              sm={"3"}
              item={true}
            >
              <Typography
                key={"0"}
                align={"inherit"}
                display={"initial"}
                variant={"body1"}
                children={t('frequency.table.frequencyname')}
                className={classes.tabletitle}
                style={qdmstyles.mRv}
              ></Typography>
            </Grid>
            <Grid
              key={"3"}
              container={false}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              lg={"4"}
              md={"4"}
              sm={"4"}
              item={true}
            >
              <Typography
                key={"0"}
                align={"inherit"}
                display={"initial"}
                variant={"body1"}
                className={classes.tabletitle}
                children={t('frequency.table.frequencytype')}
                style={qdmstyles.QKc }
              ></Typography>
            </Grid>
            <Grid
              key={"4"}
              container={false}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              lg={"1"}
              md={"1"}
              sm={"1"}
              item={true}
              style={qdmstyles.WGxV}
            >
              <Typography
                key={"0"}
                align={"inherit"}
                display={"initial"}
                variant={"body1"}
                className={classes.tabletitle}
                children={t('frequency.table.status')}
                style={qdmstyles.OHdRN}
              ></Typography>
            </Grid>
            <Grid
              key={"5"}
              container={false}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              lg={"1"}
              md={"1"}
              sm={"1"}
              item={true}
            >
              <Typography
                key={"0"}
                align={"inherit"}
                display={"initial"}
                variant={"body1"}
                className={classes.tabletitle}
                children={t('frequency.table.action')}
                style={qdmstyles.BN}
              ></Typography>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Tableheader));
