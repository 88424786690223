import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import DrugBrandTable from "../drugBrandTable";
import { WarningIcon } from "../../../../../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #DEE5EC",
    borderRadius: 8,
    padding: 12,
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  drugNameContainer: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  drugName: {
    fontSize: 12,
    fontWeight: 400,
    color: "#2A3C50",
  },
  drugUOM: {
    fontSize: 12,
    fontWeight: 400,
    
  },
}));

export const useDividerStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#B4BBC3",
  },
  flexItem: {
    height: "18px",
    alignSelf: "center",
  },
}));

function BrandTableWrapper({ drugName, dosageValue, dosageUOM, ...props }) {
  const classes = useStyles();
  const dividerStyles = useDividerStyles();

  return (
    <div className={classes.root}>
      <div className={classes.rowContainer}>
        <div className={classes.drugNameContainer}>
          <Typography className={classes.drugName}>{drugName}</Typography>
          <Divider orientation="vertical" flexItem classes={dividerStyles} />
          <Typography className={classes.drugUOM}>
            {dosageValue}
            {dosageUOM}
          </Typography>
        </div>
      </div>
      <div>{props.children}</div>
    </div>
  );
}

export default BrandTableWrapper;
