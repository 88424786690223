import { makeStyles } from "@material-ui/core/styles";

export const useDividerStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#B4BBC3",
  },
  flexItem: {
    height: "1px",
    marginTop:"8px",
    alignSelf: "center",
  },
}));

export const useDividerStyles2 = makeStyles((theme) => ({
  root: {
    backgroundColor: "#B4BBC3",
  },
  flexItem: {
    height: "1px",
    alignSelf: "center",
    background:"#DEE5EC !important"
  },
}));