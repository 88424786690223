import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Box, Grid, Avatar, Typography, Switch } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
var aes256 = require("aes256");

function PharmacyRow(props) {
  const dispatch = useDispatch();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {}, [state]);

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Box
            key={"0"}
            id={"6luKU"}
            m={"1"}
            component={"div"}
            style={qdmstyles.luKU}
          >
            <Box
              key={"0"}
              id={"eMD8Y"}
              m={"1"}
              component={"div"}
              style={qdmstyles.RlGR}
            >
              <Grid
                key={"0"}
                id={"gyqWe"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <Grid
                  key={"0"}
                  id={"hS8cY"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  lg={"8"}
                  style={qdmstyles.YsD}
                >
                  <Grid
                    key={"0"}
                    id={"3ft7O"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    lg={"3"}
                    style={qdmstyles.oEJU}
                  >
                    <Avatar
                      key={"0"}
                      id={"VVxdm"}
                      alt={"Avatar"}
                      variant={"rounded"}
                      style={qdmstyles.blzqB}
                    ></Avatar>
                    <Typography
                      key={"1"}
                      id={"7nHuZ"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"OP Pharmacy-01"}
                      style={qdmstyles.kYiO}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"3ft7O"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    lg={"2"}
                  >
                    <Avatar
                      key={"0"}
                      id={"VVxdm"}
                      alt={"Avatar"}
                      variant={"rounded"}
                      style={qdmstyles.WNmAu}
                    ></Avatar>
                    <Typography
                      key={"1"}
                      id={"7nHuZ"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"PH-001"}
                      style={qdmstyles.oqsdH}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"3ft7O"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    lg={"7"}
                  >
                    <Avatar
                      key={"0"}
                      id={"VVxdm"}
                      alt={"Avatar"}
                      variant={"rounded"}
                      style={qdmstyles.sAhAV}
                    ></Avatar>
                    <Typography
                      key={"1"}
                      id={"7nHuZ"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"AINQA Multi-Speciality Hospitals, Chennai"}
                      style={qdmstyles.VeKCf}
                    ></Typography>
                  </Grid>
                </Grid>
                <Grid
                  key={"1"}
                  id={"WNbWw"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  lg={"4"}
                >
                  <Grid
                    key={"0"}
                    id={"fHLcG"}
                    container={true}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"2"}
                  >
                    <Avatar
                      key={"0"}
                      id={"uWSkI"}
                      alt={"Avatar"}
                      variant={"rounded"}
                      style={qdmstyles.ESNlq}
                    ></Avatar>
                  </Grid>
                  <Switch
                    key={"1"}
                    id={"2TPoU"}
                    checked={true}
                    color={"primary"}
                    name={"switch"}
                  ></Switch>
                </Grid>
              </Grid>
              <Grid
                key={"1"}
                id={"EuvuQ"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                style={qdmstyles.Gbgrn}
              >
                <Grid
                  key={"0"}
                  id={"49Quk"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  lg={"3"}
                >
                  <Avatar
                    key={"0"}
                    id={"xTKXT"}
                    alt={"Avatar"}
                    variant={"rounded"}
                    style={qdmstyles.Htljn}
                  ></Avatar>
                  <Typography
                    key={"1"}
                    id={"6kNxb"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"D-Block, Second Floor, Ward 2"}
                    style={qdmstyles.cKgW}
                  ></Typography>
                </Grid>
                <Grid
                  key={"1"}
                  id={"49Quk"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  lg={"9"}
                >
                  <Grid
                    key={"0"}
                    id={"Dmwzw"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    lg={"3"}
                    xl={"10"}
                  >
                    <Grid
                      key={"0"}
                      id={"5BIUV"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"1"}
                    >
                      <Avatar
                        key={"0"}
                        id={"UERAf"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        style={qdmstyles.HzjWO}
                      ></Avatar>
                    </Grid>
                    <Grid
                      key={"1"}
                      id={"LQ7cQ"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      xl={"10"}
                      lg={"8"}
                    >
                      <Typography
                        key={"0"}
                        id={"uFEFj"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"+91 95966 65833"}
                        style={qdmstyles.ZFSIl}
                      ></Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"Dmwzw"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    lg={"3"}
                    xl={"10"}
                  >
                    <Grid
                      key={"0"}
                      id={"5BIUV"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"1"}
                    >
                      <Avatar
                        key={"0"}
                        id={"UERAf"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        style={qdmstyles.WBNy}
                      ></Avatar>
                    </Grid>
                    <Grid
                      key={"1"}
                      id={"LQ7cQ"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      xl={"7"}
                      lg={"8"}
                    >
                      <Typography
                        key={"0"}
                        id={"uFEFj"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"+91 95966 65833"}
                        style={qdmstyles.zyYJT}
                      ></Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"Dmwzw"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    lg={"3"}
                    xl={"10"}
                  >
                    <Grid
                      key={"0"}
                      id={"5BIUV"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"1"}
                    >
                      <Avatar
                        key={"0"}
                        id={"UERAf"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        style={qdmstyles.pSETx}
                      ></Avatar>
                    </Grid>
                    <Grid
                      key={"1"}
                      id={"LQ7cQ"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      xl={"8"}
                      lg={"10"}
                    >
                      <Typography
                        key={"0"}
                        id={"uFEFj"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"sara.lucas@mail.com"}
                        style={qdmstyles.BBQI}
                      ></Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(PharmacyRow));
