import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import SearchIcon from "@material-ui/icons/Search";
import {
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Badge,
  Avatar,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Cartdetails from "../../components/carddetails";
import { ExpandLess } from "@material-ui/icons";
import { AlertProps, makeNameForPract } from "../../utils";
import { withAllContexts } from "../../HOCs";
import { translate } from "react-i18next";
import { actions } from "frequencyscreen_v_dbinder";
import moment from "moment";
import { useHistory, withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Hospital from "../../assets/hospital";
import Ambulance from "../../assets/ambulance ";
import BeingSick from "../../assets/being-sick";
import DiagnosisIcon from "../../assets/diagnosisIcon";
import PatientOxygenMask from "../../assets/patient-oxygen-mask";
import { PediatricIcon, SeniorCitizenIcon } from "../../assets";
import SimpleBadge from "./badge";

const useStyles = makeStyles((theme) => ({
  rootClass: {
    "& .App1-MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },
  appBar: {
    position: "relative",
    // backgroundColor: "#fff",
    background: `${theme.palette.background.common} !important`,
    boxShadow: "none",
    "& .App1-MuiToolbar-gutters": {
      padding: "5px",
    },
    padding: "0px 10px 0px 16px",
  },
  title: {
    flex: 1,
    color: theme.palette.text.primary,
    // color: "#001C3C",
    fontSize: "14px",
    fontWeight: 600,
  },
  title2: {
    // color: "#001C3C",
    fontSize: "14px",
    fontWeight: 600,
  },
  paper: {
    width: "25%",
    position: "absolute",
    right: "0",
    boxShadow: "none",
    borderLeft: "1px solid #cccccc4a",
    height: "calc(100vh - 64px)",
    bottom: "0",
    background: `${theme.palette.background.common} !important`,
  },
  backDropRoot: {
    backgroundColor: "transparent",
  },
  closeBtn: {
    color: "#01205C",
    width: "32px",
    height: "32px",
    backgroundColor: "#DEE5EC",
    borderRadius: "50%",
  },
  dialogContent: {
    padding: "3px 0px 0px 0px",
  },
  notchedOutline: {
    borderRadius: "8px !important",
  },
  searchTextField: {
    backgroundColor: "#fff",
    borderRadius: "35px",
  },
  dialogLower: {
    padding: "0px 12px",
    marginTop: "56px",
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${"#fff"} !important`,
    // color: "#01205C !important",
  },
  toggleBtn: {
    textTransform: "none",
    "& button": {
      color: theme.palette.text.primary,
    },
    color: theme.palette.text.primary,
  },
  btnNumber: {
    padding: "1px",
    borderRadius: "50%",
    color: theme.palette.text.primary,
    background: theme.palette.background.table,
    // color: "#fff",
    marginLeft: "8px",
    width: "20px",
    height: "20px",
  },
  tabs: {
    flexGrow: 1,
    maxWidth: 500,
    boxShadow: "none",
    marginBottom: "8px",
    background: theme.palette.background.table,
    position: "absolute",
    width: "100%",
    zIndex: 10,
  },
}));
// const useTabsStyles = makeStyles((theme) => ({
//   root: {
//     minHeight: 38,
//     alignSelf: "flex-start",
//   },
//   flexContainer: {
//     gap: 24,
//   },
//   indicator: {
//     background: `${theme.palette.primary.main} !important`,
//     // backgroundColor: "#2A60BC",
//   },
// }));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ScreenDialog(props) {
  const Class = useStyles();
  const theme = useTheme();
  let dispatch = useDispatch();
  // const tabsStyles = useTabsStyles();
  // const history = useHistory();
  // const stateList = useSelector((state) => state?.signInReducerApiSlice);
  const [ward, setWard] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [queueCounts, setQueueCounts] = React.useState([]);
  const [data, setData] = React.useState({
    cartName: [],
    subStore: [],
    subStoreValue: null,
    cartNameValue: null,
    startDate: null,
    endDtae: null,
    Orgname: "",
    cartid: "",
    carttrxid: "",
    Json: [],
    ward: [],
    wardValue: {},
    wardValueCode: "",
    cartReason: [],
  });
  const handleState = async (val) => {
    const cartGenerate = await dispatch(
      actions.CART_GENERATE({
        cartid: data?.cartid,
        carttrxid: data?.carttrxid,
      })
    );
    if (cartGenerate?.payload?.data?.error) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: cartGenerate?.payload?.data?.error,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      setShow(false);
      return;
    } else {
      const data_ = cartGenerate?.payload?.data?.map((v) => {
        let child = v?.OrderDetails?.[0];
        return {
          tabname: child?.drugname ?? "",
          hand: v?.totIssueQty + " " + v?.issueuom,
          stack: v?.stkQTY + " " + v?.stockuom,
          ban: v?.blockqty ?? "",
          order: "No.of orders" + " " + `(${child?.orderID?.length})`,
          // negative: true
        };
      });
      if (data_?.length > 0) {
        setShow(true);
      } else {
        setShow(false);
      }
      setData({ ...data, Json: data_ });
    }
  };
  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events
    /// fetch all permission
    getFetch();
  }, []);
  const getFetch = async () => {
    const cartName = await dispatch(actions.CART_NAME({ open: true }));
    const result = cartName?.payload?.data
      ?.map((v) => {
        if (v?.cartName) {
          return {
            label: v?.cartName,
            value: v?.cartid,
            startDate: v?.startdate,
            endDate: v?.enddate,
            _id: v?._id,
            ward: v?.ward?.map((c) => {
              return {
                label: c?.longdesc ?? "",
                value: c?._id ?? "",
              };
            }),
          };
        }
      })
      .filter((notUndefined) => notUndefined !== undefined);
    const cartReason = await dispatch(actions.CART_REASON());
    const result_cartReason = cartReason?.payload?.data
      ?.map((v) => {
        return {
          label: v?.display,
          value: v?._id,
        };
      })
      .filter((notUndefined) => notUndefined !== undefined);
    setData({ ...data, cartName: result, cartReason: result_cartReason });
  };
  const handleChange = async (n, v) => {
    data.wardValueCode = "";
    data.wardValue = {};
    if (n === "cartNameValue") {
      const subStore = await dispatch(actions.SUB_STORE({ id: v?.value }));
      data.startDate = v?.startDate;
      data.endDate = v?.endDate;
      const result = subStore?.payload?.data?.map((v) => {
        return {
          label: v?.locationDetails?.longdesc,
          Orgname: v?.cartname || v?.locationDetails?.longdesc,
          value: v?._id,
          locationid: v?.locationid,
        };
      });
      data.cartid = v?.value ?? "";
      data.carttrxid = v?._id ?? "";
      data.ward = v?.ward ?? "";
      data.subStore = result;
      data.subStoreValue = result?.[0] ?? {};
      data.Orgname = result?.[0]?.Orgname ?? "";
      setWard(false);
    } else if (n === "wardValue") {
      data.wardValueCode = v?.label;
    }
    setData({ ...data, [n]: v });
  };
  const handleOpenCart = async () => {
    if (!data.cartNameValue?.value || !data.wardValue?.value) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the mandatory fields!.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
    const payload = {
      cartID: data?.cartNameValue?.value ?? "",
      locID: data?.subStoreValue?.locationid ?? "",
      wardID: data?.wardValue?.value ?? "",
    };
    const showCart = await dispatch(
      actions.CART_PATIENT_QUEUE({
        ...payload,
      })
    );
    if (showCart?.payload?.data?.error) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg:
          showCart?.payload?.data?.errorMessage ||
          showCart?.payload?.data?.result?.[0]?.message ||
          showCart?.payload?.data?.result?.[0]?.Reason ||
          showCart?.payload?.data?.error,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
    props.handleUpdateState({
      Cartid: data?.cartNameValue?.value ?? "",
      CartTransId: data?.cartNameValue?._id ?? "",
    });
  };
  const handlegetTransactionCart = async () => {
    const payload = {
      CartId: data?.cartNameValue?.value ?? "",
      LocationId: data?.subStoreValue?.locationid ?? "",
    };
    const openCart = await dispatch(actions.CLOSE_CART({ ...payload }));
    if (openCart?.payload?.data?.error) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg:
          openCart?.payload?.data?.errorMessage ||
          openCart?.payload?.data?.error,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
  };
  const {
    header = "Header",
    count,
    bodyComp,
    open,
    handleCloseDrawer = () => null,
    pendingCount,
    search,
    handleSearch = () => null,
    handleSearchOnclick = () => null,
  } = props;
  const handleClose = () => {
    handleCloseDrawer();
  };

  const [cart, setCart] = React.useState(false);
  // const [isQueue, setisQueue] = React.useState(0);

  const handlecart = () => {
    setCart(true);
  };
  const handlecartClose = () => {
    setCart(false);
  };
  // const onTabChange = (e, v) => {
  //   setisQueue(v);
  //   let Queue = props?.priorityList?.[v]?.id;
  //   props.handleGetQueueData(Queue);
  // };
  const Dates =
    data?.startDate || data?.endDate
      ? `${moment.unix(data?.startDate).format("DD-MM-YYYY h:mm")} to ${moment
          .unix(data?.endDate)
          .format("DD-MM-YYYY h:mm")}`
      : "";

  const QueueIcons = {
    All: "All",
    "6d114e31-392b-43ac-9f69-4d722a359de9": <BeingSick />, // Stat Order
    "d30f2166-c2a2-43fd-a63e-1a8b3e093cf1": <Ambulance />, // Emergency
    "b683d6e1-7b93-48ef-9073-534a176b6ddb": <SeniorCitizenIcon />, // Senior Citizen
    "9b20f881-79d6-4d40-9d53-89f7ba3ca097": <PediatricIcon />, // Paediatric
  };
  // const QueueIcons = [
  //   {
  //     count: "1",
  //     color: "#E71D23",
  //     icon: "All",
  //   },
  //   {
  //     count: "1",
  //     color: "#E71D23",
  //     icon: <Ambulance />,
  //   },
  //   {
  //     count: "2",
  //     color: "#F87979",
  //     icon: <Hospital />,
  //   },
  //   {
  //     count: "3",
  //     color: "#38C7B9",
  //     icon: <BeingSick />,
  //   },
  //   {
  //     count: "4",
  //     color: "#63CF3E",
  //     icon: <DiagnosisIcon />,
  //   },
  //   {
  //     count: "5",
  //     color: "#2E8EE2",
  //     icon: <PatientOxygenMask />,
  //   },
  // ];
  return (
    <div {...props}>
      <div className={Class?.rootClass}>
        <Dialog
          classes={{
            paper: Class.paper,
          }}
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          BackdropProps={{
            classes: {
              root: Class.backDropRoot,
            },
          }}
        >
          {!props?.isHidden && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "18px",
                paddingBottom: "0px",
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography className={Class.title2} variant="inherit">
                  Card Details
                </Typography>
                <div>
                  {cart ? (
                    <ExpandLess onClick={handlecartClose} />
                  ) : (
                    <ExpandMore onClick={handlecart} />
                  )}
                </div>
              </div>
              <IconButton
                className={Class.closeBtn}
                autoFocus
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
          )}
          {cart && (
            <div>
              <Cartdetails
                data={data}
                hideCloseCart={
                  !data.cartNameValue?.value || !data.wardValue?.value
                }
                Json={{
                  detail: data?.cartName ?? [],
                  issue: data?.subStore ?? [],
                  cart: data?.Orgname ?? "",
                  ward: data?.ward ?? [],
                  date: Dates,
                }}
                closeCart
                cartReason={data?.cartReason}
                selectData={{
                  cartName: data?.cartNameValue ?? "",
                  date: Dates,
                  wardValue: data?.wardValue ?? "",
                  practitoner: {
                    label: makeNameForPract(
                      props?.practitionerData?.Practitioner_id?.[0]?.name?.[0]
                    ),
                  },
                }}
                ward={ward}
                setWard={setWard}
                handleState={handleState}
                handleChange={handleChange}
                handleclickShowDetails={handleOpenCart}
                handlegetTransactionCart={handlegetTransactionCart}
              />
            </div>
          )}
          <AppBar className={Class.appBar}>
            <Toolbar disableGutters>
              <Typography variant="h6" className={Class.title}>
                {`${header}`}({`${count}`})
              </Typography>
              {props?.isHidden && (
                <IconButton
                  className={Class.closeBtn}
                  autoFocus
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          <DialogContent className={Class.dialogContent}>
            {!props?.isCardVerify && (
              <Paper square className={Class.tabs}>
                <Tabs
                  variant="fullWidth"
                  value={props?.selectedTab}
                  onChange={props?.onTabChange}
                >
                  {props?.priorityList?.map((item, index) => {
                    const currentQueue =
                      Array.isArray(props?.queue_count) &&
                      props?.queue_count?.filter(
                        (x) => x?.GrpId === item?.id
                      )?.[0];

                    return (
                      <Tooltip title={item?.label ?? "default"} arrow>
                        <Tab
                          style={{
                            minWidth: "35px",
                            // color: theme.palette.text.main
                          }}
                          key={item?.id ?? index}
                          showZero
                          label={
                            // QueueIcons[item.label]
                            <SimpleBadge
                              bgcolor={item?.bgcolor}
                              textcolor={item?.textcolor}
                              badgeContent={
                                item.id
                                  ? currentQueue?.Grpcount
                                    ? currentQueue?.Grpcount
                                    : 0
                                  : props?.count
                              }
                              component={
                                item?.icon === "All" ? (
                                  "All"
                                ) : item?.icon ? (
                                  <img
                                    src={
                                      props?.selectedTab === index
                                        ? item?.activeIcon
                                        : item?.icon
                                    }
                                    alt={item.label}
                                  />
                                ) : (
                                  <>{"N/A"}</>
                                )
                              }
                            />
                          }
                        />
                      </Tooltip>
                    );
                  })}
                </Tabs>
              </Paper>
            )}
            <div className={Class.dialogLower}>
              <TextField
                id="input-with-icon-textfield"
                placeholder="Search"
                size="small"
                fullWidth
                autoComplete="off"
                variant="outlined"
                value={search}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={() => handleSearchOnclick()}
                      position="start"
                    >
                      <SearchIcon
                        fontSize="small"
                        style={{ cursor: "pointer" }}
                      />
                    </InputAdornment>
                  ),
                  classes: {
                    root: Class.notchedOutline,
                  },
                }}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              />
            </div>
            {bodyComp?.component
              ? bodyComp?.componentName + " Mapped"
              : bodyComp}
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
export default withAllContexts(withRouter(translate()(ScreenDialog)));
