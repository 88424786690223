import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { LoggerContext } from "../../contexts";
import { withAllContexts } from "../../HOCs";
import * as ALL from "../../components";
import * as ALLMCORE from "@material-ui/core";
import { CreateRule } from "sequence-rule-builder";
import { styles } from "./styles";
import {
  Paper,
  Grid,
  Box,
  Avatar,
  Typography,
  Divider,
  Button,
  TextField,
  Switch,
  withStyles,
  Checkbox,
} from "@material-ui/core";
import { MuiStepper, ComponentRender } from "custom-material-ui-component";

import { Autocomplete } from "@material-ui/lab";
import moment from "moment";

import qdmstyles from "./styles.json";
import "./index.css";

const allowedChar = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];
class Addnewpharmacy extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      permissionData: null,
      addnewpharmacy_3yrav: null,
      addnewpharmacy_ku5mp: null,
      addnewpharmacy_flkww: null,
      addnewpharmacy_wsjxk: null,
      addnewpharmacy_2955j: null,
      addnewpharmacy_gyzj6: null,
      addnewpharmacy_jepfz: null,
      addnewpharmacy_guw19: {
        Type: "OPERATIONSTATUS",
        activestatus: true,
        code: "OPC005",
        display: "Occupied",
        id: 467,
        shortdesc: "Occupied",
        status: true,
        _id: "CodingMaster/10467",
        _key: "10467",
      },
      addnewpharmacy_ekyrq: null,
      addnewpharmacy_m9tu8: {
        Type: "LOCATIONROLE",
        activestatus: true,
        code: "SC",
        display: "Pharmacy",
        id: 599,
        status: true,
        _id: "CodingMaster/10599",
        _key: "10599",
      },
      addnewpharmacy_mrpjg: null,
      addnewpharmacy_bkahp: null,
      addnewpharmacy_vkw8j: null,
      addnewpharmacy_8mjav: null,
      addnewpharmacy_nduhj: null,
      addnewpharmacy_zqixu: null,
      addnewpharmacy_cpf6d: null,
      editData: null,
      url: "",
      index: null,
      index_ride: null,
      editDataOverRide: [],
      WorkingDays: [],
      OverRide: [],
      editId: [],
      isRule: false,
      isOpen: false,
      isExist: false,
      groupData: [],
      ruleData: [],
      group: {},
      rule: {},
      _key: "",
      _id: "",
      status: true,
      filtervalue: "",

      // Location Config States
      allowbulkverification: false,
      allowbulkfilling: false,
      considerlatearrival: false,

      autocalculate: false,
    };
  }

  handleworkdaydeleteoverride = (a, b, c) => {
    var arr = this.state.OverRide.filter((v, i) => b !== i && v);
    this.setState({
      ...this.state,
      OverRide: arr,
    });
  };

  handleworkdayeditoverride = (a, b, c) => {
    this.setState({
      workingdays_sspf0: true,
      editDataOverRide: a,
      index_ride: b,
    });
  };

  handleworkdayedit = (a, b, c) => {
    this.setState({
      workingdays_sspf0: true,
      editData: a,
      index: b,
    });
  };

  handleworkdaydelete = (a, b, c) => {
    var arr = this.state.WorkingDays.filter((v, i) => b !== i && v);
    this.setState({
      ...this.state,
      WorkingDays: arr,
    });
  };

  readDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      // async event handlers
      reader.onload = (e) => resolve(reader.result);
      reader.onerror = (e) => reject(reader.error);
      reader.readAsDataURL(file);
    });
  };
  handleChangePhotoFileInput = (e) => {
    const target = e.currentTarget;
    const file = target.files.item(0);

    // validate file as image
    if (!file.type.startsWith("image/")) {
      alert("File is not an image");
      return;
    }

    // store reference to the File object and a base64 representation of it
    this.readDataUrl(file).then((dataUrl) => {
      this.setState({
        // file,
        url: dataUrl,
        // objectUrl: URL.createObjectURL(file)
      });
    });
  };
  changeState = (key, value) => {
    const state = JSON.parse(JSON.stringify(this.state));
    if (state.index !== null && state.index >= 0 && key === "WorkingDays") {
      state.WorkingDays[state.index] = value[value.length - 1];
      this.setState({
        ...state,
        index: null,
      });
      return;
    }
    if (
      state.index_ride !== null &&
      state.index_ride >= 0 &&
      key === "OverRide"
    ) {
      state.OverRide[state.index_ride] = value[value.length - 1];
      this.setState({
        ...state,
        index_ride: null,
      });
      return;
    }
    this.setState({
      [key]: value,
      index: null,
    });
  };

  onclick_save = async () => {
    if (!this.state.Orderingdetails) {
      return this.props.alert.setSnack({
        open: true,
        msg: `Please Fill the ordering details!`,
        severity: "error",
      });
      return;
    }

    let error = [];

    const valid = this.state.Orderingdetails?.map((v) => {
      if (
        v?.orderingdetails_fqnvb === undefined ||
        v?.orderingdetails_fqnvb === null ||
        v?.orderingdetails_fqnvb?.length === 0
      ) {
        error.push("Ordering Facility");
      }
      if (
        v?.orderingdetails_pgqom === undefined ||
        v?.orderingdetails_pgqom === null ||
        v?.orderingdetails_pgqom?.length === 0
      ) {
        error.push("Location Type");
      }
    });

    let chars = error;
    let uniqueChars = [...new Set(chars)];

    if (error?.length > 0) {
      return this.props.alert.setSnack({
        open: true,
        msg: `Input fields - ${uniqueChars.join(", ")} are is required`,
        severity: "error",
      });
      return;
    }

    if (!this.state.editId?.length > 0) {
      return this.props.alert.setSnack({
        open: true,
        msg: `Service applicability is required`,
        severity: "error",
      });
    }

    const data__ = this.state.Orderingdetails?.map((v) => {
      return {
        facility: v?.orderingdetails_fqnvb?._id ?? "",
        locationType: v?.orderingdetails_pgqom?._id ?? "",
      };
    });

    const data = await this.props.drugUpsert({
      data: data__ || [],
      LocationMasterId: this.state._id ?? "",
      editRule: this.state.editId ?? [],
    });

    this.props.alert.setSnack({
      open: true,
      msg: `Order routing added successfully!.`,
      severity: "success",
    });
    this.props.history.push("/pharmacy");
  };

  onload_3yrav = async (event, data) => {
    let index = data;
    const payload0 = {
      history: this.props.history.location.state,
      data: data,
    };
    let data0 =
      await this.props.addnewpharmacypharmacy_entitytype_dropdown_3YRAV(
        payload0
      );
    const payload1 = {
      history: this.props.history.location.state,
      data: data,
    };
    let data1 =
      await this.props.addnewpharmacypharmacy_entityname_dropdown_3YRAV(
        payload1
      );
    const payload2 = {
      history: this.props.history.location.state,
      data: data,
    };
    let data2 =
      await this.props.addnewpharmacypharmacy_locationtype_dropdown_3YRAV(
        payload2
      );
    const payload3 = {
      history: this.props.history.location.state,
      data: data,
    };
    let data3 =
      await this.props.addnewpharmacypharmacy_operationstatus_dropdown_3YRAV(
        payload3
      );
    const payload4 = {
      history: this.props.history.location.state,
      data: data,
    };
    let data4 =
      await this.props.addnewpharmacypharmacy_parentlocationid_description_dropdown_3YRAV(
        payload4
      );
    const payload5 = {
      history: this.props.history.location.state,
      data: data,
    };
    let data5 =
      await this.props.addnewpharmacypharmacy_locationroletype_dropdown_3YRAV(
        payload5
      );
    const payload6 = {
      history: this.props.history.location.state,
      data: data,
    };
    let data6 =
      await this.props.addnewpharmacypharmacy_inventrysubtype_dropdown_3YRAV(
        payload6
      );

    let updatestate = {};
    this.setState({
      ...updatestate,
      addnewpharmacy_3yrav: event?.target?.value,
    });
  };
  onchange_ku5mp = (e, index) => {
    let updatestate = { addnewpharmacy_bkahp: e?.display };

    this.setState({ ...updatestate, addnewpharmacy_ku5mp: e });
  };
  onchange_flkww = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, addnewpharmacy_flkww: e });
  };
  onchange_wsjxk = (e, index) => {
    let updatestate = { addnewpharmacy_vkw8j: e?.display };

    this.setState({ ...updatestate, addnewpharmacy_wsjxk: e });
  };

  pasteHandle = (e) => {
    let filteranswer = this.removeSymbols(e.clipboardData.getData("text"));
    let updatestate = {};
    this.setState({
      ...updatestate,
      filtervalue: filteranswer,
    });
  };
  removeSymbols = (name) => {
    return name
      .substring(0, 10)
      .split("")
      .filter((item) => allowedChar.includes(item))
      .join("")
      .toUpperCase();
  };
  onchange_2955j = (e, index) => {
    // let value = this.pasteHandle(e)
    let updatestate = {};
    if (this.state.filtervalue) {
      let value = this.removeSymbols(e.target.value);
      this.setState({
        ...updatestate,
        addnewpharmacy_2955j: value,
        filtervalue: "",
      });
    } else {
      if (e.target.value.length <= 10)
        this.setState({
          ...updatestate,
          addnewpharmacy_2955j: e.target.value
            .replace(/[^a-zA-Z0-9]/gi, "")
            ?.toUpperCase(),
        });
    }

    // if (e.target.value.length <= 10) {
    //   let updatestate = {};
    //   if (this.state.filtervalue) {
    //     let value = this.state.filtervalue;
    //     this.setState({
    //       ...updatestate,
    //       addnewpharmacy_2955j: value,
    //       filtervalue: "",
    //     });
    //   } else {
    //     this.setState({
    //       ...updatestate,
    //       addnewpharmacy_2955j: e.target.value
    //         .replace(/[^a-zA-Z0-9]/gi, "")
    //         ?.toUpperCase(),
    //     });
    //   }
    // }
  };
  onchange_gyzj6 = (e, index) => {
    if (e.target.value.length <= 30) {
      let updatestate = {};
      this.setState({ ...updatestate, addnewpharmacy_gyzj6: e?.target?.value });
    }
  };
  onchange_jepfz = (e, index) => {
    if (e.target.value.length <= 100) {
      let updatestate = {};
      this.setState({ ...updatestate, addnewpharmacy_jepfz: e?.target?.value });
    }
  };
  onchange_guw19 = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, addnewpharmacy_guw19: e });
  };
  onchange_ekyrq = (e, index) => {
    let updatestate = { addnewpharmacy_cpf6d: e?.locationID };

    this.setState({ ...updatestate, addnewpharmacy_ekyrq: e });
  };
  onchange_m9tu8 = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, addnewpharmacy_m9tu8: e });
  };
  onchange_mrpjg = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, addnewpharmacy_mrpjg: e });
  };
  onchange_bkahp = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, addnewpharmacy_bkahp: e?.target?.value });
  };
  onchange_vkw8j = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, addnewpharmacy_vkw8j: e?.target?.value });
  };
  onchange_8mjav = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, addnewpharmacy_8mjav: e?.target?.value });
  };

  errValid = () => {
    var error = [];

    let ValidateEmail = (email) => {
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return Boolean(re.test(email));
    };
    // if(!this.state.url) {
    //   error.push("Upload Image")
    // }

    if (!this.state.addnewpharmacy_2955j) {
      error.push("Pharmacy ID");
    }

    if (!this.state.addnewpharmacy_gyzj6) {
      error.push("Short Description");
    }
    if (!this.state.addnewpharmacy_jepfz) {
      error.push("Long Description");
    }
    if (!this.state.addnewpharmacy_guw19?.display) {
      error.push("Operational Status");
    }
    if (!this.state.addnewpharmacy_m9tu8?.display) {
      error.push("Location Role Type");
    }

    // if (!this.state.url) {
    //   error.push("Upload Image");
    // }

    // if (!this.state.GeoLocationPharmacy?.[0]?.geo_location_pharmacy_nxmup) {
    //   error.push("Longitude");
    // }
    if (!this.state.WorkingDays?.length > 0) {
      error.push("Working Days");
    }
    // if (!this.state.OverRide?.length > 0) {
    //   error.push("Over Ride");
    // }
    if (!this.state.AddressPharmacy?.[0]?.address_pharmacy__gdjrv) {
      error.push("Location address");
    }

    if (this.state.Contactpoint?.length === 0) {
      error.push("Contact Point");
    }
    if (this.state.Contactpoint?.length > 0) {
      const _ = this.state.Contactpoint?.map((v) => {
        if (Object.keys(v).length === 0) {
          error.push("Contact Mode");
        }

        if (!v.contactpoint_lcygy?.display) {
          error.push("Contact Point" + " " + "Use");
        }

        if (!v.contactpoint_ktsnd?.display) {
          error.push("Contact Point" + " " + "Priority");
        }

        if (!v?.contactpoint_cbukw?.display || !v?.contactpoint_ovtat < 0) {
          error.push("Contact Mode" + " " + "all required fields");
        } else {
          if (
            (v.contactpoint_cbukw?.display?.toLowerCase() === "phone" ||
              v.contactpoint_cbukw?.display?.toLowerCase() === "mobile") &&
            (!v.contactpoint_ovtat || v.contactpoint_ovtat?.length !== 10)
          ) {
            error.push(
              "Contact Point" +
                " " +
                v.contactpoint_cbukw?.display?.toLowerCase()
            );
          }
        }

        if (
          v.contactpoint_cbukw?.display?.toLowerCase() === "email" &&
          !ValidateEmail(v.contactpoint_ovtat)
        ) {
          error.push(
            "Contact Point" + " " + v.contactpoint_cbukw?.display?.toLowerCase()
          );
        }

        if (
          v.contactpoint_cbukw?.display === "Pager" &&
          (v.contactpoint_ovtat === undefined ||
            v.contactpoint_ovtat?.length === 0)
        ) {
          error.push(
            "Contact Point" + " " + v.contactpoint_cbukw?.display?.toLowerCase()
          );
        }

        if (
          v.contactpoint_cbukw?.display === "OTHER" &&
          (v.contactpoint_ovtat === undefined ||
            v.contactpoint_ovtat?.length === 0)
        ) {
          error.push(
            "Contact Point" + " " + v.contactpoint_cbukw?.display?.toLowerCase()
          );
        }

        if (
          v.contactpoint_cbukw?.display === "SMS" &&
          (v.contactpoint_ovtat === undefined ||
            v.contactpoint_ovtat?.length === 0)
        ) {
          error.push(
            "Contact Point" + " " + v.contactpoint_cbukw?.display?.toLowerCase()
          );
        }
        if (
          v.contactpoint_cbukw?.display === "FAX" &&
          (v.contactpoint_ovtat === undefined ||
            v.contactpoint_ovtat?.length === 0)
        ) {
          error.push(
            "Contact Point" + " " + v.contactpoint_cbukw?.display?.toLowerCase()
          );
        }
        if (
          v.contactpoint_cbukw?.display === "URL" &&
          (v.contactpoint_ovtat === undefined ||
            v.contactpoint_ovtat?.length === 0)
        ) {
          error.push(
            "Contact Point" + " " + v.contactpoint_cbukw?.display?.toLowerCase()
          );
        }
      });
    }

    let chars = error;

    let uniqueChars = [...new Set(chars)];

    return uniqueChars;
  };

  onclick_nduhj = async (event, data) => {
    let index = data;
    const payload0 = {
      photo: this.state.url
        ? [
            {
              url: this.state.url,
              date: "",
              id: 0,
              fileName: "",
              fileid: "",
              filetype: "",
              objectid: "",
            },
          ]
        : [],
      addnewpharmacy_status: this.state.status == true ? "active" : "inactive",
      parentLocationID_desc:
        this.state.addnewpharmacy_ekyrq?.locationID?._id ||
        this.state.addnewpharmacy_ekyrq?.locationID,
      // ? {
      //     locationID:
      //       this.state.addnewpharmacy_ekyrq?.locationID?._id ||
      //       this.state.addnewpharmacy_ekyrq?.locationID,
      //   }
      // : ""
      // this.state.addnewpharmacy_ekyrq?.locationID?._id ??
      // this.state.addnewpharmacy_ekyrq?.locationID,
      addnewpharmacy_operationalstatus:
        this.state.addnewpharmacy_guw19?._id?._id ??
        this.state.addnewpharmacy_guw19?._id,
      addnewpharmacy_shortdesc:
        this.state.addnewpharmacy_gyzj6?._id ?? this.state.addnewpharmacy_gyzj6,
      addnewpharmacy_longdesc:
        this.state.addnewpharmacy_jepfz?._id ?? this.state.addnewpharmacy_jepfz,
      telecom: (this.state?.Contactpoint ?? [])?.map((val) => {
        return {
          system:
            val.contactpoint_cbukw?._id ??
            (val.contactpoint_cbukw ? val.contactpoint_cbukw : val.system),
          value:
            val.contactpoint_ovtat?._id ??
            (val.contactpoint_ovtat ? val.contactpoint_ovtat : val.value),
          use:
            val.contactpoint_lcygy?._id ??
            (val.contactpoint_lcygy ? val.contactpoint_lcygy : val.use),
          rank:
            val.contactpoint_ktsnd?._id ??
            (val.contactpoint_ktsnd ? val.contactpoint_ktsnd : val.rank),
        };
      }),
      address: (this.state?.AddressPharmacy ?? [])?.map((val) => {
        return {
          text: val.address_pharmacy__gdjrv?._id
            ? val.address_pharmacy__gdjrv?._id
            : val.address_pharmacy__gdjrv
            ? val.address_pharmacy__gdjrv
            : val.text,
          use: "",
          line: "",
          city: "",
          district: "",
          state: "",
          postalCode: "",
          country: "",
          period: [
            {
              start: 0,
              end: 0,
              id: 0,
            },
          ],
          latitude: "",
          longitude: "",
          Type: "",
          id: 0,
        };
      }),
      addnewpharmacy_locationtype:
        this.state.addnewpharmacy_wsjxk?._id?._id ??
        this.state.addnewpharmacy_wsjxk?._id,
      position: (this.state?.GeoLocationPharmacy ?? [])?.map((val) => {
        return {
          longitude:
            val.geo_location_pharmacy_nxmup?._id ??
            (val.geo_location_pharmacy_nxmup
              ? val.geo_location_pharmacy_nxmup
              : val.longitude),
          latitude:
            val.geo_location_pharmacy_g98qf?._id ??
            (val.geo_location_pharmacy_g98qf
              ? val.geo_location_pharmacy_g98qf
              : val.latitude),
          altitude:
            val.geo_location_pharmacy_chvlg?._id ??
            (val.geo_location_pharmacy_chvlg
              ? val.geo_location_pharmacy_chvlg
              : val.altitude),
        };
      }),
      addnewpharmacy_managingorgid:
        this.state.addnewpharmacy_flkww?._id?._id ??
        this.state.addnewpharmacy_flkww?._id,
      addnewpharmacy_locationroletype:
        this.state.addnewpharmacy_m9tu8?._id?._id ??
        this.state.addnewpharmacy_m9tu8?._id,
      addnewpharmacy_locationid:
        this.state.addnewpharmacy_2955j?._id ?? this.state.addnewpharmacy_2955j,
      addnewpharmacy_parentlocationid_desc:
        this.state.addnewpharmacy_ekyrq?.locationID?._id ??
        this.state.addnewpharmacy_ekyrq?.locationID,
      addnewpharmacy_managingorgentitytype:
        this.state.addnewpharmacy_ku5mp?._id?._id ??
        this.state.addnewpharmacy_ku5mp?._id,
      pharmacy: [
        {
          inventorySubStore:
            this.state.addnewpharmacy_mrpjg?._id?._id ??
            this.state.addnewpharmacy_mrpjg?._id,
        },
      ],
      workingdayyear: (this.state?.WorkingDays ?? [])?.map((val) => {
        return val;
      }),
      workingdayyearoverride: (this.state?.OverRide ?? [])?.map((val) => {
        return val;
      }),
      history: this.props.history.location.state,
      data: data,
      locconfig: [
        {
          code: "allowbulkverification",
          value: this.state.allowbulkverification ? "true" : "false",
        },
        {
          code: "allowbulkfilling",
          value: this.state.allowbulkfilling ? "true" : "false",
        },
        {
          code: "considerlatearrival",
          value: this.state.considerlatearrival ? "true" : "false",
        },
      ],
      autocalculate: this.state.autocalculate,
    };

    if (this.errValid()?.length > 0) {
      return this.props.alert.setSnack({
        open: true,
        msg: `${this.errValid().join(", ")} are is required!.`,
        severity: "error",
      });
    }
    // return;
    if (this.state._key) {
      payload0.edit_id = this.state._key;
    }
    let data0 = await this.props.addnewpharmacypharmacy_insert_nDuhJ(payload0);

    // data0?.payload?.validation_error?.[0]?.Errormsg
    if (data0?.payload?.error) {
      if (
        data0?.payload?.validation_error?.[0]?.Errormsg ==
        '["locationID"] fields must be unique'
      ) {
        return this.props.alert.setSnack({
          open: true,
          msg: "Pharmacy Code Already Exists.",
          severity: "error",
        });
      } else {
        return this.props.alert.setSnack({
          open: true,
          msg: data0?.payload?.validation_error?.[0]?.Errormsg,
          severity: "error",
        });
      }
      return;
    }
    this.props.alert.setSnack({
      open: true,
      msg: `Pharmacy definition added successfully!.`,
      severity: "success",
    });
    this.setState({
      ...this.state,
      _id: data0.payload?.Result?.[0]?.properties?.doc?._id ?? "",
      _key: data0.payload?.Result?.[0]?.properties?.doc?._key ?? "",
    });

    let updatestate = { addnewpharmacy_8mjav: 2 };
    this.setState({
      ...updatestate,
      addnewpharmacy_nduhj: event?.target?.value,
    });
  };

  onclick_zqixu = (e, index) => {
    let updatestate = { addnewpharmacy_8mjav: 1 };

    this.setState({
      ...this.state,
      ...updatestate,
      addnewpharmacy_zqixu: e?.target?.value,
    });
  };
  onchange_cpf6d = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, addnewpharmacy_cpf6d: e?.target?.value });
  };
  async componentDidMount() {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(localStorage.defaultLng);
    }

    const data = await this.props.group_dropdown();
    this.setState({
      ...this.state,
      groupData: data.payload.result,
    });

    /// setlogger && dispatch of all events
    await this.onload_3yrav();
    // if (this.state._id) {
    //   const data = await this.props.sigleRead({
    //     LocationMasterId: this.state._id,
    //   });
    // }
    /// fetch all permission
  }

  ///fetching all permission

  shouldComponentUpdate(props, state) {
    if (
      this.props?.pharmacy_inventrysubtype_dropdownData?.loading !==
      props?.pharmacy_inventrysubtype_dropdownData?.loading
    ) {
      if (
        !props?.pharmacy_inventrysubtype_dropdownData?.loading &&
        props?.pharmacy_inventrysubtype_dropdownData.error
      ) {
      } else if (
        !props?.pharmacy_inventrysubtype_dropdownData?.loading &&
        !props?.pharmacy_inventrysubtype_dropdownData.error
      ) {
      }
    }

    if (
      this.props?.pharmacy_insertData?.loading !==
      props?.pharmacy_insertData?.loading
    ) {
      if (
        !props?.pharmacy_insertData?.loading &&
        props?.pharmacy_insertData.error
      ) {
      } else if (
        !props?.pharmacy_insertData?.loading &&
        !props?.pharmacy_insertData.error
      ) {
      }
    }

    return true;
  }

  handleChange = async (v, n) => {
    if (n === "group") {
      const data = await this.props.rule_dropdown({ groupId: v?.value });
      this.setState({
        ...this.state,
        ruleData: data.payload.result,
      });
    }
    this.setState({
      ...this.state,
      [n]: v,
    });
  };

  onclick_exist_save = (data) => {
    if (!this.state.group) {
      return this.props.alert.setSnack({
        open: true,
        msg: `Please choose the group`,
        severity: "error",
      });
    }
    if (!this.state.rule) {
      return this.props.alert.setSnack({
        open: true,
        msg: `Please choose the rule`,
        severity: "error",
      });
    }
    this.setState({
      ...this.state,
      editId: [
        {
          group: this.state.group,
          rule: this.state.rule,
        },
      ],
      isExist: false,
      isOpen: true,
      group: {},
      rule: {},
    });
  };

  setRuleBuilderAction = (data) => {
    if (!data) {
      this.setState({
        ...this.state,
        isRule: !this.state.isRule,
      });
      return;
    }
    this.setState({
      ...this.state,
      editId: [data?.Result?.[0]?.properties?.doc],
      isRule: !this.state.isRule,
      isOpen: true,
    });
  };

  onChangeSwitch = () => {
    this.setState({
      ...this.state,
      status: !this.state.status,
    });
  };

  render() {
    const {
      pharmacy_entitytype_dropdownData,
      pharmacy_entityname_dropdownData,
      pharmacy_locationtype_dropdownData,
      pharmacy_operationstatus_dropdownData,
      pharmacy_parentlocationid_description_dropdownData,
      pharmacy_locationroletype_dropdownData,
      pharmacy_inventrysubtype_dropdownData,
      pharmacy_insertData,
      data,
      index,
      classes,
    } = this.props;

    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <Grid
              key={"0"}
              id={"TLuB6"}
              container={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              style={qdmstyles.TLuB}
              className={classes.nav_back}
            >
              <Grid
                key={"0"}
                id={"gniOs"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                xs={"9"}
                xl={"9"}
                sm={"9"}
                md={"9"}
                lg={"9"}
                style={qdmstyles.gniOs}
                className={classes.stepperMain}
              >
                <Box key={"0"} id={"YSVkl"} m={1} component={"div"}>
                  <Avatar
                    key={"0"}
                    id={"o9Qdp"}
                    alt={"Avatar"}
                    variant={"rounded"}
                    src={
                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/319828003"
                    }
                    style={qdmstyles.oQdp}
                    onClick={() => this.props.history.goBack(-1)}
                  ></Avatar>
                </Box>
                <Box key={"1"} id={"VHu27"} m={1} component={"div"}>
                  <Typography
                    key={"0"}
                    id={"AJhKj"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={t("pharmacy.addNewDrugCatalogMaster.title")}
                    style={qdmstyles.AJhKj}
                  ></Typography>
                </Box>
                <Divider
                  key={"2"}
                  id={"ejKbU"}
                  variant={"fullWidth"}
                  flexItem={true}
                  light={true}
                  orientation={"vertical"}
                  style={qdmstyles.KfQi}
                ></Divider>
                <MuiStepper
                  key={"3"}
                  onChange={(e) => this.onchange_8mjav(e)}
                  id={"8MJAV"}
                  value={
                    this.state.addnewpharmacy_8mjav
                      ? this.state.addnewpharmacy_8mjav - 1
                      : 0
                  }
                  stepsHeader={[
                    {
                      header: "Pharmacy Definition",
                      fullWidth: true,
                      body: {},
                    },
                    {
                      header: "Order Routing",
                      fullWidth: true,
                      body: {},
                    },
                  ]}
                  bodyRequired={""}
                  stepperActiveTextColor={"#2A60BC"}
                  alternativeLabel={""}
                  isControlleRequired={""}
                  stepperActiveColor={"#2A60BC"}
                  HeaderFontColor={"#2A60BC"}
                  previousLabel={""}
                  aliasName={"targetscreen"}
                  style={qdmstyles.MJAV}
                ></MuiStepper>
              </Grid>
              <Grid
                key={"1"}
                id={"a0vdW"}
                container={true}
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                xs={"3"}
                xl={"3"}
                sm={"3"}
                md={"3"}
                lg={"3"}
                style={(qdmstyles.avdW, { padding: 0, paddingRight: "1%" })}
              >
                <Button
                  key={"0"}
                  id={"addnewpharmacy_cancel"}
                  children={"Cancel"}
                  aliasName={"AddNewPharmacy_Cancel"}
                  onClick={() => this.props.history.goBack(-1)}
                  style={(qdmstyles.dVSXO, { margin: 0 })}
                ></Button>
                {(this.state?.addnewpharmacy_8mjav ?? null) === 2 && (
                  <Button
                    key={"1"}
                    onClick={(e) => this.onclick_zqixu(e, index)}
                    id={"addnewpharmacy_previ"}
                    variant={"contained"}
                    className={classes.add}
                    children={"Previous"}
                    aliasName={"AddNewPharmacy_Previ"}
                    style={(qdmstyles.zqIXU, { padding: 6, fontSize: 12 })}
                  ></Button>
                )}{" "}
                &nbsp;
                {(this.state?.addnewpharmacy_8mjav ?? null) === 2 && (
                  <Button
                    key={"2"}
                    id={"addnewpharmacy_save"}
                    variant={"contained"}
                    color={"primary"}
                    children={"Save"}
                    aliasName={"AddNewPharmacy_Save"}
                    onClick={() => this.onclick_save()}
                    style={(qdmstyles.JDFgQ, { marginRight: 6 })}
                  ></Button>
                )}{" "}
                {((this.state?.addnewpharmacy_8mjav ?? null) === 1 ||
                  (this.state?.addnewpharmacy_8mjav ?? null) === null) && (
                  <Button
                    key={"3"}
                    onClick={(e) => this.onclick_nduhj(e, index)}
                    id={"addnewpharmacy_saveandpro"}
                    variant={"contained"}
                    color={"primary"}
                    children={"Save and Proceed"}
                    aliasName={"addNewPharmacy_SaveAndPro"}
                    style={(qdmstyles.nDuhJ, { marginRight: 4 })}
                    className={classes.add}
                  ></Button>
                )}{" "}
              </Grid>
            </Grid>
            <Paper
              key={"0"}
              id={"3YRAV"}
              elevation={"{3}"}
              // style={qdmstyles.YRAV}
              className={classes.rootBackground}
            >
              <Grid
                key={"0"}
                id={"K1CZU"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={""}
              >
                {((this.state?.addnewpharmacy_8mjav ?? null) === 1 ||
                  (this.state?.addnewpharmacy_8mjav ?? null) === null) && (
                  <Paper
                    key={"1"}
                    id={"jvpaZ"}
                    elevation={"{3}"}
                    // style={qdmstyles.jvpaZ}
                    className={classes.background}
                  >
                    <Grid
                      key={"0"}
                      id={"1h0J2"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      spacing={"3"}
                      style={qdmstyles.hJ}
                    >
                      <Grid
                        key={"0"}
                        id={"K2h8d"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"4"}
                        md={"4"}
                        sm={"4"}
                        xl={"4"}
                        xs={"4"}
                        item={true}
                        style={qdmstyles.Khd}
                      >
                        <Typography
                          key={"0"}
                          id={"entity_type_heading"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Entity Type"}
                          aliasName={"entitytypeheading"}
                          style={qdmstyles.qZqk}
                        ></Typography>
                        <Autocomplete
                          id={"ku5Mp"}
                          disabled
                          value={{
                            Type: "ORGTYPE",
                            _id: "CodingMaster/10010",
                            _key: "10010",
                            activestatus: true,
                            code: "OT003",
                            display: "Facility",
                            id: 10,
                            shortdesc: "Facility",
                            status: true,
                          }}
                          onLoad={() =>
                            this.setState({
                              addnewpharmacy_ku5mp:
                                this.state?.addnewpharmacy_ku5mp ?? null,
                            })
                          }
                          getOptionLabel={(option) => option?.["display"]}
                          options={
                            pharmacy_entitytype_dropdownData.result ?? []
                          }
                          size={"small"}
                          onChange={(e, newvalue) =>
                            this.onchange_ku5mp(newvalue)
                          }
                          renderInput={(params) => (
                            <ALLMCORE.TextField
                              {...params}
                              margin="dense"
                              variant="outlined"
                              style={{
                                background: "#DEE5EC",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"k54b4"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"4"}
                        xl={"4"}
                        xs={"4"}
                        md={"4"}
                        sm={"4"}
                        item={true}
                        style={qdmstyles.kb}
                      >
                        <Typography
                          key={"0"}
                          id={"entity_name_heading"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Entity Name"}
                          aliasName={"entitynameheading"}
                          style={qdmstyles.WhIZW}
                        ></Typography>
                        <Autocomplete
                          id={"FLkWw"}
                          style={qdmstyles?.FLkWw}
                          onLoad={() =>
                            this.setState({
                              addnewpharmacy_flkww:
                                this.state?.addnewpharmacy_flkww ?? null,
                            })
                          }
                          getOptionLabel={(option) => option?.["name"]}
                          options={
                            pharmacy_entityname_dropdownData.result ?? []
                          }
                          size={"small"}
                          onChange={(e, newvalue) =>
                            this.onchange_flkww(newvalue)
                          }
                          classes={{
                            paper: classes.paper,
                          }}
                          className={classes.autocomplete}
                          value={this.state?.addnewpharmacy_flkww ?? {}}
                          renderInput={(params) => (
                            <ALLMCORE.TextField
                              {...params}
                              margin="dense"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>

                      <Grid lg={"4"} xl={"4"} xs={"4"} md={"4"} sm={"4"}>
                        <Typography style={qdmstyles?.statustext}>
                          Status
                        </Typography>
                        <div style={qdmstyles?.statusswitch}>
                          <Switch
                            key={"0"}
                            id={"switch_input"}
                            // checked={
                            //   checked
                            //   // data?.status === "active" ? true : false
                            // }
                            checked={this.state.status}
                            color={"primary"}
                            name={"switch"}
                            aliasName={"switchIcon"}
                            onChange={() => {
                              this.onChangeSwitch(data);
                            }}
                          ></Switch>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                )}{" "}
              </Grid>
              <Grid
                key={"1"}
                id={"Cx5VL"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={""}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                style={qdmstyles.CxVL}
              >
                <Grid
                  key={"0"}
                  id={"Fk9dp"}
                  container={true}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"stretch"}
                  lg={"12"}
                  spacing={""}
                >
                  <Grid
                    key={"0"}
                    id={"0t7fY"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    lg={"2"}
                    item={""}
                    md={"2"}
                    sm={"2"}
                    xl={"2"}
                    xs={"2"}
                    style={qdmstyles.tfY}
                  >
                    {((this.state?.addnewpharmacy_8mjav ?? null) === 1 ||
                      (this.state?.addnewpharmacy_8mjav ?? null) === null) && (
                      <Paper
                        key={"0"}
                        id={"4RYIR"}
                        elevation={"{3}"}
                        style={qdmstyles.RYIR}
                        className={classes.backgroundImage}
                      >
                        <Grid
                          key={"0"}
                          id={"S7ETM"}
                          container={""}
                          direction={"column"}
                          justifyContent={"space-evenly"}
                          alignItems={"center"}
                          lg={"12"}
                          style={{ position: "relative", height: "78%" }}
                        >
                          <label style={{ width: "100%" }}>
                            <div className="cls">
                              <Avatar
                                key={"0"}
                                id={"uploaded_image"}
                                alt={"Avatar"}
                                variant={"rounded"}
                                src={this.state?.url}
                                aliasName={"uploadedimage"}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                                // style={qdmstyles.HeEg}
                              ></Avatar>
                            </div>
                          </label>
                        </Grid>
                        <Divider
                          key={"1"}
                          id={"Cf9i7"}
                          variant={"fullWidth"}
                          flexItem={""}
                          orientation={"horizontal"}
                          style={qdmstyles.Cfi}
                        ></Divider>
                        <Button
                          key={"2"}
                          id={"upload_button"}
                          variant={"contained"}
                          color={"primary"}
                          children={
                            <span>
                              <input
                                type="file"
                                id="test"
                                onChange={(e) =>
                                  this.handleChangePhotoFileInput(e)
                                }
                              />
                              Upload Image
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </span>
                          }
                          fullWidth={true}
                          disableElevation={true}
                          aliasName={"uploadbutton"}
                          style={qdmstyles.iQQUz}
                          className={classes.add}
                        ></Button>
                      </Paper>
                    )}{" "}
                  </Grid>
                  {/* {((this.state?.addnewpharmacy_8mjav ?? null) === 1 ||
                    (this.state?.addnewpharmacy_8mjav ?? null) === null) && (
                    <Grid
                      key={"1"}
                      id={"5t3uK"}
                      container={""}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"flex-start"}
                      lg={"10"}
                      item={true}
                      md={"10"}
                      sm={"10"}
                      xl={"10"}
                      xs={"10"}
                    >
                      <Paper
                        key={"0"}
                        id={"rUOW3"}
                        elevation={"{3}"}
                        style={qdmstyles.rUOW}
                      >
                        <Grid
                          key={"0"}
                          id={"lAvjf"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography
                            key={"0"}
                            id={"location_details_heading"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"LOCATION DETAILS"}
                            aliasName={"locationdetailsheading"}
                            style={qdmstyles.Wyql}
                          ></Typography>
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"qUoh9"}
                          container={true}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                        >
                          <Grid
                            key={"0"}
                            id={"bo2uU"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            spacing={"2"}
                          >
                            <Grid
                              key={"0"}
                              id={"2XDvA"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"12"}
                              style={(qdmstyles.XDvA, { position: "relative" })}
                            >
                              <input
                                type={"file"}
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  height: "100%",
                                  zIndex: 1,
                                  // display: "none",
                                }}
                                onChange={(e) =>
                                  this.handleChangePhotoFileInput(e)
                                }
                              />
                              <Avatar
                                key={"0"}
                                id={"uploaded_image"}
                                alt={"Avatar"}
                                variant={"rounded"}
                                src={this.state?.url}
                                aliasName={"uploadedimage"}
                                style={qdmstyles.HeEg}
                              ></Avatar>
                            </Grid>
                            <Divider
                              key={"1"}
                              id={"Cf9i7"}
                              variant={"fullWidth"}
                              flexItem={""}
                              orientation={"horizontal"}
                              style={qdmstyles.Cfi}
                            ></Divider>
                            <Button
                              key={"2"}
                              id={"upload_button"}
                              variant={"contained"}
                              color={"primary"}
                              children={"Upload Image"}
                              fullWidth={true}
                              disableElevation={true}
                              aliasName={"uploadbutton"}
                              style={qdmstyles.iQQUz}
                            ></Button>
                          </Grid>
                        </Paper>
                      )}{" "}
                  </Grid> */}
                  {((this.state?.addnewpharmacy_8mjav ?? null) === 1 ||
                    (this.state?.addnewpharmacy_8mjav ?? null) === null) && (
                    <Grid
                      key={"1"}
                      id={"5t3uK"}
                      container={""}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"flex-start"}
                      lg={"10"}
                      item={true}
                      md={"10"}
                      sm={"10"}
                      xl={"10"}
                      xs={"10"}
                    >
                      <Paper
                        key={"0"}
                        id={"rUOW3"}
                        elevation={"{3}"}
                        // style={qdmstyles.rUOW}
                        className={classes.locationDetails}
                      >
                        <Grid
                          key={"0"}
                          id={"lAvjf"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography
                            key={"0"}
                            id={"location_details_heading"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={t(
                              "pharmacy.addNewDrugCatalogMaster.location"
                            )}
                            aliasName={"locationdetailsheading"}
                            style={qdmstyles.Wyql}
                          ></Typography>
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"qUoh9"}
                          container={true}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                        >
                          <Grid
                            key={"0"}
                            id={"bo2uU"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            spacing={"2"}
                          >
                            <Grid
                              key={"0"}
                              id={"Ng6mk"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              lg={"4"}
                              item={true}
                              md={"4"}
                              sm={"4"}
                              xl={"4"}
                              xs={"4"}
                            >
                              <Typography
                                key={"0"}
                                id={"location_type_heading"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={"Location Type"}
                                aliasName={"locationtypeheading"}
                                style={qdmstyles.JVhlW}
                              ></Typography>
                              <Autocomplete
                                id={"wsJxK"}
                                // style={qdmstyles?.wsJxK}
                                disabled
                                value={{
                                  Type: "LOCATIONTYPE",
                                  _id: "CodingMaster/10447",
                                  _key: "10447",
                                  activestatus: true,
                                  code: "LT006",
                                  display: "Pharmacy Dispense Location",
                                  id: 447,
                                  shortdesc: "Pharmacy Dispense Location",
                                  status: true,
                                }}
                                onLoad={() =>
                                  this.setState({
                                    addnewpharmacy_wsjxk:
                                      this.state?.addnewpharmacy_wsjxk ?? null,
                                  })
                                }
                                getOptionLabel={(option) => option?.["display"]}
                                options={
                                  pharmacy_locationtype_dropdownData.result ??
                                  []
                                }
                                classes={{
                                  paper: classes.paper,
                                }}
                                className={classes.autocomplete}
                                size={"small"}
                                onChange={(e, newvalue) =>
                                  this.onchange_wsjxk(newvalue)
                                }
                                renderInput={(params) => (
                                  <ALLMCORE.TextField
                                    {...params}
                                    margin="dense"
                                    variant="outlined"
                                    style={{
                                      background: "#DEE5EC",
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              key={"1"}
                              id={"Ng6mk"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              lg={"2"}
                              item={true}
                              md={"2"}
                              sm={"2"}
                              xl={"2"}
                              xs={"2"}
                            >
                              <Typography
                                key={"0"}
                                id={"pharmacy_id_heading"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={
                                  <span>
                                    Pharmacy ID{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </span>
                                }
                                aliasName={"pharmacyidheading"}
                                style={qdmstyles.PBOuj}
                              ></Typography>
                              <TextField
                                key={"1"}
                                value={this.state?.addnewpharmacy_2955j ?? null}
                                onPaste={(e) => this.pasteHandle(e)}
                                onChange={(e) => this.onchange_2955j(e)}
                                id={"pharmacy_id_textfield"}
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline,
                                  },
                                }}
                                label={""}
                                placeholder={"Pharmacy ID"}
                                type={"text"}
                                variant={"outlined"}
                                fullWidth={true}
                                size={"small"}
                                aliasName={"pharmacyidtextfield"}
                                margin={"dense"}
                                onKeyPress={(e) => {
                                  var k = e.keyCode || e.which;
                                  if (
                                    (k > 64 && k < 91) ||
                                    (k > 96 && k < 123) ||
                                    k == 8 ||
                                    k == 32 ||
                                    (k >= 48 && k <= 57)
                                  ) {
                                    return;
                                  } else {
                                    e.preventDefault();
                                    return;
                                  }
                                }}
                              ></TextField>
                            </Grid>
                            <Grid
                              key={"2"}
                              id={"Ng6mk"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              lg={"3"}
                              item={true}
                              md={"3"}
                              sm={"3"}
                              xl={"3"}
                              xs={"3"}
                            >
                              <Typography
                                key={"0"}
                                id={"short_description_heading"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={
                                  <span>
                                    Short Description
                                    <span style={{ color: "red" }}>*</span>
                                  </span>
                                }
                                aliasName={"shortdescriptionheading"}
                                style={qdmstyles.pwUa}
                              ></Typography>
                              <TextField
                                key={"1"}
                                value={this.state?.addnewpharmacy_gyzj6 ?? null}
                                onChange={(e) => this.onchange_gyzj6(e)}
                                id={"short_description_textfield"}
                                label={""}
                                placeholder={"Short Description"}
                                type={"text"}
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline,
                                  },
                                }}
                                variant={"outlined"}
                                fullWidth={true}
                                size={"small"}
                                aliasName={"shortdescriptiontextfield"}
                                margin={"dense"}
                              ></TextField>
                            </Grid>
                            <Grid
                              key={"3"}
                              id={"Ng6mk"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              lg={"3"}
                              item={true}
                              md={"3"}
                              sm={"3"}
                              xl={"3"}
                              xs={"3"}
                            >
                              <Typography
                                key={"0"}
                                id={"long_description_heading"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={
                                  <span>
                                    Long Description
                                    <span style={{ color: "red" }}>*</span>
                                  </span>
                                }
                                aliasName={"longdescriptionheading"}
                                style={qdmstyles.Lrra}
                              ></Typography>
                              <TextField
                                key={"1"}
                                value={this.state?.addnewpharmacy_jepfz ?? null}
                                onChange={(e) => this.onchange_jepfz(e)}
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline,
                                  },
                                }}
                                id={"long_description_textfield"}
                                label={""}
                                placeholder={"Long Description"}
                                type={"text"}
                                variant={"outlined"}
                                fullWidth={true}
                                size={"small"}
                                aliasName={"longdescriptiontextfield"}
                                margin={"dense"}
                              ></TextField>
                            </Grid>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"bo2uU"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            spacing={"2"}
                          >
                            <Grid
                              key={"0"}
                              id={"Ng6mk"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              lg={"3"}
                              item={true}
                              md={"3"}
                              sm={"3"}
                              xl={"3"}
                              xs={"3"}
                            >
                              <Typography
                                key={"0"}
                                id={"operation_status_heading"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={
                                  <span>
                                    Operational Status
                                    <span style={{ color: "red" }}>*</span>
                                  </span>
                                }
                                aliasName={"operationstatusheading"}
                                style={qdmstyles.lTH}
                              ></Typography>
                              <Autocomplete
                                id={"gUW19"}
                                style={qdmstyles?.gUW}
                                value={this.state?.addnewpharmacy_guw19 ?? null}
                                onLoad={() =>
                                  this.setState({
                                    addnewpharmacy_guw19:
                                      this.state?.addnewpharmacy_guw19 ?? null,
                                  })
                                }
                                getOptionLabel={(option) => option?.["display"]}
                                options={
                                  pharmacy_operationstatus_dropdownData.result ??
                                  []
                                }
                                classes={{
                                  paper: classes.paper,
                                }}
                                className={classes.autocomplete}
                                size={"small"}
                                onChange={(e, newvalue) =>
                                  this.onchange_guw19(newvalue)
                                }
                                renderInput={(params) => (
                                  <ALLMCORE.TextField
                                    {...params}
                                    margin="dense"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              key={"1"}
                              id={"Ng6mk"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              lg={"3"}
                              item={true}
                              md={"3"}
                              sm={"3"}
                              xl={"3"}
                              xs={"3"}
                            >
                              <Typography
                                key={"0"}
                                id={
                                  "parent_location_id_and_description_heading"
                                }
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={"Parent Location ID And Description"}
                                aliasName={
                                  "parentlocationidanddescriptionheading"
                                }
                                style={qdmstyles.xPry}
                              ></Typography>
                              <Autocomplete
                                id={"EKyrQ"}
                                style={qdmstyles?.EKyrQ}
                                onLoad={() =>
                                  this.setState({
                                    addnewpharmacy_ekyrq:
                                      this.state?.addnewpharmacy_ekyrq ?? null,
                                  })
                                }
                                value={this.state?.addnewpharmacy_ekyrq ?? {}}
                                getOptionLabel={(option) =>
                                  option?.["locationID"]
                                }
                                options={
                                  pharmacy_parentlocationid_description_dropdownData.result ??
                                  []
                                }
                                classes={{
                                  paper: classes.paper,
                                }}
                                className={classes.autocomplete}
                                size={"small"}
                                onChange={(e, newvalue) =>
                                  this.onchange_ekyrq(newvalue)
                                }
                                renderInput={(params) => (
                                  <ALLMCORE.TextField
                                    {...params}
                                    label={""}
                                    variant="outlined"
                                    margin="dense"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              key={"2"}
                              id={"Ng6mk"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              lg={"2"}
                              item={true}
                              md={"2"}
                              sm={"2"}
                              xl={"2"}
                              xs={"2"}
                            >
                              <Typography
                                key={"0"}
                                id={"location_role_type"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={
                                  <span>
                                    Location Role Type
                                    <span style={{ color: "red" }}>*</span>
                                  </span>
                                }
                                aliasName={"locationroletype"}
                                style={qdmstyles.XFa}
                              ></Typography>
                              <Autocomplete
                                id={"m9Tu8"}
                                style={qdmstyles?.mTu}
                                value={this.state?.addnewpharmacy_m9tu8 ?? null}
                                onLoad={() =>
                                  this.setState({
                                    addnewpharmacy_m9tu8:
                                      this.state?.addnewpharmacy_m9tu8 ?? null,
                                  })
                                }
                                getOptionLabel={(option) => option?.["display"]}
                                options={
                                  pharmacy_locationroletype_dropdownData.result ??
                                  []
                                }
                                classes={{
                                  paper: classes.paper,
                                }}
                                className={classes.autocomplete}
                                size={"small"}
                                onChange={(e, newvalue) =>
                                  this.onchange_m9tu8(newvalue)
                                }
                                renderInput={(params) => (
                                  <ALLMCORE.TextField
                                    {...params}
                                    label={""}
                                    variant="outlined"
                                    margin="dense"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              key={"3"}
                              id={"Ng6mk"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              lg={"2"}
                              item={true}
                              xl={"1"}
                              xs={"1"}
                              sm={"1"}
                              md={"1"}
                            >
                              <Typography
                                key={"0"}
                                id={"inventory_sub_store_header"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={"Inventory Sub Store"}
                                aliasName={"inventorysubstoreheader"}
                                style={qdmstyles.YeOZh}
                              ></Typography>
                              <Autocomplete
                                id={"MRpjg"}
                                style={qdmstyles?.MRpjg}
                                onLoad={() =>
                                  this.setState({
                                    addnewpharmacy_mrpjg:
                                      this.state?.addnewpharmacy_mrpjg ?? null,
                                  })
                                }
                                value={this.state?.addnewpharmacy_mrpjg ?? {}}
                                getOptionLabel={(option) =>
                                  option?.["locationID"]
                                }
                                options={
                                  pharmacy_inventrysubtype_dropdownData.result ??
                                  []
                                }
                                classes={{
                                  paper: classes.paper,
                                }}
                                className={classes.autocomplete}
                                size={"small"}
                                onChange={(e, newvalue) =>
                                  this.onchange_mrpjg(newvalue)
                                }
                                renderInput={(params) => (
                                  <ALLMCORE.TextField
                                    {...params}
                                    label={""}
                                    margin="dense"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              key={"3"}
                              id={"Ng6mk"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              lg={"1"}
                              item={true}
                              xl={"1"}
                              xs={"1"}
                              sm={"1"}
                              md={"1"}
                            >
                              <Typography
                                key={"0"}
                                id={"inventory_sub_store_header"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={<>Auto&nbsp;Calculate</>}
                                aliasName={"inventorysubstoreheader"}
                                style={qdmstyles.YeOZh}
                              ></Typography>
                              <Checkbox
                                id={"Dispensable_Checkbox"}
                                color={"primary"}
                                size={"small"}
                                aliasName={"AutoCalculate"}
                                checked={this.state.autocalculate}
                                onChange={(event) =>
                                  this.changeState(
                                    "autocalculate",
                                    event.target.checked
                                  )
                                }
                                inputProps={{ "aria-label": "Auto Calculate" }}
                              ></Checkbox>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Grid
                        key={"1"}
                        id={"tXjdA"}
                        container={true}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      ></Grid>
                    </Grid>
                  )}{" "}
                </Grid>
              </Grid>
              {((this.state?.addnewpharmacy_8mjav ?? null) === 1 ||
                (this.state?.addnewpharmacy_8mjav ?? null) === null) && (
                <Grid
                  key={"2"}
                  id={"ZH1rk"}
                  container={""}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  // style={qdmstyles.ZHrk}
                  className={classes.contact}
                >
                  <Grid
                    key={"0"}
                    id={"YrJKf"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                  ></Grid>
                  <Grid
                    key={"1"}
                    id={"tuuzI"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ComponentRender
                      key={"0"}
                      id={"2NoBK"}
                      component={
                        <ALL.Contactpoint
                          propsState={this.state}
                          setFun={(value) => {
                            this.changeState("Contactpoint", value);
                          }}
                          stateName={"Contactpoint"}
                          statevalue={this.state?.Contactpoint}
                          required={true}
                        />
                      }
                    >
                      <Paper key={"0"} id={"fYos9"} elevation={"{3}"}></Paper>
                      <Paper key={"1"} id={"roOdY"} elevation={"{3}"}></Paper>
                      <Paper key={"2"} id={"D2AS9"} elevation={"{3}"}></Paper>
                      <Grid
                        key={"3"}
                        id={"GLNgW"}
                        container={true}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      ></Grid>
                    </ComponentRender>
                  </Grid>
                </Grid>
              )}{" "}
              <Grid
                key={"3"}
                id={"TFjok"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"flex-start"}
                lg={"12"}
                spacing={""}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                style={qdmstyles.TFjok}
              >
                <Grid
                  key={"0"}
                  id={"OpvdP"}
                  container={""}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"6"}
                  md={"6"}
                  sm={"6"}
                  xl={"6"}
                  xs={"6"}
                  item={true}
                  style={qdmstyles.OpvdP}
                >
                  {((this.state?.addnewpharmacy_8mjav ?? null) === 1 ||
                    (this.state?.addnewpharmacy_8mjav ?? null) === null) && (
                    <ComponentRender
                      key={"0"}
                      id={"y7iZZ"}
                      component={
                        <ALL.AddressPharmacy
                          propsState={this.state}
                          setFun={(value) => {
                            this.changeState("AddressPharmacy", value);
                          }}
                          stateName={"AddressPharmacy"}
                          statevalue={this.state?.AddressPharmacy}
                          required={true}
                        />
                      }
                    ></ComponentRender>
                  )}{" "}
                </Grid>
                <Grid
                  key={"1"}
                  id={"viG1z"}
                  container={""}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  xl={"6"}
                  xs={"6"}
                  item={true}
                  lg={"6"}
                  md={"6"}
                  sm={"6"}
                  style={qdmstyles.viGz}
                >
                  {((this.state?.addnewpharmacy_8mjav ?? null) === 1 ||
                    (this.state?.addnewpharmacy_8mjav ?? null) === null) && (
                    <ComponentRender
                      key={"0"}
                      id={"c1BcH"}
                      component={
                        <ALL.GeoLocationPharmacy
                          propsState={this.state}
                          setFun={(value) => {
                            this.changeState("GeoLocationPharmacy", value);
                          }}
                          stateName={"GeoLocationPharmacy"}
                          statevalue={this.state?.GeoLocationPharmacy}
                        />
                      }
                    ></ComponentRender>
                  )}{" "}
                </Grid>
              </Grid>
              <Grid
                key={"4"}
                id={"G2KNv"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                style={qdmstyles.GKNv}
              >
                {((this.state?.addnewpharmacy_8mjav ?? null) === 1 ||
                  (this.state?.addnewpharmacy_8mjav ?? null) === null) && (
                  <ComponentRender
                    key={"0"}
                    id={"HUfCr"}
                    component={
                      <ALL.WorkingDays
                        propsState={this.state}
                        WorkingDays={this.state?.WorkingDays ?? []}
                        setFun={(value) => {
                          this.changeState("WorkingDays", value);
                        }}
                        stateName={"WorkingDays"}
                        editData={this.state.editData}
                        statevalue={this.state?.WorkingDays ?? []}
                        handleworkdayedits={this.handleworkdayedit}
                        handleworkdaydelete={this.handleworkdaydelete}
                        close={() =>
                          this.setState({
                            ...this.state,
                            index: null,
                          })
                        }
                      />
                    }
                  ></ComponentRender>
                )}{" "}
              </Grid>
              {/* {console.log(
                 moment
                  .unix(this.state?.WorkingDays?.[0]?.effToDate)
                  .format("DD-MM-YYYY") > moment().format("DD-MM-YYYY")
              )} */}
              <Grid
                key={"5"}
                id={"A0PXe"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                xl={"12"}
                xs={"12"}
                sm={"12"}
                md={"12"}
                lg={"12"}
                style={qdmstyles.APXe}
              >
                {((this.state?.addnewpharmacy_8mjav ?? null) === 1 ||
                  (this.state?.addnewpharmacy_8mjav ?? null) === null) && (
                  <ComponentRender
                    key={"0"}
                    id={"TvwqT"}
                    component={
                      <ALL.OverRide
                        propsState={this.state}
                        setFun={(value) => {
                          this.changeState("OverRide", value);
                        }}
                        stateName={"OverRide"}
                        statevalue={this.state?.OverRide ?? []}
                        editData={this.state.editDataOverRide}
                        WorkingDays={this.state?.WorkingDays ?? []}
                        isOverRise={true}
                        close={() =>
                          this.setState({
                            ...this.state,
                            index_ride: null,
                          })
                        }
                        handleworkdayeditoverride={
                          this.handleworkdayeditoverride
                        }
                        handleworkdaydelete={this.handleworkdaydeleteoverride}
                      />
                    }
                  ></ComponentRender>
                )}{" "}
              </Grid>
              <Grid
                key={"10"}
                id={"A0PXefv"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                xl={"12"}
                xs={"12"}
                sm={"12"}
                md={"12"}
                lg={"12"}
                style={qdmstyles.APXe}
              >
                {((this.state?.addnewpharmacy_8mjav ?? null) === 1 ||
                  (this.state?.addnewpharmacy_8mjav ?? null) === null) && (
                  <ALL.PharmacyLocationConfig
                    allowbulkverification={this.state.allowbulkverification}
                    allowbulkfilling={this.state.allowbulkfilling}
                    considerlatearrival={this.state.considerlatearrival}
                    changeState={(name, value) => this.changeState(name, value)}
                  />
                )}
              </Grid>
              <Grid
                key={"6"}
                id={"DxVxk"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                style={qdmstyles.DxVxk}
              >
                {(this.state?.addnewpharmacy_8mjav ?? null) === 2 && (
                  <Paper
                    key={"0"}
                    id={"z4yMs"}
                    elevation={"{3}"}
                    style={qdmstyles.zyMs}
                    className={classes.background}
                  >
                    <Grid
                      key={"0"}
                      id={"sLa09"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      spacing={"2"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                    >
                      <Grid
                        key={"0"}
                        id={"OuUFv"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                      >
                        <Typography
                          key={"0"}
                          id={"performing_details_header"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={t(
                            "pharmacy.addNewDrugCatalogMaster.performingDetails"
                          )}
                          aliasName={"performingdetailsheader"}
                          style={qdmstyles.atsL}
                        ></Typography>
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"V7v9F"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"4"}
                        md={"4"}
                        sm={"4"}
                        xl={"4"}
                        xs={"4"}
                        item={true}
                      >
                        <Typography
                          key={"0"}
                          id={"performing_facility_header"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Performing Facility"}
                          aliasName={"performingfacilityheader"}
                          style={qdmstyles.pasHi}
                        ></Typography>
                        <TextField
                          key={"1"}
                          value={
                            this.state?.addnewpharmacy_bkahp ||
                            this.state.addnewpharmacy_flkww?.name ||
                            null
                          }
                          onChange={(e) => this.onchange_bkahp(e)}
                          id={"performing_facility_value"}
                          InputProps={{
                            classes: { notchedOutline: classes.notchedOutline },
                          }}
                          label={""}
                          placeholder={"AINQA Hospital Chennai"}
                          type={"text"}
                          fullWidth={true}
                          variant={"outlined"}
                          size={"small"}
                          aliasName={"performingfacilityvalue"}
                          margin={"dense"}
                          disabled={true}
                          style={qdmstyles.BKahp}
                        ></TextField>
                      </Grid>
                      <Grid
                        key={"2"}
                        id={"kAtjH"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"4"}
                        sm={"4"}
                        md={"4"}
                        xl={"4"}
                        xs={"4"}
                        item={true}
                      >
                        <Typography
                          key={"0"}
                          id={"performing_location_type_header"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Performing Location Type"}
                          aliasName={"performinglocationtypeheader"}
                          style={qdmstyles.eiRM}
                        ></Typography>
                        <TextField
                          key={"1"}
                          value={this.state?.addnewpharmacy_vkw8j ?? null}
                          onChange={(e) => this.onchange_vkw8j(e)}
                          id={"performing_location_type_value"}
                          label={"Pharmacy"}
                          placeholder={"Pharmacy"}
                          InputProps={{
                            classes: { notchedOutline: classes.notchedOutline },
                          }}
                          type={"text"}
                          variant={"outlined"}
                          size={"small"}
                          fullWidth={true}
                          aliasName={"performinglocationtypevalue"}
                          margin={"dense"}
                          disabled={true}
                          style={qdmstyles.vKWJ}
                        ></TextField>
                      </Grid>
                      <Grid
                        key={"3"}
                        id={"571Nr"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"4"}
                        sm={"4"}
                        md={"4"}
                        xl={"4"}
                        xs={"4"}
                        item={true}
                      >
                        <Typography
                          key={"0"}
                          id={"performaing_location_header"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Performing Location"}
                          aliasName={"performainglocationheader"}
                          style={qdmstyles.RAMoZ}
                        ></Typography>
                        <TextField
                          key={"1"}
                          value={this.state?.addnewpharmacy_cpf6d ?? null}
                          onChange={(e) => this.onchange_cpf6d(e)}
                          id={"performing_location_value"}
                          InputProps={{
                            classes: { notchedOutline: classes.notchedOutline },
                          }}
                          label={""}
                          placeholder={"AINQA Hospital Chennai"}
                          type={"text"}
                          variant={"outlined"}
                          size={"small"}
                          fullWidth={true}
                          aliasName={"performinglocationvalue"}
                          margin={"dense"}
                          disabled={true}
                          style={qdmstyles.CpFd}
                        ></TextField>
                      </Grid>
                    </Grid>
                  </Paper>
                )}{" "}
              </Grid>
              <Grid
                key={"7"}
                id={"LyRof"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                style={qdmstyles.LyRof}
              >
                {(this.state?.addnewpharmacy_8mjav ?? null) === 2 && (
                  <Paper
                    key={"0"}
                    id={"R5XSx"}
                    elevation={"{3}"}
                    style={qdmstyles.RXSx}
                    className={classes.background}
                  >
                    {this.state.isOpen ? (
                      <div
                        style={{
                          padding: "0px 30px 8px 0px",
                          borderBottom: "1px solid lightgray",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {" "}
                              <div>
                                <Avatar
                                  key={"0"}
                                  id={"group_icon"}
                                  alt={"Avatar"}
                                  variant={"square"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317031738"
                                  }
                                  aliasName={"groupicon"}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    background: "red",
                                    padding: "7px",
                                    borderRadius: "50%",
                                    marginRight: "4px",
                                    backgroundColor: "#f1f6fc",
                                  }}
                                ></Avatar>
                              </div>
                              <div>
                                SERVICE APPLICABILITY <br />
                                Medication
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={5}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <Avatar
                                  key={"0"}
                                  id={"group_icon"}
                                  alt={"Avatar"}
                                  variant={"square"}
                                  src={
                                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317006791"
                                  }
                                  aliasName={"groupicon"}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    background: "red",
                                    padding: "7px",
                                    borderRadius: "50%",
                                    marginRight: "4px",
                                    backgroundColor: "#f1f6fc",
                                  }}
                                ></Avatar>
                              </div>
                              <div>
                                <label>MEDICATION SELECTION CRITERIA</label>
                                <br />
                                {this.state.editId?.[0]?.ruleName ||
                                  this.state.editId?.[0]?.group?.label ||
                                  ""}
                                <br />
                                {this.state.editId?.[0]?.priority ||
                                  this.state.editId?.[0]?.rule?.label ||
                                  ""}
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={1}>
                            <Button
                              variant="contained"
                              className={classes.add}
                              onClick={(e) => {
                                this.setState({
                                  ...this.state,
                                  isOpen: !this.state.isOpen,
                                });
                              }}
                            >
                              Remove
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    ) : (
                      <>
                        <Grid
                          key={"0"}
                          id={"piC1g"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                        >
                          <Typography
                            key={"0"}
                            id={"service_applicability_header"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={
                              <span>
                                SERVICE APPLICABILITY&nbsp;
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            }
                            aliasName={"serviceapplicabilityheader"}
                            style={qdmstyles.VbHbd}
                          ></Typography>
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"rSZ4i"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          style={qdmstyles.rSZi}
                        >
                          <Grid
                            key={"0"}
                            id={"iddKh"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                          >
                            <Button
                              key={"0"}
                              id={"ljrOp"}
                              variant={"contained"}
                              color={"primary"}
                              children={"Service Order"}
                              disabled={true}
                              size={"medium"}
                              style={qdmstyles.ljrOp}
                            ></Button>
                            <Button
                              key={"1"}
                              id={"medication_button"}
                              variant={"contained"}
                              className={classes.add}
                              children={"Medication"}
                              size={"medium"}
                              aliasName={"medicationbutton"}
                              style={qdmstyles.GrAWh}
                            ></Button>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"3uqqL"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            style={qdmstyles.uqqL}
                          >
                            <Typography
                              key={"0"}
                              id={"medication_delection_criteria_header"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"MEDICATION SELECTION CRITERIA"}
                              aliasName={"medicationdelectioncriteriaheader"}
                              style={qdmstyles.jtzl}
                            ></Typography>
                            {this.state.isExist && (
                              <Grid
                                id={"i1kt4"}
                                container={true}
                                direction={"row"}
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                                style={qdmstyles.ikt}
                                spacing={2}
                              >
                                <Grid
                                  key={"1"}
                                  id={"V7v9F"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  lg={"4"}
                                  md={"4"}
                                  sm={"4"}
                                  xl={"4"}
                                  xs={"4"}
                                  item={true}
                                >
                                  <Autocomplete
                                    value={this.state?.group ?? {}}
                                    getOptionLabel={(option) =>
                                      option?.["label"]
                                    }
                                    options={this.state?.groupData ?? []}
                                    size={"small"}
                                    onChange={(e, newvalue) =>
                                      this.handleChange(newvalue, "group")
                                    }
                                    renderInput={(params) => (
                                      <ALLMCORE.TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        label="Group"
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid
                                  key={"1"}
                                  id={"V7v9F"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  lg={"4"}
                                  md={"4"}
                                  sm={"4"}
                                  xl={"4"}
                                  xs={"4"}
                                  item={true}
                                >
                                   <Grid  lg={"12"}
                                                md={"12"}
                                                sm={"12"}
                                                xl={"12"}
                                                xs={"12"}
                                                item={true}>
                                  <Autocomplete
                                    value={this.state?.rule ?? {}}
                                    getOptionLabel={(option) =>
                                      option?.["label"]
                                    }
                                    options={this.state?.ruleData ?? []}
                                    size={"small"}
                                    onChange={(e, newvalue) =>
                                      this.handleChange(newvalue, "rule")
                                    }
                                    renderInput={(params) => (
                                      <ALLMCORE.TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        label="Rule"
                                      />
                                    )}
                                  />
                                </Grid>
                                </Grid>
                                <Button
                                  key={"1"}
                                  variant={"contained"}
                                  color={"primary"}
                                  children={"Save"}
                                  onClick={() => this.onclick_exist_save()}
                                  style={(qdmstyles.JDFgQ, { marginRight: 6 })}
                                ></Button>
                                &nbsp;
                                <Button
                                  key={"2"}
                                  variant={"contained"}
                                  children={"Cancel"}
                                  onClick={(e) => {
                                    this.setState({
                                      ...this.state,
                                      isExist: !this.state.isExist,
                                    });
                                  }}
                                  style={qdmstyles.kFjW}
                                ></Button>
                              </Grid>
                            )}
                            {!this.state.isExist && (
                              <Grid
                                key={"1"}
                                id={"i1kt4"}
                                container={true}
                                direction={"row"}
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                                style={qdmstyles.ikt}
                              >
                                <Button
                                  key={"2"}
                                  id={"add_new_rule_set_button"}
                                  variant={"contained"}
                                  className={classes.add}
                                  children={"Add New Rule Set"}
                                  aliasName={"addnewrulesetbutton"}
                                  style={qdmstyles.kFjW}
                                  onClick={(e) => {
                                    this.setState({
                                      ...this.state,
                                      isRule: !this.state.isRule,
                                    });
                                  }}
                                ></Button>
                                <Typography
                                  key={"1"}
                                  id={"(or)_label"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={"(or)"}
                                  aliasName={"(or)label"}
                                  style={qdmstyles.pfdc}
                                ></Typography>
                                <Button
                                  key={"0"}
                                  id={"add_existing_rule_set_button"}
                                  variant={"contained"}
                                  className={classes.add}
                                  children={"Add Existing Rule Set"}
                                  aliasName={"addexistingrulesetbutton"}
                                  style={qdmstyles.NrrIA}
                                  onClick={(e) => {
                                    this.setState({
                                      ...this.state,
                                      isExist: !this.state.isExist,
                                    });
                                  }}
                                ></Button>
                              </Grid>
                            )}
                            <CreateRule
                              // editId={this.state.editId?.[0]?._id}
                              // editSchema={this.state.editId?.[0]}
                              dbName={"primarycare"}
                              open={this.state.isRule}
                              handleClose={(params, e) =>
                                this.setRuleBuilderAction(
                                  params ? params : false
                                )
                              }
                            />
                          </Grid>
                        </Grid>{" "}
                      </>
                    )}
                    <Grid
                      key={"2"}
                      id={"JF6E0"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      style={qdmstyles.JFE}
                    >
                      <Grid
                        key={"0"}
                        id={"7a5rV"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      ></Grid>
                      <Grid
                        key={"1"}
                        id={"KcLtB"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        item={true}
                        style={qdmstyles.KcLtB}
                        className={classes.background}
                      >
                        <ComponentRender
                          key={"0"}
                          id={"HS7fP"}
                          component={
                            <ALL.Orderingdetails
                              propsState={this.state}
                              setFun={(value) => {
                                this.changeState("Orderingdetails", value);
                              }}
                              stateName={"Orderingdetails"}
                              statevalue={this.state?.Orderingdetails ?? []}
                            />
                          }
                          style={qdmstyles.HSfP}
                        ></ComponentRender>
                      </Grid>
                    </Grid>
                  </Paper>
                )}{" "}
              </Grid>
            </Paper>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}
const mapStateToProps = (state) => ({
  pharmacy_entitytype_dropdownData:
    state?.addnewpharmacypharmacy_entitytype_dropdown_3YRAVReducer
      ?.pharmacy_entitytype_dropdown_3YRAV?.data,
  pharmacy_entityname_dropdownData:
    state?.addnewpharmacypharmacy_entityname_dropdown_3YRAVReducer
      ?.pharmacy_entityname_dropdown_3YRAV?.data,
  pharmacy_locationtype_dropdownData:
    state?.addnewpharmacypharmacy_locationtype_dropdown_3YRAVReducer
      ?.pharmacy_locationtype_dropdown_3YRAV?.data,
  pharmacy_operationstatus_dropdownData:
    state?.addnewpharmacypharmacy_operationstatus_dropdown_3YRAVReducer
      ?.pharmacy_operationstatus_dropdown_3YRAV?.data,
  pharmacy_parentlocationid_description_dropdownData:
    state
      ?.addnewpharmacypharmacy_parentlocationid_description_dropdown_3YRAVReducer
      ?.pharmacy_parentlocationid_description_dropdown_3YRAV?.data,
  pharmacy_locationroletype_dropdownData:
    state?.addnewpharmacypharmacy_locationroletype_dropdown_3YRAVReducer
      ?.pharmacy_locationroletype_dropdown_3YRAV?.data,
  pharmacy_inventrysubtype_dropdownData:
    state?.addnewpharmacypharmacy_inventrysubtype_dropdown_3YRAVReducer
      ?.pharmacy_inventrysubtype_dropdown_3YRAV?.data,
  pharmacy_insertData:
    state?.addnewpharmacypharmacy_insert_nDuhJReducer?.pharmacy_insert_nDuhJ
      ?.data,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAllContexts(translate()(withStyles(styles)(Addnewpharmacy)))));
