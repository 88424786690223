import React from "react";
import { CommonTable, GenerateForm } from "../../../../../components";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { checkError } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";

class OrderCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditFrom: false,
      categories: [],
      edit_data: {
        category: "",
        shortdesc: "",
        longdesc: "",
        key: "",
      },
      // saveloading: false,
      // edit_data: null
    };
    // this.saveForm = this.saveForm.bind(this)
    // this.handleEdit = this.handleEdit.bind(this)
    // this.handleCheckBox = this.handleCheckBox.bind(this)
  }

  initFetch = async () => {
    await this.props.GET_ALL_ORDER_CATEGORY();
    this.updateCategories(this.props?.getAllOrderCategory?.data || []);
  };

  updateCategories = (cat, cb = () => {}) => {
    this.setState(
      {
        ...this.state,
        categories: cat,
      },
      () => {
        cb();
      }
    );
  };

  componentDidMount() {
    this.initFetch();
  }

  handleOpenForm = (val) => {
    const { changeState } = this.props;
    changeState("openIndex", 0);
    this.setState({
      openEditFrom: true,
      edit_data: { ...val },
    });
  };

  handleCloseForm = () => {
    const { changeState } = this.props;
    changeState("open", false);
    this.setState({
      openEditFrom: false,
      edit_data: {
        category: "",
        shortdesc: "",
        longdesc: "",
        key: "",
      },
      // edit_data: null
    });
  };

  saveFn = async (val) => {
    let { category, shortdesc, longdesc, status = true } = val;
    const input = {
      category,
      shortdesc,
      longdesc,
      status,
      id: 0,
      isUpdate: false,
      key: "",
    };
    if (this.state.edit_data.key) {
      input.id = this.state.edit_data.id;
      input.status = this.state.edit_data.status;
      input.key = this.state.edit_data.key;
      input.isUpdate = true;
    }
    const upsertStatus = await this.props.UPSERT_ORDER_CATEGORY(input);
    // const { isError, errMsg } = checkError(upsertStatus.payload);
    if (upsertStatus?.payload?.data?.category === undefined) {
      this.props.alert.setSnack("unique");
      this.setState({
        openEditFrom: true,
        //   edit_data: ,
      });
    } else {
      const categories = JSON.parse(JSON.stringify(this.state.categories));
      if (input.isUpdate) {
        let cat = categories.find((c) => c.key === input.key);
        if (cat) {
          const { category, shortdesc, longdesc, status } =
            upsertStatus?.payload?.data;
          cat.category = category;
          cat.shortdesc = shortdesc;
          cat.longdesc = longdesc;
          cat.status = status;
        }
      } else {
        categories.push(upsertStatus?.payload?.data);
      }
      let type = this.state?.edit_data?.key ? "update" : "success";
      this.props.alert.setSnack(type);
      this.updateCategories(categories, this.handleCloseForm);
    }
  };

  render() {
    const { open, openIndex, parent_id } = this.props;
    return (
      <React.Fragment>
        {this.props?.getAllOrderCategory?.loading ? (
          <div
            id={`${parent_id}-parent-div`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "66vh",
            }}
          >
            <CircularProgress id={`${parent_id}-CircularProgress`} />
          </div>
        ) : (
          <>
            <CommonTable
              style={{ height: "calc(100vh - 245px)" }}
              parent_id={"ordercategory"}
              // handleEdit={this.handleEdit}
              Header={[
                "S.No",
                "Order Category Code",
                "Short Description",
                "Long Description",
                "Status",
                "Action",
              ]}
              dataList={this.state.categories}
              tableData={[
                { type: ["INCRIMENT"], name: "" },
                { type: ["TEXT"], name: "category" },
                { type: ["TEXT"], name: "shortdesc" },
                { type: ["TEXT"], name: "longdesc" },
                { type: ["CHECKBOX"], name: "status" },
                { type: ["EDIT"], name: "" },
              ]}
              handleCheckBox={async (e, val) => {
                const updatedStatus =
                  await this.props.UPDATE_ORDER_CATEGORY_STATUS({
                    key: val.key,
                    status: !val.status,
                  });
                this.props.alert.setSnack("update");
                const { isError, errMsg } = checkError(updatedStatus.payload);
                if (isError) {
                  this.props.alert.setSnack("error");
                } else {
                  // const categories = JSON.parse(
                  //   JSON.stringify(this.state.categories)
                  // );
                  // const cat = categories.find((c) => c.key === val.key);
                  // if (cat) {
                  //   cat.status = !val.status;
                  // }
                  // this.updateCategories(categories);
                  this.initFetch();
                }
              }}
              handleEdit={(e, val) => {
                this.handleOpenForm(val);
              }}
            />
            <GenerateForm
              parent_id={"ordercategory"}
              nocancel
              open={(open || this.state.openEditFrom) && openIndex === 0}
              handlecancel={() => this.handleCloseForm()}
              header={
                this.state?.edit_data?.id
                  ? "Edit Order Category"
                  : "Add New Order Category"
              }
              json={[
                {
                  componet_type: "text",
                  label: "Order Category Code",
                  state_name: "category",
                  value: this.state?.edit_data?.category ?? "",
                  required: true,
                  isUpperCase: true,
                  textLength: 10,
                  disabled: this.state?.edit_data?.category ? true : false,
                },
                {
                  componet_type: "text",
                  label: "Short Description",
                  state_name: "shortdesc",
                  value: this.state?.edit_data?.shortdesc ?? "",
                  required: true,
                },
                {
                  componet_type: "text",
                  label: "Long Description",
                  state_name: "longdesc",
                  value: this.state?.edit_data?.longdesc ?? "",
                  required: true,
                },
              ]}
              loading={this.props.upsertOrderCategory?.loading}
              save={(val) => this.saveFn(val)}
            />
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  getAllOrderCategory: state?.orderMasterSlice?.getAllOrderCategory,
  upsertOrderCategory: state?.orderMasterSlice?.upsertOrderCategory,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(OrderCategory));
