import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";
import moment from "moment";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Box, Paper, Grid, Typography, Button } from "@material-ui/core";
import {
  CusWorkingDays,
  // CusWorkingDaysModal,
} from "custom-material-ui-component";

import { WorkingDaysModalComp } from "../../components";

import qdmstyles from "./styles.json";
import "./index.css";
import Color from "../../themes/default.json"
import makeStyles from "@material-ui/core/styles/makeStyles";

var aes256 = require("aes256");


const useStyles = makeStyles((theme) => ({
  childtitle: {
    color: theme.palette.primary.light
  },
  override: {
    color: theme.palette.text.primary,
  },
  overrideBackground:{
    backgroundColor:theme.palette.background.table,
    padding: '10px',
    borderRadius: '8px',
    marginTop: '10px'
  }
}));

function OverRide(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
    override_k4y5j: null,
    override_wviy0: null,
    override_liula: null,
    override_x705c: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };
  const [isopne, setisopne] = React.useState(false);

  const handleworkdayedit = (a, b, c) => {
    if (location?.state?.isdisabled === "inactive") {
      return;
    }

    setState({
      ...state,
      override_k4y5j: true,
    });
    props.handleworkdayeditoverride(a, b, c);
  };
  const statecreation_k4y5j = (e, index) => {
    let updatestate = {};

    setState({ ...state, ...updatestate, override_k4y5j: e?.target?.value });
  };
  const onclick_wviy0 = (e, index) => {
    setisopne(true);
    let updatestate = { override_k4y5j: true };

    setState({ ...state, ...updatestate, override_wviy0: e?.target?.value });
  };
  const onclick_liula = (e, index) => {
    setisopne(false);
    let updatestate = { override_k4y5j: false };
    props.close();
    setState({ ...state, ...updatestate, override_liula: e?.target?.value });
  };

  const onclick_x705c = (e, index) => {
    setisopne(false);
    delete index.ovrDay;
    delete index.day;
    delete index.format;
    delete index.effFrmDate;
    delete index.effToDate;
    delete index.workDaysOfWeek;

    index.ovrfromdate = moment(index.ovrfromdate, "YYYY-MM-DD").unix();
    index.ovrtodate =
      index.overridetype === "Single Date"
        ? 0
        : moment(index.ovrtodate, "YYYY-MM-DD").unix();

    const data_ = index.daysOfWeek?.map((v) => {
      if (v.checked || index.overridetype === "Single Date") {
        if (index.overridetype === "Single Date") {
          return {
            operate_time: v.operate_time,
          };
        } else {
          return {
            ovrDay: v.checked ? v.ovrDay : "",
            operate_time: v.operate_time,
          };
        }
      }
    });
    const _ = data_.filter(function (element) {
      return element !== undefined;
    });
    index.daysOfWeek = _;

    let updatestate = { override_k4y5j: false };

    setState({ ...state, ...updatestate, override_x705c: e?.target?.value });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];
    stateValue.push(index);
    props.setFun(stateValue);
  };
  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  const day = (data) => {
    const res = data?.filter((v) => v.ovrDay);
    return res?.length > 6 ? true : false;
  };
  const currentDate = new Date();

  const checkedHrAll = (data) => {
    const res = data?.filter(
      (v) =>
        v?.operate_time?.[0]?.ovrOpeningtime ===
        currentDate.setHours(0, 0, 0, 0) &&
        v?.operate_time?.[v?.operate_time?.length - 1]?.ovrClosingtime ===
        currentDate.setHours(23, 59, 59, 999)
    );
    return res?.length > 6 ? true : false;
  };

  const checkedHr = (v) => {
    if (
      v?.operate_time?.[0]?.ovrOpeningtime ===
      currentDate.setHours(0, 0, 0, 0) &&
      v?.operate_time?.[v?.operate_time?.length - 1]?.ovrClosingtime ===
      currentDate.setHours(23, 59, 59, 999)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const { data, index } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Paper
            key={"0"}
            id={"MvscO"}
            elevation={"{3}"}
            style={qdmstyles.CiGnd}
            className={classes.overrideBackground}
          >
            <Grid
              key={"0"}
              id={"ZQmro"}
              container={true}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid
                key={"0"}
                id={"EdCJu"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                lg={"2"}
                md={"2"}
                sm={"2"}
                xl={"2"}
                xs={"2"}
                style={qdmstyles.Uuouo}
              >
                <Typography
                  key={"0"}
                  id={"over_ride_header"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  className={classes.override}
                  children={
                    <span>
                      {t("pharmacy.addNewDrugCatalogMaster.overRide")}{" "}
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </span>
                  }
                  aliasName={"overrideheader"}
                  style={qdmstyles.dSFK}
                ></Typography>
              </Grid>
              <Grid
                key={"1"}
                id={"D5vNZ"}
                container={true}
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                lg={"2"}
                md={"2"}
                sm={"2"}
                xl={"2"}
                xs={"2"}
                style={qdmstyles.LXBFY}
              >
                <Button
                  key={"0"}
                  onClick={(e) => onclick_wviy0(e, index)}
                  id={"add_over_ride_button"}
                  variant={"text"}
                  color={"primary"}
                  children={"+ Add Over Ride"}
                  aliasName={"addoverridebutton"}
                  style={qdmstyles.WViY}
                  disabled={
                    location.state?.isdisabled === "inactive" ? true : false
                  }
                ></Button>
              </Grid>
            </Grid>
            <Paper
              key={"1"}
              id={"4I0Km"}
              elevation={"{3}"}
              aliasName={"overirdemap"}
              style={qdmstyles.hQMuT}
              onLoad={(e) => props.setFun(props?.statevalue ?? [{}])}
            >
              {/* {(props?.statevalue ?? [{}])?.map((data, index) => {
                  return (
                    <React.Fragment>
                      <CusWorkingDays
                        key={"0"}
                        data={data}
                        id={"YlJUd"}
                        aliasName={"overridedisplaybox"}
                        effectiveData={"ovrfromdate"}
                        effectiveDataTo={"ovrtodate"}
                        workDaysOfWeek={"daysOfWeek"}
                        day={"ovrDay"}
                        operate_time={"operate_time"}
                        openingtime={"ovrOpeningtime"}
                        closingtime={"ovrClosingtime"}
                        // style={qdmstyles.YlJUd}
                      ></CusWorkingDays>
                    </React.Fragment>
                  );
                })} */}

              <CusWorkingDays
                key={"0"}
                data={props?.statevalue}
                id={"YlJUd"}
                aliasName={"overridedisplaybox"}
                effectiveData={"ovrfromdate"}
                effectiveDataTo={"ovrtodate"}
                workDaysOfWeek={"daysOfWeek"}
                day={"ovrDay"}
                operate_time={"operate_time"}
                openingtime={"ovrOpeningtime"}
                closingtime={"ovrClosingtime"}
                handleworkdayedit={handleworkdayedit}
                handleworkdaydelete={props.handleworkdaydelete}
              // style={qdmstyles.YlJUd}
              ></CusWorkingDays>
            </Paper>
            <Grid
              key={"2"}
              id={"Z70Sy"}
              container={true}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <WorkingDaysModalComp
                key={"0"}
                open={state.override_k4y5j}
                handleClose={onclick_liula}
                handleSave={onclick_x705c}
                stateCreation={(e) => statecreation_k4y5j(e, index)}
                id={"k4y5J"}
                aliasName={"override_working_days_modal"}
                isWorkingDay={false}
                effectiveData={"ovrfromdate"}
                effectiveDataTo={"ovrtodate"}
                workDaysOfWeek={"daysOfWeek"}
                operate_time={"operate_time"}
                day={"ovrDay"}
                openingtime={"ovrOpeningtime"}
                closingtime={"ovrClosingtime"}
                isRadio={true}
                WorkingDays={props.WorkingDays}
                OverRide={props.statevalue}
                isOverRise={props.isOverRise ?? false}
                editData={
                  !isopne
                    ? {
                      ...props?.editData,
                      ovrfromdate: moment
                        .unix(props?.editData?.ovrfromdate)
                        .format("YYYY-MM-DD"),
                      ovrtodate: moment
                        .unix(props?.editData?.ovrtodate)
                        .format("YYYY-MM-DD"),
                      ovrDay: day(props?.editData?.daysOfWeek),
                      format: checkedHrAll(props?.editData?.daysOfWeek),
                      daysOfWeek: props?.editData?.daysOfWeek?.map((v) => {
                        return {
                          ovrDay: v.ovrDay,
                          checked: v.ovrDay ? true : false,
                          checkedHr: checkedHr(v),
                          operate_time: v.operate_time,
                        };
                      }),
                    }
                    : null
                }
                isEdit={props?.isEdit || !isopne ? false : false}

              // style={qdmstyles.kyJ}
              ></WorkingDaysModalComp>
            </Grid>
            {/* <Grid
                key={"3"}
                onClick={(e) => onclick_liula(e, index)}
                id={"LIUlA"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                aliasName={"cancelevt"}
                style={qdmstyles.LIUlA}
              ></Grid>
              <Grid
                key={"4"}
                onClick={(e) => onclick_x705c(e, index)}
                id={"x705c"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                aliasName={"saveevt"}
                style={qdmstyles.xc}
              ></Grid> */}
          </Paper>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(OverRide));
