import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import {
  bindHover,
  bindPopover,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { StyledPopover } from "../commonComponents";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    background: `${theme.palette.background.table} !important`,
    // background: "#fff",
    borderRadius: 6,
    padding: 10,
    width: "100%",
    "& .item": {
      marginRight: 4,
    },
    "& img": {
      marginBottom: "-2px",
    },
    position: "relative",
  },
  len: {
    color: "#EC6A49",
    right: 7,
    position: "absolute",
  },
  dot: {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
  },
  hover: {
    padding: 10,
  },
}));

function Diagnosis(props) {
  const classes = useStyles(props);
  const more = usePopupState({
    variant: "popover",
    popupId: "directions",
  });
  const { icon, list, activeIndex } = props;
  const color = activeIndex?.color;
  const data =
    activeIndex?.value === "All"
      ? list
      : list?.filter((val) => val?.clinicalstatus === activeIndex?.value);

  return (
    <>
      {data?.[0]?.icdshortname ? (
        <div className={classes.root}>
          <img src={icon} alt="icon" />
          <Divider orientation="vertical" variant="middle" flexItem />
          {[data?.[0]]?.map((item, index) => {
            return (
              item?.icdshortname && (
                <Typography noWrap className="item">
                  {" "}
                  <label
                    className={classes.dot}
                    style={{ background: color ?? "" }}
                  ></label>{" "}
                  &nbsp;{item?.icdshortname}
                </Typography>
              )
            );
          })}
          &nbsp;
          {data?.length > 0 && (
            <span className={classes.len} {...bindHover(more)}>
              {"+" + data?.length}
            </span>
          )}
        </div>
      ) : (
        "No Diagnosis"
      )}

      <StyledPopover renderHoverPopover {...bindPopover(more)}>
        <div className={classes.hover}>
          {data?.map((item, index) => {
            return (
              item?.icdshortname && (
                <div className="item">
                  {" "}
                  <label
                    className={classes.dot}
                    style={{ background: color ?? "" }}
                  ></label>{" "}
                  &nbsp;{item?.icdshortname}
                </div>
              )
            );
          })}
        </div>
      </StyledPopover>
    </>
  );
}

export default Diagnosis;
