import React, { useState } from "react";
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  FormControlLabel,
  Checkbox,
  Typography,
  TextField,
} from "@material-ui/core";
import DetailsCard from "./detailsCard";
import { InputComp, SelectBox } from "../../../../../../components/common/smartForm/component";
import { actions } from "frequencyscreen_v_dbinder";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { validationFormEnbty } from "../../../../../../utils";
import { AlertContext } from "../../../../../../contexts";

const option = [
  {
    label: "Ram",
    value: "ram",
  },
];

// validation field keys json
const validationList = [
  {
    componet_type: "select",
    state_name: "encounterType",
  },
  {
    componet_type: "select",
    state_name: "gender",
  },
  {
    componet_type: "select",
    state_name: "ageRange",
  },
  {
    componet_type: "select",
    state_name: "agecategory",
  },
  {
    componet_type: "text",
    state_name: "freqvalue"
  },
  {
    componet_type: "select",
    state_name: "freqValSelect"
  },
  {
    componet_type: "text",
    state_name: "freqrate"
  },
  {
    componet_type: "select",
    state_name: "freqRatSelect"
  },
  {
    componet_type: "text",
    state_name: "freqdura"
  },
  {
    componet_type: "select",
    state_name: "freqDuraSelect"
  },
  {
    componet_type: "text",
    state_name: "freqmax"
  }
]

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
    borderRadius: "0px 0px 8px 8px",
    background:theme.palette.background.table,
    "& .App1-MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "none",
  },
  label: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  // formParent: {
  //   paddingRight: 10,
  //   paddingTop: 15,
  // },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  textfieldGrid: {
    marginTop: "4px",
  },

  SecondGrid: {
    marginTop: 15,
    padding: 10,
    border: "1px solid #E0E0E0",
    borderRadius: 8,
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
    marginBottom: 10,
  },
  maintitle: {
    marginTop: 10,
    fontFamily: "poppinsemibold",
    fontSize: "14px",
  },
}));

function FrequencyTab(props) {
  const classes = useStyles();
  const [collapse, setCollaplse] = useState(false);
  const alert = React.useContext(AlertContext);

  const [state, setState] = React.useState({
    encounterType: null,
    gender: null,
    ageRange: null,
    agecategory: [],
    freqvalue: "",
    freqValSelect: null,
    freqrate: "",
    freqRatSelect: null,
    freqdura: "",
    freqDuraSelect: null,
    freqmax: "",
    editIndex: null,
    _id: "",
  });

  let dispatch = useDispatch();
  const storeData = useSelector((state) => state?.orderCatalogSlice);

  // React.useEffect(() => {
  //   getMasterData();
  // }, []);

  // const getMasterData = async () => {
  //   await dispatch(actions.ORDER_CATALOG_MASTERS());
  // };

  const handleOpen = () => {
    setCollaplse(!collapse);
  };

  // forStateChange;
  const handleState = async (name, value) => {
    if (name === "ageRange" && value) {
      await dispatch(
        actions.AGE_RANGES_CATEGORY_CATALOG({
          ageRangeId: value?.value,
        })
      );
    }
    state[name] = value;
    setState({ ...state });
  };

  const handleEdit = async (data, i) => {
    let ageCategory;
    if (data._id) {
      let ageranges_category_catalog = await dispatch(
        actions.AGE_RANGES_CATEGORY_CATALOG({
          ageRangeId: data.ageRange?.value,
        })
      );

      let selected_category = data.agecategory.map((v) => {
        return v?.value;
      });
      
      ageCategory = ageranges_category_catalog.payload.data.filter(
        (sys) => selected_category.indexOf(sys.value) > -1
      );
    }

    setState({
      ...state,
      encounterType: data.encounterType,
      gender: data.gender,
      ageRange: data.ageRange,
      agecategory: data._id ? ageCategory : data.agecategory,
      freqvalue: data.freqvalue,
      freqValSelect: data.freqValSelect,
      freqrate: data.freqrate,
      freqRatSelect: data.freqRatSelect,
      freqdura: data.freqdura,
      freqDuraSelect: data.freqDuraSelect,
      freqmax: data.freqmax,
      _id: data._id,
      editIndex: i,
    });

    handleOpen();
  };
  const handleDelete = (i) => {
    let frequencyData = JSON.parse(JSON.stringify(frequency));
    frequencyData.splice(i, 1);
    handleFormState("frequency", frequencyData);
  };

  const AddFrequency = async() => {
    // validation function
    let { error, errorState } = await validationFormEnbty(validationList, state);

    if(error){
      alert.setSnack("mandatory", "Require at least one field");
      return false
    }

    let frequencyData = JSON.parse(JSON.stringify(frequency));
    if (state.editIndex || state.editIndex === 0) {
      frequencyData[state.editIndex] = state;
      // setState({
      //   encounterType: null,
      //   gender: null,
      //   ageRange: null,
      //   agecategory: [],
      //   freqvalue: "",
      //   freqValSelect: null,
      //   freqrate: "",
      //   freqRatSelect: null,
      //   freqdura: "",
      //   freqDuraSelect: null,
      //   freqmax: "",
      //   editIndex: null,
      // });
    } else {
      frequencyData.push(state);
    }
    setState({
      encounterType: null,
      gender: null,
      ageRange: null,
      agecategory: [],
      freqvalue: "",
      freqValSelect: null,
      freqrate: "",
      freqRatSelect: null,
      freqdura: "",
      freqDuraSelect: null,
      freqmax: "",
      editIndex: null,
      _id: "",
    });
    handleFormState("frequency", frequencyData);
    handleOpen();
  };
  const { handleFormState, Frequency_applicability, editIndex, frequency, parent_id } =
    props;
  return (
    <div id={`${parent_id}-parent-div`}>
      <Paper
        id={`${parent_id}-parent-paper`}
        className={classes.root}
        elevation={0}
      >
        <Grid id={`${parent_id}-parent-container`} container>
          <Grid id={`${parent_id}-parent-grid`} item xs={12}>
            <FormControlLabel
              id={`${parent_id}-FormControlLabel`}
              control={
                <Checkbox
                  id={`${parent_id}-applicability-Checkbox`}
                  color="primary"
                  size="small"
                  checked={Frequency_applicability}
                  onChange={(e) =>
                    handleFormState("Frequency_applicability", e.target.checked)
                  }
                />
              }
              classes={{ label: classes.label }}
              label="FREQUENCY APPLICABILITY"
            />
            <Button
              id={`${parent_id}-Add-New-button`}
              disabled={!Frequency_applicability}
              className={classes.addBtn}
              onClick={() => handleOpen()}
              color="primary"
            >
              + Add New
            </Button>
          </Grid>
        </Grid>

        <DetailsCard
          parent_id={"frequency"}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          detailsData={frequency}
        />

        {collapse && (
          <>
            <Typography
              id={`${parent_id}-Add-New-typography`}
              className={classes.maintitle}
              variant="h6"
            >
              ADD NEW
            </Typography>
            <Grid
              id={`${parent_id}-Details-grid`}
              className={classes.SecondGrid}
            >
              <Typography
                id={`${parent_id}-Details-typography`}
                className={classes.title}
                variant="h6"
              >
                Details
              </Typography>
              <Grid id={`${parent_id}-Encounter-grid`} container spacing={2}>
                <Grid md="3" lg="3" item>
                  <Typography
                    id={`${parent_id}-Encounter-label-typography`}
                    className={classes.formtitle}
                    variant="body1"
                  >
                    Encounter Type
                  </Typography>
                  <SelectBox
                    parent_id={"Encounter"}
                    list={
                      storeData?.order_catalog_masters?.data?.encounterType ??
                      []
                    }
                    placeholder={"Select"}
                    value={state?.encounterType}
                    onchange={(e, value) => handleState("encounterType", value)}
                  />
                </Grid>
                <Grid id={`${parent_id}-Gender-label-grid`} md="3" lg="3" item>
                  <Typography
                    id={`${parent_id}-Gender-label-typography`}
                    className={classes.formtitle}
                    variant="body1"
                  >
                    Gender
                  </Typography>
                  <SelectBox
                    parent_id={"Gender"}
                    //list={storeData.reference_ageranges.data ?? []}
                    list={storeData?.order_catalog_masters?.data?.gender ?? []}
                    placeholder={"Select"}
                    value={state?.gender}
                    onchange={(e, value) => handleState("gender", value)}
                  />
                </Grid>

                <Grid
                  id={`${parent_id}-Range-Group-label-grid`}
                  md="3"
                  lg="3"
                  item
                >
                  <Typography
                    id={`${parent_id}-Range-Group-label-typography`}
                    className={classes.formtitle}
                    variant="body1"
                  >
                    Age Range Group
                  </Typography>
                  <SelectBox
                    parent_id={"Gender"}
                    //list={storeData.reference_ageranges.data ?? []}
                    list={
                      storeData?.order_catalog_masters?.data?.ageGroup ?? []
                    }
                    placeholder={"Select"}
                    value={state?.ageRange}
                    onchange={(e, value) => handleState("ageRange", value)}
                  />
                </Grid>
                <Grid
                  id={`${parent_id}-Age-Category-label-grid`}
                  md="3"
                  lg="3"
                  item
                >
                  <Typography
                    id={`${parent_id}-Age-Category-label-typography`}
                    className={classes.formtitle}
                    variant="body1"
                  >
                    Age Category
                  </Typography>
                  <SelectBox
                    parent_id={"Age-Category"}
                    list={storeData?.ageranges_category_catalog?.data ?? []}
                    multi
                    placeholder={"Select"}
                    value={state?.agecategory}
                    onchange={(e, value) => handleState("agecategory", value)}
                  />
                </Grid>
                <Grid id={`${parent_id}-Frequency-label-grid`} item xs={3}>
                  <Typography
                    id={`${parent_id}-Frequency-label-typography`}
                    className={classes.formtitle}
                    variant="body1"
                  >
                    Frequency Value
                  </Typography>
                  <Grid
                    id={`${parent_id}-freqvalue-grid`}
                    container
                    spacing={2}
                  >
                    <Grid
                      id={`${parent_id}-freqvalue-text-grid`}
                      className={classes.textfieldGrid}
                      item
                      xs={4}
                    >
                      <TextField
                        id={`${parent_id}-freqvalue-textField`}
                        value={state?.freqvalue}
                        onChange={(e) =>
                          handleState("freqvalue", e.target.value)
                        }
                        fullWidth
                      
                        variant="outlined"
                        size="small"
                       
                      />
                    </Grid>
                    <Grid id={`${parent_id}-freqValSelect-grid`} item xs={8}>
                      <SelectBox
                        parent_id={"freqValSelect"}
                        list={
                          storeData?.order_catalog_masters?.data?.quantity ?? []
                        }
                        
                        placeholder={"Select"}
                        value={state?.freqValSelect}
                        onchange={(e, value) =>
                          handleState("freqValSelect", value)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid id={`${parent_id}-Frequency-grid`} item xs={3}>
                  <Typography className={classes.formtitle} variant="body1">
                    Frequency Rate
                  </Typography>
                  <Grid
                    id={`${parent_id}-freqrate-sub-grid`}
                    container
                    spacing={2}
                  >
                    <Grid
                      id={`${parent_id}-freqrate-sublevel-grid`}
                      className={classes.textfieldGrid}
                      item
                      xs={3}
                    >
                      {/* <TextField
                        id={`${parent_id}-freqrate-textField`}
                        value={state?.freqrate}
                        onChange={(e) =>
                          handleState("freqrate", e.target.value)
                        }
                        fullWidth
                        type="number"
                        variant="outlined"
                        size="small"
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                      /> */}
                      <InputComp
                        id={`${parent_id}-freqrate-textField`}
                        value={state?.freqrate}
                        onChange={(e) =>
                          handleState("freqrate", e.target.value)
                        }
                        InputProps={{
                          inputProps: {
                            min: 0,
                            maxlength: 3,
                          },
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Grid>
                    <Grid id={`${parent_id}-freqRatSelect-grid`} item xs={9}>
                      <SelectBox
                        list={
                          storeData?.order_catalog_masters?.data?.quantity ?? []
                        }
                        placeholder={"Select"}
                        value={state?.freqRatSelect}
                        onchange={(e, value) =>
                          handleState("freqRatSelect", value)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid id={`${parent_id}-Frequency-grid`} item xs={3}>
                  <Typography
                    id={`${parent_id}-Frequency-typography`}
                    className={classes.formtitle}
                    variant="body1"
                  >
                    Frequency Duration
                  </Typography>
                  <Grid
                    id={`${parent_id}-Frequency-freqdura-grid`}
                    container
                    spacing={2}
                  >
                    <Grid
                      id={`${parent_id}-Frequency-freqdura-grid`}
                      className={classes.textfieldGrid}
                      item
                      xs={3}
                    >
                      {/* <TextField
                        id={`${parent_id}-Frequency-freqdura-textField`}
                        value={state?.freqdura}
                        onChange={(e) =>
                          handleState("freqdura", e.target.value)
                        }
                        fullWidth
                        type="number"
                        variant="outlined"
                        size="small"
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                      /> */}
                      <InputComp
                        id={`${parent_id}-Frequency-freqdura-textField`}
                        value={state?.freqdura}
                        onChange={(e) =>
                          handleState("freqdura", e.target.value)
                        }
                        InputProps={{
                          inputProps: {
                            min: 0,
                            maxlength: 3,
                          },
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Grid>
                    <Grid id={`${parent_id}-freqDuraSelect-grid`} item xs={9}>
                      <SelectBox
                        parent_id={"freqDuraSelect"}
                        list={
                          storeData?.order_catalog_masters?.data?.quantity ?? []
                        }
                        placeholder={"Select"}
                        value={state?.freqDuraSelect}
                        onchange={(e, value) =>
                          handleState("freqDuraSelect", value)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid id={`${parent_id}-FrequencyMax-grid`} item xs={3}>
                  <Typography
                    id={`${parent_id}-FrequencyMax-typography`}
                    className={classes.formtitle}
                    variant="body1"
                  >
                    Frequency Max Duration
                  </Typography>
                  {/* <TextField
                    id={`${parent_id}-FrequencyMax-textField`}
                    value={state?.freqmax}
                    onChange={(e) => handleState("freqmax", e.target.value)}
                    fullWidth
                    type="number"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  /> */}
                  <InputComp
                    id={`${parent_id}-FrequencyMax-textField`}
                    value={state?.freqmax}
                    onChange={(e) => handleState("freqmax", e.target.value)}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        maxlength: 3,
                      },
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              id={`${parent_id}-buttons-grid`}
              style={{ textAlign: "center", marginTop: 20 }}
            >
              <Button
                id={`${parent_id}-Cancel-button`}
                onClick={() => handleOpen("cancel")}
                style={{ marginRight: "20px" }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                id={`${parent_id}-Update-ADD-button`}
                disabled={!Frequency_applicability}
                onClick={() => AddFrequency()}
                variant="contained"
                color="primary"
              >
                {state?.editIndex || state?.editIndex === 0 ? "Update" : "ADD"}
              </Button>
            </Grid>
          </>
        )}
      </Paper>
    </div>
  );
}

export default FrequencyTab;
