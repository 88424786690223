import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {
  InputBase,
  Autocomplete,
  CustomButton,
} from "../../../../../components";
import WeekDays from "./weekdays";
import {
  DateTimePicker,
  TimePicker,
} from "../../../../../components/common/smartForm/component";
import moment from "moment";
import update from "immutability-helper";
import {
  getFormatForDateTimePicker,
  getHourOptions,
} from "../../../../../utils";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: "16px 0px 14px 0px",
    backgroundColor: "rgba(222, 229, 236, 1)",
  },
  label: {
    fontSize: 12,

    whiteSpace: "nowrap",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  actionButtons: {
    display: "flex",
    gap: "12px",
    justifyContent: "flex-end",
    marginTop: "24px",
  },
}));

function TimingForm({
  timingState,
  options,
  onSaveClick,
  onCancelClick,
  ...props
}) {
  const classes = useStyles();

  // Component States
  const [state, setState] = useState({
    weekdays: [],
    cartstarttime: null,
    durationinhrs: [],
    clscutofftime: null,
    efffrdate: null,
    efftodate: null,
  });

  const handleStateChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const sortDays = (a, b) => {
    if (a?.code < b?.code) {
      return -1;
    }
    if (a?.code > b?.code) {
      return 1;
    }
    return 0;
  };

  const handleWeekdaysChange = (weekday, value) => {
    

    if (value && weekday?.label === "All Days") {
      setState((prevState) => ({
        ...prevState,
        weekdays: options?.weekdays,
      }));
    } else if (!value && weekday?.label === "All Days") {
      setState((prevState) => ({
        ...prevState,
        weekdays: []
      }));
    } else if (value) {
      setState((prevState) => {
        return {
          ...prevState,
          weekdays:
            Array.isArray(prevState?.weekdays) &&
              prevState?.weekdays?.length > 0
              ? [...prevState?.weekdays, weekday]?.sort(sortDays)
              : [weekday]?.sort(sortDays),
        };
      });

      const isFull = Array.isArray(state?.weekdays) &&
        state?.weekdays?.length > 0
        ? state?.weekdays?.sort(sortDays)?.length == 6
        : false;

      if (isFull) {
        setState((prevState) => ({
          ...prevState,
          weekdays: options?.weekdays,
        }));
      }

    } else {
      setState((prevState) => ({
        ...prevState,
        weekdays: prevState?.weekdays?.filter(
          (item) => ((item?._id !== weekday?._id) && (item?.label !== "All Days"))
        ),
      }));
    }
  };

  // Component Lifecycle
  useEffect(() => {
    setState({
      weekdays: timingState?.weekdays,
      cartstarttime: timingState?.cartstarttime,
      durationinhrs: timingState?.durationinhrs,
      clscutofftime: timingState?.clscutofftime,
      efffrdate: timingState?.efffrdate,
      efftodate: timingState?.efftodate,
    });
  }, [timingState]);

  return (
    <div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={2}>
            <div className={classes.fieldContainer}>
              <Typography className={classes.label}>Cart Start Time</Typography>
              <TimePicker
                required={true}
                label={""}
                placeholder={getFormatForDateTimePicker("time")}
                ampm={false}
                onChange={(value) => {
                  handleStateChange("cartstarttime", value);
                }}
                value={state?.cartstarttime ? state?.cartstarttime : null}
                dateFormat={"HH:mm"}
              // maxDate={new Date()}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <div className={classes.fieldContainer}>
              <Typography className={classes.label}>Duration In Hrs</Typography>
              <Autocomplete
                onLoad={() =>
                  handleStateChange("durationinhrs", state?.durationinhrs)
                }
                getOptionLabel={(option) => option?.["label"]}
                options={getHourOptions() ?? []}
                size={"small"}
                placeholder=""
                value={state?.durationinhrs ?? null}
                onChange={(event, newValue) =>
                  handleStateChange("durationinhrs", newValue)
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <div className={classes.fieldContainer}>
              <Typography className={classes.label}>
                Close Cut-Off Time
              </Typography>
              <TimePicker
                required={true}
                label={""}
                placeholder={getFormatForDateTimePicker("time")}
                ampm={false}
                onChange={(value) => {
                  handleStateChange("clscutofftime", value);
                }}
                value={state?.clscutofftime ? state?.clscutofftime : null}
                dateFormat={"HH:mm"}
              // maxDate={new Date()}
              />
            </div>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}>
            <div className={classes.fieldContainer}>
              <Typography className={classes.label}>
                Effective From <span style={{ color: "red" }}>*</span>
              </Typography>
              <DateTimePicker
                required={true}
                label={""}
                placeholder={getFormatForDateTimePicker("date")}
                // dateFormat={"dd-MM-yyyy"}
                onChange={(value) => {
                  handleStateChange("efffrdate", value);
                }}
                value={state?.efffrdate ? moment(state?.efffrdate) : null}
                // maxDate={state?.efftodate ?? null}
              />
            </div>
          </Grid> */}
          {/* <Grid item xs={12} md={6} lg={3}>
            <div className={classes.fieldContainer}>
              <Typography className={classes.label}>Effective To</Typography>
              <DateTimePicker
                required={true}
                label={""}
                placeholder={getFormatForDateTimePicker("date")}
                // dateFormat={"dd-MM-yyyy"}
                onChange={(value) => {
                  handleStateChange("efftodate", value); 
                }}
                value={state?.efftodate ? moment(state?.efftodate) : null}
                minDate={state?.efffrdate ?? null}
              />
            </div>
          </Grid> */}
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <div>
        <WeekDays
          selectedDays={state?.weekdays}
          handleWeekdaysChange={handleWeekdaysChange}
          options={options?.weekdays}
          selectedWeekdays={props?.selectedWeekdays}
        />
      </div>
      <div className={classes.actionButtons}>
        <CustomButton
          variant={"outlined"}
          color="#242424"
          onClick={onCancelClick}
        >
          Cancel
        </CustomButton>
        <CustomButton
          variant={"contained"}
          color="#FFFFFF"
          backgroundColor="#01205C"
          onClick={() => onSaveClick({ ...state, id: uuidv4() })}
        >
          Save
        </CustomButton>
      </div>
    </div>
  );
}

export default TimingForm;
