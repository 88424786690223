import moment from "moment";

const hourWithAm = (time) => {
  return moment(time).format("HH:mm");
};

export const validateWeeklyHours = (data) => {
  let isValid = data.every((d) => {
    const schedule = d.operate_time;
    return isScheduleValid(schedule);
  });
  return isValid;
};

const isScheduleValid = (schedule) => {
  let prevHour;
  let prevMin;
  let valid = true;
  schedule.find((s) => {
    const isTheSlotValid = isSlotValid(s);
    if (prevHour !== undefined && prevMin !== undefined) {
      if (isTheSlotValid) {
        const [startSlotHour, startSlotMinute] = hourWithAm(s.openingtime)
          .split(":")
          .map((e) => Number(e));
        if (startSlotHour > prevHour) {
          return false;
        }
        if (startSlotHour === prevHour) {
          if (startSlotMinute >= prevMin) {
            return false;
          }
        }
      }
      valid = false;
      return true;
    }
    [prevHour, prevMin] = hourWithAm(s.closingtime)
      .split(":")
      .map((e) => Number(e));
    return !isTheSlotValid;
  });
  return valid;
};

const isSlotValid = (slot) => {
  const { openingtime: start, closingtime: end } = slot;
  const [startHour, startMinute] = hourWithAm(start)
    .split(":")
    .map((e) => Number(e));
  const [endHour, endMinute] = hourWithAm(end)
    .split(":")
    .map((e) => Number(e));
  if (startHour > endHour) {
    return false;
  }
  if (startHour === endHour) {
    if (startMinute >= endMinute) {
      return false;
    }
  }
  return true;
};
