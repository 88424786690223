import React from "react";
import {
  Div,
  //Select,
  Col,
  Row,
  TextInput,
  Button,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
//import { AlertProps } from "../../../utils";
import { SelectBox } from "../../common/smartForm/component";
import { Typography } from "@material-ui/core";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
class PatientAddAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      use: null,
      type: null,
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: null,
      district: null,
      state: null,
      country: null,
      pincode: null,
    };
  }

  onChangeState = async (key, value, limit) => {

    if (key === "country") {
      this.setState({
        ...this.state,
        city: null,
        district: null,
        state: null,
        pincode: null,
      });
      const data = await this.props.PRACTITIONER_STATE_MASTER({ geogLevelCode: value?.geogLevelCode });

    } else if (key === "state") {
      this.setState({
        ...this.state,
        city: null,
        district: null,
        pincode: null,
      });
      const data = await this.props.PRACTITIONER_DISTRICT_MASTER({ geogLevelCode: value?.geogLevelCode });

    } else if (key === "district") {
      this.setState({
        ...this.state,
        city: null,
        pincode: null,
      });
      const data = await this.props.PRACTITIONER_CITY_MASTER({ geogLevelCode: value?.geogLevelCode });

    } else if (key === "city") {
      this.setState({
        ...this.state,
        pincode: null,
      });
      const data = await this.props.PRACTITIONER_PINCODE_MASTER({ geogLevelCode: value?.geogLevelCode });

    }

    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  componentDidMount() {
    const { editData } = this.props;
    if (editData) {
      this.setState({ ...editData });
    } else {
      this.Clear();
    }
  }

  validateCheck = (data) => {
    if (
      data?.addressLine1?.length > 0 &&
      data?.use?.label?.length > 0 &&
      data?.type?.label?.length > 0 &&
      data?.city?.label?.length > 0 &&
      data?.country?.label?.length > 0 &&
      data?.district?.label?.length > 0 &&
      data?.pincode?.label?.length > 0 &&
      data?.state?.label?.length > 0
    ) {
      return false;
    } else {
      let errors = {
        addressLine1: data?.addressLine1?.length > 0 ? false : true,
        use: data?.use?.label?.length > 0 ? false : true,
        type: data?.type?.label?.length > 0 ? false : true,
        city: data?.city?.label?.length > 0 ? false : true,
        country: data?.country?.label?.length > 0 ? false : true,
        district: data?.district?.label?.length > 0 ? false : true,
        pincode: data?.pincode?.label?.length > 0 ? false : true,
        state: data?.state?.label?.length > 0 ? false : true,
      };
      this.setState({ ...this.state, erorr: errors });
      return true;
    }
  };

  saveAddress = () => {
    if (this.validateCheck(this.state)) {
      this.props.alert.setSnack("mandatory");
      return false;
    }
    if (this.props?.saveAddress) {
      this.props.saveAddress(this.state);
    }
    // const { onclose, index, handleClear } = this.props;
    // let patientData = this.props[this.props.saveto];
    // if (index !== null) {
    //     patientData.address[index] = this.state
    // } else {
    //     patientData.address.push(this.state)
    // }
    // patientData[this.props.setTo]({
    //     ...patientData,
    // })
    // onclose()
    // handleClear()
    // this.Clear()
  };

  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
      marginRight: 10,
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
    },
    errorBorderStyle: {
      borderRadius: 8,
      border: "1px solid red",
      // width: "514px",
      // height: "40px",
    },
  };

  Clear = () => {
    this.setState({
      use: null,
      type: null,
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: null,
      district: null,
      state: null,
      country: null,
      pincode: null,
    });
  };

  FlterOptions = (type) => {
    const { allMasters } = this.props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      var datatemp = {
        value: data[index]?.coding?.display,
      };
      lista.push(datatemp);
    }
    return lista;
  };

  handleClear = () => {
    this.Clear();
    if (this.props?.handleClear) {
      this.props.handleClear();
    }
  };
  render() {
    const {
      //onclose, 
      parent_id } = this.props;
    const {
      labelStyle,
      borderStyle,
      outlinedButton,
      containedButton,
      errorBorderStyle,
    } = this.styles;

    return (
      <Div
        id={`${parent_id}-parent-div`}
        style={{
          padding: "0px 16px 16px 16px",
        }}
      >
        {/* <Paper className="pi-paper"> */}
        <Row
          id={`${parent_id}-parent-row`}
          className="pi-actions-root">
          <Col
            id={`${parent_id}-use-title-col`}
            md={2} lg={2} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <Select
                  isRequired={true}
                  showArrow
                  label="use"
                  placeholder="Use"
                  labelStyle={labelStyle}
                  inLineStyles={
                    this.state?.erorr?.use ? errorBorderStyle : borderStyle
                  }
                  // inLineStyles={borderStyle}
                  hoverColor="#0071F2"
                  getOptionLabel="label"
                  options={this.props?.allMasters?.["Address_use"]}
                  onChangeValue={(value) => this.onChangeState("use", value)}
                  value={this.state.use?.label}
                /> */}
            <Typography
              id={`${parent_id}-use-title-typography`}
              style={{
                fontSize: "12px",

                Fontfamily: "pc_regular",
                color: "#6F6F6F",
              }}
              variant="body1"
            >
              Use <span
                id={`${parent_id}-use-title-star-span`}
                style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={'use'}
              list={this.props?.allMasters?.["Address_use"] ?? []}
              placeholder={"Select"}
              disableClearable
              value={this.state.use}
              onchange={(e, value) => this.onChangeState("use", value)}
              error={this.state?.erorr?.use}
            />
          </Col>
          <Col
            id={`${parent_id}-Type-col`}
            md={2} lg={2} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <Select
                  isRequired={true}
                  showArrow
                  label="Type"
                  placeholder="type"
                  labelStyle={labelStyle}
                  // inLineStyles={borderStyle}
                  inLineStyles={
                    this.state?.erorr?.type ? errorBorderStyle : borderStyle
                  }
                  hoverColor="#0071F2"
                  getOptionLabel="label"
                  options={this.props?.allMasters?.["address_type"]}
                  onChangeValue={(value) => this.onChangeState("type", value)}
                  value={this.state.type?.label}
                /> */}
            <Typography
              id={`${parent_id}-Type-title-typography`}
              style={{
                fontSize: "12px",

                Fontfamily: "pc_regular",
                color: "#6F6F6F",
              }}
              variant="body1"
            >
              Type <span
                id={`${parent_id}-Type-title-star-span`}
                style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={'type'}
              list={this.props?.allMasters?.["address_type"] ?? []}
              placeholder={"Select"}
              disableClearable
              value={this.state.type}
              onchange={(e, value) => this.onChangeState("type", value)}
              error={this.state?.erorr?.type}
            />
          </Col>
          <Col
            id={`${parent_id}-addressLine1-col`}
            md={4} lg={4} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
            <TextInput
              id={`${parent_id}-addressLine1-textinput`}
              isRequired={true}
              placeholder="Enter Address"
              onChange={(e) =>
                this.onChangeState("addressLine1", e.target.value, 30)
              }
              value={this.state.addressLine1 ? this.state.addressLine1 : ""}
              label="Address Line 1"
              labelStyle={labelStyle}
              // style={borderStyle}
              style={
                this.state?.erorr?.addressLine1 ? errorBorderStyle : borderStyle
              }
              hoverColor="#0071F2"
            />
          </Col>
          <Col
            id={`${parent_id}-addressLine2-col`}
            md={4} lg={4} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
            <TextInput
              id={`${parent_id}-addressLine2-textinput`}
              placeholder="Enter Address"
              onChange={(e) =>
                this.onChangeState("addressLine2", e.target.value, 30)
              }
              value={this.state.addressLine2}
              label="Address Line 2"
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor="#0071F2"
            />
          </Col>
          {/* </Row> */}
          {/* <Row className="pi-actions-root"> */}
          <Col
            id={`${parent_id}-Country-col`}
            md={4} lg={2} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <Select
                  isRequired={true}
                  options={this.FlterOptions("Country")}
                  onChangeValue={(value) =>
                    this.onChangeState("country", value)
                  }
                  value={this.state.country?.label}
                  showArrow
                  label="Country"
                  placeholder="select..."
                  labelStyle={labelStyle}
                  // inLineStyles={borderStyle}
                  hoverColor="#0071F2"
                  getOptionLabel="label"
                  options={this.props?.allMasters?.["country"]}
                  inLineStyles={
                    this.state?.erorr?.country ? errorBorderStyle : borderStyle
                  }
                /> */}

            <Typography
              id={`${parent_id}-Country-title-typography`}
              style={{
                fontSize: "12px",

                Fontfamily: "pc_regular",
                color: "#6F6F6F",
              }}
              variant="body1"
            >
              Country <span
                id={`${parent_id}-Country-star-span`}
                style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={'Country'}
              list={this.props?.practitioner_country_master?.data ?? []}
              placeholder={"Select"}
              disableClearable
              value={this.state.country}
              onchange={(e, value) => this.onChangeState("country", value)}
              error={this.state?.erorr?.country}
            />
          </Col>
          <Col
            id={`${parent_id}-State-col`}
            md={6} lg={2} sm={2} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <Select
                  // options={this.FlterOptions('State')}
                  isRequired={true}
                  onChangeValue={(value) => this.onChangeState("state", value)}
                  value={this.state.state?.label}
                  showArrow
                  label="State"
                  placeholder="select..."
                  labelStyle={labelStyle}
                  // inLineStyles={borderStyle}
                  hoverColor="#0071F2"
                  getOptionLabel="label"
                  options={this.props?.allMasters?.["state"]}
                  inLineStyles={
                    this.state?.erorr?.state ? errorBorderStyle : borderStyle
                  }
                /> */}

            <Typography
              id={`${parent_id}-State-title-typography`}
              style={{
                fontSize: "12px",

                Fontfamily: "pc_regular",
                color: "#6F6F6F",
              }}
              variant="body1"
            >
              State <span
                id={`${parent_id}-State-star-span`}
                style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={'state'}
              // list={this.props?.allMasters?.["state"] ?? []}              
              list={Array.isArray(this.props?.practitioner_state_master?.data) ? this.props?.practitioner_state_master?.data : []}
              placeholder={"Select"}
              disableClearable
              value={this.state.state}
              onchange={(e, value) => this.onChangeState("state", value)}
              error={this.state?.erorr?.state}
            />
          </Col>
          <Col
            id={`${parent_id}-District-col`}
            md={6} lg={2} sm={2} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <Select
                  isRequired={true}
                  // options={this.FlterOptions('District')}
                  onChangeValue={(value) =>
                    this.onChangeState("district", value)
                  }
                  value={this.state.district?.label}
                  showArrow
                  label="District"
                  placeholder="select..."
                  labelStyle={labelStyle}
                  // inLineStyles={borderStyle}
                  hoverColor="#0071F2"
                  getOptionLabel="label"
                  options={this.props?.allMasters?.["district"]}
                  inLineStyles={
                    this.state?.erorr?.district ? errorBorderStyle : borderStyle
                  }
                /> */}
            <Typography
              id={`${parent_id}-District-title-typography`}
              style={{
                fontSize: "12px",

                Fontfamily: "pc_regular",
                color: "#6F6F6F",
              }}
              variant="body1"
            >
              District <span
                id={`${parent_id}-District-star-span`}
                style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={'district'}
              // list={this.props?.allMasters?.["district"] ?? []}
              list={Array.isArray(this.props?.practitioner_district_master?.data) ? this.props?.practitioner_district_master?.data : []}
              placeholder={"Select"}
              disableClearable
              value={this.state.district}
              onchange={(e, value) => this.onChangeState("district", value)}
              error={this.state?.erorr?.district}
            />
          </Col>
          <Col
            id={`${parent_id}-City-col`}
            md={6} lg={2} sm={2} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <Select
                  isRequired={true}
                  options={this.FlterOptions("Town")}
                  onChangeValue={(value) => this.onChangeState("city", value)}
                  value={this.state.city?.label}
                  showArrow
                  label="City"
                  placeholder="select..."
                  labelStyle={labelStyle}
                  // inLineStyles={borderStyle}
                  hoverColor="#0071F2"
                  getOptionLabel="label"
                  options={this.props?.allMasters?.["city"]}
                  inLineStyles={
                    this.state?.erorr?.city ? errorBorderStyle : borderStyle
                  }
                /> */}
            <Typography
              id={`${parent_id}-City-title-typography`}
              style={{
                fontSize: "12px",

                Fontfamily: "pc_regular",
                color: "#6F6F6F",
              }}
              variant="body1"
            >
              City <span
                id={`${parent_id}-City-star-span`}
                style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={'city'}
              // list={this.props?.allMasters?.["city"] ?? []}
              list={Array.isArray(this.props?.practitioner_city_master?.data) ? this.props?.practitioner_city_master?.data : []}
              placeholder={"Select"}
              disableClearable
              value={this.state.city}
              onchange={(e, value) => this.onChangeState("city", value)}
              error={this.state?.erorr?.city}
            />
          </Col>

          <Col
            id={`${parent_id}-pincode-col`}
            md={4} lg={2} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <TextInput
              id={`${parent_id}-pincode-textinput`}
              isRequired={true}
              placeholder="Enter Pin Code"
              maxLength={6}
              type="number"
              onChange={(e) => this.onChangeState("pincode", e.target.value)}
              value={this.state.pincode}
              label="Pin Code"
              labelStyle={labelStyle}
              // style={borderStyle}
              style={
                this.state?.erorr?.pincode ? errorBorderStyle : borderStyle
              }
              hoverColor="#0071F2"
            /> */}
            <Typography
              id={`${parent_id}-City-title-typography`}
              style={{
                fontSize: "12px",

                Fontfamily: "pc_regular",
                color: "#6F6F6F",
              }}
              variant="body1"
            >
              Pin Code <span
                id={`${parent_id}-City-star-span`}
                style={{ color: "red" }}>*</span>
            </Typography>
     
            <SelectBox
              parent_id={'pincode'}
              // list={this.props?.allMasters?.["city"] ?? []}
              list={Array.isArray(this.props?.practitioner_pincode_master?.data) ? this.props?.practitioner_pincode_master?.data : []}
              placeholder={"Select"}
              disableClearable
              value={this.state.pincode}
              onchange={(e, value) => this.onChangeState("pincode", value)}
              error={this.state?.erorr?.pincode}
            />
          </Col>
        </Row>
        <Row
          id={`${parent_id}-buttons-row`}
        >
          <Col
            id={`${parent_id}-buttons-col`}
            className="aa-actions-btn-root" md={12} lg={12} sm={12} xs={12}>
            <Button
              id={`${parent_id}-Cancel-button`}
              className="aa-actions-btn"
              variant="outlined"
              inLineStyles={outlinedButton}
              onClick={() => this.handleClear()}
            >
              Cancel
            </Button>
            <Button
              id={`${parent_id}-Save-button`}
              className="aa-actions-btn"
              variant="outlined"
              backgroundColor="#0071F2"
              inLineStyles={containedButton}
              onClick={() => this.saveAddress()}
            >
              Save
            </Button>
          </Col>
        </Row>
        {/* </Paper> */}
      </Div>
    );
  }
}

const mapStateToProps = (state) => ({
  // oraganizationRegisterPatient: state?.OrganizationRegisterPatientApiSlice?.oraganizationRegisterPatient,
  practitioner_state_master: state?.practitionerMasterSlice?.practitioner_state_master,

  practitioner_district_master: state?.practitionerMasterSlice?.practitioner_district_master,
  practitioner_city_master: state?.practitionerMasterSlice?.practitioner_city_master,
  practitioner_pincode_master: state?.practitionerMasterSlice?.practitioner_pincode_master,
  practitioner_country_master: state?.practitionerMasterSlice?.practitioner_country_master
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(PatientAddAddress));
