import React, { useState } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {
  HoldingHandIcon,
  InfoIcon,
  NoAccessIcon,
  PillIcon,
  StackOverflowIcon,
} from "../../assets";
import { ReactComponent as PrinterIcon } from "../../assets/printerIconSecondary.svg";
import { ReactComponent as ForwardIconSmall } from "../../assets/forwarIconSmall.svg";
import {
  useDividerStyles,
  useDrugParticularsStyles,
  useCustomTooltip,
} from "./styles";
// import TextSelectField from "../textSelectField";
// import { DoseIssueUOMOptions, getImgUrl } from "../../../../../utils";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SvgIcon, Tooltip } from "@material-ui/core";
// import PatientEducation from "../patientEduca

const useDotStyles = makeStyles({
  root: {
    height: 4,
    width: 4,
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    margin: "0px 6px",
  },
});
const Dot = () => {
  const classes = useDotStyles();
  return <div className={classes.root}></div>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #DEE5EC",
    borderRadius: 8,
  },
  nroot: {
    border: "1px dashed red",
    borderRadius: 8,
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    "& .App1-MuiInputBase-root": {
      fontSize: "12px",
    },
  },
  selectcomp: {
    "& .App1-MuiInputBase-root": {
      fontSize: "12px",
    },
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
}));

function OrderCartTable(props) {
  
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  // Props
  const { brand = {}, handleChangebrandName } = props;

  const handleExpandCollapseChange = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div
      className={props?.val?.negative == true ? classes.nroot : classes.root}
    >
      <OrderParticulars
        {...props}
        handleChangebrandName={handleChangebrandName}
        isExpanded={isExpanded}
        onExpandCollapseChange={handleExpandCollapseChange}
      />
    </div>
  );
}

export default OrderCartTable;

// -------------------------------- Table Head - Drug Particulars --------------------------------------------

const OrderParticulars = ({
  val,
  drug,
  brand,
  batchQuantity,
  onBatchQuantityChange,
  isAnotherBrandAvailable,
  handleChangebrandName,
  handleAdd,
  isAdd,
  handleDelete = () => null,
  isChecker,
  index,
  selectedState,
  printLabel,
  // Is Intravenous fluid type
  isIntravenous,
  printEnable,
  // Component level props
  isExpanded,
  onExpandCollapseChange = () => null,
  ...props
}) => {
  // Styles
  const classes = useDrugParticularsStyles();

  const dividerStyles = useDividerStyles();
  const tooltipStyles = useCustomTooltip();

  // const [open, setOpen] = React.useState(false);

  // const handleState = () => {
  //   setState(val.cartDetail)
  // }

  // const data = JSON.stringify(binderData.downloadUrl);
  return (
    <>
      <div className={val?.negative === true ? classes.root : classes.nroot}>
        <Tooltip title={val?.tabname}>
          <Typography
            noWrap
            className={classes.headerText}
            style={{ fontWeight: 500 }}
          >
            {val?.tabname}
          </Typography>
        </Tooltip>
        <InfoIcon />

        <Dot />

        <Tooltip title="Issue Quantity" arrow classes={tooltipStyles}>
          <div
            className={classNames(
              classes.doseDetailItem,
              classes.stripQuantity
            )}
          >
            <HoldingHandIcon style={{ minWidth: "17.705px" }} />
            <Typography className={classes.defaultText}>{val?.hand}</Typography>
          </div>
        </Tooltip>

        <Dot />

        <Tooltip title="Total Quantity" arrow classes={tooltipStyles}>
          <div className={classes.doseDetailItem}>
            <StackOverflowIcon color="black" />
            <Typography className={classes.defaultText}>
              {val?.stack}
            </Typography>
          </div>
        </Tooltip>

        <Dot />

        <Tooltip title="Block Quantity" arrow classes={tooltipStyles}>
          <div className={classes.doseDetailItem}>
            <NoAccessIcon color="black" />
            <Typography className={classes.defaultText}>{val?.ban}</Typography>
          </div>
        </Tooltip>
        <Dot />
        <Tooltip
          title="Administrative Dose Quantity"
          arrow
          classes={tooltipStyles}
        >
          <div className={classes.doseDetailItem}>
            <PillIcon />
            <Typography className={classes.defaultText}>
              {val?.order}
            </Typography>
          </div>
        </Tooltip>

        <div style={{ marginLeft: "auto" }}>
          <PrinterIcon />
        </div>

        {/* <Divider orientation="vertical" flexItem classes={dividerStyles} />
        <div
          className={classes.iconContainer}
          onClick={() => props.handleState && props.handleState(val?.cartDetail)}
        >
          <SvgIcon
            component={ForwardIconSmall}
            viewBox="-5 -7 20 20"
            style={{
              width: "20px",
              height: "20px",
              transform: "rotate(-90deg)",
            }}
          />
        </div> */}
      </div>
    </>
  );
};

OrderParticulars.propTypes = {
  batchQuantity: PropTypes.string,
  onBatchQuantityChange: PropTypes.func,
};

OrderParticulars.defaultProps = {
  batchQuantity: "",
  onBatchQuantityChange: () => { },
};
