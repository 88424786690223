import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import clsx from "clsx";
import { SCHEDULE_TYPES } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 12,
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  title: {
    fontWeight: 600,
  },
  subtitle: {
    color: theme.palette.text.grey,
  },
  body: {
    marginTop: 8,
    display: "flex",
    flexDirection: "column",
    gap: 6,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: 12,
  },
  Bodyrow: {
    display: "contents",
    gap: 12,
  },
  rowHead: {
    paddingInline: 12,
  },
  rowBody: {
    backgroundColor: "#F1F6FC",
    paddingBlock: 8,
    paddingInline: 12,
  },
}));

function FrequencySchedule(props) {
  // <------------------Hooks--------------->
  const classes = useStyles(props);

  // <---------------Store States----------->

  // <-------------Component States--------->

  // <-----------------Handlers------------->

  // <-----------------Effects-------------->

  // <-----------------Others--------------->
  const { scheduleTypeCode, columns, rows } = props;

  // <-----------------Render--------------->
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Frequency Schedule</Typography>
      <Typography variant="body2" className={classes.subtitle}>
        Schedule By {SCHEDULE_TYPES[scheduleTypeCode]} ({scheduleTypeCode})
      </Typography>
      <div className={classes.body}>
        <div className={clsx(classes.row, classes.rowHead)}>
          {columns?.map((item) => (
            <Typography variant="body2">{item}</Typography>
          ))}
        </div>
        <div className={clsx(classes.Bodyrow)}>
          {rows?.map((row) => {
            return (
              <div className={clsx(classes.row, classes.rowBody)}>
                {columns?.map((column) => {
                  if (columns?.includes(column)) {
                    return (
                      <Typography variant="body2">{row[column]}</Typography>
                    );
                  }
                  return null;
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FrequencySchedule;

FrequencySchedule.defaultProps = {
  columns: [],
  rows: [],
};

FrequencySchedule.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  scheduleTypeCode: PropTypes.string,
};
