import React from "react";
import { Paper, Typography, makeStyles, Grid, Switch } from "@material-ui/core";
import {
  InputComp,
  SelectBox,
} from "../../../../../../components/common/smartForm/component";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    // boxShadow: "0px 10px 25px #0000000A",
    padding: "20px 20px 30px 20px",
    borderRadius: "0px 0px 0px 8px",
    background:theme.palette.background.table,
    "& .App1-MuiOutlinedInput-root": {
      borderRadius: 8,
    },
    "& .Mui-disabled": {
      background: "#f0f0f0",
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {
    border: "1px solid #E0E0E0",
    borderRadius: 8,
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  textfieldGrid: {
    marginTop: "4px",
  },
}));

function CheckDuplicate(props) {
  const classes = useStyles();
  const storeData = useSelector((state) => state?.orderCatalogSlice);
  const {
    //CheckActionOption,
    CheckAction,
    no,
    //DuplicatePeriodOption,
    DuplicatePeriod,
    checkSwitch,
    handleFormState,
    parent_id,
  } = props;
  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}_parent_paper`}
        className={classes.root}
        elevation={0}
      >
        <Grid
          id={`${parent_id}_parent_container`}
          container
          className={classes.form}
          spacing={2}
        >
          <Grid id={`${parent_id}_CHECK_FOR_DUPLICATE_grid`} item xs={6}>
            <Typography
              id={`${parent_id}_CHECK_FOR_DUPLICATE_typography`}
              className={classes.title}
              variant="h6"
            >
              CHECK FOR DUPLICATE
            </Typography>
          </Grid>
          <Grid
            id={`${parent_id}_checkSwitch_grid`}
            className={classes.grid}
            item
            xs={6}
          >
            <Switch
              id={`${parent_id}_checkSwitch_Switch`}
              checked={checkSwitch}
              color="primary"
              onChange={(e) => handleFormState("checkSwitch", e.target.checked)}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </Grid>
          <Grid id={`${parent_id}_checkSwitch_grid`} item xs={6}>
            <Typography
              id={`${parent_id}_Duplicate_Period_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Duplicate Period
            </Typography>
            <Grid
              id={`${parent_id}_Duplicate_Period_container`}
              container
              spacing={2}
            >
              <Grid
                id={`${parent_id}_Duplicate_Period_grid`}
                className={classes.textfieldGrid}
                item
                xs={3}
              >
                {/* <TextField
                  id={`${parent_id}_duplicate_Period_textField`}
                  value={no}
                  onChange={(e) => handleFormState("no", e.target.value)}
                  fullWidth
                  disabled={checkSwitch ? false : true}
                  type="number"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                /> */}
                <InputComp
                  id={`${parent_id}_duplicate_Period_textField`}
                  value={no}
                  disabled={checkSwitch ? false : true}
                  onChange={(e) => handleFormState("no", e.target.value)}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      maxlength: 3,
                    },
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                />
              </Grid>
              <Grid id={`${parent_id}_DuplicatePeriod_select_grid`} item xs={9}>
                <SelectBox
                  parent_id={`${parent_id}_duplicate_Period`}
                  list={storeData?.order_catalog_masters?.data?.quantity ?? []}
                  disabled={checkSwitch ? false : true}
                  placeholder={"Select"}
                  value={DuplicatePeriod}
                  onchange={(e, value) =>
                    handleFormState("DuplicatePeriod", value)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid id={`${parent_id}_DuplicateCheck_grid`} item xs={6}>
            <Typography
              id={`${parent_id}_DuplicateCheck_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Duplicate Check Action
            </Typography>
            <SelectBox
              parent_id={`${parent_id}_CheckAction`}
              list={storeData?.order_catalog_masters?.data?.checkaction ?? []}
              disabled={checkSwitch ? false : true}
              placeholder={"Select"}
              value={CheckAction}
              onchange={(e, value) => handleFormState("CheckAction", value)}
            />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default CheckDuplicate;
