import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";

export const Tabs = withStyles((theme) => ({
  root: {
    minHeight: 38,
    alignSelf: "flex-start",
    // padding: "16px 16px 0px",
    padding: "16px 0px 0px",
    // "&:has(.MuiTabs-scrollButtonsDesktop)": {
    //   padding: "16px 0px 0px",
    // },
    borderBottom: "1px solid #DEE5EC",
  },
  flexContainer: {
    gap: 40,
  },
  indicator: {
    backgroundColor: theme.palette.button.background,
  },
}))(MuiTabs);

export const Tab = withStyles((theme) => {
  const activeColor = theme.palette.button.background;
  const inactiveColor = "#6A7888";
  return {
    root: {
      minHeight: 28,
      lineHeight: 1.5,
      minWidth: "min-content",
      padding: "0px 0px 16px 0px",
      textTransform: "unset",
      "& .App1-MuiTab-wrapper": {
        flexDirection: "row",
        gap: theme.spacing(0.75),
        "& .App1-MuiTypography-root": {
          color: inactiveColor,
          fontWeight: 600,
          fontSize: 14,
        },
        "& div": {
          backgroundColor: inactiveColor,
        },
      },
      opacity: 1,
      "&$selected": {
        "& .App1-MuiTab-wrapper": {
          "& .App1-MuiTypography-root": {
            color: activeColor,
          },
          "& div": {
            backgroundColor: activeColor,
          },
        },
      },
    },
    selected: {},
  };
})(MuiTab);

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        padding: 16,
      }}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
