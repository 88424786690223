import React from "react";
import { OrderTags, OrderDescription } from ".";
import PropTypes from "prop-types";

//const useStyles = makeStyles((theme) => ({}));

function OrderItem({ item, ...props }) {
  // <---------------Hooks------------------>
  //const classes = useStyles();

  // <---------------Store States----------->

  // <-------------Component States--------->

  // <-----------------Handlers------------->

  // <-----------------Effects-------------->

  // <-----------------Render--------------->
  const {
    // Tags Props starts ----
    dispenseStatus = "",
    clinicalValidation = false,
    slidingScaleDose,
    prnComment,
    refillDate,
    pendingDuration,
    orderType,
    alloweddisptype,
    dispenseType,
    alertsCount,
    // Tags Props ends ----
    isIntravenous,
    isCompound,
    isSingleLine,
    route,
    name,
    dosage,
    frequency,
    duration,
    doctorName,
    speciality,
    infusionRate,
    infusionTime,
    typeOfInfusion,
    occurStartDateTime,
    occurEndDateTime,
    dosageFrequencyType,
    dosageFrequencyCode,
    orderFrequencyDetails,
    CompoundQuantity,
    CompoundQuantityUnit,
    CompoundStatbility,
    batchDrugStability,
    selectedState,
    dispenseDuration,
    dispenseDurationUOM,
    onDispenseDurationChange,
    orderValue,
    batchNo,
    onChangeBatch,
    orderedDrugs,
    onApplyDispenseDurationClick,
    brands,
    compounds,
    addictive,
    disptype,
    onBatchQuantityChange,
    addDrugOption,
    handleAdd,
    directions,
    handleDirectionSaveClick,
    _key,
    handleDelete,
    handleChangebrandName,
    drug,
    isMultiOrderSelectFuntion,
    isMultiOrderSelect,
    orderid,
    handleMedicationEdit,
  } = props;

  const handleMultiBatch = (type, index, isShow) => {
    if (props.isMultiBatch) {
      if (!isShow) {
        props.handleShowAllBatch(true, true, type, index);
      } else {
        props.onApplyDispenseDurationClick(false, true, type, index);
      }
    }
  };

  return (
    <div>
      <OrderTags
        {...props}
        dispenseStatus={dispenseStatus}
        clinicalValidation={clinicalValidation}
        slidingScaleDose={slidingScaleDose}
        prnComment={prnComment}
        refillDate={refillDate}
        pendingDuration={pendingDuration}
        orderType={orderType}
        alloweddisptype={alloweddisptype}
        dispenseType={dispenseType}
        alertsCount={alertsCount}
        selectedState={selectedState}
        orderLineID={orderid}
        _key={_key}
      />
      <OrderDescription
        {...props}
        handleMultiBatch={handleMultiBatch}
        isIntravenous={isIntravenous}
        isCompound={isCompound}
        isSingleLine={isSingleLine}
        route={route}
        name={name}
        dosage={dosage}
        frequency={frequency}
        duration={duration}
        doctorName={doctorName}
        speciality={speciality}
        infusionRate={infusionRate}
        infusionTime={infusionTime}
        typeOfInfusion={typeOfInfusion}
        occurStartDateTime={occurStartDateTime}
        occurEndDateTime={occurEndDateTime}
        dosageFrequencyType={dosageFrequencyType}
        dosageFrequencyCode={dosageFrequencyCode}
        orderFrequencyDetails={orderFrequencyDetails}
        CompoundQuantity={CompoundQuantity}
        CompoundQuantityUnit={CompoundQuantityUnit}
        CompoundStatbility={CompoundStatbility}
        batchDrugStability={batchDrugStability}
        selectedState={selectedState}
        dispenseDuration={dispenseDuration}
        dispenseDurationUOM={dispenseDurationUOM}
        onDispenseDurationChange={onDispenseDurationChange}
        orderValue={orderValue}
        batchNo={batchNo}
        onChangeBatch={onChangeBatch}
        orderedDrugs={orderedDrugs}
        onApplyDispenseDurationClick={onApplyDispenseDurationClick}
        brands={brands}
        compounds={compounds}
        addictive={addictive}
        disptype={disptype}
        onBatchQuantityChange={onBatchQuantityChange}
        addDrugOption={addDrugOption}
        handleAdd={handleAdd}
        directions={directions}
        handleDirectionSaveClick={handleDirectionSaveClick}
        handleDelete={handleDelete}
        handleChangebrandName={handleChangebrandName}
        drug={drug}
        isMultiOrderSelectFuntion={isMultiOrderSelectFuntion}
        isMultiOrderSelect={isMultiOrderSelect}
        orderid={orderid}
        handleMedicationEdit={handleMedicationEdit}
      />
    </div>
  );
}

export default OrderItem;

OrderItem.propTypes = {
  item: PropTypes.object.isRequired,

  // Order Tags props

  // Order Description Props
  isIntravenous: PropTypes.bool,
  route: PropTypes.string,
  name: PropTypes.string,
  dosage: PropTypes.string,
  frequency: PropTypes.string,
  duration: PropTypes.string,
  doctorName: PropTypes.string,
  speciality: PropTypes.string,
};
