/* eslint-disable no-unused-expressions */
import React from "react";
import { Button, Grid, Card, withStyles } from "@material-ui/core";
import { valitationFunc } from "../../../../../utils";
import { CommonTabList, OverlayCompt } from "../../../../../components";
//import { AlertProps } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";
import OrderSetDetails from "./orderSetDetails";
import ServiceDrugCatalogList from "./serviceDrugCatalogList";
import { connect } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import door from "../../../../../assets/Icon - View holidays for entities.svg";
import OrderSetApplicabilityTabel from "../../../odersetapplicability";

const styles = (theme) => ({
  root: {
    background: theme.palette.background.table
  },
});

const errorList = ["longdes", "shortdes", "orderSetCode"];
class OrderSetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm_: false,
      id: 0,
      sequencyNumber: 1,
      editSequencyNumber: null,
      parentSeqNumber: null,
      open: false,
      isEdit: false,
      _key: null,
      orderSetApplicabilityForm: false,
      modalFormName: "monitoringForm",
      orderCategory: {},
      orderSetCode: "",
      longdes: "",
      shortdes: "",
      status: true,
      tableData: [],
      monitoring: {
        seqNo: "",
        orderCategory: {},
        orderTypeDrugType: {},
        orderDrugCatalog: {},
        required: false,
        frequency_number: "",
        frequency_type: {},
        rate_number: "",
        rate_type: {},
        duration_number: "",
        duration_type: {},
        quantity_number: "",
        quantity_type: {},
        startDay: "",
        explanatoryNote: "",
        practitionerInstructions: "",
      },
      pharmacyMedication: {
        seqNo: "",
        orderCategory: {},
        orderTypeDrugType: {},
        orderDrugCatalog: {},
        required: false,
        route: {},
        quantity_number: "",
        quantity_type: {},
        dosage_freq_code: {},
        duration_number: "",
        duration_type: {},
        startDay: "",
        explanatoryNote: "",
        practitionerInstructions: "",
      },
      pharmacyIV: {
        seqNo: "",
        orderCategory: {},
        orderTypeDrugType: {},
        orderDrugCatalog: {},
        required: false,
        route: {},
        quantity_number: "",
        quantity_type: {},
        in_infusion_rate_volume_number: "",
        in_infusion_rate_volume_type: {},
        in_infusion_rate_duration_number: "",
        in_infusion_rate_duration_type: {},
        iv_dosage_duration_number: "",
        iv_dosage_duration_type: "",
        duration_number: "",
        duration_type: {},
        startDay: "",
        explanatoryNote: "",
        practitionerInstructions: "",
        additiveArray: [
          {
            drugeCatalog: {},
            quantity_number: "",
            quantity_type: {},
            required: false,
          },
        ],
      },
      error: {},
      modalerror: {},
    };
  }

  handleForm_ = async () => {
    let { openForm_ } = this.state;
    this.setState({
      openForm_: !openForm_,
      editData: null,
    });
  };

  handleApplicabilityOpen = () => {
    let { openForm_ } = this.state;
    this.setState({
      ...this.state,
      openForm_: !openForm_,
    });
  };

  handleshow = () => {
    this.setState({
      ...this.state,
      orderSetApplicabilityForm: true,
    });
  };

  async componentDidMount() {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Loading...",
    });
    await this.props.ORDER_CATEGORY_MASTER();
    await this.props.ORDER_SET_MASTER_MASTER();
    if (this.props?.editData) {
      this.getOrderSetById(this.props?.editData);
    }
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  }
  getOrderSetById = async (_key) => {
    await this.props.ORDER_SET_SINGLE_READ({ _key: _key });
    this.setState({
      ...this.state,
      ...this.props.order_set_by_id?.data,
    });
  };
  handleFormState = (name, value, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let errorObj = JSON.parse(JSON.stringify(this.state.error));
    if (errorObj[name]) {
      errorObj[name] = false;
    }
    this.setState({ [name]: value, error: errorObj });
  };
  addNewAdditive = () => {
    const temState = { ...this.state };
    let len = this.state?.pharmacyIV?.additiveArray.length;
    if (
      (this.state?.pharmacyIV?.additiveArray.length > 1 &&
        !this.state?.pharmacyIV?.additiveArray[len - 1]?.drugeCatalog?.value) ||
      !this.state?.pharmacyIV?.additiveArray[len - 1]?.quantity_number ||
      !this.state?.pharmacyIV?.additiveArray[len - 1]?.quantity_type?.value
    ) {
      this.props.alert.setSnack("mandatory");
    } else {
      let obj = {
        drugeCatalog: {},
        quantity_number: "",
        quantity_type: {},
        required: false,
      };
      temState.pharmacyIV.additiveArray.push(obj);
      //??
      this.setState(temState);
    }
  };
  additiveStateChange = (val, i, name, limit) => {
    if (limit) {
      if (limit < val.length) {
        return false;
      }
    }
    const temState = { ...this.state };
    temState.pharmacyIV.additiveArray[i][name] = val;
    this.setState(temState);
  };
  additiveHandleChange = (event, i) => {
    const temState = { ...this.state };
    temState.pharmacyIV.additiveArray[i]["required"] = event.target.checked;
    this.setState(temState);
  };
  deleteTableRow = async (seqNum) => {

    const state = JSON.parse(JSON.stringify(this.state));
    let index = this.state?.tableData?.findIndex(
      (val) => val?.seqNo === seqNum
    );
    if (this.props?.editData) {
      if (state.tableData?.[index]?._key) {
        let response = await this.props.DELETE_ORDER_SET_COMPONENT({
          _key: state.tableData?.[index]?._key,
        });
        if (response?.payload?.data?.Code === 201) {
          state.tableData?.splice(index, 1);
        }
      }
      else {
        state.tableData?.splice(index, 1);
      }
    } else {
      state.tableData?.splice(index, 1);
    }

    this.setState({ ...this.state, ...state });
  };
  handleEditModal = async (category, drugType, sequencyNo) => {
    const temState = { ...this.state };
    if (category === "Pharmacy") {
      if (drugType === "IV") {
        temState["modalFormName"] = "pharmacyIVForm";
        temState["open"] = true;
        temState["isEdit"] = true;
        let val = this.state?.tableData?.find((v) => {
          return v?.seqNo === sequencyNo;
        });
        //this.state.tableData?.map((val, i) => {

        if (val?.seqNo) {
          let obj = {
            seqNo: val?.seqNo ?? "",
            orderCategory: val?.orderCategory ?? {},
            orderTypeDrugType: val?.orderTypeDrugType ?? {},
            orderDrugCatalog: val?.orderCatalog ?? {},
            required: val?.required ?? false,
            route: val?.route ?? {},
            quantity_number: val?.quantity_number ?? "",
            quantity_type: val?.quantity_type ?? {},
            in_infusion_rate_volume_number:
              val?.in_infusion_rate_volume_number ?? "",
            in_infusion_rate_volume_type:
              val?.in_infusion_rate_volume_type ?? {},
            in_infusion_rate_duration_number:
              val?.in_infusion_rate_duration_number ?? "",
            in_infusion_rate_duration_type:
              val?.in_infusion_rate_duration_type ?? {},
            iv_dosage_duration_number: val?.iv_dosage_duration_number ?? "",
            iv_dosage_duration_type: val?.iv_dosage_duration_type ?? "",
            duration_number: val?.duration_number ?? "",
            duration_type: val?.duration_type ?? {},
            startDay: val?.startDay ?? "",
            explanatoryNote: val?.explanatoryNote ?? "",
            practitionerInstructions: val?.practitionerInstructions ?? "",
            additiveArray: this.constructEdit(val?.children),
          };
          temState["pharmacyIV"] = obj;
          temState["editSequencyNumber"] = sequencyNo;
          this.setState(temState);
          //SERVICE CALL FOR ORDER_DRUG_TYPE
          if (val?.orderCategory?.label === "Pharmacy") {
            await this.props?.DRUG__MASTER();
          } else {
            await this.props?.ORDER__MASTER({
              order_category: val?.orderCategory?.value,
            });
          }
          //SERVICE CALL FOR ORDER_DRUG_CATALOG
          // await this.props?.ORDER_DRUG_CATALOG_MASTER({
          //   order_category: val?.orderCategory?.value ?? "",
          //   order_drug_type: val?.orderTypeDrugType?.value ?? "",
          // });
          await this.props?.DRUG__CATALOG_MASTER({
            drug_type: val?.orderTypeDrugType?.value ?? "",
          });
        }
        //});
      } else {
        temState["modalFormName"] = "pharmacyMedicationForm";
        temState["open"] = true;
        temState["isEdit"] = true;
        //this.state.tableData?.map((val, i) => {
        let val = this.state?.tableData?.find((v) => {
          return v?.seqNo === sequencyNo;
        });
        if (val?.seqNo) {
          let obj = {
            seqNo: val?.seqNo ?? "",
            orderCategory: val?.orderCategory ?? {},
            orderTypeDrugType: val?.orderTypeDrugType ?? {},
            orderDrugCatalog: val?.orderCatalog ?? {},
            required: val?.required ?? false,
            route: val?.route ?? {},
            quantity_number: val?.quantity_number ?? "",
            quantity_type: val?.quantity_type ?? {},
            dosage_freq_code: val?.dosage_freq_code ?? {},
            duration_number: val?.duration_number ?? "",
            duration_type: val?.duration_type ?? {},
            startDay: val?.startDay ?? "",
            explanatoryNote: val?.explanatoryNote ?? "",
            practitionerInstructions: val?.practitionerInstructions ?? "",
          };
          temState["pharmacyMedication"] = obj;
          temState["editSequencyNumber"] = sequencyNo;
          this.setState(temState);
          //SERVICE CALL FOR ORDER_DRUG_TYPE
          if (val?.orderCategory?.label === "Pharmacy") {
            await this.props?.DRUG__MASTER();
          } else {
            await this.props?.ORDER__MASTER({
              order_category: val?.orderCategory?.value,
            });
          }
          //SERVICE CALL FOR ORDER_DRUG_CATALOG
          // await this.props?.ORDER_DRUG_CATALOG_MASTER({
          //   order_category: val?.orderCategory?.value ?? "",
          //   order_drug_type: val?.orderTypeDrugType?.value ?? "",
          // });
          await this.props?.DRUG__CATALOG_MASTER({
            drug_type: val?.orderTypeDrugType?.value ?? "",
          });
        }
        //});
      }
    } else {
      temState["modalFormName"] = "monitoringForm";
      temState["open"] = true;
      temState["isEdit"] = true;
      //this.state.tableData?.map((val, i) => {
      let val = this.state?.tableData?.find((v) => {
        return v?.seqNo === sequencyNo;
      });
      if (val?.seqNo) {
        let obj = {
          seqNo: val?.seqNo ?? "",
          orderCategory: val?.orderCategory ?? {},
          orderTypeDrugType: val?.orderTypeDrugType ?? {},
          orderDrugCatalog: val?.orderCatalog ?? {},
          required: val?.required ?? false,
          frequency_number: val?.frequency_number ?? "",
          frequency_type: val?.frequency_type ?? {},
          rate_number: val?.rate_number ?? "",
          rate_type: val?.rate_type ?? {},
          duration_number: val?.duration_number ?? "",
          duration_type: val?.duration_type ?? {},
          quantity_number: val?.quantity_number ?? "",
          quantity_type: val?.quantity_type ?? {},
          startDay: val?.startDay ?? "",
          explanatoryNote: val?.explanatoryNote ?? "",
          practitionerInstructions: val?.practitionerInstructions ?? "",
        };
        temState["monitoring"] = obj;
        temState["editSequencyNumber"] = sequencyNo;
        this.setState(temState);
        //SERVICE CALL FOR ORDER_DRUG_TYPE
        if (val?.orderCategory?.label === "Pharmacy") {
          await this.props?.DRUG__MASTER();
        } else {
          await this.props?.ORDER__MASTER({
            order_category: val?.orderCategory?.value,
          });
        }
        //SERVICE CALL FOR ORDER_DRUG_CATALOG
        await this.props?.ORDER__CATALOG_MASTER({
          order_category: val?.orderCategory?.value ?? "",
          order_drug_type: val?.orderTypeDrugType?.value ?? "",
        });
      }
      //});
    }

    //alert("handleEditModal");
  };
  constructEdit = (data) => {
    let arr = [];
    data?.map((val) => {
      let obj = {
        drugeCatalog: val?.orderCatalog ?? {},
        quantity_number: val?.quantity_number ?? "",
        quantity_type: val?.quantity_type ?? {},
        required: val?.required ?? false,
        parentordercatalog: val?.parentordercatalog ?? {},
        parentSequence: val?.parentSequence ?? "",
        seqNo: val?.seqNo ?? "",
      };
      arr.push(obj);
    });
    return arr;
  };
  handleModal = (type) => {
    if (type === "open") {
      this.setState({
        ...this.state,
        open: true,
        modalFormName: "monitoringForm",
        //INITIALIZE SATTE
        monitoring: {
          seqNo: "",
          orderCategory: {},
          orderTypeDrugType: {},
          orderDrugCatalog: {},
          required: false,
          frequency_number: "",
          frequency_type: {},
          rate_number: "",
          rate_type: {},
          duration_number: "",
          duration_type: {},
          quantity_number: "",
          quantity_type: {},
          startDay: "",
          explanatoryNote: "",
          practitionerInstructions: "",
        },
        pharmacyMedication: {
          seqNo: "",
          orderCategory: {},
          orderTypeDrugType: {},
          orderDrugCatalog: {},
          required: false,
          route: {},
          quantity_number: "",
          quantity_type: {},
          dosage_freq_code: {},
          duration_number: "",
          duration_type: {},
          startDay: "",
          explanatoryNote: "",
          practitionerInstructions: "",
        },
        pharmacyIV: {
          seqNo: "",
          orderCategory: {},
          orderTypeDrugType: {},
          orderDrugCatalog: {},
          required: false,
          route: {},
          quantity_number: "",
          quantity_type: {},
          in_infusion_rate_volume_number: "",
          in_infusion_rate_volume_type: {},
          in_infusion_rate_duration_number: "",
          in_infusion_rate_duration_type: {},
          iv_dosage_duration_number: "",
          iv_dosage_duration_type: "",
          duration_number: "",
          duration_type: {},
          startDay: "",
          explanatoryNote: "",
          practitionerInstructions: "",
          additiveArray: [
            {
              drugeCatalog: {},
              quantity_number: "",
              quantity_type: {},
              required: false,
            },
          ],
        },
      });
    } else if (type === "close") {
      this.setState({
        ...this.state,
        open: false,
        modalFormName: "monitoringForm",
        editSequencyNumber: null,
        isEdit: false,
        //INITIALIZE SATTE
        monitoring: {
          seqNo: "",
          orderCategory: {},
          orderTypeDrugType: {},
          orderDrugCatalog: {},
          required: false,
          frequency_number: "",
          frequency_type: {},
          rate_number: "",
          rate_type: {},
          duration_number: "",
          duration_type: {},
          quantity_number: "",
          quantity_type: {},
          startDay: "",
          explanatoryNote: "",
          practitionerInstructions: "",
        },
        pharmacyMedication: {
          seqNo: "",
          orderCategory: {},
          orderTypeDrugType: {},
          orderDrugCatalog: {},
          required: false,
          route: {},
          quantity_number: "",
          quantity_type: {},
          dosage_freq_code: {},
          duration_number: "",
          duration_type: {},
          startDay: "",
          explanatoryNote: "",
          practitionerInstructions: "",
        },
        pharmacyIV: {
          seqNo: "",
          orderCategory: {},
          orderTypeDrugType: {},
          orderDrugCatalog: {},
          required: false,
          route: {},
          quantity_number: "",
          quantity_type: {},
          in_infusion_rate_volume_number: "",
          in_infusion_rate_volume_type: {},
          in_infusion_rate_duration_number: "",
          in_infusion_rate_duration_type: {},
          iv_dosage_duration_number: "",
          iv_dosage_duration_type: "",
          duration_number: "",
          duration_type: {},
          startDay: "",
          explanatoryNote: "",
          practitionerInstructions: "",
          additiveArray: [
            {
              drugeCatalog: {},
              quantity_number: "",
              quantity_type: {},
              required: false,
            },
          ],
        },
      });
    }
  };
  handleModalChange = async (value, name, key) => {
    const temState = { ...this.state };
    temState.modalerror = {};
    if (name === "orderCategory") {
      if (value?.label === "Pharmacy") {
        temState["modalFormName"] = "pharmacyMedicationForm";
        //INITIALIZE SATTE
        if (this.props?.editData || this?.state?.isEdit) {
          temState["monitoring"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyMedication"] = {
            seqNo: this.state?.editSequencyNumber,
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyIV"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          };
        } else {
          temState["monitoring"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyMedication"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyIV"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          };
        }
        //FOR REF
        temState["orderCategory"] = value;
        temState["pharmacyMedication"][name] = value;
      } else {
        temState["modalFormName"] = "monitoringForm";
        // //INITIALIZE SATTE
        if (this.props?.editData || this?.state?.isEdit) {
          temState["monitoring"] = {
            seqNo: this.state?.editSequencyNumber,
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyMedication"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyIV"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          };
        } else {
          temState["monitoring"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyMedication"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyIV"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          };
        }
        //FOR REF
        temState["orderCategory"] = value;
        temState["monitoring"][name] = value;
      }
      //SERVICE CALL
      if (value?.label === "Pharmacy") {
        await this.props?.DRUG__MASTER();
      } else {
        await this.props?.ORDER__MASTER({
          order_category: value?.value,
        });
      }
    } else if (name === "orderTypeDrugType") {
      if (
        this.state?.orderCategory?.label === "Pharmacy" &&
        value?.label === "IV"
      ) {
        temState["modalFormName"] = "pharmacyIVForm";
        //INITIALIZE SATTE
        if (this.props?.editData || this?.state?.isEdit) {
          temState["monitoring"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyMedication"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyIV"] = {
            seqNo: this.state?.editSequencyNumber,
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          };
        } else {
          temState["monitoring"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyMedication"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyIV"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          };
        }
        //FOR REF
        temState["orderCategory"] = this.state?.orderCategory ?? {};
        temState["pharmacyIV"]["orderTypeDrugType"] = value;
        temState["pharmacyIV"]["orderCategory"] = this.state.orderCategory;
      } else if (this.state?.orderCategory?.label === "Pharmacy") {
        temState["modalFormName"] = "pharmacyMedicationForm";
        //INITIALIZE SATTE
        if (this.props?.editData || this?.state?.isEdit) {
          temState["monitoring"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyMedication"] = {
            seqNo: this.state?.editSequencyNumber,
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyIV"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          };
        } else {
          temState["monitoring"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyMedication"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyIV"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          };
        }
        //FOR REF
        temState["orderCategory"] = this.state?.orderCategory ?? {};
        temState["pharmacyMedication"]["orderTypeDrugType"] = value;
        temState["pharmacyMedication"]["orderCategory"] =
          this.state.orderCategory;
      } else {
        let orderCategory_ = this.state?.monitoring?.orderCategory ?? {};
        temState["modalFormName"] = "monitoringForm";
        //INITIALIZE SATTE
        if (this.props?.editData || this?.state?.isEdit) {
          temState["monitoring"] = {
            seqNo: this.state?.editSequencyNumber,
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyMedication"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyIV"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          };
        } else {
          temState["monitoring"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyMedication"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          };
          temState["pharmacyIV"] = {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          };
        }
        //FOR REF
        // temState["orderCategory"] = this.state?.orderCategory ?? {};
        // temState["monitoring"]["orderCategory"] =
        //   this.state?.orderCategory ?? {};

        //orderCategory_;
        temState["orderCategory"] = orderCategory_ ?? {};
        temState["monitoring"]["orderCategory"] = orderCategory_ ?? {};
        temState["monitoring"]["orderTypeDrugType"] = value;
      }
      //SERVICE CALL
      if (this.state.modalFormName === "monitoringForm") {
        let a = await this.props?.ORDER__CATALOG_MASTER({
          order_category: this.state?.monitoring?.orderCategory?.value ?? "",
          order_drug_type: value?.value ?? "",
        });
      } else if (this.state.modalFormName === "pharmacyMedicationForm") {
        let b = await this.props?.DRUG__CATALOG_MASTER({
          //order_category: this.state?.pharmacyMedication?.orderCategory?.value ?? "",
          drug_type: value?.value ?? "",
        });
      } else if (this.state.modalFormName === "pharmacyIVForm") {
        let c = await this.props?.DRUG__CATALOG_MASTER({
          //order_category: this.state?.pharmacyIV?.orderCategory?.value ?? "",
          drug_type: value?.value ?? "",
        });
      }
    }
    this.setState(temState);
  };
  handleModalState = async (value, name, key, limit) => {
    // let errName = JSON.stringify(name);
    const temState = { ...this.state };
    if (temState.modalerror[name]) {
      temState.modalerror[name] = false;
    }
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    temState[key][name] = value;
    // if (temState.modalerror[errName]){
    //   temState.modalerror[errName] = false;
    // }
    this.setState(temState);
  };
  handleSave = async () => {
    let errorList;
    if (this.state?.modalFormName === "monitoringForm") {
      errorList = [
        "orderCategory",
        "orderTypeDrugType",
        "orderDrugCatalog",
        "quantity_number",
        "quantity_type",
        "startDay",
      ];
      let { error, errorState } = await valitationFunc(
        this.state?.monitoring,
        errorList
      );
      if (error) {
        this.props.alert.setSnack("mandatory");
        this.setState({
          ...this.state,
          modalerror: errorState,
        });
      } else {
        const state = JSON.parse(JSON.stringify(this.state));
        const arr = [...state?.tableData];

        let obj = {
          typeId: state?.monitoring?.orderCategory?.value ?? "",
          seqNo: state?.sequencyNumber ?? "",
          //seqNo: state?.tableData ? state?.tableData.length + 1 : "",
          orderCategory: state?.monitoring?.orderCategory ?? {},
          orderTypeDrugType: state?.monitoring?.orderTypeDrugType ?? {},
          orderCatalog: state?.monitoring?.orderDrugCatalog ?? {},
          required: state?.monitoring?.required ?? false,
          requiredT: state?.monitoring?.required
            ? state?.monitoring?.required === true
              ? "Yes"
              : "No"
            : "No",
          quantity_number: state?.monitoring?.quantity_number ?? 0,
          quantity_type: state?.monitoring?.quantity_type ?? {},
          quantityT:
            state?.monitoring?.quantity_number &&
              state?.monitoring?.quantity_type?.label
              ? state.monitoring.quantity_number +
              " " +
              state.monitoring?.quantity_type.label
              : "-",
          // state?.monitoring?.quantity_number ??
          // 0 + " " + state?.monitoring?.quantity_type?.label ??
          // "",
          startDay: state?.monitoring?.startDay ?? "",
          duration_number: state?.monitoring?.duration_number ?? 0,
          duration_type: state?.monitoring?.duration_type ?? {},
          durationT:
            state?.monitoring?.duration_number &&
              state?.monitoring?.duration_type?.label
              ? state?.monitoring?.duration_number +
              " " +
              state?.monitoring?.duration_type?.label
              : "-",
          route: state?.monitoring?.route ?? {},
          routeT: state?.monitoring?.route?.value
            ? state?.monitoring?.route?.value
            : "-",
          frequency_number: state?.monitoring?.frequency_number ?? 0,
          frequency_type: state?.monitoring?.frequency_type ?? {},
          frequencyT:
            state?.monitoring?.frequency_number &&
              state?.monitoring?.frequency_type?.label
              ? state?.monitoring?.frequency_number +
              " " +
              state?.monitoring?.frequency_type?.label
              : "-",
          rate_number: state?.monitoring?.rate_number ?? 0,
          rate_type: state?.monitoring?.rate_type ?? {},
          rateT:
            state?.monitoring?.rate_number &&
              state?.monitoring?.rate_type?.label
              ? state?.monitoring?.rate_number +
              " " +
              state?.monitoring?.rate_type?.label
              : "-",
          additive: false,
          additivT: "No",
          parentSequence: state?.monitoring?.parentSequence ?? "",
          parentSequenceT: state?.monitoring?.parentSequence ?? "-",
          explanatoryNote: state?.monitoring?.explanatoryNote ?? "",
          practitionerInstructions:
            state?.monitoring?.practitionerInstructions ?? "",
          children: [],
        };
        arr.push(obj);
        this.setState({
          ...this.state,
          tableData: arr,
          open: false,
          modalFormName: "monitoringForm",
          editSequencyNumber: null,
          isEdit: false,
          //INITIALIZE SATTE
          monitoring: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          },
          pharmacyMedication: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          },
          pharmacyIV: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          },
          sequencyNumber: this.state?.sequencyNumber + 1,
          //sequencyNumber: this.state?.tableData?.length + 1,
          modalerror: {},
        });
      }
    } else if (this.state?.modalFormName === "pharmacyMedicationForm") {
      errorList = [
        "orderCategory",
        "orderTypeDrugType",
        "orderDrugCatalog",
        "quantity_number",
        "quantity_type",
        "duration_number",
        "duration_type",
        "startDay",
      ];
      let { error, errorState } = await valitationFunc(
        this.state?.pharmacyMedication,
        errorList
      );
      if (error) {
        this.props.alert.setSnack("mandatory");
        this.setState({
          ...this.state,
          modalerror: errorState,
        });
      } else {
        const state = JSON.parse(JSON.stringify(this.state));
        const arr = [...state?.tableData];

        let obj = {
          typeId: state?.pharmacyMedication?.orderCategory?.value ?? "",
          seqNo: state?.sequencyNumber ?? "",
          orderCategory: state?.pharmacyMedication?.orderCategory ?? {},
          orderTypeDrugType: state?.pharmacyMedication?.orderTypeDrugType ?? {},
          orderCatalog: state?.pharmacyMedication?.orderDrugCatalog ?? {},
          required: state?.pharmacyMedication?.required ?? false,
          requiredT: state?.pharmacyMedication?.required
            ? state?.pharmacyMedication?.required === true
              ? "Yes"
              : "No"
            : "No",

          quantity_number: state?.pharmacyMedication?.quantity_number ?? 0,
          quantity_type: state?.pharmacyMedication?.quantity_type ?? {},
          quantityT:
            state?.pharmacyMedication?.quantity_number &&
              state?.pharmacyMedication?.quantity_type?.label
              ? state.pharmacyMedication.quantity_number +
              " " +
              state.pharmacyMedication?.quantity_type.label
              : "-",
          dosage_freq_code: state?.pharmacyMedication?.dosage_freq_code ?? {},
          startDay: state?.pharmacyMedication?.startDay ?? "",
          duration_number: state?.pharmacyMedication?.duration_number ?? 0,
          duration_type: state?.pharmacyMedication?.duration_type ?? {},
          durationT:
            state?.pharmacyMedication?.duration_number &&
              state?.pharmacyMedication?.duration_type?.label
              ? state?.pharmacyMedication?.duration_number +
              " " +
              state?.pharmacyMedication?.duration_type?.label
              : "-",
          route: state?.pharmacyMedication?.route ?? {},
          routeT: state?.pharmacyMedication?.route?.label
            ? state?.pharmacyMedication?.route?.label
            : "-",
          frequency_number: state?.pharmacyMedication?.frequency_number ?? 0,
          frequency_type: state?.pharmacyMedication?.frequency_type ?? {},
          frequencyT:
            state?.pharmacyMedication?.frequency_number &&
              state?.pharmacyMedication?.frequency_type?.label
              ? state?.pharmacyMedication?.frequency_number +
              " " +
              state?.pharmacyMedication?.frequency_type?.label
              : "-",
          rate_number: state?.pharmacyMedication?.rate_number ?? 0,
          rate_type: state?.pharmacyMedication?.rate_type ?? {},
          rateT:
            state?.pharmacyMedication?.rate_number &&
              state?.pharmacyMedication?.rate_type?.label
              ? state?.pharmacyMedication?.rate_number +
              " " +
              state?.pharmacyMedication?.rate_type?.label
              : "-",
          // additive: state?.pharmacyMedication?.additive ?? false,
          // additivT: state?.pharmacyMedication?.additive
          //   ? state?.pharmacyMedication?.additive === true
          //     ? "Yes"
          //     : "No"
          //   : "No",
          additive: false,
          additivT: "No",
          parentSequence: state?.pharmacyMedication?.parentSequence ?? "",
          parentSequenceT: state?.pharmacyMedication?.parentSequence ?? "-",
          explanatoryNote: state?.pharmacyMedication?.explanatoryNote ?? "",
          practitionerInstructions:
            state?.pharmacyMedication?.practitionerInstructions ?? "",
          children: [],
        };
        arr.push(obj);
        // alert(JSON.stringify(this.state.monitoring));
        this.setState({
          ...this.state,
          tableData: arr,
          open: false,
          modalFormName: "monitoringForm",
          editSequencyNumber: null,
          isEdit: false,
          //INITIALIZE SATTE
          monitoring: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          },
          pharmacyMedication: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          },
          pharmacyIV: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          },
          sequencyNumber: this.state?.sequencyNumber + 1,
          //sequencyNumber: this.state?.tableData?.length + 1,
          modalerror: {},
        });
      }
    } else if (this.state?.modalFormName === "pharmacyIVForm") {
      //let len = this.state?.pharmacyIV?.additiveArray;
      errorList = [
        "orderCategory",
        "orderTypeDrugType",
        "orderDrugCatalog",
        "quantity_number",
        "quantity_type",
        "in_infusion_rate_volume_number",
        "in_infusion_rate_volume_type",
        "in_infusion_rate_duration_number",
        "in_infusion_rate_duration_type",
        "iv_dosage_duration_number",
        "iv_dosage_duration_type",
        "duration_number",
        "duration_type",
        "startDay",
      ];
      let { error, errorState } = await valitationFunc(
        this.state?.pharmacyIV,
        errorList
      );
      if (
        this.state?.pharmacyIV?.additiveArray.length > 0 &&
        (!this.state?.pharmacyIV?.additiveArray[
          this.state?.pharmacyIV?.additiveArray?.length - 1
        ]?.drugeCatalog?.value ||
          !this.state?.pharmacyIV?.additiveArray[
            this.state?.pharmacyIV?.additiveArray?.length - 1
          ]?.quantity_number ||
          !this.state?.pharmacyIV?.additiveArray[
            this.state?.pharmacyIV?.additiveArray?.length - 1
          ]?.quantity_type?.value)
      ) {
        this.props.alert.setSnack("mandatory");
      } else if (error) {
        this.props.alert.setSnack("mandatory");
        this.setState({
          ...this.state,
          modalerror: errorState,
        });
      } else {
        const state = JSON.parse(JSON.stringify(this.state));
        const arr = [...state?.tableData];
        const parentSeqNumber = state?.sequencyNumber ?? "";
        let { array, seqnumber } = this.childernJsonConstruct(
          state?.pharmacyIV?.additiveArray,
          parentSeqNumber,
          state?.pharmacyIV?.orderDrugCatalog
        );
        let obj = {
          typeId: state?.pharmacyIV?.orderCategory?.value ?? "",
          seqNo: state?.sequencyNumber ?? "",
          orderCategory: state?.pharmacyIV?.orderCategory ?? {},
          orderTypeDrugType: state?.pharmacyIV?.orderTypeDrugType ?? {},
          orderCatalog: state?.pharmacyIV?.orderDrugCatalog ?? {},
          required: state?.pharmacyIV?.required ?? false,
          requiredT: state?.pharmacyIV?.required
            ? state?.pharmacyIV?.required === true
              ? "Yes"
              : "No"
            : "No",

          quantity_number: state?.pharmacyIV?.quantity_number ?? 0,
          quantity_type: state?.pharmacyIV?.quantity_type ?? {},
          quantityT:
            state?.pharmacyIV?.quantity_number &&
              state?.pharmacyIV?.quantity_type?.label
              ? state.pharmacyIV.quantity_number +
              " " +
              state.pharmacyIV?.quantity_type.label
              : "-",
          in_infusion_rate_volume_number:
            state?.pharmacyIV?.in_infusion_rate_volume_number ?? "",
          in_infusion_rate_volume_type:
            state?.pharmacyIV?.in_infusion_rate_volume_type ?? {},
          in_infusion_rate_duration_number:
            state?.pharmacyIV?.in_infusion_rate_duration_number ?? "",
          in_infusion_rate_duration_type:
            state?.pharmacyIV?.in_infusion_rate_duration_type ?? {},
          iv_dosage_duration_number:
            state?.pharmacyIV?.iv_dosage_duration_number ?? "",
          iv_dosage_duration_type:
            state?.pharmacyIV?.iv_dosage_duration_type ?? {},
          dosage_freq_code: state?.pharmacyIV?.dosage_freq_code ?? {},
          startDay: state?.pharmacyIV?.startDay ?? "",
          duration_number: state?.pharmacyIV?.duration_number ?? 0,
          duration_type: state?.pharmacyIV?.duration_type ?? {},

          durationT:
            state?.pharmacyIV?.duration_number &&
              state?.pharmacyIV?.duration_type?.label
              ? state?.pharmacyIV?.duration_number +
              " " +
              state?.pharmacyIV?.duration_type?.label
              : "-",
          route: state?.pharmacyIV?.route ?? {},
          routeT: state?.pharmacyIV?.route?.label
            ? state?.pharmacyIV?.route?.label
            : "-",
          frequency_number: state?.pharmacyIV?.frequency_number ?? 0,
          frequency_type: state?.pharmacyIV?.frequency_type ?? {},
          frequencyT:
            state?.pharmacyIV?.frequency_number &&
              state?.pharmacyIV?.frequency_type?.label
              ? state?.pharmacyIV?.frequency_number +
              " " +
              state?.pharmacyIV?.frequency_type?.label
              : "-",
          rate_number: state?.pharmacyIV?.rate_number ?? 0,
          rate_type: state?.pharmacyIV?.rate_type ?? {},
          rateT:
            state?.pharmacyIV?.rate_number &&
              state?.pharmacyIV?.rate_type?.label
              ? state?.pharmacyIV?.rate_number +
              " " +
              state?.pharmacyIV?.rate_type?.label
              : "-",
          additive: false,
          additivT: "No",
          parentSequence: state?.pharmacyIV?.parentSequence ?? "",
          parentSequenceT: state?.pharmacyIV?.parentSequence ?? "-",
          explanatoryNote: state?.pharmacyIV?.explanatoryNote ?? "",
          practitionerInstructions:
            state?.pharmacyIV?.practitionerInstructions ?? "",

          children: array ?? [],
        };
        arr.push(obj);
        this.setState({
          ...this.state,
          tableData: arr,
          open: false,
          modalFormName: "monitoringForm",
          editSequencyNumber: null,
          isEdit: false,
          //INITIALIZE SATTE
          monitoring: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          },
          pharmacyMedication: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          },
          pharmacyIV: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          },
          sequencyNumber: seqnumber + 1,
          //sequencyNumber: this.state?.tableData?.length + 1,
          modalerror: {},
        });
      }
    }
  };
  handleUpdate = () => {
    if (this.state?.modalFormName === "monitoringForm") {
      if (
        !this.state?.monitoring?.orderCategory?.value ||
        !this.state?.monitoring?.orderTypeDrugType?.value ||
        !this.state?.monitoring?.orderDrugCatalog?.value ||
        !this.state?.monitoring?.quantity_number ||
        !this.state?.monitoring?.quantity_type?.value ||
        !this.state?.monitoring?.startDay
      ) {
        this.props.alert.setSnack("mandatory");
      } else {
        const state = JSON.parse(JSON.stringify(this.state));
        let obj = {
          typeId: state?.monitoring?.orderCategory?.value ?? "",
          seqNo: state?.monitoring?.seqNo ?? "",
          orderCategory: state?.monitoring?.orderCategory ?? {},
          orderTypeDrugType: state?.monitoring?.orderTypeDrugType ?? {},
          orderCatalog: state?.monitoring?.orderDrugCatalog ?? {},
          required: state?.monitoring?.required ?? false,
          requiredT: state?.monitoring?.required
            ? state?.monitoring?.required === true
              ? "Yes"
              : "No"
            : "No",
          quantity_number: state?.monitoring?.quantity_number ?? 0,
          quantity_type: state?.monitoring?.quantity_type ?? {},
          quantityT:
            state?.monitoring?.quantity_number &&
              state?.monitoring?.quantity_type?.label
              ? state.monitoring.quantity_number +
              " " +
              state.monitoring?.quantity_type.label
              : "-",
          startDay: state?.monitoring?.startDay ?? "",
          duration_number: state?.monitoring?.duration_number ?? 0,
          duration_type: state?.monitoring?.duration_type ?? {},
          durationT:
            state?.monitoring?.duration_number &&
              state?.monitoring?.duration_type?.label
              ? state?.monitoring?.duration_number +
              " " +
              state?.monitoring?.duration_type?.label
              : "-",
          in_infusion_rate_volume_number:
            state?.pharmacyIV?.in_infusion_rate_volume_number ?? "",
          in_infusion_rate_volume_type:
            state?.pharmacyIV?.in_infusion_rate_volume_type ?? {},
          in_infusion_rate_duration_number:
            state?.pharmacyIV?.in_infusion_rate_duration_number ?? "",
          in_infusion_rate_duration_type:
            state?.pharmacyIV?.in_infusion_rate_duration_type ?? {},
          iv_dosage_duration_number:
            state?.pharmacyIV?.iv_dosage_duration_number ?? "",
          iv_dosage_duration_type:
            state?.pharmacyIV?.iv_dosage_duration_type ?? {},
          route: state?.monitoring?.route ?? {},
          routeT: state?.monitoring?.route?.value
            ? state?.monitoring?.route?.value
            : "-",
          frequency_number: state?.monitoring?.frequency_number ?? 0,
          frequency_type: state?.monitoring?.frequency_type ?? {},
          frequencyT:
            state?.monitoring?.frequency_number &&
              state?.monitoring?.frequency_type?.label
              ? state?.monitoring?.frequency_number +
              " " +
              state?.monitoring?.frequency_type?.label
              : "-",
          rate_number: state?.monitoring?.rate_number ?? 0,
          rate_type: state?.monitoring?.rate_type ?? {},
          rateT:
            state?.monitoring?.rate_number &&
              state?.monitoring?.rate_type?.label
              ? state?.monitoring?.rate_number +
              " " +
              state?.monitoring?.rate_type?.label
              : "-",
          additive: state?.monitoring?.additive ?? false,
          // additivT: state?.monitoring?.additive
          //   ? state?.monitoring?.additive === true
          //     ? "Yes"
          //     : "No"
          //   : "No",
          additive: false,
          additivT: "No",
          parentSequence: state?.monitoring?.parentSequence ?? "",
          parentSequenceT: state?.monitoring?.parentSequence ?? "-",
          explanatoryNote: state?.monitoring?.explanatoryNote ?? "",
          practitionerInstructions:
            state?.monitoring?.practitionerInstructions ?? "",
          children: [],
        };
        const indexOfItemInArray = state?.tableData?.findIndex(
          (val) => val.seqNo === obj?.seqNo
        );
        state?.tableData?.splice(indexOfItemInArray, 1, obj);
        this.setState({
          ...this.state,
          tableData: state?.tableData,
          open: false,
          modalFormName: "monitoringForm",
          editSequencyNumber: null,
          isEdit: false,
          //INITIALIZE SATTE
          monitoring: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          },
          pharmacyMedication: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          },
          pharmacyIV: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          },
          //sequencyNumber: this.state?.sequencyNumber + 1,
          //sequencyNumber: this.state?.tableData?.length + 1,
        });
      }
    } else if (this.state?.modalFormName === "pharmacyMedicationForm") {
      if (
        !this.state?.pharmacyMedication?.orderCategory?.value ||
        !this.state?.pharmacyMedication?.orderTypeDrugType?.value ||
        !this.state?.pharmacyMedication?.orderDrugCatalog?.value ||
        !this.state?.pharmacyMedication?.quantity_number ||
        !this.state?.pharmacyMedication?.quantity_type?.value ||
        !this.state?.pharmacyMedication?.duration_number ||
        !this.state?.pharmacyMedication?.duration_type?.value ||
        !this.state?.pharmacyMedication?.startDay
      ) {
        this.props.alert.setSnack("mandatory");
      } else {
        const state = JSON.parse(JSON.stringify(this.state));
        let obj = {
          typeId: state?.pharmacyMedication?.orderCategory?.value ?? "",
          seqNo: state?.pharmacyMedication?.seqNo ?? "",
          orderCategory: state?.pharmacyMedication?.orderCategory ?? {},
          orderTypeDrugType: state?.pharmacyMedication?.orderTypeDrugType ?? {},
          orderCatalog: state?.pharmacyMedication?.orderDrugCatalog ?? {},
          required: state?.pharmacyMedication?.required ?? false,
          requiredT: state?.pharmacyMedication?.required
            ? state?.pharmacyMedication?.required === true
              ? "Yes"
              : "No"
            : "No",

          quantity_number: state?.pharmacyMedication?.quantity_number ?? 0,
          quantity_type: state?.pharmacyMedication?.quantity_type ?? {},
          quantityT:
            state?.pharmacyMedication?.quantity_number &&
              state?.pharmacyMedication?.quantity_type?.label
              ? state.pharmacyMedication.quantity_number +
              " " +
              state.pharmacyMedication?.quantity_type.label
              : "-",
          dosage_freq_code: state?.pharmacyMedication?.dosage_freq_code ?? {},
          startDay: state?.pharmacyMedication?.startDay ?? "",
          duration_number: state?.pharmacyMedication?.duration_number ?? 0,
          duration_type: state?.pharmacyMedication?.duration_type ?? {},
          durationT:
            state?.pharmacyMedication?.duration_number &&
              state?.pharmacyMedication?.duration_type?.label
              ? state?.pharmacyMedication?.duration_number +
              " " +
              state?.pharmacyMedication?.duration_type?.label
              : "-",
          route: state?.pharmacyMedication?.route ?? {},
          routeT: state?.pharmacyMedication?.route?.label
            ? state?.pharmacyMedication?.route?.label
            : "-",
          frequency_number: state?.pharmacyMedication?.frequency_number ?? 0,
          frequency_type: state?.pharmacyMedication?.frequency_type ?? {},
          frequencyT:
            state?.pharmacyMedication?.frequency_number &&
              state?.pharmacyMedication?.frequency_type?.label
              ? state?.pharmacyMedication?.frequency_number +
              " " +
              state?.pharmacyMedication?.frequency_type?.label
              : "-",
          rate_number: state?.pharmacyMedication?.rate_number ?? 0,
          rate_type: state?.pharmacyMedication?.rate_type ?? {},
          rateT:
            state?.pharmacyMedication?.rate_number &&
              state?.pharmacyMedication?.rate_type?.label
              ? state?.pharmacyMedication?.rate_number +
              " " +
              state?.pharmacyMedication?.rate_type?.label
              : "-",
          // additive: state?.pharmacyMedication?.additive ?? false,
          // additivT: state?.pharmacyMedication?.additive
          //   ? state?.pharmacyMedication?.additive === true
          //     ? "Yes"
          //     : "No"
          //   : "No",
          additive: false,
          additivT: "No",
          parentSequence: state?.pharmacyMedication?.parentSequence ?? "",
          parentSequenceT: state?.pharmacyMedication?.parentSequence ?? "-",
          explanatoryNote: state?.pharmacyMedication?.explanatoryNote ?? "",
          practitionerInstructions:
            state?.pharmacyMedication?.practitionerInstructions ?? "",
          children: [],
        };
        const indexOfItemInArray = state?.tableData?.findIndex(
          (val) => val.seqNo === obj?.seqNo
        );
        state?.tableData?.splice(indexOfItemInArray, 1, obj);
        this.setState({
          ...this.state,
          tableData: state?.tableData,
          open: false,
          modalFormName: "monitoringForm",
          editSequencyNumber: null,
          isEdit: false,
          //INITIALIZE SATTE
          monitoring: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          },
          pharmacyMedication: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          },
          pharmacyIV: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          },
          //sequencyNumber: this.state?.sequencyNumber + 1,
          //sequencyNumber: this.state?.tableData?.length + 1,
        });
      }
    } else if (this.state?.modalFormName === "pharmacyIVForm") {
      //let len = this.state?.pharmacyIV?.additiveArray;
      if (
        this.state?.pharmacyIV?.additiveArray.length > 0 &&
        (!this.state?.pharmacyIV?.additiveArray[
          this.state?.pharmacyIV?.additiveArray?.length - 1
        ]?.drugeCatalog?.value ||
          !this.state?.pharmacyIV?.additiveArray[
            this.state?.pharmacyIV?.additiveArray?.length - 1
          ]?.quantity_number ||
          !this.state?.pharmacyIV?.additiveArray[
            this.state?.pharmacyIV?.additiveArray?.length - 1
          ]?.quantity_type?.value)
      ) {
        this.props.alert.setSnack("mandatory");
      } else if (
        !this.state?.pharmacyIV?.orderCategory?.value ||
        !this.state?.pharmacyIV?.orderTypeDrugType?.value ||
        !this.state?.pharmacyIV?.orderDrugCatalog?.value ||
        !this.state?.pharmacyIV?.quantity_number ||
        !this.state?.pharmacyIV?.quantity_type?.value ||
        !this.state?.pharmacyIV?.in_infusion_rate_volume_number ||
        !this.state?.pharmacyIV?.in_infusion_rate_volume_type?.value ||
        !this.state?.pharmacyIV?.in_infusion_rate_duration_number ||
        !this.state?.pharmacyIV?.in_infusion_rate_duration_type?.value ||
        !this.state?.pharmacyIV?.iv_dosage_duration_number ||
        !this.state?.pharmacyIV?.iv_dosage_duration_type?.value ||
        !this.state?.pharmacyIV?.duration_number ||
        !this.state?.pharmacyIV?.duration_type?.value ||
        !this.state?.pharmacyIV?.startDay
      ) {
        this.props.alert.setSnack("mandatory");
      } else {
        const state = JSON.parse(JSON.stringify(this.state));

        let { array, seqnumber } = this.childernEditJsonConstruct(
          state?.pharmacyIV?.additiveArray,
          state?.pharmacyIV?.orderDrugCatalog,
          state?.pharmacyIV?.seqNo,
          state?.sequencyNumber - 1
        );
        let obj = {
          typeId: state?.pharmacyIV?.orderCategory?.value ?? "",
          seqNo: state?.pharmacyIV?.seqNo ?? "",
          orderCategory: state?.pharmacyIV?.orderCategory ?? {},
          orderTypeDrugType: state?.pharmacyIV?.orderTypeDrugType ?? {},
          orderCatalog: state?.pharmacyIV?.orderDrugCatalog ?? {},
          required: state?.pharmacyIV?.required ?? false,
          requiredT: state?.pharmacyIV?.required
            ? state?.pharmacyIV?.required === true
              ? "Yes"
              : "No"
            : "No",

          quantity_number: state?.pharmacyIV?.quantity_number ?? 0,
          quantity_type: state?.pharmacyIV?.quantity_type ?? {},
          quantityT:
            state?.pharmacyIV?.quantity_number &&
              state?.pharmacyIV?.quantity_type?.label
              ? state.pharmacyIV.quantity_number +
              " " +
              state.pharmacyIV?.quantity_type.label
              : "-",
          in_infusion_rate_volume_number:
            state?.pharmacyIV?.in_infusion_rate_volume_number ?? "",
          in_infusion_rate_volume_type:
            state?.pharmacyIV?.in_infusion_rate_volume_type ?? {},
          in_infusion_rate_duration_number:
            state?.pharmacyIV?.in_infusion_rate_duration_number ?? "",
          in_infusion_rate_duration_type:
            state?.pharmacyIV?.in_infusion_rate_duration_type ?? {},
          iv_dosage_duration_number:
            state?.pharmacyIV?.iv_dosage_duration_number ?? "",
          iv_dosage_duration_type:
            state?.pharmacyIV?.iv_dosage_duration_type ?? {},
          dosage_freq_code: state?.pharmacyIV?.dosage_freq_code ?? {},
          startDay: state?.pharmacyIV?.startDay ?? "",
          duration_number: state?.pharmacyIV?.duration_number ?? 0,
          duration_type: state?.pharmacyIV?.duration_type ?? {},
          durationT:
            state?.pharmacyIV?.duration_number &&
              state?.pharmacyIV?.duration_type?.label
              ? state?.pharmacyIV?.duration_number +
              " " +
              state?.pharmacyIV?.duration_type?.label
              : "-",
          route: state?.pharmacyIV?.route ?? {},
          routeT: state?.pharmacyIV?.route?.label
            ? state?.pharmacyIV?.route?.label
            : "-",
          frequency_number: state?.pharmacyIV?.frequency_number ?? 0,
          frequency_type: state?.pharmacyIV?.frequency_type ?? {},
          frequencyT:
            state?.pharmacyIV?.frequency_number &&
              state?.pharmacyIV?.frequency_type?.label
              ? state?.pharmacyIV?.frequency_number +
              " " +
              state?.pharmacyIV?.frequency_type?.label
              : "-",
          rate_number: state?.pharmacyIV?.rate_number ?? 0,
          rate_type: state?.pharmacyIV?.rate_type ?? {},
          rateT:
            state?.pharmacyIV?.rate_number &&
              state?.pharmacyIV?.rate_type?.label
              ? state?.pharmacyIV?.rate_number +
              " " +
              state?.pharmacyIV?.rate_type?.label
              : "-",
          additive: false,
          additivT: "No",
          parentSequence: state?.pharmacyIV?.parentSequence ?? "",
          parentSequenceT: state?.pharmacyIV?.parentSequence ?? "-",
          explanatoryNote: state?.pharmacyIV?.explanatoryNote ?? "",
          practitionerInstructions:
            state?.pharmacyIV?.practitionerInstructions ?? "",
          children: array,
        };
        const indexOfItemInArray = state?.tableData?.findIndex(
          (val) => val.seqNo === obj?.seqNo
        );
        state?.tableData?.splice(indexOfItemInArray, 1, obj);
        this.setState({
          ...this.state,
          tableData: state?.tableData,
          open: false,
          modalFormName: "monitoringForm",
          editSequencyNumber: null,
          isEdit: false,
          //INITIALIZE SATTE
          monitoring: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            frequency_number: "",
            frequency_type: {},
            rate_number: "",
            rate_type: {},
            duration_number: "",
            duration_type: {},
            quantity_number: "",
            quantity_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          },
          pharmacyMedication: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            dosage_freq_code: {},
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
          },
          pharmacyIV: {
            seqNo: "",
            orderCategory: {},
            orderTypeDrugType: {},
            orderDrugCatalog: {},
            required: false,
            route: {},
            quantity_number: "",
            quantity_type: {},
            in_infusion_rate_volume_number: "",
            in_infusion_rate_volume_type: {},
            in_infusion_rate_duration_number: "",
            in_infusion_rate_duration_type: {},
            iv_dosage_duration_number: "",
            iv_dosage_duration_type: "",
            duration_number: "",
            duration_type: {},
            startDay: "",
            explanatoryNote: "",
            practitionerInstructions: "",
            additiveArray: [
              {
                drugeCatalog: {},
                quantity_number: "",
                quantity_type: {},
                required: false,
              },
            ],
          },
          sequencyNumber: seqnumber + 1,
          //sequencyNumber: this.state?.tableData?.length + 1,
        });
      }
    }
  };
  childernJsonConstruct = (data, parentSeqNumber, parentordercatalog) => {
    const state = JSON.parse(JSON.stringify(this.state));
    let array = [];
    let seqnumber = parentSeqNumber;
    data?.map((val, i) => {
      let obj = {
        parentordercatalog: parentordercatalog ?? "",
        typeId: state?.pharmacyIV?.orderCategory?.value ?? "",
        seqNo: seqnumber + 1 ?? "",
        parentSequence: parentSeqNumber ?? "",
        parentSequenceT: parentSeqNumber ?? "-",
        orderCategory: state?.pharmacyIV?.orderCategory ?? {},
        orderTypeDrugType: state?.pharmacyIV?.orderTypeDrugType ?? {},
        orderCatalog: val?.drugeCatalog ?? {},
        required: val?.required ?? false,
        requiredT: val?.required
          ? val?.required === true
            ? "Yes"
            : "No"
          : "No",

        quantity_number: val?.quantity_number ?? 0,
        quantity_type: val?.quantity_type ?? {},
        quantityT:
          val?.quantity_number && val?.quantity_type?.label
            ? val?.quantity_number + " " + val?.quantity_type.label
            : "-",
        dosage_freq_code: state?.pharmacyIV?.dosage_freq_code ?? {},
        startDay: state?.pharmacyIV?.startDay ?? "",
        duration_number: state?.pharmacyIV?.duration_number ?? 0,
        duration_type: state?.pharmacyIV?.duration_type ?? {},
        durationT:
          state?.pharmacyIV?.duration_number &&
            state?.pharmacyIV?.duration_type?.label
            ? state?.pharmacyIV?.duration_number +
            " " +
            state?.pharmacyIV?.duration_type?.label
            : "-",
        route: state?.pharmacyIV?.route ?? {},
        routeT: state?.pharmacyIV?.route?.label
          ? state?.pharmacyIV?.route?.label
          : "-",
        frequency_number: state?.pharmacyIV?.frequency_number ?? 0,
        frequency_type: state?.pharmacyIV?.frequency_type ?? {},
        frequencyT:
          state?.pharmacyIV?.frequency_number &&
            state?.pharmacyIV?.frequency_type?.label
            ? state?.pharmacyIV?.frequency_number +
            " " +
            state?.pharmacyIV?.frequency_type?.label
            : "-",
        rate_number: state?.pharmacyIV?.rate_number ?? 0,
        rate_type: state?.pharmacyIV?.rate_type ?? {},
        rateT:
          state?.pharmacyIV?.rate_number && state?.pharmacyIV?.rate_type?.label
            ? state?.pharmacyIV?.rate_number +
            " " +
            state?.pharmacyIV?.rate_type?.label
            : "-",
        in_infusion_rate_volume_number:
          state?.pharmacyIV?.in_infusion_rate_volume_number ?? "",
        in_infusion_rate_volume_type:
          state?.pharmacyIV?.in_infusion_rate_volume_type ?? {},
        in_infusion_rate_duration_number:
          state?.pharmacyIV?.in_infusion_rate_duration_number ?? "",
        in_infusion_rate_duration_type:
          state?.pharmacyIV?.in_infusion_rate_duration_type ?? {},
        iv_dosage_duration_number:
          state?.pharmacyIV?.iv_dosage_duration_number ?? "",
        iv_dosage_duration_type:
          state?.pharmacyIV?.iv_dosage_duration_type ?? {},
        additive: true,
        additivT: "Yes",
        explanatoryNote: state?.pharmacyIV?.explanatoryNote ?? "",
        practitionerInstructions:
          state?.pharmacyIV?.practitionerInstructions ?? "",
      };
      seqnumber = seqnumber + 1;
      array.push(obj);
    });
    return { array, seqnumber };
  };
  childernEditJsonConstruct = (
    data,
    parentordercatalog,
    parentSeqNumber,
    sequencyNumber
  ) => {
    const state = JSON.parse(JSON.stringify(this.state));
    let array = [];
    let seqnumber = sequencyNumber;
    data?.map((val, i) => {
      let obj = {
        parentordercatalog: parentordercatalog,
        typeId: state?.pharmacyIV?.orderCategory?.value ?? "",
        seqNo: val?.seqNo ? val?.seqNo : seqnumber + 1,
        parentSequence: parentSeqNumber ?? "",
        parentSequenceT: parentSeqNumber ?? "-",
        orderCategory: state?.pharmacyIV?.orderCategory ?? {},
        orderTypeDrugType: state?.pharmacyIV?.orderTypeDrugType ?? {},
        orderCatalog: val?.drugeCatalog ?? {},
        required: val?.required ?? false,
        requiredT: val?.required
          ? val?.required === true
            ? "Yes"
            : "No"
          : "No",

        quantity_number: val?.quantity_number ?? 0,
        quantity_type: val?.quantity_type ?? {},
        quantityT:
          val?.quantity_number && val?.quantity_type?.label
            ? val?.quantity_number + " " + val?.quantity_type.label
            : "-",
        dosage_freq_code: state?.pharmacyIV?.dosage_freq_code ?? {},
        startDay: state?.pharmacyIV?.startDay ?? "",
        duration_number: state?.pharmacyIV?.duration_number ?? 0,
        duration_type: state?.pharmacyIV?.duration_type ?? {},
        durationT:
          state?.pharmacyIV?.duration_number &&
            state?.pharmacyIV?.duration_type?.label
            ? state?.pharmacyIV?.duration_number +
            " " +
            state?.pharmacyIV?.duration_type?.label
            : "-",
        route: state?.pharmacyIV?.route ?? {},
        routeT: state?.pharmacyIV?.route?.label
          ? state?.pharmacyIV?.route?.label
          : "-",
        frequency_number: state?.pharmacyIV?.frequency_number ?? 0,
        frequency_type: state?.pharmacyIV?.frequency_type ?? {},
        frequencyT:
          state?.pharmacyIV?.frequency_number &&
            state?.pharmacyIV?.frequency_type?.label
            ? state?.pharmacyIV?.frequency_number +
            " " +
            state?.pharmacyIV?.frequency_type?.label
            : "-",
        rate_number: state?.pharmacyIV?.rate_number ?? 0,
        rate_type: state?.pharmacyIV?.rate_type ?? {},
        rateT:
          state?.pharmacyIV?.rate_number && state?.pharmacyIV?.rate_type?.label
            ? state?.pharmacyIV?.rate_number +
            " " +
            state?.pharmacyIV?.rate_type?.label
            : "-",
        in_infusion_rate_volume_number:
          state?.pharmacyIV?.in_infusion_rate_volume_number ?? "",
        in_infusion_rate_volume_type:
          state?.pharmacyIV?.in_infusion_rate_volume_type ?? {},
        in_infusion_rate_duration_number:
          state?.pharmacyIV?.in_infusion_rate_duration_number ?? "",
        in_infusion_rate_duration_type:
          state?.pharmacyIV?.in_infusion_rate_duration_type ?? {},
        iv_dosage_duration_number:
          state?.pharmacyIV?.iv_dosage_duration_number ?? "",
        iv_dosage_duration_type:
          state?.pharmacyIV?.iv_dosage_duration_type ?? {},
        additive: true,
        additivT: "Yes",
        explanatoryNote: state?.pharmacyIV?.explanatoryNote ?? "",
        practitionerInstructions:
          state?.pharmacyIV?.practitionerInstructions ?? "",
      };
      if (!val?.seqNo) {
        seqnumber = seqnumber + 1;
      }
      array.push(obj);
    });
    return { array, seqnumber };
  };
  handleChange = (event, modalName) => {
    const temState = { ...this.state };
    temState[modalName][event.target.name] = event.target.checked;
    this.setState(temState);
  };
  saveForm = async () => {
    let { error, errorState } = await valitationFunc(this.state, errorList);

    if (error) {
      this.props.alert.setSnack("mandatory");
      this.setState({
        ...this.state,
        error: errorState,
      });
    } else {
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: "Updating...",
      });
      let payload = await this.props.UPSERT_ORDER_SET({ dataList: this.state });
      if (
        payload?.payload?.data?.validation_error?.[0]?.Errormsg?.includes(
          "unique"
        )
      ) {
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
          message: "",
        });
        this.props.alert.setSnack("unique");
        return false;
      } else {
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
          message: "",
        });
        let type = this.props.editData ? "update" : "success";
        this.props.alert.setSnack(type);
        this.props.saveForm();
      }
    }
  };

  render() {
    const {
      closeForm = () => null,
      saveForm = () => null,
      parent_id,
      classes
    } = this.props;
    return (
      <React.Fragment>
        {/* <CommonTabList
          parent_id={"agerange_form"}
          backbtn
          backFun={closeForm}
          title={this.props.editData ? "Edit Order Set" : "Add New Order Set"}
          list={[]}
        /> */}
        <Card className={classes.root}>
          <div
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 20px",
              }}
            >
              <div>
                <CommonTabList
                  parent_id={"agerange_form"}
                  backbtn
                  backFun={closeForm}
                  title={
                    this.props.editData ? "Edit Order Set" : "Add New Order Set"
                  }
                  list={[]}
                />
              </div>
              <div style={{ marginTop: "16px", float: "right" }}>
                {this.props?.editData && (
                  <button
                    style={{
                      border: "none",
                      color: "#0071F2",
                      textDecoration: "underline",
                      backgroundColor: "#ffffff",
                      fontSize: "12px",
                      fontFamily: "poppin",
                      cursor: "pointer",
                      paddingRight: 10,
                    }}
                    onClick={() => this.handleApplicabilityOpen()}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ fontFamily: "poppin", fontSize: "12px" }}>
                        Order Set Applicability
                      </div>
                      <img
                        src={door}
                        alt="Icon"
                        style={{ marginLeft: "5px" }}
                      />
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Card>
        <Grid id={"agerange_form_OrderSetDetails"} style={{ padding: "20px" }}>
          <OrderSetDetails
            parent_id={"agerange_form_OrderSetDetails"}
            handleFormState={this.handleFormState}
            isEditStatus={this.props?.editData}
            {...this.state}
          />
        </Grid>
        <Grid id={"agerange_form_OrderSetDetails"} style={{ padding: "20px" }}>
          <ServiceDrugCatalogList
            parent_id={"agerange_form_ServiceDrugCatalogList"}
            handleFormState={this.handleFormState}
            handleModalState={this.handleModalState}
            handleChange={this.handleChange}
            additiveStateChange={this.additiveStateChange}
            additiveHandleChange={this.additiveHandleChange}
            addNewAdditive={this.addNewAdditive}
            handleModal={this.handleModal}
            handleEditModal={this.handleEditModal}
            handleSave={this.handleSave}
            handleUpdate={this.handleUpdate}
            handleModalChange={this.handleModalChange}
            deleteTableRow={this.deleteTableRow}
            {...this.state}
          />
        </Grid>
        <Grid
          id={"agerange-form-OrderSetDetails-grid"}
          style={{ padding: "20px" }}
        >
          {this.props.editData ? (
            <Button
              id={"agerange_form_OrderSetDetails_Save_button"}
              onClick={() => this.saveForm()}
              style={{ float: "right" }}
              variant="contained"
              color="primary"
            >
              {/* {this.props?.orderSetUpsert?.loading ? "Updating..." : "Update"} */}
              {"Update"}
            </Button>
          ) : (
            <Button
              id={"agerange_form_OrderSetDetails_Save_button"}
              onClick={() => this.saveForm()}
              style={{ float: "right" }}
              variant="contained"
              color="primary"
            >
              {/* {this.props?.orderSetUpsert?.loading ? "Saving..." : "Save"} */}
              {"Save"}
            </Button>
          )}
          <Button
            id={"agerange_form_OrderSetDetails_Cancel_button"}
            onClick={() => closeForm()}
            style={{ float: "right", marginRight: "20px" }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>

        <OverlayCompt
          parent_id={"consult-master"}
          open={this.state?.openForm_}
          children={
            <React.Fragment>
              {this?.state?.openForm_ && (
                <OrderSetApplicabilityTabel
                  parent_id={"consult-master"}
                  editData={this.state?._id}
                  closeForm_={this.handleForm_}
                  saveForm_={this.saveForm}
                  editShortDes={this.state?.shortdes}
                  edit_id={this.state?._id}
                />
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  //orderSetUpsert: state?.orderSetMasterSlice?.order_set_upsert,
  orderSetMasters: state?.orderSetMasterSlice?.order_set_masters,
  order_set_by_id: state?.orderSetMasterSlice?.order_set_single_read,
});

export default connect(mapStateToProps, actions)(withAllContexts(withStyles(styles)(OrderSetForm)));
