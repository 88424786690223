import React from "react";
// import AdministrationTabPanel from "../../components/administrationtab";
import AdminTabPanel from "./adminTabPanel";
class Administration extends React.Component {
  render() {
    return (
      <div id={"admintab-parent-div"}>
        {/* <AdministrationTabPanel /> */}
        <AdminTabPanel id={"admintabpanel"} />
      </div>
    );
  }
}

export default Administration;
