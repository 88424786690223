import React from "react";
import { makeStyles, Grid, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import EntityDetails from "./components/EntityDetails";
import Identification from "./components/Identification";
import Alias from "./components/Alias";
import Address from "./components/Address";
import Contact from "./components/Contact";
import AddNewDetails from "./components/AddNewDetails";
import Speciality from "./components/speciality";
import { Row } from "qdm-component-library";
import { CustomTabs } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { withAllContexts } from "../../HOCs";
import { getEntityTypeCode, valitationFunc } from "../../utils";
import SystemConfiguration from "./components/SystemConfiguration";
const useStyles = makeStyles((theme) => ({
  titleDev: {
    height: "10vh",
    // backgroundColor: "#fff",
    background: theme.palette.background.table,
    display: "flex",
    alignItems: "center",
    paddingLeft: "2%",
    position: "relative",
  },
  left_arrow: {
    padding: 1,
    backgroundColor: "#DCDCDC",
    marginRight: 8,
    "& svg": {
      fontSize: 20,
      fontWeight: "bold",
      paddingLeft: 4,
    },
    boxShadow: "0 0px 1px 0 rgb(0 0 0 / 20%)",
    border: "1px solid #dddddd;",
  },
  wrapper: {
    background: theme.palette.background.common,
  },
  containedButton: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },
}));

const MainForm = (props) => {
  // main from
  const { parent_id } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const organizationSlice = useSelector((state) => state.organizationSlice);
  const [state, setState] = React.useState({
    EntityType: {},
    EntityCode: "",
    EntityName: "",
    ParentEntity: {},
    LevelOfCare: {},
    Identification: [
      {
        startdate: "",
        enddate: "",
        use: {},
        idtype: {},
        idnumber: "",
      },
    ],
    Alias: [],
    Address: [],
    Contact: [
      {
        code: {},
        mode: {},
        number: "",
        use: {},
        priority: {},
      },
    ],
    ContactPersonDetails: [],
    orgdateformat: "",
    orgtimeformat: "",
    orgdatetimeformat: "",
    specialtyDetails: [],
    // EditArr
    ContactPersonDetailsEditArr: [],
    AddressEditArr: [],
    AliasEditArr: [],
    // editIndex
    editIndex: null,
    imageDetails: [],
    error: {},
  });
  React.useLayoutEffect(() => {
    const getdata = async () => {
      if (props?.handelkey) {
        props.backDrop.setBackDrop({
          ...props.backDrop,
          open: true,
          message: "Loading...",
        });
        await dispatch(
          actions.ORG_FROM_GET({
            key: props.handelkey,
          })
        );
      }

      // Fetching System Config Options
      await dispatch(actions.ORGANIZATION_GET_DATE_FORMAT());
      await dispatch(actions.ORGANIZATION_GET_TIME_FORMAT());
      await dispatch(actions.ORGANIZATION_GET_DATE_TIME_FORMAT());
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: false,
        message: "Loading...",
      });
    };

    getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props?.handelkey) {
      let getState = organizationSlice?.getOrganization?.data;
      getState = JSON.stringify(getState);
      getState = JSON.parse(getState);

      setState({
        ...state,
        key: getState?._key,
        EntityType: getState?.EntityType_,
        EntityCode: getState?.EntityCode_,
        EntityName: getState?.EntityName_,
        LevelOfCare: getState?.LevelOfCare_,
        Identification: getState?.Identification_,
        Alias: getState?.Alias_,
        Address: getState?.Address_,
        ContactPersonDetails: getState?.ContactPersonDetails_,
        Contact: getState?.Contact_,
        imageDetails: getState?.imageDetails_,
        ParentEntity: getState?.ParentEntity_ ? getState?.ParentEntity_[0] : {},
        specialtyDetails: getState?.specialtyDetails,
        external: getState?.external,
        orgdateformat: getState?.orgdateformat,
        orgtimeformat: getState?.orgtimeformat,
        orgdatetimeformat: getState?.orgdatetimeformat,
      });
      if (getState?.EntityType_?.value) {
        dispatch(
          actions.ORGANIZATION_GET_PARENT_ENTITY({
            isexternal: getState?.external,
            entityType: getEntityTypeCode(getState?.EntityType_?.code),
          })
        );
      }
    } else {
      setState({
        ...state,
        external: props?.isexternal,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    organizationSlice?.getOrganization?.data,
    organizationSlice?.orgDateFormat?.data,
    organizationSlice?.orgTimeFormat?.data,
    organizationSlice?.orgDateTimeFormat?.data,
  ]);

  const onChangeAll = (e, val, types, index, Kvalue) => {
    if (types === "EntityCode") {
      if (val?.length > 20) {
        return false;
      }
    }
    //set error false
    if (state.error[types]) {
      state.error[types] = false;
    }
    //on change
    if (types === "Identification" || types === "Contact") {
      if (types === "Contact") {
        if (Kvalue === "mode") {
          state[types][index]["number"] = "";
        }
      }
      state[types][index][Kvalue] = val;
      setState({ ...state });
    } else if (
      types === "Alias" ||
      types === "Address" ||
      types === "ContactPersonDetails" ||
      types === "specialtyDetails"
    ) {
      if (state.editIndex || state.editIndex === 0) {
        state[types][state.editIndex] = val;
        state.editIndex = null;
        state[types + "EditArr"] = [];
      } else {
        if (types === "specialtyDetails" && index) {
          state[types][index - 1] = val;
        } else {
          state[types].push(val);
        }
      }
      setState({ ...state });
    } else {
      if (val?.title === "Enterprise") {
        if (types === "EntityType") {
          setState({ ...state, ParentEntity: {}, [types]: val });
        }
      } else if (types === "EntityType" || types === "external") {
        if (types === "external") {
          setState({ ...state, ParentEntity: {}, [types]: val });
          dispatch(
            actions.ORGANIZATION_GET_PARENT_ENTITY({
              isexternal: val ?? false,
              entityType: getEntityTypeCode(state?.EntityType?.code),
            })
          );
        } else {
          setState({ ...state, ParentEntity: {}, [types]: val });
          dispatch(
            actions.ORGANIZATION_GET_PARENT_ENTITY({
              isexternal: state?.external ?? false,
              entityType: getEntityTypeCode(val?.code),
            })
          );
        }
      } else {
        setState({ ...state, [types]: val });
      }
    }
  };
  const saveFuncation = async () => {
    // let Isidentification = state.Identification?.map(
    // 	(val) =>
    // 		val.startdate != "" &&
    // 		val.enddate != "" &&
    // 		val.idnumber != "" &&
    // 		val.use.value != "" &&
    // 		val.idtype.value != ""
    // ).filter((val) => val != true);
    let ParentEntityIs = state.EntityType.title === "Enterprise" ? true : false;

    let getEmailsList = state?.Contact?.filter(
      (val) => val?.mode?.title === "Email"
    );
    var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    let emailCheck = getEmailsList.filter(
      (val, i) => !val?.number.match(mailformat)
    );
    let errorList = ["EntityType", "EntityCode", "EntityName", "LevelOfCare"];
    if (!ParentEntityIs) {
      errorList.push("ParentEntity");
    }
    let { error, errorState } = await valitationFunc(state, errorList);

    // Contact validation
    let contactErrlist = ["number", "mode", "use", "priority"];

    let contactErr = false;

    if (!error) {
      state &&
        state.Contact &&
        state.Contact.forEach((val) => {
          if (val?.mode?.title === "Phone") {
            contactErrlist = ["number", "code", "mode", "use", "priority"];
          }
          let contactErrState = {};
          contactErrlist.forEach((v) => {
            if (v === "number") {
              if (val[v] === "") {
                contactErrState[v] = true;
                contactErr = true;
              }
            } else {
              if (Object.keys(val[v])?.length === 0) {
                contactErrState[v] = true;
                contactErr = true;
              }
            }
          });
          val["error"] = contactErrState;
        });
    }

    if (
      !error &&
      (!ParentEntityIs ? state.ParentEntity.value : true) &&
      state.LevelOfCare.value &&
      state.Address.length > 0 &&
      !contactErr &&
      // state.ContactPersonDetails.length > 0 &&
      // Isidentification.length === 0 &&
      emailCheck.length === 0
    ) {
      let resData = await dispatch(
        actions.SET_ORGANIZATION({
          state: state,
          key: state.key,
        })
      );
      if (resData.payload.data.error) {
        props.alert.setSnack(
          resData.payload.data.validation_error[0].Errormsg.includes("unique")
            ? "unique"
            : "error"
        );
        return false;
      }
      props.alert.setSnack(state.key ? "update" : "success");
      await dispatch(
        actions.ORGANIZATION_TREE_READ({ isexternal: state?.external ?? false })
      );

      props.getDat(props?.handelkey);
      props.handleEdit(props?.handelkey, state?.external);
    } else {
      let customMsg = error
        ? "Please fill all the mandatory field"
        : emailCheck.length > 0
          ? "Please fill contact Details Email ID"
          : state.Address.length === 0
            ? "Please Add Address"
            : // : state.ContactPersonDetails.length === 0
            contactErr
              ? "Please Add Contact Details"
              : "Please fill all the mandatory field";
      props.alert.setSnack("error", customMsg);
      setState({ ...state, error: errorState });
    }
  };
  const deleteFun = (types, index) => {
    let filtered = state[types].filter(function (value, i, arr) {
      return i !== index;
    });
    state[types] = filtered;
    setState({ ...state });
  };
  const editFun = (types, index) => {
    let filtered = state[types].filter(function (value, i, arr) {
      return i === index;
    });
    state[types + "EditArr"] = filtered;
    state.editIndex = index;
    setState({ ...state });
  };

  const handleSystemConfigurationChange = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const getTablist = () => {
    let list = [
      {
        header: (<span>
          ALIAS
        </span>),
        component: (
          <div id={`${parent_id}-Alias-div`} className={classes.wrapper}>
            <Alias
              parent_id={"org-Alias"}
              onChangeAll={onChangeAll}
              Aliasdata={state.Alias}
              editData={state.AliasEditArr}
              deleteFun={deleteFun}
              editFun={editFun}
            />
          </div>
        ),
      },
      {
        header: (
          <span>
            ADDRESS <span style={{ color: "red" }}>*</span>
          </span>
        ),
        component: (
          <div id={`${parent_id}-Address-div`} className={classes.wrapper}>
            <Address
              parent_id={"org-Address"}
              onChangeAll={onChangeAll}
              Addressdata={state.Address}
              editData={state.AddressEditArr}
              deleteFun={deleteFun}
              editFun={editFun}
            />
          </div>
        ),
      },
      {
        header: (
          <span>
            CONTACT DETAILS<span style={{ color: "red" }}> *</span>
          </span>
        ),
        component: (
          <div
            id={`${parent_id}-CONTACT-DETAILS-div`}
            className={classes.wrapper}
          >
            <Contact
              parent_id={"CONTACT-DETAILS"}
              onChangeAll={onChangeAll}
              Contactdata={state.Contact}
              deleteFun={deleteFun}
            />
          </div>
        ),
      },
      {
        header: <span>CONTACT PERSON DETAILS</span>,
        component: (
          <div
            id={`${parent_id}-CONTACT-PERSON-div`}
            className={classes.wrapper}
          >
            <AddNewDetails
              parent_id={"AddNewDetails"}
              onChangeAll={onChangeAll}
              ContactPersonDetailsData={state.ContactPersonDetails}
              deleteFun={deleteFun}
              editFun={editFun}
              editData={state.ContactPersonDetailsEditArr}
            />
          </div>
        ),
      },
      {
        header: <span>SYSTEM CONFIGURATION</span>,
        component: (
          <div
            id={`${parent_id}-SYSTEM_CONFIGURATION-div`}
            className={classes.wrapper}
          >
            <SystemConfiguration
              parent_id={"SystemConfiguration"}
              orgdateformat={state?.orgdateformat}
              orgtimeformat={state?.orgtimeformat}
              orgdatetimeformat={state?.orgdatetimeformat}
              handleSystemConfigurationChange={handleSystemConfigurationChange}
            />
          </div>
        ),
      },
    ];
    // external is true remove the alias tab
    if (state?.external) {
      list = list.filter((head) => head.header !== "ALIAS");
    }

    /** @description entityType is Facility selected enable the Speciality from tab */
    if (state?.EntityType?.code === "OT003") {
      // OT003 is unique code for Facility
      let specialityObj = {
        header: "Applicable Speciality",
        component: (
          <div id={`${parent_id}-Speciality-div`} className={classes.wrapper}>
            <Speciality
              parent_id={"Speciality"}
              onChangeAll={onChangeAll}
              specialtyDetails={state?.specialtyDetails}
              deleteFun={deleteFun}
            />
          </div>
        ),
      };
      list.push(specialityObj);
    }

    return list;
  };
  return (
    <div id={`${parent_id}-parent-div`} className={classes.wrapper}>
      <Grid id={`${parent_id}-parent-grid`} className={classes.titleDev}>
        <IconButton
          id={`${parent_id}-left-arrow-iconbutton`}
          aria-label="left arrow"
          className={classes.left_arrow}
          onClick={() => props.handleEdit(props?.handelkey, state?.external)}
        >
          <ArrowBackIosIcon id={`${parent_id}-arrowbackIosicon`} />
        </IconButton>
        <span id={`${parent_id}-edit-and-add-Organization-span`}>
          {props?.handelkey ? "Edit Organization" : "Add Organization"}
        </span>
      </Grid>
      <EntityDetails
        parent_id={"EntityDetails"}
        onChangeAll={onChangeAll}
        data={state}
        edit={state.key}
        isexternal={state?.external}
      />

      {!state?.external && (
        // {!props?.isexternal &&
        <Identification
          parent_id={"Identification"}
          onChangeAll={onChangeAll}
          deleteFun={deleteFun}
          Identificationdata={state.Identification}
        />
      )}
      {/* <div>{JSON.stringify(this?.state)}</div> */}
      <div id={`${parent_id}-CustomTabs-div`} style={{ margin: 24 }}>
        <CustomTabs parent_id={"org-CustomTabs"} tabs={getTablist()} />
      </div>

      <Row
        id={`${parent_id}-buttons-row`}
        style={{
          display: "flex",
          justifyContent: "end",
          paddingTop: "2%",
          paddingRight: "3%",
        }}
      >
        <Button
          id={`${parent_id}-cancel-button`}
          onClick={() => {
            props.handleEdit(props?.handelkey, state?.external);
          }}
          variant={"outlined"}
        >
          cancel
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          id={`${parent_id}-Save-button`}
          variant={"contained"}
          color="primary"
          onClick={saveFuncation}
          className={classes.containedButton}
        >
          {props?.handelkey ? "Update" : "Save"}
        </Button>
      </Row>
    </div>
  );
};
export default withAllContexts(MainForm);

// export default ;
