import * as React from "react";
import PropTypes from "prop-types";
import { Routes } from "../router/routes";
import { useTheme } from "@material-ui/core";

const UserIcon = (props) => {
  const theme = useTheme();
  const color =
    window.location.pathname === props.route
      ? theme.sideBar.text.active
      : theme.sideBar.text.nonActive;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.8"
      height="14.24"
      viewBox="0 0 17.8 14.24"
    >
      <path
        d="M6,8a2.013,2.013,0,0,0-2,2V20.238a2.013,2.013,0,0,0,2,2H19.8a2.013,2.013,0,0,0,2-2v-8.01a2.013,2.013,0,0,0-2-2H12.919L10.931,8.568h0A2.447,2.447,0,0,0,9.364,8ZM6,9.335H9.364a1.111,1.111,0,0,1,.712.258l2.175,1.812a.668.668,0,0,0,.428.155H19.8a.657.657,0,0,1,.668.668v8.01a.657.657,0,0,1-.668.668H6a.657.657,0,0,1-.668-.668V10A.657.657,0,0,1,6,9.335Zm6.9,3.115a1.78,1.78,0,1,0,1.78,1.78A1.78,1.78,0,0,0,12.9,12.45ZM10.429,16.9a.644.644,0,0,0-.644.644v.581c0,.989,1.262,1.89,3.115,1.89s3.115-.9,3.115-1.89v-.581a.644.644,0,0,0-.644-.644Z"
        transform="translate(-4 -8)"
        fill={color}
      />
    </svg>
  );
};

export default UserIcon;

UserIcon.defaultProps = {
  color: "black",
};

UserIcon.propTypes = {
  color: PropTypes.string,
};
