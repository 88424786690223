import React from "react";
import { Row, Col, Select, TextInput } from "qdm-component-library";
import {
  FormLabel,
  makeStyles,
  Grid,
  Button,
  Popover,
  IconButton,
  useTheme,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from "@material-ui/icons/CreateOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { DateTimePicker } from "../../../components/common/smartForm/component";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  main: {
    // backgroundColor: "white",
		background: theme.palette.background.table,
    width: "100%",
    borderRadius: 8,
    padding: 14,
  },
  paddingLeft0: {
    paddingLeft: 0,
  },
  dot: {
    height: "7px",
    width: "7px",
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    marginTop: "5px",
    marginRight: "7px",
    marginLeft: "7px",
  },
  containedButtonTheme: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      background: theme.palette.button.hover,
    }
  },
  autocompletePaperTheme: {
    color: "#6a7888"
  }
}));
const styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
  },
  errorBorderStyle: {
    borderRadius: 8,
    border: "1px solid red",
  },
};
const Alias = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    startdate: "",
    enddate: "",
    aliastype: "",
    aliasname: "",
  });
  const { parent_id } = props;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const dispatch = useDispatch();
  const organizationSlice = useSelector((state) => state.organizationSlice);

  React.useLayoutEffect(() => {
    const getdata = async () => {
      await dispatch(actions.ORGANIZATION_GET_ALIAS_TYPE());
    };
    getdata();
  }, []);
  React.useEffect(() => {
    if (props?.editData?.length > 0) {
      setState({
        ...state,
        startdate: props.editData[0].startdate,
        enddate: props.editData[0].enddate,
        aliastype: props.editData[0].aliastype,
        aliasname: props.editData[0].aliasname,
      });
    }
  }, [props?.editData]);
  const { labelStyle, borderStyle, errorBorderStyle } = styles;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setState({
      ...state,
      startdate: "",
      enddate: "",
      aliastype: "",
      aliasname: "",
    });
    setAnchorEl(null);
  };

  const saveData = (e) => {
    if (props?.onChangeAll) props.onChangeAll(e, state, "Alias");
    handleClose();
  };

  const generateFun = (e, types, val) => {
    state[types] = val;
    setState({ ...state });
  };
  return (
    <Grid
      id={`${parent_id}-parent-grid`}
      className={classes.main}
      container
      direction="row"
    >
      <Grid id={`${parent_id}-parent-sub-grid`} container item xs={12}>
        <Grid id={`${parent_id}-parent-sublevel-grid`} item xs={12}>
          <Grid id={`${parent_id}-parent-container-grid`} container>
            <Grid
              id={`${parent_id}-title-grid`}
              item
              xs={8}
              style={{ textAlign: "left" }}
            >
              <p
                id={`${parent_id}-title-p`}
                style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}
              >
                ALIAS{" "}
              </p>
            </Grid>
            <Grid id={`${parent_id}-Add-New-grid`} item xs={4}>
              <Grid
                id={`${parent_id}-Add-New-button-grid`}
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Button
                  id={`${parent_id}-Add-New-button`}
                  className={classes.newRes}
                  color="primary"
                  onClick={handleClick}
                  // id={"general_appointment_card_Id"}
                >
                  + Add New
                </Button>
              </Grid>
            </Grid>
            {props?.Aliasdata?.map((val, i) => {
              return (
                <Grid
                  id={`${parent_id}-grid` + i}
                  container
                  style={{
                    border: "1px solid #E0E0E0",
                    padding: 12,
                    marginBottom: 8,
                    // backgroundColor: "#F9F9F9",
                    background: theme.palette.background.table,
                    position: "relative",
                  }}
                  direction="row"
                  alignItems="left"
                >
                  <Grid id={`${parent_id}-Details-grid` + i} item xs={6}>
                    <Grid
                      id={`${parent_id}-Details-sub-grid` + i}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="left"
                    >
                      <Grid
                        id={`${parent_id}-Details-sublevel-grid` + i}
                        item
                        xs={1}
                        style={{
                          backgroundColor: "#E0E0E0",
                          position: "absolute",
                          left: 0,
                          padding: "6px 12px",
                          borderTopRightRadius: 9,
                          borderBottomRightRadius: 9,
                        }}
                      >
                        {i + 1}
                      </Grid>
                      <Grid
                        id={`${parent_id}-Details-sublevel-div` + i}
                        item
                        xs={12}
                        style={{ marginLeft: "6%", textAlign: "left" }}
                      >
                        <div id={`${parent_id}-Details-title-div` + i}>
                          Details
                        </div>
                        <div id={`${parent_id}-Details-label-div` + i}>
                          {val?.aliastype?.title}{" "}
                          <div
                            id={`${parent_id}-Details-aliasname-div` + i}
                            className={classes.dot}
                          />
                          {val?.aliasname}
                          <div
                            id={`${parent_id}-Details-startdate-div` + i}
                            className={classes.dot}
                          />
                          {moment(val?.startdate).format("DD-MM-YYYY")}
                          <div
                            id={`${parent_id}-Details-enddate-div` + i}
                            className={classes.dot}
                          />
                          {moment(val?.enddate).format("DD-MM-YYYY")}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid id={`${parent_id}-parent-grid` + i} item xs={6}>
                    <Grid
                      id={`${parent_id}-parent-sub-grid` + i}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <IconButton
                        id={`${parent_id}-IconButton` + i}
                        aria-label="EditIcon"
                        className={classes.margin}
                        size="small"
                      >
                        <EditIcon
                          id={`${parent_id}-Alias-EditIcon` + i}
                          onClick={(e) => {
                            handleClick(e);
                            props.editFun("Alias", i);
                          }}
                          style={{ color: "#0071F2", fontSize: 18 }}
                        />
                      </IconButton>
                      <IconButton
                        id={`${parent_id}-IconButton` + i}
                        aria-label="DeleteIcon"
                        className={classes.margin}
                        size="small"
                        onClick={() => props.deleteFun("Alias", i)}
                      >
                        <DeleteIcon
                          id={`${parent_id}-Alias-DeleteIcon` + i}
                          style={{ color: "#FF4D4A", fontSize: 18 }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          PaperProps={{
            style: { width: "80%", padding: 20, background: theme.palette.background.table },
          }}
        >
          <Grid
            id={`${parent_id}-Alias-Type-parent-grid`}
            container
            direction="column"
          >
            <Row
              id={`${parent_id}-Alias-Type-parent-Row`}
              inLineStyles={{ paddingBottom: "2%" }}
            >
              <Col
                id={`${parent_id}-Alias-Type-Col`}
                md={3}
                lg={3}
                sm={3}
                xs={12}
                className={classes.paddingLeft0}
              >
                <FormLabel
                  id={`${parent_id}-Alias-Type-title-FormLabel`}
                  component="legend"
                  style={{ ...labelStyle, marginBottom: 10 }}
                >
                  Alias Type
                </FormLabel>

                <Autocomplete
                  id={`${parent_id}-Alias-Type-autocomplete`}
                  getOptionLabel={(option) => option.title}
                  style={{ width: "auto", marginTop: "1%" }}
                  size={"small"}
                  renderInput={(params) => (
                    <TextField
                      id={`${parent_id}-Alias-Type-textField`}
                      {...params}
                      variant="outlined"
                    />
                  )}
                  classes={{
                    paper: classes.autocompletePaperTheme
                  }}
                  options={
                    organizationSlice?.aliastype?.data
                      ? organizationSlice?.aliastype?.data
                      : []
                  }
                  onChange={(e, val) => generateFun(e, "aliastype", val)}
                  value={state?.aliastype}
                />
              </Col>
              <Col
                id={`${parent_id}-Alias-name-Col`}
                // className="no-padding-left"
                md={5}
                lg={5}
                sm={5}
                xs={12}
                className={classes.paddingLeft0}
              >
                <TextInput
                  id={`${parent_id}-Alias-name-textInput`}
                  label="Alias Name"
                  placeholder="Alias Name"
                  labelStyle={labelStyle}
                  hoverColor="#0071F2"
                  onChange={(e, val) =>
                    generateFun(e, "aliasname", e.target.value)
                  }
                  value={state?.aliasname}
                />
              </Col>
              <Col
                id={`${parent_id}-startdate-Col`}
                md={2}
                lg={2}
                sm={2}
                xs={12}
                className={classes.paddingLeft0}
              >
                <DateTimePicker
                  parent_id={"Start-Date"}
                  required={true}
                  label={"Start Date"}
                  value={state?.startdate ?? null}
                  onChange={(data) => generateFun(data, "startdate", data)}
                  className={classes.datePicker}
                />
              </Col>
              <Col
                id={`${parent_id}-enddate-col`}
                md={2}
                lg={2}
                sm={2}
                xs={12}
                className={classes.paddingLeft0}
              >
                <DateTimePicker
                  parent_id={"End-Date"}
                  required={true}
                  label={"End Date"}
                  value={state?.enddate ?? null}
                  onChange={(data) => generateFun(data, "enddate", data)}
                  className={classes.datePicker}
                  minDate={state?.startdate ?? null}
                />
              </Col>
            </Row>
          </Grid>
          <div id={`${parent_id}-buttons-div`} style={{ textAlign: "right" }}>
            <Button
              id={`${parent_id}-Cancel-button`}
              variant={"outlined"}
              color="primary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              id={`${parent_id}-Save-button`}
              variant={"contained"}
              color="primary"
              onClick={saveData}
              className={classes.containedButtonTheme}
            >
              Save
            </Button>
          </div>
        </Popover>
      </Grid>
    </Grid>
  );
};
export default Alias;
