import React from "react";
import { connect } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";

import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import update from "immutability-helper";
import {
  AlertProps,
  checkWithCasbin,
  DispenseDurationOptions,
  ERROR_MESSAGE,
  GenerateLabelPayload,
  getBrand,
  getDayDifference,
  getIsSingle,
} from "../../utils";
//import { ReactComponent as ForwardIcon } from "../../assets/forwardIcon.svg";
import { HandWithPillIcon, IssueMedication } from "../../assets";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
//import copy from "fast-copy";
import { AlertContext, LoggerContext } from "../../contexts";
import * as ALL from "../../components";
import {
  MedicationTopBar,
  DrugItem,
  ToggleButtons,
  StyledDialog,
  OrderItem,
} from "../../components";
import moment from "moment";
import {
  Grid,
  Divider,
  Typography,
  Box,
  Avatar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  withStyles,
  Tooltip,
  Checkbox,
  Slide,
  Popover,
  Paper,
  Tabs,
  Tab,
} from "@material-ui/core";
import { ComponentRender, CusCard } from "custom-material-ui-component";
import qdmstyles from "./styles.json";
import "./index.css";
import { GridIcon, ListIcon } from "../../assets";
import { Document, Page } from "react-pdf";
import classNames from "classnames";
import { NewOrderComponent } from "clmm-masters";
// import MedicationInfoForm from "./medicationInfoForm";
import logo from "./logo.svg";
import { IssueMedicationTable } from "./issueMedication/IssueMedicationTable";
import CloseIcon from "@material-ui/icons/Close";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
// import { headCells, rows } from "../sandbox/utils";
import List from "../../components/clinicInfoList/list";
import Allergy from "../../components/clinicInfoList/allergis";
import Diagnosis from "../../components/clinicInfoList/diagnosis";
import ChiefComplain from "../../components/clinicInfoList/chiefComplaints";
import funnel from "../../assets/icons8-funnel.svg";
import { withAllContexts } from "../../HOCs";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { differenceInCalendarDays } from "date-fns";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  topbarRightContainer: {
    display: "flex",
    gap: 12,
  },
  selectCountContainer: {
    display: "flex",
    gap: "12px",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
  },
  itemPresent: {
    // background: "#E6EFFF",
    // border: "1px solid #DEE5EC",
    color: "#fff",
    borderRadius: 4,
    background: theme.palette.primary.main,
    // color: theme.palette.text.primary
  },
  selectCountText: {
    // color: theme.palette.text.primary,
    color: "#fff",
    fontSize: 12,
    fontWeight: "500",
  },
  Maintitle: {
    color: theme.palette.text.primary,
  },
  MainBackground: {
    background: `${theme.palette.background.common} !important`,
  },
  bgWhite: {
    background: `${theme.palette.background.table} !important`,
    // background: `#ffffff !important`,
  },
  MainBackgroundCard: {
    background: `${theme.palette.background.table} !important`,
  },
  patientInfoCard: {
    cursor: "pointer",
  },
  allergy: {
    "& .custom-material-ui-component-MuiGrid-root": {
      background: `${theme.palette.background.table} !important`,
      color: theme.palette.text.primary,
    },
    "& svg": {
      color: theme.palette.text.primary,
    },
  },
  selectIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  iconsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 12,
    alignItems: "center",
  },
  previousNextMedications: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    "& button": {
      border: `1px solid ${theme.palette.text.primary} !important`,
    },
    "& svg": {
      color: theme.palette.text.primary,
    },
  },
  addNewMedicationPaper: {
    minWidth: 1300,
    borderRadius: 8,
  },
  addNewMedicationPaperDividers: {
    padding: 22,
    backgroundColor: theme.palette.background.common,
    // borderColor: "rgba(222, 229, 236, 1)",
  },
  commonBackground: {
    background: `${theme.palette.background.table} !important`,
    // backgroundColor: "#ffffff",
    // "& svg": {
    //   color: theme.palette.text.primary,
    //   "& path": {
    //     fill: `${theme.palette.text.primary}`,
    //     color: `${theme.palette.text.primary}`,
    //   }
    // }
  },
  encounterBanner: {
    "& svg": {
      color: `${theme.palette.text.primary}`,
    },
  },
  activeback: {
    background: theme.palette.primary.main,
  },
  activebacktext: {
    // color: theme.palette.text.primary
    color: "#fff",
  },
  dot: {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
  },
  tabs: {
    flexGrow: 1,
    maxWidth: 500,
    boxShadow: "none",
    marginBottom: "30px",
    background: theme.palette.background.table,
    // position: "absolute",
    width: "100%",
    zIndex: 10,
  },
  subDrawerHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 8,
    padding: 4,
  },
  closeBtn: {
    color: "#01205C",
    width: "32px",
    height: "32px",
    backgroundColor: "#DEE5EC",
    borderRadius: "50%",
  },
});

const funcList = [
  "PATIENT_QUEUE_STATUS_LIST",
  "DRUGTYPE_MASTER",
  "DRUGMASTER_MASTER",
  "STRENGTH_MASTER",
  "STRENGTHUOM_MASTER",
  "STRENGTHVOLUMN_MASTER",
  "STRENGTHVOLUMNUOM_MASTER",
  "DOSAGETYPEINDICATOR_MASTER",
  "DOSAGEUOM_MASTER",
  "DOSAGEVOLUMEUOM_MASTER",
  "DRUGROUTES_MASTER",
  "DOSAGEFORM_MASTER",
  "FREQUENCYMASTER_MASTER",
  "DURATIONTYPE_MASTER",
  "PRIORITY_MASTER",
  "DRUGCATEGORY_MASTER",
  "ORDERNATURE_MASTER",
  "STATUS_MASTER",
  "ORDERMODE_MASTER",
  "DRUGSTOREDISPLOC_MASTER",
  "DIAGNOSISAGAINSTENCOUNTER_MASTER",
  "REASONCODE_MASTER",
  "ORGANIZATION_MASTER",
  "PRACTITIONERROLE_MASTER",
  "PRACTITIONER_MASTER",
  "INFUSIONRATEUOM_MASTER",
  "INFUSIONTIMEUOM_MASTER",
  "TOTALVOLUMEUOM_MASTER",
  "INDICATION_REASON_CODE_MASTER",
  "BASESCHEDULE_MASTER",
  "PATIENT_INSTRUCTIONS_MASTER",
  "ALERT_REASON",
  "INSTRUCTION_MASTER_READ",
  "ORDER_PRESCRIPTION_MASTER",
  "EditNewFrequency_orgbase_frequency_dropdown",
  "schedule_line_day_dropdown",
];

//const isCardVerify = true;

const statusCode = [
  {
    color: "#000",
    value: "All",
  },
  {
    color: "#38C20A",
    value: "Active",
  },
  {
    color: "#F58B00",
    value: "InActive",
  },
  {
    color: "#6F6F6F",
    value: "Resolved",
  },
];

// const userRequst = JSON.parse(atob(localStorage.getItem("userInfoKeys")));

class PharmacyVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionData: null,
      selectedUI: "list",
      currentGridStartIndex: 0,
      selectedTab: 0,
      activeIndex: 0,
      vitalactiveIndex: 0,
      activeAllergy: {
        color: "#38C20A",
        value: "Active",
      },
      activeDiagnosis: {
        color: "#38C20A",
        value: "Active",
      },
      activeChiefcomplaint: {
        color: "#38C20A",
        value: "Active",
      },
      openBody: false,
      openBodyDiagnosis: false,
      openBodyChiefcomplaint: false,
      patientId: "",
      patientID: 0,
      queueDrawerOpen: true,
      isConsultation: true,
      search: "",
      drugs: [],
      pharmacyLoad: false,
      selectedState: {},
      reasondData: [],
      open: false,
      // activeSelect: "Active Medication",
      activeSelect: "Medication Profile",
      reportGrpCode: "",
      reportid: "",
      addnewmedication: false,
      pdfOpen: false,
      patientData: [],
      selectedActiveMedicationList: [],
      isApproval: false,
      isCardVerify: false,

      // Add new active Medication Dialog Open State
      activeMedicationOpen: false,
      activeMedicationEditId: "",
      CartTransId: "",
      Cartid: "",
      permission: [],
      selectOption: {},
      fin_data: [],
      issueMedication: false,
      issueMedicationList: [],
      activeMedFullScreen: false,
      fullScreenWorkFlow: false,
      totalQueueCount: 0,
      multiOrderSelect: [],
      drugIds: [],
      showMedicationProfile: false,
      ScenrioName: "",
      workflowView: "default",
      statusCodeActive: {
        color: "#38C20A",
        value: "Active",
      },
      openStatus: null,
      isQueue: 0,
      numPages: null,
    };
  }

  changeState = (key, value) => {
    if (key === "activeSelect" && value === "Medication Profile") {
      this.state.reportGrpCode = "";
      this.state.reportid = "";
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  handleWorkflowChange = (view) => {
    this.setState({
      workflowView: view,
    });
  };

  roleAccess = async (Role) => {
    let AccessData = await checkWithCasbin(Role);
    return AccessData;
  };

  async componentDidMount() {
    let Role = localStorage.getItem("role_name");
    let accessRights = await this.roleAccess(Role);
    //Calling QDM info Log
    // await this.props.logger?.setLogData("trace", "Rx management Intialized");
    const reasondData = await this.props.DISPENSE_AND_FILLING_REASON_DROPDOWN({
      reason: "DRUGFILLINGREASON",
    });

    // to get the Queue ID previously we used hard code data which is "queue/10013"
    await this.props.GETACCESSQUEUE();
    if (!this.props.queueId) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Something went Wrong in Permission! Unable to load queue",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
    //to get Scenarios
    const groupList = await this.props.GET_PRIORITY_LIST({
      QueueID: this.props.queueId,
    });

    await this.props.QUEUE_COUNT({
      QueueID: this.props.queueId,
      groupList: groupList?.payload?.data,
    });
    await this.props.ACCESSEDSCENARIOS();
    await this.props.ADDDRUG();
    const _data = reasondData?.payload?.data?.map((v) => {
      return {
        title: v?.display,
        year: v?._id,
      };
    });

    let isCardVerify = this.props?.location.state?.isCardVerify ? true : false;

    this.setState({
      ...this.state,
      reasondData: _data,
      isCardVerify: isCardVerify,
      permission: accessRights,
    });
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    if (!isCardVerify) {
      await this.props.PATIENT_QUEUE({
        id: this.props.queueId, //"queue/10013",
        search: "",
      });
    } else {
      const cartName = await this.props.CART_NAME();

      const result = cartName?.payload?.data
        ?.map((v) => {
          if (v?.cartName) {
            return {
              label: v?.cartName,
              value: v?.cartid,
              startDate: v?.startdate,
              endDate: v?.enddate,
              _id: v?._id,
              ward: v?.ward?.map((c) => {
                return {
                  label: c?.shortdesc ?? "",
                  value: c?._id ?? "",
                };
              }),
            };
          }
        })
        .filter((notUndefined) => notUndefined !== undefined);

      const subStore = await this.props.SUB_STORE({ id: result?.[0]?.value });

      const result_subStore = subStore?.payload?.data?.map((v) => {
        return {
          label: v?.locationDetails?.longdesc,
          Orgname: v?.cartname || v?.locationDetails?.longdesc,
          value: v?._id,
          locationid: v?.locationid,
        };
      });

      const Didupdate = this.props?.location.state;
      await this.props.CART_PATIENT_QUEUE({
        cartID: Didupdate?.cartID ?? "",
        locID: Didupdate?.locID ?? "",
        wardID: Didupdate?.wardID ?? "",
      });
    }

    // let patient = this.props.patientData[0];
    this.setState({
      ...this.state,
      totalQueueCount: this.props.patientData.length,
    });
    // await this.getDashboardData(patient);
    await this.getMasterData();

    /// setlogger && dispatch of all events

    /// fetch all permission
  }

  medicationProfile = async (n, v, full) => {
    await this.props.ACTIVEMEDIACATION_AGAINST_PATIENT({
      // id: this.props.patientData[0]._id,
      id: this.state?.patientId,
      encounter_Id:
        this.props.encounterData?.[this.state.activeIndex]?._id ?? "",
      reportGrpCode: full?.reportGrpCode,
      reportid: v?.reportid,
    });

    this.setState({
      ...this.state,
      activeSelect: v?.name,
      reportid: v?.reportid,
      reportGrpCode: full?.reportGrpCode,
    });

    if (this.state?.reportid) {
      Promise.resolve(
        await this.props.ACTIVEMEDIACATION_AGAINST_PATIENT({
          // id: this.props.patientData[0]._id,
          id: this.state?.patientId,
          encounter_Id:
            this.props.encounterData?.[this.state.activeIndex]?._id ?? "",
          reportGrpCode: this.state?.reportGrpCode,
          reportid: this.state?.reportid,
        })
      ).then(async (res) => {
        const { data = [] } = res?.payload;
        await this.props.GET_ACTIVE_MEDICATION_DATA_WITH_PH_ORDER_ID({
          orderLineIds: data?.map((item) => item?._id),
        });
      });
    }
  };

  async getMasterData() {
    new Promise(async (resolve, reject) => {
      let calls = funcList.map(async (val) => {
        return await this.props[val]();
      });
      Promise.all(calls).then(() => {
        resolve(true);
      });
    });
  }

  getMedicationDetails = async (patient) => {
    this.props.MEDIACATION_LIST({
      id: patient?._id,
      encounter_Id:
        this.props.encounterData?.[this.state.activeIndex]?._id ?? "",
    });

    if (this.state?.reportid) {
      Promise.resolve(
        await this.props.ACTIVEMEDIACATION_AGAINST_PATIENT({
          id: patient?._id,
          encounter_Id:
            this.props.encounterData?.[this.state.activeIndex]?._id ?? "",
          reportGrpCode: this.state?.reportGrpCode,
          reportid: this.state?.reportid,
        })
      ).then(async (res) => {
        const { data = [] } = res?.payload;
        await this.props.GET_ACTIVE_MEDICATION_DATA_WITH_PH_ORDER_ID({
          orderLineIds: data?.map((item) => item?._id),
        });
      });
    }
  };

  async getDashboardData(patient) {
    // this.handleCloseDrawer();

    await this.props.PATIENT_BANNER({ id: patient?._id });
    await this.props.ENCOUNTER_BANNER({ id: patient?.id });
    const fin_data = await this.props.FINANCIAL_BANNER({ id: patient?.id });

    if (fin_data?.payload?.data) {
      const fin_DATA = fin_data?.payload?.data?.map((v) => {
        const setdata = v?.payer_details?.[0];
        return {
          pic: logo,
          name: null,
          care_type: setdata?.guarantor_code,
          title_name: setdata?.receiver_type + ", " + setdata?.policy,
        };
      });
      this.setState({
        ...this.state,
        selectOption: fin_DATA[0],
        fin_data: fin_DATA,
      });
    }
    //commented need to verify with prakash
    // await this.props.GET_MEDICATIONS({
    //   id: patient?._id,
    // });

    await this.props.VITALS_AGAINST_ENCOUNTER({
      id: this.props.encounterData?.[this.state.activeIndex]?._id ?? "",
    });

    this.getMedicationDetails(patient);
    await this.props.ALLERGY_AGAINST_PATIENT({ id: patient?._id });
    await this.props.DIAGNOSIS_AGAINST_PATIENT({ id: patient?._id });
    await this.props.CHIEFCOMPLAINT_AGAINST_PATIENT({
      id: patient?._id,
    });
    if (this.props?.location.state?.isCardVerify) {
      await this.props.PHARMACY_GETTINGSTATE({
        queueId: this.props.queueId,
        scenarioId: this.props?.location.state?.isCardVerify
          ? "queuescenarios/10029"
          : "queuescenarios/10019",
        isCart: this.props?.location.state?.isCardVerify,
        patientid: patient?._id,
      });
    } else {
      await this.props.GETQUEUELIST();
      await this.props.ORDERCOUNT({
        queueId: this.props.queueId,
        patientid: patient?._id,
      });
    }

    if (this.props?.location.state?.isCardVerify) {
      let SelectedTap = this.props.pharmacyState?.[0];
      await this.props.PHARMACY_FILLING_LISTING({
        FirstState: SelectedTap,
        stateid: SelectedTap?.id,
        patientid: patient?._id,
        isCart: this.props?.location.state?.isCardVerify,
      });
    } else {
      let SelectedTap = this.props.pharmacyState?.[0];
      if (
        this.props.pharmacyState?.[0]?.label?.toLowerCase() === "verification"
      ) {
        await this.props.PHARMACY_FRAMEWORK({
          FirstState: this.props.pharmacyState?.[0],
          stateid: this.props.pharmacyState?.[0]?.id,
          patientid: patient?._id,
          isCart: this.props?.location.state?.isCardVerify,
        });
      } else {
        await this.props.PHARMACY_FILLING_LISTING({
          FirstState: SelectedTap,
          stateid: SelectedTap?.id,
          patientid: patient?._id,
          isMedication:
            SelectedTap?.scenarioid === "queuescenarios/10022" ||
            SelectedTap?.scenarioid === "queuescenarios/10022",
          isCart: this.props?.location.state?.isCardVerify,
          isReisse: SelectedTap?.scenrioname === "Re-Issue",
        });
      }
    }

    this.setState({
      ...this.state,
      patientId: patient?._id,
      patientID: patient?.id,
      isConsultation: patient?._id ? true : false,
      drugs: this.props.pharmacyWorkflowData,
      vitalactiveIndex: this.props.vitalsData?.length - 1 ?? 0,
      pharmacyLoad: false,
      selectedState: this.props.pharmacyState?.[0],
      states: this.props.pharmacyState,
      selectedTab: 0,
      patientData: this.props.patientData,
      // totalQueueCount: this.props.patientData.length,
      multiOrderSelect: [],
      drugIds: [],
    });
  }

  ///fetching all permission
  shouldComponentUpdate(props, state) {
    return true;
  }

  handleDrawerOpen() {
    this.setState({
      ...this.state,
      queueDrawerOpen: true,
    });
  }

  handleCloseDrawer = () => {
    this.setState({
      ...this.state,
      queueDrawerOpen: false,
    });
  };

  handleTabChange = async (event, newValue) => {
    if (this.props?.location.state?.isCardVerify) {
      await this.props.PHARMACY_GETTINGSTATE({
        queueId: this.props.queueId,
        scenarioId: this.props?.location.state?.isCardVerify
          ? "queuescenarios/10029"
          : "queuescenarios/10019",
        isCart: this.props?.location.state?.isCardVerify,
        patientid: this.state.patientId,
      });
    } else {
      await this.props.GETQUEUELIST();
      await this.props.ORDERCOUNT({
        queueId: this.props.queueId,
        patientid: this.state.patientId,
      });
    }

    let SelectedTap = this.props.pharmacyState?.[newValue];
    if (SelectedTap?.label?.toLowerCase() === "verification") {
      await this.props.PHARMACY_FRAMEWORK({
        FirstState: {
          scenarioid: SelectedTap?.scenarioid,
          queueid: SelectedTap?.queueid,
        },
        stateid: SelectedTap?.id,
        patientid: this.state.patientId,
        isCart: this.props?.location.state?.isCardVerify,
      });
      // await this.handleRefreshButtonClick();
    } else {
      await this.props.PHARMACY_FILLING_LISTING({
        FirstState: SelectedTap,
        stateid: SelectedTap?.id,
        patientid: this.state.patientId,
        isMedication:
          SelectedTap?.scenarioid === "queuescenarios/10022" ||
          SelectedTap?.scenarioid === "queuescenarios/10022",
        //  || this.state.selectedState?.id === "queuestates/10048"
        isCart: this.props?.location.state?.isCardVerify,
        // pendingApprovel: newValue === 5
        isReisse: SelectedTap?.scenrioname === "Re-Issue",
      });
    }
    this.setState({
      selectedTab: newValue,
      drugs: this.props.pharmacyWorkflowData,
      selectedState: SelectedTap,
      multiOrderSelect: [],
      drugIds: [],
    });
    // this.forceUpdate();
  };

  handlePrintButtonClick = async () => {
    // let orderLine = this.state.drugs;

    let date = JSON.parse(localStorage.getItem("orgFormat"));
    const resLabel = await this.props.LABEL_PRESCRIPTION({
      encounterid:
        this.props.encounterData?.[this.state.activeIndex]?._id ?? "",
      patientid: this.state.patientId,
      dateformat:
        "%" +
        date?.orgdateformat?.display?.toLowerCase()?.replaceAll("/", "/%"),
      datetimeformat:
        "%" +
        date?.orgdatetimeformat?.display
          ?.toLowerCase()
          ?.replaceAll("/", "/%")
          ?.replaceAll(" ", " %")
          ?.replaceAll(":mm", ":%ii"),
      // dateformat: "%dd/%mm/%yyyy",
      // datetimeformat: "%dd/%mm/%yyyy %hh:%ii"
    });
    const payload = GenerateLabelPayload(resLabel?.payload?.data?.[0] ?? {});
    await this.props.LABEL_PRINT({
      Body: payload,
    });

    this.setState({
      ...this.state,
      pdfOpen: true,
    });
  };
  handleClose = () => {
    this.setState({ ...this.state, pdfOpen: false });
  };

  // Check for the dispenseId State
  checkDispenseState = (dispenseId) => {
    return Boolean(this.state?.[dispenseId]);
  };

  // Drug Item Handlers
  handleApplyClick = (dispenseId) => {
    // If no state exists for the current dispense, then return
    if (!this.checkDispenseState(dispenseId)) return;

    const currentDispenseState = this.state?.[dispenseId];
    if (currentDispenseState?.dispenseDuration) {
      this.setState({
        [dispenseId]: {
          ...currentDispenseState,
          isDispenseDurationApplied: true,
        },
      });
    }
  };

  // Change Handlers
  handleChange = (value, key, dispenseId) => {
    const currentState = this.state?.[dispenseId];
    this.setState({
      [dispenseId]: {
        ...currentState,
        dispenseFrequency:
          currentState?.dispenseFrequency || DispenseDurationOptions[0].value, // default value for frequency is 1
        [key]: value,
      },
    });
  };

  handleDoseType = async (value, drug, index) => {
    const currentState = JSON.parse(JSON.stringify(this.state));
    // currentState.drugs[index].disptype = value?._id ?? "";
    currentState.drugs[index].disptype = value ?? {};
    this.setState({
      ...currentState,
    });

    let SelectedTap = this.props.pharmacyState?.[this.state.selectedTab];

    await this.props.PHARMACY_CALCULATE_DOSESTRENGTH_STOCK({
      order: currentState.drugs[index],
      SelectedOrder: SelectedTap,
    });

    this.setState({
      ...this.state,
      drugs: this.props.pharmacyWorkflowData,
    });

    // window.location.reload();
  };

  getDayDiff = (date1, date2) => {
    const dayStart = moment.unix(date1).startOf("day");
    const dayEnd = moment.unix(date2).startOf("day");

    return dayEnd.diff(dayStart, "days");
  };
  checkRefillDuration = (drug, value) => {
    if (drug?.Recievednextrefilldate > 0 && value) {
      let refilDateDiff =
        this.getDayDiff(drug?.Recievednextrefilldate, drug?.occurEndDateTime) +
        1;
      if (value <= refilDateDiff) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  checkMaxDura = (value, max) => {
    if (max) {
      if (value <= max) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  getYearDayMonth = (date) => {
    const d = new Date(date * 1000);
    return {
      month: d.getMonth() - 1,
      day: d.getDate(),
      year: d.getFullYear(),
      hours: d.getHours(),
      sec: d.getSeconds(),
    };
  };

  Days = (start, end) => {
    let starDate = this.getYearDayMonth(start);
    let endDate = this.getYearDayMonth(end);
    return differenceInCalendarDays(
      new Date(
        endDate.year,
        endDate.month,
        endDate.day,
        endDate.hours,
        endDate.sec
      ),
      new Date(
        starDate.year,
        starDate.month,
        starDate.day,
        starDate.hours,
        starDate.sec
      )
    );
  };

  handleDispenseDurationChange = async (value, drugId, index) => {
    const currentState = JSON.parse(JSON.stringify(this.state));
    let filteredDrugs = currentState.drugs.find((drug) => drug.id === drugId);
    //number days medication ordered
    let dosageDays = this.Days(
      filteredDrugs.occurStartDateTime,
      filteredDrugs.occurEndDateTime
    );

    const isReverify =
      filteredDrugs?.PH_OrderLineDispenseSchedule?.[0]?.statuscode === "RVER";

    if (
      isReverify
        ? filteredDrugs?.dosageDurationValue >= value
        : value <= dosageDays &&
        this.checkRefillDuration(filteredDrugs, value) &&
        this.checkMaxDura(
          value,
          filteredDrugs?.dispenseDurationValue?.maxDefaultDurationVal
        )
    ) {
      filteredDrugs.deliveringDispenseDuration = value;
      let latearrival = this.props?.loggerUserInfo?.location?.locconfig?.find(
        (val) => val?.code === "considerlatearrival"
      )?.value;

      currentState.drugs[index] = filteredDrugs;
      let startDate =
        filteredDrugs.scenarioId?.[0]?._id === "queuescenarios/10020"
          ? filteredDrugs?.Recievednextrefilldate
          : filteredDrugs?.occurStartDateTime;
      if (value) {
        let curr = new Date();
        let prev = new Date(startDate * 1000).getTime();
        curr.setTime(prev);
        // curr.setDate(new Date().getDate());

        let endCurr = new Date();
        let prevend = new Date(filteredDrugs.occurEndDateTime * 1000).getTime();
        endCurr.setTime(prevend);
        // endCurr.setDate(new Date().getDate());

        const payload = [
          {
            // freqcode: filteredDrugs.dosageFreqCode_id,
            drug_id: filteredDrugs.dispenseDurationValue?.drug_id,
            dosageDurationValue:
              filteredDrugs?.scenarioId?.[0]?._id === "queuescenarios/10020"
                ? this.getDayDiff(
                  filteredDrugs?.Recievednextrefilldate,
                  filteredDrugs?.occurEndDateTime
                )
                : filteredDrugs?.dosageDurationValue,
            OrderId: filteredDrugs.OrderId,
            OrderStartDate: moment(curr).unix(),
            OrderEndDate: moment(endCurr).unix(),
            DispenseDurationValue: JSON.parse(value),
            dosageDurationUOM: filteredDrugs?.dosageDurationUOM_id,
            disptype: filteredDrugs?.disptype?.code ?? "",
            IsFirstDuration: false,
            considerlatearrival: latearrival === "true" ? true : false,
            scenarioname: filteredDrugs?.scenarioId?.[0]?.scenarioname
              ?.replaceAll(" ", "")
              ?.toLocaleLowerCase(),
          },
        ];

        // if (filteredDrugs?.ScenarioName === 'IP') {
        //   payload['isCart'] = filteredDrugs?.ScenarioName === 'IP'
        // }

        if (this.state.isCardVerify) {
          payload["isCart"] = true;
        }

        const data = await this.props.DGET_DISPENSE_DURATION({ list: payload });
        if (data?.payload?.data?.length > 0) {
          if (this.state.isCardVerify) {
            // if (data?.payload?.data[0]?.dispStartDate) {
            //   currentState.drugs[index]['occurStartDateTime'] = data?.payload?.data[0]?.dispStartDate
            // }
            // if (data?.payload?.data[0]?.dispEndDate) {
            //   currentState.drugs[index]['occurEndDateTime'] = data?.payload?.data[0]?.dispEndDate
            // }
            if (data?.payload?.data[0]?.cartid) {
              currentState.drugs[index]["Cartid"] =
                data?.payload?.data[0]?.cartid;
            }
            if (data?.payload?.data[0]?.cartTransId) {
              currentState.drugs[index]["CartTransId"] =
                data?.payload?.data[0]?.cartTransId;
            }

            if (this.props.encounterData[this.state.activeIndex]?.type) {
              currentState.drugs[index]["EncounterType"] =
                this.props.encounterData[this.state.activeIndex]?.type;
            }
            if (data?.payload?.data[0]?.dispDuration) {
              currentState.drugs[index].dispenseDurationValue["dispDuration"] =
                data?.payload?.data[0]?.dispDuration;
            }
          }
          filteredDrugs.dispenseDurationValue = data?.payload?.data[0];
          filteredDrugs.nextrefilldate =
            data?.payload?.data[0]?.nextRefillDate ?? 0;
        }
      }

      currentState.drugs[index] = filteredDrugs;

      this.setState({
        ...currentState,
      });
    } else {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Dispense duration should not be greater than ordered duration!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
  };

  handleDispenseFrequencyChange = (value, drugId) => {
    const updatedDrugs = this.state.drugs?.map((drug) => {
      if (drug?.id === drugId) {
        drug.dispenseFrequency = value;
        return drug;
      }
      return drug;
    });

    this.setState({
      drugs: updatedDrugs,
    });
  };

  handleDelete = async (_i, i) => {
    if (_i === 0) {
      return;
    }
    const currentState = JSON.parse(JSON.stringify(this.state));
    const _d = currentState.drugs[i].brands?.filter((v, n) => n !== _i && v);
    currentState.drugs[i].brands = _d;
    this.setState({
      ...currentState,
    });
    let SelectedTap = this.props.pharmacyState?.[this.state.selectedTab];

    await this.props.PHARMACY_MEDICATION_CHANGE({
      order: currentState.drugs[i],
      SelectedOrder: SelectedTap,
    });

    this.setState({
      ...currentState,
      drugs: this.props.pharmacyWorkflowData,
    });
  };

  handleAdd = async (val, _, i) => {
    if (this.state?.selectedState?.label?.toLowerCase() === "verification") {
      const currentState = JSON.parse(JSON.stringify(this.state));
      let OldBrands = currentState.drugs[i].brands?.[0];
      let newObject;
      if (val?.drugtype === "DISPOSABLE" || val?.drugtype === "FLUID") {
        let code;
        if (val?.drugtype === "FLUID") {
          code = val?.drugtype;
          // "DRGTYP-002";
        } else {
          code = val?.drugtype;
        }
        let option = await this.props.DRUG_AGAINST_DRUGTYPE({
          id: code,
        });
        newObject = {
          id: "",
          DrugType: val,
          //drugReleaseType: val?.drugReleaseType,
          name: "",
          strength: 0,
          route: "Oral",
          doseCount: 0,
          tabPerDose: "",
          actualdoseCount: "",
          option: option?.payload?.data,
          dosageValue: 0,
          dosageUOM: "",
          packSizeApplicability:
            option?.payload?.data?.[0]?.packSizeApplicability,
          totadmqty: "",
          totadmuom: "",
          totstkqty: "",
          totstkuom: "",
          totbillqty: "",
          totbilluom: "",
          totissueqty: "",
          totissueuom: "",
          dispensedrugcode: "",
          alias: option?.payload?.data?.[0]?.alias,
        };
      } else {
        //FLUID

        newObject = {
          id: "",
          name: "",
          DrugType: val,
          //drugReleaseType: val?.drugReleaseType,
          strength: 0,
          route: "Oral",
          doseCount: 0,
          tabPerDose: "",
          actualdoseCount: "",
          option: OldBrands?.option,
          dosageValue: 0,
          dosageUOM: "",
          packSizeApplicability:
            OldBrands?.option?.[i + 1]?.packSizeApplicability,
          totadmqty: "",
          totadmuom: "",
          totstkqty: "",
          totstkuom: "",
          totbillqty: "",
          totbilluom: "",
          totissueqty: "",
          totissueuom: "",
          dispensedrugcode: "",
          alias: OldBrands?.option?.[i + 1]?.alias,
        };
      }
      currentState.drugs[i].brands.push(newObject);
      this.setState({
        ...currentState,
      });
    }
  };

  onBatchQuantityChangeDrug = (
    value,
    batchId,
    dispenseId,
    i,
    index,
    batch_inx,
    isMedication
  ) => {
    const currentState = JSON.parse(JSON.stringify(this.state));

    if (
      value &&
      // (currentState.drugs[i].brands?.length === 1 && currentState.drugs[i].brands?.[0]?.batches?.[0]?.returnqty) ?
      //   currentState.drugs[i].brands?.[0]?.batches?.[0]?.returnqty
      //   :
      currentState.drugs[i].brands[0].totstkqty < JSON.parse(value)
    ) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Return quantity should not be greater than Issue quantity!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }

    currentState.drugs[i].brands[index].batches[0].returnqty = value
      ? JSON.parse(value)
      : "";
    this.setState({
      ...currentState,
    });
  };

  onBatchQuantityChangeDrugBatchIssuMedication = (
    value,
    i,
    index,
    batch_inx
  ) => {
    const currentState = JSON.parse(JSON.stringify(this.state));

    if (
      value &&
      currentState.drugs[i].brands[0].totstkqty < parseFloat(value)
    ) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Reissue quantity should not be greater than Issue quantity!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }

    currentState.drugs[i].brands[index].batches[batch_inx].chargeqty = value
      ? parseFloat(value)
      : "";

    currentState.drugs[i].brands[index].batches[batch_inx]["isupdate"] = true;

    this.setState({
      ...currentState,
    });
  };

  handleBatchQuantityChange = (
    value,
    batchId,
    dispenseId,
    i,
    index,
    batch_inx,
    isMedication,
    fill,
    b
  ) => {
    const currentState = JSON.parse(JSON.stringify(this.state));

    if (fill) {
      currentState.drugs[i][b][index].batches[batch_inx]["chargeqty"] = value
        ? JSON.parse(value)
        : "";
      currentState.drugs[i][b][index].batches[batch_inx]["issueqty"] = value
        ? JSON.parse(value)
        : "";
      currentState.drugs[i][b][index].batches[batch_inx]["heldQuantity"] = value
        ? JSON.parse(value)
        : "";
      currentState.drugs[i][b][index].batches[batch_inx]["isupdate"] = true;
      this.setState({
        ...currentState,
      });
    } else if (isMedication) {
      if (
        value &&
        currentState.drugs[i]?.[b][index].batches[batch_inx].heldQuantity <
        (value ? JSON.parse(value) : 0)
      ) {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Return quantity should not be greater than Issue quantity!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      currentState.drugs[i][b][index].batches[batch_inx].returnqty = value
        ? JSON.parse(value)
        : "";
      // currentState.drugs[i].[b][index].batches[batch_inx]["isupdate"] = true;
    } else {
      let num = value ? parseFloat(value) : 0;
      const resBatch = currentState?.drugs?.[i]?.[b]?.[index]?.batches?.map(
        (v) => {
          return (num = num + (v?.chargeqty ? v?.chargeqty ?? 0 : 0));
        }
      );

      if (currentState.drugs[i][b][index].totstkqty < parseFloat(num)) {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "The batch quantity should not be greater than allocated total stock quantity!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      currentState.drugs[i][b][index].batches[batch_inx]["chargeqty"] = value
        ? JSON.parse(value)
        : "";
      currentState.drugs[i][b][index].batches[batch_inx]["heldQuantity"] = value
        ? JSON.parse(value)
        : "";
      currentState.drugs[i][b][index].batches[batch_inx]["blockqty"] = value
        ? JSON.parse(value)
        : "";
      // currentState.drugs[i].[b][index].batches[batch_inx]["isupdate"] = true;
    }

    this.setState({
      ...currentState,
    });
  };

  handleChangebrandName = async (value, brandId, i, dispenseId) => {
    const currentState = JSON.parse(JSON.stringify(this.state));
    let id_;
    let name_;
    let drugReleaseType_;
    let alias;
    let packSizeApplicability;
    let dosagevalue_ = currentState?.drugs[i]?.brands?.[brandId]?.dosageValue;

    const _ = currentState?.drugs[i]?.brands?.[brandId]?.option?.map((v) => {
      if (v?.drugNameLong === value) {
        id_ = v.dispensedrugcode;
        name_ = v.drugName;
        drugReleaseType_ = v.drugreleasetype;
        alias = v?.alias;
        packSizeApplicability = v?.packSizeApplicability;

        return v;
      }
    });

    if (currentState?.drugs?.[i]?.brands?.[brandId]) {
      currentState.drugs[i].brands[brandId].drugName = name_;
      currentState.drugs[i].brands[brandId].drugNameLong = value;
      currentState.drugs[i].brands[brandId].name = name_;
      currentState.drugs[i].brands[brandId].id = id_;
      currentState.drugs[i].brands[brandId].dispensedrugcode = id_;
      currentState.drugs[i].brands[brandId].dosageValue = dosagevalue_;
      currentState.drugs[i].brands[brandId].drugreleasetype = drugReleaseType_;
      currentState.drugs[i].brands[brandId].alias = alias;
      currentState.drugs[i].brands[brandId].packSizeApplicability =
        packSizeApplicability;
    }
    this.setState({
      ...currentState,
    });
    // let drug = this.state.drugs?.filter((drug) => drug.id === dispenseId)[0];
    let SelectedTap = this.props.pharmacyState?.[this.state.selectedTab];

    await this.props.PHARMACY_MEDICATION_CHANGE({
      order: currentState.drugs[i],
      SelectedOrder: SelectedTap,
    });
    //console.log("currentState", currentState);
    this.setState({
      ...currentState,
      drugs: this.props.pharmacyWorkflowData,
    });
  };

  handleShowAllBatch = async (dispenseId, bool, batch, type, _i_, i) => {
    if (
      this.state?.selectedState?.label?.toLowerCase() === "verification" ||
      this.state?.selectedState?.label?.toLowerCase() === "filling"
    ) {
      let drug = this.state.drugs?.filter((drug) => drug.id === dispenseId)[0];
      let currenState = JSON.parse(JSON.stringify(this.state));

      let SelectedTap = this.props.pharmacyState?.[this.state.selectedTab];

      let alisName = currenState.drugs[i][type][_i_]?.alias?.find(
        (v) => v?.aliasType?.code === "AM002"
      )?.aliasCode;

      const bl = typeof bool == "boolean" ? bool ?? false : false;
      const updateBatchDetails = await this.props.PHARMACY_MULTIBATCH({
        order: drug,
        orderbatches: currenState.drugs[i][type][_i_].batches,
        SelectedOrder: SelectedTap,
        isShowMulti: bl,
        alisName:
          alisName ||
          currenState?.drugs?.[i]?.[type]?.[_i_]?.dispenseitemaliascode ||
          "",
        // isMedication: SelectedTap?.id === "queuestates/10037" || SelectedTap?.id === "queuestates/10038"
      });

      if (updateBatchDetails?.payload?.data?.length > 0) {
        currenState.drugs[i][type][_i_].batches =
          this.state?.selectedState?.label?.toLowerCase() === "filling"
            ? updateBatchDetails.payload.data?.map((v) => {
              return { ...v, isnew: true };
            })
            : updateBatchDetails.payload.data;
        currenState.drugs[i][type][_i_]["isShowMulti"] = bl;
      }

      this.setState({
        ...currenState,
      });
    }
    // if (!currentDispenseState?.dispenseDuration) return;

    // this.setState({
    //   [dispenseId]: {
    //     ...currentDispenseState,
    //     isDispenseDurationApplied: true,
    //   },
    // });
  };

  handleApplyDispenseDurationClick = async (dispenseId) => {
    if (this.state?.selectedState?.label?.toLowerCase() === "verification") {
      let drug = this.state.drugs?.filter((drug) => drug.id === dispenseId)[0];
      let SelectedTap = this.props.pharmacyState?.[this.state.selectedTab];
      const applyData = await this.props.PHARMACY_CALCULATE_DOSESTRENGTH_STOCK({
        order: drug,
        SelectedOrder: SelectedTap,
        isCart: drug?.ScenarioName === "IP",
        isCartDetails: {
          Cartid: this.state.Cartid ?? "",
          CartTransId: this.state.CartTransId ?? "",
        },
      });

      if (applyData?.payload?.error) {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: applyData?.payload?.message ?? "",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      this.setState({
        ...this.state,
        drugs: this.props.pharmacyWorkflowData,
      });
    }
    // else if (this.state?.selectedState?.label?.toLowerCase() === "filling") {
    //   const FirstState = this.props.pharmacyState?.[this.state.selectedTab];
    //   const stateid = this.props.pharmacyState?.[this.state.selectedTab]?.id;
    //   this.handleRefreshButtonClick(
    //     null,
    //     FirstState,
    //     stateid,
    //     this.state.selectedTab
    //   );
    // }
  };

  handleSearch = async (val) => {
    this.setState({
      ...this.state,
      search: val,
    });

    if (val.length >= 3 || val.length === 0) {
      await this.props.PATIENT_QUEUE({
        id: this.props.queueId,
        search: val,
      });
      await this.props.PATIENT_SEARCH_NOT_IN_QUEUE({
        search: val,
        ExistingPatient: this.state.patientData,
      });
    }
  };
  handleSearchOnclick = async () => {
    // this.props.PHARMACY_VERIFY({
    //   orders: this.state.drugs,
    // });
    await this.props.PATIENT_QUEUE({
      id: this.props.queueId,
      search: this.state.search,
    });
    await this.props.PATIENT_SEARCH_NOT_IN_QUEUE({ search: this.state.search });
  };

  handleDiscontinue = async (action, drug, index) => {
    const paylod = {
      queueid: this.state?.selectedState?.queueid,
      scenriodid: this.state?.selectedState?.scenarioid,
      stateid: this.state?.selectedState?.id,
      ticketid: drug?.ticketId,
      actionid: action?.actionid,
      _id: drug?._id,
    };
    await this.props.PHARMACY_DISCONTINUE({
      ...paylod,
    });
    this.props.alert.setSnack({
      open: true,
      severity: AlertProps.severity.success,
      msg: "Your verification has been discontinue successfully",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
      tone: true,
    });
    await this.props.PATIENT_QUEUE({
      id: this.props.queueId,
      search: "",
    });
    let patient = this.props.patientData[0];
    await this.getDashboardData(patient);
  };

  handleRefreshButtonClick = async () => {
    if (this.props?.location.state?.isCardVerify) {
      await this.props.PHARMACY_GETTINGSTATE({
        queueId: this.props.queueId,
        scenarioId: this.props?.location.state?.isCardVerify
          ? "queuescenarios/10029"
          : "queuescenarios/10019",
        isCart: this.props?.location.state?.isCardVerify,
        patientid: this.state.patientId,
      });
    } else {
      await this.props.GETQUEUELIST();
      await this.props.ORDERCOUNT({
        queueId: this.props.queueId,
        patientid: this.state.patientId,
      });
    }

    if (
      this.props.pharmacyState?.[
        this.state.selectedTab
      ]?.label?.toLowerCase() !== "verification"
    ) {
      await this.props.PHARMACY_FILLING_LISTING({
        FirstState: this.props.pharmacyState?.[this.state.selectedTab],
        stateid: this.props.pharmacyState?.[this.state.selectedTab]?.id,
        patientid: this.state.patientId,
        isCart: this.props?.location.state?.isCardVerify,
      });
    } else {
      await this.props.PHARMACY_FRAMEWORK({
        FirstState: this.props.pharmacyState?.[this.state.selectedTab],
        stateid: this.props.pharmacyState?.[this.state.selectedTab]?.id,
        patientid: this.state.patientId,
        isCart: this.props?.location.state?.isCardVerify,
      });
    }

    this.setState({
      ...this.state,
      drugs: this.props.pharmacyWorkflowData,
      selectedState: this.props.pharmacyState?.[this.state.selectedTab],
      selectedTab: this.state.selectedTab,
      multiOrderSelect: [],
      drugIds: [],
    });
  };

  handlepharmacyRefresh = async () => {
    await this.props.PHARMACY_FRAMEWORK({
      FirstState: this.state?.selectedState,
      stateid: this.state?.selectedState?.id,
      patientid: this.state.patientId,
      isCart: this.props?.location.state?.isCardVerify,
    });
  };

  handleVerify = async (action, drug, index, edirReason, isMedication) => {
    const state = JSON.parse(JSON.stringify(this.state));

    if (isMedication) {
      // var main_num = 0;
      // var num = 0;
      // if (drug?.brands?.length > 1) {
      //   const _check_main = drug?.brands?.map((v) => {
      //     return (main_num = v?.totstkqty);
      //   });

      //   const _check = drug?.brands?.map((v) => {
      //     return v?.batches?.map((v1) => {
      //       return (num = v1?.returnqty);
      //     });
      //   });

      //   if (main_num < num) {
      //     this.props.alert.setSnack({
      //       open: true,
      //       severity: AlertProps.severity.error,
      //       msg: "The batch quantity should not be greater than total stock quantity!",
      //       vertical: AlertProps.vertical.top,
      //       horizontal: AlertProps.horizontal.right,
      //       tone: true,
      //     });
      //     return;
      //   }
      // }

      await this.props.PHARMACY_RETURN_MEDICATION({
        orders: drug,
        selectedState: this.state?.selectedState,
        patientId: this.state?.patientId,
        action: action,
        status: this.state.selectedTab,
        isApproval: edirReason,
      });
    } else {
      await this.props.PHARMACY_VERIFY({
        orders: drug,
        selectedState: this.state?.selectedState,
        patientId: this.state?.patientId,
        action: action,
        status: this.state.selectedTab,
        reIssueReason: this.state.selectedState?.id === "queuestates/10048",
        isReisse: this.state.selectedState?.id === "queuestates/10048",
      });
    }

    if (
      this.props.pharmacy_verify_state?.error ||
      this.props.returnMedicationData?.error
    ) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: this.props.pharmacy_verify_state.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    } else {
      // await this.props.PHARMACY_FRAMEWORK({
      //   FirstState: this.state?.selectedState,
      //   stateid: this.state?.selectedState?.id,
      //   patientid: this.state.patientId,
      // });
      // await this.props.PHARMACY_GETTINGSTATE({
      //   queueId: "queue/10013",
      //   scenarioId: "queuescenarios/10019",
      //   patientid: this.state.patientId,
      // });
      if (action?.action?.toLowerCase() == "hold") {
        let filteredvalue = state?.states.find(
          (state) => state.id === this.state?.selectedState?.id
        );
        let stateIndex = state?.states.indexOf(filteredvalue);
        filteredvalue.count = filteredvalue.count - 1;
        state.states[stateIndex] = filteredvalue;
        let filteredState = state?.drugs?.filter(
          (drg) => drg._id !== drug?._id
        );
        state.drugs = filteredState;
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: `Your order is ${this.getText(
            action?.action?.toLowerCase()
          )} successfully`,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        this.setState({
          ...this.state,
          drugs: state?.drugs,
        });
      } else {
        this.handleRefreshButtonClick();
      }
      // window.location.reload();
    }

    // await this.props.PATIENT_QUEUE({
    //   id: "queue/10013",
    //   search: "",
    //   scenarioId: "queuescenarios/10019",
    // });
    // let patient = this.props.patientData[0];
    // await this.getDashboardData(patient);
  };

  getText = (val) => {
    switch (val) {
      case "verify":
        return "verified";
      case "filled":
        return "filled";
      case "checked":
        return "checked";
      case "issue":
        return "dispensed";
      default:
        return val;
    }
  };

  handleVerifyReason = (val, isApproval) => {
    const currentState = JSON.parse(JSON.stringify(this.state));
    const isMedication =
      this.state.selectedState?.label ===
      ("Medication to be returned" || "Pending Approval");
    let update;
    if (isMedication) {
      update = currentState.multiOrderSelect?.map((v) => {
        return { ...v, returnreason: val };
      });
      currentState.multiOrderSelect = update;
    } else {
      update = currentState.multiOrderSelect?.map((v) => {
        return { ...v, verifyReason: val?.year ?? val };
      });
      currentState.multiOrderSelect = update;
    }

    if (isMedication) {
      currentState.isApproval = isApproval;
    }

    this.setState({
      ...currentState,
    });
  };

  onChangeBatch = (val, drugIndex) => {
    const currentState = JSON.parse(JSON.stringify(this.state));
    if (currentState.drugs[drugIndex].brands[0].batches.length > 0) {
      currentState.drugs[drugIndex].brands[0].batches[0].id = val;
      this.setState({
        ...currentState,
      });
    } else {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Generate Schedule First!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
  };

  handleDirectionSaveClick = (value, index, drug, brand_in) => {
    // Converting html string to text.

    if (this.state?.selectedState?.label?.toLowerCase() === "verification") {
      let directionDescription = EditorState.createWithContent(
        stateFromHTML(value?.directionDescripion)
      );
      directionDescription = directionDescription
        .getCurrentContent()
        .getPlainText("\u0001");

      let directionLocalLanguage = EditorState.createWithContent(
        stateFromHTML(value?.directionLocalLanguage)
      );
      directionLocalLanguage = directionLocalLanguage
        .getCurrentContent()
        .getPlainText("\u0001");

      const updated = update(this.state, {
        drugs: {
          [index]: {
            brands: {
              [brand_in]: {
                pH_OrderLineDispenseSchedule: {
                  dispensedetails: {
                    [brand_in]: {
                      direction: {
                        $set: directionDescription,
                      },
                      nldirection: {
                        $set: directionLocalLanguage,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      });
      this.setState({
        drugs: updated?.drugs,
      });
    }
  };

  createNewOrder = () => {
    const state = this.state;
    this.setState({
      ...state,
      addnewmedication: !state.addnewmedication,
    });
  };

  handleDrugReturn = () => {
    if (this.state.selectedActiveMedicationList?.length === 0) {
      return this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Choose drugs to return",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }

    /**
     * Validation rules
     * 1. If the medication status is ISSUE.
     * 2. If returnApplicable flag is true.
     * 3. If current date is not after the return period.
     *    - Add day/week/month/year based on the returnPeriodVal & returnPeriodUOM.
     */

    const requestedReturnMedications = this.state.selectedActiveMedicationList;
    const { data: sourceData = [] } =
      this.props?.getActiveMedicationDataWithPhOrderId;

    const cannotReturnErrors = [];
    const notIssuedErrors = [];
    const notReturnableErrors = [];
    const returnDateExceededErrors = [];

    requestedReturnMedications.forEach((returnOrder) => {
      const source = sourceData?.find(
        (order) => returnOrder?._id === order?._id
      );

      // Return if the response does not contain the medication.
      if (!source) {
        return cannotReturnErrors.push(returnOrder?.drugName);
      }

      // Return if the medication status is not ISSUE.
      if (source?.PH_OrderLineDispenseSchedule?.status?.code !== "ISSUE") {
        return notIssuedErrors.push(returnOrder?.drugName);
      }

      // Return if the returnApplicable flag is false.
      if (!source?.drugDetails?.returnApplicable) {
        return notReturnableErrors.push(returnOrder?.drugName);
      }

      // Return if the current date is not after the return period.
      const { returnPeriodVal, returnPeriodUOM } = source?.drugDetails;

      if (!returnPeriodVal || !returnPeriodUOM) {
        return cannotReturnErrors.push(returnOrder?.drugName);
      }

      const issuedDate = source?.PH_OrderLineDispenseSchedule?.dispdate;

      let isReturnDateExceeded = false;

      if (returnPeriodUOM?.code === "FL001") {
        //Days
        isReturnDateExceeded = moment
          .unix(issuedDate)
          .add(returnPeriodVal, "days")
          .isBefore(moment());
      } else if (returnPeriodUOM?.code === "FL002") {
        //Weeks
        isReturnDateExceeded = moment
          .unix(issuedDate)
          .add(returnPeriodVal, "weeks")
          .isBefore(moment());
      } else if (returnPeriodUOM?.code === "FL003") {
        //Months
        isReturnDateExceeded = moment
          .unix(issuedDate)
          .add(returnPeriodVal, "months")
          .isBefore(moment());
      } else if (returnPeriodUOM?.code === "FL004") {
        //Years
        isReturnDateExceeded = moment
          .unix(issuedDate)
          .add(returnPeriodVal, "years")
          .isBefore(moment());
      } else {
        return cannotReturnErrors.push(returnOrder?.drugName);
      }

      if (isReturnDateExceeded) {
        return returnDateExceededErrors.push(returnOrder?.drugName);
      }
    });

    if (cannotReturnErrors?.length > 0) {
      return this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: ERROR_MESSAGE.cannotReturnMedication(
          cannotReturnErrors?.join(", ")
        ),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    } else if (notIssuedErrors?.length > 0) {
      return this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: ERROR_MESSAGE.notIssuedMedication(notIssuedErrors?.join(", ")),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    } else if (notReturnableErrors?.length > 0) {
      return this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: ERROR_MESSAGE.notReturnableMedication(
          notReturnableErrors?.join(", ")
        ),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    } else if (returnDateExceededErrors?.length > 0) {
      return this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: ERROR_MESSAGE.returnDateExceeded(
          returnDateExceededErrors?.join(", ")
        ),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }

    Promise.resolve(
      this.props.RETURN_DRUG_TRANSACTION({
        ids: this.state.selectedActiveMedicationList?.map((x) => x?._id),
      })
    )
      .then((res) => {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "Return initiated Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      })
      .catch((err) => {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: err.message ?? "Something went wrong!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      });
  };

  handleFullScreen = () => {
    this.setState({
      activeMedFullScreen: true,
      selectedUI: "list",
    });
  };

  closeFullScreen = () => {
    this.setState({
      activeMedFullScreen: false,
    });
  };

  handleActiveMedicationAddDialog = (open) => {
    this.setState({
      activeMedicationOpen: open,
    });
  };

  handleEditMedicationInfoForm = (orderId) => {
    this.setState({
      activeMedicationOpen: true,
      activeMedicationEditId: orderId,
    });
  };

  handleUpdateState = (data) => {
    this.setState({
      ...this.state,
      CartTransId: data?.CartTransId ?? "",
      Cartid: data?.Cartid ?? "",
    });
  };

  handleOpenIssueMedication = async () => {
    const state = this.state;

    if (state.selectedActiveMedicationList?.length > 1) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please choose only one drug!.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }

    const payload = {
      id: state.selectedActiveMedicationList?.[0]?._id ?? "",
    };

    const ReadData = await this.props.READ_ISSUE_MEDICATION({
      ...payload,
    });

    if (ReadData.payload.data?.error || !ReadData.payload.data?.length > 0) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg:
          !ReadData.payload.data?.length > 0
            ? // (`No ${state.selectedActiveMedicationList?.[0]?.drugName?.toLowerCase()} Data Found!`)
            `No Data Found!`
            : ReadData.payload.data.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }

    this.setState({
      ...state,
      issueMedication: !state.issueMedication,
      issueMedicationList: ReadData.payload.data || [],
    });
  };

  handleOpenIssueMedicationCharge = () => {
    const state = this.state;
    this.setState({
      ...state,
      issueMedicationCharge: !state.issueMedicationCharge,
    });
  };

  SaveIssueMedication = async () => {
    const state = this.state;

    const filterData = state.issueMedicationList.filter((v) => v?.action && v);

    if (!filterData?.length > 0) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please select atleast one action!.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }

    const data = filterData?.map((v) => {
      return {
        _key: v?._key ?? "",
        _id: v?._id ?? "",
        _rev: v?._rev ?? "",
        dispenseitemcode: v?.dispenseitemcode ?? "",
        doseday: v?.doseday ?? "",
        dosetime: v?.dosetime ?? "",
        doseuom: v?.doseuom?._id ?? "",
        dosedatetime: v?.dosedatetime ?? 0,
        dosedate: v?.dosedate ?? "",
        dose: v?.dose ?? 0,
        admuom: v?.admuom?._id ?? "",
        admqty: v?.admqty ?? "",
        PHorderline_id: v?.PHorderline_id ?? "",
        billuom: v?.billuom?._id ?? "",
        issueqty: v?.issueqty ?? 0,
        id: v?.id ?? 0,
        stkuom: v?.stkuom?._id ?? "",
        billqty: v?.billqty ?? 0,
        stkqty: v?.stkqty ?? 0,
        dispschedule_id: v?.dispschedule_id ?? "",
        status: v?.status?._id ?? "",
        // "issueuom": v?.issueuom?._id ?? '',
        issueuom: v?.issueuom?.UOM ?? "",
        createdby: v?.createdby ?? "",
        createddate: v?.createddate ?? 0,
        updatedby: v?.updatedby ?? "",
        updatedate: v?.updatedate ?? 0,
        activestatus: v?.activestatus ?? false,
      };
    });

    const Reissue = await this.props.ISSUE_MEDICATION({
      Json: data,
    });

    if (Reissue.payload.data?.error) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: Reissue.payload.data.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }

    this.setState({
      ...state,
      issueMedication: !state.issueMedication,
    });
  };

  handleRessue = (i, v) => {
    const state = JSON.parse(JSON.stringify(this.state));
    state.issueMedicationList[i] = {
      ...state.issueMedicationList[i],
      action: v,
    };

    this.setState({
      ...state,
    });
  };
  handleGetQueueData = async (priorityID) => {
    if (this.props?.location.state?.isCardVerify) {
      const Didupdate = this.props?.location.state;
      await this.props.CART_PATIENT_QUEUE({
        cartID: Didupdate?.cartID ?? "",
        locID: Didupdate?.locID ?? "",
        wardID: Didupdate?.wardID ?? "",
        // grpid: priorityID ?? '',
      });
    } else {
      await this.props.PATIENT_QUEUE({
        id: this.props.queueId,
        search: "",
        priorityID,
      });

      console.log("QueueId", priorityID);

      await this.props.QUEUE_COUNT({
        QueueID: this.props.queueId,
        groupList: this.props?.priorityList,
      });

      if (!priorityID) {
        this.setState({
          ...this.state,
          totalQueueCount: this.props.patientData?.length,
        });
      }
    }
  };

  onFullScreenWorkFlow = () => {
    var elem = document.getElementById("fullScreenClick");

    if (!this.state?.fullScreenWorkFlow) {
      elem.requestFullscreen();
    } else if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      document.exitFullscreen();
    }
    this.setState({
      ...this.state,
      fullScreenWorkFlow: !this.state.fullScreenWorkFlow,
    });
  };

  isMultiOrderSelect = (v) => {
    const state = JSON.parse(JSON.stringify(this.state));

    if (state.multiOrderSelect?.filter((m) => m?._id === v?._id)?.length > 0) {
      var filter = state.multiOrderSelect?.filter((m) => m?._id !== v?._id);

      state.multiOrderSelect = filter;
    } else {
      state.multiOrderSelect.push(v);
    }

    if (state.multiOrderSelect?.length > 0) {
      state.ScenrioName = state.multiOrderSelect?.[0]?.ScenarioName;
    } else {
      state.ScenrioName = "";
    }

    let arr = [];
    const filterDup = [...state.multiOrderSelect, { ...v }]?.map((v) => {
      arr.push(v?.ScenarioName);
    });

    const namesArr = arr.filter((val, id) => {
      return arr.indexOf(val) == id;
    });

    if (this.state?.selectedState?.label?.toLowerCase() === "verification") {
      if (
        !v?.pH_OrderLineDispenseSchedule?.dispensedetails?.length > 0 &&
        //  &&
        // !v?.brands?.[0]?.dispensedetails?.length > 0
        !v?.isOnHold
      ) {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please schedule the order.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }

      if (this.isaddfuncy(v) && !v?.isOnHold) {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Order dose not fulfilled.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }

      let error = false;

      if (v?.isCompound) {
        const iscompounds = v?.compounds?.map((b) => {
          if (!b?.batches?.length > 0) {
            return (error = true);
          }
        });
      } else if (v?.isIV) {
        const isadditives = v?.additives?.map((b) => {
          return b?.brands?.map((c) => {
            if (!c?.batches?.length > 0) {
              return (error = true);
            }
          });
        });
      }

      const isbrands = v?.brands?.map((b) => {
        if (!b?.batches?.length > 0) {
          return (error = true);
        }
      });

      if (error && !v?.isOnHold) {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Batch details not available can't be select!.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
    }

    if (namesArr?.length > 1) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please select the same scenario orders.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }

    let dArr = state.drugIds;
    dArr.push(v?.drugCode);

    const drugArrFilter = dArr.filter(function (item, pos) {
      return dArr.indexOf(item) == pos;
    });

    state.drugIds = drugArrFilter;
    this.setState({
      ...state,
    });
  };

  isaddfuncy = (drug) => {
    if (drug?.disptype?.code === "IV-SY") {
      return false;
    }
    var num = 0;
    let totalDose = 0;
    const cal = (drug?.brands || [])?.forEach((v) => {
      if (v.dosageValue?.length > 0) {
        v.dosageValue.map((C) => {
          totalDose += C;
        });
      }
    });
    return totalDose < drug?.dosageValue ? true : false;
  };

  saveMultiOrder = async (action, editReason) => {
    debugger
    if (!this.state.multiOrderSelect?.length > 0) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please select atleast one order!.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }

    let arr = [];
    const filter = this.state.multiOrderSelect?.map((v) => {
      arr.push(v?.ScenarioName);
    });
    const namesArr = arr.filter((val, id) => {
      return arr.indexOf(val) == id;
    });

    if (namesArr?.length > 1) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please select the same scenario orders!.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }

    const isMedication =
      this.state.selectedState?.label ===
      ("Medication to be returned" || "Pending Approval");

    if (isMedication) {
      await this.props.PHARMACY_RETURN_MEDICATION({
        orders: this.state.multiOrderSelect,
        selectedState: this.state?.selectedState,
        patientId: this.state?.patientId,
        action: action,
        status: this.state.selectedTab,
        isApproval: editReason,
      });
    } else {
      if (
        // this.state?.selectedState?.label?.toLowerCase() === "verification" &&
        action?.action === "RELEASE"
      ) {
        let arrHold = [];
        const filter = this.state.multiOrderSelect?.map((v) => {
          if (!v?.isOnHold) {
            arrHold.push(v?.ScenarioName);
          }
        });
        const namesArrHold = arrHold.filter((val, id) => {
          return arrHold.indexOf(val) == id;
        });

        if (namesArrHold?.length > 0) {
          this.props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please select only hold orders!.",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          return;
        }
      }

      // this.state.multiOrderSelect[0].pH_OrderLineDispenseSchedule._id

      if (
        this.getText(action?.action?.toLowerCase())?.includes("reverify") &&
        !this.state.multiOrderSelect?.[0]?.verifyReason
      ) {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: `Please enter the ${this.getText(
            action?.action?.toLowerCase()
          )} reason.`,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }

      if (
        (action?.action === "DISCONTIUNE" &&
          this.state?.selectedState?.label?.toLowerCase() === "verification") ||
        (action?.action === "HOLD" &&
          this.state?.selectedState?.label?.toLowerCase() === "verification") ||
        (action?.action === "HOLD ORDER" &&
          this.state?.selectedState?.label?.toLowerCase() === "verification") ||
        (action?.action === "RELEASE" &&
          this.state?.selectedState?.label?.toLowerCase() === "verification")
      ) {
        await this.props.PHARMACY_VERIFY_DISCANTINUE({
          action: action,
          orders: this.state.multiOrderSelect?.map((v) => {
            return {
              PH_OrderLine_id: v?._id ?? "",
              PractitionerID: this.props?.loggerUserInfo?.practitionerid ?? "",
            };
          }),
        });
      } else {
        await this.props.PHARMACY_VERIFY({
          orders: this.state.multiOrderSelect,
          selectedState: this.state?.selectedState,
          patientId: this.state?.patientId,
          action: action,
          status: this.state.selectedTab,
          reIssueReason: this.state.selectedState?.id === "queuestates/10048",
          isReisse: this.state.selectedState?.id === "queuestates/10048",
        });
      }
    }

    if (
      this.props.pharmacy_verify_state?.error ||
      this.props.pharmacy_verify_state?.validation_error ||
      this.props.returnMedicationData?.error
    ) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg:
          this.props.pharmacy_verify_state?.response?.length > 0 ||
            this.props.pharmacy_verify_state?.Response?.length > 0
            ? (
              this.props.pharmacy_verify_state?.response ||
              this.props.pharmacy_verify_state?.Response
            )
              ?.map((v) => (v?.error ? v?.message ?? v?.errorMessage : ""))
              ?.join(",")
            : this.props.pharmacy_verify_state?.validation_error?.length > 0
              ? this.props.pharmacy_verify_state?.validation_error
                ?.map((v) => v?.Errormsg)
                ?.join(",")
              : this.props.pharmacy_verify_state?.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    } else {
      let filteredDrug = this.state.drugs?.filter(
        (v) =>
          !this.props.pharmacy_verify_state?.ticketIds?.includes(v?.ticketId)
      );

      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: `Your order is ${this.getText(
          action?.action?.toLowerCase()
        )} successfully`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });

      this.setState({
        ...this.state,
        drugs: filteredDrug,
        multiOrderSelect: [],
        drugIds: [],
      });
      this.handleRefreshButtonClick();
    }
  };

  handleShowMedicationProfile = () => {
    this.setState((prev) => ({
      showMedicationProfile: !prev.showMedicationProfile,
    }));
  };

  getWorkflowHeight = (fullScreen, Cart, ShowMedication) => {
    if (fullScreen) {
      return "100vh";
    }
    // else if (Cart) {
    //   return "64vh";
    // } else if (ShowMedication) {
    //   return "calc(100vh - 364px)";
    // } else {
    //   return "calc(100vh - 300px)";
    // }
    else {
      if (this.state?.showMedicationProfile) {
        return "calc(100vh - 410px)";
      } else {
        return "calc(100vh - 300px)";
      }
    }
  };

  issueQuantity = (values, brandIndex, index) => {
    const val = values ? JSON.parse(values) : "";

    if (this.state.drugs?.[index]?.brands?.[brandIndex]?.totbillqty < val) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Issue quantity should not be greater than billing quantity!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }

    const updated = update(this.state, {
      drugs: {
        [index]: {
          brands: {
            [brandIndex]: {
              pH_OrderLineDispenseSchedule: {
                dispensedetails: {
                  [brandIndex]: {
                    totstkqty: {
                      $set: val,
                    },
                    // totbillqty: {
                    //   $set: val,
                    // },
                  },
                },
              },
              totstkqty: {
                $set: val,
              },
              // totbillqty: {
              //   $set: val,
              // },
            },
          },
        },
      },
    });
    this.setState({
      drugs: updated?.drugs,
    });
  };

  handlePopoverOpen = (event) => {
    const state = this.state;
    this.setState({
      ...state,
      openStatus: event.currentTarget,
    });
  };

  handlePopoverClose = () => {
    const state = this.state;
    this.setState({
      ...state,
      openStatus: null,
    });
  };

  onTabChange = (e, v) => {
    this.setState(
      {
        isQueue: v,
      },
      () => {
        let Queue = this.props?.priorityList?.[v]?.id;
        this.handleGetQueueData(Queue);
      }
    );
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ ...this.state, numPages: numPages });
  };

  render() {



    const { selectedTab, drugs, isCardVerify, fullScreenWorkFlow } = this.state;

    const { classes, theme } = this.props;

    const openopenStatus = Boolean(this.state.openStatus);

    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <div
              key={"2"}
              id={"srIna"}
              variant={"fullWidth"}
              style={{ background: "#dee5ec", height: "0.5px", width: "100%" }}
            ></div>
            <Grid
              id={"pharmacy_verify_screen"}
              container={false}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              aliasName={"pharmacyVerifyScreen"}
              className={classes?.commonBackground}
            >
              {
                this.state.patientId && (
                  <Grid
                    id={"encounter_cards"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    aliasName={"encounterCards"}
                  >
                    <Grid
                      id={"patient_card_grid"}
                      container={false}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      item={true}
                      lg={"4"}
                      md={"4"}
                      sm={"4"}
                      xs={"4"}
                      aliasName={"patientCardGrid"}
                    >
                      {this.props.patientInfo.length > 0 && (
                        <ComponentRender
                          id={"patient_info_component"}
                          aliasName={"patientInfoComponent"}
                          component={
                            <ALL.PatientInfo
                              classes={classes}
                              propsState={this.props.patientInfo[0]}
                              setFun={(value) => {
                                this.changeState("PatientInfo", value);
                              }}
                              stateName={"PatientInfo"}
                              statevalue={this.state?.PatientInfo}
                            />
                          }
                        ></ComponentRender>
                      )}
                    </Grid>
                    <Grid
                      id={"encounter_component_grid"}
                      container={false}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      aliasName={"encounterComponentGrid"}
                      item={true}
                      lg={"4"}
                      md={"4"}
                      sm={"4"}
                      xs={"4"}
                      xl={"4"}
                    >
                      {this.props.encounterData.length > 0 && (
                        <CusCard
                          id={"sgBpa"}
                          aliasName={"customEncounterCard"}
                          isHeaderCard={true}
                          className={classes?.encounterBanner}
                          OverAllencountersCount={this.props.encounterData.length}
                          onChange={async (value) => {
                            await this.props.VITALS_AGAINST_ENCOUNTER({
                              id: this.props.encounterData?.[value]?._id ?? "",
                            });
                            this.setState(
                              {
                                ...this.state,
                                activeIndex: value,
                                vitalactiveIndex:
                                  this.props.vitalsData?.length - 1 ?? 0,
                              },
                              async () => {
                                await this.getMedicationDetails({
                                  _id: this.state.patientId,
                                });
                              }
                            );
                          }}
                          activeIndex={this.state.activeIndex}
                          BodyComp={
                            <ALL.Encounter
                              classes={classes}
                              propsState={this.props.encounterData}
                              activeIndex={this.state.activeIndex}
                            />
                          }
                        ></CusCard>
                      )}
                    </Grid>
                    <Grid
                      key={"2"}
                      id={"insurance_card_grid"}
                      container={false}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      aliasName={"insuranceCardGrid"}
                      item={true}
                      lg={"4"}
                      md={"4"}
                      sm={"4"}
                      xl={"4"}
                      xs={"4"}
                      className={classes?.MainBackground}
                    >
                      {this.state?.fin_data?.length > 0 && (
                        <ComponentRender
                          id={"insurance_card_component"}
                          aliasName={"insuranceCardComponent"}
                          component={
                            <ALL.InsuranceInfo
                              parent_id={"insurance_Registration"}
                              careList={[...this.state.fin_data]}
                              selected={this.state.selectOption}
                              onChange={(data) =>
                                this.changeState("selectOption", data)
                              }
                              padding
                            />
                          }
                        ></ComponentRender>
                      )}
                    </Grid>
                  </Grid>
                )
              }
              < div
                key={"2"}
                id={"srIna"}
                variant={"fullWidth"}
                style={{ background: "#dee5ec", height: "1px", width: "100%" }}
              ></div>
            </Grid>
            <Grid
              id={"main_screen"}
              container={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              aliasName={"MainScreen"}
              // style={{ flex: 1 }}
              style={{ flex: 1 }}
              className={classes?.commonBackground}
            >
              <Grid
                id={"medication_section"}
                container={false}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                aliasName={"medicationSection"}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xs={"12"}
                xl={"12"}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  overflowX: "hidden",
                }}
                className={classes?.MainBackground}
              >
                {!this.state.patientId && (
                  <div style={{ position: "absolute", top: "50%" }}>
                    {this.state.pharmacyLoad ? (
                      <CircularProgress disableShrink />
                    ) : (
                      <>Please choose the patient!.</>
                    )}
                  </div>
                )}
                {this.state.patientId && (
                  <div style={{ padding: 10, width: "100%" }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3} alignContent="center">
                        <List
                          icon={
                            "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/319794701"
                          }
                          title={t("activeMedication.clinic.vitals")}
                          list={this.props.vitalsData}
                          activeIndex={this.state.vitalactiveIndex}
                        />
                      </Grid>
                      <Grid item xs={3} alignContent="center">
                        <Allergy
                          icon={
                            "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/319798223"
                          }
                          title={t("activeMedication.clinic.allergies")}
                          list={this.props.allergyData}
                          activeIndex={this.state.statusCodeActive}
                        />
                      </Grid>
                      <Grid item xs={3} alignContent="center">
                        <Diagnosis
                          icon={
                            "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/319806376"
                          }
                          title={t("activeMedication.clinic.diagnosis")}
                          list={this.props.diagnosisData}
                          activeIndex={this.state.statusCodeActive}
                        />
                      </Grid>
                      <Grid item xs={2} alignContent="center">
                        <ChiefComplain
                          icon={
                            "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/319809699"
                          }
                          title={t("activeMedication.clinic.cheifComplaints")}
                          list={this.props.chiefComplaintsData}
                          activeIndex={this.state.statusCodeActive}
                        />
                      </Grid>
                      <Grid item xs={1} alignContent="center">
                        <div
                          className={classes?.MainBackgroundCard}
                          onClick={(e) => this.handlePopoverOpen(e)}
                          style={{
                            padding: "10px",
                            width: "min-content",
                            borderRadius: "6px",
                            display: "flex",
                            margin: "auto",
                          }}
                        >
                          <img src={funnel} alt="filter" />
                          <label
                            className={classes?.dot}
                            style={{
                              background:
                                this.state.statusCodeActive?.color ?? "",
                            }}
                          ></label>
                        </div>
                      </Grid>
                    </Grid>
                    <Popover
                      open={openopenStatus}
                      anchorEl={this.state.openStatus}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      onClose={this.handlePopoverClose}
                      classes={{
                        paper: classes?.MainBackgroundCard,
                      }}
                      disableRestoreFocus
                    >
                      <div className={classes?.hover}>
                        {statusCode?.map((item, index) => {
                          return (
                            <div
                              style={{ padding: 4, cursor: "pointer" }}
                              onClick={() =>
                                this.setState(
                                  { ...this.state, statusCodeActive: item },
                                  () => {
                                    this.handlePopoverClose();
                                  }
                                )
                              }
                            >
                              {" "}
                              <label
                                className={classes?.dot}
                                style={{ background: item?.color ?? "" }}
                              ></label>{" "}
                              &nbsp;{item?.value}
                            </div>
                          );
                        })}
                      </div>
                    </Popover>
                  </div>
                )}

                {/* medication section */}
                {!isCardVerify && this.state.patientId && (
                  <Grid
                    id={"active_medication_grid"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    aliasName={"activeMedicationGrid"}
                    style={{ width: "100%", marginBottom: 6 }}
                  >
                    {/* Route Header Starts */}
                    <Grid
                      id={"medication_header_grid"}
                      container={true}
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      aliasName={"medicationHeaderGrid"}
                      style={qdmstyles.KEK}
                      className={classNames(
                        classes.MainBackground,
                        classes.bgWhite
                      )}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <div
                          onClick={() =>
                            this.changeState(
                              "activeSelect",
                              "Medication Profile"
                            )
                          }
                        >
                          <Typography
                            id={"1XDBa"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={t("activeMedication.title")}
                            style={
                              this.state?.activeSelect === "Medication Profile"
                                ? qdmstyles.FRiN
                                : qdmstyles.XDBa
                            }
                          ></Typography>
                        </div>
                        {this.state.activeSelect !== "Medication Profile" && (
                          <div
                            onClick={() =>
                              this.changeState(
                                "activeSelect",
                                "Medication Profile"
                              )
                            }
                          >
                            <Typography
                              id={"FRiN5"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"/ " + this.state.activeSelect}
                              style={
                                this.state?.activeSelect !==
                                  "Medication Profile"
                                  ? qdmstyles.FRiN
                                  : qdmstyles.XDBa
                              }
                              className={classes.Maintitle}
                            ></Typography>
                          </div>
                        )}
                        {this.state.activeSelect !== "Medication Profile" && (
                          <Box
                            key={"2"}
                            id={"number_box"}
                            m={"1"}
                            component={"div"}
                            aliasName={"numberBox"}
                            style={qdmstyles.tQNRo}
                            className={classes.activeback}
                          >
                            <Typography
                              id={"73r9Y"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={this.props.activeMedicationData.length}
                              style={qdmstyles.rY}
                              className={classes.activebacktext}
                            ></Typography>
                          </Box>
                        )}
                      </div>
                      {this.state?.activeSelect !== "Medication Profile" ? (
                        <div className={classes.topbarRightContainer}>
                          {this.state.selectedActiveMedicationList?.length >
                            0 && (
                              <IconButton>
                                <Tooltip title="Re-issue Medication">
                                  <IssueMedication
                                    color={theme.palette.text.primary}
                                    onClick={() =>
                                      this.handleOpenIssueMedication()
                                    }
                                  />
                                </Tooltip>
                              </IconButton>
                            )}

                          <div
                            className={classNames({
                              [classes.selectCountContainer]: true,
                              [classes.itemPresent]:
                                this.state.selectedActiveMedicationList
                                  ?.length > 0,
                            })}
                          >
                            {this.state.selectedActiveMedicationList?.length >
                              0 && (
                                <Typography
                                  component="span"
                                  className={classes.selectCountText}
                                >
                                  {`${this.state.selectedActiveMedicationList?.length} Selected`}
                                </Typography>
                              )}
                            <div
                              className={classes.selectIcon}
                              onClick={this.handleDrugReturn}
                            >
                              <HandWithPillIcon color={"#fff"} />
                            </div>
                          </div>
                          <div
                            className={classes.selectIcon}
                            onClick={this.handleFullScreen}
                          >
                            <FullscreenIcon />
                          </div>
                          <Divider flexItem orientation="vertical" />
                          <div className={classes.iconsContainer}>
                            {this.state.selectedUI === "grid" && (
                              <div className={classes.previousNextMedications}>
                                <IconButton
                                  disableRipple
                                  size="small"
                                  disabled={
                                    this.state.currentGridStartIndex === 0
                                  }
                                  onClick={() => {
                                    this.setState((prev) => ({
                                      ...prev,
                                      currentGridStartIndex:
                                        prev.currentGridStartIndex - 3,
                                    }));
                                  }}
                                  style={{
                                    border: "1px solid",
                                  }}
                                >
                                  <KeyboardArrowLeftIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  disableRipple
                                  size="small"
                                  disabled={
                                    this.state.currentGridStartIndex + 3 >
                                    this.props.activeMedicationData?.length - 1
                                  }
                                  onClick={() => {
                                    this.setState((prev) => ({
                                      ...prev,
                                      currentGridStartIndex:
                                        prev.currentGridStartIndex + 3,
                                    }));
                                  }}
                                  style={{
                                    border: "1px solid",
                                  }}
                                >
                                  <KeyboardArrowRightIcon fontSize="small" />
                                </IconButton>
                              </div>
                            )}
                            <div>
                              <ToggleButtons
                                classes={classes}
                                value={this.state.selectedUI}
                                onChange={(newValue) =>
                                  this.changeState("selectedUI", newValue)
                                }
                                options={[
                                  {
                                    value: "grid",
                                    children: (
                                      <GridIcon
                                        color={
                                          this.state.selectedUI === "grid"
                                            ? "#2A60BC"
                                            : "#6A7888"
                                        }
                                      />
                                    ),
                                  },
                                  {
                                    value: "list",
                                    children: (
                                      <ListIcon
                                        color={
                                          this.state.selectedUI === "list"
                                            ? "#2A60BC"
                                            : "#6A7888"
                                        }
                                      />
                                    ),
                                  },
                                ]}
                              ></ToggleButtons>
                            </div>
                          </div>
                          <Divider flexItem orientation="vertical" />
                          <div
                            className={classes.selectIcon}
                            onClick={this.handleShowMedicationProfile}
                          >
                            {this.state?.showMedicationProfile ? (
                              <IndeterminateCheckBoxOutlinedIcon />
                            ) : (
                              <AddBoxOutlinedIcon />
                            )}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                    {/* Route Header Ends */}
                    <div
                      className={
                        this.state?.activeSelect === "Medication Profile"
                          ? classes.MainBackground
                          : classes.bgWhite
                      }
                      style={{ margin: "10px 0px 0px 0px" }}
                    >
                      <Grid
                        id={"activeMed"}
                        container={true}
                        direction={"row"}
                        // justifyContent={"center"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        aliasName={"activeMed"}
                        style={{
                          // padding: "16px",
                          height: "auto",
                          overflow: "auto",
                          // paddingTop: "0px",
                          ...(this.state?.activeSelect === "Medication Profile"
                            ? { padding: "0px 16px 16px 16px" }
                            : {
                              padding: "",
                              // padding: "0px 16px 16px 16px",
                            }),
                        }}
                        spacing={2}
                      >
                        {this.state?.activeSelect === "Medication Profile"
                          ? this.props.medicationList?.data?.map((val, i) => {
                            if (val?.isfilter) return <></>;
                            return (
                              <Grid
                                key={val?.reportid}
                                container={false}
                                justifyContent={"center"}
                                alignItems={"center"}
                                aliasName={"activeMedicationListGrid"}
                                item={true}
                                style={{ padding: "6px" }}
                                lg={"3"}
                                md={"3"}
                                sm={"3"}
                                xs={"3"}
                                xl={"3"}
                              >
                                <ALL.MedProfileCard
                                  classes={classes}
                                  onClick={(val) =>
                                    this.medicationProfile(
                                      "activeSelect",
                                      val,
                                      this.props.medicationList?.fullData?.[0]
                                    )
                                  }
                                  val={val}
                                />
                              </Grid>
                            );
                          })
                          : this.state?.showMedicationProfile && (
                            <Grid
                              id={"active_medication_list_grid"}
                              container={false}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              aliasName={"activeMedicationListGrid"}
                              item={true}
                              lg={"12"}
                              md={"12"}
                              sm={"12"}
                              xs={"12"}
                              xl={"12"}
                              style={{
                                ...qdmstyles.ofbuz,
                                marginRight: 16,
                              }}
                              className={classes.bgWhite}
                            >
                              <ALL.ActiveMedication
                                classesTheme={classes}
                                propsState={this.props.activeMedicationData}
                                medicationCount={
                                  this.props.activeMedicationData.length
                                }
                                selectedUI={this.state.selectedUI}
                                currentGridStartIndex={
                                  this.state.currentGridStartIndex
                                }
                                selectedActiveMedicationList={
                                  this.state.selectedActiveMedicationList
                                }
                                handleAddCard={(item) => {
                                  this.setState((prev) => ({
                                    ...prev,
                                    selectedActiveMedicationList: [
                                      ...prev.selectedActiveMedicationList,
                                      item,
                                    ],
                                  }));
                                }}
                                handleDeleteCard={(_id) => {
                                  this.setState((prev) => {
                                    const newState = {
                                      ...prev,
                                      selectedActiveMedicationList:
                                        prev.selectedActiveMedicationList.filter(
                                          (val) => val?._id !== _id
                                        ),
                                    };
                                    return newState;
                                  });
                                }}
                              />
                              <Dialog
                                fullScreen
                                open={this.state.activeMedFullScreen}
                                onClose={this.closeFullScreen}
                                TransitionComponent={Transition}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: 16,
                                    background: theme.palette.neutral.main,
                                  }}
                                >
                                  <Typography style={{ color: "#fff" }}>
                                    Active Medication
                                  </Typography>
                                  <CloseIcon
                                    onClick={this.closeFullScreen}
                                    htmlColor="#fff"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>

                                <Divider />

                                <ALL.ActiveMedication
                                  isFullScreen={true}
                                  classesTheme={classes}
                                  propsState={this.props.activeMedicationData}
                                  medicationCount={
                                    this.props.activeMedicationData.length
                                  }
                                  selectedUI={this.state.selectedUI}
                                  currentGridStartIndex={
                                    this.state.currentGridStartIndex
                                  }
                                  selectedActiveMedicationList={
                                    this.state.selectedActiveMedicationList
                                  }
                                  handleAddCard={(item) => {
                                    this.setState((prev) => ({
                                      ...prev,
                                      selectedActiveMedicationList: [
                                        ...prev.selectedActiveMedicationList,
                                        item,
                                      ],
                                    }));
                                  }}
                                  handleDeleteCard={(_id) => {
                                    this.setState((prev) => {
                                      const newState = {
                                        ...prev,
                                        selectedActiveMedicationList:
                                          prev.selectedActiveMedicationList.filter(
                                            (val) => val?._id !== _id
                                          ),
                                      };
                                      return newState;
                                    });
                                  }}
                                />
                              </Dialog>
                            </Grid>
                          )}
                      </Grid>
                    </div>
                  </Grid>
                )}

                {(this.state?.activeSelect === "Medication Profile" &&
                  isCardVerify) ||
                  this.state?.activeSelect !== "Medication Profile" ? (
                  <div
                    id="fullScreenClick"
                    style={{
                      // backgroundColor: "#f2f3f6",
                      // borderTop: "2px solid #DEE5EC",
                      width: "100%",
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      marginTop: 6,
                    }}
                  >
                    <>
                      <MedicationTopBar
                        workflowView={this.state.workflowView}
                        handleWorkflowChange={this.handleWorkflowChange}
                        issueMedication={
                          this.state.selectedState?.id === "queuestates/10048"
                        }
                        returnMedication={
                          this.state.selectedState?.id ===
                          ("queuestates/10037" || "queuestates/10038")
                        }
                        hiddenReason={
                          this.state.selectedState?.scenarioid !==
                          "queuescenarios/10022"
                        }
                        handleVerifyReason={this.handleVerifyReason}
                        isMultiOrderSelect={this.state.multiOrderSelect}
                        drugIds={this.state.drugIds}
                        saveMultiOrder={this.saveMultiOrder}
                        reasondData={this.state.reasondData}
                        actions={
                          this.state?.selectedState?.[this.state.ScenrioName]
                          // this.props.pharmacyState?.[this.state.selectedTab]
                          //   ?.scenrioname
                          // ]
                        }
                        onBackButtonClick={() =>
                          this.changeState("activeSelect", "Medication Profile")
                        }
                        orderCount={this.props.orderCount}
                        isCardVerify={isCardVerify}
                        title={this.props.pharmacyState?.[0]?.scenrioname || ""}
                        tabs={
                          // isCardVerify
                          //   ? tabsData
                          //   :
                          this.props.pharmacyState || []
                        }
                        tabIndex={selectedTab}
                        onTabChange={this.handleTabChange}
                        onRefreshButtonClick={this.handleRefreshButtonClick}
                        onPrintButtonClick={this.handlePrintButtonClick}
                        // createNewOrder={this.createNewOrder}
                        selectedState={this.state?.selectedState?.label?.toLowerCase()}
                        createNewOrder={() =>
                          this.handleActiveMedicationAddDialog(true)
                        }
                        NewOrder={this.state.permission?.write?.includes(
                          "CLMM - Add New Order"
                        )}
                        printDiscription={this.state.permission?.write?.includes(
                          "CLMM - Print Prescription"
                        )}
                        onFullScreenWorkFlow={this.onFullScreenWorkFlow}
                      />
                      <div
                        style={{
                          padding: "12px 16px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                          overflow: "auto",
                          height: this.getWorkflowHeight(
                            fullScreenWorkFlow,
                            isCardVerify,
                            this.state?.showMedicationProfile
                          ),

                          paddingBottom: "50px",
                        }}
                        className={classes.MainBackground}
                        hiddenReason={
                          this.state.selectedState?.scenarioid !==
                          "queuescenarios/10022"
                        }
                      >
                        {this.props?.pharmacyWorkflowDataLoading ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              minHeight: 334,
                              overflowY: "scroll",
                            }}
                          >
                            <CircularProgress disableShrink />
                          </div>
                        ) : (
                          <>
                            {drugs?.length > 0 ? (
                              <>
                                {drugs?.map((drug, i) => {
                                  const currentState = this.state?.[drug?.id];
                                  if (this.state.workflowView === "compact") {
                                    return (
                                      <OrderItem
                                        isReverify={drug?.isReverify ?? false}
                                        isReverifyStatusreason={
                                          drug?.statusreason ?? ""
                                        }
                                        key={drug?.uniqueId}
                                        // ---------------------Tags ---------------//
                                        alertsCount={drug?.alerts?.length}
                                        dispenseStatus={drug?.ScenarioName}
                                        clinicalValidation={false}
                                        slidingScaleDose={
                                          drug?.SlidingScaleDose?.[0]
                                        }
                                        prnComment={drug?.prnComment}
                                        refillDate={
                                          drug.nextrefilldate > 0
                                            ? moment(
                                              drug.nextrefilldate * 1000
                                            ).format("DD MMM, YYYY")
                                            : false
                                        }
                                        pendingDuration={
                                          drug?.nextrefilldate > 0
                                            ? getDayDifference(
                                              drug?.nextrefilldate * 1000,
                                              drug?.occurEndDateTime * 1000
                                            )
                                            : false
                                        }
                                        orderType={drug?.orderType}
                                        alloweddisptype={drug?.alloweddisptype}
                                        dispenseType={drug?.disptype?.display}
                                        handleDoseType={(value) =>
                                          this.handleDoseType(value, drug, i)
                                        }
                                        _key={drug?._key}
                                        //--------------- Order Details ------------------>
                                        orderid={drug?._id}
                                        disptype={drug?.disptype?.code}
                                        isIntravenous={drug?.isIV}
                                        isCompound={drug?.isCompound}
                                        route={drug?.route}
                                        isSingleLine={getIsSingle(
                                          drug?.disptype?.code,
                                          drug?.brands
                                        )}
                                        name={drug?.name}
                                        dosage={drug?.doseStrength}
                                        frequency={drug?.frequency?.code}
                                        duration={drug?.duration}
                                        doctorName={drug?.doctorName}
                                        speciality={drug?.speciality}
                                        occurStartDateTime={drug?.startDate} // drug?.occurStartDateTime
                                        infusionRate={
                                          <>
                                            {drug?.ivInfusionRateValue}
                                            &nbsp;
                                            {drug?.ivInfusionRatetext}
                                          </>
                                        }
                                        infusionTime={
                                          <>
                                            {drug?.ivInfusionTime}
                                            &nbsp;
                                            {drug?.ivInfusionTimeUomtext}
                                          </>
                                        }
                                        typeOfInfusion={"ContinousInfusion"}
                                        // occurStartDateTime={drug?.occurStartDateTime}
                                        occurEndDateTime={drug?.endDate} //drug?.occurEndDateTime
                                        //-------- Order Frequency Props-----------//
                                        dosageFrequencyType={
                                          drug?.dosageFreqType
                                        }
                                        dosageFrequencyCode={
                                          drug?.dosageFreqCode
                                        }
                                        orderFrequencyDetails={drug?.FreqDetls}
                                        //----------Type Compound Drugs Props------------//
                                        CompoundQuantity={
                                          drug?.brands?.[0]?.totadmqty
                                        }
                                        CompoundQuantityUnit={
                                          drug?.brands?.[0]?.totadmuom
                                        }
                                        CompoundStatbility={
                                          drug?.drugDetails?.[0]?.stability
                                        }
                                        batchDrugStability={
                                          drug?.brands?.[0]?.batches?.[0]
                                            ?.expirydate
                                        }
                                        batchNo={
                                          drug?.brands?.[0]?.batches?.[0]?.id
                                        }
                                        onChangeBatch={(val) =>
                                          this.onChangeBatch(val, i)
                                        }
                                        //--------------- Selected State in Tab ---------------//
                                        selectedState={this.state?.selectedState?.label?.toLowerCase()}
                                        //------------------ DispenseDuration Props --------------//
                                        dispenseDuration={
                                          drug?.deliveringDispenseDuration
                                            ?.length > 0 ||
                                            drug?.deliveringDispenseDuration
                                              ?.length === 0
                                            ? drug?.deliveringDispenseDuration
                                            : drug?.dispenseDuration
                                        }
                                        dispenseDurationUOM={
                                          drug?.dosageDurationUOMIp
                                            ? drug?.dosageDurationUOMIp
                                            : drug?.dosageDurationUOM
                                        }
                                        onDispenseDurationChange={(value) =>
                                          this.handleDispenseDurationChange(
                                            value,
                                            drug?.id,
                                            i
                                          )
                                        }
                                        // --------------- FUll value of Specific Order ------------//
                                        orderValue={drug}
                                        //------------------ OrderedDrugs ------------//
                                        orderedDrugs={getBrand(
                                          drug?.brands,
                                          drug?.compounds,
                                          drug?.additives,
                                          drug?.isCompound,
                                          drug?.isIV
                                        )}
                                        // ----Action to Schedule Generation of Drug -----//
                                        onApplyDispenseDurationClick={() =>
                                          this.handleApplyDispenseDurationClick(
                                            drug?.id
                                          )
                                        }
                                        // --------- Order Brands for Unit dose, compound Medicatio, addtictive ---------//
                                        brands={drug?.brands}
                                        compounds={drug?.compounds}
                                        addictive={drug?.additives}
                                        //-------- Batch change -----//
                                        onBatchQuantityChange={(
                                          value,
                                          brandId,
                                          index_,
                                          batch_inx,
                                          isMedication,
                                          fill,
                                          b
                                        ) => {
                                          this.handleBatchQuantityChange(
                                            value,
                                            brandId,
                                            drug?.id,
                                            i,
                                            index_,
                                            batch_inx,
                                            isMedication,
                                            fill,
                                            b
                                          );
                                        }}
                                        //------- Added new Drugs ------//
                                        addDrugOption={this.props?.addDrug}
                                        handleAdd={(val, _i) => {
                                          this.handleAdd(val, _i, i);
                                        }}
                                        handleDelete={(_i) => {
                                          this.handleDelete(_i, i);
                                        }}
                                        handleChangebrandName={(
                                          value,
                                          data_
                                        ) => {
                                          this.handleChangebrandName(
                                            value,
                                            data_,
                                            i,
                                            drug?.id
                                          );
                                        }}
                                        //------------ Direction --------------//
                                        directions={
                                          drug?.PH_OrderLineDispenseSchedule
                                            ?.length > 0 &&
                                          drug
                                            ?.PH_OrderLineDispenseSchedule?.[0]
                                            ?.dispensedetails
                                        }
                                        handleDirectionSaveClick={(
                                          value,
                                          brand_index,
                                          brand
                                        ) =>
                                          this.handleDirectionSaveClick(
                                            value,
                                            i,
                                            drug,
                                            brand_index
                                          )
                                        }
                                        //------ multi select -----------//
                                        isMultiOrderSelect={
                                          this.state.multiOrderSelect
                                        }
                                        isMultiOrderSelectFuntion={
                                          this.isMultiOrderSelect
                                        }
                                        drug={drug}
                                        //------------ show all batch ----//
                                        // on hold
                                        isOnHold={drug?.isOnHold ?? false}
                                        handleShowAllBatch={(
                                          bool,
                                          batch,
                                          type,
                                          _i_
                                        ) =>
                                          this.handleShowAllBatch(
                                            drug?.id,
                                            bool,
                                            batch,
                                            type,
                                            _i_,
                                            i
                                          )
                                        }
                                        isMultiBatch={
                                          this.state?.selectedState?.label?.toLowerCase() ===
                                          "verification" ||
                                          this.state?.selectedState?.label?.toLowerCase() ===
                                          "filling"
                                        }
                                        handleMedicationEdit={
                                          this.handleEditMedicationInfoForm
                                        }
                                        issuedOnDate={
                                          drug
                                            ?.PH_OrderLineDispenseSchedule?.[0]
                                            ?.dispdate
                                        }
                                        returnPolicy={drug?.returnPolicy}
                                        onBatchQuantityChangeDrug={(
                                          value,
                                          brandId,
                                          index_,
                                          batch_inx,
                                          isMedication
                                        ) => {
                                          this.onBatchQuantityChangeDrug(
                                            value,
                                            brandId,
                                            drug?.id,
                                            i,
                                            index_,
                                            batch_inx,
                                            isMedication
                                          );
                                        }}
                                        issueQuantity={(val, brandIndex) =>
                                          this.issueQuantity(val, brandIndex, i)
                                        }
                                        // hiddenReason={
                                        //   this.state.selectedState
                                        //     ?.scenarioid !==
                                        //   "queuescenarios/10022"
                                        // }
                                        // returnMedicationReason={
                                        //   this.props.returnMedicationReason
                                        // }
                                        isReturnMedicationEnable={
                                          this.state.selectedState
                                            ?.scenarioid ===
                                            "queuescenarios/10022"
                                            ? false
                                            : drug?.partialAllow || false
                                        }
                                        isReturnMedication={
                                          drug?.returnApplicable ?? false
                                        }
                                        issueMedication={
                                          this.state.selectedState?.id ===
                                          "queuestates/10048"
                                        }
                                        isCardVerify={isCardVerify}
                                      />
                                    );
                                  } else if (
                                    this.state.workflowView === "default"
                                  ) {
                                    return (
                                      <>
                                        <DrugItem
                                          //error
                                          isError={
                                            drug?.DispenseErrmessage
                                              ?.statusCode === "200" ||
                                              !drug?.DispenseErrmessage
                                                ?.statusCode
                                              ? false
                                              : true
                                          }
                                          errorMessage={
                                            drug?.DispenseErrmessage
                                              ?.errorMessage
                                          }
                                          isReverify={drug?.isReverify ?? false}
                                          isReverifyStatusreason={
                                            drug?.statusreason ?? ""
                                          }
                                          isOnHold={drug?.isOnHold ?? false}
                                          issueQuantity={(val, brandIndex) =>
                                            this.issueQuantity(
                                              val,
                                              brandIndex,
                                              i
                                            )
                                          }
                                          isMultiOrderSelect={
                                            this.state.multiOrderSelect
                                          }
                                          isMultiOrderSelectFuntion={
                                            this.isMultiOrderSelect
                                          }
                                          dosageFreqType={drug?.dosageFreqType}
                                          issueMedication={
                                            this.state.selectedState?.id ===
                                            "queuestates/10048"
                                          }
                                          onChangeBatch={(val) =>
                                            this.onChangeBatch(val, i)
                                          }
                                          SlidingScaleDose={
                                            drug?.SlidingScaleDose
                                          }
                                          prnComment={drug?.prnComment}
                                          add_drug={this.props?.addDrug}
                                          SpecificScenario={
                                            this.props.QueueListData?.[
                                            drug?.ScenarioName
                                            ]
                                          }
                                          handleEditMedicationInfoForm={
                                            this.handleEditMedicationInfoForm
                                          }
                                          key={drug?.uniqueId}
                                          isCompound={drug?.isCompound}
                                          isCardVerify={isCardVerify}
                                          Order_id={drug?._id}
                                          returnPolicy={drug?.returnPolicy}
                                          hiddenReason={
                                            this.state.selectedState
                                              ?.scenarioid !==
                                            "queuescenarios/10022"
                                          }
                                          returnMedicationReason={
                                            this.props.returnMedicationReason
                                          }
                                          isReturnMedicationEnable={
                                            this.state.selectedState
                                              ?.scenarioid ===
                                              "queuescenarios/10022"
                                              ? false
                                              : drug?.partialAllow || false
                                          }
                                          isReturnMedication={
                                            drug?.returnApplicable ?? false
                                          } //Make it true for return medication
                                          handleRefreshButtonClick={
                                            this.handleRefreshButtonClick
                                          }
                                          patientID={this.state.patientId}
                                          encounterID={
                                            this.props.encounterData?.[
                                              this.state.activeIndex
                                            ]?._id ?? ""
                                          }
                                          statusID={drug?.status_id}
                                          orgID={drug?.org_Id}
                                          encountertype={
                                            this.props.encounterData?.[
                                            this.state.activeIndex
                                            ] ?? []
                                          }
                                          medication_list={
                                            this.props.medication_list
                                          }
                                          createNewOrder={this.createNewOrder}
                                          addnewmedication={
                                            this.state.addnewmedication
                                          }
                                          drug={drug}
                                          handleDirectionSaveClick={(
                                            value,
                                            brand_index,
                                            brand
                                          ) =>
                                            this.handleDirectionSaveClick(
                                              value,
                                              i,
                                              drug,
                                              brand_index
                                            )
                                          }
                                          reasondData={this.state.reasondData}
                                          dispenseDuration={
                                            currentState?.dispenseDuration ?? ""
                                          }
                                          handlepharmacyRefresh={
                                            this.handlepharmacyRefresh
                                          }
                                          handleDoseType={(value) =>
                                            this.handleDoseType(value, drug, i)
                                          }
                                          onDispenseDurationChange={(value) =>
                                            this.handleDispenseDurationChange(
                                              value,
                                              drug?.id,
                                              i
                                            )
                                          }
                                          dispenseFrequency={
                                            currentState?.dispenseFrequency ??
                                            ""
                                          }
                                          onDispenseFrequencyChange={(value) =>
                                            this.handleDispenseFrequencyChange(
                                              value,
                                              drug?.id
                                            )
                                          }
                                          isDispenseDurationApplied={
                                            currentState?.isDispenseDurationApplied
                                          }
                                          isMultiBatch={
                                            this.state?.selectedState?.label?.toLowerCase() ===
                                            "verification" ||
                                            this.state?.selectedState?.label?.toLowerCase() ===
                                            "filling"
                                          }
                                          onApplyDispenseDurationClick={() =>
                                            this.handleApplyDispenseDurationClick(
                                              drug?.id
                                            )
                                          }
                                          handleShowAllBatch={(
                                            bool,
                                            batch,
                                            type,
                                            _i_
                                          ) =>
                                            this.handleShowAllBatch(
                                              drug?.id,
                                              bool,
                                              batch,
                                              type,
                                              _i_,
                                              i
                                            )
                                          }
                                          // Batch
                                          batchQuantity={
                                            currentState?.batchQuantity ?? ""
                                          }
                                          onBatchQuantityChangeDrugBatchIssuMedication={(
                                            value,
                                            index_,
                                            batch_inx
                                          ) => {
                                            this.onBatchQuantityChangeDrugBatchIssuMedication(
                                              value,
                                              i,
                                              index_,
                                              batch_inx
                                            );
                                          }}
                                          onBatchQuantityChangeDrug={(
                                            value,
                                            brandId,
                                            index_,
                                            batch_inx,
                                            isMedication
                                          ) => {
                                            this.onBatchQuantityChangeDrug(
                                              value,
                                              brandId,
                                              drug?.id,
                                              i,
                                              index_,
                                              batch_inx,
                                              isMedication
                                            );
                                          }}
                                          onBatchQuantityChange={(
                                            value,
                                            brandId,
                                            index_,
                                            batch_inx,
                                            isMedication,
                                            fill,
                                            b
                                          ) => {
                                            this.handleBatchQuantityChange(
                                              value,
                                              brandId,
                                              drug?.id,
                                              i,
                                              index_,
                                              batch_inx,
                                              isMedication,
                                              fill,
                                              b
                                            );
                                          }}
                                          handleChangebrandName={(
                                            value,
                                            data_
                                          ) => {
                                            this.handleChangebrandName(
                                              value,
                                              data_,
                                              i,
                                              drug?.id
                                            );
                                          }}
                                          handleDelete={(_i) => {
                                            this.handleDelete(_i, i);
                                          }}
                                          handleAdd={(val, _i) => {
                                            this.handleAdd(val, _i, i);
                                          }}
                                          //handleVerify
                                          handleVerify={(
                                            action,
                                            edirReason,
                                            isMedication
                                          ) =>
                                            this.handleVerify(
                                              action,
                                              drug,
                                              i,
                                              edirReason,
                                              isMedication
                                            )
                                          }
                                          handleDiscontinue={(action) =>
                                            this.handleDiscontinue(
                                              action,
                                              drug,
                                              i
                                            )
                                          }
                                          index={i}
                                          handleVerifyReason={
                                            this.handleVerifyReason
                                          }
                                          actions={
                                            this.state?.selectedState?.[
                                            drug?.ScenarioName
                                            ]
                                          }
                                          selectedState={this.state?.selectedState?.label?.toLowerCase()}
                                          // Is Intravenous fluid type
                                          isIntravenous={drug?.isIV}
                                          // Tags Props */}
                                          _key={
                                            drug
                                              ?.PH_OrderLineDispenseSchedule?.[0]
                                              ?._key
                                          }
                                          tags={{
                                            alerts: drug?.alerts ?? "",
                                          }}
                                          scenarioName={drug?.ScenarioName}
                                          FreqDetls={drug.FreqDetls}
                                          nextrefilldate={drug.nextrefilldate}
                                          occurEndDateTime={
                                            drug.occurEndDateTime
                                          }
                                          occurStartDateTime={
                                            drug.occurStartDateTime
                                          }
                                          dosageFreqCode={drug.dosageFreqCode}
                                          selectedStateLabel={
                                            this.state?.selectedState?.label
                                          }
                                          expiryOnDate={
                                            drug
                                              ?.PH_OrderLineDispenseSchedule?.[0]
                                              ?.disptodate
                                          }
                                          issuedOnDate={
                                            drug
                                              ?.PH_OrderLineDispenseSchedule?.[0]
                                              ?.dispdate
                                          }
                                          addDrug={this.props?.addDrug}
                                        />
                                      </>
                                    );
                                  }
                                })}
                              </>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  minHeight: 334,
                                  overflowY: "scroll",
                                }}
                              >
                                No {this.state?.selectedState?.label} Data
                                Found!
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  </div>
                ) : (
                  <Grid
                    style={{
                      backgroundColor: "#fff",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        height: "calc(100vh - 400px)",
                        overflow: "auto",
                        padding: "0px 8px 8px",
                      }}
                      className={classes.MainBackground}
                    ></div>
                  </Grid>
                )}
              </Grid>

              {/* <Grid item xs={12}>
                // ACTIVEMEDIACATION_AGAINST_PATIENT
                <ALL.DynamicTable headCells={headCells} rows={rows} />
              </Grid> */}

              <div className={classes.MainBackgroundCard} style={qdmstyles.que}>
                {/* <Typography
                  id={"sVsqi"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={
                    t("Cart & Queue & More") +
                    ` (${
                      this.state?.totalQueueCount ||
                      this.props?.patientData?.length
                    })`
                  }
                  style={qdmstyles.sVsqi}
                ></Typography> */}

                <div className={classes.subDrawerHeader}>
                  <Typography
                    id={"sVsqi"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={
                      t("In Queue") +
                      ` (${this.state?.totalQueueCount ||
                      this.props?.patientData?.length
                      })`
                    }
                    style={qdmstyles.sVsqi}
                  ></Typography>
                  <IconButton
                    className={classes.closeBtn}
                    autoFocus
                    color="inherit"
                    onClick={() => this.handleDrawerOpen()}
                  >
                    <ExpandLessIcon />
                  </IconButton>
                  {/* <Box
                    id={"up_arrow_box"}
                    m={"1"}
                    component={"div"}
                    aliasName={"upArrowBox"}
                    onClick={() => this.handleDrawerOpen()}
                  >
                    <Avatar
                      // onClick={() => {
                      //   this.getDashboardData();
                      // }}

                      id={"up_arrow_logo"}
                      alt={"Avatar"}
                      variant={"rounded"}
                      aliasName={"upArrowLogo"}
                      src={
                        "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/319999117"
                      }
                      style={qdmstyles.SHqC}
                    ></Avatar>
                  </Box> */}
                </div>

                {!this.state.isCardVerify && (
                  <Paper square className={classes.tabs}>
                    <Tabs
                      variant="fullWidth"
                      value={this.state.isQueue}
                      onChange={this.onTabChange}
                    >
                      {Array.isArray(this.props.priorityList) &&
                        this.props.priorityList?.map((item, index) => {
                          const currentQueue =
                            Array.isArray(this.props.queue_count) &&
                            this.props.queue_count?.filter(
                              (x) => x?.GrpId === item?.id
                            )?.[0];

                          return (
                            <Tooltip title={item?.label ?? "default"} arrow>
                              <Tab
                                style={{
                                  minWidth: "35px",
                                  // color: theme.palette.text.main
                                }}
                                key={item?.id ?? index}
                                showZero
                                label={
                                  // QueueIcons[item.label]
                                  <ALL.SimpleBadge
                                    bgcolor={item?.bgcolor}
                                    textcolor={item?.textcolor}
                                    badgeContent={
                                      item.id
                                        ? currentQueue?.Grpcount
                                          ? currentQueue?.Grpcount
                                          : 0
                                        : this.state?.totalQueueCount ||
                                        this.props?.patientData?.length
                                    }
                                    component={
                                      item?.icon === "All" ? (
                                        "All"
                                      ) : item?.icon ? (
                                        <img
                                          src={
                                            this.state.isQueue === index
                                              ? item?.activeIcon
                                              : item?.icon
                                          }
                                          alt={item.label}
                                        />
                                      ) : (
                                        <>{"N/A"}</>
                                      )
                                    }
                                  />
                                }
                              />
                            </Tooltip>
                          );
                        })}
                    </Tabs>
                  </Paper>
                )}
                {/* &nbsp;&nbsp; */}
              </div>
              <ALL.ScreenDialog
                selectedTab={this.state.isQueue}
                onTabChange={this.onTabChange}
                handleGetQueueData={this.handleGetQueueData}
                queue_count={this.props.queue_count}
                priorityList={this.props.priorityList}
                isHidden={!this.state.isCardVerify}
                open={this.state.queueDrawerOpen}
                handleCloseDrawer={() => this.handleCloseDrawer()}
                header={"In Queue"}
                count={
                  this.state?.totalQueueCount || this.props?.patientData?.length
                }
                pendingCount={0}
                handleSearch={this.handleSearch}
                handleSearchOnclick={this.handleSearchOnclick}
                search={this.state.search}
                practitionerData={
                  this.props.encounterData?.[this.state.activeIndex]
                }
                handleUpdateState={this.handleUpdateState}
                isCardVerify={this.props?.location.state?.isCardVerify}
                bodyComp={
                  <>
                    {!this.props.patientDataLoading ? (
                      <ALL.PatientDisplay
                        propsState={this.props.patientData}
                        Didupdate={this.props?.location.state}
                        isCardVerify={this.props?.location.state?.isCardVerify}
                        patientQueueStatusData={
                          this.props.patientQueueStatusData
                        }
                        encounterData={
                          this.props?.encounterData?.[this.state.activeIndex]
                        }
                        queueId={this.props.queueId}
                        handleAttend={(patient) => {
                          this.setState({
                            ...this.state,
                            queueDrawerOpen: false,
                            pharmacyLoad: true,
                          });
                          this.getDashboardData(patient);
                        }}
                        setState={this.setState}
                        state={this.state}
                        patientNotInQueue={this.props.patientNotQueueData}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress disableShrink />
                      </div>
                    )}
                  </>
                }
              />
            </Grid>

            {/* // pdf Dialog........................... */}

            <Dialog
              open={this.state.pdfOpen}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              PaperProps={{
                classes: { root: `dialogpaper ${classes.MainBackgroundCard}` },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                Prescription Printing
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Document
                    file={this.props?.labelPrint?.downloadUrl}
                    options={{ workerSrc: "/pdf.worker.js" }}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                  >
                    {Array.from(new Array(this.state.numPages), (el, index) => (
                      <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    ))}
                  </Document>
                  {/* <Document file={this.props?.labelPrint?.downloadUrl}>
                    <Page pageNumber={1} />
                  </Document> */}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            {/* Add Medication Info Dialog */}
            <StyledDialog
              id="Add Medication Info"
              open={this.state.activeMedicationOpen}
              handleClose={() => {
                this.setState(
                  {
                    activeMedicationEditId: "",
                  },
                  () => {
                    this.handleActiveMedicationAddDialog(false);
                  }
                );
              }}
              title={"ADD MEDICATION"}
              dialogProps={{
                classes: {
                  paper: classes.addNewMedicationPaper,
                },
              }}
            // contentProps={{
            //   classes: {
            //     dividers: classes.addNewMedicationPaperDividers,
            //   },
            // }}
            >
              <NewOrderComponent
                // <MedicationInfoForm
                orderId={this.state.activeMedicationEditId}
                patientID={this.state?.patientId}
                encounterID={
                  this.props.encounterData?.[this.state.activeIndex]?._id ?? ""
                }
                encounterKey={
                  this.props.encounterData?.[this.state.activeIndex]?._key ?? ""
                }
                encountertype={
                  this.props.encounterData?.[this.state.activeIndex]
                    ?.type_id?.[0]
                }
                statusID={"CodingMaster/10704"}
                isAdd={!Boolean(this.state.activeMedicationEditId)}
                handleMedicationClose={() => {
                  this.setState(
                    {
                      activeMedicationEditId: "",
                    },
                    () => {
                      this.handleActiveMedicationAddDialog(false);
                    }
                  );
                }}
                handleRefreshButtonClick={this.handleRefreshButtonClick}
                // Data for Requestor Details
                organizationId={
                  this.props?.encounterData?.[0]?.Location?.[0]?.managingOrgID
                }
                practitionerRoleId={{
                  label:
                    this.props?.loggerUserInfo?.role?.role?.[0]?.display ?? "",
                  value: this.props?.loggerUserInfo?.role?.role?.[0]?._id,
                  id: this.props?.loggerUserInfo?.role?.role?.[0]?.id,
                }}
                orderBy={{
                  label:
                    (JSON.parse(atob(localStorage.getItem("userInfoKeys")))
                      ?.practDetails?.[0]?.name?.[0]?.text ?? "") +
                    " " +
                    (JSON.parse(atob(localStorage.getItem("userInfoKeys")))
                      ?.practDetails?.[0]?.name?.[0]?.given ?? "") +
                    " " +
                    (JSON.parse(atob(localStorage.getItem("userInfoKeys")))
                      ?.practDetails?.[0]?.name?.[0]?.family ?? ""),

                  value:
                    JSON.parse(atob(localStorage.getItem("userInfoKeys")))
                      ?.practDetails?.[0]?._id ?? "",
                }}
                practitionerId={
                  JSON.parse(atob(localStorage.getItem("userInfoKeys")))
                    ?.practDetails?.[0]?._id ?? ""
                }
                practitionerCode={
                  JSON.parse(atob(localStorage.getItem("userInfoKeys")))
                    ?.practitionerCode ?? ""
                }
                practDetails={this.props?.loggerUserInfo}
                organization={{
                  label: this.props?.loggerUserInfo?.org?.name ?? "",
                  value: this.props?.loggerUserInfo?.org?._id ?? "",
                  id: this.props?.loggerUserInfo?.org?.id ?? "",
                }}
                locationData={{
                  label: this.props?.loggerUserInfo?.location?.shortdesc ?? "",
                  value: this.props?.loggerUserInfo?.location?._id ?? "",
                }}
                encounterData={
                  this.props?.encounterData?.[this.state.activeIndex]
                }
                patientInfoData={this.props.patientInfo[0]}
                encounterInfoData={
                  this.props.encounterData?.[this.state.activeIndex]
                }
              />
            </StyledDialog>

            {/* Issue Medication */}

            <StyledDialog
              id="ISSUE MEDICATION"
              open={this.state.issueMedication}
              handleClose={() => this.handleOpenIssueMedication()}
              title={
                "ISSUE MEDICATION" +
                " - " +
                (this.state.selectedActiveMedicationList?.[0]?.drugName ?? "")
              }
              dialogProps={{
                classes: {
                  paper: classes.addNewMedicationPaper,
                },
              }}
              contentProps={{
                classes: {
                  dividers: classes.addNewMedicationPaperDividers,
                },
              }}
            >
              <IssueMedicationTable
                dataTabs={this.state.issueMedicationList ?? []}
                handleRessue={this.handleRessue}
              />

              <div
                style={{
                  marginTop: 6,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Checkbox
                    checked={this.state.issueMedicationCharge}
                    onChange={() => this.handleOpenIssueMedicationCharge()}
                  />
                  &nbsp; Chargeable?
                </div>

                <div>
                  <Button
                    onClick={() => this.handleOpenIssueMedication()}
                    color="primary"
                    variant="outlined"
                    style={{
                      padding: "7px 21px",
                      borderRadius: "11px",
                    }}
                    className={classes.btns}
                  >
                    Cancel
                  </Button>
                  &nbsp;
                  <Button
                    onClick={() => this.SaveIssueMedication()}
                    color="primary"
                    variant="contained"
                    style={{
                      padding: "7px 21px",
                      borderRadius: "11px",
                    }}
                    className={classes.btns}
                  >
                    Reissue
                  </Button>
                </div>
              </div>
            </StyledDialog>
            {/* end */}
          </React.Fragment>
        )
        }
      </NamespacesConsumer>
    );
  }
}
const mapStateToProps = (state) => ({
  patientInfo: state?.phramcyFrameworkApiSlice?.patientBanner?.data,
  financialData: state?.phramcyFrameworkApiSlice?.financialBanner?.data,
  encounterData: state?.phramcyFrameworkApiSlice?.Encounterbanner?.data,
  vitalsData: state?.phramcyFrameworkApiSlice?.vitalsAgainstEncounter?.data,
  allergyData: state?.phramcyFrameworkApiSlice?.allergyAgainstPatient?.data,
  diagnosisData: state?.phramcyFrameworkApiSlice?.diagnosisAgainstPatient?.data,
  chiefComplaintsData:
    state?.phramcyFrameworkApiSlice?.chiefComplaintsAgainstPatient?.data,
  activeMedicationData: state?.phramcyFrameworkApiSlice?.activeMedication?.data,
  reportColumns:
    state?.phramcyFrameworkApiSlice?.activeMedication?.reportColumns,
  reportRows: state?.phramcyFrameworkApiSlice?.activeMedication?.reportRows,
  patientData: state?.phramcyFrameworkApiSlice?.patientQueue?.data,
  patientDataLoading: state?.phramcyFrameworkApiSlice?.patientQueue?.loading,
  patientNotQueueData:
    state?.phramcyFrameworkApiSlice?.patientSearchNotInQueue?.data,
  patientNotQueueDataLoading:
    state?.phramcyFrameworkApiSlice?.patientSearchNotInQueue?.loading,
  pharmacyState: state?.phramcyFrameworkApiSlice?.pharmacyGettingState?.data,
  pharmacyFrameworkMultiBatch:
    state?.phramcyFrameworkApiSlice?.pharmacyFrameworkMultiBatch?.data,
  pharmacyWorkflowData:
    state?.phramcyFrameworkApiSlice?.pharmacyFramework?.data,
  pharmacyWorkflowDataLoading:
    state?.phramcyFrameworkApiSlice?.pharmacyFramework?.loading,
  pharmacy_verify_state: state?.phramcyFrameworkApiSlice?.pharmacy_verify?.data,
  medicationList: state?.phramcyFrameworkApiSlice?.medicationList?.data,
  medication_list: state.MedicationMastersSlice?.medication_list?.data,
  labelPrint: state?.phramcyFrameworkApiSlice?.label_print?.data,
  practitionerData: state?.signInReducerApiSlice?.searchPractioner?.data,
  practitionerDetails: state?.signInReducerApiSlice?.getPractitonerData?.data,
  loggerUserInfo: state?.signInReducerApiSlice?.loggedUserInfo?.data,
  getActiveMedicationDataWithPhOrderId:
    state?.phramcyFrameworkApiSlice?.getActiveMedicationDataWithPhOrderId,
  returnMedicationReason:
    state?.phramcyFrameworkApiSlice?.returnMedicationReason,
  QueueListData: state?.phramcyFrameworkApiSlice?.getQueueList?.data,
  orderCount: state?.phramcyFrameworkApiSlice?.orderCount?.data,
  Scenarios: state?.phramcyFrameworkApiSlice?.getScenarioList?.data,
  addDrug: state?.phramcyFrameworkApiSlice?.add_drug?.data,
  queueId: state?.phramcyFrameworkApiSlice?.accessedQueue?.data,
  priorityList: state?.phramcyFrameworkApiSlice?.get_priority_list?.data,
  queue_count: state?.phramcyFrameworkApiSlice?.get_queue_count?.data,
  patientQueueStatusData:
    state?.phramcyFrameworkApiSlice?.patientQueueStatusData?.data,
  qdmLogger: state?.signInReducerApiSlice?.qdm_log?.data,
});

export default withAllContexts(
  connect(
    mapStateToProps,
    actions
  )(
    withRouter(
      translate()(withStyles(styles, { withTheme: true })(PharmacyVerify))
    )
  )
);
