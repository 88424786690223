import React from "react";
import Consultmaster from "./consultMaster";

class ConsultParent extends React.Component {
  render() {
    return <Consultmaster />;
  }
}

export default ConsultParent;
