import * as React from "react";
import PropTypes from "prop-types";

const Phone = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.004"
    height="12.911"
    viewBox="0 0 10.004 12.911"
  >
    <path
      id="icons8-phone_1_"
      data-name="icons8-phone (1)"
      d="M12.238,4a1.806,1.806,0,0,0-.644.069L10.83,4.3A2.433,2.433,0,0,0,9.165,6c-.478,1.813.069,3.959,1.491,6.375s3.039,3.945,4.868,4.446a2.478,2.478,0,0,0,2.335-.569l.581-.539a1.76,1.76,0,0,0,.222-2.324l-.84-1.14a1.807,1.807,0,0,0-1.973-.652l-1.314.393c-.055.016-.187-.059-.3-.158h0a4.54,4.54,0,0,1-.791-1.051h0a4.69,4.69,0,0,1-.519-1.126.607.607,0,0,1-.034-.311.153.153,0,0,1,.05-.1h0l.98-.9a1.755,1.755,0,0,0,.414-2.023l-.591-1.288h0A1.8,1.8,0,0,0,12.238,4Zm-.07.966a.831.831,0,0,1,.694.472l.591,1.288a.774.774,0,0,1-.187.905l-.98.9a1.124,1.124,0,0,0-.36.7h0a1.923,1.923,0,0,0,.059.664,5.536,5.536,0,0,0,.621,1.374h0a5.183,5.183,0,0,0,.99,1.292h0a1.277,1.277,0,0,0,1.208.357h0l1.319-.4a.837.837,0,0,1,.915.3l.84,1.14a.779.779,0,0,1-.1,1.04l-.581.54a1.525,1.525,0,0,1-1.421.344c-1.478-.4-2.94-1.714-4.288-4S9.719,7.7,10.1,6.248a1.477,1.477,0,0,1,1-1.021L11.868,5A.835.835,0,0,1,12.168,4.969Z"
      transform="translate(-8.996 -3.999)"
      fill={props.color}
    />
  </svg>
);

export default Phone;

Phone.defaultProps = {
  color: "#2a3c50",
};

Phone.propTypes = {
  color: PropTypes.string,
};
