import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import { isDecimal, languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import {
  Grid,
  Paper,
  Typography,
  Button,
  IconButton,
  Checkbox,
} from "@material-ui/core";

// import { Autocomplete } from "@material-ui/lab";
import qdmstyles from "./styles.json";
import "./index.css";
import { InputBase, Autocomplete } from "..";
import { TrashIcon } from "../../assets";

var aes256 = require("aes256");

function Unitofmeasurecomp(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  const {
    data,
    index,
    options,
    statevalue = [],
    stateName,
    onAddNewClick = () => { },
    onDeleteClick = () => { },
    setFun = () => { },
    shouldDisable,
  } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"r8lZw"}
            container={""}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            lg={"12"}
            md={"12"}
            sm={"12"}
            xl={"12"}
            xs={"12"}
          >
            <Paper
              key={"0"}
              id={"hVUlX"}
              elevation={"{3}"}
              style={qdmstyles.LtO}
            >
              {/* <Grid
                key={"0"}
                id={"0iQGv"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-end"}
                item={true}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                spacing={"2"}
                style={qdmstyles.LuLpj}
              >
                <Grid
                  key={"0"}
                  id={"kbI7d"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item={true}
                  lg={"2"}
                  md={"2"}
                  sm={"2"}
                  xl={"2"}
                  xs={"2"}
                  style={qdmstyles.gJkJ}
                >
                  <Grid
                    key={"0"}
                    id={"Z4gyu"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    style={qdmstyles.jJJ}
                  >
                    <Typography
                      key={"0"}
                      id={"BaseStockUomHeading_Typography"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Base Stock Uom"}
                      aliasName={"BaseStockUomHeading"}
                      style={qdmstyles.AzVx}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"6RLAx"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                    lg={"12"}
                    md={"12"}
                    xl={"12"}
                    xs={"12"}
                    sm={"12"}
                    style={qdmstyles.FSP}
                  >
                    <Autocomplete
                      id={"sZ944"}
                      style={qdmstyles?.sZ}
                      value={state?.unitofmeasurecomp_sz944 ?? null}
                      onLoad={() =>
                        setState({
                          unitofmeasurecomp_sz944:
                            state?.unitofmeasurecomp_sz944 ?? null,
                        })
                      }
                      getOptionLabel={(option) => option?.["label"]}
                      options={[] ?? []}
                      size={"small"}
                      renderInput={(params) => (
                        <ALLMCORE.TextField
                          {...params}
                          label={"label"}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  key={"1"}
                  id={"kbI7d"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item={true}
                  lg={"2"}
                  md={"2"}
                  sm={"2"}
                  xl={"2"}
                  xs={"2"}
                  style={qdmstyles.KQxxG}
                >
                  <Grid
                    key={"0"}
                    id={"Z4gyu"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    style={qdmstyles.fjGmM}
                  >
                    <Typography
                      key={"0"}
                      id={"AverageRateHeading_Typography"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Average Rate"}
                      aliasName={"AverageRateHeading"}
                      style={qdmstyles.KGm}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"6RLAx"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                    lg={"12"}
                    md={"12"}
                    xl={"12"}
                    xs={"12"}
                    sm={"12"}
                    style={qdmstyles.hiPuT}
                  >
                    <TextField
                      key={"0"}
                      id={"AverageRateHeading_Typography"}
                      label={""}
                      placeholder={""}
                      type={"text"}
                      variant={"outlined"}
                      size={"small"}
                      aliasName={"AverageRateInput"}
                      fullWidth={true}
                      style={qdmstyles.FxFNF}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid
                  key={"2"}
                  id={"kbI7d"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item={true}
                  lg={"2"}
                  md={"2"}
                  sm={"2"}
                  xl={"2"}
                  xs={"2"}
                  style={qdmstyles.husub}
                >
                  <Grid
                    key={"0"}
                    id={"stock_header"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    aliasName={"stockheader"}
                    style={qdmstyles.DtBT}
                  >
                    <Typography
                      key={"0"}
                      id={"stockValueHeading_Typography"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Stock Value"}
                      aliasName={"stockValueHeading"}
                      style={qdmstyles.CIdGZ}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"6RLAx"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                    lg={"12"}
                    md={"12"}
                    xl={"12"}
                    xs={"12"}
                    sm={"12"}
                    style={qdmstyles.ss}
                  >
                    <TextField
                      key={"0"}
                      id={"stockValue_Texfield"}
                      label={""}
                      placeholder={""}
                      type={"text"}
                      variant={"outlined"}
                      size={"small"}
                      aliasName={"stockValueInput"}
                      fullWidth={true}
                      style={qdmstyles.WvQI}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid
                  key={"3"}
                  id={"kbI7d"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  item={true}
                  lg={"2"}
                  md={"2"}
                  sm={"2"}
                  xl={"2"}
                  xs={"2"}
                  style={qdmstyles.GSZZ}
                >
                  <Grid
                    key={"0"}
                    id={"rIaXw"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                  >
                    <Button
                      key={"0"}
                      id={"view_stock_details_button"}
                      variant={"text"}
                      color={"primary"}
                      children={"View Stock details"}
                      size={"small"}
                      fullWidth={""}
                      aliasName={"viewstockdetailsbutton"}
                      style={qdmstyles.HOlsc}
                    ></Button>
                  </Grid>
                </Grid>

                
              </Grid>
              <Grid
                key={"1"}
                id={"JFrVn"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
                lg={"12"}
              >
                <Box key={"0"} id={"EmTqx"} m={1} component={"div"}>
                  <Divider
                    key={"0"}
                    id={"ZMYao"}
                    variant={"fullWidth"}
                  ></Divider>
                </Box>
              </Grid> */}
              <Grid
                key={"2"}
                id={"3MSlo"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                item={true}
              >
                <Grid
                  key={"0"}
                  id={"JFZRu"}
                  container={true}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  item={true}
                  lg={"12"}
                  md={"12"}
                  sm={"12"}
                  xl={"12"}
                  xs={"12"}
                  style={qdmstyles.NrEq}
                >
                  <Grid
                    key={"0"}
                    id={"q4xYK"}
                    container={""}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    lg={"10"}
                    md={"10"}
                    sm={"10"}
                    xl={"10"}
                    xs={"10"}
                    item={true}
                  >
                    <Typography
                      key={"0"}
                      id={"gHOTR"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"APPLICABLE UOM"}
                      style={qdmstyles.BguM}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"Xxuio"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                    lg={"2"}
                    md={"2"}
                    sm={"2"}
                    xl={"2"}
                    xs={"2"}
                    style={qdmstyles.KZPj}
                  >
                    <Button
                      key={"0"}
                      disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                      id={"applicable_uom_add_new_button"}
                      variant={"text"}
                      color={"primary"}
                      children={"+ Add New"}
                      aliasName={"applicableuomaddnewbutton"}
                      style={qdmstyles.ARHq}
                      onClick={() => onAddNewClick(stateName)}
                    ></Button>
                  </Grid>
                </Grid>
                <Grid
                  key={"1"}
                  id={"7V2qa"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item={true}
                  lg={"12"}
                  md={"12"}
                  sm={"12"}
                  xl={"12"}
                  xs={"12"}
                >
                  <Grid
                    key={"0"}
                    id={"LUUaI"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    item={true}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    spacing={1}
                    alignContent={"center"}
                    style={{
                      rowGap: 10,
                    }}
                  >
                    {/* Label Starts */}
                    <Grid
                      key={"0"}
                      id={"0mTZK"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      spacing={"2"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                    >
                      <Grid
                        key={"0"}
                        id={"empYV"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"2"}
                        md={"2"}
                        sm={"2"}
                        xl={"2"}
                        xs={"2"}
                      >
                        <Typography
                          key={"0"}
                          id={"ApplicabilityHeading_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Applicability"}
                          aliasName={"ApplicabilityHeading"}
                          style={qdmstyles.WFauo}
                        ></Typography>
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"empYV"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"2"}
                        md={"2"}
                        sm={"2"}
                        xl={"2"}
                        xs={"2"}
                      >
                        <Typography
                          key={"0"}
                          id={"packuomHeading_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Pack Uom"}
                          aliasName={"packUomHeading"}
                          style={qdmstyles.usxT}
                        ></Typography>
                      </Grid>
                      <Grid
                        key={"2"}
                        id={"empYV"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={1}
                        md={1}
                        sm={1}
                        xl={1}
                        xs={1}
                      >
                        <Typography
                          key={"0"}
                          id={"packSizeHeading_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Pack Size"}
                          aliasName={"packSizeHeading"}
                          style={qdmstyles.hvwxy}
                        ></Typography>
                      </Grid>
                      <Grid
                        key={"3"}
                        id={"empYV"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"1"}
                        md={"1"}
                        sm={"1"}
                        xl={"1"}
                        xs={"1"}
                      >
                        <Typography
                          key={"0"}
                          id={"PackUnitFormHeading_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Pack Unit Uom"}
                          aliasName={"PackUnitFormHeading"}
                          style={qdmstyles.YeGM}
                        ></Typography>
                      </Grid>
                      {/* <Grid
                        key={"0"}
                        id={"empYV"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"1"}
                        md={"1"}
                        sm={"1"}
                        xl={"1"}
                        xs={"1"}
                      >
                        <Typography
                          key={"0"}
                          id={"DispenseUOMHeading_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"DispenseUOM"}
                          aliasName={"DispenseUOMHeading"}
                          style={qdmstyles.WFauo}
                        ></Typography>
                      </Grid> */}
                      <Grid
                        key={"1"}
                        id={"empYV"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"2"}
                        md={"2"}
                        sm={"2"}
                        xl={"2"}
                        xs={"2"}
                      >
                        <Typography
                          key={"0"}
                          id={"IndividualDoseRoundOffHeading_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Individual Dose RoundOff"}
                          aliasName={"IndividualDoseRoundOffHeading"}
                          style={qdmstyles.usxT}
                        ></Typography>
                      </Grid>
                      <Grid
                        key={"2"}
                        id={"empYV"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"1"}
                        md={"1"}
                        sm={"1"}
                        xl={"1"}
                        xs={"1"}
                     
                      >
                        <Typography
                          key={"0"}
                          id={"Round-offTypeHeading_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Round-off Type"}
                          aliasName={"Round-offTypeHeading"}
                          style={qdmstyles.hvwxy}
                        ></Typography>
                      </Grid>
                      <Grid
                        key={"3"}
                        id={"empYV"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"2"}
                        md={"2"}
                        sm={"2"}
                        xl={"2"}
                        xs={"2"}
                      >
                        <Typography
                          key={"0"}
                          id={"Round-offValueFormHeading_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Round-off Value"}
                          aliasName={"Round-offValueFormHeading"}
                          style={qdmstyles.YeGM}
                        ></Typography>
                      </Grid>
                    </Grid>
                    {/* Label Ends */}

                    {/* Input Starts */}
                    {Array.isArray(statevalue) &&
                      statevalue?.map((item, index) => {
                        const rowState = statevalue?.[index];
                        return (
                          <Grid
                            key={"1"}
                            id={"fVDRS"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            spacing={"1"}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                          >
                            <Grid
                              key={"0"}
                              id={"empYV"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"2"}
                              md={"2"}
                              sm={"2"}
                              xl={"2"}
                              xs={"2"}
                            >
                              <Autocomplete
                                id={"OqVny"}
                                style={qdmstyles?.OqVny}
                                getOptionLabel={(option) => option?.["label"]}
                                options={options?.applicability ?? []}
                                disabled={
                                  location?.state?.isView === true || !shouldDisable
                                    ? true
                                    : false
                                }
                                onLoad={() =>
                                  setFun(
                                    stateName,
                                    "applicableInd",
                                    rowState?.applicableInd,
                                    index
                                  )
                                }
                                value={rowState?.applicableInd ?? null}
                                onChange={(event, newValue) =>
                                  setFun(
                                    stateName,
                                    "applicableInd",
                                    newValue,
                                    index
                                  )
                                }
                              />
                            </Grid>
                            <Grid
                              key={"1"}
                              id={"empYV"}
                              container={true}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"2"}
                              md={"2"}
                              sm={"2"}
                              xl={"2"}
                              xs={"2"}
                            >
                              <Grid
                                key={"0"}
                                id={"empYV"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={"10"}
                                md={"10"}
                                sm={"10"}
                                xl={"10"}
                                xs={"10"}
                              >
                                <Autocomplete
                                  id={"lSown"}
                                  style={qdmstyles?.lSown}
                                  getOptionLabel={(option) => option?.["label"]}
                                  disabled={
                                    location?.state?.isView === true || !shouldDisable
                                      ? true
                                      : false
                                  }
                                  options={options?.pack_drug_uom ?? []}
                                  onLoad={() =>
                                    setFun(
                                      stateName,
                                      "packSizeUoM",
                                      rowState?.packSizeUoM,
                                      index
                                    )
                                  }
                                  value={rowState?.packSizeUoM ?? null}
                                  onChange={(event, newValue) =>
                                    setFun(
                                      stateName,
                                      "packSizeUoM",
                                      newValue,
                                      index
                                    )
                                  }
                                />
                              </Grid>
                              <Grid
                                key={"1"}
                                id={"empYV"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={"2"}
                                md={"2"}
                                sm={"2"}
                                xl={"2"}
                                xs={"2"}
                                style={qdmstyles.XQf}
                              >
                                <Typography
                                  key={"0"}
                                  id={"ApplicabilityHeading_Typography"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={"of"}
                                  aliasName={"packUomOf3_Typography"}
                                  style={qdmstyles.NrhHN}
                                ></Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              key={"2"}
                              id={"empYV"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={1}
                              md={1}
                              sm={1}
                              xl={1}
                              xs={1}
                            >
                              <InputBase
                                key={"0"}
                                id={"packSizeinput4_textfield"}
                                label={""}
                                placeholder={""}
                                disabled={
                                  location?.state?.isView === true || !shouldDisable
                                    ? true
                                    : false
                                }
                                type={"text"}
                                aliasName={"packSizeinput4"}
                                style={qdmstyles.PWtn}
                                inputProps={{
                                  maxlength: 7,
                                }}
                                onKeyPress={(event) => {
                                  if (!/[0-9.]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                value={
                                  rowState?.packSize ? rowState?.packSize : ""
                                }
                                onChange={(event) => {
                                  if (
                                    event.target.value.length === 0 ||
                                    isDecimal(event.target.value)
                                  ) {
                                    setFun(
                                      stateName,
                                      "packSize",
                                      event.target.value,
                                      index
                                    );
                                  }
                                }}
                              ></InputBase>
                            </Grid>
                            <Grid
                              key={"3"}
                              id={"empYV"}
                              container={true}
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              item={true}
                              lg={"1"}
                              md={"1"}
                              sm={"1"}
                              xl={"1"}
                              xs={"1"}
                            >
                              <Autocomplete
                                id={"qqCiC"}
                                style={qdmstyles?.qqCiC}
                                disabled={
                                  location?.state?.isView === true || !shouldDisable
                                    ? true
                                    : false
                                }
                                getOptionLabel={(option) => option?.["label"]}
                                options={options?.pack_drug_uom ?? []}
                                onLoad={() =>
                                  setFun(
                                    stateName,
                                    "packUnitUoM",
                                    rowState?.packUnitUoM,
                                    index
                                  )
                                }
                                value={rowState?.packUnitUoM ?? null}
                                onChange={(event, newValue) =>
                                  setFun(
                                    stateName,
                                    "packUnitUoM",
                                    newValue,
                                    index
                                  )
                                }
                              />
                            </Grid>
                            {/* <Grid
                              key={"0"}
                              id={"empYV"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"1"}
                              md={"1"}
                              sm={"1"}
                              xl={"1"}
                              xs={"1"}
                            >
                              <Autocomplete
                                id={"OqVny"}
                                style={qdmstyles?.OqVny}
                                getOptionLabel={(option) => option?.["label"]}
                                options={options?.drug_uom ?? []}
                                onLoad={() =>
                                  setFun(
                                    stateName,
                                    "applicablityUOM",
                                    rowState?.applicablityUOM,
                                    index
                                  )
                                }
                                value={rowState?.applicablityUOM ?? null}
                                onChange={(event, newValue) =>
                                  setFun(
                                    stateName,
                                    "applicablityUOM",
                                    newValue,
                                    index
                                  )
                                }
                              />
                            </Grid> */}
                            <Grid
                              key={"1"}
                              id={"empYV"}
                              container={true}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"2"}
                              md={"2"}
                              sm={"2"}
                              xl={"2"}
                              xs={"2"}
                            >
                              <Checkbox
                                key={"0"}
                                id={"B7nCd"}
                                color={"default"}
                                size={"small"}
                                disabled={
                                  location?.state?.isView === true || !shouldDisable
                                    ? true
                                    : false
                                }
                                checked={rowState?.individualDoseRound}
                                onChange={(event) =>
                                  setFun(
                                    stateName,
                                    "individualDoseRound",
                                    event.target.checked,
                                    index
                                  )
                                }
                                inputProps={{
                                  "aria-label": "Individual Dose Round Off",
                                }}
                                style={qdmstyles.jDvDk}
                              ></Checkbox>
                            </Grid>
                            <Grid
                              key={"2"}
                              id={"empYV"}
                              container={true}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"1"}
                              md={"1"}
                              sm={"1"}
                              xl={"1"}
                              xs={"1"}
                            >
                              <Autocomplete
                                id={"OqVny"}
                                style={qdmstyles?.OqVny}
                                disabled={
                                  location?.state?.isView === true || !shouldDisable
                                    ? true
                                    : false
                                }
                                getOptionLabel={(option) => option?.["label"]}
                                options={options?.round_off_type ?? []}
                                onLoad={() =>
                                  setFun(
                                    stateName,
                                    "roundtype",
                                    rowState?.roundtype,
                                    index
                                  )
                                }
                                value={rowState?.roundtype ?? null}
                                onChange={(event, newValue) =>
                                  setFun(
                                    stateName,
                                    "roundtype",
                                    newValue,
                                    index
                                  )
                                }
                              />
                            </Grid>
                            <Grid
                              key={"3"}
                              id={"empYV"}
                              container={true}
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              item={true}
                              lg={"2"}
                              md={"2"}
                              sm={"2"}
                              xl={"2"}
                              xs={"2"}
                              style={{
                                marginLeft:'8px',
                                marginTop:"-2px"
                              }}
                            >
                              <Grid
                                key={"0"}
                                id={"empYV"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={"10"}
                                md={"10"}
                                sm={"10"}
                                xl={"10"}
                                xs={"10"}
                              >
                                <InputBase
                                  key={"0"}
                                  id={"roundoffinput4_textfield"}
                                  label={""}
                                  disabled={
                                    location?.state?.isView === true || !shouldDisable
                                      ? true
                                      : false
                                  }
                                  placeholder={""}
                                  type={"number"}
                                  aliasName={"roundoffinput4"}
                                  style={qdmstyles.PWtn}
                                  value={
                                    rowState?.roundoff ? rowState?.roundoff : ""
                                  }
                                  inputProps={{
                                    maxlength: 6,
                                    min: 0,
                                    max: 999999,
                                  }}
                                  onChange={(event) =>
                                    setFun(
                                      stateName,
                                      "roundoff",
                                      parseInt(event.target.value),
                                      index
                                    )
                                  }
                                ></InputBase>
                              </Grid>
                              <Grid
                                key={"1"}
                                id={"empYV"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={"2"}
                                md={"2"}
                                sm={"2"}
                                xl={"2"}
                                xs={"2"}
                                style={qdmstyles.VFi}
                              >
                                <IconButton
                                  disabled={
                                    location?.state?.isView === true || !shouldDisable
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    onDeleteClick(stateName, index)
                                  }
                                >
                                  <TrashIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}

                    {/* Input Ends */}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Unitofmeasurecomp));
