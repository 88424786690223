export const headCells = [
  //   [

  //     {
  //         "columnname": "drugType",
  //         "datatype": "string",
  //         "sort": false,
  //         "filter": true,
  //         "group": true
  //     },
  //     {
  //         "columnname": "orderdescription",
  //         "datatype": "string",
  //         "sort": false,
  //         "filter": true,
  //         "group": false
  //     },
  //     {
  //         "columnname": "priority",
  //         "datatype": "string",
  //         "sort": false,
  //         "filter": true,
  //         "group": false
  //     }
  // ]

  {
    columnname: "PrescriptionDate",
    display: "Prescription Date",
    datatype: "number",
    sort: true,
    filter: true,
    group: false,
    search: false,
    align: "right",
  },
  // {
  //   id: "name",
  //   label: "Dessert (100g serving)",
  //   isSortable: true,
  //   isSearchable: true,
  //   isFilterable: false,
  //   align: "left",
  //   display: "Dessert",
  //   fieldType: "text",
  // },
  // {
  //   id: "calories",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Calories",
  //   isSortable: false,
  //   isSearchable: false,
  //   isFilterable: true,
  //   align: "right",
  //   display: "Calories",
  //   fieldType: "switch",
  // },
  // {
  //   id: "fat",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Fat (g)",
  //   isSortable: true,
  //   isSearchable: false,
  //   isFilterable: true,
  //   align: "right",
  //   display: "Fat",
  //   fieldType: "autocomplete",
  // },
  // {
  //   id: "carbs",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Carbs (g)",
  //   isSortable: true,
  //   isSearchable: false,
  //   isFilterable: true,
  //   align: "right",
  //   display: "Carbs",
  //   fieldType: "datepicker",
  // },
  // {
  //   id: "protein",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Protein (g)",
  //   isSortable: true,
  //   isSearchable: false,
  //   isFilterable: true,
  //   align: "right",
  //   display: "Protein",
  //   fieldType: "datepicker",
  // },
];

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export const rows = [
  // createData("Cupcake", 305, 3.7, 67, 4.3),
  // createData("Donut", 452, 25.0, 51, 4.9),
  // createData("Eclair", 262, 16.0, 24, 6.0),
  // createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  // createData("Gingerbread", 356, 16.0, 49, 3.9),
  // createData("Honeycomb", 408, 3.2, 87, 6.5),
  // createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  // createData("Jelly Bean", 375, 0.0, 94, 0.0),
  // createData("KitKat", 518, 26.0, 65, 7.0),
  // createData("Lollipop", 392, 0.2, 98, 0.0),
  // createData("Marshmallow", 318, 0, 81, 2.0),
  // createData("Nougat", 360, 19.0, 9, 37.0),
  // createData("Oreo", 437, 18.0, 63, 4.0),
];

const sampleColumns = [
  {
    columnname: "drugCode",
    datatype: "string",
    sort: true,
    filter: true,
    group: false,
    align: "left",
    search: true,
    display: "Drug Code",
  },
  {
    columnname: "dosageValue",
    datatype: "number",
    sort: true,
    filter: true,
    group: false,
    align: "right",
    search: true,
    display: "Dosage Value",
  },
  {
    columnname: "dosageUOM",
    datatype: "string",
    sort: false,
    filter: true,
    group: false,
    align: "left",
    search: true,
    display: "Dosage UOM",
  },
  {
    columnname: "dosageFreqCode",
    datatype: "string",
    sort: false,
    filter: true,
    group: false,
    align: "left",
    search: true,
    display: "Dosage Frequency Code",
  },
  {
    columnname: "occurStartDateTime",
    datatype: "date",
    sort: false,
    filter: true,
    group: false,
    align: "left",
    search: true,
    display: "Occur Start Time",
  },
];

const sampleRows = [
  {
    drugCode: "PARA500MG-DUP",
    dosageValue: 500,
    dosageUOM: "MG",
    dosageFreqCode: "TID",
    occurStartDateTime: 1657569600,
  },
  {
    drugCode: "PARA500MG-DUP",
    dosageValue: 500,
    dosageUOM: "MG",
    dosageFreqCode: "TID",
    occurStartDateTime: 1657828800,
  },
  {
    drugCode: "PARA500MG-DUP",
    dosageValue: 500,
    dosageUOM: "MG",
    dosageFreqCode: "TID",
    occurStartDateTime: 1656403200,
  },
  {
    drugCode: "CAPTOPRILORALSYRUP",
    dosageValue: 25,
    dosageUOM: "MG",
    dosageFreqCode: "TID",
    occurStartDateTime: 1657526400,
  },
  {
    drugCode: "CAPTOPRILORALSYRUP",
    dosageValue: 25,
    dosageUOM: "MG",
    dosageFreqCode: "TID",
    occurStartDateTime: 1657526400,
  },
  {
    drugCode: "CAPTOPRILORALSYRUP",
    dosageValue: 25,
    dosageUOM: "MG",
    dosageFreqCode: "TID",
    occurStartDateTime: 1657526400,
  },
  {
    drugCode: "CAPTOPRILORALSYRUP",
    dosageValue: 25,
    dosageUOM: "MG",
    dosageFreqCode: "TID",
    occurStartDateTime: 1657526400,
  },
  {
    drugCode: "CAPTOPRILORALSYRUP",
    dosageValue: 25,
    dosageUOM: "MG",
    dosageFreqCode: "TID",
    occurStartDateTime: 1657828800,
  },
];
