import { makeStyles } from "@material-ui/core/styles";

export const usePopoverStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    border: "1px solid #DEE5EC",
    borderRadius: "8px",
    boxShadow: "#0000000A 0px 10px 25px",
  },
}));
