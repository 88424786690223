import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import patientEducationImage from "../../../../../assets/patientEducationImage.svg"

const useStyles = makeStyles((theme) => ({
firstline:{
    backgroundColor:"#F1F6FC",
    fontSize:"12px",
    padding:"8px",
    borderRadius:"8px"
},
secondline:{
    marginTop:"8px",
    borderRadius:"8px",
    backgroundColor:"#F1F6FC",
    fontSize:"12px",
    padding:"8px"
}
}))
export default function PatientEducation() {
    const classes = useStyles();
    return(
        <>
        <Grid container xs={12}>
            
                <Grid item xs={8}>
                    <div>
                        <Typography className={classes.firstline}> Instructions given by doctor in Rx </Typography>
                        <Typography className={classes.secondline}> Lorem ipsum dolor sit amet, consectetur adip </Typography>
                        <Typography className={classes.secondline}> Lorem ipsum dolor sit amet, consectetur adip </Typography>

                    </div>
                    </Grid>
                    <Grid item xs={4}>
                        <img src={patientEducationImage} alt="img3"/> 
                        </Grid>
            </Grid>
        </>
    )
}