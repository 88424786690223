import React from "react";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";

import {
  Grid,
  Avatar,
  Box,
  Typography,
  Divider,
  makeStyles,
  Button,
  ListItem,
  ListItemText,
  Collapse,
  List,
  Popover,
  TextField,
  Tooltip,
  withStyles,
  InputBase,
} from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
import { getImgUrl, makeName } from "../../utils";
import moment from "moment";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import notArrived from "../../assets/Group 95712.svg";
import Arrived from "../../assets/Group 95689 (1).svg";
// import Completed from "../../assets/Group 95689.svg";
import Hold from "../../assets/On hold.svg";
import { actions } from "frequencyscreen_v_dbinder";
import { useDispatch } from "react-redux";

const StripInput = withStyles((theme) => ({
  root: {
    flex: 0,
    gap: 10,
    padding: "4px 8px",
    background: "white",
    borderRadius: "4px",
    border: "1px solid #DEE5EC",
    "& .App1-MuiInputBase-input": {
      minWidth: 20,
    },
  },
  input: {
    padding: 0,
    "&:focus": {
      backgroundColor: "unset",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    margin: "auto",
    background: `${theme.palette.primary.main} !important`,
    // backgroundColor: "#2A60BC",
    color: "#fff",
    // color: theme.palette.text.primary,
    boxShadow: "none",
    fontSize: 12,
    // padding: 2,
  },
  MainBackgroundCard: {
    background: `${theme.palette.background.table} !important`,
    padding: 10,
    borderRadius: 4,
    marginTop: 6,
  },
  arrived: {
    height: "13px",
    position: "absolute",
    fontSize: "8px",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    background: "#080808",
    width: "13px",
    alignItems: "center",
  },
  dropdwon: {
    boxShadow: "none",
    border: "1px solid #dee5ec",
    zIndex: 100,
    position: "absolute",
    background: "#fff",
    top: "23px",
    left: "6px",
    borderRadius: "6px",
    cursor: "pointer",
  },
}));

const GetPatientUI = ({
  index,
  item,
  handleAttend,
  propsState,
  handleClick,
  data,
  Arrow,
  handleChoose,
  wrapperRef,
  setvalue,
  value,
}) => {
  const classes = useStyles();
  /// fetching all permission

  const getGender = (value) => {
    switch (value) {
      case "male":
        return "M";
      case "female":
        return "F";
      default:
        return "";
    }
  };

  const getDifference = (time) => {
    const minutes = moment.duration(time).minutes();
    const hours = moment.duration(time).hours();
    if (hours > 0) {
      return hours + " hrs";
    } else {
      return minutes + " mins";
    }
  };

  const arrivedIcon = (data) => {
    if (data === "Not Arrived") {
      return notArrived;
    } else if (data === "Arrived") {
      return Arrived;
    } else if (data === "Completed" || data === "Left") {
      return Arrived;
    } else if (data === "On Hold" || data === "Hold") {
      return Hold;
    } else {
      return notArrived;
    }
  };

  return (
    <>
      <Grid
        id={"patient_queue_div"}
        container={true}
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        aliasName={"patientQueueDiv"}
        // style={{ marginTop: "10px", marginBottom: "10px" }}
        className={`PatientCard ${classes.MainBackgroundCard}`}
      >
        <Grid
          id={"avatar_queue_div"}
          container={false}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item={true}
          lg={"2"}
          md={"2"}
          sm={"2"}
          xs={"2"}
          aliasName={"avatarQueueDiv"}
          style={qdmstyles.afxP}
        >
          <Avatar
            id={"GlRE2"}
            alt={"Avatar"}
            variant={"rounded"}
            style={qdmstyles.GlRE}
            src={
              item?.photo?.[0]?.fileid
                ? getImgUrl(item?.photo?.[0]?.fileid ?? "")
                : "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
            }
          ></Avatar>
          {item?.token?.patientstatus?.display && (
            <div
              className={classes.arrived}
              // onClick={(e) => handleClick(index)}
              // ref={wrapperRef}
            >
              <img
                src={arrivedIcon(item?.token?.patientstatus?.display ?? "")}
                alt="Arrived icon"
                height="10px"
              />
            </div>
          )}
        </Grid>

        <Grid
          key={"1"}
          id={"patient_queue_contents"}
          container={false}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          aliasName={"patient_queue_contents"}
          item={true}
          lg={"7"}
          md={"7"}
          sm={"7"}
          xs={"7"}
          style={qdmstyles.YlGtv}
        >
          <Box
            id={"header_box"}
            m={"1"}
            component={"div"}
            aliasName={"headerBox"}
            style={qdmstyles.UThnj}
          >
            <Tooltip title={makeName(item?.name?.[0])}>
              <Typography
                noWrap
                id={"rzlN2"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={makeName(item?.name?.[0])}
                style={qdmstyles.rzlN}
              ></Typography>
            </Tooltip>
            <Box
              key={"1"}
              id={"dot"}
              m={"1"}
              component={"div"}
              aliasName={"dot"}
              style={qdmstyles.ekTo}
            ></Box>
            <Typography
              key={"2"}
              id={"age"}
              align={"inherit"}
              color={"initial"}
              display={"initial"}
              variant={"body1"}
              children={item?.age}
              aliasName={"age"}
              style={qdmstyles.KrWT}
            ></Typography>
            <Divider
              key={"3"}
              id={"divider"}
              variant={"fullWidth"}
              aliasName={"divider"}
              orientation={"vertical"}
              style={qdmstyles.RkTTy}
            ></Divider>
            <Typography
              key={"4"}
              id={"gender"}
              align={"inherit"}
              color={"initial"}
              display={"initial"}
              variant={"body1"}
              children={getGender(item?.gender?.toLowerCase())}
              aliasName={"gender"}
              style={qdmstyles.TFuHR}
            ></Typography>
          </Box>
          <Box
            key={"1"}
            id={"patient_queue_appointment"}
            m={"1"}
            component={"div"}
            aliasName={"patientQueueAppointment"}
            style={qdmstyles.iXX}
          >
            <Typography
              id={"mrn"}
              align={"inherit"}
              color={"initial"}
              display={"initial"}
              variant={"body1"}
              children={item?.alias}
              aliasName={"mrn"}
              style={qdmstyles.XyEdc}
            ></Typography>
            <Box
              key={"1"}
              id={"dot"}
              m={"1"}
              component={"div"}
              aliasName={"dot"}
              style={qdmstyles.TtOJ}
            ></Box>
            <Typography
              key={"2"}
              id={"contact "}
              align={"inherit"}
              color={"initial"}
              display={"initial"}
              variant={"body1"}
              children={item?.telecom?.[0] ?? ""}
              aliasName={"contact "}
              style={qdmstyles.iWcFn}
            ></Typography>
          </Box>
          <Box
            style={{ display: "flex", position: "relative", paddingTop: "5px" }}
            ref={wrapperRef}
          >
            <span
              onClick={(e) => handleClick(index)}
              style={{ marginRight: "5px", display: "flex" }}
            >
              {item?.token?.patientstatus?.display ?? "Select Status"}
              <ExpandMoreOutlinedIcon style={{ fontSize: 16 }} />
            </span>
            {Arrow === index && (
              <div className={classes.dropdwon} ref={wrapperRef}>
                {data?.map((v) => {
                  return (
                    <Typography
                      onClick={() => {
                        // setStatus(v?.display);
                        handleChoose(item, v?._id, null);
                      }}
                      variant="body1"
                      style={{
                        color:
                          item?.token?.patientstatus?.display === v?.display &&
                          "#2A60BC",
                        fontSize: 12,
                        padding: "4px 8px",
                      }}
                    >
                      {v?.display ?? ""}
                    </Typography>
                  );
                })}
              </div>
            )}
            {(item?.token?.queueno || item?.token?.queueno >= 0) && (
              <>
                <StripInput
                  onChange={(e) => setvalue(index, e.target.value)}
                  value={
                    value?.index === index ? value?.value : item?.token?.queueno
                  }
                  variant="outlined"
                  style={{ fontSize: 12 }}
                  disabled={
                    item?.token?.patientstatus?.display === "Hold"
                      ? true
                      : false
                  }
                  size="small"
                  fullWidth
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleChoose(
                        item,
                        item?.token?.patientstatus?._id,
                        value
                      );
                      setvalue(null, null);
                    }
                  }}
                  startAdornment={<>Queue&nbsp;no:</>}
                />
              </>
            )}
          </Box>
        </Grid>

        {item?.waiting_time && (
          <Grid
            key={"2"}
            id={"time_grid"}
            container={false}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item={true}
            lg={"2"}
            md={"3"}
            sm={"3"}
            xs={"3"}
            aliasName={"timeGrid"}
            className={item?.token?.patientstatus?.display !== "Hold" && "time"}
          >
            <Box
              id={"time_div"}
              m={"1"}
              component={"div"}
              aliasName={"timeDiv"}
              style={qdmstyles.dPFfM}
            >
              <Avatar
                id={"clock_queue"}
                alt={"Avatar"}
                variant={"rounded"}
                aliasName={"clockQueue"}
                src={
                  "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316382595"
                }
                style={qdmstyles.QyXv}
              ></Avatar>
              <Typography
                key={"1"}
                id={"time"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={getDifference(item?.waiting_time)}
                aliasName={"time"}
                style={qdmstyles.IrJt}
              ></Typography>
            </Box>
          </Grid>
        )}

        {item?.token?.patientstatus?.display !== "Hold" && (
          <Grid className={"attendButton"} xs={2}>
            <Button
              disableRipple
              disableFocusRipple
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={() => handleAttend(item)}
            >
              Attend
            </Button>
          </Grid>
        )}
      </Grid>

      {index !== propsState.length - 1 && (
        <Divider key={"1"} id={"ORl0v"} variant={"fullWidth"}></Divider>
      )}
    </>
  );
};

function PatientDisplay(props) {
  let dispatch = useDispatch();

  const {
    propsState,
    handleAttend,
    patientNotInQueue,
    patientQueueStatusData,
    Didupdate,
    isCardVerify,
    queueId,
  } = props;

  const [isOpen, setIsOpen] = React.useState(true);
  const [Arrow, setArrow] = React.useState(null);
  const [value, setvalue] = React.useState({});

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
  }, []);

  const handleChange = (i, v) => {
    if (i >= 0) {
      setvalue({ index: i, value: v });
      return;
    }
    setvalue({});
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (item) => {
    const update = item === Arrow ? null : item;
    setArrow(update);
  };
  const handleClose = () => {
    setArrow(null);
  };

  const makeid = () => {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return JSON.parse(result);
  };

  const handleChoose = async (data, status, queueValue) => {
    

    const pract = props.encounterData ?? {};
    const list = JSON.parse(JSON.stringify(data?.token || {}));
    const queueNo = queueValue?.value
      ? JSON.parse(queueValue?.value)
      : queueValue;

    list["statushistory"] = list?.statushistory || [];
    if (list?.statushistory?.length > 0) {
      list.statushistory.push({
        seqno: list?.statushistory?.length,
        status: status ?? "",
        statusupdatetime: moment().unix(),
        practitioner: pract?.Practitioner_id?.[0]?._id ?? "",
        remarks: "",
        practitionerRole:
          pract?.PractitionerRole_id?.[0]?.[0]?._id ||
          pract?.PractitionerRole_id?.[0]?._id ||
          "",
      });
    } else {
      list["statushistory"] = [
        {
          seqno: 0,
          status: status ?? "",
          statusupdatetime: moment().unix(),
          practitioner: pract?.Practitioner_id?.[0]?._id ?? "",
          remarks: "",
          practitionerRole:
            pract?.PractitionerRole_id?.[0]?.[0]?._id ||
            pract?.PractitionerRole_id?.[0]?._id ||
            "",
        },
      ];
    }

    const payload = {
      queueid: data?.QueueID ?? "",
      transactionid: data?._id ?? "",
      queueno: queueNo || (list?.queueno ?? makeid()),
      // queueno: queueNo ? queueNo : 0,
      patientstatus: status ?? "",
      statushistory: list.statushistory ?? [],
    };
    await handleUpsert(payload, list?._key ?? "", queueNo);
    handleClose();
  };

  const handleUpsert = async (item, key, queueNo) => {
    
    const statusUpdate = await dispatch(
      actions.STATUS_UPDATE_QUEUE({ Body: item, key: key })
    );
    if (statusUpdate?.payload?.data?.error) {
      // props.alert.setSnack({
      //   open: true,
      //   severity: AlertProps.severity.error,
      //   msg: statusUpdate?.payload?.data?.errorMessage || statusUpdate?.payload?.data?.result?.[0]?.message ||
      //     statusUpdate?.payload?.data?.error,
      //   vertical: AlertProps.vertical.top,
      //   horizontal: AlertProps.horizontal.right,
      // });
      return;
    } else {
      // if (queueNo) { return }

      if (isCardVerify) {
        await dispatch(
          actions.CART_PATIENT_QUEUE({
            cartID: Didupdate?.cartID ?? "",
            locID: Didupdate?.locID ?? "",
            wardID: Didupdate?.wardID ?? "",
          })
        );
      } else {
        await dispatch(
          actions.PATIENT_QUEUE({
            id: queueId,
            search: "",
            scenarioId: "queuescenarios/10019",
          })
        );
      }
    }
  };

  function useOutsideAlerter(ref) {
    React.useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleClose();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            id={"patient_queue_card"}
            container={false}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            aliasName={"patientQueueCard"}
            style={qdmstyles.BEbB}
          >
            {propsState?.map((item, index) => {
              return (
                <div key={item?._key}>
                  {
                    <GetPatientUI
                      index={index}
                      item={item}
                      propsState={propsState}
                      handleAttend={handleAttend}
                      handleClick={handleClick}
                      data={patientQueueStatusData}
                      Arrow={Arrow}
                      handleChoose={handleChoose}
                      wrapperRef={wrapperRef}
                      setvalue={handleChange}
                      value={value}
                    />
                  }
                </div>
              );
            })}

            {patientNotInQueue?.length > 0 && (
              <List>
                <ListItem button onClick={handleOpen}>
                  <ListItemText
                    primary={
                      <div style={{ display: "flex" }}>
                        <div>Not in Queue</div>
                        <div>
                          {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </div>
                      </div>
                    }
                  />
                </ListItem>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  {patientNotInQueue?.map((item, index) => {
                    return (
                      <div key={item?._key}>
                        {
                          <GetPatientUI
                            index={index}
                            item={item}
                            propsState={propsState}
                            handleAttend={handleAttend}
                            handleClick={handleClick}
                            data={patientQueueStatusData}
                            Arrow={Arrow}
                            handleChoose={handleChoose}
                            wrapperRef={wrapperRef}
                            setvalue={handleChange}
                            value={value}
                          />
                        }
                      </div>
                    );
                  })}
                </Collapse>
              </List>
            )}
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(PatientDisplay));
