import React from "react";
import { Card, 
  //CircularProgress, Drawer
 } from "@material-ui/core";
import "../../components/font.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { CommonTable } from "../../components/common"
import { makeStyles } from "@material-ui/core/styles";
// import { routes } from "../../router/routes";
// import { useHistory } from "react-router";
import { withAllContexts } from "../../HOCs";

// const drawerWidth = "94%";
const useStyles = makeStyles((theme) => ({
  tableDiv: {
    background: theme.palette.background.table,
  },
  tableHeaderDiv: {
    background: theme.palette.background.table,
  }
}));

function CareTypeTable(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { parent_id } = props;
  const listData = useSelector((state) => state?.organizationConfigSlice?.care_type_list?.data);
  //const loading = useSelector((state) => state?.organizationConfigSlice?.care_type_list?.loading);
  //const [state, setState] = React.useState({});

  React.useLayoutEffect(() => {
    
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Loading...",
    });
    dispatch(actions.CARE_TYPE_READ());
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
  }, []);

  return (
    <Card
      id={`${parent_id}-parent-card`}
      style={{ borderRadius: "12px" }}>
      <div
        id={`${parent_id}-parent-div`}
        style={{ width: "100%" }}>
        <Card
          id={`${parent_id}-parent-sub-Card`}
          style={{ borderRadius: "12px" }}>
          <div
            id={`${parent_id}-parent-sub-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              id={`${parent_id}-header-title-div`}
              style={{ display: "flex", justifyContent: "space-between" }} className={classes.tableHeaderDiv}>
              <div
                id={`${parent_id}-header-title-sub-div`}
                style={{ marginLeft: "18px" }}>
                <p
                  id={`${parent_id}-header-title-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  {props.header}
                </p>
              </div>
            </div>

            {/* table */}
            <div
              id={`${parent_id}-CommonTable-div`}
              style={{ padding: 20, height: "71vh", overflow: 'auto' }} className={classes.tableDiv}>
              {/* {
              loading ? 
                <div style={{display: 'flex', justifyContent: "center", alignItems: "center", minHeight: "66vh"}}>
                  <CircularProgress />
                </div>
              : */}
              <CommonTable
                parent_id={'CommonTable'}
                Header={["S.No", "Code", "Description", "Care Level Rank"]}
                dataList={listData}
                tableData={[
                  { type: ["INCRIMENT"], name: "" },
                  { type: ["TEXT"], name: "careType" },
                  { type: ["TEXT"], name: "description" },
                  { type: ["TEXT"], name: "carelevelRank" }
                ]}
              />
              {/* } */}
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
}

export default withAllContexts(CareTypeTable);