/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc The first component of the application, all the required context and routes are used here
 * to setup the application.
 */

import React from "react";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AppAuth from "./App.auth";
import AppTheme from "./App.theme";
// import AppTheme from "./App.theme";
import AppDateTime from "./App.dateTime";
import AppAlert from "./App.alert"; 
import AppBackdrop from "./App.backdrop";
import AppErrorBoundary from "./App.errorBoundry";
import RouterApp from "./router";
import AppLogger from "./App.logger";
import { store as ReduxStore } from "./redux";
import { Provider } from "react-redux";
import {
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";

import PatientDetailsContext from "./contexts/consult";
import { CssBaseline } from "@material-ui/core";
import Practitionet from "./contexts/context";
import AppDrawer from "./App.drawer";

// import { CssBaseline } from "@material-ui/core";
// import AppDialog from "./App.dialog";
// import AppBackdrop from "./App.backdrop";

const generateClassName = createGenerateClassName({
  seed: "App1",
});

function App() {
  return (
    <div>
      <StylesProvider generateClassName={generateClassName}>
        <Provider store={ReduxStore}>
          <AppLogger>
            <AppAuth>
              <AppErrorBoundary>
                <AppTheme>
                  <CssBaseline />
                  <AppDrawer>
                    <AppAlert>
                      {/* <AppDialog> */}
                      <AppBackdrop>
                        <AppDateTime>
                          <PatientDetailsContext>
                            <Practitionet>
                              <RouterApp />
                            </Practitionet>
                          </PatientDetailsContext>
                        </AppDateTime>
                      </AppBackdrop>
                      {/* </AppDialog> */}
                    </AppAlert>
                  </AppDrawer>
                </AppTheme>
              </AppErrorBoundary>
            </AppAuth>
          </AppLogger>
        </Provider>
      </StylesProvider>
      <ToastContainer autoClose={5000} theme="colored" />
    </div>
  );
}

export default App;
