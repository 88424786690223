import React from "react";

import {
  MedicationContext,
} from "./";
const PatientDetailsContext = (props) => {
  let [medication, setMedication] = React.useState({
    list: [],
    editIndex: null,
  });
  return (
    <MedicationContext.Provider value={{ ...medication, setMedication }}>
      {props.children}
    </MedicationContext.Provider>

  );
};

export default PatientDetailsContext;