import React from "react";
import { Button, Grid } from "@material-ui/core";
import { CommonTabList, Directioncomp } from "../../../../../../components";
import UmoDetails from "./umoDetails";
import ConversionMap from "./conversionMap";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withAllContexts } from "../../../../../../HOCs";
import { valitationFunc } from "../../../../../../utils";
import Aliasmappingcomp from "./Aliasmappingcomp";

//import { AlertProps } from "../../../../../../utils";

let errorList = ["umoType", "longdes", "shortdes", "umoCode"];
class DrugUmoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uomCheckboxJson: [
        {
          label: "Dosage Strength UOM",
          checked: true,
        },
        {
          label: "Dosage Quantity UOM",
          checked: true,
        },
        {
          label: "Dispense UOM",
          checked: true,
        },
        {
          label: "Purchase UOM",
          checked: true,
        },
        {
          label: "Stock UOM",
          checked: true,
        },
        {
          label: "Store Issue UOM",
          checked: true,
        },
      ],
      coversionData: [
        {
          umoCode: { title: "", value: "" },
          coversionFactor: "",
        },
      ],
      umoType: null,
      longdes: "",
      shortdes: "",
      umoCode: "",
      periodType: null,
      doseCalcType: null,
      dosecalcuom: null,
      directionDtls: [],
      error: {},
      aliasDtls: [],
    };
    this.handleFormState = this.handleFormState.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.addConversion = this.addConversion.bind(this);
  }

  componentDidMount(props) {
    const getdata = async () => {
      await this.props.DRUG_UOM_TYPE_DROP_DOWN();
      await this.props?.DRUG_UOM_PERIOD_TYPE_DROPDOWN();
      await this.props?.DRUG_UOM_DOSE_CALC_TYPE_DROPDOWN();
      await this.props?.DRUG_UOM_DOSE_CALC_UOM_DROPDOWN();
      await this.props.DRUG_SOURCE_DROP_DOWN();
      await this.props.DRUG_UOM_SORCE_DROPDOWN();
      if (this.props.editData) {
        await this.props.DRUG_UOM_LINE_READ({
          key: this.props.editData.key,
        });
        if (
          this.props?.drugMasterUOM?.drug_uom_read_line?.data &&
          this.props.editData.key
        ) {
          let getData = this.props?.drugMasterUOM?.drug_uom_read_line?.data;
          getData = JSON.stringify(getData);
          getData = JSON.parse(getData);

          this.setState({
            uomCheckboxJson: getData?.uomCheckboxJson_ ?? [],
            umoType: getData.UOMType_ ?? {},
            umoCode: getData.umoCode_ ?? "",
            shortdes: getData.shortdes_ ?? "",
            longdes: getData.longdes_ ?? "",
            periodType: getData.periodType ?? {},
            doseCalcType: getData.doseCalcType ?? {},
            dosecalcuom: getData.dosecalcuom ?? {},
            coversionData: getData.ConversionMaparr_ ?? [],
            directionDtls: getData.directionDtls ?? [],
            key: this.props.editData.key,
            aliasDtls:
              getData?.aliasDtls?.map((v) => {
                return {
                  ...v,
                  aliasType: {
                    label: v?.aliasType?.display ?? "",
                    value: v?.aliasType?._id ?? "",
                  },
                };
              }) ?? [],
          });
        } else {
        }
      }
    };
    getdata();
  }
  componentWillUnmount() {
    this.setState({
      uomCheckboxJson: [
        {
          label: "Dosage Strength UOM",
          checked: false,
        },
        {
          label: "Dosage Quantity UOM",
          checked: false,
        },
        {
          label: "Dispense UOM",
          checked: false,
        },
        {
          label: "Purchase UOM",
          checked: false,
        },
        {
          label: "Stock UOM",
          checked: false,
        },
        {
          label: "Store Issue UOM",
          checked: false,
        },
      ],
      coversionData: [
        {
          umoCode: { label: "", value: "" },
          coversionFactor: "",
        },
      ],
      umoType: null,
      umoCode: "",
      shortdes: "",
      longdes: "",
      key: null,
      aliasDtls: [],
    });
  }
  handleFormState = (name, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return false;
      }
    }
    let errorObj = JSON.parse(JSON.stringify(this.state.error));
    errorObj[name] = false;
    this.setState({ [name]: value, error: errorObj });
  };
  handleCheckbox = (value, i) => {
    const state = this.state;
    state.uomCheckboxJson[i].checked = value;
    this.setState({ ...state });
  };
  addConversion = () => {
    let obj = {
      umoCode: { label: "", value: "" },
      coversionFactor: "",
    };
    this.state.coversionData.push(obj);
    this.setState({ ...this.state });
  };

  deleteConversion = (e, i) => {
    const state = this.state;
    state.coversionData.splice(i, 1);
    this.setState({ ...state });
  };

  handleInputState = (name, e, i) => {
    const state = this.state;
    if (name === "umoCode") {
      if (e?.value) {
        state.coversionData[i][name].label = e.label;
        state.coversionData[i][name].value = e.value;
      } else {
        state.coversionData[i][name].title = { title: "", value: "" };
      }
    } else {
      state.coversionData[i][name] = e;
    }
    this.setState({ ...state });
  };

  saveForm = async () => {
    let finalSave = this.state;
    let coversionFactor = this.state.coversionData
      .map(
        (val) =>
          val.coversionFactor > 0 &&
          val.umoCode.label !== "" &&
          val.umoCode.value !== ""
      )
      .filter((val) => val !== true);
    let { error, errorState } = await valitationFunc(this.state, errorList);

    // (this.state.coversionData[0].umoCode.value === "" ||
    //     coversionFactor.length === 0) &&
    if (!error) {
      let save = await this.props.UOM_CODE_INSERT({
        data: finalSave,
        key: finalSave.key,
      });

      if (save.payload?.data?.Code === 1111) {
        let type = save.payload?.data?.validation_error?.[0]?.Errormsg.includes(
          "unique"
        )
          ? "unique"
          : "error";
        this.props.alert.setSnack(type);
      } else {
        let type = this.props.editData ? "update" : "success";
        this.props.alert.setSnack(type);
        this.props.DRUG_UOM_READ();
        this.props.closeForm();
      }
    } else {
      this.props.alert.setSnack("mandatory");
      this.setState({
        ...this.state,
        error: errorState,
      });
    }
  };

  changeStateForArrayElement = (stateKey, propertyKey, value, index) => {
    
    this.state[stateKey][index][propertyKey] = value;
    this.setState({
      ...this.state,
    });
  };

  handleAddNewClick = (key) => {
    // this.state.aliasDtls.push({})
    this.setState({
      aliasDtls: [...this.state.aliasDtls, {}],
    });
  };

  handleDeleteClick = (key, index) => {
    this.setState({
      aliasDtls: this.state.aliasDtls.filter((item, i) => i !== index),
    });
  };

  render() {
    const { closeForm = () => null, parent_id } = this.props;
    const { dropdownOptions } = this.props;
    const { data: options } = dropdownOptions;
    console.log(this.state.aliasDtls);
    return (
      <React.Fragment>
        <CommonTabList
          parent_id={"Drug-UOM"}
          backbtn
          backFun={closeForm}
          title={this.props.editData ? "Edit Drug UOM" : "Add New Drug UOM"}
          list={[]}
        />
        <Grid id={`${parent_id}-UmoDetails-grid`} style={{ padding: "20px" }}>
          <UmoDetails
            editData={this?.props?.editData ?? false}
            parent_id={"UmoDetails"}
            handleCheckState={this.handleCheckbox}
            handleFormState={this.handleFormState}
            typeDropDown={
              this.props?.drugMasterUOM?.drug_uom_type_master?.data
                ? this.props?.drugMasterUOM?.drug_uom_type_master?.data
                : []
            }
            periodTypeOptions={
              this.props?.drugMasterUOM?.drug_uom_period_type_master?.data
                ? this.props?.drugMasterUOM?.drug_uom_period_type_master?.data
                : []
            }
            doseCalcTypeOptions={
              this.props?.drugMasterUOM?.drug_uom_dose_calc_type_master?.data
                ? this.props?.drugMasterUOM?.drug_uom_dose_calc_type_master
                  ?.data
                : []
            }
            doseCalcUomOptions={
              this.props?.drugMasterUOM?.drug_uom_dose_calc_uom_master?.data
                ? this.props?.drugMasterUOM?.drug_uom_dose_calc_uom_master?.data
                : []
            }
            {...this.state}
          />
        </Grid>
        <Grid
          id={`${parent_id}-ConversionMap-grid`}
          style={{ padding: "20px" }}
        >
          <ConversionMap
            parent_id={"ConversionMap"}
            arrayConversion={this.umCode}
            handleFormState={this.handleFormState}
            addConversion={this.addConversion}
            deleteConversion={this.deleteConversion}
            handleInputState={this.handleInputState}
            {...this.state}
          />
        </Grid>

        <Grid id={`${parent_id}-Direction-grid`} style={{ padding: "20px" }}>
          <Directioncomp
            shouldDisable={true}
            propsState={this.state}
            setFun={(value) => {
              this.handleFormState("directionDtls", value);
            }}
            stateName={"directionDtls"}
            statevalue={this.state.directionDtls}
          />
        </Grid>
        {/* {JSON.stringify(this.props?.drugMasterUOM?.drug_uom_sorce_master)} */}
        <Grid id={`${parent_id}-alise-grid`} style={{ padding: "20px" }}>
          <div style={{ padding: "20px", background: "#fff" }}>
            <Aliasmappingcomp
              shouldDisable={true}
              propsState={this.state}
              setFun={this.changeStateForArrayElement}
              stateName={"aliasDtls"}
              statevalue={this.state.aliasDtls}
              onAddNewClick={this.handleAddNewClick}
              onDeleteClick={this.handleDeleteClick}
              options={{
                source: this.props?.drugMasterUOM?.drug_uom_sorce_master?.data
                  ? this.props?.drugMasterUOM?.drug_uom_sorce_master?.data
                  : []
              }}
            />
          </div>
        </Grid>

        <Grid id={`${parent_id}-button-grid`} style={{ padding: "20px" }}>
          <Button
            id={`${parent_id}-Save-button`}
            onClick={() => this.saveForm()}
            style={{ float: "right" }}
            variant="contained"
            color="primary"
          >
            {this.props.editData ? "Update" : "Save"}
          </Button>
          <Button
            id={`${parent_id}-Cancel-button`}
            onClick={() => closeForm()}
            style={{ float: "right", marginRight: "20px" }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  drugMasterUOM: state?.drugMasterUOMSlice,
  dropdownOptions:
    state?.drugCatalogMasterSlice?.drugCatalogMasterDropdownQuery,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(withRouter(DrugUmoForm)));
