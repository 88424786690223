import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tag from "./tag";
import classNames from "classnames";
import { Tooltip, Typography } from "@material-ui/core";
import moment from "moment";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { CheckIcon, InfoIcon, Tick } from "../../../../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: 6,
    flexWrap: "nowrap",
  },
  defaultText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    color: "white",
  },
  expiredText: {
    fontWeight: 600,
    color: "#EC6A49",
  },
  issuedText: {
    fontWeight: 600,
  },
  dateText: {
    fontWeight: 400,
  },
}));


function AlertTagsForCart(props) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        {/* Return Tag */}
        {props?.val?.negative == true ? (
          <div style={{ display: "flex" }}>
            <Tag backgroundColor={"#FDF3D8"}>
              <InfoIcon color="#FFC00F" />
            </Tag>
            <div style={{ marginLeft: "5px" }}>
              <Tag backgroundColor={"#FFE4E3"}>
                <Typography style={{ color: "#FF4D4A", fontSize: "12px", fontWeight: 410 }}>out of stock</Typography>
              </Tag>
            </div>
          </div>
        ) : (<Tag backgroundColor={"#D4F7D4"}>
          <CheckIcon />
        </Tag>)}
      </div>
    </>
  );
}

export default AlertTagsForCart;
