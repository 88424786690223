import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
import { InputBase, Autocomplete } from "../../../../components";
import { getFormatForDateTimePicker } from "../../../../utils";
import moment from "moment";
import { DateTimePicker } from "../../../../components/common/smartForm/component";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 16,
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
    // color: "#001C3C",
    color: theme.palette.text.primary,
    textTransform: "uppercase",
    marginBottom: 12,
  },
  label: {
    fontSize: 12,
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
}));

function CartDefinition({ state, setState, options }) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>Cart Definition</Typography>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <div className={classes.fieldContainer}>
              <Typography className={classes.label}>
                Cart Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <InputBase
                id={"cartname"}
                label={""}
                placeholder={""}
                type={"text"}
                variant={"outlined"}
                size={"small"}
                fullWidth={true}
                inputProps={
                  {
                    // maxlength: 20,
                  }
                }
                value={state?.cartname}
                onChange={(event) => setState("cartname", event.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <div className={classes.fieldContainer}>
              <Typography className={classes.label}>
                Dispense Type <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                onLoad={() => setState("disptype", state?.disptype)}
                getOptionLabel={(option) => option?.["label"] ?? ""}
                options={options?.dispenseType ?? []}
                size={"small"}
                placeholder=""
                value={Array.isArray(state?.disptype) ? state?.disptype : []}
                onChange={(event, newValue) => setState("disptype", newValue)}
                multiple
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option?.label}
                      deleteIcon={<CloseIcon htmlColor="#6A7888" />}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <div className={classes.fieldContainer}>
              <Typography className={classes.label}>
                Pharmacy Location <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                onLoad={() => setState("locationid", state?.locationid)}
                getOptionLabel={(option) => option?.["label"]}
                options={options?.pharmacy ?? []}
                size={"small"}
                placeholder=""
                value={state?.locationid ?? null}
                onChange={(event, newValue) => setState("locationid", newValue)}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <div className={classes.fieldContainer}>
              <Typography className={classes.label}>
                Ward <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                multiple
                value={state?.ward ?? []}
                onLoad={() => setState("ward", state?.ward)}
                size={"small"}
                onChange={(event, newValue) => setState("ward", newValue)}
                options={options?.ward ?? []}
                getOptionLabel={(option) => option?.["label"] ?? ""}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option?.label}
                      deleteIcon={<CloseIcon htmlColor="#6A7888" />}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <div className={classes.fieldContainer}>
              <Typography className={classes.label}>
                Effective From <span style={{ color: "red" }}>*</span>
              </Typography>
              <DateTimePicker
                required={true}
                label={""}
                placeholder={getFormatForDateTimePicker("date")}
                // dateFormat={"dd-MM-yyyy"}
                onChange={(value) => {
                  setState("efffrdate", value);
                }}
                value={state?.efffrdate ? moment(state?.efffrdate) : null}
              // maxDate={state?.efftodate ?? null}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <div className={classes.fieldContainer}>
              <Typography className={classes.label}>Effective To <span style={{ color: "red" }}>*</span></Typography>
              <DateTimePicker
                required={true}
                label={""}
                placeholder={getFormatForDateTimePicker("date")}
                // dateFormat={"dd-MM-yyyy"}
                onChange={(value) => {
                  setState("efftodate", value);
                }}
                value={state?.efftodate ? moment(state?.efftodate) : null}
                minDate={state?.efffrdate ?? null}
              />
            </div>
          </Grid>

        </Grid>
      </div>
    </div>
  );
}

export default CartDefinition;
