import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
// import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";

// import IDMPANEL from "idmpanel-pkg";
// import Config from "../../config";
// import cookie from "react-cookies";

import * as ALL from "../../components";
import * as ALLMCORE from "@material-ui/core";

// import { Modal } from "qdm-component-library";
import SearchIcon from "@material-ui/icons/Search";

import { Paper, Grid, Typography, Button, withStyles } from "@material-ui/core";
import { CustRowTable } from "custom-material-ui-component";

import qdmstyles from "./styles.json";
import "./index.css";
import { SearchInputBox } from "./searchInputBox";
import { styles } from "./styles";
import copy from "fast-copy";
import Color from "../../themes/default.json";

var aes256 = require("aes256");
// var aes256 = require("aes256");

const minSearchCount = 3;
class Pharmacy extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      permissionData: null,
      page: 0,
      perPage: 10,
      searchQuery: "",
      fullData: [],
      filteredList: [],
      visibleList: [],
      shouldFilterLocally: false,
    };
  }

  // Limit Data Helper
  limitData = (data = [], page, perPage) => {
    let start = page;
    let end = start + perPage;
    return data?.slice(start, end);
  };

  // Filter Data Helper
  filterData = (data, searchQuery) => {
    if (searchQuery?.length) {
      let res = data?.filter((item) =>
        item?.locationID?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
      return res;
    }

    return data;
  };

  // Full Read
  pharmacyFullRead = (page, perPage, searchQuery, fetchWithoutLimit) => {
    const withLimitPayload = {
      page: page,
      perPage: perPage,
      searchQuery: searchQuery,
    };

    const withOutLimitPayload = {
      searchQuery: searchQuery,
      isWithoutLimit: true,
    };

    Promise.resolve(
      this.props
        .PHARMACY_MASTER_FULL_READ(
          fetchWithoutLimit ? withOutLimitPayload : withLimitPayload
        )
        .then((res) => {
          let filteredData = this.filterData(res?.payload?.data, searchQuery);
          let copiedData = copy(filteredData);
          let listToShow = fetchWithoutLimit
            ? this.limitData(copiedData, page, perPage)
            : copiedData;
          let s = {
            fullData: res?.payload?.data,
            filteredList: filteredData,
            visibleList: listToShow,
          };
          this.setState(s);
        })
        .catch((err) => console.error(err))
    );
  };

  // Filter List Locally Helper
  filterLocalList = (page, perPage) => {
    const { searchQuery } = this.state;
    let list = this.props.PharmacyMasterFullRead?.data;

    list = this.filterData(list, searchQuery);

    let filteredList = copy(list);
    let copiedList = copy(list);

    let final = this.limitData(copiedList, page, perPage);

    this.setState({
      fullData: this.props.PharmacyMasterFullRead?.data,
      filteredList: filteredList,
      visibleList: final,
    });
  };

  changeState = (key, value) => {
    let prevSearchQuery = "";
    this.setState(
      (prev) => {
        if (key === "searchQuery") {
          prevSearchQuery = prev.searchQuery;
        }
        return {
          [key]: value,
        };
      },
      () => {
        if (key === "searchQuery") {
          if (
            value?.length === 0 ||
            (value?.length === minSearchCount &&
              !this.state.shouldFilterLocally)
          ) {
            this.pharmacyFullRead(
              this.state.page,
              this.state.perPage,
              value,
              value?.length !== 0
            );
            this.setState({
              shouldFilterLocally: value?.length >= 3,
            });
            // this.props.DRUG_CATALOG_MASTER_FULL_READ({
            //   page: this.state.page,
            //   perPage: 0,
            //   searchQuery: value,
            // });
          } else if (value.length < minSearchCount) {
            if (prevSearchQuery.length > value.length) {
              this.setState({
                shouldFilterLocally: false,
              });
              // this.callFullRead(this.state.page, this.state.perPage, value);
            }
          } else if (this.state.shouldFilterLocally) {
            this.filterLocalList(0, 10);
          }
        }
      }
    );
  };

  handlepageChange = async (currentPage, PerPage) => {
    this.setState(
      {
        ...this.state,
        page: (currentPage - 1) * PerPage,
        perPage: PerPage,
      },
      () => {
        if (this.state.shouldFilterLocally) {
          this.filterLocalList((currentPage - 1) * PerPage, PerPage);
        } else {
          this.pharmacyFullRead(
            (currentPage - 1) * PerPage,
            PerPage,
            this.state.searchQuery
          );
        }
      }
    );
  };

  componentDidMount() {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(localStorage.defaultLng);
    }

    /// setlogger && dispatch of all events

    this.pharmacyFullRead(
      this.state.page,
      this.state.perPage,
      this.state.searchQuery
    );

    /// fetch all permission
  }

  ///fetching all permission

  shouldComponentUpdate(props, state) {
    return true;
  }

  render() {
    const { classes } = this.props;
    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <Paper
              key={"0"}
              id={"Hp2Ar"}
              elevation={"{3}"}
              // style={qdmstyles.HpAr}
              className={classes.commonBackground}
            >
              <Paper
                key={"0"}
                id={"pharmacy_typography"}
                elevation={"{3}"}
                aliasName={"pharmacytypography"}
                className={classes.pharmacy_back}
                style={qdmstyles.PkT}
              >
                <Grid
                  key={"0"}
                  id={"N3knu"}
                  container={""}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid
                    key={"0"}
                    id={"DINCo"}
                    container={true}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    style={qdmstyles.DINCo}
                  >
                    <Grid
                      key={"0"}
                      id={"p3VrG"}
                      container={""}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"2"}
                      item={true}
                    >
                      <Typography
                        key={"0"}
                        id={"pharmacy_typography"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        className={classes.pharmacy}
                        children={t("pharmacy.title")}
                        aliasName={"pharmacytypography"}
                        style={qdmstyles.NNTP}
                      ></Typography>
                    </Grid>
                    <Grid
                      key={"1"}
                      id={"psRKa"}
                      container={""}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      xs
                      item={true}
                      style={qdmstyles.psRKa}
                    >
                      <div className={classes.searchAndAddButtonContainer}>
                        <SearchInputBox
                          id="searchDrug"
                          placeholder="Search Code"
                          onChange={(e) =>
                            this.changeState("searchQuery", e.target.value)
                          }
                          style={{ color: Color.palette.text.primary }}
                          className={classes.searchAndButtonInput}
                          startAdornment={
                            <ALLMCORE.InputAdornment position="start">
                              <SearchIcon htmlColor="#6A7888" />
                            </ALLMCORE.InputAdornment>
                          }
                        />

                        <Button
                          key={"0"}
                          onClick={() => {
                            this.props.history.push("/addnewpharmacy");
                          }}
                          id={"add_new_button"}
                          variant={"contained"}
                          className={classes.addnew}
                          color={"secondary"}
                          children={"+ Add New"}
                          size={"small"}
                          fullWidth={true}
                          style={qdmstyles.WMUdU}
                        ></Button>
                      </div>
                    </Grid>
                  </Grid>
                  <CustRowTable
                    key={"1"}
                    id={"S2WDU"}
                    BodyComp={
                      <ALL.Pharmacytable
                        propsState={this.state.visibleList}
                        loading={this.props?.PharmacyMasterFullRead?.loading}
                        setFun={(value) => {
                          this.changeState("Pharmacytable", value);
                        }}
                        stateName={"Pharmacytable"}
                        statevalue={this.state?.Pharmacytable}
                      />
                    }
                  ></CustRowTable>

                  {/* {this.state.fullData?.length > 0 && ( */}
                  <div>
                    <ALLMCORE.Divider light className={classes.divider} />

                    <ALL.CustomTablePagination
                      handlepageChange={(currentPage, PerPage) =>
                        this.handlepageChange(currentPage, PerPage)
                      }
                      // count={
                      //   this.props.FrequencyMasterFullRead.data[0]?.count
                      //   // 5
                      // }
                      count={
                        this.state.shouldFilterLocally
                          ? this.state.filteredList?.length
                          : this.props?.PharmacyMasterFullRead?.data?.[0]
                            ?.Total?.[0]
                      }
                      incrementCount={this.state.page}
                      showPagination={true}
                      rowsPerPageOptions={[
                        { label: "10 Rows", value: 10 },
                        { label: "50 Rows", value: 50 },
                        { label: "100 Rows", value: 100 },
                      ]}
                    />
                  </div>
                  {/* )} */}
                </Grid>
              </Paper>
            </Paper>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}
const mapStateToProps = (state) => ({
  PharmacyMasterFullRead:
    state?.pharmacytablepharmacy_fullread_pnrrOReducer?.pharmacy_fullread_pnrrO,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(translate()(withStyles(styles)(Pharmacy))));
