import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Button, useTheme } from "@material-ui/core";
import { CommonTable } from "../../components";
import { GenerateForm } from "../../components";
import { AlertProps } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { withAllContexts } from "../../HOCs";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    marginLeft: "18px",
    width: "97%",
    border: "1px solid lightgrey",
    borderRadius: "2px",
  },
  leftButton: {
    border: "none",
    backgroundColor: "#fff",
    width: 32,
    height: 32,
    borderRadius: "50%",
    cursor: "pointer",
  },
  add: {
    "&.App1-MuiButton-contained:hover": {
        backgroundColor: theme.palette.button.hover
      },
      color:theme.palette.button.text,
      backgroundColor:theme.palette.button.background,
  },
}));

function SymtomsAndSpecialtyTable(props) {
  const { parent_id } = props;
  const classes = useStyles();
  const theme = useTheme()
  const dispatch = useDispatch();

  const listData = useSelector(
    (state) => state?.symptomAndSpecialtySlice?.symptom_and_specialty_list?.data
  );
  const symptomAndSpecialtyMasterData = useSelector(
    (state) =>
      state?.symptomAndSpecialtySlice?.symptom_and_specialty_master?.data
  );
  const loading = useSelector(
    (state) => state?.holidaySlice?.holidy_list?.loading
  );
  const saveloading = useSelector(
    (state) => state?.holidaySlice?.holiday_upsert?.loading
  );

  const handleDrawerOpen = () => {
    setState({
      ...state,
      open: true,
    });
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      edit_data: null,
    });
  };

  React.useLayoutEffect(() => {
    dispatch(actions.SYMPTOM_AND_SPECIALTY_READ());
    dispatch(actions.SYMPTOM_AND_SPECIALTY_MASTERS());
  }, []);

  const constructSpecialtyData = (data) => {
    let arry = [];
    data.map((val) => {
      arry.push(val.value);
    });
    return arry;
  };

  const saveSymptomAndSpecialties = async (values) => {
    let severity = AlertProps.severity.success;
    let data_ = {};
    if (state?.edit_data) {
      data_ = {
        _key: state?.edit_data._key,
        id: state?.edit_data.id,
        status: state?.edit_data.status,
        symptom_code: values.symptom_code,
        symtom_description: values.symtom_description,
        specialities: constructSpecialtyData(values.specialities),
      };
    } else {
      data_ = {
        symptom_code: values.symptom_code,
        symtom_description: values.symtom_description,
        specialities: constructSpecialtyData(values.specialities),
        status: true,
      };
    }
    const upsertdata = await dispatch(
      actions.SYMPTOM_AND_SPECIALTY_UPSERT(data_)
    );
    if (upsertdata?.payload?.data?.error) {
      severity = AlertProps.severity.error;
      //ERROR ALERT
      props.alert.setSnack({
        open: true,
        severity: severity,
        msg: upsertdata?.payload?.data?.validation_error[0].Errormsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else {
      await dispatch(actions.SYMPTOM_AND_SPECIALTY_READ());
      await handleDrawerClose();
      //SUCCESS ALERT
      props.alert.setSnack({
        open: true,
        severity: severity,
        msg: state?.edit_data?._key
          ? "Symptom & Specialties updated successfully"
          : "Symptom & Specialties added successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  const handleEdit = async (e, values, index) => {
    if (values?._key) {
      let d = await dispatch(
        actions.SYMPTOM_AND_SPECIALTY_SINGLE_READ({ kay: values?._key })
      );
      let data = {
        ...values,
        symptom_code: d?.payload?.data?.[0]?.symptom_code ?? "",
        symtom_description: d?.payload?.data?.[0]?.symtom_description ?? "",
        specialities: d?.payload?.data?.[0]?.specialities ?? null,
      };
      setState({
        ...state,
        edit_data: data,
        open: true,
      });
    }
  };

  const statusChange = async (e, values, index) => {
    let data = {
      _key: values._key,
      status: e.target.checked,
    };
    await dispatch(actions.SYMPTOM_AND_SPECIALTY_STATUS_CHANGE(data));
    await dispatch(actions.SYMPTOM_AND_SPECIALTY_READ());
    setState({
      ...state,
      edit_data: null,
    });
    props.alert.setSnack({
      open: true,
      severity: AlertProps.severity.success,
      msg: "Symptom & Specialties status updated",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
  };
  const specialties_data = [
    { value: "1", label: "val 1" },
    { value: "2", label: "val 2" },
    { value: "3", label: "val 3" },
  ];
  // old state
  const [state, setState] = React.useState({
    code: "",
    symptom_code: "",
    symtom_description: "",
    specialities: [],
  });

  return (
    <div id={`${parent_id}-parent-div`} style={{ borderRadius: "12px" }}>
      <div id={`${parent_id}-parent-sub-div`} style={{ width: "100%" }}>
        <Card id={`${parent_id}-parent-card`} style={{ borderRadius: "12px" }}>
          <div
            id={`${parent_id}-parent-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              id={`${parent_id}-discription-sub-div`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 20px",
                backgroundColor: theme.palette.background.table,
              }}
            >
              <div id={`${parent_id}-List-of-symptonsAndSpecialties-div`}>
                <p
                  id={`${parent_id}-List-of-symptonsAndSpecialties-title-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  Symptoms & Specialties
                </p>
              </div>
              <div
                id={`${parent_id}-entity-div`}
                style={{ marginTop: "16px", float: "right" }}
              >
                <Button
                  id={`${parent_id}-Add-New-button`}
                  disabled={false}
                  variant={"contained"}
                  color={"primary"}
                  className={classes.add}
                  onClick={handleDrawerOpen}
                >
                  +Add New
                </Button>

                <GenerateForm
                  parent_id={"SymptomAndSpecialtiestable"}
                  loading={saveloading}
                  open={state.open}
                  save={saveSymptomAndSpecialties}
                  handlecancel={handleDrawerClose}
                  header={state.edit_data ? "Edit Symptom" : "Add New Symptom"}
                  json={[
                    {
                      componet_type: "text",
                      label: "Symptom Code",
                      isUpperCase: true,
                      disabled: state.edit_data ? true : false,
                      state_name: "symptom_code",
                      value: state?.edit_data?.symptom_code ?? "",
                      required: true,
                    },
                    {
                      componet_type: "text",
                      label: "Symptom Description",
                      state_name: "symtom_description",
                      value: state?.edit_data?.symtom_description ?? "",
                      required: true,
                    },
                    {
                      componet_type: "select",
                      options: symptomAndSpecialtyMasterData ?? [],
                      label: "Specialties",
                      state_name: "specialities",
                      value: state?.edit_data?.specialities ?? [],
                      required: true,
                      multi: true,
                    },
                  ]}
                  nocancel
                />
              </div>
            </div>
            <div
              id={`${parent_id}-CommonTable-div`}
              style={{ padding: 20, height: "71vh", overflow: "auto",backgroundColor: theme.palette.background.table}}
            >
              <CommonTable
                parent_id={"symptonsAndSpecialtiestable"}
                Header={[
                  "S.No",
                  "Symptom Code",
                  "Symptom Description",
                  "Specialties",
                  "Status",
                  "Action",
                ]}
                dataList={listData}
                tableData={[
                  { type: ["INCRIMENT"], name: "" },
                  { type: ["TEXT"], name: "symptom_code" },
                  { type: ["TEXT"], name: "symtom_description" },
                  { type: ["TEXT"], name: "specialities" },
                  { type: ["CHECKBOX"], name: "status" },
                  { type: ["EDIT"], name: "action" },
                ]}
                handleCheckBox={statusChange}
                handleEdit={handleEdit}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
export default withAllContexts(SymtomsAndSpecialtyTable);
