import React from "react";
import {
  makeStyles,
  TextField,
  Popover,
  Typography,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { TextInput, Text, Div, Icons } from "qdm-component-library";
import { CalendarIcon } from "../../../../../../assets";
import DatePicker from "react-datepicker";
import moment from "moment";
//import { useSelector } from 'react-redux';
import { UIColor } from "../../../../../../utils";

const borderColor = "solid 1px rgba(224, 224, 224, 1)";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 200,
    padding: 10,
  },
  tableRoot: {
    "& .MuiPaper-root": {
      maxWidth: 800,
      padding: 10,
      borderRadius: 12,
    },
    "& .MuiTypography-h6": {
      fontSize: 16,
    },
    "& .MuiTableRow-root td:first-child": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "& .MuiTableRow-root td:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
  dialogWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  dialogTitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  saveButton: {
    backgroundColor: UIColor.primaryColor.color,
  },
  addMore: {
    color: UIColor.primaryColor.color,
    cursor: "pointer",
  },
  table: {
    border: borderColor,
  },
  tableRow: {
    borderRadius: 14,
  },
  tableHead: {
    backgroundColor: UIColor.lineBorderFill.color,
    "& .MuiTableCell-root": {
      borderLeft: borderColor,
      padding: 8,
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      padding: "8px 12px",
    },
  },
  input: {
    "&.MuiPaper-root": {
      width: 30,
      padding: "0px 0px",
      borderRadius: 6,
      border: borderColor,
    },
    "& input": {
      padding: 0,
      textAlign: "center",
      height: 30,
      border: "none !important",
    },
  },
  labelText: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px !important",
    fontFamily: "pc_regular!important",
    marginBottom: "6px",
    fontWeight: 400,
  },
  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,

      fontSize: "12px !important",

      fontFamily: "pc_regular!important",
    },

    "& .MuiInputBase-root": {
      height: 40,
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: UIColor.lineBorderFill.color,

        borderRadius: 8,
      },

      "&:hover fieldset": {
        borderColor: UIColor.lineBorderFill.color,
      },

      "&.Mui-focused fieldset": {
        borderWidth: 1,

        borderColor: UIColor.lineBorderFill.color,
      },
    },

    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
}));

export default function FrequencyList(props) {
  const styles = props.styles;
  const classes = useStyles();
  // const stateList = useSelector(state => state?.MedicationMastersSlice);
  const [state, setState] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorIndex, setanchorIndex] = React.useState({}); //multiple calenders

  const handleClick = (event, rowIndex, columnIndex) => {
    setAnchorEl(event);
    setanchorIndex({
      rowIndex: rowIndex,
      columnIndex: columnIndex,
    });
  };

  React.useEffect(() => {
    setState({
      row: props.row,
      column: props.column,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value, rowIndex, columnIndex, valueType) => {
    if (value && props.contentEditable) {
      if (valueType) {
        state.column[rowIndex][columnIndex].unit = value;
      } else {
        let fcount = state.column[rowIndex][0].value;
        if (columnIndex === 1 && fcount) {
          let count = Math.abs(parseInt(fcount) - (parseInt(value) + 1));
          let endDate = moment(
            moment(state.column[rowIndex][2].value, "DD-MM-YYYY,hh:mm A")
          )
            .add(count, "d")
            .format("DD-MM-YYYY,h:mm a");
          state.column[rowIndex][3].value = endDate;
        }
        state.column[rowIndex][columnIndex].value = value;
      }
      setState({ ...state });
      props.handleSave("frequencyList", state);
    }
  };

  const deleteRow = (index) => {
    state.column.splice(index, 1);
    setState({ ...state });
    props.handleSave("frequencyList", state);
  };

  const addNewRow = (index) => {
    // let clm = state.column;
    let startDate = moment().format("DD-MM-YYYY,h:mm a");
    // if (clm.length > 0) {
    //     startDate = clm[clm.length - 1][3].value
    // }
    state.column.push([
      {
        value: null,
        type: "quantity",
        typeName: "fromday",
      },
      {
        value: null,
        type: "quantity",
        typeName: "today",
      },
      {
        value: startDate,
        type: "datetime",
        typeName: "startday",
      },
      {
        value: "",
        type: "datetime",
        typeName: "endday",
      },

      {
        value: null,
        type: "select",
        optionsName: "schedule",
        typeName: "schedule",
      },
      {
        value: null,
        type: "quantity",
        valueType: "quantity",
        typeName: "dose",
        unit: null,
      },
    ]);
    setState({ ...state });
    props.handleSave(props.title, state);
  };

  const InputField = (item, columnIndex, itemIndex, valueType) => {
    switch (item?.type) {
      case "time":
        // const { parent_id } = props;
        return (
          <Paper
            // id={`${parent_id}_frequency_parent_div`}
            className={classes.input}
            style={{ width: 100 }}
          >
            {/* <TextInput
                        className={classes.input}
                        value={item?.value}
                        onChange={(event) => {
                            handleChange(event.target.value, columnIndex, itemIndex)
                        }}
                        variant="contained"
                    /> */}
            <TextField
              id="time"
              label=""
              type="time"
              onChange={(event) => {
                handleChange(event.target.value, columnIndex, itemIndex);
              }}
              value={item?.value}
              // className={classes.textField}
              inputProps={{ step: 300 }}
            />
          </Paper>
        );
      case "quantity":
        return (
          <div
            // id={`${parent_id}_frequency_quantity_parent_div`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper
              // id={`${parent_id}_frequency_quantity_parent_paper`}
              className={classes.input}
              style={{ width: 60 }}
            >
              <TextInput
                // id={`${parent_id}_frequency_quantity_textinput`}
                className={classes.input}
                value={item?.value}
                placeholder=""
                onChange={(event) => {
                  handleChange(event.target.value, columnIndex, itemIndex);
                }}
                variant="contained"
              />
            </Paper>
            {valueType && (
              <Autocomplete
                // id={`${parent_id}_frequency_valueType_autpcomplete`}
                className={classes.autocomplete}
                label={""}
                isRequired
                placeholder={""}
                size={"small"}
                style={{ width: "125px", marginLeft: "5px" }}
                disableClearable
                options={
                  // stateList.DosageUOM_masters?.data
                  []
                }
                getOptionLabel={(option) => option.label}
                value={item?.unit ?? null}
                onChange={(e, value) =>
                  handleChange(value, columnIndex, itemIndex, valueType)
                }
                renderInput={(params) => (
                  <TextField {...params} label="" variant="outlined" />
                )}
              />
            )}
            {props?.columnEditable && itemIndex === state?.row.length - 1 ? (
              <Icons
                // id={`${parent_id}_frequency_trash_icons`}
                fontIcon="trash"
                style={{
                  color: UIColor.error.color,
                  marginLeft: 5,
                  marginTop: 1,
                  cursor: "pointer",
                }}
                onClick={() => deleteRow(columnIndex)}
              />
            ) : null}
          </div>
        );
      case "label":
        return (
          <Text
          // id={`${parent_id}_frequency_${item?.value}_text`}
          >
            {item?.value}
          </Text>
        );
      case "select":
        return (
          <Autocomplete
            // id={`${parent_id}_frequency_select_autocomplete`}
            className={classes.autocomplete}
            label={""}
            isRequired
            placeholder={""}
            size={"small"}
            style={{ width: "125px" }}
            disableClearable
            options={
              // stateList.FrequencyMaster_masters?.data
              []
            }
            getOptionLabel={(option) => option.label}
            value={item?.value}
            onChange={(e, value) => handleChange(value, columnIndex, itemIndex)}
            renderInput={(params) => (
              <TextField {...params} label="" variant="outlined" />
            )}
          />
        );
      case "datetime":
        return (
          <Div
            // id={`${parent_id}_frequency_datetime_div`}
            inLineStyles={styles.dateWrapper}
            style={{ minWidth: 200 }}
          >
            <Text
              // id={`${parent_id}_frequency_datetime_${item?.value ? item?.value : ' '.replaceAll(" ", "_")}_text`}
              className="pc_regular"
              style={styles.dateText}
            >
              {item?.value ? item?.value : " "}
            </Text>
            <Text
              // id={`${parent_id}_frequency_datetime_calender-popover_text`}
              style={styles.calenderText}
              aria-describedby={"calender-popover"}
              onClick={(event) =>
                handleClick(event?.currentTarget, columnIndex, itemIndex)
              }
            >
              <CalendarIcon
                // id={`${parent_id}_frequency_datetime_CalendarIcon`}
                color={UIColor.tertiaryText.color}
                width={"16"}
                height={"16"}
              />
            </Text>
          </Div>
        );
      default:
    }
  };
  // const { parent_id } = props;
  return (
    <div
      // id={`${parent_id}_frequency_parent_div`}
      className={classes.root}
    >
      <div
        // id={`${parent_id}_frequency_title_div`}
        className={classes.dialogWrapper}
      >
        <Typography
          // id={`${parent_id}_frequency_${props.title.replaceAll(" ", "_")}_typography`}
          variant="h6"
          className={classes.dialogTitle}
        >
          {props.title}
        </Typography>
        {props?.columnEditable ? (
          <Text
            // id={`${parent_id}_frequency_Add_New_text`}
            className={classes.addMore}
            onClick={addNewRow}
          >
            + Add New
          </Text>
        ) : null}
      </div>
      <TableContainer
        // id={`${parent_id}_frequency_parent_tablecontainer`}
        className={classes.tableRoot}
      >
        <Table
          // id={`${parent_id}_frequency_parent_table`}
          className={classes.table}
        >
          <TableHead
            // id={`${parent_id}_frequency_parent_tablehead`}
            className={classes.tableHead}
          >
            <TableRow
              // id={`${parent_id}_frequency_parent_tablerow`}
              className={classes.tableRow}
            >
              {state?.row.map((item, i) => {
                return (
                  <TableCell
                  // id={`${parent_id}_frequency_${i}_item_tablecell`}
                  >
                    {item}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody
            // id={`${parent_id}_frequency_tablebody`}
            className={classes.tableBody}
          >
            {state?.column.map((column, columnIndex) => {
              return (
                <TableRow>
                  {column.map((item, itemIndex, i) => {
                    return (
                      <TableCell
                        // id={`${parent_id}_frequency_valueType_${i}_tablecell`}
                        style={{
                          borderRight: itemIndex === 0 ? borderColor : "",
                        }}
                      >
                        {InputField(
                          item,
                          columnIndex,
                          itemIndex,
                          item.valueType
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        id={"calender-popover"}
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: styles.muiPopovers }}
      >
        {
          <DatePicker
            // id={`${parent_id}_frequency_DatePicker`}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput
            selected={new Date()}
            inline
            onChange={(date) => {
              handleChange(
                moment(date).format("DD-MM-YYYY,hh:mm A"),
                anchorIndex.rowIndex,
                anchorIndex.columnIndex
              );
              setAnchorEl(null);
            }}
          />
        }
      </Popover>
    </div>
  );
}

// const optionsList = {
//     "schedule": [{ value: 'QD-Once daily', label: 'QD-Once daily' }]
// }
