import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  DrugCard,
  DrugCardForReturnMedication,
  DrugCardTags,
} from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  }
}));

function DrugItem(props) {
  const classes = useStyles();

  const { isReturnMedication, issueMedication } = props;
  return (
    <div className={classes.root}>
      {!props?.drug?.isEmpty && <DrugCardTags {...props} />}

      {/* If the scenario is return medication, then different drug card will be used to keep the default UI intact. */}
      {
        issueMedication ? <DrugCardForReturnMedication {...props} issueMedication={issueMedication} /> :
          isReturnMedication ? (
            <DrugCardForReturnMedication {...props} />
          ) : (
            <DrugCard {...props} />
          )
      }

      {props?.drug?.isEmpty && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: 334,
            overflowY: "scroll",
          }}
        >
          No {props?.selectedStateLabel} Data Found!
        </div>
      )}
    </div>
  );
}

export default DrugItem;

DrugItem.propTypes = {};

DrugCard.defaultProps = {};
