import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Routes } from "./routes";
import PrivateRoute from "./privateRouter";
import locationAndRoles from "../components/locationAndRoles";
import {
  NotFound,
  OrderRoutingScreen,
  Addnewpharmacy,
  PharmacyTest,
  Pharmacy,
  EditNewPharmacy,
  // Frequency,
  AddNewFrequency,
  Addnewalertandrestriction,
  // AddnewfrequencyOrg,
  // EditNewFrequencyOrg,
  PharmacyVerify,
  Login,
  Drugmastertable,
  Drugintscreen,
  Alertrestriction,
  TaskAndDocument,
  PrimaryCare,
  PrimaryCareMasters,
  PrinterQueue,
  LocationScreen,
  OrganizationConfig,
  Organizations,
  Administration,
  Repository,
  PractitionerMaster,
  ConsultMaster,
  CartDefinitionListing,
  CartDefinitionUpsert,
  CardOrderVerify,
  Sandbox,
} from "../screens";

import { MainLayout } from "./../layout";
import AddNewPerson from "../components/repositoryMaster/addNewPerson";
import Addpractitioner from "../components/practitionermaster/addPractotioner/index";
import CartOrder from "../screens/cartOrder";
import { FrequencyListing, FrequencyAddEdit } from "../screens";
import Reports from "../screens/reports";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path={Routes.login} component={Login} />
        {/*orderRoutingScreen Route */}
        <PrivateRoute
          path={Routes.orderRoutingScreen}
          component={OrderRoutingScreen}
        />
        <PrivateRoute
          path={Routes.practitionerMaster}
          component={() => (
            <MainLayout>
              <PractitionerMaster />
            </MainLayout>
          )}
        />
        <PrivateRoute
          path={Routes.addpractitioner}
          component={() => (
            <MainLayout>
              <Addpractitioner />
            </MainLayout>
          )}
          // idmEnabled={false}
        />
        {/*cartVerify  Route */}
        <Route
          path={Routes.cartVerify}
          component={() => (
            <MainLayout ismenu={false}>
              <PharmacyVerify />
            </MainLayout>
          )}
        />
        {/*pharmacy_detail Route */}
        {/* <PrivateRoute
          path={Routes.pharmacyDetail}
          component={PharmacyDetail}
          /> */}
        {/*Pharmacy_test Route */}
        <PrivateRoute path={Routes.pharmacyTest} component={PharmacyTest} />
        {/*Pharmacy_table Route */}
        {/* <PrivateRoute
          path={Routes.pharmacyTable}
          component={() => (
            <MainLayout>
              <PharmacyTable />
            </MainLayout>
          )}
        /> */}
        <PrivateRoute
          path={Routes.pharmacy}
          component={() => (
            <MainLayout>
              <Pharmacy />
            </MainLayout>
          )}
        />
        <PrivateRoute
          path={Routes.editNewPharmacy}
          component={() => (
            <MainLayout>
              <EditNewPharmacy />
            </MainLayout>
          )}
        />
        <PrivateRoute
          path={Routes.addnewpharmacy}
          component={() => (
            <MainLayout>
              <Addnewpharmacy />
            </MainLayout>
          )}
        />
        {/* <Route
          path={Routes.addnewpharmacy}
          component={Addnewpharmacy}
          idmEnabled={false}
        /> */}
        {/* <Route path={Routes.pharmacy} component={Pharmacy} idmEnabled={false} /> */}
        {/* <Route path={Routes.editNewPharmacy} component={EditNewPharmacy} idmEnabled={false} /> */}
        {/* Drug Route */}
        {/* Drug Catalogue master route*/}
        <PrivateRoute
          path={Routes.drugmastertable}
          component={() => (
            <MainLayout>
              <Drugmastertable />
            </MainLayout>
          )}
        />
        {/* Add Drug Step 1 */}
        <PrivateRoute
          path={Routes.drugintscreen}
          component={() => (
            <MainLayout>
              <Drugintscreen />
            </MainLayout>
          )}
        />
        {/* Add Drug Step 2 */}
        <PrivateRoute
          path={Routes.alertrestriction}
          component={() => (
            <MainLayout>
              <Alertrestriction />
            </MainLayout>
          )}
        />
        {/*Frequency Routes */}
        {/*-----Frequency Listing------ */}
        <PrivateRoute
          path={Routes.frequency}
          component={() => (
            <MainLayout>
              <FrequencyListing />
            </MainLayout>
          )}
        />
        {/*----------Frequency Add Edit----------*/}
        <PrivateRoute
          path={Routes.addFrequency}
          component={() => (
            <MainLayout>
              <FrequencyAddEdit />
            </MainLayout>
          )}
          idmEnabled={false}
        />
        <PrivateRoute
          path={Routes.editFrequency}
          component={() => (
            <MainLayout>
              <FrequencyAddEdit />
            </MainLayout>
          )}
          idmEnabled={false}
        />

        {/*addnewalertandrestriction Route */}
        <PrivateRoute
          path={Routes.addnewalertandrestriction}
          component={Addnewalertandrestriction}
        />
        {/*pharmacyverify  Route */}
        <PrivateRoute
          path={Routes.pharmacyVerify}
          component={() => (
            <MainLayout ismenu={false}>
              <PharmacyVerify />
            </MainLayout>
          )}
        />
        <PrivateRoute
          path={Routes.cartOrder}
          component={() => (
            <MainLayout ismenu={false}>
              <CartOrder />
            </MainLayout>
          )}
        />
        {/* task and document */}
        <PrivateRoute
          path={Routes.taskAndDocument}
          component={() => (
            <MainLayout>
              <TaskAndDocument />
            </MainLayout>
          )}
        />
        {/* Cart Definition Listing */}
        <PrivateRoute
          path={Routes.cartDefinition}
          component={() => (
            <MainLayout>
              <CartDefinitionListing />
            </MainLayout>
          )}
        />
        {/* Cart Definition Upsert */}
        <PrivateRoute
          path={Routes.cartDefinitionUpsert}
          component={() => (
            <MainLayout>
              <CartDefinitionUpsert />
            </MainLayout>
          )}
        />
        {/* Primary Care */}
        <PrivateRoute
          path={Routes.primaryCare}
          component={() => (
            <MainLayout>
              <PrimaryCare />
            </MainLayout>
          )}
        />
        {/* Organization Config - PC */}
        <PrivateRoute
          path={Routes.organizationConfig}
          component={() => (
            <MainLayout>
              <OrganizationConfig />
            </MainLayout>
          )}
        />
        {/* Organization - PC */}
        <PrivateRoute
          path={Routes.organization}
          component={() => (
            <MainLayout>
              <Organizations />
            </MainLayout>
          )}
        />
        {/* administration - PC */}
        <PrivateRoute
          path={Routes.administration}
          component={() => (
            <MainLayout>
              <Administration />
            </MainLayout>
          )}
        />
        {/* userManagement - PC */}
        <PrivateRoute
          path={Routes.userManagement}
          component={() => (
            <MainLayout>
              <Repository />
            </MainLayout>
          )}
        />
        {/* PermissionManagement - PC */}
        <PrivateRoute
          path={Routes.permissionManagement}
          component={() => (
            <MainLayout>
              <Repository />
            </MainLayout>
          )}
        />
        <PrivateRoute
          path={Routes.addperson}
          component={() => (
            <MainLayout>
              <AddNewPerson />
            </MainLayout>
          )}
          idmEnabled={false}
        />
        {/* Add practioner */}
        {/* practitionerMaster - PC */}
        {/* consultMaster - PC */}
        <PrivateRoute
          path={Routes.consultMaster}
          component={() => (
            <MainLayout>
              <ConsultMaster />
            </MainLayout>
          )}
        />
        {/* Printer Queue */}
        <PrivateRoute
          path={Routes.printerQueue}
          component={() => (
            <MainLayout>
              <PrinterQueue />
            </MainLayout>
          )}
        />

        {/* Report */}
        <PrivateRoute
          path={Routes.report}
          component={() => (
            <MainLayout>
              <Reports />
            </MainLayout>
          )}
        />

        {/* Sandbox */}
        <Route path="/sandbox" component={Sandbox} />

        {/* For unknow/non-defined path */}
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
