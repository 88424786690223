import * as React from "react";
import PropTypes from "prop-types";

const Person = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.055"
    height="12.571"
    viewBox="0 0 11.055 12.571"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_51796"
          data-name="Rectangle 51796"
          width="11.055"
          height="12.571"
          transform="translate(0 0)"
          fill={props.color}
        />
      </clipPath>
    </defs>
    <g id="Group_95669" data-name="Group 95669" transform="translate(0 0)">
      <g id="Group_95668" data-name="Group 95668" clip-path="url(#clip-path)">
        <path
          id="Path_95398"
          data-name="Path 95398"
          d="M9.719,8.182a.279.279,0,0,1,.274.277c0,1.971-2.1,3.038-4.046,3.038H5.264c-1.949,0-4.2-1.067-4.2-3.038a.279.279,0,0,1,.274-.277Zm0-1.074H1.336A1.347,1.347,0,0,0,0,8.459c0,2.557,2.736,4.112,5.264,4.112h.683c2.528,0,5.108-1.555,5.108-4.112A1.347,1.347,0,0,0,9.719,7.108M5.525,1.074A1.926,1.926,0,1,1,3.6,3,1.928,1.928,0,0,1,5.525,1.074M5.525,0a3,3,0,1,0,3,3,3,3,0,0,0-3-3"
          fill={props.color}
        />
      </g>
    </g>
  </svg>
);

export default Person;

Person.defaultProps = {
  color: "#2a3c50",
};

Person.propTypes = {
  color: PropTypes.string,
};
