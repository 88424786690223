import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";

const styles = (theme) => ({
  customBadge: {
    backgroundColor: (props) => props.bgcolor,
    color: (props) => props.textcolor,
  },
});

function SimpleBadge(props, ref) {
  const { classes, component, badgeContent } = props;
  return (
    <>
      <Badge
        classes={{ badge: classes.customBadge }}
        badgeContent={badgeContent}
        ref={ref}
      >
        {component}
      </Badge>
    </>
  );
}

SimpleBadge.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(React.forwardRef(SimpleBadge));
