import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AlertTags from "./alertTags";
import AlertTagsForReturnMedication from "./alertTagsForReturnMedication";
import AlertTagsForIssueMedication from "./alertTagForIssueMedication";
import InfoTags from "./infoTags";
import Tag from "./tag";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-end",
    padding: "0px 12px",
  },
  defaultText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    color: "white",
  },
  boldText: {
    fontWeight: 600,
  },
}));

function DrugCardTags(props) {
  const classes = useStyles();

  // Props
  const { tags = {}, isReturnMedication, issueMedication } = props;

  const alertTags = {
    ...props,
    _key: props?._key,
    elapsedTime: tags?.elapsedTime,
    delay: tags?.delay,
    dispenseStatus: props?.scenarioName,
    clinicalValidation: tags?.alerts?.length > 0,
    alerts: tags?.alerts,
    drugColor: tags?.drugColor,
    orderType: props?.drug?.ordertype,
    orderLineID: props?.drug?._id,
    expiryOnDate: props?.expiryOnDate,
    issuedOnDate: props?.issuedOnDate,
    SlidingScaleDose: props?.SlidingScaleDose,
    prnComment: props?.prnComment,
    selectedState: props?.selectedState,
    isReverify: props?.isReverify
  };

  const infoTags = {
    ...props,
    totalRequiredDose: tags?.totalRequiredDose,
    balanceRequiredDose: tags?.balanceRequiredDose,
    totalAmount: tags?.totalAmount,
  };

  return (
    <div className={classes.root}>
      {issueMedication ? (
        <AlertTagsForIssueMedication {...alertTags} {...props} />
      ) : isReturnMedication ? (
        <AlertTagsForReturnMedication {...alertTags} {...props} />
      ) : (
        <AlertTags {...alertTags} />
      )}

      <div style={{ flex: 1 }}></div>
      {issueMedication ? (
        ""
      ) : isReturnMedication ? (
        <>
          {/* {tags?.totalAmount && (
            <Tag backgroundColor={"#01205C"}>
              <Typography className={classes.defaultText}>
                Total Amount&nbsp;$&nbsp;
                <span className={classes.boldText}>{tags?.totalAmount}</span>
              </Typography>
            </Tag>
          )} */}
        </>
      ) : (
        <InfoTags {...infoTags} />
      )}
    </div>
  );
}

export default DrugCardTags;
