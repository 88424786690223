export const styles = (theme) => ({
  root: {
    // height: "100%",
    flex: 1,
  },
  tableContent: {
    height: "100%", // To make container fill full height
    borderRadius: "8px",
    border: "1px solid #DEE5EC",
    boxShadow: "0px 10px 25px #0000000A",
  },
  tableparent: {
    color: theme.palette.primary.light
  },
  gbfHy: {
    // borderRadius: "0px !important",
    padding: "10px 30px 10px !important",
    backgroundColor: theme.palette.background.tableHeader 
  },
  HKmhv: {
    margin: "0px !important",
  },
});
