import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "..";
import { Modal } from "qdm-component-library";
import {
  Grid,
  Paper,
  Typography,
  Button,
  IconButton,
  Checkbox,
} from "@material-ui/core";

// import { Autocomplete } from "@material-ui/lab";
import qdmstyles from "./styles.json";
import "./index.css";
import { InputBase, Autocomplete } from "..";
import { TrashIcon } from "../../assets";

var aes256 = require("aes256");

function DispenseAndChargeComp(props) {
  const dispatch = useDispatch();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {}, [state]);

  const {
    data,
    index,
    options,
    statevalue = [],
    stateName,
    onAddNewClick = () => {},
    onDeleteClick = () => {},
    setFun = () => {},
  } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"r8lZw"}
            container={""}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            lg={"12"}
            md={"12"}
            sm={"12"}
            xl={"12"}
            xs={"12"}
          >
            <Paper
              key={"0"}
              id={"hVUlX"}
              elevation={"{3}"}
              style={qdmstyles.LtO}
            >
              {/* Add new Row */}
              <Grid
                key={"2"}
                id={"3MSlo"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                item={true}
              >
                <Grid
                  key={"0"}
                  id={"JFZRu"}
                  container={true}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  item={true}
                  lg={"12"}
                  md={"12"}
                  sm={"12"}
                  xl={"12"}
                  xs={"12"}
                  style={qdmstyles.NrEq}
                >
                  <Grid
                    key={"0"}
                    id={"q4xYK"}
                    container={""}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    lg={"10"}
                    md={"10"}
                    sm={"10"}
                    xl={"10"}
                    xs={"10"}
                    item={true}
                  >
                    <Typography
                      key={"0"}
                      id={"gHOTR"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Dispense & Charge"}
                      style={qdmstyles.BguM}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"Xxuio"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                    lg={"2"}
                    md={"2"}
                    sm={"2"}
                    xl={"2"}
                    xs={"2"}
                    style={qdmstyles.KZPj}
                  >
                    <Button
                      key={"0"}
                      id={"dispense_and_charge_add_new_button"}
                      variant={"text"}
                      color={"primary"}
                      children={"+ Add New"}
                      aliasName={"dispenseandchargeaddnewbutton"}
                      style={qdmstyles.ARHq}
                      onClick={() => onAddNewClick(stateName)}
                    ></Button>
                  </Grid>
                </Grid>
                <Grid
                  key={"1"}
                  id={"7V2qa"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item={true}
                  lg={"12"}
                  md={"12"}
                  sm={"12"}
                  xl={"12"}
                  xs={"12"}
                >
                  <Grid
                    key={"0"}
                    id={"LUUaI"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    item={true}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    spacing={1}
                    alignContent={"center"}
                    style={{
                      rowGap: 10,
                    }}
                  >
                    {/* Label Starts */}
                    <Grid
                      key={"0"}
                      id={"0mTZK"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      spacing={"2"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                    >
                      <Grid
                        key={"0"}
                        id={"empYV"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"2"}
                        md={"2"}
                        sm={"2"}
                        xl={"2"}
                        xs={"2"}
                      >
                        <Typography
                          key={"0"}
                          id={"DispenseUOMHeading_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"DispenseUOM"}
                          aliasName={"DispenseUOMHeading"}
                          style={qdmstyles.WFauo}
                        ></Typography>
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"empYV"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"2"}
                        md={"2"}
                        sm={"2"}
                        xl={"2"}
                        xs={"2"}
                      >
                        <Typography
                          key={"0"}
                          id={"IndividualDoseRoundOffHeading_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Individual Dose RoundOff"}
                          aliasName={"IndividualDoseRoundOffHeading"}
                          style={qdmstyles.usxT}
                        ></Typography>
                      </Grid>
                      <Grid
                        key={"2"}
                        id={"empYV"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"2"}
                        md={"2"}
                        sm={"2"}
                        xl={"2"}
                        xs={"2"}
                       
                      >
                        <Typography
                          key={"0"}
                          id={"Round-offTypeHeading_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Round-off Type"}
                          aliasName={"Round-offTypeHeading"}
                          style={qdmstyles.hvwxy}
                        ></Typography>
                      </Grid>
                      <Grid
                        key={"3"}
                        id={"empYV"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"2"}
                        md={"2"}
                        sm={"2"}
                        xl={"2"}
                        xs={"2"}
                      >
                        <Typography
                          key={"0"}
                          id={"Round-offValueFormHeading_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Round-off Value"}
                          aliasName={"Round-offValueFormHeading"}
                          style={qdmstyles.YeGM}
                        ></Typography>
                      </Grid>
                    </Grid>
                    {/* Label Ends */}

                    {/* Input Starts */}
                    {Array.isArray(statevalue) &&
                      statevalue?.map((item, index) => {
                        const rowState = statevalue?.[index];
                        return (
                          <Grid
                            key={"1"}
                            id={"fVDRS"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            spacing={"2"}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                          >
                            <Grid
                              key={"0"}
                              id={"empYV"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"2"}
                              md={"2"}
                              sm={"2"}
                              xl={"2"}
                              xs={"2"}
                            >
                              <Autocomplete
                                id={"OqVny"}
                                style={qdmstyles?.OqVny}
                                getOptionLabel={(option) => option?.["label"]}
                                options={options?.drug_uom ?? []}
                                onLoad={() =>
                                  setFun(
                                    stateName,
                                    "dispUOM",
                                    rowState?.dispUOM,
                                    index
                                  )
                                }
                                value={rowState?.dispUOM ?? null}
                                onChange={(event, newValue) =>
                                  setFun(stateName, "dispUOM", newValue, index)
                                }
                              />
                            </Grid>
                            <Grid
                              key={"1"}
                              id={"empYV"}
                              container={true}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"2"}
                              md={"2"}
                              sm={"2"}
                              xl={"2"}
                              xs={"2"}
                            >
                              <Checkbox
                                key={"0"}
                                id={"B7nCd"}
                                color={"default"}
                                size={"small"}
                                checked={rowState?.individualDoseRound}
                                onChange={(event) =>
                                  setFun(
                                    stateName,
                                    "individualDoseRound",
                                    event.target.checked,
                                    index
                                  )
                                }
                                inputProps={{
                                  "aria-label": "Individual Dose Round Off",
                                }}
                                style={qdmstyles.jDvDk}
                              ></Checkbox>
                            </Grid>
                            <Grid
                              key={"2"}
                              id={"empYV"}
                              container={true}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"2"}
                              md={"2"}
                              sm={"2"}
                              xl={"2"}
                              xs={"2"}
                            >
                              <Autocomplete
                                id={"OqVny"}
                                style={qdmstyles?.OqVny}
                                getOptionLabel={(option) => option?.["label"]}
                                options={options?.round_off_type ?? []}
                                onLoad={() =>
                                  setFun(
                                    stateName,
                                    "dispRoundingType",
                                    rowState?.dispRoundingType,
                                    index
                                  )
                                }
                                value={rowState?.dispRoundingType ?? null}
                                onChange={(event, newValue) =>
                                  setFun(
                                    stateName,
                                    "dispRoundingType",
                                    newValue,
                                    index
                                  )
                                }
                              />
                            </Grid>
                            <Grid
                              key={"3"}
                              id={"empYV"}
                              container={true}
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              item={true}
                              lg={"3"}
                              md={"3"}
                              sm={"3"}
                              xl={"3"}
                              xs={"3"}
                          
                            >
                              <Grid
                                key={"0"}
                                id={"empYV"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={"10"}
                                md={"10"}
                                sm={"10"}
                                xl={"10"}
                                xs={"10"}
                              >
                                <InputBase
                                  key={"0"}
                                  id={"DispRoundingValinput4_textfield"}
                                  label={""}
                                  placeholder={""}
                                  type={"text"}
                                  aliasName={"DispRoundingValinput4"}
                                  style={qdmstyles.PWtn}
                                  value={rowState?.DispRoundingVal}
                                  onChange={(event) =>
                                    setFun(
                                      stateName,
                                      "DispRoundingVal",
                                      event.target.value,
                                      index
                                    )
                                  }
                                ></InputBase>
                              </Grid>
                              <Grid
                                key={"1"}
                                id={"empYV"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={"2"}
                                md={"2"}
                                sm={"2"}
                                xl={"2"}
                                xs={"2"}
                                style={qdmstyles.VFi}
                              >
                                <IconButton
                                  onClick={() =>
                                    onDeleteClick(stateName, index)
                                  }
                                >
                                  <TrashIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}

                    {/* Input Ends */}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(DispenseAndChargeComp));
