import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Grid, Typography, TextField, Button } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
var aes256 = require("aes256");

function OrderRoutingComponent(props) {
  const dispatch = useDispatch();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {}, [state]);

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"gURfx"}
            container={true}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid
              key={"0"}
              id={"8i9n4"}
              container={true}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              aliasName={"performingDetails"}
              style={qdmstyles.in}
            >
              <Grid
                key={"0"}
                id={"6wBxd"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                aliasName={"performingDiv"}
                style={qdmstyles.wBxd}
              >
                <Typography
                  key={"0"}
                  id={"874cz"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"PERFORMING DETAILS"}
                  style={qdmstyles.cz}
                ></Typography>
                <Grid
                  key={"1"}
                  id={"L7OA3"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  aliasName={"performingForm"}
                  spacing={"2"}
                >
                  <Grid
                    key={"0"}
                    id={"rf214"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    aliasName={"performingFacility"}
                    xs={"4"}
                    item={true}
                  >
                    <Typography
                      key={"0"}
                      id={"JpfaA"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Performing Facility"}
                      style={qdmstyles.JpfaA}
                    ></Typography>
                    <TextField
                      key={"1"}
                      id={"DtVAe"}
                      label={""}
                      placeholder={""}
                      type={"text"}
                      variant={"outlined"}
                      name={"performingFacility"}
                      size={"small"}
                      fullWidth={true}
                      disabled={false}
                      style={qdmstyles.DtVAe}
                    ></TextField>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"rf214"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    aliasName={"performingFacility"}
                    xs={"4"}
                    item={true}
                  >
                    <Typography
                      key={"0"}
                      id={"JpfaA"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Performing Location Type"}
                      style={qdmstyles.QxjqZ}
                    ></Typography>
                    <TextField
                      key={"1"}
                      id={"DtVAe"}
                      label={""}
                      placeholder={""}
                      type={"text"}
                      variant={"outlined"}
                      name={"performingLocationType"}
                      size={"small"}
                      fullWidth={true}
                      disabled={false}
                      style={qdmstyles.uBLou}
                    ></TextField>
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"rf214"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    aliasName={"performingFacility"}
                    xs={"4"}
                    item={true}
                  >
                    <Typography
                      key={"0"}
                      id={"JpfaA"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Performing Location"}
                      style={qdmstyles.LtDQK}
                    ></Typography>
                    <TextField
                      key={"1"}
                      id={"DtVAe"}
                      label={""}
                      placeholder={""}
                      type={"text"}
                      variant={"outlined"}
                      name={"performingLocation"}
                      size={"small"}
                      fullWidth={true}
                      disabled={false}
                      style={qdmstyles.MdroL}
                    ></TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                key={"1"}
                id={"8i9iw"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                aliasName={"performingContents"}
                style={qdmstyles.iiw}
              >
                <Grid
                  key={"0"}
                  id={"N1D0i"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Typography
                    key={"0"}
                    id={"ryHjK"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"SERVICE APPLICABILITY"}
                    style={qdmstyles.ryHjK}
                  ></Typography>
                  <Grid
                    key={"1"}
                    id={"y1Y7s"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                  >
                    <Button
                      key={"0"}
                      id={"bzyHU"}
                      variant={"contained"}
                      color={"primary"}
                      children={"Service Order"}
                      disabled={true}
                      style={qdmstyles.bzyHU}
                    ></Button>
                    <Button
                      key={"1"}
                      id={"fmfuQ"}
                      variant={"contained"}
                      color={"primary"}
                      children={"Medication"}
                      style={qdmstyles.fmfuQ}
                    ></Button>
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"pGfHO"}
                    container={true}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    aliasName={"medicationSelection"}
                    style={qdmstyles.pGfHO}
                  >
                    <Grid
                      key={"0"}
                      id={"AVtfC"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <Typography
                        key={"0"}
                        id={"TA2ko"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"MEDICATION SELECTION CRITERIA"}
                        style={qdmstyles.TAko}
                      ></Typography>
                      <Grid
                        key={"1"}
                        id={"rJpEh"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <Button
                          key={"0"}
                          id={"Nxg7E"}
                          variant={"contained"}
                          color={"primary"}
                          children={"Add New Rule Set"}
                          style={qdmstyles.NxgE}
                        ></Button>
                        <Typography
                          key={"1"}
                          id={"jiYFh"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"(or)"}
                          style={qdmstyles.jiYFh}
                        ></Typography>
                        <Button
                          key={"2"}
                          id={"Nxg7E"}
                          variant={"contained"}
                          color={"primary"}
                          children={"Add Existing Rule Set"}
                          style={qdmstyles.wAUjU}
                        ></Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    key={"3"}
                    id={"fPxBr"}
                    container={true}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid
                      key={"0"}
                      id={"qoej0"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      style={qdmstyles.qoej}
                    >
                      <Grid
                        key={"0"}
                        id={"rUYS3"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        spacing={"2"}
                      >
                        <Grid
                          key={"0"}
                          id={"ztsGm"}
                          container={true}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Grid
                            key={"0"}
                            id={"5rxan"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            xs={"6"}
                          >
                            <Typography
                              key={"0"}
                              id={"avTRF"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"ORDERING DETAILS"}
                              style={qdmstyles.avTRF}
                            ></Typography>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"joK0o"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                            item={true}
                            xs={"6"}
                          >
                            <Button
                              key={"0"}
                              id={"3a4iK"}
                              variant={"text"}
                              color={"primary"}
                              children={"+ Add New"}
                              style={qdmstyles.aiK}
                            ></Button>
                          </Grid>
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"nckvJ"}
                          container={false}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          xs={"3"}
                        >
                          <Typography
                            key={"0"}
                            id={"4q4CE"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Ordering Facility *"}
                            style={qdmstyles.qCE}
                          ></Typography>
                          <TextField
                            key={"1"}
                            id={"M3Keo"}
                            label={""}
                            placeholder={"Select"}
                            type={"text"}
                            variant={"outlined"}
                            size={"small"}
                            fullWidth={true}
                            name={"orderFacility"}
                            required={true}
                          ></TextField>
                        </Grid>
                        <Grid
                          key={"2"}
                          id={"nckvJ"}
                          container={false}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          xs={"3"}
                        >
                          <Typography
                            key={"0"}
                            id={"4q4CE"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Location Type *"}
                            style={qdmstyles.awaD}
                          ></Typography>
                          <TextField
                            key={"1"}
                            id={"M3Keo"}
                            label={""}
                            placeholder={"Select"}
                            type={"text"}
                            variant={"outlined"}
                            size={"small"}
                            fullWidth={true}
                            name={"locationType"}
                            required={true}
                          ></TextField>
                        </Grid>
                        <Grid
                          key={"3"}
                          id={"nckvJ"}
                          container={false}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          xs={"3"}
                        >
                          <Typography
                            key={"0"}
                            id={"4q4CE"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Ordering Location"}
                            style={qdmstyles.UAb}
                          ></Typography>
                          <TextField
                            key={"1"}
                            id={"M3Keo"}
                            label={""}
                            placeholder={"Select"}
                            type={"text"}
                            variant={"outlined"}
                            size={"small"}
                            fullWidth={true}
                            name={"orderLocation"}
                            required={false}
                            select={false}
                          ></TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(OrderRoutingComponent));
