import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import TypographyWithIcon from "./typographyWithIcon";
import {
  EstimateIcon,
  HoldingHandIcon,
  PillIcon,
  PrinterIcon,
  ReturnPolicyIcon,
  SupplementDoseIcon,
  SupplementTotalDoseIcon,
  TimesheetIcon,
} from "../../../assets";
import { ReactComponent as CollapseIcon } from "../../../assets/collapse.svg";
import { ReactComponent as ExpandIcon } from "../../../assets/expand.svg";
import Dot from "../../dot";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogTitle, InputBase, Tooltip } from "@material-ui/core";
import {
  bindHover,
  bindPopover,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { StyledPopover } from "../../commonComponents";
import { Directions } from "../popovers";
import { EditInstructions } from "../..";
import PlaylistAddOutlinedIcon from "@material-ui/icons/PlaylistAddOutlined";
import CalendarViewDayOutlinedIcon from "@material-ui/icons/CalendarViewDayOutlined";
import { useReturnPolicyDialogStyles } from "../../drugItem/components/drugCard/drugBrandTableForReturnMedication/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { DialogContent } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useDividerStyles } from "../../patientProfile/styles";
import classNames from "classnames";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useSelector } from "react-redux";

const StripInput = withStyles((theme) => ({
  root: {
    flex: 0,
    gap: 10,
    padding: "4px 8px",
    background: "white",
    borderRadius: "4px",
    border: "1px solid #DEE5EC",
    "& .App1-MuiInputBase-input": {
      minWidth: 20,
    },
  },
  input: {
    padding: 0,
    "&:focus": {
      backgroundColor: "unset",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    background: "#F1F6FC",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.75),
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1.25, 1.25, 0, 0),
  },
  expandCollapseButton: {
    position: "absolute",
    zIndex: 1,
    bottom: "-10px",
    left: "5px",
  },
  returnFieldContainer: {
    display: "flex",
    gap: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  returnFieldLabel: {
    fontSize: "14px",
    color: "#2A3C50",
  },
  returnField: {
    maxWidth: "100px",
    background: "white",
  },
  returnPolicyDialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  returnPolicyDialogTitleText: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  returnPolicyTitleText: {
    color: "#001C3C",
    fontSize: 14,
    fontWeight: 600,
  },
  returnPolicyDialogCloseButton: {
    background: "rgba(1, 32, 92, 0.1)",
    "&:hover": {
      background: "rgba(1, 32, 92, 0.1)",
    },
    "& .App1-MuiIconButton-label": {
      color: "rgba(1, 32, 92, 1)",
      "& .App1-MuiSvgIcon-root": {
        fontSize: "1.2rem",
      },
    },
  },
  actionContainer: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  doseDetailItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
  },
  stripQuantity: {
    cursor: "pointer",
    flex: 0,
  },
  stripFielAdornText: {
    // color: "#B4BBC3",
    fontSize: 12,
    fontWeight: 400,
    whiteSpace: "nowrap",
    lineHeight: 1,
  },
}));
const useCustomTooltip = makeStyles({
  tooltip: {
    backgroundColor: "#485C74",
    fontSize: 12,
    fontWeight: 500,
  },
});

function DrugParticular(props) {
  // <---------------Hooks------------------>
  const [editDirecton, setEditDirection] = React.useState(false);
  const classes = useStyles();
  const tooltipStyles = useCustomTooltip();
  const directionsPopupState = usePopupState({
    variant: "popover",
    popupId: "directions",
  });

  // <---------------Store States----------->

  // <-------------Component States--------->

  // <-----------------Handlers------------->

  // <-----------------Effects-------------->

  const {
    drug,
    type,
    disptype,
    drugName,
    Strength,
    singleStrength,
    dosageUOM,
    ivInfusionRate,
    drugType,
    doseCount,
    tabPerDose,
    administrativeDose,
    actualdoseCount,
    requiredDose,
    totalQuantity,
    issueQuantity,
    billQuantity,
    direction,
    nlDirection,
    isVerficationState,
    brand
  } = props;
  const [printopen, setPrintOpen] = React.useState(false);

  const binderData = useSelector(
    (state) => state?.phramcyFrameworkApiSlice?.label_print?.data
  );

  const { isCollapsed, handleCollapseToggle } = props;

  const returnPolicyDialogStyles = useReturnPolicyDialogStyles();
  const dividerStyles = useDividerStyles();

  React.useEffect(() => {
    props.setisShow(props.isShowMulti);
  }, [props.isShowMulti]);

  const [returnPolicyOpen, setReturnPolicyOpen] = React.useState(false);
  const [isStripTextfield, setIsStripTextfield] = React.useState(false);

  const handleReturnPolicyOpen = () => {
    setReturnPolicyOpen(true);
  };

  const handleReturnPolicyClose = () => {
    setReturnPolicyOpen(false);
  };

  const handlePrint = () => {
    setPrintOpen(true);
    props.printLabel();
  };

  const handleClose = () => {
    setPrintOpen(false);
  };


  return (
    <div className={classes.root}>

      <Dialog
        open={printopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.pdfDialogPaper }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Label Print Preview"}
        </DialogTitle>
        <DialogContent>
          {/* <div>{binderData.downloadUrl}</div> */}
            <Document file={binderData.downloadUrl}>
              <Page pageNumber={1} />
            </Document>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton
        size="small"
        disableRipple
        onClick={handleCollapseToggle}
        className={classes.expandCollapseButton}
      >
        {isCollapsed ? <CollapseIcon /> : <ExpandIcon />}
      </IconButton>
      <Typography variant="body2">{drugName}</Typography>
      <Dot />
      <Typography variant="body2">
        {drugType !== "DISPOSABLE" && (
          <>
            {(Strength || [])?.map((v, i) => {
              return (
                <>
                  {v}
                  {i !== Strength?.length - 1 ? `/ ` : ``}
                </>
              );
            })}
            &nbsp; {dosageUOM}
          </>
        )}
      </Typography>
      {
        <>
          {type === "IV" && ivInfusionRate && (
            <Typography variant="body2">{ivInfusionRate}</Typography>
          )}
        </>
      }
      {doseCount && drugType !== "DISPOSABLE" && (
        <Tooltip title={type === "IV" ? "Rx Dose" : "Total Quantity"} arrow>
          <>
            <Dot />
            <TypographyWithIcon startAdornment={<SupplementDoseIcon />}>
              {doseCount} Doses
            </TypographyWithIcon>
          </>
        </Tooltip>
      )}
      <Dot />
      {tabPerDose && drugType !== "DISPOSABLE" && (
        <Tooltip
          title={
            type === "IV" ? "Administrative Dose Quantity" : "Per Dose Quantity"
          }
          arrow
        >
          <>
            <Dot />
            <TypographyWithIcon startAdornment={<SupplementTotalDoseIcon />}>
              {disptype === "IV-LV" ? singleStrength + " " : administrativeDose}
              per dose
            </TypographyWithIcon>
          </>
        </Tooltip>
      )}
      {actualdoseCount && (
        <Tooltip
          title={
            disptype === "IV-LV" ? "Required Dose Quantity" : "Issue Quantity"
          }
          arrow
        >
          <>
            <Dot />
            <TypographyWithIcon startAdornment={<PillIcon />}>
              {requiredDose}
            </TypographyWithIcon>
          </>
        </Tooltip>
      )}
      {disptype === "IV-LV" && totalQuantity && (
        <Tooltip title="Total Quantity" arrow>
          <>
            <Dot />
            <TypographyWithIcon startAdornment={<HoldingHandIcon />}>
              {totalQuantity}
            </TypographyWithIcon>
          </>
        </Tooltip>
      )}
      {/* <Tooltip title="Issue Quantity" arrow>
        <>
          <Dot />
          <TypographyWithIcon startAdornment={<EstimateIcon />}>
            {issueQuantity}
          </TypographyWithIcon>
        </>
      </Tooltip> */}
      <Tooltip title="Issue Quantity" arrow classes={tooltipStyles}>
        <div
          className={classNames(
            classes.doseDetailItem,
            classes.stripQuantity
          )}
          onClick={(e) => {
            setIsStripTextfield((prev) => !prev);
          }}
        >
          <HoldingHandIcon style={{ minWidth: "17.705px" }} />
          {(isStripTextfield
            && props?.drug?.disptype?.code === "B"
          ) ? (
            <>
              <StripInput
                autoFocus
                id={"stripInput"}
                placeholder="00"
                type={"text"}
                //value={batchQuantity}
                value={brand?.totstkqty}
                // disabled
                onChange={(e) => {
                  props.issueQuantityFuncy(e.target.value);
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                endAdornment={
                  <Typography className={classes.stripFielAdornText}>
                    {brand?.totstkuom}
                  </Typography>
                }
                inputProps={{ "aria-label": "Input field" }}
              />
              {/* <Typography
          style={{ display: "inline" }}
          className={classes.defaultText}
        >
          ( {brand?.totadmqty} {brand?.totadmuom})
        </Typography> */}
            </>
          ) : (
            <Typography className={classes.defaultText}>
              {issueQuantity}
            </Typography>
          )}
        </div>
      </Tooltip>
      <Tooltip title="Bill Quantity" arrow>
        <>
          <Dot />
          <TypographyWithIcon startAdornment={<EstimateIcon />}>
            {billQuantity}
          </TypographyWithIcon>
        </>
      </Tooltip>
      <Dot />
      <TimesheetIcon {...bindHover(directionsPopupState)} />
      {/* Directions */}
      <StyledPopover renderHoverPopover {...bindPopover(directionsPopupState)}>
        <Directions
          isVerficationState={isVerficationState}
          handleEditClick={() => {
            setEditDirection(true);
          }}
          directions={[direction, nlDirection]}
        />
      </StyledPopover>
      <EditInstructions
        directionDialog={editDirecton}
        handleEditDirectionsClose={() => {
          setEditDirection(false);
        }}
        directionDescripion={direction}
        directionLocalLanguage={nlDirection}
        handleDirectionSaveClick={(e) => props.handleDirectionSaveClick(e)}
      />
      {props?.isMultiBatch
        && brand?.batches?.length > 0 && !props?.isCardVerify && (
          <Tooltip
            title={
              props.isShow ? "Show only allocated batches" : "Show all batches"
            }
            arrow
            classes={tooltipStyles}
          >
            <div
              style={{ marginTop: "4px" }}
              onClick={() =>
                props.handleMultiBatch(
                  props.isIntravenous
                    ? "additives"
                    : props.isCompound
                      ? "compounds"
                      : "brands",
                  props.isShow
                )
              }
            >
              {props.isShow ? (
                <CalendarViewDayOutlinedIcon
                  style={{ fontSize: 17, marginLeft: 6 }}
                />
              ) : (
                <PlaylistAddOutlinedIcon
                  style={{ fontSize: 19, marginLeft: 6 }}
                />
              )}
            </div>
          </Tooltip>
        )}

      {props.isReturnMedication && <div
        className={classes.returnPolicyIconContainer}
        onClick={handleReturnPolicyOpen}
      >
        <ReturnPolicyIcon />
      </div>}

      {!props.isCompound && <Tooltip title="Print Label" arrow>
        <div
          style={{ marginLeft: "10px", marginTop: "4px" }}
          onClick={() => handlePrint()}
        >
          <PrinterIcon />
        </div>
      </Tooltip>}

      <Dialog
        onClose={handleReturnPolicyClose}
        aria-labelledby="return-policy-dialog"
        open={returnPolicyOpen}
        classes={returnPolicyDialogStyles}
      >
        <MuiDialogTitle
          disableTypography
          id="customized-dialog-title"
          onClose={handleReturnPolicyClose}
          className={classes.returnPolicyDialogTitle}
        >
          <div className={classes.returnPolicyDialogTitleText}>
            <ReturnPolicyIcon color="#2A3C50" />
            <Typography className={classes.returnPolicyTitleText}>
              RETURN GUIDELINES/ POLICY
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            className={classes.returnPolicyDialogCloseButton}
            size="small"
            onClick={handleReturnPolicyClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent dividers>
          {/* <div dangerouslySetInnerHTML={}></div> */}
          {/* <div dangerouslySetInnerHTML={{ __html: "<div>Hello</div>" }} /> */}
          <div>{props?.returnPolicy ?? "NO RETURN GUIDELINES/ POLICY"}</div>
        </DialogContent>
      </Dialog>
      {(props.isReturnMedication && props.issueMedication) && <Divider orientation="vertical" flexItem classes={dividerStyles} />}
      {(props.isReturnMedication && props.issueMedication) && <div className={classes.actionContainer}>
        <div className={classes.returnFieldContainer}>
          <Typography className={classes.returnFieldLabel}>{props.issueMedication ? "ReIssue" : "Return"}</Typography>
          <div
            className={classes.returnField}
            style={{
              padding: 6,
              borderRadius: 4,
            }}
          >
            {props.isEdit && props.isReturnMedicationEnable ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <StripInput
                  style={{ width: "40px" }}
                  value={brand?.totstkqty}
                  onChange={(e) =>
                    props.onBatchQuantityChangeDrug(e.target.value, true)
                  }
                />
                &nbsp;
                {brand?.totstkuom}
              </div>
            ) : (
              <Typography className={classes.defaultText}>
                {brand?.totstkqty}&nbsp;
                {brand?.totstkuom}
              </Typography>
            )}

            {/* <StripInput
                style={{ width: "40px" }}
                value={batch?.chargeqty}
                onChange={(e) => onBatchQuantityChange(e.target.value)}
              />
              {drug?.returnuom} */}
            {/* {/* <TextSelectField
                disabled={false}
                inputValue={""}
                onInputValueChange={(e) => {}}
                selectValue={null}
                onSelectValueChange={(e) => {}}
                options={[]} */}
            {/* /> */}
          </div>
        </div>

        {/* <div className={classes.replyIconContainer} onClick={() => {}}>
            <ReplyArrowIcon />
          </div> */}
      </div>}
    </div>
  );
}

export default DrugParticular;

DrugParticular.propTypes = {
  isCollapsed: PropTypes.bool,
  handleCollapseToggle: PropTypes.func.isRequired,
};

DrugParticular.defaultProps = {
  isCollapsed: false,
  handleCollapseToggle: () => { },
};

// AddIcon,
//   DollarIcon,
//   //DrugPrint,
//   EstimateIcon,
//   GroceryShelfIcon,
//   HoldingHandIcon,
//   HoldingHandWithPillIcon,
//   InfoIcon,
//   NoAccessIcon,
//   PillIcon,
//   ScheduleIcon,
//   SchoolIcon,
//   StackIcon,
//   StackOverflowIcon,
//   SupplementDoseIcon,
//   SupplementTotalDoseIcon,
//   TimesheetIcon,
//   TrashIcon,
