const styles = (theme) => ({
  container: {
    height: "100%",
  },
  topBarContainer: {
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.background.tableHeader,
  },
  formTitleContainer: {
    gap: theme.spacing(1),
  },
  backicon: {
    width: 34,
    height: 34,
    backgroundColor: "#DEE5EC",
    "&$hover": {
      backgroundColor: "#DEE5EC",
    },
  },
  formTitle: {
    fontWeight: "600",
  },
  actionButtonsContainer: {
    gap: theme.spacing(1),
  },
  paper: {
    margin: 20,
    padding: 24,
  },
  directionPaper: {
    margin: 20,
  },
  formControlLabel: {
    fontSize: "0.875rem",
    whiteSpace: "nowrap",
  },
});

export default styles;
