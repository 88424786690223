import React from "react";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { LoggerContext } from "../../contexts";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Box, Typography, Avatar, makeStyles } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
var aes256 = require("aes256");


const Styles = makeStyles((theme) => ({
  root: {
    // background: `${theme.palette.background.common} !important`,
  },
}));

function AllergyInfo(props) {
  const dispatch = useDispatch();
  const classes = Styles();
  const contextType = React.useContext(LoggerContext);
  const { propsState, openBody, activeAllergy } = props;

  const [state, setState] = React.useState({
    permissionData: null,
  });

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  const Allergydata = () => {
    if (activeAllergy?.value === "All") {
      return propsState;
    } else {
      return propsState?.filter(
        (val) => val?.clinicalstatus === activeAllergy?.value
      );
    }
  };

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          {Allergydata().length > 0 ? (
            <>
              {Allergydata()?.map((item, index) => {
                return (
                  <Grid
                    key={item?._key}
                    id={"allergy_info"}
                    container={true}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    aliasName={"allergyInfo"}
                    style={qdmstyles.epBpx}
                    className={classes.root}
                  >
                    <Grid
                      id={"allergy_contents"}
                      container={false}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      item={true}
                      lg={"11"}
                      md={"11"}
                      sm={"11"}
                      xs={"11"}
                      aliasName={"allergyContents"}
                    >
                      <Box
                        id={"allergy_header_div"}
                        m={"1"}
                        component={"div"}
                        aliasName={"allergyHeaderDiv"}
                        style={qdmstyles.CfLf}
                      >
                        <Box
                          id={"alergy_status_div"}
                          m={"1"}
                          component={"div"}
                          aliasName={"allergyStatusDiv"}
                          style={{
                            ...qdmstyles.DEjk,
                            // backgroundColor: openBody ? "#CDF0C1" : "#fff",
                          }}
                        >
                          <Box
                            id={"dot"}
                            m={"1"}
                            component={"div"}
                            aliasName={"dot"}
                            style={{
                              ...qdmstyles.lLy,
                              // backgroundColor: activeAllergy?.color,
                            }}
                          ></Box>
                        </Box>
                        <Typography
                          key={"1"}
                          id={"vC57G"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={item?.shortdesc}
                          style={qdmstyles.vCG}
                        ></Typography>
                      </Box>
                      {openBody && (
                        <Box
                          key={"1"}
                          id={"allergy_composition_div"}
                          m={"1"}
                          component={"div"}
                          aliasName={"allergyCompositionDiv"}
                          style={qdmstyles.kbNJf}
                        >
                          <Box
                            id={"allergy_severity_div"}
                            m={"1"}
                            component={"div"}
                            aliasName={"allergySeverityDiv"}
                            style={qdmstyles.BMElU}
                          >
                            <Box
                              id={"eyqL2"}
                              m={"1"}
                              component={"div"}
                              style={qdmstyles.eyqL}
                            >
                              <Avatar
                                id={"severity_logo"}
                                alt={"Avatar"}
                                variant={"rounded"}
                                aliasName={"severityLogo"}
                                src={
                                  "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317076983"
                                }
                                style={qdmstyles.vRJHy}
                              ></Avatar>
                            </Box>
                            <Typography
                              key={"1"}
                              id={"severity"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={item?.severity}
                              aliasName={"severity"}
                              style={qdmstyles.dbBqu}
                            ></Typography>
                          </Box>
                          <Box
                            key={"1"}
                            id={"allergy_severity_div"}
                            m={"1"}
                            component={"div"}
                            aliasName={"allergySeverityDiv"}
                            style={qdmstyles.bvxb}
                          >
                            <Box id={"JdMbe"} m={"1"} component={"div"}>
                              <Avatar
                                id={"food_logo"}
                                alt={"Avatar"}
                                variant={"rounded"}
                                aliasName={"foodLogo"}
                                src={
                                  "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317077803"
                                }
                                style={qdmstyles.PNodB}
                              ></Avatar>
                            </Box>
                            <Typography
                              key={"1"}
                              id={"severity"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={item?.type}
                              aliasName={"severity"}
                              style={qdmstyles.rfk}
                            ></Typography>
                          </Box>
                          <Box
                            key={"2"}
                            id={"dot"}
                            m={"1"}
                            component={"div"}
                            aliasName={"dot"}
                            style={qdmstyles.oqPb}
                          ></Box>
                          <Typography
                            key={"3"}
                            id={"allergy_condition"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={item?.criticality}
                            aliasName={"allergyCondition"}
                            style={qdmstyles.zGK}
                          ></Typography>
                        </Box>
                      )}
                    </Grid>
                    <Grid
                      key={"1"}
                      id={"allergy_edit"}
                      container={false}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      item={true}
                      lg={"1"}
                      md={"1"}
                      sm={"1"}
                      xs={"1"}
                      aliasName={"allergyEdit"}
                    >
                      {openBody && (
                        <Box
                          id={"pen_box"}
                          m={"1"}
                          component={"div"}
                          aliasName={"penBox"}
                        >
                          <Avatar
                            id={"edit_allergy"}
                            alt={"Avatar"}
                            variant={"rounded"}
                            aliasName={"editAllergy"}
                            src={
                              "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316716564"
                            }
                            style={qdmstyles.PkHzC}
                          ></Avatar>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                paddingBottom: "10px", 
              }}
            >
              No Data Found
            </div>
          )}
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(AllergyInfo));
