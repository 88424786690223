import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { frequencyTypes } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import DayBased from "./dayBased";
import Detail from "./detail";
import IntervalSequential from "./IntervalSequential";
import UserDefined from "./UserDefined";

const {
  EditNewFrequency_orgbase_frequency_dropdown,
  schedule_line_day_dropdown,
} = actions;

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
  },
}));

function ScheduleLine({
  isView,
  type,
  handleChangeState,
  dayBasedSchedule,
  detailSchedule,
  intervalSchedule,
  sequentialSchedule,
  userDefinedSchedule,
  handleAddNewClick = () => {},
  handleDeleteClick = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Store States-----------------------------------------------------
  const baseFrequencyResponse = useSelector(
    (state) =>
      state?.EditNewFrequency_orgbase_frequency_dropdownReducer
        ?.base_frequency_dropdown?.data?.result
  );
  const baseFrequencyOptions =
    Array.isArray(baseFrequencyResponse) &&
    baseFrequencyResponse?.map((v) => {
      return {
        ...v,
        display: v?.freqcode + " " + v?.shortdesc,
      };
    });
  const dayOptions = useSelector(
    (state) =>
      state?.schedule_line_day_dropdownReducer?.schedule_line_day?.data?.result
  );

  const selectedDays = dayBasedSchedule?.map((item) => item?.day?._id);

  // Handlers------------------------------------------------------------
  const loadOptions = async () => {
    await dispatch(EditNewFrequency_orgbase_frequency_dropdown());
    await dispatch(schedule_line_day_dropdown());
  };

  // LifeCycle------------------------------------------------------------
  useEffect(() => {
    loadOptions();
  }, []);

  // Render---------------------------------------------------------------
  return (
    <div>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems="center"
        mb={type?._id ? 2 : 0}
      >
        <Typography variant="body2" className={classes.title}>
          SCHEDULE LINES
        </Typography>
        {type?._id &&
          type?._id !== frequencyTypes.interval &&
          type?._id !== frequencyTypes.sequential && (
            <Button
              color="primary"
              disabled={isView}
              onClick={() => handleAddNewClick(type?._id)}
            >
              + Add New
            </Button>
          )}
      </Box>

      {/* Day Based Schedule */}
      {type?._id === frequencyTypes.dayBased && (
        <>
          {dayBasedSchedule?.map((schedule, index) => (
            <DayBased
              key={index}
              index={index}
              isView={isView}
              day={schedule?.day}
              basefreq={schedule?.basefreq}
              remarks={schedule?.remarks}
              handleChangeState={(key, value) =>
                handleChangeState("dayBasedSchedule", key, value, index)
              }
              baseFrequencyOptions={baseFrequencyOptions}
              dayOptions={dayOptions?.filter(
                (option) => !selectedDays?.includes(option?._id)
              )}
              handleDeleteClick={() => handleDeleteClick(type?._id, index)}
            />
          ))}
        </>
      )}

      {/* Detail Schedule */}
      {type?._id === frequencyTypes.detail && (
        <>
          {detailSchedule?.map((schedule, index) => (
            <Detail
              key={index}
              index={index}
              isView={isView}
              time={schedule?.time}
              remarks={schedule?.remarks}
              handleChangeState={(key, value) =>
                handleChangeState("detailSchedule", key, value, index)
              }
              handleDeleteClick={() => handleDeleteClick(type?._id, index)}
            />
          ))}
        </>
      )}

      {/* Interval Schedule */}
      {type?._id === frequencyTypes.interval && (
        <>
          {intervalSchedule?.map((schedule, index) => (
            <IntervalSequential
              key={index}
              isView={isView}
              basefreq={schedule?.basefreq}
              remarks={schedule?.remarks}
              handleChangeState={(key, value) =>
                handleChangeState("intervalSchedule", key, value, index)
              }
              baseFrequencyOptions={baseFrequencyOptions}
            />
          ))}
        </>
      )}

      {/* Sequential Schedule */}
      {type?._id === frequencyTypes.sequential && (
        <>
          {sequentialSchedule?.map((schedule, index) => (
            <IntervalSequential
              key={index}
              isView={isView}
              basefreq={schedule?.basefreq}
              remarks={schedule?.remarks}
              handleChangeState={(key, value) =>
                handleChangeState("sequentialSchedule", key, value, index)
              }
              baseFrequencyOptions={baseFrequencyOptions}
            />
          ))}
        </>
      )}

      {/* User Defined Schedule */}
      {type?._id === frequencyTypes.userDefined && (
        <>
          {userDefinedSchedule?.map((schedule, index) => (
            <UserDefined
              key={index}
              index={index}
              isView={isView}
              fromDay={schedule?.fromDay}
              toDay={schedule?.toDay}
              basefreq={schedule?.basefreq}
              remarks={schedule?.remarks}
              handleChangeState={(key, value) =>
                handleChangeState("userDefinedSchedule", key, value, index)
              }
              baseFrequencyOptions={baseFrequencyOptions}
              handleDeleteClick={() => handleDeleteClick(type?._id, index)}
            />
          ))}
        </>
      )}
    </div>
  );
}

export default ScheduleLine;
