import * as React from "react";
import PropTypes from "prop-types";

const Identity = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.32"
    height="9.266"
    viewBox="0 0 14.32 9.266"
  >
    <path
      id="icons8-identity"
      d="M9.7,8a1.728,1.728,0,0,0-1.033.307L6.875,9.544H1.343A1.374,1.374,0,0,0,0,10.934v4.942a1.374,1.374,0,0,0,1.343,1.39H9.1a1.317,1.317,0,0,0,.963-.427l.3.113-.017-.007a4,4,0,0,0,1.566.32h1.96a.456.456,0,0,0,.448-.463V11.4a.469.469,0,0,0-.179-.37l-3.426-2.66h0A1.735,1.735,0,0,0,9.7,8Zm-.024.925a.859.859,0,0,1,.5.183l3.247,2.521v4.71H11.913a3.127,3.127,0,0,1-1.225-.25l-.017-.007-3.8-1.428h0a.566.566,0,0,1-.322-.708.54.54,0,0,1,.661-.369l.01,0,3.57.894a.437.437,0,0,0,.441-.123.475.475,0,0,0,.108-.459.454.454,0,0,0-.337-.318l-.553-.139v-2.5A1.374,1.374,0,0,0,9.1,9.544H8.489l.675-.466A.856.856,0,0,1,9.676,8.925ZM1.343,10.471H6.95a.432.432,0,0,0,.114,0H9.1a.449.449,0,0,1,.448.463V13.21l-2.113-.53h0a1.441,1.441,0,0,0-1.74.971,1.516,1.516,0,0,0,.85,1.866l.009,0,2.179.818H1.343A.449.449,0,0,1,.9,15.876V10.934A.449.449,0,0,1,1.343,10.471Zm1.939.927a.927.927,0,1,0,.9.927A.911.911,0,0,0,3.282,11.4ZM2.1,13.868a.314.314,0,0,0-.308.319v.288c0,.491.6.937,1.492.937s1.492-.446,1.492-.937v-.288a.314.314,0,0,0-.308-.319Z"
      transform="translate(0 -8)"
      fill={props.color}
    />
  </svg>
);

export default Identity;

Identity.defaultProps = {
  color: "#2a3c50",
};

Identity.propTypes = {
  color: PropTypes.string,
};
