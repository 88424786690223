import React, { useRef, useLayoutEffect, useState, useContext } from "react";
import { CheckIcon, InfoIcon, ReplyArrowIcon } from "../../../../assets";
import { ReactComponent as ScheduleIcon } from "../../../../assets/scheduleIcon.svg";
import { ReactComponent as ForwardIconSmall } from "../../../../assets/forwarIconSmall.svg";
import { ReactComponent as CollapseIcon } from "../../../../assets/collapse.svg";
import { ReactComponent as ExpandIcon } from "../../../../assets/expand.svg";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {
  useDialogStylesWorksheet,
  useDividerStyles,
  useDividerStyles2,
} from "./styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Popover,
  InputBase,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Collapse from "@material-ui/core/Collapse";
import DrugBrandTable from "./drugBrandTable";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  makeName,
  TimeFormatConversion,
  AlertProps,
  getFormatForDateTimePicker,
  CustomaddDays,
} from "../../../../utils";
import moment from "moment";
import OrderInfo from "./orderInfo";
import CloseIcon from "@material-ui/icons/Close";
import BasicTable from "./frequencySchedule";
import { Close, Tick } from "../../../../assets";
import { Autocomplete } from "@material-ui/lab";
import DrugAdditiveCard from "./drugAdditiveCard";
import OrderInfoIV from "./orderInfoIV";
//import Config from "../../../../config";
import { actions } from "frequencyscreen_v_dbinder";
import { useDispatch, useSelector } from "react-redux";
import { AlertContext } from "../../../../contexts";
import BrandTableWrapper from "./brandTableWrapper";
import { useDialogStyles, useDialogContentStyles } from "./styles";
import DrugOrderDetails from "../../../drugOrderDetails";
import { StyledDialog } from "../../../commonComponents";
import TimeComponent from "../../../timeComponent";
import { fromUnixTime, getUnixTime } from "date-fns";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import format from "date-fns/format";
import { ReactComponent as PrinterIcon } from "../../../../assets/printerIconSecondary.svg";
//import { TimePicker } from "../../../common";
import { DoctorIcon } from "../../../../assets";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ReasonMaster from "../../../reasonMaster";
import { useCustomTooltip } from "./drugBrandTable/styles";

const StripInput = withStyles((theme) => ({
  root: {
    flex: 0,
    gap: 10,
    padding: "4px 8px",
    background: `${theme.palette.background.common} !important`,
    borderRadius: "4px",
    // border: "1px solid #DEE5EC",
    "& .App1-MuiInputBase-input": {
      minWidth: 20,
    },
  },
  input: {
    padding: 0,
    "&:focus": {
      backgroundColor: "unset",
    },
  },
}))(InputBase);

const useDotStyles = makeStyles({
  root: {
    height: 4,
    width: 4,
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    margin: "0px 6px",
  },
});
const Dot = () => {
  const classes = useDotStyles();
  return <div className={classes.root}></div>;
};

const useStyles = makeStyles((theme) => ({
  selectOrder: {
    color: `${theme.palette.primary.main} !important`,
    marginBottom: "-5px",
  },
  Maintitle: {
    color: theme.palette.text.primary,
  },
  MainBackground: {
    background: `${theme.palette.background.common} !important`,
  },
  MainBackgroundCard: {
    background: `${theme.palette.background.table} !important`,
  },
  dialogPaper: {
    minWidth: "871px",
    maxWidth: "871px",
    maxHeight: "100%",
  },
  container: {
    background: `${theme.palette.background.table} !important`,
    boxShadow: "0px 10px 25px #0000000A",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
    overflow: "hidden",
  },
  root: {
    display: "flex",
    alignItems: "center",
    maxHeight: 44,
    gap: 24,
  },
  drugFlexContainer: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    // width: 600, // Make this div adjust to the width of the right side element using refs - Assigned inline style
    "&:last-child": {
      minWidth: 0,
    },
  },
  drugIvInfoContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 4,
  },
  name: {
    // maxWidth: 200,
    // minWidth: "20px",
    fontSize: 14,
    fontWeight: 500,
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    marginRight: theme.spacing(1),
  },
  particularText: {
    fontSize: 12,
    cursor: "pointer",
  },
  info: {
    fontSize: 12,
    color: theme.palette.text.primary,
    // color: "#001C3C",
    fontWeight: 500,
  },
  doctorName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  drugDosageContainer: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    flex: 0,
  },
  doseFieldContainer: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    "& p": {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  doseInput: {
    // width: 115,
    flex: 0,
  },
  iconContainer: {
    // backgroundColor: "rgb(42, 96, 188)",
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    height: 20,
    width: 20,
    minWidth: 20,
    cursor: "pointer",
  },
  drugBrandGutter: {
    marginTop: theme.spacing(1.5),
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
  brandTableCompoundGutter: {
    marginTop: theme.spacing(1.5),
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
  button: {
    color: "black",
    backgroundColor: "#DEE5EC",
    height: "30px",
    borderRadius: "8px",
    marginLeft: "6px",
    fontSize: "12px",
    "& .App1-MuiButton-root": {
      textTransform: "none",
    },
  },
  btngroup: {
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
    "& .App1-MuiButton-root": {
      textTransform: "none",
    },
  },
  paper: {
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
    borderRadius: "8px",
  },
  header: {
    padding: "10px",
    "& .App1-MuiCardHeader-content": {
      "& .App1-MuiCardHeader-subheader": {
        color: "black",
      },
    },
  },
  content: {
    padding: "10px",
    paddingBottom: "15px",
  },
  additiveHeaderContainer: {
    position: "relative",
    margin: "12px 0px 10px 0px",
    display: "flex",
  },
  additiveHeaderContent: {
    display: "flex",
    alignItems: "center",
    padding: "0px 4px 0px 2px",
    marginLeft: 4,
    background: "white",
    zIndex: 1,
  },
  additiveHeaderText: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#2A3C50",
  },
  expandCollapseButton: {
    zIndex: 1,
  },
  additiveHeaderLine: {
    position: "absolute",
    left: "0px",
    right: "0px",
    alignSelf: "center",
    margin: "0px -50px",
    border: "0px",
    borderBottom: "1px solid #DEE5EC",
  },
  stripQuantityContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    // padding: "6px 12px",
  },
  doseInputLabelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    // border: "1px solid #DEE5EC",
    borderRadius: "8px",
    // padding: "5px",
    "& > p": {
      lineHeight: 1,
      fontSize: 14,
      fontWeight: 500,
    },
  },
  multiBatch: {
    padding: "4px 16px 4px 12px",
    border: "1px solid #DEE5EC",
    borderTop: "none",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    display: "flex",
    cursor: "pointer",
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: "12px",
    top: "12px",
    color: theme.palette.grey[500],
    backgroundColor: "#DEE5EC",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6">{children}</Typography>
      </div>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={props?.onClose}
        size={"small"}
        style={{ marginLeft: "20px" }}
      >
        <CloseIcon style={{ color: "#01205C", fontSize: "16px" }} />
      </IconButton>
    </MuiDialogTitle>
  );
});
function DrugCard({
  drug,
  dispenseDuration,
  onDispenseDurationChange,
  dispenseFrequency,
  onDispenseFrequencyChange,
  isDispenseDurationApplied,
  onApplyDispenseDurationClick,
  isIntravenous = false,
  isCompound = false,
  handleEditMedicationInfoForm = () => {},
  onChangeBatch = () => {},
  ...props
}) {
  // Hooks
  const theme = useTheme();
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const { setSnack } = useContext(AlertContext);

  // Styles
  const classes = useStyles();
  const dividerStyles = useDividerStyles();
  const dividerstyles2 = useDividerStyles2();

  const dialogStyles = useDialogStyles();
  const dialogStyleWorksheet = useDialogStylesWorksheet();
  const dialogContentStyles = useDialogContentStyles();
  const tooltipStyles = useCustomTooltip();

  // States
  const [needToBeWidth, setNeedToBeWidth] = React.useState(200);
  const [orderInfo, setOrderInfo] = React.useState(null);
  const [orderValue, setOrdervalue] = React.useState(null);
  const [totalOrder, setTotalOrder] = React.useState(null);
  const [doctorName, setDoctorName] = React.useState(null);
  const [Arrow, setArrow] = React.useState(null);
  const [schedule, setSchedule] = React.useState(null);
  const [isAdditiveExpanded, setIsAdditiveExpanded] = React.useState(false);
  const binderData = useSelector(
    (state) => state?.phramcyFrameworkApiSlice?.label_print?.data
  );

  const onAdditiveExpandCollapseChange = () => {
    setIsAdditiveExpanded((prev) => !prev);
  };

  const handleOrderinfo = (event, drug) => {
    setOrderInfo(true);
    setOrdervalue(drug?.orderinfo);
    setTotalOrder(drug);
  };
  const OrderInfoClose = () => {
    setOrderInfo(false);
  };
  // const open = Boolean(orderInfo);
  // const id = open ? 'simple-popover' : undefined;
  // Doctor name..........................
  const open = Boolean(doctorName);
  const handleDoctorName = (event) => {
    setDoctorName(event.currentTarget);
  };
  const DoctorClose = () => {
    setDoctorName(null);
  };
  const id = open ? "simple-popover" : undefined;

  // arrow popover
  const openArrow = Boolean(Arrow);
  const idArrow = open ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setArrow(event.currentTarget);
  };
  const handleClose = () => {
    setArrow(null);
  };
  // Schedule popover

  const openSchedule = Boolean(schedule);
  const idFrq = open ? "simple-popover" : undefined;
  const handleClickSchedule = (event) => {
    setSchedule(event.currentTarget);
  };
  const handleCloseschedule = () => {
    setSchedule(null);
  };

  // Use Layout Effect to set the width of the left side container.
  useLayoutEffect(() => {
    if (!rootRef.current) return;

    const calculateRemainingContainerWidth = () => {
      const parent = rootRef.current;
      const needToRemoveWidthChild = parent.querySelector(
        "#drugDosageContainer"
      );

      const parentWidth = window
        .getComputedStyle(parent)
        .getPropertyValue("width");
      const childWidth = window
        .getComputedStyle(needToRemoveWidthChild)
        .getPropertyValue("width");

      const parentWidthNum = parseFloat(parentWidth.replace("px", ""));
      const childWidthNum = parseFloat(childWidth.replace("px", ""));

      const remainingWidth = parentWidthNum - childWidthNum;
      return remainingWidth;
    };

    setNeedToBeWidth(calculateRemainingContainerWidth());
  });
  React.useEffect(() => {
    isaddfuncy(drug);
    isaddfuncyOnclick(drug);
  }, [drug]);

  const isaddfuncyOnclick = (drug) => {
    if (drug?.dosageValue === 0) {
      return true;
    }
    if (drug?.dosageUOM !== drug?.brands?.[0]?.dosageUOM) {
      return true;
    }
    if (drug?.disptype?.code === "IV-SY") {
      return true;
    }
    if (drug?.disptype?.code === "IV-LV") {
      return true;
    }
    let Split = drug?.dosageUOM?.split("/");
    if (Split?.length > 1) {
      return true;
    } else {
      var num = 0;
      let totalDose = 0;
      const a = (drug?.brands || [])?.forEach((v) => {
        if (
          v?.dosageValue?.length > 0 &&
          v.DrugType?.drugtype !== "DISPOSABLE"
        ) {
          v.dosageValue.map((c) => {
            totalDose += c;
          });
          // num = num + totalDose;
        }
      });
      return totalDose === drug?.dosageValue ? true : false;
    }
  };

  const isaddfuncy = (drug) => {
    //temperory
    return true;
    var num = 0;
    let totalDose = 0;
    const a = (drug?.brands || [])?.forEach((v) => {
      if (v.dosageValue?.length > 0) {
        v.dosageValue.map((C) => {
          totalDose += C;
        });
        //num = num + totalDose;
      }
    });
    return totalDose < drug?.dosageValue ? true : false;
  };

  const [expanded, setExpanded] = React.useState(false);
  const [action, setAction] = React.useState(null);

  const handleExpandClick = (val) => {
    setAction(val);
    setExpanded(false);
    if (val?.action === "DISCONTIUNE") {
      props.handleDiscontinue(val);
      setExpanded(false);
      return;
    }
    setExpanded(true);
  };

  const getDrugName = (drug) => {
    let name = [];
    drug.brands.forEach((v) => {
      name.push(v.name);
    });
    drug.additives.forEach((v) => {
      v.brands.forEach((b) => {
        name.push(b.name);
      });
    });
    return name;
  };

  const getDosage = (drug) => {
    let dosage = [];
    debugger;
    drug.brands.forEach((v) => {
      v.dosageValue.forEach((d) => {
        dosage.push(d);
      });
    });
    drug.additives.forEach((v) => {
      v.brands.forEach((b) => {
        b.dosageValue.forEach((d) => {
          dosage.push(d);
        });
      });
    });
    return dosage;
  };
  const getUOM = (drug) => {
    let uom = [];
    drug.brands.forEach((v) => {
      let UOM = v.dispuomtext ? v.dispuomtext : v.dosageUOM;
      uom.push(UOM);
    });
    drug.additives.forEach((v) => {
      v.brands.forEach((b) => {
        let UOM = b.dispuomtext ? b.dispuomtext : b.dosageUOM;
        uom.push(UOM);
      });
    });
    return uom;
  };

  const printLabel = async (drug, index, brand) => {
    if (
      brand?.pH_OrderLineDispenseSchedule ||
      brand?.pH_OrderLineDispenseSchedule?.dispensedetails ||
      brand?.dispensedetails
    ) {
      // alert("Never");
    } else {
      setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Generate Schedule to Print label!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }

    if (drug?.disptype?.code === "COMPOUND") {
      setOpenWorkSheet(true);
    }
    let type = drug?.additives?.length > 0 ? "IV" : "Brands";
    // Labelpayload........................

    let DrugName = drug?.mappedItems?.find(
      (v) => v?.dispensedrugcode === brand?.dispensedrugcode
    )?.drugNameLong;

    let date_label = JSON.parse(localStorage.getItem("orgFormat"));

    const Labelpayload = {
      reportid: process.env.REACT_APP_OP_LABEL_PRINT_ID,

      inputparams: {},
      result: [
        {
          Mandatory: {
            drugType:
              drug?.drugType?.longdesc ?? drug?.drugType?.shortdesc ?? "",
            name: drug?.encounter_Id[0]?.patient_id[0].name[0]
              ? makeName(drug?.encounter_Id[0]?.patient_id[0].name[0])
              : "",
            mrn: drug?.encounter_Id[0]?.patient_id[0].alias
              ? drug?.encounter_Id[0]?.patient_id[0].alias
              : "",
            qty: brand?.batches?.[0]?.chargeqty || brand?.totadmqty || "",
            qtyuom: brand?.batches?.[0]?.chargeuom || brand?.totadmuom || "",
            batchno: brand?.batches?.[0]?.batchno ?? "",

            expiry: brand?.batches?.[0]?.expdate
              ? moment(brand?.batches?.[0]?.expdate).format(
                  date_label?.orgdateformat?.display || "DD/MM/YYYY"
                )
              : brand?.batches?.[0]?.expirydate
              ? moment(brand?.batches?.[0]?.expirydate).format(
                  date_label?.orgdateformat?.display || "DD/MM/YYYY"
                )
              : "",
            date: moment(new Date()).format(
              date_label?.orgdatetimeformat?.display || "DD/MM/YYYY HH:mm"
            ),
            medicationname: DrugName ? DrugName : brand?.name,
            ControlledMedicine: true,
            hospname: drug?.encounter_Id?.[0]?.practitioner_details?.[0]?.clinic
              ? drug?.encounter_Id?.[0]?.practitioner_details?.[0]?.clinic
              : "",
            medicationorderdetails: drug?.medicationorderdetails ?? "",
            prescno: drug?.prescno ?? "",
            ward: drug?.ward ?? "",
            logo: drug?.logo ?? "",
            hospaddress: drug?.hospaddress ?? "",
            telecom: drug?.telecom ?? [],
            qrcode:
              (drug?.encounter_Id[0]?.patient_id[0].name[0]
                ? makeName(drug?.encounter_Id[0]?.patient_id[0].name[0])
                : "") +
              (drug?.encounter_Id[0]?.patient_id[0].alias
                ? drug?.encounter_Id[0]?.patient_id[0].alias
                : "") +
              (DrugName ? DrugName : brand?.name) +
              (brand?.totadmqty ? brand?.totadmqty : "") +
              (brand?.totadmuom ? brand?.totadmuom : "") +
              (drug?.ward ?? ""),
            listpres: brand?.pH_OrderLineDispenseSchedule
              ? brand?.pH_OrderLineDispenseSchedule?.dispensedetails[index]
                  ?.direction
              : brand?.dispensedetails[index]?.direction ?? "",
            disptype:
              brand?.pH_OrderLineDispenseSchedule?.disptype ??
              drug?.disptype?.code ??
              "",
            // currentDate: moment().unix(),
            // currentDate_format: TimeFormatConversion(
            //   moment().unix(),
            //   getFormatForDateTimePicker("date")
            // ), //TimeFormatConversion(moment().unix()),

            // hospitalname: drug?.encounter_Id?.[0]?.practitioner_details?.[0]
            //   ?.clinic
            //   ? drug?.encounter_Id?.[0]?.practitioner_details?.[0]?.clinic
            //   : "",

            // logo: "10012",

            // PatientName: drug?.encounter_Id[0]?.patient_id[0].name[0]
            //   ? makeName(drug?.encounter_Id[0]?.patient_id[0].name[0])
            //   : "",

            // MRN: drug?.encounter_Id[0]?.patient_id[0].alias
            //   ? drug?.encounter_Id[0]?.patient_id[0].alias
            //   : " ",

            // DrugName: DrugName ? DrugName : brand?.name,

            // Qty: brand?.totadmqty
            //   ? `${brand?.totadmqty}${brand?.totadmuom}`
            //   : " ",

            // Direction: brand?.pH_OrderLineDispenseSchedule
            //   ? brand?.pH_OrderLineDispenseSchedule?.dispensedetails[index]
            //       ?.direction
            //   : brand?.dispensedetails[index]?.direction ?? "",

            // ArabicDirection: brand?.pH_OrderLineDispenseSchedule
            //   ? brand?.pH_OrderLineDispenseSchedule?.dispensedetails[index]
            //       ?.nldirection
            //   : brand?.dispensedetails[index]?.nldirection ?? "",

            // PrescribedBy: drug?.doctorName ? drug?.doctorName : "",

            // ReviewedBy: "",

            // CheckedBy: "",

            // ExpiryDate: brand?.batches?.[0]?.expdate
            //   ? moment(brand?.batches?.[0]?.expdate).unix()
            //   : brand?.batches?.[0]?.expirydate,
            // ExpiryDate_format: brand?.batches?.[0]?.expdate
            //   ? TimeFormatConversion(
            //       moment(brand?.batches?.[0]?.expdate).unix()
            //     )
            //   : TimeFormatConversion(brand?.batches?.[0]?.expirydate),

            // RefillDate: brand?.pH_OrderLineDispenseSchedule
            //   ? brand?.pH_OrderLineDispenseSchedule?.nextrefilldate
            //   : brand?.nextrefilldate,
            // RefillDate_format:
            //   brand?.nextrefilldate > 0
            //     ? TimeFormatConversion(brand?.nextrefilldate)
            //     : "No Refill",

            // RefillLocation: "",

            // Barcode: drug?._id ? drug?._id : "",
          },
          optional: {},
        },
      ],
    };
    // IVpayload...................

    const IVpayload = {
      reportid: process.env.REACT_APP_OP_IV_LABEL_PRINT_ID,

      inputparams: {},

      result: [
        {
          Mandatory: {
            drugType:
              drug?.drugType?.longdesc ?? drug?.drugType?.shortdesc ?? "",
            PatientName: drug?.encounter_Id[0]?.patient_id[0].name[0]
              ? makeName(drug?.encounter_Id[0]?.patient_id[0].name[0])
              : "",
            MRN: drug?.encounter_Id[0]?.patient_id[0].alias
              ? drug?.encounter_Id[0]?.patient_id[0].alias
              : "",
            ord: drug._id ? drug._id : "",
            "#C": "",
            location: drug?.location ?? "",
            rate:
              (drug?.brands?.[index]?.pH_OrderLineDispenseSchedule
                ?.ivInfusionRateValue ?? 0,
              drug?.brands?.[index].pH_OrderLineDispenseSchedule
                ?.ivInfusionRateUOM ?? "",
              " ",
              drug?.brands?.[index]?.pH_OrderLineDispenseSchedule
                ?.IVDosageDurationValue ?? 0,
              drug?.brands?.[index].pH_OrderLineDispenseSchedule
                ?.IVDosageDurationUOM ?? ""),
            Exp: "",
            Due_time: drug?.brands?.[index]?.pH_OrderLinePerdoseSchedule
              ?.dosetime
              ? TimeFormatConversion(
                  drug?.brands?.[index]?.pH_OrderLinePerdoseSchedule?.dosetime,
                  date_label?.orgtimeformat || "hh:mm"
                )
              : TimeFormatConversion(
                  drug?.brands?.[index]?.PH_OrderLinePerdoseSchedule?.dosetime,
                  date_label?.orgtimeformat || "hh:mm"
                ),
            Due_date:
              TimeFormatConversion(
                drug?.brands?.[index]?.pH_OrderLinePerdoseSchedule?.dosedate,
                date_label?.orgdateformat || "dd/MM/yyyy"
              ) ||
              TimeFormatConversion(
                drug?.brands?.[index]?.PH_OrderLinePerdoseSchedule?.dosedate,
                date_label?.orgdateformat || "dd/MM/yyyy"
              ),
            Due_date_format:
              TimeFormatConversion(
                drug?.brands?.[index]?.pH_OrderLinePerdoseSchedule?.dosedate,
                date_label?.orgdatetimeformat || "dd/MM/yyyy hh:mm"
              ) ||
              TimeFormatConversion(
                drug?.brands?.[index]?.PH_OrderLinePerdoseSchedule?.dosedate,
                date_label?.orgdatetimeformat || "dd/MM/yyyy hh:mm"
              ),
            drugname: getDrugName(drug),
            dose: getDosage(drug),
            uom: getUOM(drug),
            direction: brand?.pH_OrderLineDispenseSchedule
              ? brand?.pH_OrderLineDispenseSchedule?.dispensedetails[index]
                  ?.direction ?? ""
              : brand?.dispensedetails[index]?.direction ?? "",
            Route: drug?.routeCode ? drug?.routeCode : "",
            Freq: drug?.frequency.code ?? "",
          },
          optional: {},
          //   Mandatory: {
          //     PatientName: drug?.encounter_Id[0]?.patient_id[0].name[0]
          //       ? makeName(drug?.encounter_Id[0]?.patient_id[0].name[0])
          //       : "",

          //     MRN: drug?.encounter_Id[0]?.patient_id[0].alias
          //       ? drug?.encounter_Id[0]?.patient_id[0].alias
          //       : " ",

          //     ord: drug._id ? drug._id : " ",

          //     "#C": "",

          //     location: "",

          //     drugname: getDrugName(drug),

          //     dose: getDosage(drug),

          //     uom: getUOM(drug),

          //     rate: "",

          //     direction: brand?.pH_OrderLineDispenseSchedule
          //       ? brand?.pH_OrderLineDispenseSchedule?.dispensedetails[index]
          //         ?.direction
          //       : brand?.dispensedetails[index]?.direction ?? "",

          //     Route: drug?.routeCode ? drug?.routeCode : "",

          //     Freq: drug.frequency.code,

          //     Exp: "",

          // Exp_format: brand?.batches?.[0]?.expdate
          //   ? moment(brand?.batches?.[0]?.expdate).unix()
          //   : brand?.batches?.[0]?.expirydate,
          // ExpiryDate_format: brand?.batches?.[0]?.expdate
          //   ? TimeFormatConversion(
          //       moment(brand?.batches?.[0]?.expdate).unix()
          //     )
          //   : TimeFormatConversion(brand?.batches?.[0]?.expirydate),
          // brand?.batches?.[0]?.expdate
          //   ? moment(brand?.batches?.[0]?.expdate).unix()
          //   : brand?.batches?.[0]?.expirydate,

          //   Due_time: "",
          //   Due_date: "",
          //   Due_date_format: "",
          // },

          // optional: {},
        },
      ],
    };

    await dispatch(
      actions.LABEL_PRINT({
        Body: type === "IV" ? IVpayload : Labelpayload,
      })
    );
  };

  const FreqHeader = (type) => {
    switch (type) {
      case "DBA":
        return ["Day", "Base schedule", "Dosage", "Remarks"];
      case "UDE":
        return ["Base schedule", "From Day", "To Day", "Dosage", "Remarks"];
      case "DET":
        return ["Time", "Dosage", "Remarks"];
      case "INT":
      case "SEQ":
        return ["Base schedule", "Dosage", "Remarks"];
      default:
        return [
          "Day",
          "Time",
          "Base schedule",
          "Start date",
          "End date",
          "Dosage",
          "Remarks",
        ];
    }
  };

  const FreqBody = (FreqDetls) => {
    let data = FreqDetls?.map((v) => {
      return {
        Remarks: v?.adminDosageInstruction
          ? v?.adminDosageInstruction
          : v.remarks ?? "-",
        Day: v?.day ? v?.day : "-",
        Time: v?.time ? v?.time : "-",
        "Base schedule": v?.baseFreq ?? "-",
        "Start date":
          v?.fromDate === 0
            ? "-"
            : moment(v?.fromDate * 1000).format("DD-MM-YYYY"),

        "End date":
          v?.toDate === 0 ? "-" : moment(v?.toDate * 1000).format("DD-MM-YYYY"),
        Dosage: v?.dose ? v?.dose : "-",
        "From Day": v?.fromDay,

        "To Day": v?.toDay,
      };
    });
    return data;
  };

  const [openWorkSheet, setOpenWorkSheet] = useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);

  const handleMedicationEdit = () => {
    OrderInfoClose();
    // setMedicationEdit(true);
    handleEditMedicationInfoForm(totalOrder?._id);
  };

  const onCloseWorkSheet = () => {
    setOpenWorkSheet(false);
  };

  const handlePrintWorkSheet = async (drug) => {
    setOpenWorkSheet(true);
    let date = JSON.parse(localStorage.getItem("orgFormat"));
    let payload = {
      reportid: process.env.REACT_APP_COMPOUND_PRINT_LABEL,
      inputparams: {
        "@orderID": drug?._id,
        "@datetimeformat":
          "%" +
          date?.orgdatetimeformat?.display
            ?.replaceAll("/", "/%")
            ?.replaceAll(" ", " %")
            ?.replaceAll("mm", "%ii"),
        // "%dd/%mm/%yyyy %hh:%ii",
      },
      result: [],
    };

    await dispatch(
      actions.LABEL_PRINT({
        Body: payload,
      })
    );
  };

  const handleMultiBatch = (type, index, isShow) => {
    if (props.isMultiBatch) {
      if (!isShow) {
        props.handleShowAllBatch(true, true, type, index);
      } else {
        onApplyDispenseDurationClick(false, true, type, index);
      }
    }
  };

  const isSelect = (d) => {
    var selected = false;

    const isFilter = props.isMultiOrderSelect;

    if (isFilter?.length > 0) {
      const data = isFilter?.filter((v) => v?._id === d?._id);
      selected = data?.length > 0;
    }
    return selected;
  };

  return (
    <>
      {!drug?.isEmpty && (
        <div className={classes.container}>
          <div className={classes.root} ref={rootRef}>
            <div className={classes.drugFlexContainer}>
              {drug?.route && (
                <>
                  <Typography className={classes.particularText}>
                    {drug?.route}
                  </Typography>
                </>
              )}
              <Dot />
              <Tooltip title={drug?.name} arrow>
                <Typography className={classes.name}>{drug?.name}</Typography>
              </Tooltip>
              <Tooltip title="Drug info" arrow>
                <InfoIcon
                  onClick={(e) => handleOrderinfo(e, drug)}
                  style={{
                    minWidth: "14px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
              <Divider
                orientation="vertical"
                flexItem
                classes={dividerStyles}
                style={{ margin: theme.spacing(0, 1) }}
              />
              {!isIntravenous && (
                <>
                  <Typography className={classes.particularText}>
                    {drug?.doseStrength}
                  </Typography>
                  <Dot />
                  <Typography className={classes.particularText}>
                    {drug?.frequency?.code}
                  </Typography>
                  <Dot />
                  <Tooltip title="Frequency Schedule" arrow>
                    <div
                      style={{ margin: "0px 4px", cursor: "pointer" }}
                      onClick={handleClickSchedule}
                    >
                      <ScheduleIcon />
                    </div>
                  </Tooltip>
                  <Dot />
                  <Typography
                    onMouseOver={handleDoctorName}
                    className={classNames(classes.particularText)}
                  >
                    <DoctorIcon />
                  </Typography>
                  <Dot />
                  <Tooltip
                    title={
                      <TimeComponent
                        dateTime={[
                          getUnixTime(new Date(drug?.startDate)),
                          getUnixTime(new Date(drug?.endDate)),
                        ]}
                        timeZone={"UTC"}
                        separator={" - "}
                        formatType={"datetime"}
                        className={classes.particularText}
                      />
                    }
                  >
                    <Typography className={classes.particularText}>
                      {drug?.duration}
                    </Typography>
                  </Tooltip>

                  {/* <TimeComponent
                    dateTime={[
                      getUnixTime(new Date(drug?.startDate)),
                      getUnixTime(new Date(drug?.endDate)),
                    ]}
                    separator={" - "}
                    formatType={"datetime"}
                    className={classes.particularText}
                  />
                  <Dot /> */}
                </>
              )}

              {/* {isIntravenous && (
                <>
                  <Dot />
                  <Typography
                    className={classNames(
                      classes.particularText,
                      classes.doctorName
                    )}
                  >
                    {"Cardiology"}
                  </Typography>
                </>
              )} */}
            </div>

            <div style={{ flex: 1 }}></div>
            {props.isError && (
              <Tooltip title={props.errorMessage} arrow classes={tooltipStyles}>
                <ErrorOutlineIcon
                  style={{ marginLeft: "auto", marginTop: "4px" }}
                  fontSize="small"
                />
              </Tooltip>
            )}
            <div
              id="drugDosageContainer"
              className={classes.drugDosageContainer}
            >
              {/* Dispense Duration Container */}

              <div className={classes.stripQuantityContainer}>
                <div className={classes.doseInputLabelContainer}>
                  <Typography>Disp.Durn&nbsp;:</Typography>
                  <div className={classes.doseInput}>
                    <StripInput
                      // autoFocus
                      id={"stripInput"}
                      placeholder="00"
                      type={"text"}
                      value={
                        drug?.deliveringDispenseDuration?.length > 0 ||
                        drug?.deliveringDispenseDuration?.length === 0
                          ? drug?.deliveringDispenseDuration
                          : drug?.dispenseDuration
                      }
                      onChange={(e) => {
                        onDispenseDurationChange(e.target.value);
                      }}
                      disabled={
                        props?.selectedState === "verification" &&
                        props.dosageFreqCode !== "STAT"
                          ? false
                          : true
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      // IVDosageDurationUOM
                      endAdornment={
                        <Typography className={classes.stripFielAdornText}>
                          {/* {props?.scenarioName?.toLowerCase() === "cart" ||
                          (drug?.CartTransId &&
                            props?.scenarioName?.toLowerCase() === "ip")
                            ? "Hours"
                            : drug?.dosageDurationUOMIp
                            ? drug?.dosageDurationUOMIp
                            : } */}
                          {drug?.dosageDurationUOMIp || drug?.dosageDurationUOM}
                        </Typography>
                      }
                      inputProps={{ "aria-label": "Input field" }}
                    />
                  </div>
                </div>
              </div>
              {!props?.isOnHold && (
                <Tooltip title="Apply" arrow>
                  <div
                    className={classes.iconContainer}
                    onClick={
                      isaddfuncyOnclick(drug)
                        ? onApplyDispenseDurationClick
                        : () => {
                            setSnack({
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Dispense Dosage is not fullfilled!",
                              vertical: AlertProps.vertical.top,
                              horizontal: AlertProps.horizontal.right,
                              tone: true,
                            });
                          }
                    }
                  >
                    <ReplyArrowIcon
                      color="#fff"
                      style={{
                        color: "#fff",
                      }}
                    />
                  </div>
                </Tooltip>
              )}

              {drug?.brands?.length > 0 && (
                <Divider
                  orientation="vertical"
                  flexItem
                  classes={dividerStyles}
                  // style={{ margin: theme.spacing(0, 1.5) }}
                />
              )}

              {/* <div
                className={classes.iconContainer}
                onClick={props.onApplyClick}
                onMouseOver={handleClick}
              >
                <SvgIcon
                  component={ForwardIconSmall}
                  color="#fff"
                  viewBox="-5 -7 20 20"
                  style={{
                    width: "20px",
                    height: "20px",
                    transform: "rotate(-90deg)",
                    color: "#fff",
                  }}
                />
              </div> */}

              {props?.isOnHold && (
                <Tooltip title="Can you please release the on-hold order" arrow>
                  <div
                    style={{
                      padding: "3px 6px",
                      background: "#3f51b5",
                      color: "#fff",
                      borderRadius: 8,
                      cursor: "pointer",
                    }}
                  >
                    Hold
                  </div>
                </Tooltip>
              )}

              {/* {!props?.isOnHold && */}
              <div onClick={() => props.isMultiOrderSelectFuntion(drug)}>
                {isSelect(drug) ? (
                  <CheckCircleIcon className={classes.selectOrder} />
                ) : (
                  <CheckCircleOutlineIcon className={classes.selectOrder} />
                )}
              </div>
              {/* } */}
            </div>
          </div>

          {/* Intravenous fluid (IV) only row */}
          {isIntravenous && (
            <div className={classes.drugIvInfoContainer}>
              {drug?.ivInfusionRateValue > 0 && (
                <Tooltip arrow title="Infusion Rate">
                  <Typography className={classes.particularText}>
                    {drug?.ivInfusionRateValue}&nbsp;
                    {drug?.ivInfusionRatetext}
                    {drug?.ivInfusionTime > 0 && <>for</>}
                  </Typography>
                </Tooltip>
              )}
              {/* <Dot /> */}
              {drug?.ivInfusionTime > 0 && (
                <>
                  <Tooltip arrow title="Infusion Time">
                    <Typography className={classes.particularText}>
                      {drug?.ivInfusionTime}&nbsp;
                      {drug?.ivInfusionTimeUomtext}
                    </Typography>
                  </Tooltip>
                </>
              )}
              <Dot />
              <Tooltip arrow title="Strength">
                <Typography className={classes.particularText}>
                  {drug?.doseStrength}
                </Typography>
              </Tooltip>
              <Dot />
              <Typography className={classes.particularText}>
                {drug?.frequency?.code}
              </Typography>
              <Dot />
              <Typography className={classes.particularText}>
                {drug?.duration}
              </Typography>
              <Dot />
              <Tooltip title="Frequency Schedule" arrow>
                <div
                  style={{ margin: "0px 4px", cursor: "pointer" }}
                  onClick={handleClickSchedule}
                >
                  <ScheduleIcon />
                </div>
              </Tooltip>
              <Dot />
              <Typography className={classes.particularText}>
                {`${moment(drug?.startDate)?.format(
                  "DD-MM-YY HH:mm"
                )} - ${moment(drug?.endDate)?.format("DD-MM-YY HH:mm")}`}
              </Typography>
              <Dot />
              <Typography className={classes.particularText}>
                {drug?.drugType?.longdesc}
              </Typography>
            </div>
          )}

          {/* Compound only row */}
          {isCompound && (
            <div className={classes.drugIvInfoContainer}>
              <Dot />
              <Typography className={classes.particularText}>
                Quantity &nbsp;
                {drug?.brands?.[0]?.totadmqty ?? ""}
                {drug?.brands?.[0]?.totadmuom ?? ""}
              </Typography>
              <Dot />
              <Typography className={classes.particularText}>
                Stability {drug?.drugDetails?.[0]?.stability}&nbsp;
                {"days"}
              </Typography>
              <Dot />
              <Typography className={classes.particularText}>
                Expired on &nbsp;
                {props?.selectedState === "verification" ? (
                  format(
                    CustomaddDays(drug?.drugDetails?.[0]?.stability ?? 0),
                    getFormatForDateTimePicker("date")
                  )
                ) : (
                  <>
                    {drug?.brands?.[0]?.batches?.[0]?.expirydate
                      ? // moment(drug?.brands?.[0]?.batches?.[0]?.expirydate).format('hh:mm a')
                        format(
                          fromUnixTime(
                            moment(
                              drug?.brands?.[0]?.batches?.[0]?.expirydate
                            ).unix()
                          ),
                          getFormatForDateTimePicker("date")
                        )
                      : ""}
                  </>
                )}
              </Typography>
              <Dot />
              <Typography className={classes.particularText}>
                Batch Number&nbsp;&nbsp;
                {props?.selectedState === "verification" ? (
                  <StripInput
                    id={"stripInput"}
                    type={"text"}
                    value={drug?.brands?.[0]?.batches?.[0]?.id}
                    onChange={(e) => {
                      onChangeBatch(e.target.value);
                    }}
                    inputProps={{ "aria-label": "Input field" }}
                  />
                ) : (
                  <span className={classes.name}>
                    {drug?.brands?.[0]?.batches?.[0]?.id}
                  </span>
                )}
              </Typography>

              <div
                style={{
                  marginLeft: "auto",
                  marginTop: "4px",
                  display: "flex",
                }}
              >
                {isCompound && (
                  <Tooltip title="Work Sheet" arrow>
                    <div
                      style={{ marginTop: "4px" }}
                      onClick={() => handlePrintWorkSheet(drug)}
                    >
                      <PrinterIcon />
                    </div>
                  </Tooltip>
                )}
                {!isCompound && (
                  <Tooltip title="Print Label" arrow>
                    <div
                      style={{ marginLeft: "10px", marginTop: "4px" }}
                      onClick={() => printLabel(drug, 0, drug.brands?.[0])}
                    >
                      <PrinterIcon />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          )}

          {!isCompound && (
            <>
              <div className={classes.drugBrandGutter}>
                {drug?.brands?.map((brand, index) => (
                  <DrugBrandTable
                    {...props}
                    key={brand?.uniqueId}
                    disptype={drug?.disptype?.code}
                    drug={drug}
                    brand={brand}
                    imgId={brand?.drugPhotoId}
                    isAnotherBrandAvailable={index !== drug?.brands?.length - 1}
                    batchQuantity={props?.batchQuantity}
                    issueQuantity={(val) => props.issueQuantity(val, index)}
                    onBatchQuantityChange={(value, batch_inx, fill, b) =>
                      props?.onBatchQuantityChange(
                        value,
                        brand?.id,
                        index,
                        batch_inx,
                        false,
                        fill,
                        b
                      )
                    }
                    handleChangebrandName={(value) => {
                      props.handleChangebrandName(value, index, brand?.id);
                    }}
                    handleDelete={() => props?.handleDelete(index)}
                    handleAdd={(val) => props?.handleAdd(val, index)}
                    isAdd={
                      isaddfuncy(drug) && drug?.brands?.length - 1 === index
                    }
                    index={index}
                    isIntravenous={isIntravenous}
                    printLabel={() => printLabel(drug, index, brand)}
                    handleDirectionSaveClick={(e) =>
                      props.handleDirectionSaveClick(e, index, brand)
                    }
                    printEnable
                    drugType={brand?.DrugType?.drugtype}
                    isShowMulti={brand.isShowMulti ?? false}
                    handleMultiBatch={(t, b) => handleMultiBatch(t, index, b)}
                    isMultiBatch={props.isMultiBatch}
                    stateValue={props?.selectedState}
                    infusionrateUOM={drug?.ivInfusionRatetext}
                    infusiontimeUOM={drug?.ivInfusionTimeUomtext}
                    errorMessage={brand?.StrengthErrmessage}
                    scheduleErrorMessage={brand?.ScheduleErrmessage}
                  />
                ))}
              </div>
            </>
          )}
          {/* )} */}

          {isIntravenous && drug?.additives?.length > 0 && (
            <>
              <div className={classes.additiveHeaderContainer}>
                <div className={classes.additiveHeaderContent}>
                  {/* Add a expand/collapse button here */}
                  <IconButton
                    size="small"
                    disableRipple
                    onClick={onAdditiveExpandCollapseChange}
                    className={classes.expandCollapseButton}
                  >
                    {isAdditiveExpanded ? <CollapseIcon /> : <ExpandIcon />}
                  </IconButton>

                  <Typography className={classes.additiveHeaderText}>
                    Additives for {drug?.name} {drug?.doseStrength}
                  </Typography>
                </div>

                <hr className={classes.additiveHeaderLine} />
              </div>

              <Collapse in={isAdditiveExpanded}>
                {drug?.additives?.map((additive, index) => (
                  <DrugAdditiveCard
                    key={additive?.uniqueId}
                    additive={additive}
                    drug={drug}
                    printLabel={(brand, ivIndex) =>
                      printLabel(drug, ivIndex, brand)
                    }
                  />
                ))}
              </Collapse>
            </>
          )}
          {isCompound && (
            <>
              {drug?.compounds?.map((comp, index) => {
                return (
                  <div className={classes.brandTableCompoundGutter}>
                    <BrandTableWrapper
                      key={comp?.uniqueId}
                      drugName={comp?.drugNameLong}
                      dosageValue={comp?.dosageValue}
                      dosageUOM={comp?.dosageUOM}
                    >
                      <DrugBrandTable
                        {...props}
                        key={comp?.uniqueId}
                        disptype={drug?.disptype?.code}
                        drug={drug}
                        brand={comp}
                        imgId={comp?.drugPhotoId}
                        isAnotherBrandAvailable={false}
                        isCompound={true}
                        batchQuantity={props?.batchQuantity}
                        handleMultiBatch={(t, b) =>
                          handleMultiBatch(t, index, b)
                        }
                        issueQuantity={(val) => props.issueQuantity(val, index)}
                        isShowMulti={comp.isShowMulti ?? false}
                        isMultiBatch={props.isMultiBatch}
                        onBatchQuantityChange={(value, batch_inx, fill, b) =>
                          props?.onBatchQuantityChange(
                            value,
                            comp?.id,
                            index,
                            batch_inx,
                            false,
                            fill,
                            b
                          )
                        }
                        // handleChangebrandName={(value) => {
                        //   props.handleChangebrandName(value, index, comp?.id);
                        // }}
                        // handleDelete={() => props?.handleDelete(index)}
                        // handleAdd={() => props?.handleAdd(index)}
                        isAdd={false}
                        index={0}
                        // isIntravenous={isIntravenous}
                        // printLabel={() => printLabel(drug, index, comp)}
                        // handleDirectionSaveClick={(e) =>
                        //   props.handleDirectionSaveClick(e, index, comp)
                        // }
                        // printEnable
                        stateValue={props?.selectedState}
                        errorMessage={comp?.StrengthErrmessage}
                      />
                    </BrandTableWrapper>
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}

      <StyledDialog
        id="Order_Details"
        open={orderInfo}
        handleClose={OrderInfoClose}
        title={"ORDER DETAILS"}
        dialogProps={{
          classes: dialogStyles,
        }}
        contentProps={{
          classes: dialogContentStyles,
        }}
      >
        <DrugOrderDetails
          id={props?.Order_id}
          showPractitionerEditButton={true}
          handleEditClick={handleMedicationEdit}
        />
      </StyledDialog>

      {/* Order Info IV Dialog..... */}
      <Dialog
        fullWidth
        // maxWidth="sm"
        classes={{ paper: classes.dialogPaper }}
        open={false}
        onClose={true}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={true}
          style={{ borderBottom: "1px solid #DEE5EC" }}
        >
          <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
            ORDER INFO
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: "16px" }}>
          <OrderInfoIV orderinfo={orderValue} />
        </DialogContent>
      </Dialog>

      {/* Doctor Name PopOver...... */}
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={doctorName}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={DoctorClose}
        disableRestoreFocus
      >
        <div
          onMouseLeave={DoctorClose}
          style={{ marginTop: "5px", padding: "10px", width: "244px" }}
        >
          <Typography className={classes.info} style={{ paddingBottom: "8px" }}>
            Order Info
          </Typography>
          <Divider
            orientation="horizontal"
            flexItem
            classes={dividerstyles2}
            style={{ height: "1px !important" }}
          />
          <div style={{ marginTop: "5px" }}>
            <Typography
              className={classes.info}
              style={{ paddingTop: "8px", paddingBottom: "5px" }}
            >
              {drug?.speciality}
            </Typography>
            <div>
              <div style={{ display: "flex" }}>
                <div>
                  <Typography className={classes.particularText}>
                    {drug?.doctorName}
                  </Typography>
                </div>
                <div style={{ marginLeft: "5px" }}>
                  <Typography className={classes.particularText}>
                    <TimeComponent
                      key={"10"}
                      noWrap
                      id={"userInfo_date_time"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      aliasName={"userInfoDateTime"}
                      style={{ fontSize: "12px" }}
                      dateTime={drug?.startDate}
                      formatType="date"
                    />
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popover>

      {/* Frequency Schedule-------------------------------- */}
      <Popover
        id={idFrq}
        open={openSchedule}
        anchorEl={schedule}
        onClose={handleCloseschedule}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          style={{ display: "flex", padding: "12px" }}
          className={classes.btngroup}
        >
          <BasicTable
            header={FreqHeader(props.dosageFreqType)}
            dataTabs={FreqBody(props.FreqDetls)}
            FreqDetls={props.FreqDetls}
            dosageFreqCode={props.dosageFreqCode}
          />
        </div>
      </Popover>
      <StyledDialog
        id="compound_work_sheet"
        open={openWorkSheet}
        handleClose={onCloseWorkSheet}
        title={"Compound Work Sheet"}
        dialogProps={{
          classes: dialogStyleWorksheet,
        }}
        contentProps={{
          classes: dialogContentStyles,
        }}
      >
        <Document file={binderData.downloadUrl}>
          <Page pageNumber={pageNumber} />
        </Document>
      </StyledDialog>
    </>
  );
}

export default DrugCard;

DrugCard.propTypes = {
  drug: PropTypes.object.isRequired,
  dispenseDuration: PropTypes.string.isRequired,
  onDispenseDurationChange: PropTypes.func.isRequired,
  dispenseFrequency: PropTypes.string.isRequired,
  onDispenseFrequencyChange: PropTypes.func.isRequired,
  onApplyDispenseDurationClick: PropTypes.func.isRequired,
};

DrugCard.defaultProps = {
  drug: {},
  onDispenseDurationChange: () => {},
  onDispenseFrequencyChange: () => {},
  onApplyDispenseDurationClick: () => {},
};
