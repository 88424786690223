export const styles = (theme) => ({
  nav_back: {
    backgroundColor: theme.palette.background.commonHeader,
  },
  rootBackground: {
    backgroundColor: theme.frequency.paperColor,
    padding: "20px",
    display: "block",
  },
  background: {
    backgroundColor: theme.palette.background.table,
    padding: "10px",
    borderRadius: "8px",
  },
  backgroundImage: {
    padding: "10px",
    borderRadius: "8px",
    alignItems: "stretch",
    height: "100%",
    backgroundColor: theme.palette.background.table,
  },
  locationDetails: {
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: theme.palette.background.table,
  },
  contact: {
    marginTop: "15px",
    backgroundColor: theme.palette.background.table,
    borderRadius: "6px",
  },
  add: {
    "&.App1-MuiButton-contained:hover": {
      backgroundColor: theme.palette.button.hover
    },
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.background,
  },
  autocomplete: {
    "& .App1-MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.textField
    },
  },
  paper: {
    color: "#6a7888"
  },
  notchedOutline: {
    borderColor: theme.palette.border.textField
  },
  stepperMain: {
    "& .custom-material-ui-component-MuiStepLabel-label.custom-material-ui-component-MuiStepLabel-active": {
      color: theme.palette.text.primary,
    },
    "& .custom-material-ui-component-MuiStepper-horizontal": {
      backgroundColor: theme.palette.background.commonHeader,
      color: theme.palette.text.primary,
    }
  }
});
