export const styles = (theme) => ({
  searchAndAddButtonContainer: {
    display: "flex",
    gap: "8px",
    //   marginRight: 20,
    "& .App1-MuiInputBase-root": {
      minWidth: "360px",
    },
  },
  searchAndButtonInput: {
    flex: 1,
  },
  pharmacy_back: {
    backgroundColor: theme.palette.background.table
  },
  addnew: {
    "&.App1-MuiButton-contained:hover": {
      backgroundColor: theme.palette.button.hover
    },
    color:theme.palette.button.text,
    backgroundColor:theme.palette.button.background,
  },
  pharmacy: {
    color: theme.palette.text.primary
  },
  divider: {
    margin: "0px -16px",
    backgroundColor: "#DEE5EC",
  },
  commonBackground:{
    backgroundColor:theme.palette.background.common,
    padding:"20px"
  },
});
