import React from "react";
import { CommonTable, OverlayCompt } from "../../../../../components";
import OrderCatalogForm from "./form";
import { withAllContexts } from "../../../../../HOCs";
import { connect } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
//import { AlertProps } from "../../../../../utils";
class OrderCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: null,
      page: 0,
      perPage: 10
    };
    // this.saveForm = this.saveForm.bind(this)
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  async componentDidMount() {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Loading...",
    });

    await this.props.READ_ORDER_CATALOG({page: this?.state?.page, perPage: this?.state?.perPage});
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  }
  handleForm = () => {
    let { closeFrom } = this.props;
    this.setState({
      ...this.state,
      editData: null,
    });
    closeFrom();
  };
  handleEdit = (e, data, index) => {
    let { closeFrom } = this.props;
    this.setState({
      ...this.state,
      editData: data._key,
    });
    closeFrom();
  };

  //checkbox for status update
  handleCheckBox = async (e, data, index) => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Updating...",
    });
    await this.props.UPDATE_ORDER_CATALOG_STATUS({
      editId: data._key,
      status: e.target.checked,
    });
    await this.props.READ_ORDER_CATALOG({page: this?.state?.page, perPage: this?.state?.perPage});
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
    this.props.alert.setSnack("update");
  };
  condition = (value, type) => {
    if (type === "DRAFT") {
      if (value.draft) {
        return true;
      } else {
        return false;
      }
    } else if (type === "CHECKBOX") {
      if (value.draft) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  handlepageChange = async(currentPage, PerPage) => {

    this.setState({
      ...this.state,
      page: (currentPage - 1) * PerPage,
      perPage: PerPage
    });
    await this.props.READ_ORDER_CATALOG({page: (currentPage - 1) * PerPage, perPage: PerPage})

  };

  render() {
    const { open } = this.props;
    const { editData, 
      //openIndex, parent_id
     } = this.state;
    return (
      <React.Fragment>
        <CommonTable
          style={{ height: "calc(100vh - 245px)" }}
          parent_id={"order-catalog"}
          handleEdit={this.handleEdit}
          handleCheckBox={this.handleCheckBox}
          Header={[
            "S.No",
            "Order Catalog Code",
            "Short Description",
            "Long Description",
            "Status",
            "Action",
          ]}
          dataList={this.props.orderCatalogRead.data ?? []}
          tableData={[
            { type: ["INCRIMENT"], name: "" },
            { type: ["TEXT"], name: "code" },
            { type: ["TEXT"], name: "short" },
            { type: ["TEXT"], name: "long" },
            { type: ["CHECKBOX", "DRAFT"], name: "status" },
            { type: ["EDIT"], name: "" },
          ]}
          condition={this.condition}
          
          handlepageChange={(currentPage, PerPage)=>this.handlepageChange(currentPage, PerPage)}
          TableCount={this.props.orderCatalogRead?.data?.[0]?.TotalCount}
          incrementCount={this.state?.page}
          showPagination={true}
          rowsPerPageOptions={[
              { label: "10 Rows", value: 10 },
              { label: "50 Rows", value: 50 },
              { label: "100 Rows", value: 100 },
          ]}
        />
        <OverlayCompt
          parent_id={"order-catalog"}
          open={open}
          children={
            <React.Fragment>
              {open && (
                <OrderCatalogForm
                  parent_id={"ordercatalog"}
                  editData={editData}
                  closeForm={this.handleForm}
                  parentState={this.state}
                />
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  orderCatalogRead: state.orderCatalogSlice.order_catalog_read,
});

export default connect(mapStateToProps, actions)(withAllContexts(OrderCatalog));
