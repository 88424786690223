import { makeStyles } from "@material-ui/core/styles";

export const useTabsStyles = makeStyles((theme) => ({
  root: {
    minHeight: 38,
    alignSelf: "flex-start",
  },
  flexContainer: {
    gap: 24,
  },
  indicator: {
    background: `${theme.palette.primary.main} !important`,
    // backgroundColor: "#2A60BC",
  },
}));

export const useTabStyles = makeStyles((theme) => {
  const activeColor = theme.palette.primary.main;
  const inactiveColor = theme.palette.text.primary;
  return {
    root: {
      //minHeight: 28,
      lineHeight: 1.5,
      minWidth: "min-content",
      padding: 0,
      textTransform: "unset",
      "& .App1-MuiTab-wrapper": {
        flexDirection: "row",
        gap: theme.spacing(0.75),
        "& .App1-MuiTypography-root": {
          color: inactiveColor,
          fontWeight: 500,
          fontSize: 14,
        },
        "& div": {
          backgroundColor: inactiveColor,
          "& p":{
            color: theme.palette.background.common,
          }
        },
      },
      opacity: 1,
      "&$selected": {
        "& .App1-MuiTab-wrapper": {
          "& .App1-MuiTypography-root": {
            color: activeColor,
          },
          "& div": {
            backgroundColor: activeColor,
          },
        },
      },
    },
    selected: {},
  };
});

export const useDividerStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#B4BBC3",
  },
  flexItem: {
    height: "18px",
    alignSelf: "center",
  },
}));

export const useIconButtonStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: theme.palette.background.common,
    },
    "& svg": {
      color: theme.palette.text.primary,
      "& path": {
        fill: `${theme.palette.text.primary}`,
        color: `${theme.palette.text.primary}`,
      }
    }
  },
}));

// export const useDividerStyles = makeStyles((theme) => ({}))
