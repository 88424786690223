import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TypographyWithIcon from "./typographyWithIcon";
import {
  DollarIcon,
  GroceryShelfIcon,
  NoAccessIcon,
  ScheduleIcon,
  StackIcon,
  StackOverflowIcon,
} from "../../../assets";
import { StyledDivider, StyledTooltip } from "../../commonComponents";
import Dot from "../../dot";
import StyledInput from "../styledInput";
import { Tooltip } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: ({ isLast }) => ({
    display: "flex",
    alignItems: "center",
    borderRadius: isLast ? theme.spacing(0, 0, 1.25, 1.25) : theme.spacing(0),
    overflow: "hidden",
  }),
  batchNumber: {
    fontWeight: 600,
  },
  icon: {
    minWidth: 14,
  },
  divider: {
    background: "#DEE5EC",
  },
  binLocation: {
    borderRadius: 4,
    background: "#DDE7F9",
    display: "flex",
    alignItems: "center",
    gap: 6,
    minHeight: 23,
    paddingRight: 6,
  },
  binNumber: {
    background: "#385181",
    borderRadius: 4,
    alignSelf: "stretch",
    display: "flex",
    padding: "3px 6px",
    "& p": {
      color: "white",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1,
      margin: "auto",
      "& span": {
        fontWeight: 500,
      },
    },
  },
  aisleShelfLevel: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1,
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    flex: 0,
    marginLeft: "auto",
  },
  inputPrefix: {
    color: theme.palette.text.grey,
  },
  addedDrugContainer: {
    backgroundColor: "#6A7888",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 6,
    background: theme.palette.background.table,
    borderRadius: 4,
    padding: "4px 6px",
    minWidth: 50,
    height: 24,
    "& > p": {
      color: "#fff",
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1,
    },
  },
}));

const Section = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    padding: theme.spacing(1.5),
    gap: theme.spacing(0.75),
    maxHeight: 41,
  },
}))(Box);

function BatchParticular(props) {
  // <---------------Hooks------------------>
  const classes = useStyles(props);

  // <---------------Store States----------->

  // <-------------Component States--------->

  // <-----------------Handlers------------->

  // <-----------------Effects-------------->

  const dividerProps = {
    orientation: "vertical",
    flexItem: true,
    className: classes.divider,
  };

  const {
    BatchName,
    expiryDate,
    bin,
    aisle,
    availableQuantity,
    blockedQuantity,
    amount,
    chargeqty,
    chargeuom,
    BalanceQuantity,
    onBatchQuantityChange,
  } = props;



  return (
    <div className={classes.root}>
      <Section>
        <Tooltip title={BatchName}>
          <Typography className={classes.batchNumber}>{BatchName}</Typography>
        </Tooltip>
        <Dot />
        <TypographyWithIcon
          startAdornment={<ScheduleIcon className={classes.icon} />}
        >
          {moment(expiryDate).format("DD MMM, YYYY")}
        </TypographyWithIcon>
      </Section>
      <StyledDivider {...dividerProps} />
      <Section>
        <div className={classes.binLocation}>
          <div className={classes.binNumber}>
            <StyledTooltip
              title={
                <>
                  BIN&nbsp;<span>{bin}</span>
                </>
              }
            >
              <Typography noWrap>
                BIN&nbsp;<span>{bin}</span>
              </Typography>
            </StyledTooltip>
          </div>

          <GroceryShelfIcon />

          <Typography className={classes.aisleShelfLevel}>
            {aisle}
            {/* &nbsp;-&nbsp;S15&nbsp;-&nbsp;{aisle} */}
          </Typography>
        </div>
      </Section>
      <StyledDivider {...dividerProps} />
      <Section>
        <TypographyWithIcon startAdornment={<StackOverflowIcon />}>
          {availableQuantity}
        </TypographyWithIcon>
        <Dot />
        <TypographyWithIcon startAdornment={<NoAccessIcon />}>
          {blockedQuantity}
        </TypographyWithIcon>
        <Dot />
        <TypographyWithIcon startAdornment={<DollarIcon />}>
          {amount}
        </TypographyWithIcon>
      </Section>
      <div style={{ flex: 1 }}></div>
      <StyledDivider {...dividerProps} />
      <Section>
        <div className={classes.inputContainer}>
          <Typography className={classes.inputPrefix}>Qty&nbsp;:</Typography>
          {!props?.isCardVerify ? <StyledInput
            id={"quantity"}
            type={"text"}
            variant={"outlined"}
            size={"small"}
            fullWidth={true}
            value={chargeqty}
            onChange={(e) => {
              onBatchQuantityChange(e.target.value, (props?.selectedState?.toLowerCase() === 'filling'),
                props.isIntravenous
                  ? "additives"
                  : props.isCompound
                    ? "compounds"
                    : "brands")
            }}
            // onChange={(e) => onBatchQuantityChange(e.target.value)}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            endAdornment={<Typography>{chargeuom}</Typography>}
          ></StyledInput> : <div style={{ display: "flex", width: "54px" }}>{chargeqty + ' ' + chargeuom}</div>}
        </div>

        {/* NOTE: To Preserve UI for above input field in the same place, 
        during integration render this with visibility:"hidden" & user-select: "none" in CSS  */}
        <div className={classes.addedDrugContainer}>
          <StackIcon style={{ minWidth: 16 }} />
          <Typography>{BalanceQuantity}</Typography>
        </div>
      </Section>
    </div>
  );
}

export default BatchParticular;
