import React from "react";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { getFormatForDateTimePicker, makeName } from "../../utils";

import { Grid, Box, Avatar, Typography } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
import moment from "moment";
import TimeComponent from "../timeComponent";

function Encounter(props) {
  const { propsState, activeIndex, classes } = props;

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission
  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          {propsState?.map((item, index) => {
            if (activeIndex === index) {
              return (
                <Grid
                  key={item?._key}
                  id={"encounter_info_grid"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  aliasName={"encounterInfoGrid"}
                  item={true}
                  lg={"auto"}
                  md={"auto"}
                  sm={"auto"}
                  xs={"auto"}
                  xl={"auto"}
                  style={qdmstyles.xIIK}
                  className={classes.MainBackgroundCard}
                >
                  <Grid
                    id={"encounter_logo_div"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    aliasName={"encounterLogoDiv"}
                    item={true}
                    lg={"1"}
                    md={"1"}
                    sm={"1"}
                    xs={"1"}
                  >
                    <Box
                      id={"encounter_avatar_div"}
                      m={"1"}
                      component={"div"}
                      aliasName={"encounterAvatarDiv"}
                      style={qdmstyles.cjoEx}
                    >
                      <Avatar
                        id={"encounter_logo"}
                        alt={"Avatar"}
                        variant={"rounded"}
                        aliasName={"encounterLogo"}
                        src={
                          "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316358414"
                        }
                        style={qdmstyles.iPrn}
                      ></Avatar>
                    </Box>
                  </Grid>
                  <Grid
                    id={"encounter_contents_div"}
                    container={false}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    aliasName={"encounterContentsDiv"}
                    item={true}
                    lg={"11"}
                    md={"11"}
                    sm={"11"}
                    xs={"11"}
                    style={qdmstyles.MteUe}
                  >
                    <Grid
                      id={"encounter_header"}
                      container={false}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      aliasName={"encounterHeader"}
                      style={qdmstyles.pMr}
                    >
                      <Typography
                        id={"encounter_id"}
                        noWrap
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        title={`EN-${item?._key}`}
                        children={`EN-${item?._key ?? ""}`}
                        aliasName={"encounterId"}
                        className={classes.Maintitle}
                        style={qdmstyles.KYAR}
                      ></Typography>
                      <Box
                        id={"dots"}
                        m={"1"}
                        component={"div"}
                        aliasName={"dot_id"}
                        style={qdmstyles.QtFCK}
                      ></Box>
                      <Typography
                        id={"encounter_Op"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={item?.type ?? ""}
                        aliasName={"encounterOp"}
                        className={classes.Maintitle}
                        style={qdmstyles.Piuad}
                      ></Typography>
                      <Box
                        id={"dots_op"}
                        m={"1"}
                        component={"div"}
                        aliasName={"dot_op"}
                        style={qdmstyles.wfadQ}
                      ></Box>
                      <Typography
                        key={"4"}
                        noWrap
                        id={"encounter_follow"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        title={item?.visit_type}
                        children={item?.visit_type ?? ""}
                        aliasName={"encounterFollow"}
                        className={classes.Maintitle}
                        style={qdmstyles.cdBm}
                      ></Typography>
                      <Box
                        id={"dots_follow"}
                        m={"1"}
                        component={"div"}
                        aliasName={"dot_follow"}
                        style={qdmstyles.tnrp}
                      ></Box>
                      <Typography
                        noWrap
                        id={"encounter_select"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={item?.speciality?.[0] ?? ""}
                        aliasName={"encounterSelect"}
                        className={classes.Maintitle}
                        style={qdmstyles.ffkg}
                      ></Typography>
                    </Grid>
                    {/* encounter_information */}
                    <Grid
                      id={"qenLW"}
                      container={false}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      aliasName={"encounterInformation"}
                      comment={"encounter_information"}
                      item={false}
                      style={qdmstyles.qenLW}
                    >
                      <Box
                        id={"time_logo_div"}
                        m={"1"}
                        component={"div"}
                        aliasName={"timeLogoDiv"}
                        style={qdmstyles.DBy}
                      >
                        <Box
                          id={"clock_box"}
                          m={"1"}
                          component={"div"}
                          aliasName={"clockBox"}
                        >
                          <Avatar
                            id={"wgJTi"}
                            alt={"Avatar"}
                            variant={"rounded"}
                            src={
                              "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316382595"
                            }
                            style={qdmstyles.wgJTi}
                          ></Avatar>
                        </Box>

                        {item?.encounterdate ? (
                          <TimeComponent
                            noWrap
                            title={moment
                              .unix(item?.encounterdate)
                              .format(getFormatForDateTimePicker("date"))}
                            id={"fhBKO"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            dateTime={item?.encounterdate}
                            style={qdmstyles.fhBKO}
                          />
                        ) : (
                          " N/A"
                        )}
                      </Box>
                      <Box
                        id={"ward_div"}
                        m={"1"}
                        component={"div"}
                        aliasName={"wardDiv"}
                        style={qdmstyles.ucl}
                      >
                        {item?.location && (
                          <Box
                            id={"ward_box"}
                            m={"1"}
                            component={"div"}
                            aliasName={"wardBox"}
                          >
                            <Avatar
                              id={"ward_logo"}
                              alt={"Avatar"}
                              variant={"rounded"}
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317076717"
                              }
                              aliasName={"wardLogo"}
                              style={qdmstyles.aKX}
                            ></Avatar>
                          </Box>
                        )}
                        <Typography
                          noWrap
                          id={"ward_type"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={item?.location ?? ""}
                          aliasName={"wardType"}
                          style={qdmstyles.FaYb}
                        ></Typography>
                      </Box>
                    </Grid>
                    <Grid
                      id={"qenLW"}
                      container={false}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      aliasName={"encounterInformation"}
                      comment={"encounter_information"}
                      item={false}
                      style={qdmstyles.qenLW}
                    >
                      <Typography
                        noWrap
                        title={item?.Practitioner_id[0]?.position?.[0] || ""}
                        id={"fhBKO"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={item?.Practitioner_id[0]?.position?.[0] || ""}
                        style={qdmstyles.UBRcn}
                      ></Typography>
                      {item?.Practitioner_id[0]?.name?.[0] && (
                        <Box
                          id={"dotsDoctor"}
                          m={"1"}
                          component={"div"}
                          aliasName={"dot_doctor"}
                          style={qdmstyles.FZW}
                        ></Box>
                      )}
                      <Typography
                        noWrap
                        title={makeName(
                          item?.Practitioner_id[0]?.name?.[0] || {}
                        )}
                        id={"fhBKO"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={makeName(
                          item?.Practitioner_id[0]?.name?.[0] || {}
                        )}
                        style={qdmstyles.UBRcn}
                      ></Typography>
                      {item?.Practitioner_id[0]?.telecom?.[0]?.value && (
                        <Box
                          id={"dotsDoctorPhone"}
                          m={"1"}
                          component={"div"}
                          aliasName={"dot_doctor_phone"}
                          style={qdmstyles.FZW}
                        ></Box>
                      )}
                      <Typography
                        noWrap
                        title={`${
                          item?.Practitioner_id[0]?.telecom?.[0]?.valueprefix ??
                          ""
                        } ${
                          item?.Practitioner_id[0]?.telecom?.[0]?.value ?? ""
                        }`}
                        id={"fhBKO"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={`${
                          item?.Practitioner_id[0]?.telecom?.[0]?.valueprefix ??
                          ""
                        } ${
                          item?.Practitioner_id[0]?.telecom?.[0]?.value ?? ""
                        }`}
                        style={qdmstyles.UBRcn}
                      ></Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            } else {
              return null;
            }
          })}
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Encounter));
