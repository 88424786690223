import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Chip,
  Avatar,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDividerStyles } from "./styles";
import React, { useRef, useLayoutEffect } from "react";
import {
  PillDark,
  TaskIcon,
  Pills,
  DocumentBlack,
  Clock,
  Calender,
  Diagnosis,
  Speciality,
  LocationIcon,
  StethoscopeIcon,
} from "../../../../../assets";

const useStyles = makeStyles({
  align: {
    marginLeft: "30px",
    marginTop: "20px",
  },
  align2: {
    marginTop: "20px",
  },
  heading: {
    fontSize: "12px !important",
    
    marginLeft: "5px",
  },
  header: {
    fontSize: "14px !important",
    color: "#2A3C50",
    fontWeight: 600,
  },
  subContents: {
    fontSize: "14px !important",
    color: "#2A3C50",
    marginLeft: "18px",
  },
  secondline: {
    marginTop: "24px",
  },
  card: {
    minWidth: 175,
  },
  root: {
    borderRadius: "13px",
    "& .App1-MuiCardContent-root": {
      padding: "0px !important",
    },
  },
  chip: {
    height: "15px",
    borderRadius: "0px",
    marginLeft: "5px",
  },
  flexALign: {
    display: "flex",
    alignItems: "center",
  },
  backgroundLabel: {
    backgroundColor: "#DEE5EC",
    padding: "4px 8px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    marginRight: "8px",
  },
  dot: {
    margin: "0px 6px",
    backgroundColor: "#B4BBC3",
    padding: "2px",
    borderRadius: "50%",
  },
});

function OrderInfoIV(props) {
  const classes = useStyles();
  const theme = useTheme();
  const dividerStyles = useDividerStyles();
  return (
    <>
      <div style={{ display: "flex" }}>
        <Typography className={classes.header}>DRUG INFO</Typography>
        <Divider
          orientation="horizontal"
          flexItem
          classes={dividerStyles}
          style={{ margin: theme.spacing(0, 1) }}
        />
      </div>

      <div className={classes.flexALign} style={{ marginTop: "12px" }}>
        <div style={{ marginRight: "24px" }}>
          <div className={classes.flexALign}>
            <PillDark color={"#01205C"} />
            <Typography className={classes.heading}>Drug type</Typography>
          </div>
          <Typography className={classes.subContents}>IV</Typography>
        </div>
        <div style={{ marginRight: "24px" }}>
          <div className={classes.flexALign}>
            <TaskIcon color={"#01205C"} />
            <Typography className={classes.heading}>
              Order Description
            </Typography>
          </div>
          <Typography className={classes.subContents}>
            KCL 8meq + MgSO4 4meq in NS 1000ml @ 250CC/Hr
          </Typography>
        </div>
      </div>

      <div className={classes.flexALign} style={{ marginTop: "24px" }}>
        <div style={{ marginRight: "24px" }}>
          <div className={classes.flexALign}>
            <TaskIcon color={"#01205C"} />
            <Typography className={classes.heading}>Base Solution</Typography>
          </div>
          <Typography className={classes.subContents}>
            Dextrose Saline 1000ml
          </Typography>
        </div>
        <div style={{ marginRight: "24px" }}>
          <div className={classes.flexALign}>
            <Pills color={"#01205C"} />
            <Typography className={classes.heading}>Dosage</Typography>
          </div>
          <Typography className={classes.subContents}>1000mlr</Typography>
        </div>
      </div>

      <div style={{ marginTop: "24px" }}>
        <div className={classes.flexALign}>
          <DocumentBlack color={"#01205C"} />
          <Typography className={classes.heading}>Additives</Typography>
        </div>
        <div style={{ display: "flex", marginLeft: "18px" }}>
          <div className={classes.backgroundLabel}>
            <Typography
              className={classes.subContents}
              style={{ color: "#101010", marginLeft: "0px" }}
            >
              Potassium Chloride(2meq/ml)
            </Typography>
            <div className={classes.dot}></div>
            <Pills color={"#01205C"} />
            <Typography
              className={classes.subContents}
              style={{ color: "#101010", marginLeft: "8px" }}
            >
              4meq
            </Typography>
          </div>
          <div className={classes.backgroundLabel}>
            <Typography
              className={classes.subContents}
              style={{ color: "#101010", marginLeft: "0px" }}
            >
              MegnesiumSulphate 4meq/ml)
            </Typography>
            <div className={classes.dot}></div>
            <Pills color={"#01205C"} />
            <Typography
              className={classes.subContents}
              style={{ color: "#101010", marginLeft: "8px" }}
            >
              8meq
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.flexALign} style={{ marginTop: "24px" }}>
        <div style={{ marginRight: "24px" }}>
          <div className={classes.flexALign}>
            <PillDark color={"#01205C"} />
            <Typography className={classes.heading}>
              Infusion Rate Volume
            </Typography>
          </div>
          <Typography className={classes.subContents}>250CC</Typography>
        </div>
        <div style={{ marginRight: "24px" }}>
          <div className={classes.flexALign}>
            <Clock color={"#01205C"} />
            <Typography className={classes.heading}>
              Infuse Over Duration
            </Typography>
          </div>
          <Typography className={classes.subContents}>4 hrs</Typography>
        </div>
        <div style={{ marginRight: "24px" }}>
          <div className={classes.flexALign}>
            <Clock color={"#01205C"} />
            <Typography className={classes.heading}>Duration</Typography>
          </div>
          <Typography className={classes.subContents}>24 hrs</Typography>
        </div>
        <div style={{ marginRight: "24px" }}>
          <div className={classes.flexALign}>
            <Calender color={"#01205C"} />
            <Typography className={classes.heading}>Start Date</Typography>
          </div>
          <Typography className={classes.subContents}>
            24-03-2022 10:30
          </Typography>
        </div>
        <div style={{ marginRight: "24px" }}>
          <div className={classes.flexALign}>
            <Calender color={"#01205C"} />
            <Typography className={classes.heading}>End Date</Typography>
          </div>
          <Typography className={classes.subContents}>
            30-03-2022 10:30
          </Typography>
        </div>
      </div>

      <div className={classes.flexALign} style={{ marginTop: "24px" }}>
        <div style={{ marginRight: "24px" }}>
          <div className={classes.flexALign}>
            <Pills color={"#01205C"} />
            <Typography className={classes.heading}>Route</Typography>
          </div>
          <Typography className={classes.subContents}>Intravenous</Typography>
        </div>
        <div style={{ marginRight: "24px" }}>
          <div className={classes.flexALign}>
            <DocumentBlack color={"#01205C"} />
            <Typography className={classes.heading}>Priority</Typography>
          </div>
          <Typography className={classes.subContents}>Urgent</Typography>
        </div>
        <div style={{ marginRight: "24px" }}>
          <div className={classes.flexALign}>
            <DocumentBlack color={"#01205C"} />
            <Typography className={classes.heading}>Drug Category</Typography>
          </div>
          <Typography className={classes.subContents}>Narcotic</Typography>
        </div>
        <div style={{ marginRight: "24px" }}>
          <div className={classes.flexALign}>
            <DocumentBlack color={"#01205C"} />
            <Typography className={classes.heading}>Mode</Typography>
          </div>
          <Typography className={classes.subContents}>Regular</Typography>
        </div>
        <div style={{ marginRight: "24px" }}>
          <div className={classes.flexALign}>
            <DocumentBlack color={"#01205C"} />
            <Typography className={classes.heading}>Nature</Typography>
          </div>
          <Typography className={classes.subContents}>Pre-Admission</Typography>
        </div>
      </div>

      <div style={{ marginTop: "24px" }}>
        <div className={classes.flexALign}>
          <StethoscopeIcon color={"#01205C"} />
          <Typography className={classes.heading}>Diagnosis</Typography>
        </div>
        <div style={{ display: "flex", marginLeft: "18px" }}>
          <div className={classes.backgroundLabel}>
            <Typography
              className={classes.subContents}
              style={{ color: "#101010", marginLeft: "0px" }}
            >
              Benign essential hypertension
            </Typography>
          </div>
        </div>
      </div>

      {/* <!---------------Practioner info-------------> */}

      <div style={{ marginTop: "16px" }}>
        <div style={{ display: "flex" }}>
          <Typography className={classes.header} style={{ marginLeft: "0px" }}>
            PRACTIONER INFO
          </Typography>
          <Divider
            orientation="horizontal"
            flexItem
            classes={dividerStyles}
            style={{ margin: theme.spacing(0, 1), minWidth: "83%" }}
          />
        </div>
        <div style={{ width: "80%", marginTop: "10px" }}>
          <Card variant="outlined" className={classes.root}>
            <CardContent>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "12px",
                    paddingTop: "12px",
                    paddingLeft: "12px",
                  }}
                >
                  <div>
                    <Avatar variant="rounded">A</Avatar>
                  </div>
                  <div
                    style={{
                      borderRight: "1px solid #DEE5EC",
                      paddingRight: "18px",
                    }}
                  >
                    <div>
                      <Typography className={classes.subContents}>
                        Dr. Roger Fox
                      </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        className={classes.heading}
                        style={{ marginLeft: "18px" }}
                      >
                        ID 34562
                      </Typography>
                      <div
                        style={{ backgroundColor: "#6A7888" }}
                        className={classes.dot}
                      ></div>
                      <Typography className={classes.heading}>
                        +91 95854 25622
                      </Typography>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "12px",
                    paddingTop: "12px",
                    paddingLeft: "12px",
                    paddingBottom: "16px",
                  }}
                >
                  <div>
                    <div className={classes.flexALign}>
                      <Speciality />
                      <Typography className={classes.heading}>
                        Speciality Name
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.subContents}>
                        Cardiology
                      </Typography>
                    </div>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <div className={classes.flexALign}>
                      <LocationIcon />
                      <Typography className={classes.heading}>
                        Clinic
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.subContents}>
                        Neoro Clinic Room
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default OrderInfoIV;
