import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { getDifferenceByLeftArray, WEEKDAYS } from "../../../../../utils";

const CustomCheckbox = withStyles({
  root: {
    color: "rgba(42, 96, 188, 1)",
    "&$checked": {
      color: "rgba(42, 96, 188, 1)",
    },
  },
  checked: {},
})((props) => <Checkbox size="small" color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "row",
    gap: 10,
  },
  labelRoot: {
    margin: "0px",
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

function WeekDays({
  selectedDays = [],
  handleWeekdaysChange,
  options,
  selectedWeekdays = [],
}) {
  const classes = useStyles();
  const [checkedWeekdays, setCheckedWeekdays] = React.useState([]);

  const mappedSD = selectedDays?.map((x) => x?._id);
  const mappedSWD = selectedWeekdays?.map((x) => x?._id);
  const differenceArray = getDifferenceByLeftArray(mappedSWD, mappedSD);

  // useEffect(() => {
  //   if (selectedDays) {
  //     setCheckedWeekdays(selectedDays);
  //   }
  // }, [selectedDays]);

  // handleChange = (day, checked) => {

  //   handleWeekdaysChange()
  // }

  return (
    <FormGroup className={classes.root}>
      {options?.map((day) => {
        const isChecked =
          Array.isArray(selectedDays) &&
          selectedDays?.map((item) => item?._id)?.indexOf(day?._id) > -1
            ? true
            : false;
        return (
          <FormControlLabel
            disabled={
              mappedSWD?.indexOf(day?._id) > -1 &&
              differenceArray?.indexOf(day?._id) > -1
            }
            control={
              <CustomCheckbox
                checked={isChecked}
                onChange={() => handleWeekdaysChange(day, !isChecked)}
                name={day?.label}
              />
            }
            label={day?.label}
            classes={{ root: classes.labelRoot, label: classes.label }}
          />
        );
      })}
    </FormGroup>
  );
}

export default WeekDays;
