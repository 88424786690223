import React from "react";
import { CommonTable,
  // GenerateForm
 } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";

function DrugCategory(props) {
  const dispatch = useDispatch();

  const drugCategorylist = useSelector((state) => state?.drugCategorySlice?.drug_category_list?.data);

  React.useLayoutEffect(() => {
    dispatch(actions.DRUG_CATEGORY_READ());
  }, [])
  // handleCloseForm = () => {
  //     const { changeState } = this.props
  //     changeState("open", false)
  //     this.setState({
  //         openEditFrom: false,
  //         edit_data: null
  //     })
  // }
  //const { open, openIndex, parent_id } = props;
  return <React.Fragment>
    <CommonTable
      style={{ height: "calc(100vh - 245px)" }}
      parent_id={"Drug-Category"}
      // handleEdit={this.handleEdit}
      Header={["S.No", "Drug Category", "Short Description", "Long Description"]}
      dataList={drugCategorylist}
      tableData={[
        { type: ["INCRIMENT"], name: "" },
        { type: ["TEXT"], name: "drug_category" },
        { type: ["TEXT"], name: "short_description" },
        { type: ["TEXT"], name: "long_description" },
      ]}
    />
    {/* <GenerateForm 
                open={(open && openIndex === 2) }
                handlecancel={() => this.handleCloseForm()}
                    header={'Add Form'}
                    json = {[{
                      componet_type: "text",
                      label: "Drug Category",
                      state_name: "drug_category",
                      value: this.state?.edit_data?.code??"",
                      required: true
                    },
                    {
                      componet_type: "text",
                      label: "Short Description",
                      state_name: "short_Description",
                      value: this.state?.edit_data?.description??"",
                      required: true
                    },
                    {
                        componet_type: "text",
                        label: "Long Description",
                        state_name: "long_Description",
                        value: this.state?.edit_data?.description??"",
                        required: true
                      },
                      {
                        componet_type: "switch",
                        label: "Status",
                        state_name: "status",
                        value: this.state?.edit_data?.status??false
                      }
                  ]}
                 /> */}
  </React.Fragment>
}

export default DrugCategory