import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Box,
  Divider,
  Checkbox,
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import qdmstyles from "./styles.json";
import "./index.css";
var aes256 = require("aes256");

function Frequencydefinition(props) {
  const dispatch = useDispatch();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {}, [state]);

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"bHcZj"}
            container={true}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            lg={"12"}
            aliasName={"user defined"}
            style={qdmstyles.bHcZj}
          >
            <Paper
              key={"0"}
              id={"FImGi"}
              elevation={"1"}
              variant={"elevation"}
              style={qdmstyles.FImGi}
            >
              <Typography
                key={"0"}
                id={"3zKDA"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={"FREQUENCY DEFINITION"}
                style={qdmstyles.zKDA}
              ></Typography>
              <Grid
                key={"1"}
                id={"2WGYO"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                spacing={"2"}
                style={qdmstyles.WGYO}
              >
                <Grid
                  key={"0"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Code"}
                  ></Typography>
                  <TextField
                    key={"1"}
                    id={"VWbB8"}
                    label={""}
                    placeholder={"TAPER"}
                    type={"text"}
                    variant={"outlined"}
                    margin={"dense"}
                    fullWidth={true}
                    style={qdmstyles.VWbB}
                  ></TextField>
                </Grid>
                <Grid
                  key={"1"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"4"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Name"}
                  ></Typography>
                  <TextField
                    key={"1"}
                    id={"2TiFD"}
                    label={""}
                    placeholder={"Tapering"}
                    type={"text"}
                    fullWidth={true}
                    variant={"outlined"}
                    margin={"dense"}
                    style={qdmstyles.TiFD}
                  ></TextField>
                </Grid>
                <Grid
                  key={"2"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Type"}
                  ></Typography>
                  <Autocomplete
                    id={"t7znW"}
                    style={qdmstyles?.tznW}
                    value={state?.frequencydefinition_t7znw ?? null}
                    onLoad={() =>
                      setState({
                        frequencydefinition_t7znw:
                          state?.frequencydefinition_t7znw ?? null,
                      })
                    }
                    getOptionLabel={(option) => option?.["label"]}
                    options={[]}
                    onChange={(e, newvalue) =>
                      setState({ frequencydefinition_t7znw: newvalue })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"label"}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                key={"2"}
                id={"2WGYO"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                spacing={"2"}
                style={qdmstyles.siP}
              >
                <Grid
                  key={"0"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                  md={"2"}
                  sm={"3"}
                  style={qdmstyles.AKH}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Dose Per Day"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.KwQJ}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.LwxMg}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Typography
                      key={"2"}
                      id={"h9zkG"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Times"}
                      style={qdmstyles.BAFm}
                    ></Typography>
                  </Box>
                </Grid>
                <Grid
                  key={"1"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                  md={"2"}
                  sm={"3"}
                  style={qdmstyles.cAAm}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Dispense Grace Period"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.rDrx}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.zmdwf}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Typography
                      key={"2"}
                      id={"h9zkG"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Hrs"}
                      style={qdmstyles.yrCkt}
                    ></Typography>
                  </Box>
                </Grid>
                <Grid
                  key={"2"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                  md={"3"}
                  sm={"4"}
                  style={qdmstyles.fcFGN}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Fixed Duration"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.PXWS}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.geveT}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Autocomplete
                      id={"8cdb1"}
                      style={qdmstyles?.cdb}
                      value={state?.frequencydefinition_8cdb1 ?? null}
                      onLoad={() =>
                        setState({
                          frequencydefinition_8cdb1:
                            state?.frequencydefinition_8cdb1 ?? null,
                        })
                      }
                      getOptionLabel={(option) => option?.["label"]}
                      options={[]}
                      onChange={(e, newvalue) =>
                        setState({ frequencydefinition_8cdb1: newvalue })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"label"}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid
                  key={"3"}
                  id={"FTOds"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"stretch"}
                  lg={"2"}
                  item={true}
                  alignContent={"stretch"}
                  md={"2"}
                  sm={"2"}
                  style={qdmstyles.tVGDN}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Is Base Frequency"}
                  ></Typography>
                  <Checkbox
                    key={"1"}
                    id={"Wr5U5"}
                    checked={true}
                    color={"default"}
                    size={"small"}
                  ></Checkbox>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid
            key={"1"}
            id={"bHcZj"}
            container={true}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            lg={"12"}
            aliasName={"interval"}
          >
            <Paper
              key={"0"}
              id={"FImGi"}
              elevation={"1"}
              variant={"elevation"}
              style={qdmstyles.BjB}
            >
              <Typography
                key={"0"}
                id={"3zKDA"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={"FREQUENCY DEFINITION"}
                style={qdmstyles.DSk}
              ></Typography>
              <Grid
                key={"1"}
                id={"2WGYO"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                spacing={"2"}
                style={qdmstyles.afJVT}
              >
                <Grid
                  key={"0"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Code"}
                  ></Typography>
                  <TextField
                    key={"1"}
                    id={"VWbB8"}
                    label={""}
                    placeholder={"TAPER"}
                    type={"text"}
                    variant={"outlined"}
                    margin={"dense"}
                    fullWidth={true}
                    style={qdmstyles.axUUH}
                  ></TextField>
                </Grid>
                <Grid
                  key={"1"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"4"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Name"}
                  ></Typography>
                  <TextField
                    key={"1"}
                    id={"2TiFD"}
                    label={""}
                    placeholder={"Tapering"}
                    type={"text"}
                    fullWidth={true}
                    variant={"outlined"}
                    margin={"dense"}
                    style={qdmstyles.MrD}
                  ></TextField>
                </Grid>
                <Grid
                  key={"2"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Type"}
                  ></Typography>
                  <Autocomplete
                    id={"e69bu"}
                    style={qdmstyles?.ebu}
                    value={state?.frequencydefinition_e69bu ?? null}
                    onLoad={() =>
                      setState({
                        frequencydefinition_e69bu:
                          state?.frequencydefinition_e69bu ?? null,
                      })
                    }
                    getOptionLabel={(option) => option?.["label"]}
                    options={[]}
                    onChange={(e, newvalue) =>
                      setState({ frequencydefinition_e69bu: newvalue })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"label"}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                key={"2"}
                id={"2WGYO"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                spacing={"2"}
                style={qdmstyles.tJtP}
              >
                <Grid
                  key={"0"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                  md={"2"}
                  sm={"3"}
                  style={qdmstyles.Ujl}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Once Every"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.dOY}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.UiLR}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Autocomplete
                      id={"JjLvl"}
                      style={qdmstyles?.JjLvl}
                      value={state?.frequencydefinition_jjlvl ?? null}
                      onLoad={() =>
                        setState({
                          frequencydefinition_jjlvl:
                            state?.frequencydefinition_jjlvl ?? null,
                        })
                      }
                      getOptionLabel={(option) => option?.["label"]}
                      options={[]}
                      onChange={(e, newvalue) =>
                        setState({ frequencydefinition_jjlvl: newvalue })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"label"}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid
                  key={"1"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                  md={"2"}
                  sm={"3"}
                  style={qdmstyles.WVyE}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Dispense Grace Period"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.TMaru}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.tLsN}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Typography
                      key={"2"}
                      id={"h9zkG"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Hrs"}
                      style={qdmstyles.FmZKb}
                    ></Typography>
                  </Box>
                </Grid>
                <Grid
                  key={"2"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                  md={"3"}
                  sm={"4"}
                  style={qdmstyles.xdII}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Fixed Duration"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.inyu}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.xfh}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Autocomplete
                      id={"uWGhb"}
                      style={qdmstyles?.uWGhb}
                      value={state?.frequencydefinition_uwghb ?? null}
                      onLoad={() =>
                        setState({
                          frequencydefinition_uwghb:
                            state?.frequencydefinition_uwghb ?? null,
                        })
                      }
                      getOptionLabel={(option) => option?.["label"]}
                      options={[]}
                      onChange={(e, newvalue) =>
                        setState({ frequencydefinition_uwghb: newvalue })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"label"}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid
                  key={"3"}
                  id={"FTOds"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"stretch"}
                  lg={"2"}
                  item={true}
                  alignContent={"stretch"}
                  md={"2"}
                  sm={"2"}
                  style={qdmstyles.mMmM}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Is Base Frequency"}
                  ></Typography>
                  <Checkbox
                    key={"1"}
                    id={"Wr5U5"}
                    checked={true}
                    color={"default"}
                    size={"small"}
                  ></Checkbox>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid
            key={"2"}
            id={"bHcZj"}
            container={true}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            lg={"12"}
            aliasName={"sequential"}
          >
            <Paper
              key={"0"}
              id={"FImGi"}
              elevation={"1"}
              variant={"elevation"}
              style={qdmstyles.JwVnV}
            >
              <Typography
                key={"0"}
                id={"3zKDA"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={"FREQUENCY DEFINITION"}
                style={qdmstyles.Gj}
              ></Typography>
              <Grid
                key={"1"}
                id={"2WGYO"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                spacing={"2"}
                style={qdmstyles.ILqvt}
              >
                <Grid
                  key={"0"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Code"}
                  ></Typography>
                  <TextField
                    key={"1"}
                    id={"VWbB8"}
                    label={""}
                    placeholder={"TAPER"}
                    type={"text"}
                    variant={"outlined"}
                    margin={"dense"}
                    fullWidth={true}
                    style={qdmstyles.Uqfgl}
                  ></TextField>
                </Grid>
                <Grid
                  key={"1"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"4"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Name"}
                  ></Typography>
                  <TextField
                    key={"1"}
                    id={"2TiFD"}
                    label={""}
                    placeholder={"Tapering"}
                    type={"text"}
                    fullWidth={true}
                    variant={"outlined"}
                    margin={"dense"}
                    style={qdmstyles.ChKu}
                  ></TextField>
                </Grid>
                <Grid
                  key={"2"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Type"}
                  ></Typography>
                  <Autocomplete
                    id={"kMeM0"}
                    style={qdmstyles?.kMeM}
                    value={state?.frequencydefinition_kmem0 ?? null}
                    onLoad={() =>
                      setState({
                        frequencydefinition_kmem0:
                          state?.frequencydefinition_kmem0 ?? null,
                      })
                    }
                    getOptionLabel={(option) => option?.["label"]}
                    options={[]}
                    onChange={(e, newvalue) =>
                      setState({ frequencydefinition_kmem0: newvalue })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"label"}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                key={"2"}
                id={"2WGYO"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                spacing={"2"}
                style={qdmstyles.PHT}
              >
                <Grid
                  key={"0"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                  md={"2"}
                  sm={"3"}
                  style={qdmstyles.RpHqD}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Interval Days"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.eWXce}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.puQZ}
                    ></TextField>
                  </Box>
                </Grid>
                <Grid
                  key={"1"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                  md={"3"}
                  sm={"3"}
                  style={qdmstyles.WKZEw}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Dispense Grace Period"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.LuHB}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.cCHz}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Typography
                      key={"2"}
                      id={"h9zkG"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Hrs"}
                      style={qdmstyles.vuBF}
                    ></Typography>
                  </Box>
                </Grid>
                <Grid
                  key={"2"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                  md={"3"}
                  sm={"4"}
                  style={qdmstyles.Rw}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Fixed Duration"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.DOIg}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.LaCtt}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Autocomplete
                      id={"vVa33"}
                      style={qdmstyles?.vVa}
                      value={state?.frequencydefinition_vva33 ?? null}
                      onLoad={() =>
                        setState({
                          frequencydefinition_vva33:
                            state?.frequencydefinition_vva33 ?? null,
                        })
                      }
                      getOptionLabel={(option) => option?.["label"]}
                      options={[]}
                      onChange={(e, newvalue) =>
                        setState({ frequencydefinition_vva33: newvalue })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"label"}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid
                  key={"3"}
                  id={"FTOds"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"stretch"}
                  lg={"2"}
                  item={true}
                  alignContent={"stretch"}
                  md={"2"}
                  sm={"2"}
                  style={qdmstyles.sfNv}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Is Base Frequency"}
                  ></Typography>
                  <Checkbox
                    key={"1"}
                    id={"Wr5U5"}
                    checked={true}
                    color={"default"}
                    size={"small"}
                  ></Checkbox>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid
            key={"3"}
            id={"bHcZj"}
            container={true}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            lg={"12"}
            aliasName={"details"}
            style={qdmstyles.AvVr}
          >
            <Paper
              key={"0"}
              id={"FImGi"}
              elevation={"1"}
              variant={"elevation"}
              style={qdmstyles.NOuU}
            >
              <Typography
                key={"0"}
                id={"3zKDA"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={"FREQUENCY DEFINITION"}
                style={qdmstyles.lbaUU}
              ></Typography>
              <Grid
                key={"1"}
                id={"2WGYO"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                spacing={"2"}
                style={qdmstyles.OtSJR}
              >
                <Grid
                  key={"0"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Code"}
                  ></Typography>
                  <TextField
                    key={"1"}
                    id={"VWbB8"}
                    label={""}
                    placeholder={"TAPER"}
                    type={"text"}
                    variant={"outlined"}
                    margin={"dense"}
                    fullWidth={true}
                    style={qdmstyles.Rowwd}
                  ></TextField>
                </Grid>
                <Grid
                  key={"1"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"4"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Name"}
                  ></Typography>
                  <TextField
                    key={"1"}
                    id={"2TiFD"}
                    label={""}
                    placeholder={"Tapering"}
                    type={"text"}
                    fullWidth={true}
                    variant={"outlined"}
                    margin={"dense"}
                    style={qdmstyles.EnmV}
                  ></TextField>
                </Grid>
                <Grid
                  key={"2"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Type"}
                  ></Typography>
                  <Autocomplete
                    id={"O5nPo"}
                    style={qdmstyles?.OnPo}
                    value={state?.frequencydefinition_o5npo ?? null}
                    onLoad={() =>
                      setState({
                        frequencydefinition_o5npo:
                          state?.frequencydefinition_o5npo ?? null,
                      })
                    }
                    getOptionLabel={(option) => option?.["label"]}
                    options={[]}
                    onChange={(e, newvalue) =>
                      setState({ frequencydefinition_o5npo: newvalue })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"label"}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                key={"2"}
                id={"2WGYO"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                spacing={"2"}
                style={qdmstyles.qoKNV}
              >
                <Grid
                  key={"0"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                  md={"2"}
                  sm={"3"}
                  style={qdmstyles.vWtV}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Dose Per Day"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.Sxss}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.uJw}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Typography
                      key={"2"}
                      id={"h9zkG"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Times"}
                      style={qdmstyles.CCkR}
                    ></Typography>
                  </Box>
                </Grid>
                <Grid
                  key={"1"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                  md={"3"}
                  sm={"3"}
                  style={qdmstyles.BQaD}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Dispense Grace Period"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.Myvp}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.jLJF}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Typography
                      key={"2"}
                      id={"h9zkG"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Hrs"}
                      style={qdmstyles.iiV}
                    ></Typography>
                  </Box>
                </Grid>
                <Grid
                  key={"2"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                  md={"3"}
                  sm={"4"}
                  style={qdmstyles.UEeIj}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Fixed Duration"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.GUccc}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.dFq}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Autocomplete
                      id={"6tQYy"}
                      style={qdmstyles?.tQYy}
                      value={state?.frequencydefinition_6tqyy ?? null}
                      onLoad={() =>
                        setState({
                          frequencydefinition_6tqyy:
                            state?.frequencydefinition_6tqyy ?? null,
                        })
                      }
                      getOptionLabel={(option) => option?.["label"]}
                      options={[]}
                      onChange={(e, newvalue) =>
                        setState({ frequencydefinition_6tqyy: newvalue })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"label"}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid
                  key={"3"}
                  id={"FTOds"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"stretch"}
                  lg={"2"}
                  item={true}
                  alignContent={"stretch"}
                  md={"2"}
                  sm={"2"}
                  style={qdmstyles.eNGzU}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Is Base Frequency"}
                  ></Typography>
                  <Checkbox
                    key={"1"}
                    id={"Wr5U5"}
                    checked={true}
                    color={"default"}
                    size={"small"}
                  ></Checkbox>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid
            key={"4"}
            id={"bHcZj"}
            container={true}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            lg={"12"}
            aliasName={"day based"}
          >
            <Paper
              key={"0"}
              id={"FImGi"}
              elevation={"1"}
              variant={"elevation"}
              style={qdmstyles.oJ}
            >
              <Typography
                key={"0"}
                id={"3zKDA"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={"FREQUENCY DEFINITION"}
                style={qdmstyles.qpims}
              ></Typography>
              <Grid
                key={"1"}
                id={"2WGYO"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                spacing={"2"}
                style={qdmstyles.Zbga}
              >
                <Grid
                  key={"0"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Code"}
                  ></Typography>
                  <TextField
                    key={"1"}
                    id={"VWbB8"}
                    label={""}
                    placeholder={"TAPER"}
                    type={"text"}
                    variant={"outlined"}
                    margin={"dense"}
                    fullWidth={true}
                    style={qdmstyles.oPcd}
                  ></TextField>
                </Grid>
                <Grid
                  key={"1"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"4"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Name"}
                  ></Typography>
                  <TextField
                    key={"1"}
                    id={"2TiFD"}
                    label={""}
                    placeholder={"Tapering"}
                    type={"text"}
                    fullWidth={true}
                    variant={"outlined"}
                    margin={"dense"}
                    style={qdmstyles.IRdu}
                  ></TextField>
                </Grid>
                <Grid
                  key={"2"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Frequency Type"}
                  ></Typography>
                  <Autocomplete
                    id={"hmVU8"}
                    style={qdmstyles?.hmVU}
                    value={state?.frequencydefinition_hmvu8 ?? null}
                    onLoad={() =>
                      setState({
                        frequencydefinition_hmvu8:
                          state?.frequencydefinition_hmvu8 ?? null,
                      })
                    }
                    getOptionLabel={(option) => option?.["label"]}
                    options={[]}
                    onChange={(e, newvalue) =>
                      setState({ frequencydefinition_hmvu8: newvalue })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"label"}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                key={"2"}
                id={"2WGYO"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                spacing={"2"}
                style={qdmstyles.UBApr}
              >
                <Grid
                  key={"0"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"2"}
                  item={true}
                  md={"2"}
                  sm={"3"}
                  style={qdmstyles.EarEp}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Total No Of Days"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.nFnly}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.cmyn}
                    ></TextField>
                  </Box>
                </Grid>
                <Grid
                  key={"1"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                  md={"3"}
                  sm={"3"}
                  style={qdmstyles.BATwC}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Dispense Grace Period"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.HZCuZ}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.FOIB}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Typography
                      key={"2"}
                      id={"h9zkG"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Hrs"}
                      style={qdmstyles.sSp}
                    ></Typography>
                  </Box>
                </Grid>
                <Grid
                  key={"2"}
                  id={"FTOds"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  item={true}
                  md={"3"}
                  sm={"4"}
                  style={qdmstyles.Fszt}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Fixed Duration"}
                  ></Typography>
                  <Box
                    key={"1"}
                    id={"5KwQJ"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.fYdw}
                  >
                    <TextField
                      key={"0"}
                      id={"VWbB8"}
                      label={""}
                      placeholder={"TAPER"}
                      type={"text"}
                      variant={"outlined"}
                      margin={"dense"}
                      style={qdmstyles.EubHB}
                    ></TextField>
                    <Divider
                      key={"1"}
                      id={"78BJ8"}
                      variant={"fullWidth"}
                      flexItem={true}
                      light={false}
                      orientation={"vertical"}
                    ></Divider>
                    <Autocomplete
                      id={"FuoWM"}
                      style={qdmstyles?.FuoWM}
                      value={state?.frequencydefinition_fuowm ?? null}
                      onLoad={() =>
                        setState({
                          frequencydefinition_fuowm:
                            state?.frequencydefinition_fuowm ?? null,
                        })
                      }
                      getOptionLabel={(option) => option?.["label"]}
                      options={[]}
                      onChange={(e, newvalue) =>
                        setState({ frequencydefinition_fuowm: newvalue })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"label"}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid
                  key={"3"}
                  id={"FTOds"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"stretch"}
                  lg={"2"}
                  item={true}
                  alignContent={"stretch"}
                  md={"2"}
                  sm={"2"}
                  style={qdmstyles.ObQa}
                >
                  <Typography
                    key={"0"}
                    id={"h9zkG"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Is Base Frequency"}
                  ></Typography>
                  <Checkbox
                    key={"1"}
                    id={"Wr5U5"}
                    checked={true}
                    color={"default"}
                    size={"small"}
                  ></Checkbox>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Frequencydefinition));
