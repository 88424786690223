import React from "react";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Grid, Box, Avatar, Typography } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
var aes256 = require("aes256");

function TimeSlot(props) {
  const dispatch = useDispatch();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {}, [state]);

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"time_component"}
            container={false}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            aliasName={"timeComponent"}
            style={qdmstyles.OUPb}
          >
            <Box
              key={"0"}
              id={"ticket_side_box"}
              m={"1"}
              component={"div"}
              aliasName={"ticketSideBox"}
              style={qdmstyles.oaf}
            >
              <Box
                key={"0"}
                id={"ticket_box"}
                m={"1"}
                component={"div"}
                aliasName={"ticketBox"}
              >
                <Avatar
                  key={"0"}
                  id={"ticketLogo"}
                  alt={"Avatar"}
                  variant={"rounded"}
                  aliasName={"ticketLogo"}
                  src={
                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/320008908"
                  }
                  style={qdmstyles.aiGvC}
                ></Avatar>
              </Box>
              <Typography
                key={"1"}
                id={"ticket_name"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={"101"}
                aliasName={"ticketName"}
                style={qdmstyles.tqzmE}
              ></Typography>
            </Box>
            <Box
              key={"1"}
              id={"clock_side_box"}
              m={"1"}
              component={"div"}
              aliasName={"clockSideBox"}
              style={qdmstyles.rjYC}
            >
              <Box
                key={"0"}
                id={"clock_box"}
                m={"1"}
                component={"div"}
                aliasName={"clockBox"}
              >
                <Avatar
                  key={"0"}
                  id={"ticketLogo"}
                  alt={"Avatar"}
                  variant={"rounded"}
                  aliasName={"ticketLogo"}
                  src={
                    "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316382595"
                  }
                  style={qdmstyles.CRuV}
                ></Avatar>
              </Box>
              <Typography
                key={"1"}
                id={"ticket_name"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={"00:12"}
                aliasName={"ticketName"}
                style={qdmstyles.gCusj}
              ></Typography>
            </Box>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(TimeSlot));
