import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Box, Paper, Grid, Typography, Button, makeStyles } from "@material-ui/core";
import {
  CusWorkingDays,
  //CusWorkingDaysModal,
} from "custom-material-ui-component";

import { WorkingDaysModalComp } from "../../components";

import qdmstyles from "./styles.json";
import "./index.css";
import moment from "moment";
import { Palette } from "@material-ui/icons";
var aes256 = require("aes256");

const useStyles = makeStyles((theme) => ({
  workday: {
    color: theme.palette.text.primary
  },
  workingDayBackground:{
    backgroundColor:theme.palette.background.table,
    padding: '10px',
    borderRadius: '8px',
    marginTop: '10px'
  }
}))

function WorkingDays(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();
  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
    workingdays_s5eoe: null,
    workingdays_sspf0: null,
    workingdays_oh7yx: null,
    workingdays_gtnko: null,
  });
  const [isopne, setisopne] = React.useState(false);

  const handleworkdayedit = (a, b, c) => {
    if (location?.state?.isdisabled === "inactive") {
      return;
    } else {
      setState({
        ...state,
        workingdays_sspf0: true,
      });
      props.handleworkdayedits(a, b, c);
    }
  };
  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const onclick_s5eoe = (e, index) => {
    setisopne(true);
    let updatestate = { workingdays_sspf0: true };

    setState({ ...state, ...updatestate, workingdays_s5eoe: e?.target?.value });
  };
  const statecreation_sspf0 = (e, index) => {
    let updatestate = {};

    setState({ ...state, ...updatestate, workingdays_sspf0: e?.target?.value });
  };
  const onclick_oh7yx = (e, index) => {
    let updatestate = { workingdays_sspf0: false };
    setisopne(false);
    props.close();
    setState({ ...state, ...updatestate, workingdays_oh7yx: e?.target?.value });
  };
  const currentDate = new Date();
  const checkedHr = (v) => {
    if (
      v?.operate_time?.[0]?.openingtime === currentDate.setHours(0, 0, 0, 0) &&
      v?.operate_time?.[v?.operate_time?.length - 1]?.closingtime ===
      currentDate.setHours(23, 59, 59, 999)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const onclick_gtnko = (e, index) => {
    setisopne(false);

    delete index.day;
    delete index.format;
    delete index.ovrDay;
    delete index.daysOfWeek;
    delete index.overridetype;

    index.effFrmDate = moment(index.effFrmDate, "YYYY-MM-DD").unix();
    index.effToDate = moment(index.effToDate, "YYYY-MM-DD").unix();
    if (
      index.workDaysOfWeek?.filter((val, i) => val?.checked || val?.checkedHr)
        .length > 0
    ) {
      const data_ = index.workDaysOfWeek?.map((v) => {
        if (v.checked) {
          return {
            day: v?.checked ? v?.day : "",
            operate_time: v?.checked ? v?.operate_time : [],
          };
        }
      });
      const dat_a = data_.filter(function (element) {
        return element !== undefined;
      });
      index.workDaysOfWeek = dat_a;

      let updatestate = { workingdays_sspf0: false };

      setState({
        ...state,
        ...updatestate,
        workingdays_gtnko: e?.target?.value,
      });
      let stateValue = props.statevalue;
      stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];
      stateValue.push(index);
      props.setFun(stateValue);
    } else {
      // alert("ddfsfsd")

      props.alert({
        open: true,
        msg: `please select atleast one Day`,
        severity: "error",
      });
    }
  };
  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  const day = (data) => {
    const res = data?.filter((v) => v.day);
    return res?.length > 6 ? true : false;
  };

  const checkedHrAll = (data) => {
    const res = data?.filter(
      (v) =>
        v?.operate_time?.[0]?.openingtime ===
        currentDate.setHours(0, 0, 0, 0) &&
        v?.operate_time?.[v?.operate_time?.length - 1]?.closingtime ===
        currentDate.setHours(23, 59, 59, 999)
    );
    return res?.length > 6 ? true : false;
  };

  const { data, index, statevalue } = props;
  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Paper
            key={"0"}
            id={"HffL5"}
            elevation={"{3}"}
            // style={qdmstyles.tHUie}
            className={classes.workingDayBackground}
          >
            <Grid
              key={"0"}
              id={"wMNgM"}
              container={true}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              style={qdmstyles.lJRX}
            >
              <Grid
                key={"0"}
                id={"COBbb"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                lg={"2"}
                style={qdmstyles.Dpz}
              >
                <Typography
                  key={"0"}
                  id={"working_days_header"}
                  align={"inherit"}
                  display={"initial"}
                  variant={"body1"}
                  children={
                    <span>
                      WORKING DAYS <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  comment={""}
                  className={classes.workday}
                  aliasName={"workingdaysheader"}
                  style={qdmstyles.Obv}
                ></Typography>
              </Grid>
              <Grid
                key={"1"}
                id={"tdgo1"}
                container={true}
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                lg={"2"}
                style={qdmstyles.HerT}
              >
                <Button
                  key={"0"}
                  onClick={(e) => onclick_s5eoe(e, index)}
                  id={"add_new_contact_button"}
                  variant={"text"}
                  children={"+ Add Working Days"}
                  color={"primary"}
                  aliasName={"addnewcontactbutton"}
                  style={qdmstyles.SeoE}
                  disabled={
                    location.state?.isdisabled === "inactive" ? true : false
                  }
                ></Button>
              </Grid>
            </Grid>
            <Paper
              key={"1"}
              id={"4I0Km"}
              elevation={"{3}"}
              aliasName={"workingdaysloop"}
              style={qdmstyles.SYoId}
              onLoad={(e) => props.setFun(props?.statevalue ?? [{}])}
            >
              {/* {(props?.statevalue ?? [{}])?.map((data, index) => {
                  return (
                    <React.Fragment>
                      <CusWorkingDays
                        key={"0"}
                        data={data}
                        id={"fL4xY"}
                        aliasName={"cusworkingDays"}
                        effectiveData={"effFrmDate"}
                        effectiveDataTo={"effToDate"}
                        workDaysOfWeek={"workDaysOfWeek"}
                        operate_time={"operate_time"}
                        closingtime={"closingtime"}
                        openingtime={"openingtime"}
                        day={"day"}
                        // style={qdmstyles.fLxY}
                      ></CusWorkingDays>
                    </React.Fragment>
                  );
                })} */}
              <CusWorkingDays
                key={"0"}
                data={props?.statevalue}
                id={"fL4xY"}
                aliasName={"cusworkingDays"}
                effectiveData={"effFrmDate"}
                effectiveDataTo={"effToDate"}
                workDaysOfWeek={"workDaysOfWeek"}
                operate_time={"operate_time"}
                closingtime={"closingtime"}
                openingtime={"openingtime"}
                day={"day"}
                handleworkdayedit={handleworkdayedit}
                handleworkdaydelete={props.handleworkdaydelete}
              // style={qdmstyles.fLxY}
              ></CusWorkingDays>
            </Paper>
            <Grid
              key={"2"}
              id={"GZdcH"}
              container={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            // style={qdmstyles.GZdcH}
            >
              <WorkingDaysModalComp
                key={"0"}
                open={state.workingdays_sspf0}
                handleClose={onclick_oh7yx}
                handleSave={onclick_gtnko}
                stateCreation={(e) => statecreation_sspf0(e, index)}
                id={"sSPF0"}
                aliasName={"workingdaysModal"}
                isWorkingDay={true}
                effectiveData={"effFrmDate"}
                effectiveDataTo={"effToDate"}
                workDaysOfWeek={"workDaysOfWeek"}
                day={"day"}
                operate_time={"operate_time"}
                openingtime={"openingtime"}
                closingtime={"closingtime"}
                // style={qdmstyles.sSPF}
                WorkingDays={props?.WorkingDays ?? []}
                style={{
                  borderBottom: "2px",
                }}
                editData={
                  !isopne
                    ? {
                      ...props?.editData,
                      effFrmDate: moment
                        .unix(props?.editData?.effFrmDate)
                        .format("YYYY-MM-DD"),
                      effToDate: moment
                        .unix(props?.editData?.effToDate)
                        .format("YYYY-MM-DD"),
                      day: day(props?.editData?.workDaysOfWeek),
                      format: checkedHrAll(props?.editData?.workDaysOfWeek),
                      workDaysOfWeek: props?.editData?.workDaysOfWeek?.map(
                        (v) => {
                          return {
                            day: v.day,
                            checked: v.day ? true : false,
                            checkedHr: checkedHr(v),
                            operate_time: v.operate_time,
                          };
                        }
                      ),
                    }
                    : null
                }
                isEdit={isopne ? false : props?.isEdit ?? false}
              // handleworkdayedit={handleworkdayedit}
              ></WorkingDaysModalComp>
            </Grid>
            {/* {JSON.stringify(isopne)} */}
            {/* <Grid
                key={"3"}
                onClick={(e) => onclick_oh7yx(e, index)}
                id={"OH7YX"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                aliasName={"closeevt"}
                style={qdmstyles.OHYX}
              ></Grid>
              <Grid
                key={"4"}
                onClick={(e) => onclick_gtnko(e, index)}
                id={"gtnkO"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                aliasName={"saveevt"}
                style={qdmstyles.gtnkO}
              ></Grid> */}
          </Paper>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(WorkingDays));
