import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: ({ backgroundColor }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3px 6px",
    maxHeight: 21,
    borderTopLeftRadius: "4px !important",
    borderTopRightRadius: "4px !important",
    // background: theme.palette.primary.main

    background: backgroundColor
      ? `${backgroundColor} !important`
      : theme.palette.primary.main,
  }),
}));

function Tag({ children, className, ...others }) {
  const classes = useStyles(others);

  return (
    <div className={classNames(classes.root, className)} {...others}>
      {children}
    </div>
  );
}

export default Tag;
