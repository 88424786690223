import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputBase } from "../inputs";

const useStyles = makeStyles((theme) => ({
  inputField: {
    fontWeight: 500,
    paddingBlock: 2,
    "& > p": {
      color: theme.palette.text.grey,
    },
  },
}));

function StyledInput(props) {
  // <------------------Hooks--------------->
  const classes = useStyles(props);

  // <-----------------Render--------------->
  return <InputBase className={classes.inputField} {...props}></InputBase>;
}

export default StyledInput;
