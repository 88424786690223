import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
// import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";

// import IDMPANEL from "idmpanel-pkg";
// import Config from "../../config";
// import cookie from "react-cookies";

import { PharmacyHeader, OrderRoutingComponent } from "../../components";

// import * as ALL from "../../components";

// import { Modal } from "qdm-component-library";

// import { Box } from "@material-ui/core";

// import qdmstyles from "./styles.json";
import "./index.css";
// var aes256 = require("aes256");

class OrderRoutingScreen extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      permissionData: null,
    };
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(localStorage.defaultLng);
    }

    /// setlogger && dispatch of all events

    /// fetch all permission
  }

  ///fetching all permission

  shouldComponentUpdate(props, state) {
    return true;
  }

  render() {
    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <PharmacyHeader caller={"orderRoutingScreen"} />{" "}
            <OrderRoutingComponent caller={"orderRoutingScreen"} />
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  actions
)(withRouter(translate()(OrderRoutingScreen)));
