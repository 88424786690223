import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  Avatar,
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import qdmstyles from "./styles.json";
import "./index.css";
import makeStyles from "@material-ui/core/styles/makeStyles";
var aes256 = require("aes256");

const useStyles = makeStyles((theme) => ({
  childtitle: {
    color: theme.palette.primary.light
  },
  contact: {
    color: theme.palette.text.primary,
  },
  autocomplete: {
    "& .App1-MuiOutlinedInput-notchedOutline":{
      borderColor:theme.palette.border.textField
    },
   },
   paper: {
     color:"#6a7888"
   },
   notchedOutline:{
    borderColor:theme.palette.border.textField
  },
  mobileValue:{
    border:"1px solid",
    borderColor:theme.palette.border.textField,
    borderRadius:"4px"
  },
  dividerColor:{
    backgroundColor:theme.palette.border.textField,
  },
}));


function Contactpoint(props) {

  const classes = useStyles();
  const dispatch = useDispatch();

  const pharmacy_contact_contactmode_dropdownData = useSelector(
    (state) =>
      state?.contactpointpharmacy_contact_contactmode_dropdown_VqEMpReducer
        ?.pharmacy_contact_contactmode_dropdown_VqEMp?.data
  );
  const pharmacy_contact_use_dropdownData = useSelector(
    (state) =>
      state?.contactpointpharmacy_contact_use_dropdown_VqEMpReducer
        ?.pharmacy_contact_use_dropdown_VqEMp?.data
  );
  const pharmacy_contact_priority_dropdownData = useSelector(
    (state) =>
      state?.contactpointpharmacy_contact_priority_dropdown_VqEMpReducer
        ?.pharmacy_contact_priority_dropdown_VqEMp?.data
  );

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
    contactpoint_vqemp: null,
    contactpoint_cbukw: null,
    contactpoint_lcygy: null,
    contactpoint_ktsnd: null,
    contactpoint_jojas: null,
    contactpoint_lbfun: null,
    contactpoint_ovtat: null,
    contactpoint_gd1si: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const onload_vqemp = async (event, data) => {
    let index = data;
    const payload0 = {
      history: props.history.location.state,
      data: data,
    };
    let data0 = await dispatch(
      actions.contactpointpharmacy_contact_contactmode_dropdown_VqEMp(payload0)
    );
    const payload1 = {
      history: props.history.location.state,
      data: data,
    };
    let data1 = await dispatch(
      actions.contactpointpharmacy_contact_use_dropdown_VqEMp(payload1)
    );
    const payload2 = {
      history: props.history.location.state,
      data: data,
    };
    let data2 = await dispatch(
      actions.contactpointpharmacy_contact_priority_dropdown_VqEMp(payload2)
    );

    let updatestate = {};
    setState({
      ...state,
      ...updatestate,
      contactpoint_vqemp: event?.target?.value,
    });
  };
  const onchange_cbukw = (e, index) => {
    let updatestate = {};

    setState({ ...state, ...updatestate, contactpoint_cbukw: e });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = {
      ...stateValue[index],
      contactpoint_cbukw: e,
      contactpoint_ovtat: "",
    };
    props.setFun(stateValue);
  };
  const onchange_lcygy = (e, index) => {
    let updatestate = {};

    setState({ ...state, ...updatestate, contactpoint_lcygy: e });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = { ...stateValue[index], contactpoint_lcygy: e };
    props.setFun(stateValue);
  };
  const onchange_ktsnd = (e, index) => {
    let updatestate = {};

    setState({ ...state, ...updatestate, contactpoint_ktsnd: e });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = { ...stateValue[index], contactpoint_ktsnd: e };
    props.setFun(stateValue);
  };
  const onclick_jojas = (e, index) => {
    let updatestate = {};

    setState({
      ...state,
      ...updatestate,
      contactpoint_jojas: e?.target?.value,
    });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];
    props.setFun([...stateValue, {}]);
  };
  const onchange_lbfun = (e, index) => {
    let updatestate = {};

    setState({ ...state, ...updatestate, contactpoint_lbfun: e });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = { ...stateValue[index], contactpoint_lbfun: e };
    props.setFun(stateValue);
  };
  const onchange_ovtat = (e, index, data) => {
    if (
      data?.contactpoint_cbukw?.display === "FAX" ||
      data?.contactpoint_cbukw?.display === "EMAIL" ||
      data?.contactpoint_cbukw?.display === "URL" ||
      data?.contactpoint_cbukw?.display === "OTHER" ||
      data?.contactpoint_cbukw?.display === "SMS"
    ) {
      if (e?.target?.value?.length <= 50) {
        let updatestate = {};

        setState({
          ...state,
          ...updatestate,
          contactpoint_ovtat: e?.target?.value,
        });
        let stateValue = props.statevalue;
        stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

        stateValue[index] = {
          ...stateValue[index],
          contactpoint_ovtat: e?.target?.value,
        };
        props.setFun(stateValue);
      }
    } else {
      let updatestate = {};

      setState({
        ...state,
        ...updatestate,
        contactpoint_ovtat: e?.target?.value,
      });
      let stateValue = props.statevalue;
      stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

      stateValue[index] = {
        ...stateValue[index],
        contactpoint_ovtat: e?.target?.value,
      };
      props.setFun(stateValue);
    }
  };
  const onclick_gd1si = (e, index) => {
    let updatestate = {};

    setState({
      ...state,
      ...updatestate,
      contactpoint_gd1si: e?.target?.value,
    });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue.splice(index, 1);
    props.setFun([...stateValue]);
  };
  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events
    onload_vqemp();
    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {
    if (
      pharmacy_contact_priority_dropdownData?.loading !==
      pharmacy_contact_priority_dropdownData?.loading
    ) {
      if (
        !pharmacy_contact_priority_dropdownData?.loading &&
        pharmacy_contact_priority_dropdownData.error
      ) {
      } else if (
        !pharmacy_contact_priority_dropdownData?.loading &&
        !pharmacy_contact_priority_dropdownData.error
      ) {
      }
    }
  }, [state]);

  const { data, index } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <div
          >
            <Box key={"0"} id={"2mWit"} m={1} component={"div"}
            >
              <Box key={"0"} id={"LFT8q"} m={1} component={"div"}>
                <Grid
                  key={"0"}
                  id={"YrJKf"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Grid
                    key={"0"}
                    id={"6EVn4"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"flex-end"}
                    xs={"6"}
                    xl={"6"}
                    sm={"6"}
                    md={"6"}
                    lg={"6"}
                  >
                    <Typography
                      key={"0"}
                      id={"BuMXV"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      className={classes.contact}
                      children={t(
                        "pharmacy.addNewDrugCatalogMaster.contactPoints"
                      )}
                      style={qdmstyles.RDf}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"GOaqU"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"flex-end"}
                    xs={"6"}
                    xl={"6"}
                    sm={"6"}
                    md={"6"}
                    lg={"6"}
                  >
                    <Button
                      key={"0"}
                      onClick={(e) => onclick_jojas(e, index)}
                      id={"yYkqp"}
                      variant={"text"}
                      color={"primary"}
                      children={"+ Add New Contact"}
                      style={qdmstyles.jojAs}
                    ></Button>
                  </Grid>
                </Grid>
              </Box>
              <Box key={"1"} id={"5hJ1y"} m={1} component={"div"}>
                <Grid
                  key={"0"}
                  id={"VqEMp"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  spacing={"2"}
                  style={qdmstyles.VqEMp}
                  onLoad={(e) => props.setFun(props?.statevalue ?? [{}])}
                >
                  {(props?.statevalue ?? [{}])?.map((data, index) => {
                    return (
                      <React.Fragment>
                        <Grid
                          key={"0"}
                          id={"7Nzhv"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"3"}
                          item={true}
                        >
                          <Typography
                            key={"0"}
                            id={"contact_mode_header"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            className={classes.childtitle}
                            variant={"body1"}
                            children={
                              <span>
                                Contact Mode
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            }
                            aliasName={"contactmodeheader"}
                            style={qdmstyles.HIaxA}
                          ></Typography>
                          <Autocomplete
                            id={"CBUkw"}
                            style={qdmstyles?.CBUkw}
                            // value={data?.contactpoint_cbukw}
                            onLoad={() =>
                              setState({
                                contactpoint_cbukw:
                                  state?.contactpoint_cbukw ?? null,
                              })
                            }
                            classes={{
                              paper: classes.paper
                            }}
                            className={classes.autocomplete}
                            // value={state?.contactpoint_cbukw ||data?.contactpoint_cbukw|| {}}
                            value={
                              data?.contactpoint_cbukw
                                ? data?.contactpoint_cbukw
                                : data?.system
                                  ? data?.system
                                  : {}
                            }
                            getOptionLabel={(option) => option?.["display"]}
                            options={
                              pharmacy_contact_contactmode_dropdownData.result ??
                              []
                            }
                            size={"small"}
                            onChange={(e, newvalue) =>
                              onchange_cbukw(newvalue, index)
                            }
                            renderInput={(params) => (
                              <ALLMCORE.TextField
                                {...params}
                                label={""}
                                margin={"dense"}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"iayzf"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"4"}
                          item={true}
                        >
                          <div style={{ display: "flex" }}>
                            <Typography
                              key={"0"}
                              id={"mobile_header"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              className={classes.childtitle}
                              variant={"body1"}
                              children={
                                data.contactpoint_cbukw?.display || "Phone"
                              }
                              aliasName={"mobileheader"}
                              style={qdmstyles.gspX}
                            ></Typography>
                            {props?.required ? (
                              <Typography style={{ color: "red" }}>
                                {" * "}
                              </Typography>
                            ) : (
                              <></>
                            )}
                          </div>
                          <Grid
                            key={"1"}
                            id={"mobile_value"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            aliasName={"mobilevalue"}
                            // style={qdmstyles.vNuy}
                            className={classes.mobileValue}
                          >
                            {(data.contactpoint_cbukw?.display?.toLowerCase() ===
                              "phone" ||
                              data.contactpoint_cbukw?.display?.toLowerCase() ===
                              "mobile" ||
                              data.contactpoint_cbukw?.display?.toLowerCase() ===
                              "fax" ||
                              data.contactpoint_cbukw?.display?.toLowerCase() ===
                              "sms" ||
                              !data.contactpoint_cbukw?.display) && (
                                <Grid
                                  key={"0"}
                                  id={"etDh7"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  lg={"3"}
                                  md={"2"}
                                  sm={"2"}
                                  item={true}
                                  xl={"2"}
                                  xs={"2"}
                                >
                                  <Autocomplete
                                    id={"lBFUN"}
                                    style={qdmstyles?.lBFUN}
                                    onLoad={() =>
                                      setState({
                                        contactpoint_lbfun:
                                          state?.contactpoint_lbfun ?? null,
                                      })
                                    }
                                    getOptionLabel={(option) => option?.["label"]}
                                    options={[] ?? []}
                                    disabled
                                    value={{ label: "+ 91" }}
                                    size={"small"}
                                    onChange={(e, newvalue) =>
                                      onchange_lbfun(newvalue, index)
                                    }
                                    classes={{
                                      paper: classes.paper
                                    }}
                                    className={classes.autocomplete}
                                    renderInput={(params) => (
                                      <ALLMCORE.TextField
                                        {...params}
                                        margin={"dense"}
                                        variant="outlined"
                                        class={"outlined"}
                                      />
                                    )}
                                  />
                                </Grid>
                              )}
                            {(data.contactpoint_cbukw?.display?.toLowerCase() ===
                              "phone" ||
                              data.contactpoint_cbukw?.display?.toLowerCase() ===
                              "mobile" ||
                              data.contactpoint_cbukw?.display?.toLowerCase() ===
                              "sms" ||
                              data.contactpoint_cbukw?.display?.toLowerCase() ===
                              "fax" ||
                              !data.contactpoint_cbukw?.display) && (
                                <Divider
                                  key={"1"}
                                  id={"PR2E5"}
                                  variant={"middle"}
                                  flexItem={true}
                                  orientation={"vertical"}
                                  className={classes.dividerColor}
                                  style={{ height: 24, margin: 0, marginTop: 8 }}
                                ></Divider>
                              )}
                            <Grid
                              key={"2"}
                              id={"GHkwH"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"8"}
                              md={"8"}
                            >
                              <TextField
                                key={"0"}
                                value={data?.contactpoint_ovtat ?? null}
                                onChange={(e) => onchange_ovtat(e, index, data)}
                                id={"OVtAT"}
                                label={""}
                                placeholder={
                                  data.contactpoint_cbukw?.display || "Phone"
                                }
                                // type={
                                //   data.contactpoint_cbukw?.display?.toLowerCase() ===
                                //     "phone" ||
                                //   data.contactpoint_cbukw?.display?.toLowerCase() ===
                                //     "mobile" ||
                                //   !data.contactpoint_cbukw?.display
                                //     ? "number"
                                //     : "text"
                                // }
                                onKeyPress={
                                  data.contactpoint_cbukw?.display?.toLowerCase() ===
                                    "phone" ||
                                    data.contactpoint_cbukw?.display?.toLowerCase() ===
                                    "fax" ||
                                    data.contactpoint_cbukw?.display?.toLowerCase() ===
                                    "sms" ||
                                    !data.contactpoint_cbukw?.display
                                    ? (e) => {
                                      var k = e.keyCode || e.which;
                                      if (k >= 48 && k <= 57) {
                                        return;
                                      } else {
                                        e.preventDefault();
                                        return;
                                      }
                                    }
                                    : (e) => null
                                }
                                inputProps={{
                                  maxLength:
                                    (data.contactpoint_cbukw?.display?.toLowerCase() ===
                                      "phone" ||
                                      data.contactpoint_cbukw?.display?.toLowerCase() ===
                                      "fax" ||
                                      data.contactpoint_cbukw?.display?.toLowerCase() ===
                                      "sms" ||
                                      !data.contactpoint_cbukw?.display) &&
                                    10,
                                }}
                                variant={"outlined"}
                                size={"small"}
                                margin={"dense"}
                                aliasName={"mobile_value"}
                                class={"outlined"}
                              ></TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          key={"2"}
                          id={"EcYcz"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"2"}
                          item={true}
                        >
                          <Typography
                            key={"0"}
                            id={"use_header"}
                            align={"inherit"}
                            color={"initial"}
                            className={classes.childtitle}
                            display={"initial"}
                            variant={"body1"}
                            children={
                              <span>
                                Use
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            }
                            aliasName={"useheader"}
                            style={qdmstyles.cVjA}
                          ></Typography>
                          <Autocomplete
                            id={"lCYGY"}
                            style={qdmstyles?.lCYGY}
                            onLoad={() =>
                              setState({
                                contactpoint_lcygy:
                                  state?.contactpoint_lcygy ?? null,
                              })
                            }
                            classes={{
                              paper: classes.paper
                            }}
                            className={classes.autocomplete}
                            // value={state?.contactpoint_lcygy||data?.contactpoint_lcygy|| {}}
                            getOptionLabel={(option) => option?.["display"]}
                            options={
                              pharmacy_contact_use_dropdownData.result ?? []
                            }
                            value={
                              data?.contactpoint_lcygy
                                ? data?.contactpoint_lcygy
                                : data?.use
                            }
                            size={"small"}
                            onChange={(e, newvalue) =>
                              onchange_lcygy(newvalue, index)
                            }
                            renderInput={(params) => (
                              <ALLMCORE.TextField
                                {...params}
                                label={""}
                                margin={"dense"}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          key={"3"}
                          id={"dW8aj"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"3"}
                          item={true}
                        >
                          <Typography
                            key={"0"}
                            id={"priority_header"}
                            align={"inherit"}
                            className={classes.childtitle}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={
                              <span>
                                Priority
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            }
                            aliasName={"priorityheader"}
                            style={qdmstyles.SQZuM}
                          ></Typography>
                          <Grid
                            key={"1"}
                            id={"xzvVQ"}
                            container={""}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            style={qdmstyles.xzvVQ}
                          >
                            <Autocomplete
                              id={"KTSND"}
                              style={qdmstyles?.KTSND}
                              onLoad={() =>
                                setState({
                                  contactpoint_ktsnd:
                                    state?.contactpoint_ktsnd ?? null,
                                })
                              }
                              value={
                                data?.contactpoint_ktsnd
                                  ? data?.contactpoint_ktsnd
                                  : data?.rank
                              }
                              fullWidth
                              classes={{
                                paper: classes.paper
                              }}
                              className={classes.autocomplete}
                              // value={state?.contactpoint_ktsnd ||data?.contactpoint_ktsnd|| {}}
                              getOptionLabel={(option) => option?.["display"]}
                              options={
                                pharmacy_contact_priority_dropdownData.result ??
                                []
                              }
                              size={"small"}
                              onChange={(e, newvalue) =>
                                onchange_ktsnd(newvalue, index)
                              }
                              renderInput={(params) => (
                                <ALLMCORE.TextField
                                  {...params}
                                  margin={"dense"}
                                  variant="outlined"
                                />
                              )}
                            />{" "}
                            <Avatar
                              key={"1"}
                              onClick={(e) => onclick_gd1si(e, index)}
                              id={"delete_icon"}
                              alt={"Avatar"}
                              variant={"rounded"}
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317130944"
                              }
                              aliasName={"deleteicon"}
                              style={qdmstyles.gdSI}
                            ></Avatar>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          </div>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Contactpoint));
