import React from "react";
import { Card, CircularProgress } from "@material-ui/core";
import "../../components/font.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { CommonTable } from "../../components";
//import Drawer from "@material-ui/core/Drawer";
//import { makeStyles } from "@material-ui/core/styles";
//import AddLocation from "./addLocation/index";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router";
import { withAllContexts } from "../../HOCs";
import { useTheme } from "@material-ui/styles";
//import { AlertProps } from "../../utils";

//const drawerWidth = "94%";
// const useStyles = makeStyles((theme) => ({
//   btn: {
//     backgroundColor: "#0071F2",
//     color: "#fff",
//     padding: "6px 16px",
//     fontFamily: "poppin",
//     fontSize: "12px",
//     textTransform: "none",
//     borderRadius: "10px",
//     width: "100px",
//     "&:hover": {
//       backgroundColor: "#0e5ab2",
//     },
//   },
//   numbersEdit: {
//     marginTop: "4px",
//     fontFamily: "poppinsemibold",
//   },
//   table: {
//     minWidth: 650,
//     marginLeft: "18px",
//     width: "97%",
//     border: "1px solid lightgrey",
//     borderRadius: "2px",
//   },
//   bgcolor: {
//     backgroundColor: "#F9F9F9",
//     paddingTop: "10px",
//   },
//   root: {
//     "& .App1-MuiTableCell-sizeSmall": {
//       paddingBottom: "0px",
//       paddingTop: "0px",
//     },
//   },
//   appBarShift: {
//     width: `calc(100% - ${drawerWidth}px)`,

//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginRight: drawerWidth,
//     //marginTop: "63px",
//   },
//   title: {
//     flexGrow: 1,
//   },
//   hide: {
//     display: "none",
//   },
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//   },
//   drawerPaper: {
//     width: drawerWidth,
//     marginTop: "63px",
//   },
//   drawerHeader: {
//     display: "flex",
//     alignItems: "center",
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//     justifyContent: "flex-start",
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginRight: -drawerWidth,
//   },
//   contentShift: {
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginRight: 0,
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));

function LocationMaster(props) {
  //const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme()
  const history = useHistory();
  const { parent_id } = props;

  const listData = useSelector(
    (state) => state?.locationSlice?.location_list?.data ?? []
  );

  const loading = useSelector(
    (state) => state?.locationSlice?.location_list?.loading
  );
  // const saveloading = useSelector(
  //   (state) => state?.locationSlice?.location_list_upsert?.loading
  // );

  const [state, setState] = React.useState({
    open: false,
    edit_data: null,
  });
  //const [role, setRole] = React.useState(false);

  const handleDrawerOpen = () => {
    // setState({
    //   ...state,
    //   open: true,
    // });
    history.push(Routes.addlocationMater);
  };
  // const handleDrawerClose = () => {
  //   setState({
  //     ...state,
  //     open: false,
  //     edit_data: null,
  //   });
  // };

  const userEffFunction = async () => {
    let type = props.val;
    await dispatch(actions.LOCATION_READ({ type: type }));
  };
  React.useLayoutEffect(() => {
    userEffFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const savePractitionerRole = async (values) => {
  //   let data = {
  //     code: values.type_code,
  //     description: values.type_description,
  //     type: props.type,
  //     status: values.status,
  //   };
  //   if (state?.edit_data) {
  //     data["_key"] = state?.edit_data._key;
  //     data["id"] = state?.edit_data.id;
  //   }
  //   await dispatch(actions.PRACTITIONER_ROLE_UPSERT(data));
  //   await dispatch(actions.PRACTITIONER_ROLE_READ({ type: props.type }));
  //   await handleDrawerClose();
  // };

  const handleEdit = (e, values, index) => {
    // let data = {
    //   code: values.code_type,
    //   description: values.description,
    //   type: props.type,
    //   status: values.status,
    //   _key: values._key,
    //   id: values.id,
    // };

    history.push({
      pathname: Routes.addlocationMater,
      state: {
        Id: values._key,
      },
    });
  };

  const statusChange = async (e, values, index) => {
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Updating...",
    });
    let data = {
      isStatusChange: true,
      _key: values._key,
      status: e.target.checked ? "Active" : "In-Active",
    };
    setState({
      ...state,
      edit_data: data,
    });
    let dat = await dispatch(actions.LOCATION_LIST_UPSERT(data));
    if (dat.payload.data.Code === 201) {
      props.alert.setSnack("update");
    } else {
      props.alert.setSnack("error");
    }
    await dispatch(actions.LOCATION_READ({ type: props.val }));
    setState({
      ...state,
      edit_data: null,
    });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
  };

  return (
    <Card id={`${parent_id}-parent-card`} style={{ borderRadius: "12px" }}>
      <div id={`${parent_id}-parent-div`} style={{ width: "100%" }}>
        <Card
          id={`${parent_id}-parent-sub-Card`}
          style={{ borderRadius: "12px" }}
        >
          <div
            id={`${parent_id}-parent-sub-div`}
            style={{
              borderRadius: "6px",
              backgroundColor: theme.palette.background.table,

            }}
          >
            <div
              id={`${parent_id}-button-div`}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                id={`${parent_id}-button-sub-div`}
                style={{ marginLeft: "18px" }}
              >
                <p
                  id={`${parent_id}-header-title-sub-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  {props.header}
                </p>
              </div>
              <div
                id={`${parent_id}-button-div`}
                style={{ marginTop: "16px", width: "10%", float: "right" }}
              >
                <button
                  id={`${parent_id}-Add-New-button`}
                  style={{
                    backgroundColor: theme.palette.button.background,
                    color: theme.palette.button.text,
                    border: "none",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderRadius: "5px",
                    fontFamily: "poppin",
                    fontSize: "12px",
                  }}
                  onClick={handleDrawerOpen}
                >
                  +Add New
                </button>
              </div>
            </div>

            {/* table */}
            <div
              id={`${parent_id}-loading-parent-div`}
              style={{ padding: 20, height: "71vh", overflow: "auto" }}
            >
              {(loading && state.edit_data === null) ||
              state?.locationSlice?.location_list_upsert?.loading ? (
                <div
                  id={`${parent_id}-loading-CircularProgress-div`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "66vh",
                  }}
                >
                  <CircularProgress
                    id={`${parent_id}-loading-CircularProgress`}
                  />
                </div>
              ) : (
                <CommonTable
                  parent_id={"locationmaster"}
                  Header={[
                    "S.No",
                    "Location Type",
                    "Location ID",
                    "Short Description",
                    "Long Description",
                    "Status",
                    "Action",
                  ]}
                  dataList={listData}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "location_type" },
                    { type: ["TEXT"], name: "location_id" },
                    { type: ["TEXT"], name: "short_description" },
                    { type: ["TEXT"], name: "long_description" },
                    { type: ["CHECKBOX"], name: "status" },
                    { type: ["EDIT"], name: "" },
                  ]}
                  handleCheckBox={statusChange}
                  handleEdit={handleEdit}
                />
              )}
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
}

export default withAllContexts(LocationMaster);
