import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
// import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";

// import IDMPANEL from "idmpanel-pkg";
// import Config from "../../config";
// import cookie from "react-cookies";

// import * as ALL from "../../components";

// import { Modal } from "qdm-component-library";

import { Box, Grid, Avatar, Typography, Switch } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
// var aes256 = require("aes256");

class PharmacyTest extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      permissionData: null,
    };
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(localStorage.defaultLng);
    }

    /// setlogger && dispatch of all events

    /// fetch all permission
  }

  ///fetching all permission

  shouldComponentUpdate(props, state) {
    return true;
  }

  render() {
    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <Box
              key={"0"}
              id={"eMD8Y"}
              m={"1"}
              component={"div"}
              style={qdmstyles.eMDY}
            >
              <Grid
                key={"0"}
                id={"gyqWe"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <Grid
                  key={"0"}
                  id={"hS8cY"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  lg={"8"}
                  style={qdmstyles.hScY}
                >
                  <Grid
                    key={"0"}
                    id={"3ft7O"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    lg={"3"}
                  >
                    <Avatar
                      key={"0"}
                      id={"VVxdm"}
                      alt={"Avatar"}
                      variant={"rounded"}
                    ></Avatar>
                    <Typography
                      key={"1"}
                      id={"7nHuZ"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"OP Pharmacy-01"}
                      style={qdmstyles.nHuZ}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"3ft7O"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    lg={"2"}
                  >
                    <Avatar
                      key={"0"}
                      id={"VVxdm"}
                      alt={"Avatar"}
                      variant={"rounded"}
                    ></Avatar>
                    <Typography
                      key={"1"}
                      id={"7nHuZ"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"PH-001"}
                      style={qdmstyles.tOXu}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"3ft7O"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    lg={"7"}
                  >
                    <Avatar
                      key={"0"}
                      id={"VVxdm"}
                      alt={"Avatar"}
                      variant={"rounded"}
                    ></Avatar>
                    <Typography
                      key={"1"}
                      id={"7nHuZ"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"AINQA Multi-Speciality Hospitals, Chennai"}
                      style={qdmstyles.hilr}
                    ></Typography>
                  </Grid>
                </Grid>
                <Grid
                  key={"1"}
                  id={"WNbWw"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  lg={"4"}
                >
                  <Grid
                    key={"0"}
                    id={"fHLcG"}
                    container={true}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"2"}
                  >
                    <Avatar
                      key={"0"}
                      id={"uWSkI"}
                      alt={"Avatar"}
                      variant={"rounded"}
                    ></Avatar>
                  </Grid>
                  <Switch
                    key={"1"}
                    id={"2TPoU"}
                    checked={true}
                    color={"primary"}
                    name={"switch"}
                  ></Switch>
                </Grid>
              </Grid>
              <Grid
                key={"1"}
                id={"EuvuQ"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                style={qdmstyles.EuvuQ}
              >
                <Grid
                  key={"0"}
                  id={"49Quk"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  lg={"3"}
                >
                  <Avatar
                    key={"0"}
                    id={"xTKXT"}
                    alt={"Avatar"}
                    variant={"rounded"}
                    style={qdmstyles.xTKXT}
                  ></Avatar>
                  <Typography
                    key={"1"}
                    id={"6kNxb"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"D-Block, Second Floor, Ward 2"}
                    style={qdmstyles.kNxb}
                  ></Typography>
                </Grid>
                <Grid
                  key={"1"}
                  id={"49Quk"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  lg={"9"}
                >
                  <Grid
                    key={"0"}
                    id={"Dmwzw"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    lg={"3"}
                  >
                    <Grid
                      key={"0"}
                      id={"5BIUV"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"1"}
                    >
                      <Avatar
                        key={"0"}
                        id={"UERAf"}
                        alt={"Avatar"}
                        variant={"rounded"}
                      ></Avatar>
                    </Grid>
                    <Grid
                      key={"1"}
                      id={"LQ7cQ"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      xl={"4"}
                      lg={"10"}
                    >
                      <Typography
                        key={"0"}
                        id={"uFEFj"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"+91 95966 65833"}
                      ></Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"Dmwzw"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    lg={"3"}
                  >
                    <Grid
                      key={"0"}
                      id={"5BIUV"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"1"}
                    >
                      <Avatar
                        key={"0"}
                        id={"UERAf"}
                        alt={"Avatar"}
                        variant={"rounded"}
                      ></Avatar>
                    </Grid>
                    <Grid
                      key={"1"}
                      id={"LQ7cQ"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      xl={"7"}
                      lg={"10"}
                    >
                      <Typography
                        key={"0"}
                        id={"uFEFj"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"+91 95966 65833"}
                      ></Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"Dmwzw"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    lg={"3"}
                  >
                    <Grid
                      key={"0"}
                      id={"5BIUV"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"1"}
                    >
                      <Avatar
                        key={"0"}
                        id={"UERAf"}
                        alt={"Avatar"}
                        variant={"rounded"}
                      ></Avatar>
                    </Grid>
                    <Grid
                      key={"1"}
                      id={"LQ7cQ"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      xl={"5"}
                      lg={"10"}
                    >
                      <Typography
                        key={"0"}
                        id={"uFEFj"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"sara.lucas@mail.com"}
                      ></Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  actions
)(withRouter(translate()(PharmacyTest)));
