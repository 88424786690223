import { withStyles } from "@material-ui/core";
import { actions } from "frequencyscreen_v_dbinder";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import styles from "./styles.js";
import {
  errorMessages,
  frequencyTypes,
  getDynamicContent,
  getSnackObject,
  isValidUserDefinedSchedule,
  findOverlapForUserDefinedType,
  shouldShowScheduleLine,
  successMessages,
  durationUOM,
} from "./utils";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import {
  Autocomplete,
  Directioncomp,
  FieldContainer,
  FieldLabel,
  InputBase,
  StyledPaper,
  TextfieldWithSelect,
} from "../../../components/index.js";
import InputWithLegend from "../../../components/inputs/inputWithLegend.js";
import ScheduleLine from "./scheduleLine";
import update from "immutability-helper";
import withAllContexts from "../../../HOCs/withAllContexts.js";
import { Routes } from "../../../router/routes.js";
import { parse } from "date-fns";
import { isValidDate } from "../../../utils/validations.js";
import { FrequencyAddEditWrapper } from "clmm-masters"
import { AlertProps } from "../../../utils/helperFunctions.js";
const initialDayBasedTypeState = {
  day: null,
  basefreq: null,
  remarks: "",
};

const initialDetailTypeState = {
  time: null,
  remarks: "",
};

const initialIntervalAndSequentialTypeState = {
  basefreq: null,
  remarks: "",
};

const initialUserDefinedTypeState = {
  fromDay: "",
  toDay: "",
  basefreq: null,
  remarks: "",
};

class FrequencyAddEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      frequencyCode: "",
      frequencyName: "",
      frequencyType: null,

      totalNoOfDays: "", // Frequency Type - Day Based
      dosePerDay: "", // Frequency Type - Detail & User Defined
      onceEveryValue: "", // Frequency Type - Interval
      onceEveryUOM: null, // Frequency Type - Interval
      intervalDays: "", // Frequency Type - Sequential

      dispenseGracePeriod: "",
      fixedDurationValue: "",
      fixedDurationUOM: null,
      isBaseFrequency: false,
      status: true,

      dayBasedSchedule: [{ ...initialDayBasedTypeState }],
      detailSchedule: [{ ...initialDetailTypeState }],
      intervalSchedule: [{ ...initialIntervalAndSequentialTypeState }],
      sequentialSchedule: [{ ...initialIntervalAndSequentialTypeState }],
      userDefinedSchedule: [{ ...initialUserDefinedTypeState }],

      directionDtls: [],
    };
  }

  async componentDidMount() {
    await this.loadOptions();

    if (this.props.location.state?._key) {
      this.loadFrequency();
    }
  }

  loadOptions = async () => {
    // Frequency Type Options
    this.props.addnewfrequency_orgfrequencymaster_frequencytype_dropdown_OE55Y();

    // Duration UOM Options
    this.props.addnewfrequency_orgfrequencymaster_uom_dropdown_OE55Y();

    // Fixed Duration UOM Options
    this.props.UOM_TIME_TYPE_OPTIONS();

    //Tags
    this.props.DIRECTION_TAGS()
    await this.props.EditNewFrequency_orgbase_frequency_dropdown();
  };

  loadFrequency = async () => {
    try {
      const response = await this.props.FREQUENCY_SINGLE_READ({
        _key: this.props.location.state?._key,
      });

      const data = response?.payload?.data?.[0];

      const frequencyTypeBasedStatePayload = () => {
        const { baseFrequencyResponse } = this.props;

        const baseFrequencyOptions = Array.isArray(baseFrequencyResponse)
          ? baseFrequencyResponse?.map((v) => {
            return {
              ...v,
              display: v?.freqcode + " " + v?.shortdesc,
            };
          })
          : [];

        const getBaseFrequencyOption = (_id) => {
          return baseFrequencyOptions.filter((freq) => freq?._id === _id)?.[0];
        };

        switch (data?.frequencytype?._id) {
          case frequencyTypes.dayBased:
            return {
              totalNoOfDays: data?.dosefreqval,
              dayBasedSchedule: data?.scheduleline?.map((schedule) => {
                return {
                  day: schedule?.day,
                  basefreq: getBaseFrequencyOption(schedule?.basefreq),
                  remarks: schedule?.remarks,
                };
              }),
            };

          case frequencyTypes.detail:
            return {
              dosePerDay: data?.dosefreqval,
              detailSchedule: data?.scheduleline?.map((schedule) => {
                return {
                  time: parse(schedule?.time, "HH:mm", new Date()),
                  remarks: schedule?.remarks,
                };
              }),
            };

          case frequencyTypes.interval:
            return {
              onceEveryValue: data?.dosefreqval,
              onceEveryUOM: data?.dosefreqvalUOM,
              intervalSchedule: data?.scheduleline?.map((schedule) => {
                return {
                  basefreq: getBaseFrequencyOption(schedule?.basefreq),
                  remarks: schedule?.remarks,
                };
              }),
            };

          case frequencyTypes.sequential:
            return {
              intervalDays: data?.dosefreqval,
              sequentialSchedule: data?.scheduleline?.map((schedule) => {
                return {
                  basefreq: getBaseFrequencyOption(schedule?.basefreq),
                  remarks: schedule?.remarks,
                };
              }),
            };

          case frequencyTypes.userDefined:
            return {
              dosePerDay: data?.dosefreqval,
              userDefinedSchedule: data?.scheduleline?.map((schedule) => {
                return {
                  fromDay: schedule?.fromDay,
                  toDay: schedule?.toDay,
                  basefreq: getBaseFrequencyOption(schedule?.basefreq),
                  remarks: schedule?.remarks,
                };
              }),
            };
        }
      };

      const statePayload = {
        frequencyCode: data?.freqcode,
        frequencyName: data?.shortdesc,
        frequencyType: data?.frequencytype,

        dispenseGracePeriod: data?.graceperiod,
        fixedDurationValue: data?.fixeddurationVal,
        fixedDurationUOM: {
          ...data?.fixeddurUOM,
          label: data?.fixeddurUOM?.shortdesc,
          value: data?.fixeddurUOM?._id
        },
        isBaseFrequency: data?.IsBaseFreq,
        status: data?.status,

        ...frequencyTypeBasedStatePayload(),

        directionDtls:
          Boolean(data?.directiontag) || Boolean(data?.directiontaglocal)
            ? [
              {
                directiontag: data?.directiontag,
                directiontaglocal: data?.directiontaglocal,
              },
            ]
            : [],
      };

      this.setState({
        ...statePayload,
      });
    } catch (error) {
      console.error(error);
    }
  };

  goToListing = () => {
    this.props.history.push(Routes.frequency);
  };

  handleChangeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleScheduleLineChangeState = (stateName, key, value, index) => {
    const newState = update(this.state, {
      [stateName]: {
        [index]: {
          [key]: {
            $set: value,
          },
        },
      },
    });

    this.setState({
      [stateName]: newState[stateName],
    });
  };

  handleScheduleLineAddNewClick = (frequencyTypeId) => {
    switch (frequencyTypeId) {
      case frequencyTypes.dayBased: {
        if (
          this.state?.dayBasedSchedule?.length <
          Number(this.state.totalNoOfDays)
        ) {
          const newState = update(this.state, {
            dayBasedSchedule: {
              $push: [{ ...initialDayBasedTypeState }],
            },
          });

          this.setState({
            dayBasedSchedule: newState.dayBasedSchedule,
          });
        } else {
          this.props.alert.setSnack(
            getSnackObject.open(errorMessages.scheduleLineMaxReached)
          );
        }

        return;
      }

      case frequencyTypes.detail: {
        if (
          this.state?.detailSchedule?.length < Number(this.state.dosePerDay)
        ) {
          const newState = update(this.state, {
            detailSchedule: {
              $push: [{ ...initialDetailTypeState }],
            },
          });

          this.setState({
            detailSchedule: newState.detailSchedule,
          });
        } else {
          this.props.alert.setSnack(
            getSnackObject.open(errorMessages.scheduleLineMaxReached)
          );
        }

        return;
      }

      // TODO: Confirm the requirement, if restricting schedule line on user defined type is required
      case frequencyTypes.userDefined: {
        // if (
        //   this.state?.userDefinedSchedule?.length < Number(this.state.dosePerDay)
        // ) {
        const newState = update(this.state, {
          userDefinedSchedule: {
            $push: [{ ...initialUserDefinedTypeState }],
          },
        });

        this.setState({
          userDefinedSchedule: newState.userDefinedSchedule,
        });
        // } else {
        //   this.props.alert.setSnack(
        //     getSnackObject.open(errorMessages.scheduleLineMaxReached)
        //   );
        // }

        return;
      }
    }
  };

  handleScheduleLineDeleteClick = (frequencyTypeId, index) => {
    switch (frequencyTypeId) {
      case frequencyTypes.dayBased: {
        const newState = update(this.state, {
          dayBasedSchedule: {
            $splice: [[index, 1]],
          },
        });

        this.setState({
          dayBasedSchedule: newState.dayBasedSchedule,
        });

        return;
      }

      case frequencyTypes.detail: {
        const newState = update(this.state, {
          detailSchedule: {
            $splice: [[index, 1]],
          },
        });

        this.setState({
          detailSchedule: newState.detailSchedule,
        });

        return;
      }

      case frequencyTypes.userDefined: {
        const newState = update(this.state, {
          userDefinedSchedule: {
            $splice: [[index, 1]],
          },
        });

        this.setState({
          userDefinedSchedule: newState.userDefinedSchedule,
        });

        return;
      }
    }
  };

  validateRequiredFields = () => {
    const {
      frequencyCode,
      frequencyName,
      frequencyType,
      totalNoOfDays,
      dosePerDay,
    } = this.state;

    const requiredFields = [
      //   "frequencyCode",
      //   "frequencyName",
      //   "frequencyType",
      //   "totalNoOfDays",
      //   "dosePerDay",
    ];

    if (!frequencyCode) {
      requiredFields.push("Frequency Code");
    }
    if (!frequencyName) {
      requiredFields.push("Frequency Name");
    }
    if (!frequencyType) {
      requiredFields.push("Frequency Type");
    }
    if (frequencyType) {
      if (frequencyType?._id === frequencyTypes.dayBased && !totalNoOfDays) {
        requiredFields.push("Total No Of Days");
      }
      if (
        [frequencyTypes.detail, frequencyTypes.userDefined].includes(
          frequencyType?._id
        ) &&
        !dosePerDay
      ) {
        requiredFields.push("Dose Per Day");
      }
    }

    return requiredFields;
  };

  validateScheduleLineSufficiency = () => {
    const {
      frequencyType,
      dayBasedSchedule,
      detailSchedule,
      intervalSchedule,
      sequentialSchedule,
      userDefinedSchedule,
      totalNoOfDays,
      dosePerDay,
    } = this.state;
    const scheduleLineChecker = (schedule, controlField = "1") => {
      if (schedule.length < Number(controlField)) {
        this.props.alert.setSnack(
          getSnackObject.open(errorMessages.scheduleLineLacksSufficiency)
        );
        return false;
      } else if (schedule.length > Number(controlField)) {
        this.props.alert.setSnack(
          getSnackObject.open(errorMessages.scheduleLineExceedsSufficiency)
        );
        return false;
      }
      return true;
    };

    if (frequencyType?._id === frequencyTypes.dayBased) {
      return scheduleLineChecker(dayBasedSchedule, totalNoOfDays);
    }
    if (frequencyType?._id === frequencyTypes.detail) {
      return scheduleLineChecker(detailSchedule, dosePerDay);
    }
    if (frequencyType?._id === frequencyTypes.interval) {
      return scheduleLineChecker(intervalSchedule);
    }
    if (frequencyType?._id === frequencyTypes.sequential) {
      return scheduleLineChecker(sequentialSchedule);
    }
    if (frequencyType?._id === frequencyTypes.userDefined) {
      return true;
      //scheduleLineChecker(userDefinedSchedule, dosePerDay);
    }
  };

  validateScheduleLineData = () => {
    const {
      frequencyType,
      onceEveryUOM,
      dayBasedSchedule,
      detailSchedule,
      intervalSchedule,
      sequentialSchedule,
      userDefinedSchedule,
    } = this.state;
    switch (frequencyType?._id) {
      case frequencyTypes.dayBased: {
        const isValid = dayBasedSchedule.every((schedule) => {
          return schedule?.day && schedule?.basefreq
          //&& schedule?.remarks;
        });

        if (!isValid) {
          this.props.alert.setSnack(
            getSnackObject.open(errorMessages.scheduleLineDataInvalid)
          );
        }

        return isValid;
      }

      case frequencyTypes.detail: {
        const isValid = detailSchedule.every((schedule) => {
          return schedule?.time
          //&& schedule?.remarks;
        });

        const isTimeValid = detailSchedule.every((schedule) => {
          return isValidDate(schedule?.time);
        });

        if (!isTimeValid) {
          this.props.alert.setSnack(
            getSnackObject.open(errorMessages.invalidTimeFormat)
          );
        }

        if (!isValid) {
          this.props.alert.setSnack(
            getSnackObject.open(errorMessages.scheduleLineDataInvalid)
          );
        }

        return isValid && isTimeValid;
      }

      case frequencyTypes.interval: {
        if (
          onceEveryUOM?._id === durationUOM.Hours ||
          onceEveryUOM?._id === durationUOM.Times
        ) {
          return true;
        }

        const isValid = intervalSchedule.every((schedule) => {
          return schedule?.basefreq
          //&& schedule?.remarks;
        });

        if (!isValid) {
          this.props.alert.setSnack(
            getSnackObject.open(errorMessages.scheduleLineDataInvalid)
          );
        }

        return isValid;
      }

      case frequencyTypes.sequential: {
        const isValid = sequentialSchedule.every((schedule) => {
          return schedule?.basefreq
          //&& schedule?.remarks;
        });

        if (!isValid) {
          this.props.alert.setSnack(
            getSnackObject.open(errorMessages.scheduleLineDataInvalid)
          );
        }

        return isValid;
      }

      case frequencyTypes.userDefined: {
        // const isValid = userDefinedSchedule.every((schedule) => {
        //   return (
        //     schedule?.fromDay &&
        //     schedule?.toDay &&
        //     schedule?.basefreq 
        //     &&
        //     schedule?.remarks
        //   );
        // });
        const isValid = true;
        if (!isValid) {
          this.props.alert.setSnack(
            getSnackObject.open(errorMessages.scheduleLineDataInvalid)
          );
        }

        return isValid;

      }
    }
  };



  saveFrequency = async () => {
    // 1. Validate Required Fields
    const requiredFields = this.validateRequiredFields();

    // 2. If Required Fields are not filled, show error message
    if (requiredFields?.length) {
      //   alert(errorMessages.requiredFields(requiredFields));
      this.props.alert.setSnack(
        getSnackObject.open(errorMessages.requiredFields(requiredFields))
      );
      return;
    }

    // 3. Validate Schedule Lines length depending upon Frequency Type selected
    if (!this.validateScheduleLineSufficiency()) {
      return;
    }

    // 4. Check if the schedule line data are not empty
    if (!this.validateScheduleLineData()) {
      return;
    }

    // 5. check the validity of user defined schedule lines
    if (this.state.frequencyType?._id === frequencyTypes.userDefined) {
      const { userDefinedSchedule } = this.state;

      // 5.a check if user defined schedule lines are valid
      if (!isValidUserDefinedSchedule(userDefinedSchedule)) {
        this.props.alert.setSnack(
          getSnackObject.open(errorMessages.userDefinedFromToDayInvalid)
        );
        return;
      }

      // 5.b check if user defined schedule lines are overlapping
      if (findOverlapForUserDefinedType(userDefinedSchedule)) {
        this.props.alert.setSnack(
          getSnackObject.open(errorMessages.userDefinedOverlap)
        );
        return;
      }
    }

    const payload = {
      ...(!!this.props.location.state?._key && {
        _key: this.props.location.state?._key,
      }),
      frequencyCode: this.state.frequencyCode,
      frequencyName: this.state.frequencyName,
      frequencyType: this.state.frequencyType,

      totalNoOfDays: this.state.totalNoOfDays, // Frequency Type - Day Based
      dosePerDay: this.state.dosePerDay, // Frequency Type - Detail & User Defined
      onceEveryValue: this.state.onceEveryValue, // Frequency Type - Interval
      onceEveryUOM: this.state.onceEveryUOM, // Frequency Type - Interval
      intervalDays: this.state.intervalDays, // Frequency Type - Sequential

      dispenseGracePeriod: this.state.dispenseGracePeriod,
      fixedDurationValue: this.state.fixedDurationValue,
      fixedDurationUOM: this.state.fixedDurationUOM,
      isBaseFrequency: this.state.isBaseFrequency,
      status: this.state.status,

      dayBasedSchedule: this.state.dayBasedSchedule,
      detailSchedule: this.state.detailSchedule,
      intervalSchedule: this.state.intervalSchedule,
      sequentialSchedule: this.state.sequentialSchedule,
      userDefinedSchedule: this.state.userDefinedSchedule,

      directionDtls: this.state.directionDtls,
    };

    const response = await this.props.FREQUENCY_UPSERT(payload);

    if (response?.payload?.data?.Code === 201) {
      this.props.alert.setSnack(
        getSnackObject.open(
          null,
          payload?._key
            ? successMessages.frequencyUpdateSuccess
            : successMessages.frequencySaveSuccess
        )
      );

      // window.location.href = "/frequency";

      if (this.props.history.state?.prevRoute === Routes.frequency) {
        this.props.history.goBack();
      } else {
        this.props.history.push(Routes.frequency);
      }
    } else if (
      response?.payload?.data?.Code === 1111 &&
      response?.payload?.data?.validation_error?.[0]?.Errormsg?.toString()?.includes(
        "freqcode"
      )
    ) {
      this.props.alert.setSnack(
        getSnackObject.open(errorMessages.duplicateFrequencyCode)
      );
      return;
    } else {
      this.props.alert.setSnack(getSnackObject.open(errorMessages.default));
    }
  };

  getInputBasedOnFrequencyType = () => {
    const isView = this.props.location?.state?.isView;
    const { handleChangeState } = this;
    const { frequencymaster_uom_dropdownData } = this.props;
    const {
      frequencyType,
      totalNoOfDays,
      dosePerDay,
      onceEveryValue,
      onceEveryUOM,
      intervalDays,
    } = this.state;

    switch (frequencyType?._id) {
      case frequencyTypes.dayBased:
        return (
          <Grid item xs={12} sm={6} md={3}>
            <FieldContainer>
              <FieldLabel
                id={"Total_No_Of_Days_Typography"}
                variant={"body2"}
                required={true}
              >
                Total No Of Days
              </FieldLabel>
              <InputBase
                id={"Total_No_Of_Days_Field"}
                type={"text"}
                variant={"outlined"}
                size={"small"}
                fullWidth={true}
                disabled={isView}
                value={totalNoOfDays}
                onChange={(event) => {
                  if (
                    (event.target.value === "" ||
                      /^[1-9][0-9]{0,3}$/.test(event.target.value)) &&
                    Number(event.target.value <= 7)
                  ) {
                    handleChangeState("totalNoOfDays", event.target.value);
                  }
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              ></InputBase>
            </FieldContainer>
          </Grid>
        );

      case frequencyTypes.detail:
      case frequencyTypes.userDefined:
        return (
          <Grid item xs={12} sm={6} md={2}>
            <FieldContainer>
              <FieldLabel
                id={"Dose_Per_Day_Typography"}
                variant={"body2"}
                required={true}
              >
                Dose Per Day
              </FieldLabel>
              <InputWithLegend
                legend="Times"
                legendWidth={"35%"}
                id={"Dose_Per_Day_Field"}
                type={"text"}
                variant={"outlined"}
                size={"small"}
                fullWidth={true}
                disabled={isView}
                value={dosePerDay}
                onChange={(event) => {
                  if (
                    event.target.value === "" ||
                    /^[1-9][0-9]{0,5}$/.test(event.target.value)
                  ) {
                    handleChangeState("dosePerDay", event.target.value);
                  }
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              ></InputWithLegend>
            </FieldContainer>
          </Grid>
        );

      case frequencyTypes.interval:
        return (
          <Grid item xs={12} sm={6} md={3}>
            <FieldContainer>
              <FieldLabel
                id={"Once_Every_Typography"}
                variant={"body2"}
                required={false}
              >
                Once Every
              </FieldLabel>
              <TextfieldWithSelect
                InputProps={{
                  id: `Once_Every_Value_Field`,
                  type: "text",
                  fullWidth: true,
                  disabled: isView,
                  value: onceEveryValue,
                  onChange: (event) => {
                    if (
                      event.target.value === "" ||
                      /^[1-9][0-9]{0,3}$/.test(event.target.value)
                    ) {
                      handleChangeState("onceEveryValue", event.target.value);
                    }
                  },
                  onKeyPress: (event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  },
                }}
                AutocompleteProps={{
                  id: `Once_Every_UOM_Field`,
                  disabled: isView,
                  // disabled:
                  //   location?.state?.isView === true || !shouldDisable
                  //     ? true
                  //     : false,
                  getOptionLabel: (option) => option?.["display"],
                  options: frequencymaster_uom_dropdownData?.result ?? [],
                  value: onceEveryUOM ?? null,
                  onLoad: () => handleChangeState("onceEveryUOM", {}),
                  onChange: (event, newValue) =>
                    handleChangeState("onceEveryUOM", newValue),
                }}
              />
            </FieldContainer>
          </Grid>
        );

      case frequencyTypes.sequential:
        return (
          <Grid item xs={12} sm={6} md={2}>
            <FieldContainer>
              <FieldLabel
                id={"Interval_Days_Typography"}
                variant={"body2"}
                required={false}
              >
                Interval Days
              </FieldLabel>
              <InputBase
                id={"Interval_Days_Field"}
                type={"text"}
                variant={"outlined"}
                size={"small"}
                fullWidth={true}
                disabled={isView}
                value={intervalDays}
                onChange={(event) => {
                  if (
                    event.target.value === "" ||
                    /^[1-9][0-9]{0,3}$/.test(event.target.value)
                  ) {
                    handleChangeState("intervalDays", event.target.value);
                  }
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              ></InputBase>
            </FieldContainer>
          </Grid>
        );

      default:
        return <></>;
    }
  };

  render() {
    const {
      classes,
      frequencymaster_frequencytype_dropdownData,
      // frequencymaster_uom_dropdownData,
      fixedDurationUOMOptions,
    } = this.props;
    const {
      goToListing = () => { },
      saveFrequency = () => { },
      handleChangeState = () => { },
      getInputBasedOnFrequencyType = () => { },
      handleScheduleLineChangeState = () => { },
      handleScheduleLineAddNewClick = () => { },
      handleScheduleLineDeleteClick = () => { },
    } = this;

    const {
      frequencyCode,
      frequencyName,
      frequencyType,
      onceEveryUOM,
      dispenseGracePeriod,
      fixedDurationValue,
      fixedDurationUOM,
      isBaseFrequency,
      status,
      directionDtls,
    } = this.state;

    const isView = this.props.location?.state?.isView;

    const successMessage = (isEdit) => {
      if (isEdit) {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "Updated Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      } else {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "Added Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
    }

    return (
      <div>
        <FrequencyAddEditWrapper
          _key={this.props.location?.state?._key}
          isView={this.props.location?.state?.isView}
          onBackClick={() => {
            this.props.history.push(Routes.frequency);
          }}
          onCancelClick={() => {
            this.props.history.push(Routes.frequency);
          }}
          onSaveSuccess={(_key) => {
            this.props.history.push(Routes.frequency);
            successMessage(this.props.location?.state?._key);
          }}
        />
      </div>
      // <div className={classes.container}>
      //   <Box
      //     display={"flex"}
      //     justifyContent="space-between"
      //     className={classes.topBarContainer}
      //   >
      //     <Box
      //       display={"flex"}
      //       alignItems="center"
      //       className={classes.formTitleContainer}
      //     >
      //       <IconButton
      //         size="small"
      //         className={classes.backicon}
      //         onClick={goToListing}
      //       >
      //         <KeyboardArrowLeftIcon htmlColor="#000000" />
      //       </IconButton>
      //       <Typography className={classes.formTitle}>
      //         {
      //           getDynamicContent(Boolean(this.props.location.state?._key))
      //             .title
      //         }
      //       </Typography>
      //     </Box>
      //     <Box
      //       display={"flex"}
      //       alignItems="center"
      //       className={classes.actionButtonsContainer}
      //     >
      //       <Button onClick={goToListing}>Cancel</Button>
      //       <Button
      //         disabled={isView}
      //         color="primary"
      //         variant="contained"
      //         onClick={saveFrequency}
      //       >
      //         {
      //           getDynamicContent(Boolean(this.props.location.state?._key))
      //             .buttonText
      //         }
      //       </Button>
      //     </Box>
      //   </Box>

      //   {/* Main Section */}
      //   <StyledPaper className={classes.paper}>
      //     <Grid container spacing={4}>
      //       {/* Row 1 */}
      //       <Grid container item spacing={2}>
      //         {/* Frequency Code */}
      //         <Grid item xs={12} sm={6} md={2}>
      //           <FieldContainer>
      //             <FieldLabel
      //               id={"Frequency_Code_Typography"}
      //               variant={"body2"}
      //               required={true}
      //             >
      //               Frequency Code
      //             </FieldLabel>
      //             <InputBase
      //               id={"Frequency_Code_Field"}
      //               type={"text"}
      //               variant={"outlined"}
      //               size={"small"}
      //               fullWidth={true}
      //               disabled={isView}
      //               value={frequencyCode}
      //               onChange={(event) => {
      //                 // Prevent if the string length is greater than 10
      //                 if (event.target.value?.length <= 10) {
      //                   handleChangeState(
      //                     "frequencyCode",
      //                     event.target.value
      //                       ?.toUpperCase()
      //                       ?.replace(/[^a-zA-Z0-9]/gi, "")
      //                   );
      //                 }
      //               }}
      //               onKeyPress={(event) => {
      //                 // Prevent if it is a special character
      //                 if (!/[a-zA-Z0-9]/.test(event.key)) {
      //                   event.preventDefault();
      //                 }
      //               }}
      //             ></InputBase>
      //           </FieldContainer>
      //         </Grid>

      //         {/* Frequency Name */}
      //         <Grid item xs={12} sm={6} md={4}>
      //           <FieldContainer>
      //             <FieldLabel
      //               id={"Frequency_Name_Typography"}
      //               variant={"body2"}
      //               required={true}
      //             >
      //               Frequency Name
      //             </FieldLabel>
      //             <InputBase
      //               id={"Frequency_Name_Field"}
      //               type={"text"}
      //               variant={"outlined"}
      //               size={"small"}
      //               fullWidth={true}
      //               disabled={isView}
      //               value={frequencyName}
      //               onChange={(event) => {
      //                 if (event.target.value?.length <= 100) {
      //                   handleChangeState("frequencyName", event.target.value);
      //                 }
      //               }}
      //             //   onKeyPress={(event) => {
      //             //     if (!/[0-9]/.test(event.key)) {
      //             //       event.preventDefault();
      //             //     }
      //             //   }}
      //             ></InputBase>
      //           </FieldContainer>
      //         </Grid>

      //         {/* Frequency Type */}
      //         <Grid item xs={12} sm={6} md={3}>
      //           <FieldContainer>
      //             <FieldLabel
      //               id={"Frequency_Type_Typography"}
      //               variant={"body2"}
      //               required={true}
      //             >
      //               Frequency Type
      //             </FieldLabel>
      //             <Autocomplete
      //               id={"Frequency_Type_Field"}
      //               disabled={isView}
      //               value={frequencyType ?? null}
      //               onLoad={() =>
      //                 handleChangeState(
      //                   "frequencyType",
      //                   this.state.frequencyType
      //                 )
      //               }
      //               getOptionLabel={(option) => option?.["display"]}
      //               options={
      //                 frequencymaster_frequencytype_dropdownData?.result ?? []
      //               }
      //               onChange={(e, newvalue) =>
      //                 handleChangeState("frequencyType", newvalue)
      //               }
      //             />
      //           </FieldContainer>
      //         </Grid>
      //       </Grid>

      //       {/* Row 2 */}
      //       <Grid container item spacing={2}>
      //         {/* Dynamic Field */}
      //         {getInputBasedOnFrequencyType()}

      //         {/* Dispense Grace Period */}
      //         <Grid item xs={12} sm={6} md={3}>
      //           <FieldContainer>
      //             <FieldLabel
      //               id={"Dispense_Grace_Period_Typography"}
      //               variant={"body2"}
      //               required={false}
      //             >
      //               Dispense Grace Period
      //             </FieldLabel>
      //             <InputWithLegend
      //               legend="Hrs"
      //               id={"Dispense_Grace_Period_Field"}
      //               type={"text"}
      //               variant={"outlined"}
      //               size={"small"}
      //               fullWidth={true}
      //               disabled={isView}
      //               value={dispenseGracePeriod}
      //               onChange={(event) => {
      //                 if (
      //                   (event.target.value === "" ||
      //                     /^[1-9][0-9]{0,3}$/.test(event.target.value)) &&
      //                   Number(event.target.value <= 24)
      //                 ) {
      //                   handleChangeState(
      //                     "dispenseGracePeriod",
      //                     event.target.value
      //                   );
      //                 }
      //               }}
      //               onKeyPress={(event) => {
      //                 if (!/[0-9]/.test(event.key)) {
      //                   event.preventDefault();
      //                 }
      //               }}
      //             ></InputWithLegend>
      //           </FieldContainer>
      //         </Grid>

      //         {/* Fixed Duration */}
      //         <Grid item xs={12} sm={6} md={3}>
      //           <FieldContainer>
      //             <FieldLabel
      //               id={"Fixed_Duration_Typography"}
      //               variant={"body2"}
      //               required={false}
      //             >
      //               Fixed Duration
      //             </FieldLabel>
      //             <TextfieldWithSelect
      //               InputProps={{
      //                 id: `Fixed_Duration_Value_Field`,
      //                 type: "text",
      //                 // fullWidth: true,
      //                 disabled: isView,
      //                 value: fixedDurationValue,
      //                 onChange: (event) => {
      //                   if (
      //                     event.target.value === "" ||
      //                     /^[1-9][0-9]{0,3}$/.test(event.target.value)
      //                   ) {
      //                     handleChangeState(
      //                       "fixedDurationValue",
      //                       event.target.value
      //                     );
      //                   }
      //                 },
      //                 onKeyPress: (event) => {
      //                   if (!/[0-9]/.test(event.key)) {
      //                     event.preventDefault();
      //                   }
      //                 },
      //               }}
      //               AutocompleteProps={{
      //                 id: `Fixed_Duration_UOM_Field`,
      //                 disabled: isView,
      //                 // disabled:
      //                 //   location?.state?.isView === true || !shouldDisable
      //                 //     ? true
      //                 //     : false,
      //                 getOptionLabel: (option) => option?.["label"],
      //                 options: Array.isArray(fixedDurationUOMOptions?.data) ? fixedDurationUOMOptions?.data : [],
      //                 value: fixedDurationUOM ?? null,
      //                 onLoad: () => handleChangeState("fixedDurationUOM", {}),
      //                 onChange: (event, newValue) =>
      //                   handleChangeState("fixedDurationUOM", newValue),
      //               }}
      //             />
      //           </FieldContainer>
      //         </Grid>

      //         {/* Is Base Frequency */}
      //         {frequencyType?._id === frequencyTypes.detail && (
      //           <Grid item xs={12} sm={6} md={2}>
      //             <FormControlLabel
      //               value="top"
      //               disabled={isView}
      //               control={
      //                 <Checkbox
      //                   color="primary"
      //                   checked={isBaseFrequency}
      //                   onChange={(event) =>
      //                     handleChangeState(
      //                       "isBaseFrequency",
      //                       event.target.checked
      //                     )
      //                   }
      //                 />
      //               }
      //               label="Is Base Frequency?"
      //               labelPlacement="top"
      //               classes={{ label: classes.formControlLabel }}
      //             />
      //           </Grid>
      //         )}

      //         {/* Status */}
      //         <Grid item xs={12} sm={6} md={1}>
      //           <FormControlLabel
      //             value="top"
      //             disabled={isView}
      //             control={
      //               <Switch
      //                 color="primary"
      //                 checked={status}
      //                 onChange={(event) =>
      //                   handleChangeState("status", event.target.checked)
      //                 }
      //               />
      //             }
      //             label="Status"
      //             labelPlacement="top"
      //             classes={{ label: classes.formControlLabel }}
      //           />
      //         </Grid>
      //       </Grid>
      //     </Grid>
      //   </StyledPaper>

      //   {/* Schedule Line */}
      //   {shouldShowScheduleLine(frequencyType, onceEveryUOM) && (
      //     <StyledPaper className={classes.paper}>
      //       <ScheduleLine
      //         isView={isView}
      //         type={frequencyType}
      //         dayBasedSchedule={this.state.dayBasedSchedule}
      //         detailSchedule={this.state.detailSchedule}
      //         intervalSchedule={this.state.intervalSchedule}
      //         sequentialSchedule={this.state.sequentialSchedule}
      //         userDefinedSchedule={this.state.userDefinedSchedule}
      //         handleChangeState={handleScheduleLineChangeState}
      //         handleAddNewClick={handleScheduleLineAddNewClick}
      //         handleDeleteClick={handleScheduleLineDeleteClick}
      //       />
      //     </StyledPaper>
      //   )}

      //   {/* Directions */}
      //   <StyledPaper className={classes.directionPaper}>
      //     <Directioncomp
      //       propsState={this.state}
      //       setFun={(value) => {
      //         handleChangeState("directionDtls", value);
      //       }}
      //       stateName={"directionDtls"}
      //       shouldDisable={true}
      //       statevalue={directionDtls}
      //     // options={{
      //     //   instruction_type: options?.instruction_type,
      //     // }}
      //     />
      //   </StyledPaper>
      // </div>
    );
  }
}

const mapStateToProps = (state) => ({
  frequencymaster_frequencytype_dropdownData:
    state
      ?.addnewfrequency_orgfrequencymaster_frequencytype_dropdown_OE55YReducer
      ?.frequencymaster_frequencytype_dropdown_OE55Y?.data,
  frequencymaster_uom_dropdownData:
    state?.addnewfrequency_orgfrequencymaster_uom_dropdown_OE55YReducer
      ?.frequencymaster_uom_dropdown_OE55Y?.data,
  baseFrequencyResponse:
    state?.EditNewFrequency_orgbase_frequency_dropdownReducer
      ?.base_frequency_dropdown?.data?.result,
  fixedDurationUOMOptions: state?.frequencyMasterSlice?.frequencyUomTimeTypeOptions
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withStyles(styles)(withAllContexts(FrequencyAddEdit))));
