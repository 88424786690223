import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  StyledDialog,
  StyledDivider,
  StyledPopover,
  StyledTooltip,
} from "../commonComponents";
import Dot from "../dot";
import {
  DoctorIcon,
  InfoIcon,
  ScheduleIcon,
  StackOverflowIcon,
  TimesheetIcon,
} from "../../assets";
import PropTypes from "prop-types";
import {
  usePopupState,
  bindTrigger,
  bindHover,
  bindPopover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { FrequencySchedue, OrderPractitionerInfo } from "./popovers";
import {
  FrequencyFieldsByScheduleType,
  FrequencyDetailsByScheduleType,
} from "../../utils";
import { Directions } from "./popovers";
import { getUnixTime } from "date-fns";
import TimeComponent from "../timeComponent";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import DrugOrderDetails from "../drugOrderDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { EditInstructions } from "..";

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 980,
    borderRadius: 8,
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
  },
}));

const useDialogContentStyles = makeStyles((theme) => ({
  dividers: {
    padding: 0,
    borderColor: "rgba(222, 229, 236, 1Batch)",
  },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  icon: {
    minWidth: 14,
    cursor: "pointer",
  },
  typography: {
    color: theme.palette.text.grey,
  },
  divider: {
    height: 18,
    alignSelf: "center",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  orderInfoContent: {
    padding: theme.spacing(1.5),
  },
  sizeSmall: {
    padding: theme.spacing(0.5),
  },
  particularText: {
    fontSize: 12,
    cursor: "pointer",
  },
  textWrap: {
    whiteSpace: "nowrap",
    maxWidth: "fit-content",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  clickable: {
    cursor: "pointer",
    display: "flex",
  },
}));

function Description(props) {
  // <---------------Hooks------------------>
  const classes = useStyles();
  const dialogStyles = useDialogStyles();
  const dialogContentStyles = useDialogContentStyles();
  const [editDirecton, setEditDirection] = React.useState(false);

  const frequencySchedulePopupState = usePopupState({
    variant: "popover",
    popupId: "frequency-schedule",
  });

  const doctorNamePopupState = usePopupState({
    variant: "popover",
    popupId: "doctor-name",
  });

  const drugNamePopupState = usePopupState({
    variant: "popover",
    popupId: "drugName",
  });

  const directionsPopupState = usePopupState({
    variant: "popover",
    popupId: "directions",
  });

  // <---------------Store States----------->

  // <-------------Component States--------->
  const [isOrderInfoOpen, setIsOrderInfoOpen] = useState(false);

  // <-----------------Handlers------------->
  const handleOrderInfoDialogToggle = () => {
    setIsOrderInfoOpen((prev) => !prev);
  };

  // <-----------------Effects-------------->

  // <-----------------Others--------------->
  const dividerProps = {
    orientation: "vertical",
    flexItem: true,
    className: classes.divider,
  };

  const typographyProps = {
    variant: "body2",
    // color: "textSecondary",
    className: clsx(classes.typography, classes.textWrap),
  };

  // <-----------------Render--------------->
  const {
    isIntravenous,
    route,
    allowChangeDrug,
    name,
    dosage,
    frequency,
    duration,
    doctorName,
    speciality,
    occurStartDateTime,
    occurEndDateTime,
    dosageFrequencyType,
    dosageFrequencyCode,
    orderFrequencyDetails,
    orderid,
    handleMedicationEdit,
    options,
    handleChangebrandName,
    isVerficationState,
    direction,
    nlDirection,
    handleDirectionSaveClick,
  } = props;

  return (
    <div className={classes.container}>
      {isIntravenous ? (
        <>
          <InfoIcon
            className={classes.icon}
            onClick={handleOrderInfoDialogToggle}
          />
          <StyledDivider {...dividerProps} />
          <Typography className={classes.textWrap}>{route}</Typography>
          <Dot />
          <Typography className={classes.textWrap}>{name}</Typography>
          <Dot />
          <div
            className={classes.iconContainer}
            {...bindHover(doctorNamePopupState)}
          >
            <DoctorIcon className={classes.icon} />
          </div>
          <Dot />
          <TimeComponent
            dateTime={getUnixTime(new Date(occurStartDateTime))}
            formatType={"datetime"}
            className={classes.particularText}
          />
        </>
      ) : (
        <>
          <InfoIcon
            className={classes.icon}
            onClick={handleOrderInfoDialogToggle}
          />
          <StyledDivider {...dividerProps} />
          <Typography className={classes.textWrap}>{route}</Typography>
          <Dot />
          <div
            className={clsx(classes.clickable)}
            {...(allowChangeDrug && { ...bindTrigger(drugNamePopupState) })}
          >
            <Typography className={classes.textWrap}>{name}</Typography>
            {allowChangeDrug && <ExpandMoreIcon fontSize="small" />}
          </div>
          <Typography {...typographyProps}>{dosage}</Typography>
          <Dot />
          <Typography {...typographyProps}>{frequency}</Typography>
          <Dot />
          <StyledTooltip title="Frequency Schedule">
            <div
              className={classes.iconContainer}
              {...bindTrigger(frequencySchedulePopupState)}
            >
              <ScheduleIcon className={classes.icon} />
            </div>
          </StyledTooltip>
          <Dot />
          <div
            className={classes.iconContainer}
            {...bindHover(doctorNamePopupState)}
          >
            <DoctorIcon className={classes.icon} />
          </div>
          <Dot />
          <Tooltip
            title={
              <TimeComponent
                dateTime={[
                  getUnixTime(new Date(occurStartDateTime)),
                  getUnixTime(new Date(occurEndDateTime)),
                ]}
                separator={" - "}
                formatType={"datetime"}
                className={clsx(classes.particularText, classes.textWrap)}
              />
            }
          >
            <Typography {...typographyProps}>{duration}</Typography>
          </Tooltip>

          <Dot />
          <TimeComponent
            dateTime={getUnixTime(new Date(occurStartDateTime))}
            formatType={"datetime"}
            className={clsx(classes.particularText, classes.textWrap)}
          />

          <Dot />
          <IconButton
            size="small"
            classes={{ sizeSmall: classes.sizeSmall }}
            {...bindHover(directionsPopupState)}
          >
            <TimesheetIcon />
          </IconButton>
        </>
      )}

      {/* Menu for Drug Name Dropdown Icon */}
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...bindMenu(drugNamePopupState)}
      >
        {options?.map((item) => {
          return (
            <MenuItem onClick={() => handleChangebrandName(item.drugNameLong)}>
              {item.drugNameLong}
            </MenuItem>
          );
        })}
      </Menu>

      {/* Frequency Schedule */}
      <StyledPopover
        maxWidth={700}
        {...bindPopover(frequencySchedulePopupState)}
      >
        <FrequencySchedue
          columns={FrequencyFieldsByScheduleType(dosageFrequencyType)}
          rows={FrequencyDetailsByScheduleType(orderFrequencyDetails)}
          scheduleTypeCode={dosageFrequencyCode}
        />
      </StyledPopover>

      {/* Order Info - Doctor Details */}
      <StyledPopover renderHoverPopover {...bindPopover(doctorNamePopupState)}>
        <OrderPractitionerInfo
          speciality={speciality}
          doctorName={doctorName}
        />
      </StyledPopover>

      {/* Drug Order Info */}
      <StyledDialog
        id="Order_Details"
        open={isOrderInfoOpen}
        handleClose={handleOrderInfoDialogToggle}
        title={"ORDER DETAILS"}
        dialogProps={{
          classes: dialogStyles,
        }}
        contentProps={{
          classes: dialogContentStyles,
        }}
      >
        <DrugOrderDetails
          id={orderid}
          showPractitionerEditButton={true}
          handleEditClick={() => handleMedicationEdit(orderid)}
        />
      </StyledDialog>

      {/* Directions */}
      <StyledPopover renderHoverPopover {...bindPopover(directionsPopupState)}>
        <Directions
          isVerficationState={isVerficationState}
          directions={[direction, nlDirection]}
          handleEditClick={() => {
            setEditDirection(true);
          }}
        />
      </StyledPopover>
      {/* 
      Direction edit */}

      <EditInstructions
        directionDialog={editDirecton}
        handleEditDirectionsClose={() => {
          setEditDirection(false);
        }}
        directionDescripion={direction}
        directionLocalLanguage={nlDirection}
        handleDirectionSaveClick={(e) => handleDirectionSaveClick(e)}
      />
    </div>
  );
}

export default Description;

Description.propTypes = {
  isIntravenous: PropTypes.bool,
  route: PropTypes.string,
  name: PropTypes.string,
  dosage: PropTypes.string,
  frequency: PropTypes.string,
  duration: PropTypes.string,
  doctorName: PropTypes.string,
  speciality: PropTypes.string,
};
