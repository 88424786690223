import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import React, { useState } from "react";
import FieldContainer from "../fieldContainer";
import FieldLabel from "../fieldLabel";
import { Autocomplete, InputBase } from "../inputs";
import { DateTimePicker } from "../common/smartForm/component";
import { makeStyles } from "@material-ui/core";
import CustomButton from "../customButton";
import { FIELD_TYPES } from "./utils";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 16,
    fontWeight: 500,
  },
  body: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 16,
    padding: 20,
  },
  cta: {
    display: "flex",
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    gap: 8,
  },
}));

function FilterComponent({
  headCells,
  state,
  setState,
  onApplyFilterClick,
  onCancelClick,
  ...props
}) {
  const classes = useStyles();

  const [text, setText] = useState("");

  const getField = (cell) => {
    switch (cell?.datatype) {
      case FIELD_TYPES.text:
      case FIELD_TYPES.string:
      case FIELD_TYPES.number: {
        return (
          <InputBase
            value={state?.[cell?.id]}
            onChange={(event) => setState(cell?.id, event.target.value)}
            {...(FIELD_TYPES.number && {
              onKeyPress: (event) => {
                if (!/[0-9.]/.test(event.key)) {
                  event.preventDefault();
                }
              },
            })}
          />
        );
      }
      case FIELD_TYPES.switch: {
        return (
          <Switch
            checked={state?.[cell?.id]}
            onChange={(event) => setState(cell?.id, event.target.checked)}
          />
        );
      }
      case FIELD_TYPES.autocomplete: {
        return (
          <Autocomplete
            getOptionLabel={(option) => option.label}
            options={[]}
            value={state?.[cell?.id]}
            onChange={(event, newValue) => setState(cell?.id, newValue)}
          />
        );
      }
      case FIELD_TYPES.datepicker:
      case FIELD_TYPES.date: {
        return (
          <DateTimePicker
            value={state?.[cell?.id]}
            onChange={(newDate) => setState(cell?.id, newDate)}
          />
        );
      }
      case FIELD_TYPES.timepicker: {
        return <></>;
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <div>
      <Typography className={classes.title}>Filter</Typography>
      <Divider />

      <div className={classes.body}>
        {headCells.map((cell) => (
          <FieldContainer key={cell?.id}>
            <FieldLabel>{cell?.display}</FieldLabel>
            {getField(cell)}
          </FieldContainer>
        ))}

        <div className={classes.cta}>
          <CustomButton variant="contained" onClick={onApplyFilterClick}>
            Apply Filter
          </CustomButton>
          <CustomButton variant="outlined" onClick={onCancelClick}>
            Cancel
          </CustomButton>
        </div>
      </div>

      {/* <FieldContainer>
        <FieldLabel>Label</FieldLabel>
      </FieldContainer>

      <FieldContainer>
        <FieldLabel>Label</FieldLabel>
        <DateTimePicker value={null} onChange={() => {}} />
      </FieldContainer>

      <FieldContainer>
        <FieldLabel>Label</FieldLabel>
        <Autocomplete
          getOptionLabel={(option) => option.label}
          options={[]}
          value={null}
          onChange={() => {}}
        />
      </FieldContainer>

      <FieldContainer>
        <FieldLabel>Label</FieldLabel>
        <Switch checked={false} onChange={() => {}} />
      </FieldContainer> */}
    </div>
  );
}

export default FilterComponent;
