import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Box, Paper, Typography, Grid, TextField } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

import qdmstyles from "./styles.json";
import "./index.css";
var aes256 = require("aes256");


const useStyles = makeStyles((theme) => ({
  childtitle: {
    color: theme.palette.primary.light
  },
  address: {
    color: theme.palette.text.primary,
  },
  addresspharmacy: {
    backgroundColor: theme.palette.background.table,
  },
  notchedOutline:{
    borderColor:theme.palette.border.textField
  },
}))


function AddressPharmacy(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
    address_pharmacy__gdjrv: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const onchange_gdjrv = (e, index) => {
    if (e?.target?.value.length <= 50) {
      let updatestate = {};

      setState({
        ...state,
        ...updatestate,
        address_pharmacy__gdjrv: e?.target?.value,
      });
      let stateValue = props.statevalue;
      stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

      stateValue[index] = {
        ...stateValue[index],
        address_pharmacy__gdjrv: e?.target?.value,
      };
      props.setFun(stateValue);
    }
  };
  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  const { data, index } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Paper
            key={"0"}
            id={"yl1ZB"}
            elevation={"{3}"}
            style={qdmstyles.RJzb}
            onLoad={(e) => props.setFun(props?.statevalue ?? [{}])}
            className={classes.addresspharmacy}
          >
            {(props?.statevalue ?? [{}])?.map((data, index) => {
              return (
                <React.Fragment>
                  <Typography
                    key={"0"}
                    id={"address_header"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    className={classes.address}
                    variant={"body1"}
                    children={t("pharmacy.addNewDrugCatalogMaster.address")}
                    aliasName={"addressheader"}
                    style={qdmstyles.pXSc}
                  ></Typography>
                  <Grid
                    key={"1"}
                    id={"Dd1WL"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                  >
                    <div style={{ display: "flex" }}>
                      <Typography
                        key={"0"}
                        id={"location_address_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        className={classes.childtitle}
                        variant={"body1"}
                        children={"Location address"}
                        aliasName={"locationaddressheader"}
                        style={qdmstyles.xabis}
                      ></Typography>
                      {props?.required ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <TextField
                      key={"1"}
                      value={
                        state?.address_pharmacy__gdjrv ||
                        data?.address_pharmacy__gdjrv ||
                        null
                      }
                      InputProps={{ classes: {notchedOutline: classes.notchedOutline}}}
                      onChange={(e) => onchange_gdjrv(e, index)}
                      id={"location_address_textfield"}
                      label={""}
                      placeholder={"Location address"}
                      type={"text"}
                      variant={"outlined"}
                      inputProps={{
                        maxLength: 100,
                      }}
                      fullWidth={true}
                      size={"small"}
                      aliasName={"locationaddresstextfield"}
                      margin={"dense"}
                    ></TextField>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Paper>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(AddressPharmacy));
