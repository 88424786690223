import React from "react";
import { VerticalList, } from "../../../../components";
import { MeasureCode } from "./measureCode";
import { Vitals } from "./vitalsMasters";
import { VitalsCriteria } from "./VitalsApplicableCriteria";
class VitalsMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openIndex: null,
    };
    this.handleAddBtn = this.handleAddBtn.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.changeState = this.changeState.bind(this);
  }
  handleAddBtn = (index) => {
    let { open } = this.state;
    this.setState({
      open: !open,
      openIndex: index,
    });
  };
  changeState = (name, value) => {
    this.setState({ [name]: value });
  };
  changeTab = () => {
    this.setState({ open: false });
  };
  render() {
    const { open, openIndex } = this.state;
    return (
      <React.Fragment>
        <VerticalList
          parent_id={"consult_master_vitals"}
          isLeftList={true}
          addFunc={this.handleAddBtn}
          changeTab={this.changeTab}
          TabList={[
            {
              name: "Measure code",
              component: (
                <MeasureCode
                  parent_id={"consult_master_MeasureCode"}
                  changeState={this.changeState}
                  open={open}
                  openIndex={openIndex}
                />
              ),
            },
            {
              name: "Vitals Master",
              component: (
                <Vitals
                  parent_id={"consult_master_Vitals"}
                  closeFrom={this.handleAddBtn}
                  open={open}
                  openIndex={openIndex}
                />
              ),
            },
            {
              name: "Vitals Applicable Criterias",
              component: (
                <VitalsCriteria
                  parent_id={"consult_master_Vitals"}
                  closeFrom={this.handleAddBtn}
                  open={open}
                  openIndex={openIndex}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

export default VitalsMaster;
