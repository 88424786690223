import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Grid, Box, Typography, Switch } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
var aes256 = require("aes256");

function Pharmacy(props) {
  const dispatch = useDispatch();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {}, [state]);

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"6Oy1e"}
            container={true}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            style={qdmstyles.Oye}
          >
            <Grid
              key={"0"}
              id={"vIVoi"}
              container={true}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              lg={"12"}
              md={"12"}
              sm={"12"}
            >
              <Grid
                key={"0"}
                id={"wbGSS"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                lg={"8"}
                md={"8"}
                sm={"8"}
              >
                <Grid
                  key={"0"}
                  id={"KqKI1"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  md={"3"}
                  sm={"3"}
                  style={qdmstyles.KqKI}
                >
                  <Box
                    key={"0"}
                    id={"GlSaC"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.KRFl}
                  ></Box>
                  <Typography
                    key={"1"}
                    id={"zuJj4"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"OP Pharmacy-01"}
                    style={qdmstyles.dBwX}
                  ></Typography>
                </Grid>
                <Grid
                  key={"1"}
                  id={"KqKI1"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"3"}
                  md={"3"}
                  sm={"3"}
                  style={qdmstyles.wuD}
                >
                  <Box
                    key={"0"}
                    id={"GlSaC"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.JrvJ}
                  ></Box>
                  <Typography
                    key={"1"}
                    id={"zuJj4"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"PH-001"}
                    style={qdmstyles.dOmwT}
                  ></Typography>
                </Grid>
                <Grid
                  key={"2"}
                  id={"KqKI1"}
                  container={false}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"6"}
                  md={"6"}
                  sm={"6"}
                  style={qdmstyles.YOmZ}
                >
                  <Box
                    key={"0"}
                    id={"GlSaC"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.urgMQ}
                  ></Box>
                  <Typography
                    key={"1"}
                    id={"zuJj4"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"AINQA Multi-Speciality Hospitals, Chennai"}
                    style={qdmstyles.LOj}
                  ></Typography>
                </Grid>
              </Grid>
              <Grid
                key={"1"}
                id={"3aPDJ"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                lg={"2"}
                md={"2"}
                sm={"2"}
              >
                <Box
                  key={"0"}
                  id={"GlSaC"}
                  m={"1"}
                  component={"div"}
                  style={qdmstyles.tISd}
                ></Box>
                <Switch
                  key={"1"}
                  id={"O9WUj"}
                  checked={true}
                  color={"primary"}
                  name={"switch"}
                ></Switch>
              </Grid>
            </Grid>
            <Grid
              key={"1"}
              id={"VaAbw"}
              container={true}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              lg={"12"}
              md={"12"}
              sm={"12"}
              style={qdmstyles.OXvAQ}
            >
              <Grid
                key={"0"}
                id={"9salj"}
                container={false}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                lg={"4"}
                item={true}
                md={"4"}
                sm={"4"}
                style={qdmstyles.acgL}
              >
                <Box
                  key={"0"}
                  id={"GlSaC"}
                  m={"1"}
                  component={"div"}
                  style={qdmstyles.BgMc}
                ></Box>
                <Typography
                  key={"1"}
                  id={"zuJj4"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"D-Block, Second Floor, Ward 2"}
                  style={qdmstyles.uBGXH}
                ></Typography>
              </Grid>
              <Grid
                key={"1"}
                id={"9salj"}
                container={true}
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                lg={"8"}
                item={true}
                md={"8"}
                sm={"8"}
                style={qdmstyles.RhW}
              >
                <Grid
                  key={"0"}
                  id={"E106v"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item={true}
                  lg={"4"}
                  md={"4"}
                  sm={"4"}
                  style={qdmstyles.XTq}
                >
                  <Grid
                    key={"0"}
                    id={"TaHRq"}
                    container={true}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    style={qdmstyles.TaHRq}
                  >
                    <Box
                      key={"0"}
                      id={"GlSaC"}
                      m={"1"}
                      component={"div"}
                      style={qdmstyles.GL}
                    ></Box>
                    <Typography
                      key={"1"}
                      id={"zuJj4"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"+(769)773-4125"}
                      style={qdmstyles.kVQJ}
                    ></Typography>
                  </Grid>
                </Grid>
                <Grid
                  key={"1"}
                  id={"E106v"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  item={true}
                  lg={"4"}
                  md={"4"}
                  sm={"4"}
                  style={qdmstyles.NYuJQ}
                >
                  <Box
                    key={"0"}
                    id={"2A87p"}
                    m={"1"}
                    component={"div"}
                    style={qdmstyles.RjNVm}
                  >
                    <Box
                      key={"0"}
                      id={"GlSaC"}
                      m={"1"}
                      component={"div"}
                      style={qdmstyles.XRGr}
                    ></Box>
                    <Typography
                      key={"1"}
                      id={"zuJj4"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"sara.lucas@mail.com"}
                      style={qdmstyles.bk}
                    ></Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Pharmacy));
