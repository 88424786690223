import React from "react";
import { Div, H6, Col, Row, Text, Avatar, Image } from 'qdm-component-library'
import "./style.css"
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { TrashIcon } from "../../../assets";
import { EditIcon } from "../../svg";


class ShowList extends React.Component {
    IOSSwitch = withStyles((theme) => ({
        root: {
            width: 37,
            height: 21,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            "&$checked": {
                transform: "translateX(16px)",
                color: theme.palette.common.white,
                "& + $track": {
                    backgroundColor: "#0071F2",
                    opacity: 1,
                    border: "none"
                }
            },
            "&$focusVisible $thumb": {
                color: "#52d869",
                border: "6px solid #fff"
            }
        },
        thumb: {
            width: 18,
            height: 18
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create(["background-color", "border"])
        },
        checked: {},
        focusVisible: {}
    }))
        (({ classes, ...props }) => {
            return (
                <Switch
                    focusVisibleClassName={classes.focusVisible}
                    disableRipple
                    classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked
                    }}
                    {...props}
                />
            );
        });
    render() {
        const { imgUrl, letter, title, description, ondelete, onedit, onswitch, checked = false, parent_id } = this.props;
        return <Row
            id={`${parent_id}-parent-row`}
            className="sl-root">
            <Col
                id={`${parent_id}-parent-Col`}
                md={10} lg={10} sm={6} xs={12} className="sl-detail-root">
                <Div
                    id={`${parent_id}-text-div`}
                    className="sl-count">
                    <Text
                        id={`${parent_id}-${(`${this.props?.index ?? ""}`).replaceAll(" ", "-")}-text`}
                        className="pc_medium" style={{
                            color: '#101010'
                        }}>{this.props?.index}</Text>
                </Div>
                <Div
                    id={`${parent_id}-title-div`}
                    className={(imgUrl || letter) ? "sl-details" : "sl-detail"}>
                    {(imgUrl || letter) && <Avatar
                        id={`${parent_id}-details-avatar`}
                        className="sl-details-avatar" src={imgUrl} letter={letter} />}
                    <Div
                        id={`${parent_id}-title-div`}
                        className="sl-details-names">
                        <H6
                            id={`${parent_id}-${title ? title : " ".replaceAll(" ", "-")}-title-h6`}
                        >{title ? title : " "}</H6>
                        <Text
                            id={`${parent_id}-description-title-text`}
                            style={{
                                color: '#6F6F6F',
                                fontSize: 12,
                            }}>{description ? description : " "}</Text>
                    </Div>
                </Div>
            </Col>
            <Col
                id={`${parent_id}-FormControlLabel-col`}
                md={2} lg={2} sm={6} xs={12} className="sl-action-root">
                <Div
                    id={`${parent_id}-FormControlLabel-div`}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    {onswitch && <FormControlLabel
                        id={`${parent_id}-FormControlLabel`}
                        name={false} control={<this.IOSSwitch checked={checked} />} />}
                    {onedit &&
                        // <Image
                        //     id={`${parent_id}-edit-Image`}
                        //     onClick={(e) => onedit(e)} style={{ cursor: 'pointer' }} src="images/icons8-edit.svg" alt="edit" />
                            <EditIcon id={`${parent_id}-edit-Image`} onClick={(e) => onedit(e)} style={{ cursor: 'pointer' }} />
                    }
                    {ondelete &&
                        // <Image
                        //     id={`${parent_id}-delete-Image`}
                        //     onClick={() => ondelete()} style={{ cursor: 'pointer', marginLeft: 15 }} src="images/icons8-trash.svg" alt="delete" />
                            <TrashIcon id={`${parent_id}-delete-Image`} onClick={() => ondelete()} style={{ cursor: 'pointer', marginLeft: 15 }} />
                    }
                </Div>
            </Col>
        </Row>

    }
}


export default ShowList;