import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import React, { useEffect } from "react";
import { CustomButton, StyledPaper } from "../../../components";
import { CartDefinition, Timing } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../../router/routes";
import {
  AlertProps,
  CARTSTATUS,
  getHourOptions,
  optionsFormatter,
} from "../../../utils";
import { withAllContexts } from "../../../HOCs";
import moment from "moment";
import fromUnixTime from "date-fns/fromUnixTime";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  topbar: {
    padding: `16px 20px`,
    display: "flex",
    alignItems: "center",
    gap: 12,
  },
  backicon: {
    width: 34,
    height: 34,
    backgroundColor: "#DEE5EC",
    "&$hover": {
      backgroundColor: "#DEE5EC",
    },
  },
  title: {
    // color: "#001C3C",
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: "600",
  },
  flexAuto: {
    flex: 1,
  },
  container: {
    padding: 24,
    // backgroundColor: "#F1F2F5",
    backgroundColor: theme.palette.background.common,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: 12,
  },
}));

const {
  CART_DEFINITION_MASTER_DROPDOWN_OPTIONS,
  CART_DEFINITION_MASTER_SINGLE_READ,
  CART_DEFINITION_INSERT,
  CART_DEFINITION_UPDATE,
  CART_DEFINITION_TRANSACTION_UPDATE,
} = actions;

function CartDefinitionUpsert(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();

  // Store States
  const dropdownOptions = useSelector(
    (state) => state?.cartDefinitionMasterSlice?.cartDefinitionDropdownOptions
  );
  const options = dropdownOptions?.data;
  const prefillData = useSelector(
    (state) => state?.cartDefinitionMasterSlice?.cartDefinitionMasterSingleread
  )?.data?.[0];

  const [state, setState] = React.useState({
    cartname: "",
    disptype: [],
    ward: [],
    Timings: [],
    locationid: null,
    efffrdate: null,
    efftodate: null,
    isEditData: false
  });

  const [selectedWeekdays, setSelectedWeekdays] = React.useState([]);

  // const handleSetState = (key, value) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     [key]: value,
  //   }));
  // };

  const handleSetState = (key, value) => {
    
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const isValidate = (state) => {
    let isError = false;
    if (
      !state?.cartname ||
      !state?.ward?.length > 0 ||
      !state?.locationid?.value ||
      !state?.Timings?.length > 0
    ) {
      isError = true;
    }
    if (state?.disptype?.length === 0) {
      isError = true;
    }
    // if (!state?.efffrdate) {
    //   isError = true;
    // }
    // if (!state?.efftodate) {
    //   isError = true;
    // }
    return isError;
  };

  const checkIfFormHasErrors = (data) => {
    let hasErrors = false;

    // Effective From date is mandatory
    if (!Boolean(data?.efffrdate)) {
      hasErrors = true;
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Effective From date is mandatory",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return hasErrors;
    }

    // Effective From date should not exceed Effective To date
    if (Boolean(data?.efffrdate) && Boolean(data?.efftodate)) {
      const efffrdate = moment(data.efffrdate);
      const efftodate = moment(data.efftodate);
      if (efffrdate.isAfter(efftodate)) {
        hasErrors = true;
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Effective to date should be after Effective from date.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return hasErrors;
      }
    }

    return hasErrors;
  };

  const handleSave = () => {
    const { cartname, disptype, locationid, ward, Timings } = state;


    if (isValidate(state)) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the mandatory fields!.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }

    if (checkIfFormHasErrors(state)) {
      return;
    }

    const payload = {
      _key: location.state?._key ?? null,
      cartname,
      disptype: disptype?.map((item) => item?.value),
      locationid: locationid?.value,
      ward: ward?.map((x) => x?._id),
      efffrdate: moment(state?.efffrdate)
        .set({
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
        .unix(),
      efftodate: moment(state?.efftodate)
        .set({
          hours: 23,
          minutes: 59,
          seconds: 0,
          milliseconds: 0,
        })
        .unix(),
      Timings: Timings?.map((x) => {
        delete x.id;
        return {
          ...x,
          cartstarttime: moment(x?.cartstarttime).unix(),
          durationinhrs: parseInt(x?.durationinhrs?.value) ?? null,
          clscutofftime: moment(x?.clscutofftime).unix(),
          // efffrdate: moment(x?.efffrdate)
          //   .set({
          //     hours: 0,
          //     minutes: 0,
          //     seconds: 0,
          //     milliseconds: 0,
          //   })
          //   .unix(),
          // efftodate: moment(x?.efftodate)
          //   .set({
          //     hours: 23,
          //     minutes: 59,
          //     seconds: 0,
          //     milliseconds: 0,
          //   })
          //   .unix(),
          weekdays: x?.weekdays?.map((x) => x?._id),
        };
      }),
      cartstatus: location.state?._key ? prefillData?.cartstatus : true,
    };

    if (location.state?._key) {
      Promise.resolve(
        dispatch(
          CART_DEFINITION_UPDATE({
            body: payload,
          })
        )
      )
        .then((response) => {
          executeCartTransactionUpdate(response);

          // props.alert.setSnack({
          //   open: true,
          //   severity: AlertProps.severity.success,
          //   msg: "Cart Definition updated successfully",
          //   vertical: AlertProps.vertical.top,
          //   horizontal: AlertProps.horizontal.right,
          // });

          history.push(Routes.cartDefinition);
        })
        .catch((err) => {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: err ?? "Something went wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        });
    } else {
      Promise.resolve(
        dispatch(
          CART_DEFINITION_INSERT({
            body: payload,
          })
        )
      )
        .then((response) => {
          executeCartTransactionUpdate(response);

          // props.alert.setSnack({
          //   open: true,
          //   severity: AlertProps.severity.success,
          //   msg: "Cart Definition saved successfully",
          //   vertical: AlertProps.vertical.top,
          //   horizontal: AlertProps.horizontal.right,
          // });

          history.push(Routes.cartDefinition);
        })
        .catch((err) => {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: err ?? "Something went wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        });
    }
  };

  const executeCartTransactionUpdate = (res) => {
    const document = res?.payload?.data?.Result?.[0]?.properties?.doc;
    const { Timings } = state;
    const timing = Timings?.[0] ?? {};
    let userInfoKeysData = JSON.parse(
      atob(localStorage.getItem("userInfoKeys"))
    );

    // Function that take two arguments and return the first date argument with the time from second date argument attached to the first date argument.
    const getDateWithTime = (date, time) => {
      const dateWithTime = moment(date).add(time, "hours");
      return dateWithTime.format("YYYY-MM-DD HH:mm:ss");
    };

    const startDate = moment(state?.efffrdate)
      .set({
        hours: moment(timing?.cartstarttime)?.get("hours"),
        minutes: moment(timing?.cartstarttime)?.get("minutes"),
        seconds: 0,
        milliseconds: 0,
      })
      .unix();

    const endDate = moment
      .unix(startDate)
      .add(Number(timing?.durationinhrs?.value), "hours")
      .subtract(1, "minute")
      .unix();

    const transactionPayload = {
      isEdit: location.state?._key ? true : false,
      cartid: document?._id,
      startdate: startDate,
      enddate: endDate,
      cartopentime: 0,
      cartclosetime: 0,
      cartopenuserid: "",
      cartcloseuserid: "",
      cartstatus: CARTSTATUS.PENDING,
      // cartopentime: moment(timing?.cartstarttime).unix(),
      // cartclosetime: moment(timing?.clscutofftime).unix(),
      // cartopenuserid: userInfoKeysData?.userid,
      // cartcloseuserid: userInfoKeysData?.userid,
    };

    // if (state.isEditData) {
    Promise.resolve(
      dispatch(
        CART_DEFINITION_TRANSACTION_UPDATE({
          body: transactionPayload,
        })
      )
    )
      .then(() => {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: location.state?._key
            ? "Cart Definition updated successfully"
            : "Cart Definition saved successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      })
      .catch((err) => {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: err ?? "Something went wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      });
    // }
  };

  // Component Lifecycle
  useEffect(() => {
    Promise.resolve(dispatch(CART_DEFINITION_MASTER_DROPDOWN_OPTIONS()))
      .then((opt) => {
        console.log(location);
        Promise.resolve(
          dispatch(
            CART_DEFINITION_MASTER_SINGLE_READ({ id: location.state?._id })
          )
        )
          .then((res) => {
            const { data } = res?.payload;
            const cartData = data?.[0];

            const stateData = {
              cartname: cartData?.cartname,
              disptype: cartData?.disptype?.map((x) => ({
                ...x,
                label: x?.display,
                value: x?._id,
              })),
              locationid: optionsFormatter(
                [cartData?.locationid],
                "longdesc"
              )?.[0],
              efffrdate: cartData?.efffrdate ? fromUnixTime(cartData?.efffrdate) : null,
              efftodate: cartData?.efftodate ? fromUnixTime(cartData?.efftodate) : null,
              ward: optionsFormatter(cartData?.ward, "longdesc"),
              Timings: cartData?.Timings?.map((timing) => {
                return {
                  ...timing,
                  cartstarttime: fromUnixTime(timing?.cartstarttime),
                  clscutofftime: fromUnixTime(timing?.clscutofftime),
                  durationinhrs: getHourOptions()?.filter(
                    (hour) =>
                      hour.value ===
                      timing?.durationinhrs?.toString()?.padStart(2, "0")
                  )?.[0],
                  weekdays: Array.isArray(timing?.weekdays)
                    ? optionsFormatter(timing?.weekdays, "display")
                    : [],
                };
              }),
              isEditData: (cartData?.PH_CartTransaction?.length === 1 && cartData?.PH_CartTransaction?.[0]?.cartstatus === "CodingMaster/11101") ? true : false
            };
            setState(stateData);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const isEdit = Boolean(location.state?._key);

  return (
    <div className={classes.wrapper}>
      <div className={classes.topbar}>
        <IconButton
          size="small"
          className={classes.backicon}
          onClick={() => history.goBack()}
        >
          <KeyboardArrowLeftIcon htmlColor="#000000" />
        </IconButton>
        <Typography className={classes.title}>
          {isEdit ? "Edit Cart definition" : "Add New Cart definition"}
        </Typography>
        <div className={classes.flexAuto}></div>
        <CustomButton
          variant={"text"}
          onClick={() => history.push(Routes.cartDefinition)}
        >
          Cancel
        </CustomButton>
        <CustomButton
          variant={"contained"}
          color="#FFFFFF"
          backgroundColor="#EC6A49"
          onClick={handleSave}
        >
          {isEdit ? "Update" : "Save"}
        </CustomButton>
      </div>
      <div className={classes.container}>
        <StyledPaper>
          <CartDefinition
            state={state}
            setState={handleSetState}
            options={{
              dispenseType: options?.dispenseType,
              pharmacy: options?.pharmacy,
              ward: options?.ward
            }}
          />
        </StyledPaper>
        <StyledPaper>
          <Timing
            state={state}
            stateName={"Timings"}
            setState={handleSetState}
            options={{ weekdays: options?.weekdays }}
            selectedWeekdays={state?.Timings?.map((x) => x?.weekdays)?.flat()}
            isEdit={state.isEditData || !location.state?._key}
          />
        </StyledPaper>
      </div>
    </div>
  );
}

export default withAllContexts(CartDefinitionUpsert);
