import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drug from "./drug";
import DrugWrapper from "./drugWrapper";
import { TitleWithDivider } from "../../commonComponents";
import Collapse from "@material-ui/core/Collapse";
import { ReactComponent as CollapseIcon } from "../../../assets/collapse.svg";
import { ReactComponent as ExpandIcon } from "../../../assets/expand.svg";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 12px",
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
  expandCollapseButton: {
    position: "absolute",
    zIndex: 1,
    bottom: "-10px",
    left: "5px",
  },
  additionalDoseWrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.75),
  },
  divider: {
    position: "absolute",
    top: "calc(25% - 1px)",
  },
  additiveRoot: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
}));

function DrugSelectorByType({ type, printLabel, drug, ...props }) {
  // <---------------Hooks------------------>
  const classes = useStyles();

  // <---------------Store States----------->

  // <-------------Component States--------->
  const [isCollapsed, setIsCollapsed] = useState(true);

  // <-----------------Handlers------------->
  const handleCollapseToggle = () => {
    setIsCollapsed((prev) => !prev);
  };

  // <-----------------Effects-------------->

  const {
    brands,
    compounds,
    addictives,
    disptype,
    orderedDrugName,
    directions,
    isVerficationState,
  } = props;

  return (
    <div className={classes.root}>
      {type === "UD" && (
        <>
          {brands?.map((brand, index) => {
            return (
              <Drug
                {...props}
                isIntravenous={props.isIntravenous}
                key={brand?.uniqueId}
                type={type}
                disptype={disptype}
                drugName={brand?.drugNameLong}
                Strength={brand?.allStrength}
                singleStrength={brand?.Strength}
                dosageUOM={brand?.dosageUOM}
                drugType={brand?.DrugType?.drugtype}
                doseCount={brand?.doseCount}
                tabPerDose={brand?.tabPerDose}
                ivInfusionRate={
                  <>
                    {brand?.rateqty}&nbsp;{brand?.rateuom}
                  </>
                }
                administrativeDose={
                  brand?.perdoseadminqty + " " + brand?.totadmuom
                }
                actualdoseCount={brand?.actualdoseCount}
                requiredDose={
                  <>
                    {brand?.totadmqty}&nbsp;{brand?.totadmuom}
                  </>
                }
                totalQuantity={
                  <>
                    {brand?.totstkqty}&nbsp;{brand?.totstkuom}
                  </>
                }
                issueQuantityFuncy={(val) => props.issueQuantity(val, index)}
                issueQuantity={
                  <>
                    {brand?.totstkqty}&nbsp;{brand?.totstkuom}
                  </>
                }
                billQuantity={
                  <>
                    {brand?.totbillqty}&nbsp;{brand?.totbilluom}
                  </>
                }
                batches={brand.batches}
                onBatchQuantityChangeDrug={(value, isMedication) =>
                  props?.onBatchQuantityChangeDrug(
                    value,
                    brand?.id,
                    index,
                    isMedication
                  )
                }
                onBatchQuantityChange={(value, batch_inx, fill, b) => {
                  return props?.onBatchQuantityChange(
                    value,
                    brand?.id,
                    index,
                    batch_inx,
                    false,
                    fill, b
                  )
                }
                }
                direction={
                  directions
                    ? directions?.[index]?.direction
                    : brand?.pH_OrderLineDispenseSchedule?.dispensedetails?.[
                      index
                    ]?.direction
                }
                nlDirection={
                  directions
                    ? directions?.[index]?.direction
                    : brand?.pH_OrderLineDispenseSchedule?.dispensedetails?.[
                      index
                    ]?.nldirection
                }
                handleDirectionSaveClick={(e) =>
                  props.handleDirectionSaveClick(e, index, brand)
                }
                isVerficationState={isVerficationState}
                // imgId={brand?.drugPhotoId}

                // handleChangebrandName={(value) => {
                //   props.handleChangebrandName(value, index, brand?.id);
                // }}
                // handleDelete={() => props?.handleDelete(index)}
                // handleAdd={(val) => props?.handleAdd(val, index)}
                // // isAdd={isaddfuncy(drug) && drug?.brands?.length - 1 === index}
                // index={index}
                printLabel={() => printLabel(drug, index, brand)}
                // printEnable
                brand={brand}
                isShowMulti={brand.isShowMulti ?? false}
                handleMultiBatch={(t, b) => props.handleMultiBatch(t, index, b)}
                isMultiBatch={props.isMultiBatch}
              />
            );
          })}
        </>
      )}
      {type === "compound" && (
        <>
          {/* No Need to show for Compound Drug */}
          {/* <>
            {brands?.map((brand, index) => {
              return (
                <Drug
                  key={brand?.uniqueId}
                  type={type}
                  disptype={disptype}
                  drugName={brand?.drugNameLong}
                  Strength={brand?.allStrength}
                  singleStrength={brand?.Strength}
                  dosageUOM={brand?.dosageUOM}
                  drugType={brand?.DrugType?.drugtype}
                  doseCount={brand?.doseCount}
                  tabPerDose={brand?.tabPerDose}
                  isVerficationState={isVerficationState}
                  ivInfusionRate={
                    <>
                      {brand?.rateqty}&nbsp;{brand?.rateuom}
                    </>
                  }
                  administrativeDose={
                    brand?.perdoseadminqty + " " + brand?.totadmuom
                  }
                  actualdoseCount={brand?.actualdoseCount}
                  requiredDose={
                    <>
                      {brand?.totadmqty}&nbsp;{brand?.totadmuom}
                    </>
                  }
                  totalQuantity={
                    <>
                      {brand?.totstkqty}&nbsp;{brand?.totstkuom}
                    </>
                  }
                  issueQuantity={
                    <>
                      {brand?.totstkqty}&nbsp;{brand?.totstkuom}
                    </>
                  }
                  billQuantity={
                    <>
                      {brand?.totbillqty}&nbsp;{brand?.totbilluom}
                    </>
                  }
                  batches={brand.batches}
                    direction={
                  directions
                    ? directions?.[index]?.direction
                    : brand?.pH_OrderLineDispenseSchedule?.dispensedetails?.[
                        index
                      ]?.direction
                }
                nlDirection={
                  directions
                    ? directions?.[index]?.direction
                    : brand?.pH_OrderLineDispenseSchedule?.dispensedetails?.[
                        index
                      ]?.nldirection
                }
                  onBatchQuantityChange={(value, batch_inx) =>
                    props?.onBatchQuantityChange(
                      value,
                      brand?.id,
                      index,
                      batch_inx
                    )
                  }
                    handleDirectionSaveClick={(e) =>
                  props.handleDirectionSaveClick(e, index, brand)
                }
                  // imgId={brand?.drugPhotoId}

                  // handleChangebrandName={(value) => {
                  //   props.handleChangebrandName(value, index, brand?.id);
                  // }}
                  // handleDelete={() => props?.handleDelete(index)}
                  // handleAdd={(val) => props?.handleAdd(val, index)}
                  // // isAdd={isaddfuncy(drug) && drug?.brands?.length - 1 === index}
                  // index={index}
                   isIntravenous={isIntravenous}
                  printLabel={() => printLabel(drug, index, brand)}
                
                  // printEnable
                  // isShowMulti={brand.isShowMulti ?? false}
                  // // handleMultiBatch={(t, b) => handleMultiBatch(t, index, b)}
                  // isMultiBatch={props.isMultiBatch}
                />
              );
            })}
          </> */}
          {compounds?.map((compound, index) => {
            return (
              <DrugWrapper
                key={compound?.uniqueId}
                drugName={compound?.drugNameLong}
                dosageValue={compound?.dosageValue}
                dosageUOM={compound?.dosageUOM}
                type={type}
              >
                <Drug
                  {...props}
                  key={compound?.uniqueId}
                  type={type}
                  isCompound={true}
                  disptype={disptype}
                  drugName={compound?.drugNameLong}
                  Strength={compound?.allStrength}
                  singleStrength={compound?.Strength}
                  dosageUOM={compound?.dosageUOM}
                  drugType={compound?.DrugType?.drugtype}
                  doseCount={compound?.doseCount}
                  tabPerDose={compound?.tabPerDose}
                  isVerficationState={isVerficationState}
                  ivInfusionRate={
                    <>
                      {compound?.rateqty}&nbsp;{compound?.rateuom}
                    </>
                  }
                  administrativeDose={
                    compound?.perdoseadminqty + " " + compound?.totadmuom
                  }
                  actualdoseCount={compound?.actualdoseCount}
                  requiredDose={
                    <>
                      {compound?.totadmqty}&nbsp;{compound?.totadmuom}
                    </>
                  }
                  totalQuantity={
                    <>
                      {compound?.totstkqty}&nbsp;{compound?.totstkuom}
                    </>
                  }
                  issueQuantityFuncy={(val) => props.issueQuantity(val, index)}
                  issueQuantity={
                    <>
                      {compound?.totstkqty}&nbsp;{compound?.totstkuom}
                    </>
                  }
                  billQuantity={
                    <>
                      {compound?.totbillqty}&nbsp;{compound?.totbilluom}
                    </>
                  }
                  direction={
                    directions
                      ? directions?.[index]?.direction
                      : compound?.pH_OrderLineDispenseSchedule
                        ?.dispensedetails?.[index]?.direction
                  }
                  nlDirection={
                    directions
                      ? directions?.[index]?.direction
                      : compound?.pH_OrderLineDispenseSchedule
                        ?.dispensedetails?.[index]?.nldirection
                  }
                  batches={compound.batches}
                  onBatchQuantityChange={(value, batch_inx, fill, b) => {
                    return props?.onBatchQuantityChange(
                      value,
                      compound?.id,
                      index,
                      batch_inx,
                      false,
                      fill, b
                    )
                  }
                  }
                  handleDirectionSaveClick={(e) =>
                    props.handleDirectionSaveClick(e, index, compound)
                  }
                  brand={compound}
                  isShowMulti={compound.isShowMulti ?? false}
                  handleMultiBatch={(t, b) => props.handleMultiBatch(t, index, b)}
                  isMultiBatch={props.isMultiBatch}
                  onBatchQuantityChangeDrug={(value, isMedication) =>
                    props?.onBatchQuantityChangeDrug(
                      value,
                      compound?.id,
                      index,
                      isMedication
                    )
                  }
                />
              </DrugWrapper>
            );
          })}
        </>
      )}

      {type === "IV" && (
        <>
          {brands?.map((brand, index) => {
            return (
              <Drug
                {...props}
                key={brand?.uniqueId}
                type={type}
                disptype={disptype}
                drugName={brand?.drugNameLong}
                Strength={brand?.allStrength}
                singleStrength={brand?.Strength}
                dosageUOM={brand?.dosageUOM}
                drugType={brand?.DrugType?.drugtype}
                doseCount={brand?.doseCount}
                tabPerDose={brand?.tabPerDose}
                isVerficationState={isVerficationState}
                printLabel={() => printLabel(drug, index, brand)}
                ivInfusionRate={
                  <>
                    {brand?.rateqty}&nbsp;{brand?.rateuom}
                  </>
                }
                administrativeDose={
                  brand?.perdoseadminqty + " " + brand?.totadmuom
                }
                actualdoseCount={brand?.actualdoseCount}
                requiredDose={
                  <>
                    {brand?.totadmqty}&nbsp;{brand?.totadmuom}
                  </>
                }
                totalQuantity={
                  <>
                    {brand?.totstkqty}&nbsp;{brand?.totstkuom}
                  </>
                }
                issueQuantityFuncy={(val) => props.issueQuantity(val, index)}
                issueQuantity={
                  <>
                    {brand?.totstkqty}&nbsp;{brand?.totstkuom}
                  </>
                }
                billQuantity={
                  <>
                    {brand?.totbillqty}&nbsp;{brand?.totbilluom}
                  </>
                }
                batches={brand.batches}
                direction={
                  directions
                    ? directions?.[index]?.direction
                    : brand?.pH_OrderLineDispenseSchedule?.dispensedetails?.[
                      index
                    ]?.direction
                }
                nlDirection={
                  directions
                    ? directions?.[index]?.direction
                    : brand?.pH_OrderLineDispenseSchedule?.dispensedetails?.[
                      index
                    ]?.nldirection
                }
                onBatchQuantityChange={(value, batch_inx, fill, b) => {
                  return props?.onBatchQuantityChange(
                    value,
                    brand?.id,
                    index,
                    batch_inx,
                    false,
                    fill, b
                  )
                }
                }
                handleDirectionSaveClick={(e) =>
                  props.handleDirectionSaveClick(e, index, brand)
                }

                brand={brand}
                isShowMulti={brand.isShowMulti ?? false}
                handleMultiBatch={(t, b) => props.handleMultiBatch(t, index, b)}
                isMultiBatch={props.isMultiBatch}
                onBatchQuantityChangeDrug={(value, isMedication) =>
                  props?.onBatchQuantityChangeDrug(
                    value,
                    brand?.id,
                    index,
                    isMedication
                  )
                }
              />
            );
          })}
          <TitleWithDivider
            icon={isCollapsed ? <CollapseIcon /> : <ExpandIcon />}
            title={
              <>
                Additives for {orderedDrugName}
                {/* {orderedDosage} */}
              </>
            }
            onClick={handleCollapseToggle}
          />

          {/* Additional Doses */}
          <Collapse in={isCollapsed}>
            <div className={classes.additiveRoot}>
              {addictives?.map((addictive, index) => {
                return (
                  <DrugWrapper
                    key={addictive?.uniqueId}
                    drugName={addictive?.name}
                    dosageValue={addictive?.addose}
                    dosageUOM={addictive?.addoseuomtext}
                    type={type}
                    orderedDrugName={orderedDrugName}
                  >
                    {addictive.brands?.map((brand, index) => {
                      return (
                        <Drug
                          {...props}
                          key={brand?.uniqueId}
                          type={type}
                          disptype={disptype}
                          drugName={brand?.drugNameLong}
                          Strength={brand?.allStrength}
                          singleStrength={brand?.Strength}
                          dosageUOM={brand?.dosageUOM}
                          drugType={brand?.DrugType?.drugtype}
                          doseCount={brand?.doseCount}
                          tabPerDose={brand?.tabPerDose}
                          isVerficationState={isVerficationState}
                          printLabel={() => printLabel(drug, index, brand)}
                          ivInfusionRate={
                            <>
                              {brand?.rateqty}&nbsp;{brand?.rateuom}
                            </>
                          }
                          administrativeDose={
                            brand?.perdoseadminqty + " " + brand?.totadmuom
                          }
                          actualdoseCount={brand?.actualdoseCount}
                          requiredDose={
                            <>
                              {brand?.totadmqty}&nbsp;{brand?.totadmuom}
                            </>
                          }
                          totalQuantity={
                            <>
                              {brand?.totstkqty}&nbsp;{brand?.totstkuom}
                            </>
                          }
                          issueQuantityFuncy={(val) => props.issueQuantity(val, index)}
                          issueQuantity={
                            <>
                              {brand?.totstkqty}&nbsp;{brand?.totstkuom}
                            </>
                          }
                          billQuantity={
                            <>
                              {brand?.totbillqty}&nbsp;
                              {brand?.totbilluom}
                            </>
                          }
                          direction={
                            directions
                              ? directions?.[index]?.direction
                              : brand?.pH_OrderLineDispenseSchedule
                                ?.dispensedetails?.[index]?.direction
                          }
                          nlDirection={
                            directions
                              ? directions?.[index]?.direction
                              : brand?.pH_OrderLineDispenseSchedule
                                ?.dispensedetails?.[index]?.nldirection
                          }
                          batches={brand.batches}
                          brand={brand}
                          onBatchQuantityChange={(value, batch_inx, fill, b) => {
                            return props?.onBatchQuantityChange(
                              value,
                              brand?.id,
                              index,
                              batch_inx,
                              false,
                              fill, b
                            )
                          }
                          }
                          handleDirectionSaveClick={(e) =>
                            props.handleDirectionSaveClick(e, index, brand)
                          }
                          onBatchQuantityChangeDrug={(value, isMedication) =>
                            props?.onBatchQuantityChangeDrug(
                              value,
                              brand?.id,
                              index,
                              isMedication
                            )
                          }
                        />
                      );
                    })}
                  </DrugWrapper>
                );
              })}
            </div>
          </Collapse>
        </>
      )}
    </div>
  );
}

export default DrugSelectorByType;

DrugSelectorByType.propTypes = {
  type: PropTypes.string.isRequired,
};

DrugSelectorByType.defaultProps = {
  type: "unitDose",
};
