import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    padding: 20,
    borderRadius: 8,
    background:theme.palette.background.table,
    "& .App1-MuiOutlinedInput-root": {
      borderRadius: 8,
    },
    "& .Mui-disabled": {
      background: "#f0f0f0",
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {},
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  textfieldGrid: {
    marginTop: "4px",
  },
  titleGrid: {
    padding: 14,
  },
  titleGrid1: {
    paddingTop: "30px !important",
  },
}));

function GeneralReport(props) {
  const classes = useStyles();
  const { explantorymsg, handleFormState, parent_id } = props;
  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}_parent_paper`}
        className={classes.root}>
        <Grid
          id={`${parent_id}_parent_container`}
          container className={classes.form} spacing={2}>
          <Grid
            id={`${parent_id}_REMARKS_grid`}
            className={classes.titleGrid} item xs={12}>
            <Typography
              id={`${parent_id}_REMARKS_typography`}
              className={classes.title} variant="h6">
              REMARKS
            </Typography>
          </Grid>

          <Grid
            id={`${parent_id}_Explanatory_grid`}
            item xs={12}>
            <Typography
              id={`${parent_id}_Explanatory_typography`}
              className={classes.formtitle} variant="body1">
              Explanatory Notes
            </Typography>
            <Grid
              id={`${parent_id}_Explanatory_textField_grid`}
              className={classes.textfieldGrid}>
              <TextField
                id={`${parent_id}_Explanatory_notes_textField`}
                value={explantorymsg}
                onChange={(e) =>
                  handleFormState("explantorymsg", e.target.value)
                }
                fullWidth
                type="text"
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default GeneralReport;
