import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, makeStyles, DialogContent, Button, Paper, DialogActions, TableContainer, Table, TableHead, TableCell, TableRow, Typography, IconButton, Divider, TableBody } from '@material-ui/core'
import { TextInput } from 'qdm-component-library';
import { UIColor} from '../../../../../../utils';

const borderColor = 'solid 1px rgba(224, 224, 224, 1)';
const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiPaper-root": {
            maxWidth: 800,
            padding: 10,
            borderRadius: 12
        },
        "& .MuiTypography-h6": {
            fontSize: 16
        },
        "& .MuiTableRow-root td:first-child": {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px'
        },
        "& .MuiTableRow-root td:last-child": {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px'
        }
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: "0px 12px"
    },
    saveButton: {
        backgroundColor: UIColor.primaryColor.color
    },
    cancelButton: {
        backgroundColor: '#fff'
    },
    table: {
        border: borderColor,
        marginTop: 20,
    },
    tableRow: {
        borderRadius: 14
    },
    tableHead: {
        backgroundColor: UIColor.lineBorderFill.color,
        "& .MuiTableCell-root": {
            borderLeft: borderColor
        }
    },
    input: {
        "&.MuiPaper-root": {
            width: 30,
            padding: '0px 0px',
            borderRadius: 6,
            border: borderColor
        },
        "& input": {
            padding: 0,
            textAlign: 'center',
            height: 30,
            border: 'none !important'
        }
    }
}))

const SlidingScale = (props) => {
    const classes = useStyles();
    const [state, setState] = React.useState({ row: [], column: [] })

    React.useEffect(() => {
        setState({
            row: props.row,
            column: props.column
        })
    }, [])

    const handleChange = (value, rowIndex, columnIndex) => {
        if (value) {
            state.column[rowIndex][columnIndex] = value;
            setState({ ...state })
        }
    }
    const handleSave = () => {
        props.handleSave("slidingScale", state)
        props.handleModal(false)
    }
    const { parent_id } = props;
    return (
        <Dialog
            // id={`${parent_id}_slidingscale_parent_div`}
            open={props?.open}
            onClose={() => props?.handleModal(false)}
            aria-labelledby="simple-dialog-title"
            aria-describedby="simple-dialog-description"
            className={classes.root}
        >
            <div
                // id={`${parent_id}_slidingscale_parent_sub_div`}
                className={classes.dialogTitle}>
                <div
                    // id={`${parent_id}_slidingscale_title_div`}
                >
                    <Typography
                        // id={`${parent_id}_slidingscale_label_typography`}
                        variant="h6">Sliding Scale</Typography>
                </div>
                <div
                    // id={`${parent_id}_slidingscale_iconbutton_div`}
                >
                    <IconButton
                        // id={`${parent_id}_slidingscale_iconbutton`}
                    >
                        <CloseIcon
                            // id={`${parent_id}_slidingscale_iconcon`}
                        />
                    </IconButton>
                </div>
            </div>
            <Divider />
            <TableContainer
                // id={`${parent_id}_slidingscale_tablecontainer`}
            >
                <Table
                    // id={`${parent_id}_slidingscale_item_table`}
                    className={classes.table}>
                    <TableHead
                        // id={`${parent_id}_slidingscale_item_tablehead`}
                        className={classes.tableHead}>
                        <TableRow
                            // id={`${parent_id}_slidingscale_item_tablerow`}
                            className={classes.tableRow}>
                            {
                                state?.row?.map((item, i) => {
                                    return <TableCell
                                        // id={`${parent_id}_slidingscale_${i}_item_tablecell`}
                                    >{item}</TableCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            // id={`${parent_id}_slidingscale_hypoglycaemia_tablerow`}
                        >
                            <TableCell
                                // id={`${parent_id}_slidingscale_number_tablecell`}
                                style={{ borderRight: borderColor }}>70</TableCell>
                            <TableCell
                                // id={`${parent_id}_slidingscale_hypoglycaemia_tablecell`}
                                colSpan={3}>Initiate hypoglycaemia protocol</TableCell>
                        </TableRow>
                        {
                            state?.column?.map((column, columnIndex) => {
                                return <TableRow
                                    // id={`${parent_id}_slidingscale_${columnIndex}_paent_tablerow`}
                                >
                                    {
                                        column?.map((item, itemIndex) => {
                                            return <TableCell
                                                // id={`${parent_id}_slidingscale_${itemIndex}_textinput_tablecell`}
                                                style={{ borderRight: itemIndex === 0 ? borderColor : '' }}>
                                                {
                                                    itemIndex === 0 ?
                                                        <>{item}</>
                                                        :
                                                        <Paper
                                                            // id={`${parent_id}_slidingscale_${itemIndex}_textinput_paper`}
                                                            className={classes.input}>
                                                            <TextInput
                                                                // id={`${parent_id}_slidingscale_${itemIndex}_textinput`}
                                                                className={classes.input}
                                                                value={item}
                                                                onChange={(event) => {
                                                                    handleChange(event.target.value, columnIndex, itemIndex)
                                                                }}
                                                                variant="contained"
                                                            >

                                                            </TextInput>
                                                        </Paper>
                                                }
                                            </TableCell>
                                        })
                                    }
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <DialogActions
                // id={`${parent_id}_slidingscale_buttons_DialogActions`}
                style={{ marginTop: 20 }}>
                <Button
                    // id={`${parent_id}_slidingscale_Cancel_button`}
                    onClick={() => props?.handleModal(false)}
                    variant="contained"
                    className={classes.cancelButton}
                >
                    Cancel
                </Button>
                <Button
                    // id={`${parent_id}_slidingscale_Save_button`}
                    onClick={handleSave}
                    color="primary"
                    variant="contained"
                    className={classes.saveButton}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SlidingScale;