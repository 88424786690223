import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
// import { useCustomDotStyles2 } from "../drugBrandTable/styles";
import { Dot } from "../../";
import { actions } from "frequencyscreen_v_dbinder";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    "& .App1-MuiListItem-gutters": {
      paddingTop: "0px",
      paddingBottom: "0px",
      "& .App1-MuiListItemText-multiline": {
        marginTop: "0px",
        marginBottom: "0px",
      },
    },
  },
  inline: {
    display: "inline",
  },
  blue: {
    // backgroundColor: "#D5E4FD",
    // color: "#2A60BC",
    background: `${theme.palette.background.common} !important`,
    color: theme.palette.text.primary,
    padding: "4px 8px 4px 8px",
    fontSize: "12px",
    marginLeft: "8px",
  },
  black: {
    // color: "#2A3C50",
    color: theme.palette.primary.text,
    fontSize: "12px",
    marginLeft: "8px",
  },
  gray: {
    fontSize: "12px",
    marginLeft: "8px",
  },
  highlight: {
    // backgroundColor: "#F1F6FC",
    // color: "#2A3C50",
    padding: "4px 8px 4px 8px",
    marginTop: "8px",
    fontSize: "12px",
    marginLeft: "8px",
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
  },
}));

const details = [
  {
    name: "Dr. John",
    job: "verifier",
    details: "consultant On 24-02-2021 10:00",
    content: "Instructions given by doctor in Rx",
  },
  {
    name: "Richards",
    job: "Filler",
    details: "pharmacist On 24-02-2021 10:00",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    name: "Raechal",
    job: "checker",
    details: "pharmacist on 24-03-2021 10:30",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
];
function VerticalLinearStepper({ _key }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { READ_STATUS_HISTORY } = actions;

  const historyList = useSelector(
    (state) => state?.phramcyFrameworkApiSlice?.readStatusHistory
  )?.data;

  useEffect(() => {
    if (!_key) return;
    dispatch(READ_STATUS_HISTORY({ key: _key }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_key]);

  return (
    <>
      {historyList?.statushistory?.length > 0 ? (
        <List className={classes.root}>
          {historyList?.statushistory?.map((history, i) => {
            return (
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderLeft:
                          i !== details.length - 1 && "1px dashed grey",
                      }}
                    >
                      <div style={{ display: "flex", justifyContent: "left" }}>
                        <Dot style={{ marginTop: "8px" }} />
                        <Typography className={classes.black}>
                          {history?.practitioner}
                        </Typography>
                      </div>
                      <Typography className={classes.blue}>
                        {history?.practitionerRole}
                      </Typography>
                    </div>
                  }
                  secondary={
                    <React.Fragment>
                      <div
                        style={{
                          borderLeft:
                            i !== details.length - 1 && "1px dashed grey",
                          paddingBottom: "16px",
                        }}
                      >
                        <Typography className={classes.gray}>
                          {`${history?.status
                            ?.toString()
                            ?.slice(0, 1)
                            ?.toUpperCase()}${history?.status
                            ?.toString()
                            ?.slice(1)} on ${moment(
                            history?.statusupdatetime * 1000
                          ).format("DD-MM-YYYY HH:mm")}`}
                        </Typography>
                        <div>
                          <Typography className={classes.highlight}>
                            {history?.remarks}
                          </Typography>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Typography variant="body2" align="center">
          No Remarks found
        </Typography>
      )}
    </>
  );
}

export default VerticalLinearStepper;

// const Dot = () => {
//   const classes = useCustomDotStyles2();
//   return <div className={classes.root}></div>;
// };
