import React, { useContext } from "react";
import { Icons } from "qdm-component-library";
import { Grid, Button, makeStyles } from "@material-ui/core";
import { DateTimePicker } from "../../../../components/common/smartForm/component";
import { CustomCollapse } from "./customCollapse";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import {
  getTimeSlot,
  getTimeString,
  checkError,
  getUtcTime,
  utcTOLocal,
} from "../../../../utils";
import { BackdropContext, AlertContext } from "../../../../contexts";
import { SelectWithLabel } from "../../../../components";

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: "#6F6F6F",
    borderRadius: 8,
    textTransform: "capitalize",
  },
  date: {
    "& .App1-MuiFormControl-root": {
      marginTop: 5,
      "& .App1-MuiInputBase-root": {
        borderRadius: 8,
        "& input": {
          padding: "9px 14px",
        },
        "& fieldset": {
          borderColor: "#E0E0E0",
        },
      },
    },
  },
}));

const icons = {
  video: (
    <Icons
      key={"0"}
      fontIcon="video-camera"
      ariaHidden="true"
      size="small"
      style={{ color: "#B6B6B6" }}
    ></Icons>
  ),
  direct: (
    <Icons
      key={"0"}
      fontIcon="user"
      ariaHidden="true"
      size="small"
      style={{ color: "#B6B6B6" }}
    ></Icons>
  ),
  suggested: (
    <Icons
      key={"0"}
      fontIcon="star"
      ariaHidden="true"
      size="small"
      style={{ color: "#0071F2" }}
    ></Icons>
  ),
};

export const Reschedule = (props) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const { parent_id } = props;
  const { setBackDrop } = useContext(BackdropContext);
  const { setSnack } = useContext(AlertContext);

  const [state, setState] = React.useState({
    scheduleAt: "",
    selectedPendingList: props?.selectedList ?? [],
    data: {},
    appointmentInfo: [],
    reason: {},
  });

  React.useEffect(() => {
    if (props?.selectedList?.length) {
      fetchInfo(props?.selectedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reasonOptionList = useSelector(
    (state) => state.appointmentApiSlice.reasonForApptCancelModify
  );

  const fetchInfo = async (list) => {
    const listData = await Promise.all(
      list?.map(async (_, i) => {
        const data = await dispatch(
          actions.APPOINTMENT_READ({ appointmentId: _?.appointmentId })
        );
        return data?.payload?.data;
      })
    );

    await dispatch(
      actions.REASON_FOR_APPT_CANCEL_MODIFY({ type: "APPMODIFYBYORG" })
    );

    onChange("appointmentInfo", listData);
  };

  const onChange = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const onChangeDate = async (date) => {
    if (date !== "Invalid Date") {
      let payload = {
        id: props?.doctorDetails?.id,
        startdate: getUtcTime(moment(date).startOf("day")),
        enddate: getUtcTime(moment(date).endOf("day")),
        type: "practionerid",
      };

      let slotsAvailable = await dispatch(actions.READ_SLOT(payload));
      if (slotsAvailable?.payload?.data) {
        updateSlot(slotsAvailable?.payload?.data, date);
      }

      state.selectedPendingList.map(
        (_) => _?.selectedSlot && delete _?.selectedSlot
      ); // eslint-disable-line no-unused-expressions

      setState({
        ...state,
        scheduleAt: moment(date).format("YYYY-MM-DD"),
        selectedPendingList: state?.selectedPendingList,
      });
    }

    // onChange('scheduleAt',moment(date).format("YYYY-MM-DD"))
  };

  const updateSlot = (info, date) => {
    const slot = JSON.parse(JSON.stringify(info));
    filterSlots(
      date,
      slot.resourceInfo,
      slot.slots,
      slot.morning,
      slot.afternoone,
      slot.evening,
      slot.night,
      slot.allSession,
      slot.isClinic,
      slot.healthCareId
    );
    const newState = { ...state };
    newState.data.morning = slot.morning;
    newState.data.afternoone = slot.afternoone;
    newState.data.evening = slot.evening;
    newState.data.night = slot.night;
    newState.data.allSession = slot.allSession;
    newState.date = date;
    newState.selectedTime = getTimeSlot(date);
    setState(newState);
  };

  const filterSlots = (
    date,
    resourceInfo,
    slots,
    morning,
    afternoone,
    evening,
    night,
    allSession,
    isClinic,
    healthCareId,
    isUpdate = false,
    selectedSlot = 0
  ) => {
    const obj = {
      selectedTimeSlot: {},
      selectedTime: getTimeSlot(
        moment(date).startOf("day").unix() * 1000
        // moment(this.props.data?.weekCalendar).format("DD-MM-YYYY") //current date startData*1000
      ),
    };
    slots.forEach((slot) => {
      if (slot) {
        let {
          start,
          id: slotId,
          status,
          end,
          isdirect = false,
          issuggest = false,
          isvideo = false,
          Maxbooking = 0,
          Maxwaiting = 0,
          bookedCount = 0,
          maxgeneral = 0,
          maxwalkin = 0,
          appointmentType,
          _id,
        } = slot;
        const preferedSlotType = appointmentType
          .split(",")
          .map((a) => a.toLowerCase());
        if (status !== "closed" && status !== "booked") {
          //const slotTime = new Date(start * 1000);
          const slotTime = utcTOLocal(start).toDate();
          const hours = slotTime.getHours();
          let label = getTimeString(hours, slotTime.getMinutes());
          if (isClinic) {
            const endTime = new Date(end * 1000);
            const endHours = endTime.getHours();
            const endTimeLabel = getTimeString(endHours, endTime.getMinutes());
            label = `${label} - ${endTimeLabel}`;
          }
          if (
            preferedSlotType.includes("direct") ||
            preferedSlotType.includes("both")
          ) {
            isdirect = true;
          }
          if (
            preferedSlotType.includes("video") ||
            preferedSlotType.includes("both")
          ) {
            isvideo = true;
          }
          const iconsArr = [];
          if (isdirect) {
            iconsArr.push(icons["direct"]);
          }
          if (isvideo) {
            iconsArr.push(icons["video"]);
          }
          if (issuggest) {
            iconsArr.push(icons["suggested"]);
          }
          const slotData = {
            value: slotId,
            label,
            _id,
            date: slotTime.getTime(),
            dateEnd: end,
            dateStart: start,
            status,
            isdirect,
            issuggest,
            isvideo,
            booked: bookedCount,
            maxBooking: Maxbooking,
            maxWaiting: Maxwaiting,
            resourcetype: "",
            resourcerole: "",
            resourcecode: "",
            waiting: bookedCount > Maxbooking ? bookedCount - Maxbooking : 0,
            icon: iconsArr,
            healthCareId,
            maxgeneral,
            isWalkin:
              moment().diff(moment(start * 1000), "d") === 0 ? true : false,
            maxwalkin,
            ...resourceInfo,
          };
          if (isClinic) {
            allSession.push(slotData);
          } else {
            if (slot?.DayType?.display?.toLowerCase() === "morning") {
              morning.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "afternoon") {
              afternoone.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "evening") {
              evening.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "night") {
              night.push(slotData);
            } else {
              morning.push(slotData);
            }
          }
        }
      }
    });
    return obj;
  };

  const getSelected = async (val, index) => {
    if (val?.value) {
      const data = await dispatch(
        actions.SLOT_AVAILABILITY({ slotId: val.value })
      );
      const { isError } = checkError(data?.payload);
      if (!isError) {
        if (
          Array.isArray(data?.payload?.data) &&
          data.payload.data[0]?.status !== "closed" &&
          data.payload.data[0]?.status !== "booked" &&
          !data.payload.data[0]?.overbooked
        ) {
          state.selectedPendingList[index]["selectedSlot"] = val;
          onChange("selectedPendingList", state?.selectedPendingList);
        } else {
          alert("Slot is Overbooked");
        }
      }
    }
  };

  const rescheduleAppointment = async () => {
    if (state?.reason?.value) {
      let updateStatus = [];

      setBackDrop({
        open: true,
        message: "Rescheduling Appointment",
      });

      await Promise.all(
        state?.appointmentInfo?.map(async (_, i) => {
          let payload = {
            ..._,
            cancelationReason: [state?.reason?._id],
            slotID: [state?.selectedPendingList?.[i]?.selectedSlot?.value],
            start: state?.selectedPendingList?.[i]?.selectedSlot?.dateStart,
            end: state?.selectedPendingList?.[i]?.selectedSlot?.dateEnd,
            oldStart: state?.selectedPendingList?.[i]?.["appt date & time"]
              ? moment.unix(
                  new Date(
                    state?.selectedPendingList?.[i]?.["appt date & time"]
                  )
                )?._i
              : "",
            oldEnd: state?.selectedPendingList?.[i]?.["appt date & time"]
              ? moment.unix(
                  new Date(
                    state?.selectedPendingList?.[i]?.["appt date & time"]
                  )
                )?._i
              : "",
            requestedPeriod: [
              {
                start: state?.selectedPendingList?.[i]?.selectedSlot?.dateStart,
                end: state?.selectedPendingList?.[i]?.selectedSlot?.dateEnd,
              },
            ],
            orgid: _?.orgid?._id,
            orgtype: _?.orgtype?._id,
            resourcecode: _?.resourcecode?._id,
          };

          const updatedData = await dispatch(
            actions.APPOINTMENT_UPDATE(payload)
          );
          if (updatedData?.payload?.error) {
            updateStatus.push(_);
          }
          return _;
        })
      );

      if (updateStatus?.length) {
        await Promise.all(
          updateStatus?.map(async (_, i) => {
            const data = await dispatch(actions.APPOINTMENT_MODIFY_STATUS(_));
            return data?.payload?.data;
          })
        );

        let appNoList = updateStatus?.map((_) => _?.appno)?.join(",");
        let customMsg = `Slot is already filled, Kindly select some other slot for ${appNoList}!.`;
        setSnack("error", customMsg);
      }
      props?.refreshPendingData && props?.refreshPendingData(); // eslint-disable-line no-unused-expressions

      setBackDrop({
        open: false,
        message: "",
      });
    } else {
      setSnack("mandatory");
    }
  };

  const isDisable = () => {
    return state?.scheduleAt &&
      state?.reason?.value &&
      state?.selectedPendingList?.filter((_) => _?.selectedSlot?.value)
        ?.length === state?.selectedPendingList?.length
      ? false
      : true;
  };

  return (
    <div id={`${parent_id}-parent-div`}>
      <Grid id={`${parent_id}-container-div`} container>
        <Grid id={`${parent_id}-container-sub-div`} item xs={12} sm={12} md={5}>
          <div id={`${parent_id}-DateTimePicker-div`} className={classes.date}>
            <DateTimePicker
              parent_id={"reschedule"}
              // required={data.required}
              label={"Schedule at"}
              dateFormat={"dd MMM,yyyy"}
              value={state?.scheduleAt ? state?.scheduleAt : null} //moment(date).format('DD MMM,yyyy')
              onChange={(data) => onChangeDate(data)}
              // error={state?.error?.[data?.state_name] ?? false}
            />
          </div>
        </Grid>
      </Grid>
      {state?.selectedPendingList?.length > 0 && (
        <div id={`${parent_id}-CustomCollapse-div`}>
          {state?.selectedPendingList?.map((_, i) => {
            return (
              <div
                id={`${parent_id}-CustomCollapse-div` + i}
                style={{
                  marginTop: 12,
                }}
              >
                <CustomCollapse
                  parent_id={"reschedule" + i}
                  patientDetails={{
                    name: _?.name,
                    age: _?.age,
                    gender:
                      _?.gender === "male"
                        ? "M"
                        : _?.gender === "male"
                        ? "F"
                        : "",
                    mrn: _?.mrn,
                    contact: _?.["mobile no"],
                    appointmentDateTime: _?.["appt date & time"],
                  }}
                  doctorDetails={{
                    name: props?.doctorDetails?.["resource name"],
                    role: props?.doctorDetails?.role,
                    speciality: props?.doctorDetails?.speciality,
                  }}
                  scheduleAt={state?.scheduleAt}
                  slotsData={state?.data}
                  getSelected={(data) => getSelected(data, i)}
                  selectedSlot={_?.selectedSlot}
                  hideSlotAvaible={state?.scheduleAt ? false : true}
                  handleCalenderChange={(date) => onChangeDate(date)}
                  scheduleDateFormat="DD MMM,YYYY"
                />
              </div>
            );
          })}
        </div>
      )}
      <div id={`${parent_id}-Reschedule-reason`} style={{ marginTop: 12 }}>
        <SelectWithLabel
          id={`${parent_id}-selectwithlabel`}
          options={reasonOptionList?.data}
          label={`Reason`}
          required={true}
          value={state?.reason}
          onChange={(data) => onChange("reason", data)}
          // error={props?.data?.error?.category??false}
        />
      </div>
      <div
        id={`${parent_id}-Reschedule-button-div`}
        style={{ marginTop: 30, textAlign: "center" }}
      >
        <Button
          id={`${parent_id}-Reschedule-button`}
          className={classes.button}
          onClick={rescheduleAppointment}
          variant="contained"
          color="primary"
          disabled={isDisable()}
        >
          Reschedule
        </Button>
      </div>
    </div>
  );
};
