import React from "react";
import { withRouter } from "react-router";
import LocationRoles from "../../components/locationAndRoles";
import { withAllContexts } from "../../HOCs";


// class LocationScreen.ex{
    class LocationScreen extends React.Component{
        render(){
    return(
        <>
        <LocationRoles />
        </>
    )
        }
};

export default LocationScreen