import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import * as ALL from "../../components";
import {
  Paper,
  Grid,
  Typography,
  Button,
  Avatar,
  Divider,
  Link,
  makeStyles,
  Modal,
} from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
import { Autocomplete, RichTextfield } from "../inputs";
import { CreateIcon, TrashIcon } from "../../assets";
import copy from "fast-copy";
var aes256 = require("aes256");

const useStyles = makeStyles((theme) => ({
  dropdown: {
    background: `${theme.palette.background.common} !important`,
    color: theme.palette.text.primary,
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalPaper: {
    width: 740,
    // backgroundColor: theme.palette.background.paper,
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
    "& > .App1-MuiTypography-root": {
      fontSize: 14,
      color: theme.palette.text.primary,
      // color: "rgba(0, 28, 60, 1)",
      fontWeight: 600,
      textTransform: "uppercase",
    },
    "& > .App1-MuiIconButton-root": {
      background: "rgba(1, 32, 92, 0.1)",
    },
    "& .App1-MuiSvgIcon-root": {
      fontSize: "1.1rem",
    },
  },
  editdirection: {
    color: theme.palette.text.primary,
  },
  editdirectionClose: {
    color: theme.palette.text.primary,
  },
  modalHeaderDivider: {
    background: "#DEE5EC",
  },
  modalBodyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    padding: "20px 16px",
  },
  modalInputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    "& > .App1-MuiTypography-root": {
      color: theme.palette.text.primary,
      // color: "rgb(106, 120, 136)",
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "0px",
      textAlign: "left",
      textTransform: "capitalize",
      margin: "0px",
    },
  },
  modalInput: {
    width: "345px",
  },
  modalActionContainer: {
    display: "flex",
    gap: 8,
    marginLeft: "auto",
  },
  modalSaveAndAdd: {
    color: "#2A60BC",
    backgroundColor: "#F1F6FC",
    borderRadius: "8px",
  },
  modalAdd: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    borderRadius: "8px",
  },
  instructionValueContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
}));

function EditInstructions({
  directionDescripion,
  directionLocalLanguage,
  handleDirectionSaveClick = () => { },
  // changeDrugDirection = () => {},
  ...props
}) {
  const dispatch = useDispatch();

  // Store States
  const instructionType =
    useSelector(
      (state) => state?.phramcyFrameworkApiSlice?.instructionMasterRead?.data
    ) ?? [];

  const instructionTypeOptions =
    instructionType?.map((item) => ({
      ...item,
      label: item?.shortdesc,
      value: item?.code,
    })) ?? [];

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    instrType: "",
    directionDescripion: "",
    directionLocalLanguage: "",

    // immutable State
    appendableDescription: "",
    appendableLocalLanguage: "",
  });

  // State Prefill
  React.useEffect(() => {

    setState((prev) => ({
      ...prev,
      directionDescripion: directionDescripion,
      directionLocalLanguage: directionLocalLanguage,
    }));
  }, [directionDescripion, directionLocalLanguage]);

  const changeState = (key, value) => {
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const changeInstructionType = (value, reason) => {
    if (reason === "select-option") {
      const {
        directionDescripion: descValue,
        directionLocalLanguage: localLangValue,
      } = state;
      changeState("appendableDescription", `${value?.shortdesc}`);
      changeState("appendableLocalLanguage", `${value?.display}`);
    }
  };

  React.useEffect(() => {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  // React.useEffect(() => {
  //   dispatch(INSTRUCTION_MASTER_READ({}));
  // }, []);

  const classes = useStyles();
  const { data, index, options } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          {/* Modal Section Starts */}
          <Modal
            open={props?.directionDialog}
            className={classes.modal}
            onClose={props?.handleEditDirectionsClose}
          >
            <Paper className={classes.modalPaper}>
              <div className={classes.modalHeader}>
                <Typography
                  className={classes.editdirection}
                >EDIT DIRECTIONS</Typography>

                <ALLMCORE.IconButton
                  size="small"
                  onClick={props?.handleEditDirectionsClose}
                >
                  <CloseRoundedIcon
                    className={classes.editdirectionClose}
                  />
                </ALLMCORE.IconButton>
              </div>

              <Divider className={classes.modalHeaderDivider} />

              <div className={classes.modalBodyContainer}>
                <div className={classes.modalInputContainer}>
                  <Typography variant={"body1"}>Instructions</Typography>

                  <Autocomplete
                    className={classes.modalInput}
                    getOptionLabel={(option) => option?.["label"]}
                    options={instructionTypeOptions ?? []}
                    value={state?.instrType ?? null}
                    // onLoad={() => changeInstructionType("instrType", state?.instrType)}
                    onChange={(event, newValue, reason) => {
                      changeInstructionType(newValue, reason);
                    }}
                    // onChange={(event, newValue) =>
                    //   changeState("instrType", newValue)
                    // }
                    PaperComponent={({ children }) => (
                      <Paper className={classes.dropdown}>{children}</Paper>
                    )}
                  />
                </div>
                <div className={classes.modalInputContainer}>
                  <Typography variant={"body1"}>
                    Direction Description
                  </Typography>

                  <RichTextfield
                    initialValue={state?.directionDescripion ?? ""}
                    appendValue={state?.appendableDescription}
                    resetAppendValue={() =>
                      changeState("appendableDescription", "")
                    }
                    // value={state?.directionDescripion}
                    // onChange={(rawValue) =>
                    //   changeDrugDirection("directionDescripion", rawValue)
                    // }
                    onChange={(rawValue) =>
                      changeState("directionDescripion", rawValue)
                    }
                  // onChange={(rawValue) =>
                  //   changeState("instruction", rawValue)
                  // }
                  />
                </div>
                <div className={classes.modalInputContainer}>
                  <Typography variant={"body1"}>
                    Direction Local Language
                  </Typography>
                  <RichTextfield
                    initialValue={state?.directionLocalLanguage ?? ""}
                    appendValue={state?.appendableLocalLanguage}
                    resetAppendValue={() =>
                      changeState("appendableLocalLanguage", "")
                    }
                    // onChange={(rawValue) =>
                    //   changeDrugDirection("directionLocalLanguage", rawValue)
                    // }
                    onChange={(rawValue) =>
                      changeState("directionLocalLanguage", rawValue)
                    }
                  // onChange={(rawValue) =>
                  //   changeState("otrLangInstr", rawValue)
                  // }
                  />
                </div>

                <div className={classes.modalActionContainer}>
                  {/* <Button
                    variant={"text"}
                    color={"primary"}
                    style={qdmstyles.Phme}
                    size="small"
                    className={classes.modalSaveAndAdd}
                    // onClick={() => handleSaveClick(state?.editIndex, true)}
                  >
                    {state?.editIndex
                      ? "Update & Add another"
                      : "Save & Add another"}
                  </Button> */}
                  <Button
                    variant={"contained"}
                    style={qdmstyles.CPPs}
                    size="small"
                    className={classes.modalAdd}
                    onClick={() => {
                      handleDirectionSaveClick(state);
                      props.handleEditDirectionsClose();
                    }}
                  // onClick={() => handleSaveClick(state?.editIndex, false)}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Paper>
          </Modal>
          {/* Modal Section Ends */}
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(EditInstructions));
