import React, { useState } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {
  AddIcon,
  DollarIcon,
  //DrugPrint,
  EstimateIcon,
  GroceryShelfIcon,
  HoldingHandIcon,
  HoldingHandWithPillIcon,
  InfoIcon,
  NoAccessIcon,
  PillIcon,
  ScheduleIcon,
  SchoolIcon,
  StackIcon,
  StackOverflowIcon,
  SupplementDoseIcon,
  SupplementTotalDoseIcon,
  TimesheetIcon,
  TrashIcon,
} from "../../../../../assets";
import { ReactComponent as CollapseIcon } from "../../../../../assets/collapse.svg";
import { ReactComponent as ExpandIcon } from "../../../../../assets/expand.svg";
import { ReactComponent as PrinterIcon } from "../../../../../assets/printerIconSecondary.svg";
import {
  useDividerStyles,
  useDrugBatchStyles,
  useDrugParticularsStyles,
  useCustomDotStyles,
  useCustomTooltip,
} from "./styles";
import { useDividerStyles2 } from "../styles";
import InputBase from "@material-ui/core/InputBase";
// import TextSelectField from "../textSelectField";
// import { DoseIssueUOMOptions, getImgUrl } from "../../../../../utils";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  //DialogContentText,
  FormControl,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Tooltip,
  Collapse,
  MenuList,
  // Modal,
} from "@material-ui/core";
import PatientEducation from "../patientEducation";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { GlassMagnifier } from "react-image-magnifiers";
//import { Directions } from "@material-ui/icons";
import { Edit } from "../../../../../assets/edit";
import EditInstructions from "../../../../editDirections";
//import { actions } from "frequencyscreen_v_dbinder";
import { useDispatch, useSelector } from "react-redux";
// import { Document, Page } from "react-pdf";
import { Document, Page } from "react-pdf";
import { getImgUrl } from "../../../../../utils";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
// import PDFViewer from "pdf-viewer-reactjs";
import PlaylistAddOutlinedIcon from "@material-ui/icons/PlaylistAddOutlined";
import CalendarViewDayOutlinedIcon from "@material-ui/icons/CalendarViewDayOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const StripInput = withStyles((theme) => ({
  root: {
    flex: 0,
    gap: 10,
    padding: "4px 8px",
    background: "white",
    borderRadius: "4px",
    border: "1px solid #DEE5EC",
    "& .App1-MuiInputBase-input": {
      minWidth: 20,
    },
  },
  input: {
    padding: 0,
    "&:focus": {
      backgroundColor: "unset",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    // border: "1px solid #DEE5EC",
    background: `${theme.palette.background.common} !important`,
    borderRadius: 8,
    "& svg": {
      color: theme.palette.text.primary,
      "& path": {
        fill: `${theme.palette.text.primary}`,
        color: `${theme.palette.text.primary}`,
      },
    },
  },
  rootMulti: {
    border: "1px solid #DEE5EC",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    "& .App1-MuiInputBase-root": {
      fontSize: "12px",
    },
  },
  selectcomp: {
    "& .App1-MuiInputBase-root": {
      fontSize: "12px",
    },
  },
  select: {
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function DrugBrandTable(props) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isShow, setisShow] = React.useState(false);

  // Props
  const { brand = {}, handleChangebrandName } = props;

  const handleExpandCollapseChange = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div
      className={
        // !props.isMultiBatch ?
        classes.root
        // : classes.rootMulti
      }
    >
      <DrugParticulars
        {...props}
        handleChangebrandName={handleChangebrandName}
        isExpanded={isExpanded}
        onExpandCollapseChange={handleExpandCollapseChange}
        isShow={isShow}
        setisShow={setisShow}
        isError={
          props?.errorMessage?.statusCode === "200" || !props?.errorMessage
            ? false
            : true
        }
        errorMessage={props?.errorMessage?.errorMessage}
        isScheduleErr={
          props?.scheduleErrorMessage?.statusCode === "200" ||
            !props?.scheduleErrorMessage
            ? false
            : true
        }
        scheduleErrMessage={props?.scheduleErrorMessage?.errorMessage}
      />
      <Collapse in={isExpanded}>
        {brand.batches &&
          brand.batches.length > 0 &&
          brand.batches.map((batch, _in) => {
            return (
              <DrugBatch
                key={batch?.uniqueId}
                batch={batch}
                {...props}
                isShow={isShow}
                setisShow={setisShow}
                brand={brand}
                onBatchQuantityChange={(e, fil, b) =>
                  props.onBatchQuantityChange(e, _in, fil, b)
                }
              />
            );
          })}
      </Collapse>

      {/* <DrugBatch />
      <DrugBatch /> */}
    </div>
  );
}

export default DrugBrandTable;

// ----------------------------------------Custom dialog--------------------------------------------

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

// -------------------------------- Table Head - Drug Particulars --------------------------------------------

const DrugParticulars = ({
  drug,
  brand,
  batchQuantity,
  onBatchQuantityChange,
  isAnotherBrandAvailable,
  handleChangebrandName,
  handleAdd,
  isAdd,
  handleDelete = () => null,
  isChecker,
  index,
  selectedState,
  printLabel,
  // Is Intravenous fluid type
  isIntravenous,
  printEnable,
  //isCompound
  isCompound,
  // Component level props
  isExpanded,
  onExpandCollapseChange = () => null,
  isError,
  errorMessage,
  isScheduleErr,
  scheduleErrMessage,
  ...props
}) => {
  // Styles
  const classes = useDrugParticularsStyles();
  const theme = useTheme();
  //const dispatch = useDispatch();
  const binderData = useSelector(
    (state) => state?.phramcyFrameworkApiSlice?.label_print?.data
  );
  const dividerStyles = useDividerStyles();
  const tooltipStyles = useCustomTooltip();

  // State
  const [isStripTextfield, setIsStripTextfield] = React.useState(false);
  //const [Brand, setBrand] = React.useState(10);
  const [patientEdu, setPatientEdu] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [directionDialog, setDirectionDialog] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  const [printopen, setPrintOpen] = React.useState(false);
  //const [numPages, setNumPages] = React.useState();
  const [pageNumber, setPageNumber] = React.useState(1);

  const handleChange = (event) => {
    handleChangebrandName(event.target.value);
  };
  // const dropdownMenuProps = {
  //   menuStyle: {
  //     border: "1px solid black",
  //     borderRadius: "5%",
  //     backgroundColor: "lightgrey",
  //     maxWidth: "50px",
  //   },
  // };
  // Dialog

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });
  const handleDialog = (event, drug) => {
    setPatientEdu(true);
    // setOrdervalue(drug?.patientEdu);
  };
  const DialogClose = () => {
    setPatientEdu(false);
  };

  // Directions PopOver
  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Edit Directions Dialog
  const handleEditDirections = () => {
    setAnchorEl(null);
    setDirectionDialog(true);
  };
  const handleEditDirectionsClose = () => {
    setDirectionDialog(false);
  };

  //print popover

  const handlePrint = () => {
    setPrintOpen(true);
    printLabel();
  };

  const handleClose = () => {
    setPrintOpen(false);
  };

  React.useEffect(() => {
    props.setisShow(props.isShowMulti);
  }, [props.isShowMulti]);

  const [numPages, setNumPages] = useState(null);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // const data = JSON.stringify(binderData.downloadUrl);
  return (
    <>
      <div className={classes.root}>
        {/* Add a expand/collapse button here */}
        <IconButton
          size="small"
          disableRipple
          onClick={onExpandCollapseChange}
          className={classes.expandCollapseButton}
        >
          {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
        </IconButton>

        {selectedState === "verification" && !isCompound ? (
          <FormControl className={classes.formControl}>
            <Select
              disableUnderline
              className={classes.selectcomp}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={brand?.drugNameLong}
              // MenuProps={{
              //   anchorOrigin: {
              //     vertical: "bottom",
              //     horizontal: "left",
              //   },
              //   transformOrigin: {
              //     vertical: "top",
              //     horizontal: "left",
              //   },
              //   getContentAnchorEl: null,
              // }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                classes: { paper: classes.select },
              }}
              onChange={handleChange}
              InputLabelProps={{ style: { fontSize: 12 } }}
              inputProps={{ style: { fontSize: 12 } }}
            // MenuProps={{ classes: { paper: classes.select } }}
            >
              {brand?.option?.map((val) => {
                return (
                  <MenuItem value={val.drugNameLong}>
                    {val.drugNameLong}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : (
          <Tooltip title={brand?.drugNameLong}>
            <Typography noWrap className={classes.headerText}>
              {brand?.drugNameLong}
            </Typography>
          </Tooltip>
        )}
        <Dot />

        <Typography className={classes.defaultText}>
          {props?.drugType !== "DISPOSABLE" && (
            <>
              {(brand?.allStrength || [])?.map((v, i) => {
                return (
                  <>
                    {v}
                    {i !== brand?.allStrength?.length - 1 ? `/ ` : ``}
                  </>
                );
              })}

              {brand?.strength}
            </>
          )}
        </Typography>
        <Dot />
        {brand?.doseCount > 0 && props?.drugType !== "DISPOSABLE" && (
          <>
            <Tooltip
              title={isIntravenous ? "Rx Dose" : "Total Doses"}
              arrow
              classes={tooltipStyles}
            >
              <div className={classes.doseDetailItem}>
                <SupplementDoseIcon />
                <Typography className={classes.defaultText}>
                  {brand?.doseCount} doses
                </Typography>
              </div>
            </Tooltip>

            <Dot />
          </>
        )}
        {brand?.actualdoseCount > 0 && isIntravenous && (
          <>
            <Tooltip
              title={"Required Dose Quantity"}
              arrow
              classes={tooltipStyles}
            >
              <div className={classes.doseDetailItem}>
                <SupplementTotalDoseIcon />
                <Typography className={classes.defaultText}>
                  {brand?.totadmqty}&nbsp;{brand?.totadmuom}
                </Typography>
              </div>
            </Tooltip>

            <Dot />
          </>
        )}
        {brand?.tabPerDose > 0 && props?.drugType !== "DISPOSABLE" && (
          <>
            <Tooltip
              title={
                isIntravenous
                  ? "Administrative Dose Quantity"
                  : "Per Dose Quantity"
              }
              arrow
              classes={tooltipStyles}
            >
              <div className={classes.doseDetailItem}>
                <PillIcon />
                <Typography className={classes.defaultText}>
                  {props?.disptype === "IV-LV"
                    ? brand?.dosageValue + " " + brand?.strength + " "
                    : brand?.perdoseadminqty +
                    " " +
                    brand?.totadmuom +
                    "per dose"}
                </Typography>
              </div>
            </Tooltip>

            <Dot />
          </>
        )}
        {brand?.actualdoseCount > 0 && !isIntravenous && (
          <>
            <Tooltip title={"Admin Quantity"} arrow classes={tooltipStyles}>
              <div className={classes.doseDetailItem}>
                <SupplementTotalDoseIcon />
                <Typography className={classes.defaultText}>
                  {brand?.totadmqty}&nbsp;{brand?.totadmuom}
                </Typography>
              </div>
            </Tooltip>

            <Dot />
          </>
        )}
        {isIntravenous && brand?.infusionrate && (
          <>
            <div className={classes.doseDetailItem}>
              <Typography className={classes.defaultText}>
                {/* {"250CC/Hr for 2 hrs"} */}
                {brand?.infusionrate}&nbsp; {props?.infusionrateUOM}&nbsp; for{" "}
                {brand?.infusiontime}&nbsp;{props?.infusiontimeUOM}
              </Typography>
            </div>
            <Dot />
          </>
        )}
        {isIntravenous && brand?.totstkqty && (
          <>
            <Tooltip title="Total Quantity" arrow classes={tooltipStyles}>
              <div className={classes.doseDetailItem}>
                <HoldingHandWithPillIcon />
                <Typography className={classes.defaultText}>
                  {brand?.totstkqty}&nbsp;{brand?.totstkuom}
                </Typography>
              </div>
            </Tooltip>
            <Dot />
          </>
        )}

        <Tooltip title="Issue Quantity" arrow classes={tooltipStyles}>
          <div
            className={classNames(
              classes.doseDetailItem,
              classes.stripQuantity
            )}
            onClick={(e) => {
              setIsStripTextfield((prev) => !prev);
            }}
          >
            <HoldingHandIcon style={{ minWidth: "17.705px" }} />
            {isStripTextfield && drug?.disptype?.code === "B" ? (
              <>
                <StripInput
                  autoFocus
                  id={"stripInput"}
                  placeholder="00"
                  type={"text"}
                  //value={batchQuantity}
                  value={brand?.totstkqty}
                  disabled={selectedState !== "verification"}
                  onChange={(e) => {
                    props.issueQuantity(e.target.value);
                    // onBatchQuantityChange(e.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  endAdornment={
                    <Typography className={classes.stripFielAdornText}>
                      {brand?.totstkuom}
                    </Typography>
                  }
                  inputProps={{ "aria-label": "Input field" }}
                />
                {/* <Typography
                  style={{ display: "inline" }}
                  className={classes.defaultText}
                >
                  ( {brand?.totadmqty} {brand?.totadmuom})
                </Typography> */}
              </>
            ) : (
              <Typography className={classes.defaultText}>
                {brand?.totstkqty}&nbsp;{brand?.totstkuom}
                {/* ( {brand?.totadmqty} {brand?.totadmuom}) */}
              </Typography>
            )}
          </div>
        </Tooltip>

        <Dot />

        <Tooltip title="Bill Quantity" arrow classes={tooltipStyles}>
          <div className={classes.doseDetailItem}>
            <EstimateIcon />
            <Typography className={classes.defaultText}>
              {brand?.totbillqty}&nbsp;{brand?.totbilluom}
              {/* ( {brand?.totadmqty}
              {brand?.totadmuom}) */}
            </Typography>
          </div>
        </Tooltip>
        {props?.drugType !== "DISPOSABLE" && (
          <>
            <Dot />
            <div
              style={{ cursor: "pointer", display: "flex" }}
              onMouseOver={handleClickPopover}
            >
              <TimesheetIcon />
            </div>
          </>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: classes.btngroup,
          }}
        >
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "12px 12px 4px",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <TimesheetIcon />
                <Typography
                  color="primary"
                  style={{
                    fontSize: "12px",
                    marginLeft: "6px",
                  }}
                >
                  Directions
                </Typography>
              </div>
              {selectedState === "verification" && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={handleEditDirections}
                >
                  <Edit width={"14"} height={"14"} />
                </div>
              )}
            </div>
            <div style={{ padding: "12px", maxWidth: "400px" }}>
              {drug?.PH_OrderLineDispenseSchedule?.length > 0
                ? drug?.PH_OrderLineDispenseSchedule?.[0]?.dispensedetails?.[
                  index
                ]?.direction
                : brand?.pH_OrderLineDispenseSchedule?.dispensedetails?.[index]
                  ?.direction && (
                  <div
                    style={{
                      borderRadius: "8px",
                      marginBottom: "8px",
                    }}
                    className={classes.MainBackgroundCard}
                  >
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#2A3C50",
                        padding: "8px 12px",
                      }}
                    >
                      {drug?.PH_OrderLineDispenseSchedule?.length > 0
                        ? // <div
                        //   dangerouslySetInnerHTML={{
                        //     __html:
                        //       drug?.PH_OrderLineDispenseSchedule?.[0]
                        //         ?.dispensedetails?.[index]?.direction,
                        //   }}
                        // />
                        drug?.PH_OrderLineDispenseSchedule?.[0]
                          ?.dispensedetails?.[index]?.direction
                        : // <div
                        //   dangerouslySetInnerHTML={{
                        //     __html:
                        //       brand?.pH_OrderLineDispenseSchedule
                        //         ?.dispensedetails?.[index]?.direction,
                        //   }}
                        // />
                        brand?.pH_OrderLineDispenseSchedule
                          ?.dispensedetails?.[index]?.direction}
                    </Typography>
                  </div>
                )}
              {drug?.PH_OrderLineDispenseSchedule?.length > 0
                ? drug?.PH_OrderLineDispenseSchedule?.[0]?.dispensedetails?.[
                  index
                ]?.nldirection
                : brand?.pH_OrderLineDispenseSchedule?.dispensedetails?.[index]
                  ?.nldirection && (
                  <div
                    style={{
                      // backgroundColor: "#F1F6FC",
                      borderRadius: "8px",
                    }}
                    className={classes.MainBackgroundCard}
                  >
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#2A3C50",
                        padding: "8px 12px",
                      }}
                    >
                      {drug?.PH_OrderLineDispenseSchedule?.length > 0
                        ? // <div
                        //   dangerouslySetInnerHTML={{
                        //     __html:
                        //       drug?.PH_OrderLineDispenseSchedule?.[0]
                        //         ?.dispensedetails?.[index]?.nldirection,
                        //   }}
                        // />
                        drug?.PH_OrderLineDispenseSchedule?.[0]
                          ?.dispensedetails?.[index]?.nldirection
                        : // <div
                        //   dangerouslySetInnerHTML={{
                        //     __html:
                        //       brand?.pH_OrderLineDispenseSchedule
                        //         ?.dispensedetails?.[index]?.nldirection,
                        //   }}
                        // />
                        brand?.pH_OrderLineDispenseSchedule
                          ?.dispensedetails?.[index]?.nldirection}
                    </Typography>
                  </div>
                )}
            </div>
          </>
        </Popover>
        <EditInstructions
          directionDialog={directionDialog}
          handleEditDirectionsClose={handleEditDirectionsClose}
          directionDescripion={
            drug?.PH_OrderLineDispenseSchedule?.length > 0
              ? drug?.PH_OrderLineDispenseSchedule?.[0]?.dispensedetails?.[
                index
              ]?.direction
              : brand?.pH_OrderLineDispenseSchedule?.dispensedetails?.[index]
                ?.direction
          }
          directionLocalLanguage={
            drug?.PH_OrderLineDispenseSchedule?.length > 0
              ? drug?.PH_OrderLineDispenseSchedule?.[0]?.dispensedetails?.[
                index
              ]?.nldirection
              : brand?.pH_OrderLineDispenseSchedule?.dispensedetails?.[index]
                ?.nldirection
          }
          handleDirectionSaveClick={(e) => props.handleDirectionSaveClick(e)}
        // changeDrugDirection={props.changeDrugDirection}
        />

        {isChecker && (
          <>
            <Dot />
            <Tooltip title="Medication at home" arrow classes={tooltipStyles}>
              <div onClick={handleDialog} style={{ marginTop: "4px" }}>
                <SchoolIcon />
              </div>
            </Tooltip>
            <div style={{ flex: 1 }} />
            <div className={classes.doseDetailItem}>
              <Typography className={classes.defaultText}>sub-Total</Typography>
              <Typography className={classes.amount}>
                <span style={{ marginRight: "5px" }}>$</span>475.00
              </Typography>
            </div>
          </>
        )}

        {props?.isMultiBatch &&
          brand?.batches?.length > 0 &&
          !props?.isCardVerify && (
            <Tooltip
              title={
                props.isShow
                  ? "Show only allocated batches"
                  : "Show all batches"
              }
              arrow
              classes={tooltipStyles}
            >
              <div
                style={{ marginTop: "4px" }}
                onClick={() =>
                  props.handleMultiBatch(
                    props.isIntravenous
                      ? "additives"
                      : isCompound
                        ? "compounds"
                        : "brands",
                    props.isShow
                  )
                }
              >
                {props.isShow ? (
                  <CalendarViewDayOutlinedIcon
                    style={{ fontSize: 17, marginLeft: 6 }}
                  />
                ) : (
                  <PlaylistAddOutlinedIcon
                    style={{ fontSize: 19, marginLeft: 6 }}
                  />
                )}
              </div>
            </Tooltip>
          )}

        {(isError || isScheduleErr) && (
          <Tooltip
            title={errorMessage || scheduleErrMessage}
            arrow
            classes={tooltipStyles}
          >
            <ErrorOutlineIcon
              style={{ marginLeft: "auto", marginTop: "4px" }}
              fontSize="small"
            />
          </Tooltip>
        )}
        {printEnable && (
          <Tooltip title="Print" arrow classes={tooltipStyles}>
            <div
              style={
                !isError
                  ? { marginLeft: "auto", marginTop: "4px" }
                  : { marginTop: "4px" }
              }
              onClick={() => handlePrint()}
            >
              <PrinterIcon />
            </div>
          </Tooltip>
        )}

        <Divider orientation="vertical" flexItem classes={dividerStyles} />

        <div className={classes.actionContainer}>
          {index > 0 && (
            <TrashIcon
              onClick={() => handleDelete()}
              color={theme.palette.primary.main}
            />
          )}

          {/* {isAnotherBrandAvailable && <AddIcon />} */}
          {isAdd && (
            <AddIcon
              {...bindTrigger(popupState)}
              color={theme.palette.primary.main}
            />
          )}
        </div>
      </div>
      <Dialog
        fullWidth
        // maxWidth="sm"
        classes={{ paper: classes.dialogPaper }}
        open={patientEdu}
        onClose={DialogClose}
        aria-labelledby="customized-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="customized-dialog-title" onClose={DialogClose}>
          <div style={{ fontSize: "14px", fontWeight: 500 }}>
            PATIENT EDUCATION
          </div>
        </DialogTitle>
        <DialogContent>
          <PatientEducation />
          {/* <patientEdu patientEdu={orderValue} /> */}
        </DialogContent>
      </Dialog>

      {/* //LabelPrint........................... */}
      <Dialog
        open={printopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.pdfDialogPaper }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Label Print Preview"}
        </DialogTitle>
        <DialogContent>
          {/* <div>{binderData.downloadUrl}</div> */}
          <Document
            file={binderData.downloadUrl}
            options={{ workerSrc: "/pdf.worker.js" }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.prnPopoverPaper,
        }}
      >
        <MenuList>
          {props?.add_drug?.map((val) => {
            return (
              <MenuItem
                onClick={() => {
                  handleAdd(val);
                  popupState.close();
                }}
              >
                <Typography className={classes.optionText}>
                  {val?.longdesc}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Popover>
    </>
  );
};

DrugParticulars.propTypes = {
  batchQuantity: PropTypes.string,
  onBatchQuantityChange: PropTypes.func,
};

DrugParticulars.defaultProps = {
  batchQuantity: "",
  onBatchQuantityChange: () => { },
};

// End of Drug Particulars

// -------------------------------- Table Body - Drug Batch --------------------------------------------

const DrugBatch = ({
  batch,
  batchQuantity,
  onBatchQuantityChange,
  isChecker,
  imgId,
  selectedState,
  brand,
  ...props
}) => {
  // const theme = useTheme();
  // Styles
  const classes = useDrugBatchStyles();
  const dividerStyles = useDividerStyles();
  const dividerstyles2 = useDividerStyles2();
  const [breakdown, setBreakdown] = React.useState(null);
  const [state, setState] = useState({
    allowOverflow: true,
    magnifierBorderSize: 5,
    magnifierBorderColor: "rgba(255, 255, 255, .5)",
    magnifierSize: "25%",
    square: false,
  });

  const [anchorElImage, setAnchorElImage] = React.useState(null);

  const handleZoomImage = (event) => {
    setAnchorElImage(event.currentTarget);
  };

  const handleZoomImageClose = () => {
    setAnchorElImage(null);
  };

  const openImager = Boolean(anchorElImage);
  const tooltipStyles = useCustomTooltip();
  const open = Boolean(breakdown);
  const handleBreakdown = (event) => {
    //
    setBreakdown(event.currentTarget);
  };
  const BreakdownClose = () => {
    setBreakdown(null);
  };

  const getRemainig = (total, issue) => {
    return total - issue;
  };
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <div className={classes.root}>
        <div className={classes.batchIdExpiry}>
          <Tooltip title={batch?.id}>
            <Typography noWrap className={classes.batchId}>
              {batch?.id}
            </Typography>
          </Tooltip>
          <Dot />
          <Tooltip title="Expiry Date" arrow classes={tooltipStyles}>
            <div className={classes.expiry}>
              <ScheduleIcon color="#2A3C50" />
              <Typography className={classes.defaultText}>
                {moment(batch?.expiryDate).format("DD MMM, YYYY")}
              </Typography>
            </div>
          </Tooltip>
        </div>
        <Divider orientation="vertical" flexItem classes={dividerStyles} />
        <div className={classes.BinLocation}>
          <div className={classes.binNumber}>
            <Tooltip
              title={
                <>
                  BIN&nbsp;<span>{batch?.bin?.number}</span>
                </>
              }
            >
              <Typography
                // style={{ width: "30px" }}
                noWrap
              >
                &nbsp;<span>{batch?.bin?.number}</span>
              </Typography>
            </Tooltip>
          </div>

          <GroceryShelfIcon />

          <Typography className={classes.aisleShelfLevel}>
            {`${batch?.bin?.aisle} `}
            {/* - ${batch?.bin?.shelf} - ${batch?.bin?.level} */}
          </Typography>
        </div>
        <Divider orientation="vertical" flexItem classes={dividerStyles} />
        <div className={classes.stockBlockedAmount}>
          <Tooltip title="Available Quantity" arrow classes={tooltipStyles}>
            <div className={classes.stockDetailItem}>
              <StackOverflowIcon />
              <Typography className={classes.defaultText}>
                {batch?.availableQuantity}&nbsp;{batch?.availableQuantityUOM}
              </Typography>
            </div>
          </Tooltip>
          <Dot />
          <Tooltip title="Blocked Quantity" arrow classes={tooltipStyles}>
            <div className={classes.stockDetailItem}>
              <NoAccessIcon />
              <Typography className={classes.defaultText}>
                {
                  batch?.heldQuantity
                  //  || brand?.totstkqty
                }
              </Typography>
            </div>
          </Tooltip>
          <Dot />
          <div className={classes.stockDetailItem}>
            <DollarIcon />
            <Typography className={classes.defaultText}>
              {batch?.totalAmount}
            </Typography>
          </div>
          <Dot />
          {selectedState !== "verification" && imgId && (
            <div
              aria-owns={openImager ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              className={classes.stockDetailItem}
              onMouseOver={handleZoomImage}
            >
              <div
                style={{
                  border: "1px solid #DEE5EC",
                  borderRadius: "4px",
                  padding: "2px",
                }}
              >
                <img
                  alt="Drug"
                  src={getImgUrl(imgId)}
                  width="20px"
                  height={"18px"}
                />
              </div>
            </div>
          )}

          {/* Popover For Zoom Image */}
          <Popover
            id="mouse-over-popover-image"
            className={classes.popover}
            classes={{
              paper: classes.paperImage,
            }}
            open={openImager}
            anchorEl={anchorElImage}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handleZoomImageClose}
            disableRestoreFocus
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <GlassMagnifier
                className="input-position"
                imageSrc={getImgUrl(imgId)}
                largeImageSrc={getImgUrl(imgId)}
                style={{ width: "330px", height: "100%" }}
                allowOverflow={state.allowOverflow}
                magnifierSize={state.magnifierSize}
                magnifierBorderSize={state.magnifierBorderSize}
                magnifierBorderColor={state.magnifierBorderColor}
                square={state.square}
              />
              <div></div>
            </div>
          </Popover>
        </div>
        <div style={{ flex: 1 }} />

        <Divider
          orientation="vertical"
          flexItem
          classes={dividerStyles}
          style={{ marginRight: 4 }}
        />
        <div className={classes.stripQuantityContainer}>
          <div className={classes.doseInputLabelContainer}>
            <Typography>Qty :&nbsp;</Typography>
            {(props?.drug?.disptype?.code === "B" ||
              props.drugType === "DISPOSABLE" ||
              props.isShow ||
              props?.stateValue?.toLowerCase() === "filling") &&
              !props?.isCardVerify &&
              !props?.isOnHold ? (
              <StripInput
                style={{ width: "40px" }}
                value={batch?.chargeqty}
                onChange={(e) =>
                  onBatchQuantityChange(
                    e.target.value,
                    props?.stateValue?.toLowerCase() === "filling",
                    props.isIntravenous
                      ? "additives"
                      : props.isCompound
                        ? "compounds"
                        : "brands"
                  )
                }
              />
            ) : (
              batch?.chargeqty
            )}
            &nbsp;
            {batch?.chargeuom?.UOM ?? batch?.chargeuom ?? ''}
          </div>
          <Tooltip title="Balance Quantity" arrow classes={tooltipStyles}>
            <div className={classes.addedDrugContainer}>
              <StackIcon />
              <Typography color="primary">
                {getRemainig(batch?.stockqty, batch?.blockqty)}
              </Typography>
            </div>
          </Tooltip>
        </div>

        {/* ------------------Disp Breakdown--------------------------- */}
        {isChecker && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              classes={dividerStyles}
              style={{ marginRight: 4 }}
            />
            <div className={classes.stripQuantityContainer}>
              <Tooltip title="Bill Quantity" arrow>
                <div className={classes.aliign}>
                  {/* <Typography>Qty :&nbsp;</Typography> */}
                  <EstimateIcon />
                  <Typography className={classes.defaultText}>15</Typography>
                </div>
              </Tooltip>
              <Dot />
              <div className={classes.doseDetailItem}>
                <div style={{ display: "flex" }} onMouseOver={handleBreakdown}>
                  <InfoIcon style={{ marginBottom: "3px" }} />
                  <Typography
                    className={classes.defaultText2}
                    style={{ marginLeft: "5px" }}
                  >
                    Breakdown{" "}
                  </Typography>
                </div>
              </div>
            </div>
            <Divider
              orientation="vertical"
              flexItem
              classes={dividerStyles}
              style={{ marginRight: 4 }}
            />
            <Typography
              className={classes.defaultText2}
              style={{ marginLeft: "8px" }}
            >
              <span style={{ fontSize: "14px", fontWeight: 500 }}>$</span>{" "}
              475.00
            </Typography>
          </>
        )}
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={breakdown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={BreakdownClose}
        disableRestoreFocus
      >
        <div style={{ marginTop: "5px", padding: "10px", width: "157px" }}>
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: "3px" }}>
              <InfoIcon />
            </div>
            <Typography className={classes.bold}>Price brakdown</Typography>
          </div>

          <Divider
            orientation="horizontal"
            flexItem
            classes={dividerstyles2}
            style={{ height: "1px !important" }}
          />
          <div style={{ marginTop: "5px" }}>
            {/* <Typography
              className={classes.info}
              style={{ paddingTop: "8px", paddingBottom: "5px" }}
            >
              self pay 
            </Typography> */}

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Typography className={classes.boldtext}>self pay</Typography>
              </div>
              <div style={{ marginLeft: "5px" }}>
                <Typography className={classes.greytext}>$125.00</Typography>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Typography className={classes.boldtext}>
                  corporate pay
                </Typography>
              </div>
              <div style={{ marginLeft: "5px" }}>
                <Typography className={classes.greytext}>$350.00</Typography>
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};

// End of Drug Batch

// -------------------------------- Dot ----------------------------------------------------------------

const Dot = () => {
  const classes = useCustomDotStyles();
  return <div className={classes.root}></div>;
};

// End of Dot
