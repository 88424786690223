import React from "react";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import {
  Grid,
  Typography,
  Divider,
  Box,
  Avatar,
  Popover,
  makeStyles,
  Paper,
  Dialog,
  Tooltip,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

import qdmstyles from "./styles.json";
import "./index.css";
import { BasicTable } from "../drugItem/components";
import { InfoIcon } from "../../assets";
import OrderDetails from "../drugItem/components/drugCard/orderDetails";
import { useDialogStyles, useDialogContentStyles } from "./style";
import { StyledDialog } from "../commonComponents";
import DrugOrderDetails from "../drugOrderDetails";
import TimeComponent from "../timeComponent";
import classNames from "classnames";

var aes256 = require("aes256");

// Dialog..............

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function ActiveMedication(props) {
  const dispatch = useDispatch();
  const customClasses = useCustomStyles(props);
  const {
    propsState,
    selectedUI,
    currentGridStartIndex,
    selectedActiveMedicationList = [],
    handleAddCard = () => { },
    handleDeleteCard = () => { },
  } = props;

  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  const [schedule, setSchedule] = React.useState(null);
  const [openPopover, setOpenPopover] = React.useState(null);

  const handleClickSchedule = (event, id) => {
    setSchedule(event.currentTarget);
    setOpenPopover(id);
  };
  const handleCloseschedule = () => {
    setSchedule(null);
    setOpenPopover(null);
  };

  const FreqHeader = (type) => {
    switch (type) {
      case "DBA":
        return ["Day", "Base schedule", "Dosage", "Remarks"];
      case "UDE":
        return ["Base schedule", "From Day", "To Day", "Dosage", "Remarks"];
      case "DET":
        return ["Time", "Dosage", "Remarks"];
      case "INT":
      case "SEQ":
        return ["Base schedule", "Dosage", "Remarks"];
      default:
        return [
          "Day",
          "Time",
          "Base schedule",
          "Start date",
          "End date",
          "Dosage",
          "Remarks",
        ];
    }
  };

  const FreqBody = (FreqDetls) => {
    let data = FreqDetls?.map((v) => {
      return {
        Day: v?.day ? v?.day : "-",
        Time: v?.time ? v?.time : "-",
        "Base schedule": v?.baseFreq ?? "-",
        "Start date":
          v?.fromDate === 0
            ? "-"
            : moment(v?.fromDate * 1000).format("DD-MM-YYYY"),

        "End date":
          v?.toDate === 0 ? "-" : moment(v?.toDate * 1000).format("DD-MM-YYYY"),
        Dosage: v?.dose ? v?.dose : "-",
        "From Day": v?.fromDay,

        "To Day": v?.toDay,
        Remarks: v?.adminDosageInstruction ? v?.adminDosageInstruction : (v.remarks ?? "-"),
      };
    });
    return data;
  };

  const getIsCardSelected = (_id, list) => {
    const mapped = list.map((x) => x?._id);
    const res = mapped?.includes(_id);

    return res;
  };

  const { classesTheme } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          {selectedUI === "list" || propsState?.length === 0 ? (
            <Grid
              id={"active_medication_card"}
              container={false}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              aliasName={"activeMedicationCard"}
              style={qdmstyles.Gde}
              className={classNames(classesTheme.MainBackgroundCard, {
                [customClasses.activeMedList]: !props.isFullScreen,
                [customClasses.activeMedListFullScreen]: props.isFullScreen,
              })}
            >
              <>
                {propsState?.length > 0 ? (
                  <>
                    {propsState?.map((item, index) => {
                      return (
                        <>
                          <Grid
                            id={"active_med_div"}
                            container={false}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            aliasName={"activeMedDiv"}
                            style={qdmstyles.yBx}
                          >
                            <Typography
                              noWrap
                              id={"active_med"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={item?.drugName}
                              aliasName={"activeMed"}
                              style={qdmstyles.fXU}
                              className={classesTheme.Maintitle}
                            ></Typography>
                            <Divider
                              id={"divider"}
                              variant={"middle"}
                              orientation={"vertical"}
                              aliasName={"divider"}
                              style={qdmstyles.MFmp}
                            ></Divider>
                            <Typography
                              id={"med_qunatity"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={`${item?.dosageValue} ${item?.dosageUOM}`}
                              aliasName={"medQunatity"}
                              style={qdmstyles.COJY}
                            ></Typography>
                            <Box
                              key={"3"}
                              id={"dot"}
                              m={"1"}
                              component={"div"}
                              aliasName={"dot"}
                              style={qdmstyles.tLCxw}
                            ></Box>
                            <Typography
                              key={"4"}
                              id={"med_qunatity_time"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={item?.dosageFreqCode}
                              aliasName={"medQunatityTime"}
                              style={qdmstyles.prv}
                            ></Typography>
                            <Box
                              key={"5"}
                              id={"dot"}
                              m={"1"}
                              component={"div"}
                              aliasName={"dot"}
                              style={qdmstyles.pskFH}
                            ></Box>
                            <Avatar
                              key={"6"}
                              id={"med_icon"}
                              alt={"Avatar"}
                              variant={"rounded"}
                              aliasName={"medIcon"}
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317181939"
                              }
                              style={qdmstyles.xNxps}
                              onClick={(e) => handleClickSchedule(e, item?._id)}
                            ></Avatar>
                            <Box
                              key={"7"}
                              id={"dot"}
                              m={"1"}
                              component={"div"}
                              aliasName={"dot"}
                              style={qdmstyles.thHPx}
                            ></Box>
                            <Typography
                              key={"8"}
                              id={"med_qunatity_time"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={`${item?.duration}`}
                              aliasName={"medQunatityTime"}
                              style={qdmstyles.VTvbt}
                            ></Typography>
                            <Box
                              key={"9"}
                              id={"dot"}
                              m={"1"}
                              component={"div"}
                              aliasName={"dot"}
                              style={qdmstyles.Rmfm}
                            ></Box>

                            <TimeComponent
                              key={"10"}
                              noWrap
                              id={"med_date_time"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              aliasName={"medDateTime"}
                              style={qdmstyles.Czb}
                              dateTime={item?.startdate}
                              formatType="datetime"
                            />
                            <Box
                              key={"11"}
                              id={"dot"}
                              m={"1"}
                              component={"div"}
                              aliasName={"dot"}
                              style={qdmstyles.fMdN}
                            ></Box>
                            <Tooltip arrow title={item?.doctorName}>
                              <Typography
                                key={"12"}
                                id={"med_practitioner"}
                                noWrap
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                title={item?.doctorName ?? ""}
                                children={item?.doctorName ?? ""}
                                aliasName={"medPractitioner"}
                                style={qdmstyles.TWVwj}
                              ></Typography>
                            </Tooltip>
                            <Box
                              key={"13"}
                              id={"dot"}
                              m={"1"}
                              component={"div"}
                              aliasName={"dot"}
                              style={qdmstyles.Kefe}
                            ></Box>

                            <TimeComponent
                              noWrap
                              id={"med_date_time"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              aliasName={"medDateTime"}
                              style={qdmstyles.Czb}
                              dateTime={item?.createddate}
                              formatType="datetime"
                            />
                          </Grid>
                          {index !== propsState.length - 1 && (
                            <Divider
                              id={"ORl0v"}
                              variant={"fullWidth"}
                            ></Divider>
                          )}
                          <Popover
                            id={openPopover}
                            open={openPopover === item?._id}
                            anchorEl={schedule}
                            onClose={handleCloseschedule}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <div style={{ display: "flex", padding: "12px" }}>
                              <BasicTable
                                header={FreqHeader(item.dosageFreqTypeCode)}
                                dataTabs={FreqBody(item.FreqDetls)}
                                FreqDetls={item.FreqDetls}
                                dosageFreqCode={item.dosageFreqCode}
                              />
                            </div>
                          </Popover>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "15vh",
                    }}
                    item
                    xs={12}
                  >
                    No Active Medication Found
                  </Grid>
                )}
              </>
            </Grid>
          ) : (
            <>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: 12,
                  width: "100%",
                  padding: "0px 8px",
                }}
              >
                {propsState
                  ?.slice(currentGridStartIndex, currentGridStartIndex + 3)
                  ?.map((item, index) => (
                    <div key={item?._id}>
                      <MedicationGridCard
                        className={classesTheme}
                        item={item}
                        handleClickSchedule={(e) =>
                          handleClickSchedule(e, item?._id)
                        }
                        // isCardSelected={getIsCardSelected(
                        //   item?._id,
                        //   selectedActiveMedicationList
                        // )}
                        isCardSelected={selectedActiveMedicationList
                          ?.map((x) => x?._id)
                          ?.includes(item?._id)}
                        // selectedActiveMedicationList
                        //     .map((x) => x?._id)
                        //     .indexOf(item?._id) > 1
                        // }
                        onCardSelect={() => {
                          handleAddCard(item);
                        }}
                        onCardRemove={() => {
                          handleDeleteCard(item?._id);
                        }}
                      />
                      <Popover
                        id={openPopover}
                        open={openPopover === item?._id}
                        anchorEl={schedule}
                        onClose={handleCloseschedule}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <div
                          style={{ display: "flex", padding: "12px" }}
                        // className={classes.btngroup}
                        >
                          <BasicTable
                            header={FreqHeader(item.dosageFreqTypeCode)}
                            dataTabs={FreqBody(item.FreqDetls)}
                            FreqDetls={item.FreqDetls}
                            dosageFreqCode={item.dosageFreqCode}
                          />
                        </div>
                      </Popover>
                    </div>
                  ))}
              </div>
            </>
          )}
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

const useMedicationGridCardStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    border: "1px solid #DEE5EC",
    borderRadius: 8,
    display: "flex",
    justifyContent: "space-between",
    gap: 8,
    flex: 1,
    padding: 12,
  },
  leftSide: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: "#2A3C50",
    fontWeight: 500,
    fontSize: 14,
  },
  bottomContainer: {
    display: "flex",
    gap: 6,
    alignItems: "center",
  },
  dialogPaper: {
    minWidth: "1096px",
    maxWidth: "1096px",
    maxHeight: "100%",
  },
}));

const useCustomStyles = makeStyles((theme) => ({
  activeMedList: {
    maxHeight: "15vh",
    border: "1px solid #eaeaea",
  },
  activeMedListFullScreen: {
    maxHeight: "unset",
  },
}));

const CustomCheckbox = withStyles({
  root: {
    color: "#2A60BC",
    // '&$checked': {
    //   color: green[600],
    // },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function MedicationGridCard({
  item,
  handleClickSchedule,
  isCardSelected,
  onCardSelect,
  onCardRemove,
  className,
}) {
  const classes = useMedicationGridCardStyles();
  const dialogStyles = useDialogStyles();
  const dialogContentStyles = useDialogContentStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [isSelected, setIsSelected] = React.useState(isCardSelected);

  const handleCheckboxChange = (event) => {
    setIsSelected(event?.target?.checked);

    if (event?.target?.checked) {
      onCardSelect();
    } else {
      onCardRemove();
    }

    // handleCardSelect(item.id);
  };

  return (
    <>
      <Paper className={`${classes.root} ${className.MainBackgroundCard}`}>
        <div className={classes.leftSide}>
          <div style={{ display: "flex" }}>
            <Typography
              noWrap
              className={`${classes.title} ${className.Maintitle}`}
            >
              {item?.drugName}
            </Typography>
            <InfoIcon
              style={{
                minWidth: "14px",
                cursor: "pointer",
                marginLeft: "8px",
                marginTop: "4px",
              }}
              onClick={handleClickOpen}
            />
          </div>
          <div className={classes.bottomContainer}>
            <Typography
              id={"med_qunatity"}
              align={"inherit"}
              color={"initial"}
              display={"initial"}
              variant={"body1"}
              children={`${item?.dosageValue} ${item?.dosageUOM}`}
              aliasName={"medQunatity"}
              style={qdmstyles.COJY}
            ></Typography>
            <Box
              id={"dot"}
              m={"1"}
              component={"div"}
              aliasName={"dot"}
              style={qdmstyles.tLCxw}
            ></Box>
            <Typography
              id={"med_qunatity_time"}
              align={"inherit"}
              color={"initial"}
              display={"initial"}
              variant={"body1"}
              children={item?.dosageFreqCode}
              aliasName={"medQunatityTime"}
              style={qdmstyles.prv}
            ></Typography>
            <Box
              id={"dot"}
              m={"1"}
              component={"div"}
              aliasName={"dot"}
              style={qdmstyles.tLCxw}
            ></Box>
            <Avatar
              id={"med_icon"}
              alt={"Avatar"}
              variant={"rounded"}
              aliasName={"medIcon"}
              src={
                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317181939"
              }
              style={qdmstyles.xNxps}
              onClick={(e) => handleClickSchedule(e)}
            ></Avatar>
            <Box
              id={"dot"}
              m={"1"}
              component={"div"}
              aliasName={"dot"}
              style={qdmstyles.tLCxw}
            ></Box>
            {/* <Typography
              noWrap
              id={"med_date_time"}
              align={"inherit"}
              color={"initial"}
              display={"initial"}
              variant={"body1"}
              children={`${moment
                .unix(item?.startdate)
                ?.format("MM-DD-YYYY hh:mm")}`}
              aliasName={"medDateTime"}
              style={qdmstyles.Czb}
            ></Typography> */}
            <TimeComponent
              noWrap
              id={"med_date_time"}
              align={"inherit"}
              color={"initial"}
              display={"initial"}
              variant={"body1"}
              aliasName={"medDateTime"}
              style={qdmstyles.Czb}
              dateTime={item?.startdate}
              formatType="datetime"
            />
          </div>
        </div>
        <div>
          <CustomCheckbox
            checked={isSelected}
            onChange={handleCheckboxChange}
            inputProps={{ "aria-label": "check" }}
          />
        </div>
      </Paper>

      {/* <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Order Details
        </DialogTitle>
        <DialogContent dividers>
          <OrderDetails id={item?._id} />
        </DialogContent>
      </Dialog> */}

      <StyledDialog
        id="Order_Details"
        open={open}
        handleClose={handleClose}
        title={"ORDER DETAILS"}
        dialogProps={{
          classes: dialogStyles,
        }}
        contentProps={{
          classes: dialogContentStyles,
        }}
      >
        <DrugOrderDetails id={item?._id} />
      </StyledDialog>
    </>
  );
}

export default withRouter(translate()(ActiveMedication));
