import { Typography } from "@material-ui/core";
import React from "react";

const MedProfileCard = (props) => {
  const { val, classes } = props;
  return (
    <div>
      <div
        onClick={() => props.onClick(val)}
        style={{
          borderRadius: "8px",
          // border: "1px solid #DEE5EC",
          padding: "8px 12px",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          cursor: "pointer"
        }}
        className={classes.MainBackgroundCard}
      >
        <Typography style={{ fontSize: "14px" }}
          className={classes.Maintitle}>
          {val?.name}
        </Typography>
        <Typography
          style={{ color: val?.color, fontSize: "14px", fontWeight: 600 }}
        >
          {val?.number}
        </Typography>
      </div>
    </div>
  );
};

export default MedProfileCard;
