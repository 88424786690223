import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import * as ALL from "../../components";
import {
  Paper,
  Grid,
  Typography,
  Button,
  Avatar,
  Divider,
  Link,
  makeStyles,
  Modal,
  useTheme,
} from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
import { Autocomplete, RichTextfield } from "../inputs";
import { CreateIcon, TrashIcon } from "../../assets";
import copy from "fast-copy";
import { withAllContexts } from "../../HOCs";
var aes256 = require("aes256");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalPaper: {
    width: 740,
    backgroundColor: theme.palette.background.table,
  },
  instruction: {
    color: theme.palette.text.primary,
  },
  addnew: {
    color: theme.palette.text.light,
  },
  instype: {
    color: theme.palette.primary.light,
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
    "& > .App1-MuiTypography-root": {
      fontSize: 14,
      color: theme.palette.button.background,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    "& > .App1-MuiIconButton-root": {
      background: "rgba(1, 32, 92, 0.1)",
    },
    "& .App1-MuiSvgIcon-root": {
      fontSize: "1.1rem",
    },
  },
  modalHeaderDivider: {
    background: "#DEE5EC",
  },
  modalBodyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    padding: "20px 16px",
  },
  modalInputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    "& > .App1-MuiTypography-root": {
      color: theme.palette.text.tertiary,
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "0px",
      textAlign: "left",
      textTransform: "capitalize",
      margin: "0px",
    },
  },
  modalInput: {
    width: "345px",
  },
  modalActionContainer: {
    display: "flex",
    gap: 8,
    marginLeft: "auto",
  },
  modalSaveAndAdd: {
    "&.App1-MuiButton-contained:hover": {
      backgroundColor: theme.palette.button.hover,
    },
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.background,
    borderRadius: "8px",
  },
  modalAdd: {
    "&.App1-MuiButton-contained:hover": {
      backgroundColor: theme.palette.button.hover,
    },
    color: theme.palette.button.text,
    backgroundColor: theme.palette.button.background,
  },
  instructionValueContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
  commonBackground: {
    backgroundColor: theme.palette.background.table,
  },
}));

const initialInstructionsState = {
  instrType: "",
  instruction: "",
  otrLangInstr: "",
};

function Instructioncomp({ shouldDisable, ...props }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
    showAddNewInstructionModal: false,
    editIndex: "",

    ...copy(initialInstructionsState),

    // Local State
    instrDtls: props?.statevalue,
  });

  const changeState = (key, value) => {
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleModalOpen = (editIndex) => {
    setState({
      ...state,
      showAddNewInstructionModal: true,
      editIndex: null,
    });
    // changeState("showAddNewInstructionModal", true);
    // changeState("editIndex", editIndex?.toString());
  };
  const handleModalClose = () => {
    changeState("showAddNewInstructionModal", false);
  };

  const handleAddNewClick = () => {
    handleModalOpen("");
  };

  const saveNewInstruction = () => {};

  const resetDataState = () => {
    setState((prev) => ({
      ...prev,
      // ...initialInstructionsState,
      instrType: "",
      instruction: "",
      otrLangInstr: "",
    }));
  };

  const handleSaveClick = (editIndex, addAnother) => {
    // // 1. Save
    // saveNewInstruction();

    // // 2. Reset Data
    // // resetDataState();

    // setState({ ...state, ...copy(initialInstructionsState) });

    // // 2 a. Reset edit index
    // // changeState("editIndex", null);

    // // 3. Close the modal if not add Another
    // if (!addAnother) {
    //   handleModalClose();
    // }

    const { instrType, instruction, otrLangInstr } = state;

    if (editIndex) {
      // Take the previous instructions
      let newInstruction = [...state?.instrDtls];

      // Update the index element with new values
      newInstruction[editIndex] = {
        ...newInstruction[editIndex],
        instrType,
        instruction,
        otrLangInstr,
      };

      // Validate Procedure Instructions
      if (props?.isProcedureInstructionsError(newInstruction)) {
        props.alert.setSnack({
          open: true,
          msg: `Please fill Instruction Description or Instruction Local Language field if the instruction type is Procedural Instruction`,
          severity: "error",
        });
        return;
      }

      // Update State
      setState((prev) => ({
        ...prev,
        instrDtls: newInstruction,
        instrType: "",
        instruction: "",
        otrLangInstr: "",
        showAddNewInstructionModal: addAnother,
        editIndex: "",
      }));

      // Save value to parent
      props.setFun(newInstruction);
    } else {
      // Create New Object for add
      let newInstructionState = {
        ...initialInstructionsState,
        instrType,
        instruction,
        otrLangInstr,
      };

      // Append to existing array
      let newInstruction = [...state?.instrDtls, newInstructionState];

      // Validate Procedure Instructions
      if (props?.isProcedureInstructionsError(newInstruction)) {
        props.alert.setSnack({
          open: true,
          msg: `Please fill Instruction Description or Instruction Local Language field if the instruction type is Procedural Instruction`,
          severity: "error",
        });
        return;
      }

      // Update State
      setState((prev) => ({
        ...prev,
        instrDtls: newInstruction,
        instrType: "",
        instruction: "",
        otrLangInstr: "",
        showAddNewInstructionModal: addAnother,
      }));

      // Save value to parent
      props.setFun(newInstruction);
    }
  };

  const handleEditClick = (index) => {
    let newInstructions = [...state?.instrDtls];
    let newInstructionsState = newInstructions[index];

    setState((prev) => ({
      ...prev,
      instrType: newInstructionsState?.instrType,
      instruction: newInstructionsState?.instruction,
      otrLangInstr: newInstructionsState?.otrLangInstr,

      // Open the modal
      showAddNewInstructionModal: true,

      // Set Edit Index
      editIndex: index?.toString(),
    }));

    // handleModalOpen(index);
  };

  const handleDeleteClick = (index) => {
    let newInstructions = [...state?.instrDtls];
    newInstructions.splice(index, 1);
    changeState("instrDtls", newInstructions);

    // Save value to parent
    props.setFun(newInstructions);
  };

  React.useEffect(() => {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {}, [state]);

  const classes = useStyles();
  const theme = useTheme();
  const { data, index, options } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Paper
            key={"0"}
            id={"DlhyF"}
            elevation={"{3}"}
            style={qdmstyles.hoZb}
            className={classes.commonBackground}
          >
            {/* Header */}
            <Grid
              key={"0"}
              id={"TgstR"}
              container={true}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              lg={"auto"}
              item={true}
              style={qdmstyles.qkQU}
            >
              {/* Instruction Head */}
              <Grid
                key={"0"}
                id={"TgstR"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
                lg={"6"}
                xs={"6"}
                xl={"6"}
                sm={"6"}
                md={"6"}
              >
                <Typography
                  key={"0"}
                  id={"instructions_header"}
                  align={"left"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"INSTRUCTIONS"}
                  aliasName={"instructionsheader"}
                  style={qdmstyles.MpX}
                  className={classes.instruction}
                ></Typography>
              </Grid>

              {/* Instruction Add new Button */}
              <Grid
                key={"1"}
                id={"TgstR"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
              >
                <Button
                  key={"0"}
                  id={"instruction_add_new_button"}
                  variant={"text"}
                  disabled={
                    location?.state?.isView === true || !shouldDisable
                      ? true
                      : false
                  }
                  color={"primary"}
                  children={"+ Add New"}
                  aliasName={"instructionaddnewbutton"}
                  style={qdmstyles.gPw}
                  className={classes.addnew}
                  onClick={handleAddNewClick}
                ></Button>
              </Grid>
            </Grid>

            {/* Instruction Card Section Starts */}
            <Grid
              id={"6gUvB"}
              container
              item
              lg={12}
              md={12}
              sm={12}
              xl={12}
              xs={12}
              style={{ rowGap: 10 }}
            >
              {state?.instrDtls?.map((item, index) => {
                return (
                  <Paper
                    key={index}
                    id={"1Cfv0"}
                    elevation={0}
                    style={qdmstyles.QFILH}
                  >
                    {/* Instruction Label Row Starts */}
                    <Grid
                      key={"0"}
                      id={"6gUvB"}
                      container={true}
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      item={true}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      style={qdmstyles.OphvD}
                    >
                      {/* Instruction Label Starts */}
                      <Grid
                        key={"0"}
                        id={"gP71K"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"4"}
                        md={"4"}
                        sm={"4"}
                        xs={"4"}
                        xl={"4"}
                      >
                        <Typography
                          key={"0"}
                          id={"practitioner_instruction_header"}
                          align={"left"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={item?.instrType?.label}
                          aliasName={"practitionerinstructionheader"}
                          style={qdmstyles.HZdY}
                          className={classes.instruction}
                        ></Typography>
                      </Grid>
                      {/* Instruction Label Ends */}

                      {/* Instruction Actions Starts */}
                      <Grid
                        key={"1"}
                        id={"gP71K"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        item={true}
                        lg={"2"}
                        md={"2"}
                        sm={"2"}
                        xs={"2"}
                        xl={"2"}
                      >
                        <Grid
                          key={"0"}
                          id={"Edit_icon2"}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          lg={"5"}
                          md={"5"}
                          sm={"5"}
                          xl={"5"}
                          xs={"5"}
                          aliasName={"editIcon1"}
                          style={qdmstyles.fzmVy}
                        >
                          <ALLMCORE.IconButton
                            onClick={() => handleDeleteClick(index)}
                            disabled={
                              location?.state?.isView === true || !shouldDisable
                                ? true
                                : false
                            }
                          >
                            <TrashIcon />
                          </ALLMCORE.IconButton>
                          <ALLMCORE.IconButton
                            onClick={() => handleEditClick(index)}
                            disabled={
                              location?.state?.isView === true || !shouldDisable
                                ? true
                                : false
                            }
                          >
                            <CreateIcon />
                          </ALLMCORE.IconButton>
                        </Grid>
                      </Grid>
                      {/* Instruction Actions Ends */}
                    </Grid>
                    {/* Instruction Label Row Ends */}

                    <Grid
                      key={"1"}
                      id={"3DIMg"}
                      container={true}
                      direction={"column"}
                      justifyContent={"flex-start"}
                      alignItems={"flex-start"}
                      item={true}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      style={qdmstyles.jiamz}
                    >
                      <Grid
                        key={"0"}
                        id={"roJ0A"}
                        container={true}
                        direction={"column"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                      >
                        {/* Instruction Type Starts */}
                        <Grid
                          key={"0"}
                          id={"kZfj1"}
                          container={true}
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                          item={true}
                        >
                          <Grid
                            key={"0"}
                            id={"kZfj1"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"5"}
                            md={"5"}
                            sm={"5"}
                            xl={"5"}
                            xs={"5"}
                            style={qdmstyles.TMqMD}
                          >
                            <Typography
                              key={"0"}
                              id={"cTAwT"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Instruction Description"}
                              style={qdmstyles.Lqop}
                              className={classes.instype}
                            ></Typography>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"kZfj1"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            item={true}
                            style={qdmstyles.srNfC}
                          >
                            <Divider
                              key={"0"}
                              id={"JxlSx"}
                              variant={"fullWidth"}
                              orientation={"horizontal"}
                              light={""}
                              flexItem={""}
                              absolute={""}
                            ></Divider>
                          </Grid>
                        </Grid>
                        {/* Instruction Type Ends */}

                        {/* Instruction Value Starts */}
                        <Grid
                          key={"1"}
                          id={"roJ0A"}
                          container={true}
                          direction={"column"}
                          justifyContent={"flex-start"}
                          alignItems={"flex-start"}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                          style={qdmstyles.YQQt}
                        >
                          <Typography
                            key={"0"}
                            id={"cTAwT"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            gutterBottom={""}
                            style={{ ...qdmstyles.TFbkV, width: "100%" }}
                          >
                            <div className={classes.instructionValueContainer}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.instruction,
                                }}
                              />
                              <div style={{ alignSelf: "flex-end" }}>
                                {/* <Button
                                  key={"0"}
                                  id={"seemore"}
                                  color={"primary"}
                                  children={"See More"}
                                  style={{
                                    ...qdmstyles.gPw,
                                    lineHeight: "1",
                                    padding: "0px",
                                    textDecoration: "underline",
                                  }}
                                  disableRipple
                                  onClick={() => {}}
                                ></Button> */}
                              </div>
                            </div>
                          </Typography>
                        </Grid>
                        {/* Instruction Value Ends */}
                      </Grid>
                    </Grid>

                    <Grid
                      key={"2"}
                      id={"3DIMg"}
                      container={true}
                      direction={"column"}
                      justifyContent={"flex-start"}
                      alignItems={"flex-start"}
                      item={true}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      style={qdmstyles.JVES}
                    >
                      <Grid
                        key={"0"}
                        id={"kZfj1"}
                        container={true}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                        item={true}
                        style={qdmstyles.XZHC}
                      >
                        <Grid
                          key={"0"}
                          id={"kZfj1"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"5"}
                          md={"5"}
                          sm={"5"}
                          xl={"5"}
                          xs={"5"}
                        >
                          <Typography
                            key={"0"}
                            id={"cTAwT"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Instruction Local Language"}
                            style={qdmstyles.pKjj}
                            className={classes.instype}
                          ></Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"kZfj1"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                        item={true}
                        style={qdmstyles.tUPl}
                      >
                        <Divider
                          key={"0"}
                          id={"JxlSx"}
                          variant={"fullWidth"}
                          orientation={"horizontal"}
                          light={""}
                          flexItem={""}
                          absolute={""}
                        ></Divider>
                      </Grid>
                      <Grid
                        key={"2"}
                        id={"roJ0A"}
                        container={true}
                        direction={"column"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                      >
                        <Grid
                          key={"0"}
                          id={"roJ0A"}
                          container={true}
                          direction={"column"}
                          justifyContent={"flex-start"}
                          alignItems={"flex-start"}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                          style={qdmstyles.aHRsm}
                        >
                          <Typography
                            key={"0"}
                            id={"cTAwT"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            // children={"1. Fermentum Tristique"}
                            gutterBottom={""}
                            style={{ ...qdmstyles.JVGNw, width: "100%" }}
                          >
                            <div className={classes.instructionValueContainer}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.otrLangInstr,
                                }}
                              />
                              <div style={{ alignSelf: "flex-end" }}>
                                {/* <Button
                                  key={"0"}
                                  id={"seemore"}
                                  color={"primary"}
                                  children={"See More"}
                                  style={{
                                    ...qdmstyles.gPw,
                                    lineHeight: "1",
                                    padding: "0px",
                                    textDecoration: "underline",
                                  }}
                                  disableRipple
                                  onClick={() => {}}
                                ></Button> */}
                              </div>
                            </div>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })}
            </Grid>
            {/* Instruction Card Section Ends */}
          </Paper>

          {/* Modal Section Starts */}
          <Modal
            open={state.showAddNewInstructionModal}
            className={classes.modal}
            onClose={handleModalClose}
          >
            <Paper className={classes.modalPaper}>
              <div className={classes.modalHeader}>
                <Typography>Add Instructions</Typography>

                <ALLMCORE.IconButton
                  size="small"
                  disabled={
                    location?.state?.isView === true || !shouldDisable
                      ? true
                      : false
                  }
                  onClick={() =>
                    changeState(
                      "showAddNewInstructionModal",
                      !state.showAddNewInstructionModal
                    )
                  }
                >
                  <CloseRoundedIcon
                    htmlColor={theme.palette.button.background}
                  />
                </ALLMCORE.IconButton>
              </div>

              <Divider className={classes.modalHeaderDivider} />

              <div className={classes.modalBodyContainer}>
                <div className={classes.modalInputContainer}>
                  <Typography className={classes.instype} variant={"body1"}>
                    Instruction Type
                  </Typography>

                  <Autocomplete
                    className={classes.modalInput}
                    disabled={
                      location?.state?.isView === true || !shouldDisable
                        ? true
                        : false
                    }
                    getOptionLabel={(option) => option?.["label"]}
                    options={options?.instruction_type ?? []}
                    value={state?.instrType ?? null}
                    onLoad={() => changeState("instrType", state?.instrType)}
                    onChange={(event, newValue) =>
                      changeState("instrType", newValue)
                    }
                  />
                </div>
                <div className={classes.modalInputContainer}>
                  <Typography className={classes.instype} variant={"body1"}>
                    Instruction Description
                  </Typography>

                  <RichTextfield
                    initialValue={state?.instruction}
                    onChange={(rawValue) =>
                      changeState("instruction", rawValue)
                    }
                  />
                </div>
                <div className={classes.modalInputContainer}>
                  <Typography className={classes.instype} variant={"body1"}>
                    Instruction Local Language
                  </Typography>

                  <RichTextfield
                    initialValue={state?.otrLangInstr}
                    onChange={(rawValue) =>
                      changeState("otrLangInstr", rawValue)
                    }
                  />
                </div>

                <div className={classes.modalActionContainer}>
                  <Button
                    variant={"text"}
                    disabled={
                      location?.state?.isView === true || !shouldDisable
                        ? true
                        : false
                    }
                    // color={"primary"}
                    // children={
                    //   state?.editIndex
                    //     ? "Update & Add another"
                    //     : "Save & Add another"
                    // }
                    style={qdmstyles.Phme}
                    size="small"
                    // onClick={() => handleSaveClick(state?.editIndex, true)}
                    className={classes.modalSaveAndAdd}
                    onClick={() => handleSaveClick(state?.editIndex, true)}
                  >
                    {state?.editIndex
                      ? "Update & Add another"
                      : "Save & Add another"}
                  </Button>
                  <Button
                    variant={"contained"}
                    disabled={
                      location?.state?.isView === true || !shouldDisable
                        ? true
                        : false
                    }
                    color={"primary"}
                    // children={state?.editIndex ? "Update" : "Add"}
                    style={qdmstyles.CPPs}
                    size="small"
                    // onClick={() => handleSaveClick(state?.editIndex, false)}
                    className={classes.modalAdd}
                    onClick={() => handleSaveClick(state?.editIndex, false)}
                  >
                    {state?.editIndex ? "Update" : "Add"}
                  </Button>
                </div>
              </div>
            </Paper>
          </Modal>
          {/* Modal Section Ends */}
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(withAllContexts(translate()(Instructioncomp)));
