import * as React from "react";
import PropTypes from "prop-types";

const Calendar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.5"
    height="12.5"
    viewBox="0 0 12.5 12.5"
  >
    <path
      id="icons8-calendar"
      d="M8.257,6A2.265,2.265,0,0,0,6,8.257v7.986A2.265,2.265,0,0,0,8.257,18.5h7.986A2.265,2.265,0,0,0,18.5,16.243V8.257A2.265,2.265,0,0,0,16.243,6Zm0,1.042h7.986a1.208,1.208,0,0,1,1.215,1.215v.521H7.042V8.257A1.208,1.208,0,0,1,8.257,7.042ZM7.042,9.819H17.458v6.424a1.208,1.208,0,0,1-1.215,1.215H8.257a1.208,1.208,0,0,1-1.215-1.215ZM9.3,11.208a.868.868,0,1,0,.868.868.868.868,0,0,0-.868-.868Zm2.951,0a.868.868,0,1,0,.868.868A.868.868,0,0,0,12.25,11.208Zm2.951,0a.868.868,0,1,0,.868.868A.868.868,0,0,0,15.2,11.208ZM9.3,14.333a.868.868,0,1,0,.868.868A.868.868,0,0,0,9.3,14.333Zm2.951,0a.868.868,0,1,0,.868.868A.868.868,0,0,0,12.25,14.333Z"
      transform="translate(-6 -6)"
      fill={props.color}
    />
  </svg>
);

export default Calendar;

Calendar.defaultProps = {
  color: "#2a3c50",
};

Calendar.propTypes = {
  color: PropTypes.string,
};
