import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Routes } from "./routes";
import { useHistory } from "react-router";

const PrivateRoute = (routeprops) => {
  let { component: Component, path, ...rest } = routeprops;
  const history = useHistory();
  const isAuthenticated = (router) => {
    try {
      const token = localStorage.getItem("token");
      // if (token) {
      //   // dispatch(actions.REFRESH_TOKEN({ token, isOrg: true })).then(userInfo => {
      //   //   localStorage.setItem("token", userInfo.payload?.data || token);
      //   // }).catch(err => {
      //   //   console.log(err);
      //   // }
      //   var myHeaders = { "Content-Type": "text/plain" };
      //   var encoded = token;
      //   var requestOptions = {
      //     method: 'POST',
      //     headers: myHeaders,
      //     data: encoded,
      //     url: `${config.keylock_url}/api/v1/refreshOrgAuthToken`

      //   };
      //   await axios(requestOptions)
      //     .then((res) => {
      //       localStorage.setItem("token", btoa(JSON.stringify(res?.data)) || token);
      //     })
      //     .catch((err) => {
      //       localStorage.removeItem("token")
      //       localStorage.removeItem("role_name")
      //       localStorage.removeItem("role_data")
      //       // localStorage.clear();
      //       history.push({
      //         pathname: "/",
      //       });
      //       return false
      //     })

      // }
      let data = localStorage.getItem("role_data");

      const accessObj =
        JSON.parse(atob(data))?.[localStorage.getItem("role_name")] || [];
      if (accessObj?.accessedRouters?.length === 0) {
        throw new Error("Invalid Permissions");
      }
      return (
        localStorage.getItem("role_name") &&
        accessObj?.accessedRouters.indexOf(path) > -1 &&
        localStorage.token
      );
    } catch (err) {
      localStorage.removeItem("token");
      localStorage.removeItem("role_name");
      localStorage.removeItem("role_data");
      // localStorage.clear();
      history.push({
        pathname: Routes.login,
      });
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: Routes.login,
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
