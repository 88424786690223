import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dot from "../dot";
import { ScheduleIcon } from "../../assets";
import PropTypes from "prop-types";
import SwitchableInputText from "./switchableInputText";
import { format, fromUnixTime, getUnixTime } from "date-fns";
import { CustomaddDays, getFormatForDateTimePicker } from "../../utils";
import TimeComponent from "../timeComponent";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  icon: {
    minWidth: 14,
    cursor: "pointer",
  },
  batchNumber: {
    fontWeight: 500,
  },
  typography: {
    color: theme.palette.text.grey,
    whiteSpace: "nowrap",
  },
  fieldContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
    cursor: "pointer",
    flex: 0,
  },
}));

function AdditionalDescription(props) {
  // <---------------Hooks------------------>
  const classes = useStyles();

  // <---------------Store States----------->

  // <-------------Component States--------->
  const [isStabilityInput, setIsStabilityInput] = useState(false);
  const [stability, setStability] = useState("");
  const [isBatchInput, setIsBatchInput] = useState(false);
  const [batch, setBatch] = useState("");

  // <-----------------Handlers------------->

  // <-----------------Effects-------------->

  // <-----------------Others--------------->

  const typographyProps = {
    variant: "body2",
    // color: "textSecondary",
    className: classes.typography,
  };

  // <-----------------Render--------------->
  const {
    infusionRate,
    infusionTime,
    dosage,
    typeOfInfusion,
    duration,
    occurStartDateTime,
    occurEndDateTime,
    CompoundQuantity,
    CompoundQuantityUnit,
    CompoundStatbility,
    batchDrugStability,
    selectedState,
    onChangeBatch = () => {},
    batchNo,
  } = props;

  return (
    <div className={classes.container}>
      {props.type === "IV" && (
        <>
          <Typography {...typographyProps}>{infusionRate}</Typography>
          <Dot />
          <Typography {...typographyProps}>{infusionTime}</Typography>
          <Dot />
          <Typography {...typographyProps}>{dosage}</Typography>
          <Dot />
          <Typography {...typographyProps}>{typeOfInfusion}</Typography>
          <Dot />
          <Typography {...typographyProps}>{duration}</Typography>
          {/* <Dot />
         <ScheduleIcon className={classes.icon} />  */}
          <Dot />
          <Typography {...typographyProps}>
            <TimeComponent
              dateTime={[
                getUnixTime(new Date(occurStartDateTime)),
                getUnixTime(new Date(occurEndDateTime)),
              ]}
              separator={" - "}
              formatType={"datetime"}
              className={classes.particularText}
            />
          </Typography>
        </>
      )}
      {props.type === "compound" && (
        <>
          {/* <Typography {...typographyProps}>{duration}</Typography>
          <Dot />
          <Typography {...typographyProps}>{dosage}</Typography>
          <Dot /> */}
          <Typography {...typographyProps}>
            {CompoundQuantity}&nbsp;{CompoundQuantityUnit} Quantity
          </Typography>
          <Dot />
          {/* <Typography {...typographyProps}>Stability 10 days</Typography> */}
          <div
            className={classes.fieldContainer}
            onClick={(e) => {
              setIsStabilityInput((prev) => !prev);
            }}
          >
            <Typography {...typographyProps}>Stability</Typography>
            <SwitchableInputText
              isInput={isStabilityInput}
              autoFocus
              id={"stabilityInput"}
              placeholder="00"
              type={"text"}
              //value={batchQuantity}
              value={CompoundStatbility}
              onChange={(e) => {
                setStability(e.target.value);
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              inputProps={{ "aria-label": "Input field" }}
            />

            <Typography {...typographyProps}>days</Typography>
          </div>
          <Dot />
          <Typography {...typographyProps}>
            Expired on &nbsp;
            {selectedState === "verification" ? (
              format(
                CustomaddDays(CompoundStatbility ?? 0),
                getFormatForDateTimePicker("date")
              )
            ) : (
              <>
                {batchDrugStability
                  ? format(
                      fromUnixTime(batchDrugStability),
                      getFormatForDateTimePicker("date")
                    )
                  : ""}
              </>
            )}
          </Typography>
          <Dot />

          <div
            className={classes.fieldContainer}
            onClick={(e) => {
              setIsBatchInput((prev) => !prev);
            }}
          >
            <Typography {...typographyProps}>Batch number</Typography>
            <SwitchableInputText
              isInput={isBatchInput}
              autoFocus
              id={"batchInput"}
              placeholder="B012345"
              type={"text"}
              value={batchNo}
              onChange={(e) => {
                onChangeBatch(e.target.value);
              }}
              inputProps={{ "aria-label": "Input field" }}
              minWidth={80}
              typographyStyles={{
                fontWeight: 500,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AdditionalDescription;

AdditionalDescription.propTypes = {
  type: PropTypes.string,
};
