/* eslint-disable no-unused-expressions */
import React from "react";
import { CommonTabList } from "../../../../../components";
import { FrequencyDefinition } from "./frequencyDefinition";
import { FrequencyDescription } from "./frequencyDescription";
import { Button, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { withAllContexts } from "../../../../../HOCs";
import { valitationFunc } from "../../../../../utils";
const useStyles = makeStyles(() => ({
  btnView: {
    marginTop: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .cancelBtn": {
      borderRadius: 8,
      border: "1px solid #DEDEDE",
      backgroundColor: "transparent",
      boxShadow: "none",
      marginRight: "12px",
      minWidth: 40,
      padding: "5px 15px",
      "& .App1-MuiButton-label": {
        color: "#242424",
        fontSize: 14,
        textTransform: "capitalize",
        letterSpacing: "-0.32px",
      },
    },
    "& .saveBtn": {
      borderRadius: 8,
      border: "1px solid #0071F2",
      backgroundColor: "#0071F2",
      boxShadow: "none",
      minWidth: 40,
      padding: "5px 15px",
      "& .App1-MuiButton-label": {
        color: "#fff",
        fontSize: 14,
        textTransform: "capitalize",
        letterSpacing: "-0.32px",
      },
    },
  },
}));



 const AddNewFrequencyMaster = (props) => {
  const classes = useStyles(props);
  const { parent_id } = props;
  const dispatch = useDispatch();
  function Alert(props) {
    return <MuiAlert
      id={`${parent_id}-newfreq-MuiAlert`}
      elevation={6} variant="filled" {...props} />;
  }
  const [state, setState] = React.useState({
    frequencyDefinition: {
      frequencyCode: "",
      shortDescription: "",
      longDescription: "",
    },
    frequencyDescription: {
      scheduleBy: {},
      doseFrequencyValue: {
        value: "",
        type: {},
      },
      doseFrequencyRateValue: {
        value: "",
        type: {},
      },
      startDay: {},
      startTime: "",
      gracePeriod: "",
      fixedDuration: "",
      fixedDurationType: {},
      frequencyPattern: "",
      frequencyType: {},
      dosageDirection: "",
      firstDone: true,
    },
    error: {},
  });
  const [Alertstate, setAlertState] = React.useState(false);
  const onChangeState = (parentKey, key, value) => {
    if(parentKey === "frequencyDefinition" && key === "frequencyCode") {
      if(value.length > 20){
        return false;
      }
    }
    if(state.error[key]){
      state.error[key]=false
    }
    state[parentKey][key] = value;
    setState({ ...state });
  };
  const resourceFREQUENCY = useSelector((state) => state.drugFrequencySlice);

  React.useLayoutEffect(() => {
    var key = props.data;
    
    const getAIP = async () => {
      await dispatch(
        actions.DRUG_FREQ_READ_SINGLE({
          key,
        })
      );
    };
    if (key) {
      getAIP();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props.data) {
      let getResdata = resourceFREQUENCY?.drug_freq_read_single_yt?.data
        ? resourceFREQUENCY?.drug_freq_read_single_yt?.data
        : "";
      getResdata = JSON.stringify(getResdata);
      getResdata = JSON.parse(getResdata);
      state.frequencyDefinition.frequencyCode =
        getResdata?.frequencyDefinition_?.frequencyCode_ ?? "";
      state.frequencyDefinition.shortDescription =
        getResdata?.frequencyDefinition_?.shortDescription_ ?? "";
      state.frequencyDefinition.longDescription =
        getResdata?.frequencyDefinition_?.longDescription_ ?? "";

      state.frequencyDescription.gracePeriod =
        getResdata?.frequencyDescription_?.gracePeriod_ ?? "";
      state.frequencyDescription.dosageDirection =
        getResdata?.frequencyDescription_?.dosageDirection_ ?? "";
      state.frequencyDescription.fixedDuration =
        getResdata?.frequencyDescription_?.fixedDuration_ ?? "";
      state.frequencyDescription.firstDone =
        getResdata?.frequencyDescription_?.firstDone_ ?? "";
      state.frequencyDescription.frequencyPattern =
        getResdata?.frequencyDescription_?.frequencyPattern_ ?? "";
      state.frequencyDescription.scheduleBy =
        getResdata?.frequencyDescription_?.scheduleBy_ ?? "";

      state.frequencyDescription.startTime =
        getResdata?.frequencyDescription_?.startTime_ ?? "";

      state.frequencyDescription.fixedDurationType =
        getResdata?.frequencyDescription_?.fixedDurationType_ ?? "";

      state.frequencyDescription.fixedDurationType =
        getResdata?.frequencyDescription_?.fixedDurationType_ ?? "";
      state.frequencyDescription.frequencyType =
        getResdata?.frequencyDescription_?.frequencyType_ ?? "";

      state.frequencyDescription.doseFrequencyValue.value =
        getResdata?.frequencyDescription_?.doseFrequencyValue.value ?? "";
      state.frequencyDescription.doseFrequencyValue.type =
        getResdata?.frequencyDescription_?.doseFrequencyValue.type ?? "";

      state.frequencyDescription.doseFrequencyRateValue.value =
        getResdata?.frequencyDescription_?.doseFrequencyRateValue.value ?? "";
      state.frequencyDescription.doseFrequencyRateValue.type =
        getResdata?.frequencyDescription_?.doseFrequencyRateValue.type ?? "";

      state.frequencyDescription.startDay =
        getResdata?.frequencyDescription_?.startDay ?? "";

      setState({
        ...state,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceFREQUENCY?.drug_freq_read_single_yt?.data]);

  const Save = async() => {
    let type;
    let Formstate = {
      ...state.frequencyDefinition,
      ...state.frequencyDescription,
    };
    let list = [
      "frequencyCode",
      // "shortDescription",
      // "longDescription",
      // "scheduleBy",
      // "doseFrequencyValue",
      // "doseFrequencyRateValue",
      // "startDay",
      // "startTime",
      // "gracePeriod",
      // "fixedDuration",
      // "fixedDurationType",
      // "frequencyPattern",
      // "frequencyType",
      // "dosageDirection",
    ];

    let { error, errorState } = await valitationFunc(Formstate, list);

    // if (
    //   state.frequencyDescription?.doseFrequencyValue?.value < 1 ||
    //   !state.frequencyDescription?.doseFrequencyValue?.type?.value
    // ) {
    //   errorState["doseFrequencyValue"] = true;
    //   error=true;
    // } 
    // if (
    //   state.frequencyDescription?.doseFrequencyRateValue?.value < 1 ||
    //   !state.frequencyDescription?.doseFrequencyRateValue?.type?.value
    // ) {
    //   errorState["doseFrequencyRateValue"] = true;
    //    error = true;
    // } 
    //  if (state.frequencyDescription?.gracePeriod < 1) {
    //   errorState["gracePeriod"] = true;
    // }
    //  if (state.frequencyDescription?.fixedDuration < 1) {
    //   errorState["fixedDuration"] = true;
    // }
   
    if (!error) {
      //const getdata = async () => {
      let save = await dispatch(
        actions.UOM_DRUG_FREQ_INSERT({
          data: state,
          key: props.data,
        })
      );

      if (save.payload?.data?.Code === 1111) {
        type = save.payload?.data?.validation_error?.[0]?.Errormsg.includes(
          "unique"
        )
          ? "unique"
          : "error";
        props.alert.setSnack(type);
      } else {
        type = props.data ? "update" : "success";
        props.alert.setSnack(type);
        await dispatch(actions.DRUG_FREQUENCY_READ());
        props?.closeForm();
      }
      //};
      //getdata();
    } else {
      //setAlertState(true);
      props.alert.setSnack("mandatory");
      setState({
        ...state,
        error: errorState,
      });
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertState(false);
  };
  return (
    <div
      id={`${parent_id}-newfreq-parent-div`}
    >
      <Snackbar
        id={`${parent_id}-Snackbar`}
        open={Alertstate}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        <Alert
          parent_id={"alert"}
          severity="error">
          {state.frequencyDescription?.doseFrequencyValue?.value < 1 ||
            state.frequencyDescription?.doseFrequencyRateValue?.value < 1 ||
            state.frequencyDescription?.gracePeriod < 1 ||
            state.frequencyDescription?.fixedDuration < 1
            ? "Please Fill valid Data"
            : "Please Fill all Mandatory Fields"}
        </Alert>
      </Snackbar>
      <CommonTabList
        parent_id={"addnew-freq"}
        backbtn
        backFun={props?.closeForm}
        title={props?.data? "Edit Frequency Master" : "Add New Frequency Master"}
        list={[]}
      />
      <div
        id={`${parent_id}-FrequencyDefinition-div`}
        style={{ padding: 24 }}>
        <FrequencyDefinition
          parent_id={"FrequencyDefinition"}
          data={state.frequencyDefinition}
          onChangeState={(key, value) =>
            onChangeState("frequencyDefinition", key, value)
          }
          isEdit={props?.data??false}
          error={state.error}
        />
        <div
          id={`${parent_id}-FrequencyDescription-div`}
          style={{ marginTop: 16 }}>
          <FrequencyDescription
            parent_id={"FrequencyDescription"}
            data={state.frequencyDescription}
            onChangeState={(key, value) =>
              onChangeState("frequencyDescription", key, value)
            }
             error={state.error}
          />
        </div>
        <div
          id={`${parent_id}-FrequencyDescription-button-div`}
          className={classes.btnView}>
          <Button
            id={`${parent_id}-FrequencyDescription-cancel-button`}
            variant="contained"
            className="cancelBtn"
            onClick={props?.closeForm}
          >
            cancel
          </Button>
          <Button
            id={`${parent_id}-FrequencyDescription-save-button`}
            variant="contained" className="saveBtn" onClick={Save}>
            save
          </Button>
        </div>
      </div>
    </div>
  );
};
 export default withAllContexts(AddNewFrequencyMaster);
