import React from "react";
import { CheckIcon, HomeDeliveryIcon } from "../../../../assets";
import { ReactComponent as ClockIcon } from "../../../../assets/clockIcon.svg";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tag from "./tag";
import classNames from "classnames";
import moment from "moment";
import { Dialog, Popover } from "@material-ui/core";
import { getDayDifference } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: 6,
    flexWrap: "nowrap",
  },
  defaultText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    color: "white",
  },
  defaultActive: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    padding: 6,
    cursor: "pointer",
    borderRadius: 4,
    backgroundColor: theme.palette.background.table,
    color: theme.palette.primary.main,
  },
  default: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    padding: 6,
    cursor: "pointer",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.background.table,
      color: theme.palette.text.primary,
    },
  },
  boldText: {
    fontWeight: 600,
  },
  totalRequiredText: {
    color: "#2A60BC",
  },
  defaultTitle: {
    fontSize: 14,
    fontWeight: 600,
    padding: 6,
  },
  paper: {
    boxShadow: "0px 10px 25px #0000000a",
    background: theme.palette.background.table,
    color: theme.palette.text.primary,
  },
}));

function InfoTags({
  totalAmount,
  totalRequiredDose,
  balanceRequiredDose,
  nextrefilldate,
  occurEndDateTime,
  occurStartDateTime,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const getDayDiff = (date1, date2) => {
  //   let dayStart;
  //   let data = JSON.parse(atob(localStorage.getItem("userInfoKeys")));
  //   let lateArrival =
  //     data?.location?.locconfig?.find(
  //       (val) => val?.code === "considerlatearrival"
  //     )?.value === "false"
  //       ? false
  //       : true;

  //   if (lateArrival) {
  //     dayStart = moment(date1).startOf("day");
  //   } else {
  //     let currentDate = moment()
  //       .set({
  //         hour: 0,
  //         minutes: 0,
  //       })
  //       .unix();
  //     dayStart = moment.unix(currentDate).startOf("day");
  //   }

  //   const dayEnd = moment(date2).startOf("day");
  //   return dayEnd.diff(dayStart, "days");
  // };

  return (
    <div className={classes.root}>
      {/* Hidden Refill & Pending tags as per Designer Decision. */}
      {/* Refill Tag */}
      {nextrefilldate > 0 && (
        <Tag>
          <Typography className={classes.defaultText}>
            Refill on&nbsp;
            <span className={classes.boldText}>
              {moment(nextrefilldate * 1000).format("DD MMM, YYYY")}
            </span>
          </Typography>
        </Tag>
      )}

      {/* Pending Duration Tag */}
      {nextrefilldate > 0 && (
        <Tag>
          <Typography className={classes.defaultText}>
            Pending Duration&nbsp;
            <span className={classes.boldText}>
              {
                getDayDifference(nextrefilldate * 1000, occurEndDateTime * 1000)
                // + 1
              }
            </span>
          </Typography>
        </Tag>
      )}

      {/* Total Required Tag */}
      {!!totalAmount && (
        <Tag backgroundColor={"#01205C"}>
          <Typography className={classes.defaultText}>
            Total Amount&nbsp;$&nbsp;
            <span className={classes.boldText}>{totalAmount}</span>
          </Typography>
        </Tag>
      )}

      {/* Total Required Tag */}
      {!!totalRequiredDose && (
        <Tag backgroundColor={"#DEE5EC"}>
          <Typography
            className={classNames(
              classes.defaultText,
              classes.totalRequiredText
            )}
          >
            Total Required&nbsp;
            <span className={classes.boldText}>
              {totalRequiredDose?.toString()?.padStart(2, "0")}
            </span>
          </Typography>
        </Tag>
      )}

      {/* Balance Required Tag */}
      {balanceRequiredDose && (
        <Tag backgroundColor={"#01205C"}>
          <Typography className={classes.defaultText}>
            Balance Required&nbsp;
            <span className={classes.boldText}>
              {balanceRequiredDose?.toString()?.padStart(2, "0")}
            </span>
          </Typography>
        </Tag>
      )}

      {props?.drug?.disptype?.code && (
        <Tag
          onClick={(e) => handleClick(e)}
          backgroundColor={theme.palette.primary.main}
        >
          <Typography className={classes.defaultText}>
            <span className={classes.boldText}>
              {props?.drug?.disptype?.display}
            </span>
          </Typography>
        </Tag>
      )}

      {/* Total Amount   Required Tag */}
      {props?.drug?.totAmt && (
        <Tag backgroundColor={"#01205C"}>
          <Typography className={classes.defaultText}>
            Total Amount&nbsp;$&nbsp;
            <span className={classes.boldText}>{props?.drug?.totAmt}</span>
          </Typography>
        </Tag>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        <div style={{ padding: 10 }}>
          <Typography className={classes.defaultTitle}>
            {"Dispense Type"}
          </Typography>

          {props?.drug?.alloweddisptype?.map((v) => {
            return (
              <Typography
                className={
                  v?.code ===
                    (props?.drug?.disptype?.code || props?.drug?.disptype)
                    ? classes.defaultActive
                    : classes.default
                }
                onClick={() => props.handleDoseType(v)}
              >
                {v?.code}
              </Typography>
            );
          })}
        </div>
      </Popover>
    </div>
  );
}

export default InfoTags;
