import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  TextField,
} from "@material-ui/core";
import { SelectBox } from "../../../../../../components/common/smartForm/component";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    padding: 20,
    background:theme.palette.background.table,
    borderRadius: "0px 0px 8px 8px",
    "& .App1-MuiOutlinedInput-root": {
      borderRadius: 8,
    },
    "& .Mui-disabled": {
      background: "#f0f0f0",
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {},
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  textfieldGrid: {
    marginTop: "4px",
  },
  titleGrid: {
    padding: "14px !important",
  },
}));

function SpecimenDetails(props) {
  const classes = useStyles();
  const storeData = useSelector((state) => state?.orderCatalogSlice);
  const {
    SpecimenType,

    Volume,
    VolumeQuantity,

    BodyType,

    FastingPeriod,
    FastingPeriodQuantity,

    SpecimenContainer,

    CollectionMethod,
    handleFormState,
    parent_id,
    error
  } = props;
  return (
    <React.Fragment>
      <Paper id={`${parent_id}_parent_paper`} className={classes.root}>
        <Grid
          id={`${parent_id}_parent_container`}
          container
          className={classes.form}
          spacing={2}
        >
          <Grid id={`${parent_id}_Specimen_grid`} item xs={3}>
            <Typography
              id={`${parent_id}_Specimen_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Specimen Type 
              {/* <span style={{ color: "red" }}>*</span> */}
            </Typography>
            <Grid id={`${parent_id}_Specimen_grid`} container spacing={2}>
              <Grid id={`${parent_id}_Specimen_div`} item xs={12}>
                <SelectBox
                  parent_id={`${parent_id}_SpecimenType`}
                  list={
                    storeData?.order_catalog_masters?.data?.specimenType ?? []
                  }
                  placeholder={"Select"}
                  value={SpecimenType}
                  onchange={(e, value) =>
                    handleFormState("SpecimenType", value)
                  }
                  error={error?.SpecimenType}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid id={`${parent_id}_Volume_grid`} item xs={4}>
            <Typography
              id={`${parent_id}_Volume_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Volume 
              {/* <span style={{ color: "red" }}>*</span> */}
            </Typography>
            <Grid id={`${parent_id}_Volume_grid`} container spacing={1}>
              <Grid
                id={`${parent_id}_VolumeQuantity_grid`}
                className={classes.textfieldGrid}
                item
                xs={3}
              >
                <TextField
                  id={`${parent_id}_VolumeQuantity_textField`}
                  value={VolumeQuantity}
                  onChange={(e) =>
                    handleFormState("VolumeQuantity", e.target.value)
                  }
                  fullWidth
                  type="number"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  error={error?.VolumeQuantity}
                />
              </Grid>
              <Grid id={`${parent_id}_VolumeQuantity_select_div`} item xs={9}>
                <SelectBox
                  parent_id={`${parent_id}_VolumeQuantity`}
                  list={storeData?.order_catalog_masters?.data?.quantity ?? []}
                  placeholder={"Select"}
                  value={Volume}
                  onchange={(e, value) => handleFormState("Volume", value)}
                  error={error?.Volume}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid id={`${parent_id}_Body_grid`} item xs={5}>
            <Typography
              id={`${parent_id}_Body_typography`}
              style={{ marginLeft: "10px" }}
              className={classes.formtitle}
              variant="body1"
            >
              Body Site
            </Typography>
            <Grid id={`${parent_id}_BodyType_grid`} container spacing={2}>
              <Grid item xs={10}>
                <SelectBox
                  parent_id={`${parent_id}_BodyType`}
                  list={storeData?.order_catalog_masters?.data?.bodySite ?? []}
                  placeholder={"Select"}
                  value={BodyType}
                  onchange={(e, value) => handleFormState("BodyType", value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          id={`${parent_id}_Fasting_grid`}
          container
          className={classes.form}
          spacing={2}
        >
          <Grid id={`${parent_id}_Fasting_title_grid`} item xs={4}>
            <Typography
              id={`${parent_id}_Fasting_title_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Fasting Period
            </Typography>
            <Grid
              id={`${parent_id}_Fasting_container_grid`}
              container
              spacing={1}
            >
              <Grid
                id={`${parent_id}_Fasting_textField_grid`}
                className={classes.textfieldGrid}
                item
                xs={3}
              >
                <TextField
                  id={`${parent_id}_Fasting_textField`}
                  value={FastingPeriodQuantity}
                  onChange={(e) =>
                    handleFormState("FastingPeriodQuantity", e.target.value)
                  }
                  fullWidth
                  type="number"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                />
              </Grid>
              <Grid id={`${parent_id}_Fasting_select_grid`} item xs={9}>
                <SelectBox
                  parent_id={`${parent_id}_fasting_period`}
                  list={storeData?.order_catalog_masters?.data?.quantity ?? []}
                  placeholder={"Select"}
                  value={FastingPeriod}
                  onchange={(e, value) =>
                    handleFormState("FastingPeriod", value)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid id={`${parent_id}_SpecimenContainer_grid`} item xs={3}>
            <Typography
              id={`${parent_id}_SpecimenContainer_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Specimen Container 
              {/* <span style={{ color: "red" }}>*</span> */}
            </Typography>
            <Grid
              id={`${parent_id}_SpecimenContainer_grid`}
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <SelectBox
                  parent_id={`${parent_id}_SpecimenContainer`}
                  list={
                    storeData?.order_catalog_masters?.data?.specimenContainer ??
                    []
                  }
                  placeholder={"Select"}
                  value={SpecimenContainer}
                  onchange={(e, value) =>
                    handleFormState("SpecimenContainer", value)
                  }
                  error={error?.SpecimenContainer}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid id={`${parent_id}_Collection_grid`} item xs={5}>
            <Typography
              id={`${parent_id}_Collection_typography`}
              style={{ marginLeft: "10px" }}
              className={classes.formtitle}
              variant="body1"
            >
              Collection Method
            </Typography>
            <Grid id={`${parent_id}_Collection_grid`} container spacing={2}>
              <Grid item xs={10}>
                <SelectBox
                  parent_id={`${parent_id}_CollectionMethod`}
                  list={
                    storeData?.order_catalog_masters?.data?.collectionMethod ??
                    []
                  }
                  placeholder={"Select"}
                  value={CollectionMethod}
                  onchange={(e, value) =>
                    handleFormState("CollectionMethod", value)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default SpecimenDetails;
