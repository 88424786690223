import React from "react";
import { Paper, Typography, makeStyles, Grid, Button, TextField } from "@material-ui/core";
import { SelectBox } from "../../../../../../components/common/smartForm/component";
import { RuleSet } from "../../../../../../components"
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    padding: 20,
    borderRadius: 8,
    "& .App1-MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  addRule: {
    borderColor: "#ff6384",
    color: "#ff6384",
    margin: "15px 0px 0px",
    padding: "8px 16px",
    fontFamily: "poppin",
    fontSize: "12px",
    textTransform: "none",
    borderRadius: "8px",
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
  btn: {
    padding: "8px 16px",
    fontFamily: "poppin",
    fontSize: "12px",
    textTransform: "none",
    margin: "3px 10px 15px 0px",
    borderRadius: "8px",
  },
  btnErr: {
    padding: "8px 16px",
    fontFamily: "poppin",
    fontSize: "12px",
    textTransform: "none",
    margin: "3px 10px 15px 0px",
    borderRadius: "8px",
    border: "1px solid red"
  },
  lowerdiv: {
    border: "1px solid #DCDCDC",
    borderRadius: "8px",
    padding: "15px",
    marginBottom: "18px",
  },
  slectedbtn: {
    backgroundColor: "#0071F2",
    color: "#fff",
    padding: "8px 16px",
    fontFamily: "poppin",
    fontSize: "12px",
    textTransform: "none",
    borderRadius: "8px",
    margin: "3px 10px 15px 0px",
    "&:hover": {
      background: "#0071F2",
    },
  },
}));

function LoationAlerts(props) {
  const classes = useStyles();
  const {
    ruleType, handleFormState, alertText, PrivilegeType,
    ServiceApplicabiliy, orderingFacility, parent_id, error,
    orderCriteria, patientCriteria, practitionerCriteria
  } = props;

  const getPrivillage = (arr, value) => {
    if (value) {
      let type = value?.toLocaleLowerCase()?.includes("alert") ? 'PTA' : 'PTR';
      let data = arr.filter(val => val?.text?.includes(type))
      return data
    } else {
      return arr
    }
  }
  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}-parent-paper`}
        className={classes.root}>
        <Typography
          id={`${parent_id}-PEFORMING-typography`}
          className={classes.title} variant="h6">
          PEFORMING LOCATION
        </Typography>
        <Grid
          id={`${parent_id}-Facility-parent-grid`}
          container className={classes.form}>
          <Grid
            id={`${parent_id}-Facility-grid`}
            md="6" lg="6" className={classes.formParent}>
            <Typography
              id={`${parent_id}-Facility-label-typography`}
              className={classes.formtitle} variant="body1">
              Facility <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={"locationalerts"}
              list={props?.allMaster?.facility ?? []}
              placeholder={"Select"}
              value={orderingFacility}
              onchange={(e, value) => handleFormState("orderingFacility", value)}
              error={error?.orderingFacility}
            />
          </Grid>
          <Grid
            id={`${parent_id}-Service-label-grid`}
            md="4" lg="4" className={classes.formParent}>
            <Typography
              id={`${parent_id}-Service-label-typography`}
              className={classes.formtitle} variant="body1">
              Service Applicability <span style={{ color: "red" }}>*</span>
            </Typography>
            {/* {JSON.stringify(props?.allMaster?.service_applicables)} */}
            {props?.allMaster?.service_applicables?.map((v, i) => {
              return <Button
                id={`${parent_id}-${i}-ServiceApplicabiliy-button`}
                variant="outlined"
                onClick={(e) => handleFormState("ServiceApplicabiliy", v)}
                className={
                  ServiceApplicabiliy?.value === v?.value ? classes.slectedbtn : error?.ServiceApplicabiliy ? classes.btnErr : classes.btn
                }
              >
                {v?.value}
              </Button>
            })}
            {/* <Button
              variant="outlined"
              onClick={(e) => handleFormState("ServiceApplicabiliy", e.target.innerText)}
              className={
                ServiceApplicabiliy === "Service Order" ? classes.slectedbtn : classes.btn
              }

            >
              Service Order
            </Button> */}
            {/* <Button
              id={`${parent_id}-Medication-button`}
              variant="outlined"
              onClick={(e) => handleFormState("ServiceApplicabiliy", e.target.innerText)}
              className={
                ServiceApplicabiliy === "Medication" ? classes.slectedbtn : classes.btn
              }
            >
              Medication
            </Button> */}
          </Grid>
        </Grid>
        <Grid
          id={`${parent_id}-ORDER-CRITERIA-grid`}
          className={classes.lowerdiv}>
          <Typography
            id={`${parent_id}-ORDER-CRITERIA-typography`}
            className={classes.title} variant="h6">
            ORDER CRITERIA
          </Typography>
          {/* <Button
            id={`${parent_id}-Add-New-Rule-Set-button`}
            variant="outlined"
            className={classes.addRule}
          >
            + Add New Rule Set
          </Button> */}
          <RuleSet
            parent_id={`${parent_id}-Add-New-Rule-Set-button`}
            stateData={orderCriteria} setState={(data) => handleFormState("orderCriteria", data)}
          />
        </Grid>
        <Grid
          id={`${parent_id}-PATIENT-CRITERIA-grid`}
          className={classes.lowerdiv}>
          <Typography
            id={`${parent_id}-PATIENT-CRITERIA-typography`}
            className={classes.title} variant="h6">
            PATIENT CRITERIA
          </Typography>
          {/* <Button
            id={`${parent_id}-PATIENT-Rule-Set-button`}
            variant="outlined"
            className={classes.addRule}
          >
            + Add New Rule Set
          </Button> */}
          <RuleSet
            parent_id={`${parent_id}-PATIENT-Rule-Set-button`}
            stateData={patientCriteria} setState={(data) => handleFormState("patientCriteria", data)}
          />
        </Grid>
        <Grid
          id={`${parent_id}-PRACTITIONER-grid`}
          className={classes.lowerdiv}>
          <Typography
            id={`${parent_id}-PRACTITIONER-typography`}
            className={classes.title} variant="h6">
            PRACTITIONER CRITERIA
          </Typography>
          {/* <Button
            id={`${parent_id}-PRACTITIONER-Rule-Set-button`}
            variant="outlined"
            className={classes.addRule}
          >
            + Add New Rule Set
          </Button> */}
          <RuleSet
            parent_id={`${parent_id}-PRACTITIONER-Rule-Set-button`}
            stateData={practitionerCriteria} setState={(data) => handleFormState("practitionerCriteria", data)}
          />
        </Grid>
        <Grid
          id={`${parent_id}-parent-container`}
          container>
          <Grid
            id={`${parent_id}-Rule-Type-grid`}
            md="2" lg="2">
            <Typography
              id={`${parent_id}-Rule-Type-typography`}
              className={classes.formtitle} variant="body1">
              Rule Type <span style={{ color: "red" }}>*</span>
            </Typography>
            {props?.allMaster?.rule_type_datas?.map((v, i) => {
              return <Button
                id={`${parent_id}-${i}-Rule-Type-${v?.value.replaceAll(" ", "-")}-button`}
                variant="outlined"
                onClick={(e) => handleFormState("ruleType", v)}
                className={
                  ruleType?.value === v?.value ? classes.slectedbtn : error?.ruleType ? classes.btnErr : classes.btn
                }
              >
                {v?.value}
              </Button>
            })}
            {/* <Button
              variant="outlined"
              onClick={(e) => handleFormState("ruleType", e.target.innerText)}
              className={
                ruleType === "Restriction" ? classes.slectedbtn : classes.btn
              }
            >
              Restriction
            </Button>
            <Button
              variant="outlined"
              onClick={(e) => handleFormState("ruleType", e.target.innerText)}
              className={
                ruleType === "Alert" ? classes.slectedbtn : classes.btn
              }
            >
              Alert
            </Button> */}
          </Grid>
          <Grid
            id={`${parent_id}-Privilege-Type-grid`}
            md="10" lg="10">
            <Typography
              id={`${parent_id}-Privilege-Type-typography`}
              className={classes.formtitle} variant="body1">
              Privilege Type <span style={{ color: "red" }}>*</span>
            </Typography>

            {getPrivillage(props?.allMaster?.privillage_type_datas, ruleType?.value)?.map((v, i) => {
              return <Button
                id={`${parent_id}-${i}-Privilege-Type-${v?.value.replaceAll(" ", "-")}-button`}
                variant="outlined"
                onClick={(e) => handleFormState("PrivilegeType", v)}
                className={
                  PrivilegeType?.value === v?.value ? classes.slectedbtn : error?.PrivilegeType ? classes.btnErr : classes.btn
                }
              >
                {v?.value}
              </Button>
            })}
            {/* <Button
              variant="outlined"
              onClick={(e) => handleFormState("PrivilegeType", e.target.innerText)}
              className={
                PrivilegeType === "No Access" ? classes.slectedbtn : classes.btn
              }
            >
              No Access
            </Button>
            <Button
              variant="outlined"
              onClick={(e) => handleFormState("PrivilegeType", e.target.innerText)}
              className={
                PrivilegeType === "Approval Required" ? classes.slectedbtn : classes.btn
              }
            >
              Approval Required
            </Button>
            <Button
              variant="outlined"
              onClick={(e) => handleFormState("PrivilegeType", e.target.innerText)}
              className={
                PrivilegeType === "Overridden Access" ? classes.slectedbtn : classes.btn
              }
            >
              Overridden Access
            </Button> */}
          </Grid>
        </Grid>
        {ruleType?.value?.toLocaleLowerCase()?.includes("alert") ?
          <Grid
            id={`${parent_id}-Alert-Text-grid`}
            className={classes.formParent}>
            <Typography
              id={`${parent_id}-Alert-Text-label-typography`}
              className={classes.formtitle} variant="body1">
              Alert Text Message
            </Typography>
            <TextField
              id={`${parent_id}-Alert-Text-label-textField`}
              value={alertText}
              onChange={(e) => handleFormState("alertText", e.target.value)}
              placeholder="Type here"
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              multiline
              rows="3"
            />
          </Grid>
          : null}
      </Paper>
    </React.Fragment>
  );
}

export default LoationAlerts;