/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react";
import { CommonTable } from "../../../../../components";

function ServiceDrugCatalogTable(props) {
  const [tableData_, setTableData] = React.useState([]);

  useEffect(() => {
    getTabelData();
  }, [props?.tableData]);
  const getTabelData = () => {
    let arr = [];
    if (props?.tableData?.length > 0) {
      props?.tableData?.map((val) => {
        if (props.id === 0) {
          arr.push(val);
        } else {
          if (val?.typeId === props.value) {
            arr.push(val);
          }
        }
      });
    }
    setTableData(arr);
  };
  const handleEdit = (e, data, index) => {
    props.handleEditModal(
      data.orderCategory?.label,
      data.orderTypeDrugType?.label,
      data.seqNo
    );
  };

  const handleDelete = (e, data, index) => {
    props?.deleteTableRow(data?.seqNo);
  };
  const collapseCondition = (value, type, index, parentIndex) => {
    if (type === "EDIT") {
      return false;
    } else if (type === "DELETE") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <React.Fragment>
      {props.id === 0 ? (
        <CommonTable
          parent_id={"consult_master"}
          rightAlign={["Status", "Action"]}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          collapseCondition={collapseCondition}
          Header={[
            "Seq No.",
            "Order Category",
            "Order Type / Drug Type",
            "Order Catalog",
            "Required",
            "Quantity",
            "Start day",
            "Duration",
            "Route",
            "Action",
          ]}
          dataList={tableData_ ?? []}
          tableData={[
            { type: ["TEXT"], name: "seqNo" },
            { type: ["TEXT"], name: "orderCategory", optionLabel: "label" },
            {
              type: ["TEXT"],
              name: "orderTypeDrugType",
              optionLabel: "label",
            },
            { type: ["TEXT"], name: "orderCatalog", optionLabel: "label" },
            { type: ["TEXT"], name: "requiredT" },
            { type: ["TEXT"], name: "quantityT" },
            { type: ["TEXT"], name: "startDay" },
            { type: ["TEXT"], name: "durationT" },
            { type: ["TEXT"], name: "routeT" },
            { type: ["EDIT", "DELETE", "COLLAPSE"], name: "", align: "right" },
          ]}
        />
      ) : props.id === 1 ? (
        <CommonTable
          parent_id={"consult_master"}
          rightAlign={["Status", "Action"]}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          Header={[
            "Seq No.",
            "Order Type",
            "Order Catalog",
            "Required",
            "Quantity",
            "Start day",
            "Duration",
            "Frequency",
            "Rate",
            "Action",
          ]}
          dataList={tableData_ ?? []}
          tableData={[
            { type: ["TEXT"], name: "seqNo" },
            {
              type: ["TEXT"],
              name: "orderTypeDrugType",
              optionLabel: "label",
            },
            { type: ["TEXT"], name: "orderCatalog", optionLabel: "label" },
            { type: ["TEXT"], name: "requiredT" },
            { type: ["TEXT"], name: "quantityT" },
            { type: ["TEXT"], name: "startDay" },
            { type: ["TEXT"], name: "durationT" },
            { type: ["TEXT"], name: "frequencyT" },
            { type: ["TEXT"], name: "rateT" },
            { type: ["EDIT", "DELETE"], name: "", align: "right" },
          ]}
        />
      ) : (
        <CommonTable
          parent_id={"consult_master"}
          rightAlign={["Status", "Action"]}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          collapseCondition={collapseCondition}
          Header={[
            "Seq No.",
            "Drug Type",
            "Order Catalog",
            "Additive",
            "Parent sequence",
            "Required",
            "Quantity",
            "Start day",
            "Duration",
            "Route",
            "Action",
          ]}
          dataList={tableData_ ?? []}
          tableData={[
            { type: ["TEXT"], name: "seqNo" },
            {
              type: ["TEXT"],
              name: "orderTypeDrugType",
              optionLabel: "label",
            },
            { type: ["TEXT"], name: "orderCatalog", optionLabel: "label" },
            { type: ["TEXT"], name: "additivT" },
            { type: ["TEXT"], name: "parentSequenceT" },
            { type: ["TEXT"], name: "requiredT" },
            { type: ["TEXT"], name: "quantityT" },
            { type: ["TEXT"], name: "startDay" },
            { type: ["TEXT"], name: "durationT" },
            { type: ["TEXT"], name: "routeT" },
            { type: ["EDIT", "DELETE", "COLLAPSE"], name: "", align: "right" },
          ]}
        />
      )}
    </React.Fragment>
  );
}
export default ServiceDrugCatalogTable;
