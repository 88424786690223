import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Avatar,
  IconButton,
} from "@material-ui/core";

// import { Autocomplete } from "@material-ui/lab";
// import qdmstyles from "./styles.json";
// import "./index.css";
import qdmstyles from "./styles.json";
import "./index.css";
import { InputBase, Autocomplete } from "../inputs";
import { TrashIcon } from "../../assets";
import Color from "../../themes/default.json"
import { makeStyles } from "@material-ui/core/styles";

var aes256 = require("aes256");

const useStyles = makeStyles((theme) => ({
  alias: {
    color: theme.palette.text.primary
  },
  source: {
    color: theme.palette.primary.light
  },
  commonBackground: {
    backgroundColor: theme.palette.background.table
  }

}));

function Aliasmappingcomp({ shouldDisable, ...props }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  const {
    data,
    index,
    options,
    statevalue = [],
    stateName,
    onAddNewClick = () => { },
    onDeleteClick = () => { },
    setFun = () => { },
  } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"76GZv"}
            container={""}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item={true}
            lg={"12"}
            md={"12"}
            sm={"12"}
            xl={"12"}
            xs={"12"}
          >
            <Paper
              key={"0"}
              id={"fxDUL"}
              elevation={"{3}"}
              style={qdmstyles.rgfGG}
              className={classes.commonBackground}
            >
              <Grid
                key={"0"}
                id={"OXKhr"}
                container={true}
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                item={""}
              >
                {/* Header Starts */}
                <Grid
                  key={"0"}
                  id={"TgstR"}
                  container={true}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  lg={"auto"}
                  style={qdmstyles.xenT}
                >
                  <Grid
                    key={"0"}
                    id={"TgstR"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                    lg={"6"}
                    md={"6"}
                    sm={"6"}
                    xl={"6"}
                    xs={"6"}
                  >
                    <Typography
                      key={"0"}
                      id={"aliasmapping_Typography"}
                      align={"left"}
                      color={"initial"}
                      display={"initial"}
                      className={classes.alias}
                      variant={"body1"}
                      children={"ALIAS MAPPING"}
                      aliasName={"aliasmappingTypography"}
                      style={qdmstyles.IeWX}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"AliasmappingAddNewButton_button"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                    aliasName={"AliasmappingAddNewButton"}
                    lg={"6"}
                    md={"6"}
                    sm={"6"}
                    xl={"6"}
                    xs={"6"}
                    style={qdmstyles.pQx}
                  >
                    <Button
                      key={"0"}
                      id={"alias_mapping_add_new_button"}
                      variant={"text"}
                      disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                      color={"primary"}
                      children={"+ Add New"}
                      aliasName={"aliasmappingaddnewbutton"}
                      style={{ ...qdmstyles.MRw, color: Color.palette.text.light }}
                      onClick={() => onAddNewClick(stateName)}
                    ></Button>
                  </Grid>
                </Grid>
                {/* Header Ends */}

                {/* Body Starts */}
                <Grid
                  key={"1"}
                  id={"0PNTj"}
                  container={true}
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item={true}
                  wrap={"wrap"}
                  spacing={""}
                  lg={"12"}
                  md={"12"}
                  sm={"12"}
                  xl={"12"}
                  xs={"12"}
                >
                  <Grid
                    key={"0"}
                    id={"7uXWP"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"baseline"}
                    lg={"12"}
                    item={true}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    spacing={1}
                  >
                    {(Array.isArray(statevalue) && statevalue?.length > 0) && <Grid container item xs={12}>
                      <Grid item xs={12} sm={6} md={2} lg={4}>
                        <Typography
                          key={"0"}
                          id={"sourceHeading_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          className={classes.source}
                          children={"Source"}
                          aliasName={"sourceHeading"}
                          style={qdmstyles.AQij}
                        ></Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2}>
                        <Typography
                          key={"0"}
                          id={"codeHeading_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          className={classes.source}
                          children={"Code"}
                          aliasName={"codeHeading"}
                          style={qdmstyles.VKjZ}
                        ></Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={7} lg={6}>
                        <Typography
                          key={"0"}
                          id={"Description_Typography"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Description"}
                          className={classes.source}
                          aliasName={"DescriptionTypography"}
                          style={qdmstyles.ZLyM}
                        ></Typography>
                      </Grid>
                    </Grid>}

                    {Array.isArray(statevalue) &&
                      statevalue?.map((item, index) => {
                        const rowState = statevalue?.[index];
                        return (
                          <Grid container item xs={12} spacing={1}>
                            <Grid item xs={12} sm={6} md={2} lg={4}>
                              <Autocomplete
                                id={"kXfMY"}
                                style={qdmstyles?.kXfMY}
                                disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                                getOptionLabel={(option) => option?.["label"]}
                                options={options?.source ?? []}
                                size={"small"}
                                placeholder="label"
                                onLoad={() =>
                                  setFun(
                                    stateName,
                                    "aliasType",
                                    rowState?.aliasType,
                                    index
                                  )
                                }
                                value={rowState?.aliasType ?? null}
                                onChange={(event, newValue) =>
                                  setFun(
                                    stateName,
                                    "aliasType",
                                    newValue,
                                    index
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                              <InputBase
                                key={"0"}
                                id={"code_autocomplete"}
                                label={""}
                                placeholder={""}
                                disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                                type={"text"}
                                variant={"outlined"}
                                size={"small"}
                                fullWidth={true}
                                aliasName={"codeAutocomplete"}
                                inputProps={{
                                  maxlength: 15,
                                }}
                                value={rowState?.aliasCode ?? ''}
                                onChange={(event) =>
                                  setFun(
                                    stateName,
                                    "aliasCode",
                                    event.target.value,
                                    index
                                  )
                                }
                              ></InputBase>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              sm={6}
                              md={7}
                              lg={6}
                              spacing={1}
                            >
                              <Grid item xs>
                                <InputBase
                                  key={"0"}
                                  id={"description_textfield1"}
                                  label={""}
                                  placeholder={""}
                                  type={"text"}
                                  disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                                  size={"small"}
                                  variant={"outlined"}
                                  fullWidth={true}
                                  aliasName={"descriptiontextfield1"}
                                  inputProps={{
                                    maxlength: 30,
                                  }}
                                  value={rowState?.aliasName ?? ''}
                                  onChange={(event) =>
                                    setFun(
                                      stateName,
                                      "aliasName",
                                      event.target.value,
                                      index
                                    )
                                  }
                                ></InputBase>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                                  onClick={() =>
                                    onDeleteClick(stateName, index)
                                  }
                                >
                                  <TrashIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Aliasmappingcomp));
