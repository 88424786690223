import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { NamespacesConsumer, translate } from "react-i18next";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Logout, qdmApplicationLogger } from "../../utils/helperFunctions";
import "./index.css";
import { Image } from "qdm-component-library";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";
import LocationRoles from "../../components/locationAndRoles/locationRoles";
import { useDispatch } from "react-redux";
import Config from "../../config";
import { actions } from "frequencyscreen_v_dbinder";
import md5 from "md5";
import { UserInfo, userInfoKeys, AlertProps } from "../../utils";
import jsonwebtoken from "jsonwebtoken";
import { basicRouters } from "../../router/routes";
import { withAllContexts } from "../../HOCs";
import { MoonIcon, SunIcon, ThemesIcon, TickIcon } from "../../assets";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // height: "100%",
    flex: 1,
    backgroundColor: theme.palette.background.common,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.appBar.main,
    color: theme.palette.neutral.contrastText,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerTheme: {
    width: 260,
  },
  drawerThemePaper: {
    width: 260,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
    backgroundColor: theme.sideBar.background.color,
  },
  userContent: {
    marginRight: "3%",
  },
  HrTag: {
    marginRight: 18,
    marginLeft: 18,
    height: 28,
    backgroundColor: "white",
    width: 1,
  },
  TitleName: {
    fontWeight: "bold",
    letterSpacing: 1.5,
  },
  Avatar: {
    marginRight: "2%",
  },
  Icons: {
    minWidth: 34,
    justifyContent: "center",
    paddingRight: 6,
  },
  content: {
    flexGrow: 1,
    marginLeft: drawerWidth,
    width: "100%",
    overflow: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.common,
  },
  active: {
    backgroundColor: theme.sideBar.background.active,
    color: theme.sideBar.text.active,
    width: "94%",
    margin: "3%",
    borderRadius: 4,
  },
  nonactive: {
    backgroundColor: theme.sideBar.background.nonActive,
    color: theme.sideBar.text.nonActive,
    width: "94%",
    margin: "3%",
    borderRadius: 4,
  },
  langList: {
    padding: "6px",
    borderBottom: "1px solid #cccccc59",
    cursor: "pointer",
    width: "100px",
    display: "flex",
    fontSize: 14,
    fontWeight: 500,
    color: "#000",
  },
  images: {
    height: "18px !important",
    width: "18px !important",
  },
  themeType: {
    borderRadius: "4px",
    padding: "4px",
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    cursor: "pointer",
    gap: 6,
  },
  RoledialogPaper: {
    padding: "0px",
  },
  drawerPaperTheme: {
    width: 280,
  },
}));

function ClippedDrawer(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [lang, setLang] = React.useState(
    localStorage.defaultLng
      ? "https://media.baamboozle.com/uploads/images/24048/1601059615_15849"
      : "https://media.baamboozle.com/uploads/images/24048/1601059450_6650"
  );
  const [roleOpen, setRoleOpen] = React.useState(false);
  // const open = Boolean(anchorEl);
  let history = useHistory();
  const LayoutClick = (val, props) => {
    if (val?.page === "/cartOrderVerify") {
      if (val?.redirectUrl) {
        return window.open(val?.redirectUrl);
      }
      history.push({
        pathname: val?.page,
        state: {
          isCardVerify: true,
        },
      });
    } else {
      if (val?.redirectUrl) {
        return window.open(val?.redirectUrl);
      }

      history.push(val?.page);
    }
    qdmApplicationLogger("trace", val?.page);
  };

  const handleClick = () => {
    setRoleOpen(true);
  };

  const changeLang = (val) => {
    const { i18n } = props;
    i18n.changeLanguage(val);
    localStorage.setItem("defaultLng", val);
    handleClose();
    setLang(val);
  };
  const handleClose = () => {
    setRoleOpen(false);
  };
  const onNextClick = async (
    location,
    facilitystate,
    organisation,
    enterprise,
    rolestate,
    practitionerCode,
  ) => {
    
    let userInfo = jsonwebtoken.decode(localStorage.getItem("userInfo"));
    let userInfoKeysData = JSON.parse(
      atob(localStorage.getItem("userInfoKeys"))
    );
    let practitonerId = userInfo?.practitionerid;
    let person_id = userInfo?.personid;
    let personId = userInfoKeysData?.personId;

    let params = UserInfo(
      location,
      facilitystate,
      organisation,
      enterprise,
      rolestate,
      practitionerCode,
      practitonerId,
      person_id
    );
    let keys = userInfoKeys(
      location,
      facilitystate,
      organisation,
      enterprise,
      rolestate,
      practitionerCode,
      practitonerId,
      person_id
    );

    params.apikey = Config.TokenApikey;
    let CheckSum = md5(JSON.stringify(params));
    delete params.apikey;
    params.CheckSum = CheckSum;

    // Adding personId in userInfoKeys localstorage
    keys = {
      ...keys,
      personId: personId,
    };

    let Generated_Token = await dispatch(
      actions.GET_TOKEN({
        params,
      })
    );
    if (params && keys) {
      localStorage.setItem("userInfo", Generated_Token?.payload?.data?.Result);
      localStorage.setItem("userInfoKeys", btoa(JSON.stringify({ ...keys, practDetails: [rolestate?.practitioner], practitionerCode: practitionerCode })));
      await dispatch(
        actions.LOGGED_USER_INFO({
          params,
          keys,
        })
      );

      const userPreference = await dispatch(
        actions.READ_USER_PREFERENCE({
          personId: personId,
        })
      );

      // Check if any preference has been already saved in database.
      const userPreferenceData = userPreference?.payload?.data?.result;

      if (userPreferenceData?.length > 0) {
        const userPreferenceObj = [...userPreferenceData]?.pop();

        saveUserPreferences({
          ...keys,
          practitionerCode,
          _key: userPreferenceObj?._key,
        });
      } else {
        saveUserPreferences({
          ...keys,
          practitionerCode,
        });
      }

      const orgFormat = await dispatch(
        actions.GET_DATE_FORMAT_USING_FACILITY_ID({
          facilityId: facilitystate?._key,
        })
      );

      localStorage.setItem(
        "orgFormat",
        JSON.stringify(orgFormat?.payload?.data?.result?.[0])
      );

      const permissionValue = await dispatch(
        actions.PERMISSION_ROLES({
          id: keys?.role?.role?.[0]?._id,
        })
      );
      //await dispatch(actions.GETQUEUELIST());

      if ((permissionValue?.payload?.data.Result.length || 0) === 0) {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Role Permission Not Mapped!. Please contact your admin.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return;
      }
      try {
        let Arr = [];
        let pageJson =
          permissionValue?.payload?.data.Result?.[0]?.permission?.[0]
            ?.permissionRepoAccess;
        localStorage.setItem("permissionData", btoa(JSON.stringify(pageJson)));

        let extraRoute = [];
        //et permissionJson = [];
        pageJson.forEach((v) => {
          //permissionJson.push(v.permissionRepo?.rep_name);
          let repo = {
            repoName: v.permissionRepo?.rep_name,
            rep_type: v.permissionRepo?.rep_type,
            route_url: v.permissionRepo?.route_url ?? "",
            unique_id: v.permissionRepo?.unique_id,
            projectid: v.permissionRepo?.projectid,
            _id: v?.permissionRepo?._id,
            permsndelete: v?.permsndelete,
            permsnread: v?.permsnread,
            permsnupdate: v?.permsnupdate,
            permsnwrite: v?.permsnwrite,
          };
          Arr.push(repo ?? "");
          if (
            v.permissionRepo?.route_url &&
            v?.permsndelete &&
            v?.permsnread &&
            v?.permsnupdate &&
            v?.permsnwrite
          ) {
            extraRoute.push(v.permissionRepo?.route_url);
          }
        });

        let basicRoute = basicRouters;
        basicRoute = basicRoute.filter((val) => extraRoute.indexOf(val) > -1);

        const permission = {
          [keys?.role?.role?.[0]?.display?.toLowerCase()]: {
            permission: [...Arr],

            accessedRouters: [...extraRoute],
          },
        };

        localStorage.setItem(
          "role_name",
          keys?.role?.role?.[0]?.display?.toLowerCase()
        );

        localStorage.setItem("role_data", btoa(JSON.stringify(permission)));

        if (basicRoute.length > 0) {
          handleClose();
          props.history.push({
            pathname: basicRoute[0],
          });

          // window.location.reload();
        } else {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something went wrong. Please contact your admin.",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
          localStorage.clear();
        }
      } catch (e) {}
    }
  };

  const saveUserPreferences = ({ personId, _key, ...others }) => {
    const payload = {
      // username: state.email_id,
      // email: state.email_id,
      // realmRoles: [],
      // origin: "",
      // id: "",
      // is_active: true,
      // role_mapping: {},
      personid: personId,
      userpreference: {
        ...others,
        enterprise: others?.enterprise?._id,
        facility: others?.facility?._id,
        location: others?.location?._id,
        org: others?.org?._id,
      },
      _key: _key,
    };

    Promise.resolve(dispatch(actions.SAVE_USER_PREFERENCE(payload)))
      .then((res) => {
        console.log("Successfully saved user preference");
        window.location.reload();
      })
      .catch((error) =>
        console.error(`Error Occured in SAVE_USER_PREFERENCE: ${error}`)
      );
  };

  useEffect(() => {
    changeTheme();
  }, [props.themes.type, props.themes.color]);

  const changeType = (type) => {
    props.themes.setTheme({ ...props.themes, type: type });
  };

  const changeColor = (name) => {
    if (props.themes.color === name) {
      props.themes.setTheme({ ...props.themes, color: "" });
    } else {
      props.themes.setTheme({ ...props.themes, color: name });
    } 
  };

  const themeOrigin = [
    {
      name: "Default",
      icon: (
        <SunIcon
          color={props.themes.type === "default" ? "#FFFFFF" : "#6A7888"}
        />
      ),
    },
    {
      name: "Dark",
      icon: (
        <MoonIcon
          color={props.themes.type === "dark" ? "#FFFFFF" : "#6A7888"}
        />
      ),
    },
  ];
  const colorOrigin = [
    { name: "green", color: "#00FF00" },
    { name: "purple", color: "#5B1D83" },
  ];
  const changeTheme = () => {
    props.themes.setTheme({
      ...props.themes,
      name: props.themes.type + props.themes.color,
    });
  };

  const [open, setOpen] = useState(false);
  const handleDrawerClick = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getPractitionerId = () => {
    let userInfoKeys = localStorage.getItem("userInfoKeys");
    userInfoKeys = JSON.parse(atob(userInfoKeys));
    return userInfoKeys?.practitioner;
  };

  // const Language = [
  //   {
  //     image:
  //       "https://media.baamboozle.com/uploads/images/24048/1601059615_15849",
  //     language: "English",
  //     key: "en",
  //   },
  //   {
  //     image:
  //       "https://media.baamboozle.com/uploads/images/24048/1601059450_6650",
  //     language: "Tamil",
  //     key: "ta",
  //   },
  // ];
  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <div className="custom_material_ui_header">
          {/* <StylesProvider generateClassName={generateClassName} injectFirst> */}
          <div className={classes.root} {...props}>
            <CssBaseline />
            {props.Header ? (
              <AppBar
                position="fixed"
                className={classes.appBar}
                // style={{
                //   backgroundColor: props.HeaderBackgroundColor,
                //   color: props.HeaderFontColor,
                // }}
                elevation={0}
              >
                <Toolbar>
                  <Grid container>
                    <Grid
                      container
                      item
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xs={6}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      {props?.ProjectTitle ?? (
                        <Typography
                          variant="subtitle1"
                          noWrap
                          className={classes.TitleName}
                        >
                          {props?.ProjectTitle ? props?.ProjectTitle : "Title"}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      container
                      item
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xs={6}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid
                        container
                        item
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xs={8}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        className={classes.userContent}
                      >
                        <Grid
                          container
                          item
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          xs={2}
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={handleDrawerClick}
                          >
                            <ThemesIcon color={"#fff"} />
                          </div>
                          <Drawer
                            id={"theme-drawer"}
                            className={classes.drawerTheme}
                            variant="temporary"
                            anchor="right"
                            open={open}
                            onClose={handleDrawerClose}
                            classes={{
                              paper: classes.drawerPaperTheme,
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  padding: "10px",
                                  borderBottom: "1px solid #e0e0e0",
                                }}
                              >
                                <Typography
                                  style={{
                                    paddingBottom: "4px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Mode
                                </Typography>
                                <Grid container spacing={1}>
                                  {themeOrigin?.map((v) => {
                                    return (
                                      <Grid item xs={6}>
                                        <div
                                          className={classes.themeType}
                                          style={{
                                            color:
                                              props.themes.type ===
                                              v.name.toLowerCase()
                                                ? "#FFFFFF"
                                                : "#6A7888",
                                            backgroundColor:
                                              props.themes.type ===
                                              v.name.toLowerCase()
                                                ? "#2A60BC"
                                                : "#F1F6FC",
                                          }}
                                          onClick={() =>
                                            changeType(v.name.toLowerCase())
                                          }
                                        >
                                          <Typography
                                            style={{ fontSize: "14px" }}
                                          >
                                            {v.name}
                                          </Typography>
                                          {v.icon}
                                        </div>
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              </div>
                              <div style={{ padding: "10px" }}>
                                <Typography
                                  style={{
                                    paddingBottom: "4px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Colors
                                </Typography>
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ padding: "10px" }}
                                >
                                  {colorOrigin.map((v) => (
                                    <Grid
                                      item
                                      xs={2}
                                      style={{
                                        padding: "12px",
                                        borderRadius: "10%",
                                        backgroundColor: v.color,
                                        width: "10%",
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginRight: "10px",
                                        height: "34px",
                                      }}
                                      onClick={() => changeColor(v.name)}
                                    >
                                      {v.name === props.themes.color && (
                                        <TickIcon />
                                      )}
                                    </Grid>
                                  ))}
                                </Grid>
                              </div>
                            </div>
                          </Drawer>
                        </Grid>
                        <Grid
                          container
                          item
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          xs={4}
                          direction="column"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                        >
                          {props?.VersionNo ? (
                            <Typography variant="caption" noWrap>
                              {props?.VersionNo
                                ? props?.VersionNo
                                : "Version: 0.0.0"}
                            </Typography>
                          ) : (
                            <span />
                          )}
                        </Grid>
                        {props?.VersionNo &&
                        (props?.Title || props?.SubTitle) ? (
                          <div className={classes.HrTag}></div>
                        ) : (
                          <span />
                        )}
                        <Grid
                          container
                          item
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          xs={4}
                          direction="column"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                        >
                          <Grid
                            container
                            item
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xs={12}
                          >
                            {props?.UserName ? (
                              <Typography variant="subtitle1" noWrap>
                                {props?.UserName
                                  ? props?.UserName
                                  : "Martha Richards"}
                              </Typography>
                            ) : (
                              <span />
                            )}
                          </Grid>
                          <Grid
                            container
                            item
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xs={12}
                          >
                            {props.SubTitle ? (
                              <Typography
                                variant="caption"
                                noWrap
                                style={{ textTransform: "capitalize" }}
                              >
                                {props?.SubTitle
                                  ? props?.SubTitle
                                  : "Pharmacist, Apollo Hospital"}
                              </Typography>
                            ) : (
                              <span />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      {props?.ImageSrc || props?.Title ? (
                        <Avatar
                          alt="Remy Sharp"
                          src={props?.ImageSrc}
                          className={classes.Avatar}
                          variant={"rounded"}
                        >
                          {props?.Title?.split("")[0]}
                        </Avatar>
                      ) : (
                        <span />
                      )}
                      <SwapHorizontalCircleIcon
                        onClick={handleClick}
                        style={{ color: "#fff" }}
                      />
                      <Button
                        style={{ color: "white" }}
                        onClick={() => Logout()}
                      >
                        Logout
                      </Button>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            ) : (
              <span />
            )}
            {props.Menu ? (
              <div
                className="custom_material_ui_drawer_body"
                style={{ width: `calc(100% - ${drawerWidth}px)` }}
              >
                <Drawer
                  className={classes.drawer}
                  variant="permanent"
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  <Toolbar />
                  <div className={classes.drawerContainer}>
                    <List>
                      {props?.pages?.map((val, index) => {
                        if (val?.isShow) {
                          return (
                            <ListItem
                              button
                              key={val + "" + index}
                              className={
                                val?.pages?.includes(window.location.pathname)
                                  ? classes.active
                                  : classes.nonactive
                              }
                              onClick={() => LayoutClick(val, props)}
                            >
                              <ListItemIcon className={classes.Icons}>
                                {/* style={val?.active ? {color:"#001C3C"}: {color:"#2A3C50"}} */}
                                {/* <img  src={val.icon} style={{height: "auto", width: val.size+"px"}}/> */}
                                {val?.iconType === "image" ? (
                                  <Image
                                    id={"hovernavbar-logo-image" + index}
                                    src={val?.icon}
                                    alt="logo"
                                    className={classes.images}
                                  />
                                ) : (
                                  val.icon
                                )}
                              </ListItemIcon>
                              <Typography
                                children={val.name}
                                style={{ fontSize: val.size + "px" }}
                              />
                            </ListItem>
                          );
                        } else {
                          return <span />;
                        }
                      })}
                    </List>
                  </div>
                </Drawer>
                <main className={props.Menu ? classes.content : ""}>
                  <Toolbar />
                  {props?.Component}
                </main>
              </div>
            ) : (
              <main className={props.Menu ? classes.content : ""}>
                <Toolbar />
                {props?.Component}
              </main>
            )}
          </div>
          {/* </StylesProvider> */}

          <Dialog
            open={roleOpen}
            onClose={handleClose}
            classes={{ paper: classes.RoledialogPaper }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ paddingBottom: "0px" }}
            >
              Select Location and Role
            </DialogTitle>
            <DialogContent
              style={{
                padding: "0px",
                paddingBottom: "10px",
                paddingRight: "20px",
              }}
            >
              <LocationRoles
                // practitonerDetails={practitonerDetails}
                practitonerId={getPractitionerId()}
                onNextClick={onNextClick}
              />
            </DialogContent>
          </Dialog>
        </div>
      )}
    </NamespacesConsumer>
  );
}

export default withAllContexts(translate()(ClippedDrawer));
