import * as React from "react";
import PropTypes from "prop-types";

const DiagnosisIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19.917" viewBox="0 0 19.917 19.917">
    <path id="icons8-diagnosis" d="M17.522,2.914a3.243,3.243,0,0,0-3.168,3.313,3.171,3.171,0,1,0,6.336,0,3.243,3.243,0,0,0-3.168-3.313ZM4.938,5.321a1.119,1.119,0,0,0-.96.564,2.657,2.657,0,0,0-1.685,1.4,2.861,2.861,0,0,0,.18,2.862l4.05,6.05a.094.094,0,0,0,0,.011v.473a6.029,6.029,0,0,0,5.884,6.152,6.029,6.029,0,0,0,5.884-6.152V15.017a1.2,1.2,0,0,0,.453-.941,1.133,1.133,0,1,0-2.263,0,1.2,1.2,0,0,0,.453.941v1.662a4.638,4.638,0,0,1-4.526,4.732A4.638,4.638,0,0,1,7.88,16.679v-.473c0-.008,0-.015,0-.023l3.8-6.117a2.833,2.833,0,0,0,.107-2.826A2.659,2.659,0,0,0,10.487,6a1.128,1.128,0,0,0-1.023-.684,1.184,1.184,0,0,0,0,2.366,1.092,1.092,0,0,0,.7-.257,1.321,1.321,0,0,1,.434.487,1.378,1.378,0,0,1-.051,1.374L7.18,14.7,3.586,9.33A1.374,1.374,0,0,1,3.5,7.938a1.283,1.283,0,0,1,.634-.6,1.1,1.1,0,0,0,.806.354,1.184,1.184,0,0,0,0-2.366Zm9.295,5.165a1.733,1.733,0,0,0-1.69,1.768v5.169a5.644,5.644,0,0,0,.8,2.909,3.765,3.765,0,0,0,2.683-3.653V15.411a2.164,2.164,0,0,1-.453-1.335,2.039,2.039,0,1,1,4.073,0,2.164,2.164,0,0,1-.453,1.335v1.268a7.186,7.186,0,0,1-3.027,5.9,4.463,4.463,0,0,0,1,.194c.113.009.224.012.334.012A3.932,3.932,0,0,0,20.3,21.65a5.932,5.932,0,0,0,1.607-4.261V12.177a1.625,1.625,0,0,0-1.575-1.656Z" transform="translate(-1.994 -2.914)" fill="#8895a5" />
  </svg>
);

export default DiagnosisIcon;

DiagnosisIcon.defaultProps = {
  color: "#2a3c50",
};

DiagnosisIcon.propTypes = {
  color: PropTypes.string,
};
