import React from "react";
import { ReportViewer } from "atp-reportviewer";

function Reports() {
  return (
    <>
      {/* Reports */}
      <ReportViewer
        metadataid={
          process.env.REACT_APP_REPORT_METADATA_ID ||
          "0bc57c75-09ed-46d8-9958-72eb0b948046"
        }
      />
    </>
  );
}

export default Reports;
