import {
  AppBar,
  Avatar,
  Button,
  Grid,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Logout } from "../../utils";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  userContent: {
    marginRight: "3%",
  },
  HrTag: {
    marginRight: 18,
    marginLeft: 18,
    height: 28,
    backgroundColor: "white",
    width: 1,
  },
  TitleName: {
    fontWeight: "bold",
    letterSpacing: 1.5,
  },
  Avatar: {
    marginRight: "2%",
  },
  Icons: {
    minWidth: 34,
    justifyContent: "center",
    paddingRight: 6,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: drawerWidth,
    width: "100%",
  },
}));

const TopNavBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      style={{
        backgroundColor: props.HeaderBackgroundColor,
        color: props.HeaderFontColor,
      }}
      elevation={0}
    >
      <Toolbar>
        <Grid container>
          <Grid
            container
            item
            sm={6}
            md={6}
            lg={6}
            xl={6}
            xs={6}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {props?.ProjectTitle ?? (
              <Typography
                variant="subtitle1"
                noWrap
                className={classes.TitleName}
              >
                {props?.ProjectTitle ? props?.ProjectTitle : "Title"}
              </Typography>
            )}
          </Grid>
          <Grid
            container
            item
            sm={6}
            md={6}
            lg={6}
            xl={6}
            xs={6}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid
              container
              item
              sm={8}
              md={8}
              lg={8}
              xl={8}
              xs={8}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.userContent}
            >
              <Grid
                container
                item
                sm={4}
                md={4}
                lg={4}
                xl={4}
                xs={4}
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                {props?.VersionNo ? (
                  <Typography variant="caption" noWrap>
                    {props?.VersionNo ? props?.VersionNo : "Version: 0.1.73"}
                  </Typography>
                ) : (
                  <span />
                )}
              </Grid>
              {props?.VersionNo && (props?.Title || props?.SubTitle) ? (
                <div className={classes.HrTag}></div>
              ) : (
                <span />
              )}
              <Grid
                container
                item
                sm={4}
                md={4}
                lg={4}
                xl={4}
                xs={4}
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid container item sm={12} md={12} lg={12} xl={12} xs={12}>
                  {props?.UserName ? (
                    <Typography variant="subtitle1" noWrap>
                      {props?.UserName ? props?.UserName : "Martha Richards"}
                    </Typography>
                  ) : (
                    <span />
                  )}
                </Grid>
                <Grid container item sm={12} md={12} lg={12} xl={12} xs={12}>
                  {props.SubTitle ? (
                    <Typography variant="caption" noWrap>
                      {props?.SubTitle
                        ? props?.SubTitle
                        : "Pharmacist, Apollo Hospital"}
                    </Typography>
                  ) : (
                    <span />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {props?.ImageSrc || props?.Title ? (
              <Avatar
                alt="Remy Sharp"
                src={props?.ImageSrc}
                className={classes.Avatar}
                variant={"rounded"}
              >
                {props?.Title?.split("")[0]}
              </Avatar>
            ) : (
              <span />
            )}
            <ExpandMoreIcon style={{ color: "#fff" }} />
            <Button style={{ color: "white" }} onClick={() => Logout()}>
              Logout
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavBar;
