import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tag from "./tag";
import Typography from "@material-ui/core/Typography";
import {
  CheckIcon,
  DocumentBlack,
  DocumentIcon,
  HomeDeliveryIcon,
  InfoIcon,
  PrnIcon,
  SlidingScaleIcon,
} from "../../assets";
import { StyledDialog, StyledTooltip } from "../commonComponents";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import PropTypes from "prop-types";
import { VerticalLinearStepper } from "./popovers";
import classNames from "classnames";
import moment from "moment";
import { actions } from "frequencyscreen_v_dbinder";
import { AlertDialog } from "../drugItem/components";
import { useDispatch } from "react-redux";
import { AlertProps } from "../../utils";
import { withAllContexts } from "../../HOCs";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-end",
    padding: "0px 12px",
  },
  alertTagsRoot: {
    display: "flex",
    gap: 6,
    flexWrap: "nowrap",
  },
  boldText: {
    fontWeight: 600,
  },
  statusDialogBody: {
    margin: theme.spacing(2, 2, 2, 2.5),
    padding: theme.spacing(1.25, 1.5),
  },
  dialogBody: {
    margin: theme.spacing(2, 2, 2, 2.5),
    padding: theme.spacing(1.25, 1.5),
    background: "#F1F6FC",
    borderRadius: theme.spacing(1),
  },
  clickableTag: {
    cursor: "pointer",
  },
  drugTypeTag: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
  },
  textWrapForRight: {
    whiteSpace: "nowrap",
    minWidth: "60px",
    textOverflow: "ellipsis",
  },
  defaultText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    color: "white",
  },
  expiredText: {
    fontWeight: 600,
    color: "#EC6A49",
  },
  issuedText: {
    fontWeight: 600,
  },
  dateText: {
    fontWeight: 400,
  },
  alertTag: {
    color: "#fff",
    fontWeight: 600,
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 460,
    borderRadius: 8,
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
  },
}));

const useDialogContentStyles = makeStyles((theme) => ({
  dividers: {
    padding: 0,
    borderColor: "rgba(222, 229, 236, 1)",
  },
}));
const { ALERTLOGS_SINGLE_READ, ALERT_UPDATE } = actions;
function OrderTags({
  dispenseStatus,
  clinicalValidation,
  slidingScaleDose,
  prnComment,
  refillDate,
  pendingDuration,
  orderType,
  alloweddisptype,
  handleDoseType,
  dispenseType,
  _key,
  issueMedication,
  isReturnMedication,
  issuedOnDate,
  alertsCount,
  selectedState,
  orderLineID,
  ...props
}) {
  // <---------------Hooks------------------>
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const dialogStyles = useDialogStyles();
  const dialogContentStyles = useDialogContentStyles();
  const drugTypePopupState = usePopupState({
    variant: "popover",
    popupId: "drugType",
  });

  // <---------------Store States----------->

  // <-------------Component States--------->
  const [isStatusLogOpen, setIsStatusLogOpen] = useState(false);
  const [isSlidingScaleOpen, setIsSlidingScaleOpen] = useState(false);
  const [isPrnOpen, setIsPrnOpen] = useState(false);
  const [alert, setAlert] = React.useState(false);

  // <-----------------Handlers------------->
  const handleStatusDialogToggle = () => {
    setIsStatusLogOpen((prev) => !prev);
  };

  const handleSlidingDialogToggle = () => {
    setIsSlidingScaleOpen((prev) => !prev);
  };

  const handlePrnDialogToggle = () => {
    setIsPrnOpen((prev) => !prev);
  };

  const handleClickAlert = async () => {
    // if (selectedState === "verification") {
      await dispatch(ALERTLOGS_SINGLE_READ({ key: orderLineID }));
      setAlert(true);
    // }
  };
  const handleCloseAlert = (value, save) => {
    setAlert(false);
    
    if (save === "save") {
      handleUpsertAlert(value);
    }
  };

  const handleUpsertAlert = (data) => {
    // if (orderLineID) {
    //   data['orderLineId'] = orderLineID
    // }
    Promise.resolve(dispatch(ALERT_UPDATE({ alertPayload: data })))
      .then((res) => {
        if (res?.payload?.data?.Code === 201) {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Alert updated successfully!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        } else {
          throw new Error();
        }
      })
      .catch((e) => {
        console.error(e);
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong. Failed to update alert.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      });
  };

  // <-----------------Effects-------------->

  // <-----------------Render--------------->
  return (
    <div className={classes.root}>
      <div className={classes.alertTagsRoot}>
        <Tag>
          <Typography variant="body2" className={classes.defaultText}>
            {issueMedication && "ReIssue"}
            {isReturnMedication && "Return"}
            {!issueMedication && !isReturnMedication && dispenseStatus}
          </Typography> 
        </Tag>

        {props?.isReverify && (
          <Tag>
            <Tooltip title={props?.isReverifyStatusreason ?? ''} arrow>
              <Typography className={classes.defaultText}>
                {'Re-verify'}
              </Typography>
            </Tooltip>
          </Tag>
        )}

        {issueMedication && (
          <Tag>
            <Typography
              className={classNames(classes.defaultText, classes.issuedText)}
            >
              Issued On&nbsp;
              <span className={classes.dateText}>
                {moment.unix(issuedOnDate).format("DD-MM-YYYY")}
              </span>
            </Typography>
          </Tag>
        )}

        {isReturnMedication && (
          <Tag>
            <Typography
              className={classNames(classes.defaultText, classes.issuedText)}
            >
              Issued On&nbsp;
              <span className={classes.dateText}>
                {moment.unix(issuedOnDate).format("DD-MM-YYYY")}
              </span>
            </Typography>
          </Tag>
        )}
        {!isReturnMedication && !issueMedication && (
          <>
            <Tag backgroundColor={clinicalValidation ? "#FDF3D8" : "#D4F7D4"}>
              {clinicalValidation ? (
                <InfoIcon color={"#FFC00F"} />
              ) : (
                <CheckIcon color={"#3bb213"} />
              )}
            </Tag>
            <StyledTooltip title="Status Log">
              <Tag
                className={classes.clickableTag}
                onClick={handleStatusDialogToggle}
              >
                <DocumentIcon />
              </Tag>
            </StyledTooltip>
            {orderType?.toString()?.toLowerCase() === "regular" && (
              <Tag>
                <HomeDeliveryIcon />
              </Tag>
            )}
            <StyledTooltip title="Sliding Scale Dose">
              <Tag
                className={classes.clickableTag}
                onClick={handleSlidingDialogToggle}
              >
                <SlidingScaleIcon />
              </Tag>
            </StyledTooltip>
            {alertsCount > 0 && (
              <StyledTooltip title="Alerts">
                <Tag className={classes.alertTag} onClick={handleClickAlert}>
                  Alerts&nbsp;{alertsCount}
                </Tag>
              </StyledTooltip>
            )}
            <StyledTooltip title="prnComment">
              <Tag
                className={classes.clickableTag}
                onClick={handlePrnDialogToggle}
              >
                <PrnIcon />
              </Tag>
            </StyledTooltip>
          </>
        )}
      </div>

      <div style={{ flex: 1 }}></div>

      {!isReturnMedication && !issueMedication && (
        <>
          <div className={classes.alertTagsRoot}>
            {refillDate && (
              <Tag>
                <Typography
                  variant="body2"
                  className={clsx(
                    classes.defaultText,
                    classes.textWrapForRight
                  )}
                >
                  Refill on&nbsp;
                  <span className={classes.boldText}>{refillDate}</span>
                </Typography>
              </Tag>
            )}

            {pendingDuration && (
              <Tag>
                <Typography
                  variant="body2"
                  className={clsx(
                    classes.defaultText,
                    classes.textWrapForRight
                  )}
                >
                  Pending Duration&nbsp;
                  <span className={classes.boldText}>{pendingDuration}</span>
                </Typography>
              </Tag>
            )}
            {dispenseType && (
              <Tag
                backgroundColor={theme.palette.primary.main}
                {...bindTrigger(drugTypePopupState)}
                className={clsx(
                  classes.clickableTag,
                  classes.drugTypeTag,
                  classes.textWrapForRight
                )}
              >
                <Typography variant="body2" className={classes.defaultText}>
                  {dispenseType}
                </Typography>
                <ExpandMoreIcon fontSize="small" htmlColor="#fff" />
              </Tag>
            )}
          </div>
        </>
      )}

      {/* Dialog for Sliding Scale */}
      <StyledDialog
        id="status_log"
        open={isStatusLogOpen}
        handleClose={handleStatusDialogToggle}
        title={"STATUS LOG"}
        dialogProps={{
          classes: dialogStyles,
        }}
        contentProps={{
          classes: dialogContentStyles,
        }}
        icon={<DocumentBlack color={theme.palette.text.primary} />}
      >
        <div className={classes.statusDialogBody}>
          <VerticalLinearStepper _key={_key} />
        </div>
      </StyledDialog>

      {/* Dialog for Sliding Scale */}
      <StyledDialog
        id="Sliding_Scale"
        open={isSlidingScaleOpen}
        handleClose={handleSlidingDialogToggle}
        title={"SLIDING SCALE"}
        dialogProps={{
          classes: dialogStyles,
        }}
        contentProps={{
          classes: dialogContentStyles,
        }}
        icon={<SlidingScaleIcon color="inherit" />}
      >
        <div className={classes.dialogBody}>
          <Typography variant="body2">{slidingScaleDose}</Typography>
        </div>
      </StyledDialog>

      {/* Dialog for PRN Remarks */}
      <StyledDialog
        id="prn_remarks"
        open={isPrnOpen}
        handleClose={handlePrnDialogToggle}
        title={"PRN"}
        dialogProps={{
          classes: dialogStyles,
        }}
        contentProps={{
          classes: dialogContentStyles,
        }}
        icon={<PrnIcon color="inherit" />}
      >
        <div className={classes.dialogBody}>
          <Typography variant="body2">{prnComment}</Typography>
        </div>
      </StyledDialog>

      {/* Menu for Drug Type */}
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...bindMenu(drugTypePopupState)}
      >
        {alloweddisptype?.map((disptype) => {
          return (
            <MenuItem onClick={() => handleDoseType(disptype)}>
              {disptype?.code}
            </MenuItem>
          );
        })}
      </Menu>

      {/* Alert Dialog_______________ */}
      <AlertDialog
        alertDialogOpen={alert}
        handleCloseAlertDialog={handleCloseAlert}
        isHide={true}
        selectedState={selectedState === "verification"}
        />
    </div>
  );
}

export default withAllContexts(OrderTags);

OrderTags.defaultProps = {
  dispenseStatus: "",
  clinicalValidation: false,
  slidingScaleDose: "",
  prnComment: "",
  refillDate: "",
  pendingDuration: "",
};

OrderTags.propTypes = {
  dispenseStatus: PropTypes.string,
  clinicalValidation: PropTypes.string,
  slidingScaleDose: PropTypes.string,
  prnComment: PropTypes.string,
  refillDate: PropTypes.string,
  pendingDuration: PropTypes.string,
};
