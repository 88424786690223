import React, { useState } from "react";
import {
  Button,
  Div,
  Row,
  Col,
  Select,
  TextInput,
  Text,
  Avatar,
} from "qdm-component-library";
import {
  InputBase,
  Grid,
  Popover,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
// import FormHeader from './formHeader';
import { withRouter } from "react-router-dom";
import { UnitDoseForm, IVForm } from "./forms";
import {
  SelectCard,
  RequestorDetailsForm,
  InputGroup,
  SlidingScale,
  ChangeStatus,
  FrequencyList,
} from "./components";
// import { CalendarIcon, SelectBox } from "../../../components";
import SelectBox from "../../../../selectBox";
import { CalendarIcon, InfoIcon } from "../../../../../assets";
import { withAllContexts } from "../../../../../HOCs";
import { AlertProps, makeNameForPract, UIColor } from "../../../../../utils";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  generateTapparing,
  generateDose,
  TwiceDaily,
  getErrorValidation,
} from "./func";
import { actions } from "frequencyscreen_v_dbinder";
import { CreateJson, getStatusHistory } from "./generateJson";
import CircularProgress from "@material-ui/core/CircularProgress";
//import MaterialAutoComplete from "./components/materialAutoComplete";
import { Autocomplete } from "@material-ui/lab";
import ScheduleLine from "./scheduleLine";

const isShowTapIcon = ["Twice Daily", "EVERY THURSDAY AND FRIDAY", "TAPERING"];

const styles = {
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  cancelBtn: {
    padding: "8px",
    fontSize: 14,
    marginRight: 10,
    borderColor: UIColor.lineBorderFill.color,
    color: UIColor.primaryColor.color,
    borderRadius: 8,
  },
  filledBtn: {
    backgroundColor: UIColor.primaryColor.color,
    borderColor: UIColor.primaryColor.color,
    padding: "8px",
    fontSize: 14,
    borderRadius: 8,
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "4px",
    fontFamily: `pc_regular !important`,
  },
  labelStyleScheduleLine: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "12px",
    fontFamily: `pc_regular !important`,
  },
  labelStyle_: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "4px",
    fontFamily: `pc_regular !important`,
    margin: "6px 5px",
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: UIColor.error.color,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  colPadding: {
    padding: "7px",
  },
  label: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px !important",
    fontFamily: "pc_regular!important",
    marginBottom: "6px",
    fontWeight: 400,
  },
  required: {
    color: UIColor.error.color,
    marginLeft: 5,
  },
  switch: {
    "& .App1-Mui-checked": {
      color: "#fff",
    },
    "& .App1-Mui-checked + .App1-MuiSwitch-track": {
      backgroundColor: UIColor.primaryColor.color,
      opacity: 1,
    },
  },
  dateWrapper: {
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    padding: 5,
    display: "flex",
    alignItems: "center",
    minHeight: 40,
    "&:hover": {
      borderColor: UIColor.lineBorderFill.color,
    },
  },
  calenderText: {
    marginLeft: 5,
    cursor: "pointer",
    display: "flex",
  },
  dateText: {
    fontSize: 14,
    color: UIColor.secondaryText.color,
    flex: 1,
  },
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
  iconWrapper: {
    borderRadius: 8,
    width: 40,
    height: 40,
    backgroundColor: UIColor.lineBorderFill.color,
    margin: 0,
    position: "absolute",
    marginTop: 22,
  },
  patientInstructionDiv: {
    height: "82px",
    padding: "5px",
    border: "1px solid #c3c3c3",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
  },
};

// const statusColors = {
//   Draft: { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
//   "On-Hold": { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
//   Discontinue: { color: "#d60000", background: UIColor.lineBorderFill.color },
//   Continue: {
//     color: UIColor.primaryColor.color,
//     background: UIColor.primaryColor.color,
//   },
//   //"Active": { color: UIColor.primaryColor.color, background: "#D4E7FD" },
//   Active: { color: UIColor.success.color, background: "#fff" },
//   // "Cancel": { color: "#FF4D4A", background: "#FFE1E0" }
// };

const useStyles = makeStyles((theme) => ({
  deskPadding: {
    paddingLeft: 15,
    paddingRight: 15,
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  autocomplete_label: {
    color: "#6F6F6F",
    fontSize: "12px !important",
    fontFamily: "pc_regular!important",
    marginBottom: "6px",
    fontWeight: 400,
  },
}));

// const getAllDiagnosiis = (state, list) => {
//     let arr = null;
//     if (state && state.length > 0) {
//         arr = list.filter(val => state.indexOf(val.value) > -1);
//     }
//     return arr
// }

const MedicationForm = (props) => {
  let dispatch = useDispatch();
  // const loggedUserInfo = useSelector((state) => state?.authSlice?.loggedUserInfo);
  const stateList = useSelector((state) => state?.MedicationMastersSlice);
  // const rulesvalidatedData = useSelector(state => state?.validateRulesSlice?.PFMedData);
  const personData = useSelector(
    (state) => state?.signInSlice?.searchPractioner?.data
  );
  //const medicationsData = props.medicationsData;
  // const { list, editIndex, setMedication } = medicationsData;
  const [EditData, setEditData] = useState({});
  const [loader, setLoader] = useState(true);

  React.useEffect(() => {
    // if (!props.isAdd) {
    //   setIntialData();
    // } else {
    //   setLoader(false)
    // }
    setIntialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [masters, setMasters] = React.useState([]);
  const handleDiagnosis = async (v) => {
    if (v.length > 1) {
      let ccMaster = await dispatch(
        actions.DIAGNOSIS_MASTERS({
          input_text: v,
          enId: props?.encounterId,
        })
      );
      let ccMasters = ccMaster?.payload?.data;
      setMasters(ccMasters);
    }
  };

  const setIntialData = async () => {
    const singleRead = await dispatch(
      actions.SINGLE_READ_EDIT_INFO({ key: props?.totalOrder?._id })
    );
    const result = singleRead?.payload?.data[0];
    await dispatch(
      actions.DIAGNOSISAGAINSTENCOUNTER_MASTER({
        id: result?.encounter_Id?._key,
      })
    );

    let data = result?.requestor?.[0];
    
    // EditData.requestorDetails = {
    //   organization: {
    //     value: data?.organization?._id ?? "",
    //     label: data?.organization?.name ?? "",
    //     id: data?.organization?.id ?? ""
    //   },
    //   practitionerRole: {
    //     value: data?.practRole?._id ?? "",
    //     label: data?.practRole?.display ?? "",
    //     id: data?.practRole?.id ?? ""
    //   },
    //   orderBy: {
    //     value: data?.practId?.[0]?._id ?? "",
    //     label: makeNameForPract(data?.practId?.[0].name?.[0]) ?? "",
    //     id: data?.practId?.[0]?.id ?? ""
    //   }
    // };

    setEditData(result);
    await setState({
      prescripionType: {
        value: result?.prescriptionType?._id ?? "",
        label: result?.prescriptionType?.display ?? "",
        id: result?.prescriptionType?.id ?? "",
      },
      requestorDetails: {
        organization: data?.organization?._id
          ? {
              value: data?.organization?._id ?? "",
              label: data?.organization?.name ?? "",
              id: data?.organization?.id ?? "",
            }
          : result?.requestor?.[0]
          ? stateList?.Organization_masters?.data.filter(
              (value) => value?.value === result?.requestor?.[0].organization
            )?.[0]
          : null,
        practitionerRole: data?.practRole?._id
          ? {
              value: data?.practRole?._id ?? "",
              label: data?.practRole?.display ?? "",
              id: data?.practRole?.id ?? "",
            }
          : result?.requestor?.[0]
          ? stateList?.PractitionerRole_masters?.data.filter(
              (value) => value?.value === result?.requestor?.[0].practRole
            )?.[0]
          : null,
        orderBy: data?.practId?.[0]?._id
          ? {
              value: data?.practId?.[0]?._id ?? "",
              label: makeNameForPract(data?.practId?.[0].name?.[0]) ?? "",
              id: data?.practId?.[0]?.id ?? "",
            }
          : result?.requestor?.[0]
          ? stateList?.Practitioner_masters?.data.filter(
              (value) => value?.value === result?.requestor?.[0].practId?._id
            )?.[0]
          : null,
      },
      drugType: {
        label: result?.drugType?.shortdesc,
        value: result?.drugType?._id,
      },
      formType: result?.drugType?.shortdesc,
      ivForm: {
        name: result?.orderdescription,
        orderDescription: result?.orderdescription ?? "",
        route: getData("routeCode", "DrugRoutes_masters", result),
        baseSolution: {
          label: result?.orderdescription,
          value: result?.drugCode?._id,
        },
        strength:
          (result?.name?.StrengthValues ?? "") +
          " " +
          (result?.name?.StrengthUOM ?? ""),
        volume: {
          unit: result?.unitValue ?? result?.name?.StrengthValues,
          quantity: result?.doseMeasurementValue ?? result?.name?.StrengthUOM,
        },
        additiveDetails: getAdditiveDetails("components", result),
        infusionRate: {
          unit: getData("ivInfusionRateUOM", "InfusionRateUoM_masters", result),
          quantity: result?.ivInfusionRateValue,
        },
        duration: {
          unit: getData("IVDosageDurationUOM", "DurationType_masters", result),
          quantity: result?.IVDosageDurationValue,
        },
        totalVolume: {
          unit: getData("totPresQtyUOM", "strengthVolumnUom_masters", result),
          quantity: result?.totPresQty,
        },
        prn: result?.PRNYN ?? false,
        prnCommnts: result?.prnComment,
      },
      unitDose: {
        name: {
          label: result?.orderdescription,
          value: result?.drugCode?._id,
          FormData: result?.FormCode || result?.formCode,
        },
        description: result?.orderdescription,
        dosage: {
          min: {
            unit: result?.dosageUOM?.UOM,
            unit_id: result?.dosageUOM?._id,
            quantity: result?.dosageValue,
          },
          max: {
            quantity: "",
            unit: "",
          },
        },
        strength: {
          min: {
            unit: result?.drugCode?.StrengthValuesPossible?.[0]?.StrengthUOM
              ?.UOM,
            quantity:
              result?.drugCode?.StrengthValuesPossible?.[0]?.StrengthValue,
          },
          max: {
            quantity: "",
            unit: "",
          },
        },
        dosageForm: result?.FormCode || result?.formCode,
        route: getData("routeCode", "DrugRoutes_masters", result),
        prn: result?.PRNYN ?? false,
        prnCommnts: result?.prnComment,
      },
      statusData: result?.status?._id ?? null,
      statusReason: result?.statusreason ?? "",
      statusperiod: result?.statusperiod
        ? moment.unix(result?.statusperiod).format("DD-MM-YYYY,h:mm a")
        : "",
      selectDiagnosis: result?.diagnosis?.map((val) => {
        return { label: val?.diagcode?.icdname, value: val?._id };
      }),
      indication: {
        label: result?.indication?.display,
        value: result?.indication?._id,
      },
      // patientInstruction: result?.patientInstruction,
      patientInstruction: {
        value: result?.patientInstruction?.[0]?._id,
        label: result?.patientInstruction?.[0]?.code ?? "",
        display: result?.patientInstruction?.[0]?.display ?? "",
        id: result?.patientInstruction?.[0]?.id,
      },
      patientInstructionDes:
        result?.patientInstructiondesc || result?.patientInstructiondesc || "",
      startDate: result?.occurStartDateTime
        ? moment.unix(result?.occurStartDateTime).format("DD-MM-YYYY,h:mm a")
        : moment().format("DD-MM-YYYY,h:mm a"),
      endDate: result?.occurEndDateTime
        ? moment.unix(result?.occurEndDateTime).format("DD-MM-YYYY,h:mm a")
        : "",
      // priority: getData("priority", "Priority_masters", result),
      priority: {
        value: result?.priority?.[0]?._id,
        masterId: result?.priority?.[0]?._id ?? "",
        label: result?.priority?.[0]?.display ?? "",
        id: result?.priority?.[0]?.id ?? "",
      },
      drugCategory: getData("drug_category", "DrugCategory_masters", result),
      nature: getData("presNature", "OrderNature_masters", result),
      mode: getData("presMode", "OrderMode_masters", result),
      dispensingPharmacy: {
        label: result?.dispensing_pharmacy?.DispLocCode,
        value: result?.dispensing_pharmacy?._id,
      },
      frequencyDuration: {
        quantity: result?.dosageDurationValue ?? "",
        unit: {
          label: result?.dosageDurationUOM?.UOM ?? "",
          value: result?.dosageDurationUOM?._id ?? "",
        },
      },
      frequencyList: {
        "Twice Daily": {
          title: "BID - Twice Daily",
          row: ["Time", "Date"],
          column: TwiceDaily(stateList, result?.UseAsFreqDose ?? []),
        },
        "EVERY THURSDAY AND FRIDAY": {
          row: ["Frequency day", "Schedule", "Dose"],
          column: generateDose(stateList, result?.UseAsFreqDose ?? []),
        },
        TAPERING: {
          row: [
            "From day",
            "To day",
            "startDate",
            "endDate",
            "schedule",
            "Dose",
          ],
          column: generateTapparing(stateList, result?.useAsTaperedDose ?? []),
        },
      },
      frequency: {
        value: result?.dosageFreqCode?.[0]?._id,
        label: result?.dosageFreqCode?.[0]?.freqcode ?? "",
        id: result?.dosageFreqCode?.[0]?.id,
        frequency: result?.dosageFreqCode?.[0]?.scheduleline,
        type: result?.dosageFreqCode?.[0]?.frequencytype?.display,
        shortdesc: result?.dosageFreqCode?.[0]?.shortdesc,
        // value: result?.dosageFreqCode?._id,
      },
      notes: result?.notes,

      slidingScale: {
        row: [
          "Blood sugar (mg/dl)",
          "Low dose scale",
          "Mod dose scale",
          "High dose scale",
        ],
        column: [
          ["70-130", 0, 0, 0],
          ["131-180", 2, 4, 0],
          ["181-240", 4, 8, 12],
          ["241-300", 6, 10, 16],
          ["301-350", 8, 12, 20],
          ["351-400", 10, 16, 24],
          [">400", 12, 20, 28],
        ],
      },
    });
    fetchDropData(result);
    setLoader(false);
  };

  const getData = (value, stateName, result) => {
    let data = null;
    if (result?.[value]) {
      data = stateList?.[stateName]?.data.filter(
        (val) => val.value === result[value]
      )[0];
    }
    if (
      !data &&
      (value === "ivInfusionRateUOM" || value === "IVDosageDurationUOM")
    ) {
      data = stateList.DurationType_masters.data.filter(
        (val) => val.label === "min"
      )[0];
    }
    if (!data && value === "priority") {
      data = stateList.Priority_masters.data.filter(
        (val) => val.label === "1"
      )[0];
    }
    if (!data && value === "presMode") {
      data = stateList.OrderMode_masters.data.filter(
        (val) => val.label === "Regular"
      )[0];
    }
    return data;
  };
  const getAdditiveDetails = (value, result) => {
    let arr = [];

    if (result?.[value]?.length > 0) {
      result[value].map((val) => {
        let dose = stateList?.drugMaster_masters?.data.filter(
          (value) => value.value === val.drugcode
        )[0];
        arr.push({
          description: dose,
          dosage: {
            quantity: val.dosageQty,
            unit: stateList?.strengthUom_masters?.data.filter(
              (value) => value.value === val.dosageQtyUOM
            )[0],
          },
          strength: dose?.StrengthValues + " " + dose?.StrengthUOM,
          dosageForm: dose?.FormCode,
        });
      });
    }
    return arr;
  };

  const fetchDropData = async (result) => {
    if (result?.requestor?.[0]) {
      const orgId = stateList?.Organization_masters?.data.filter(
        (value) => value?.value === result.requestor?.[0].organization
      )?.[0]?.id;
      if (orgId) {
        const role = await dispatch(
          actions.ORDER_TYPE_REQ_DETAILS_ROLE({
            id: orgId,
          })
        );
        const practRole = role?.payload?.data?.filter?.(
          (value) => value?._id === result.requestor?.[0].practRole
        )?.[0];
        const pract = await dispatch(
          actions.ORDER_TYPE_REQ_DETAILS_LAB({
            id: practRole?.id,
            _id: practRole?._id,
            orgId,
          })
        );
        const practioner = pract?.payload?.data?.filter?.(
          (value) => value?.value === result.requestor?.[0].practId
        )?.[0];
        const requestorDetails = {
          organization: result.requestor?.[0]
            ? stateList?.Organization_masters?.data.filter(
                (value) => value?.value === result.requestor?.[0].organization
              )?.[0]
            : null,
          practitionerRole: practRole,
          orderBy: practioner,
        };
        setState({
          ...state,
          requestorDetails,
        });
      }
    }
  };

  const [state, setState] = React.useState({});

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showSchedule, setShowSchedule] = React.useState(false);
  const [anchorIndex, setanchorIndex] = React.useState(null); //multiple calenders
  const [slidingModal, setSlidingModal] = React.useState(false);
  const handleClick = (index, event) => {
    setAnchorEl(event);
    setanchorIndex(index);
  };

  const handleScheduleLine = (event) => {
    setShowSchedule(true);
  };
  // ///all actions click events
  const handleActionClick = (type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "go-back":
        props.onCancel();
        break;
      case "change-form":
        state.drugType = value;
        state.formType = value?.label;
        setState({
          ...state,
        });
        break;
      case "frequency":
        state.frequency = value;
        setState({
          ...state,
        });
        break;
      case "sliding-scale":
        setSlidingModal(true);
        break;
    }
  };

  const [isErr, setIsErr] = useState(false);

  const UpdateMedication = async () => {
    
    let isError = getErrorValidation(state);
    if (isError) {
      setIsErr(true);
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }
    // props.backDrop.setBackDrop({
    //     ...props.backDrop,
    //     open: true,
    //     message: "Updating....",
    // });
    let payload = CreateJson(state, EditData);

    let statusHistory = getStatusHistory(
      state.statusData,
      EditData,
      personData?.[0]?._id,
      state
    );

    let res = await dispatch(
      actions.SAVE_MEDICATIONS({
        ...payload,
        statushistory: statusHistory,
        orgId: EditData?.org_Id?._id,
        patientid: EditData?.patient_Id?._id,
        confirmedby: personData?.[0]?._id ?? "",
        encountertype: "CodingMaster/10264",
        createdby: EditData?.createdby?.[0]?._id,
        updatedby: personData?.[0]._id,
        encounterid: EditData?.encounter_Id?._id,
        prescripionType: state?.prescripionType?.value ?? "",
      })
    );
    if (res?.payload?.data) {
      props.handlepharmacyRefresh();
      // window.location.reload();
      props.handleMedicationClose();
      if (props.isAdd) {
        props.setisOpenMedcation && props.setisOpenMedcation(false);
      }
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "The Medication cannot be updated",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  const AddMedication = async () => {
    let isError = getErrorValidation(state);

    if (isError) {
      setIsErr(true);
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }

    let payload = CreateJson(state, EditData);

    delete payload.editId;
    let res = await dispatch(
      actions.SAVE_MEDICATIONS({
        ...payload,
        orgId: props?.orgID ?? "0",
        patientid: props?.patientID,
        encounterid: props?.encounterID,
        encountertype:
          props?.encountertype?.type?.[0]?._id ?? "CodingMaster/10264",
        status_id: props.statusID,
        isAdd: true,
        prescripionType: state?.prescripionType?.value ?? "",
      })
    );

    if (res?.payload?.data) {
      // props.handlepharmacyRefresh();
      // window.location.reload();
      props.handleMedicationClose();
      props.handleRefreshButtonClick();
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "The Medication cannot be updated",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  const getForm = (formType) => {
    switch (formType) {
      case "IV":
        return (
          <IVForm
            styles={styles}
            stateList={stateList}
            handleInputChange={handleInputChange}
            handleActionClick={handleActionClick}
            optionsList={optionsList}
            data={state?.ivForm}
            isErr={isErr}
          />
        );
      default:
        return (
          <UnitDoseForm
            styles={styles}
            handleInputChange={handleInputChange}
            handleActionClick={handleActionClick}
            optionsList={optionsList}
            data={state?.unitDose ?? {}}
            isErr={isErr}
            isAdd={props.isAdd}
          />
        );
    }
  };

  // ///all input changes
  const handleInputChange = async (selector, value, parent) => {
    
    if (parent) state[parent][selector] = value; ///for common selector forms
    state[selector] = value;
    if (selector === "description" && parent === "unitDose") {
      state.unitDose.name = value;
    }
    if ((parent === "unitDose" || parent === "ivForm") && value?.result) {
      const result = value?.result
        ? JSON.parse(JSON.stringify(value?.result))
        : {};

      if (parent === "ivForm") {
        state.ivForm = {
          ...state.ivForm,
          strength:
            (value?.StrengthValues || "") +
            " " +
            (result?.StrengthValuesPossible?.[0]?.StrengthUOM?.UOM ||
              value?.StrengthUOM ||
              ""),
          volume: {
            quantity: value?.StrengthVolume || "",
            unit:
              result?.StrengthValuesPossible?.[0]?.StrengthVolumeUoM?.UOM ||
              value?.StrengthVolumeUoM ||
              "",
          },
          route: getData("DefaultRouteCode", "DrugRoutes_masters", result),
          orderDescription: result?.LongDesc ?? "",
          totalVolume: {
            unit: result?.StrengthValuesPossible?.TotalVolContentUoM ?? "",
            quantity: result?.StrengthValuesPossible?.TotalVolContent ?? "",
          },
        };
      } else {
        state.drugType = {
          label: result?.DrugType?.shortdesc ?? "",
          value: result?.DrugType?._id ?? "",
        };
        state.drugCategory = getData(
          "DrugCategory",
          "DrugCategory_masters",
          result
        );
        state.unitDose = {
          ...state.unitDose,
          dosageForm: result?.FormCode ?? "",

          dosage: {
            min: {
              unit:
                result?.StrengthValuesPossible?.[0]?.AllowedOrderDoseUOM?.UOM ||
                value?.AllowedOrderDoseUOM ||
                "",
              quantity:
                result?.StrengthValuesPossible?.[0]?.AllowedOrderDoseVal ?? "",
            },
            max: {
              quantity: "",
              unit: "",
            },
          },
          strength: {
            min: {
              unit:
                result?.StrengthValuesPossible?.[0]?.StrengthUOM?.UOM ||
                value?.StrengthUOM,
              quantity: value?.StrengthValues ?? "",
            },
            max: {
              quantity: "",
              unit: "",
            },
          },
          route: getData("DefaultRouteCode", "DrugRoutes_masters", result),
        };
      }

      const fil = props?.encountertype?.Location?.[0]
        ? stateList?.Organization_masters?.data.filter(
            (value) =>
              value?.value ===
              props?.encountertype?.Location?.[0]?.managingOrgID
          )?.[0]
        : null;

      state.requestorDetails = {
        organization: fil,
        practitionerRole: {
          value: props?.encountertype?.PractitionerRole_id?.[0]?.[0]?._id,
          label:
            props?.encountertype?.PractitionerRole_id?.[0]?.[0]?.display ?? "",
          id: props?.encountertype?.PractitionerRole_id?.[0]?.[0]?.id,
        },
        orderBy: {
          value: props?.encountertype?.Practitioner_id?.[0]?.name?.[0]?._id,
          label:
            props?.encountertype?.Practitioner_id?.[0]?.name?.[0]?.text ?? "",
        },
      };
      setState({
        ...state,
      });

      if (fil) {
        dispatch(
          actions.ORDER_TYPE_REQ_DETAILS_ROLE({
            id: fil?.id,
          })
        );
      }
      if (props?.encountertype?.PractitionerRole_id?.[0]?.[0]?.id) {
        dispatch(
          actions.ORDER_TYPE_REQ_DETAILS_LAB({
            id: props?.encountertype?.PractitionerRole_id?.[0]?.[0]?.id,
            _id: props?.encountertype?.PractitionerRole_id?.[0]?.[0]?._id,
            orgId: fil?.id,
          })
        );
      }
    } else if (parent === "unitDose" || parent === "ivForm") {
      // if (value?.length > 1) {
      await dispatch(actions.DRUGMASTER_MASTER({ searchText: value }));
      // }
    }

    setState({
      ...state,
    });
  };

  const getDateFunction = async (date, value, end, duration_) => {
    

    const getDate = await dispatch(
      actions.GET_ORDER_DATE({
        // DurationValue: duration,
        DurationValue:
          duration_ || JSON.parse(state?.frequencyDuration?.quantity),
        OrderStartDate: moment(
          (date === "startDate" && value) || state.startDate,
          "DD-MM-YYYY,hh:mm A"
        ).unix(),
        OrderEndDate: moment(
          (date === "endDate" && value) || end || state.endDate,
          "DD-MM-YYYY,hh:mm A"
        ).unix(),
        DurationType:
          // state?.frequencyDuration?.unit?.label === 'Doses' ? 'Days' : state?.frequencyDuration?.unit?.label,
          state?.frequencyDuration?.unit?.label,
        FrequencyType: state?.frequency?.label,
      })
    );

    return getDate;
  };

  const handleDurationInputChange = async (selector, value, parent) => {
    

    if (parent) state[parent][selector] = value; ///for common selector forms

    if (selector === "startDate") {
      let count = state.frequencyDuration.quantity;

      let pr = state?.frequencyDuration?.unit?.label ?? "days";

      let end = moment(moment(value, "DD-MM-YYYY,hh:mm A"))
        .add(count, pr?.toLowerCase())
        .format("DD-MM-YYYY,h:mm a");

      // let duration_ = moment(
      //   moment(state.startDate, "DD-MM-YYYY,hh:mm A").unix() * 1000
      // ).diff(
      //   moment(state.endDate, "DD-MM-YYYY,hh:mm A").unix() * 1000,
      //   pr?.toLowerCase()
      // );

      // count = duration_;

      if (count) {
        const getDate = await getDateFunction("startDate", value, end);

        state.startDate = moment(
          getDate?.payload?.data?.orderStartDate * 1000
        ).format("DD-MM-YYYY,hh:mm A");
        state.endDate = moment(
          getDate?.payload?.data?.orderEndDate * 1000
        ).format("DD-MM-YYYY,hh:mm A");
        // state.endDate = end;

        let duration = moment(
          moment(getDate?.payload?.data?.orderEndDate * 1000)
        ).diff(
          moment(getDate?.payload?.data?.orderStartDate * 1000),
          pr?.toLowerCase()
        );

        if (pr) {
          state.frequencyDuration.quantity = duration + 1;
        }
      }
    } else if (selector === "endDate") {
      let pr =
        state?.frequencyDuration?.unit?.label === "Doses"
          ? "Days"
          : state?.frequencyDuration?.unit?.label;

      let duration_ = moment(
        moment(value, "DD-MM-YYYY,hh:mm A").unix() * 1000
      ).diff(
        moment(state.startDate, "DD-MM-YYYY,hh:mm A").unix() * 1000,
        pr?.toLowerCase()
      );

      const getDate = await getDateFunction(
        "endDate",
        value,
        false,
        duration_ + 1
      );

      state.startDate = moment(
        getDate?.payload?.data?.orderStartDate * 1000
      ).format("DD-MM-YYYY,hh:mm A");

      state.endDate = moment(
        getDate?.payload?.data?.orderEndDate * 1000
      ).format("DD-MM-YYYY,hh:mm A");

      // let count = state.frequencyDuration.quantity

      let duration = moment(
        moment(getDate?.payload?.data?.orderEndDate * 1000)
      ).diff(
        moment(getDate?.payload?.data?.orderStartDate * 1000),
        pr?.toLowerCase()
      );

      if (pr) {
        state.frequencyDuration.quantity = duration + 1;
      }
    } else {
      let count = parseInt(value.quantity);

      if (count && value?.unit?.label) {
        let pr =
          state?.frequencyDuration?.unit?.label === "Doses"
            ? "Days"
            : state?.frequencyDuration?.unit?.label;

        let prV = value?.unit?.label === "Doses" ? "Days" : value?.unit?.label;

        const end = moment(state.startDate, "DD-MM-YYYY,hh:mm A")
          .add(count, prV?.toLowerCase())
          .format("DD-MM-YYYY,h:mm a");

        const getDate = await getDateFunction(
          "startDate",
          state.startDate,
          end
          // state.endDate || end
        );

        state.startDate = moment(
          getDate?.payload?.data?.orderStartDate * 1000
        ).format("DD-MM-YYYY,hh:mm A");

        state.endDate = moment(
          getDate?.payload?.data?.orderEndDate * 1000
        ).format("DD-MM-YYYY,hh:mm A");

        let duration = moment(
          moment(getDate?.payload?.data?.orderEndDate * 1000)
        ).diff(
          moment(getDate?.payload?.data?.orderStartDate * 1000),
          pr?.toLowerCase()
        );

        if (pr) {
          state.frequencyDuration.quantity = Math.abs(duration + 1);
        }
      }
    }

    if (selector !== "startDate" && selector !== "endDate") {
      state[selector] = JSON.parse(JSON.stringify(value));
    }

    setState({
      ...state,
    });
  };

  const handleFreqencyList = (label, value) => {
    state["frequencyList"][label] = value;
    setState({
      ...state,
    });
  };

  const handleScheduleLineChange = (name, value, index) => {
    const newState = JSON.parse(JSON.stringify(state));
    
    newState.frequency.frequency[index] = {
      ...newState.frequency.frequency[index],
      [name]: value,
    };
    // state.frequency.frequency = newState;
    setState({
      ...newState,
    });
  };

  const { parent_id } = props;
  return (
    <>
      {loader ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Div
          id={`${parent_id}_medication_parent_div`}
          inLineStyles={{ borderRadius: "10px", height: "100%", width: "100%" }}
        >
          {/* form header START*/}
          {/* <FormHeader
                parent_id={'medication_form'}
                stateList={stateList}
                date={EditData.createdAt + " - Created"}
                name={getOrderName({ accessionNum: EditData.createdName }, loggedUserInfo?.data?.completeName || "")}
                title={state?.formType == 1 ? state?.unitDose?.name?.label : state?.ivForm?.name?.label}
                goBack={() => handleActionClick("go-back")}
                currentStatus={"inactive"}
                color={statusColors[state.statusData?.label]?.color}
                currentStatusText={state.statusData?.label === "Hold" ? `${state.statusData?.label} ${state.statusperiod ? `till ${moment(state.statusperiod, "DD-MM-YYYY,h:mm a").format("DD, MMM")}` : ''}` : state.statusData?.label ?? ""}
                openStausInfo={isFirstVisit ? () => false : (event) => handleClick(4, event?.currentTarget)}
            /> */}
          {/* form header START*/}
          <div
            id={`${parent_id}_medication_description_div`}
            style={{
              height: "65vh",
              overflow: "auto",
              background: "#fff",
              borderRadius: "10px",
              margin: "10px 0px",
              border: "1px solid #DEE5EC",
            }}
          >
            <Row
              id={`${parent_id}_medication_description_row`}
              style={{ padding: 7 }}
            >
              {/* drug type START */}
              <Col
                id={`${parent_id}_medication_Drug_type_col`}
                xs={12}
                sm={12}
                md={3}
                lg={2}
                xl={2}
                inLineStyles={styles.colPadding}
              >
                <Select
                  id={`${parent_id}_medication_Drug_type_select`}
                  getOptionLabel="label"
                  showArrow
                  // id={"mf-drugType"}
                  label={"Drug Type"}
                  isRequired
                  placeholder={"Select..."}
                  options={stateList?.drugType_masters?.data ?? []}
                  labelStyle={styles.labelStyle}
                  inLineStyles={
                    isErr && !state?.drugType?.value
                      ? styles.errorborderStyle
                      : styles.borderStyle
                  }
                  hoverColor={UIColor.lineBorderFill.color}
                  value={state?.drugType?.label}
                  onChangeValue={(value) =>
                    handleActionClick("change-form", value)
                  }
                  error={isErr && !state?.drugType?.value ? true : false}
                />
              </Col>

              {/* drug type END */}
              {getForm(state?.formType)}
              {/* common forms start*/}
              {/* setion -1 START */}
              {/* select frequency START */}
              <Col
                id={`${parent_id}_medication_Frequency_col`}
                xs={12}
                sm={12}
                md={3}
                lg={2}
                xl={2}
                inLineStyles={styles.colPadding}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Select
                    id={`${parent_id}_medication_Frequency_select`}
                    getOptionLabel="label"
                    showArrow
                    // id={"mf-frequency"}
                    label={"Frequency"}
                    isRequired
                    placeholder={"Select..."}
                    options={stateList?.FrequencyMaster_masters?.data}
                    labelStyle={styles.labelStyle}
                    inLineStyles={
                      isErr && !state?.frequency?.value
                        ? styles.errorborderStyle
                        : styles.borderStyle
                    }
                    hoverColor={UIColor.lineBorderFill.color}
                    value={state?.frequency?.label}
                    onChangeValue={(value) =>
                      handleActionClick("frequency", value)
                    }
                  />
                  {state?.frequency?.label && (
                    <InfoIcon
                      onClick={(e) => handleScheduleLine(e)}
                      aria-describedby={"scheduleLine-popover"}
                      style={{
                        minWidth: "14px",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                    />
                  )}
                </div>
              </Col>

              {/* select frequency END */}
              {/* tappering start */}
              <Col
                id={`${parent_id}_medication_tappering_col`}
                xs={4}
                sm={4}
                md={1}
                lg={1}
                xl={1}
                inLineStyles={{
                  ...styles.colPadding,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Text
                  id={`${parent_id}_medication_tappering_space_text`}
                  inLineStyles={styles.label}
                >
                  &nbsp;
                </Text>
                {isShowTapIcon.indexOf(state?.frequency?.label ?? "") > -1 && (
                  <Avatar
                    id={`${parent_id}_medication_tappering_avatar`}
                    style={{
                      ...styles.iconWrapper,
                      backgroundColor:
                        anchorIndex === 5
                          ? UIColor.primaryColor.color
                          : UIColor.lineBorderFill.color,
                    }}
                    onClick={(event) => handleClick(5, event?.currentTarget)}
                  >
                    <TapperingIcon
                      id={`${parent_id}_medication_tapperingicon`}
                      color={anchorIndex === 5 ? "white" : "black"}
                    />
                  </Avatar>
                )}
              </Col>
              {/* tappering end */}
              {/* Frequency Duration END */}
              <Col
                id={`${parent_id}_medication_Duration_col`}
                xs={12}
                sm={12}
                md={3}
                lg={2}
                xl={2}
                inLineStyles={styles.colPadding}
              >
                <Text
                  id={`${parent_id}_medication_Duration_text`}
                  inLineStyles={styles.label}
                >
                  Duration
                  {
                    <span
                      id={`${parent_id}_medication_Duration_star_span`}
                      style={styles.required}
                    >
                      *
                    </span>
                  }
                </Text>
                <InputGroup
                  parent_id={"medication_form"}
                  title="Duration"
                  isRequired={true}
                  options={stateList?.DurationType_masters?.data || []}
                  handleChange={handleDurationInputChange}
                  data={
                    // state?.frequencyDuration?.quantity
                    //   ?
                    state?.frequencyDuration
                    //   : {}
                  }
                  disabled={false}
                  type={null}
                  error={
                    isErr &&
                    !state?.frequencyDuration?.quantity &&
                    !state?.frequencyDuration?.unit?.value
                      ? true
                      : false
                  }
                />
              </Col>
              {/* Frequency Duration END */}
              {/* start date START*/}
              <Col
                id={`${parent_id}_medication_Start_Date_col`}
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                inLineStyles={styles.colPadding}
              >
                <Text
                  id={`${parent_id}_medication_Start_Date_text`}
                  inLineStyles={styles.label}
                >
                  Start Date{<span style={styles.required}>*</span>}
                </Text>
                <Div
                  id={`${parent_id}_medication_Start_Date_div`}
                  inLineStyles={styles.dateWrapper}
                  style={{
                    border:
                      isErr && !state?.startDate
                        ? `1px solid ${UIColor.error.color}`
                        : `1px solid ${UIColor.lineBorderFill.color}`,
                  }}
                >
                  <Text
                    id={`${parent_id}_medication_${
                      state?.startDate
                        ? state?.startDate
                        : " ".replaceAll(" ", "_")
                    }_text`}
                    className="pc_regular"
                    style={styles.dateText}
                  >
                    {state?.startDate ? state?.startDate : " "}
                  </Text>
                  <Text
                    id={`${parent_id}_medication_CalendarIcon_text`}
                    style={styles.calenderText}
                    aria-describedby={"calender-popover"}
                    onClick={(event) => handleClick(1, event?.currentTarget)}
                  >
                    <CalendarIcon
                      id={`${parent_id}_medication_CalendarIcon`}
                      color={UIColor.secondaryText.color}
                      width={"16"}
                      height={"16"}
                    />
                  </Text>
                </Div>
              </Col>
              {/* start date END*/}
              {/* end date START */}
              <Col
                id={`${parent_id}_medication_End_Date_col`}
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                inLineStyles={styles.colPadding}
              >
                <Text
                  id={`${parent_id}_medication_End_Date_text`}
                  inLineStyles={styles.label}
                >
                  End Date
                  {/* {<span style={styles.required}>*</span>} */}
                </Text>
                <Div
                  id={`${parent_id}_medication_End_Date_div`}
                  inLineStyles={styles.dateWrapper}
                  style={{
                    border: `1px solid ${UIColor.lineBorderFill.color}`,
                  }}
                >
                  <Text
                    id={`${parent_id}_medication_${
                      state?.endDate ? state?.endDate : " ".replace(" ", "_")
                    }_text`}
                    className="pc_regular"
                    style={styles.dateText}
                  >
                    {state?.endDate ? state?.endDate : " "}
                  </Text>
                  <Text
                    id={`${parent_id}_medication_calender-popover_text`}
                    style={styles.calenderText}
                    aria-describedby={"calender-popover"}
                    onClick={(event) => handleClick(2, event?.currentTarget)}
                  >
                    <CalendarIcon
                      id={`${parent_id}_medication_calender-popover_CalendarIcon`}
                      color={UIColor.secondaryText.color}
                      width={"16"}
                      height={"16"}
                    />
                  </Text>
                </Div>
              </Col>
              {/* end date END */}
              {/* calender popover start*/}
              <Popover
                id={"calender-popover"}
                open={anchorEl}
                anchorEl={anchorEl}
                onClose={() => {
                  setAnchorEl(null);
                  setanchorIndex(null);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                // classes={{ paper: styles.muiPopovers }}
              >
                {(anchorIndex === 1 || anchorIndex === 2) && (
                  <DatePicker
                    id={`${parent_id}_medication_DatePicker`}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                    selected={
                      (anchorIndex === 1 &&
                        new Date(
                          moment(state.startDate, "DD-MM-YYYY,hh:mm A")
                        )) ||
                      (anchorIndex === 2 &&
                        new Date(
                          moment(
                            state.endDate || new Date(),
                            "DD-MM-YYYY,hh:mm A"
                          )
                        ))
                    }
                    minDate={
                      anchorIndex === 2
                        ? new Date(
                            moment(state.startDate, "DD-MM-YYYY,hh:mm A")
                          )
                        : new Date()
                    }
                    inline
                    onChange={(date) => {
                      handleDurationInputChange(
                        anchorIndex === 1 ? "startDate" : "endDate",
                        moment(date).format("DD-MM-YYYY,hh:mm A")
                      );
                      // handleInputChange((anchorIndex === 1 ? 'startDate' : 'endDate'), moment(date).format('DD-MM-YYYY,hh:mm A'));
                      // setAnchorEl(null);
                    }}
                  />
                )}
                {anchorIndex === 4 && (
                  <ChangeStatus
                    parent_id={"medication_form"}
                    cancelButton="Cancel"
                    cancelButtonAction={() => setAnchorEl(null)}
                    saveButton="Save"
                    saveButtonAction={(data, reason, statusperiod) => {
                      setState({
                        ...state,
                        statusReason: reason,
                        statusData: data,
                        statusperiod: statusperiod,
                      });
                      setAnchorEl(null);
                    }}
                    statusOption={stateList?.status_masters?.data}
                    changeStatusData={state?.statusData}
                    statusReason={state?.statusReason}
                    statusperiod={state?.statusperiod}
                    statusHistory={
                      // EditData.statushistory
                      []
                    }
                  />
                )}
                {anchorIndex === 5 && (
                  <FrequencyList
                    parent_id={"medication_form"}
                    title={state?.frequency?.label}
                    row={
                      state?.frequencyList[state?.frequency?.label]?.row ?? []
                    }
                    column={
                      state?.frequencyList[state?.frequency?.label]?.column ??
                      []
                    }
                    contentEditable={true}
                    columnEditable={state?.frequency?.label === "TAPERING"}
                    styles={styles}
                    handleSave={handleFreqencyList}
                  />
                )}
              </Popover>
              {/* calender popover end */}
              {/* setion -1 END */}
              {/* setion -2 START */}

              {/* Show Frequency Schedule Line */}
              {showSchedule && (
                <Col
                  id={`${parent_id}_medication_Frequency_col`}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  inLineStyles={styles.colPadding}
                >
                  <ScheduleLine
                    handleScheduleLineChange={handleScheduleLineChange}
                    type={state?.frequency?.type}
                    data={state?.frequency}
                    styles={styles}
                  />
                </Col>
              )}

              <Col
                id={`${parent_id}_medication_Priority_col`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={styles.colPadding}
              >
                <Row
                  id={`${parent_id}_medication_Priority_row`}
                  inLineStyles={{ display: "flex", flexDirection: "row" }}
                >
                  {/* priority START */}
                  <Grid
                    id={`${parent_id}_medication_Priority_grid`}
                    xs={12}
                    sm={12}
                    md={4}
                    lg
                  >
                    <Select
                      id={`${parent_id}_medication_Priority_select`}
                      getOptionLabel="label"
                      showArrow
                      label={"Priority"}
                      isRequired
                      placeholder={"Select..."}
                      options={stateList?.Priority_masters?.data}
                      labelStyle={styles.labelStyle}
                      inLineStyles={
                        isErr && !state?.priority?.label
                          ? styles.errorborderStyle
                          : styles.borderStyle
                      }
                      hoverColor={UIColor.lineBorderFill.color}
                      value={state?.priority?.label}
                      onChangeValue={(value) =>
                        handleInputChange("priority", value)
                      }
                    />
                  </Grid>
                  {/* priority END */}
                  {/* drugCategory START */}
                  <Grid
                    id={`${parent_id}_medication_drugCategory_grid`}
                    xs={12}
                    sm={12}
                    md={4}
                    lg
                    className={classes.deskPadding}
                  >
                    <Select
                      id={`${parent_id}_medication_drugCategory_select`}
                      getOptionLabel="label"
                      showArrow
                      label={"Drug Category"}
                      isRequired
                      placeholder={"Select..."}
                      options={stateList?.DrugCategory_masters?.data}
                      labelStyle={styles.labelStyle}
                      inLineStyles={
                        isErr && !state?.drugCategory?.label
                          ? styles.errorborderStyle
                          : styles.borderStyle
                      }
                      hoverColor={UIColor.lineBorderFill.color}
                      value={state?.drugCategory?.label}
                      onChangeValue={(value) =>
                        handleInputChange("drugCategory", value)
                      }
                    />
                  </Grid>
                  {/* drugCategory END */}
                  {/* nature START */}
                  <Grid
                    id={`${parent_id}_medication_nature_grid`}
                    xs={12}
                    sm={12}
                    md={4}
                    lg
                    className={classes.deskPadding}
                  >
                    <Select
                      id={`${parent_id}_medication_nature_select`}
                      getOptionLabel="label"
                      showArrow
                      label={"Nature"}
                      isRequired
                      placeholder={"Select..."}
                      options={stateList?.OrderNature_masters?.data}
                      labelStyle={styles.labelStyle}
                      inLineStyles={
                        isErr && !state?.nature?.label
                          ? styles.errorborderStyle
                          : styles.borderStyle
                      }
                      hoverColor={UIColor.lineBorderFill.color}
                      value={state?.nature?.label}
                      onChangeValue={(value) =>
                        handleInputChange("nature", value)
                      }
                    />
                  </Grid>
                  {/* nature END */}
                  {/* mode START */}
                  <Grid
                    id={`${parent_id}_medication_mode_grid`}
                    xs={12}
                    sm={12}
                    md={4}
                    lg
                    className={classes.deskPadding}
                  >
                    <Select
                      id={`${parent_id}_medication_mode_select`}
                      getOptionLabel="label"
                      showArrow
                      label={"Mode"}
                      isRequired
                      placeholder={"Select..."}
                      options={stateList?.OrderMode_masters?.data}
                      labelStyle={styles.labelStyle}
                      inLineStyles={
                        isErr && !state?.mode?.label
                          ? styles.errorborderStyle
                          : styles.borderStyle
                      }
                      hoverColor={UIColor.lineBorderFill.color}
                      value={state?.mode?.label}
                      onChangeValue={(value) =>
                        handleInputChange("mode", value)
                      }
                    />
                  </Grid>
                  {/* mode END */}
                  {/* dispensingPharmacy START */}
                  <Grid
                    id={`${parent_id}_medication_dispensingPharmacy_grid`}
                    xs={12}
                    sm={12}
                    md={4}
                    lg
                  >
                    <Select
                      id={`${parent_id}_medication_dispensingPharmacy_select`}
                      getOptionLabel="label"
                      showArrow
                      label={"Dispensing Pharmacy"}
                      // isRequired
                      placeholder={"Select..."}
                      options={stateList.DrugStoreDispLoc_masters?.data}
                      labelStyle={styles.labelStyle}
                      inLineStyles={
                        // isErr && !state?.dispensingPharmacy?.label
                        //   ? styles.errorborderStyle
                        //   :
                        styles.borderStyle
                      }
                      hoverColor={UIColor.lineBorderFill.color}
                      value={state?.dispensingPharmacy?.label}
                      onChangeValue={(value) =>
                        handleInputChange("dispensingPharmacy", value)
                      }
                    />
                  </Grid>
                  {/* dispensingPharmacy END */}
                </Row>
              </Col>
              {/* setion -2 END */}
              {/* select diagonsis START */}
              <Col
                id={`${parent_id}_medication_selectDiagnosis_col`}
                xs={12}
                sm={12}
                md={9}
                lg={9}
                xl={9}
                inLineStyles={styles.colPadding}
              >
                <SelectBox
                  size="small"
                  id={`${parent_id}_medication_selectDiagnosis_selectbox`}
                  // requireStart={true}
                  multi={true}
                  top_title={"Select Diagnosis"}
                  list={
                    stateList?.DiagnosisAgainstEncounter_masters?.data?.length >
                    0
                      ? stateList?.DiagnosisAgainstEncounter_masters?.data
                      : masters ?? []
                  }
                  placeholder={"Search here..."}
                  value={state?.selectDiagnosis ?? []}
                  onchange={(e, value) =>
                    handleInputChange("selectDiagnosis", value)
                  }
                  // error={isErr && !state?.selectDiagnosis ? true : false}
                  medForm
                  handleDiagnosis={handleDiagnosis}
                />
                {/* <MultiSelect
                            id={"mf-selectDiagnosis"}
                            label="Select Diagnosis"
                            getOptionLabel="label"
                            placeholder=""
                            options={stateList.DiagnosisAgainstEncounter_masters?.data}
                            showArrow
                            isRequired
                            labelStyle={styles.labelStyle}
                            inLineStyles={styles.borderStyle}
                            hoverColor={UIColor.lineBorderFill.color}
                            borderColor="#9b9b9b"
                            data={state?.selectDiagnosis ? [state?.selectDiagnosis] : null}
                            onChangeValue={(e, data) => { handleInputChange("selectDiagnosis", e) }}
                        /> */}
              </Col>
              {/* select diagonsis End */}
              {/* select indication START */}
              <Col
                id={`${parent_id}_medication_indication_col`}
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                inLineStyles={styles.colPadding}
              >
                <Select
                  id={`${parent_id}_medication_indication_select`}
                  getOptionLabel="label"
                  showArrow
                  label={"Indication (Reason)"}
                  // isRequired
                  placeholder={"Select Indication"}
                  options={stateList?.Reasoncode_masters?.data ?? []}
                  labelStyle={styles.labelStyle}
                  inLineStyles={
                    // isErr && !state?.indication?.label
                    //   ? styles.errorborderStyle
                    //   :
                    styles.borderStyle
                  }
                  hoverColor={UIColor.lineBorderFill.color}
                  value={state?.indication?.label}
                  onChangeValue={(e) => handleInputChange("indication", e)}
                />
              </Col>
              {/* select diagonsis End */}
              {/* select patient instruction START */}
              <Col
                id={`${parent_id}_medication_patientInstruction_col`}
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                inLineStyles={styles.colPadding}
              >
                <SelectCard
                  parent_id={"medication_form"}
                  label="Patient Instructions"
                  placeholder="Select Patient instructions"
                  contentPlaceHolder="Instructions"
                  value={state?.patientInstruction?.label}
                  content={state?.patientInstruction?.content}
                  options={stateList?.Patient_Instruction.data}
                  onChangeValue={(data) => {
                    handleInputChange("patientInstruction", data);
                    handleInputChange("patientInstructionDes", data?.display);
                  }}
                  isRequired={false}
                />
                {/* {state?.patientInstruction?.display && <Div style={styles.patientInstructionDiv}>{state?.patientInstruction?.display}</Div>} */}
                {state?.patientInstruction?.display && (
                  <Div
                    id={`${parent_id}_medication_patientInstructionDes_div`}
                    style={styles.patientInstructionDiv}
                  >
                    <InputBase
                      id={`${parent_id}_medication_patientInstructionDes_inputbase`}
                      className="pc_regular"
                      onChange={(data) =>
                        handleInputChange(
                          "patientInstructionDes",
                          data.target.value
                        )
                      }
                      value={state?.patientInstructionDes}
                      inputProps={{ "aria-label": "naked" }}
                      fullWidth
                    />
                  </Div>
                )}
              </Col>
              {/* select patient instruction END */}
              {/* select notes START */}
              <Col
                id={`${parent_id}_medication_notes_col`}
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                inLineStyles={styles.colPadding}
              >
                <Div style={styles.labelStyle}>Notes</Div>
                <TextInput
                  id={`${parent_id}_medication_notes_textinput`}
                  label={""}
                  placeholder="type here...."
                  hoverColor={UIColor.lineBorderFill.color}
                  value={state?.notes}
                  style={{ borderColor: UIColor.lineBorderFill.color }}
                  onChange={(e) => {
                    handleInputChange("notes", e.target.value);
                  }}
                />
              </Col>
              {/* select notes END */}
              <Col
                xs={12}
                sm={12}
                md={5}
                lg={6}
                xl={6}
                inLineStyles={{ marginTop: -10, marginBottom: 10 }}
              >
                <Text className={classes.autocomplete_label}>
                  {"Prescripion Type"}
                  {/* {props?.isRequired || true ? (
                    <span style={{ color: "red", marginLeft: 5 }}>*</span>
                  ) : null} */}
                </Text>
                <Autocomplete
                  options={stateList?.order_prescription_list?.data ?? []}
                  value={state?.prescripionType}
                  placeholder={"search.."}
                  getOptionLabel={(option) => option.label}
                  label={"Prescripion Type"}
                  size="small"
                  //   isRequired
                  onChange={(value, val) =>
                    handleInputChange("prescripionType", val)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      //   onChange={(e) =>
                      //     props?.onInputChange(props?.keyCode, e?.target?.value)
                      //   }
                      placeholder={"search.."}
                      variant="outlined"
                      //   error={props?.error}
                    />
                  )}
                  //   error={isErr && !data?.name ? true : false}
                />
              </Col>
              {/* reqest detail form START */}
              <Col
                id={`${parent_id}_medication_requestorDetails_col`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={styles.colPadding}
              >
                <RequestorDetailsForm
                  parent_id={"medication_form"}
                  requestorDetails={state?.requestorDetails}
                  title="Requestor Details"
                  action="+ Add Requestor Details"
                  stateList={stateList}
                  isErr={isErr}
                  actionButton={(data) =>
                    handleInputChange("requestorDetails", data)
                  }
                />
                {/* reqest detail form START */}
              </Col>
              {/* common forms end */}
              <SlidingScale
                parent_id={"medication_form"}
                open={slidingModal}
                handleModal={setSlidingModal}
                row={state?.slidingScale?.row}
                column={state?.slidingScale?.column}
                handleSave={handleInputChange}
              />
            </Row>
          </div>
          <Row
            id={`${parent_id}_medication_buttons_row`}
            inLineStyles={{ background: "#fff", borderRadius: "10px" }}
          >
            <Col
              id={`${parent_id}_medication_buttons_col`}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ margin: "10px 0px" }}
            >
              <Div
                id={`${parent_id}_medication_buttons_div`}
                inLineStyles={styles.flexEnd}
              >
                <Button
                  id={`${parent_id}_medication_Cancel_button`}
                  // onClick={() => goBack()}
                  variant={"text"}
                  onClick={() => {
                    props.OrderInfoClose();
                    if (props.isAdd) {
                      props.setisOpenMedcation && props.setisOpenMedcation();
                    }
                  }}
                  inLineStyles={styles.cancelBtn}
                >
                  Cancel
                </Button>
                <Button
                  id={`${parent_id}_medication_Update_button`}
                  variant={"outlined"}
                  inLineStyles={styles.filledBtn}
                  onClick={props.isAdd ? AddMedication : UpdateMedication}
                >
                  {props.isAdd ? "Save" : "Update"}
                </Button>
              </Div>
            </Col>
          </Row>
        </Div>
      )}
    </>
  );
};

const optionsList = {};

export default withAllContexts(withRouter(MedicationForm));

function TapperingIcon(props) {
  return (
    <svg
      id="TapperingIcon_svg"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill={props.color}
    >
      <path d="M11.951 9.268A2.955 2.955 0 009 12.219v13.415a2.955 2.955 0 002.951 2.951h7.274a6.933 6.933 0 01-.763-1.611h-6.511a1.343 1.343 0 01-1.341-1.341v-11h17.17v3.756a6.982 6.982 0 011.61.95v-7.12a2.955 2.955 0 00-2.951-2.951zm1.61 7.512a.805.805 0 100 1.61h1.073a.805.805 0 100-1.61zm4.293 0a.805.805 0 000 1.61h4.56a6.949 6.949 0 012.684-.537c.162 0 .321.013.48.024a.8.8 0 00-.748-1.1zm7.246 2.147a5.9 5.9 0 105.9 5.9 5.9 5.9 0 00-5.9-5.9zM13.561 20a.805.805 0 100 1.61h1.073a.805.805 0 100-1.61zm4.293 0a.805.805 0 100 1.61h1.06A6.986 6.986 0 0120.072 20zm7.244.537a.537.537 0 01.537.537v3.22h3.22a.537.537 0 010 1.073h-3.22v3.22a.537.537 0 11-1.073 0v-3.219h-3.22a.537.537 0 110-1.073h3.22v-3.227a.537.537 0 01.538-.532zm-11.537 2.682a.805.805 0 100 1.61h1.073a.805.805 0 100-1.61zm4.293 0a.805.805 0 000 1.61h.268a6.984 6.984 0 01.194-1.61z"></path>
    </svg>
  );
}
