import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import { Box, Grid, Typography, Button, IconButton } from "@material-ui/core";
import { TrashIcon } from "../../assets";

import { Autocomplete } from "@material-ui/lab";
import qdmstyles from "./styles.json";
import "./index.css";
import copy from "fast-copy";
import Color from "../../themes/default.json"
import makeStyles from "@material-ui/core/styles/makeStyles";

var aes256 = require("aes256");

const useStyles = makeStyles((theme) => ({
  editorderchildtitle: {
    color: theme.palette.primary.light
  },
  editorderdetails: {
    color: theme.palette.text.primary,
  },
  autocomplete: {
    "& .App1-MuiOutlinedInput-notchedOutline":{
      borderColor:theme.palette.border.textField
    },
   },
   paper: {
     color:"#6a7888"
   },
   notchedOutline:{
    borderColor:theme.palette.border.textField
  },
  add: {
    "&.App1-MuiButton-contained:hover": {
        backgroundColor: theme.palette.button.hover
      },
      color:theme.palette.button.text,
      backgroundColor:theme.palette.button.background,
  },
}));
function EditOrderingdetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pharmacy_orderrouting_orderingfacility_dropdownData = useSelector(
    (state) =>
      state
        ?.Edit_Orderingdetailspharmacy_orderrouting_orderingfacility_dropdown_ZBjTJReducer
        ?.pharmacy_orderrouting_orderingfacility_dropdown_ZBjTJ?.data
  );
  const pharmacy_orderrouting_sourcelocationtype_dropdownData = useSelector(
    (state) =>
      state
        ?.Edit_Orderingdetailspharmacy_orderrouting_sourcelocationtype_dropdown_ZBjTJReducer
        ?.pharmacy_orderrouting_sourcelocationtype_dropdown_ZBjTJ?.data
  );
  const pharmacy_orderrouting_sourcelocation_dropdownData = useSelector(
    (state) =>
      state
        ?.Edit_Orderingdetailspharmacy_orderrouting_sourcelocation_dropdown_a8JXXReducer
        ?.pharmacy_orderrouting_sourcelocation_dropdown_a8JXX?.data
  );

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
    edit_orderingdetails_zbjtj: null,
    edit_orderingdetails_zoc1d: null,
    edit_orderingdetails_0k7nc: null,
    edit_orderingdetails_a8jxx: null,
    edit_orderingdetails_3vcns: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const onload_zbjtj = async (event, data) => {
    let index = data;
    const payload0 = {
      history: props.history.location.state,
      data: data,
    };
    let data0 = await dispatch(
      actions.Edit_Orderingdetailspharmacy_orderrouting_orderingfacility_dropdown_ZBjTJ(
        payload0
      )
    );
    const payload1 = {
      history: props.history.location.state,
      data: data,
    };
    let data1 = await dispatch(
      actions.Edit_Orderingdetailspharmacy_orderrouting_sourcelocationtype_dropdown_ZBjTJ(
        payload1
      )
    );

    let updatestate = {};
    setState({
      ...state,
      ...updatestate,
      edit_orderingdetails_zbjtj: event?.target?.value,
    });
  };
  const onclick_zoc1d = (e, index) => {
    let updatestate = {};

    setState({
      ...state,
      ...updatestate,
      edit_orderingdetails_zoc1d: e?.target?.value,
    });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];
    props.setFun([...stateValue, {}]);
  };
  const onchange_0k7nc = (e, index) => {
    let updatestate = {};

    setState({ ...state, ...updatestate, edit_orderingdetails_0k7nc: e });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = { ...stateValue[index], edit_orderingdetails_0k7nc: e };
    props.setFun(stateValue);
  };
  const onchange_a8jxx = async (event, data) => {
    let index = data;
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = {
      ...stateValue[index],
      edit_orderingdetails_a8jxx: event,
    };
    props.setFun(stateValue);

    const payload0 = {
      edit_orderingdetails_loccode: event?.code?._id ?? event?.code,
      edit_orderingdetails_orgcode:
        state.edit_orderingdetails_0k7nc?.entitycode?._id ??
        state.edit_orderingdetails_0k7nc?.entitycode,
      history: props.history.location.state,
      data: data,
    };
    let data0 = await dispatch(
      actions.Edit_Orderingdetailspharmacy_orderrouting_sourcelocation_dropdown_a8JXX(
        payload0
      )
    );

    let updatestate = {};
    setState({ ...state, ...updatestate, edit_orderingdetails_a8jxx: event });
  };
  const onchange_3vcns = (e, index) => {
    let updatestate = {};

    setState({ ...state, ...updatestate, edit_orderingdetails_3vcns: e });
    let stateValue = props.statevalue;
    stateValue = stateValue ? JSON.parse(JSON.stringify(stateValue)) : [{}];

    stateValue[index] = { ...stateValue[index], edit_orderingdetails_3vcns: e };
    props.setFun(stateValue);
  };
  React.useEffect(() => {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events
    onload_zbjtj();
    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {
    if (
      pharmacy_orderrouting_sourcelocationtype_dropdownData?.loading !==
      pharmacy_orderrouting_sourcelocationtype_dropdownData?.loading
    ) {
      if (
        !pharmacy_orderrouting_sourcelocationtype_dropdownData?.loading &&
        pharmacy_orderrouting_sourcelocationtype_dropdownData.error
      ) {
      } else if (
        !pharmacy_orderrouting_sourcelocationtype_dropdownData?.loading &&
        !pharmacy_orderrouting_sourcelocationtype_dropdownData.error
      ) {
      }
    }

    if (
      pharmacy_orderrouting_sourcelocation_dropdownData?.loading !==
      pharmacy_orderrouting_sourcelocation_dropdownData?.loading
    ) {
      if (
        !pharmacy_orderrouting_sourcelocation_dropdownData?.loading &&
        pharmacy_orderrouting_sourcelocation_dropdownData.error
      ) {
      } else if (
        !pharmacy_orderrouting_sourcelocation_dropdownData?.loading &&
        !pharmacy_orderrouting_sourcelocation_dropdownData.error
      ) {
      }
    }
  }, [state]);

  const onDeleteOrderingDetailsClick = (index) => {
    const { statevalue } = props || {};
    const copiedstatevalue = copy(statevalue);
    copiedstatevalue.splice(index, 1);
    props.setFun(copiedstatevalue);
  };

  const { data, index } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Box key={"0"} id={"AYYFA"} m={1} component={"div"}>
            <Box key={"0"} id={"8OHOP"} m={1} component={"div"}>
              <Grid
                key={"0"}
                id={"7a5rV"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <Grid
                  key={"0"}
                  id={"4If2S"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  xs={"6"}
                  xl={"6"}
                  sm={"6"}
                  md={"6"}
                  lg={"6"}
                >
                  <Typography
                    key={"0"}
                    id={"VYVmg"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    className={classes.editorderdetails}
                    variant={"body1"}
                    children={t(
                      "pharmacy.editNewDrugCatalogMaster.orderingDetails"
                    )}
                    style={qdmstyles.mwyZ}
                  ></Typography>
                </Grid>
                <Grid
                  key={"1"}
                  id={"RVSUy"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  xs={"6"}
                  xl={"6"}
                  sm={"6"}
                  md={"6"}
                  lg={"6"}
                >
                  <Button
                    key={"0"}
                    onClick={(e) => onclick_zoc1d(e, index)}
                    disabled={props?.isDisabled == true ? true : false}
                    id={"h8YUT"}
                    variant={"text"}
                    color={"primary"}
                    children={"+ Add New"}
                    style={qdmstyles.ZOCD}
                  ></Button>
                </Grid>
              </Grid>
            </Box>
            <Box
              key={"1"}
              id={"7ncTc"}
              m={1}
              component={"div"}
              onLoad={(e) => props.setFun(props?.statevalue ?? [{}])}
            >
              {(props?.statevalue ?? [{}])?.map((data, index) => {
                return (
                  <React.Fragment>
                    <Grid
                      key={"0"}
                      id={"kjwa3"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      item={true}
                      spacing={"2"}
                      // style={qdmstyles.ZBjTJ}
                      onLoad={(e) => props.setFun(props?.statevalue ?? [{}])}
                    >
                      {/* {(props?.statevalue ?? [{}])?.map((data, index) => {
                        return ( */}
                      <React.Fragment>
                        <Grid
                          key={"0"}
                          id={"kxf7u"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"4"}
                          item={true}
                          md={"4"}
                          sm={"4"}
                          xl={"4"}
                          xs={"4"}
                        >
                          <Typography
                            key={"0"}
                            id={"ordering_facility_heading"}
                            align={"inherit"}
                            color={"initial"}
                            className={classes.editorderchildtitle}
                            display={"initial"}
                            variant={"body1"}
                            children={
                              <span>
                                Ordering Facility
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            }
                            aliasName={"orderingfacilityheading"}
                            style={qdmstyles.WOUK}
                          ></Typography>

                          <Autocomplete
                            id={"0k7NC"}
                            style={qdmstyles?.kNC}
                            disabled={props?.isDisabled == true ? true : false}
                            value={
                              data?.edit_orderingdetails_0k7nc
                                ? data?.edit_orderingdetails_0k7nc
                                : data?.facility
                                  ? { name: data?.facility?.name }
                                  : data.orderingdetails_fqnvb
                                    ? data.orderingdetails_fqnvb
                                    : ""
                            }
                            classes={{
                              paper: classes.paper
                            }}
                            className={classes.autocomplete}
                            onLoad={() =>
                              setState({
                                edit_orderingdetails_0k7nc:
                                  state?.edit_orderingdetails_0k7nc ?? null,
                              })
                            }
                            getOptionLabel={(option) => option?.["name"]}
                            options={
                              pharmacy_orderrouting_orderingfacility_dropdownData.result ??
                              []
                            }
                            size={"small"}
                            onChange={(e, newvalue) =>
                              onchange_0k7nc(newvalue, index)
                            }
                            renderInput={(params) => (
                              <ALLMCORE.TextField
                                {...params}
                                label={""}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"HkE9H"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"4"}
                          item={true}
                          md={"4"}
                          sm={"4"}
                          xl={"4"}
                          xs={"4"}
                        >
                          <Typography
                            key={"0"}
                            id={"location_type_heading"}
                            align={"inherit"}
                            color={"initial"}
                            className={classes.editorderchildtitle}
                            display={"initial"}
                            variant={"body1"}
                            children={
                              <span>
                                Location Type
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            }
                            aliasName={"locationtypeheading"}
                            style={qdmstyles.MnmTz}
                          ></Typography>
                          <Autocomplete
                            id={"a8JXX"}
                            style={qdmstyles?.aJXX}
                            disabled={props?.isDisabled == true ? true : false}
                            onLoad={() =>
                              setState({
                                edit_orderingdetails_a8jxx:
                                  state?.edit_orderingdetails_a8jxx ?? null,
                              })
                            }
                            classes={{
                              paper: classes.paper
                            }}
                            className={classes.autocomplete}
                            getOptionLabel={(option) => option?.["display"]}
                            value={
                              data?.edit_orderingdetails_a8jxx
                                ? data?.edit_orderingdetails_a8jxx
                                : data?.locationType
                                  ? { display: data?.locationType?.display }
                                  : data?.locationType
                                    ? { display: data?.locationType }
                                    : data.orderingdetails_pgqom
                                      ? data.orderingdetails_pgqom
                                      : ""
                            }
                            options={
                              pharmacy_orderrouting_sourcelocationtype_dropdownData.result ??
                              []
                            }
                            size={"small"}
                            onChange={(e, newvalue) =>
                              onchange_a8jxx(newvalue, index)
                            }
                            renderInput={(params) => (
                              <ALLMCORE.TextField
                                {...params}
                                label={""}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          key={"2"}
                          id={"ZheNQ"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"4"}
                          item={true}
                          md={"4"}
                          sm={"4"}
                          xs={"4"}
                          xl={"4"}
                        >
                          <Typography
                            key={"0"}
                            id={"ordering_location_heading"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            className={classes.editorderchildtitle}
                            variant={"body1"}
                            children={"Ordering Location"}
                            aliasName={"orderinglocationheading"}
                            style={qdmstyles.XnQu}
                          ></Typography>
                          <Grid item container xs={12}>
                            <Grid item xs>
                              <Autocomplete
                                id={"3vcNs"}
                                style={qdmstyles?.vcNs}
                                disabled={props?.isDisabled == true ? true : false}
                                onLoad={() =>
                                  setState({
                                    edit_orderingdetails_3vcns:
                                      state?.edit_orderingdetails_3vcns ?? null,
                                  })
                                }
                                classes={{
                                  paper: classes.paper
                                }}
                                className={classes.autocomplete}
                                getOptionLabel={(option) =>
                                  option?.["locationID"]
                                }
                                value={state?.edit_orderingdetails_3vcns}
                                options={
                                  pharmacy_orderrouting_sourcelocation_dropdownData.result ??
                                  []
                                }
                                size={"small"}
                                onChange={(e, newvalue) =>
                                  onchange_3vcns(newvalue, index)
                                }
                                renderInput={(params) => (
                                  <ALLMCORE.TextField
                                    {...params}
                                    label={""}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item>
                              <IconButton
                                onClick={() =>
                                  onDeleteOrderingDetailsClick(index)
                                }
                              >
                                <TrashIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                      {/* //   );
                      // })} */}
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Box>
          </Box>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(EditOrderingdetails));
