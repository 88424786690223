import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  StyledPaper,
} from "../../components";
import Typography from "@material-ui/core/Typography";
import { FormControlLabel, Switch } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.light,
    fontSize: "14px",
    lineHeight: "20px",
    textTransform: "uppercase",
    fontWeight: "600",
    marginBottom: 10,
  },
  paper: {
    backgroundColor: theme.palette.background.table,
    padding: "10px",
    borderRadius: "8px",
    marginTop: "10px",
  },
  switchContainer: {
    display: "flex",
    gap: 20,
    marginBottom: 10
  }
}));

function PharmacyLocationConfig({
  allowbulkverification,
  allowbulkfilling,
  considerlatearrival,
  changeState,
}) {
  const classes = useStyles();
  return (
    <StyledPaper className={classes.paper}>
      <Typography className={classes.title}>Location Config</Typography>

      <div className={classes.switchContainer}>
      <FormControlLabel
          value="start"
          control={
            <Switch
              checked={allowbulkverification}
              onChange={(event) => {
                changeState("allowbulkverification", event.target.checked);
              }}
              color={"primary"}
            />
          }
          label="Allow bulk verification"
          labelPlacement="start"
        />

        <FormControlLabel
          value="start"
          control={
            <Switch
              checked={allowbulkfilling}
              onChange={(event) => {
                changeState("allowbulkfilling", event.target.checked);
              }}
              color={"primary"}
            />
          }
          label="Allow bulk filling"
          labelPlacement="start"
        />

        <FormControlLabel
          value="start"
          control={
            <Switch
              checked={considerlatearrival}
              onChange={(event) => {
                changeState("considerlatearrival", event.target.checked);
              }}
              color={"primary"}
            />
          }
          label="Consider late arrival"
          labelPlacement="start"
        />
      </div>
    </StyledPaper>
  );
}

export default PharmacyLocationConfig;
