import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { LoggerContext } from "../../contexts";
import { withAllContexts } from "../../HOCs";

import * as ALL from "../../components";
import * as ALLMCORE from "@material-ui/core";

import {
  Paper,
  Grid,
  Box,
  Avatar,
  Typography,
  Divider,
  Button,
  TextField,
  Switch,
  withStyles,
} from "@material-ui/core";
import { MuiStepper, ComponentRender } from "custom-material-ui-component";
import { CreateRule } from "sequence-rule-builder";

import { Autocomplete } from "@material-ui/lab";
import { styles } from "./styles";
import qdmstyles from "./styles.json";

const allowedChar = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

class EditNewPharmacy extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      permissionData: null,
      editnewpharmacy_wsf2t: null,
      editnewpharmacy_14nsh: null,
      editnewpharmacy_l6jpw: null,
      editnewpharmacy_sfhjn: null,
      editnewpharmacy_qa35i: null,
      editnewpharmacy_apwbs: null,
      editnewpharmacy_mr2at: null,
      editnewpharmacy_zntxm: null,
      editnewpharmacy_dslum: null,
      editnewpharmacy_qwvdb: null,
      editnewpharmacy_bf657: null,
      editnewpharmacy_m7pur: null,
      editnewpharmacy_1pizh: null,
      editnewpharmacy_ofyo3: null,
      editnewpharmacy_a0gku: null,
      editnewpharmacy_8q65i: null,
      editnewpharmacy_kbnjk: null,
      editData: [],
      WorkingDays: [],
      OverRide: [],
      url: "",
      index: null,
      index_ride: null,
      editDataOverRide: [],
      editId: [],
      isRule: false,
      isOpen: false,
      isExist: false,
      groupData: [],
      ruleData: [],
      group: {},
      rule: {},
      status:
        this.props.pharmacy_singlereadData?.status === "active" ? true : false,
      filtervalue: "",

      // Location Config States
      allowbulkverification: false,
      allowbulkfilling: false,
      considerlatearrival: false,
      autocalculate: false,
    };
  }
  readDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      // async event handlers
      reader.onload = (e) => resolve(reader.result);
      reader.onerror = (e) => reject(reader.error);
      reader.readAsDataURL(file);
    });
  };
  handleChangePhotoFileInput = (e) => {
    const target = e.currentTarget;
    const file = target.files.item(0);

    // validate file as image
    if (!file.type.startsWith("image/")) {
      alert("File is not an image");
      return;
    }

    // store reference to the File object and a base64 representation of it
    this.readDataUrl(file).then((dataUrl) => {
      this.setState({
        // file,
        url: dataUrl,
        // objectUrl: URL.createObjectURL(file)
      });
    });
  };
  changeState = (key, value) => {
    const state = JSON.parse(JSON.stringify(this.state));
    let value_ = JSON.parse(JSON.stringify(value));
    if (state.index !== null && state.index >= 0 && key === "WorkingDays") {
      state.WorkingDays[state.index] = value_[value_.length - 1];
      this.setState({
        ...state,
        index: null,
      });
      return;
    }
    if (
      state.index_ride !== null &&
      state.index_ride >= 0 &&
      key === "OverRide"
    ) {
      state.OverRide[state.index_ride] = value[value.length - 1];
      this.setState({
        ...state,
        index_ride: null,
      });
      return;
    }
    this.setState({
      ...state,
      [key]: value,
    });
  };
  handleworkdayedit = (a, b, c) => {
    this.setState({
      workingdays_sspf0: true,
      editData: a,
      index: b,
    });
  };
  handleworkdayeditoverride = (a, b, c) => {
    this.setState({
      workingdays_sspf0: true,
      editDataOverRide: a,
      index_ride: b,
    });
  };

  handleworkdaydelete = (a, b, c) => {
    if (this.props.location.state?.isdisabled !== "inactive") {
      var arr = this.state.WorkingDays.filter((v, i) => b !== i && v);
      this.setState({
        ...this.state,
        WorkingDays: arr,
      });
    }
  };
  handleworkdaydeleteoverride = (a, b, c) => {
    if (this.props.location.state?.isdisabled !== "inactive") {
      var arr = this.state.OverRide.filter((v, i) => b !== i && v);
      this.setState({
        ...this.state,
        OverRide: arr,
      });
    }
  };
  onchange_wsf2t = (e, index) => {
    let updatestate = { editnewpharmacy_bf657: e?.display };

    this.setState({ ...updatestate, editnewpharmacy_wsf2t: e });
  };
  onchange_14nsh = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, editnewpharmacy_14nsh: e });
  };
  onchange_l6jpw = (e, index) => {
    let updatestate = { editnewpharmacy_m7pur: e?.display };

    this.setState({ ...updatestate, editnewpharmacy_l6jpw: e });
  };
  pasteHandle = (e) => {
    let filteranswer = this.removeSymbols(e.clipboardData.getData("text"));
    let updatestate = {};
    this.setState({
      ...updatestate,
      filtervalue: filteranswer,
    });
  };
  removeSymbols = (name) => {
    return name
      .substring(0, 10)
      .split("")
      .filter((item) => allowedChar.includes(item))
      .join("")
      .toUpperCase();
  };

  onchange_sfhjn = (e, index) => {
    let updatestate = { editnewpharmacy_1pizh: e?.target?.value };

    if (this.state.filtervalue) {
      let value = this.removeSymbols(e?.target?.value);
      this.setState({
        ...updatestate,
        editnewpharmacy_sfhjn: value,
        filtervalue: "",
      });
    } else {
      if (e.target.value.length <= 10)
        this.setState({
          ...updatestate,
          editnewpharmacy_sfhjn: e?.target?.value
            .replace(/[^a-zA-Z0-9]/gi, "")
            ?.toUpperCase(),
        });
    }

    // if (e.target.value.length <= 10) {

    //   this.setState({
    //     ...updatestate,
    //     editnewpharmacy_sfhjn: e?.target?.value.replace(/[^a-zA-Z0-9]/gi, "")?.toUpperCase(),
    //   });
    // }
  };
  onchange_qa35i = (e, index) => {
    if (e.target.value.length <= 30) {
      let updatestate = {};

      this.setState({
        ...updatestate,
        editnewpharmacy_qa35i: e?.target?.value,
      });
    }
  };
  onchange_apwbs = (e, index) => {
    if (e.target.value.length <= 100) {
      let updatestate = {};

      this.setState({
        ...updatestate,
        editnewpharmacy_apwbs: e?.target?.value,
      });
    }
  };
  onchange_mr2at = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, editnewpharmacy_mr2at: e });
  };
  onchange_zntxm = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, editnewpharmacy_zntxm: e });
  };
  onchange_dslum = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, editnewpharmacy_dslum: e });
  };
  onchange_qwvdb = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, editnewpharmacy_qwvdb: e });
  };
  onchange_bf657 = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, editnewpharmacy_bf657: e?.target?.value });
  };
  onchange_m7pur = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, editnewpharmacy_m7pur: e?.target?.value });
  };
  onchange_1pizh = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, editnewpharmacy_1pizh: e?.target?.value });
  };
  onchange_ofyo3 = (e, index) => {
    let updatestate = {};

    this.setState({ ...updatestate, editnewpharmacy_ofyo3: e?.target?.value });
  };

  validateOrderingDetails = (data) => {
    const CONSTANTS = {
      facility: "Ordering Facility",
      locationType: "Location Type",
    };

    const errors = [];

    data.forEach((item) => {
      if (!Boolean(item?.facility)) {
        errors.indexOf(CONSTANTS.facility) === -1 &&
          errors.push(CONSTANTS.facility);
      }
      if (!Boolean(item?.locationType)) {
        errors.indexOf(CONSTANTS.locationType) === -1 &&
          errors.push(CONSTANTS.locationType);
      }
    });

    if (errors.length > 0) {
      this.props.alert.setSnack({
        open: true,
        msg: `${errors.join(",")} is required.`,
        severity: "error",
      });
      return true;
    }

    return false;
  };

  onclick_save = async () => {
    // edit_orderingdetails_0k7nc
    // edit_orderingdetails_a8jxx
    const data__ = this.state.EditOrderingdetails?.map((v) => {
      return {
        facility:
          v?.edit_orderingdetails_0k7nc?._id ||
          v.facility?._id ||
          v.orderingdetails_fqnvb?._id,
        locationType:
          v?.edit_orderingdetails_a8jxx?._id ||
          v.locationType?._id ||
          v.orderingdetails_pgqom?._id,
        _key: v?._key,
      };
    });

    if (!this.state?.EditOrderingdetails?.length > 0) {
      this.props.alert.setSnack({
        open: true,
        msg: `Please Fill the ordering details!`,
        severity: "error",
      });
      return;
    }

    if (this.validateOrderingDetails(data__)) {
      return;
    }

    const data_ = await this.props.sigleRead({
      LocationMasterId: this.props.pharmacy_singlereadData?.result?.[0]?._id,
    });
    await this.props.drugUpdate({
      data: data__ || [],
      key: data_?.payload?.result?.[0]?._key ?? "",
      LocationMasterId:
        this.props.pharmacy_singlereadData?.result?.[0]?._id ?? "",
      // "LocationMaster/10010",
      editRule: this.state.editId ?? [],
    });
    this.props.alert.setSnack({
      open: true,
      msg: `Order routing update successfully!.`,
      severity: "success",
    });
    // this.props.history.push("/pharmacy");
    window.location.href = "/pharmacy";
  };

  errValid = () => {
    var error = [];
    let ValidateEmail = (email) => {
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return Boolean(re.test(email));
    };

    if (!this.state.editnewpharmacy_1pizh) {
      error.push("Pharmacy ID");
    }

    if (!this.state.editnewpharmacy_qa35i) {
      error.push("Short Description");
    }
    if (!this.state.editnewpharmacy_apwbs) {
      error.push("Long Description");
    }
    if (!this.state.editnewpharmacy_mr2at?.display) {
      error.push("Operational Status");
    }
    if (!this.state.editnewpharmacy_dslum?.display) {
      error.push("Location Role Type");
    }

    // if (!this.state.url) {
    //   error.push("Upload Image");
    // }
    if (
      !this.state.EditAddressPharmacy?.[0]?.editaddresspharmacy_wdhjk &&
      !this.state.EditAddressPharmacy?.[0]?.text
    ) {
      error.push("Location address");
    }
    // if (
    //   !this.state.EditGeoLocationPharmacy?.[0]
    //     ?.edit_geo_location_pharmacy_ufkfi &&
    //   !this.state.EditGeoLocationPharmacy?.[0]?.longitude
    // ) {
    //   error.push("Longitude");
    // }

    if (!this.state.WorkingDays?.length > 0) {
      error.push("Working Days");
    }
    // if (!this.state.OverRide?.length > 0) {
    //   error.push("Over Ride");
    // }

    if (this.state.Editcontactpoint?.length === 0) {
      error.push("Contact Point");
    }

    if (this.state.Editcontactpoint?.length > 0) {
      const _ = this.state.Editcontactpoint?.map((v) => {
        if (Object.keys(v).length === 0) {
          error.push("Contact Mode");
        }

        if (!v.editcontactpoint_tbhrq?.display) {
          error.push("Contact Point" + " " + "Use");
        }

        if (!v.editcontactpoint_7gob3?.display) {
          error.push("Contact Point" + " " + "Priority");
        }
        if (!v?.editcontactpoint_1gu0d && !v?.editcontactpoint_tqcwn?.display) {
          error.push("Contact Point" + " " + "Priority");
        } else {
          if (
            (v.editcontactpoint_tqcwn?.display?.toLowerCase() === "phone" ||
              v.editcontactpoint_tqcwn?.display?.toLowerCase() === "mobile") &&
            (!v.editcontactpoint_1gu0d ||
              v.editcontactpoint_1gu0d?.length !== 10)
          ) {
            error.push(
              "Contact Point" +
              " " +
              v.editcontactpoint_tqcwn?.display?.toLowerCase()
            );
          }
        }

        if (
          v.editcontactpoint_tqcwn?.display?.toLowerCase() === "email" &&
          !ValidateEmail(v.editcontactpoint_1gu0d)
        ) {
          error.push(
            "Contact Point" +
            " " +
            v.editcontactpoint_tqcwn?.display?.toLowerCase()
          );
        }

        if (
          v.editcontactpoint_tqcwn?.display === "Pager" &&
          (v.editcontactpoint_1gu0d === undefined ||
            v.editcontactpoint_1gu0d?.length === 0)
        ) {
          error.push(
            "Contact Point" +
            " " +
            v.editcontactpoint_tqcwn?.display?.toLowerCase()
          );
        }

        if (
          v.editcontactpoint_tqcwn?.display === "OTHER" &&
          (v.editcontactpoint_1gu0d === undefined ||
            v.editcontactpoint_1gu0d?.length === 0)
        ) {
          error.push(
            "Contact Point" +
            " " +
            v.editcontactpoint_tqcwn?.display?.toLowerCase()
          );
        }

        if (
          v.editcontactpoint_tqcwn?.display === "SMS" &&
          (v.editcontactpoint_1gu0d === undefined ||
            v.editcontactpoint_1gu0d?.length === 0)
        ) {
          error.push(
            "Contact Point" +
            " " +
            v.editcontactpoint_tqcwn?.display?.toLowerCase()
          );
        }
        if (
          v.editcontactpoint_tqcwn?.display === "FAX" &&
          (v.editcontactpoint_1gu0d === undefined ||
            v.editcontactpoint_1gu0d?.length === 0)
        ) {
          error.push(
            "Contact Point" +
            " " +
            v.editcontactpoint_tqcwn?.display?.toLowerCase()
          );
        }
        if (
          v.editcontactpoint_tqcwn?.display === "URL" &&
          (v.editcontactpoint_1gu0d === undefined ||
            v.editcontactpoint_1gu0d?.length === 0)
        ) {
          error.push(
            "Contact Point" +
            " " +
            v.editcontactpoint_tqcwn?.display?.toLowerCase()
          );
        }
      });
    }

    let chars = error;

    let uniqueChars = [...new Set(chars)];

    return uniqueChars;
  };

  onclick_a0gku = async (event, data) => {
    let index = data;

    const payload0 = {
      editnewpharmacy__key:
        this.props?.location?.state?.pharmacytable_pharmacy_row_key,
      editnewpharmacy_status: this.state.status == true ? "active" : "inactive",
      parentLocationID_desc:
        this.state.editnewpharmacy_zntxm?.locationID?._id ||
        this.state.editnewpharmacy_zntxm?.locationID ||
        this.props.pharmacy_singlereadData?.result?.[0]?.parentLocationID_desc,
      // ? {
      //     locationID:
      //       this.state.editnewpharmacy_zntxm?.locationID?._id ||
      //       this.state.editnewpharmacy_zntxm?.locationID ||
      //       this.props.pharmacy_singlereadData?.result?.[0]
      //         ?.parentLocationID_desc ||
      //       "",
      //     _key:
      //       this.props.pharmacy_singlereadData?.result?.[0]
      //         ?.parentLocationID_desc?._key ?? "",
      //   }
      // : "",
      editnewpharmacy_operationalstatus:
        this.state.editnewpharmacy_mr2at?._id ??
        this.state.editnewpharmacy_mr2at,
      editnewpharmacy_shortdesc:
        this.state.editnewpharmacy_qa35i?._id ??
        this.state.editnewpharmacy_qa35i,
      editnewpharmacy_longdesc:
        this.state.editnewpharmacy_apwbs?._id ??
        this.state.editnewpharmacy_apwbs,
      telecom: (this.state?.Editcontactpoint ?? [])?.map((val) => {
        return {
          system: val.editcontactpoint_tqcwn?._id || val.system?._id || "",
          value: val.editcontactpoint_1gu0d || val.value || "",
          use: val.editcontactpoint_tbhrq?._id || val.use?._id || "",
          rank: val.editcontactpoint_7gob3?._id || val.rank?._id || "",
        };
      }),
      address: (
        JSON.parse(JSON.stringify(this.state?.EditAddressPharmacy)) ||
        JSON.parse(
          JSON.stringify(
            this.props.pharmacy_singlereadData?.result?.[0]?.address
          )
        ) ||
        []
      )?.map((val) => {
        if (val?._key) {
          let a = val.editaddresspharmacy_wdhjk;
          delete val.editaddresspharmacy_wdhjk;
          return {
            ...val,
            period: [],
            text: a?._id ? a?._id : a ? a : val.text,
          };
        } else {
          return {
            text: val.editaddresspharmacy_wdhjk?._id
              ? val.editaddresspharmacy_wdhjk?._id
              : val.editaddresspharmacy_wdhjk
                ? val.editaddresspharmacy_wdhjk
                : val.text,
            use: "",
            line: "",
            city: "",
            district: "",
            state: "",
            postalCode: "",
            country: "",
            period: [],
            latitude: "",
            longitude: "",
            Type: "",
            id: 0,
          };
        }
      }),
      position: (
        this.state?.EditGeoLocationPharmacy ||
        this.props.pharmacy_singlereadData?.result?.[0]?.position ||
        []
      )?.map((val) => {
        return {
          longitude:
            val.edit_geo_location_pharmacy_ufkfi?._id ||
            val.edit_geo_location_pharmacy_ufkfi ||
            val.longitude ||
            "",
          latitude:
            val.edit_geo_location_pharmacy_bdq8z?._id ||
            val.edit_geo_location_pharmacy_bdq8z ||
            val.latitude ||
            "",
          altitude:
            val.edit_geo_location_pharmacy_yoyzf?._id ||
            val.edit_geo_location_pharmacy_yoyzf ||
            val.altitude ||
            "",
        };
      }),
      editnewpharmacy_managingorgid:
        this.state.editnewpharmacy_14nsh?._id?._id ??
        this.state.editnewpharmacy_14nsh?._id,
      editnewpharmacy_locationroletype:
        this.state.editnewpharmacy_dslum?._id?._id ??
        this.state.editnewpharmacy_dslum?._id,
      editnewpharmacy_locationid:
        this.state.editnewpharmacy_sfhjn?._id ??
        this.state.editnewpharmacy_sfhjn,
      editnewpharmacy_parentlocationid_desc:
        this.state.editnewpharmacy_zntxm?.locationID?._id ||
        this.state.editnewpharmacy_zntxm?.locationID ||
        this.props.pharmacy_singlereadData?.result?.[0]
          ?.parentLocationID_desc ||
        "",
      editnewpharmacy_managingorgentitytype:
        this.state.editnewpharmacy_wsf2t?._id?._id ||
        this.state.editnewpharmacy_wsf2t?._id,
      pharmacy: [
        {
          inventorySubStore:
            this.state.editnewpharmacy_qwvdb?._id ??
            this.state.editnewpharmacy_qwvdb,
        },
      ],
      workingdayyear: (this.state?.WorkingDays ?? [])?.map((val) => {
        return val;
      }),
      workingdayyearoverride: (this.state?.OverRide ?? [])?.map((val) => {
        return val;
      }),
      history: this.props.history.location.state,
      data: data,
      photo: [
        {
          date: "",
          id: 0,
          fileName: "",
          fileid: "",
          filetype: "",
          objectid: "",
          url: this.state.url,
          _key:
            this.props.pharmacy_singlereadData?.result?.[0]?.photo?.[0]?._key ??
            "",
        },
      ],
      locconfig: [
        {
          code: "allowbulkverification",
          value: this.state.allowbulkverification ? "true" : "false",
        },
        {
          code: "allowbulkfilling",
          value: this.state.allowbulkfilling ? "true" : "false",
        },
        {
          code: "considerlatearrival",
          value: this.state.considerlatearrival ? "true" : "false",
        },
      ],
      autocalculate: this.state?.autocalculate,
    };
    if (this.errValid()?.length > 0) {
      this.props.alert.setSnack({
        open: true,
        msg: `${this.errValid().join(", ")} are is required!.`,
        severity: "error",
      });
      return;
    }
    // return;
    let data0 = await this.props.EditNewPharmacypharmacy_update_a0GKU(payload0);

    if (data0?.payload?.error) {
      if (
        data0?.payload?.validation_error?.[0]?.Errormsg ==
        '["locationID"] fields must be unique'
      ) {
        return this.props.alert.setSnack({
          open: true,
          msg: "Pharmacy Code Already Exists.",
          severity: "error",
        });
      } else {
        return this.props.alert.setSnack({
          open: true,
          msg: data0?.payload?.validation_error?.[0]?.Errormsg,
          severity: "error",
        });
      }
      return;
    }
    this.props.alert.setSnack({
      open: true,
      msg: `Pharmacy definition update successfully!.`,
      severity: "success",
    });
    let updatestate = { editnewpharmacy_ofyo3: 2 };
    this.setState({
      ...updatestate,
      editnewpharmacy_a0gku: event?.target?.value,
    });
  };
  onclick_8q65i = async (e, index) => {
    // await this.getDidmount();
    window.location.reload(false);
    let updatestate = { editnewpharmacy_ofyo3: 1 };

    this.setState({
      ...this.state,
      ...updatestate,
      editnewpharmacy_8q65i: e?.target?.value,
    });
  };
  onload_kbnjk = async (event, data) => {
    //let index = data;
    const payload0 = {
      editnewpharmacy__key:
        this.props?.location?.state?.pharmacytable_pharmacy_row_key,
      history: this.props.history.location.state,
      data: data,
    };
    //let data0 =
    await this.props.EditNewPharmacypharmacy_singleread_kbnjk(payload0);
    const payload1 = {
      history: this.props.history.location.state,
      data: data,
    };
    // let data1 =
    await this.props.EditNewPharmacypharmacy_entitytype_dropdown_kbnjk(
      payload1
    );
    const payload2 = {
      history: this.props.history.location.state,
      data: data,
    };
    // let data2 =
    await this.props.EditNewPharmacypharmacy_entityname_dropdown_kbnjk(
      payload2
    );
    const payload3 = {
      history: this.props.history.location.state,
      data: data,
    };
    // let data3 =
    await this.props.EditNewPharmacypharmacy_locationtype_dropdown_kbnjk(
      payload3
    );
    const payload4 = {
      history: this.props.history.location.state,
      data: data,
    };
    // let data4 =
    await this.props.EditNewPharmacypharmacy_operationstatus_dropdown_kbnjk(
      payload4
    );
    const payload5 = {
      history: this.props.history.location.state,
      data: data,
    };
    // let data5 =
    await this.props.EditNewPharmacypharmacy_parentlocationid_description_dropdown_kbnjk(
      payload5
    );
    const payload6 = {
      history: this.props.history.location.state,
      data: data,
    };
    // let data6 =
    await this.props.EditNewPharmacypharmacy_locationroletype_dropdown_kbnjk(
      payload6
    );
    const payload7 = {
      history: this.props.history.location.state,
      data: data,
    };
    // let data7 =
    await this.props.EditNewPharmacypharmacy_inventrysubtype_dropdown_kbnjk(
      payload7
    );

    let updatestate = {};
    this.setState({
      ...updatestate,
      editnewpharmacy_kbnjk: event?.target?.value,
    });
  };
  handleChange = async (v, n) => {
    if (n === "group") {
      const data = await this.props.rule_dropdown({ groupId: v?.value });
      this.setState({
        ...this.state,
        ruleData: data.payload.result,
      });
    }
    this.setState({
      ...this.state,
      [n]: v,
    });
  };

  getDidmount = async () => {
    await this.onload_kbnjk();
    const data__ = await this.props.group_dropdown();
    this.setState({
      ...this.state,
      groupData: data__.payload.result,
    });
    if (this.props.pharmacy_singlereadData?.result?.length > 0) {
      const data = await this.props.sigleRead({
        LocationMasterId: this.props.pharmacy_singlereadData?.result?.[0]?._id,
      });

      var arr = [];

      const __ = data.payload?.result?.map((v) => {
        arr.push({
          facility: v?.ordering_org ?? "",
          locationType: v?.ordering_source_type ?? "",
          _key: v?._key ?? "",
        });
        return v;
      });
      this.setState({
        ...this.state,
        editId:
          data.payload?.result?.[data.payload?.result?.length - 1]
            ?.order_criteria ?? [],
        EditOrderingdetails: arr ?? [],
        isOpen: data.payload?.result?.[data.payload?.result?.length - 1]
          ?.order_criteria
          ? true
          : false,
      });
    }
  };
  async componentDidMount() {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    await this.getDidmount();
    /// setlogger && dispatch of all events

    /// fetch all permission
  }

  ///fetching all permission

  shouldComponentUpdate(props, state) {
    if (
      this.props?.pharmacy_updateData?.loading !==
      props?.pharmacy_updateData?.loading
    ) {
      if (
        !props?.pharmacy_updateData?.loading &&
        props?.pharmacy_updateData.error
      ) {
      } else if (
        !props?.pharmacy_updateData?.loading &&
        !props?.pharmacy_updateData.error
      ) {
      }
    }

    if (
      this.props?.pharmacy_inventrysubtype_dropdownData?.loading !==
      props?.pharmacy_inventrysubtype_dropdownData?.loading
    ) {
      if (
        !props?.pharmacy_inventrysubtype_dropdownData?.loading &&
        props?.pharmacy_inventrysubtype_dropdownData.error
      ) {
      } else if (
        !props?.pharmacy_inventrysubtype_dropdownData?.loading &&
        !props?.pharmacy_inventrysubtype_dropdownData.error
      ) {
      }
    }

    return true;
  }
  componentWillUnmount() {
    this.setState({
      ...this.state,
      editId: [],
      EditOrderingdetails: [],
      isOpen: false,
    });
  }
  onclick_exist_save = (data) => {
    if (!this.state.group) {
      return this.props.alert.setSnack({
        open: true,
        msg: `Please choose the group`,
        severity: "error",
      });
    }
    if (!this.state.rule) {
      return this.props.alert.setSnack({
        open: true,
        msg: `Please choose the rule`,
        severity: "error",
      });
    }
    this.setState({
      ...this.state,
      editId: [
        {
          group: this.state.group,
          rule: this.state.rule,
        },
      ],
      isExist: false,
      isOpen: true,
      group: {},
      rule: {},
    });
  };
  setRuleBuilderAction = (data) => {
    if (!data) {
      this.setState({
        ...this.state,
        isRule: !this.state.isRule,
      });
      return;
    }
    this.setState({
      ...this.state,
      editId: [data?.Result?.[0]?.properties?.doc],
      isRule: !this.state.isRule,
      isOpen: true,
    });
  };
  onChangeSwitch = () => {
    this.setState({
      ...this.state,
      status: !this.state.status,
    });
  };

  render() {
    const {
      //pharmacy_updateData,
      pharmacy_singlereadData,
      pharmacy_entitytype_dropdownData,
      pharmacy_entityname_dropdownData,
      pharmacy_locationtype_dropdownData,
      pharmacy_operationstatus_dropdownData,
      pharmacy_parentlocationid_description_dropdownData,
      pharmacy_locationroletype_dropdownData,
      pharmacy_inventrysubtype_dropdownData,
      data,
      index,
      classes,
    } = this.props;
    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <Grid
              key={"0"}
              id={"TLuB6"}
              container={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              className={classes.nav_back}
            >
              <Grid
                key={"0"}
                id={"gniOs"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                xs={"9"}
                xl={"9"}
                sm={"9"}
                md={"9"}
                lg={"9"}
                style={qdmstyles.kvJn}
                className={classes.stepperMain}
              >
                <Box key={"0"} id={"YSVkl"} m={1} component={"div"}>
                  <Avatar
                    key={"0"}
                    id={"o9Qdp"}
                    alt={"Avatar"}
                    variant={"rounded"}
                    src={
                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/319828003"
                    }
                    style={qdmstyles.dZX}
                    onClick={
                      () => (window.location.href = "/pharmacy")
                      // this.props.history.goBack(-1)
                    }
                  ></Avatar>
                </Box>
                <Box key={"1"} id={"VHu27"} m={1} component={"div"}>
                  <Typography
                    key={"0"}
                    id={"AJhKj"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Edit Pharmacy Setup"}
                    style={qdmstyles.DmMK}
                  ></Typography>
                </Box>
                <Divider
                  key={"2"}
                  id={"ejKbU"}
                  variant={"fullWidth"}
                  flexItem={true}
                  light={true}
                  orientation={"vertical"}
                  style={qdmstyles.IZFuO}
                ></Divider>
                <MuiStepper
                  key={"3"}
                  onChange={(e) => this.onchange_ofyo3(e)}
                  id={"8MJAV"}
                  value={
                    this.state.editnewpharmacy_ofyo3
                      ? this.state.editnewpharmacy_ofyo3 - 1
                      : 0
                  }
                  stepsHeader={[
                    {
                      header: "Pharmacy Definition",
                      fullWidth: true,
                      body: {},
                    },
                    {
                      header: "Order Routing",
                      fullWidth: true,
                      body: {},
                    },
                  ]}
                  bodyRequired={""}
                  stepperActiveTextColor={"#2A60BC"}
                  alternativeLabel={""}
                  isControlleRequired={""}
                  stepperActiveColor={"#2A60BC"}
                  HeaderFontColor={"#2A60BC"}
                  previousLabel={""}
                  aliasName={"targetscreen"}
                  style={qdmstyles.ofyo}
                ></MuiStepper>
              </Grid>
              <Grid
                key={"1"}
                id={"a0vdW"}
                container={true}
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                xs={"3"}
                xl={"3"}
                sm={"3"}
                md={"3"}
                lg={"3"}
                style={(qdmstyles.pnCCh, { padding: 0 })}
              >
                <Button
                  key={"0"}
                  id={"addnewpharmacy_cancel"}
                  // variant={"text"}
                  // disabled={this.props.location.state?.isView === "inactive" ? true : false}
                  children={"Cancel"}
                  aliasName={"AddNewPharmacy_Cancel"}
                  onClick={
                    () => (window.location.href = "/pharmacy")
                    //  this.props.history.goBack(-1)
                  }
                  style={(qdmstyles.B, { margin: 0 })}
                ></Button>
                {(this.state?.editnewpharmacy_ofyo3 ?? null) === 2 && (
                  <Button
                    key={"1"}
                    onClick={(e) => this.onclick_8q65i(e, index)}
                    id={"addnewpharmacy_previ"}
                    variant={"contained"}
                    color={"primary"}
                    disabled={
                      this.props.location.state?.isView === "inactive"
                        ? true
                        : false
                    }
                    className={classes.add}
                    children={"Previous"}
                    aliasName={"AddNewPharmacy_Previ"}
                    style={(qdmstyles.QI, { padding: 4, fontSize: 13 })}
                  ></Button>
                )}{" "}
                &nbsp;
                {(this.state?.editnewpharmacy_ofyo3 ?? null) === 2 && (
                  <Button
                    key={"2"}
                    id={"addnewpharmacy_save"}
                    variant={"contained"}
                    color={"primary"}
                    children={"Update"}
                    disabled={
                      this.props.location.state?.isView === "inactive"
                        ? true
                        : false
                    }
                    aliasName={"AddNewPharmacy_Save"}
                    className={classes.add}
                    style={(qdmstyles.SCbW, { padding: 4 })}
                    // this.onclick_a0gku(e, data, index)
                    onClick={(e) => this.onclick_save(e, data, index)}
                  ></Button>
                )}{" "}
                &nbsp;
                {((this.state?.editnewpharmacy_ofyo3 ?? null) === 1 ||
                  (this.state?.editnewpharmacy_ofyo3 ?? null) === null) && (
                    <Button
                      key={"3"}
                      onClick={(e) => this.onclick_a0gku(e, data, index)}
                      id={"addnewpharmacy_saveandpro"}
                      variant={"contained"}
                      color={"primary"}
                      disabled={
                        this.props.location.state?.isView === "inactive"
                          ? true
                          : false
                      }
                      children={"Update and Proceed"}
                      className={classes.add}
                      aliasName={"addNewPharmacy_SaveAndPro"}
                      style={(qdmstyles.aGKU, { marginRight: 10, fontSize: 12 })}
                    ></Button>
                  )}{" "}
              </Grid>
            </Grid>
            <Paper key={"0"} id={"5Q2Aj"} elevation={"{3}"}>
              <Paper
                key={"0"}
                id={"3YRAV"}
                elevation={"{3}"}
                children={data?.result?.[0]}
                // style={qdmstyles.kbnjk}
                className={classes.rootBackground}
              >
                {Array.isArray(pharmacy_singlereadData?.result) &&
                  pharmacy_singlereadData?.result?.map((data, index) => {

                    if (!this.state.isfirst) {
                      this.setState({
                        ...this.state,
                        isfirst: true,
                        editnewpharmacy_wsf2t: data?.managingOrgEntityType,
                        editnewpharmacy_14nsh: data?.managingOrgID?.[0],
                        editnewpharmacy_l6jpw: data?.locationType,
                        editnewpharmacy_sfhjn: data?.locationID,
                        editnewpharmacy_qa35i: data?.shortdesc,
                        editnewpharmacy_apwbs: data?.longdesc,
                        editnewpharmacy_mr2at: data?.operationalStatus?.[0],
                        editnewpharmacy_zntxm: data?.parentLocationID_desc,
                        editnewpharmacy_dslum: data?.locationRoletype,
                        editnewpharmacy_qwvdb:
                          data?.pharmacy?.[0]?.inventorySubStore,
                        // Editcontactpoint: data?.telecom,
                        Editcontactpoint: (data?.telecom ?? [])?.map((val) => {
                          return {
                            editcontactpoint_tqcwn: val?.system,
                            editcontactpoint_1gu0d: val?.value,
                            editcontactpoint_tbhrq: val?.use,
                            editcontactpoint_7gob3: val?.rank,
                          };
                        }),
                        EditAddressPharmacy: data?.address,
                        WorkingDays: data?.workingdayyear,
                        OverRide: data?.workingdayyearoverride,
                        editnewpharmacy_bf657:
                          data?.managingOrgEntityType?.display,
                        editnewpharmacy_m7pur: data?.locationType?.text,
                        editnewpharmacy_1pizh: data?.locationID,
                        url: data?.photo?.[0]?.url ?? "",
                        EditGeoLocationPharmacy: data?.position,
                        status: data?.status === "active" ? true : false,
                        allowbulkverification:
                          data?.locconfig?.filter(
                            (item) => item?.code === "allowbulkverification"
                          )?.[0]?.value === "true",
                        allowbulkfilling:
                          data?.locconfig?.filter(
                            (item) => item?.code === "allowbulkfilling"
                          )?.[0]?.value === "true",
                        considerlatearrival:
                          data?.locconfig?.filter(
                            (item) => item?.code === "considerlatearrival"
                          )?.[0]?.value === "true",
                        autocalculate: data?.autocalculate,
                      });
                    }
                    return (
                      <React.Fragment key={index}>
                        <Grid
                          key={"0"}
                          id={"K1CZU"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          spacing={""}
                        >
                          {((this.state?.editnewpharmacy_ofyo3 ?? null) === 1 ||
                            (this.state?.editnewpharmacy_ofyo3 ?? null) ===
                            null) && (
                              <Paper
                                key={"1"}
                                id={"jvpaZ"}
                                elevation={"{3}"}
                                // style={qdmstyles.XnwG}
                                className={classes.background}
                              >
                                <Grid
                                  key={"0"}
                                  id={"1h0J2"}
                                  container={true}
                                  direction={"row"}
                                  justifyContent={"flex-start"}
                                  alignItems={"center"}
                                  lg={"12"}
                                  md={"12"}
                                  sm={"12"}
                                  xl={"12"}
                                  xs={"12"}
                                  spacing={"3"}
                                  style={qdmstyles.KPTO}
                                >
                                  <Grid
                                    key={"0"}
                                    id={"K2h8d"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    lg={"4"}
                                    md={"4"}
                                    sm={"4"}
                                    xl={"4"}
                                    xs={"4"}
                                    item={true}
                                    style={qdmstyles.tDLxY}
                                  >
                                    <Typography
                                      key={"0"}
                                      id={"entity_type_heading"}
                                      align={"inherit"}
                                      color={"initial"}
                                      display={"initial"}
                                      variant={"body1"}
                                      children={"Entity Type"}
                                      aliasName={"entitytypeheading"}
                                      style={qdmstyles.Yi}
                                    ></Typography>
                                    <Autocomplete
                                      id={"WSF2t"}
                                      style={qdmstyles?.WSFt}
                                      disabled
                                      value={{
                                        Type: "ORGTYPE",
                                        _id: "CodingMaster/10010",
                                        _key: "10010",
                                        activestatus: true,
                                        code: "OT003",
                                        display: "Facility",
                                        id: 10,
                                        shortdesc: "Facility",
                                        status: true,
                                      }}
                                      // value={
                                      //   this.state?.editnewpharmacy_wsf2t ??
                                      //   data?.managingOrgEntityType
                                      // }
                                      onLoad={() =>
                                        this.setState({
                                          editnewpharmacy_wsf2t:
                                            this.state?.editnewpharmacy_wsf2t ??
                                            data?.managingOrgEntityType,
                                        })
                                      }
                                      getOptionLabel={(option) =>
                                        option?.["display"]
                                      }
                                      options={
                                        pharmacy_entitytype_dropdownData.result ??
                                        []
                                      }
                                      classes={{
                                        paper: classes.paper,
                                      }}
                                      className={classes.autocomplete}
                                      size={"small"}
                                      onChange={(e, newvalue) =>
                                        this.onchange_wsf2t(newvalue)
                                      }
                                      renderInput={(params) => (
                                        <ALLMCORE.TextField
                                          {...params}
                                          // label={"Entity Type"}
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid
                                    key={"1"}
                                    id={"k54b4"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    lg={"4"}
                                    xl={"4"}
                                    xs={"4"}
                                    md={"4"}
                                    sm={"4"}
                                    item={true}
                                    style={qdmstyles.EzjCM}
                                  >
                                    <Typography
                                      key={"0"}
                                      id={"entity_name_heading"}
                                      align={"inherit"}
                                      color={"initial"}
                                      display={"initial"}
                                      variant={"body1"}
                                      children={"Entity Name"}
                                      aliasName={"entitynameheading"}
                                      style={qdmstyles.fPal}
                                    ></Typography>
                                    <Autocomplete
                                      id={"14Nsh"}
                                      style={qdmstyles?.Nsh}
                                      value={
                                        this.state?.editnewpharmacy_14nsh ??
                                        data?.managingOrgID?.[0]
                                      }
                                      classes={{
                                        paper: classes.paper,
                                      }}
                                      className={classes.autocomplete}
                                      disabled={
                                        this.props.location.state?.isdisabled ===
                                          "inactive"
                                          ? true
                                          : false
                                      }
                                      onLoad={() =>
                                        this.setState({
                                          editnewpharmacy_14nsh:
                                            this.state?.editnewpharmacy_14nsh ??
                                            data?.managingOrgID?.[0],
                                        })
                                      }
                                      getOptionLabel={(option) =>
                                        option?.["name"]
                                      }
                                      options={
                                        pharmacy_entityname_dropdownData.result ??
                                        []
                                      }
                                      size={"small"}
                                      onChange={(e, newvalue) =>
                                        this.onchange_14nsh(newvalue)
                                      }
                                      renderInput={(params) => (
                                        <ALLMCORE.TextField
                                          {...params}
                                          // label={"Entity Name"}
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  </Grid>

                                  <Grid
                                    lg={"4"}
                                    md={"4"}
                                    sm={"4"}
                                    xl={"4"}
                                    xs={"4"}
                                  // style={qdmstyles?.switch}
                                  >
                                    <Typography style={qdmstyles?.statustext}>
                                      Status
                                    </Typography>
                                    <div style={qdmstyles?.statusswitch}>
                                      <Switch
                                        key={"0"}
                                        id={"switch_input"}
                                        checked={this.state.status}
                                        disabled={
                                          this.props.location.state?.isView ===
                                            "inactive"
                                            ? true
                                            : false
                                        }
                                        color={"primary"}
                                        name={"switch"}
                                        aliasName={"switchIcon"}
                                        onChange={() => {
                                          this.onChangeSwitch(data);
                                        }}
                                      ></Switch>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Paper>
                            )}{" "}
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"Cx5VL"}
                          container={true}
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"flex-start"}
                          spacing={""}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                          style={qdmstyles.VRUGd}
                        >
                          <Grid
                            key={"0"}
                            id={"Fk9dp"}
                            container={true}
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"stretch"}
                            lg={"12"}
                            spacing={""}
                          >
                            <Grid
                              key={"0"}
                              id={"0t7fY"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"flex-start"}
                              lg={"2"}
                              item={""}
                              md={"2"}
                              sm={"2"}
                              xl={"2"}
                              xs={"2"}
                              style={qdmstyles.QVri}
                            >
                              {((this.state?.editnewpharmacy_ofyo3 ?? null) ===
                                1 ||
                                (this.state?.editnewpharmacy_ofyo3 ?? null) ===
                                null) && (
                                  <Paper
                                    key={"0"}
                                    id={"4RYIR"}
                                    elevation={"{3}"}
                                    style={qdmstyles.Me}
                                    className={classes.backgroundImage}
                                  >
                                    <Grid
                                      key={"0"}
                                      id={"S7ETM"}
                                      container={""}
                                      direction={"column"}
                                      justifyContent={"space-evenly"}
                                      alignItems={"center"}
                                      lg={"12"}
                                      item={true}
                                      md={"12"}
                                      xl={"12"}
                                      xs={"12"}
                                      style={qdmstyles.YngWd}
                                    >
                                      <Grid
                                        key={"0"}
                                        container={""}
                                        direction={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        item={true}
                                        lg={"12"}
                                        style={{
                                          position: "relative",
                                          height: "78%",
                                        }}
                                      >
                                        <label
                                          style={{
                                            width: "100%",
                                          }}
                                        >
                                          <div className="cls">
                                            <Avatar
                                              key={"0"}
                                              id={"uploaded_image"}
                                              alt={"Avatar"}
                                              variant={"rounded"}
                                              src={this.state?.url}
                                              aliasName={"uploadedimage"}
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                              }}
                                            // style={qdmstyles.HeEg}
                                            ></Avatar>
                                          </div>
                                          <input
                                            type="file"
                                            id="test"
                                            onChange={(e) =>
                                              this.handleChangePhotoFileInput(e)
                                            }
                                          />
                                        </label>
                                      </Grid>
                                      <Divider
                                        key={"1"}
                                        id={"Cf9i7"}
                                        variant={"fullWidth"}
                                        flexItem={""}
                                        orientation={"horizontal"}
                                        style={qdmstyles.lsDZx}
                                      ></Divider>
                                      <Button
                                        disabled={
                                          this.props.location.state
                                            ?.isdisabled === "inactive"
                                            ? true
                                            : false
                                        }
                                        key={"2"}
                                        id={"upload_button"}
                                        variant={"contained"}
                                        color={"primary"}
                                        children={
                                          <span>
                                            <input
                                              type="file"
                                              id="test"
                                              onChange={(e) =>
                                                this.handleChangePhotoFileInput(e)
                                              }
                                            />
                                            Upload Image
                                            {/* <span style={{ color: "red" }}>*</span> */}
                                          </span>
                                        }
                                        fullWidth={true}
                                        disableElevation={true}
                                        aliasName={"uploadbutton"}
                                        style={qdmstyles.oPvkL}
                                      ></Button>
                                    </Grid>
                                  </Paper>
                                )}{" "}
                            </Grid>
                            {((this.state?.addnewpharmacy_8mjav ?? null) ===
                              1 ||
                              (this.state?.addnewpharmacy_8mjav ?? null) ===
                              null) && (
                                <Grid
                                  key={"1"}
                                  id={"5t3uK"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"flex-start"}
                                  lg={"10"}
                                  item={true}
                                  md={"10"}
                                  sm={"10"}
                                  xl={"10"}
                                  xs={"10"}
                                >
                                  {((this.state?.editnewpharmacy_ofyo3 ??
                                    null) === 1 ||
                                    (this.state?.editnewpharmacy_ofyo3 ??
                                      null) === null) && (
                                      <Paper
                                        key={"0"}
                                        id={"rUOW3"}
                                        elevation={"{3}"}
                                        // style={qdmstyles.Qd}
                                        className={classes.locationDetails}
                                      >
                                        <Grid
                                          key={"0"}
                                          id={"lAvjf"}
                                          container={""}
                                          direction={"row"}
                                          justifyContent={"center"}
                                          alignItems={"center"}
                                        >
                                          <Typography
                                            key={"0"}
                                            id={"location_details_heading"}
                                            align={"inherit"}
                                            color={"initial"}
                                            display={"initial"}
                                            variant={"body1"}
                                            children={"LOCATION DETAILS"}
                                            aliasName={"locationdetailsheading"}
                                            style={qdmstyles.iUBI}
                                          ></Typography>
                                        </Grid>
                                        <Grid
                                          key={"1"}
                                          id={"qUoh9"}
                                          container={true}
                                          direction={"row"}
                                          justifyContent={"center"}
                                          alignItems={"center"}
                                          lg={"12"}
                                          md={"12"}
                                          sm={"12"}
                                          xl={"12"}
                                          xs={"12"}
                                        >
                                          <Grid
                                            key={"0"}
                                            id={"bo2uU"}
                                            container={true}
                                            direction={"row"}
                                            justifyContent={"flex-start"}
                                            alignItems={"center"}
                                            spacing={"2"}
                                          >
                                            <Grid
                                              key={"0"}
                                              id={"Ng6mk"}
                                              container={""}
                                              direction={"row"}
                                              justifyContent={"center"}
                                              alignItems={"center"}
                                              lg={"4"}
                                              item={true}
                                              md={"4"}
                                              sm={"4"}
                                              xl={"4"}
                                              xs={"4"}
                                            >
                                              <Typography
                                                key={"0"}
                                                id={"location_type_heading"}
                                                align={"inherit"}
                                                color={"initial"}
                                                display={"initial"}
                                                variant={"body1"}
                                                children={"Location Type"}
                                                aliasName={"locationtypeheading"}
                                                style={qdmstyles.eOP}
                                              ></Typography>
                                              <Autocomplete
                                                id={"l6jpW"}
                                                style={qdmstyles?.ljpW}
                                                disabled
                                                value={{
                                                  Type: "LOCATIONTYPE",
                                                  _id: "CodingMaster/10447",
                                                  _key: "10447",
                                                  activestatus: true,
                                                  code: "LT006",
                                                  display:
                                                    "Pharmacy Dispense Location",
                                                  id: 447,
                                                  shortdesc:
                                                    "Pharmacy Dispense Location",
                                                  status: true,
                                                }}
                                                // value={
                                                //   this.state
                                                //     ?.editnewpharmacy_l6jpw ??
                                                //   data?.locationType
                                                // }
                                                classes={{
                                                  paper: classes.paper,
                                                }}
                                                className={classes.autocomplete}
                                                onLoad={() =>
                                                  this.setState({
                                                    editnewpharmacy_l6jpw:
                                                      this.state
                                                        ?.editnewpharmacy_l6jpw ??
                                                      data?.locationType,
                                                  })
                                                }
                                                getOptionLabel={(option) =>
                                                  option?.["display"]
                                                }
                                                options={
                                                  pharmacy_locationtype_dropdownData.result ??
                                                  []
                                                }
                                                size={"small"}
                                                onChange={(e, newvalue) =>
                                                  this.onchange_l6jpw(newvalue)
                                                }
                                                renderInput={(params) => (
                                                  <ALLMCORE.TextField
                                                    {...params}
                                                    // label={"Location Type"}
                                                    variant="outlined"
                                                  />
                                                )}
                                              />
                                            </Grid>
                                            <Grid
                                              key={"1"}
                                              id={"Ng6mk"}
                                              container={""}
                                              direction={"row"}
                                              justifyContent={"center"}
                                              alignItems={"center"}
                                              lg={"2"}
                                              item={true}
                                              md={"2"}
                                              sm={"2"}
                                              xl={"2"}
                                              xs={"2"}
                                            >
                                              <Typography
                                                key={"0"}
                                                id={"pharmacy_id_heading"}
                                                align={"inherit"}
                                                color={"initial"}
                                                display={"initial"}
                                                variant={"body1"}
                                                children={
                                                  <span>
                                                    Pharmacy ID{" "}
                                                    <span style={{ color: "red" }}>
                                                      *
                                                    </span>
                                                  </span>
                                                }
                                                aliasName={"pharmacyidheading"}
                                                style={qdmstyles.kaL}
                                              ></Typography>
                                              <TextField
                                                key={"1"}
                                                onPaste={(e) => this.pasteHandle(e)}
                                                onChange={(e) =>
                                                  this.onchange_sfhjn(e)
                                                }
                                                disabled={
                                                  this.props.location.state
                                                    ?.isdisabled === "inactive"
                                                    ? true
                                                    : false
                                                }
                                                id={"pharmacy_id_textfield"}
                                                label={""}
                                                placeholder={"Pharmacy ID"}
                                                type={"text"}
                                                InputProps={{
                                                  classes: {
                                                    notchedOutline:
                                                      classes.notchedOutline,
                                                  },
                                                }}
                                                variant={"outlined"}
                                                fullWidth={true}
                                                size={"small"}
                                                aliasName={"pharmacyidtextfield"}
                                                margin={"dense"}
                                                onLoad={(e) =>
                                                  this.changeState(
                                                    "editnewpharmacy_sfhjn",
                                                    this.state
                                                      .editnewpharmacy_sfhjn ??
                                                    data?.locationID
                                                  )
                                                }
                                                value={
                                                  this.state
                                                    .editnewpharmacy_sfhjn ??
                                                  data?.locationID
                                                }
                                                onKeyPress={(e) => {
                                                  var k = e.keyCode || e.which;
                                                  if (
                                                    (k > 64 && k < 91) ||
                                                    (k > 96 && k < 123) ||
                                                    k === 8 ||
                                                    k === 32 ||
                                                    (k >= 48 && k <= 57)
                                                  ) {
                                                    return;
                                                  }
                                                  //  else {
                                                  //   e.preventDefault();
                                                  //   return;
                                                  // }
                                                }}
                                              ></TextField>
                                            </Grid>
                                            <Grid
                                              key={"2"}
                                              id={"Ng6mk"}
                                              container={""}
                                              direction={"row"}
                                              justifyContent={"center"}
                                              alignItems={"center"}
                                              lg={"3"}
                                              item={true}
                                              md={"3"}
                                              sm={"3"}
                                              xl={"3"}
                                              xs={"3"}
                                            >
                                              <Typography
                                                key={"0"}
                                                id={"short_description_heading"}
                                                align={"inherit"}
                                                color={"initial"}
                                                display={"initial"}
                                                variant={"body1"}
                                                children={
                                                  <span>
                                                    Short Description
                                                    <span style={{ color: "red" }}>
                                                      *
                                                    </span>
                                                  </span>
                                                }
                                                aliasName={
                                                  "shortdescriptionheading"
                                                }
                                                style={qdmstyles.jEoFh}
                                              ></Typography>
                                              <TextField
                                                key={"1"}
                                                onChange={(e) =>
                                                  this.onchange_qa35i(e)
                                                }
                                                disabled={
                                                  this.props.location.state
                                                    ?.isdisabled === "inactive"
                                                    ? true
                                                    : false
                                                }
                                                id={"short_description_textfield"}
                                                label={""}
                                                placeholder={"Short Description"}
                                                type={"text"}
                                                variant={"outlined"}
                                                InputProps={{
                                                  classes: {
                                                    notchedOutline:
                                                      classes.notchedOutline,
                                                  },
                                                }}
                                                fullWidth={true}
                                                size={"small"}
                                                aliasName={
                                                  "shortdescriptiontextfield"
                                                }
                                                margin={"dense"}
                                                onLoad={(e) =>
                                                  this.changeState(
                                                    "editnewpharmacy_qa35i",
                                                    this.state
                                                      .editnewpharmacy_qa35i ??
                                                    data?.shortdesc
                                                  )
                                                }
                                                value={
                                                  this.state
                                                    .editnewpharmacy_qa35i ??
                                                  data?.shortdesc
                                                }
                                              ></TextField>
                                            </Grid>
                                            <Grid
                                              key={"3"}
                                              id={"Ng6mk"}
                                              container={""}
                                              direction={"row"}
                                              justifyContent={"center"}
                                              alignItems={"center"}
                                              lg={"3"}
                                              item={true}
                                              md={"3"}
                                              sm={"3"}
                                              xl={"3"}
                                              xs={"3"}
                                            >
                                              <Typography
                                                key={"0"}
                                                id={"long_description_heading"}
                                                align={"inherit"}
                                                color={"initial"}
                                                display={"initial"}
                                                variant={"body1"}
                                                children={
                                                  <span>
                                                    Long Description
                                                    <span style={{ color: "red" }}>
                                                      *
                                                    </span>
                                                  </span>
                                                }
                                                aliasName={"longdescriptionheading"}
                                                style={qdmstyles.ZPGn}
                                              ></Typography>
                                              <TextField
                                                key={"1"}
                                                onChange={(e) =>
                                                  this.onchange_apwbs(e)
                                                }
                                                disabled={
                                                  this.props.location.state
                                                    ?.isdisabled === "inactive"
                                                    ? true
                                                    : false
                                                }
                                                id={"long_description_textfield"}
                                                label={""}
                                                InputProps={{
                                                  classes: {
                                                    notchedOutline:
                                                      classes.notchedOutline,
                                                  },
                                                }}
                                                placeholder={
                                                  <span>
                                                    Long Description
                                                    <span style={{ color: "red" }}>
                                                      *
                                                    </span>
                                                  </span>
                                                }
                                                type={"text"}
                                                variant={"outlined"}
                                                fullWidth={true}
                                                size={"small"}
                                                aliasName={
                                                  "longdescriptiontextfield"
                                                }
                                                margin={"dense"}
                                                onLoad={(e) =>
                                                  this.changeState(
                                                    "editnewpharmacy_apwbs",
                                                    this.state
                                                      .editnewpharmacy_apwbs ??
                                                    data?.longdesc
                                                  )
                                                }
                                                value={
                                                  this.state
                                                    .editnewpharmacy_apwbs ??
                                                  data?.longdesc
                                                }
                                              ></TextField>
                                            </Grid>
                                          </Grid>
                                          <Grid
                                            key={"1"}
                                            id={"bo2uU"}
                                            container={true}
                                            direction={"row"}
                                            justifyContent={"flex-start"}
                                            alignItems={"center"}
                                            spacing={"2"}
                                          >
                                            <Grid
                                              key={"0"}
                                              id={"Ng6mk"}
                                              container={""}
                                              direction={"row"}
                                              justifyContent={"center"}
                                              alignItems={"center"}
                                              lg={"3"}
                                              item={true}
                                              md={"3"}
                                              sm={"3"}
                                              xl={"3"}
                                              xs={"3"}
                                            >
                                              <Typography
                                                key={"0"}
                                                id={"operation_status_heading"}
                                                align={"inherit"}
                                                color={"initial"}
                                                display={"initial"}
                                                variant={"body1"}
                                                children={
                                                  <span>
                                                    Operational Status
                                                    <span style={{ color: "red" }}>
                                                      *
                                                    </span>
                                                  </span>
                                                }
                                                aliasName={"operationstatusheading"}
                                                style={qdmstyles.SfWxs}
                                              ></Typography>
                                              <Autocomplete
                                                id={"mr2at"}
                                                style={qdmstyles?.mrat}
                                                value={
                                                  this.state
                                                    ?.editnewpharmacy_mr2at ??
                                                  data?.operationalStatus?.[0]
                                                }
                                                disabled={
                                                  this.props.location.state
                                                    ?.isdisabled === "inactive"
                                                    ? true
                                                    : false
                                                }
                                                classes={{
                                                  paper: classes.paper,
                                                }}
                                                className={classes.autocomplete}
                                                onLoad={() =>
                                                  this.setState({
                                                    editnewpharmacy_mr2at:
                                                      this.state
                                                        ?.editnewpharmacy_mr2at ??
                                                      data?.operationalStatus?.[0]
                                                        ?.coding?.[0],
                                                  })
                                                }
                                                getOptionLabel={(option) =>
                                                  option?.["display"]
                                                }
                                                options={
                                                  pharmacy_operationstatus_dropdownData.result ??
                                                  []
                                                }
                                                size={"small"}
                                                onChange={(e, newvalue) =>
                                                  this.onchange_mr2at(newvalue)
                                                }
                                                renderInput={(params) => (
                                                  <ALLMCORE.TextField
                                                    {...params}
                                                    // label={"Opertaional Status"}
                                                    margin={"dense"}
                                                    variant="outlined"
                                                  />
                                                )}
                                              />
                                            </Grid>
                                            <Grid
                                              key={"1"}
                                              id={"Ng6mk"}
                                              container={""}
                                              direction={"row"}
                                              justifyContent={"center"}
                                              alignItems={"center"}
                                              lg={"3"}
                                              item={true}
                                              md={"3"}
                                              sm={"3"}
                                              xl={"3"}
                                              xs={"3"}
                                            >
                                              <Typography
                                                key={"0"}
                                                id={
                                                  "parent_location_id_and_description_heading"
                                                }
                                                align={"inherit"}
                                                color={"initial"}
                                                display={"initial"}
                                                variant={"body1"}
                                                children={
                                                  "Parent Location ID And Description"
                                                }
                                                aliasName={
                                                  "parentlocationidanddescriptionheading"
                                                }
                                                style={qdmstyles.UZwu}
                                              ></Typography>

                                              <Autocomplete
                                                id={"zntXm"}
                                                style={qdmstyles?.zntXm}
                                                value={
                                                  typeof this.state
                                                    ?.editnewpharmacy_zntxm ===
                                                    "string"
                                                    ? {
                                                      locationID:
                                                        this.state
                                                          ?.editnewpharmacy_zntxm,
                                                    }
                                                    : this.state
                                                      ?.editnewpharmacy_zntxm
                                                }
                                                classes={{
                                                  paper: classes.paper,
                                                }}
                                                className={classes.autocomplete}
                                                disabled={
                                                  this.props.location.state
                                                    ?.isdisabled === "inactive"
                                                    ? true
                                                    : false
                                                }
                                                onLoad={() =>
                                                  this.setState({
                                                    editnewpharmacy_zntxm:
                                                      this.state
                                                        ?.editnewpharmacy_zntxm ??
                                                      data?.parentLocationID_desc,
                                                  })
                                                }
                                                getOptionLabel={(option) =>
                                                  option?.["locationID"]
                                                }
                                                options={
                                                  pharmacy_parentlocationid_description_dropdownData.result ??
                                                  []
                                                }
                                                size={"small"}
                                                onChange={(e, newvalue) =>
                                                  this.onchange_zntxm(newvalue)
                                                }
                                                renderInput={(params) => (
                                                  <ALLMCORE.TextField
                                                    {...params}
                                                    label={""}
                                                    margin={"dense"}
                                                    variant="outlined"
                                                  />
                                                )}
                                              />
                                            </Grid>
                                            <Grid
                                              key={"2"}
                                              id={"Ng6mk"}
                                              container={""}
                                              direction={"row"}
                                              justifyContent={"center"}
                                              alignItems={"center"}
                                              lg={"2"}
                                              item={true}
                                              md={"2"}
                                              sm={"2"}
                                              xl={"2"}
                                              xs={"2"}
                                            >
                                              <Typography
                                                key={"0"}
                                                id={"location_role_type"}
                                                align={"inherit"}
                                                color={"initial"}
                                                display={"initial"}
                                                variant={"body1"}
                                                children={
                                                  <span>
                                                    Location Role Type
                                                    <span style={{ color: "red" }}>
                                                      *
                                                    </span>
                                                  </span>
                                                }
                                                aliasName={"locationroletype"}
                                                style={qdmstyles.GHul}
                                              ></Typography>
                                              <Autocomplete
                                                id={"dslum"}
                                                style={qdmstyles?.dslum}
                                                value={
                                                  this.state
                                                    ?.editnewpharmacy_dslum ??
                                                  data?.locationRoletype
                                                }
                                                disabled={
                                                  this.props.location.state
                                                    ?.isdisabled === "inactive"
                                                    ? true
                                                    : false
                                                }
                                                classes={{
                                                  paper: classes.paper,
                                                }}
                                                className={classes.autocomplete}
                                                onLoad={() =>
                                                  this.setState({
                                                    editnewpharmacy_dslum:
                                                      this.state
                                                        ?.editnewpharmacy_dslum ??
                                                      data?.locationRoletype,
                                                  })
                                                }
                                                getOptionLabel={(option) =>
                                                  option?.["display"]
                                                }
                                                options={
                                                  pharmacy_locationroletype_dropdownData.result ??
                                                  []
                                                }
                                                size={"small"}
                                                onChange={(e, newvalue) =>
                                                  this.onchange_dslum(newvalue)
                                                }
                                                renderInput={(params) => (
                                                  <ALLMCORE.TextField
                                                    {...params}
                                                    label={""}
                                                    margin={"dense"}
                                                    variant="outlined"
                                                  />
                                                )}
                                              />
                                            </Grid>
                                            <Grid
                                              key={"3"}
                                              id={"Ng6mk"}
                                              container={""}
                                              direction={"row"}
                                              justifyContent={"center"}
                                              alignItems={"center"}
                                              lg={"2"}
                                              item={true}
                                              xl={"1"}
                                              xs={"1"}
                                              sm={"1"}
                                              md={"1"}
                                            >
                                              <Typography
                                                key={"0"}
                                                id={"inventory_sub_store_header"}
                                                align={"inherit"}
                                                color={"initial"}
                                                display={"initial"}
                                                variant={"body1"}
                                                children={"Inventory Sub Store"}
                                                aliasName={
                                                  "inventorysubstoreheader"
                                                }
                                                style={qdmstyles.whYIk}
                                              ></Typography>
                                              <Autocomplete
                                                id={"qwVDB"}
                                                style={qdmstyles?.qwVDB}
                                                value={
                                                  this.state
                                                    ?.editnewpharmacy_qwvdb ??
                                                  data?.pharmacy?.[0]
                                                    ?.inventorySubStore
                                                }
                                                disabled={
                                                  this.props.location.state
                                                    ?.isdisabled === "inactive"
                                                    ? true
                                                    : false
                                                }
                                                classes={{
                                                  paper: classes.paper,
                                                }}
                                                className={classes.autocomplete}
                                                onLoad={() =>
                                                  this.setState({
                                                    editnewpharmacy_qwvdb:
                                                      this.state
                                                        ?.editnewpharmacy_qwvdb ??
                                                      data?.pharmacy?.[0]
                                                        ?.inventorySubStore,
                                                  })
                                                }
                                                getOptionLabel={(option) =>
                                                  option?.["locationID"]
                                                }
                                                options={
                                                  pharmacy_inventrysubtype_dropdownData.result ??
                                                  []
                                                }
                                                size={"small"}
                                                onChange={(e, newvalue) =>
                                                  this.onchange_qwvdb(newvalue)
                                                }
                                                renderInput={(params) => (
                                                  <ALLMCORE.TextField
                                                    {...params}
                                                    label={""}
                                                    margin={"dense"}
                                                    variant="outlined"
                                                  />
                                                )}
                                              />
                                            </Grid>
                                            <Grid
                                              key={"3"}
                                              id={"Ng6mk"}
                                              container={""}
                                              direction={"row"}
                                              justifyContent={"center"}
                                              alignItems={"center"}
                                              lg={"1"}
                                              item={true}
                                              xl={"1"}
                                              xs={"1"}
                                              sm={"1"}
                                              md={"1"}
                                            >
                                              <Typography
                                                key={"0"}
                                                id={"inventory_sub_store_header"}
                                                align={"inherit"}
                                                color={"initial"}
                                                display={"initial"}
                                                variant={"body1"}
                                                children={<>Auto&nbsp;Calculate</>}
                                                aliasName={
                                                  "inventorysubstoreheader"
                                                }
                                                style={qdmstyles.whYIk}
                                              ></Typography>
                                              <ALLMCORE.Checkbox
                                                id={"Dispensable_Checkbox"}
                                                color={"primary"}
                                                size={"small"}
                                                aliasName={"AutoCalculate"}
                                                checked={this.state.autocalculate}
                                                onChange={(event) =>
                                                  this.changeState(
                                                    "autocalculate",
                                                    event.target.checked
                                                  )
                                                }
                                                inputProps={{
                                                  "aria-label": "Auto Calculate",
                                                }}
                                              ></ALLMCORE.Checkbox>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Paper>
                                    )}{" "}
                                  <Grid
                                    key={"1"}
                                    id={"tXjdA"}
                                    container={true}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                  ></Grid>
                                </Grid>
                              )}{" "}
                          </Grid>
                        </Grid>
                        {((this.state?.editnewpharmacy_ofyo3 ?? null) === 1 ||
                          (this.state?.editnewpharmacy_ofyo3 ?? null) ===
                          null) &&
                          // data?.telecom?.length > 0 &&
                          (
                            <Grid
                              key={"2"}
                              id={"ZH1rk"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              // style={qdmstyles.WLhMk}
                              className={classes.contact}
                            >
                              <Grid
                                key={"0"}
                                id={"YrJKf"}
                                container={true}
                                direction={"row"}
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                              ></Grid>
                              <Grid
                                key={"1"}
                                id={"tuuzI"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                              >
                                <ComponentRender
                                  key={"0"}
                                  id={"2NoBK"}
                                  component={
                                    <ALL.Editcontactpoint
                                      propsState={this.state}
                                      setFun={(value) => {
                                        this.changeState(
                                          "Editcontactpoint",
                                          value
                                        );
                                      }}
                                      stateName={"Editcontactpoint"}
                                      statevalue={
                                        this.state?.Editcontactpoint
                                          ? this.state?.Editcontactpoint
                                          : []
                                        //  ??
                                        // data?.telecom
                                      }
                                    />
                                  }
                                  children={data?.telecom?.[0]}
                                >
                                  <Paper
                                    key={"0"}
                                    id={"fYos9"}
                                    elevation={"{3}"}
                                  ></Paper>
                                  <Paper
                                    key={"1"}
                                    id={"roOdY"}
                                    elevation={"{3}"}
                                  ></Paper>
                                  <Paper
                                    key={"2"}
                                    id={"D2AS9"}
                                    elevation={"{3}"}
                                  ></Paper>
                                  <Grid
                                    key={"3"}
                                    id={"GLNgW"}
                                    container={true}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                  ></Grid>
                                </ComponentRender>
                              </Grid>
                            </Grid>
                          )}{" "}
                        <Grid
                          key={"3"}
                          id={"TFjok"}
                          container={true}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          lg={"12"}
                          spacing={""}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                          style={qdmstyles.hhU}
                        >
                          <Grid
                            key={"0"}
                            id={"OpvdP"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"6"}
                            md={"6"}
                            sm={"6"}
                            xl={"6"}
                            xs={"6"}
                            item={true}
                            style={qdmstyles.TAkw}
                          >
                            {((this.state?.editnewpharmacy_ofyo3 ?? null) ===
                              1 ||
                              ((this.state?.editnewpharmacy_ofyo3 ?? null) ===
                                null
                                //  &&
                                // data?.address?.length > 0
                              )) && (
                                <ComponentRender
                                  key={"0"}
                                  id={"y7iZZ"}
                                  component={
                                    <ALL.EditAddressPharmacy
                                      propsState={this.state}
                                      setFun={(value) => {
                                        this.changeState(
                                          "EditAddressPharmacy",
                                          value
                                        );
                                      }}
                                      stateName={"EditAddressPharmacy"}
                                      statevalue={
                                        this.state?.EditAddressPharmacy ??
                                        data?.address
                                      }
                                    />
                                  }
                                  children={data?.address?.[0]}
                                ></ComponentRender>
                              )}
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"viG1z"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            xl={"6"}
                            xs={"6"}
                            item={true}
                            lg={"6"}
                            md={"6"}
                            sm={"6"}
                            style={qdmstyles.HowQ}
                          >
                            {((this.state?.editnewpharmacy_ofyo3 ?? null) ===
                              1 ||
                              (this.state?.editnewpharmacy_ofyo3 ?? null) ===
                              null) && (
                                <ComponentRender
                                  key={"0"}
                                  id={"c1BcH"}
                                  component={
                                    <ALL.EditGeoLocationPharmacy
                                      propsState={this.state}
                                      setFun={(value) => {
                                        this.changeState(
                                          "EditGeoLocationPharmacy",
                                          value
                                        );
                                      }}
                                      stateName={"EditGeoLocationPharmacy"}
                                      statevalue={
                                        this.state?.EditGeoLocationPharmacy ??
                                        data?.position
                                      }
                                    />
                                  }
                                ></ComponentRender>
                              )}{" "}
                          </Grid>
                        </Grid>
                        <Grid
                          key={"4"}
                          id={"G2KNv"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          style={qdmstyles.yESm}
                        >
                          {((this.state?.editnewpharmacy_ofyo3 ?? null) === 1 ||
                            (this.state?.editnewpharmacy_ofyo3 ?? null) ===
                            null) && (
                              <ComponentRender
                                key={"0"}
                                id={"HUfCr"}
                                component={
                                  <ALL.WorkingDays
                                    propsState={this.state}
                                    setFun={(value) => {
                                      this.changeState("WorkingDays", value);
                                    }}
                                    handleworkdayedits={this.handleworkdayedit}
                                    WorkingDays={this.state?.WorkingDays ?? []}
                                    handleworkdaydelete={this.handleworkdaydelete}
                                    stateName={"WorkingDays"}
                                    editData={this.state.editData}
                                    statevalue={
                                      this.state?.WorkingDays ??
                                      data?.workingdayyear?.[0]
                                    }
                                    close={() =>
                                      this.setState({
                                        ...this.state,
                                        index: null,
                                      })
                                    }
                                    isEdit={true}
                                    alert={this.props.alert.setSnack}
                                  />
                                }
                                children={data?.workingdayyear?.[0]}
                              ></ComponentRender>
                            )}{" "}
                        </Grid>
                        <Grid
                          key={"5"}
                          id={"A0PXe"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          xl={"12"}
                          xs={"12"}
                          sm={"12"}
                          md={"12"}
                          lg={"12"}
                          style={qdmstyles.MImJ}
                        >
                          {((this.state?.editnewpharmacy_ofyo3 ?? null) === 1 ||
                            (this.state?.editnewpharmacy_ofyo3 ?? null) ===
                            null) && (
                              <ComponentRender
                                key={"0"}
                                id={"TvwqT"}
                                component={
                                  <ALL.OverRide
                                    propsState={this.state}
                                    setFun={(value) => {
                                      this.changeState("OverRide", value);
                                    }}
                                    stateName={"OverRide"}
                                    statevalue={
                                      this.state?.OverRide ??
                                      data?.workingdayyearoverride?.[0]
                                    }
                                    isEdit={true}
                                    WorkingDays={this.state?.WorkingDays ?? []}
                                    editData={this.state.editDataOverRide}
                                    isOverRise={true}
                                    handleworkdayeditoverride={
                                      this.handleworkdayeditoverride
                                    }
                                    handleworkdaydelete={
                                      this.handleworkdaydeleteoverride
                                    }
                                    close={() =>
                                      this.setState({
                                        ...this.state,
                                        index_ride: null,
                                      })
                                    }
                                  />
                                }
                                children={data?.workingdayyearoverride?.[0]}
                              ></ComponentRender>
                            )}{" "}
                        </Grid>
                        <Grid
                          key={"10"}
                          id={"A0PXefv"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          xl={"12"}
                          xs={"12"}
                          sm={"12"}
                          md={"12"}
                          lg={"12"}
                          style={qdmstyles.APXe}
                        >
                          {((this.state?.editnewpharmacy_ofyo3 ?? null) === 1 ||
                            (this.state?.editnewpharmacy_ofyo3 ?? null) ===
                            null) && (
                              <ALL.PharmacyLocationConfig
                                allowbulkverification={
                                  this.state.allowbulkverification
                                }
                                allowbulkfilling={this.state.allowbulkfilling}
                                considerlatearrival={
                                  this.state.considerlatearrival
                                }
                                changeState={(name, value) =>
                                  this.changeState(name, value)
                                }
                              />
                            )}
                        </Grid>
                        <Grid
                          key={"6"}
                          id={"DxVxk"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                          style={qdmstyles.RahDu}
                        >
                          {(this.state?.editnewpharmacy_ofyo3 ?? null) ===
                            2 && (
                              <Paper
                                key={"0"}
                                id={"z4yMs"}
                                elevation={"{3}"}
                                style={qdmstyles.kbNkH}
                                className={classes.background}
                              >
                                <Grid
                                  key={"0"}
                                  id={"sLa09"}
                                  container={true}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  spacing={"2"}
                                  lg={"12"}
                                  md={"12"}
                                  sm={"12"}
                                  xl={"12"}
                                  xs={"12"}
                                >
                                  <Grid
                                    key={"0"}
                                    id={"OuUFv"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    item={true}
                                    lg={"12"}
                                    md={"12"}
                                    sm={"12"}
                                    xl={"12"}
                                    xs={"12"}
                                  >
                                    <Typography
                                      key={"0"}
                                      id={"performing_details_header"}
                                      align={"inherit"}
                                      color={"initial"}
                                      display={"initial"}
                                      variant={"body1"}
                                      children={"PERFORMING DETAILS"}
                                      aliasName={"performingdetailsheader"}
                                      style={qdmstyles.aLKU}
                                    ></Typography>
                                  </Grid>
                                  <Grid
                                    key={"1"}
                                    id={"V7v9F"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    lg={"4"}
                                    md={"4"}
                                    sm={"4"}
                                    xl={"4"}
                                    xs={"4"}
                                    item={true}
                                  >
                                    <Typography
                                      key={"0"}
                                      id={"performing_facility_header"}
                                      align={"inherit"}
                                      color={"initial"}
                                      display={"initial"}
                                      variant={"body1"}
                                      children={"Performing Facility"}
                                      aliasName={"performingfacilityheader"}
                                      style={qdmstyles.NtSE}
                                    ></Typography>
                                    <TextField
                                      key={"1"}
                                      onChange={(e) => this.onchange_bf657(e)}
                                      id={"performing_facility_value"}
                                      InputProps={{
                                        classes: {
                                          notchedOutline: classes.notchedOutline,
                                        },
                                      }}
                                      label={""}
                                      placeholder={"AINQA Hospital Chennai"}
                                      type={"text"}
                                      fullWidth={true}
                                      variant={"outlined"}
                                      size={"small"}
                                      aliasName={"performingfacilityvalue"}
                                      margin={"dense"}
                                      disabled={true}
                                      onLoad={(e) =>
                                        this.changeState(
                                          "editnewpharmacy_bf657",
                                          this.state.editnewpharmacy_bf657 ??
                                          data?.managingOrgEntityType?.display
                                        )
                                      }
                                      value={
                                        this.state?.editnewpharmacy_14nsh?.name ??
                                        data?.managingOrgID?.[0]
                                      }
                                      // value={
                                      //   "Pharmacy Dispense Location"
                                      //   // this.state.editnewpharmacy_bf657 ??
                                      //   // data?.managingOrgEntityType?.display
                                      // }
                                      style={qdmstyles.Bf}
                                    ></TextField>
                                  </Grid>
                                  <Grid
                                    key={"2"}
                                    id={"kAtjH"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    lg={"4"}
                                    sm={"4"}
                                    md={"4"}
                                    xl={"4"}
                                    xs={"4"}
                                    item={true}
                                  >
                                    <Typography
                                      key={"0"}
                                      id={"performing_location_type_header"}
                                      align={"inherit"}
                                      color={"initial"}
                                      display={"initial"}
                                      variant={"body1"}
                                      children={"Performing Location Type"}
                                      aliasName={"performinglocationtypeheader"}
                                      style={qdmstyles.ehgxR}
                                    ></Typography>
                                    <TextField
                                      key={"1"}
                                      onChange={(e) => this.onchange_m7pur(e)}
                                      id={"performing_location_type_value"}
                                      InputProps={{
                                        classes: {
                                          notchedOutline: classes.notchedOutline,
                                        },
                                      }}
                                      label={""}
                                      placeholder={"Pharmacy"}
                                      type={"text"}
                                      variant={"outlined"}
                                      size={"small"}
                                      fullWidth={true}
                                      aliasName={"performinglocationtypevalue"}
                                      margin={"dense"}
                                      disabled={true}
                                      value={
                                        this.state.editnewpharmacy_m7pur ??
                                        data?.locationRoletype?.display
                                      }
                                      onLoad={(e) =>
                                        this.changeState(
                                          "editnewpharmacy_m7pur",
                                          this.state.editnewpharmacy_m7pur ??
                                          data?.locationType?.text
                                        )
                                      }
                                      // value={
                                      //   //  'Pharmacy Dispense Location'
                                      //   this.state.editnewpharmacy_m7pur ??
                                      //   data?.locationType?.text
                                      // }
                                      style={qdmstyles.mpuR}
                                    ></TextField>
                                  </Grid>
                                  <Grid
                                    key={"3"}
                                    id={"571Nr"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    lg={"4"}
                                    sm={"4"}
                                    md={"4"}
                                    xl={"4"}
                                    xs={"4"}
                                    item={true}
                                  >
                                    <Typography
                                      key={"0"}
                                      id={"performaing_location_header"}
                                      align={"inherit"}
                                      color={"initial"}
                                      display={"initial"}
                                      variant={"body1"}
                                      children={"Performing Location"}
                                      aliasName={"performainglocationheader"}
                                      style={qdmstyles.FmKg}
                                    ></Typography>
                                    <TextField
                                      key={"1"}
                                      onChange={(e) => this.onchange_1pizh(e)}
                                      id={"performing_location_value"}
                                      InputProps={{
                                        classes: {
                                          notchedOutline: classes.notchedOutline,
                                        },
                                      }}
                                      label={""}
                                      placeholder={"AINQA Hospital Chennai"}
                                      type={"text"}
                                      variant={"outlined"}
                                      size={"small"}
                                      fullWidth={true}
                                      aliasName={"performinglocationvalue"}
                                      margin={"dense"}
                                      disabled={true}
                                      onLoad={(e) =>
                                        this.changeState(
                                          "editnewpharmacy_1pizh",
                                          this.state.editnewpharmacy_1pizh ??
                                          data?.locationID
                                        )
                                      }
                                      value={
                                        typeof this.state
                                          ?.editnewpharmacy_zntxm === "string"
                                          ? this.state?.editnewpharmacy_zntxm
                                          : this.state?.editnewpharmacy_zntxm
                                            ?.locationID
                                      }
                                      // value={
                                      //   this.state.editnewpharmacy_1pizh ??
                                      //   data?.locationID
                                      // }
                                      style={qdmstyles.pizh}
                                    ></TextField>
                                  </Grid>
                                </Grid>
                              </Paper>
                            )}{" "}
                        </Grid>
                        <Grid
                          key={"7"}
                          id={"LyRof"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                          style={qdmstyles.lImah}
                        >
                          {(this.state?.editnewpharmacy_ofyo3 ?? null) ===
                            2 && (
                              <Paper
                                key={"0"}
                                id={"R5XSx"}
                                elevation={"{3}"}
                                style={qdmstyles.EBYYl}
                                className={classes.background}
                              >
                                {this.state.isOpen ? (
                                  <div
                                    style={{
                                      padding: "0px 30px 8px 0px",
                                      borderBottom: "1px solid lightgray",
                                    }}
                                  >
                                    <Grid container spacing={2}>
                                      <Grid item xs={6}>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {" "}
                                          <div>
                                            <Avatar
                                              key={"0"}
                                              id={"group_icon"}
                                              alt={"Avatar"}
                                              variant={"square"}
                                              src={
                                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317031738"
                                              }
                                              aliasName={"groupicon"}
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                                background: "red",
                                                padding: "7px",
                                                borderRadius: "50%",
                                                marginRight: "4px",
                                                backgroundColor: "#f1f6fc",
                                              }}
                                            ></Avatar>
                                          </div>
                                          <div>
                                            SERVICE APPLICABILITY <br />
                                            Medication
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item xs={5}>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div>
                                            <Avatar
                                              key={"0"}
                                              id={"group_icon"}
                                              alt={"Avatar"}
                                              variant={"square"}
                                              src={
                                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317006791"
                                              }
                                              aliasName={"groupicon"}
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                                background: "red",
                                                padding: "7px",
                                                borderRadius: "50%",
                                                marginRight: "4px",
                                                backgroundColor: "#f1f6fc",
                                              }}
                                            ></Avatar>
                                          </div>
                                          <div>
                                            <label>
                                              MEDICATION SELECTION CRITERIA
                                            </label>
                                            <br />
                                            {this.state.editId?.[0]?.ruleName ||
                                              this.state.editId?.[0]?.group
                                                ?.label ||
                                              ""}
                                            <br />
                                            {this.state.editId?.[0]?.priority ||
                                              this.state.editId?.[0]?.rule
                                                ?.label ||
                                              ""}
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Button
                                          variant="contained"
                                          className={classes.add}
                                          onClick={(e) => {
                                            this.setState({
                                              ...this.state,
                                              isOpen: !this.state.isOpen,
                                            });
                                          }}
                                        >
                                          Remove
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    <Grid
                                      key={"0"}
                                      id={"piC1g"}
                                      container={""}
                                      direction={"row"}
                                      justifyContent={"center"}
                                      alignItems={"center"}
                                      item={true}
                                    >
                                      <Typography
                                        key={"0"}
                                        id={"service_applicability_header"}
                                        align={"inherit"}
                                        color={"initial"}
                                        display={"initial"}
                                        variant={"body1"}
                                        children={
                                          <span>
                                            SERVICE APPLICABILITY&nbsp;
                                            <span style={{ color: "red" }}>*</span>
                                          </span>
                                        }
                                        aliasName={"serviceapplicabilityheader"}
                                        style={qdmstyles.JxJfI}
                                      ></Typography>
                                    </Grid>
                                    <Grid
                                      key={"1"}
                                      id={"rSZ4i"}
                                      container={""}
                                      direction={"row"}
                                      justifyContent={"center"}
                                      alignItems={"center"}
                                      style={qdmstyles.svgP}
                                    >
                                      <Grid
                                        key={"0"}
                                        id={"iddKh"}
                                        container={true}
                                        direction={"row"}
                                        justifyContent={"flex-start"}
                                        alignItems={"center"}
                                      >
                                        <Button
                                          key={"0"}
                                          id={"ljrOp"}
                                          variant={"contained"}
                                          color={"primary"}
                                          children={"Service Order"}
                                          disabled={true}
                                          size={"medium"}
                                          style={qdmstyles.HGN}
                                        ></Button>
                                        <Button
                                          key={"1"}
                                          id={"medication_button"}
                                          variant={"contained"}
                                          color={"primary"}
                                          children={"Medication"}
                                          size={"medium"}
                                          aliasName={"medicationbutton"}
                                          style={qdmstyles.nZpL}
                                        ></Button>
                                      </Grid>
                                      <Grid
                                        key={"1"}
                                        id={"3uqqL"}
                                        container={""}
                                        direction={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        style={qdmstyles.ANGz}
                                      >
                                        <Typography
                                          key={"0"}
                                          id={
                                            "medication_delection_criteria_header"
                                          }
                                          align={"inherit"}
                                          color={"initial"}
                                          display={"initial"}
                                          variant={"body1"}
                                          children={
                                            "MEDICATION SELECTION CRITERIA"
                                          }
                                          aliasName={
                                            "medicationdelectioncriteriaheader"
                                          }
                                          style={qdmstyles.zolc}
                                        ></Typography>
                                        <Grid
                                          key={"1"}
                                          id={"i1kt4"}
                                          container={true}
                                          direction={"row"}
                                          justifyContent={"flex-start"}
                                          alignItems={"center"}
                                          style={qdmstyles.IWl}
                                        >
                                          {this.state.isExist && (
                                            <Grid
                                              id={"i1kt4"}
                                              container={true}
                                              direction={"row"}
                                              justifyContent={"flex-start"}
                                              alignItems={"center"}
                                              style={qdmstyles.ikt}
                                              spacing={2}
                                            >
                                              <Grid
                                                key={"1"}
                                                id={"V7v9F"}
                                                container={""}
                                                direction={"row"}
                                                justifyContent={"center"}
                                                alignItems={"center"}
                                                lg={"4"}
                                                md={"4"}
                                                sm={"4"}
                                                xl={"4"}
                                                xs={"4"}
                                                item={true}
                                              >
                                                <Autocomplete
                                                  value={this.state?.group ?? {}}
                                                  getOptionLabel={(option) =>
                                                    option?.["label"]
                                                  }
                                                  options={
                                                    this.state?.groupData ?? []
                                                  }
                                                  size={"small"}
                                                  onChange={(e, newvalue) =>
                                                    this.handleChange(
                                                      newvalue,
                                                      "group"
                                                    )
                                                  }
                                                  renderInput={(params) => (
                                                    <ALLMCORE.TextField
                                                      {...params}
                                                      margin="dense"
                                                      variant="outlined"
                                                      label="Group"
                                                    />
                                                  )}
                                                />
                                              </Grid>
                                              <Grid
                                                key={"1"}
                                                id={"V7v9F"}
                                                container={""}
                                                direction={"row"}
                                                justifyContent={"center"}
                                                alignItems={"center"}
                                                lg={"4"}
                                                md={"4"}
                                                sm={"4"}
                                                xl={"4"}
                                                xs={"4"}
                                                item={true}
                                              >
                                                <Grid  lg={"12"}
                                                md={"12"}
                                                sm={"12"}
                                                xl={"12"}
                                                xs={"12"}
                                                item={true}>
                                                <Autocomplete
                                                  value={this.state?.rule ?? {}}
                                                  getOptionLabel={(option) =>
                                                    option?.["label"]
                                                  }
                                                  options={
                                                    this.state?.ruleData ?? []
                                                  }
                                                  size={"small"}
                                                  onChange={(e, newvalue) =>
                                                    this.handleChange(
                                                      newvalue,
                                                      "rule"
                                                    )
                                                  }
                                                  renderInput={(params) => (
                                                    <ALLMCORE.TextField
                                                      {...params}
                                                      margin="dense"
                                                      variant="outlined"
                                                      label="Rule"
                                                    />
                                                  )}
                                                />
                                                </Grid>
                                              </Grid>
                                              <Button
                                                key={"1"}
                                                variant={"contained"}
                                                color={"primary"}
                                                children={"Save"}
                                                onClick={() =>
                                                  this.onclick_exist_save()
                                                }
                                                style={
                                                  (qdmstyles.JDFgQ,
                                                    { marginRight: 6 })
                                                }
                                              ></Button>
                                              &nbsp;
                                              <Button
                                                key={"2"}
                                                variant={"contained"}
                                                children={"Cancel"}
                                                onClick={(e) => {
                                                  this.setState({
                                                    ...this.state,
                                                    isExist: !this.state.isExist,
                                                  });
                                                }}
                                                style={qdmstyles.kFjW}
                                              ></Button>
                                            </Grid>
                                          )}
                                          {!this.state.isExist && (
                                            <Grid
                                              key={"1"}
                                              id={"i1kt4"}
                                              container={true}
                                              direction={"row"}
                                              justifyContent={"flex-start"}
                                              alignItems={"center"}
                                              style={qdmstyles.ikt}
                                            >
                                              <Button
                                                key={"2"}
                                                disabled={
                                                  this.props.location.state
                                                    ?.isdisabled === "inactive"
                                                    ? true
                                                    : false
                                                }
                                                id={"add_new_rule_set_button"}
                                                variant={"contained"}
                                                color={"primary"}
                                                children={"Add New Rule Set"}
                                                aliasName={"addnewrulesetbutton"}
                                                style={{
                                                  ...qdmstyles.kFjW,
                                                  background: "#f1f6fc",
                                                  color: "#2a60bc",
                                                }}
                                                onClick={(e) => {
                                                  this.setState({
                                                    ...this.state,
                                                    isRule: !this.state.isRule,
                                                  });
                                                }}
                                              ></Button>
                                              &nbsp;
                                              <Typography
                                                key={"1"}
                                                id={"(or)_label"}
                                                align={"inherit"}
                                                color={"initial"}
                                                display={"initial"}
                                                variant={"body1"}
                                                children={"(or)"}
                                                aliasName={"(or)label"}
                                                style={qdmstyles.pfdc}
                                              ></Typography>
                                              &nbsp;
                                              <Button
                                                key={"0"}
                                                id={
                                                  "add_existing_rule_set_button"
                                                }
                                                disabled={
                                                  this.props.location.state
                                                    ?.isdisabled === "inactive"
                                                    ? true
                                                    : false
                                                }
                                                variant={"contained"}
                                                color={"primary"}
                                                children={"Add Existing Rule Set"}
                                                aliasName={
                                                  "addexistingrulesetbutton"
                                                }
                                                style={{
                                                  ...qdmstyles.NrrIA,
                                                  background: "#f1f6fc",
                                                  color: "#2a60bc",
                                                }}
                                                onClick={(e) => {
                                                  this.setState({
                                                    ...this.state,
                                                    isExist: !this.state.isExist,
                                                  });
                                                }}
                                              ></Button>
                                            </Grid>
                                          )}
                                          <CreateRule
                                            // editId={this.state.editId?.[0]?._id}
                                            // editSchema={this.state.editId?.[0]}
                                            dbName={"primarycare"}
                                            open={this.state.isRule}
                                            handleClose={(params, e) =>
                                              this.setRuleBuilderAction(
                                                params ? params : false
                                              )
                                            }
                                          />
                                          {/* <Typography
                                   key={"1"}
                                   id={"(or)_label"}
                                   align={"inherit"}
                                   color={"initial"}
                                   display={"initial"}
                                   variant={"body1"}
                                   children={"(or)"}
                                   aliasName={"(or)label"}
                                   style={qdmstyles.QhTY}
                                 ></Typography>
                                 <Button
                                   key={"2"}
                                   id={"add_new_rule_set_button"}
                                   variant={"contained"}
                                   color={"primary"}
                                   children={"Add New Rule Set"}
                                   aliasName={"addnewrulesetbutton"}
                                   style={qdmstyles.wubeK}
                                 ></Button> */}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </>
                                )}
                                <Grid
                                  key={"2"}
                                  id={"JF6E0"}
                                  container={true}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                // style={qdmstyles.GsrX}
                                >
                                  <Grid
                                    key={"0"}
                                    id={"7a5rV"}
                                    container={true}
                                    direction={"row"}
                                    justifyContent={"flex-start"}
                                    alignItems={"center"}
                                  ></Grid>
                                  <Grid
                                    key={"1"}
                                    id={"KcLtB"}
                                    container={true}
                                    direction={"row"}
                                    justifyContent={"flex-start"}
                                    alignItems={"center"}
                                    item={true}
                                    style={qdmstyles.kHVhi}
                                  >
                                    {/* {JSON.stringify(this.state?.EditOrderingdetails)} */}
                                    <ComponentRender
                                      key={"0"}
                                      id={"HS7fP"}
                                      component={
                                        <ALL.EditOrderingdetails
                                          propsState={this.state}
                                          setFun={(value) => {
                                            this.changeState(
                                              "EditOrderingdetails",
                                              value
                                            );
                                          }}
                                          stateName={"EditOrderingdetails"}
                                          isDisabled={
                                            this.props.location.state
                                              ?.isdisabled === "inactive"
                                              ? true
                                              : false
                                          }
                                          statevalue={
                                            this.state?.EditOrderingdetails
                                          }
                                        />
                                      }
                                      style={qdmstyles.Ax}
                                    ></ComponentRender>
                                  </Grid>
                                </Grid>
                              </Paper>
                            )}{" "}
                        </Grid>
                      </React.Fragment>
                    );
                  })}
              </Paper>
            </Paper>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}
const mapStateToProps = (state) => ({
  pharmacy_updateData:
    state?.EditNewPharmacypharmacy_update_a0GKUReducer?.pharmacy_update_a0GKU
      ?.data,
  pharmacy_singlereadData:
    state?.EditNewPharmacypharmacy_singleread_kbnjkReducer
      ?.pharmacy_singleread_kbnjk?.data,
  pharmacy_entitytype_dropdownData:
    state?.EditNewPharmacypharmacy_entitytype_dropdown_kbnjkReducer
      ?.pharmacy_entitytype_dropdown_kbnjk?.data,
  pharmacy_entityname_dropdownData:
    state?.EditNewPharmacypharmacy_entityname_dropdown_kbnjkReducer
      ?.pharmacy_entityname_dropdown_kbnjk?.data,
  pharmacy_locationtype_dropdownData:
    state?.EditNewPharmacypharmacy_locationtype_dropdown_kbnjkReducer
      ?.pharmacy_locationtype_dropdown_kbnjk?.data,
  pharmacy_operationstatus_dropdownData:
    state?.EditNewPharmacypharmacy_operationstatus_dropdown_kbnjkReducer
      ?.pharmacy_operationstatus_dropdown_kbnjk?.data,
  pharmacy_parentlocationid_description_dropdownData:
    state
      ?.EditNewPharmacypharmacy_parentlocationid_description_dropdown_kbnjkReducer
      ?.pharmacy_parentlocationid_description_dropdown_kbnjk?.data,
  pharmacy_locationroletype_dropdownData:
    state?.EditNewPharmacypharmacy_locationroletype_dropdown_kbnjkReducer
      ?.pharmacy_locationroletype_dropdown_kbnjk?.data,
  pharmacy_inventrysubtype_dropdownData:
    state?.EditNewPharmacypharmacy_inventrysubtype_dropdown_kbnjkReducer
      ?.pharmacy_inventrysubtype_dropdown_kbnjk?.data,
});

export default connect(
  mapStateToProps,
  actions
)(
  withRouter(withAllContexts(translate()(withStyles(styles)(EditNewPharmacy))))
);
