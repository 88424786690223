/* eslint-disable no-unused-expressions */
import React, { useContext } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { SelectWithLabel } from '../../../../components';
import { CustomCollapse } from './customCollapse';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { BackdropContext,AlertContext } from "../../../../contexts";
//import { AlertProps } from '../../../../utils';

const useStyles = makeStyles(() => ({
    button: {
        backgroundColor: '#FF4D4A',
        borderRadius: 8,
        textTransform: 'capitalize'
    }
}));

export const Cancel = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const { parent_id } = props;
    const { setBackDrop, open: loading } = useContext(BackdropContext);
    const { setSnack } = useContext(AlertContext);
    const [error,setError]=React.useState(false)

    const [state,setState] = React.useState({
        reason:{},
        appointmentInfo:[],
    })

    React.useEffect(() => {
        if (props?.selectedList?.length) {
            fetchInfo(props?.selectedList)
        }
    }, [])

    const reasonOptionList = useSelector(
        (state) => state.appointmentApiSlice.reasonForApptCancelModify
    );

    const fetchInfo = async (list) => {

        const listData = await Promise.all(list?.map(async (_, i) => {
            const data = await dispatch(actions.APPOINTMENT_READ({ appointmentId: _?.appointmentId }))
            return data?.payload?.data
        }))

        await dispatch(actions.REASON_FOR_APPT_CANCEL_MODIFY({type:'APPCANCELBYORG'}));

        onChange('appointmentInfo', listData)
    }

    const onChange = (key, value) => {
        setState({
            ...state, [key]: value
        })
         setError(false);
    }

    const cancelAppointment = async () =>{
        if(state?.reason?.value){
            setBackDrop({
                    open: true,
                    message: "Cancelling Appointment",
                });
            await Promise.all(state?.appointmentInfo?.map(async(_,i)=>{
                let payload = {..._,cancelationReason:[state?.reason?._id]}
                await dispatch(actions.APPOINTMENT_DELETE(payload))
            return _
            }))
            
            props?.refreshPendingData && props?.refreshPendingData()
            
            setBackDrop({
                open: false,
                message: "",
            });
        }
        else{
            setError(true)
            setSnack("mandatory");
        }
    }


    return(
        <div>
            {
                props?.selectedList?.length > 0 &&
                <div
                    id={`${parent_id}-parent-sub-div`}
                >
                    {
                        props?.selectedList?.map((_, i) => {
                            return (
                                <div
                                    id={`${parent_id}-CustomCollapse-div` + i}
                                    style={{
                                        marginTop: 12
                                    }}>
                                    <CustomCollapse
                                        parent_id={'cancel'}
                                        patientDetails={{
                                            name: _?.name,
                                            age: _?.age,
                                            gender: _?.gender === "male" ? 'M' : _?.gender === "male" ? 'F' : '',
                                            mrn: _?.mrn,
                                            contact: _?.['mobile no'],
                                            appointmentDateTime: _?.['appt date & time']
                                        }}
                                        doctorDetails={{
                                            name: props?.doctorDetails?.['resource name'],
                                            role: props?.doctorDetails?.role,
                                            speciality: props?.doctorDetails?.speciality,
                                        }}
                                        hideSlotAvaible={true}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            }
            <div
                id={`${parent_id}-SelectWithLabel-div`}
                style={{ marginTop: 12 }}>
                <SelectWithLabel
                    parent_id={'cancel'}
                    options={reasonOptionList?.data}
                    label={`Reason`}
                    required={true}
                    value={state?.reason}
                    onChange={(data) => onChange('reason', data)}
                error={error}
                />
            </div>
            <div style={{ marginTop: 30, textAlign: 'center' }}>
                <Button className={classes.button} onClick={cancelAppointment} variant="contained" color="primary" >Cancel Appointment</Button>
            </div>
        </div>
    )
}