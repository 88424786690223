import React from "react";
import {
  Avatar,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Person, Phone, Calendar, Address, Identity } from "../../assets";
import { getAge, getImgUrl, makeName } from "../../utils";
import Dot from "../dot";
import InfoDisplay from "../infoDisplay";
import { useDividerStyles } from "./styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
  },
  avatar: {
    height: 106,
    width: 106,
  },
  detailContainer: {
    display: "grid",
    gridTemplateAreas: `
        "infoRow1"
        "infoRow2"
        `,
    gridTemplateColumns: "1fr",
    gridGap: 20,
  },
  infoRow1: {
    gridArea: "infoRow1",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
  },
  infoRow2: {
    gridArea: "infoRow2",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  emergencyContactDetailsHeader: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  emergencyCard: {
    display: "flex",
    alignItems: "center",
    gap: 6,
    padding: 12,
    border: "1px solid #DEE5EC",
    borderRadius: 8,
    marginTop: 12,
    maxWidth: 300,
  },
  emergencyDetail: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  subrow: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  down: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}));

const getText = (value = "", filler = "-") =>
  value?.toString() ? value : filler;

function PatientProfile({ propsState, ...props }) {
  const classes = useStyles();
  const theme = useTheme();
  const dividerStyles = useDividerStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={2}>
          <Avatar
            variant="rounded"
            src={getImgUrl(propsState?.photo?.[0]?.fileid) ?? ""}
            className={classes.avatar}
          />
        </Grid>
        <Grid item xs={10}>
          <div className={classes.detailContainer}>
            <div className={classes.infoRow1}>
              <InfoDisplay
                icon={<Person color={theme.palette.primary.main} />}
                label="Patient Name"
                value={makeName(propsState?.name?.[0] || {})}
              />
              <InfoDisplay
                icon={<Person color={theme.palette.primary.main} />}
                label="Gender"
                value={getText(propsState?.gender?.display)}
              />
              <InfoDisplay
                icon={<Calendar color={theme.palette.primary.main} />}
                label="Age"
                value={getAge(propsState?.birthDate)}
              />
              <InfoDisplay
                icon={<Calendar color={theme.palette.primary.main} />}
                label="Date of birth"
                value={getText(propsState?.birthDate)}
              />
              <InfoDisplay
                icon={<Address color={theme.palette.primary.main} />}
                label="Address"
                value={getText(propsState?.address)?.replaceAll(",", "-")}
              />
            </div>
            <div className={classes.infoRow2}>
              <InfoDisplay
                icon={<Phone color={theme.palette.primary.main} />}
                label="Primary Contact number"
                value={getText(propsState?.telecom?.[0]?.value)}
              />
              <InfoDisplay
                icon={<Phone color={theme.palette.primary.main} />}
                label="Second Contact Number"
                value={getText(propsState?.telecom?.[1]?.value)}
              />
              <InfoDisplay
                icon={<Identity color={theme.palette.primary.main} />}
                label="Patient type"
                value={getText(propsState?.PatientType)}
              />
            </div>
          </div>
        </Grid>
        <Grid container item xs={12}>
          <div className={classes.down}>
            <Typography className={classes.emergencyContactDetailsHeader}>
              EMERGENCY CONTACT DETAILS
            </Typography>
            <Divider
              orientation="horizontal"
              flexItem
              classes={dividerStyles}
            />

            <div className={classes.emergencyCard}>
              <Avatar
                variant="rounded"
                src=""
                className={classes.emergencyAvatar}
              />
              <div className={classes.emergencyDetail}>
                <Typography>
                  {propsState?.EmergencyDetails?.[0]?.name ?? "N/A"}
                </Typography>
                <div className={classes.subrow}>
                  <Typography style={{ fontSize: 12 }}>
                    {propsState?.EmergencyDetails?.[0]?.relationship ?? "N/A"}
                  </Typography>
                  <Dot />
                  <Typography style={{ fontSize: 12 }}>
                    {propsState?.EmergencyDetails?.[0]?.telecom?.[0]?.value ??
                      "N/A"}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default PatientProfile;
