import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import {
  DispenseDurationUOMOptions,
  isEmptyObject,
  languageOptions,
  Permissions,
} from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";
import { CreateRule } from "sequence-rule-builder";
import moment from "moment";
import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  Divider,
  TextField,
  Avatar,
  makeStyles,
  withStyles,
  Chip as MuiChip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// import { Autocomplete } from "@material-ui/lab";
import qdmstyles from "./styles.json";
import "./index.css";
import { InputBase, Autocomplete, TextfieldWithSelect } from "../inputs";
import copy from "fast-copy";
import classNames from "classnames";
import { CreateIcon, TrashIcon } from "../../assets";
import { withAllContexts } from "../../HOCs";
import CustomButton from "../customButton";
import Color from "../../themes/default.json";
var aes256 = require("aes256");

const Chip = withStyles((theme) => ({
  root: {
    borderRadius: "4px",
    height: 24,
    backgroundColor: "#DEE5EC",
  },
  deleteIcon: {
    height: 16,
    width: 16,
  },
}))(MuiChip);

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    "& > .App1-MuiTypography-root": {
      fontSize: 12,
      fontWeight: 400,
      color: Color.palette.text.tertiary,
    },
  },
  removeGutterRight: {
    "&.App1-MuiAutocomplete-hasPopupIcon&.MuiAutocomplete-hasClearIcon": {
      "& .App1-MuiAutocomplete-inputRoot": {
        paddingRight: 30,
      },
    },
  },
  multiAutoComplete: {
    "& .App1-MuiAutocomplete-inputRoot": {
      position: "relative",
      gap: 0,
      "& .App1-MuiInputAdornment-root": {
        position: "absolute",
        right: 2,
      },
    },
  },
  alloweddisptype: {
    color: theme.palette.primary.light
  },
  dispanse: {
    color: theme.palette.text.primary
  },
  cancel: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.primary.light
  },
  add_rule: {
    "&.App1-MuiButton-contained:hover": {
      backgroundColor: theme.palette.button.hover
    },
    color:theme.palette.button.text,
    backgroundColor:theme.palette.button.background,
  },
  dispanse_duration: {
    color: theme.palette.primary.light
  },
  update_save: {
    "&.App1-MuiButton-contained:hover": {
      backgroundColor: theme.palette.button.hover
    },
    color:theme.palette.button.text,
    backgroundColor:theme.palette.button.background,
  },
  up_add: {
    "&.App1-MuiButton-contained:hover": {
      backgroundColor: theme.palette.button.hover
    },
    color:theme.palette.button.text,
    backgroundColor:theme.palette.button.background,
  },
  commonBackground:{
    backgroundColor:theme.palette.background.table
  }
}));

const { group_dropdown, rule_dropdown } = actions;

const initialDrugDispenseState = {
  id: 0,
  dfltDispDurationVal: "",
  dfltDispDurationUOM: "",
  maxDispDurationVal: "",
  maxDispDurationUOM: "",
  Action: "",
  ActionMessage: "",
  Criteria: [],
};

const initialCriteriaRuleState = {
  editId: [],
  isRule: false,
  isExist: false,
  isOpen: false,
  group: {},
  rule: {},
};

function Despensedefaultcomp(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();

  // Store States
  const groupDropDownData = useSelector(
    (state) => state?.ruleExistReducer?.group_dropdown
  );

  const {
    data,
    index,
    options,
    setFun = () => { },
    stateName,
    statevalue,
    shouldDisable,
  } = props;

  const contextType = React.useContext(LoggerContext);
  // const [isRule, setIsRule] = React.useState(false);
  const [ruleState, setRuleState] = React.useState({
    editId: [],
    isRule: false,
    isExist: false,
    isOpen: false,

    // Existing
    groupData: [],
    ruleData: [],
    group: {},
    rule: {},
  });
  const [state, setState] = React.useState({
    permissionData: null,
    showAdd: false,
    editIndex: "",

    ...initialDrugDispenseState,

    // Local State
    MaxDispDurnRulebyEnctype: props?.statevalue?.MaxDispDurnRulebyEnctype,
  });

  const changeState = (key, value) => {
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const changeRuleState = (key, value) => {
    setRuleState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // Open add if no disp rules present in state
  React.useEffect(() => {
    if (statevalue?.MaxDispDurnRulebyEnctype?.length === 0) {
      changeState("showAdd", true);
    }
  }, [statevalue]);

  React.useEffect(() => {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {
    Promise.resolve(dispatch(group_dropdown()))
      .then((res) => {
        const groupOptions = res?.payload?.result;
        changeRuleState("groupData", groupOptions);
      })
      .catch((e) => console.error(e));
  }, []);

  const handleCancelAddClick = () => {
    resetDataState();
    changeState("showAdd", false);
  };

  const handleAddNewClick = () => {
    changeState("showAdd", true);
  };

  const saveNewFrequencyRule = () => {
    const {
      dfltDispDurationVal,
      dfltDispDurationUOM,
      maxDispDurationVal,
      maxDispDurationUOM,
      Action,
      ActionMessage,
      Criteria,
    } = state;

    let newDispRuleState = {
      dfltDispDurationVal,
      dfltDispDurationUOM,
      maxDispDurationVal,
      maxDispDurationUOM,
      Action,
      ActionMessage,
      Criteria,
    };

    let newMaxDispDurnRulebyEnctype = [
      ...state?.MaxDispDurnRulebyEnctype,
      newDispRuleState,
    ];
    changeState("MaxDispDurnRulebyEnctype", newMaxDispDurnRulebyEnctype);

    // Save value to parent
    props.setFun(
      stateName,
      "MaxDispDurnRulebyEnctype",
      newMaxDispDurnRulebyEnctype
    );
  };

  const resetDataState = () => {
    setState((prev) => ({
      ...prev,
      ...initialDrugDispenseState,
      editIndex: "",
    }));
    setRuleState((prev) => ({
      ...prev,
      ...initialCriteriaRuleState,
    }));
  };

  const convertDaysUOM = (value, uom) => {
    switch (uom) {
      case DispenseDurationUOMOptions.Hours: {
        return moment().add(value, "hours");
      }
      case DispenseDurationUOMOptions.Days: {
        return moment().add(value, "days");
      }
      case DispenseDurationUOMOptions.Weeks: {
        return moment().add(value, "weeks");
      }
      case DispenseDurationUOMOptions.Months: {
        return moment().add(value, "months");
      }
      case DispenseDurationUOMOptions.Years: {
        return moment().add(value, "years");
      }
      default: {
        return moment();
      }
    }
  };

  const checkIsFormError = ({
    dfltDispDurationVal,
    dfltDispDurationUOM,
    maxDispDurationVal,
    maxDispDurationUOM,
  }) => {
    if (dfltDispDurationUOM && maxDispDurationUOM) {
      // If Default and max dispense duration, in which any one is of Dose UOM, and the other is not then throuw error
      if (
        (dfltDispDurationUOM?._id === DispenseDurationUOMOptions.Doses &&
          maxDispDurationUOM?._id !== DispenseDurationUOMOptions.Doses) ||
        (dfltDispDurationUOM?._id !== DispenseDurationUOMOptions.Doses &&
          maxDispDurationUOM?._id === DispenseDurationUOMOptions.Doses)
      ) {
        props.alert.setSnack({
          open: true,
          msg: `Invalid UOM for default dispense duration and max dispense duration.`,
          severity: "error",
        });
        return true;
      }

      // If UOM is doses then do normal check
      if (dfltDispDurationUOM?._id === DispenseDurationUOMOptions.Doses && maxDispDurationUOM?._id === DispenseDurationUOMOptions.Doses) {
        if (dfltDispDurationVal > maxDispDurationVal) {
          props.alert.setSnack({
            open: true,
            msg: `Default dispense duration should be less than max dispense duration.`,
            severity: "error",
          });
          return true;
        }
      }
    }

    let defaultDispDate = convertDaysUOM(
      dfltDispDurationVal,
      dfltDispDurationUOM?._id
    );
    let maxDispDate = convertDaysUOM(
      maxDispDurationVal,
      maxDispDurationUOM?._id
    );

    if (
      dfltDispDurationVal &&
      maxDispDurationVal &&
      dfltDispDurationUOM &&
      maxDispDurationUOM &&
      defaultDispDate.isAfter(maxDispDate, "hours")
    ) {
      props.alert.setSnack({
        open: true,
        msg: `Default dispense duration should be less than max dispense duration.`,
        severity: "error",
      });
      return true;
    }

    return false;
  };

  const handleSaveClick = (editIndex, addAnother) => {
    // 0. Handle Validation

    // // 1. Save new frequency rule
    // saveNewFrequencyRule();

    // // 2. Reset Data State
    // resetDataState();

    // // 3. Close Add
    // if (!addAnother) {
    //   changeState("showAdd", false);
    // }

    const {
      dfltDispDurationVal,
      dfltDispDurationUOM,
      maxDispDurationVal,
      maxDispDurationUOM,
      Action,
      ActionMessage,
      // Criteria,
    } = state;

    const { editId: Criteria } = ruleState;

    // if (maxDispDurationVal < dfltDispDurationVal) {
    //   return props.alert.setSnack({
    //     open: true,
    //     msg: `Max Dispense Duration value should not be less than Default Dispense Duration value`,
    //     severity: "error",
    //   });
    // }

    if (
      checkIsFormError({
        dfltDispDurationVal,
        dfltDispDurationUOM,
        maxDispDurationVal,
        maxDispDurationUOM,
        Action,
        ActionMessage,
        Criteria,
      })
    ) {
      return;
    }

    if (editIndex) {
      // Take the previous array
      let newMaxDispDurnRulebyEnctype = [...state?.MaxDispDurnRulebyEnctype];

      // Update the index element with new values
      newMaxDispDurnRulebyEnctype[editIndex] = {
        ...newMaxDispDurnRulebyEnctype[editIndex],
        dfltDispDurationVal,
        dfltDispDurationUOM,
        maxDispDurationVal,
        maxDispDurationUOM,
        Action,
        ActionMessage,
        Criteria,
      };

      // Update State
      setState((prev) => ({
        ...prev,
        MaxDispDurnRulebyEnctype: newMaxDispDurnRulebyEnctype,
        ...copy(initialDrugDispenseState),
        showAdd: addAnother,
        editIndex: "",
      }));

      setRuleState((prev) => ({
        ...prev,
        ...copy(initialCriteriaRuleState),
      }));

      // Save value to parent
      props.setFun(
        stateName,
        "MaxDispDurnRulebyEnctype",
        newMaxDispDurnRulebyEnctype
      );
    } else {
      // Create New Object for add
      let newDispRuleState = {
        ...initialDrugDispenseState,
        dfltDispDurationVal,
        dfltDispDurationUOM,
        maxDispDurationVal,
        maxDispDurationUOM,
        Action,
        ActionMessage,
        Criteria,
      };

      // Append to existing array
      let newMaxDispDurnRulebyEnctype = [
        ...state?.MaxDispDurnRulebyEnctype,
        newDispRuleState,
      ];

      // Update State
      setState((prev) => ({
        ...prev,
        MaxDispDurnRulebyEnctype: newMaxDispDurnRulebyEnctype,
        ...copy(initialDrugDispenseState),
        showAdd: addAnother,
      }));

      setRuleState((prev) => ({
        ...prev,
        ...copy(initialCriteriaRuleState),
      }));

      // Save value to parent
      props.setFun(
        stateName,
        "MaxDispDurnRulebyEnctype",
        newMaxDispDurnRulebyEnctype
      );
    }
  };

  const handleEditClick = (index) => {
    let newMaxDispDurnRulebyEnctype = [...state?.MaxDispDurnRulebyEnctype];
    let newDispRuleState = newMaxDispDurnRulebyEnctype[index];

    setState((prev) => ({
      ...prev,
      dfltDispDurationVal: newDispRuleState?.dfltDispDurationVal,
      dfltDispDurationUOM: newDispRuleState?.dfltDispDurationUOM,
      maxDispDurationVal: newDispRuleState?.maxDispDurationVal,
      maxDispDurationUOM: newDispRuleState?.maxDispDurationUOM,
      Action: newDispRuleState?.Action,
      ActionMessage: newDispRuleState?.ActionMessage,
      // Criteria: newDispRuleState?.Criteria,

      // Open the modal
      showAdd: true,

      // Set Edit Index
      editIndex: index?.toString(),
    }));

    setRuleState((prev) => ({
      ...prev,
      editId: newDispRuleState?.Criteria,
      isOpen: newDispRuleState?.Criteria?.length > 0,
    }));

    // handleModalOpen(index);
  };

  const handleDeleteClick = (index) => {
    let newMaxDispDurnRulebyEnctype = [...state?.MaxDispDurnRulebyEnctype];
    newMaxDispDurnRulebyEnctype.splice(index, 1);
    changeState("MaxDispDurnRulebyEnctype", newMaxDispDurnRulebyEnctype);

    // Save value to parent
    props.setFun(
      stateName,
      "MaxDispDurnRulebyEnctype",
      newMaxDispDurnRulebyEnctype
    );
  };

  const setRuleBuilderAction = (data) => {
    if (!data) {
      setRuleState((prev) => ({
        ...prev,
        isRule: !prev?.isRule,
      }));
      return;
    }

    setRuleState((prev) => ({
      ...prev,
      editId: [data?.Result?.[0]?.properties?.doc],
      isRule: !prev.isRule,
      isOpen: true,
    }));
  };

  // Existing Rule Set
  const handleGroupStateChange = (value) => {
    
    Promise.resolve(dispatch(rule_dropdown({ groupId: value?.value })))
      .then((res) => {
        changeRuleState("ruleData", res?.payload?.result);
        // setRuleState((prev) => ({
        //   ...prev,
        //   group: value,
        //   ruleData: res?.payload?.result,
        // }));
      })
      .catch((e) => {
        // setRuleState((prev) => ({
        //   ...prev,
        //   group: value,
        // }));
        console.error(e);
      })
      .finally(() => {
        changeRuleState("group", value);
      });
  };

  const handleExistingRuleSave = () => {
    const { group, rule } = ruleState;
    if (isEmptyObject(group)) {
      return props.alert.setSnack({
        open: true,
        msg: `Please choose the group`,
        severity: "error",
      });
    }
    if (isEmptyObject(rule)) {
      return props.alert.setSnack({
        open: true,
        msg: `Please choose the rule`,
        severity: "error",
      });
    }

    setRuleState((prev) => ({
      ...prev,
      editId: [
        {
          group: group,
          rule: rule,
        },
      ],
      isExist: false,
      isOpen: true,
      group: {},
      rule: {},
    }));
  };

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"bfymE"}
            container={""}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Paper
              key={"0"}
              id={"paper_coverforaddnew"}
              elevation={"{3}"}
              aliasName={"papercoverforaddnew"}
              style={qdmstyles.HyGnC}
              className={classes.commonBackground}
            >
              <Grid
                key={"1"}
                id={"CZVZN"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                spacing={1}
                xl={"12"}
                xs={"12"}
                style={qdmstyles.vFYR}
              >
                <Grid
                  key={"0"}
                  id={"2MXs9"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item={true}
                  lg={"9"}
                  md={"9"}
                  sm={"9"}
                  xl={"9"}
                  xs={"9"}
                  style={qdmstyles.ixFX}
                >
                  <Grid
                    key={"0"}
                    id={"d2nWC"}
                    container={""}
                    direction={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    item={true}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    style={qdmstyles.LLDye}
                  >
                    <Typography
                      key={"0"}
                      id={"allowedDispTypeHeading_typogrpahy"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Allowed Dispense Type"}
                      aliasName={"allowedDispTypeHeading"}
                      className={classes.alloweddisptype}
                      style={qdmstyles.oJhzE}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"d2nWC"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                  >
                    <Autocomplete
                      id={"XhCxn"}
                      style={qdmstyles?.XhCxn}
                      // Controllers
                      disabled={
                        location?.state?.isView === true || !shouldDisable
                          ? true
                          : false
                      }
                      getOptionLabel={(option) => option?.["label"]}
                      options={options?.dispense_type ?? []}
                      multiple
                      className={classNames(
                        classes.removeGutterRight,
                        classes.multiAutoComplete
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option?.label}
                            deleteIcon={<CloseIcon htmlColor="#6A7888" />}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      value={statevalue?.alloweddisptype}
                      onLoad={() =>
                        setFun(
                          stateName,
                          "alloweddisptype",
                          statevalue?.alloweddisptype
                        )
                      }
                      onChange={(event, newValue) =>
                        setFun(stateName, "alloweddisptype", newValue)
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  key={"1"}
                  id={"2MXs9"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item={true}
                  lg={"3"}
                  sm={"3"}
                  md={"3"}
                  xl={"3"}
                  xs={"3"}
                >
                  <Grid
                    key={"0"}
                    id={"d2nWC"}
                    container={""}
                    direction={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    item={true}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    style={qdmstyles.UY}
                  >
                    <Typography
                      key={"0"}
                      id={"defaultDispenseTypeHeading_Typography"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Default Dispense Type"}
                      className={classes.alloweddisptype}
                      aliasName={"defaultDispenseTypeHeading"}
                      style={qdmstyles.PoAZ}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"d2nWC"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                  >
                    <Autocomplete
                      id={"i43fB"}
                      style={qdmstyles?.ifB}
                      // Controllers
                      disabled={
                        location?.state?.isView === true || !shouldDisable
                          ? true
                          : false
                      }
                      getOptionLabel={(option) => option?.["label"]}
                      options={statevalue?.alloweddisptype ?? []}
                      className={classes.removeGutterRight}
                      value={statevalue?.defaultdisptype}
                      onLoad={() =>
                        setFun(
                          stateName,
                          "defaultdisptype",
                          statevalue?.defaultdisptype
                        )
                      }
                      onChange={(event, newValue) =>
                        setFun(stateName, "defaultdisptype", newValue)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Add new dispense defaults Section Starts */}
              {state?.showAdd && (
                <>
                  <Grid
                    key={"0"}
                    id={"b7AAp"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid
                      key={"0"}
                      id={"pULoO"}
                      container={true}
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"flex-start"}
                      lg={"auto"}
                      item={true}
                      alignContent={"stretch"}
                      md={"auto"}
                      sm={"auto"}
                      xl={"auto"}
                      xs={"auto"}
                      style={qdmstyles.hJTj}
                    >
                      <Typography
                        key={"0"}
                        id={"addNewDispensedefaultheading"}
                        align={"inherit"}
                        color={"primary"}
                        display={"initial"}
                        variant={"body1"}
                        children={"ADD NEW DISPENSE DEFAULTS"}
                        aliasName={"addNewDispensedefaultheading"}
                        className={classes.dispanse}
                        style={
                          qdmstyles.JfSS
                        }
                      ></Typography>
                      {statevalue?.MaxDispDurnRulebyEnctype?.length > 0 && (
                        <Grid item>
                          <Button
                            key={"0"}
                            id={"cancel_add_new_button"}
                            disabled={
                              location?.state?.isView === true || !shouldDisable
                                ? true
                                : false
                            }
                            onClick={handleCancelAddClick}
                            variant={"text"}
                            children={"Cancel"}
                            aliasName={"statusaddnewbutton"}
                            style={qdmstyles.KIyuwwasK}
                          ></Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    key={"1"}
                    id={"0NqD2"}
                    container={""}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    item={true}
                    lg={"12"}
                    style={qdmstyles.KWgvx}
                  >
                    <Paper
                      key={"0"}
                      id={"A4OWh"}
                      elevation={""}
                      variant={"elevation"}
                      style={qdmstyles.FDGI}
                      className={classes.commonBackground}
                    >
                      <Grid
                        key={"0"}
                        id={"0wzSg"}
                        container={""}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        style={qdmstyles.suz}
                      >
                        <Grid
                          key={"0"}
                          id={"AySG3"}
                          container={true}
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          item={true}
                          style={qdmstyles.wbZg23}
                        >
                          <Typography
                            key={"0"}
                            id={"defineCriteriaByPatientAndPharmacy_heading"}
                            align={"inherit"}
                            color={"primary"}
                            display={"initial"}
                            variant={"body1"}
                            className={classes.dispanse}
                            children={"DEFINE CRITERIA BY PATIENT AND PHARMACY"}
                            aliasName={
                              "defineCriteriaByPatientAndPharmacytypography"
                            }
                            style={{
                              ...qdmstyles.xOuU,
                              color: Color.palette.text.dark,
                            }}
                          ></Typography>
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"Ew1Xu"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          lg={"12"}
                          style={qdmstyles.uhnDj}
                        >
                          {ruleState?.isOpen ? (
                            <Grid container spacing={2}>
                              <Grid item xs>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {" "}
                                  <div>
                                    <Avatar
                                      key={"0"}
                                      id={"group_icon"}
                                      alt={"Avatar"}
                                      variant={"square"}
                                      src={
                                        "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317031738"
                                      }
                                      aliasName={"groupicon"}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        background: "red",
                                        padding: "7px",
                                        borderRadius: "50%",
                                        marginRight: "4px",
                                        backgroundColor: "#f1f6fc",
                                      }}
                                    ></Avatar>
                                  </div>
                                  <div>
                                    DISPENSE DEFAULTS <br />
                                    PATIENT AND PHARMACY
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xs>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    <Avatar
                                      key={"0"}
                                      id={"group_icon"}
                                      alt={"Avatar"}
                                      variant={"square"}
                                      src={
                                        "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317006791"
                                      }
                                      aliasName={"groupicon"}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        background: "red",
                                        padding: "7px",
                                        borderRadius: "50%",
                                        marginRight: "4px",
                                        backgroundColor: "#f1f6fc",
                                      }}
                                    ></Avatar>
                                  </div>
                                  <div>
                                    {ruleState.editId?.[0]?.ruleName ||
                                      ruleState.editId?.[0]?.group?.label ||
                                      ""}
                                    <br />
                                    {ruleState.editId?.[0]?.priority ||
                                      ruleState.editId?.[0]?.rule?.label ||
                                      ""}
                                  </div>
                                </div>
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={() => {
                                    setRuleState((prev) => ({
                                      ...prev,
                                      ...copy(initialCriteriaRuleState),
                                      isOpen: !prev.isOpen,
                                    }));
                                  }}
                                  style={qdmstyles.dLEbQ2}
                                  disabled={
                                    location?.state?.isView === true ||
                                    !shouldDisable
                                      ? true
                                      : false
                                  }
                                >
                                  Remove
                                </Button>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid
                              key={"0"}
                              id={"h6VuZ"}
                              container={true}
                              direction={"row"}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              lg={"12"}
                              item={""}
                              md={"12"}
                              sm={"12"}
                              xl={"12"}
                              xs={"12"}
                            >
                              {ruleState?.isExist && (
                                <Grid
                                  id={"i1kt4"}
                                  container={true}
                                  direction={"row"}
                                  justifyContent={"flex-start"}
                                  alignItems={"center"}
                                  style={qdmstyles.ikt}
                                  spacing={2}
                                >
                                  <Grid
                                    key={"1"}
                                    id={"V7v9F"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    lg={"4"}
                                    md={"4"}
                                    sm={"4"}
                                    xl={"4"}
                                    xs={"4"}
                                    item={true}
                                  >
                                    <div className={classes.fieldContainer}>
                                      <Typography
                                        id={"Group_Typography"}
                                        children={"Group"}
                                        className={classes.alloweddisptype}
                                      ></Typography>
                                      <Autocomplete
                                        // value={this.state?.group ?? {}}
                                        // getOptionLabel={(option) => option?.["label"]}
                                        // options={this.state?.groupData ?? []}
                                        // size={"small"}
                                        // onChange={(e, newvalue) =>
                                        //   this.handleChange(newvalue, "group")
                                        // }
                                        // renderInput={(params) => (
                                        //   <ALLMCORE.TextField
                                        //     {...params}
                                        //     margin="dense"
                                        //     variant="outlined"
                                        //     label="Group"
                                        //   />
                                        // )}
                                        id={"r9IiL"}
                                        style={qdmstyles?.rIiL}
                                        disabled={
                                          location?.state?.isView === true ||
                                          !shouldDisable
                                            ? true
                                            : false
                                        }
                                        getOptionLabel={(option) =>
                                          option?.["label"]
                                        }
                                        options={ruleState?.groupData ?? []}
                                        value={ruleState?.group}
                                        onLoad={() =>
                                          changeRuleState(
                                            "group",
                                            ruleState?.group
                                          )
                                        }
                                        onChange={(event, newValue) =>
                                          handleGroupStateChange(newValue)
                                        }
                                      />
                                    </div>
                                  </Grid>
                                  <Grid
                                    key={"1"}
                                    id={"V7v9F"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    lg={"4"}
                                    md={"4"}
                                    sm={"4"}
                                    xl={"4"}
                                    xs={"4"}
                                    item={true}
                                  >
                                    <div className={classes.fieldContainer}>
                                      <Typography
                                        id={"Rule_Typography"}
                                        className={classes.alloweddisptype}
                                        children={"Rule"}
                                      ></Typography>
                                      <Autocomplete
                                        // value={this.state?.rule ?? {}}
                                        // getOptionLabel={(option) => option?.["label"]}
                                        // options={this.state?.ruleData ?? []}
                                        // size={"small"}
                                        // onChange={(e, newvalue) =>
                                        //   this.handleChange(newvalue, "rule")
                                        // }
                                        // renderInput={(params) => (
                                        //   <ALLMCORE.TextField
                                        //     {...params}
                                        //     margin="dense"
                                        //     variant="outlined"
                                        //     label="Rule"
                                        //   />
                                        // )}
                                        id={"lksad93"}
                                        style={qdmstyles?.rIiL}
                                        getOptionLabel={(option) =>
                                          option?.["label"]
                                        }
                                        options={ruleState?.ruleData ?? []}
                                        value={ruleState?.rule}
                                        disabled={
                                          location?.state?.isView === true ||
                                          !shouldDisable
                                            ? true
                                            : false
                                        }
                                        onLoad={() =>
                                          changeRuleState(
                                            "rule",
                                            ruleState?.rule
                                          )
                                        }
                                        onChange={(event, newValue) =>
                                          changeRuleState("rule", newValue)
                                        }
                                      />
                                    </div>
                                  </Grid>

                                  <Grid
                                    key={"1"}
                                    id={"V7v339F"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    lg={"4"}
                                    md={"4"}
                                    sm={"4"}
                                    xl={"4"}
                                    xs={"4"}
                                    item={true}
                                  >
                                    <div className={classes.fieldContainer}>
                                      <Typography
                                        style={{
                                          opacity: 0,
                                          userSelect: "none",
                                        }}
                                      >
                                        cta
                                      </Typography>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 8,
                                        }}
                                      >
                                        <CustomButton
                                          variant={"contained"}
                                          disabled={
                                            location?.state?.isView === true ||
                                            !shouldDisable
                                              ? true
                                              : false
                                          }
                                          onClick={() =>
                                            handleExistingRuleSave()
                                          }
                                          color={'primary'}
                                        >
                                          Save
                                        </CustomButton>
                                        <CustomButton
                                          variant="text"
                                          disabled={
                                            location?.state?.isView === true ||
                                            !shouldDisable
                                              ? true
                                              : false
                                          }
                                          onClick={(e) => {
                                            setRuleState((prev) => {
                                              return {
                                                ...prev,
                                                isExist: !prev.isExist,
                                              };
                                            });
                                          }}
                                          className={classes.cancel}
                                        >
                                          Cancel
                                        </CustomButton>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              )}

                              {!ruleState?.isExist && (
                                <Grid item container xs={12}>
                                  <Grid
                                    key={"0"}
                                    id={"wDQNk"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"flex-start"}
                                    alignItems={"center"}
                                    item={true}
                                    lg={"auto"}
                                    style={qdmstyles.MYHwo}
                                  >
                                    <Button
                                      key={"0"}
                                      id={"addNewRuleSet_Button"}
                                      disabled={
                                        location?.state?.isView === true ||
                                        !shouldDisable
                                          ? true
                                          : false
                                      }
                                      variant={"text"}
                                      color={"primary"}
                                      children={"Add New Rule Set"}
                                      aliasName={"addNewRuleSetButton"}
                                      style={qdmstyles.FoTd}
                                      onClick={() => {
                                        setRuleState((prev) => {
                                          return {
                                            ...prev,
                                            isRule: !prev.isRule,
                                          };
                                        });
                                      }}
                                      className={classes.add_rule}
                                    ></Button>
                                  </Grid>
                                  <Grid
                                    key={"1"}
                                    id={"CU1k8"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                  >
                                    <Typography
                                      key={"0"}
                                      id={"(or)_header"}
                                      align={"inherit"}
                                      color={"initial"}
                                      display={"initial"}
                                      variant={"body1"}
                                      children={"(or)"}
                                      aliasName={"(or)header"}
                                      className={classes.dispanse}
                                    ></Typography>
                                  </Grid>
                                  <Grid
                                    key={"2"}
                                    id={"DWbgk"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    item={true}
                                    lg={"auto"}
                                    style={qdmstyles.qrjcz}
                                  >
                                    <Button
                                      key={"0"}
                                      id={"addExistingRuleSet_button"}
                                      variant={"text"}
                                      disabled={
                                        location?.state?.isView === true ||
                                        !shouldDisable
                                          ? true
                                          : false
                                      }
                                      color={"primary"}
                                      children={"Add Existing Rule Set"}
                                      aliasName={"addExistingRuleSetbutton"}
                                      style={qdmstyles.SVdEr}
                                      className={classes.add_rule}
                                      onClick={(e) => {
                                        setRuleState((prev) => {
                                          return {
                                            ...prev,
                                            isExist: !prev.isExist,
                                          };
                                        });
                                      }}
                                    ></Button>
                                  </Grid>
                                </Grid>
                              )}

                              <CreateRule
                                // editId="Rules/10719"
                                editId={ruleState.editId?.[0]?._id}
                                editSchema={ruleState.editId?.[0]}
                                dbName={"primarycare"}
                                open={ruleState.isRule}
                                handleClose={(params, e) =>
                                  setRuleBuilderAction(params ? params : false)
                                }
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Grid
                          key={"2"}
                          id={"9w7mS"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                        >
                          <Box
                            key={"0"}
                            id={"Zq4Lx"}
                            m={1}
                            component={"div"}
                            style={qdmstyles.zIaSk}
                          >
                            <Divider
                              key={"0"}
                              id={"divider_adddispensedefault"}
                              variant={"fullWidth"}
                              orientation={"horizontal"}
                              light={""}
                              flexItem={""}
                              absolute={""}
                              aliasName={"dividerofadddispensedefault"}
                            ></Divider>
                          </Box>
                        </Grid>
                        <Grid
                          key={"3"}
                          id={"qsdJf"}
                          container={true}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"12"}
                          item={true}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                          spacing={"2"}
                          style={qdmstyles.DVD}
                        >
                          <Grid
                            key={"0"}
                            id={"2MXs9"}
                            container={true}
                            direction={"column"}
                            justifyContent={"center"}
                            alignItems={"flex-start"}
                            item={true}
                            lg={"3"}
                            md={"6"}
                            sm={"6"}
                            xl={"3"}
                            xs={"6"}
                            alignContent={"center"}
                          >
                            <Grid
                              key={"0"}
                              id={"d2nWC"}
                              container={""}
                              direction={"column"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"12"}
                              xs={"12"}
                              xl={"12"}
                              sm={"12"}
                              md={"12"}
                            >
                              <Typography
                                key={"0"}
                                id={"defaultDispenseDuration&Uom_heading"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={"Default Dispense Duration & Uom"}
                                aliasName={"defaultDispenseDuration&Uom"}
                                className={classes.dispanse_duration}
                                style={qdmstyles.zIaSk}
                              ></Typography>
                            </Grid>
                            <Grid
                              key={"1"}
                              id={"d2nWC"}
                              container={true}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"12"}
                              md={"12"}
                              sm={"12"}
                              xl={"12"}
                              xs={"12"}
                              style={qdmstyles.WPTV}
                            >
                              <TextfieldWithSelect
                                InputProps={{
                                  id: `DefaultDispenseDuration`,
                                  type: "text",
                                  fullWidth: true,
                                  disabled:
                                    location?.state?.isView === true ||
                                    !shouldDisable
                                      ? true
                                      : false,
                                  inputProps: {
                                    maxLength: 3,
                                  },
                                  onKeyPress: (event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  },
                                  value: state?.dfltDispDurationVal,
                                  // rowState?.compQty
                                  onChange: (event) =>
                                    changeState(
                                      "dfltDispDurationVal",
                                      event.target.value
                                        ? parseInt(event.target.value)
                                        : 0
                                    ),
                                }}
                                AutocompleteProps={{
                                  id: `DefaultDispenseUOM`,
                                  disabled:
                                    location?.state?.isView === true ||
                                    !shouldDisable
                                      ? true
                                      : false,
                                  getOptionLabel: (option) => option?.["label"],
                                  options:
                                    options?.order_default_duration_uom ?? [],
                                  value: state?.dfltDispDurationUOM,
                                  onLoad: () =>
                                    changeState(
                                      "dfltDispDurationUOM",
                                      state?.dfltDispDurationUOM
                                    ),
                                  onChange: (event, newValue) =>
                                    changeState(
                                      "dfltDispDurationUOM",
                                      newValue
                                    ),
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"2MXs9"}
                            container={true}
                            direction={"column"}
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            item={true}
                            lg={"2"}
                            md={"6"}
                            sm={"6"}
                            xl={"2"}
                            xs={"6"}
                          >
                            <Grid
                              key={"0"}
                              id={"d2nWC"}
                              container={""}
                              direction={"column"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"12"}
                              md={"12"}
                              sm={"12"}
                              xl={"12"}
                              xs={"12"}
                            >
                              <Typography
                                key={"0"}
                                id={"maxDispDurationVal&Uom_heading "}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={"maxDispDurationVal & Uom"}
                                aliasName={"maxDispDurationVal&UomTypography "}
                                className={classes.dispanse_duration}
                                style={qdmstyles.VJX}
                              ></Typography>
                            </Grid>
                            <Grid
                              key={"1"}
                              id={"d2nWC"}
                              container={true}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"12"}
                              md={"12"}
                              sm={"12"}
                              xl={"12"}
                              xs={"12"}
                              style={qdmstyles.Exqmy}
                            >
                              {/* <Grid
                            key={"0"}
                            id={"d2nWC"}
                            container={true}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"6"}
                            md={"6"}
                            sm={"6"}
                            xl={"6"}
                            xs={"6"}
                          >
                            <TextField
                              key={"0"}
                              id={"maxDispDurationVal&Uom_Textfield"}
                              label={""}
                              placeholder={""}
                              type={"text"}
                              select={""}
                              size={"small"}
                              variant={"outlined"}
                              fullWidth={true}
                              margin={"none"}
                              aliasName={"maxDispDurationVal&Uominput"}
                              style={qdmstyles.cFu}
                            ></TextField>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"d2nWC"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"6"}
                            xs={"6"}
                            xl={"6"}
                            sm={"6"}
                            md={"6"}
                          >
                            <Autocomplete
                              id={"OXRzD"}
                              style={qdmstyles?.OXRzD}
                              value={state?.despensedefaultcomp_oxrzd ?? null}
                              onLoad={() =>
                                setState({
                                  despensedefaultcomp_oxrzd:
                                    state?.despensedefaultcomp_oxrzd ?? null,
                                })
                              }
                              getOptionLabel={(option) => option?.["label"]}
                              options={[] ?? []}
                              size={"small"}
                              renderInput={(params) => (
                                <ALLMCORE.TextField
                                  {...params}
                                  label={""}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid> */}
                              <TextfieldWithSelect
                                InputProps={{
                                  id: `maxDispDurationVal`,
                                  type: "text",
                                  fullWidth: true,
                                  disabled:
                                    location?.state?.isView === true ||
                                    !shouldDisable
                                      ? true
                                      : false,
                                  inputProps: {
                                    maxLength: 3,
                                  },
                                  onKeyPress: (event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  },
                                  value: state?.maxDispDurationVal,
                                  onChange: (event) =>
                                    changeState(
                                      "maxDispDurationVal",
                                      event.target.value
                                        ? parseInt(event.target.value)
                                        : 0
                                    ),
                                }}
                                AutocompleteProps={{
                                  id: `maxDispDurationUOM`,
                                  disabled:
                                    location?.state?.isView === true ||
                                    !shouldDisable
                                      ? true
                                      : false,
                                  getOptionLabel: (option) => option?.["label"],
                                  options:
                                    options?.order_default_duration_uom ?? [],
                                  value: state?.maxDispDurationUOM,
                                  onLoad: () =>
                                    changeState(
                                      "maxDispDurationUOM",
                                      state?.maxDispDurationUOM
                                    ),
                                  onChange: (event, newValue) =>
                                    changeState("maxDispDurationUOM", newValue),
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            key={"2"}
                            id={"2MXs9"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            item={true}
                            lg={"2"}
                            md={"3"}
                            sm={"3"}
                            xl={"2"}
                            xs={"3"}
                          >
                            <Grid
                              key={"0"}
                              id={"d2nWC"}
                              container={""}
                              direction={"column"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"12"}
                              md={"12"}
                              sm={"12"}
                              xl={"12"}
                              xs={"12"}
                            >
                              <Typography
                                key={"0"}
                                id={"action_heading"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={"Action"}
                                aliasName={"actionTypography"}
                                className={classes.dispanse_duration}
                                comment={""}
                                style={qdmstyles.onNLr}
                              ></Typography>
                            </Grid>
                            <Grid
                              key={"1"}
                              id={"d2nWC"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"12"}
                              md={"12"}
                              sm={"12"}
                              xl={"12"}
                              xs={"12"}
                            >
                              <Autocomplete
                                id={"d1D8Z"}
                                style={qdmstyles?.dDZ}
                                disabled={
                                  location?.state?.isView === true ||
                                  !shouldDisable
                                    ? true
                                    : false
                                }
                                getOptionLabel={(option) => option?.["label"]}
                                options={options?.privilege_type ?? []}
                                value={state?.Action}
                                onLoad={() =>
                                  changeState("Action", state?.Action)
                                }
                                onChange={(event, newValue) =>
                                  changeState("Action", newValue)
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            key={"3"}
                            id={"2MXs9"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            item={true}
                            lg={"5"}
                            md={"9"}
                            sm={"9"}
                            xl={"5"}
                            xs={"9"}
                          >
                            <Grid
                              key={"0"}
                              id={"d2nWC"}
                              container={""}
                              direction={"column"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"12"}
                              md={"12"}
                              sm={"12"}
                              xl={"12"}
                              xs={"12"}
                            >
                              <Typography
                                key={"0"}
                                id={"actionMessage_heading"}
                                align={"inherit"}
                                color={"initial"}
                                display={"initial"}
                                variant={"body1"}
                                children={"Action Message"}
                                className={classes.dispanse_duration}
                                aliasName={"actionMessage_typography"}
                                style={qdmstyles.sXqXt}
                              ></Typography>
                            </Grid>
                            <Grid
                              key={"1"}
                              id={"d2nWC"}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={"12"}
                              md={"12"}
                              sm={"12"}
                              xl={"12"}
                              xs={"12"}
                            >
                              <InputBase
                                key={"0"}
                                id={"actionmessage_input"}
                                label={""}
                                placeholder={"Type Here"}
                                type={"text"}
                                select={""}
                                size={"small"}
                                variant={"outlined"}
                                disabled={
                                  location?.state?.isView === true ||
                                  !shouldDisable
                                    ? true
                                    : false
                                }
                                fullWidth={true}
                                margin={"none"}
                                aliasName={"actionmessageTextfield"}
                                style={qdmstyles.dcmVQ}
                                inputProps={{
                                  maxLength: 100,
                                }}
                                value={state?.ActionMessage}
                                onChange={(event) =>
                                  changeState(
                                    "ActionMessage",
                                    event.target.value
                                  )
                                }
                              ></InputBase>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          key={"4"}
                          id={"iRF0G"}
                          container={true}
                          direction={"row"}
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                          item={true}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                          style={qdmstyles.vxru}
                        >
                          <Grid
                            key={"0"}
                            id={"Bf7YT"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                            lg={"4"}
                            spacing={""}
                            md={"4"}
                            sm={"4"}
                            xl={"4"}
                            xs={"4"}
                            item={true}
                          >
                            <Button
                              key={"0"}
                              disabled={
                                location?.state?.isView === true ||
                                !shouldDisable
                                  ? true
                                  : false
                              }
                              id={"save&AddAnother_Button"}
                              variant={"text"}
                              aliasName={"save&AddAnotherButton"}
                              style={qdmstyles.mbBtY}
                              onClick={() =>
                                handleSaveClick(state?.editIndex, true)
                              }
                              className={classes.update_save}
                            >
                              {state?.editIndex
                                ? "Update & Add another"
                                : "Save & Add another"}
                            </Button>
                            <Grid
                              key={"1"}
                              id={""}
                              container={""}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              style={qdmstyles.RkD}
                            >
                              <Button
                                key={"0"}
                                id={"addButtonofaddnewdispensedefault_Button"}
                                variant={"contained"}
                                disabled={
                                  location?.state?.isView === true ||
                                  !shouldDisable
                                    ? true
                                    : false
                                }
                                color={'primary'}
                                size={"small"}
                                aliasName={"addButtonofaddnewdispensedefault"}
                                style={qdmstyles.iUJe}
                                className={classes.up_add}
                                onClick={() =>
                                  handleSaveClick(state?.editIndex, false)
                                }
                              >
                                {state?.editIndex ? "Update" : "Add"}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </>
              )}
              {/* Add new dispense defaults Section Ends */}

              {statevalue?.MaxDispDurnRulebyEnctype?.length > 0 && (
                <Grid
                  key={"4"}
                  id={"ncT2W"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid
                    key={"0"}
                    id={"TZfNs"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    item={true}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                  >
                    <Paper
                      key={"0"}
                      id={"bx6t7"}
                      elevation={"{3}"}
                      style={{
                        ...qdmstyles.bxt,
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 10,
                      }}
                    >
                      <Grid
                        key={"0"}
                        id={"BTGwG"}
                        container={true}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        style={qdmstyles.BTGwG}
                      >
                        <Grid
                          key={"0"}
                          id={"mCpCY"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"4"}
                          item={true}
                        >
                          <Typography
                            key={"0"}
                            id={"1LrwM"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"dispense defaults"}
                            className={classes.dispanse}
                            style={{
                              ...qdmstyles.LrwM,
                              margin: "12px 0px 6px",
                            }}
                          ></Typography>
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"Xtuss"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"4"}
                          item={true}
                          style={qdmstyles.Xtuss}
                        >
                          <Button
                            key={"0"}
                            disabled={
                              location?.state?.isView === true || !shouldDisable
                                ? true
                                : false
                            }
                            id={"T8AoZ"}
                            variant={"text"}
                            color={"primary"}
                            children={"+ Add new"}
                            style={qdmstyles.TAoZ}
                            onClick={handleAddNewClick}
                          ></Button>
                        </Grid>
                      </Grid>
                      {/* Map Items Section */}
                      {statevalue?.MaxDispDurnRulebyEnctype?.map(
                        (item, index) => {
                          const rowState =
                            state?.MaxDispDurnRulebyEnctype[index];
                          return (
                            <Grid
                              key={"1"}
                              id={"uo50z"}
                              container={true}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <Grid
                                key={"0"}
                                id={"FP3C7"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"12"}
                              >
                                <Paper
                                  key={"0"}
                                  id={"bHiFf"}
                                  elevation={"{3}"}
                                  style={qdmstyles.uzpZ}
                                >
                                  <Grid
                                    key={"0"}
                                    id={"GYTKC"}
                                    container={true}
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    lg={"12"}
                                    item={true}
                                    md={"12"}
                                    sm={"12"}
                                    xl={"12"}
                                    xs={"12"}
                                    wrap={"wrap"}
                                  >
                                    <Grid
                                      key={"0"}
                                      id={"fQkr3"}
                                      container={true}
                                      direction={"row"}
                                      justifyContent={"flex-start"}
                                      alignItems={"center"}
                                      lg={"11"}
                                      md={"11"}
                                      sm={"11"}
                                      xl={"11"}
                                      xs={true}
                                      item={true}
                                      spacing={""}
                                      style={qdmstyles.cMI}
                                    >
                                      <Grid
                                        key={"0"}
                                        id={"Ut94g"}
                                        container={""}
                                        direction={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        item={true}
                                        lg={1}
                                        md={1}
                                        sm={1}
                                        xl={1}
                                        xs={1}
                                        wrap={"wrap"}
                                        style={qdmstyles.BHnWT}
                                      >
                                        <Grid
                                          key={"0"}
                                          id={"Po8nM"}
                                          container={""}
                                          direction={"row"}
                                          justifyContent={"center"}
                                          alignItems={"center"}
                                          lg={"12"}
                                          item={true}
                                          md={"12"}
                                          sm={"12"}
                                          xl={"12"}
                                          xs={"12"}
                                          wrap={"wrap"}
                                          style={qdmstyles.ReVMC}
                                        >
                                          <Typography
                                            key={"0"}
                                            id={"serialnoTypography"}
                                            align={"inherit"}
                                            color={"initial"}
                                            display={"initial"}
                                            variant={"body1"}
                                            children={index + 1}
                                            noWrap={""}
                                            aliasName={"serialnoTypography"}
                                            style={qdmstyles.CbFTC}
                                            className={classes.dispanse}
                                          ></Typography>
                                          <Paper
                                            key={"1"}
                                            id={"ejYaE"}
                                            elevation={"{3}"}
                                            style={qdmstyles.Zuh}
                                          >
                                            <Avatar
                                              key={"0"}
                                              id={"wg8M8"}
                                              alt={"Avatar"}
                                              variant={"rounded"}
                                              src={
                                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/325367543"
                                              }
                                              style={qdmstyles.KQg}
                                            ></Avatar>
                                          </Paper>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        key={"1"}
                                        id={"CIdwA"}
                                        container={""}
                                        direction={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        lg={"2"}
                                        md={"2"}
                                        sm={"2"}
                                        xl={"2"}
                                        spacing={""}
                                        xs={"2"}
                                        item={true}
                                      >
                                        <Typography
                                          key={"0"}
                                          id={"criteria_typography"}
                                          align={"inherit"}
                                          color={"initial"}
                                          display={"initial"}
                                          variant={"body1"}
                                          children={"Criteria"}
                                          className={classes.dispanse}
                                          aliasName={"criteriaheading"}
                                          style={qdmstyles.oXGn}
                                        ></Typography>
                                        <Typography
                                          key={"1"}
                                          id={"toDisplayTheValue_Typography"}
                                          align={"inherit"}
                                          color={"initial"}
                                          display={"initial"}
                                          variant={"body1"}
                                          className={classes.dispanse}
                                          children={`${rowState?.Criteria?.[0]?.ruleName ||
                                            rowState?.Criteria?.[0]?.group
                                              ?.label ||
                                            ""
                                            }-${rowState?.Criteria?.[0]?.priority ||
                                            rowState?.Criteria?.[0]?.rule
                                              ?.label ||
                                            ""
                                            }`}
                                          aliasName={
                                            "toDisplayTheValueTypography"
                                          }
                                          style={qdmstyles.dsNK}
                                        ></Typography>
                                      </Grid>
                                      <Grid
                                        key={"2"}
                                        id={"CIdwA"}
                                        container={""}
                                        direction={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        xl={3}
                                        xs={3}
                                      >
                                        <Typography
                                          key={"0"}
                                          id={
                                            "defaultDispenseDuration&Uom_Typography"
                                          }
                                          align={"inherit"}
                                          color={"initial"}
                                          display={"initial"}
                                          variant={"body1"}
                                          children={
                                            "Default Dispense Duration & Uom"
                                          }
                                          className={classes.dispanse}
                                          aliasName={
                                            "defaultDispenseDuration&Uomtypography"
                                          }
                                          style={qdmstyles.mGkLH}
                                        ></Typography>
                                        <Typography
                                          key={"1"}
                                          id={"TodisplayTheValue_Typography"}
                                          align={"inherit"}
                                          color={"initial"}
                                          display={"initial"}
                                          variant={"body1"}
                                          className={classes.dispanse}
                                          children={[
                                            rowState?.dfltDispDurationVal
                                              ?.toString()
                                              ?.padStart(2, "0"),
                                            rowState?.dfltDispDurationUOM
                                              ?.label ?? "",
                                          ].join(" ")}
                                          aliasName={
                                            "TodisplayTheValueTypography"
                                          }
                                          style={qdmstyles.ODUkA}
                                        ></Typography>
                                      </Grid>
                                      <Grid
                                        key={"3"}
                                        id={"CIdwA"}
                                        container={""}
                                        direction={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        lg={"2"}
                                        md={"2"}
                                        sm={"2"}
                                        xl={"2"}
                                        xs={"2"}
                                      >
                                        <Typography
                                          key={"0"}
                                          id={
                                            "maxDispDurationVal&Uom_Typography"
                                          }
                                          align={"inherit"}
                                          color={"initial"}
                                          display={"initial"}
                                          className={classes.dispanse}
                                          variant={"body1"}
                                          children={"maxDispDurationVal & Uom"}
                                          aliasName={
                                            "maxDispDurationVal&UomTypography"
                                          }
                                          style={qdmstyles.gtKUe}
                                        ></Typography>
                                        <Typography
                                          key={"1"}
                                          id={"toDisplayTheValue_Typography"}
                                          align={"inherit"}
                                          color={"initial"}
                                          display={"initial"}
                                          className={classes.dispanse}
                                          variant={"body1"}
                                          children={[
                                            rowState?.maxDispDurationVal
                                              ?.toString()
                                              ?.padStart(2, "0"),
                                            rowState?.maxDispDurationUOM?.label,
                                          ].join(" ")}
                                          aliasName={
                                            "toDisplayTheValueTypography"
                                          }
                                          style={qdmstyles.ENjS}
                                        ></Typography>
                                      </Grid>
                                      <Grid
                                        key={"4"}
                                        id={"CIdwA"}
                                        container={""}
                                        direction={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        lg={"1"}
                                        md={"1"}
                                        sm={"1"}
                                        xl={"1"}
                                        xs={"1"}
                                      >
                                        <Typography
                                          key={"0"}
                                          id={"action_Typography"}
                                          align={"inherit"}
                                          color={"initial"}
                                          display={"initial"}
                                          variant={"body1"}
                                          children={"Action"}
                                          className={classes.dispanse}
                                          aliasName={"actionTypography"}
                                          style={qdmstyles.uHMj}
                                        ></Typography>
                                        <Typography
                                          key={"1"}
                                          id={"gAydS"}
                                          align={"inherit"}
                                          color={"initial"}
                                          display={"initial"}
                                          variant={"body1"}
                                          children={rowState?.Action?.label}
                                          className={classes.dispanse}
                                          aliasName={"stop"}
                                          style={qdmstyles.SBw}
                                        ></Typography>
                                      </Grid>
                                      <Grid
                                        key={"5"}
                                        id={"CIdwA"}
                                        container={""}
                                        direction={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        lg={"3"}
                                        md={"3"}
                                        sm={"3"}
                                        xl={"3"}
                                        xs={"3"}
                                      >
                                        <Typography
                                          key={"0"}
                                          id={"actionMessage_Id"}
                                          align={"inherit"}
                                          color={"initial"}
                                          display={"initial"}
                                          variant={"body1"}
                                          children={"Action Message"}
                                          className={classes.dispanse}
                                          aliasName={"actionMessageId"}
                                          style={qdmstyles.iEX}
                                        ></Typography>
                                        <Typography
                                          key={"1"}
                                          id={"toDisplayTheValue_Typography"}
                                          align={"inherit"}
                                          color={"initial"}
                                          display={"initial"}
                                          className={classes.dispanse}
                                          variant={"body1"}
                                          children={rowState?.ActionMessage}
                                          aliasName={
                                            "toDisplayTheValueTypography"
                                          }
                                          style={qdmstyles.piHGG}
                                        ></Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      key={"1"}
                                      id={"vLpha"}
                                      container={true}
                                      direction={"row"}
                                      justifyContent={"center"}
                                      alignItems={"center"}
                                      item={true}
                                      lg={1}
                                      md={1}
                                      sm={1}
                                      xl={1}
                                      xs={1}
                                    >
                                      <Grid
                                        key={"0"}
                                        id={"drg92"}
                                        container={""}
                                        direction={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        item={true}
                                        lg={"6"}
                                        md={"6"}
                                        sm={"6"}
                                        xl={"6"}
                                        xs={"6"}
                                      >
                                        <ALLMCORE.IconButton
                                          onClick={() => handleEditClick(index)}
                                          disabled={
                                            location?.state?.isView === true ||
                                            !shouldDisable
                                              ? true
                                              : false
                                          }
                                        >
                                          <CreateIcon />
                                        </ALLMCORE.IconButton>
                                      </Grid>
                                      <Grid
                                        key={"1"}
                                        id={"w7zVn"}
                                        container={""}
                                        direction={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        item={true}
                                        lg={"6"}
                                        md={"6"}
                                        sm={"6"}
                                        xl={"6"}
                                        xs={"6"}
                                      >
                                        <ALLMCORE.IconButton
                                          onClick={() =>
                                            handleDeleteClick(index)
                                          }
                                          disabled={
                                            location?.state?.isView === true ||
                                            !shouldDisable
                                              ? true
                                              : false
                                          }
                                        >
                                          <TrashIcon />
                                        </ALLMCORE.IconButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(withAllContexts(Despensedefaultcomp)));
