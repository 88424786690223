import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";
import * as ALL from "../../components";
import { CreateIcon } from "../../assets";
import { ComponentRender } from "custom-material-ui-component";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  Avatar,
  Switch,
  Divider,
  Modal,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";

import qdmstyles from "./styles.json";
import "./index.css";
import Color from "../../themes/default.json";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
var aes256 = require("aes256");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
  },
  grid: {
    outline: 0,
  },
  gbfHy: {
    borderRadius: "0px !important",
    padding: "10px 30px 10px !important",
    backgroundColor: theme.palette.background.table
  },
  HKmhv: {
    margin: "0px !important",
  },
  childparent: {
    color: theme.palette.primary.light,
  },
  available: {
    color: theme.palette.text.primary
  },
  Strength: {
    color: theme.palette.primary.light

  }
}));
function Drugmastertablevalue(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { propsState, handleStatusChange = () => null } = props;

  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });
  const [showModal, setshowModal] = React.useState(false);
  const [strength, setStrength] = React.useState([]);
  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission
  const handleCloseModal = (val) => {
    setshowModal(false);
  };
  React.useEffect(() => { }, [state]);

  const { data, index, loading } = props;
  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <div>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ALLMCORE.CircularProgress />
            </div>
          ) : (
            <>
              {propsState?.length > 0 ? (
                <>
                  {propsState?.map((item, index) => {
                    const isNotLastElement = index !== propsState?.length - 1;
                    return (
                      <Grid
                        key={"0"}
                        id={"I06HM"}
                        container={true}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                        style={{
                          ...(isNotLastElement && {
                            borderBottom: `1px solid ${"#DEE5EC"}`,
                          }),
                        }}
                      >
                        <Grid
                          key={"0"}
                          id={"xHezf"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                          item={true}
                        >
                          <Paper
                            key={"0"}
                            id={"gbfHy"}
                            elevation={"{3}"}
                            style={qdmstyles.DBqDA}
                            className={classes.gbfHy}
                          >
                            <Grid
                              key={"0"}
                              id={"qAc9p"}
                              container={true}
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              lg={"12"}
                              md={"12"}
                              sm={"12"}
                              xl={"12"}
                              xs={"12"}
                            >
                              <Grid
                                key={"0"}
                                id={"HKmhv"}
                                container={true}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"10"}
                                md={"10"}
                                sm={"10"}
                                xl={"10"}
                                xs={"10"}
                                spacing={1}
                                style={qdmstyles.MzG}
                                className={classes.HKmhv}
                              >
                                <Grid
                                  key={"0"}
                                  id={"LHGC4"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  lg={"4"}
                                  item={true}
                                  md={"4"}
                                  sm={"4"}
                                  xl={"4"}
                                  xs={"4"}
                                  style={qdmstyles.VAOQU}
                                >
                                  <Typography
                                    key={"0"}
                                    id={"code_and_description_value"}
                                    align={"inherit"}
                                    color={"initial"}
                                    display={"initial"}
                                    variant={"body1"}
                                    children={`${item?.DrugCode} & ${item?.LongDesc}`}
                                    aliasName={"codeanddescriptionvalue"}
                                    className={classes.childparent}
                                    // noWrap={true}
                                    style={{
                                      ...qdmstyles.bzWxP,
                                    }}
                                  ></Typography>
                                </Grid>
                                <Grid
                                  key={"1"}
                                  id={"MA8np"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  lg={"2"}
                                  item={true}
                                  md={"2"}
                                  sm={"2"}
                                  xl={"2"}
                                  xs={"2"}
                                  wrap={"wrap"}
                                  style={qdmstyles.Wfj}
                                >
                                  <Typography
                                    key={"0"}
                                    id={"class_value"}
                                    align={"inherit"}
                                    color={"initial"}
                                    display={"initial"}
                                    variant={"body1"}
                                    children={item?.DrugClass?.longdesc}
                                    className={classes.childparent}
                                    aliasName={"classvalue"}
                                    noWrap={true}
                                    style={{
                                      ...qdmstyles.cHPhn,
                                    }}
                                  ></Typography>
                                </Grid>
                                <Grid
                                  key={"2"}
                                  id={"jhYjV"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  lg={"2"}
                                  item={true}
                                  md={"2"}
                                  sm={"2"}
                                  xl={"2"}
                                  xs={"2"}
                                >
                                  <Typography
                                    key={"0"}
                                    id={"category_value"}
                                    align={"inherit"}
                                    color={"initial"}
                                    display={"initial"}
                                    className={classes.childparent}
                                    variant={"body1"}
                                    children={item?.DrugCategory?.longdesc}
                                    aliasName={"categoryvalue"}
                                    noWrap={true}
                                    style={{
                                      ...qdmstyles.cMNw
                                    }}
                                  ></Typography>
                                </Grid>
                                <Grid
                                  key={"3"}
                                  id={"Tz6TR"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  lg={"2"}
                                  item={true}
                                  sm={"2"}
                                  xl={"2"}
                                  xs={"2"}
                                  md={"2"}
                                >
                                  <Typography
                                    key={"0"}
                                    id={"type_value"}
                                    align={"inherit"}
                                    color={"initial"}
                                    display={"initial"}
                                    variant={"body1"}
                                    children={item?.DrugType?.longdesc}
                                    className={classes.childparent}
                                    aliasName={"typevalue"}
                                    noWrap={true}
                                    style={{
                                      ...qdmstyles.GBTG
                                    }}
                                  ></Typography>
                                </Grid>
                                <Grid
                                  key={"4"}
                                  id={"fW36i"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  lg={1}
                                  item={true}
                                  md={1}
                                  sm={1}
                                  xl={1}
                                  xs={1}
                                >
                                  <Typography
                                    key={"0"}
                                    id={"form_value"}
                                    align={"inherit"}
                                    color={"initial"}
                                    display={"initial"}
                                    variant={"body1"}
                                    children={item?.FormCode?.longdesc}
                                    aliasName={"formvalue"}
                                    className={classes.childparent}
                                    noWrap={true}
                                    style={{
                                      ...qdmstyles.MSx
                                    }}
                                  ></Typography>
                                </Grid>
                                <Grid
                                  key={"5"}
                                  id={"ecYCH"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  lg={1}
                                  item={true}
                                  md={1}
                                  sm={1}
                                  xl={1}
                                  xs={1}
                                >
                                  {item?.RoutesAllowed?.map((val) => {
                                    return (
                                      <Typography
                                        key={"0"}
                                        id={"route_value"}
                                        align={"inherit"}
                                        color={"initial"}
                                        display={"initial"}
                                        variant={"body1"}
                                        children={val?.longdesc}
                                        aliasName={"routevalue"}
                                        className={classes.childparent}
                                        noWrap={true}
                                        style={{
                                          ...qdmstyles.rDsyW
                                        }}
                                      ></Typography>
                                    );
                                  })}
                                </Grid>
                                {/* <Grid
                                  key={"6"}
                                  id={"ev6i9"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  lg={1}
                                  item={true}
                                  md={1}
                                  sm={1}
                                  xl={1}
                                  xs={1}
                                  style={qdmstyles.HLu}
                                >
                                  <Avatar
                                    key={"0"}
                                    onClick={() => {
                                      setshowModal(true);
                                      setStrength(item?.StrengthValuesPossible);
                                    }}
                                    id={"strength_value_icon"}
                                    alt={"Avatar"}
                                    variant={"rounded"}
                                    aliasName={"strengthvalueicon"}
                                    src={
                                      "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/323303442"
                                    }
                                    style={qdmstyles.PnsL}
                                  ></Avatar>
                                </Grid> */}
                              </Grid>
                              <Grid
                                key={"1"}
                                id={"hUUJ7"}
                                container={true}
                                direction={"row"}
                                justifyContent={"flex-end"}
                                alignItems={"center"}
                                lg={"2"}
                                md={"2"}
                                sm={"2"}
                                xl={"2"}
                                xs={"2"}
                                spacing={1}
                                style={qdmstyles.pvGla}
                              >
                                <Grid
                                  key={"0"}
                                  id={"BYd0H"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  lg={"6"}
                                  item={true}
                                  md={"6"}
                                  sm={"6"}
                                  xl={"6"}
                                  xs={"6"}
                                  style={qdmstyles.fWwA}
                                >
                                  {item?.status ? (
                                    <div style={qdmstyles.active}>active</div>
                                  ) : (
                                    <div style={qdmstyles.inactive}>
                                      in active
                                    </div>
                                  )}
                                  {/* <Switch
                                    onChange={(e) =>
                                      handleStatusChange(
                                        e.target.checked,
                                        item?._key
                                      )
                                    }
                                    key={"0"}
                                    id={"status_value"}
                                    checked={item?.status}
                                    color={"primary"}
                                    name={"switch"}
                                    aliasName={"statusvalue"}
                                  ></Switch> */}
                                </Grid>
                                <Grid
                                  key={"1"}
                                  id={"YZbST"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  lg={"3"}
                                  item={true}
                                  xl={"3"}
                                  xs={"3"}
                                  sm={"3"}
                                  md={"3"}
                                  style={qdmstyles.qkKXh}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      history.push(Routes.drugintscreen, {
                                        docId: item?._id,
                                        docKey: item?._key,
                                      });
                                    }}
                                  >
                                    <CreateIcon fontSize="small" />
                                  </IconButton>
                                </Grid>

                                <Grid
                                  lg={"3"}
                                  item={true}
                                  xl={"3"}
                                  xs={"3"}
                                  sm={"3"}
                                  md={"3"}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  style={qdmstyles.qkKXh}
                                >
                                  <VisibilityOutlinedIcon
                                    style={{ fontSize: "19px" }}
                                    onClick={() => {
                                      history.push(Routes.drugintscreen, {
                                        docId: item?._id,
                                        docKey: item?._key,
                                        isView: true,
                                        isDisabled: false,
                                        isActive: item?.status,
                                      });
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* <Divider key={"1"} id={"jL9Jj"} variant={"fullWidth"}></Divider> */}
                          </Paper>
                        </Grid>
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <Typography
                  variant="body1"
                  style={{ textAlign: "center", margin: "10px 0px" }}
                >
                  No data found
                </Typography>
              )}
            </>
          )}

          {/* Modal CODE */}
          {showModal && (
            <Modal
              open={showModal}
              onClose={handleCloseModal}
              className={classes.modal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <Grid
                className={classes.grid}
                key={"0"}
                id={"Z6EBo"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                lg={"4"}
                md={"4"}
                sm={"4"}
                xl={"4"}
                xs={"4"}
              >
                <Grid
                  key={"0"}
                  id={"Z9Hs0"}
                  container={""}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item={true}
                  lg={"12"}
                  md={"12"}
                  sm={"12"}
                  xl={"12"}
                  xs={"12"}
                >
                  <Paper
                    key={"0"}
                    id={"pCtsN"}
                    elevation={"{3}"}
                    style={qdmstyles.pCtsN}
                  >
                    <Grid
                      key={"0"}
                      id={"p9FEL"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                    >
                      <Grid
                        key={"0"}
                        id={"Y6MRQ"}
                        container={true}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        lg={"12"}
                        item={true}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                        style={qdmstyles.YMRQ}
                      >
                        <Grid
                          key={"0"}
                          id={"Z8H2H"}
                          container={true}
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          lg={"7"}
                          md={"7"}
                          sm={"7"}
                          xl={"7"}
                          xs={"7"}
                        >
                          <Typography
                            key={"0"}
                            id={"6qn9l"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"AVAILABLE STRENGTH"}
                            className={classes.available}
                            style={{
                              ...qdmstyles.qnl
                            }}
                          ></Typography>
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"5WDf9"}
                          container={""}
                          direction={"row"}
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                          lg={"2"}
                          md={"2"}
                          sm={"2"}
                          xl={"2"}
                          xs={"2"}
                          style={qdmstyles.WDf}
                        >
                          <Paper
                            style={{
                              width: "26px",
                              height: "26px",
                              borderRadius: "50%",
                              backgroundColor: "#E5E8EE",
                              display: "grid",
                              cursor: "pointer",
                            }}
                            elevation={false}
                            onClick={handleCloseModal}
                          >
                            <CloseRoundedIcon
                              fontSize="small"
                              style={{
                                placeSelf: "center",
                                fill: Color.palette.primary.main,
                              }}
                            />
                          </Paper>
                        </Grid>
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"PLePk"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"12"}
                        item={true}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                        style={qdmstyles.PLePk}
                      >
                        <Divider
                          key={"0"}
                          id={"u6B8U"}
                          variant={"fullWidth"}
                          flexItem={""}
                        ></Divider>
                      </Grid>
                      <Grid
                        key={"2"}
                        id={"T7xTu"}
                        container={true}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        lg={"12"}
                        item={true}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                        style={qdmstyles.TxTu}
                      >
                        <Grid
                          key={"0"}
                          id={"oEUGh"}
                          container={true}
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          lg={"12"}
                          item={true}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                        >
                          <Grid
                            key={"0"}
                            id={"pYP7X"}
                            container={true}
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            lg={"12"}
                            item={true}
                            style={{ margin: "0 16px" }}
                          >
                            {strength?.length === 0 && (
                              <Typography
                                style={{
                                  margin: "8px 0px 18px",
                                  textAlign: "center",
                                  flex: "1",
                                }}
                              >
                                No strength available
                              </Typography>
                            )}
                            {strength?.length > 0 && (
                              <>
                                <Grid
                                  key={"0"}
                                  id={"S5mqN"}
                                  container={true}
                                  direction={"row"}
                                  justifyContent={"flex-start"}
                                  alignItems={"center"}
                                  lg={"5"}
                                  md={"5"}
                                  sm={"5"}
                                  xl={"5"}
                                  xs={"5"}
                                >
                                  <Typography
                                    key={"0"}
                                    id={"U5nAb"}
                                    align={"inherit"}
                                    color={"initial"}
                                    display={"initial"}
                                    className={classes.Strength}
                                    variant={"body1"}
                                    children={"Strength Value"}
                                    style={{
                                      ...qdmstyles.UnAb
                                    }}
                                  ></Typography>
                                </Grid>
                                <Grid
                                  key={"1"}
                                  id={"w8Av4"}
                                  container={true}
                                  direction={"row"}
                                  justifyContent={"flex-start"}
                                  alignItems={"center"}
                                  lg={"5"}
                                  xl={"5"}
                                  md={"5"}
                                  sm={"5"}
                                  xs={"5"}
                                >
                                  <Typography
                                    key={"0"}
                                    id={"GFYVy"}
                                    align={"inherit"}
                                    color={"initial"}
                                    display={"initial"}
                                    className={classes.Strength}
                                    variant={"body1"}
                                    children={"Strength Volume"}
                                    style={{
                                      ...qdmstyles.GFYVy
                                    }}
                                  ></Typography>
                                </Grid>
                              </>
                            )}
                          </Grid>
                          {strength?.length > 0 &&
                            strength?.map((item, index) => {
                              return (
                                <Grid
                                  key={"1"}
                                  id={"4eEz8"}
                                  container={true}
                                  direction={"row"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  lg={"12"}
                                  md={"12"}
                                  sm={"12"}
                                  xl={"12"}
                                  xs={"12"}
                                  item={true}
                                  style={qdmstyles.eEz}
                                >
                                  <Grid
                                    key={"0"}
                                    id={"0yFDe"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    item={true}
                                    lg={"5"}
                                    xl={"5"}
                                    md={"5"}
                                    sm={"5"}
                                    xs={"5"}
                                  >
                                    <ALL.Capsule
                                      value={{
                                        StrengthValue: item?.StrengthValue,
                                        StrengthVolumeUoM:
                                          item?.StrengthUOM?.shortdesc,
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    key={"1"}
                                    id={"XLXZi"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    lg={"2"}
                                    item={true}
                                    md={"2"}
                                    sm={"2"}
                                    xl={"2"}
                                    xs={"2"}
                                    style={qdmstyles.YKtx}
                                  >
                                    <Typography
                                      key={"0"}
                                      id={"JMvWO"}
                                      align={"inherit"}
                                      color={"initial"}
                                      display={"initial"}
                                      className={classes.Strength}
                                      variant={"body1"}
                                      children={" /"}
                                      style={{
                                        ...qdmstyles.YBla
                                      }}
                                    ></Typography>
                                  </Grid>
                                  <Grid
                                    key={"2"}
                                    id={"xTaHq"}
                                    container={""}
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    lg={"5"}
                                    item={true}
                                    md={"5"}
                                    sm={"5"}
                                    xl={"5"}
                                    xs={"5"}
                                  >
                                    <ALL.Capsule
                                      value={{
                                        StrengthValue: item?.StrengthVolume,
                                        StrengthVolumeUoM:
                                          item?.StrengthVolumeUoM?.shortdesc,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              );
                            })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Modal>
          )}
        </div>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Drugmastertablevalue));
