import {
  Typography,
  makeStyles,
  AppBar,
  Tab,
  Tabs,
  Box,
  Grid,
} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import AttributesComp from "./attributes";
import Characteristic from "./characteristic";
import CheckDuplicate from "./checkDuplicate";
import ConsentRequired from "./consentRequired";
import FacilityTab from "./facility";
import SpecimenDetails from "./specimenDetails";
import InstructionsTab from "./instructionsTab";
import FrequencyTab from "./frequencyTab";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
    background:theme.palette.background.table,
    borderRadius: "8px 8px 0px 0px",
    "& .App1-MuiTab-textColorPrimary": {
      "&.Mui-selected": {
        color: "#0071F2",
      },
    },
    "&.App1-MuiPaper-elevation4": {
      shadow: "none",
    },
    "& .App1-MuiTab-root": {
      [theme.breakpoints.up("xs")]: {
        minWidth: "inherit !important",
      },
    },
  },
  MuiTab: {
    root: {
      minWidth: 0,
      "@media (min-width: 0px)": {
        minWidth: 0,
      },
    },
  },
  box: {
    background:theme.palette.background.table,
    // backgroundColor: "#F6F6F6",
    height: "100%",
  },
  indcator: {
    display: "block !important",
  },
  flexContainer: {
    margin: "0px !important",
  },
  tabRoot: {
    fontSize: "14px",
    textTransform: "unset",
    fontFamily: "poppinsemibold",
    // paddingLeft: "0px !important",
    // paddingRight: "0px !important",
    marginLeft: "20px",
  },
  selected: {
    backgroundColor: "transparent !important",
  },
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, parent_id, index, ...other } = props;

  return (
    <div
      id={`${parent_id}-ConsentRequired-div`}
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          id={`${parent_id}-ConsentRequired-Box`}
          className={classes.box}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MultiTabForms(props) {
  const { handleFormState, handleCheckState, parent_id } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    // props?.orderCategory?.label?.toLocaleLowerCase() === "lab"
    setValue(newValue);
  };
  return (
    <Grid id={`${parent_id}_parent_container`} container spacing={0}>
      <Grid id={`${parent_id}_parent_grid`} item md={12}>
        <div id={`${parent_id}_parent_div`} className={classes.root}>
          <div
            id={`${parent_id}_parent_tab_div`}
            style={{ display: "flex", borderBottom: "2px solid #eee" }}
          >
            <AppBar
              id={`${parent_id}_parent_appbar`}
              position="static"
              color="default"
              style={{ zIndex: "auto", boxShadow: "none" }}
            >
              <Tabs
                id={`${parent_id}_parent_Tabs`}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria_label="scrollable auto tabs example"
                classes={{
                  root: classes.root,
                  indicator: classes.indcator,
                  flexContainer: classes.flexContainer,
                }}
              >
                <Tab
                  id={`${parent_id}_Characteristics_tab`}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.selected,
                  }}
                  label="Characteristics"
                  {...a11yProps(1)}
                />
                <Tab
                  id={`${parent_id}_Attributes_tab`}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.selected,
                  }}
                  label={<span>
                    Attributes
                    {props.AttributeList?.length > 0 && <span style={{color:"red"}}>*</span>}
                  </span>}
                  {...a11yProps(2)}
                />
                <Tab
                  id={`${parent_id}_Facility_tab`}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.selected,
                  }}
                  label="Facility"
                  {...a11yProps(3)}
                />

                {/* If order category is selected on lab, enable the specimen section */}
              {props?.orderCategory?.label?.toLocaleLowerCase() === "lab" &&
                <Tab
                  id={`${parent_id}_Specimen_tab`}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.selected,
                  }}
                  label={
                    <span>
                      Specimen Details
                      {/* <span style={{color:"red"}}>*</span> */}
                    </span>
                  }
                  {...a11yProps(4)}
                />
                }
                
                <Tab
                  id={`${parent_id}_Instructions_tab`}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.selected,
                  }}
                  label={<span>
                    Instructions
                    {/* <span style={{color:"red"}}>*</span> */}
                  </span>}
                  {...a11yProps(props?.orderCategory?.label?.toLocaleLowerCase() === "lab" ? 5 : 4)}
                />
                <Tab
                  id={`${parent_id}_Frequency_tab`}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.selected,
                  }}
                  label="Frequency"
                  {...a11yProps(props?.orderCategory?.label?.toLocaleLowerCase() === "lab" ? 6 : 5)}
                />
              </Tabs>
            </AppBar>
          </div>
          <TabPanel id={`${parent_id}_parent_tabPanel`} value={value} index={0}>
            <Characteristic
              parent_id={`${parent_id}_characteristics`}
              handleCheckState={handleCheckState}
              {...props}
            />
            <Grid id={`${parent_id}_sub_div`} container>
              <Grid id={`${parent_id}_CheckDuplicate_grid`} item xs={6}>
                <CheckDuplicate
                  parent_id={"CheckDuplicate"}
                  handleFormState={handleFormState}
                  {...props}
                />
              </Grid>
              <Grid id={`${parent_id}_ConsentRequired_grid`} item xs={6}>
                <ConsentRequired
                  parent_id={"ConsentRequired"}
                  handleFormState={handleFormState}
                  {...props}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel
            id={`${parent_id}_AttributesComp_tabPanel`}
            value={value}
            index={1}
          >
            <AttributesComp
              parent_id={"multitab_AttributesComp"}
              handleFormState={handleFormState}
              {...props}
            />
          </TabPanel>
          <TabPanel
            id={`${parent_id}_FacilityTab_tabPanel`}
            value={value}
            index={2}
          >
            <FacilityTab
              parent_id={"multitab_FacilityTab"}
              {...props}
              handleFormState={handleFormState}
            />
          </TabPanel>
          {props?.orderCategory?.label?.toLocaleLowerCase() === "lab" &&
              <TabPanel
                id={`${parent_id}_SpecimenDetails_tabPanel`}
                value={value}
                index={3}
              >
                <SpecimenDetails
                  parent_id={"multitab_SpecimenDetails"}
                  handleFormState={handleFormState}
                  {...props}
                />
              </TabPanel>
          }
          <TabPanel
            id={`${parent_id}_InstructionsTab_tabPanel`}
            value={value}
            index={props?.orderCategory?.label?.toLocaleLowerCase() === "lab" ? 4 : 3}
          >
            <InstructionsTab
              parent_id={"multitab_InstructionsTab"}
              handleFormState={handleFormState}
              {...props}
            />
          </TabPanel>
          <TabPanel
            id={`${parent_id}_FrequencyTab_tabPanel`}
            value={value}
            index={props?.orderCategory?.label?.toLocaleLowerCase() === "lab" ? 5 : 4}
          >
            <FrequencyTab
              parent_id={"multitab_FrequencyTab"}
              handleFormState={handleFormState}
              {...props}
            />
          </TabPanel>
        </div>
      </Grid>
    </Grid>
  );
}

export default MultiTabForms;
