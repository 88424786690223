import React from "react";
import { connect } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import { CommonTable, GenerateForm } from "../../../../../components";
import { CircularProgress } from "@material-ui/core";
import { checkError } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";

class OrderMode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditFrom: false,
      modes: [],
      edit_data: {
        code: "",
        shortdesc: "",
        longdesc: "",
        key: "",
      },
      // saveloading: false,
      // edit_data: null
    };
    // this.saveForm = this.saveForm.bind(this)
    // this.handleEdit = this.handleEdit.bind(this)
    // this.handleCheckBox = this.handleCheckBox.bind(this)
  }

  initFetch = async () => {
    await this.props.GET_ALL_ORDER_MODE();
    this.updateModes(this.props?.getAllOrderMode?.data || []);
  };

  updateModes = (cat, cb = () => {}) => {
    this.setState(
      {
        ...this.state,
        modes: cat,
      },
      () => {
        cb();
      }
    );
  };

  componentDidMount() {
    this.initFetch();
  }

  handleOpenForm = (val) => {
    const { changeState } = this.props;
    changeState("openIndex", 2);

    this.setState({
      openEditFrom: true,
      edit_data: { ...val },
    });
  };

  handleCloseForm = () => {
    const { changeState } = this.props;
    changeState("open", false);
    this.setState({
      openEditFrom: false,
      edit_data: {
        code: "",
        shortdesc: "",
        longdesc: "",
        key: "",
      },
      // edit_data: null
    });
  };

  saveFn = async (val) => {
    let { code, shortdesc, longdesc, status = true } = val;
    const input = {
      code,
      shortdesc,
      longdesc,
      status,
      id: 0,
      coding_id: 0,
      isUpdate: false,
      key: "",
    };
    if (this.state.edit_data.key) {
      input.id = this.state.edit_data.id;
      input.coding_id = this.state.edit_data.coding_id;
      input.status = this.state.edit_data.status;
      input.key = this.state.edit_data?.codeable?.coding?.[0]?._key;
      // input.key = this.state.edit_data.key;
      input.isUpdate = true;
    }
    const upsertStatus = await this.props.UPSERT_ORDER_MODE(input);
    // const { isError, errMsg } = checkError(upsertStatus.payload);
    if (upsertStatus?.payload?.data?.error) {
      this.props.alert.setSnack("unique");
    } else if (upsertStatus?.payload?.error) {
      this.props.alert.setSnack("error");
    } else {
      const key =
        upsertStatus?.payload?.data?.Result?.[0]?.properties?.doc?._key;
      const resp = await this.props.GET_SINGLE_ORDER_MODE({
        isSingle: true,
        key,
      });
      const modes = JSON.parse(JSON.stringify(this.state.modes));
      if (input.isUpdate) {
        let mod = modes.find((c) => c.key === input.key);
        if (mod) {
          const { code, shortdesc, longdesc, status } =
            resp?.payload?.data?.[0];
          mod.code = code;
          mod.shortdesc = shortdesc;
          mod.longdesc = longdesc;
          mod.status = status;
        }
      } else {
        modes.push(resp?.payload?.data?.[0]);
      }
      let type = this.state.edit_data.key ? "update" : "success";
      this.props.alert.setSnack(type);
      this.updateModes(modes, this.handleCloseForm);
    }
  };

  render() {
    const { open, openIndex, parent_id } = this.props;
    return (
      <React.Fragment>
        {this.props?.getAllOrderMode?.loading ? (
          <div
            id={`${parent_id}_mode_parent_div`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "66vh",
            }}
          >
            <CircularProgress id={`${parent_id}_mode_CircularProgress`} />
          </div>
        ) : (
          <>
            <CommonTable
              style={{ height: "calc(100vh - 245px)" }}
              parent_id={"order_mode"}
              // handleEdit={this.handleEdit}
              Header={[
                "S.No",
                "Order Mode Code",
                "Short Description",
                "Long Description",
                "Status",
                "Action",
              ]}
              dataList={this.state.modes}
              tableData={[
                { type: ["INCRIMENT"], name: "" },
                { type: ["TEXT"], name: "code" },
                { type: ["TEXT"], name: "shortdesc" },
                { type: ["TEXT"], name: "longdesc" },
                { type: ["CHECKBOX"], name: "status" },
                { type: ["EDIT"], name: "" },
              ]}
              handleCheckBox={async (e, val) => {
                const updatedStatus = await this.props.UPDATE_ORDER_MODE_STATUS(
                  {
                    key: val.key,
                    status: !val.status,
                  }
                );
                const { isError } = checkError(updatedStatus.payload);
                if (isError) {
                  this.props.alert.setSnack("error");
                } else {
                  this.props.alert.setSnack("update");
                  const modes = JSON.parse(JSON.stringify(this.state.modes));
                  const mod = modes.find((c) => c.key === val.key);
                  if (mod) {
                    mod.status = !val.status;
                  }
                  this.updateModes(modes);
                }
              }}
              handleEdit={(e, val) => {
                this.handleOpenForm(val);
              }}
            />
            <GenerateForm
              parent_id={"order_mode"}
              nocancel
              open={(open || this.state.openEditFrom) && openIndex === 2}
              handlecancel={() => this.handleCloseForm()}
              header={
                this.state?.edit_data?.id
                  ? "Edit Order Mode"
                  : "Add New Order Mode"
              }
              loading={
                this.props.upsertOrderMode?.loading ||
                this.props.getSingleOrderMode?.loading
              }
              json={[
                {
                  componet_type: "text",
                  label: "Order Mode Code",
                  state_name: "code",
                  value: this.state?.edit_data?.code ?? "",
                  required: true,
                  isUpperCase: true,
                  disabled: this.state?.edit_data?.code ? true : false,
                  textLength: 20,
                },
                {
                  componet_type: "text",
                  label: "Short Description",
                  state_name: "shortdesc",
                  value: this.state?.edit_data?.shortdesc ?? "",
                  required: true,
                },
                {
                  componet_type: "text",
                  label: "Long Description",
                  state_name: "longdesc",
                  value: this.state?.edit_data?.longdesc ?? "",
                  required: true,
                },
              ]}
              save={(val) => this.saveFn(val)}
            />
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  getAllOrderMode: state?.orderMasterSlice?.getAllOrderMode,
  getSingleOrderMode: state?.orderMasterSlice?.getSingleOrderMode,
  upsertOrderMode: state?.orderMasterSlice?.upsertOrderMode,
});

export default connect(mapStateToProps, actions)(withAllContexts(OrderMode));
