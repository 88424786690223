import React from "react";
import { Row, Col, TextInput } from "qdm-component-library";
import {
  FormLabel,
  makeStyles,
  Grid,
  Button,
  Paper,
  IconButton,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "frequencyscreen_v_dbinder";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from "@material-ui/icons/CreateOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { withAllContexts } from "../../../HOCs";
import { valitationFunc } from "../../../utils";
const useStyles = makeStyles((theme) => ({
  main: {
    // backgroundColor: "white",
		background: theme.palette.background.table,
    width: "100%",
    borderRadius: 8,
    padding: 14,
  },
  paddingLeft0: {
    paddingLeft: 0,
    marginBottom: "1%",
  },
  dot: {
    height: "7px",
    width: "7px",
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    marginTop: "5px",
    marginRight: "7px",
    marginLeft: "7px",
  },
  req_red: {
    color: "red",
  },
  paperColor: {
    background: theme.palette.background.common
  },
  containedButtonTheme: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      background: theme.palette.button.hover,
    }
  }
}));
const styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
  },
  errorBorderStyle: {
    borderRadius: 8,
    border: "1px solid red",
  },
};
const AddNewDetails = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const organizationSlice = useSelector((state) => state.organizationSlice);
  const { parent_id } = props;
  const [hide, setshide] = React.useState(false);
  const [state, setState] = React.useState({
    use: {},
    prefix: {},
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: {},
    designation: {},
    point: [
      {
        mode: {},
        code: {},
        number: "",
        use: {},
        priority: {},
      },
    ],
    error: {},
  });
  const [contactError, setContactErr] = React.useState([]);
  React.useLayoutEffect(() => {
    const getdata = async () => {
      await dispatch(actions.ORGANIZATION_GET_CONTACTDETAILUSE());
      await dispatch(actions.ORGANIZATION_GET_CONTACT_SUFFIX());
      await dispatch(actions.ORGANIZATION_GET_CONTACT_PREFIX());
      await dispatch(actions.ORGANIZATION_GET_CONTACT_DESI());
      await dispatch(actions.ORGANIZATION_GET_CONTACTDETAILPRIORITY());
      await dispatch(actions.ORGANIZATION_GET_CONNTACTDETAILCODE());
      await dispatch(actions.ORGANIZATION_GET_CONTACTDETAILUSE());
      await dispatch(actions.ORGANIZATION_GET_CONTACTDETAILMODE());
    };
    getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props?.editData?.length > 0) {
      setState({
        ...state,
        use: props?.editData[0]?.use,
        prefix: props?.editData[0]?.prefix,
        firstName: props?.editData[0]?.firstName,
        middleName: props?.editData[0]?.middleName,
        lastName: props?.editData[0]?.lastName,
        suffix: props?.editData[0]?.suffix,
        designation: props?.editData[0]?.designation,
        point: props?.editData[0]?.point,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.editData]);
  const { labelStyle } = styles;

  const addNew = () => {
    state &&
      state.point &&
      state.point.push({
        mode: {},
        code: {},
        number: "",
        use: {},
        priority: {},
      });
    setContactErr([...contactError]);
  };
  const handleClick = () => {
    setshide(true);
  };
  const handleClose = () => {
    setState({
      use: {},
      prefix: {},
      firstName: "",
      middleName: "",
      lastName: "",
      suffix: {},
      designation: {},
      point: [
        {
          mode: {},
          code: {},
          number: "",
          use: {},
          priority: {},
        },
      ],
    });
    setshide(false);
  };
  const Savefun = async (e) => {
    // let Ispoint = state.point
    // 	?.map(
    // 		(val) =>
    // 			val?.number !== "" &&
    // 			val?.mode?.value !== "" &&
    // 			val?.use?.value !== "" &&
    // 			val?.priority?.value !== ""
    // 	)
    // 	.filter((val) => val !== true);

    let getEmailsList = state?.point?.filter(
      (val) => val.mode?.title === "Email"
    );
    var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    let emailCheck = getEmailsList.filter(
      (val, i) => !val?.number.match(mailformat)
    );

    let errorList = [
      "use",
      "prefix",
      "firstName",
      "middleName",
      "lastName",
      "suffix",
      //   "designation", validation removed - PC-5643
    ];
    let { error, errorState } = await valitationFunc(state, errorList);
    // let contactErrlist = ["number","mode", "use", "priority"];

    let contactErr = false;

    // validation removed - PC-5643
    // if (!error) {
    //   let contact = [];
    //   state?.point.forEach((val) => {
    // 	  if (val?.mode?.title === "Phone") {
    //     contactErrlist = ["number", "code", "mode", "use", "priority"];
    //   }
    //     let contactErrState = {};
    //     contactErrlist.forEach((v) => {
    //       if (v === "number") {
    //         if (val[v] === "") {
    //           contactErrState[v] = true;
    //           contactErr = true;
    //         }
    //       } else {
    //         if (Object.keys(val[v])?.length === 0) {
    //           contactErrState[v] = true;
    //           contactErr = true;
    //         }
    //       }
    //     });
    //     contact.push(contactErrState);
    //   });
    //   setContactErr(contact);
    // }

    if (!error && !contactErr && emailCheck.length === 0) {
      if (props?.onChangeAll)
        props.onChangeAll(e, state, "ContactPersonDetails");
      handleClose();
    } else {
      let customMsg =
        emailCheck.length > 0
          ? "Please Check Email ID"
          : "Please fill all the mandatory fields";
      props.alert.setSnack("mandatory", customMsg);
      setState({
        ...state,
        error: errorState,
      });
    }
  };
  const generateFun = (e, val, types, index, Kvalue) => {
    if (types === "point") {
      if (Kvalue === "mode") {
        state[types][index]["number"] = "";
        state[types][index]["code"] = {};
      }
      state[types][index][Kvalue] = val;
      if (contactError[index]) {
        contactError[index][Kvalue] = false;
        setContactErr([...contactError]);
      }
      setState({ ...state });
    } else {
      if (state?.error?.[types]) {
        state.error[types] = false;
      }
      setState({ ...state, [types]: val });
    }
  };
  return (
    <Grid
      id={`${parent_id}-parent-grid`}
      className={classes.main}
      container
      direction="row"
    >
      <Grid id={`${parent_id}-parent-sub-grid`} container item xs={12}>
        <Grid id={`${parent_id}-parent-sublevel-grid`} item xs={12}>
          <Grid id={`${parent_id}-parent-container-grid`} container>
            <Grid
              id={`${parent_id}-CONTACT-PERSON-DETAILS-grid`}
              item
              xs={8}
              style={{ textAlign: "left" }}
            >
              <p
                id={`${parent_id}-CONTACT-PERSON-DETAILS-title-p`}
                style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}
              >
                CONTACT PERSON DETAILS{" "}
              </p>
            </Grid>
            <Grid id={`${parent_id}-button-grid`} item xs={4}>
              <Grid
                id={`${parent_id}-button-sub-grid`}
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Button
                  id={`${parent_id}-Add-New-Address-button`}
                  className={classes.newRes}
                  color="primary"
                  onClick={handleClick}
                >
                  + Add New Details
                </Button>
              </Grid>
            </Grid>
            {props?.ContactPersonDetailsData?.map((val, i) => {
              return (
                <Grid
                  id={`${parent_id}-details-parent-grid` + i}
                  container
                  style={{
                    border: "1px solid #E0E0E0",
                    padding: 12,
                    marginBottom: 8,
                    // backgroundColor: "#F9F9F9",
                    background: theme.palette.background.table,
                    position: "relative",
                  }}
                  direction="row"
                  alignItems="left"
                >
                  <Grid
                    id={`${parent_id}-details-parent-sub-grid` + i}
                    item
                    xs={6}
                  >
                    <Grid
                      id={`${parent_id}-details-parent-grid`}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="left"
                    >
                      <Grid
                        id={`${parent_id}-details-styled-grid` + i}
                        item
                        xs={1}
                        style={{
                          backgroundColor: "#E0E0E0",
                          position: "absolute",
                          left: 0,
                          padding: "6px 12px",
                          borderTopRightRadius: 9,
                          borderBottomRightRadius: 9,
                        }}
                      >
                        {i + 1}
                      </Grid>
                      {val?.point.map((val, i) => {
                        return (
                          <Grid
                            id={`${parent_id}-details-grid` + i}
                            item
                            xs={12}
                            style={{ marginLeft: "6%", textAlign: "left" }}
                          >
                            <div id={`${parent_id}-details-mode-div` + i}>
                              Details
                            </div>
                            {val?.mode?.title && <>{val?.mode?.title}</>}
                            {val?.number && (
                              <>
                                <div
                                  id={`${parent_id}-details-number-div` + i}
                                  className={classes.dot}
                                />
                                <span title={val?.number}>
                                  {`${val?.number}`?.length > 20
                                    ? `${val?.number}`.slice(0, 20) + "..."
                                    : val?.number}
                                </span>
                              </>
                            )}

                            {val?.use?.title && (
                              <>
                                <div
                                  id={`${parent_id}-details-use-div` + i}
                                  className={classes.dot}
                                />
                                {val?.use?.title}
                              </>
                            )}

                            {val?.priority?.title && (
                              <>
                                <div
                                  id={`${parent_id}-details-priority-div` + i}
                                  className={classes.dot}
                                />
                                {val?.priority?.title}
                              </>
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid id={`${parent_id}-IconButton-grid`} item xs={6}>
                    <Grid
                      id={`${parent_id}-EditIcon-grid`}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <IconButton
                        id={`${parent_id}-EditIcon-IconButton`}
                        aria-label="EditIcon"
                        className={classes.margin}
                        size="small"
                        style={{ marginTop: "1%" }}
                      >
                        <EditIcon
                          id={`${parent_id}-EditIcon`}
                          onClick={(e) => {
                            handleClick();
                            props.editFun("ContactPersonDetails", i);
                          }}
                          style={{
                            color: "#0071F2",
                            fontSize: 18,
                          }}
                        />
                      </IconButton>
                      <IconButton
                        id={`${parent_id}-IconButton`}
                        aria-label="DeleteIcon"
                        className={classes.margin}
                        size="small"
                        style={{ marginTop: "1%" }}
                        onClick={() =>
                          props.deleteFun("ContactPersonDetails", i)
                        }
                      >
                        <DeleteIcon
                          id={`${parent_id}-DeleteIcon`}
                          style={{ color: "#FF4D4A", fontSize: 18 }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {hide ? (
          <Grid
            id={`${parent_id}-parent-container`}
            container
            direction="column"
          >
            <Grid id={`${parent_id}-parent-grid`} container direction="column">
              <Row
                id={`${parent_id}-title-row`}
                inLineStyles={{ paddingBottom: "2%", paddingTop: "2%" }}
              >
                ADD NEW DETAILS
              </Row>
              <Paper
                id={`${parent_id}-CONTACT-PERSON-paper`}
                style={{ padding: 20 }}
                className={classes.paperColor}
              >
                <Row
                  id={`${parent_id}-CONTACT-PERSON-title-row`}
                  inLineStyles={{ paddingBottom: "2%" }}
                >
                  CONTACT PERSON
                </Row>
                <Row id={`${parent_id}-CONTACT-PERSON-row`}>
                  <Col
                    id={`${parent_id}-CONTACT-PERSON-Use-col`}
                    md={2}
                    lg={2}
                    sm={2}
                    xs={12}
                    className={classes.paddingLeft0}
                  >
                    <FormLabel
                      id={`${parent_id}-CONTACT-PERSON-Use-FormLabel`}
                      component="legend"
                      style={{ ...labelStyle, marginBottom: 10 }}
                    >
                      Use{" "}
                      <span
                        id={`${parent_id}-CONTACT-PERSON-Use-star-span`}
                        className={classes.req_red}
                      >
                        *
                      </span>
                    </FormLabel>

                    <Autocomplete
                      id={`${parent_id}-CONTACT-PERSON-Use-autocomplete`}
                      getOptionLabel={(option) => option.title}
                      style={{ width: "auto", marginTop: "1%" }}
                      size={"small"}
                      renderInput={(params) => (
                        <TextField
                          id={`${parent_id}-CONTACT-PERSON-Use-textField`}
                          {...params}
                          variant="outlined"
                          error={state?.error?.use}
                        />
                      )}
                      options={
                        organizationSlice?.orgContactDetailUse?.data
                          ? organizationSlice?.orgContactDetailUse?.data
                          : []
                      }
                      onChange={(e, val) => generateFun(e, val, "use")}
                      value={state?.use}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}-CONTACT-PERSON-Prefix-col`}
                    md={2}
                    lg={2}
                    sm={2}
                    xs={12}
                    className={classes.paddingLeft0}
                  >
                    <FormLabel
                      id={`${parent_id}-CONTACT-PERSON-Prefix-FormLabel`}
                      component="legend"
                      style={{ ...labelStyle, marginBottom: 10 }}
                    >
                      Prefix{" "}
                      <span
                        id={`${parent_id}-CONTACT-PERSON-Prefix-star-span`}
                        className={classes.req_red}
                      >
                        *
                      </span>
                    </FormLabel>
                    <Autocomplete
                      id={`${parent_id}-CONTACT-PERSON-Prefix-autocomplete`}
                      getOptionLabel={(option) => option.title}
                      style={{ width: "auto", marginTop: "1%" }}
                      size={"small"}
                      renderInput={(params) => (
                        <TextField
                          id={`${parent_id}-CONTACT-PERSON-Prefix-textField`}
                          {...params}
                          variant="outlined"
                          error={state?.error?.prefix}
                        />
                      )}
                      options={
                        organizationSlice?.orgNamePrefix?.data
                          ? organizationSlice?.orgNamePrefix?.data
                          : []
                      }
                      onChange={(e, val) => generateFun(e, val, "prefix")}
                      value={state?.prefix}
                    />
                  </Col>

                  <Col
                    id={`${parent_id}-CONTACT-PERSON-firstName-col`}
                    //className="no-padding-left"
                    md={2}
                    lg={2}
                    sm={2}
                    xs={12}
                    className={classes.paddingLeft0}
                  >
                    <TextInput
                      id={`${parent_id}-CONTACT-PERSON-firstName-textInput`}
                      label="First Name"
                      placeholder="First Name"
                      labelStyle={labelStyle}
                      hoverColor="#0071F2"
                      onChange={(e, val) =>
                        generateFun(e, e.target.value, "firstName")
                      }
                      value={state?.firstName}
                      error={state?.error?.firstName}
                      isRequired={true}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}-CONTACT-PERSON-middleName-col`}
                    //className="no-padding-left"
                    md={2}
                    lg={2}
                    sm={2}
                    xs={12}
                    className={classes.paddingLeft0}
                  >
                    <TextInput
                      id={`${parent_id}-CONTACT-PERSON-middleName-textInput`}
                      label="Middle Name"
                      placeholder="Middle Name"
                      labelStyle={labelStyle}
                      hoverColor="#0071F2"
                      onChange={(e, val) =>
                        generateFun(e, e.target.value, "middleName")
                      }
                      value={state?.middleName}
                      error={state?.error?.middleName}
                      isRequired={true}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}-CONTACT-PERSON-lastName-col`}
                    //className="no-padding-left"
                    md={2}
                    lg={2}
                    sm={2}
                    xs={12}
                    className={classes.paddingLeft0}
                  >
                    <TextInput
                      id={`${parent_id}-CONTACT-PERSON-lastName-textInput`}
                      label="Last Name"
                      placeholder="Last Name"
                      labelStyle={labelStyle}
                      hoverColor="#0071F2"
                      onChange={(e, val) =>
                        generateFun(e, e.target.value, "lastName")
                      }
                      value={state?.lastName}
                      isRequired={true}
                      error={state?.error?.lastName}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}-CONTACT-PERSON-Suffix-col`}
                    md={1}
                    lg={1}
                    sm={1}
                    xs={12}
                    className={classes.paddingLeft0}
                  >
                    <FormLabel
                      id={`${parent_id}-CONTACT-PERSON-Suffix-FormLabel`}
                      component="legend"
                      style={{ ...labelStyle, marginBottom: 10 }}
                    >
                      Suffix{" "}
                      <span
                        id={`${parent_id}-CONTACT-PERSON-Suffix-star-span`}
                        className={classes.req_red}
                      >
                        *
                      </span>
                    </FormLabel>
                    <Autocomplete
                      id={`${parent_id}-CONTACT-PERSON-Suffix-autocomplete`}
                      getOptionLabel={(option) => option.title}
                      style={{ width: "auto", marginTop: "1%" }}
                      size={"small"}
                      renderInput={(params) => (
                        <TextField
                          id={`${parent_id}-CONTACT-PERSON-Suffix-textField`}
                          {...params}
                          variant="outlined"
                          error={state?.error?.suffix}
                        />
                      )}
                      options={
                        organizationSlice?.orgNameSuffix?.data
                          ? organizationSlice?.orgNameSuffix?.data
                          : []
                      }
                      onChange={(e, val) => generateFun(e, val, "suffix")}
                      value={state?.suffix}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}-CONTACT-PERSON-designation-col`}
                    md={3}
                    lg={3}
                    sm={3}
                    xs={12}
                    className={classes.paddingLeft0}
                  >
                    <FormLabel
                      id={`${parent_id}-CONTACT-PERSON-designation-FormLabel`}
                      component="legend"
                      style={{ ...labelStyle, marginBottom: 10 }}
                    >
                      Designation
                      {/* <span id={`${parent_id}-CONTACT-PERSON-designation-star-span`} className={classes.req_red}>*</span> */}
                    </FormLabel>
                    <Autocomplete
                      id={`${parent_id}-CONTACT-PERSON-designation-autocomplete`}
                      getOptionLabel={(option) => option.title}
                      style={{ width: "auto", marginTop: "1%" }}
                      size={"small"}
                      renderInput={(params) => (
                        <TextField
                          id={`${parent_id}-CONTACT-PERSON-designation-textField`}
                          {...params}
                          variant="outlined"
                          error={state?.error?.designation}
                        />
                      )}
                      options={
                        organizationSlice?.positionDesi?.data
                          ? organizationSlice?.positionDesi?.data
                          : []
                      }
                      onChange={(e, val) => generateFun(e, val, "designation")}
                      value={state?.designation}
                    />
                  </Col>
                </Row>
              </Paper>
              <Paper
                id={`${parent_id}-CONTACT-PERSON-DETAILS-Paper`}
                style={{ marginTop: "2%", padding: 20, marginBottom: "2%" }}
                className={classes.paperColor}
              >
                <Grid
                  id={`${parent_id}-CONTACT-PERSON-DETAILS-grid`}
                  item
                  xs={12}
                >
                  <Grid id={`${parent_id}-CONTACT-PERSON-grid`} container>
                    <Grid
                      id={`${parent_id}-CONTACT-PERSON-sub-grid`}
                      item
                      xs={8}
                      style={{ textAlign: "left" }}
                    >
                      <p
                        id={`${parent_id}-CONTACT-PERSON-DETAILS-title-p`}
                        style={{
                          fontFamily: "poppinsemibold",
                          marginTop: "0px",
                        }}
                      >
                        CONTACT PERSON DETAILS{" "}
                      </p>
                    </Grid>
                    <Grid
                      id={`${parent_id}-CONTACT-PERSON-Add-New-Address-grid`}
                      item
                      xs={4}
                    >
                      <Grid
                        id={`${parent_id}-CONTACT-PERSON-Add-New-Address-sub-grid`}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                      >
                        <Button
                          id={`${parent_id}-CONTACT-PERSON-Add-New-Address-button`}
                          className={classes.newRes}
                          color="primary"
                          onClick={addNew}
                          //id={"general_appointment_card_Id"}
                        >
                          + Add New Contact
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {state.point.map((val, i) => {
                  return (
                    <Row
                      id={`${parent_id}-CONTACT-PERSON-row`}
                      inLineStyles={{ paddingBottom: "2%" }}
                    >
                      <Col
                        id={`${parent_id}-CONTACT-PERSON-Contact-Mode-col`}
                        md={2}
                        lg={2}
                        sm={2}
                        xs={12}
                        className={classes.paddingLeft0}
                      >
                        <FormLabel
                          id={`${parent_id}-Contact-Mode-title-FormLabel`}
                          component="legend"
                          style={{ ...labelStyle, marginBottom: 10 }}
                        >
                          Contact Mode{" "}
                          {/* <span id={`${parent_id}-Contact-Mode-star-span`} className={classes.req_red}>*</span> */}
                        </FormLabel>
                        <Autocomplete
                          id={`${parent_id}-Contact-Mode-autocomplete`}
                          getOptionLabel={(option) => option.title}
                          style={{ width: "auto", marginTop: "1%" }}
                          size={"small"}
                          renderInput={(params) => (
                            <TextField
                              id={`${parent_id}-Contact-Mode-textField`}
                              {...params}
                              variant="outlined"
                              error={contactError[i]?.mode}
                            />
                          )}
                          options={
                            organizationSlice?.orgContactDetailMode?.data
                              ? organizationSlice?.orgContactDetailMode?.data
                              : []
                          }
                          onChange={(e, val) =>
                            generateFun(e, val, "point", i, "mode")
                          }
                          value={val?.mode}
                        />
                      </Col>

                      {val?.mode?.title === "Phone" ? (
                        <Col
                          id={`${parent_id}-Country-Code-col`}
                          md={1}
                          lg={1}
                          sm={1}
                          xs={12}
                          className={classes.paddingLeft0}
                        >
                          <FormLabel
                            id={`${parent_id}-Country-Code-title-FormLabel`}
                            component="legend"
                            style={{ ...labelStyle, marginBottom: 10 }}
                          >
                            Country code{" "}
                          </FormLabel>
                          <Autocomplete
                            id={`${parent_id}-Country-Code-autocomplete`}
                            getOptionLabel={(option) => option.title}
                            style={{ width: "auto", marginTop: "1%" }}
                            size={"small"}
                            renderInput={(params) => (
                              <TextField
                                id={`${parent_id}-Country-Code-textField`}
                                {...params}
                                variant="outlined"
                                placeholder={"code"}
                                error={contactError[i]?.code}
                              />
                            )}
                            options={
                              organizationSlice?.orgContactDetailCode?.data
                                ? organizationSlice?.orgContactDetailCode?.data
                                : []
                            }
                            onChange={(e, val) =>
                              generateFun(e, val, "point", i, "code")
                            }
                            value={val?.code}
                          />
                        </Col>
                      ) : null}
                      <Col
                        id={`${parent_id}-mode-col`}
                        md={2}
                        lg={2}
                        sm={2}
                        xs={12}
                        className={classes.paddingLeft0}
                      >
                        <FormLabel
                          id={`${parent_id}-mode-FormLabel`}
                          component="legend"
                          style={{ ...labelStyle, marginBottom: 10 }}
                        >
                          {val?.mode?.title ? val?.mode?.title : "Given"}{" "}
                          {/* <span id={`${parent_id}-mode-span`} className={classes.req_red}>*</span> */}
                        </FormLabel>
                        <TextField
                          id={`${parent_id}-mode-textField`}
                          onChange={(e, val) =>
                            generateFun(e, e.target.value, "point", i, "number")
                          }
                          size={"small"}
                          type={
                            val?.mode?.title === "Phone"
                              ? "number"
                              : val?.mode?.title === "Email"
                              ? "email"
                              : "text"
                          }
                          variant="outlined"
                          placeholder={"Given"}
                          onKeyDown={(evt) =>
                            val?.mode?.title === "Phone"
                              ? evt.key === "e" && evt.preventDefault()
                              : ""
                          }
                          onWheel={false}
                          value={val?.number}
                          error={contactError[i]?.number}
                          onInput={(e) =>
                            val?.mode?.title === "Phone"
                              ? (e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 10))
                              : null
                          }
                        />
                      </Col>
                      <Col
                        id={`${parent_id}-Use-col`}
                        md={2}
                        lg={2}
                        sm={2}
                        xs={12}
                        className={classes.paddingLeft0}
                      >
                        <FormLabel
                          id={`${parent_id}-Use-title-FormLabel`}
                          component="legend"
                          style={{ ...labelStyle, marginBottom: 10 }}
                        >
                          Use
                          {/* <span id={`${parent_id}-Use-star-span`} className={classes.req_red}>*</span> */}
                        </FormLabel>
                        <Autocomplete
                          id={`${parent_id}-Use-autocomplete`}
                          getOptionLabel={(option) => option.title}
                          style={{ width: "auto", marginTop: "1%" }}
                          size={"small"}
                          renderInput={(params) => (
                            <TextField
                              id={`${parent_id}-Use-textField`}
                              {...params}
                              variant="outlined"
                              error={contactError[i]?.use}
                            />
                          )}
                          options={
                            organizationSlice?.orgContactDetailUse?.data
                              ? organizationSlice?.orgContactDetailUse?.data
                              : []
                          }
                          onChange={(e, val) =>
                            generateFun(e, val, "point", i, "use")
                          }
                          value={val?.use}
                        />
                      </Col>
                      <Col
                        id={`${parent_id}-Priority-col`}
                        md={2}
                        lg={2}
                        sm={2}
                        xs={12}
                        className={classes.paddingLeft0}
                      >
                        <FormLabel
                          id={`${parent_id}-Priority-title-FormLabel`}
                          component="legend"
                          style={{ ...labelStyle, marginBottom: 10 }}
                        >
                          Priority
                          {/* <span id={`${parent_id}-Priority-title-star-span`} className={classes.req_red}>*</span> */}
                        </FormLabel>
                        <Autocomplete
                          id={`${parent_id}-Priority-autocomplete`}
                          getOptionLabel={(option) => option.title}
                          style={{ width: "auto", marginTop: "1%" }}
                          size={"small"}
                          renderInput={(params) => (
                            <TextField
                              id={`${parent_id}-Priority-textField`}
                              {...params}
                              variant="outlined"
                              error={contactError[i]?.priority}
                            />
                          )}
                          options={
                            organizationSlice?.orgContactDetailPriority?.data
                              ? organizationSlice?.orgContactDetailPriority
                                  ?.data
                              : []
                          }
                          onChange={(e, val) =>
                            generateFun(e, val, "point", i, "priority")
                          }
                          value={val?.priority}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </Paper>
            </Grid>
            <Row
              id={`${parent_id}-buttons-row`}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                id={`${parent_id}-Cancel-button`}
                variant={"outlined"}
                onClick={handleClose}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                id={`${parent_id}-Add-button`}
                variant={"contained"}
                color="primary"
                onClick={Savefun}
                className={classes.containedButtonTheme}
              >
                Add
              </Button>
            </Row>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};
export default withAllContexts(AddNewDetails);
