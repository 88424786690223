/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Collection of all the message used in the application.
 */

//Normal messagess
export let Message = {
  requiredMessage: "This field is required",
};

export const ERROR_MESSAGE = {
  cannotReturnMedication: (medication) => `${medication} cannot be returned`,
  notIssuedMedication: (medication) => `${medication} is not issued`,
  notReturnableMedication: (medication) => `${medication} is not returnable`,
  returnDateExceeded: (medication) => `${medication} return date exceeded`,
};
