import React from "react";
import {
  Div,
  Paper,
  //Select,
  TextInput,
  Upload,
  H6,
  Col,
  Row,
  Text,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
import { uploadImage, checkMaxDateValidation, getFormatForDateTimePicker } from "../../../utils";
import Delete from "../../../assets/icons8-trash.svg";
import { Filenameui } from "./commonUI";
import moment from "moment";
import CustomInputDatePicker from "../../common/datepicker/inputDatePicker";
import { SelectWithLabel } from "../../../components";
import { Typography, withStyles } from "@material-ui/core";
import format from "date-fns/format"
import { DateTimePicker } from "../../common/smartForm/component";
//const optiondata = [{ value: "data" }];

const style = (theme) => ({
  piPaperBackground: {
    background: theme.palette.background.table,
  },
});

class PatientIdentification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Showfilter: this.props?.[this.props.saveto]?.names,
      limited: false,
      showdoc: true,
    };
  }
  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorBorderStyle: {
      borderRadius: 8,
      border: "1px solid red",
    },
  };

  setData = (name, value, index, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let patientData = this.props[this.props.saveto];
    patientData.identification[index][name] = value;
    if (patientData.identification[index].error) {
      patientData.identification[index].error[name] = false;
    }
    patientData[this.props.setTo]({
      ...patientData,
    });
  };
  setDataUpload = async (name, value, next, limit) => {
    var UploadedFiles = [];
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let patientData = this.props[this.props.saveto];
    UploadedFiles = await uploadImage([value]);
    patientData.identification[next][name] = UploadedFiles;
    if (patientData.identification?.error) {
      patientData.identification.error[name] = false;
    }
    patientData[this.props.setTo]({
      ...patientData,
    });
  };
  newRowAdd = () => {
    let patientData = this.props[this.props.saveto];
    patientData.identification.push({});
    patientData[this.props.setTo]({
      ...patientData,
    });
  };

  getSize = (size) => {
    let fileSize = size / 1024 / 1024;
    if (fileSize < 1) {
      return `${parseInt(size / 1024) + 1} KB`;
    } else {
      return `${parseInt(fileSize)} MB`;
    }
  };
  handleDelete = (i) => {
    let patientData = this.props[this.props.saveto];
    patientData.identification.splice(i, 1);
    patientData[this.props.setTo]({
      ...patientData,
    });
  };
  FlterOptions = (type) => {
    const { allMasters } = this.props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      var datatemp = {
        value: data[index]?.coding?.display,
      };
      lista.push(datatemp);
    }
    return lista;
  };
  handleLimit = () => {
    this.setState({
      limited: !this.state.limited,
    });
  };
  removedoc = () => {
    this.setState({
      showdoc: !this.state.showdoc,
    });
  };
  render() {
    const { classes } = this.props
    const { identification } = this.props[this.props.saveto];
    const { labelStyle, borderStyle, errorBorderStyle } = this.styles;
    // const { showdoc } = this.state;
    const mapped = identification;
    const { parent_id } = this.props;

    // const mapped = identification?.map((v, i) => {
    //
    //   if (i === 0) {
    //     v?.["doc"] = {
    //       fileName: "Employee Id.pdf 12MB",
    //     };
    //   }
    //   return v;
    // });
    return (
      <Div id={`${parent_id}-parent-div`} className="pi-root">
        <Paper
          id={`${parent_id}-parent-paper`}
          className={`pi-paper ${classes?.piPaperBackground}`}
        >
          {/* {JSON.stringify(identification)} */}
          <Div id={`${parent_id}-parent-sub-div`}>
            <H6 id={`${parent_id}-title-h6`} className="pi-title">
              {this.props.saveto === "nextOfKin"
                ? "NEXT OF KIN IDENTIFICATION"
                : "PRACTITIONER IDENTIFICATION"}
            </H6>
            <Text
              id={`${parent_id}-Add-New-text`}
              onClick={() => this.newRowAdd()}
              className="pi-addbtn"
            >
              + Add New
            </Text>
          </Div>
          {mapped
            ?.slice(0, this.state.limited ? mapped.length : 1)
            ?.map((val, i) => (
              <Row id={`${parent_id}-${i}-Add-New-row`}>
                <Col
                  id={`${parent_id}-${i}-Add-New-col`}
                  md={2}
                  lg={2}
                  sm={6}
                  xs={12}
                  className="no-padding-left"
                  inLineStyles={{ padding: 10, color: "#101010" }}
                >
                  {/* <Select
                    value={val?.id_type?.label ?? null}
                    onChangeValue={(e) => this.setData("id_type", e, i)}
                    label="ID Type"
                    placeholder="select..."
                    showArrow
                    labelStyle={labelStyle}
                    inLineStyles={
                      val?.error?.id_type ? errorBorderStyle : borderStyle
                    }
                    hoverColor="#0071F2"
                    getOptionLabel="label"
                    options={this?.props?.allMasters?.["id_type"]}
                    isRequired={true}
                  /> */}
                  {/* <Typography
                    style={{
                      fontSize: "12px",

                      Fontfamily: "pc_regular",
                      color: "#6F6F6F",
                    }}
                    variant="body1"
                  >
                    ID Type<span style={{ color: "red" }}>*</span>
                  </Typography> */}
                  {/* <SelectBox
                    list={this?.props?.allMasters?.["id_type"] ?? []}
                    placeholder={"select..."}
                    disableClearable
                    value={val?.id_type}
                    onchange={(e, value) => this.setData("id_type", value, i)}
                  /> */}
                  {/* {JSON.stringify(val?.id_type)} */}

                  <SelectWithLabel
                    id={`${parent_id}-${i}-id-type-selectwithlabel`}
                    options={this?.props?.allMasters?.["id_type"] ?? []}
                    label={"ID Type"}
                    required={true}
                    disableClearable
                    value={val?.id_type}
                    onChange={(value) => this.setData("id_type", value, i)}
                    placeholder={"Select"}
                    // onChange={(data)=>props?.onChangeState('class',data)}
                    error={val?.error?.id_type ?? false}
                  />
                </Col>
                <Col
                  id={`${parent_id}-${i}-id-number-col`}
                  md={2}
                  lg={2}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <TextInput
                    id={`${parent_id}-${i}-id-number-textinput`}
                    // helperText={
                    //   val?.error && val?.error["id_number"] ? "Is required" : ""
                    // }
                    error={val?.error && val?.error["id_number"] ? true : false}
                    value={val.id_number ? val.id_number : ""}
                    onChange={(e) =>
                      this.setData("id_number", e.target.value, i, 20)
                    }
                    label="ID Number"
                    isRequired={true}
                    // isRequired={val?.id_type ? true : false}
                    placeholder="Id Number"
                    labelStyle={labelStyle}
                    // style={borderStyle}
                    style={
                      val?.error?.id_number ? errorBorderStyle : borderStyle
                    }
                    hoverColor="#0071F2"
                  />
                </Col>
                <Col
                  id={`${parent_id}-${i}-expiry-date-col`}
                  md={2}
                  lg={2}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  {/* <DateTimePicker
                    required={true}
                    label={"Expiry Date"}
                    dateFormat={"dd/MM/yyyy"}
                    value={val?.expiry_date ? val.expiry_date : null}
                    onChange={(value) =>
                      this.setData(
                        "expiry_date",
                        moment(value).format("YYYY-MM-DD"),
                        i
                      )
                    }
                    error={val?.error?.expiry_date ?? false}
                    // minDate={
                    //   val?.expiry_date ? moment(val?.expiry_date) : new Date()
                    // }
                    minDate={val?.expiry_date ? moment(val?.expiry_date).diff(moment(new Date()), 'days') >= 0 ? new Date() : moment(val?.expiry_date) : new Date()}
                  /> */}

                  {/* <TextInput
                    id={`${parent_id}-${i}-expiry-date-textinput`}
                    value={val?.expiry_date ? this.props.oldData?.loading ? null : val.expiry_date : null}
                    error={
                      val?.error?.expiry_date ? true : false
                    }
                    // max={moment(new Date()).format("YYYY-MM-DD")}
                    max={"9999-12-31"}
                    // min={(this?.props?.edit) ? moment(this.props.oldData?.data?.identification?.[i]?.expiry_date).diff(moment(new Date()), 'days') >= 0 ? moment(this.props.oldData?.data?.identification?.[i]?.expiry_date).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD")}
                    min={(this?.props?.edit && this.props.oldData?.data?.identification?.[i]?.expiry_date) ? moment(this.props.oldData?.data?.identification?.[i]?.expiry_date).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD")}
                    // min={(val?.expiry_date && this?.props?.edit) ? moment(this.props.oldData?.data?.identification?.[i]?.expiry_date).diff(moment(new Date()), 'days') >= 0 ? moment(this.props.oldData?.data?.identification?.[i]?.expiry_date).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      this.setData(
                        "expiry_date",
                        e.target.value,
                        i
                      )
                    }
                    isRequired={true}
                    label="Expiry Date"
                    type="date"
                    labelStyle={labelStyle}
                    style={val?.error?.expiry_date ? errorBorderStyle : borderStyle}
                    hoverColor="#0071F2"
                    helperText={(val?.expiry_date && this?.props?.edit && this.props.oldData?.data?.identification?.[i]?.expiry_date && !this.props.oldData?.loading) ? checkMaxDateValidation(this.props.oldData?.data?.identification?.[i]?.expiry_date, val?.expiry_date)??" ": checkMaxDateValidation(moment(new Date()).format("YYYY-MM-DD"), val?.expiry_date)}
                  /> */}
                  {/* <Typography
                    style={{
                      fontFamily: "poppin",
                      color: "#6F6F6F",
                      fontSize: "12px",
                      paddingBottom: "8px",
                    }}
                  >
                    {"Expiry Date"}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <CustomInputDatePicker
                    disabled={false}
                    placeholderText={getFormatForDateTimePicker("date")}
                    selectedDate={
                      val?.expiry_date
                        ? this.props.oldData?.loading
                          ? null
                          : new Date(val?.expiry_date)
                        : null
                      // val?.expiry_date
                      //   ? new Date(utcTOLocal(val?.expiry_date, "MM/DD/YYYY"))
                      //  new Date()
                    }
                    minDate={
                      this?.props?.edit &&
                      this.props.oldData?.data?.identification?.[i]?.expiry_date
                        ? new Date(
                            this.props.oldData?.data?.identification?.[
                              i
                            ]?.expiry_date
                          )
                        : new Date()
                    }
                    handleChange={(value) =>
                      this.setData("expiry_date", value, i)
                    }
                    inputField={
                      <TextInput
                        id={`${parent_id}-${i}-expiry-date-textinput`}
                        fullWidth
                        placeholder={getFormatForDateTimePicker("date")}
                        variant="outlined"
                        required={true}
                        disabled={false}
                        type={"text"}
                        value={
                          val?.expiry_date
                            ? format(new Date(), getFormatForDateTimePicker("date"))
                            : null
                        }
                        inputProps={{
                          style: {
                            padding: "10.5px 14px",
                            fontFamily: "poppin",
                            fontSize: "12px",
                            borderRadius: 8,
                            borderColor: "#e0e0e0",
                            // backgroundColor: data?.disabled
                            //   ? "#E0E0E0"
                            //   : "#fff",
                          },
                        }}
                      />
                    }
                  /> */}
                  <DateTimePicker
                      parent_id={parent_id}
                      label={"Expiry Date"}
                      placeholder={getFormatForDateTimePicker("date")}
                      // dateFormat={"dd-MM-yyyy"}
                      onChange={(value) => this.setData("expiry_date", value, i)}
                      value={val?.expiry_date ? moment(val?.expiry_date) : null}
                      minDate={
                        this?.props?.edit &&
                        this.props.oldData?.data?.identification?.[i]?.expiry_date
                          ? new Date(
                              this.props.oldData?.data?.identification?.[
                                i
                              ]?.expiry_date
                            )
                          : new Date()
                      }
                    />
                </Col>
                {/* {JSON.stringify("old"+this.props.oldData?.data?.identification?.[i]?.expiry_date)}
                {JSON.stringify(val?.expiry_date)}
                {JSON.stringify(moment(this.props.oldData?.data?.identification?.[i]?.expiry_date).diff(moment(new Date()), 'days'))} */}

                {/* {this.props.saveto !== "nextOfKin" && <Col md={2} lg={2} sm={6} xs={12} inLineStyles={{ padding: 10 }}>
                        <Select value={val?.id_source?.value ?? null}
                            helperText={(val?.error && val?.error['id_source']) ? 'Is required' : ''}
                            error={(val?.error && val?.error['id_source']) ? true : false}
                            onChangeValue={(e) => this.setData("id_source", e, i)}
                            options={this.FlterOptions('Relationship')}
                            showArrow
                            options={optiondata}
                            isRequired={true}
                            //  isRequired={val?.id_type ? true : false}
                            label="ID Source" placeholder="select..." labelStyle={labelStyle}
                            inLineStyles={borderStyle} hoverColor="#0071F2" />
                    </Col>} */}
                <Col
                  id={`${parent_id}-${i}-upload-document-col`}
                  md={4}
                  lg={4}
                  sm={12}
                  xs={12}
                  className="pi-upload-root"
                  inLineStyles={{ padding: 10 }}
                >
                  {!val?.doc?.[0]?.filename ? (
                    <Div
                      id={`${parent_id}-${i}-upload-document-div`}
                      style={{ display: "flex", marginTop: "18px" }}
                    >
                      <Upload
                        id={`${parent_id}-${i}-upload-document-Upload`}
                        label="Upload Document"
                        className="mt"
                        handleChange={(e, value) =>
                          this.setDataUpload("doc", value, i)
                        }
                        labelColor="#0071F2"
                        iconColor="#0071F2"
                        iconSize={14}
                        imgUrl="images/uploadBlue.svg"
                        labelStyle={{
                          fontSize: 14,
                          fontFamily: "pc_medium !important",
                        }}
                      />
                      {/* <Div
                        style={{
                          width: 15,
                          cursor: "pointer",
                        }}
                      >
                        <Image
                          src="images/icons8-trash.svg"
                          alt="delete"
                          onClick={() => this.handleDelete(i)}
                        />
                      </Div> */}
                    </Div>
                  ) : (
                    <div id={`${parent_id}-${i}-Filenameui-div`}>
                      {
                        <div
                          id={`${parent_id}-${i}-Filenameui-sub-div`}
                          style={{ padding: 0 }}
                        >
                          <Filenameui
                            parent_id={"Filenameui"}
                            fileName={val?.doc?.[0]?.filename ?? " "}
                            delete={() => this.setData("doc", null, i)}
                          />
                        </div>
                      }
                      {/* <div
                        style={{
                          width: 15,
                          cursor: "pointer",
                        }}
                      >
                        <Image
                          src="images/icons8-trash.svg"
                          alt="delete"
                          onClick={() => this.handleDelete(i)}
                        />
                      </div> */}
                    </div>
                  )}
                  {mapped?.length > 0 && i !== 0 && (
                    <div
                      id={`${parent_id}-${i}-delete-div`}
                      style={{ marginTop: "30px", marginLeft: "20px" }}
                    >
                      <img
                        id={`${parent_id}-${i}-delete-img`}
                        src={Delete}
                        alt="delete"
                        onClick={() => this.handleDelete(i)}
                      />
                      {/* <CloseIcon onClick={() => this.handleDelete(i)}/> */}
                    </div>
                  )}

                  {mapped?.length > 1 && i === 0 && (
                    <Text
                      id={`${parent_id}-${i}-show-less-text`}
                      className="pc_medium"
                      style={{
                        color: "#F58B00",
                        marginLeft: 15,
                        cursor: "pointer",
                        fontSize: "14px",
                        marginTop: 20,
                      }}
                      onClick={() => this.handleLimit()}
                    >
                      {this.state.limited
                        ? "Show Less"
                        : `Show +${mapped?.length}`}{" "}
                    </Text>
                  )}
                </Col>
              </Row>
            ))}
        </Paper>
      </Div>
    );
  }
}

export default withAllContexts(withStyles(style)(PatientIdentification));
