import React, { useState } from "react";
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
  TextField,
} from "@material-ui/core";

import DetailsCard from "./detailsCard";
import { SelectBox } from "../../../../../../components/common/smartForm/component";
import { actions } from "frequencyscreen_v_dbinder";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AlertContext } from "../../../../../../contexts";
import { validationFormEnbty } from "../../../../../../utils";

// validation field keys json
const validationList = [
  {
    componet_type: "select",
    state_name: "encounterType",
  },
  {
    componet_type: "select",
    state_name: "gender",
  },
  {
    componet_type: "select",
    state_name: "ageRange",
  },
  {
    componet_type: "select",
    state_name: "agecategory",
  },
  {
    componet_type: "text",
    state_name: "patientInstrction"
  },
  {
    componet_type: "text",
    state_name: "practitionerInstrction"
  },
  {
    componet_type: "text",
    state_name: "prepratoryInstrction"
  },
  {
    componet_type: "text",
    state_name: "departmentInstrction"
  }
]

const useStyles = makeStyles((theme) => ({
  root: {
    background:theme.palette.background.table,
    padding: "20px",
    borderRadius: "0px 0px 8px 8px",
    "& .App1-MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "none",
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  textfieldGrid: {
    marginTop: "4px",
  },

  SecondGrid: {
    marginTop: 15,
    padding: 10,
    border: "1px solid #E0E0E0",
    borderRadius: 8,
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
    marginBottom: 10,
  },
  maintitle: {
    marginTop: 10,
    fontFamily: "poppinsemibold",
    fontSize: "14px",
  },
}));

function InstructionsTab(props) {
  const classes = useStyles();
  const alert = React.useContext(AlertContext);

  const [collapse, setCollaplse] = useState(false);

  const [state, setState] = React.useState({
    encounterType: null,
    gender: null,
    ageRange: null,
    agecategory: [],
    patientInstrction: "",
    practitionerInstrction: "",
    prepratoryInstrction: "",
    departmentInstrction: "",
    _id: "",
    editIndex: null,
  });

  let dispatch = useDispatch();
  const storeData = useSelector((state) => state?.orderCatalogSlice);

  // React.useEffect(() => {
  //   getMasterData();
  // }, []);

  // const getMasterData = async () => {
  //   await dispatch(actions.ORDER_CATALOG_MASTERS());
  // };

  const handleOpen = () => {
    setCollaplse(!collapse);
  };

  // forStateChange;
  const handleState = async (name, value) => {
    if (name === "ageRange" && value) {
      await dispatch(
        actions.AGE_RANGES_CATEGORY_CATALOG({
          ageRangeId: value?.value,
        })
      );
      state["agecategory"] = []
    }
    state[name] = value;
    setState({ ...state });
  };

  const handleEdit = async (data, i) => {
    
    let ageCategory;
    if (data._id) {
      let ageranges_category_catalog = await dispatch(
        actions.AGE_RANGES_CATEGORY_CATALOG({
          ageRangeId: data.ageRange?.value,
        })
      );
      let selected_category = data?.agecategory?.length > 0 && data?.agecategory?.map((v) => {
        return v?.value;
      });
      ageCategory = ageranges_category_catalog?.payload?.data?.length > 0 && ageranges_category_catalog?.payload?.data?.filter(
        (sys) => selected_category.indexOf(sys.value) > -1
      );
    }

    setState({
      ...state,
      encounterType: data.encounterType,
      gender: data.gender,
      ageRange: data.ageRange,
      agecategory: data._id ? ageCategory : data.agecategory,
      patientInstrction: data.patientInstrction,
      practitionerInstrction: data.practitionerInstrction,
      prepratoryInstrction: data.prepratoryInstrction,
      departmentInstrction: data.departmentInstrction,
      _id: data._id,
      editIndex: i,
    });

    handleOpen();
  };
  const handleDelete = (i) => {
    let instructionData = JSON.parse(JSON.stringify(instructions));
    instructionData.splice(i, 1);
    handleFormState("instructions", instructionData);
  };

  const AddInstruction = async() => {
    // validation form fucntion at least one filed to be fill
    let { error, errorState } = await validationFormEnbty(validationList, state);
    if(error){
      alert.setSnack("mandatory", "Require at least one field");
      return false
    }
    let instructionData = JSON.parse(JSON.stringify(instructions));
    if (state.editIndex || state.editIndex === 0) {
      instructionData[state.editIndex] = state;
    } else {
      instructionData.push(state);
    }
    setState({
      encounterType: null,
      gender: null,
      ageRange: null,
      agecategory: [],
      freqvalue: "",
      freqValSelect: null,
      freqrate: "",
      freqRatSelect: null,
      freqdura: "",
      freqDuraSelect: null,
      freqmax: "",
      _id: "",
      editIndex: null,
    });
    handleFormState("instructions", instructionData);
    handleOpen();
  };
  const { handleFormState, instructions, parent_id } = props;
  return (
    <div>
      <Paper
        id={`${parent_id}-parent-paper`}
        className={classes.root} elevation={0}>
        <Grid
          id={`${parent_id}-parent-container`}
          container>
          <Grid
            id={`${parent_id}-parent-grid`}
            item xs={12}>
            <Button
              id={`${parent_id}-Add-New-button`}
              className={classes.addBtn}
              onClick={() => handleOpen()}
              color="primary"
            >
              + Add New
            </Button>
          </Grid>
        </Grid>

        <DetailsCard
          parent_id={"DetailsCard"}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          detailsData={instructions}
        />

        {collapse && (
          <>
            <Typography
              id={`${parent_id}-Add-New-typography`}
              className={classes.maintitle} variant="h6">
              ADD NEW
            </Typography>
            <Grid
              id={`${parent_id}-Details-grid`}
              className={classes.SecondGrid}>
              <Typography
                id={`${parent_id}-Details-label-typography`}
                className={classes.title} variant="h6">
                Details
              </Typography>
              <Grid
                id={`${parent_id}-Encounter-grid`}
                container spacing={2}>
                <Grid
                  id={`${parent_id}-Encounter-sub-grid`}
                  xs={4} item>
                  <Typography
                    id={`${parent_id}-Encounter-label-typography`}
                    className={classes.formtitle} variant="body1">
                    Encounter Type
                  </Typography>
                  <SelectBox
                    parent_id={"instructiontab"}
                    list={
                      storeData?.order_catalog_masters?.data?.encounterType ??
                      []
                    }
                    placeholder={"Select"}
                    value={state?.encounterType}
                    onchange={(e, value) => handleState("encounterType", value)}
                  />
                </Grid>
                <Grid
                  id={`${parent_id}-Gender-label-grid`}
                  xs={4} item>
                  <Typography
                    id={`${parent_id}-Gender-label-typography`}
                    className={classes.formtitle} variant="body1">
                    Gender
                  </Typography>
                  <SelectBox
                    parent_id={"instructiontab"}
                    //list={storeData.reference_ageranges.data ?? []}
                    list={storeData?.order_catalog_masters?.data?.gender ?? []}
                    placeholder={"Select"}
                    value={state?.gender}
                    onchange={(e, value) => handleState("gender", value)}
                  />
                </Grid>

                <Grid
                  id={`${parent_id}-Range-Group-label-grid`}
                  xs={4} item>
                  <Typography
                    id={`${parent_id}-Range-Group-label-typography`}
                    className={classes.formtitle} variant="body1">
                    Age Range Group
                  </Typography>
                  <SelectBox
                    parent_id={"instructiontab"}
                    //list={storeData.reference_ageranges.data ?? []}
                    list={
                      storeData?.order_catalog_masters?.data?.ageGroup ?? []
                    }
                    placeholder={"Select"}
                    value={state?.ageRange}
                    onchange={(e, value) => handleState("ageRange", value)}
                  />
                </Grid>
                <Grid
                  id={`${parent_id}-Age-Category-label-gird`}
                  xs={12} item>
                  <Typography
                    id={`${parent_id}-Age-Category-label-typography`}
                    className={classes.formtitle} variant="body1">
                    Age Category 
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Typography>
                  <SelectBox
                    parent_id={"instructiontab"}
                    list={storeData?.ageranges_category_catalog?.data ?? []}
                    multi
                    placeholder={"Select"}
                    value={state?.agecategory}
                    onchange={(e, value) => handleState("agecategory", value)}
                  />
                </Grid>
                <Grid
                  id={`${parent_id}-Patient-label-grid`}
                  item xs={12}>
                  <Typography
                    id={`${parent_id}-Patient-label-typography`}
                    className={classes.formtitle} variant="body1">
                    Patient Instruction
                  </Typography>
                  <TextField
                    id={`${parent_id}-Patient-textField`}
                    multiline
                    value={state?.patientInstrction}
                    onChange={(e) =>
                      handleState("patientInstrction", e.target.value)
                    }
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid
                  id={`${parent_id}-Practitioner-label-grid`}
                  item xs={12}>
                  <Typography
                    id={`${parent_id}-Practitioner-label-typography`}
                    className={classes.formtitle} variant="body1">
                    Practitioner Instruction
                  </Typography>
                  <TextField
                    id={`${parent_id}-Practitioner-textField`}
                    multiline
                    value={state?.practitionerInstrction}
                    onChange={(e) =>
                      handleState("practitionerInstrction", e.target.value)
                    }
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    id={`${parent_id}-Prepratory-label-typography`}
                    className={classes.formtitle} variant="body1">
                    Prepratory Instruction
                  </Typography>
                  <TextField
                    id={`${parent_id}-Prepratory-textField`}
                    multiline
                    value={state?.prepratoryInstrction}
                    onChange={(e) =>
                      handleState("prepratoryInstrction", e.target.value)
                    }
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid
                  id={`${parent_id}-Department-label-grid`}
                  item xs={12}>
                  <Typography
                    id={`${parent_id}-Department-label-typography`}
                    className={classes.formtitle} variant="body1">
                    Department Instruction
                  </Typography>
                  <TextField
                    id={`${parent_id}-Department-textField`}
                    multiline
                    value={state?.departmentInstrction}
                    onChange={(e) =>
                      handleState("departmentInstrction", e.target.value)
                    }
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              id={`${parent_id}-buttons-grid`}
              style={{ textAlign: "center", marginTop: 20 }}>
              <Button
                id={`${parent_id}-Cancel-button`}
                onClick={() => handleOpen("cancel")}
                style={{ marginRight: "20px" }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                id={`${parent_id}-ADD-button`}
                onClick={() => AddInstruction()}
                variant="contained"
                color="primary"
              >
                {state?.editIndex || state?.editIndex === 0 ? "Update" : "ADD"}
              </Button>
            </Grid>
          </>
        )}
      </Paper>
    </div>
  );
}

export default InstructionsTab;
