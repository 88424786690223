import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";
import IDMPANEL from "idmpanel-pkg";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as ALLMCORE from "@material-ui/core";
import cookie from "react-cookies";
import { makeStyles } from "@material-ui/core/styles";

import * as ALL from "../../components";
import { Modal } from "qdm-component-library";
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Avatar,
  IconButton,
} from "@material-ui/core";

// import { Autocomplete } from "@material-ui/lab";
import { InputBase, Autocomplete } from "..";
import qdmstyles from "./styles.json";
import "./index.css";
import { TrashIcon } from "../../assets";
import classNames from "classnames";
var aes256 = require("aes256");

const useStyles = makeStyles((theme) => ({
  synonym: {
    color: theme.palette.text.primary
  },
  synonym_type: {
    color: theme.palette.primary.light
  },
  commonBackground:{
    backgroundColor:theme.palette.background.table
  }

}));

function Synonymcomp({ shouldDisable, ...props }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();


  const contextType = React.useContext(LoggerContext);
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  const {
    data,
    index,
    options,
    statevalue = [],
    stateName,
    onAddNewClick = () => { },
    onDeleteClick = () => { },
    setFun = () => { },
  } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid
            key={"0"}
            id={"OXKhr"}
            container={""}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            item={true}
          >
            <Paper
              key={"0"}
              id={"2CyVg"}
              elevation={"{3}"}
              style={qdmstyles.bdBel} 
              className={classes.commonBackground}
            >
              {/* Header Starts */}
              <Grid
                key={"0"}
                id={"TgstR"}
                container={true}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                lg={"12"}
                item={true}
                xl={"12"}
                xs={"12"}
                sm={"12"}
                md={"12"}
                style={qdmstyles.FzOFq}
              >
                <Grid
                  key={"0"}
                  id={"TgstR"}
                  container={""}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item={true}
                  lg={"5"}
                  md={"10"}
                  sm={"6"}
                  xl={"6"}
                  xs={"6"}
                >
                  <Typography
                    key={"0"}
                    id={"SynonymHeading_Typography"}
                    align={"left"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"SYNONYM"}
                    className={classes.synonym}
                    aliasName={"SynonymHeading"}
                    style={qdmstyles.UENRN}
                  ></Typography>
                </Grid>
                <Grid
                  key={"1"}
                  id={"TgstR"}
                  container={""}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  item={true}
                  lg={"2"}
                  md={"2"}
                  sm={"2"}
                  xl={"2"}
                  xs={"2"}
                  style={qdmstyles.Zoos}
                >
                  <Button
                    key={"0"}
                    id={"SynonymAddnewbutton_Button"}
                    variant={"text"}
                    color={"primary"}
                    children={"+ Add New"}
                    disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                    aliasName={"SynonymAddnewbutton"}
                    style={qdmstyles.UQJ}
                    onClick={() => onAddNewClick(stateName)}
                  ></Button>
                </Grid>
              </Grid>
              {/* Header Ends */}

              {/* Body Starts */}
              <Grid
                key={"1"}
                id={"0PNTj"}
                container={true}
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
                wrap={"wrap"}
                spacing={""}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
              >
                <Grid
                  key={"0"}
                  id={"7uXWP"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"baseline"}
                  lg={"12"}
                  item={true}
                  md={"12"}
                  sm={"12"}
                  xl={"12"}
                  xs={"12"}
                  spacing={1}
                >
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={6} md={2} lg={3}>
                      <Typography
                        key={"0"}
                        id={"synonymTypeHeading_Typography"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Synonym Type"}
                        className={classes.synonym_type}
                        aliasName={"synonymTypeHeading"}
                        style={qdmstyles.HScd}
                      ></Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <Typography
                        key={"0"}
                        id={"NameHeading_Typography"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        className={classes.synonym_type}
                        children={"Name"}
                        aliasName={"NameHeading"}
                        style={qdmstyles.DoNfh}
                      ></Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Typography
                        key={"0"}
                        id={"ShortdescriptionHeading_Typography"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        className={classes.synonym_type}
                        children={"Short Description"}
                        aliasName={"ShortdescriptionHeading"}
                        style={qdmstyles.micoB}
                      ></Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography
                        key={"0"}
                        id={"long_desctipition_header"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        className={classes.synonym_type}
                        children={"Long Description"}
                        aliasName={"longdesctipitionheader"}
                        style={qdmstyles.FBQ}
                      ></Typography>
                    </Grid>
                  </Grid>

                  {Array.isArray(statevalue) &&
                    statevalue?.map((item, index) => {
                      const rowState = statevalue?.[index];
                      return (
                        <Grid container item xs={12} spacing={1}>
                          <Grid item xs={12} sm={6} md={2} lg={3}>
                            <Autocomplete
                              id={"kXfMY"}
                              style={qdmstyles?.kXfMY}
                              disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                              getOptionLabel={(option) => option?.["label"]}
                              options={options?.synonym_type ?? []}
                              size={"small"}
                              placeholder="label"
                              onLoad={() =>
                                setFun(
                                  stateName,
                                  "synonymType",
                                  rowState?.synonymType,
                                  index
                                )
                              }
                              value={rowState?.synonymType ?? null}
                              onChange={(event, newValue) =>
                                setFun(
                                  stateName,
                                  "synonymType",
                                  newValue,
                                  index
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={2} lg={2}>
                            <InputBase
                              key={"0"}
                              id={"langID"}
                              label={""}
                              placeholder={""}
                              disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                              type={"text"}
                              variant={"outlined"}
                              size={"small"}
                              fullWidth={true}
                              margin={"none"}
                              aliasName={"langID"}
                              inputProps={{
                                maxlength: 15,
                              }}
                              value={rowState?.langID}
                              onChange={(event) =>
                                setFun(
                                  stateName,
                                  "langID",
                                  event.target.value,
                                  index
                                )
                              }
                            ></InputBase>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputBase
                              key={"0"}
                              id={"short_description_value1"}
                              label={""}
                              disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                              placeholder={""}
                              type={"text"}
                              variant={"outlined"}
                              size={"small"}
                              fullWidth={true}
                              margin={"none"}
                              aliasName={"shortdescriptionvalue1"}
                              inputProps={{
                                maxlength: 30,
                              }}
                              value={rowState?.shortDesc}
                              onChange={(event) =>
                                setFun(
                                  stateName,
                                  "shortDesc",
                                  event.target.value,
                                  index
                                )
                              }
                            ></InputBase>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={4}
                           
                            spacing={1}
                          >
                            <Grid item xs>
                              <InputBase
                                key={"0"}
                                id={"long_description_value1"}
                                disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                                label={""}
                                placeholder={""}
                                type={"text"}
                                variant={"outlined"}
                                fullWidth={true}
                                size={"small"}
                                margin={"none"}
                                aliasName={"longdescriptionvalue1"}
                                inputProps={{
                                  maxlength: 100,
                                }}
                                value={rowState?.longDesc}
                                onChange={(event) =>
                                  setFun(
                                    stateName,
                                    "longDesc",
                                    event.target.value,
                                    index
                                  )
                                }
                              ></InputBase>
                            </Grid>
                            <Grid item>
                              <IconButton
                                disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                                onClick={() => onDeleteClick(stateName, index)}
                              >
                                <TrashIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
              {/* Body Ends */}
            </Paper>
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer >
  );
}

export default withRouter(translate()(Synonymcomp));
