import { Grid, TextField, Typography } from "@material-ui/core";
import { Div, TextInput } from "qdm-component-library";
import React from "react";
import { useSelector } from "react-redux";
import { UIColor } from "../../../../../utils";
import Select from "../../../../common/Select";
import { Autocomplete } from "../../../../inputs";
import DatePicker from "react-datepicker";
import moment from "moment";

function ScheduleLine(props) {
  const base_frequency_dropdown = useSelector(
    (state) =>
      state?.EditNewFrequency_orgbase_frequency_dropdownReducer
        ?.base_frequency_dropdown?.data
  );
  const scheduleLine_day_dropdown = useSelector(
    (state) => state?.schedule_line_day_dropdownReducer?.schedule_line_day?.data
  );

  const onchange = (name, value, index) => {
    props.handleScheduleLineChange(name, value, index);
  };
  return (
    <div
      style={{
        padding: "20px",
        minWidth: "200px",
        border: "1px solid #dee5ec",
        borderRadius: "8px",
      }}
    >
      <Typography
        style={{ fontSize: "12px", fontWeight: 600, marginBottom: "15px" }}
      >
        SCHEDULE LINES
      </Typography>
      {props?.type === "Detail" &&
        props?.data?.frequency?.map((val, index) => (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Div style={props?.styles.labelStyle}>Time</Div>
              <DatePicker
                id={`DatePicker`}
                selected={
                  val?.time ? new Date(moment.unix(val?.time, "hh:mm A")) : ""
                }
                onChange={(e) =>
                  onchange("time", moment(e, "hh:mm A").unix(), index)
                }
                timeFormat={"HH:mm"}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={5}
                timeCaption="Time"
                dateFormat={"HH:mm"}
                placeholderText="Time"
              />
            </Grid>
            <Grid item xs={8}>
              <Div style={props?.styles.labelStyle}>Remarks</Div>
              <TextInput
                // id={`${parent_id}_medication_notes_textinput`}
                label={""}
                // placeholder="type here...."
                hoverColor={UIColor.lineBorderFill.color}
                value={val?.remarks}
                style={{ borderColor: UIColor.lineBorderFill.color }}
                onChange={(e) => {
                  onchange("remarks", e.target.value, index);
                }}
              />
            </Grid>
          </Grid>
        ))}
      {(props?.type === "Interval" || props?.type === "Sequential") &&
        props?.data?.frequency?.map((val, index) => (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Div style={props?.styles.labelStyle}> Base Frequency</Div>
              <Autocomplete
                id={"fW4qT"}
                getOptionLabel={(option) => option.val}
                value={
                  {
                    code: val?.basefreq?._id ?? "",
                    val:
                      val?.basefreq?.freqcode + " " + val?.basefreq?.longdesc ??
                      "",
                  } ?? {}
                }
                options={base_frequency_dropdown?.result?.map((v) => {
                  return {
                    val: v?.freqcode + " " + v?.shortdesc,
                    code: v?._id,
                  };
                })}
                onChange={(e, newvalue) =>
                  onchange("basefreq", newvalue, index)
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" margin="dense" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Div style={props?.styles.labelStyleScheduleLine}>Remarks</Div>
              <TextInput
                label={""}
                hoverColor={UIColor.lineBorderFill.color}
                value={val?.remarks}
                style={{ borderColor: UIColor.lineBorderFill.color }}
                onChange={(e) => {
                  onchange("remarks", e.target.value, index);
                }}
              />
            </Grid>
          </Grid>
        ))}

      {props?.type === "Day Based" &&
        props?.data?.frequency?.map((val, index) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Div style={props?.styles.labelStyle}>Day</Div>
                <Autocomplete
                  getOptionLabel={(option) => option.val}
                  value={
                    {
                      code: val?.day?._id ?? "",
                      val: val?.day?.display ?? "",
                    } ?? {}
                  }
                  options={scheduleLine_day_dropdown?.result?.map((v) => {
                    return {
                      val: v?.shortdesc,
                      code: v?._id,
                    };
                  })}
                  onChange={(e, newvalue) => onchange("day", newvalue, index)}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" margin="dense" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Div style={props?.styles.labelStyle}> Base Frequency</Div>
                <Autocomplete
                  getOptionLabel={(option) => option.val}
                  value={
                    {
                      code: val?.basefreq?._id ?? "",
                      val:
                        val?.basefreq?.freqcode +
                          " " +
                          val?.basefreq?.longdesc ?? "",
                    } ?? {}
                  }
                  options={base_frequency_dropdown?.result?.map((v) => {
                    return {
                      val: v?.freqcode + " " + v?.shortdesc,
                      code: v?._id,
                    };
                  })}
                  onChange={(e, newvalue) =>
                    onchange("basefreq", newvalue, index)
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" margin="dense" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Div style={props?.styles.labelStyleScheduleLine}>Remarks</Div>
                <TextInput
                  label={""}
                  hoverColor={UIColor.lineBorderFill.color}
                  value={val?.remarks}
                  style={{ borderColor: UIColor.lineBorderFill.color }}
                  onChange={(e) => {
                    onchange("remarks", e.target.value, index);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        ))}

      {props?.type === "User Defined" &&
        props?.data?.frequency?.map((val, index) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Div style={props?.styles.labelStyleScheduleLine}>From Day</Div>
                <TextInput
                  // id={`${parent_id}_medication_notes_textinput`}
                  label={""}
                  hoverColor={UIColor.lineBorderFill.color}
                  value={val?.fromDay ?? ""}
                  style={{ borderColor: UIColor.lineBorderFill.color }}
                  onChange={(e) => {
                    onchange("fromDay", e.target.value, index);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Div style={props?.styles.labelStyleScheduleLine}>To Day</Div>
                <TextInput
                  // id={`${parent_id}_medication_notes_textinput`}
                  label={""}
                  // placeholder="type here...."
                  hoverColor={UIColor.lineBorderFill.color}
                  value={val?.toDay ?? ""}
                  style={{ borderColor: UIColor.lineBorderFill.color }}
                  onChange={(e) => {
                    onchange("toDay", e.target.value, index);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Div style={props?.styles.labelStyle}> Base Frequency</Div>
                <Autocomplete
                  getOptionLabel={(option) => option.val}
                  value={
                    {
                      code: val?.basefreq?._id ?? "",
                      val:
                        val?.basefreq?.freqcode +
                          " " +
                          val?.basefreq?.longdesc ?? "",
                    } ?? {}
                  }
                  options={base_frequency_dropdown?.result?.map((v) => {
                    return {
                      val: v?.freqcode + " " + v?.shortdesc,
                      code: v?._id,
                    };
                  })}
                  onChange={(e, newvalue) =>
                    onchange("basefreq", newvalue, index)
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" margin="dense" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Div style={props?.styles.labelStyleScheduleLine}>Remarks</Div>
                <TextInput
                  // id={`${parent_id}_medication_notes_textinput`}
                  label={""}
                  // placeholder="type here...."
                  hoverColor={UIColor.lineBorderFill.color}
                  value={val?.remarks}
                  style={{ borderColor: UIColor.lineBorderFill.color }}
                  onChange={(e) => {
                    onchange("remarks", e.target.value, index);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        ))}

      {props?.type === "" && (
        <Div style={props?.styles.labelStyle}>The Type is not defined</Div>
      )}
    </div>
  );
}

export default ScheduleLine;
