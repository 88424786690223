import { makeStyles } from "@material-ui/core/styles";

export const useDialogStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 875,
    borderRadius: 8,
    background: theme.palette.background.table,
  },
}));

export const useDialogContentStyles = makeStyles((theme) => ({
    dividers: {
      padding: 22,
      borderColor: "rgba(222, 229, 236, 1)",
    },
  }));
  
