import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {
  CartFilledIcon,
  CreateIcon,
  Calender as CalendarIcon,
  Clock as ClockIcon,
} from "../../../../../assets";
import TimingDisplay from "./timingDisplay";
import moment from "moment";
import TimeComponent from "../../../../../components/timeComponent";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: "1px solid #DEE5EC",
    borderRadius: 8,
    padding: "16px 20px 16px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 20,
  },
  index: {
    width: "23px",
    height: "36px",
    background: "#DEE5EC",
    borderRadius: "0px 8px 8px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  timingDisplayContainer: {
    display: "flex",
    alignItems: "center",
    gap: 24,
  },
  flexAuto: {
    flex: 1,
  },
  effectiveDatesValue: {
    minWidth: 157,
  },
}));

function TimingRow({ index = 0, data, onEditClick, isEdit, ...props }) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.index}>{index}</div>
      <div className={classes.timingDisplayContainer}>
        <TimingDisplay
          icon={<CartFilledIcon />}
          label={"Cart Start Time"}
          value={moment(data?.cartstarttime).format("HH:mm")}
          // valueComponent={<TimeComponent dateTime={data?.cartstarttime} formatType="time" />}
        />
        <TimingDisplay
          icon={<ClockIcon color="#2A60BC" />}
          label={"Duration"}
          value={data?.durationinhrs?.label ?? null}
        />
        <TimingDisplay
          icon={<CartFilledIcon />}
          label={"Close Cut-Off Time"}
          value={moment(data?.clscutofftime).format("HH:mm")}
          // valueComponent={<TimeComponent dateTime={data?.clscutofftime} formatType="time" />}
        />
        {/* <TimingDisplay
          icon={<CalendarIcon color="#2A60BC" />}
          label={"Effective Dates"}
          // value={`${moment(data?.efffrdate).format("DD MMM, YYYY")}${
          //   Boolean(data?.efftodate)
          //     ? ` - ${moment(data?.efftodate).format("DD MMM, YYYY")}`
          //     : ""
          // }`}
          valueClassName={classes.effectiveDatesValue}
          valueComponent={
            <TimeComponent
              dateTime={[data?.efffrdate, data?.efftodate]}
              separator={" - "}
            />
          }
        /> */}
        <TimingDisplay
          icon={<CalendarIcon color="#2A60BC" />}
          label={"Week days"}
          value={
            Array.isArray(data?.weekdays) &&
            data?.weekdays?.map((item) => item?.label)?.join(", ")
          }
        />
      </div>
      <div className={classes.flexAuto}></div>
      {isEdit && (
        <IconButton size="small" onClick={onEditClick}>
          <CreateIcon />
        </IconButton>
      )}
    </div>
  );
}

export default TimingRow;
