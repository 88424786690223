import React from "react";
import { actions } from "frequencyscreen_v_dbinder";
import { connect } from "react-redux";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter } from "react-router-dom";
// import { languageOptions, Permissions } from "../../utils";
import { LoggerContext } from "../../contexts";

// import IDMPANEL from "idmpanel-pkg";
// import Config from "../../config";
// import cookie from "react-cookies";

// import * as ALL from "../../components";

// import { Modal } from "qdm-component-library";

import { Grid, Typography, TextField, Button } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
// var aes256 = require("aes256");

class Addnewalertandrestriction extends React.Component {
  static contextType = LoggerContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      permissionData: null,
    };
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() {
    /// initializing transalation
    if (localStorage.defaultLng) {
      const { i18n } = this.props;
      i18n.changeLanguage(localStorage.defaultLng);
    }

    /// setlogger && dispatch of all events

    /// fetch all permission
  }

  ///fetching all permission

  shouldComponentUpdate(props, state) {
    return true;
  }

  render() {
    return (
      <NamespacesConsumer>
        {(t, { i18n }) => (
          <React.Fragment>
            <Grid
              key={"0"}
              id={"gURfx"}
              container={true}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid
                key={"0"}
                id={"8i9n4"}
                container={true}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                aliasName={"performingDetails"}
                style={qdmstyles.ECgZo}
              >
                <Grid
                  key={"0"}
                  id={"6wBxd"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  aliasName={"performingDiv"}
                  item={true}
                  style={qdmstyles.RMur}
                >
                  <Typography
                    key={"0"}
                    id={"874cz"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"ORDER CRITERIA"}
                    style={qdmstyles.JOfXL}
                  ></Typography>
                  <Grid
                    key={"1"}
                    id={"L7OA3"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    aliasName={"performingForm"}
                    spacing={"2"}
                    lg={"3"}
                  >
                    <Grid
                      key={"0"}
                      id={"rf214"}
                      container={false}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      aliasName={"performingFacility"}
                      xs={"4"}
                      item={true}
                    >
                      <Typography
                        key={"0"}
                        id={"JpfaA"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Code"}
                        style={qdmstyles.iEepb}
                      ></Typography>
                      <TextField
                        key={"1"}
                        id={"DtVAe"}
                        label={""}
                        placeholder={""}
                        type={"text"}
                        variant={"outlined"}
                        name={"performingFacility"}
                        size={"small"}
                        fullWidth={true}
                        disabled={false}
                        style={qdmstyles.NwCo}
                      ></TextField>
                    </Grid>
                    <Grid
                      key={"1"}
                      id={"rf214"}
                      container={false}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      aliasName={"performingFacility"}
                      xs={"4"}
                      item={true}
                    >
                      <Typography
                        key={"0"}
                        id={"JpfaA"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Description"}
                        style={qdmstyles.qAY}
                      ></Typography>
                      <TextField
                        key={"1"}
                        id={"DtVAe"}
                        label={""}
                        placeholder={""}
                        type={"text"}
                        variant={"outlined"}
                        name={"performingLocationType"}
                        size={"small"}
                        fullWidth={true}
                        disabled={false}
                        style={qdmstyles.mMFB}
                      ></TextField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  key={"1"}
                  id={"8i9iw"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  aliasName={"performingContents"}
                  style={qdmstyles.QLIlN}
                >
                  <Grid
                    key={"0"}
                    id={"N1D0i"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                  >
                    <Grid
                      key={"0"}
                      id={"pGfHO"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      aliasName={"medicationSelection"}
                      style={qdmstyles.dvzQc}
                    >
                      <Grid
                        key={"0"}
                        id={"AVtfC"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <Typography
                          key={"0"}
                          id={"TA2ko"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"PATIENT CRITERIA"}
                          style={qdmstyles.UDo}
                        ></Typography>
                        <Grid
                          key={"1"}
                          id={"rJpEh"}
                          container={true}
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          <Button
                            key={"0"}
                            id={"Nxg7E"}
                            variant={"contained"}
                            color={"primary"}
                            children={"Add New Rule Set"}
                            style={qdmstyles.BBgOR}
                          ></Button>
                          <Typography
                            key={"1"}
                            id={"jiYFh"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"(or)"}
                            style={qdmstyles.eCOOx}
                          ></Typography>
                          <Button
                            key={"2"}
                            id={"Nxg7E"}
                            variant={"contained"}
                            color={"primary"}
                            children={"Add Existing Rule Set"}
                            style={qdmstyles.kRgDZ}
                          ></Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      key={"1"}
                      id={"pGfHO"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      aliasName={"medicationSelection"}
                      style={qdmstyles.IKQxs}
                    >
                      <Grid
                        key={"0"}
                        id={"AVtfC"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <Typography
                          key={"0"}
                          id={"TA2ko"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"PRACTITIONER CRITERIA"}
                          style={qdmstyles.vGI}
                        ></Typography>
                        <Grid
                          key={"1"}
                          id={"rJpEh"}
                          container={true}
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          <Button
                            key={"0"}
                            id={"Nxg7E"}
                            variant={"contained"}
                            color={"primary"}
                            children={"Add New Rule Set"}
                            style={qdmstyles.vRUw}
                          ></Button>
                          <Typography
                            key={"1"}
                            id={"jiYFh"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"(or)"}
                            style={qdmstyles.LuYtm}
                          ></Typography>
                          <Button
                            key={"2"}
                            id={"Nxg7E"}
                            variant={"contained"}
                            color={"primary"}
                            children={"Add Existing Rule Set"}
                            style={qdmstyles.vKgK}
                          ></Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      key={"2"}
                      id={"yRF5g"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      lg={"1"}
                      style={qdmstyles.yRFg}
                    >
                      <Typography
                        key={"0"}
                        id={"ryHjK"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Rule Type"}
                        style={qdmstyles.ZPwVE}
                      ></Typography>
                      <Grid
                        key={"1"}
                        id={"y1Y7s"}
                        container={false}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        lg={"12"}
                        item={true}
                      >
                        <Button
                          key={"0"}
                          id={"bzyHU"}
                          variant={"contained"}
                          color={"primary"}
                          children={"Restriction"}
                          disabled={true}
                          style={qdmstyles.YVZhr}
                        ></Button>
                        <Button
                          key={"1"}
                          id={"fmfuQ"}
                          variant={"contained"}
                          color={"primary"}
                          children={"Alert"}
                          style={qdmstyles.NhL}
                        ></Button>
                      </Grid>
                    </Grid>
                    <Grid
                      key={"3"}
                      id={"yRF5g"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      lg={"3"}
                      style={qdmstyles.BnRP}
                    >
                      <Typography
                        key={"0"}
                        id={"ryHjK"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Privilege Type"}
                        style={qdmstyles.cqB}
                      ></Typography>
                      <Grid
                        key={"1"}
                        id={"y1Y7s"}
                        container={false}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        lg={"12"}
                        item={true}
                      >
                        <Button
                          key={"0"}
                          id={"fmfuQ"}
                          variant={"contained"}
                          color={"primary"}
                          children={"Alert & Stop"}
                          style={qdmstyles.EwFcB}
                        ></Button>
                        <Button
                          key={"1"}
                          id={"bzyHU"}
                          variant={"contained"}
                          color={"primary"}
                          children={"Continue with reason"}
                          disabled={true}
                          style={qdmstyles.yhJJ}
                        ></Button>
                      </Grid>
                    </Grid>
                    <Grid
                      key={"4"}
                      id={"xrzZR"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      style={qdmstyles.xrzZR}
                    >
                      <Typography
                        key={"0"}
                        id={"jzG9F"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Alert Text Message"}
                      ></Typography>
                      <Grid
                        key={"1"}
                        id={"UBznZ"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        lg={"12"}
                      >
                        <TextField
                          key={"0"}
                          id={"TtOuZ"}
                          label={"name"}
                          placeholder={"name"}
                          type={"text"}
                          variant={"outlined"}
                          fullWidth={true}
                          style={qdmstyles.TtOuZ}
                        ></TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </NamespacesConsumer>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  actions
)(withRouter(translate()(Addnewalertandrestriction)));
