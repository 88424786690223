import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { CustomButton, StyledDialog } from "../../../../../components";
import TimingRow from "./timingRow";
import TimingForm from "./timingForm";
import { useDialogStyles, useDialogContentStyles } from "./style";
import update from "immutability-helper";
import moment from "moment";
import { AlertProps } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 16,
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
    // color: "#001C3C",
    color: theme.palette.text.primary,
    textTransform: "uppercase",
  },
  timeRowsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
}));

function Timing({
  state,
  stateName,
  setState,
  options,
  selectedWeekdays,
  setSelectedWeekdays,
  isEdit,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  const dialogStyles = useDialogStyles();
  const dialogContentStyles = useDialogContentStyles();

  // Component States
  const [open, setOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const handleDialogOpen = (index = null) => {
    setOpen(true);
    setEditIndex(index);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setEditIndex(null);
  };

  const checkIfFormHasErrors = (data) => {
    let hasErrors = false;

    // Effective From date is mandatory
    if (!Boolean(data?.efffrdate)) {
      hasErrors = true;
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Effective From date is mandatory",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return hasErrors;
    }

    // Effective From date should not exceed Effective To date
    if (Boolean(data?.efffrdate) && Boolean(data?.efftodate)) {
      const efffrdate = moment(data.efffrdate);
      const efftodate = moment(data.efftodate);
      if (efffrdate.isAfter(efftodate)) {
        hasErrors = true;
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Effective to date should be after Effective from date.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return hasErrors;
      }
    }

    return hasErrors;
  };

  const getSelectedWeekdays = (Timings = []) => {
    // Functionality to avoid multiple same day timings
    let newSelectedWeekdays = Array.isArray(selectedWeekdays)
      ? [...selectedWeekdays]
      : [];
    Timings.map((timing) => {
      newSelectedWeekdays = [
        ...(newSelectedWeekdays || []),
        ...(timing?.weekdays || []),
      ];
    });

    newSelectedWeekdays = [...new Set(newSelectedWeekdays)];

    return newSelectedWeekdays;
  };

  const handleSave = (newTiming) => {
    // if (checkIfFormHasErrors(newTiming)) {
    //   return;
    // }

    if (editIndex !== null) {
      const updatedTiming = update(state, {
        [stateName]: {
          [editIndex]: {
            $set: newTiming,
          },
        },
      });
      setState(stateName, updatedTiming?.[stateName]);
      // setSelectedWeekdays(getSelectedWeekdays(updatedTiming?.[stateName]));
      handleDialogClose();
    } else {
      const Timings = Array.isArray(state?.Timings) ? [...state?.Timings] : [];
      Timings.push(newTiming);
      setState(stateName, Timings);

      const res = getSelectedWeekdays(Timings);
      // setSelectedWeekdays(res);

      handleDialogClose();
    }
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>
            Timings <span style={{ color: "red" }}>*</span>
          </Typography>
          {
            // !isEdit &&
            !state?.[stateName]?.length > 0 && (
              <CustomButton
                variant={"text"}
                color={theme.palette.text.primary}
                onClick={() => handleDialogOpen()}
              >
                + Add New
              </CustomButton>
            )}
        </div>
        <div className={classes.timeRowsContainer}>
          {Array.isArray(state?.[stateName]) &&
            state?.[stateName]?.map((item, index) => (
              <TimingRow
                index={index + 1}
                data={item}
                onEditClick={() => handleDialogOpen(index)}
                isEdit={isEdit}
              />
            ))}
        </div>
      </div>

      {/* Dialog */}
      <StyledDialog
        id="Timings"
        open={open}
        handleClose={handleDialogClose}
        title={"Timings"}
        dialogProps={{
          classes: dialogStyles,
        }}
        contentProps={{
          classes: dialogContentStyles,
        }}
        titleStyle={{
          color: theme.palette.text.primary,
        }}
      >
        <TimingForm
          timingState={state?.[stateName]?.[editIndex]}
          options={options}
          onSaveClick={handleSave}
          onCancelClick={handleDialogClose}
          selectedWeekdays={selectedWeekdays}
        />
      </StyledDialog>
    </>
  );
}

export default withAllContexts(Timing);
