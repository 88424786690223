import React from "react";

import { NamespacesConsumer, translate } from "react-i18next";

import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import {
  Typography,
  Divider,
  TextField,
  Card,
  Button,
  Paper,
} from "@material-ui/core";
import calender_ from "../../assets/calendar-Blue.svg";
import city_ from "../../assets/GeoMaster/city.svg";
import Grid from "@material-ui/core/Grid";
import { Autocomplete } from "@material-ui/lab";
import CartClose from "../cartclose";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  close: {
    margin: "19px",
  },
  cart: {
    color: theme.palette.text.primary,
    // color: "#001C3C",
    font: "14px",
    fontWeight: "bold",
    padding: "0px 8px",
    paddingBottom: "10px",
  },
  title: {
    fontSize: "14px",

  },
  sideicon: {
    display: "flex",
    padding: "10px 0px",
  },
  btn: {
    display: "flex",
    padding: "0px 4px",
    // justifyContent: "space-evenly",
  },
  btns: {
    color: theme.palette.text.primary,
    background: `${theme.palette.background.table} !important`,
    fontSize: 12,
    borderRadius: "8px",
    marginRight: 6,
  },
  carts: {
    color: theme.palette.text.primary,
    // color: "#2A3C50",
    fontSize: "14px",
  },
  img: {
    backgroundColor: "#F1F6FC",
    borderRadius: "30px",
  },
  cartBtn: {
    // backgroundColor: "#001C3C",
    // color: "#FFFFFF",
    color: theme.palette.text.primary,
    borderRadius: "8px",
    fontSize: 12,
  },
  divider: {
    width: "100vh",
  },
  resize: {
    fontSize: 12,
  },
  Maintitle: {
    color: theme.palette.text.primary,
  },
  MainBackground: {
    background: `${theme.palette.background.common} !important`,
  },
  MainBackgroundCard: {
    background: `${theme.palette.background.table} !important`,
  },
  dropdown: {
    background: `${theme.palette.background.table} !important`,
    "&:input": {
      color: theme.palette.text.primary,
    }
  }
}));

function Cartdetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = React.useState({});
  const [open, setOpen] = React.useState(false);
  // const [value, setValue] = React.useState(options[0]);

  React.useEffect(() => {
    /// initializing transalation

    if (localStorage.defaultLng) {
      const { i18n } = props;

      i18n.changeLanguage(localStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => { }, [state]);

  const handleclick = () => {
    props.setWard && props.setWard(true);
    props.handleState();
  };

  const handleClickOpen = async () => {
    await props.handlegetTransactionCart && props.handlegetTransactionCart()
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    // setSelectedValue(value);
  };
  //   const handleclick2 = ()=>{
  //     history.push(routes.addpractitioner)
  //   }

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          {/* {props?.Json.map((val)=>( */}
          <Card
            style={{
              height: props?.closeCart ? "auto" : "90vh",
              padding: 10,
              boxShadow: "none",
            }}
            className={classes.MainBackground}
          >
            <Grid container spacing={2} justifyContent="space-between">
              {!props.closeCart && (
                <Grid item>
                  <Typography className={classes.cart} style={{ padding: "4px 0px 8px" }}>Cart Details</Typography>
                </Grid>
              )}
            </Grid>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  className={classes.title}
                  style={{ marginTop: "8px" }}
                >
                  Cart Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <Autocomplete
                  size="small"
                  options={props?.Json?.detail ?? []}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, v) => props?.handleChange('cartNameValue', v)}
                  value={props?.data?.cartNameValue ?? {}}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  PaperComponent={({ children }) => (
                    <Paper className={classes.dropdown}>{children}</Paper>
                  )}
                  className={classes.dropdown}
                />
              </Grid>

              <Grid item xs={12}>
                {/* {!ward ? ( */}
                <>
                  <Typography
                    className={classes.title}
                  // style={{ marginTop: "8px" }}
                  >
                    Pharmacy Location
                  </Typography>
                  <Autocomplete
                    size="small"
                    disabled
                    options={props?.Json?.issue ?? []}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, v) => props?.handleChange('subStoreValue', v)}
                    value={props?.data?.subStoreValue ?? {}}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    PaperComponent={({ children }) => (
                      <Paper className={classes.dropdown}>{children}</Paper>
                    )}
                    className={classes.dropdown}
                  />
                  {/* </> */}
                  {/* // ) : (
                //   <> */}
                  {/* <Typography
                    className={classes.title}
                    style={{ marginTop: "8px" }}
                  >
                    Issue store
                  </Typography>
                  <Autocomplete
                    size="small"
                    options={props?.Json?.issue ?? []}
                    getOptionLabel={(option) => option.value}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  /> */}
                </>
                {/* // )} */}
              </Grid>
            </Grid>
            {/* {ward && ( */}
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Typography className={classes.title}>Ward <span style={{ color: "red" }}>*</span></Typography>
                <Autocomplete
                  size="small"
                  options={props?.Json?.ward ?? []}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, v) => props?.handleChange('wardValue', v)}
                  value={props?.data?.wardValue ?? {}}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  PaperComponent={({ children }) => (
                    <Paper className={classes.dropdown}>{children}</Paper>
                  )}
                  className={classes.dropdown}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.title}>&nbsp;</Typography>
                <TextField
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  disabled
                  value={props?.data?.wardValue?._loc ?? props?.data?.wardValueCode ?? ''}
                  variant="outlined"
                  size="small"
                  className={classes.dropdown}
                />
              </Grid>
            </Grid>
            {/* )} */}

            {props?.Json?.cart && <div className={classes.sideicon}>
              <div className={classes.img}>
                <img
                  src={city_}
                  width="18px"
                  style={{ margin: "10px 10px 0px 10px " }}
                  alt="pic"
                />
              </div>&nbsp;
              <Typography
                style={{

                  fontSize: "13px",
                }}
              >
                Cart <br />
                <span className={classes.carts}>{props?.Json?.cart}</span>
              </Typography>
            </div>}

            {props?.Json?.cart && <div className={classes.sideicon}>
              <div className={classes.img}>
                <img
                  src={calender_}
                  width="18px"
                  style={{ margin: "10px 10px 0px 10px" }}
                  alt="pic"
                />
              </div>&nbsp;
              <Typography
                style={{

                  fontSize: "13px",
                }}
              >
                Start date & End date <br />
                <span className={classes.carts}>{props?.Json?.date}</span>
              </Typography>
            </div>}

            <br />
            <div className={classes.btn}>
              {!props?.closeCart ? (
                <Button
                  className={classes.btns}
                  variant="contained"
                  color="primary"
                  onClick={handleclick}
                >
                  {" "}
                  Generate
                </Button>
              ) : (
                <Button
                  className={classes.btns}
                  variant="contained"
                  color="primary"
                  onClick={() => props.handleclickShowDetails && props.handleclickShowDetails()}
                >
                  Show details
                </Button>
              )}

              {props?.closeCart ? (
                <Button
                  variant="contained"
                  className={classes.btns}
                  color="primary"
                  onClick={handleClickOpen}
                  disabled={props.hideCloseCart}
                >
                  Close Cart
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className={classes.btns}
                  color="primary"
                  onClick={() => props.handleOpenCart && props.handleOpenCart()}
                  disabled={!props?.OrderData?.length > 0}
                >
                  Open Cart
                </Button>
              )}
            </div>
            <br />
          </Card>
          {/* ))} */}
          <CartClose open={open} onClose={handleClose} cartReason={props.cartReason ?? []} selectData={props.selectData || {}} />
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Cartdetails));
