
import * as React from "react";
import PropTypes from "prop-types";

const BeingSick = (props) => (

  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20.204" viewBox="0 0 22.331 20.204">
  <path id="icons8-being-sick" d="M14.867,5a2.651,2.651,0,0,0-2.081,1,1.072,1.072,0,0,1-.748.4v1.26l8.241,0a.8.8,0,0,1,0,1.6l-8.241,0v1.258a1.071,1.071,0,0,1,.747.4,2.652,2.652,0,0,0,2.082,1h5.412a3.456,3.456,0,1,0,0-6.912ZM10.443,6.6l-1.516.042a1.826,1.826,0,0,0,0,3.639l1.516.042ZM3.785,8.179A.8.8,0,0,0,3,8.988V24.406a.8.8,0,1,0,1.6,0v-.8h19.14v.8a.8.8,0,1,0,1.6,0v-3.19a1.861,1.861,0,0,0-1.861-1.861H4.595V8.988a.8.8,0,0,0-.81-.809Zm4.532,5.328a2.127,2.127,0,1,0,2.127,2.127A2.127,2.127,0,0,0,8.317,13.507Zm5.67,0a1.948,1.948,0,0,0-1.948,1.948V17.76H23.413a2.92,2.92,0,0,0-2.6-4.253Z" transform="translate(-3 -5)" fill="#8895a5"/>
</svg>
);

export default BeingSick;

BeingSick.defaultProps = {
  color: "#2a3c50",
};

BeingSick.propTypes = {
  color: PropTypes.string,
};
