import React from "react";

import { NamespacesConsumer, translate } from "react-i18next";

import { withRouter } from "react-router-dom";

import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  Card,
  Grid,
  CardContent,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const useStyles = makeStyles({
  normal: {
    minWidth: 80,
    backgroundColor: "#ffff",
    marginLeft: "13px",
  },
  selected: {
    minWidth: 80,
    backgroundColor: "#01205C",
    color: "white !important",
    marginLeft: "13px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  div: {
    marginTop: "20px",
    marginLeft: "23px",
  },
  align: {
    marginTop: "25px",
    marginLeft: "23px",
  },
  heading: {
    fontSize: "12px",
    fontWeight: 500,
    marginBottom: "10px",
  },
});

function LocationRoles(props) {
  const classes = useStyles();
  const { practitonerDetails } = props;
  const [location, setLocation] = React.useState();
  const [facilitystate, setFaciilitystate] = React.useState();
  const [rolestate, setRolestate] = React.useState(null);
  const dispatch = useDispatch();

  const handleRole = (data) => {
    setRolestate(data);
  };

  React.useEffect(() => {
    /// initializing transalation

    if (localStorage.defaultLng) {
      const { i18n } = props;

      i18n.changeLanguage(localStorage.defaultLng);
    }

    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);
  console.clear();
  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <div className={classes.div}>
            <Typography className={classes.heading}>ROLE :</Typography>
            <div style={{ display: "flex", marginTop: "15px" }}>
              {practitonerDetails?.map((data) => {
                return (
                  <div>
                    <Card
                      onClick={() => handleRole(data)}
                      className={
                        data?.PractitionerRole?._id ===
                        rolestate?.PractitionerRole?._id
                          ? classes.selected
                          : classes.normal
                      }
                    >
                      <CardContent
                        style={{
                          paddingBottom: "12px",
                          paddingTop: "12px",
                          paddingRight: "5px",
                          paddingLeft: "8px",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "12px", textAlign: "center" }}
                        >
                          {data.PractitionerRole?.role?.[0]}
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
          <Grid className={classes.align}>
            <Typography className={classes.heading}>LOCATION</Typography>
            <Autocomplete
              id="combo-box-demo"
              size={"small"}
              options={rolestate?.location ?? []}
              value={location}
              onChange={(event, newValue) => {
                setLocation(newValue);
              }}
              getOptionLabel={(option) => option?.locationID}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>

          <Grid className={classes.align}>
            <Typography className={classes.heading}>FACILITY</Typography>
            <Autocomplete
              id="combo-box-demo"
              size={"small"}
              options={rolestate?.facility ?? []}
              value={facilitystate}
              onChange={(event, newValue) => {
                setFaciilitystate(newValue);
              }}
              getOptionLabel={(option) => option?.name}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>

          <Button
            disabled={location && facilitystate && rolestate ? false : true}
            onClick={() =>
              props.onNextClick(location, facilitystate, rolestate)
            }
            style={{ float: "right", marginTop: "20px" }}
          >
            Next
          </Button>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(LocationRoles));
