import React from "react";
import { NamespacesConsumer, translate } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import { Grid, Box, Checkbox, Typography, makeStyles } from "@material-ui/core";

import qdmstyles from "./styles.json";
import "./index.css";
import {
  FieldContainer,
  FieldLabel,
  // InputBase,
  TextfieldWithSelect,
} from "../../components";

const useStyle = makeStyles((theme) => ({
  use_addituce: {
    color: theme.palette.text.primary,
  },
  ph_childtitle: {
    color: theme.palette.primary.light,
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0px",
    textAlign: "left",
    textTransform: "capitalize",
  },
  fieldContainer: {
    paddingLeft: 10,
    marginTop: 10,
  },
}));

function Drugotherattributes(props) {
  const location = useLocation();
  const classes = useStyle();

  const [state, setState] = React.useState({
    permissionData: null,
  });

  React.useEffect(() => {
    /// initializing transalation
    if (sessionStorage.defaultLng) {
      const { i18n } = props;
      i18n.changeLanguage(sessionStorage.defaultLng);
    }
    /// setlogger && dispatch of all events

    /// fetch all permission
  }, []);

  ///fetching all permission

  React.useEffect(() => {}, [state]);

  const {
    stateName,
    statevalue,
    setFun = () => {},
    shouldDisable,
    isDrugTypeVehicle,
    options,
  } = props;

  return (
    <NamespacesConsumer>
      {(t, { i18n }) => (
        <React.Fragment>
          <Grid container>
            <Grid
              key={"0"}
              id={"dmbxa"}
              container={true}
              item={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              lg={"12"}
              wrap={"wrap"}
              alignContent={"flex-start"}
              style={qdmstyles.dmbxa}
            >
              <Box
                key={"0"}
                id={"a85gl"}
                component={"div"}
                style={qdmstyles.agl}
              >
                <Checkbox
                  key={"0"}
                  id={"B7nCd"}
                  color={"primary"}
                  disabled={
                    location?.state?.isView === true || !shouldDisable
                      ? true
                      : false
                  }
                  size={"small"}
                  style={qdmstyles.jDvDk}
                  checked={statevalue?.Additive}
                  onChange={(event) =>
                    setFun(stateName, "Additive", event.target.checked)
                  }
                  inputProps={{ "aria-label": "Use As Additive" }}
                ></Checkbox>
                <Typography
                  key={"1"}
                  id={"BU52G"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  className={classes.use_addituce}
                  children={"Use As Additive"}
                  style={qdmstyles.BUG}
                ></Typography>
              </Box>
              <Box
                key={"1"}
                id={"a85gl"}
                component={"div"}
                style={qdmstyles.ihUn}
              >
                <Checkbox
                  key={"0"}
                  id={"B7nCd"}
                  color={"primary"}
                  disabled={
                    location?.state?.isView === true || !shouldDisable
                      ? true
                      : false
                  }
                  size={"small"}
                  style={qdmstyles.jDvDk}
                  checked={statevalue?.ApplicableForCompounding}
                  onChange={(event) =>
                    setFun(
                      stateName,
                      "ApplicableForCompounding",
                      event.target.checked
                    )
                  }
                  inputProps={{ "aria-label": "Use For Compound Preparation" }}
                ></Checkbox>
                <Typography
                  key={"1"}
                  id={"BU52G"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  className={classes.use_addituce}
                  children={"Use For Compound Preparation"}
                  style={qdmstyles.bUWkK}
                ></Typography>
              </Box>
              <Box
                key={"2"}
                id={"a85gl"}
                component={"div"}
                style={qdmstyles.qUEoU}
              >
                <Checkbox
                  key={"0"}
                  id={"B7nCd"}
                  color={"primary"}
                  disabled={
                    location?.state?.isView === true || !shouldDisable
                      ? true
                      : false
                  }
                  size={"small"}
                  style={qdmstyles.jDvDk}
                  checked={statevalue?.DispenseviaprescriptionOnly}
                  onChange={(event) =>
                    setFun(
                      stateName,
                      "DispenseviaprescriptionOnly",
                      event.target.checked
                    )
                  }
                  inputProps={{ "aria-label": "Dispense Via Rx Only" }}
                ></Checkbox>
                <Typography
                  key={"1"}
                  id={"BU52G"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"Dispense Via Rx Only"}
                  className={classes.use_addituce}
                  style={qdmstyles.uTrv}
                ></Typography>
              </Box>
              <Box
                key={"3"}
                id={"a85gl"}
                component={"div"}
                style={qdmstyles.qcBS}
              >
                <Checkbox
                  key={"0"}
                  id={"B7nCd"}
                  color={"primary"}
                  disabled={
                    location?.state?.isView === true || !shouldDisable
                      ? true
                      : false
                  }
                  size={"small"}
                  style={qdmstyles.jDvDk}
                  checked={statevalue?.CounsellingRequired}
                  onChange={(event) =>
                    setFun(
                      stateName,
                      "CounsellingRequired",
                      event.target.checked
                    )
                  }
                  inputProps={{ "aria-label": "Counselling Required" }}
                ></Checkbox>
                <Typography
                  key={"1"}
                  id={"BU52G"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  className={classes.use_addituce}
                  children={"Counselling Required"}
                  style={qdmstyles.cRMr}
                ></Typography>
              </Box>
              <Box
                key={"4"}
                id={"kjahdl"}
                component={"div"}
                style={qdmstyles.qcBS}
              >
                <Checkbox
                  key={"0"}
                  id={"clasdx"}
                  color={"primary"}
                  disabled={
                    location?.state?.isView === true || !shouldDisable
                      ? true
                      : false
                  }
                  size={"small"}
                  style={qdmstyles.jDvDk}
                  checked={statevalue?.returnApplicable}
                  onChange={(event) =>
                    setFun(stateName, "returnApplicable", event.target.checked)
                  }
                  inputProps={{ "aria-label": "Return Applicable" }}
                ></Checkbox>
                <Typography
                  key={"1"}
                  id={"szBU52G"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  className={classes.use_addituce}
                  children={"Return Applicable"}
                  style={qdmstyles.cRMr}
                ></Typography>
              </Box>

              <Box
                key={"5"}
                id={"kjahdl5"}
                component={"div"}
                style={qdmstyles.qcBS}
              >
                {/* <Checkbox
                key={"0"}
                id={"stability"}
                color={"primary"}
                disabled={location?.state?.isView === true || !shouldDisable ? true : false}
                size={"small"}
                style={qdmstyles.jDvDk}
                checked={statevalue?.stability}
                onChange={(event) =>
                  setFun(stateName, "stability", event.target.checked)
                }
                inputProps={{ "aria-label": "Stability Period for vehicle" }}
              ></Checkbox>
              <Typography
                key={"1"}
                id={"szBU52G"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                className={classes.use_addituce}
                children={"Stability Period for vehicle"}
                style={qdmstyles.cRMr}
              ></Typography> */}
              </Box>
            </Grid>
            {isDrugTypeVehicle && (
              <Grid container item xs={12}>
                <Grid item xs={4}>
                  <div className={classes.fieldContainer}>
                    <FieldContainer>
                      <FieldLabel
                        id={"Stability_Typography"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        required={false}
                        aliasName={"StabilityHeading"}
                        className={classes.ph_childtitle}
                      >
                        Stability Period for vehicle
                      </FieldLabel>
                      <TextfieldWithSelect
                        InputProps={{
                          id: `stability_period_for_vehicle`,
                          type: "number",
                          fullWidth: true,
                          disabled:
                            location?.state?.isView === true || !shouldDisable
                              ? true
                              : false,
                          value: statevalue?.stability,
                          onChange: (event) => {
                            if (event?.target?.value?.length > 30) return;
                            setFun(stateName, "stability", event.target.value);
                          },
                          onKeyPress: (event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          },
                        }}
                        // stabilityUOM
                        AutocompleteProps={{
                          id: `stability_period_for_vehicle_UOM`,
                          onLoad: () =>
                            setFun(
                              stateName,
                              "stabilityUOM",
                              statevalue?.stabilityUOM
                            ),
                          getOptionLabel: (option) => option?.["label"],
                          options: options?.uom_time_type ?? [],
                          value: statevalue?.stabilityUOM ?? null,
                          onChange: (event, newValue) =>
                            setFun(stateName, "stabilityUOM", newValue),
                          disabled:
                            location?.state?.isView === true || !shouldDisable
                              ? true
                              : false,
                        }}
                      />
                      {/* <InputBase
                        id={"stability"}
                        label={""}
                        placeholder={""}
                        type={"text"}
                        variant={"outlined"}
                        size={"small"}
                        fullWidth={true}
                        value={statevalue?.stability}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(event) =>
                          setFun(stateName, "stability", event.target.value)
                        }
                      ></InputBase> */}
                    </FieldContainer>
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
    </NamespacesConsumer>
  );
}

export default withRouter(translate()(Drugotherattributes));
