import React from "react";
import { CardContent } from "../../commonComponents";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Dot from "../../dot";

function OrderPractitionerInfo({ speciality, doctorName, ...props }) {
  return (
    <CardContent title="Order Info">
      <Box p={1.5} sx={{ "& > p": { fontWeight: 600 } }}>
        <Typography variant="body2">{speciality}</Typography>
        <Box display="flex" alignItems="center" mt={1} sx={{ gap: 6 }}>
          <Typography variant="body2">{doctorName}</Typography>
          <Dot />
          <Typography variant="body2">On 24 Feb, 2022</Typography>
        </Box>
      </Box>
    </CardContent>
  );
}

export default OrderPractitionerInfo;
